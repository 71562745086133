import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderCalendarType } from '@headway/api/models/ProviderCalendarType';
import { ProviderCalendarUpdate } from '@headway/api/models/ProviderCalendarUpdate';
import { ProviderCalendarApi } from '@headway/api/resources/ProviderCalendarApi';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@headway/shared/react-query';

import { getExternalCalendarsQueryKey } from 'views/Calendar/utils/queries';

const updateExternalCalendarsInQueryCache = (
  newOrUpdatedCalendar: ProviderCalendarRead,
  existingCalendars: Array<ProviderCalendarRead> | undefined
): Array<ProviderCalendarRead> => {
  if (!existingCalendars?.length) {
    return [newOrUpdatedCalendar];
  }

  // check if the calendar already exists in the cache:
  // - if it doesn't, add it
  // - else (it does), update it

  if (!existingCalendars.find((cal) => cal.id === newOrUpdatedCalendar.id)) {
    return [...existingCalendars, newOrUpdatedCalendar];
  }

  return existingCalendars.map((c) => {
    if (c.id === newOrUpdatedCalendar.id) {
      return newOrUpdatedCalendar;
    }

    return c;
  });
};

export const useAddExternalCalendarMutation = (
  options: UseMutationOptions<
    ProviderCalendarRead,
    unknown,
    { providerId: number; url: string; calendarName: string },
    unknown
  > = {}
) => {
  const queryClient = useQueryClient();

  const addExternalCalendarMutation = useMutation(
    async (variables: {
      providerId: number;
      url: string;
      calendarName: string;
    }) => {
      return ProviderCalendarApi.createProviderCalendar({
        providerId: variables.providerId,
        subscriptionUrl: variables.url,
        calendarName: variables.calendarName,
        type: ProviderCalendarType.EXTERNAL,
      });
    },
    {
      ...options,
      onSuccess(createdCalendar, variables, context) {
        queryClient.setQueryData(
          getExternalCalendarsQueryKey(createdCalendar.providerId),
          (existing: ProviderCalendarRead[] | undefined) =>
            updateExternalCalendarsInQueryCache(createdCalendar, existing)
        );

        options.onSuccess?.(createdCalendar, variables, context);
      },
    }
  );

  return addExternalCalendarMutation;
};

export function useSyncExternalCalendarMutation(
  options: UseMutationOptions<
    ProviderCalendarRead,
    unknown,
    { calendarId: number },
    unknown
  > = {}
) {
  const queryClient = useQueryClient();
  const syncCalendarMutation = useMutation(
    (variables: { calendarId: number }) => {
      return ProviderCalendarApi.syncProviderCalendar(variables.calendarId);
    },
    {
      ...options,
      onSuccess(syncedCalendar, variables, context) {
        queryClient.setQueryData(
          getExternalCalendarsQueryKey(syncedCalendar.providerId),
          (existing: ProviderCalendarRead[] | undefined) =>
            updateExternalCalendarsInQueryCache(syncedCalendar, existing)
        );

        options.onSuccess?.(syncedCalendar, variables, context);
      },
    }
  );

  return syncCalendarMutation;
}

export const useUpdateExternalCalendarMutation = (
  options: UseMutationOptions<
    ProviderCalendarRead,
    unknown,
    { calendarId: number; updateData: ProviderCalendarUpdate },
    unknown
  >
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: { calendarId: number; updateData: ProviderCalendarUpdate }) => {
      return ProviderCalendarApi.updateProviderCalendar(
        variables.calendarId,
        variables.updateData
      );
    },
    {
      ...options,
      onSuccess(updatedCalendar, variables, context) {
        queryClient.setQueryData(
          getExternalCalendarsQueryKey(updatedCalendar.providerId),
          (existing: ProviderCalendarRead[] | undefined) =>
            updateExternalCalendarsInQueryCache(updatedCalendar, existing)
        );

        options.onSuccess?.(updatedCalendar, variables, context);
      },
    }
  );
};

export const useDeleteExternalCalendarMutation = (
  options: UseMutationOptions<
    {},
    unknown,
    { calendarId: number; providerId: number },
    unknown
  >
) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: { calendarId: number; providerId: number }) => {
      return ProviderCalendarApi.deleteProviderCalendar(variables.calendarId);
    },
    {
      ...options,
      onSuccess(response, variables, context) {
        queryClient.setQueryData(
          getExternalCalendarsQueryKey(variables.providerId),
          (existing: ProviderCalendarRead[] | undefined) => {
            if (!existing) {
              return [];
            }

            return existing.filter((c) => {
              return c.id !== variables.calendarId;
            });
          }
        );

        options.onSuccess?.(response, variables, context);
      },
    }
  );
};
