import { isEmpty, omitBy, reduce } from 'lodash';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { ProviderPanelabilityEvaluation } from '@headway/api/models/ProviderPanelabilityEvaluation';
import {
  ConsolidatedRates,
  ConsolidatedRatesByState,
  Rates as RatesType,
} from '@headway/shared/types/rates';

export function excludeNonPanelableRates(
  rates: ConsolidatedRatesByState,
  carriersById: {
    [p: number]: FrontEndCarrierRead;
  },
  providerPanelabilityEvaluation: ProviderPanelabilityEvaluation
): ConsolidatedRatesByState {
  const panelabilityStatusByStateAndCarrierName =
    providerPanelabilityEvaluation?.stateInsuranceCarrierPanelabilityEvaluations?.reduce(
      (acc, { state, frontEndCarrierId, finalPanelabilityStatus }) => {
        if (!frontEndCarrierId) {
          // this shouldn't really happen
          return acc;
        }
        if (!acc[state]) {
          acc[state] = {};
        }
        const frontEndCarrierName = carriersById[frontEndCarrierId]?.name;
        if (frontEndCarrierName) {
          acc[state][frontEndCarrierName] = finalPanelabilityStatus;
        }
        return acc;
      },
      {} as {
        [state: string]: {
          [frontEndCarrierName: string]: PanelabilityStatus;
        };
      }
    );
  // ConsolidatedRatesByState is a 4 level deep object. we need to filter out rates based on the state key at the
  // outermost layer and the carrier key at the innermost layer to get the panelability status based on state + carrier
  return reduce<ConsolidatedRatesByState, ConsolidatedRatesByState>(
    rates,
    (consolidatedRatesByStateAcc, consolidatedRates, state) => {
      const consolidatedRatesExcludingNonPanelable = reduce<
        ConsolidatedRates,
        ConsolidatedRates
      >(
        consolidatedRates,
        (consolidatedRatesAcc, ratesMap, providerType) => {
          const ratesMapExcludingNonPanelable = reduce<RatesType, RatesType>(
            ratesMap,
            (ratesAcc, ratesByCarrier, cptCode) => {
              const ratesByCarrierExcludingNonPanelable = omitBy(
                ratesByCarrier,
                (_, carrierName) =>
                  panelabilityStatusByStateAndCarrierName?.[state]?.[
                    carrierName
                  ] === PanelabilityStatus.NOT_PANELABLE
              );
              if (!isEmpty(ratesByCarrierExcludingNonPanelable)) {
                return {
                  ...ratesAcc,
                  [cptCode]: ratesByCarrierExcludingNonPanelable,
                };
              } else {
                return ratesAcc;
              }
            },
            {}
          );
          if (!isEmpty(ratesMapExcludingNonPanelable)) {
            return {
              ...consolidatedRatesAcc,
              [providerType]: ratesMapExcludingNonPanelable,
            };
          } else {
            return consolidatedRatesAcc;
          }
        },
        {}
      );
      if (!isEmpty(consolidatedRatesExcludingNonPanelable)) {
        return {
          ...consolidatedRatesByStateAcc,
          [state]: consolidatedRatesExcludingNonPanelable,
        };
      } else {
        return consolidatedRatesByStateAcc;
      }
    },
    {}
  );
}
