import clsx from 'clsx';
import capitalize from 'lodash/capitalize';
import { useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { LinkButton } from '@headway/helix/LinkButton';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { ListHeader } from '@headway/helix/ListHeader';
import { PageSection } from '@headway/helix/Page';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { PROMS_ONLY_RATE_BOOST } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { trackEvent } from '@headway/shared/utils/analytics';

import TherapistImage from 'assets/img/cropped-therapist-study.png';
import {
  INCENTIVE_BONUS_AMOUNT_DOLLARS,
  RateBoostBannerCopyVariant,
} from 'views/Incentives/constants';

interface AssessmentsIncentiveCardProps {
  client: UserRead;
  onOpenManageAssessmentsModal: () => void;
}

export const AssessmentsIncentiveCard = ({
  client,
  onOpenManageAssessmentsModal,
}: AssessmentsIncentiveCardProps) => {
  const isBelowDesktopMedia = useMediaQuery(
    theme.__futureMedia.below('desktop')
  );

  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  useEffect(() => {
    trackEvent({
      name: 'Rate Boost Banner Viewed',
      properties: {
        copyVariant: RateBoostBannerCopyVariant.LARGE_GUIDANCE_CARD,
      },
    });
  }, []);

  return (
    <PageSection className={'px-0'}>
      <div
        className={`flex-between flex rounded-[8px] bg-hue-lightBlue ${
          !promsOnlyRateBoostEnabled && `border border-primary-blue`
        }`}
      >
        <div className={clsx('p-5', !isBelowDesktopMedia && `w-2/3`)}>
          {promsOnlyRateBoostEnabled ? (
            <div>
              <ListHeader>Earn a $5 rate boost for every session</ListHeader>
              <div className="pt-3">
                <BodyText>
                  Have your client complete an assessment once every 30 days and
                  earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} for{' '}
                  <strong>each and every session</strong> with that client — on
                  top of your standard rate.
                </BodyText>
              </div>
            </div>
          ) : (
            <div>
              <ListHeader>
                Have your client complete assessments to meet 1 of 2 criteria
                for rate boosts
              </ListHeader>
              <div className="pt-2">
                <BodyText>
                  <strong>
                    {`Meet both criteria to unlock a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} session rate boost:`}
                  </strong>
                </BodyText>
                <div className="pt-2">
                  <NumberedList>
                    <NumberedListItem>
                      A client in Michigan completes any Headway Assessment 30
                      days before or after a session
                    </NumberedListItem>
                    <NumberedListItem>
                      When confirming session details, upload an existing note
                      OR use a Headway template
                    </NumberedListItem>
                  </NumberedList>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center gap-3 pt-5">
            <LinkButton
              variant="secondary"
              href={'/incentives/rate-boost'}
              target="_blank"
              onPress={() =>
                trackEvent({ name: 'Learn More Rate Boost Button Clicked' })
              }
            >
              Learn more
            </LinkButton>
            <Button
              variant="primary"
              onPress={onOpenManageAssessmentsModal}
            >{`Send assessment to ${capitalize(client.firstName)}`}</Button>
          </div>
        </div>
        {!isBelowDesktopMedia && (
          <div className="w-1/3">
            <img
              alt="therapist-img"
              role="presentation"
              src={TherapistImage}
              className="h-full w-full rounded-r-[8px]"
            />
          </div>
        )}
      </div>
    </PageSection>
  );
};
