import React from 'react';

import { Banner } from '@headway/helix/Banner';
import { useQueryParam } from '@headway/shared/utils/queryParams';

import { NqfConsentForm, NqfConsentFormMode } from './NqfConsentForm';

/**
 * Displays patient consent form for NQF Aligned Innovation study.
 */
export const NqfConsentFormPreview = () => {
  const [clientId] = useQueryParam('clientId');
  const [providerPatientId] = useQueryParam('providerPatientId');
  return (
    <>
      <Banner variant="neutral">
        This is a preview of what your client would see when viewing this form
      </Banner>
      <NqfConsentForm
        clientId={parseInt(clientId!)}
        mode={NqfConsentFormMode.PREVIEW}
        providerPatientId={parseInt(providerPatientId!)}
        isSigmund
      />
    </>
  );
};
