import { css } from '@emotion/react';
import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { trackEvent } from '@headway/shared/utils/analytics';

import { useProviderPaymentPause } from 'hooks/useProviderPaymentPause';
import { getActiveProviderPaymentPause } from 'utils/providerPaymentPauses';

interface PausedPaymentsModalProps {
  providerId: number;
  open: boolean;
  onClose: () => Promise<void>;
}

export const PausedPaymentsModal = ({
  providerId,
  open,
  onClose,
}: PausedPaymentsModalProps) => {
  const { data: providerPaymentPauses, isLoading: isPaymentPausesLoading } =
    useProviderPaymentPause({ providerId, activeOnly: true });

  const providerPausedPayment = providerPaymentPauses
    ? getActiveProviderPaymentPause(providerPaymentPauses)
    : undefined;

  return (
    <Modal
      title="Your payments will be on hold"
      isOpen={open}
      onDismiss={async () => {
        await onClose();
      }}
    >
      <ModalContent>
        <BodyText>
          <p>
            <strong>What this means</strong>
          </p>
        </BodyText>
        <BodyText>
          <p>
            Moving forward, we'll review all your notes for sessions covered by
            insurance before issuing payment to ensure your notes meet{' '}
            <Link
              href="https://headway.co/resources/behavioral-health-compliance"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent({
                  name: 'Paused Payment Learn More Button Clicked',
                  properties: {
                    providerId: providerId,
                    auditBy: 'Headway',
                  },
                });
              }}
            >
              insurance compliance standards.
            </Link>
          </p>
        </BodyText>
        <BodyText>
          <p>
            <strong>What next?</strong>
          </p>
        </BodyText>
        <BodyText>
          <p>
            Submit progress notes for each session you confirm. Once we confirm
            these notes consistenly meet insurance standards, we'll discontinue
            reviews and resume automatic payments.
          </p>
        </BodyText>
        {!isPaymentPausesLoading && providerPausedPayment && (
          <section css={containerCss}>
            <BodyText>
              <p>
                <strong>Why is this happening?</strong>
              </p>
            </BodyText>
            <BodyText>
              <p>{providerPausedPayment.reason}</p>
            </BodyText>
          </section>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          onPress={async () => {
            await onClose();
          }}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const containerCss = css({
  background: `${theme.color.system.backgroundGray}`,
  padding: `${theme.spacing.x5} ${theme.spacing.x4}`,
  borderRadius: '3px',
  boxSizing: 'border-box',
  marginTop: `${theme.spacing.x4}`,
});
