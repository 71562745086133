import { Global } from '@emotion/react';
import React from 'react';

export const RemoveInitialLoadStyles = () => (
  <Global
    styles={{
      '.initial-load-container': {
        display: 'none !important',
      },
    }}
  />
);
