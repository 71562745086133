import { css } from '@emotion/react';
import React, { useRef } from 'react';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { Form } from '@headway/form-builder';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';
import Michael from '@headway/shared/assets/img/company/michael.png';

import { FormRef } from '../../form-builder/src/view/form';
import {
  isMedicareAdvantage,
  PostIntakeInfoCollectionFormState,
} from '../../shared/utils/postIntakeInfoCollection';
import { HumanTouchpoint } from '../HumanTouchpoint';
import { PostIntakeInfoCollectionForm } from './PostIntakeInfoCollectionForm';

type PostIntakeInfoCollectionProps = {
  isProviderCredentialed?: boolean;
  frontEndCarrierName: string;
  payerQuestionnaire: PayerQuestionnaire;
  formState: PostIntakeInfoCollectionFormState;
  onSaveForLater: (values: any) => any;
  onSubmit: (values: any) => any;
};

export const PostIntakeInfoCollectionContent: React.FC<
  React.PropsWithChildren<PostIntakeInfoCollectionProps>
> = ({
  isProviderCredentialed = false,
  frontEndCarrierName,
  payerQuestionnaire,
  formState,
  onSaveForLater,
  onSubmit,
}) => {
  const formRef = useRef<FormRef>(null);

  return (
    <div className="piic-root" css={postIntakeInfoCollectionCSS}>
      <div className="piic-header" css={postIntakeInfoCollectionCSS}>
        <span className="piic-heading">
          {' '}
          {isProviderCredentialed ? 'Continue working with' : 'Apply to'}{' '}
          {frontEndCarrierName}
        </span>
      </div>
      <div className="piic-content" css={postIntakeInfoCollectionCSS}>
        <div className="flex max-w-3xl flex-col">
          <div
            className={`my-8 flex flex-col gap-5 ${
              isMedicareAdvantage(frontEndCarrierName)
                ? 'border-system-borderGray border-b border-l-0 border-r-0 border-t-0 border-solid pb-8'
                : ''
            }`}
          >
            <div>
              <BrandText variant="section">
                Work with {frontEndCarrierName}
              </BrandText>
            </div>
            {isMedicareAdvantage(frontEndCarrierName) &&
            !isProviderCredentialed ? (
              <MedicareAdvantageContent />
            ) : (
              <BodyText>
                We need to collect a few pieces of new information so you can{' '}
                {isProviderCredentialed ? 'continue' : 'start'} seeing clients
                in the {frontEndCarrierName} network.
              </BodyText>
            )}
          </div>
          <div>
            <PostIntakeInfoCollectionForm
              formState={formState}
              onSubmit={async (values) => {
                await onSubmit(values);
              }}
              payerQuestionnaire={payerQuestionnaire}
              ref={formRef}
            />
          </div>
        </div>
      </div>
      <div className="piic-footer" css={postIntakeInfoCollectionCSS}>
        <Button
          variant="secondary"
          size="large"
          type="submit"
          onPress={async () => {
            await onSaveForLater(formRef.current?.getValues());
          }}
        >
          Save for later
        </Button>
        <Button
          variant="primary"
          size="large"
          type="submit"
          onPress={() => Form.Submit(formRef)}
        >
          {isMedicareAdvantage(frontEndCarrierName) ? 'Opt-in' : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

export const MedicareAdvantageContent = () => (
  <>
    <BodyText>
      <p>
        As a clinician myself, I know first-hand how much work it takes to
        navigate Medicare compliance requirements. That’s why our clinical team
        has created comprehensive training and time-saving, compliant
        documentation templates to guide you through working with Medicare.
      </p>
    </BodyText>

    <BodyText>
      <b>
        Before you start taking Medicare appointments, you’ll be required to
        complete a Headway Academy course.
      </b>
      <p>
        Our clinical team has created a comprehensive guide to help make sure
        you’re ready to meet Medicare compliance requirements. If you’ve
        completed this course before, you won’t need to complete it again.
      </p>
    </BodyText>

    <BodyText>
      <b>
        When you bill Medicare appointments, you’ll be required to use Headway
        progress note templates (for Medicare appointments only).
      </b>
    </BodyText>

    <HumanTouchpoint
      imageSources={[Michael]}
      primaryLabel="Michael Heckendorn"
      secondaryLabel="Headway Clinical Lead and LPC"
    />
  </>
);

// Based off the CSS used for the Helix Modal
export const postIntakeInfoCollectionCSS = css({
  ...theme.reset,
  '&.piic-root': {
    background: theme.color.system.white,
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    width: '100%',
    height: '100%',
  },
  '& .piic-header': {
    background: theme.color.system.white,
    display: 'grid',
    padding: theme.spacing.x5,
    alignItems: 'center',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto 1fr 44px',
    gap: theme.spacing.x2,
    [theme.__futureMedia.above('tablet')]: {
      borderBottom: `1px solid ${theme.color.system.borderGray}`,
      gridTemplateColumns: '1fr 1fr auto',
    },
  },
  '& .piic-header > .piic-button': {
    gridColumn: 1,
    gridRow: '1',
    [theme.__futureMedia.above('tablet')]: {
      gridColumn: 3,
    },
  },
  '& .piic-header > .piic-button svg': {
    height: 28,
    width: 28,

    [theme.__futureMedia.above('tablet')]: {
      height: 20,
      width: 20,
    },
  },

  '& .piic-content': {
    padding: theme.spacing.x5,
    overflow: 'auto',
    display: 'flex', //Added specifically for this page
    justifyContent: 'center', //Added specifically for this page
  },
  '& .piic-footer': {
    padding: theme.spacing.x5,
    alignItems: 'center',
    gap: theme.spacing.x2,
    display: 'grid',
    [theme.__futureMedia.above('tablet')]: {
      display: 'flex',
      justifyContent: 'end',
      borderTop: `1px solid ${theme.color.system.borderGray}`,
    },
    // Allow consumer to control dom order of footer buttons
    // so they can control tab priority order but always keep
    // the submit button on the right
    '& > .piic-button[type="submit"]': {
      order: 999,
    },
  },
  '& .piic-heading': {
    ...theme.typography.sectionHeader,
    color: theme.color.system.textBlack,
    gridColumn: '2',
    gridRow: '1',
    justifySelf: 'center',
    textAlign: 'center',

    [theme.__futureMedia.above('tablet')]: {
      gridColumn: '1/3',
      justifySelf: 'auto',
      textAlign: 'left',
    },
  },
});
