import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback, useMemo } from 'react';

import { validity } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';

import { SchemaComponent } from '../../schema/schema.types';
import { ViewComponent } from './ViewComponent';

const MemoizedSelect = React.memo(Select);

export const FormSelect = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, options, placeholder, subTitle } = element;
  const formik = useFormikContext();
  const formikSelectedKeys = get(formik.values as any, id);

  if (readOnly) {
    return <ViewComponent title={title} id={id} />;
  }

  const selectOptions = Array.from(options as string[]).map((option) => {
    return { key: option };
  });

  const selectedKeys = useMemo(
    () => [formikSelectedKeys] || [],
    [formikSelectedKeys]
  );

  const child = useCallback(
    (item: { key?: string }) => <Item>{item?.key}</Item>,
    []
  );
  const setFieldValue = formik.setFieldValue;

  const onSelectionChange = useCallback(
    (value: Set<string>) => {
      setFieldValue(id, Array.from(value)[0]);
    },
    [setFieldValue, id]
  );

  return (
    <MemoizedSelect
      name={id}
      label={title}
      items={selectOptions}
      selectedKeys={selectedKeys}
      selectionMode="single"
      menuWidth="stretch"
      validation={validity(id, formik)}
      onSelectionChange={onSelectionChange}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={subTitle}
    >
      {child}
    </MemoizedSelect>
  );
};
