import React from 'react';

import {
  FORETHOUGHT_LIVE_CHAT_ENABLED,
  LIVE_CHAT_INSURANCE_VERIFICATION,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { ForethoughtScriptProvider as BaseForethoughtScriptProvider } from '@headway/ui/Forethought';

import { useAuthStore } from '../stores/AuthStore';

const ForethoughtScriptProvider = (props: { children: React.ReactNode }) => {
  const AuthStore = useAuthStore();
  const isForethoughtFFEnabled = useFlag('forethoughtEnabled', false);

  const isLiveChatEnabled = useFlag(FORETHOUGHT_LIVE_CHAT_ENABLED, false);
  const isInsuranceVerificationViaLiveChatEnabled = useFlag(
    LIVE_CHAT_INSURANCE_VERIFICATION,
    false
  );

  if (!isForethoughtFFEnabled || !AuthStore.user) {
    return props.children;
  }

  const name = `${AuthStore.provider.displayFirstName} ${AuthStore.provider.displayLastName}`;

  return (
    <BaseForethoughtScriptProvider
      mambaHost={process.env.REACT_APP_API_URL}
      apiKey={process.env.REACT_APP_FORETHOUGHT_DATA_API_KEY}
      zendeskAPIKey={process.env.REACT_APP_ZENDESK_CHAT_API_KEY}
      email={AuthStore.user.email}
      name={name}
      providerID={AuthStore.provider.id}
      userID={AuthStore.user.id}
      defaultWorkflowTag="I provide mental health services"
      position="left"
      liveChatEnabled={isLiveChatEnabled}
      insuranceVerificationLiveChat={isInsuranceVerificationViaLiveChatEnabled}
    >
      {props.children}
    </BaseForethoughtScriptProvider>
  );
};

export { ForethoughtScriptProvider };
