import { useSlotId } from '@react-aria/utils';
import { AriaRadioProps } from '@react-types/radio';
import clsx from 'clsx';
import React, { useContext, useRef } from 'react';
import {
  mergeProps,
  useFocusRing,
  useHover,
  useRadio,
  VisuallyHidden,
} from 'react-aria';
import { Simplify } from 'type-fest';

import { Badge } from './Badge';
import { DATA } from './consts';
import { ValidationState } from './forms';
import { RadioGroupContext } from './RadioGroup';
import { formatError } from './utils/errorFormatter';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type RadioProps = Simplify<
  {
    validation?: ValidationState;
    description?: React.ReactNode;
    badge?: React.ReactElement;
  } & PickAndConfigure<
    AriaRadioProps,
    | 'autoFocus'
    | { children: 'children' }
    | { value: 'value' }
    | { disabled?: 'isDisabled' }
    | 'aria-describedby'
  >
>;

export const Radio = ({ disabled, value, ...props }: RadioProps) => {
  const radioGroupState = useContext(RadioGroupContext);
  if (!radioGroupState) {
    throw new Error(
      formatError('Radio components must be a descendant of RadioGroup')
    );
  }
  const ref = useRef<HTMLInputElement>(null);
  const descriptionId = useSlotId([Boolean(props.description)]);
  let badgeId = useSlotId([Boolean(props.description)]);

  if (props.badge) {
    const child = React.Children.only(props.badge);
    badgeId = child.props.id ?? badgeId;
  }

  const ariaProps = {
    value,
    isDisabled: disabled || radioGroupState.isDisabled,
    isSelected: radioGroupState.selectedValue === value,
    isReadOnly: radioGroupState.isReadOnly,
    ...props,
    'aria-describedby': [descriptionId, props['aria-describedby'], badgeId]
      .filter(Boolean)
      .join(' '),
  };

  const { inputProps } = useRadio(ariaProps, radioGroupState, ref);
  const { hoverProps, isHovered } = useHover({
    isDisabled: ariaProps.isDisabled,
  });
  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus: ariaProps.autoFocus,
  });

  const Icon = ariaProps.isSelected ? SelectedIcon : UnselectedIcon;

  return (
    <div
      className="hlx-radio-root"
      {...mergeProps({
        [DATA.HOVERED]: !ariaProps.isReadOnly && isHovered,
        [DATA.DISABLED]: ariaProps.isDisabled,
        [DATA.VALIDATION]: props.validation?.validity,
      })}
    >
      <label className="hlx-radio-label" {...hoverProps}>
        <span>
          <VisuallyHidden>
            <input {...mergeProps(inputProps, focusProps)} ref={ref} />
          </VisuallyHidden>
          <Icon
            className={clsx('hlx-radio-control', {
              'focus-ring': isFocusVisible,
            })}
            aria-hidden="true"
          />
          {props.children}
        </span>
      </label>
      {props.badge && (
        <div className="hlx-radio-badge">
          {React.cloneElement(props.badge, { id: badgeId })}
        </div>
      )}
      {props.description && (
        <div id={descriptionId} className="hlx-radio-description">
          {props.description}
        </div>
      )}
    </div>
  );
};

const SelectedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 19a9 9 0 100-18 9 9 0 000 18zm0-6a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const UnselectedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <circle cx="10" cy="10" r="8.5" stroke="currentColor" />
    </svg>
  );
};
