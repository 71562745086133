import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

interface PausedPaymentsReleasedModalProps {
  open: boolean;
  onClose: () => Promise<void>;
}

export const PausedPaymentsReleasedModal = ({
  open,
  onClose,
}: PausedPaymentsReleasedModalProps) => {
  return (
    <Modal
      title="Your payments are no longer on hold"
      isOpen={open}
      onDismiss={async () => {
        await onClose();
      }}
    >
      <ModalContent>
        <BodyText>
          <p>
            Your notes have maintained a consistent level of quality since we
            put your payments on hold. As a result, we’re resuming automatic
            payments for your sessions and will no longer review these notes in
            advance.
          </p>
        </BodyText>
        <BodyText>
          <p>
            We appreciate your diligence, and we’ll let you know right away if
            any other payment issues come up in the future.
          </p>
        </BodyText>
      </ModalContent>
      <ModalFooter>
        <Button
          onPress={async () => {
            await onClose();
          }}
        >
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};
