import moment from 'moment';

import { CredentialVerificationStatus } from '@headway/api/models/CredentialVerificationStatus';
import { LicenseAndVerification } from '@headway/api/models/LicenseAndVerification';
import { Malpractice } from '@headway/api/models/Malpractice';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { VerificationType } from '@headway/api/models/VerificationType';
import {
  licenseTypes,
  marketLicenseTypes,
} from '@headway/shared/utils/marketLicenseTypes';
import {
  getCurrentCoiPqvs,
  getInsuranceCoiS3Key,
} from '@headway/shared/utils/providerQuestionnaireVerification';

import { StepState } from 'views/IntakeQuestionnaireV2/IntakeNavigation';

export enum CoiErrorType {
  BadDocument = 'bad_document',
  BadNameInsured = 'bad_name',
  BadCoverageType = 'bad_coverage_type',
  BadExpirationDate = 'bad_expiration_date',
  BadExpiringSoon = 'bad_expiring_soon',
  BadEffectiveDate = 'bad_effective_date',
  BadPerClaimLimit = 'bad_per_claim_limit',
  BadAggregateLimit = 'bad_aggregate_limit',
  BadOccupation = 'bad_occupation',
  FailedOcr = 'failed_ocr',
}

export const NonBlockingCoiErrorTypes = [
  CoiErrorType.BadNameInsured,
  CoiErrorType.BadAggregateLimit,
  CoiErrorType.BadPerClaimLimit,
  CoiErrorType.BadOccupation,
];

export enum CurrentVerificationStatus {
  CoiValidationComplete = 'COI_VALIDATION_COMPLETE',
  CoiValidationInProgress = 'IN_PROGRESS',
}

export type StateAndLicenseSpecificCertificationType = {
  [keyof in UnitedStates]?: { [licenseType: string]: string };
};

export type CoiProviderQuestionnaireVerificationError = {
  errorType: CoiErrorType;
  errorMessage: string;
  valuesFound: string[];
};

export const getLicenseTypesForMarket = (unitedState: UnitedStates) => {
  const marketLicenseTypesArr = marketLicenseTypes[unitedState];
  let licenseTypeList;
  if (!marketLicenseTypesArr) {
    licenseTypeList = Object.keys(licenseTypes).map((key) => {
      return {
        enum: key,
        ...licenseTypes[key],
      };
    });
  } else {
    licenseTypeList = marketLicenseTypesArr.map((licenseType) => {
      return {
        enum: licenseType,
        ...licenseTypes[licenseType],
      };
    });
  }
  return licenseTypeList.sort(
    (a: any, b: any) => a.displayName - b.displayName
  );
};

export const getPendingCOIVerifications = (
  pqvs: ProviderQuestionnaireVerificationRead[]
) => {
  return pqvs.filter((pqv) => {
    return pqv.responseJson?.jobStatus === 'IN_PROGRESS';
  });
};

export const pqvHasCoiValidationErrorOfType = (
  pqv: ProviderQuestionnaireVerificationRead,
  errorType: CoiErrorType
) =>
  pqv.type === VerificationType.CERTIFICATE_OF_INSURANCE &&
  pqv.responseJson?.errorsFound?.some(
    (error: CoiProviderQuestionnaireVerificationError) =>
      error.errorType === errorType
  );

export const filterCoiPqvErrorsBasedOnCurrentPqvs = (
  coiPqv: ProviderQuestionnaireVerificationRead,
  currentCoiPqvs: ProviderQuestionnaireVerificationRead[]
): CoiProviderQuestionnaireVerificationError[] => {
  const anyInsuranceIsValidOrExpiringSoon = !!currentCoiPqvs.find(
    (pqv) =>
      pqv.responseJson?.isCoiValid ||
      pqvHasCoiValidationErrorOfType(pqv, CoiErrorType.BadExpiringSoon)
  );
  const anyInsuranceIsValidOrFuture = !!currentCoiPqvs.find(
    (pqv) =>
      pqv.responseJson?.isCoiValid ||
      pqvHasCoiValidationErrorOfType(pqv, CoiErrorType.BadEffectiveDate)
  );

  // If there is a COI expiring soon error, but another COI has been provided that
  // is valid or not effective yet, then the expiring soon error shouldn't be displayed.
  // Similarly, if there is a COI not effective yet error, but another COI has
  // been provided that is valid or expiring soon, then the not effective yet error shouldn't be displayed.
  // All other errors are always displayed.
  return (
    coiPqv?.responseJson?.errorsFound?.filter(
      (errorFound: CoiProviderQuestionnaireVerificationError) =>
        errorFound.errorType === CoiErrorType.BadExpiringSoon
          ? !anyInsuranceIsValidOrFuture
          : errorFound.errorType === CoiErrorType.BadEffectiveDate
          ? !anyInsuranceIsValidOrExpiringSoon
          : true
    ) || []
  );
};

export const getUnskippedInvalidCoiPqvs = (
  pqvs: ProviderQuestionnaireVerificationRead[],
  malpracticeInsurances: Malpractice[]
) => {
  const currentCoiPqvs = getCurrentCoiPqvs(pqvs, malpracticeInsurances);
  const invalidUnskippedPqvs: ProviderQuestionnaireVerificationRead[] = [];
  currentCoiPqvs.forEach((currentCoiPqv) => {
    if (currentCoiPqv.responseJson?.isCoiValid) {
      return;
    }
    const pqvSkipped = malpracticeInsurances?.filter(
      (insurance) =>
        getInsuranceCoiS3Key(insurance) === currentCoiPqv.requestJson.coiS3Key
    )[0].confirmSkipCoiVerification;
    if (pqvSkipped) {
      return;
    }
    const errors = filterCoiPqvErrorsBasedOnCurrentPqvs(
      currentCoiPqv,
      currentCoiPqvs
    );
    if (errors.length) {
      invalidUnskippedPqvs.push(currentCoiPqv);
    }
  });
  return invalidUnskippedPqvs;
};

export const getLicenseStepState = (
  licensesAndVerifications: LicenseAndVerification[]
) => {
  if (!licensesAndVerifications) {
    return StepState.INVALID;
  }

  let isPending = false;
  for (const { license, verification } of licensesAndVerifications) {
    if (license.isVerified) {
      continue;
    }

    if (
      verification &&
      [
        // If this is FOUND but the license isnt verified, this means that
        // there's something about the license_type that we don't accept on our end
        // See #license-verification-alerts for examples
        CredentialVerificationStatus.FOUND,
        CredentialVerificationStatus.FAILED,
        CredentialVerificationStatus.NOT_FOUND,
      ].includes(verification.status)
    ) {
      return StepState.INVALID;
    }
    if (
      verification &&
      [
        CredentialVerificationStatus.IN_PROGRESS,
        CredentialVerificationStatus.NEEDS_REVIEW,
      ].includes(verification.status)
    ) {
      isPending = true;
    }
  }

  if (isPending) {
    return StepState.PENDING;
  }
  return StepState.VALID;
};

export const getMalpracticeStepState = (
  pqvs: ProviderQuestionnaireVerificationRead[],
  rawData: ProviderQuestionnaireRawData
) => {
  const currentCoiPqvs = rawData.malpracticeInsurances
    ? getCurrentCoiPqvs(pqvs, rawData.malpracticeInsurances)
    : [];
  if (!currentCoiPqvs.length) {
    return StepState.INVALID;
  }

  const hasVerificationInProgress = currentCoiPqvs.some(
    (pqv) =>
      pqv.responseJson?.jobStatus ===
      CurrentVerificationStatus.CoiValidationInProgress
  );
  if (hasVerificationInProgress) {
    return StepState.PENDING;
  }

  const unskippedInvalidCoiPqvs = getUnskippedInvalidCoiPqvs(
    currentCoiPqvs,
    rawData.malpracticeInsurances || []
  );
  if (unskippedInvalidCoiPqvs.length) {
    // TODO(justin): potentially populate if relevant
    return StepState.INVALID;
  }

  return StepState.VALID;
};

export const hasLongPendingCoiVerifications = (
  pqvs?: ProviderQuestionnaireVerificationRead[]
) => {
  if (!pqvs) {
    return false;
  }
  const coiPqvs = pqvs.filter(
    (pqv) => pqv.type === VerificationType.CERTIFICATE_OF_INSURANCE
  );
  return !!getPendingPQVsAfterMinutes(coiPqvs, 4).length;
};

const getPendingPQVsAfterMinutes = (
  pqvs: ProviderQuestionnaireVerificationRead[],
  minutes: number
) => {
  const now = moment();
  return pqvs.filter((pqv) => {
    const pqvDate = moment(pqv.createdOn).add(minutes, 'minutes');

    return (
      now.isAfter(pqvDate) && pqv.responseJson?.jobStatus === 'IN_PROGRESS'
    );
  });
};
