import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';

export const WaivedSessionsRemainingGuidanceCard = ({
  patient,
}: {
  patient: UserRead;
}) => {
  const sessionsRemaining = patient?.waivedPaymentsRemaining ?? 0;
  if (!sessionsRemaining) return null;
  return (
    <GuidanceCard
      key="issue-waived-sessions-remaining"
      layout="horizontal"
      variant="warning"
    >
      <BodyText>
        Your client has {sessionsRemaining} waived session
        {sessionsRemaining > 1 && 's'} covered at no cost by Headway. See your
        email for more details.
      </BodyText>
    </GuidanceCard>
  );
};
