// @ts-nocheck
import {
  CalendarDate,
  CalendarDateTime,
  DateValue,
  getLocalTimeZone,
  parseAbsoluteToLocal,
  ZonedDateTime,
} from '@internationalized/date';
import { FormikProps, getIn, useField, useFormikContext } from 'formik';
import { ValidationState } from 'forms';
import keyBy from 'lodash/keyBy';
import React, {
  ComponentProps,
  JSXElementConstructor,
  useCallback,
  useRef,
} from 'react';
import { mergeProps } from 'react-aria';

import { Checkbox } from './Checkbox';
import { ComboBox } from './ComboBox';
import { CurrencyField } from './CurrencyField';
import { DateField } from './datetime/DateField';
import { DatePickerField } from './datetime/DatePickerField';
import { TimeField } from './datetime/TimeField';
import { NumberField } from './NumberField';
import { Select } from './Select';
import { Switch } from './Switch';

const isDateValue = (value: any): value is DateValue => {
  return (
    value instanceof CalendarDate ||
    value instanceof CalendarDateTime ||
    value instanceof ZonedDateTime
  );
};

type FormControlProps<T extends JSXElementConstructor<any>> = {
  component: T;
  name: string;
} & ComponentProps<T> & { mapItemToKey?: (...args: any[]) => any };

export function FormControl<T extends JSXElementConstructor<any>>({
  component: Component,
  name,
  ...props
}: FormControlProps<T>) {
  // Note: we use setFieldValue rather than setValue from useField because setValue is not a stable
  // reference, which can cause performance issues due to changing props on every rerender.
  // setFieldValue, however, is stable across rerenders.
  const { setFieldValue, setFieldTouched, ...form } = useFormikContext();
  const [{ onChange, onBlur: formikOnBlur, ...field }, meta] = useField(name);
  // Similarly, onBlur is not a stable reference. However, we can wrap the current value in a stable
  // function.
  const formikOnBlurRef = useRef(formikOnBlur);
  formikOnBlurRef.current = formikOnBlur;
  let onBlur = useCallback(
    (...args) => formikOnBlurRef.current(name)(...args),
    [name]
  );
  const onBlurNoValidate = useCallback(() => {
    setFieldTouched(name, true, false);
  }, [name, setFieldTouched]);

  const checkboxOnChange = useCallback(
    (newValue: boolean) => {
      if (newValue) {
        setFieldValue(name, props.value || true);
      } else {
        setFieldValue(name, false);
      }
    },
    [setFieldValue, name]
  );
  const switchOnChange = useCallback(
    (selected: boolean) => {
      setFieldValue(name, selected);
    },
    [setFieldValue, name]
  );
  const toISOStringOnChange = useCallback(
    (value: DateValue | null | undefined) => {
      const iso = value?.toDate(getLocalTimeZone()).toISOString() ?? null;

      setFieldValue(name, iso);
    },
    [setFieldValue, name]
  );

  const otherOnChange = useCallback(
    (e: any) => {
      if (e?.currentTarget) {
        setFieldValue(name, e.currentTarget.value);
      } else {
        setFieldValue(name, e);
      }
    },
    [setFieldValue, name]
  );

  let componentProps;
  if (Component === Checkbox) {
    componentProps = {
      onChange: checkboxOnChange,
      value: props.value,
      checked: field.value,
    };
  } else if (Component === Switch) {
    componentProps = {
      onChange: switchOnChange,
      selected: field.value,
    };
  } else if (Component === Select || Component === ComboBox) {
    if (props.items) {
      const itemsByKey = keyBy(props.items, (item) => {
        return props.mapItemToKey(item);
      });
      componentProps = {
        onSelectionChange: (selected) => {
          const values = Array.from(selected).map((key) => {
            return itemsByKey[key];
          });

          if (props.selectionMode === 'single') {
            setFieldValue(name, values[0]);
          } else {
            setFieldValue(name, values);
          }
        },
        selectedKeys:
          props.selectionMode === 'single'
            ? [props.mapItemToKey(field.value)]
            : field.value.map((item) => {
                return props.mapItemToKey(item);
              }),
      } as ComponentProps<typeof ComboBox>;
    } else if (props.children) {
      componentProps = {
        onSelectionChange: (selected) => {
          if (props.selectionMode === 'single') {
            const value = selected.values().next().value;
            setFieldValue(name, value);
          } else {
            setFieldValue(name, Array.from(selected));
          }
        },
        selectedKeys:
          props.selectionMode === 'single' ? [field.value] : field.value,
      } as ComponentProps<typeof ComboBox>;
    }
  } else if (Component === DateField || Component === DatePickerField) {
    let value = field.value;
    let handler = otherOnChange;

    // if the incoming value is a DateValue, leave it as-is because we assume the consumer is intentionally using the DateValue.
    // otherwise, if `value` is a string and truthy (i.e., not empty), we assume it's a datetime string and try to parse it to a DateValue.
    // otherwise, we don't do anything (and it will probably break)
    if (isDateValue(value)) {
      handler = otherOnChange;
    } else {
      handler = toISOStringOnChange;
      if (typeof value == 'string' && value.length > 0) {
        value = parseAbsoluteToLocal(value);
      }
    }

    componentProps = {
      value,
      onChange: handler,
    };
  } else if (Component === TimeField) {
    // This is the same as the else case below but I imagine we will eventually want some special
    // handling for TimeField and @internationalized/date::Time objects.
    componentProps = {
      onChange: otherOnChange,
      value: field.value,
    };
  } else if (Component === NumberField || Component === CurrencyField) {
    componentProps = {
      onChange: otherOnChange,
      value: field.value,
    };
    // NumberField and anything that extends it (e.g., CurrencyField) have a special onChange
    // behavior that only fires after the field loses focus. This is because sometimes the value
    // is not a valid number until the user is done typing. For example, if the user types a
    // decimal point, the value is not a valid number until they type a digit after the decimal.
    //
    // This seems to cause Formik to validate with stale values because the default onBlur handler
    // calls setFieldTouched with shouldValidate=true, which causes Formik to validate the field immediately.
    // However, the value is not updated in Formik's state so validation fails.
    onBlur = onBlurNoValidate;
  } else {
    componentProps = {
      onChange: otherOnChange,
      value: field.value,
    };
  }

  return (
    <Component
      {...mergeProps(
        {
          onBlur,
        },
        field,
        componentProps,
        props
      )}
      name={field.name}
      validation={
        (meta.touched || form.submitCount > 0) && meta.error
          ? {
              validity: 'invalid',
              message: meta.error,
            }
          : undefined
      }
    />
  );
}

/** Using a stable object for valid results helps performance on memoized components. */
const VALID = Object.freeze({ validity: 'valid' });

export const validity = (
  field: string,
  { touched, errors, submitCount }: FormikProps<any>
): ValidationState => {
  const isTouched = getIn(touched, field);
  const error = getIn(errors, field);

  const isInvalid = (isTouched || submitCount > 0) && error;
  return isInvalid
    ? {
        validity: 'invalid',
        message: error,
      }
    : VALID;
};
