import { AxiosError } from 'axios';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderWizardApi } from '@headway/api/resources/ProviderWizardApi';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { useNumberQueryParam } from '@headway/shared/utils/queryParams';
import { logException, logWarning } from '@headway/shared/utils/sentry';
import { ProgressBar } from '@headway/ui';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';
import { useProvider } from 'hooks/useProvider';
import { ONBOARDING_MODULE_STATUSES_CACHE_KEY } from 'utils/cacheKeys';

import { WizardContext } from './context';

export interface WizardProps {
  /**
   * UI to show at each step. The rendered components should be WizardSteps or wrappers around
   * WizardSteps.
   */
  steps: ReactNode[];
  /** The title to display on each step of the wizard. */
  title: string;
  /** UI to display after completing the final step in the wizard. */
  endContent: ReactNode;
  /**
   * The module associated with this wizard. This will be used for saving progress through the
   * wizard.
   */
  module: ProviderModule;
  getDefaultStep?: (provider: ProviderRead) => Promise<number>;
}

const DEFAULT_STEP_WIDTH = 800;
const DEFAULT_STEP_HEIGHT = 500;
const END_CONTENT_WIDTH = 528;
const END_CONTENT_HEIGHT = 405;

/**
 * Renders a step-by-step guided workflow. The wizard persists its current step in the URL via a
 * query parameter so that refreshing the page reloads the same step.
 */
export const Wizard = ({
  steps,
  title,
  endContent,
  module,
  getDefaultStep,
}: WizardProps) => {
  const queryClient = useQueryClient();
  const provider = useProvider();
  const [stepParam, setStepParam] = useNumberQueryParam('step');
  const [widthOverride, setWidthOverride] = useState<number | undefined>();
  const [heightOverride, setHeightOverride] = useState<number | undefined>();
  const statusesQuery = useOnboardingModuleStatuses({
    refetchOnMount: 'always',
  });

  const savedWizardData = (statusesQuery.data || []).find(
    (status) => status.module === module
  )?.wizard;
  const lastSavedStep = savedWizardData?.currentStep;
  const totalSteps = steps.length;
  if (totalSteps === 0) {
    throw new Error('Expected at least one step to be provided');
  }

  const saveProgressMutation = useMutation<void, AxiosError, number>(
    async (stepToSave) => {
      const completionData =
        stepToSave === totalSteps
          ? { completedOn: new Date().toISOString() }
          : {};
      // Only update the step if we think it's greater than the current saved step.
      if (savedWizardData && stepToSave > (lastSavedStep || 0)) {
        await ProviderWizardApi.updateProviderWizard(savedWizardData.id, {
          currentStep: stepToSave,
          ...completionData,
        });
      } else if (!savedWizardData) {
        await ProviderWizardApi.createProviderWizard({
          providerId: provider.id,
          module,
          currentStep: stepToSave,
          ...completionData,
        });
      }
    },
    {
      onError: (err: AxiosError) => {
        if (
          err.isAxiosError &&
          (err.response?.status === 409 || err.response?.status === 400)
        ) {
          // These statuses can happen when there are race conditions (e.g. multiple browser tabs
          // open) that cause the client's saved progress to be out-of-date with the server. It is
          // not necessary to notify failure since we can recover by just refetching.
          logWarning(
            'Did not save wizard progress because current progress is out-of-date.',
            { extra: { providerId: provider.id, module } }
          );
        } else {
          notifyError('Failed to save wizard progress.');
          logException(err);
        }
      },
      onSettled: () => {
        // Refetch onboarding statuses since we expect progress data to have changed.
        queryClient.invalidateQueries([
          ONBOARDING_MODULE_STATUSES_CACHE_KEY,
          provider.id,
        ]);
      },
    }
  );

  // Mutations are not referentially stable, but their mutate functions are.
  const mutate = saveProgressMutation.mutate;
  const didDetermineInitialStep = useRef<boolean>(false);
  // When there is not already a query param in the URL, determine the initial step as follows:
  // - First, look for saved wizard progress. If there is any, use that.
  // - If there is no saved progress, call getDefaultStep and use the result of that.
  // - If there is no saved progress and no getDefaultStep, go to step 0.
  useEffect(() => {
    const determineInitialStep = async () => {
      if (lastSavedStep !== undefined) {
        setStepParam(lastSavedStep, { replace: true });
      } else {
        const initialStep = getDefaultStep ? await getDefaultStep(provider) : 0;
        setStepParam(initialStep, { replace: true });
        mutate(initialStep);
      }
    };

    if (
      stepParam === undefined &&
      statusesQuery.isFetchedAfterMount &&
      !didDetermineInitialStep.current
    ) {
      // Only run this function once.
      didDetermineInitialStep.current = true;
      determineInitialStep();
    }
  }, [
    statusesQuery.isFetchedAfterMount,
    lastSavedStep,
    getDefaultStep,
    mutate,
    provider,
    setStepParam,
    stepParam,
  ]);

  const stepsWithEndContent = [...steps, endContent];
  const currentStep =
    stepParam !== undefined && stepsWithEndContent[stepParam] ? stepParam : 0;

  const handleSetStep = (step: number) => {
    if (step > currentStep) {
      saveProgressMutation.mutate(step);
    }
    setStepParam(step);
  };

  const wizardContext = {
    currentStep,
    totalSteps,
    title,
    module,
    setStep: handleSetStep,
    setWidthOverride,
    setHeightOverride,
  };

  let width, height;
  if (currentStep === steps.length) {
    width = END_CONTENT_WIDTH;
    height = END_CONTENT_HEIGHT;
  } else {
    width = widthOverride || DEFAULT_STEP_WIDTH;
    height = heightOverride || DEFAULT_STEP_HEIGHT;
  }

  if (stepParam === undefined) {
    return null;
  }

  return (
    <WizardContext.Provider value={wizardContext}>
      <div
        css={{
          width,
          height,
          transition: 'width 0.25s ease-in-out, height 0.25s ease-in-out',
          backgroundColor: theme.color.white,
          display: 'flex',
          flexDirection: 'column',
          [`@media (max-width: ${width}px)`]: {
            width: '100%',
          },
          // The extra 100px accounts for the 50px header.
          [`@media (max-height: ${height + 100}px)`]: {
            height: 'unset',
            paddingTop: 50,
            alignSelf: 'stretch',
          },
          [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            width: '100%',
            height: 'unset',
            paddingTop: 0,
          },
        }}
      >
        <ProgressBar
          currentStep={currentStep}
          totalSteps={totalSteps}
          backgroundColor="primary"
        ></ProgressBar>
        {stepsWithEndContent[currentStep]}
      </div>
    </WizardContext.Provider>
  );
};
