import * as React from 'react';

export const HighlightPhone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M35.247 46.872H8.491c-.82 0-1.491-.671-1.491-1.491V17.51c0-.82 1.21-1.772 2.165-.817l27.35 27.35c.897.897.323 2.83-1.268 2.83v-.002z"
      fill="#FFE6F6"
    />
    <path
      d="M33.252 3H14.41a2.884 2.884 0 00-2.88 2.88v33.576a2.884 2.884 0 002.88 2.88h18.84a2.884 2.884 0 002.881-2.88V5.88A2.884 2.884 0 0033.252 3zm1.26 36.456c0 .696-.567 1.26-1.26 1.26H14.41c-.696 0-1.26-.567-1.26-1.26V5.88c0-.695.567-1.26 1.26-1.26h18.84c.697 0 1.26.567 1.26 1.26v33.576zm-3.778-1.83a.81.81 0 01-.81.811H17.739a.81.81 0 010-1.62h12.185a.81.81 0 01.81.81z"
      fill="#128656"
    />
  </svg>
);
