import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import {
  Component,
  ComponentTypes,
  Section,
} from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/schemaRenderer/versions/2/types';
import { isElementASection } from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/utils';

import schema_id_1 from './Schemas/id_1_template.json';
import schema_id_2_mm from './Schemas/id_2_mm_template.json';
import schema_id_2 from './Schemas/id_2_template.json';
import { Template, TreatmentPlanMetadata } from './types';

export interface SchemaWithVersion {
  version: number;
  template: Template;
}

export const templates: SchemaWithVersion[] = [
  schema_id_1 as SchemaWithVersion,
  schema_id_2 as SchemaWithVersion,
  schema_id_2_mm as SchemaWithVersion,
];

export const componentsWithMultipleValues = [
  'checklist',
  'dropdownDiagnosis',
  'dropdown',
] as ComponentTypes[];

export const createJsonFromComponent = (
  child: Section<TreatmentPlanMetadata> | Component<TreatmentPlanMetadata>,
  initialValue?: any
): { [id: string]: any } => {
  if (isElementASection(child)) {
    const childJson = child.components.reduce(
      (o, val) => ({ ...o, ...createJsonFromComponent(val, initialValue) }),
      {}
    );
    if (child.id) {
      return {
        [child.id]: child.allowMultiple ? [childJson] : childJson,
      };
    }
    return {};
  }

  if (child.id) {
    return {
      [child.id]:
        initialValue !== undefined
          ? initialValue
          : componentsWithMultipleValues.includes(child.type as ComponentTypes)
          ? []
          : '',
    };
  }
  return {};
};

export const createJsonFromTemplate = (
  template: Template,
  initialValue?: any
) => {
  let json: { [key: string]: any } = {};

  template.steps.forEach((step) => {
    step.components.forEach((child) => {
      json = { ...json, ...createJsonFromComponent(child, initialValue) };
    });
  });

  return json;
};

export const useTemplate = (patientId?: number) => {
  const isMMTreatmentPlanRequirement = useFlag(
    'mmTreatmentPlanRequirement',
    false
  );

  const isMedicareOrMedicaid = useMedicareOrMedicaid(patientId);

  if (isMMTreatmentPlanRequirement && isMedicareOrMedicaid) {
    return templates[templates.length - 1];
  }

  return templates[1];
};
