import React from 'react';

import { formatError } from './utils/errorFormatter';

type HTMLFormFieldElement =
  | HTMLButtonElement
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLFieldSetElement
  | HTMLOutputElement
  | HTMLTextAreaElement;

export const useAssertFormParentEffect = (
  ref: React.RefObject<HTMLFormFieldElement>,
  component: string,
  name?: string
) => {
  React.useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const el = ref.current;

      if (!el) {
        return;
      }

      if (!el.form && !el.closest('form')) {
        console.warn(
          formatError(
            `${component} '${
              name ?? el.name
            }' is not associated with a <form>. ${component} should always have a corresponding <form>.`
          )
        );
      }
    }
  }, []);
};
