import { Button } from '@mui/material';

import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';

export const CoiBadDocument = () => {
  return (
    <div>
      <GuidanceCard variant="error" layout="vertical">
        <>
          <div>
            <b>
              Looks like the file you uploaded isn't a certificate of insurance.
            </b>{' '}
            Make sure your file reads "Certificate of Insurance" or "Memorandum
            of Insurance".
          </div>
          <LinkButton
            variant="link"
            href={
              'https://headway-redacted-malpractice.s3.amazonaws.com/allied-world-insurance.jpg'
            }
            target="_blank"
            rel="noreferrer"
          >
            See COI Example
          </LinkButton>
        </>
      </GuidanceCard>
    </div>
  );
};
