import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface usePatientAssessmentUnreadCountQueryKeyArgs {
  providerPatientId: number | undefined;
}

export const getusePatientAssessmentUnreadCountQueryKey = ({
  providerPatientId,
}: usePatientAssessmentUnreadCountQueryKeyArgs) =>
  ['patient-assessment-unread-count', providerPatientId] as const;

const {
  useSingleQuery: usePatientAssessmentUnreadCount,
  useListQuery: usePatientAssessmentUnreadCountList,
  useCachedQuery: usePatientAssessmentUnreadCountCache,
} = createBasicApiHooks(
  getusePatientAssessmentUnreadCountQueryKey,
  ({ providerPatientId }) =>
    PatientAssessmentApi.getPatientAssessmentUnreadCount(providerPatientId!),
  ({ providerPatientId }) => providerPatientId != null,
  () => 'Failed to fetch patient assessment unread count'
);

export {
  usePatientAssessmentUnreadCount,
  usePatientAssessmentUnreadCountList,
  usePatientAssessmentUnreadCountCache,
};
