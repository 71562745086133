import { css } from '@emotion/react';
import React from 'react';

import { theme } from '@headway/helix/theme';

/** A vertical layout appropriate for Helix-based pages. */
export const PanelLayout = ({ children }: { children: React.ReactNode }) => (
  <div css={panelLayoutOuterCss}>
    <div css={panelLayoutInnerCss}>{children}</div>
  </div>
);

const panelLayoutOuterCss = css({
  ...theme.reset,
  '--hw-header-height': '50px',
  minHeight: 'calc(100vh - var(--hw-header-height))',
  marginTop: 'var(--hw-header-height)',
  padding: `0 ${theme.spacing.x4}`,
  backgroundColor: theme.color.system.white,
});

const panelLayoutInnerCss = css({
  width: '100%',
  margin: '0 auto',
  ...theme.stack.vertical,
  [theme.media.desktop]: { maxWidth: 927 },
});
