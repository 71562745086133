import React from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';

type ButtonProps = React.ComponentProps<typeof Button>;
type PlaidLinkButtonProps = Omit<ButtonProps, 'onClick'> & {
  linkOptions: PlaidLinkOptions;
};

const PlaidLinkButton: React.FC<
  React.PropsWithChildren<PlaidLinkButtonProps>
> = (props) => {
  const { children, linkOptions, ...rest } = props;
  const { open } = usePlaidLink(linkOptions);

  return (
    <Button onPress={() => open()} {...rest}>
      {children}
    </Button>
  );
};

export const PlaidLinkButtonWithError: React.FC<
  React.PropsWithChildren<PlaidLinkButtonProps>
> = (props) => {
  const { children, linkOptions, ...rest } = props;

  if (!linkOptions.token) {
    return (
      <>
        <Button {...rest}>{children}</Button>
        <BodyText css={{ color: theme.color.primary.red }}>
          We can't establish a connection with the banks we work with. Please
          try again later.
        </BodyText>
      </>
    );
  }

  return <PlaidLinkButton {...props} />;
};
