import * as React from 'react';

export const InsuranceStatusOutlined = (
  props: React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.063 6.667h13.874V4.729H3.063v1.938zm-1.397 10V3.332h16.668V9.71H3.063v5.562h4.374v1.395h-5.77zm10.793 1.666l-3.543-3.541.98-.98 2.563 2.542 4.895-4.916.98 1.02-5.875 5.876zM3.063 4.73v10.542-3.438 2.583V4.73z"
      fill="currentColor"
    />
  </svg>
);
