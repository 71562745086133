import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressSourceType } from '@headway/api/models/PatientAddressSourceType';
import { UserRead } from '@headway/api/models/UserRead';

export const sortPatientAddresses = (
  addresses: PatientAddressRead[],
  user: UserRead
) => {
  const activeAddress = addresses.find((address) =>
    isAddressUsedForClaims(address, user)
  );
  if (activeAddress) {
    return [
      activeAddress,
      ...addresses.filter((address) => !isAddressUsedForClaims(address, user)),
    ];
  }
  return addresses;
};

export const isAddressUsedForClaims = (
  address: PatientAddressRead,
  user: UserRead
) => {
  return (
    address.source === PatientAddressSourceType.ELIGIBILITY_LOOKUP ||
    address.id === user.activePatientAddressId
  );
};
