import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import moment from 'moment';
import React, { useContext, useState } from 'react';

import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { Button } from '@headway/helix/Button';
import { IconButton } from '@headway/helix/IconButton';
import { IconDelete } from '@headway/helix/icons/Delete';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { HeadwayLogo } from '@headway/ui/icons/HeadwayLogo';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';

import { InfoBox } from '../Components/InfoBox';
import { TreatmentPlanPreview } from '../Components/TreatmentPlanPreview';
import { DeleteTreatmentPlanModal } from '../Modals/DeleteTreatmentPlanModal';
import { trackCompleteDraftButtonClickedEvent } from '../TreatmentPlanAnalyticsUtils';
import { TreatmentPlanContext } from '../TreatmentPlanContext';
import { TreatmentPlanStatusBadges } from '../TreatmentPlanStatusBadges';
import {
  getTreatmentPlanName,
  isSignedTreatmentPlan,
} from '../TreatmentPlanUtils';
import { Page } from './Page';

export interface PreviewPageProps {
  closeModal: () => void;
}

export const PreviewPage = ({ closeModal }: PreviewPageProps) => {
  const provider = useProvider();
  const { user } = useAuthStore();

  const { editTreatmentPlan, treatmentPlan, providerPatient, treatmentPlans } =
    useContext(TreatmentPlanContext);
  const { deleteTreatmentPlan } = useProviderTreatmentPlan(
    providerPatient!.userId
  );

  const [isDeleteTreatmentPlanModalOpen, setIsDeleteTreatmentPlanModalOpen] =
    useState<boolean>(false);
  const { data: client } = useUser({ userId: providerPatient!.userId });

  return (
    <Page buttons={[]}>
      {treatmentPlan && (
        <>
          <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: `${theme.spacing.x5} 0`,
              }}
            >
              <div
                css={{
                  ...theme.stack.vertical,
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SectionHeader>
                    {getTreatmentPlanName(treatmentPlan?.id!, treatmentPlans)}
                  </SectionHeader>
                  <div css={statusBadgeCss}>
                    <TreatmentPlanStatusBadges
                      treatmentPlan={treatmentPlan!}
                      isLatestAttestedPlan={
                        treatmentPlan.id ===
                        treatmentPlans.find((plan) =>
                          isSignedTreatmentPlan(plan)
                        )?.id
                      }
                    />
                  </div>
                </div>
                <SubBodyText>
                  {treatmentPlan.attestedOn
                    ? 'Signed on ' +
                      moment(treatmentPlan?.attestedOn).format('MM/DD/YY')
                    : 'Updated on ' +
                      moment(treatmentPlan?.updatedOn).format('MM/DD/YY')}
                </SubBodyText>
              </div>
              {!treatmentPlan.attestedOn && (
                <div css={editActionCss}>
                  <div css={{ marginRight: theme.spacing.x2 }}>
                    <IconButton
                      variant="default"
                      size="medium"
                      onPress={() => {
                        setIsDeleteTreatmentPlanModalOpen(true);
                      }}
                    >
                      <IconDelete />
                    </IconButton>
                  </div>
                  <Button
                    variant="secondary"
                    size="medium"
                    onPress={() => {
                      trackCompleteDraftButtonClickedEvent(providerPatient!);
                      editTreatmentPlan(treatmentPlan!);
                    }}
                    disabled={
                      treatmentPlan.planType !== TreatmentPlanType.UPLOAD &&
                      isGroupAdminImpersonatingProvider(provider, user)
                    }
                  >
                    Complete draft
                  </Button>
                </div>
              )}
            </div>
            <HeadwayLogo css={{ width: '174px' }} />
            {client && <InfoBox clientId={client.id} />}
          </div>
          <TreatmentPlanPreview closeModal={closeModal} />
          <DeleteTreatmentPlanModal
            open={isDeleteTreatmentPlanModalOpen}
            onClose={() => {
              setIsDeleteTreatmentPlanModalOpen(false);
            }}
            onDelete={async () => {
              await deleteTreatmentPlan(treatmentPlan?.id!);
              closeModal();
            }}
            treatmentPlanId={treatmentPlan?.id!}
          />
        </>
      )}
    </Page>
  );
};

const editActionCss = css({
  display: 'flex',
  marginLeft: 'auto',
  justifyContent: 'flex-end',
  flexDirection: 'row',
});

const statusBadgeCss = css({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  marginLeft: theme.spacing.x2,
});
