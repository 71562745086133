import { UserRead } from '@headway/api/models/UserRead';
import { TELEHEALTH_LOCATIONS_SIGMUND } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { formatPatientName } from '@headway/shared/utils/patient';

import {
  AddPatientModalPage,
  AddPatientModalStep,
  mapPageToStep,
} from '../AddPatient/AddPatientModalContext';

export const MIN_VALUE = 0;

export const formatFirstName = (client: UserRead) => {
  return (
    formatPatientName(client, {
      firstNameOnly: true,
    }) ||
    (client?.firstName &&
      client.firstName
        .charAt(0)
        .toUpperCase()
        .concat(client.firstName.slice(1))) ||
    ''
  );
};

export const getModalProgressProps = (
  currentStep: AddPatientModalPage,
  steps: AddPatientModalStep[]
) => {
  const step = mapPageToStep[currentStep];
  let currentStepNumber = steps.indexOf(step);

  if (currentStepNumber === -1) {
    return;
  }

  currentStepNumber += 1; // Offset by 1 to start at 1

  return {
    value: currentStepNumber,
    min: MIN_VALUE,
    max: steps.length,
    valueLabel: `${currentStepNumber}${' '}of ${steps.length}`,
  };
};

export const useAddPatientModalSteps = () => {
  const telehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );

  const steps = [
    AddPatientModalStep.BASIC_INFO,
    AddPatientModalStep.INSURANCE,
    AddPatientModalStep.INVITE,
    AddPatientModalStep.ASSESSMENTS,
  ];
  if (telehealthLocationsEnabled) {
    steps.splice(2, 0, AddPatientModalStep.TELEHEALTH);
  }

  return steps;
};
