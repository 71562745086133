import { parseAbsoluteToLocal, TimeFields } from '@internationalized/date';
import moment, { Moment } from 'moment';

export const getDaysUntilDate = (upcomingDate: Moment) => {
  return upcomingDate.startOf('day').diff(moment().startOf('day'), 'days');
};

export const getDaysLeftCopy = (daysLeft: number) => {
  if (daysLeft > 1) {
    return `${daysLeft} days`;
  }

  return '1 day';
};

export const convertDateToTime = (date: Date) => {
  return moment(date).format('HH:mm');
};

export const convertDateToTimeFields = (date: Date): TimeFields => {
  return {
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
    millisecond: date.getMilliseconds(),
  };
};

export const convertTimeFieldsToDateString = (
  scheduledDate: Date,
  timeFields: TimeFields
) => {
  let timeToday = parseAbsoluteToLocal(scheduledDate.toISOString());

  if (timeFields.hour !== undefined) {
    timeToday = timeToday.set({
      hour: timeFields.hour,
    });
  }

  if (timeFields.minute !== undefined) {
    timeToday = timeToday.set({
      minute: timeFields.minute,
    });
  }

  if (timeFields.second !== undefined) {
    timeToday = timeToday.set({
      second: timeFields.second,
    });
  }

  if (timeFields.millisecond !== undefined) {
    timeToday = timeToday.set({
      millisecond: timeFields.millisecond,
    });
  }

  return timeToday.toDate().toISOString();
};

export const convertTimeToDateString = (scheduledDate: Date, time: string) => {
  const [hour, minute] = time.split(':');
  const today = moment(scheduledDate);
  today.set('hour', parseInt(hour));
  today.set('minute', parseInt(minute));
  return today.toISOString();
};
