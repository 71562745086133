import { difference, isEmpty } from 'lodash';

import { ProviderTreatmentPlanAttachmentRead } from '@headway/api/models/ProviderTreatmentPlanAttachmentRead';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';

import {
  useCreateTreatmentPlanMutation,
  useDeleteTreatmentPlanMutation,
  useUpdateTreatmentPlanAttachmentsMutation,
  useUpdateTreatmentPlanMutation,
} from 'mutations/treatmentPlan';
import {
  createJsonFromTemplate,
  useTemplate,
} from 'views/Clients/TreatmentPlan/Template';
import { Attachment } from 'views/Patients/AttachmentsList';

import { useProvider } from './useProvider';
import { useProviderPatient } from './useProviderPatient';

export const useProviderTreatmentPlan = (patientId?: number) => {
  const provider = useProvider();

  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId,
  });
  const providerPatientId = providerPatient?.id;
  const template = useTemplate(patientId);

  const deleteTreatmentPlanMutation = useDeleteTreatmentPlanMutation();
  const createTreatmentPlanMutation = useCreateTreatmentPlanMutation();
  const updateTreatmentPlanMutation = useUpdateTreatmentPlanMutation();

  const updateTreatmentPlanAttachmentsMutation =
    useUpdateTreatmentPlanAttachmentsMutation();

  const getInitialPlanJson = (values: any, planType: TreatmentPlanType) => {
    let planJson = undefined;
    if (planType === TreatmentPlanType.TEMPLATE) {
      planJson = {
        template: !isEmpty(values)
          ? values
          : createJsonFromTemplate(template.template),
      };
    } else if (planType === TreatmentPlanType.TEXT) {
      planJson = {
        text: values.treatmentPlanText,
      };
    }
    return planJson;
  };

  const saveTreatmentPlan = async ({
    values = {},
    planType,
    treatmentPlanId,
    shouldAttestPlan = false,
    initialAttachments = [],
  }: {
    values?: any;
    planType: TreatmentPlanType;
    treatmentPlanId?: number;
    shouldAttestPlan?: boolean;
    initialAttachments?: ProviderTreatmentPlanAttachmentRead[];
  }) => {
    if (providerPatientId) {
      if (treatmentPlanId) {
        let planJson = getInitialPlanJson(values, planType);
        let updatedTreatmentPlan: ProviderTreatmentPlanRead;
        const attachmentsAdded = difference(
          values.treatmentPlanAttachments ?? [],
          initialAttachments
        );
        const attachmentsDeleted = difference(
          initialAttachments,
          values.treatmentPlanAttachments ?? []
        );

        if (attachmentsAdded.length > 0 || attachmentsDeleted.length > 0) {
          await updateTreatmentPlanAttachmentsMutation.mutateAsync({
            treatmentPlanId,
            attachmentsToCreate: attachmentsAdded.map((attachment) => ({
              providerTreatmentPlanId: treatmentPlanId,
              name: attachment.name,
              link: attachment.link,
            })),
            attachmentsToDelete: attachmentsDeleted.map((attachment) =>
              Number(attachment.id)
            ),
          });
        }

        const dateTimeISOString = new Date().toISOString();
        updatedTreatmentPlan = await updateTreatmentPlanMutation.mutateAsync({
          treatmentPlanId,
          providerPatientId,
          update: {
            planJson: planJson,
            updatedOn: dateTimeISOString,
            attestedOn: shouldAttestPlan ? dateTimeISOString : undefined,
          },
        });

        return updatedTreatmentPlan;
      } else {
        return createTreatmentPlan({ values, planType, shouldAttestPlan });
      }
    }
  };

  const createTreatmentPlan = async ({
    values = {},
    planType,
    templateVersion,
    shouldAttestPlan = false,
  }: {
    values?: any;
    planType: TreatmentPlanType;
    templateVersion?: number;
    shouldAttestPlan?: boolean;
  }) => {
    if (providerPatientId) {
      let planJson = getInitialPlanJson(values, planType);

      const createdTreatmentPlan =
        await createTreatmentPlanMutation.mutateAsync({
          treatmentPlan: {
            providerPatientId: providerPatientId,
            planType: planType,
            templateVersion: templateVersion,
            planJson: planJson,
            attestedOn: shouldAttestPlan ? new Date().toISOString() : undefined,
          },
        });

      if (
        values.treatmentPlanAttachments &&
        values.treatmentPlanAttachments.length > 0
      ) {
        await updateTreatmentPlanAttachmentsMutation.mutateAsync({
          treatmentPlanId: createdTreatmentPlan.id,
          attachmentsToCreate: values.treatmentPlanAttachments.map(
            (attachment: Attachment<number | string>) => ({
              providerTreatmentPlanId: createdTreatmentPlan.id,
              name: attachment.name,
              link: attachment.link,
            })
          ),
        });
      }
      return createdTreatmentPlan;
    }
  };

  const deleteTreatmentPlan = async (treatmentPlanId: number) => {
    if (providerPatientId) {
      await deleteTreatmentPlanMutation.mutateAsync({
        treatmentPlanId,
        providerPatientId,
      });
    }
  };

  return { createTreatmentPlan, deleteTreatmentPlan, saveTreatmentPlan };
};
