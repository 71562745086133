import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React from 'react';

import { ComboBox, Item, Section } from '@headway/helix/ComboBox';
import { validity } from '@headway/helix/FormControl';

import {
  COMMON_DIAGNOSIS_CODES,
  DXCodeInfo,
  LESS_COMMON_DIAGNOSIS_CODES,
  PHYSIOLOGICAL_CODES,
} from '../shared/constants/diagnosisCodes';

export interface DiagnosisCodeOption extends DXCodeInfo {
  description: string;
}

export const diagnosisOptions = (
  includePhysiologicalCodes?: boolean
): DiagnosisCodeOption[] => {
  const commonCodes = [
    ...COMMON_DIAGNOSIS_CODES.map((code) => ({
      ...code,
      description: 'Common codes',
    })),
  ];
  const lessCommonCodes = [
    ...LESS_COMMON_DIAGNOSIS_CODES.map((code) => ({
      ...code,
      description: 'Less common codes',
    })),
  ];
  const physiologicalCodes = [
    ...PHYSIOLOGICAL_CODES.map((code) => ({
      ...code,
      description: 'Physiological codes',
    })),
  ];
  if (includePhysiologicalCodes) {
    return [...commonCodes, ...lessCommonCodes, ...physiologicalCodes];
  }
  return [...commonCodes, ...lessCommonCodes];
};

const shouldIncludePhysiologicalCodes = (
  chosenCodes: DXCodeInfo[]
): boolean => {
  // https://docs.google.com/document/d/13-u6iW0sVv7c9F_v09LOqO60SQhJnXGK-_CiayPISy4/edit
  const result = chosenCodes.some((code) => {
    const value = code.value.toUpperCase();
    return (
      value.startsWith('F01') ||
      value.startsWith('F02') ||
      value === 'F04' ||
      value === 'F05' ||
      value.startsWith('F06') ||
      value.startsWith('F07') ||
      value === 'F09' ||
      value === 'F48.2' ||
      value === 'F54'
    );
  });
  return result;
};

const physiologicalCodeValues = PHYSIOLOGICAL_CODES.map((c) => c.value);

const orderedDiagnosisCodes = (codes: DXCodeInfo[]): DXCodeInfo[] => {
  // physiological codes go first
  // everything else should stay in order it was entered.
  return codes.sort((a, b) => {
    if (
      physiologicalCodeValues.includes(a.value) &&
      physiologicalCodeValues.includes(b.value)
    ) {
      return 0;
    }
    if (physiologicalCodeValues.includes(a.value)) {
      return -1;
    }
    if (physiologicalCodeValues.includes(b.value)) {
      return 1;
    }
    return 0;
  });
};

type ComboBoxProps = React.ComponentProps<typeof ComboBox>;

interface DiagnosisCodeComboBoxProps
  extends Omit<ComboBoxProps, 'children' | 'items'> {
  disabled?: boolean;
  helpText?: React.ReactNode;
  instructionalText?: React.ReactNode;
  optionalityText?: React.ReactNode;
  // field name for Formik
  name: string;
  searchable?: boolean;
}

const allCodesByKey = Object.entries({
  'Common codes': COMMON_DIAGNOSIS_CODES,
  'Less common codes': LESS_COMMON_DIAGNOSIS_CODES,
  'Physiological codes': PHYSIOLOGICAL_CODES,
});

const commonAndLessCommonCodesByKey = Object.entries({
  'Common codes': COMMON_DIAGNOSIS_CODES,
  'Less common codes': LESS_COMMON_DIAGNOSIS_CODES,
});

const allCodes = [
  ...COMMON_DIAGNOSIS_CODES,
  ...LESS_COMMON_DIAGNOSIS_CODES,
  ...PHYSIOLOGICAL_CODES,
];

const codesByValue = allCodes.reduce(
  (acc, code) => {
    acc[code.value] = code;
    return acc;
  },
  {} as Record<string, DXCodeInfo>
);

export const DX_COMBOBOX_DEFAULT_HELP_TEXT = 'Select multiple if applicable.';
export const DX_COMBOBOX_DEFAULT_OPTIONALITY_TEXT =
  'Optional for private pay clients. Please note, clients cannot file for OON reimbursement without CPT/diagnosis codes.';

const DiagnosisCodeComboBox = (props: DiagnosisCodeComboBoxProps) => {
  const formik = useFormikContext<{
    [name: string]: (undefined | DXCodeInfo) | DXCodeInfo[];
  }>();
  const {
    disabled = false,
    helpText,
    instructionalText,
    optionalityText,
    name,
    placeholder,
    selectionMode,
    ...rest
  } = props;

  const singleOrArrayValue = get(formik.values, name);
  const value = Array.isArray(singleOrArrayValue)
    ? singleOrArrayValue
    : singleOrArrayValue
    ? [singleOrArrayValue]
    : [];
  const includePhysiologicalCodes = shouldIncludePhysiologicalCodes(
    value || []
  );

  const options = React.useMemo(() => {
    const items = includePhysiologicalCodes
      ? allCodesByKey
      : commonAndLessCommonCodesByKey;

    return items.map(([description, codes]) => {
      return { description, items: codes };
    });
  }, [includePhysiologicalCodes]);

  return (
    <div
      css={{
        '& .hlx-chip-root .code-combobox-desc': {
          display: 'none',
        },
      }}
    >
      <ComboBox
        disabled={disabled}
        helpText={helpText}
        items={options}
        instructionalText={instructionalText}
        label="Diagnosis codes"
        name={name}
        onSelectionChange={(keys) => {
          const newValues = Array.from(keys, (key) => codesByValue[key]);
          formik.setFieldValue(
            name,
            selectionMode === 'single'
              ? newValues[0]
              : orderedDiagnosisCodes(newValues)
          );
        }}
        selectedKeys={value?.map((code) => code.value) ?? []}
        validation={validity(name, formik)}
        placeholder={placeholder}
        selectionMode={selectionMode}
        {...rest}
      >
        {(section) => {
          return (
            <Section
              key={section.description}
              title={section.description}
              items={section.items}
            >
              {(code) => {
                return (
                  <Item
                    key={code.value}
                    textValue={`${code.value} — ${code.display}`}
                  >
                    <span className="code-combobox-value">{code.value}</span>
                    <span className="code-combobox-desc">
                      {' '}
                      — {code.display}
                    </span>
                  </Item>
                );
              }}
            </Section>
          );
        }}
      </ComboBox>
    </div>
  );
};

export { DiagnosisCodeComboBox };
