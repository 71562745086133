import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';

import { ContactInfo } from '@headway/api/models/ContactInfo';
import { DataWarningType } from '@headway/api/models/DataWarningType';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { theme } from '@headway/ui/theme';

import { formatDisplayName } from '../BulkPatientPorting';

export const NotifyStep = ({
  clients,
  uploadedClientCount,
  selectedProviderPatientIds,
  isSelected,
  onSelectClick,
  onSelectAllClick,
  onPreviewEmail,
  showUserDisplayName,
}: {
  clients: ContactInfo[];
  uploadedClientCount: number;
  selectedProviderPatientIds: readonly number[];
  isSelected: (providerPatientId: number | undefined) => boolean;
  onSelectClick: (
    event: React.MouseEvent<unknown>,
    providerPatientId: number | undefined
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPreviewEmail: () => void;
  showUserDisplayName: boolean;
}) => {
  return (
    <div css={{ marginTop: theme.space.xs }}>
      <p>
        Let us know if you’d like to invite your clients to Headway via email
        now. We’ll reach out to them to get any necessary insurance or billing
        info and to acknowledge our standard forms.
      </p>
      <div>
        <Button
          variant="link"
          onPress={() => {
            onPreviewEmail();
          }}
          disabled={clients.length === 0}
        >
          Preview email
        </Button>
      </div>
      {uploadedClientCount > clients.length && (
        <GuidanceCard variant="warning">
          <BodyText>
            Some client details could not be saved. You may want to review or
            reupload.
          </BodyText>
        </GuidanceCard>
      )}
      <TableContainer
        component={'div'}
        css={{ height: '315px', overflowY: 'auto', marginTop: theme.space.xl2 }}
      >
        <Table aria-label="new client list" stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedProviderPatientIds.length > 0 &&
                    selectedProviderPatientIds.length < clients.length
                  }
                  checked={
                    clients.length > 0 &&
                    selectedProviderPatientIds.length === clients.length
                  }
                  onChange={onSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all clients',
                  }}
                />
              </TableCell>
              {(showUserDisplayName
                ? ['Legal Name', 'Preferred Name', 'Email', 'Mobile Phone']
                : ['First Name', 'Last Name', 'Email', 'Mobile Phone']
              ).map((header) => (
                <TableCell
                  key={header}
                  css={{ fontWeight: theme.fontWeight.bold }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((row, index) => (
              <TableRow key={index}>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isSelected(row.providerPatientId)}
                    onClick={(event) =>
                      onSelectClick(event, row.providerPatientId)
                    }
                  />
                </TableCell>
                {showUserDisplayName ? (
                  <>
                    <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                    <TableCell>{`${formatDisplayName(row) ?? '-'}`}</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{row.firstName}</TableCell>
                    <TableCell>{row.lastName}</TableCell>{' '}
                  </>
                )}
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  {row.dataWarningTypes &&
                    !row.dataWarningTypes.includes(
                      DataWarningType.INVALID_PHONE
                    ) && <>{row.phone}</>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
