import * as React from 'react';

export const HighlightPeople = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M17.278 23.604l15.033 8.768c.763.455.763 1.198 0 1.653l-14.675 8.474c-.56.455-1.471.455-2.03 0L.571 33.731c-.763-.455-.763-1.198 0-1.652l14.676-8.475c.559-.455 1.47-.455 2.03 0z"
      fill="#D7F7E5"
    />
    <path
      d="M38.298 19.252a8.025 8.025 0 001.173-4.185c0-4.449-3.618-8.067-8.067-8.067-4.448 0-8.067 3.618-8.067 8.067 0 1.531.43 2.962 1.173 4.185-4.88 2.468-8.149 7.446-8.413 12.943-.02.403.13.8.408 1.091a1.5 1.5 0 001.076.463h27.644a1.483 1.483 0 001.484-1.553c-.264-5.498-3.532-10.476-8.413-12.944h.002zM31.404 8.62a6.456 6.456 0 016.45 6.45 6.456 6.456 0 01-6.45 6.449 6.456 6.456 0 01-6.449-6.45 6.456 6.456 0 016.45-6.449zM17.72 32.13c.291-4.96 3.313-9.428 7.787-11.566a8.05 8.05 0 0011.795 0c4.473 2.138 7.495 6.606 7.786 11.567H17.72zM16.7 20.565a13.691 13.691 0 00-7.811 12.377.81.81 0 01-1.62 0c0-5.828 3.28-11.1 8.437-13.691a8.064 8.064 0 01-1.18-4.18c0-4.449 3.618-8.068 8.066-8.068a.81.81 0 010 1.62 6.456 6.456 0 00-6.449 6.45A6.492 6.492 0 0019.3 20.62a.807.807 0 11-.828 1.39 7.926 7.926 0 01-1.774-1.442l.002-.003z"
      fill="#128656"
    />
  </svg>
);
