import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';

export const useMSCGuardrail = (): {
  isMSCGuardrailWarning: boolean;
  isMSCGuardrailRestriction: boolean;
  restrictionDate?: string;
} => {
  const MSCGuardrailJSON = useFlag(
    'multiStateCredentialingGuardrailRollout',
    null
  );
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  const guardrailWarningDate =
    MSCGuardrailJSON && MSCGuardrailJSON?.warningDate
      ? new Date(MSCGuardrailJSON?.warningDate)
      : undefined;

  const guardrailRestrictionDate =
    MSCGuardrailJSON && MSCGuardrailJSON?.restrictionDate
      ? new Date(MSCGuardrailJSON?.restrictionDate)
      : undefined;

  const today = new Date();

  const dateStringOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
  };

  const restrictionDate = guardrailRestrictionDate
    ? guardrailRestrictionDate?.toLocaleString('en-US', dateStringOptions)
    : undefined;

  const isMSCGuardrailRestriction = guardrailRestrictionDate
    ? today.valueOf() >= guardrailRestrictionDate.valueOf()
    : false;

  const isMSCGuardrailWarning =
    (guardrailWarningDate
      ? today.valueOf() >= guardrailWarningDate?.valueOf()
      : false) &&
    (guardrailRestrictionDate
      ? today.valueOf() < guardrailRestrictionDate?.valueOf()
      : true) &&
    !isMSCBetaEnabled;

  return { isMSCGuardrailWarning, isMSCGuardrailRestriction, restrictionDate };
};
