import { CloseTwoTone } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogContentProps,
  DialogProps,
  Grow,
  GrowProps,
  IconButton,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useProvider } from 'hooks';
import React, { useContext, useEffect, useState } from 'react';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';
import { PaginatedConcreteProviderEventRead } from 'utils/types';

import { GroupPracticeAdminBanner } from './Components/GroupPracticeAdminBanner';
import { RequiredInfoTreatmentPlanModal } from './Modals/RequiredInfoTreatmentPlanModal';
import { SaveAndExitTreatmentPlanModal } from './Modals/SaveAndExitTreatmentPlanModal';
import { useTreatmentPlanRequirements } from './Requirements/TreatmentPlanRequirements';
import { trackSaveAndExitButtonClickedEvent } from './TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from './TreatmentPlanContext';
import {
  getActiveTreatmentPlan,
  isTreatmentPlanExpired,
} from './TreatmentPlanUtils';

const GrowTransition = React.forwardRef(function Transition(
  props: GrowProps,
  ref
) {
  return <Grow ref={ref} {...props} />;
});

const treatmentPlanEditingPages = [
  TREATMENT_PLAN_PAGES.SUMMARY,
  TREATMENT_PLAN_PAGES.TEXT,
  TREATMENT_PLAN_PAGES.UPLOAD,
  TREATMENT_PLAN_PAGES.TEMPLATE,
];

const pagesToPlanType = new Map([
  [TREATMENT_PLAN_PAGES.SUMMARY, TreatmentPlanType.TEMPLATE],
  [TREATMENT_PLAN_PAGES.TEMPLATE, TreatmentPlanType.TEMPLATE],
  [TREATMENT_PLAN_PAGES.TEXT, TreatmentPlanType.TEXT],
  [TREATMENT_PLAN_PAGES.UPLOAD, TreatmentPlanType.UPLOAD],
]);

interface ModalBaseProps {
  onClose: () => void;
  onSubmit?: () => void;
}

export type TreatmentPlanModalProps = Omit<DialogProps, 'title'> &
  Pick<DialogContentProps, 'dividers'> &
  ModalBaseProps;

export const TreatmentPlanModal = ({
  open,
  onClose,
  children,
}: TreatmentPlanModalProps) => {
  const {
    page,
    hasUnsavedChanges,
    isNewTreatmentPlan,
    treatmentPlan,
    providerPatient,
    currentStep,
    treatmentPlans,
  } = useContext(TreatmentPlanContext);
  const { values } = useFormikContext();
  const { saveTreatmentPlan, deleteTreatmentPlan } = useProviderTreatmentPlan(
    providerPatient!.userId
  );
  const [saveAndExitModalOpen, setSaveAndExitModalOpen] =
    useState<boolean>(false);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);

  const { data: client } = useUser({ userId: providerPatient!.userId });
  const provider = useProvider();
  const { user } = useAuthStore();
  const { isTreatmentPlanRequired, treatmentPlanRequirementType } =
    useTreatmentPlanRequirements(client?.id);

  const hasPriorActiveTreatmentPlan = getActiveTreatmentPlan(
    treatmentPlans,
    isTreatmentPlanRequired
  )
    ? true
    : false;

  const showRequiredInfoTreatmentPlanModal =
    isTreatmentPlanRequired &&
    page === TREATMENT_PLAN_PAGES.TEMPLATE &&
    currentStep === 0 &&
    !hasPriorActiveTreatmentPlan &&
    !isInitializing;

  const [requiredInfoModalOpen, setRequiredInfoModalOpen] = useState<boolean>(
    showRequiredInfoTreatmentPlanModal
  );
  const [
    hasConfirmedIntakeNoteApptWithIntakeTherapyTemplate,
    setHasConfirmedIntakeNoteApptWithIntakeTherapyTemplate,
  ] = useState<boolean>(false);

  const attestedPreviousTreatmentPlans = treatmentPlans?.filter(
    (item) => item.attestedOn
  );

  const hasOnlyExpiredActiveTreatmentPlans =
    attestedPreviousTreatmentPlans?.length! > 0 &&
    attestedPreviousTreatmentPlans?.every((plan) =>
      isTreatmentPlanExpired(plan, isTreatmentPlanRequired)
    );

  useEffect(() => {
    const initialize = async () => {
      if (isTreatmentPlanRequired) {
        setIsInitializing(true);
        const confirmedEvents = (await ProviderEventApi.getEvents({
          provider_id: providerPatient?.providerId,
          patient_user_id: providerPatient?.userId,
          event_types: [ProviderEventType.APPOINTMENT],
          appointment_statuses: [ProviderAppointmentStatus.DETAILS_CONFIRMED],
          expand_estimated_prices: false,
        })) as PaginatedConcreteProviderEventRead;

        const confirmedIntakeNote = confirmedEvents.data.find(
          (event) => event.providerAppointment?.cptCodes?.includes('90791')
        );

        const intakeTherapyNotesForIntakeNoteAppt = confirmedIntakeNote
          ? await ProviderProgressNotesApi.findProviderProgressNotes({
              template_id: 29,
              provider_id: providerPatient?.providerId,
              patient_id: providerPatient?.userId,
              appointment_id: confirmedIntakeNote?.providerAppointment?.id,
            })
          : undefined;

        setHasConfirmedIntakeNoteApptWithIntakeTherapyTemplate(
          intakeTherapyNotesForIntakeNoteAppt?.find((item) => item.attestedOn)
            ? true
            : false
        );
        setIsInitializing(false);
      }
    };

    initialize();
  }, [
    isTreatmentPlanRequired,
    providerPatient?.providerId,
    providerPatient?.userId,
  ]);

  return (
    <Dialog
      open={open}
      TransitionComponent={GrowTransition}
      scroll="paper"
      onClose={onClose}
      fullScreen={true}
      css={{ zIndex: theme.layers.modal - 2 }}
      disableEscapeKeyDown
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: theme.spacing.x3,
        }}
      >
        <div css={{ marginLeft: theme.spacing.x3 }}>
          <PageHeader>
            {isNewTreatmentPlan || page === TREATMENT_PLAN_PAGES.START
              ? `New Treatment Plan for ${formatPatientName(client)}`
              : `${formatPatientName(client)}'s Treatment Plan`}
          </PageHeader>
        </div>

        <IconButton
          onClick={async () => {
            if (!treatmentPlanEditingPages.includes(page)) {
              onClose();
            }

            if (hasUnsavedChanges(values) || !isNewTreatmentPlan) {
              trackSaveAndExitButtonClickedEvent(providerPatient!);
            }

            if (hasUnsavedChanges(values)) {
              setSaveAndExitModalOpen(true);
            } else {
              if (
                page === TREATMENT_PLAN_PAGES.TEMPLATE &&
                isNewTreatmentPlan
              ) {
                await deleteTreatmentPlan(treatmentPlan?.id!);
              }
              onClose();
            }
          }}
          sx={{
            margin: theme.spacing.x1,
            zIndex: 1005,
          }}
          size="large"
        >
          <CloseTwoTone />
          <VisuallyHidden>Close dialog</VisuallyHidden>
        </IconButton>
      </div>
      {isGroupAdminImpersonatingProvider(provider, user) && (
        <GroupPracticeAdminBanner />
      )}
      <DialogContent css={{ padding: 0 }} dividers={true}>
        {children}
      </DialogContent>
      <SaveAndExitTreatmentPlanModal
        open={saveAndExitModalOpen}
        onClose={() => setSaveAndExitModalOpen(false)}
        onSaveAndExit={async () => {
          await saveTreatmentPlan({
            values,
            planType: pagesToPlanType.get(page)!,
            shouldAttestPlan: false,
            treatmentPlanId: treatmentPlan?.id,
          });
          onClose();
        }}
      />
      {showRequiredInfoTreatmentPlanModal && (
        <RequiredInfoTreatmentPlanModal
          open={requiredInfoModalOpen}
          onClose={async () => {
            setRequiredInfoModalOpen(false);
            if (
              page === TREATMENT_PLAN_PAGES.TEMPLATE &&
              isNewTreatmentPlan &&
              !hasUnsavedChanges(values)
            ) {
              await deleteTreatmentPlan(treatmentPlan?.id!);
            }
            onClose();
          }}
          onCreatePlanNow={() => setRequiredInfoModalOpen(false)}
          hasCompletedIntakeTherapyNote={
            hasConfirmedIntakeNoteApptWithIntakeTherapyTemplate
          }
          hasPriorActiveTreatmentPlan={hasPriorActiveTreatmentPlan}
          hasOnlyExpiredActiveTreatmentPlans={
            hasOnlyExpiredActiveTreatmentPlans
          }
          requirementType={treatmentPlanRequirementType}
        />
      )}
    </Dialog>
  );
};
