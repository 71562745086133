import React from 'react';
import { useContext } from 'react';

import { theme } from '@headway/ui/theme';

import { SetViewHeightVar } from '../utils/SetViewHeightVar';
import { BannersContext } from './utils';
import { calculateBannerHeight, HEADER_HEIGHT } from './utils';

export const FullHeightPanelLayout = ({ children }) => {
  const numBanners = useContext(BannersContext);
  const bannerHeight = calculateBannerHeight(numBanners + 1);

  return (
    <React.Fragment>
      <SetViewHeightVar />
      <div
        css={{
          marginTop: HEADER_HEIGHT,
          height: `calc(var(--real100vh) - ${bannerHeight}px)`,
          [theme.media.medium]: {
            width: '100%',
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </div>
    </React.Fragment>
  );
};
