import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  WHO5Submission,
  WHO5SubmissionAssessmentType,
} from '@headway/api/models/WHO5Submission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const WHO5_OPTIONS: OptionsSchema = [
  {
    displayText: 'At no time',
    value: 0,
  },
  {
    displayText: 'Some of the time',
    value: 1,
  },
  {
    displayText: 'Less than half of the time',
    value: 2,
  },
  {
    displayText: 'More than half of the time',
    value: 3,
  },
  {
    displayText: 'Most of the time',
    value: 4,
  },
  {
    displayText: 'All of the time',
    value: 5,
  },
];

const WHO5_QUESTIONS: QuestionsSchema = [
  {
    key: 'WHO5_1',
    questionText: 'I have felt cheerful and in good spirits',
  },
  {
    key: 'WHO5_2',
    questionText: 'I have felt calm and relaxed',
  },
  {
    key: 'WHO5_3',
    questionText: 'I have felt active and vigorous',
  },
  {
    key: 'WHO5_4',
    questionText: 'I woke up feeling fresh and rested',
  },
  {
    key: 'WHO5_5',
    questionText: 'My daily life has been filled with things that interest me',
  },
];

const INSTRUCTION =
  "Over the last 2 weeks, indicate how you've been feeling for each of the statements below.";

const WHO5_VALIDATION_SCHEMA = Yup.object().shape(
  WHO5_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type WHO5FormValues = {
  [key in keyof Omit<WHO5Submission, 'assessmentType'>]: string;
};

export const WHO5AssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: WHO5FormValues) => {
    return onSubmit({
      assessmentType: WHO5SubmissionAssessmentType.WHO5,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<WHO5FormValues>
      onSubmit={innerOnSubmit}
      validationSchema={WHO5_VALIDATION_SCHEMA}
      initialValues={{
        WHO5_1: '',
        WHO5_2: '',
        WHO5_3: '',
        WHO5_4: '',
        WHO5_5: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={WHO5_QUESTIONS}
            options={WHO5_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyWHO5Assessment = (props: ReadonlyAssessmentProps) => {
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={WHO5_QUESTIONS}
        options={WHO5_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </section>
  );
};
