import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderPatientQueryKeyArgs {
  providerId?: number;
  patientId?: number;
}

export const getUseProviderPatientQueryKey = ({
  providerId,
  patientId,
}: UseProviderPatientQueryKeyArgs): [
  string,
  number | undefined,
  number | undefined,
] => ['provider-patient', providerId, patientId];

const {
  useSingleQuery: useProviderPatient,
  useListQuery: useProviderPatientList,
  useCachedQuery: useProviderPatientCache,
} = createBasicApiHooks(
  getUseProviderPatientQueryKey,
  ({ providerId, patientId }) => {
    return ProviderPatientApi.getProviderPatientByIds({
      provider_id: providerId!,
      patient_user_id: patientId!,
    });
  },
  ({ providerId, patientId }) => !!(providerId && patientId),
  () => 'Failed to fetch provider patient'
);

export { useProviderPatient, useProviderPatientList, useProviderPatientCache };
