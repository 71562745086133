import React from 'react';

import { HospitalAffiliation } from '@headway/api/models/HospitalAffiliation';
import { Practice } from '@headway/api/models/Practice';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { theme } from '@headway/helix/theme';

import {
  CaqhKeyValuePair,
  CaqhSection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';

export const PracticeAddress: React.FC<
  React.PropsWithChildren<{
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    zip?: string;
  }>
> = ({ street1, street2, city, state, zip }) => (
  <div css={{ width: '100%' }}>
    <div css={[theme.typography.subbody.medium]}>
      {street1} {street2}
    </div>
    <div css={[theme.typography.subbody.medium]}>
      {city}, {state}, {zip}
    </div>
  </div>
);

const AddressColumn = ({
  addresses,
  label,
  labelStartingNumber = 0,
}: {
  addresses: Practice[] | HospitalAffiliation[];
  label: string;
  labelStartingNumber?: number;
}) => (
  <div
    css={{
      ...theme.stack.vertical,
      gap: theme.spacing.x2,
      width: '50%',
    }}
  >
    {addresses.map((practice: Practice | HospitalAffiliation, i: number) => (
      <CaqhKeyValuePair
        label={`${label} #${labelStartingNumber + i + 1}`}
        value={
          <PracticeAddress
            street1={practice.street1}
            street2={practice.street2}
            city={practice.city}
            state={practice.state}
            zip={practice.zip}
          />
        }
      />
    ))}
  </div>
);

const AddressList = ({
  addresses,
  label,
}: {
  addresses?: Practice[] | HospitalAffiliation[];
  label: string;
}) => {
  if (!addresses?.length) return <></>;

  const column1Limit = !!(addresses.length % 2 === 0)
    ? addresses.length / 2
    : Math.ceil(addresses.length / 2);

  return (
    <div css={{ ...theme.stack.horizontal, width: '100%' }}>
      <AddressColumn
        label={label}
        addresses={addresses.slice(0, column1Limit)}
      />
      <AddressColumn
        label={label}
        addresses={addresses.slice(column1Limit, addresses.length)}
        labelStartingNumber={column1Limit}
      />
    </div>
  );
};

export const PracticeHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  const { caqhPracticeLocations, hospitalAffiliations } = initialValues;

  return (
    <CaqhSection>
      <AddressList addresses={caqhPracticeLocations} label={'Practice'} />

      <AddressList
        addresses={hospitalAffiliations}
        label={'Hospital Affiliation'}
      />
    </CaqhSection>
  );
};
