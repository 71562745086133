import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';

type AppointmentUndoConfirmedSessionFormProps = {
  onClose: () => void;
  handleUndoConfirmedSession: () => void;
};

export const AppointmentUndoConfirmedSessionForm = ({
  onClose,
  handleUndoConfirmedSession,
}: AppointmentUndoConfirmedSessionFormProps) => {
  return (
    <div>
      <BodyText>
        Are you sure you want to undo your session confirmation? You can
        re-confirm anytime.
      </BodyText>
      <div className="mt-3 flex justify-end gap-2">
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onPress={handleUndoConfirmedSession}>
          Ok
        </Button>
      </div>
    </div>
  );
};
