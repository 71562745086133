import { Skeleton } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireRecredSummary } from '@headway/api/models/ProviderQuestionnaireRecredSummary';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { VerificationType } from '@headway/api/models/VerificationType';
import { ModalityApi } from '@headway/api/resources/ModalityApi';
import { ProviderCredentialApi } from '@headway/api/resources/ProviderCredentialApi';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { ProviderQuestionnaireVerificationApi } from '@headway/api/resources/ProviderQuestionnaireVerificationApi';
import { SpecialtyApi } from '@headway/api/resources/SpecialtyApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';
import {
  DEA_INTAKE_FORM_UPDATES,
  MULTI_STATE_CREDENTIALING_ENTRY_POINTS,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { usePayerQuestionnaires } from '@headway/shared/hooks/usePayerQuestionnaires';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  isMedicareAdvantage,
  MEDICARE_ADVANTAGE_PARAM,
} from '@headway/shared/utils/postIntakeInfoCollection';
import {
  isExistingProvider,
  isProviderInAnyStates,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { isRecredQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { getCurrentCoiPqvs } from '@headway/shared/utils/providerQuestionnaireVerification';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui';
import { notifyError } from '@headway/ui/utils/notify';

import { useSendIroncladResponseMutation } from 'components/IroncladAmendmentsModal/helpers/utils';
import { IroncladAgreementStatus } from 'components/IroncladAmendmentsModal/types/IroncladAgreementStatus';
import { useIsPanelabilityEnabledForNewProviders } from 'hooks/useIsPanelabilityIntakeFormEnabled';
import { hasRateAccess } from 'utils/access';
import PracticeStatesStepConfig from 'views/IntakeQuestionnaireV2/steps/PracticeStatesStep';
import PrescriberRegistrations from 'views/IntakeQuestionnaireV2/steps/PrescriberRegistrations';

import { useAuthStore } from '../../stores/AuthStore';
import {
  getPendingCOIVerifications,
  getUnskippedInvalidCoiPqvs,
  hasLongPendingCoiVerifications,
} from '../../utils/questionnaireVerificationHelpers';
import {
  getStepIndexByConfig,
  IntakeNavigation,
  StepState,
  validateSteps,
} from './IntakeNavigation';
import { OnBeforeSubmitError } from './OnBeforeSubmitErrorCard';
import { QuestionnaireStep } from './QuestionnaireStep';
import {
  QuestionnaireV2Context,
  QuestionnaireV2ContextType,
} from './QuestionnaireV2Context';
import { QuestionnaireV2Step } from './QuestionnaireV2Step';
import AddendumStepConfig, { AddendumStep } from './steps/Addendum';
import BoardCertificateConfig from './steps/BoardCertificate/BoardCertificate';
import CaqhAuthorizationStepConfig from './steps/CaqhAuthorization';
import {
  FinalCaqhErrorsStepConfig,
  InitialCaqhErrorsStepConfig,
} from './steps/CaqhErrorsStep/CaqhErrorsStep';
import CurrentPatientsConfig from './steps/CurrentClients';
import EducationConfig from './steps/Education/Education';
import EmploymentConfig from './steps/Employment/Employment';
import FocusAreasConfig from './steps/FocusAreas';
import IdentificationConfig from './steps/Identification';
import InsuranceStepConfig from './steps/InsuranceStep';
import { Introduction } from './steps/Introduction';
import LicenseConfig from './steps/LicenseStep/LicenseStep';
import { getMatchingLicensesAndVerifications } from './steps/LicenseStep/LicenseStepHelper';
import MalpracticeConfig from './steps/Malpractice/Malpractice';
import NpiStepConfig from './steps/Npi';
import PersonalInformationConfig from './steps/PersonalInformation/PersonalInformation';
import PracticeConfig from './steps/Practice/Practice';
import PracticeLanguagesConfig from './steps/PracticeLanguages';
import PracticeModalitiesConfig from './steps/PracticeModalities';
import ProfessionalReferencesConfig from './steps/ProfessionalReferences';
import { stepConfig as RatesConfig } from './steps/Rates';
import { Release, stepConfig as ReleaseConfig } from './steps/Release';
import WelcomeConfigV1 from './steps/Welcome';
import WelcomeConfigV2 from './steps/WelcomeV2';
import { handleConfirmSubmitQuestionnaire } from './utils/submission';

type QuestionnaireV2Props = {
  existingQuestionnaireData: ProviderQuestionnaireReadV2;
  refreshCaqhToIntakeMapping: (caqhNumber?: number) => Promise<{}>;
  refetchQuestionnaire: () => Promise<{}>;
  isRefreshing: boolean;
  doResetQuestionnaire: boolean;
  setDoResetQuestionnaire: (value: boolean) => void;
  recredSummary?: ProviderQuestionnaireRecredSummary;
};

const getCurrentStepFromIntake = (
  existingQuestionnaireData: ProviderQuestionnaireReadV2,
  steps: QuestionnaireV2Step[],
  isDEAIntakeUpdatesEnabled: boolean
) => {
  const currentStepFromIntake = existingQuestionnaireData?.currentStep;

  // currentStepFromIntake may be 0, so we explicitly check for undefined and null
  if (currentStepFromIntake === undefined || currentStepFromIntake === null) {
    if (isDEAIntakeUpdatesEnabled) {
      return 0;
    } else return -1;
  }

  return Math.min(currentStepFromIntake, steps.length - 1);
};
const getLatestCompletedStep = (
  prevLatestCompletedStep: number,
  currentStep: number,
  intakeSteps: QuestionnaireV2Step[]
) => {
  const nextStep = currentStep + 1;
  return nextStep > prevLatestCompletedStep
    ? Math.min(nextStep, intakeSteps.length - 1)
    : prevLatestCompletedStep;
};

export const QuestionnaireV2 = (props: QuestionnaireV2Props) => {
  const { rawData } = props.existingQuestionnaireData;

  const authStore = useAuthStore();
  const navigate = useNavigate();
  const shouldUseVerifiable = useFlag('verifiable');
  const shouldValidateCoiUploads = useFlag('validateCoi');
  const hispanicLatinxMigration = useFlag('hispanicLatinxMigration');
  const isMSCBetaEnabled = useFlag('multiStateCredentialingBeta');
  const isLicenseUploadDisabled = useFlag('licenseUploadDisabled');
  const isMSCEntryPointsEnabled = useFlag(
    MULTI_STATE_CREDENTIALING_ENTRY_POINTS
  );
  const isMSCOnboardingEnabled = useFlag(MULTI_STATE_CREDENTIALING_ONBOARDING);
  const isDEAIntakeUpdatesEnabled = useFlag(DEA_INTAKE_FORM_UPDATES, false);
  const providerSelectedPracticeStates =
    rawData?.providerSelectedPracticeStates;
  const isProviderPanelabilityEnabledForNewProviders =
    useIsPanelabilityEnabledForNewProviders(providerSelectedPracticeStates);
  const provider = authStore.provider as ProviderRead; // HACK: Until authstore is properly typescriptified
  const { refetchQuestionnaire } = props;
  const { mutateAsync: sendIroncladResponse } = useSendIroncladResponseMutation(
    authStore.user,
    provider,
    true,
    true
  );

  const { carriersById } = useFrontEndCarriers();
  const { data: payerQuestionnaires, refetch: refetchPayerQuestionnaires } =
    usePayerQuestionnaires({ provider });

  const AddendumSteps = useMemo(
    () =>
      payerQuestionnaires &&
      payerQuestionnaires.length &&
      Object.keys(carriersById).length
        ? AddendumStepConfig(payerQuestionnaires, carriersById)
        : [],
    [payerQuestionnaires, carriersById]
  );

  const isOptingIntoMedicareAdvantage = useMemo(
    () =>
      AddendumSteps.length > 0 &&
      payerQuestionnaires?.some((payerQuestionnaire: PayerQuestionnaire) => {
        const { name: frontEndCarrierName } =
          carriersById[payerQuestionnaire?.template?.frontEndCarrierId];
        return isMedicareAdvantage(frontEndCarrierName);
      }),
    [AddendumSteps, payerQuestionnaires, carriersById]
  );

  const STEPS: QuestionnaireV2Step[] = useMemo(() => {
    const steps = [
      ...(isDEAIntakeUpdatesEnabled ? [WelcomeConfigV2] : []),
      ...(isExistingProvider(provider) &&
      !isDEAIntakeUpdatesEnabled &&
      (isMSCBetaEnabled || isMSCEntryPointsEnabled || isMSCOnboardingEnabled)
        ? [PracticeStatesStepConfig]
        : []),
      NpiStepConfig,
      CaqhAuthorizationStepConfig,
      InitialCaqhErrorsStepConfig,
      PersonalInformationConfig,
      LicenseConfig,
      BoardCertificateConfig,
      ...(!isDEAIntakeUpdatesEnabled
        ? [IdentificationConfig]
        : provider.isPrescriber
        ? [PrescriberRegistrations]
        : []),
      MalpracticeConfig,
      EducationConfig,
      EmploymentConfig,
      PracticeConfig,
      FocusAreasConfig,
      PracticeModalitiesConfig,
      PracticeLanguagesConfig,
      InsuranceStepConfig,
      ...(isProviderInAnyStates(provider, [
        UnitedStates.WASHINGTON,
        UnitedStates.OREGON,
      ])
        ? [ProfessionalReferencesConfig]
        : []),
      ...(isRecredQuestionnaire(props.existingQuestionnaireData)
        ? []
        : [CurrentPatientsConfig]),
      ...AddendumSteps,
      ReleaseConfig,
      FinalCaqhErrorsStepConfig,
    ];

    if (
      (isMSCBetaEnabled || isMSCOnboardingEnabled) &&
      isExistingProvider(provider) &&
      hasRateAccess(provider, authStore.user)
    ) {
      steps.push(RatesConfig);
    } else if (isProviderPanelabilityEnabledForNewProviders) {
      steps.push(RatesConfig);
    }

    return steps;
  }, [
    isMSCEntryPointsEnabled,
    isMSCBetaEnabled,
    isMSCOnboardingEnabled,
    isDEAIntakeUpdatesEnabled,
    isProviderPanelabilityEnabledForNewProviders,
    props.existingQuestionnaireData,
    provider.activeProviderLicenseStates,
    provider.isPrescriber,
    AddendumSteps,
  ]);

  const currentStepFromIntake = getCurrentStepFromIntake(
    props.existingQuestionnaireData,
    STEPS,
    isDEAIntakeUpdatesEnabled
  );

  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [onBeforeSubmitError, setOnBeforeSubmitError] =
    useState<OnBeforeSubmitError | null>(null);
  const [currentStep, setCurrentStep] = useState(currentStepFromIntake);
  const [haveFormValuesChanged, setHaveFormValuesChanged] = useState(false);
  const [showStepChangeWarningModal, setShowStepChangeWarningModal] =
    useState(false);
  const [stepToGoTo, setStepToGoTo] = useState<number>(currentStep);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showMissingInfoModal, setShowMissingInfoModal] = useState(false);
  const [showVerificationPendingModal, setShowVerificationPendingModal] =
    useState(false);
  const [validatedSteps, setValidatedSteps] = useState<
    {
      step: QuestionnaireV2Step;
      stepState: StepState;
    }[]
  >();

  const showWarningOrGoToStep = (stepToGoTo: number) => {
    if (haveFormValuesChanged) {
      setShowStepChangeWarningModal(true);
      setStepToGoTo(stepToGoTo);
    } else {
      setCurrentStep(stepToGoTo);
    }
  };

  const updateQuestionnaire = useCallback(
    async (
      oldData: ProviderQuestionnaireRawData,
      newData: ProviderQuestionnaireRawData
    ) => {
      await ProviderQuestionnaireApi.updateProviderQuestionnaire(
        props.existingQuestionnaireData.id,
        {
          firstName: newData?.firstName,
          lastName: newData?.lastName,
          email: newData?.email,
          rawData: Object.assign(oldData, newData),
          currentStep: getLatestCompletedStep(
            currentStepFromIntake,
            currentStep,
            STEPS
          ),
        }
      );
      await refetchQuestionnaire();
    },
    [
      refetchQuestionnaire,
      props.existingQuestionnaireData.id,
      currentStepFromIntake,
      currentStep,
      STEPS,
    ]
  );

  const { data: malpracticeMinimums } = useQuery(
    [
      'malpracticeMinimums',
      provider.activeProviderLicenseStates
        .map((pls) => pls.state)
        .sort()
        .join(','),
    ],
    async () =>
      await ProviderQuestionnaireApi.getProviderMalpracticeMinimums({
        provider_id: provider.id,
      })
  );

  const { data: newPqvs } = useQuery(
    ['pqvs'],
    async () => {
      let pqvs = (
        await ProviderQuestionnaireApi.getProviderQuestionnaireVerifications(
          props.existingQuestionnaireData.id
        )
      ).filter(
        (pqv) =>
          pqv.type === VerificationType.CERTIFICATE_OF_INSURANCE &&
          shouldValidateCoiUploads
      );
      let pendingPqvs = getPendingCOIVerifications(pqvs);

      if (pendingPqvs.length >= 1) {
        const refreshedPendingPqvs =
          await ProviderQuestionnaireVerificationApi.refreshProviderQuestionnaireVerificationByIds(
            pendingPqvs.map((pqv) => pqv.id)
          );
        pqvs = pqvs.map((pqv) => {
          const refreshedPqv = refreshedPendingPqvs.find(
            (possiblePqv) => possiblePqv.id === pqv.id
          );
          return refreshedPqv || pqv;
        });
      }

      // TODO: Figure out how to handle validating steps
      // props.validateSteps();
      return pqvs;
    },
    {
      refetchInterval: 8000,
      enabled: shouldUseVerifiable || shouldValidateCoiUploads,
      notifyOnChangeProps: ['data'],
    }
  );
  const { data: licensesAndVerifications } = useQuery(
    ['licensesAndVerifications'],
    async () => await ProviderCredentialApi.getProviderLicenses(provider.id),
    {
      refetchInterval: 8000,
      enabled: shouldUseVerifiable,
      notifyOnChangeProps: ['data'],
    }
  );

  const hasLongRunningCoiVerifications: boolean = useMemo(() => {
    return hasLongPendingCoiVerifications(newPqvs);
  }, [newPqvs]);

  const { data: specialties = [] } = useQuery(['specialties'], {
    queryFn: async () => {
      const res = await SpecialtyApi.getSpecialties();
      res.sort((a, b) => {
        if (a.clinicalDisplayName < b.clinicalDisplayName) return -1;
        if (a.clinicalDisplayName > b.clinicalDisplayName) return 1;
        return 0;
      });

      return res;
    },

    initialData: [],
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const { data: modalities = [] } = useQuery(['modalities'], {
    queryFn: async () => {
      const res = await ModalityApi.getModalities();
      res.sort((a, b) => {
        if (a.clinicalDisplayName < b.clinicalDisplayName) return -1;
        if (a.clinicalDisplayName > b.clinicalDisplayName) return 1;
        return 0;
      });

      return res;
    },

    initialData: [],
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const contextValue = useMemo<QuestionnaireV2ContextType>(() => {
    return {
      provider,
      specialties,
      modalities,
      providerQuestionnaire: props.existingQuestionnaireData,
      refetchQuestionnaire: props.refetchQuestionnaire,
      refreshCaqhToIntakeMapping: props.refreshCaqhToIntakeMapping,
      isRefreshing: props.isRefreshing,
      pqvs: newPqvs,
      licensesAndVerifications: licensesAndVerifications,
      malpracticeMinimums,
      recredSummary: props.recredSummary,
      shouldUseVerifiable,
      shouldValidateCoiUploads,
      isRecredentialing: isRecredQuestionnaire(props.existingQuestionnaireData),
      hispanicLatinxMigration: hispanicLatinxMigration,
      disableSubmit: disableSubmit,
      setDisableSubmit: setDisableSubmit,
      onBeforeSubmitError: onBeforeSubmitError,
      setOnBeforeSubmitError: setOnBeforeSubmitError,
    };
  }, [
    provider,
    modalities,
    specialties,
    props.existingQuestionnaireData,
    props.refetchQuestionnaire,
    props.refreshCaqhToIntakeMapping,
    props.isRefreshing,
    hispanicLatinxMigration,
    malpracticeMinimums,
    newPqvs,
    licensesAndVerifications,
    shouldUseVerifiable,
    shouldValidateCoiUploads,
    disableSubmit,
    setDisableSubmit,
    onBeforeSubmitError,
    setOnBeforeSubmitError,
  ]);
  const formMeta =
    currentStep === -1 ? null : STEPS[currentStep].getFormMeta(contextValue);

  const selectedLicensesAndVerifications = useMemo(
    () =>
      getMatchingLicensesAndVerifications(
        provider,
        rawData?.selectedLicenses,
        licensesAndVerifications
      ),
    [provider, rawData, rawData?.selectedLicenses, licensesAndVerifications]
  );
  useEffect(() => {
    async function getValidatedSteps() {
      setValidatedSteps(
        await validateSteps(
          contextValue,
          newPqvs || [],
          selectedLicensesAndVerifications,
          setCurrentStep,
          STEPS,
          isMSCBetaEnabled,
          isLicenseUploadDisabled,
          isDEAIntakeUpdatesEnabled
        )
      );
    }
    getValidatedSteps();
  }, [
    contextValue,
    newPqvs,
    selectedLicensesAndVerifications,
    setCurrentStep,
    STEPS,
  ]);

  const unskippedInvalidCoiPqvs = useMemo(
    () =>
      getUnskippedInvalidCoiPqvs(
        newPqvs || [],
        rawData?.malpracticeInsurances || []
      ),
    [rawData, newPqvs]
  );
  const currentCoiPqvs = useMemo(
    () =>
      getCurrentCoiPqvs(newPqvs || [], rawData?.malpracticeInsurances || []),
    [rawData, newPqvs]
  );

  const pendingSectionConfigs = useMemo(
    () =>
      validatedSteps
        ?.filter((s) => s.stepState === StepState.PENDING)
        .map((x) => x.step),
    [validatedSteps]
  );

  useEffect(() => {
    if (!pendingSectionConfigs?.length && showVerificationPendingModal) {
      setShowVerificationPendingModal(false);
      setShowMissingInfoModal(true);
    }
  }, [pendingSectionConfigs, showVerificationPendingModal]);

  const onFinalStepSubmit = async () => {
    const hasNoInvalidSteps =
      validatedSteps?.filter((s) => s.stepState === StepState.INVALID)
        .length === 0;
    const hasPendingSteps = !!validatedSteps?.filter(
      (s) => s.stepState === StepState.PENDING
    ).length;

    // TODO(justin): comment back in once we figure out the behavior that we want
    // const shouldBlockSubmitDueToPendingVerifications =
    //   !hasLongRunningCoiVerifications && hasPendingSteps;

    const shouldBlockSubmitDueToPendingVerifications = false;

    if (shouldBlockSubmitDueToPendingVerifications) {
      setShowVerificationPendingModal(true);
    } else if (hasNoInvalidSteps) {
      await submitIntakeForm();
    } else {
      setShowMissingInfoModal(true);
    }
  };

  const submitIntakeForm = async () => {
    setDisableSubmitButton(true);
    if (!props.existingQuestionnaireData.completedOn) {
      trackEvent({
        name: 'Intake Form Completed',
        properties: {
          screenName: 'Intake Form: Submit Confirmation',
        },
      });
    }
    if (
      hasRateAccess(provider, authStore.user) &&
      isExistingProvider(provider)
    ) {
      await sendIroncladResponse(
        {
          ironcladAgreementStatus: IroncladAgreementStatus.AGREED,
        },
        {
          onError: (err: any) => {
            notifyError(
              'An error occurred while accepting your agreement. Please try again later. If the problem persists please contact hello@findheadway.com'
            );
            logException(err);
          },
        }
      );
    }

    try {
      await handleConfirmSubmitQuestionnaire(
        props.existingQuestionnaireData,
        provider,
        hasLongRunningCoiVerifications,
        specialties,
        modalities,
        hispanicLatinxMigration
      );
    } catch (error) {
      notifyError(
        'There was an error saving the form status, please try again.'
      );
      logException(error);
      setDisableSubmitButton(false);
      return;
    }

    setDisableSubmitButton(false);
    navigate(
      `/credentials/intake-submit-success${
        isOptingIntoMedicareAdvantage ? `?${MEDICARE_ADVANTAGE_PARAM}=true` : ''
      }`
    );
  };

  let currentStepConfig: QuestionnaireV2Step | undefined =
    currentStep === -1 ? undefined : STEPS[currentStep];

  if (isMSCBetaEnabled || isMSCEntryPointsEnabled || isMSCOnboardingEnabled) {
    currentStepConfig =
      currentStep === -1 ? WelcomeConfigV1 : STEPS[currentStep];
  }

  return (
    <QuestionnaireV2Context.Provider value={contextValue}>
      <div
        css={{
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 50px)',
          ...theme.reset,
        }}
      >
        {/*<IntakeProgress value={50} />*/}
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.color.system.white,
          }}
        >
          <IntakeNavigation
            steps={STEPS}
            currentStepNum={currentStep}
            validatedSteps={validatedSteps || []}
            setCurrentStepNum={showWarningOrGoToStep}
            doResetQuestionnaire={props.doResetQuestionnaire}
            setDoResetQuestionnaire={props.setDoResetQuestionnaire}
          />
          <main
            css={
              currentStepConfig?.css?.mainContainer ?? {
                flex: '1 1 auto',
                '@media (max-width: 1260px)': {
                  paddingLeft: theme.spacing.x4,
                  paddingRight: theme.spacing.x4,
                },
              }
            }
          >
            <div
              css={
                currentStepConfig?.css?.stepContainer ?? {
                  maxWidth: '960px',
                  margin: '20px auto',
                }
              }
            >
              {/* if isDEAIntakeUpdatesEnabled is off, the Welcome step index === -1 */}
              {currentStep === -1 ? (
                isMSCBetaEnabled ||
                isMSCOnboardingEnabled ||
                isMSCEntryPointsEnabled ? (
                  <QuestionnaireStep
                    key={currentStep}
                    currentStep={currentStep}
                    stepInfo={WelcomeConfigV1}
                    setHaveFormValuesChanged={setHaveFormValuesChanged}
                    onStepGoBack={() => {}}
                    onStepComplete={async (stepData) => {
                      await updateQuestionnaire(
                        props.existingQuestionnaireData.rawData || {},
                        stepData
                      );
                      if (currentStep === STEPS.length - 1) {
                        onFinalStepSubmit();
                      } else if (currentStep < STEPS.length - 1) {
                        setCurrentStep(currentStep + 1);
                        if (document.scrollingElement) {
                          document.scrollingElement.scrollTop = 0;
                        }
                      }
                    }}
                  />
                ) : (
                  <Introduction
                    onPressStart={() => {
                      setCurrentStep(0);
                    }}
                  />
                )
              ) : STEPS[currentStep] === ReleaseConfig ? (
                <Release
                  initialValues={props.existingQuestionnaireData.rawData || {}}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  updateQuestionnaire={updateQuestionnaire}
                  existingQuestionnaireData={props.existingQuestionnaireData}
                />
              ) : AddendumSteps.includes(STEPS[currentStep]) &&
                formMeta?.payerQuestionnaire !== undefined ? (
                <AddendumStep
                  payerQuestionnaire={formMeta.payerQuestionnaire}
                  refetchPayerQuestionnaires={refetchPayerQuestionnaires}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              ) : STEPS[currentStep] ? (
                <QuestionnaireStep
                  key={currentStep}
                  currentStep={currentStep}
                  isFinalStep={currentStep === STEPS.length - 1}
                  stepInfo={STEPS[currentStep]}
                  setHaveFormValuesChanged={setHaveFormValuesChanged}
                  onStepGoBack={() => {
                    if (currentStep >= 0) {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                  onStepComplete={async (stepData) => {
                    await updateQuestionnaire(
                      props.existingQuestionnaireData.rawData || {},
                      stepData
                    );
                    if (currentStep === STEPS.length - 1) {
                      onFinalStepSubmit();
                    } else if (currentStep < STEPS.length - 1) {
                      setCurrentStep(currentStep + 1);
                      if (document.scrollingElement) {
                        document.scrollingElement.scrollTop = 0;
                      }
                    }
                  }}
                />
              ) : (
                <div className="p-10">
                  <Skeleton variant="rectangular" height={500} />
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
      <Modal
        title="Leave section"
        open={showStepChangeWarningModal}
        onClose={() => {
          setShowStepChangeWarningModal(false);
          setStepToGoTo(currentStep);
        }}
      >
        Are you sure you want to leave this step? Changes you made will not be
        saved.
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing.x4,
            gap: theme.spacing.x2,
          }}
        >
          <Button
            variant="secondary"
            type="button"
            onPress={() => {
              setShowStepChangeWarningModal(false);
            }}
          >
            No
          </Button>
          <Button
            disabled={disableSubmitButton}
            onPress={() => {
              setCurrentStep(stepToGoTo);
              setShowStepChangeWarningModal(false);
              setStepToGoTo(currentStep);
            }}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Modal
        title="Missing information"
        open={showMissingInfoModal}
        onClose={() => setShowMissingInfoModal(false)}
      >
        <div>
          <BodyText>
            You have missing or unverified information in at least one section.
            Please fill out all the required fields in this section(s) before
            you proceed.
          </BodyText>
        </div>
        {shouldValidateCoiUploads &&
          (!currentCoiPqvs.length || !!unskippedInvalidCoiPqvs.length) && (
            <div css={{ marginBlock: theme.spacing.x4 }}>
              <span
                css={{
                  textDecoration: 'underline',
                  color: theme.color.system.green,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setCurrentStep(
                    getStepIndexByConfig(STEPS, MalpracticeConfig)
                  );
                  setShowMissingInfoModal(false);
                }}
              >
                Missing Certificates of Insurance Verification
              </span>
              <div>
                We were unable to verify your certificate of insurance. Please
                upload the correct certificate(s) as specified in the Documents
                step.
              </div>
            </div>
          )}
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing.x4,
            gap: theme.spacing.x2,
          }}
        >
          <Button
            variant="secondary"
            type="button"
            onPress={() => {
              setShowMissingInfoModal(false);
            }}
          >
            Back
          </Button>
        </div>
      </Modal>
      <Modal
        title="Pending Verification"
        open={showVerificationPendingModal}
        onClose={() => setShowVerificationPendingModal(false)}
        data-testid="pendingInfoModal"
      >
        <div>
          {hasLongRunningCoiVerifications ? (
            <>
              One or more verifications are taking a while to complete. You can
              submit your intake form and we will contact you if there is an
              issue with the information you provided!
            </>
          ) : (
            <>
              We are still verifying some of the information you provided for
              the following steps:
              <ul>
                {pendingSectionConfigs?.map((config: QuestionnaireV2Step) => {
                  return (
                    <li
                      css={{
                        cursor: 'pointer',
                        color: theme.color.system.green,
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        setCurrentStep(getStepIndexByConfig(STEPS, config));
                        setShowVerificationPendingModal(false);
                      }}
                    >
                      {config.title}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </Modal>
    </QuestionnaireV2Context.Provider>
  );
};
