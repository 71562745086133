import React from 'react';
import * as Yup from 'yup';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  FrontEndCarrierContext,
  FrontEndCarrierProvider,
} from '@headway/ui/providers/FrontEndCarrierProvider';

import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const statesThatRequireOhpId = [UnitedStates.WASHINGTON];

const InsuranceStep = ({ formikHelpers }: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const { frontEndCarriers } = React.useContext(FrontEndCarrierContext);
  const frontEndCarrierOptions: { label?: string; value?: string }[] =
    frontEndCarriers
      .sort((a, b) => (a?.id < b?.id ? -1 : 1))
      .map((carrier) => ({
        label: carrier.name,
        value: carrier.name,
      }))
      // Remove first option of "Other Insurances"
      .slice(1);
  return (
    <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
      <FormControl
        component={Select}
        name="insurances"
        label="Which insurances are you already participating and in network with?"
        selectionMode="multiple"
        menuWidth="stretch"
        onSelectionChange={(selected) => {
          const selectedFrontEndCarrierIds = Array.from(selected)
            .map((key) => frontEndCarriers.find((fec) => fec.name === key)?.id)
            .filter(Boolean);
          formikHelpers.setFieldValue(
            'previouslyInNetworkCarrierIds',
            selectedFrontEndCarrierIds
          );
        }}
      >
        {frontEndCarrierOptions.map((fec) => (
          <Item key={fec.value}>{fec.label}</Item>
        ))}
      </FormControl>
      <FormControl
        component={TextField}
        name={'otherInsurances'}
        label="Are there any other insurance providers that you accept?"
      />
      {isProviderInAnyStates(provider, statesThatRequireOhpId) && (
        <FormControl
          component={TextField}
          name={'ohpId'}
          label="OneHealthPort (OHP) Subscriber ID"
        />
      )}
    </div>
  );
};

export const InsuranceStepWithCarriers: React.FC<
  React.PropsWithChildren<CustomComponentProps>
> = (props) => {
  return (
    <FrontEndCarrierProvider>
      <InsuranceStep {...props} />
    </FrontEndCarrierProvider>
  );
};

const InsuranceStepConfig: QuestionnaireV2Step = {
  title: 'Insurance Information',
  description: 'In this section we ask that you share details about insurance.',
  Component: InsuranceStepWithCarriers,
  getFormMeta: ({ providerQuestionnaire }) => {
    const validationSchema = Yup.object().shape({
      insurances: Yup.array().of(Yup.string()),
      otherInsurances: Yup.string(),
      previouslyInNetworkCarrierIds: Yup.array().of(Yup.number()),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default InsuranceStepConfig;
