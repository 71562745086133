import React from 'react';
import * as Yup from 'yup';

import '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { Item, Select } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';
import { languages as allLanguages } from '@headway/shared/constants/languages';

import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const PracticeLanguages = () => {
  return (
    <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
      <FormControl
        component={Select}
        name="languages"
        label="Please indicate any languages that you speak."
        selectionMode="multiple"
        menuWidth="stretch"
      >
        {allLanguages.map((language) => (
          <Item key={language}>{language}</Item>
        ))}
      </FormControl>
    </div>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Practice Languages',
  description:
    'In this section we ask that you share details about languages spoken at your practice.',
  Component: PracticeLanguages,
  getFormMeta: ({ providerQuestionnaire }) => {
    const validationSchema = Yup.object().shape({
      languages: Yup.array().of(
        Yup.string().nullable().required('This cannot be blank.')
      ),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;
