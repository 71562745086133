import { PatientFormNestedRead } from '@headway/api/models/PatientFormNestedRead';
import { PatientFormRead } from '@headway/api/models/PatientFormRead';
import { PatientFormApi } from '@headway/api/resources/PatientFormApi';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';

export const getUsePatientFormQueryKey = (
  providerPatientId: number,
  PatientFormId: number
): [string, number | undefined, number] => [
  'patient-form',
  providerPatientId,
  PatientFormId,
];

export const getUsePatientFormsQueryKey = (
  providerPatientIds: Array<number>
): [string, Array<number>] => ['patient-forms', providerPatientIds];

export const usePatientForm = (
  queryParams: {
    providerPatientId: number;
    patientFormId: number;
  },
  options?: UseQueryOptions<
    PatientFormRead,
    unknown,
    PatientFormRead,
    ReturnType<typeof getUsePatientFormQueryKey>
  >
) => {
  return useQuery(
    getUsePatientFormQueryKey(
      queryParams.providerPatientId,
      queryParams.patientFormId
    ),
    () =>
      PatientFormApi.getPatientForm(
        queryParams.providerPatientId,
        queryParams.patientFormId
      ),
    {
      enabled: options?.enabled ?? !!queryParams.providerPatientId,
    }
  );
};

export function usePatientForms<T = PatientFormNestedRead[]>(
  queryParams?: {
    providerPatientIds: Array<number>;
    deletionStatus?: Array<'ACTIVE' | 'ARCHIVED'>;
  },
  options?: UseQueryOptions<
    PatientFormNestedRead[],
    unknown,
    T,
    ReturnType<typeof getUsePatientFormsQueryKey>
  >
) {
  return useQuery(
    getUsePatientFormsQueryKey(queryParams?.providerPatientIds || []),
    () =>
      PatientFormApi.findPatientForms({
        provider_patient_ids: queryParams?.providerPatientIds,
        deletion_status: queryParams?.deletionStatus,
      }),
    options
  );
}
