import React from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListHeader } from '@headway/helix/ListHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { trackEvent } from '@headway/shared/utils/analytics';

interface AssessmentsEmptyStateProps {
  onOpenManageAssessmentsModal: () => void;
  providerPatient: ProviderPatientRead;
}

/** Displayed when no assessments exist for a patient.  */
export const AssessmentsEmptyState = ({
  onOpenManageAssessmentsModal,
  providerPatient,
}: AssessmentsEmptyStateProps) => {
  const handleManageAssessments = () => {
    onOpenManageAssessmentsModal();
    trackEvent({
      name: 'See List of Assessments Button Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
      },
    });
  };

  const handleLearnMore = () => {
    trackEvent({
      name: 'Learn More Button About Client Assessments Clicked',
      properties: {
        patientUserId: providerPatient.userId,
        providerId: providerPatient.providerId,
      },
    });
  };

  return (
    <>
      <div className="mt-6 flex justify-between gap-[64px] overflow-hidden rounded bg-system-backgroundGray">
        <div className="flex-grow bg-system-backgroundGray p-5 shadow-[0_0_12px_8px] shadow-system-backgroundGray tablet:max-w-[630px]">
          <ListHeader>
            Prepare for sessions with deeper client insights
          </ListHeader>
          <ol className="hlx-typography-subbody relative mt-3 space-y-3 ps-8 text-system-textBlack [&>*]:before:absolute [&>*]:before:left-0 [&>*]:before:inline-block [&>*]:before:h-[18px] [&>*]:before:w-[18px] [&>*]:before:rounded-[18px] [&>*]:before:bg-system-textBlack [&>*]:before:text-center [&>*]:before:font-extrabold [&>*]:before:leading-[18px] [&>*]:before:text-system-white [&>*]:before:content-[counter(list-item)]">
            <li>
              Choose from a list of evidence-based assessments commonly used by
              providers
            </li>
            <li>Customize for each client's needs</li>
            <li>Track progress and understand client-reported concerns</li>
          </ol>
          <div className="mt-4 flex flex-row gap-3">
            <LinkButton
              variant="secondary"
              href="https://findheadway.zendesk.com/hc/en-us/articles/22620367351956"
              target="_blank"
              rel="noreferrer"
              onPress={handleLearnMore}
            >
              Learn more
            </LinkButton>
            <Button variant="primary" onPress={handleManageAssessments}>
              See list of assessments
            </Button>
          </div>
        </div>
        <div
          aria-hidden
          className="pointer-events-none flex flex-col items-end justify-center max-tablet:hidden"
        >
          <div className="flex w-[175px] flex-col gap-2 rounded-bl-[6px] rounded-tl-[6px] border-[1px] border-r-0 border-system-borderGray bg-system-white p-4 shadow-light">
            <ListHeader>PHQ-9</ListHeader>
            <div className="flex gap-1">
              <Badge variant="positive">Mild</Badge>
              <Badge variant="positive">↓ 3 since last</Badge>
            </div>
          </div>
          <div className="relative top-[-10px] flex w-[203px] flex-col gap-2 rounded-bl-[6px] rounded-tl-[6px] border-[1px] border-r-0 border-system-borderGray bg-system-white p-4 shadow-medium">
            <ListHeader>GAD-7</ListHeader>
            <div className="flex gap-1">
              <Badge variant="warning">Moderate</Badge>
              <Badge variant="negative">↑ 3 since last</Badge>
            </div>
          </div>
        </div>
      </div>
      <div className="my-4">
        <SubBodyText>
          <span className="text-system-gray">
            Product screenshots shown above are for concept only and do not
            reflect your client's results. Get answers to{' '}
          </span>
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://help.headway.co/hc/en-us/articles/22620367351956-Sending-assessments-to-clients-on-Headway#h_01HTCY4XARS8DEPF3REEJ3578K"
          >
            <span className="text-system-gray">
              common questions about assessments.
            </span>
          </Link>
        </SubBodyText>
      </div>
    </>
  );
};
