import { FileCopyOutlined, Security } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import React from 'react';
import { useToggle } from 'react-use';

import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { SetupAvailabilityStepEvent } from '@headway/avo';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { useMutation } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui';
import { theme as legacyTheme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';

const EXPORT_HC_LINK =
  'https://findheadway.zendesk.com/hc/articles/4409491542292';

export const CalendarExportSettings: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const calendarToken = useProvider().calendarAddressToken;

  return (
    <PageSection layout="grid.two-column">
      <div>
        <h2>
          <SectionHeader>Export Headway calendar</SectionHeader>
        </h2>

        <PageSectionSubText>
          <p>
            <BodyText>
              See your whole schedule in one place by exporting your Headway
              calendar.
            </BodyText>
          </p>
          <p>
            <BodyText>
              Copy this URL to your external calendar system to show the type,
              location, and time of your events — no client information will be
              shown.{' '}
              {calendarToken !== null ? (
                <>
                  Visit our help center for a{' '}
                  <Link
                    href={EXPORT_HC_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    step-by-step guide on exporting your calendar
                  </Link>
                  .
                </>
              ) : null}
            </BodyText>
            <p>
              <CalendarExportHelp />
            </p>
          </p>
        </PageSectionSubText>
      </div>
      <div
        css={{
          justifySelf: 'end',
        }}
      >
        <CalendarExportButton />
      </div>
    </PageSection>
  );
};

interface CalendarExportButtonProps {
  eventTrackingData?: SetupAvailabilityStepEvent;
}

export const CalendarExportButton: React.FC<
  React.PropsWithChildren<CalendarExportButtonProps>
> = ({ eventTrackingData }) => {
  const authStore = useAuthStore();
  const provider = useProvider();
  const calendarToken = provider.calendarAddressToken;
  const url = `${process.env.REACT_APP_API_URL}/calendar/${calendarToken}.ics`;

  const [showResetSuccess, setShowResetSuccess] = React.useState(false);

  const resetCalendarExportUrl = useMutation(
    () => {
      return ProviderApi.generateCalendarAddressToken(provider.id);
    },
    {
      onSuccess(response) {
        authStore.setProvider(response);
      },
    }
  );

  React.useEffect(() => {
    if (showResetSuccess) {
      const id = window.setTimeout(() => {
        setShowResetSuccess(false);
      }, 200);

      return () => window.clearTimeout(id);
    }
  }, [showResetSuccess]);

  return (
    <>
      {calendarToken ? (
        <React.Fragment>
          <div
            css={{
              display: 'grid',
              gap: theme.spacing.x3,
              alignItems: 'end',
              gridTemplateColumns: '1fr auto auto',
              maxWidth: '650px',
            }}
          >
            <TextField
              name="generated-calendar-url"
              label="Calendar URL"
              value={calendarToken ? url : ''}
              disabled={!calendarToken}
              readonly={true}
              data-testid="generatedCalendarUrl"
            />
            <Button
              variant="secondary"
              onPress={() => {
                navigator.clipboard.writeText(url).then(() => {
                  notifySuccess('Copied', {
                    autoHideDuration: 3000,
                  });
                });
              }}
              data-testid="copyGeneratedCalendarUrl"
            >
              Copy
            </Button>
            <Button
              variant="secondary"
              disabled={resetCalendarExportUrl.status === 'loading'}
              onPress={async () => {
                try {
                  await resetCalendarExportUrl.mutateAsync(undefined, {
                    onSuccess(response) {
                      setShowResetSuccess(true);
                    },
                  });
                } catch (err) {
                  notifyError(
                    'There was a problem resetting your calendar URL.'
                  );
                  logException(err);
                }
              }}
              // endIcon={
              //   resetCalendarExportUrl.status === 'loading' ? (
              //     <CircularProgress size={20} />
              //   ) : (
              //     <RefreshOutlined />
              //   )
              // }
            >
              Reset
            </Button>
          </div>

          <Alert
            css={{ marginTop: legacyTheme.space.base }}
            severity="info"
            icon={<Security />}
          >
            You should not give your calendar URL to other people. If you
            accidentally shared your calendar URL, click Reset to invalidate the
            old URL and create a new one.
          </Alert>
        </React.Fragment>
      ) : (
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <Button
            type="submit"
            variant="secondary"
            onPress={async () => {
              if (eventTrackingData) {
                trackEvent(eventTrackingData);
              }
              try {
                await resetCalendarExportUrl.mutateAsync();
              } catch (err) {
                notifyError(
                  'There was a problem generating your calendar URL.'
                );
                logException(err);
              }
            }}
            // endIcon={
            //   resetCalendarExportUrl.status === 'loading' ? (
            //     <CircularProgress size={20} />
            //   ) : null
            // }
            disabled={resetCalendarExportUrl.status === 'loading'}
            data-testid="generateCalendarUrl"
          >
            Generate URL
          </Button>
        </div>
      )}
    </>
  );
};

export const CalendarExportHelp: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [isOpen, toggle] = useToggle(false);

  return (
    <React.Fragment>
      <Button
        variant="link"
        onPress={() => {
          toggle();
        }}
      >
        Learn more
      </Button>

      <Modal title="How to Export your Calendar" open={isOpen} onClose={toggle}>
        <div css={{ minHeight: 400, display: 'flex', flexDirection: 'column' }}>
          <div>
            <section>
              <strong>Within Headway:</strong>
              <ol>
                <li>Click “Generate” to create your unique calendar URL</li>
                <li>
                  Copy URL with the copy to clipboard button{' '}
                  <FileCopyOutlined css={{ width: 18, height: 18 }} />
                </li>
              </ol>
              <strong>Within your preferred calendar app:</strong>
              <ol>
                <li>Navigate to where add external calendars</li>
                <li>Choose to add or import “from URL”</li>
                <li>
                  Paste your copied Headway URL (on clipboard) and add calendar
                </li>
              </ol>
            </section>
          </div>
          <Button
            variant="primary"
            onPress={() => {
              toggle();
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
