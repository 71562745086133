// @ts-ignore
import { CheckCircleTwoTone, InfoTwoTone } from '@mui/icons-material';
import { FormHelperText } from '@mui/material';
import { useField } from 'formik';
import React, { useEffect } from 'react';

import {
  getCarrierHelperText,
  getCarrierRegex,
} from '@headway/shared/utils/insuranceUtils';
import { FieldControlContext } from '@headway/ui/form/FieldControl';
import { FieldInput } from '@headway/ui/form/FieldInput';
import { theme } from '@headway/ui/theme';

interface FieldMemberIdWithValidationProps {
  carrierId: number;
  successIconColor?: string;
  inputProps?: React.ComponentProps<typeof FieldInput>['inputProps'];
}

export const FieldMemberIdWithValidation: React.FC<
  FieldMemberIdWithValidationProps
> = ({ carrierId, successIconColor, inputProps = {}, ...props }) => {
  const { name } = React.useContext(FieldControlContext);
  const [field, meta] = useField(name);
  const [isValidMemberId, setIsValidMemberId] = React.useState(true);
  const [isMemberIdChecked, setIsMemberIdChecked] = React.useState(false);

  const memberId = field.value;
  const error = meta.error;

  const checkMemberId = (carrierId: number, memberId: string) => {
    if (!!getCarrierRegex(carrierId)) {
      const matches = memberId.match(getCarrierRegex(carrierId).regex);
      setIsValidMemberId(!matches ? false : true);
      setIsMemberIdChecked(true);
    }
  };

  const timeoutcheckMemberId = (carrierId: number, memberId: string) => {
    return setTimeout(function () {
      checkMemberId(carrierId, memberId);
    }, 500);
  };

  useEffect(() => {
    if (!!memberId && !!carrierId) {
      const timeout = timeoutcheckMemberId(carrierId, memberId);
      return () => clearTimeout(timeout);
    }
  }, [memberId, carrierId]);

  return (
    <>
      <FieldInput
        data-testid="memberIdField"
        disabled={!carrierId}
        endAdornment={
          !error && isMemberIdChecked && !isValidMemberId ? (
            <InfoTwoTone css={{ color: theme.color.info }} />
          ) : !error && isMemberIdChecked && isValidMemberId ? (
            <CheckCircleTwoTone
              css={{ color: successIconColor || theme.color.primary }}
            />
          ) : null
        }
        inputProps={{
          ...inputProps,
          autoComplete: 'off',
        }}
        {...props}
      />
      {!error && isMemberIdChecked && !isValidMemberId ? (
        <FormHelperText
          variant="standard"
          css={{ color: theme.color.darkGray }}
        >
          {getCarrierHelperText(carrierId)}
        </FormHelperText>
      ) : null}
    </>
  );
};
