import { theme } from '@headway/helix/theme';

import { MOST_RECENT_ASSESSMENT_CLASSNAME } from '../../helpers/constants';

export const WarningGlyph = ({
  cx,
  cy,
  setIsTooltipOpen,
  active,
  isMostRecentAssessment,
}: {
  cx: number;
  cy: number;
  setIsTooltipOpen: (toChange: boolean) => {};
  active?: boolean;
  isMostRecentAssessment: boolean;
}) => (
  <svg
    x={cx - 11.5}
    y={cy - 10.5}
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onMouseEnter={() => setIsTooltipOpen(true)}
    onMouseLeave={() => setIsTooltipOpen(false)}
    className={isMostRecentAssessment ? MOST_RECENT_ASSESSMENT_CLASSNAME : ''}
  >
    <path
      d="M12.37052 4.24994C11.79317 3.24994 10.3498 3.249937 9.77244 4.24994L3.710267 14.7499C3.132918 15.7499 3.854603 16.9999 5.0093 16.9999L17.1337 16.9999C18.2884 16.9999 19.01 15.7499 18.4327 14.7499L12.37052 4.24994Z"
      fill="#DA9309"
      stroke={active ? theme.color.hue.lightYellow : theme.color.system.white}
      strokeWidth={active ? '6px' : '2px'}
    />
    <path
      d="M12.37052 4.24994C11.79317 3.24994 10.3498 3.249937 9.77244 4.24994L3.710267 14.7499C3.132918 15.7499 3.854603 16.9999 5.0093 16.9999L17.1337 16.9999C18.2884 16.9999 19.01 15.7499 18.4327 14.7499L12.37052 4.24994Z"
      fill="#DA9309"
    />
    {!active && (
      <path
        d="M8.47345 13.4999L11.07152 8.99994L13.6696 13.4999L8.47345 13.4999Z"
        fill="white"
        stroke="white"
      />
    )}
  </svg>
);
