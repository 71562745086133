import { EmergencyContactApi } from '@headway/api/resources/EmergencyContactApi';
import { useQuery } from '@headway/shared/react-query';

export const getUseEmergencyContactsQueryKey = (
  patientId: number | undefined
): [string, number | undefined] => ['emergency-contacts', patientId];

export const useEmergencyContacts = (patientId: number | undefined) => {
  return useQuery(
    getUseEmergencyContactsQueryKey(patientId),
    () => EmergencyContactApi.getEmergencyContactsByPatientId(patientId!),
    { enabled: !!patientId }
  );
};
