import { InfoTwoTone } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Malpractice } from '@headway/api/models/Malpractice';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { theme } from '@headway/helix/theme';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';

export interface ConfirmDocumentsProps {
  initialValues: any;
  closeModal: () => void;
  next: () => void;
}

const confirmNameSchema = Yup.object().shape({
  malpracticeAttest: Yup.boolean().oneOf(
    [true],
    'You must read and agree to the statement above.'
  ),
});

const compareNames = (nameOne: string, nameTwo: string) => {
  const sanitizedNameOne = nameOne.trim().toLowerCase();
  const sanitizedNameTwo = nameTwo.trim().toLowerCase();

  return sanitizedNameOne === sanitizedNameTwo;
};

export const ConfirmName: React.FC<
  React.PropsWithChildren<ConfirmDocumentsProps>
> = ({ initialValues, closeModal, next }) => {
  const initialName = `${initialValues.firstName} ${initialValues.lastName}`;
  const showError = initialValues?.malpracticeInsurances?.some(
    (malpractice: Malpractice) =>
      !compareNames(
        initialName,
        malpractice?.liabilityInsuranceNameInsured || ''
      )
  );

  return (
    <Formik
      initialValues={{ malpracticeAttest: false, ...initialValues }}
      onSubmit={() => {
        next();
      }}
      validationSchema={confirmNameSchema}
      render={({ values, isSubmitting }) => (
        <SafeFormikForm>
          <div css={{ marginBottom: theme.spacing.x4 }}>
            <span>Please confirm that the names listed below are correct:</span>
          </div>
          <div>
            <strong>Name provided at the beginning of the intake form:</strong>
          </div>
          <div css={{ marginBottom: theme.spacing.x4 }}>
            <span>
              {values.firstName} {values.lastName}
            </span>
          </div>
          <div>
            <strong>Liability Insurance Policy Name Insured:</strong>
          </div>
          <div css={{ marginBottom: theme.spacing.x6 }}>
            {values?.malpracticeInsurances?.map((malpractice: any) => (
              <div>
                <span>{malpractice.liabilityInsuranceNameInsured}</span>
              </div>
            ))}
          </div>
          {showError && (
            <div css={{ marginBottom: theme.spacing.x6 }}>
              <Alert
                color="error"
                variant="outlined"
                icon={<InfoTwoTone />}
                css={{ marginBottom: theme.spacing.x4 }}
              >
                The name insured you provided does not appear to match your
                legal name provided to us at the beginning of the form. The
                "Name Insured" listed on your Certificate of Insurance must be
                your individual name, not the name of your business or practice.
                <br />
                <br />
                If the “Name Insured” on your insurance policy does not match
                your individual name, this will cause delays in the
                credentialing process.
                <br />
                <br />
                To note: If both your business name and individual name are
                listed as the insured, this will suffice.
              </Alert>
            </div>
          )}
          <FormControl component={Checkbox} name="malpracticeAttest">
            I affirm that the document I have uploaded includes all of the
            required information above and I acknowledge that any failure to do
            so will slow down the credentialing process.{' '}
          </FormControl>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.spacing.x4,
              gap: theme.spacing.x2,
            }}
          >
            <Button
              variant="secondary"
              disabled={isSubmitting}
              onPress={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="large"
              type="submit"
              disabled={isSubmitting}
            >
              Confirm and Continue
            </Button>
          </div>
        </SafeFormikForm>
      )}
    />
  );
};
