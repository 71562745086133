export enum SectionStepForBscaSpecialty {
  FocusAreas = 'FocusAreas',
  Modalities = 'Modalities',
  CurrentClients = 'CurrentClients',
}

export enum BscaSpecialties {
  Addiction = 'addiction',
  EatingDisorders = 'eating_disorders',
  NeuropsychologicalTesting = 'Neuropsychological Testing',
  InfantsToddlers = 'Infants / Toddlers',
  Children = 'Children',
  Adolescents = 'Adolescents',
  Seniors = 'Seniors',
}
