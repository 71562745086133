/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum StyleTag {
    CHALLENGING = 'challenging',
    AFFIRMING = 'affirming',
    INQUISITIVE = 'inquisitive',
    DIRECT = 'direct',
    HUMOROUS = 'humorous',
    WARM = 'warm',
    OPEN_MINDED = 'open_minded',
    PARTICIPATORY = 'participatory',
    SOLUTION_ORIENTED = 'solution_oriented',
    CREATIVE = 'creative',
    HOLISTIC = 'holistic',
    ENERGETIC = 'energetic',
    EMPOWERING = 'empowering'
}
