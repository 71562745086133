import { Formik } from 'formik';
import React from 'react';

import { unitedStatesAbbreviations } from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  FieldAutocomplete,
  FieldControl,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
} from '@headway/ui/form';
import { theme } from '@headway/ui/theme';

import { Button } from '../Button';
import { SafeFormikForm } from '../form/SafeFormikForm';
import { AddressSchema } from './AddressModal';

export const AddressFormManual = ({
  selectedAddress,
  saveAddress,
  providerStates,
  setIsManualEditing,
}) => {
  return (
    <Formik
      initialValues={{
        city: selectedAddress.city || '',
        state: selectedAddress.state || '',
        streetLine1: selectedAddress.streetLine1 || '',
        streetLine2: selectedAddress.streetLine2 || '',
        isActive: selectedAddress.hasOwnProperty('isActive')
          ? selectedAddress.isActive
          : true,
        zipCode: selectedAddress.zipCode || '',
      }}
      validationSchema={AddressSchema}
      onSubmit={(values) => {
        saveAddress(values);
        setIsManualEditing(false);
      }}
      render={({ isSubmitting }) => (
        <SafeFormikForm>
          <div>
            <FieldControl name={`streetLine1`} fullWidth={true}>
              <FieldInputLabel>Street Line 1</FieldInputLabel>
              <FieldInput />
              <FieldErrorText />
            </FieldControl>
            <FieldControl name={`streetLine2`} fullWidth={true}>
              <FieldInputLabel>Street Line 2</FieldInputLabel>
              <FieldInput />
              <FieldErrorText />
            </FieldControl>
            <FieldControl name={`city`} fullWidth={true}>
              <FieldInputLabel>City</FieldInputLabel>
              <FieldInput />
              <FieldErrorText />
            </FieldControl>
            <FieldControl name={`state`} fullWidth={true}>
              <FieldAutocomplete
                label="State"
                options={providerStates.map(
                  (st) => unitedStatesAbbreviations[st.state]
                )}
                getOptionLabel={(option) => option}
              />
              <FieldErrorText />
            </FieldControl>
            <FieldControl name={`zipCode`} fullWidth={true}>
              <FieldInputLabel>Zip Code</FieldInputLabel>
              <FieldInput />
              <FieldErrorText />
            </FieldControl>
            <div
              css={{
                paddingTop: theme.space.base,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                disabled={isSubmitting}
                variant="text"
                onClick={() => setIsManualEditing(false)}
              >
                Back
              </Button>
              <Button
                disabled={isSubmitting}
                color="primary"
                variant="contained"
                css={{ marginLeft: theme.space.sm }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </SafeFormikForm>
      )}
    />
  );
};
