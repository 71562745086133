import * as Sentry from '@sentry/browser';
import React, { forwardRef } from 'react';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { Form, FormRef } from '@headway/form-builder';
import { theme } from '@headway/helix/theme';

import { PostIntakeInfoCollectionFormState } from '../../shared/utils/postIntakeInfoCollection';

type PostIntakeInfoCollectionFormProps = {
  formState: PostIntakeInfoCollectionFormState;
  onSubmit: (values: any) => Promise<void>;
  payerQuestionnaire: PayerQuestionnaire;
};

export const PostIntakeInfoCollectionForm = forwardRef(
  (
    {
      formState,
      onSubmit,
      payerQuestionnaire,
    }: PostIntakeInfoCollectionFormProps,
    ref: React.Ref<FormRef>
  ) => {
    const template = JSON.parse(
      JSON.stringify(payerQuestionnaire.template.templateSchema)
    );
    const response = JSON.parse(
      JSON.stringify(payerQuestionnaire.response.response)
    );

    const sendSentryMessage = (messages: string[]) => {
      messages.push(
        'frontEndCarrierId: ' + payerQuestionnaire.template.frontEndCarrierId
      );
      Sentry.captureException(new Error(messages.join(' -- ')), (scope) => {
        scope.setTag('form-builder', 'piic');
        scope.setTransactionName('PIIC - Runtime Error');
        return scope;
      });
    };

    return (
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          paddingBottom: theme.spacing.x6,
        }}
      >
        <Form.View
          ref={ref}
          form={template}
          state={formState}
          response={response}
          onSubmit={onSubmit}
          onRuntimeErrors={(errors: string[]) => {
            if (errors.length > 0) {
              sendSentryMessage(errors);
            }
          }}
        />
      </div>
    );
  }
);
