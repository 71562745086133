import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useQueryParams } from './useQueryParams';

export function withReactRouterV5Props<T>(Component: React.ComponentType<T>) {
  return (props: T) => {
    const params = useParams();
    const queryParams = useQueryParams();
    const location = useLocation();
    const navigate = useNavigate();

    const historyFacade = useMemo(() => {
      return {
        push: (path: string) => {
          navigate(path);
        },
        replace: (path: string) => {
          navigate(path, { replace: true });
        },
        go: (index: number) => {
          navigate(index);
        },
      };
    }, [navigate]);

    return (
      <Component
        {...props}
        match={{ params }}
        queryParams={queryParams}
        location={location}
        history={historyFacade}
      />
    );
  };
}
