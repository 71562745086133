import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderTerminatedReason } from '@headway/api/models/ProviderTerminatedReason';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { ProviderLicenseStateApi } from '@headway/api/resources/ProviderLicenseStateApi';

const resetTerminatedProviderLicenseState = () => ({
  terminatedOn: null,
  terminatedNote: null,
  terminatedReason: null,
  terminatedTrigger: null,
  terminatedByUserId: null,
});

/**
 * Creates PLS rows and corresponding PFEC's based on what the provider
 * selected in the page dropdown.
 *
 * - if it is a new state, we create a new pls row
 * - if the pls row already exists we un-terminate it if it was termianted from
 * within the intake form and was never live or tied to a completed questionnaire
 * - for each pls row we generate pfecs for the given carriersByState and the
 * coresponding pls
 *
 * @param provider
 * @param addedStates
 * @param carriersByState
 */
export const addProviderLicenseStatesAndPFECs = async (
  provider: ProviderRead,
  addedStates: UnitedStates[],
  carriersByState: Required<ProviderQuestionnaireRawData>['providerSelectedCarriersByState']
): Promise<{ stateErrors: UnitedStates[]; carrierErrors: number[] }> => {
  const allProviderPLSs = provider.allProviderLicenseStates;
  const stateErrors: UnitedStates[] = [];
  const carrierErrors: number[] = [];

  const createPLSPromises = addedStates.map((state) => {
    const preExistingPLS = allProviderPLSs.find((pls) => pls.state === state);
    if (preExistingPLS) {
      // If the previous PLS was live or not terminated or was not terminated because the provider cancelled intake, don't do anything here
      if (
        !preExistingPLS.terminatedOn ||
        preExistingPLS.terminatedReason !==
          ProviderTerminatedReason.PROVIDER_CANCELED_NEW_CREDENTIALING_REQUEST_IN_INTAKE ||
        preExistingPLS.liveOn
      ) {
        stateErrors.push(state);
        return null;
      }

      let questionnaireUpdate = {};
      if (preExistingPLS.questionnaireCompletedOn && !preExistingPLS.liveOn) {
        // Make the update payload set questionnaireCompletedOn to null
        questionnaireUpdate = {
          questionnaireCompletedOn: null,
        };
      }

      return new Promise<ProviderLicenseStateRead>(async (resolve, reject) => {
        try {
          const updatedRow =
            await ProviderLicenseStateApi.updateProviderLicenseState(
              preExistingPLS.id,
              {
                ...resetTerminatedProviderLicenseState(),
                ...questionnaireUpdate,
                ...{
                  isActive: true,
                },
              } as any
            );
          resolve(updatedRow);
        } catch (e) {
          console.error(
            `Error in updating existing PLS row in intake form for provider: ${provider.id}`
          );
          stateErrors.push(state);
        }
      });
    } else {
      return new Promise<ProviderLicenseStateRead>(async (resolve, reject) => {
        try {
          const createdRow =
            await ProviderLicenseStateApi.createProviderLicenseState({
              providerId: provider.id,
              isActive: true,
              state: state,
            });

          resolve(createdRow);
        } catch (e) {
          console.error(
            `Error in creating a PLS row in intake form for provider: ${provider.id}`
          );
          stateErrors.push(state);
        }
      });
    }
  });

  const nonNullPls = (
    plsOrNull: ProviderLicenseStateRead | null
  ): plsOrNull is ProviderLicenseStateRead => plsOrNull !== null;

  const new_pls_rows: (ProviderLicenseStateRead | null)[] =
    await Promise.all(createPLSPromises);

  const createPFECPromises = new_pls_rows
    .filter(nonNullPls)
    .map((pls) => {
      if (!carriersByState[pls.state]) {
        return [];
      }

      return carriersByState[pls.state].map((carrierId: number) => {
        return new Promise<void>(async (resolve, reject) => {
          try {
            await ProviderFrontEndCarrierApi.addProviderFrontEndCarrierToProvider(
              {
                providerId: provider.id,
                providerLicenseStateId: pls.id,
                frontEndCarrierId: carrierId,
              }
            );
            resolve();
          } catch (e) {
            console.error(
              `Error in creating a pfecs in intake form for provider: ${provider.id}`
            );
            carrierErrors.push(carrierId);
          }
        });
      });
    })
    .flat();

  await Promise.all(createPFECPromises);

  return {
    stateErrors,
    carrierErrors,
  };
};

/**
 *
 * We remove the PFECs and terminate the PLS, only if the pls
 * is not live and is not tied to a completed questionnaire
 *
 * @param provider
 * @param removeStatePLSs
 * @returns
 */
export const removePFECsAndTerminatePLSs = async (
  provider: ProviderRead,
  removeStatePLSs: ProviderLicenseStateRead[]
): Promise<{
  stateErrors: UnitedStates[];
  carrierErrors: number[];
}> => {
  const stateErrors: UnitedStates[] = [];
  const carrierErrors: number[] = [];
  const terminatePLSPromises = removeStatePLSs
    .filter((pls) => !pls.liveOn && !pls.questionnaireCompletedOn)
    .map((pls) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          await ProviderLicenseStateApi.terminateProviderLicenseState(pls.id, {
            terminatedReason:
              ProviderTerminatedReason.PROVIDER_CANCELED_NEW_CREDENTIALING_REQUEST_IN_INTAKE,
          });
        } catch (e) {
          console.error(
            `Error in terminating pls in intake form for provider: ${provider.id}`
          );
          stateErrors.push(pls.state);
        }

        resolve();
      });
    });

  await Promise.all(terminatePLSPromises);

  return {
    stateErrors,
    carrierErrors,
  };
};
