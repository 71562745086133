import React from 'react';

export function IconCreditCardCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.0625 6.6665H16.9375V4.729H3.0625V6.6665ZM1.6665 16.6665V3.3335H18.3335V9.7085H3.0625V15.271H7.4375V16.6665H1.6665ZM12.4585 18.3335L8.9165 14.7915L9.896 13.8125L12.4585 16.354L17.354 11.4375L18.3335 12.4585L12.4585 18.3335ZM3.0625 4.729V15.271V11.8335V14.4165V4.729Z"
        fill="currentColor"
      />
    </svg>
  );
}
