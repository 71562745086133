import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormHelperTextProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { ErrorText } from './ErrorText';
import { FieldControlContext } from './FieldControl';

export const FieldErrorText = ({
  children,
  errorOverride,
  ...props
}: FormHelperTextProps & {
  errorOverride?: string;
}) => {
  const { name, id } = React.useContext(FieldControlContext);
  const [, meta] = useField(name);
  const form = useFormikContext();
  if (
    (!errorOverride && !meta.error) ||
    (!meta.touched && form.submitCount == 0)
  ) {
    return null;
  }

  return (
    <ErrorText id={`${id}-error`} error={true} {...props}>
      <ErrorOutlineIcon
        titleAccess="Error"
        css={{ fontSize: 'inherit', verticalAlign: '-2px', marginRight: '2px' }}
      />
      {errorOverride || meta.error}
    </ErrorText>
  );
};
