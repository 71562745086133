import { useProvider } from 'hooks';
import React, { FC, useContext } from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import { Modal } from '@headway/ui';

import {
  useProviderEvent,
  useProviderEventCache,
} from 'hooks/useProviderEvent';
import {
  UpdateProviderEventMutationArgs,
  UpdateRecurringInstanceMutationArgs,
  useCreateProviderEventMutation,
  useUpdateProviderEventMutation,
  useUpdateRecurringInstanceAndAllFollowingMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';

import { ScheduleUnavailabilityForm } from '../form/ScheduleUnavailabilityForm';
import { CalendarSlot } from '../utils/Calendar';
import {
  useCreateProviderEventSideEffectsForCalendar,
  useUpdateProviderEventSideEffectsForCalendar,
  useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar,
} from '../utils/queries';

interface Props {
  isOpen: boolean;
  calendarSlot?: CalendarSlot;
  isRescheduling: boolean;
  timeZone: string;
  onClose: () => void;
}

const ScheduleUnavailabilityModal: FC<React.PropsWithChildren<Props>> = ({
  calendarSlot,
  isOpen,
  isRescheduling,
  onClose,
  timeZone,
}) => {
  const { selectedEventVirtualId } = useContext(SelectedEventContext);
  let { data: event } = useProviderEvent(
    {
      eventIdOrVirtualId: selectedEventVirtualId,
    },
    {
      enabled: isRescheduling || !calendarSlot,
    }
  );

  const provider = useProvider();
  const providerEventCache = useProviderEventCache();

  const createEventMutation = useCreateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      ProviderEventCreate
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useCreateProviderEventSideEffectsForCalendar()),
  });
  const updateEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar()),
  });
  const updateEventAndAllFollowingMutation =
    useUpdateRecurringInstanceAndAllFollowingMutation({
      sideEffects: new SideEffectsBuilder<
        ProviderEventRecurrenceUpdateResponse,
        unknown,
        UpdateRecurringInstanceMutationArgs
      >()
        .add({
          onSuccess: (result) => {
            providerEventCache.set(
              { eventIdOrVirtualId: result.updatedInstance.virtualId },
              result.updatedInstance
            );
          },
        })
        .merge(
          useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar()
        ),
    });

  const handleSubmit = async (createValues: ProviderEventCreate) => {
    await createEventMutation.mutateAsync(createValues);
    onClose();
  };

  const handleRescheduleSubmit = async (
    updateValues: ProviderEventUpdate,
    recurringUpdateApplyTo: RecurringUpdateApplyTo = RecurringUpdateApplyTo.THIS_EVENT
  ) => {
    if (isRescheduling && event) {
      if (recurringUpdateApplyTo === RecurringUpdateApplyTo.FOLLOWING_EVENTS) {
        await updateEventAndAllFollowingMutation.mutateAsync({
          virtualId: event.virtualId,
          update: updateValues,
        });
      } else {
        await updateEventMutation.mutateAsync({
          eventIdOrVirtualId: event.virtualId,
          update: updateValues,
        });
      }

      onClose();
    }
  };

  const calendarSlotOrEvent = isRescheduling ? event : calendarSlot ?? event;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={`${isRescheduling ? 'Reschedule ' : 'Schedule'} unavailability`}
    >
      {provider && calendarSlotOrEvent ? (
        <ScheduleUnavailabilityForm
          event={calendarSlotOrEvent}
          isRescheduling={isRescheduling}
          provider={provider}
          timeZone={timeZone}
          handleSubmit={handleSubmit}
          handleRescheduleSubmit={handleRescheduleSubmit}
        />
      ) : null}
    </Modal>
  );
};

export { ScheduleUnavailabilityModal };
