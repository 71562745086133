import * as React from 'react';

export const AddPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 8.66665H8.66671V12.6666H7.33337V8.66665H3.33337V7.33331H7.33337V3.33331H8.66671V7.33331H12.6667V8.66665Z"
      fill="#212326"
    />
  </svg>
);
