import { keyframes } from '@emotion/react';
import { Formik } from 'formik';
import React, { useState } from 'react';

import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { Loading } from '@headway/icons/dist/helix/Loading';
import { PATIENT_WELCOME_EMAIL } from '@headway/shared/constants/zendesk';
import { NEW_HUMAN_INPUT_ERROR_PRODUCT_EXPERIENCE } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { isFuzzyMatchedLookup } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui';
import { PatientAddressForm } from '@headway/ui/PatientAddressForm';

import { SelfPayOptInForm } from 'components/SelfPayOptInForm/SelfPayOptInForm';
import { InsuranceVerifyMatch } from 'views/Clients/InsuranceVerifyMatch';
import {
  getAuthorizationInstructionsModalTitle,
  InsuranceAuthorizationInstructionsModalContent,
} from 'views/Patients/InsuranceAuthorizationInstructionsModalContent';

import { PatientBillingForm } from './PatientBillingForm';
import {
  PatientDemographicsForm,
  PatientDemographicsFormProps,
} from './PatientDemographicsForm';
import { PatientInsuranceReview } from './PatientInsuranceReview';

type AddPatientModalProps = Omit<PatientDemographicsFormProps, 'onSubmit'> & {
  open: boolean;
  onClose: () => void;
  onComplete: (updatedUser: UserRead) => void;
  onPatientAdded: () => void;
  provider: ProviderRead;
};

enum AddPatientModalPage {
  BASIC_INFO = 'BASIC_INFO',
  EXISTING_INSURANCE = 'EXISTING_INSURANCE',
  PAYMENT_DETAIL = 'PAYMENT_DETAIL',
  PATIENT_ADDRESS = 'PATIENT_ADDRESS',
  SEND_ACCOUNT_INVITE = 'SEND_ACCOUNT_INVITE',
  INSURANCE_AUTHORIZATION_INSTRUCTIONS = 'INSURANCE_AUTHORIZATION_INSTRUCTIONS',
  VERIFY_INSURANCE_MATCH = 'VERIFY_INSURANCE_MATCH',
}

/**ts
 * Modal for adding a patient
 * @param open boolean indicating whether modal is open
 * @param onClose callback when modal is closed
 * @param onPatientAdded callback after a provider patient relationship gets created
 * @param onComplete callback after a provider patient relationship gets created
 */
export const AddPatientModal: React.FC<
  React.PropsWithChildren<AddPatientModalProps>
> = ({ open, onClose, onPatientAdded, onComplete, provider }) => {
  const [currentPage, setCurrentPage] = React.useState<AddPatientModalPage>(
    AddPatientModalPage.BASIC_INFO
  );
  const [patient, setPatient] = React.useState<UserRead>();
  const [providerPatient, setProviderPatient] =
    React.useState<ProviderPatientRead>();
  const [isSelfPayProviderOptInModalOpen, setIsSelfPayProviderOptInModalOpen] =
    React.useState(false);
  const [didAttemptSelfPayOptIn, setDidAttemptSelfPayOptIn] =
    React.useState(false);

  const newHumanInputErrorExperienceEnabled = useFlag(
    NEW_HUMAN_INPUT_ERROR_PRODUCT_EXPERIENCE,
    false
  );

  const closeAndClearState = () => {
    onClose();
    setCurrentPage(AddPatientModalPage.BASIC_INFO);
    setPatient(undefined);
  };
  const [
    carrierToShowAuthzInstructionsFor,
    setCarrierToShowAuthzInstructionsFor,
  ] = useState<FrontEndCarrierRead | FrontEndCarrierNested>();

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const queryClient = useQueryClient();

  const isModalOpen = !isSelfPayProviderOptInModalOpen && open;

  React.useEffect(() => {
    if (isModalOpen && currentPage === AddPatientModalPage.BASIC_INFO) {
      trackEvent({
        name: 'Add Patient Started',
        properties: {
          providerFlow: 'single_patient',
        },
      });
    }
  }, [isModalOpen, currentPage]);

  function title() {
    const totalSteps = 3;

    switch (currentPage) {
      case AddPatientModalPage.BASIC_INFO:
        return `Step 1 of ${totalSteps}: Add client`;
      case AddPatientModalPage.EXISTING_INSURANCE:
      case AddPatientModalPage.PAYMENT_DETAIL:
      case AddPatientModalPage.PATIENT_ADDRESS:
      case AddPatientModalPage.VERIFY_INSURANCE_MATCH:
        return `Step 2 of ${totalSteps}: ${patientName}'s Billing Details`;
      case AddPatientModalPage.SEND_ACCOUNT_INVITE:
        return `Step 3 of ${totalSteps}: Send ${patientName} a setup invitation`;
      case AddPatientModalPage.INSURANCE_AUTHORIZATION_INSTRUCTIONS:
        return getAuthorizationInstructionsModalTitle(patientName);
      default:
        throw new Error('Unknown page when rendering title');
    }
  }

  function onBillingDetailsComplete() {
    setCurrentPage(AddPatientModalPage.SEND_ACCOUNT_INVITE);
  }

  return (
    <>
      <Modal
        data-testid="add-client-modal"
        open={isModalOpen}
        onClose={closeAndClearState}
        title={title()}
      >
        {currentPage === AddPatientModalPage.BASIC_INFO && (
          <React.Fragment>
            <GuidanceCard variant="info">
              <span>
                Add a client below to start seeing them on Headway. You can
                easily send a notification to your client via{' '}
                <Link
                  target="_blank"
                  href={PATIENT_WELCOME_EMAIL}
                  rel="noopener noreferrer"
                >
                  email
                </Link>{' '}
                to complete their account, complete insurance and billing
                details, and acknowledge our standard forms (
                <Link
                  target="_blank"
                  href={'https://headway.co/legal/privacy-practices'}
                  rel="noopener noreferrer"
                >
                  form 1
                </Link>
                ,{' '}
                <Link
                  target="_blank"
                  href={'https://headway.co/legal/responsibility'}
                  rel="noopener noreferrer"
                >
                  form 2
                </Link>
                ).
              </span>
            </GuidanceCard>
            <PatientDemographicsForm
              provider={provider}
              onSubmit={async (
                user,
                providerPatient,
                hasDisplayNameChecked,
                displayNameCheckedCount,
                notifyUser
              ) => {
                setPatient(user);
                setProviderPatient(providerPatient);
                if (user.activeUserInsuranceId) {
                  setCurrentPage(AddPatientModalPage.EXISTING_INSURANCE);
                } else {
                  setCurrentPage(AddPatientModalPage.PAYMENT_DETAIL);
                }
                onPatientAdded();
                trackEvent({
                  name: 'Add Patient Step Viewed',
                  properties: {
                    stepName: 'submitted',
                    screenName: 'Add Patient',
                    numPatientsSaved: 1,
                    ctaButtonCopy: 'Save',
                    patientsSaved: [user.id],
                  },
                });
                const nameMatch =
                  (user.displayFirstName === '' &&
                    user.displayLastName === '') ||
                  (user.displayFirstName === user.firstName &&
                    user.displayLastName === user.lastName);
                trackEvent({
                  name: 'Add Patient Completed',
                  properties: {
                    differentNameCheckboxSelectionCount:
                      displayNameCheckedCount,
                    nameCheckbox: hasDisplayNameChecked,
                    nameMatch: [nameMatch],
                    numPatientsInvited: notifyUser ? 1 : 0,
                    ...(notifyUser && { patientsInvited: [user.id] }),
                  },
                });
              }}
            />
          </React.Fragment>
        )}
        {currentPage === AddPatientModalPage.EXISTING_INSURANCE && (
          <PatientInsuranceReview
            patient={patient!}
            onUpdateInsurance={() =>
              setCurrentPage(AddPatientModalPage.PAYMENT_DETAIL)
            }
            onComplete={() => {
              onBillingDetailsComplete();
            }}
          />
        )}
        {currentPage === AddPatientModalPage.VERIFY_INSURANCE_MATCH && (
          <InsuranceVerifyMatch
            patient={patient!}
            onEdit={() => setCurrentPage(AddPatientModalPage.PAYMENT_DETAIL)}
            onSuccess={onBillingDetailsComplete}
          />
        )}
        {currentPage === AddPatientModalPage.PAYMENT_DETAIL && (
          <PatientBillingForm
            showPatientAddedAlert
            patient={patient!}
            provider={provider}
            providerPatient={providerPatient!}
            startFormOnSelfPay={didAttemptSelfPayOptIn}
            openSelfPayProviderOptInModal={() => {
              setIsSelfPayProviderOptInModalOpen(true);
              setDidAttemptSelfPayOptIn(true);
            }}
            showAuthorizationInstructionsModal={(carrier) => {
              setCarrierToShowAuthzInstructionsFor(carrier);
              setCurrentPage(
                AddPatientModalPage.INSURANCE_AUTHORIZATION_INSTRUCTIONS
              );
            }}
            onUpdateSuccess={async ({
              userRead: updatedUser,
              eligibilityLookup,
              userInsurance,
            }) => {
              if (updatedUser.activeUserInsuranceId) {
                try {
                  if (
                    newHumanInputErrorExperienceEnabled &&
                    isFuzzyMatchedLookup(eligibilityLookup, userInsurance)
                  ) {
                    setPatient(updatedUser);
                    setCurrentPage(AddPatientModalPage.VERIFY_INSURANCE_MATCH);
                    return;
                  }

                  // TODO(michael): paused lookups
                  const readiness = await UserApi.getClaimReadiness(
                    updatedUser.id
                  );
                  if (
                    readiness.requirements?.includes(
                      UserClaimReadinessCheck.PATIENT_ADDRESS
                    )
                  ) {
                    setCurrentPage(AddPatientModalPage.PATIENT_ADDRESS);
                  } else {
                    onBillingDetailsComplete();
                  }
                } catch (e) {
                  logException(e);
                  onBillingDetailsComplete();
                }
              } else {
                onBillingDetailsComplete();
              }

              setDidAttemptSelfPayOptIn(false);
            }}
            onCancel={() => {
              onBillingDetailsComplete();
            }}
          />
        )}
        {currentPage ===
          AddPatientModalPage.INSURANCE_AUTHORIZATION_INSTRUCTIONS &&
          !!carrierToShowAuthzInstructionsFor && (
            <div className="hlx-modal hlx-modal-root">
              <InsuranceAuthorizationInstructionsModalContent
                carrier={carrierToShowAuthzInstructionsFor}
                clientDisplayName={patientName}
                closeModal={closeAndClearState}
              />
            </div>
          )}
        {currentPage === AddPatientModalPage.PATIENT_ADDRESS && (
          <section>
            <h4 css={{ marginTop: 0 }}>Address</h4>
            <p>
              We ask for a home address because the insurance company requires
              residence verification for eligibility and claims. We will not use
              this information otherwise, nor will we share it with anyone
              besides the insurance and provider.
            </p>

            <PatientAddressForm
              user={patient!}
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_ID as string}
              onSuccess={(address, user) => {
                queryClient.invalidateQueries([
                  'patient-address',
                  user.activePatientAddressId,
                ]);
                setPatient(user);
                onBillingDetailsComplete();
              }}
            />
          </section>
        )}
        {currentPage === AddPatientModalPage.SEND_ACCOUNT_INVITE && patient && (
          <PatientInvitationForm
            onComplete={() => {
              onComplete(patient!);
              closeAndClearState();
            }}
            providerPatientId={providerPatient!.id}
            patient={patient}
          />
        )}
      </Modal>
      <Modal
        title="Welcome to Private Pay"
        open={isSelfPayProviderOptInModalOpen}
        onClose={() => {
          setIsSelfPayProviderOptInModalOpen(false);
        }}
      >
        <SelfPayOptInForm
          provider={provider}
          onSubmit={() => {
            setIsSelfPayProviderOptInModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

enum SendAccountInvitationOption {
  SEND = 'send',
  SKIP = 'skip',
}

interface PatientInvitationFormProps {
  patient: UserRead;
  providerPatientId: number;
  onComplete: (choice: SendAccountInvitationOption) => void;
}

function PatientInvitationForm(props: PatientInvitationFormProps) {
  const firstName = formatPatientName(props.patient, {
    firstNameOnly: true,
  });

  const appointmentReadinessQuery = useQuery(
    ['appointment-readiness', props.patient.id],
    () => UserApi.getUserAppointmentReadiness(props.patient.id),
    {
      retry: false,
    }
  );

  const sendMutation = useMutation(
    async () => {
      await ProviderPatientApi.sendProviderPatientAccountInvite(
        props.providerPatientId
      );
    },
    {
      onSuccess: async () => {
        await UserApi.updateUser(props.patient.id, { isInvited: true });
      },
    }
  );

  const emailPreviewQuery = useQuery({
    queryKey: [
      'provider-patient-account-invite-email-preview',
      props.providerPatientId,
    ],
    queryFn: async () => {
      return ProviderPatientApi.getPatientInvitationEmailPreview(
        props.providerPatientId
      );
    },
    onError: (e) => {
      logException(e);
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  React.useEffect(() => {
    trackPageView({
      name: 'Individual New Client Invite Email Modal Viewed',
    });
  }, []);

  return (
    <div>
      <div>
        <BodyText>
          Before you can start accepting {firstName}’s insurance, they need to
          set up their Headway account to complete required tasks:
        </BodyText>
      </div>
      <div>
        <ul
          css={{
            paddingLeft: theme.spacing.x6,
          }}
        >
          <li>
            Agree to our required{' '}
            <Link
              href={`${process.env.REACT_APP_MAIN_URL}/legal/privacy-practices`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              href={`${process.env.REACT_APP_MAIN_URL}/legal/responsibility`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Consent Forms
            </Link>
          </li>
          <li>Add billing and insurance information</li>
        </ul>
      </div>
      <Formik
        initialValues={{
          sendAccountInvitation: SendAccountInvitationOption.SEND,
        }}
        onSubmit={async (values) => {
          const isAppointmentReady = appointmentReadinessQuery.data?.isReady;

          if (
            values.sendAccountInvitation === SendAccountInvitationOption.SEND
          ) {
            try {
              await sendMutation.mutateAsync();

              const message = !isAppointmentReady
                ? `${firstName} has been invited to set up their account`
                : `${firstName} has been added as a client`;

              toasts.add(message);
            } catch (e) {
              toasts.add(`Failed to send invitation to ${firstName}`, {
                variant: 'negative',
              });
              logException(e);
            }
          } else {
            const message = !isAppointmentReady
              ? `${firstName} has been added as a client. Some additional setup tasks are required.`
              : `${firstName} has been added as a client`;
            toasts.add(message);
          }

          trackEvent({
            name: 'Individual New Client Invite Email Done Button Clicked',
            properties: {
              sendEmailFlag:
                values.sendAccountInvitation ===
                SendAccountInvitationOption.SEND,
            },
          });

          props.onComplete(values.sendAccountInvitation);
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <FormControl
                name="sendAccountInvitation"
                component={RadioGroup}
                label={`How would you like to remind ${firstName} to set up their account?`}
              >
                <Radio
                  value={SendAccountInvitationOption.SEND}
                  description="Recommended for the fastest setup"
                >
                  Send client a personalized Headway setup invitation
                  {!emailPreviewQuery.isError ? ' (see preview below)' : ''}
                </Radio>
                <Radio
                  value={SendAccountInvitationOption.SKIP}
                  description={`You’ll need to tell ${firstName} to complete the required tasks on Headway using your own form of communication`}
                >
                  Don’t send client a setup invitation
                </Radio>
              </FormControl>
              {values.sendAccountInvitation ===
                SendAccountInvitationOption.SEND &&
                !emailPreviewQuery.isError && (
                  <section
                    css={{
                      backgroundColor: theme.color.system.backgroundGray,
                      padding: theme.spacing.x6,
                      ...theme.reset,
                    }}
                  >
                    <h3>
                      <BodyText>
                        <strong>Preview of invitation:</strong>
                      </BodyText>
                    </h3>
                    <div>
                      <BodyText>
                        If your client doesn’t receive the email, please ask
                        them to check their spam or promotions folder:
                      </BodyText>
                    </div>
                    {emailPreviewQuery.isSuccess ? (
                      <div
                        css={{ marginTop: theme.spacing.x3 }}
                        dangerouslySetInnerHTML={{
                          __html: sanitize(emailPreviewQuery.data?.html || ''),
                        }}
                      />
                    ) : emailPreviewQuery.isLoading ? (
                      <div
                        css={{
                          display: 'grid',
                          placeItems: 'center',
                          color: theme.color.system.textBlack,
                          padding: theme.spacing.x6,
                          '& > *': {
                            animation: `${rotate} 2s infinite linear`,
                          },
                        }}
                      >
                        <Loading />
                      </div>
                    ) : null}
                  </section>
                )}
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'end',
                  borderTop: `1px solid ${theme.color.system.borderGray}`,
                  margin: `0 -${theme.spacing.x6}`,
                  padding: theme.spacing.x5,
                  paddingBottom: 0,
                }}
              >
                <Button
                  size="large"
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending...' : 'Done'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
