import { AriaSwitchProps } from '@react-types/switch';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { mergeProps, useSwitch, VisuallyHidden } from 'react-aria';
import { useToggleState } from 'react-stately';
import { Simplify } from 'type-fest';

import { DATA } from './consts';
import { FormInputProps, useFormInput } from './useFormInput';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type SwitchProps = Simplify<
  {
    labelPosition?: 'left' | 'right';
  } & Omit<FormInputProps<string, boolean>, 'label'> &
    PickAndConfigure<
      AriaSwitchProps,
      | { children: 'children' }
      | { selected?: 'isSelected' }
      | { 'aria-describedby'?: 'aria-describedby' }
    >
>;

export const Switch = ({
  labelPosition = 'left',
  selected,
  ...props
}: SwitchProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const {
    ariaProps,
    rootProps,
    hoverProps,
    focusProps,
    isHovered,
    isFocusVisible,
  } = useFormInput({ isSelected: selected, ...props });

  const toggleState = useToggleState(ariaProps);
  const { inputProps } = useSwitch(ariaProps, toggleState, ref);

  const Icon = toggleState.isSelected ? SwitchOnIcon : SwitchOffIcon;

  return (
    <label
      className="hlx-switch-root"
      style={{
        flexDirection: labelPosition === 'left' ? 'row' : 'row-reverse',
      }}
      {...mergeProps(rootProps, hoverProps, {
        [DATA.HOVERED]: !ariaProps.isReadOnly && isHovered,
        [DATA.DISABLED]: ariaProps.isDisabled,
        'data-hlx-selected': toggleState.isSelected,
        [DATA.VALIDATION]: props.validation?.validity,
      })}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      {props.children}
      <Icon
        className={clsx('hlx-switch-icon', {
          'focus-ring': isFocusVisible,
        })}
        aria-hidden="true"
      />
    </label>
  );
};

const SwitchOnIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="20"
      fill="none"
      {...props}
    >
      <rect width="36" height="20" fill="var(--track-color)" rx="10" />
      <circle cx="26" cy="10" r="6" fill="var(--handle-color)" />
    </svg>
  );
};

const SwitchOffIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="20"
      fill="none"
      {...props}
    >
      <rect
        width="35"
        height="19"
        x="0.5"
        y="0.5"
        stroke="transparent"
        fill="var(--track-color)"
        rx="9.5"
      />
      <circle cx="10" cy="10" r="6" fill="var(--handle-color)" />
    </svg>
  );
};
