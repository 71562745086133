import { mergeProps, useSlotId } from '@react-aria/utils';
import React from 'react';
import { useCheckboxGroup } from 'react-aria';
import { CheckboxGroupState, useCheckboxGroupState } from 'react-stately';

import { DATA } from './consts';
import { theme } from './theme';
import { FormInputProps, useFormInput } from './useFormInput';

export const CheckboxGroupContext =
  React.createContext<CheckboxGroupState | null>(null);

type CheckboxGroupProps = {
  children: React.ReactNode;
  optionalityText?: React.ReactNode;
  size?: 'small' | 'large';
} & FormInputProps<string[]>;

export const CheckboxGroup = ({
  size = 'small',
  ...props
}: CheckboxGroupProps) => {
  const { ariaProps, rootProps } = useFormInput(props);
  const cbgState = useCheckboxGroupState(ariaProps);
  const { groupProps, labelProps } = useCheckboxGroup(ariaProps, cbgState);

  const descriptionId = useSlotId([Boolean(props.helpText), props.validation]);
  const errorMessageId = useSlotId([Boolean(props.helpText), props.validation]);
  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  return (
    <div
      className="hlx-checkbox-group-root"
      {...mergeProps(groupProps, rootProps, {
        'aria-describedby':
          [
            descriptionId,
            errorMessageId,
            optionalityId,
            props['aria-describedby'],
          ]
            .filter(Boolean)
            .join(' ') || undefined,
        [DATA.DISABLED]: props.disabled,
        'data-hlx-size': size,
      })}
    >
      <div className="hlx-checkbox-group-descriptors">
        <span className="hlx-checkbox-group-label" {...labelProps}>
          {props.label}
        </span>
        {props.optionalityText && (
          <span
            id={optionalityId}
            className="hlx-checkbox-group-optionality-text"
          >
            {props.optionalityText}
          </span>
        )}
      </div>
      <div className="hlx-checkbox-group-controls">
        <CheckboxGroupContext.Provider value={cbgState}>
          {React.Children.toArray(props.children).map((child) => {
            return React.cloneElement(child as React.ReactElement, {
              validation: props.validation,
            });
          })}
        </CheckboxGroupContext.Provider>
      </div>

      {props.helpText && (
        <div className="hlx-checkbox-group-help-text" id={descriptionId}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-checkbox-group-error" id={errorMessageId}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
};
