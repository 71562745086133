import { UserApi } from '@headway/api/resources/UserApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseUserQueryKeyArgs {
  userId: number;
}
export const getUseUserQueryKey = ({
  userId,
}: UseUserQueryKeyArgs): [string, number] => ['user', userId];

const {
  useSingleQuery: useUser,
  useListQuery: useUserList,
  useCachedQuery: useUserCache,
} = createBasicApiHooks(
  getUseUserQueryKey,
  ({ userId }) => UserApi.getUser(userId),
  ({ userId }) => !Number.isNaN(userId),
  () => 'Failed to fetch user by ID'
);
export { useUser, useUserList, useUserCache };
