import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { BillingType } from '@headway/api/models/BillingType';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { TransactionAppointmentInfo } from '@headway/api/models/TransactionAppointmentInfo';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import statesToDisplayNames, {
  abbreviationToStateEnum,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { formatBillingType } from '@headway/shared/utils/appointments';

export const getAdditionalTransactionAppointmentInfoFromAppointment = (
  event: ProviderEventRead
) => {
  const isTelehealth =
    event.providerAppointment && 'telehealth' in event.providerAppointment
      ? event.providerAppointment.telehealth
      : event.telehealth;

  return {
    frontEndCarrier:
      event.providerAppointment?.patient?.activeUserInsurance
        ?.frontEndCarrierName,
    appointmentStateLocation: isTelehealth
      ? event.providerAppointment?.appointmentLocationPatientAddress?.state
      : event.providerAppointment?.address
      ? event.providerAppointment.address.state
      : event.address?.state,
    isTelehealth,
  };
};

const createInsuranceAppointmentInfo = (
  frontEndCarrier: string | undefined,
  isTelehealth: boolean,
  patientName: string,
  appointmentDate: string,
  patientUserId: number,
  appointmentLocationState: string | undefined
) => {
  let appointmentLocationString = isTelehealth ? 'telehealth' : 'in-person';

  if (appointmentLocationState) {
    let displayName = '';
    if (
      statesToDisplayNames[
        appointmentLocationState as keyof typeof UnitedStates
      ]
    ) {
      displayName =
        statesToDisplayNames[
          appointmentLocationState as keyof typeof UnitedStates
        ];
    } else if (abbreviationToStateEnum[appointmentLocationState]) {
      displayName =
        statesToDisplayNames[
          abbreviationToStateEnum[
            appointmentLocationState
          ] as keyof typeof UnitedStates
        ];
    }

    appointmentLocationString += ' in ' + displayName;
  }

  return (
    <>
      {frontEndCarrier ?? 'Insurance'} appointment ({appointmentLocationString})
      with <Link to={`/clients/${patientUserId}/clinical`}>{patientName}</Link>{' '}
      on {appointmentDate}
    </>
  );
};

export const FormattedAppointmentInfo: React.FC<
  React.PropsWithChildren<
    TransactionAppointmentInfo & { isProviderIncentiveBonus: boolean }
  >
> = ({
  appointmentStartAt,
  billingType,
  patientName,
  patientUserId,
  status,
  frontEndCarrier,
  appointmentStateLocation,
  isTelehealth,
  isProviderIncentiveBonus,
}) => {
  const formattedDate = moment(appointmentStartAt).format('MM/DD');
  const isMSCEnabled = useFlag('multiStateCredentialingAlpha');

  if (isProviderIncentiveBonus) {
    return (
      <span>
        Rate boost for session with{' '}
        <Link to={`/clients/${patientUserId}/clinical`}>{patientName}</Link> on{' '}
        {formattedDate}
      </span>
    );
  } else if (status === ProviderAppointmentStatus.CANCELED) {
    return (
      <span>
        Cancellation fee from{' '}
        <Link to={`/clients/${patientUserId}/clinical`}>{patientName}</Link> for{' '}
        {formattedDate} appointment
      </span>
    );
  } else if (isMSCEnabled && billingType === BillingType.INSURANCE) {
    return createInsuranceAppointmentInfo(
      frontEndCarrier,
      isTelehealth,
      patientName,
      formattedDate,
      patientUserId,
      appointmentStateLocation
    );
  } else {
    return (
      <span>
        {formatBillingType(billingType)} appointment with{' '}
        <Link to={`/clients/${patientUserId}/clinical`}>{patientName}</Link> on{' '}
        {formattedDate}
      </span>
    );
  }
};
