import * as React from 'react';

export const DashboardOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.688 7.917V2.5H17.5v5.417h-6.813zM2.5 10.687V2.5h6.813v8.188H2.5zm8.188 6.813V9.312H17.5V17.5h-6.813zm-8.188 0v-5.416h6.813V17.5H2.5zm1.396-8.188h4.02V3.896h-4.02v5.417zm8.188 6.792h4.02v-5.416h-4.02v5.416zm0-9.583h4.02V3.896h-4.02v2.625zm-8.188 9.583h4.02v-2.625h-4.02v2.625z"
      fill="currentColor"
    />
  </svg>
);
