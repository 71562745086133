import { Provider } from 'mobx-react';
import React from 'react';

import { initializeAuthStore } from '../stores/AuthStore';

export class AuthProvider extends React.Component {
  authStore = initializeAuthStore();

  render() {
    return (
      <Provider AuthStore={this.authStore}>{this.props.children}</Provider>
    );
  }
}
