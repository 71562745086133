import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePaginatedUnreadAssessmentsPerPatientQueryKeyArgs {
  providerId: number | undefined;
  limit?: number;
  offset?: number;
  completedOnDateRangeStart?: string;
}

export const getUsePaginatedUnreadAssessmentsPerPatientQueryKey = ({
  providerId,
  limit,
  offset,
  completedOnDateRangeStart,
}: UsePaginatedUnreadAssessmentsPerPatientQueryKeyArgs): [
  'paginated-unread-assessments-per-patient',
  number | undefined,
  {
    limit: number | undefined;
    offset: number | undefined;
    completedOnDateRangeStart: string | undefined;
  },
] => [
  'paginated-unread-assessments-per-patient',
  providerId,
  { limit, offset, completedOnDateRangeStart },
];

const {
  useSingleQuery: usePaginatedUnreadAssessmentsPerPatient,
  useListQuery: usePaginatedUnreadAssessmentsPerPatientList,
  useCachedQuery: usePaginatedUnreadAssessmentsPerPatientCache,
} = createBasicApiHooks(
  getUsePaginatedUnreadAssessmentsPerPatientQueryKey,
  ({ providerId, ...query }) =>
    PatientAssessmentApi.getPaginatedUnreadPerPatient(providerId!, {
      limit: query.limit,
      offset: query.offset,
      completed_on_date_range_start: query.completedOnDateRangeStart,
    }),
  ({ providerId }) => providerId != null,
  () => 'Failed to fetch unread assessments'
);

export {
  usePaginatedUnreadAssessmentsPerPatient,
  usePaginatedUnreadAssessmentsPerPatientList,
  usePaginatedUnreadAssessmentsPerPatientCache,
};
