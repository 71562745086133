/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum PriceChangeReason {
    COPAY_VALUE_INCREASED = 'COPAY_VALUE_INCREASED',
    COPAY_VALUE_DECREASED = 'COPAY_VALUE_DECREASED',
    COINSURANCE_VALUE_INCREASED = 'COINSURANCE_VALUE_INCREASED',
    COINSURANCE_VALUE_DECREASED = 'COINSURANCE_VALUE_DECREASED',
    INDIVIDUAL_DEDUCTIBLE_RESET = 'INDIVIDUAL_DEDUCTIBLE_RESET',
    FAMILY_DEDUCTIBLE_RESET = 'FAMILY_DEDUCTIBLE_RESET',
    INDIVIDUAL_OUT_OF_POCKET_RESET = 'INDIVIDUAL_OUT_OF_POCKET_RESET',
    FAMILY_OUT_OF_POCKET_RESET = 'FAMILY_OUT_OF_POCKET_RESET',
    DEDUCTIBLE_MET = 'DEDUCTIBLE_MET',
    DEDUCTIBLE_MET_WITH_NEXT_SESSION = 'DEDUCTIBLE_MET_WITH_NEXT_SESSION',
    COPAY_TO_COINSURANCE = 'COPAY_TO_COINSURANCE',
    COINSURANCE_TO_COPAY = 'COINSURANCE_TO_COPAY',
    GAINED_COPAY = 'GAINED_COPAY',
    GAINED_COINSURANCE = 'GAINED_COINSURANCE',
    LOST_COPAY = 'LOST_COPAY',
    LOST_COINSURANCE = 'LOST_COINSURANCE'
}
