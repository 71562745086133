import type { Node } from '@react-types/shared';
import React from 'react';
import {
  AriaListBoxOptions,
  FocusRing,
  useListBox,
  useListBoxSection,
  useOption,
  useSeparator,
} from 'react-aria';
import { ListState } from 'react-stately';

import { CheckedIcon, UncheckedIcon } from '../Checkbox';
import { IconCheck } from '../icons/Check';
import { useIsMobileDevice } from '../utils';

interface ListBoxProps<T> extends AriaListBoxOptions<T> {
  listBoxRef?: React.RefObject<HTMLUListElement>;
  state: ListState<T>;
}

export function ListBox<T>(props: ListBoxProps<T>) {
  let ref = React.useRef<HTMLUListElement>(null);
  let { listBoxRef = ref, state } = props;
  let { listBoxProps } = useListBox(
    { ...props, isVirtualized: false },
    state,
    listBoxRef
  );

  const items = [...state.collection];

  return (
    <ul {...listBoxProps} ref={listBoxRef} className="hlx-listbox">
      {items.length > 0 ? (
        items.map((item) => {
          if (item.type === 'section') {
            return (
              <ListBoxSection key={item.key} section={item} state={state}>
                {/* @ts-expect-error */}
                {item.childNodes}
              </ListBoxSection>
            );
          }

          return <Option key={item.key} item={item} state={state} />;
        })
      ) : (
        <li className="hlx-listbox-option no-results">No results</li>
      )}
    </ul>
  );
}

interface ListBoxSectionProps<T> {
  children?: React.ReactNode;
  section: Node<T>;
  state: ListState<T>;
}

export function ListBoxSection<T>(props: ListBoxSectionProps<T>) {
  const { section, state } = props;
  let { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  let { separatorProps } = useSeparator({
    elementType: 'li',
  });

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className="hlx-listbox-separator" />
      )}
      <li {...itemProps} className="hlx-listbox-section">
        {section.rendered && (
          <div {...headingProps} className="hlx-listbox-section-heading">
            {section.rendered}
          </div>
        )}
        <ul {...groupProps}>
          {[...section.childNodes].map((node) => (
            <Option key={node.key} item={node} state={state} />
          ))}
        </ul>
      </li>
    </>
  );
}

interface OptionProps<T> {
  item: Node<T>;
  state: ListState<T>;
}
function Option<T>({ item, state }: OptionProps<T>) {
  let ref = React.useRef<HTMLLIElement>(null);
  let { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  );

  const isMobile = useIsMobileDevice();

  const iconSize = isMobile ? 24 : 20;
  const iconProps = {
    'aria-hidden': true,
    width: iconSize,
    height: iconSize,
  };

  let check;

  if (isMobile && state.selectionManager.selectionMode === 'multiple') {
    check = isSelected ? (
      <CheckedIcon {...iconProps} />
    ) : (
      <UncheckedIcon {...iconProps} />
    );
  } else {
    check = isSelected ? <IconCheck {...iconProps} /> : null;
  }

  return (
    <FocusRing within focusRingClass="focus-ring">
      <li
        className="hlx-listbox-option"
        {...optionProps}
        ref={ref}
        data-selected={isSelected}
        data-focused={isFocused}
        data-disabled={isDisabled}
        data-selection-mode={state.selectionManager.selectionMode}
      >
        {check}
        {item.rendered}
      </li>
    </FocusRing>
  );
}
