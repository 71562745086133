import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Collapse, Typography } from '@mui/material';
import React from 'react';

import { Button } from '@headway/helix/Button';
import { theme as legacyTheme } from '@headway/ui/theme';

import { Tooltip } from '../Tooltip';
import {
  AddressCard,
  AddressCardContainer,
  AddressCardContent,
} from './AddressCard';
import { AddressList } from './AddressList';

const AddAddressButton = ({ toggleIsModalOpen }) => {
  return (
    <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        variant="link"
        size="medium"
        onPress={() => toggleIsModalOpen('Add')}
      >
        Add address
      </Button>
    </div>
  );
};

const InactiveAddressToggle = ({ inactiveOpen, toggleInactiveCollapse }) => {
  return (
    <div
      css={{
        display: 'flex',
        marginLeft: 'auto',
        marginBottom: legacyTheme.space.xl,
        marginTop: legacyTheme.space.base,
        [legacyTheme.media.small]: {
          marginBottom: legacyTheme.space.base,
        },
      }}
    >
      <Button
        variant="link"
        size="medium"
        onPress={() => {
          return toggleInactiveCollapse();
        }}
      >
        {inactiveOpen ? 'Hide' : 'Show'} Archived Offices
      </Button>
    </div>
  );
};

export const AddressLists = ({
  addresses,
  inactiveOpen,
  toggleAddressArchived,
  toggleIsModalOpen,
  toggleInactiveCollapse,
}) => {
  const activeAddresses = addresses.filter((address) => address.isActive);
  const inactiveAddresses = addresses.filter((address) => !address.isActive);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <AddressList title="Active">
        {activeAddresses.length ? (
          activeAddresses.map((address) => (
            <AddressCard
              key={address.id}
              toggleAddressArchived={toggleAddressArchived}
              toggleIsModalOpen={toggleIsModalOpen}
              address={address}
            />
          ))
        ) : (
          <AddressCardContainer>
            <AddressCardContent>
              <Typography
                color="textSecondary"
                css={{
                  paddingLeft: legacyTheme.space.xs,
                  paddingTop: legacyTheme.space.base,
                  paddingBottom: legacyTheme.space.base,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Virtual Visits Only{' '}
                <Tooltip
                  title={
                    "You're only set up for virtual appointments. Add a practice location address to start seeing patients in person."
                  }
                >
                  <HelpOutlineIcon
                    sx={{
                      stroke: legacyTheme.highContrastColors.black,
                      strokeWidth: 0.5,
                    }}
                    css={{
                      fontSize: '1rem',
                      marginLeft: legacyTheme.space.xs2,
                    }}
                  />
                </Tooltip>
              </Typography>
            </AddressCardContent>
          </AddressCardContainer>
        )}
      </AddressList>
      <AddAddressButton toggleIsModalOpen={toggleIsModalOpen} />
      {inactiveAddresses.length ? (
        <InactiveAddressToggle
          inactiveOpen={inactiveOpen}
          toggleInactiveCollapse={toggleInactiveCollapse}
        />
      ) : null}
      <Collapse in={inactiveOpen}>
        {inactiveAddresses.map((address) => (
          <AddressCard
            key={address.id}
            toggleAddressArchived={toggleAddressArchived}
            toggleIsModalOpen={toggleIsModalOpen}
            address={address}
          />
        ))}
      </Collapse>
    </div>
  );
};
