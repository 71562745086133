import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAssessmentQueryKeyArgs {
  assessmentId: number;
}

export const getUsePatientAssessmentQueryKey = ({
  assessmentId,
}: UsePatientAssessmentQueryKeyArgs) =>
  ['patient-assessment', assessmentId] as const;

const {
  useSingleQuery: usePatientAssessment,
  useListQuery: usePatientAssessmentList,
  useCachedQuery: usePatientAssessmentCache,
} = createBasicApiHooks(
  getUsePatientAssessmentQueryKey,
  ({ assessmentId }) => PatientAssessmentApi.getPatientAssessment(assessmentId),
  ({ assessmentId }) => assessmentId != null,
  () => 'Failed to fetch patient assessment'
);

export {
  usePatientAssessment,
  usePatientAssessmentList,
  usePatientAssessmentCache,
};
