import { ModalProps } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SmsOptOutType as SmsReminderType } from '@headway/api/models/SmsOptOutType';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { Modal } from '@headway/ui';
import { Button } from '@headway/ui';
import { FieldCheckbox } from '@headway/ui/form/FieldCheckbox';
import { FieldControl } from '@headway/ui/form/FieldControl';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

type SmsAgreementModalProps = ModalProps & {
  open: boolean;
  onClose: () => void;
  onError: () => void;
  AuthStore: { provider: ProviderRead };
};

const validationSchema = Yup.object().shape({
  smsOptInTypes: Yup.array().of(Yup.string()),
});

export const SmsAgreementModal: React.FC<SmsAgreementModalProps> = ({
  open,
  onClose,
  onError,
  AuthStore,
}) => {
  const smsReminderTypes = [
    SmsReminderType.CAL_DIGEST,
    SmsReminderType.NEW_SESSION,
    SmsReminderType.CANCELED_SESSION,
  ];
  const initialValues = {
    smsOptInTypes: smsReminderTypes,
  };

  const handleOnSubmit = async (values: typeof initialValues) => {
    // opt out types = difference between smsReminderTypes and form values
    let smsOptOutTypes = smsReminderTypes.filter(
      (reminderType) => !values.smsOptInTypes.includes(reminderType)
    );
    // if length of opt out types array equals length of reminder types array,
    // then the provider has opted out of all reminder types
    if (smsOptOutTypes.length === smsReminderTypes.length) {
      smsOptOutTypes = [SmsReminderType.ALL];
    }
    const providerUpdate = {
      smsOptOutTypes,
      smsAgreementAcceptanceDate: new Date().toISOString(),
    };

    try {
      await ProviderApi.updateProvider(AuthStore.provider.id, providerUpdate);
      // re-set the value in the AuthStore
      AuthStore.provider = { ...AuthStore.provider, ...providerUpdate };
      onClose();
    } catch (e: AnyTS4TryCatchUnknownError) {
      onError(e.toString());
    }
  };

  return (
    <Modal open={open} closeable={false} title="Get important updates via text">
      {!AuthStore.provider.smsPhone ? (
        <div>
          Get texted about new referrals, bookings, and cancellations, and prep
          for the week ahead with schedule overviews. Set up this feature and
          change your notification preferences anytime in Settings.
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: theme.space.base,
            }}
          >
            <Button
              color="error"
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: theme.space.xs }}
            >
              Decline
            </Button>
            <Button
              component={Link}
              to="/settings/account"
              type="submit"
              color="primary"
              variant="contained"
              onClick={onClose}
            >
              Set up in Settings
            </Button>
          </div>
        </div>
      ) : (
        <Formik
          onSubmit={handleOnSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <SafeFormikForm>
              <div css={{ paddingBottom: theme.space.base }}>
                <p>
                  Select which updates you'd like texted to{' '}
                  {AuthStore.provider.smsPhone}. You can always change your
                  preferences and mobile number in{' '}
                  <Link to="/settings/account" onClick={onClose}>
                    Settings
                  </Link>
                  .
                </p>
                <FieldControl
                  css={{ margin: `${theme.space.xl} ${theme.space.sm}` }}
                  name="smsOptInTypes"
                >
                  <FieldCheckbox
                    value={SmsReminderType.CAL_DIGEST}
                    label="Overview of upcoming sessions and openings (weekly on Sundays)"
                  />
                  <FieldCheckbox
                    value={SmsReminderType.CANCELED_SESSION}
                    label="Cancellations"
                  />
                  <FieldCheckbox
                    value={SmsReminderType.NEW_SESSION}
                    label="New referrals and bookings"
                  />
                </FieldControl>
                <p>
                  By clicking Receive text notifications, I authorize Headway to
                  send text messages to the mobile number on file. Message and
                  data rates may apply.{' '}
                  <Link to="/legal/notifications" target="_blank">
                    View full contract addendum.
                  </Link>
                </p>
              </div>
              <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={onClose}
                  css={{ marginRight: theme.space.xs }}
                >
                  Decline
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  Receive text notifications
                </Button>
              </div>
            </SafeFormikForm>
          )}
        </Formik>
      )}
    </Modal>
  );
};
