import { css } from '@emotion/react';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { LinkButton } from '@headway/helix/LinkButton';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import { unitedStatesAbbreviations } from '@headway/shared/constants/unitedStatesDisplayNames';

interface AddressCardProps {
  patientAddress: PatientAddressRead;
  onDelete?: (id: number) => void;
  deletable?: boolean;
  withBorder?: boolean;
  withIcon?: boolean;
  variant?: 'vertical' | 'horizontal';
  subText?: string;
}

export const AddressCard = ({
  patientAddress,
  onDelete,
  subText,
  deletable = true,
  withBorder = false,
  withIcon = true,
  variant = 'horizontal',
}: AddressCardProps) => (
  <div key={patientAddress.id} css={containerCss({ withBorder })}>
    <div css={iconLayoutCss}>
      {withIcon && (
        <div>
          <LocationOnOutlined
            css={{
              width: theme.spacing.x4,
              height: theme.spacing.x4,
              marginTop: 2,
            }}
          />
        </div>
      )}
      <div css={variantCss({ vertical: variant === 'vertical' })}>
        <div>
          <BodyText>
            <span>
              {patientAddress.addressType
                ? addressTypeDisplayNames[
                    patientAddress.addressType as PatientAddressType
                  ]
                : addressTypeDisplayNames[PatientAddressType.OTHER]}
            </span>
          </BodyText>
          <div>
            <BodyText>{patientAddress.streetLine1}</BodyText>
          </div>
          {patientAddress.streetLine2 && (
            <div>
              <BodyText>{patientAddress.streetLine2}</BodyText>
            </div>
          )}
          <div>
            <BodyText>
              {patientAddress.city},{' '}
              {unitedStatesAbbreviations[UnitedStates[patientAddress.state]]}{' '}
              {patientAddress.zipCode}
            </BodyText>
          </div>
          {subText && (
            <div>
              <SubBodyText color="gray">{subText}</SubBodyText>
            </div>
          )}
        </div>
        {deletable && (
          <div>
            <LinkButton
              variant="link"
              onPress={() => {
                if (onDelete) onDelete(patientAddress.id);
              }}
            >
              Remove
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  </div>
);

const containerCss = ({ withBorder }: { withBorder: boolean }) => css`
  display: flex;
  flex-direction: 'row';
  justify-content: space-between;
  padding: 0;
  ${withBorder &&
  `
    padding: ${theme.spacing.x2};
    border: 1px solid ${theme.color.system.borderGray};
    border-radius: 6px;
  `}
`;

const iconLayoutCss = css`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1};
  flex-grow: 1;
`;

const variantCss = ({ vertical }: { vertical: boolean }) => css`
  display: flex;
  flex-direction: ${vertical ? 'column' : 'row'};
  justify-content: space-between;
  flex-grow: 1;
`;
