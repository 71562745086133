import { useProvider } from 'hooks';
import React, { useEffect } from 'react';

import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { EinField } from '@headway/helix/MaskedTextField';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { SELF_PAY_FEES } from '@headway/shared/constants/selfPayFees';
import { trackEvent } from '@headway/shared/utils/analytics';

export const EnableSelfPayForm = ({
  errors,
  isSubmitting,
}: {
  errors?: any;
  isSubmitting?: boolean;
}) => {
  const provider = useProvider();
  const selfPayFlatRate = SELF_PAY_FEES.flat;

  useEffect(() => {
    if (errors.acceptedTerms && isSubmitting) {
      const element = document.querySelector('[aria-label="acceptedTerms"]');
      element?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [errors.acceptedTerms, isSubmitting]);

  useEffect(() => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Add billing information',
        stepName: 'SELF_PAY_ENABLEMENT',
      },
    });
  }, []);

  return (
    <>
      <div css={{ marginTop: theme.spacing.x6 }}>
        <strong>Enable private pay</strong>
        <br />
        Bring your private pay clients to Headway and keep your practice in one
        place. Simply set your rate and schedule sessions, and we’ll include
        these payments with your existing Headway payouts.
      </div>
      <div css={{ marginTop: theme.spacing.x6 }}>
        <strong>Here's how it works:</strong>
        <ul>
          <li>
            Set your own private pay rate for clients you’re seeing
            out-of-network. You can use your existing cancellation policy, too.
          </li>
          <li>
            Schedule and confirm these sessions directly in your Headway
            calendar.
          </li>
          <li>
            Receive payment with the rest of your bi-weekly Headway payouts.
          </li>
        </ul>
      </div>
      <div css={{ marginTop: theme.spacing.x6 }}>
        <strong>Terms and conditions:</strong>
        <br />
        Headway’s platform has a feature that allows providers to see private
        pay patients who do not have insurance accepted by Headway. By accepting
        the following terms and conditions, you will amend your agreement with
        the Practice and be provided access to this feature:
      </div>
      <div
        css={{
          marginBottom: theme.spacing.x8,
        }}
      >
        <ul css={{ marginTop: theme.spacing.x6 }}>
          <li>
            PROVIDER is able to negotiate and set private pay rates for patients
            without insurance (the “Private Pay Rate”). PROVIDER understands
            that the Practice will pass along to PROVIDER certain of the costs
            associated with seeing private pay patients, including (i) a Stripe
            percentage fee that is variable to whether ACH (currently 0.8% of
            the Private Pay Rate) or credit card (currently 2.3% of the Private
            Pay Rate) payments are made, and (ii) a flat rate of $
            {selfPayFlatRate?.toFixed(2)}, which is dependent on geographic
            location.
            <ul
              css={{
                marginTop: theme.spacing.x2,
                marginBottom: theme.spacing.x2,
              }}
            >
              <li>
                By way of example, if PROVIDER negotiates a Private Pay Rate of
                $100, Practice will charge the patient $100. If the patient pays
                $100 by credit card and the flat rate for PROVIDER’s geographic
                location is set at $2.00, the Practice would pay the PROVIDER
                $95.70. ($100 - (($100 * 0.023) + $2.00) = $95.70).
              </li>
            </ul>
          </li>
          <li>
            The following requirements apply to your use of Private Pay Rates.
            Failure to observe any of these requirements will result in
            non-payment: (a) Private Pay Rates are only available for patients
            who do not have insurance that can be used to see the PROVIDER
            through the Practice; (b) Private pay patients must pay the Private
            Pay Rate in order for the PROVIDER to receive compensation for
            seeing the private pay patient; (c) to charge a Private Pay Rate,
            PROVIDER must either be credentialed with the Practice or in process
            to be credentialed with the Practice; and (d) Private Pay Rates are
            only available to patients the PROVIDER has brought to the Practice;
            except that, a PROVIDER may charge a Private Pay Rate to a
            Practice-sourced patient if that patient is no longer covered by an
            insurance that the Practice accepts.
          </li>
          <li>
            In addition, PROVIDER and Practice agree that: (a) the Practice is
            not responsible for payment to the PROVIDER if a private pay patient
            does not complete payment; and (b) Private Pay patients will be able
            to download superbills from the Practice to submit to insurance
            companies that will contain PROVIDER’s EIN (if provided) and NPI
            (and not the Practice’s EIN or NPI).
          </li>
        </ul>
      </div>
      <FormControl
        component={EinField}
        label="Employer Identification Number (EIN)"
        name="taxId"
        optionalityText="Optional"
      />
      <SubBodyText>
        Headway uses this to create superbills. Do not include your Social
        Security number.
      </SubBodyText>
      <div
        css={{ marginTop: theme.spacing.x6, marginBottom: theme.spacing.x12 }}
      >
        <FormControl
          aria-label="acceptedTerms"
          component={Checkbox}
          name="acceptedTerms"
        >
          I, {provider?.firstName}, agree to the private pay terms and
          conditions
        </FormControl>
      </div>
    </>
  );
};
