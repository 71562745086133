import { FieldArray } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import '@headway/api/resources/ProviderApi';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_PHONE_ERROR_MESSAGE,
  YUP_PHONE_MATCH,
} from '@headway/shared/constants/format';
import { FieldControl } from '@headway/ui/form';
import { FieldDatePicker } from '@headway/ui/form';
import { FieldErrorText } from '@headway/ui/form';

import { states } from '../../../utils/states';
import {
  AddButton,
  FieldArraySectionItemContainer,
} from '../FieldArraySection';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { transformDateToNullOrStartOfDay } from '../utils/transformDateToNullOrStartOfDay';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const initialReference = {
  firstName: '',
  lastName: '',
  degree: '',
  contactMethod: '',
  email: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  fax: '',
  associationStartDate: '',
  associationEndDate: '',
};

const ProfessionalReferences = ({
  initialValues,
  formikHelpers,
}: CustomComponentProps) => {
  return (
    <>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
        }}
      >
        <FieldArray
          name="professionalReferences"
          render={(arrayHelpers) => (
            <>
              {formikHelpers.values.professionalReferences?.map(
                (reference: any, index: number) => (
                  <React.Fragment key={index}>
                    <FieldArraySectionItemContainer>
                      <div
                        css={{
                          ...theme.stack.horizontal,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <h2>
                            <SectionHeader>
                              Professional Reference {`#${index + 1}`}
                            </SectionHeader>
                          </h2>
                        </div>
                        {index > 0 ? (
                          <Button
                            onPress={() => arrayHelpers.remove(index)}
                            variant="secondary"
                            size="medium"
                          >
                            Remove Reference
                          </Button>
                        ) : null}
                      </div>
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].firstName`}
                        label="First Name"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].lastName`}
                        label="Last Name"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].degree`}
                        label="Degree"
                      />
                      <FormControl
                        component={Select}
                        name={`professionalReferences[${index}].contactMethod`}
                        selectionMode="single"
                        label="Preferred contact method"
                      >
                        <Item key="Email">Email</Item>
                        <Item key="Fax">Fax</Item>
                        <Item key="Mail">Mail</Item>
                      </FormControl>
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].street1`}
                        label="Address"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].street2`}
                        label="Apartment, suite, etc. (optional)"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].city`}
                        label="City"
                      />
                      <FormControl
                        component={Select}
                        name={`professionalReferences[${index}].state`}
                        selectionMode="single"
                        label="State"
                      >
                        {Object.keys(states).map((key) => (
                          <Item key={key}>
                            {(states as { [k: string]: string })[key]}
                          </Item>
                        ))}
                      </FormControl>
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].zip`}
                        label="ZIP code"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].email`}
                        label="Email"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].fax`}
                        label="Fax Number"
                      />
                      <FormControl
                        component={TextField}
                        name={`professionalReferences[${index}].phone`}
                        label="Phone Number"
                      />
                      <FieldControl
                        name={`professionalReferences[${index}].associationStartDate`}
                        fullWidth={true}
                        css={{ marginBottom: '0' }}
                      >
                        <FieldDatePicker
                          label="Association start date"
                          inputFormat="MM/DD/YYYY"
                          placeholder="MM/DD/YYYY"
                          variant="outlined"
                          size="small"
                          maxDate={moment()}
                        />
                        <FieldErrorText />
                      </FieldControl>
                      <FieldControl
                        name={`professionalReferences[${index}].associationEndDate`}
                        fullWidth={true}
                      >
                        <FieldDatePicker
                          label="Association end date (optional)"
                          inputFormat="MM/DD/YYYY"
                          placeholder="MM/DD/YYYY"
                          variant="outlined"
                          size="small"
                        />
                        <FieldErrorText />
                      </FieldControl>
                    </FieldArraySectionItemContainer>
                  </React.Fragment>
                )
              )}
              <AddButton
                buttonText="Add another professional reference"
                onPress={() => arrayHelpers.push(initialReference)}
              />
            </>
          )}
        />
        <FormControl component={Checkbox} name="confirmReferencesCorrect">
          I confirm that the references I listed above adhere to the
          instructions outlined at the top of the page.
        </FormControl>
      </div>
    </>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Professional References',
  description:
    'Please list three professional references who are preferably from your specialty area, not including relatives, who have worked with you in the past two years and who, through recent observation, are directly familiar with your work and can attest to your clinical competence in your specialty area. If you have been out of residency for a period of less than three years, please provide one reference from the Program Director. If you are an Allied Health Professional, please provide one reference from same discipline.',
  Component: ProfessionalReferences,
  getFormMeta: ({ providerQuestionnaire }) => {
    const validationSchema = Yup.object().shape({
      professionalReferences: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().required('First name is required.'),
          lastName: Yup.string().required('Last name is required.'),
          email: Yup.string().when(
            'contactMethod',
            (contactMethod: string, schema: Yup.StringSchema) =>
              contactMethod === 'Email'
                ? Yup.string()
                    .email('Must be a valid email address.')
                    .required('Email is required.')
                : Yup.string().email('Must be a valid email address.')
          ),
          degree: Yup.string().required('Degree is required.'),
          contactMethod: Yup.string().required(
            'Preferred contact method is required'
          ),
          street1: Yup.string().required('This question is required.'),
          street2: Yup.string(),
          city: Yup.string().required('This question is required.'),
          state: Yup.string().required('This question is required.'),
          zip: Yup.string().required('This question is required.'),
          phone: Yup.string()
            .required('This question is required.')
            .matches(YUP_PHONE_MATCH, YUP_PHONE_ERROR_MESSAGE),
          fax: Yup.string().when(
            'contactMethod',
            (contactMethod: string, schema: Yup.StringSchema) =>
              contactMethod === 'Fax'
                ? Yup.string()
                    .required('Fax number is required.')
                    .matches(YUP_PHONE_MATCH, YUP_PHONE_ERROR_MESSAGE)
                : Yup.string().matches(YUP_PHONE_MATCH, YUP_PHONE_ERROR_MESSAGE)
          ),
          associationStartDate: Yup.date()
            .max(new Date(), 'Association date cannot be in the future.')
            .nullable()
            .transform(transformDateToNullOrStartOfDay)
            .required('This question is required.'),
          associationEndDate: Yup.date()
            .transform(transformDateToNullOrStartOfDay)
            .nullable(),
        })
      ),
      confirmReferencesCorrect: Yup.boolean()
        .oneOf([true], 'Please confirm you have followed the instructions.')
        .required('Please confirm you have followed the instructions.'),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema, [initialReference]),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;
