import { useFormikContext } from 'formik';
import { useProvider } from 'hooks';
import React, { useContext, useState } from 'react';

import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useUser } from '@headway/shared/hooks/useUser';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { HeadwayLogo } from '@headway/ui/icons/HeadwayLogo';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { TreatmentPlansRequirementsList } from 'views/Clients/TreatmentPlan/Components/TreatmentPlansRequirementsList';
import { CancelTreatmentPlanModal } from 'views/Clients/TreatmentPlan/Modals/CancelTreatmentPlanModal';
import { CANCEL_TREATMENT_PLAN_MODAL_TITLE } from 'views/Clients/TreatmentPlan/Modals/CancelTreatmentPlanModal';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from 'views/Clients/TreatmentPlan/TreatmentPlanContext';

import { InfoBox } from '../Components/InfoBox';
import {
  trackBackButtonClickedEvent,
  trackSaveAndExitButtonClickedEvent,
  trackSaveButtonClickedEvent,
} from '../TreatmentPlanAnalyticsUtils';
import { parseTextFromHtmlString } from '../TreatmentPlanUtils';
import { Page } from './Page';

export interface TextInputPageProps {
  closeModal: () => void;
}

export const TextInputPage = ({ closeModal }: TextInputPageProps) => {
  const {
    setPage,
    treatmentPlan,
    providerPatient,
    hasUnsavedChanges,
    initialValues,
  } = useContext(TreatmentPlanContext);
  const [goBackModalOpen, setGoBackModalOpen] = useState<boolean>(false);

  const provider = useProvider();
  const { data: client } = useUser({ userId: providerPatient!.userId });
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery({
      providerId: provider.id,
      patientUserId: providerPatient?.userId,
    });
  const matchingFrontendCarrierName =
    matchingProviderFrontEndCarrier?.frontEndCarrier?.name;

  const pageHeader = 'Write from scratch';

  const { values, submitCount, setFieldTouched, submitForm } =
    useFormikContext<any>();

  const { saveTreatmentPlan } = useProviderTreatmentPlan(
    providerPatient!.userId
  );

  return (
    <Page
      buttons={[
        <Button
          key="back"
          variant="secondary"
          onPress={() => {
            if (
              values?.treatmentPlanText !== initialValues?.treatmentPlanText
            ) {
              trackBackButtonClickedEvent({
                providerPatient: providerPatient!,
                pageHeader: CANCEL_TREATMENT_PLAN_MODAL_TITLE,
              });
              setGoBackModalOpen(true);
            } else {
              trackBackButtonClickedEvent({
                providerPatient: providerPatient!,
                pageHeader: pageHeader,
              });

              if (treatmentPlan) {
                closeModal();
              } else {
                setPage(TREATMENT_PLAN_PAGES.START);
              }
            }
          }}
        >
          Back
        </Button>,
        <Button
          variant="primary"
          type="submit"
          onPress={async () => {
            trackSaveButtonClickedEvent({
              providerPatient: providerPatient!,
              treatmentPlanType: TreatmentPlanType.TEXT,
              characterCount:
                parseTextFromHtmlString(values?.treatmentPlanText)?.length ?? 0,
            });
            await submitForm();
            closeModal();
          }}
          disabled={
            !values?.treatmentPlanText || !values?.treatmentPlanAttestation
          }
        >
          Sign and save
        </Button>,
        <div css={{ marginLeft: 'auto', display: 'flex' }}>
          <Button
            variant="link"
            onPress={async () => {
              trackSaveAndExitButtonClickedEvent(providerPatient!);
              await saveTreatmentPlan({
                values,
                planType: TreatmentPlanType.TEXT,
                shouldAttestPlan: false,
                treatmentPlanId: treatmentPlan?.id,
              });
              closeModal();
            }}
            disabled={!hasUnsavedChanges(values)}
          >
            Save and Exit
          </Button>
        </div>,
      ]}
      hint="Once signed, this plan cannot be edited"
    >
      <SafeFormikForm className="free-text-treatment-plan">
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x6,
          }}
        >
          <div css={{ padding: `${theme.spacing.x5} 0` }}>
            <SectionHeader>{pageHeader}</SectionHeader>
          </div>
          <HeadwayLogo css={{ width: '174px' }} />
          {client && <InfoBox clientId={client.id} />}
          <TreatmentPlansRequirementsList title="Remember to include:" />
        </div>
        <div css={{ marginBottom: theme.spacing.x8 }}>
          <FormControl
            component={RichTextArea}
            name="treatmentPlanText"
            onBlur={() => {
              if (submitCount === 0) {
                setFieldTouched('treatmentPlanText', false);
              }
            }}
            height="fill"
          />
        </div>
        <div>
          <FormControl component={Checkbox} name="treatmentPlanAttestation">
            <div>
              I confirm that...
              <ul css={{ marginTop: theme.spacing.x1 }}>
                <li>
                  I have discussed the risks and benefits of the treatment plan
                  with the client, and the client has acknowledged and agreed to
                  this plan.
                </li>
                <li>
                  My plan is true, accurate and meets{' '}
                  {matchingFrontendCarrierName ?? 'the payer'}
                  's requirements, and — if requested by Headway — I'll provide
                  extra information on request within three business days.
                </li>
              </ul>
            </div>
          </FormControl>
        </div>
      </SafeFormikForm>
      <CancelTreatmentPlanModal
        open={goBackModalOpen}
        onClose={() => setGoBackModalOpen(false)}
        onCancel={() => {
          if (treatmentPlan) {
            closeModal();
          } else {
            setPage(TREATMENT_PLAN_PAGES.START);
          }
        }}
      />
    </Page>
  );
};
