import axiosModule, { AxiosError } from 'axios';

/**
 * Insert specific axios configuration here
 */

export const axios = axiosModule.create({
  withCredentials: true,
  paramsSerializer: {
    indexes: null, // format array query params as `?a=1&a=2`
  },
});

export class MambaApiError<TransformedData, RawData> extends AxiosError<
  TransformedData,
  RawData
> {
  public apiRoute: string;
  public name = 'MambaApiError';

  constructor({
    apiRoute,
    error,
  }: {
    apiRoute: string;
    error: AxiosError<TransformedData, RawData>;
  }) {
    super(
      error.message,
      error.code,
      error.config,
      error.request,
      error.response
    );

    this.name = 'MambaApiError';
    this.apiRoute = apiRoute;
  }
}

export default axios;
