import keyBy from 'lodash/keyBy';
import React from 'react';

import { MarketRead } from '@headway/api/models/MarketRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { MarketApi } from '@headway/api/resources/MarketApi';

interface MarketProviderState {
  isLoading: boolean;
  error?: unknown;
  markets: MarketRead[];
  marketsById: { [key: number]: MarketRead };
  marketsByState: { [key in UnitedStates]?: MarketRead };
  liveMarkets: MarketRead[];
}

export type MarketContextState = MarketProviderState & {
  reload: () => Promise<void>;
};

export const MarketContext = React.createContext<MarketContextState>({
  isLoading: true,
  markets: [],
  marketsById: {},
  marketsByState: {},
  liveMarkets: [],
  reload: async () => Promise.resolve(),
});

export class MarketProvider extends React.Component<
  React.PropsWithChildren<{}>,
  MarketProviderState
> {
  state: MarketProviderState = {
    isLoading: true,
    markets: [],
    marketsById: {},
    marketsByState: {},
    liveMarkets: [],
  };

  async componentDidMount() {
    await this.fetchMarkets();
  }

  fetchMarkets = async () => {
    this.setState({ error: undefined, isLoading: true });

    try {
      const markets = await MarketApi.getAllMarkets();

      this.setState({
        markets,
        marketsById: keyBy(markets, 'id'),
        marketsByState: keyBy(markets, 'state'),
        liveMarkets: markets.filter((market) => market.marketplaceLive),
      });
    } catch (error: unknown) {
      this.setState({ error });
    }
    this.setState({ isLoading: false });
  };

  reload = async () => {
    await this.fetchMarkets();
  };

  render() {
    return (
      <MarketContext.Provider
        value={{
          ...this.state,
          reload: this.reload,
        }}
      >
        {this.props.children}
      </MarketContext.Provider>
    );
  }
}

export const useMarkets = () => React.useContext(MarketContext);

export const MarketConsumer = MarketContext.Consumer;
