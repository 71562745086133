import moment from 'moment';

import { PrelimPricingType } from '@headway/api/models/PrelimPricingType';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';

const PRELIM_PRICING_GRACE_PERIOD_DAYS = 3;

const isPatientInPrelimPricing = (
  pricingType: PrelimPricingType,
  insurance: UserInsuranceRead
): boolean => {
  return (
    !!insurance.prelimPriceActivatedOn &&
    !insurance.prelimPriceDeactivatedOn &&
    insurance.prelimPricingType === pricingType
  );
};

export const isPatientInNoDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead
): boolean => {
  if (!patientInsurance) {
    return false;
  }
  return isPatientInPrelimPricing(
    PrelimPricingType.NO_DATA_USE_FULL_COST,
    patientInsurance
  );
};

export const isPatientInOldDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead
): boolean => {
  if (!patientInsurance) {
    return false;
  }
  return isPatientInPrelimPricing(
    PrelimPricingType.OLD_DATA_PRESUME_ACCUMULATOR_RESET,
    patientInsurance
  );
};

export const wasPatientInPrelimPricing = (
  pricingType: PrelimPricingType,
  insurance: UserInsuranceRead
): boolean => {
  return (
    !!insurance.prelimPriceActivatedOn &&
    !!insurance.prelimPriceDeactivatedOn &&
    insurance.prelimPricingType === pricingType
  );
};

export const wasPatientInNoDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead
): boolean => {
  if (!patientInsurance) {
    return false;
  }
  return wasPatientInPrelimPricing(
    PrelimPricingType.NO_DATA_USE_FULL_COST,
    patientInsurance
  );
};

export const wasPatientInOldDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead
): boolean => {
  if (!patientInsurance) {
    return false;
  }
  return wasPatientInPrelimPricing(
    PrelimPricingType.OLD_DATA_PRESUME_ACCUMULATOR_RESET,
    patientInsurance
  );
};

export const shouldWaiveCancellationFeeDueToPrelimPricing = (
  patientInsurance?: UserInsuranceRead
): boolean => {
  if (!patientInsurance) {
    return false;
  }
  let withinPrelimGracePeriod = false;
  if (
    (wasPatientInNoDataPrelimPricing(patientInsurance) ||
      wasPatientInOldDataPrelimPricing(patientInsurance)) &&
    !!patientInsurance.prelimPriceDeactivatedOn
  ) {
    const gracePeriodEndDate = new Date(
      patientInsurance.prelimPriceDeactivatedOn
    );
    gracePeriodEndDate.setDate(
      gracePeriodEndDate.getDate() + PRELIM_PRICING_GRACE_PERIOD_DAYS
    );
    withinPrelimGracePeriod = gracePeriodEndDate >= moment().toDate();
  }

  return (
    isPatientInNoDataPrelimPricing(patientInsurance) ||
    isPatientInOldDataPrelimPricing(patientInsurance) ||
    withinPrelimGracePeriod
  );
};

export const isAppointmentInPrelimPricing = ({
  event,
  patientInsurance,
}: {
  event: ProviderEventRead;
  patientInsurance?: UserInsuranceRead;
}): boolean => {
  if (!patientInsurance || !event.endDate) {
    return false;
  }

  const { prelimPriceActivatedOn, prelimPriceDeactivatedOn } = patientInsurance;

  const isAfterActivation = !!(
    prelimPriceActivatedOn &&
    moment(event.endDate).isSameOrAfter(prelimPriceActivatedOn)
  );

  const isBeforeDeactivation =
    !prelimPriceDeactivatedOn ||
    moment(event.endDate).isBefore(prelimPriceDeactivatedOn);

  return isAfterActivation && isBeforeDeactivation;
};

export const wasCancellationFeeWaivedDueToPrelimPricing = ({
  isPrelimPriceAppointment,
  canceledOn,
  patientInsurance,
}: {
  isPrelimPriceAppointment: boolean;
  canceledOn?: string;
  patientInsurance?: UserInsuranceRead;
}): boolean => {
  if (
    !patientInsurance ||
    !patientInsurance.prelimPriceActivatedOn ||
    !isPrelimPriceAppointment ||
    !canceledOn ||
    moment(canceledOn).isBefore(patientInsurance.prelimPriceActivatedOn)
  ) {
    return false;
  }

  if (patientInsurance.prelimPriceDeactivatedOn) {
    const gracePeriodEndDate = moment(
      patientInsurance.prelimPriceDeactivatedOn
    ).add(PRELIM_PRICING_GRACE_PERIOD_DAYS, 'days');
    return moment(canceledOn).isBefore(gracePeriodEndDate);
  }
  return true;
};
