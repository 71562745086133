import React from 'react';

export function IconVideoChat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7499 8C19.5399 7.89 19.2899 7.9 19.0999 8.02L16.1699 9.89999V8.75999C16.1699 8.18999 15.7099 7.73 15.1399 7.73H5.38986C4.81986 7.73 4.35986 8.18999 4.35986 8.75999V15.34C4.35986 15.91 4.81986 16.37 5.38986 16.37H15.1299C15.6999 16.37 16.1599 15.91 16.1599 15.34V14.2L19.0899 16.08C19.1899 16.15 19.3099 16.18 19.4299 16.18C19.5399 16.18 19.6399 16.15 19.7399 16.1C19.9499 15.99 20.0699 15.77 20.0699 15.54V8.55C20.0699 8.32 19.9399 8.09999 19.7399 7.98999L19.7499 8ZM15.3499 15.35C15.3499 15.47 15.2499 15.57 15.1299 15.57H5.38986C5.26986 15.57 5.16986 15.47 5.16986 15.35V8.77C5.16986 8.65 5.26986 8.55 5.38986 8.55H15.1299C15.2499 8.55 15.3499 8.65 15.3499 8.77V15.35ZM19.2699 15.23L16.1699 13.24V10.87L19.2699 8.88V15.23Z"
        fill="currentColor"
        fillOpacity="0.87"
      />
    </svg>
  );
}
