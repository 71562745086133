import { BscaSpecialties, SectionStepForBscaSpecialty } from './constants';

export const focusAreaConfig = [
  {
    title: 'Alcohol and Substance Abuse',
    specialtyKey: BscaSpecialties.Addiction,
    physicianOnly: false,
    questions: [
      {
        key: 'bcsaAddictionTraining',
        text: {
          physician:
            'Have you completed an ACGME approved fellowship in Addiction Medicine OR do you have a certification in Addiction Medicine or ASAM?',
          other:
            'Have you completed an APA or other accepted training in Addictionology OR do you have certification in Addiction Counseling?',
        },
      },
      {
        key: 'bcsaAddictionContinuingEducation',
        text: {
          physician:
            'Do you have 10 hours of CME in Substance Abuse in the last 24 month period?',
          other:
            'Do you have 10 hours of CEU in Substance Abuse in the last 24 month period?',
        },
      },
      {
        key: 'bcsaAddictionPracticeExperience',
        text: {
          physician:
            'Do you have evidence of at least 25% of practice experience in substance abuse?',
          other:
            'Do you have evidence of 25% practice experience in substance abuse?',
        },
      },
    ],
  },

  {
    title: 'Eating Disorders',
    specialtyKey: BscaSpecialties.EatingDisorders,
    physicianOnly: false,
    questions: [
      {
        key: 'bcsaEatingDisorderTraining',
        text: {
          physician:
            'Have you completed a (1) year fellowship, internship or practice in Eating Disorders, completed at an accredited institution or approved program?',
          other:
            'Have you completed one (1) year fellowship, internship or practice in Eating Disorders completed at an accredited institution or approved program?',
        },
      },
      {
        key: 'bcsaEatingDisorderProfessionalExperience',
        text: {
          physician:
            'Do you have evidence of at least one (1) year professional experience with at least twenty-five percent (25%) of practice in the treatment of eating disorders?',
          other:
            'Do you have evidence of at least one (1) year professional experience with at least twenty-five (25%) of practice in the treatment of eating disorders?',
        },
      },
      {
        key: 'bcsaEatingDisorderContinuingEducation',
        text: {
          physician:
            'Do you have ten (10) hours of CME in Eating Disorders in the last twenty-four (24) month period?',
          other:
            'Have you completed ten (10) hours of CEU in Eating Disorders in the last twenty-four (24) month period?',
        },
      },
    ],
  },
];

export const modalitiesConfig = [
  {
    title: 'Neuropsychological Testing',
    specialtyKey: BscaSpecialties.NeuropsychologicalTesting,
    physicianOnly: true,
    questions: [
      {
        key: 'bcsaNeuropsychTestingBoardMember',
        text: {
          physician:
            'Are you a member of the American Board of Clinical Neuropsychology OR the American Board of Professional Neuropsychology?',
          other: '',
        },
      },
      {
        key: 'bcsaNeuropsychTestingCompletedCourses',
        text: {
          physician:
            'Have you completed all the following courses in Neuropsychology including: Neuroanatomy, Neuropsychological testing, Neuropathology, or Neuropharmacology?',
          other: '',
        },
      },
      {
        key: 'bcsaNeuropsychTestingCompletedInternshipOrFellowship',
        text: {
          physician:
            'Have you completed an internship, fellowship, or practicum in Neuropsychological Assessment at an accredited institution?',
          other: '',
        },
      },
      {
        key: 'bcsaNeuropsychTestingCompletedSupervisedExperience',
        text: {
          physician:
            'Have you completed two (2) years of supervised professional experience in Neuropsychological Assessment?',
          other: '',
        },
      },
    ],
  },
];

export const currentClientsConfig = [
  {
    title: 'Infants/Toddlers (Ages 1-5)',
    specialtyKey: BscaSpecialties.InfantsToddlers,
    physicianOnly: false,
    questions: [
      {
        key: 'bcsaInfantsToddlersTraining',
        text: {
          physician:
            'Have you completed an ACGME approved Child Fellowship OR do you have a recognized certification in Child Psychiatry?',
          other:
            'Have you completed an APA approved or other accepted training program in Child Psychology?',
        },
      },
      {
        key: 'bcsaInfantsToddlersContinuingEducation',
        text: {
          physician:
            'Do you have 10 hours of CME in preschool/children in the last 24 month period?',
          other:
            'Have you completed 10 hours of CEU in preschool/children in the last 24 month period?',
        },
      },
      {
        key: 'bcsaInfantsToddlersPracticeExperience',
        text: {
          physician:
            'Do you have evidence of at least 25% of practice experience in the treatment of preschool/children?',
          other:
            'Do you have evidence of at least 25% of practice experience in the treatment of preschool/children?',
        },
      },
    ],
  },

  {
    title: 'Children (Ages 6-12)',
    specialtyKey: BscaSpecialties.Children,
    questions: [
      {
        key: 'bcsaChildrenTraining',
        text: {
          physician:
            'Have you completed an ACGME approved Child Fellowship OR do you have a recognized certification in Child Psychiatry?',
          other:
            'Have you completed an APA approved or other accepted training program in Child Psychology?',
        },
      },
      {
        key: 'bcsaChildrenContinuingEducation',
        text: {
          physician:
            'Do you have 10 hours of CME in preschool/children in the last 24 month period?',
          other:
            'Have you completed 10 hours of CEU in preschool/children in the last 24 month period?',
        },
      },
      {
        key: 'bcsaChildrenPracticeExperience',
        text: {
          physician:
            'Do you have evidence of at least 25% of practice experience in the treatment of preschool/children?',
          other:
            'Do you have evidence of at least 25% of practice experience in the treatment of preschool/children?',
        },
      },
    ],
  },

  {
    title: 'Adolescents (Ages 13-17)',
    specialtyKey: BscaSpecialties.Adolescents,
    summary: '',
    questions: [
      {
        key: 'bcsaAdolescentsTraining',
        text: {
          physician:
            'Have you completed an ACGME approved Child and Adolescent Fellowship OR have you completed a recognized certification in Adolescent Psychiatry?',
          other:
            'Have you completed an APA approved or other accepted training program in Adolescent Psychology?',
        },
      },
      {
        key: 'bcsaAdolescentsContinuingEducation',
        text: {
          physician:
            'Have you completed ten (10) hours of CME in adolescents in the last twenty-four (24) month period?',
          other:
            'Have you completed ten (10) hours of CEU in adolescents in the last 24 month period?',
        },
      },
      {
        key: 'bcsaAdolescentsPracticeExperience',
        text: {
          physician:
            'Do you have evidence of at least twenty-five percent (25%) of practice experience in treating adolescent patients?',
          other:
            'Do you have evidence of at least 25% of practice experience in treating adolescent patients?',
        },
      },
    ],
  },

  {
    title: 'Seniors (Ages 66+)',
    specialtyKey: BscaSpecialties.Seniors,
    summary: '',
    questions: [
      {
        key: 'bcsaSeniorsTraining',
        text: {
          physician:
            'Have you completed an ACGME approved Geriatric Fellowship OR do you have a recognized certification in Geriatric Psychiatry?',
          other:
            'Have you completed an APA approved or other accepted training program in Geriatric Psychology?',
        },
      },
      {
        key: 'bcsaSeniorsContinuingEducation',
        text: {
          physician:
            'Have you completed ten (10) hours of CME in Geriatrics in the last twenty-four (24) month period?',
          other:
            'Have you completed (10) hours of CEU in Geriatrics/Gerontology in the last twenty-four (24) month period?',
        },
      },
      {
        key: 'bcsaSeniorsPracticeExperience',
        text: {
          physician:
            'Do you have evidence of at least twenty-five percent (25%) of practice experience in treating geriatric patients',
          other:
            'Do you have evidence of twenty-five (25%) of practice experience in treating geriatric patients?',
        },
      },
    ],
  },
];

const stepToConfigMap = {
  [SectionStepForBscaSpecialty.FocusAreas]: focusAreaConfig,
  [SectionStepForBscaSpecialty.Modalities]: modalitiesConfig,
  [SectionStepForBscaSpecialty.CurrentClients]: currentClientsConfig,
};

export const getConfigForStep = (step: SectionStepForBscaSpecialty) => {
  return stepToConfigMap[step];
};
