import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { getContentOfHtml } from '@headway/shared/utils/strings';
import { MaxWidthContainer } from '@headway/ui';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { DownloadProviderAgreementButton } from 'components/IroncladAmendmentsModal/components/AcceptModalContent/DownloadProviderAgreementButton';
import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useIroncladVersionData } from 'hooks/useIroncladVersionData';
import { useLastSignedIroncladAgreement } from 'hooks/useLastSignedIroncladAgreement';

import { LegalPart } from './LegalOverview';
import { ArizonaAgreement } from './StateAgreements/ArizonaAgreement';
import { CaliforniaAgreement } from './StateAgreements/CaliforniaAgreement';
import { ColoradoAgreement } from './StateAgreements/ColoradoAgreement';
import { ConnecticutAgreement } from './StateAgreements/ConnecticutAgreement';
import { DistrictOfColumbiaAgreement } from './StateAgreements/DistrictOfColumbiaAgreement';
import { FloridaAgreement } from './StateAgreements/FloridaAgreement';
import { GeorgiaAgreement } from './StateAgreements/GeorgiaAgreement';
import { IllinoisAgreement } from './StateAgreements/IllinoisAgreement';
import { LouisianaAgreement } from './StateAgreements/LouisianaAgreement';
import { MarylandAgreement } from './StateAgreements/MarylandAgreement';
import { MassachusettsAgreement } from './StateAgreements/MassachusettsAgreement';
import { MichiganAgreement } from './StateAgreements/MichiganAgreement';
import { MinnesotaAgreement } from './StateAgreements/MinnesotaAgreement';
import { NewJerseyAgreement } from './StateAgreements/NewJerseyAgreement';
import { NewYorkAgreement } from './StateAgreements/NewYorkAgreement';
import { NorthCarolinaAgreement } from './StateAgreements/NorthCarolinaAgreement';
import { OhioAgreement } from './StateAgreements/OhioAgreement';
import { OregonAgreement } from './StateAgreements/OregonAgreement';
import { PennsylvaniaAgreement } from './StateAgreements/PennsylvaniaAgreement';
import { TennesseeAgreement } from './StateAgreements/TennesseeAgreement';
import { TexasAgreement } from './StateAgreements/TexasAgreement';
import { VirginiaAgreement } from './StateAgreements/VirginiaAgreements';
import { WashingtonAgreement } from './StateAgreements/WashingtonAgreement';

interface AgreementProps {
  provider: ProviderRead;
  user: any;
  setSelectedPart: (part: LegalPart) => void;
  agreementState?: UnitedStates;
}

const stateToAgreementMap = {
  [UnitedStates.COLORADO]: ColoradoAgreement,
  [UnitedStates.FLORIDA]: FloridaAgreement,
  [UnitedStates.GEORGIA]: GeorgiaAgreement,
  [UnitedStates.ILLINOIS]: IllinoisAgreement,
  [UnitedStates.MICHIGAN]: MichiganAgreement,
  [UnitedStates.NEW_YORK]: NewYorkAgreement,
  [UnitedStates.NEW_JERSEY]: NewJerseyAgreement,
  [UnitedStates.NORTH_CAROLINA]: NorthCarolinaAgreement,
  [UnitedStates.TEXAS]: TexasAgreement,
  [UnitedStates.VIRGINIA]: VirginiaAgreement,
  [UnitedStates.WASHINGTON]: WashingtonAgreement,
  [UnitedStates.TENNESSEE]: TennesseeAgreement,
  [UnitedStates.LOUISIANA]: LouisianaAgreement,
  [UnitedStates.DISTRICT_OF_COLUMBIA]: DistrictOfColumbiaAgreement,
  [UnitedStates.MARYLAND]: MarylandAgreement,
  [UnitedStates.MINNESOTA]: MinnesotaAgreement,
  [UnitedStates.OHIO]: OhioAgreement,
  [UnitedStates.OREGON]: OregonAgreement,
  [UnitedStates.PENNSYLVANIA]: PennsylvaniaAgreement,
  [UnitedStates.ARIZONA]: ArizonaAgreement,
  [UnitedStates.CONNECTICUT]: ConnecticutAgreement,
  [UnitedStates.MASSACHUSETTS]: MassachusettsAgreement,
  [UnitedStates.CALIFORNIA]: CaliforniaAgreement,
};

const Agreement = ({ title, body, groupKey }: any) => {
  return (
    <React.Fragment key={title}>
      <div
        css={{
          marginTop: theme.spacing.x12,
        }}
      >
        <PageHeader>{title}</PageHeader>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(getContentOfHtml(body) || ''),
        }}
        css={{
          [`#ps-group-${groupKey}`]: {
            '& div.ps-contract-title': {
              ...theme.typography.sectionHeader,
            },
            '& div.ps-contract': {
              display: 'block !important',
            },
            '& label.ps-checkbox-container': {
              display: 'none',
            },
            '& div.ps-contract-body': {
              borderBottomWidth: '1px',
              '& p, & div': {
                ...theme.typography.body.regular,
              },
            },
            '& a': {
              ...theme.typography.hyperlink,
              color: theme.color.system.textBlack,
              '&:hover': {
                textDecoration: 'none !important',
              },
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export const Contract: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<AgreementProps>>
> = ({ user, provider, setSelectedPart, agreementState }) => {
  const isGroupPractice = !!user.group_practice;
  const isNursePractitioner = provider.providerType === 'Nurse Practitioner';
  const isPhysician = provider.providerType === 'Physician';

  const licenseType = provider.licenseType;

  const shouldUseIroncladAgreements = useFlag('ironcladProviderAgreements');
  const { ironcladAgreementInfo } = useIroncladAgreementInfo();
  const { ironcladVersionData } = useIroncladVersionData();
  const { groupKey } = ironcladAgreementInfo || {};
  const { ironcladAgreement, isLoading } = useLastSignedIroncladAgreement();

  if (isLoading)
    return (
      <MaxWidthContainer>
        <div
          css={{
            padding: '10px 0',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.__futureMedia.above('tablet')]: {
              height: '75px',
            },
          }}
        >
          <LogoLoader size={50} /> :
        </div>
      </MaxWidthContainer>
    );

  if (shouldUseIroncladAgreements && ironcladAgreement && groupKey) {
    const isPrimaryAgreement = (agreementTitle: string) =>
      ['Therapist', 'Group', 'Nurse Practitioner', 'Physician'].some((term) =>
        agreementTitle.includes(term)
      );
    const primaryAgreement = Object.entries(ironcladAgreement).filter(
      ([title]) => isPrimaryAgreement(title)
    )[0];

    return (
      <MaxWidthContainer>
        <div
          css={{
            width: '100%',
            paddingTop: theme.spacing.x6,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <DownloadProviderAgreementButton downloadData={ironcladVersionData} />
        </div>

        {/* Primary agreement */}
        <Agreement
          title={primaryAgreement[0]}
          body={primaryAgreement[1]}
          groupKey={groupKey}
        />

        {/* State specific addendums */}
        {Object.entries(ironcladAgreement).map(([title, body]) => {
          if (isPrimaryAgreement(title)) return <></>;
          return <Agreement title={title} body={body} groupKey={groupKey} />;
        })}
      </MaxWidthContainer>
    );
  }

  if (!agreementState || !stateToAgreementMap.hasOwnProperty(agreementState)) {
    return (
      <MaxWidthContainer>
        <p>
          We can't find your agreement. Please reach out to us via{' '}
          <a href="https://headway.co/contact" target="_blank" rel="noreferrer">
            our contact form
          </a>{' '}
          to get this resolved.
        </p>
      </MaxWidthContainer>
    );
  }

  const ContractComponent =
    stateToAgreementMap[agreementState as keyof typeof stateToAgreementMap];

  return (
    <ContractComponent
      isGroupPractice={isGroupPractice}
      isNursePractitioner={isNursePractitioner}
      isPhysician={isPhysician}
      licenseType={licenseType}
      setSelectedPart={setSelectedPart}
    />
  );
};
