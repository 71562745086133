import React, { useContext } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import teaImageSrc from '@headway/shared/assets/img/illustrations/Tea.jpg';

import { OnboardingCompletionCardContext } from 'providers/OnboardingCompletionCardProvider';

export const OnboardingCompletionCard = () => {
  const [, dismissOnboardingCompletionCard] = useContext(
    OnboardingCompletionCardContext
  );

  return (
    <PageSection>
      <div className="flex flex-col items-center rounded border border-system-borderGray p-8">
        <img src={teaImageSrc} width="25%" alt="" className="mb-5" />
        <SectionHeader>You're all set!</SectionHeader>
        <p className="mb-5">
          <BodyText>
            We'll let you know as soon as your credentialing is complete and
            your profile is published for new referrals.
          </BodyText>
        </p>
        <Button onPress={dismissOnboardingCompletionCard}>Done</Button>
      </div>
    </PageSection>
  );
};
