/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* These values map to the tag values for the Customer Type zendesk field */    /**
    * These values map to the tag values for the Customer Type zendesk field
    */
export enum CustomerType {
    CLIENT = 'receiving_mental_health_services',
    PROVIDER_ON_BEHALF_OF_CLIENT = 'provider_on_behalf_of_client',
    PROVIDER_ON_BEHALF_OF_SELF = 'providing_mental_health_services'
}
