import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  GAD7Submission,
  GAD7SubmissionAssessmentType,
} from '@headway/api/models/GAD7Submission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const GAD7_OPTIONS: OptionsSchema = [
  {
    displayText: 'Not at all',
    value: 0,
  },
  {
    displayText: 'Several days',
    value: 1,
  },
  {
    displayText: 'More than half the days',
    value: 2,
  },
  {
    displayText: 'Nearly every day',
    value: 3,
  },
];

const GAD7_QUESTIONS: QuestionsSchema = [
  {
    key: 'GAD7_1',
    questionText: 'Feeling nervous, anxious, or on edge',
  },
  {
    key: 'GAD7_2',
    questionText: 'Not being able to stop or control worrying',
  },
  {
    key: 'GAD7_3',
    questionText: 'Worrying too much about different things',
  },
  {
    key: 'GAD7_4',
    questionText: 'Trouble relaxing',
  },
  {
    key: 'GAD7_5',
    questionText: 'Being so restless that it is hard to sit still',
  },
  {
    key: 'GAD7_6',
    questionText: 'Becoming easily annoyed or irritable',
  },
  {
    key: 'GAD7_7',
    questionText: 'Feeling afraid, as if something awful might happen',
  },
];

const INSTRUCTION =
  'Over the last 2 weeks, how often have you been bothered by any of the following problems?';

const GAD7_VALIDATION_SCHEMA = Yup.object().shape(
  GAD7_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type GAD7FormValues = {
  [key in keyof Omit<GAD7Submission, 'assessmentType'>]: string;
};

export const GAD7AssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: GAD7FormValues) => {
    return onSubmit({
      assessmentType: GAD7SubmissionAssessmentType.GAD7,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<GAD7FormValues>
      onSubmit={innerOnSubmit}
      validationSchema={GAD7_VALIDATION_SCHEMA}
      initialValues={{
        GAD7_1: '',
        GAD7_2: '',
        GAD7_3: '',
        GAD7_4: '',
        GAD7_5: '',
        GAD7_6: '',
        GAD7_7: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={GAD7_QUESTIONS}
            options={GAD7_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyGAD7Assessment = (props: ReadonlyAssessmentProps) => {
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={GAD7_QUESTIONS}
        options={GAD7_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </section>
  );
};
