/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum LicenseTypes {
    NONE = '',
    APN = 'APN',
    APRN = 'APRN',
    APRN_OUT_OF_STATE = 'APRN_OUT_OF_STATE',
    APRN_FPA = 'APRN_FPA',
    APNP = 'APNP',
    ARNP = 'ARNP',
    APRN_CNS = 'APRN_CNS',
    APRN_CNP = 'APRN_CNP',
    APRN_NP = 'APRN_NP',
    TLHT_APRN_CNP = 'TLHT_APRN_CNP',
    CNP = 'CNP',
    CRNP = 'CRNP',
    CADC = 'CADC',
    CP = 'CP',
    MP = 'MP',
    PSYCHOLOGIST = 'PSYCHOLOGIST',
    PSYCHOLOGIST_OUT_OF_STATE = 'PSYCHOLOGIST_OUT_OF_STATE',
    LICENSED_PSYCHOLOGIST = 'LICENSED_PSYCHOLOGIST',
    PSYD = 'PSYD',
    PHD = 'PHD',
    PSY = 'PSY',
    PSYP = 'PSYP',
    DO = 'DO',
    LCAT = 'LCAT',
    LCAS = 'LCAS',
    LCADC = 'LCADC',
    LCMHC = 'LCMHC',
    LCMHC_S = 'LCMHC_S',
    LCPC = 'LCPC',
    LPCMH = 'LPCMH',
    LPCMHC = 'LPCMHC',
    LCPCS = 'LCPCS',
    LPC_S = 'LPC_S',
    CPC = 'CPC',
    LCSW = 'LCSW',
    LCSW_C = 'LCSW_C',
    LISW = 'LISW',
    LISW_S = 'LISW_S',
    LICSW = 'LICSW',
    LICSW_OUT_OF_STATE = 'LICSW_OUT_OF_STATE',
    LCSW_OUT_OF_STATE = 'LCSW_OUT_OF_STATE',
    LMSW = 'LMSW',
    LSCSW = 'LSCSW',
    LISW_CP = 'LISW_CP',
    LICSW_PIP = 'LICSW_PIP',
    CSW_PIP = 'CSW_PIP',
    LC = 'LC',
    LMHC = 'LMHC',
    LMHC_OUT_OF_STATE = 'LMHC_OUT_OF_STATE',
    CMHC = 'CMHC',
    LIMHP = 'LIMHP',
    LP = 'LP',
    LPC = 'LPC',
    LPCC_S = 'LPCC_S',
    LPCC = 'LPCC',
    LPCMHSP = 'LPCMHSP',
    MD = 'MD',
    MFT = 'MFT',
    MFT_OUT_OF_STATE = 'MFT_OUT_OF_STATE',
    LIMFT = 'LIMFT',
    LMFT = 'LMFT',
    LCMFT = 'LCMFT',
    LMFTS = 'LMFTS',
    NP = 'NP',
    NP_COMPACT = 'NP_COMPACT',
    CNS = 'CNS',
    PCNS = 'PCNS',
    NCAC_I = 'NCAC I',
    NCAC_II = 'NCAC II',
    MAC = 'MAC',
    NDS = 'NDS',
    NCAAC = 'NCAAC',
    NCPRSS = 'NCPRSS',
    NCSE = 'NCSE',
    CASAC = 'CASAC',
    CASAC_T = 'CASAC-T',
    CSAC = 'CSAC',
    CPRC = 'CPRC',
    CCS = 'CCS',
    CAADC = 'CAADC',
    CADC_I = 'CADC I',
    CADC_II = 'CADC II',
    CADC_III = 'CADC III',
    CADC_T = 'CADC-T',
    LCDC = 'LCDC',
    LCDC_INTERN = 'LCDC Intern',
    SUDP = 'SUDP',
    SUDPT = 'SUDPT',
    LSATP = 'LSATP',
    CSAC_ASSISTANT = 'CSAC Assistant',
    CAC_I = 'CAC I',
    CAC_II = 'CAC II',
    CAC = 'CAC',
    CAC_AD = 'CAC-AD',
    CSC_AD = 'CSC-AD',
    CAP = 'CAP',
    LADAC = 'LADAC',
    CAAC = 'CAAC',
    AAC_II = 'AAC II',
    LISAC = 'LISAC',
    LASAC = 'LASAC',
    LSAT = 'LSAT',
    LAC = 'LAC',
    CAS = 'CAS',
    CAT = 'CAT',
    RAC = 'RAC',
    LICDC = 'LICDC',
    LCDC_II = 'LCDC II',
    LCDC_III = 'LCDC III',
    LADC = 'LADC',
    A_AND_DC = 'A&DC',
    LADC_I = 'LADC I',
    LADC_II = 'LADC II',
    LADC_ASSISTANT = 'LADC Assistant',
    CSADC = 'CSADC',
    CRADC = 'CRADC',
    CATC_I = 'CATC I',
    CATC_II = 'CATC II',
    CATC_III = 'CATC III',
    CATC_IV = 'CATC IV',
    CATC_V = 'CATC V',
    CATC_N = 'CATC N',
    OTHER = 'OTHER'
}
