import { UserApi } from '@headway/api/resources/UserApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseUserAppointmentReadinessQueryKeyArgs {
  userId: number;
  providerId?: number;
}

export const getUseUserAppointmentReadinessQueryKey = ({
  userId,
}: UseUserAppointmentReadinessQueryKeyArgs): [string, number] => [
  'userAppointmentReadiness',
  userId,
];

const {
  useSingleQuery: useUserAppointmentReadiness,
  useListQuery: useUserAppointmentReadinessList,
} = createBasicApiHooks(
  getUseUserAppointmentReadinessQueryKey,
  ({ userId, providerId }) =>
    UserApi.getUserAppointmentReadiness(userId, {
      provider_id: providerId,
    }),
  ({ userId }) => !!userId && !Number.isNaN(userId),
  () => 'Failed to fetch user appointment readiness'
);

export { useUserAppointmentReadiness, useUserAppointmentReadinessList };
