import styled from '@emotion/styled';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { EinField } from '@headway/helix/MaskedTextField';
import { ModalContent } from '@headway/helix/Modal';
import { SELF_PAY_FEES } from '@headway/shared/constants/selfPayFees';
import { logException } from '@headway/shared/utils/sentry';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { useAuthStore } from 'stores/AuthStore';

const TermsAndConditionStyles = styled.li({
  marginBottom: theme.space.base,
});

interface SelfPayOptInFormProps {
  provider: ProviderRead;
  onSubmit: () => void;
}

interface SelfPayOptInFormValues {
  acceptedTerms: boolean;
  taxId: string;
}

// gemini.link(mamba/app/shared/models/provider.py)
export const TAX_ID_SSN_MATCH_ERROR = 'Tax ID cannot match SSN.';
// gemini.endlink

export function SelfPayOptInForm({
  provider,
  onSubmit: afterSubmitCb,
}: SelfPayOptInFormProps) {
  const authStore = useAuthStore();
  const [readTerms, setReadTerms] = React.useState(false);
  const termsRef = React.useRef<HTMLDivElement>(null);
  const selfPayFlatRate = SELF_PAY_FEES.flat;

  async function onSubmit(
    { taxId }: SelfPayOptInFormValues,
    { setFieldError }: FormikHelpers<SelfPayOptInFormValues>
  ) {
    try {
      const updatedProvider = await ProviderApi.updateProvider(provider.id, {
        selfPayTermsAcceptanceDate: new Date().toISOString(),
        ...(taxId ? { taxId } : {}),
      });
      authStore.setProvider(updatedProvider);
      afterSubmitCb();
    } catch (err: any) {
      if (
        err?.response?.status === 400 &&
        err?.response?.data?.detail === TAX_ID_SSN_MATCH_ERROR
      ) {
        // API returns a 400 if the EIN and SSN match.
        setFieldError('taxId', 'EIN must not match Social Security number');
      } else {
        notifyError('There was a problem enabling private pay.');
        logException(err);
      }
    }
  }

  const handleScroll = () => {
    if (termsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
      const bottom = Math.abs(scrollHeight - scrollTop - clientHeight) <= 3;
      if (bottom) {
        setReadTerms(true);
      }
    }
  };

  return (
    <>
      <ModalContent>
        <p css={{ marginBottom: theme.space.xl }}>
          We’re excited to offer the option to see your private pay clients
          through Headway!
        </p>
        <div>
          <b>Here’s how it works:</b>
          <ul>
            <li>
              Set your own private pay rate for clients you’re seeing
              out-of-network. You can use your existing cancellation policy,
              too.
            </li>
            <li>
              Schedule and confirm these sessions directly in your Headway
              calendar.
            </li>
            <li>
              Receive payment with the rest of your bi-weekly Headway payouts.
            </li>
          </ul>
        </div>
        <b>Terms and Conditions</b>
        <div
          onScroll={readTerms ? undefined : handleScroll}
          ref={termsRef}
          css={{
            maxHeight: '250px',
            overflowY: 'auto',
            marginBottom: theme.space.xl,
          }}
          tabIndex={0}
          data-testid="terms"
        >
          <p css={{ marginTop: theme.space.base }}>
            Headway’s platform has a feature that allows providers to see
            private pay patients who do not have insurance accepted by Headway.
            By accepting the following terms and conditions, you will amend your
            agreement with the Practice and be provided access to this feature:
          </p>
          <ul css={{ marginBottom: theme.space.base }}>
            <TermsAndConditionStyles>
              PROVIDER is able to negotiate and set private pay rates for
              patients without insurance (the “Private Pay Rate”). PROVIDER
              understands that the Practice will pass along to PROVIDER certain
              of the costs associated with seeing private pay patients,
              including (i) a Stripe percentage fee that is variable to whether
              ACH (currently 0.8% of the Private Pay Rate) or credit card
              (currently 2.3% of the Private Pay Rate) payments are made, and
              (ii) a flat rate of ${selfPayFlatRate?.toFixed(2)}, which is
              dependent on geographic location.
              <ul css={{ marginTop: theme.space.base }}>
                <li>
                  By way of example, if PROVIDER negotiates a Private Pay Rate
                  of $100, Practice will charge the patient $100. If the patient
                  pays $100 by credit card and the flat rate for PROVIDER’s
                  geographic location is set at $2.00, the Practice would pay
                  the PROVIDER $95.70. ($100 - (($100 * 0.023) + $2.00) =
                  $95.70).
                </li>
              </ul>
            </TermsAndConditionStyles>
            <TermsAndConditionStyles>
              The following requirements apply to your use of Private Pay Rates.
              Failure to observe any of these requirements will result in
              non-payment: (a) Private Pay Rates are only available for patients
              who do not have insurance that can be used to see the PROVIDER
              through the Practice; (b) Private pay patients must pay the
              Private Pay Rate in order for the PROVIDER to receive compensation
              for seeing the private pay patient; (c) to charge a Private Pay
              Rate, PROVIDER must either be credentialed with the Practice or in
              process to be credentialed with the Practice; and (d) Private Pay
              Rates are only available to patients the PROVIDER has brought to
              the Practice; except that, a PROVIDER may charge a Private Pay
              Rate to a Practice-sourced patient if that patient is no longer
              covered by an insurance that the Practice accepts.{' '}
            </TermsAndConditionStyles>
            <TermsAndConditionStyles>
              In addition, PROVIDER and Practice agree that: (a) the Practice is
              not responsible for payment to the PROVIDER if a private pay
              patient does not complete payment; and (b) Private Pay patients
              will be able to download superbills from the Practice to submit to
              insurance companies that will contain PROVIDER’s EIN (if provided)
              and NPI (and not the Practice’s EIN or NPI).
            </TermsAndConditionStyles>
          </ul>
        </div>
        <Formik
          initialValues={{ acceptedTerms: false, taxId: provider.taxId || '' }}
          validationSchema={Yup.object({
            acceptedTerms: Yup.boolean().oneOf(
              [true],
              'You must accept the private pay terms before continuing'
            ),
            taxId: Yup.string()
              .notRequired()
              .test(
                'nineDigits',
                'EIN must be 9 digits if provided',
                (val) =>
                  !val || val === '__-_______' || /^\d{2}-\d{7}$/.test(val)
              ),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form id="self-pay-opt-in">
              <FormControl
                name="taxId"
                component={EinField}
                label="EIN"
                optionalityText="Optional"
                helpText="Headway uses this to create superbills. Do not include your Social Security number."
              />

              <FormControl
                component={Checkbox}
                name="acceptedTerms"
                disabled={!readTerms}
                helpText={
                  !readTerms
                    ? 'Please read the terms and conditions before continuing'
                    : undefined
                }
              >
                I, {provider.firstName}, agree to the private pay terms and
                conditions.
              </FormControl>
            </Form>
          )}
        </Formik>
      </ModalContent>
      {/* not using the actual footer because this form is rendered in several places, some of which
      are not migrated to Helix modal yet. This allows us to use this form in both. */}
      <div className="hlx-modal-footer">
        <div className="hlx-modal-footer-inner flex justify-end">
          <Button type="submit" variant="primary" form="self-pay-opt-in">
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
