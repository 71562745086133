import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseUserInsuranceQueryKeyArgs {
  userInsuranceId: number | undefined;
}

export const getUseUserInsuranceQueryKey = ({
  userInsuranceId,
}: UseUserInsuranceQueryKeyArgs) => ['user-insurance', userInsuranceId];

const { useSingleQuery: useUserInsurance, useListQuery: useUserInsuranceList } =
  createBasicApiHooks(
    getUseUserInsuranceQueryKey,
    ({ userInsuranceId }) =>
      UserInsuranceApi.getUserInsurance(userInsuranceId!),
    ({ userInsuranceId }) => !!userInsuranceId,
    () => 'Failed to fetch user insurance'
  );

export { useUserInsurance, useUserInsuranceList };
