import { useFormikContext } from 'formik';
import { useProvider } from 'hooks';
import moment from 'moment';
import { useContext, useState } from 'react';

import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';

import { createJsonFromTemplate, useTemplate } from '../Template';
import { PlanJson } from '../Template/types';
import { trackBeginButtonClickedEvent } from '../TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from '../TreatmentPlanContext';
import { getTreatmentPlanName } from '../TreatmentPlanUtils';
import { Page } from './Page';

export const StartPage = () => {
  const {
    initializeTemplateForm,
    setPage,
    setIsNewTreatmentPlan,
    providerPatient,
    setTreatmentPlan,
    prefillTreatmentPlan,
    setPrefillTreatmentPlan,
    treatmentPlans,
  } = useContext(TreatmentPlanContext);
  const { user } = useAuthStore();
  const provider = useProvider();
  const isImpersonatingProvider = isGroupAdminImpersonatingProvider(
    provider,
    user
  );
  const template = useTemplate(providerPatient?.userId);

  const [selectedPlanType, setSelectedPlanType] =
    useState<TREATMENT_PLAN_PAGES>(
      isImpersonatingProvider
        ? TREATMENT_PLAN_PAGES.UPLOAD
        : TREATMENT_PLAN_PAGES.TEMPLATE
    );
  const { values } = useFormikContext();

  const { createTreatmentPlan } = useProviderTreatmentPlan(
    providerPatient!.userId
  );

  const previousPlans = treatmentPlans
    ?.filter((plan) => {
      const validTemplateVersion =
        plan.planType === TreatmentPlanType.TEMPLATE
          ? plan.templateVersion === template.version
          : true;
      return (
        TREATMENT_PLAN_PAGES[plan.planType] === selectedPlanType &&
        !!plan.attestedOn &&
        validTemplateVersion
      );
    })
    .sort(
      (planA, planB) =>
        +new Date(planB.updatedOn!) - +new Date(planA.updatedOn!)
    );

  if (!previousPlans) {
    setPrefillTreatmentPlan(undefined);
  }

  return (
    <Page
      buttons={[
        <Button
          key="begin"
          variant="primary"
          onPress={async () => {
            trackBeginButtonClickedEvent({
              providerPatient: providerPatient!,
              treatmentPlanType: selectedPlanType,
            });
            if (selectedPlanType === TREATMENT_PLAN_PAGES.TEMPLATE) {
              const planJson =
                prefillTreatmentPlan &&
                (prefillTreatmentPlan?.planJson as PlanJson)?.template
                  ? (prefillTreatmentPlan.planJson as PlanJson)
                  : {
                      template: createJsonFromTemplate(template.template),
                    };
              initializeTemplateForm(planJson.template);
              setTreatmentPlan(
                await createTreatmentPlan({
                  values,
                  planType: TreatmentPlanType.TEMPLATE,
                  templateVersion: template.version,
                  shouldAttestPlan: false,
                })
              );
            }
            setIsNewTreatmentPlan(true);
            setPage(selectedPlanType);
          }}
        >
          Begin
        </Button>,
      ]}
    >
      <div
        css={{
          padding: `${theme.spacing.x5} 0`,
        }}
      >
        <SectionHeader>
          Let's work together to write a treatment plan
        </SectionHeader>
      </div>
      {!isImpersonatingProvider && (
        <div css={{ marginBottom: theme.spacing.x6 }}>
          <GuidanceCard variant="compliance" title="Headway is here to help">
            <BodyText>
              Our templates offer structure and support, making it easier to
              write treatment plans that meet insurers' standards.
            </BodyText>
            <Link
              href="https://findheadway.zendesk.com/hc/en-us/articles/14936779297172"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Learn more about treatment plans</strong>
            </Link>
          </GuidanceCard>
        </div>
      )}
      <div css={{ marginBottom: theme.spacing.x6 }}>
        <BigRadioGroup
          name="treatmentPlanType"
          onChange={(value: string) => {
            setSelectedPlanType(value as TREATMENT_PLAN_PAGES);
            setPrefillTreatmentPlan(undefined);
          }}
          value={selectedPlanType}
          aria-label="How would you like to create your treatment plan?"
        >
          <BigRadio
            key="template"
            value={TREATMENT_PLAN_PAGES.TEMPLATE}
            disabled={isImpersonatingProvider}
          >
            Use Headway template
          </BigRadio>
          <BigRadio
            key="text"
            value={TREATMENT_PLAN_PAGES.TEXT}
            disabled={isImpersonatingProvider}
          >
            Write from scratch
          </BigRadio>
          <BigRadio key="upload" value={TREATMENT_PLAN_PAGES.UPLOAD}>
            Upload existing plan
          </BigRadio>
        </BigRadioGroup>
      </div>
      <div css={{ marginBottom: theme.spacing.x6 }}>
        {!isImpersonatingProvider && (
          <BodyText>
            Choose the Headway template to walk through key plan requirements,
            step by step.
          </BodyText>
        )}
      </div>
      {(selectedPlanType === TREATMENT_PLAN_PAGES.TEMPLATE ||
        selectedPlanType === TREATMENT_PLAN_PAGES.TEXT) &&
        previousPlans &&
        previousPlans.length > 0 && (
          <div css={{ marginBottom: theme.spacing.x6 }}>
            <Select
              name="treatmentPlanPrefill"
              label="Prefill treatment plan"
              selectionMode="single"
              placeholder="Select a previous treatment plan"
              menuWidth="stretch"
              items={previousPlans.map((plan) => ({
                key: plan.id,
                name: `${
                  treatmentPlans
                    ? getTreatmentPlanName(plan.id, treatmentPlans)
                    : ''
                } (${moment(plan.updatedOn).format('MM/DD/YYYY')})`,
              }))}
              selectedKeys={
                prefillTreatmentPlan ? [prefillTreatmentPlan.id] : []
              }
              onSelectionChange={(keys) => {
                const [value] = keys;
                const selectedTreatmentPlan = treatmentPlans?.find(
                  (plan) => plan.id === parseInt(value)
                );
                setPrefillTreatmentPlan(selectedTreatmentPlan);
              }}
            >
              {(plan) => <Item>{plan.name}</Item>}
            </Select>
          </div>
        )}
    </Page>
  );
};
