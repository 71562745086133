import React from 'react';
import { View } from 'react-big-calendar';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';

import { PatientBookedMessages } from './utils/Calendar';

/**
 * CalendarContext represents the state of the calendar that
 * nested components depend on. The hope is to reduce prop drilling
 * for common data that most nested components require.
 */

export type CalendarContextType = {
  filters: {
    hideCancelledAppointments: boolean;
    excludedCalendars: number[];
  };
  calendars: ProviderCalendarRead[];
  view: View;
  mobileView: boolean;
  patientMessages: PatientBookedMessages;
  conflictedOpenings: Set<any>;
  carriersById: { [index: string]: FrontEndCarrierRead };
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
  eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
  isProviderFrontendCarriersLoading: boolean;
};

export const CalendarContext = React.createContext<CalendarContextType>({
  filters: {
    hideCancelledAppointments: false,
    excludedCalendars: [],
  },
  calendars: [],
  view: 'week',
  mobileView: false,
  patientMessages: [],
  conflictedOpenings: new Set(),
  carriersById: {},
  freezeReasonsByUser: {},
  eventIdsToMatchingProviderFrontEndCarriers: new Map(),
  isProviderFrontendCarriersLoading: false,
});
