import * as React from 'react';

export const HighlightPrescriber = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M48 0H0v48h48V0z" fill="#fff" />
    <g clipPath="url(#highlight-prescriber_svg__clip0_2045_27245)">
      <path
        d="M15.498 30.194c5.798 0 10.498-4.745 10.498-10.597C25.996 13.744 21.296 9 15.498 9 9.7 9 5 13.744 5 19.597c0 5.852 4.7 10.597 10.498 10.597z"
        fill="#DCF3FF"
      />
      <path
        d="M39.925 12.104A10.38 10.38 0 0032.502 9H14.717v9.84C9.291 19.246 5 23.827 5 29.406c0 5.578 4.708 10.596 10.498 10.596 5.79 0 10.065-4.334 10.464-9.808h6.54a10.38 10.38 0 007.423-3.104A10.578 10.578 0 0043 19.597c0-2.83-1.092-5.492-3.075-7.493zm-23.649 8.315c4.308.377 7.747 3.85 8.123 8.199h-8.123v-8.2zm-.78 18.007c-4.928 0-8.937-4.046-8.937-9.02 0-4.709 3.594-8.586 8.158-8.985v9.773H24.4c-.398 4.607-4.236 8.235-8.901 8.235l-.002-.003zm23.325-12.45a8.835 8.835 0 01-6.319 2.642h-6.54c-.38-5.217-4.518-9.391-9.686-9.778v-8.266h16.226c2.387 0 4.631.937 6.32 2.642a9.004 9.004 0 012.617 6.378c0 2.41-.93 4.675-2.618 6.38v.002z"
        fill="#128656"
      />
    </g>
    <defs>
      <clipPath id="highlight-prescriber_svg__clip0_2045_27245">
        <path fill="#fff" transform="translate(5 9)" d="M0 0h38v31H0z" />
      </clipPath>
    </defs>
  </svg>
);
