/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { FrontEndCarrierCreate } from '../models/FrontEndCarrierCreate';
import { FrontEndCarrierInferenceRequest } from '../models/FrontEndCarrierInferenceRequest';
import { FrontEndCarrierInferenceResponse } from '../models/FrontEndCarrierInferenceResponse';
import { FrontEndCarrierRead } from '../models/FrontEndCarrierRead';
import { FrontEndCarrierReadByUser } from '../models/FrontEndCarrierReadByUser';
import { FrontEndCarrierUpdate } from '../models/FrontEndCarrierUpdate';
import { HTTPValidationError } from '../models/HTTPValidationError';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class FrontEndCarrierApiResource {

    /**
     * Create Front End Carrier
     * 
     * @param frontEndCarrierCreate 
     
     */
    public createFrontEndCarrier(body: FrontEndCarrierCreate, axiosConfig?: AxiosRequestConfig): Promise<FrontEndCarrierRead> {
        const apiRoute = '/front-end-carrier';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Front End Carrier
     * 
     * @param frontEndCarrierId 
     
     */
    public getFrontEndCarrier(frontEndCarrierId: number, axiosConfig?: AxiosRequestConfig): Promise<FrontEndCarrierRead> {
        const apiRoute = '/front-end-carrier/{front_end_carrier_id}';
        const reqPath = apiRoute
                    .replace('{' + 'front_end_carrier_id}', String(frontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Front End Carrier Inference
     * 
     * @param frontEndCarrierInferenceRequest 
     
     */
    public getFrontEndCarrierInference(body: FrontEndCarrierInferenceRequest, axiosConfig?: AxiosRequestConfig): Promise<FrontEndCarrierInferenceResponse> {
        const apiRoute = '/front-end-carrier/inference';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Front End Carriers
     * 
     * @param excludeFullyOutOfNetwork 
     
     */
    public getFrontEndCarriers(query?: { exclude_fully_out_of_network?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<FrontEndCarrierRead>> {
        const apiRoute = '/front-end-carrier';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Searchable Front End Carriers
     * 
     * @param knownFrontEndCarrierIds 
     
     */
    public getSearchableFrontEndCarriers(query?: { known_front_end_carrier_ids?: Array<number> }, axiosConfig?: AxiosRequestConfig): Promise<Array<FrontEndCarrierReadByUser>> {
        const apiRoute = '/front-end-carrier/searchable';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Front End Carrier
     * 
     * @param frontEndCarrierId 
     * @param frontEndCarrierUpdate 
     
     */
    public updateFrontEndCarrier(frontEndCarrierId: number, body: FrontEndCarrierUpdate, axiosConfig?: AxiosRequestConfig): Promise<FrontEndCarrierRead> {
        const apiRoute = '/front-end-carrier/{front_end_carrier_id}';
        const reqPath = apiRoute
                    .replace('{' + 'front_end_carrier_id}', String(frontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const FrontEndCarrierApi = new FrontEndCarrierApiResource();
