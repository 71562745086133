import * as React from 'react';

export const HeadwayAcademy = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M135.782 197.045l-8.532-4.58a.463.463 0 00-.442 0l-8.533 4.58a.468.468 0 00-.007.821l3.1 1.743v4.441c0 .18.104.346.266.423l5.258 2.491c.13.061.276.061.405-.002l5.128-2.486a.466.466 0 00.264-.421v-4.446l1.689-.95v7.096a.469.469 0 00.936 0v-7.622l.475-.267a.468.468 0 00-.007-.82z"
      transform="translate(-117.028 -189.71)"
      fill="currentColor"
    ></path>
  </svg>
);
