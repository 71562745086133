import * as React from 'react';

export const Checkmark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={15}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path d="M0 14.98h14v-14H0v14z" fill="url(#checkmark_svg__pattern0)" />
    <defs>
      <pattern
        id="checkmark_svg__pattern0"
        patternContentUnits="objectBoundingBox"
        width={1}
        height={1}
      >
        <use
          xlinkHref="#checkmark_svg__image0_759_19227"
          transform="scale(.01563)"
        />
      </pattern>
      <image
        id="checkmark_svg__image0_759_19227"
        width={64}
        height={64}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAEX0lEQVR4Ae2bA5BrSRSGT+zk2bZt2/Yb27b1bBTWts3C2rZt2+j919496bk3c9OTr+orznRy/r483aHWjCCyktJEiOCAXuihVnS494NZ8AJ4G7z3F2+Ce2AXUpRR8Ez4KhT/pNVqFX379i0iBUmGb0DxX/bo0ePLLVu2zCXF2Ai//b/izWazmDt37vWkGJ3gC1D8n506dfp28+bNK0kxUjjFm0wmMW3atLtjYmKspBA++DAngLZt24qNGzcmkmKsgYLjhAkTHo+NjXWr9oBzI6d4v98v1q1bV0yKMRcKjqNHj34Bs9+OFMICL+YU7/F4xJo1a7aRYkyE33ACGDZs2OtxcXHdSTFO4RTvdDrFypUrj5BiDIefcwIYNGjQuzj3+5NiHOUU73A4xPLly08ixRgAP+QE0L9//w/x1DecFGMXp3ibzSaWLFlyNilGb/gOJ4A+ffp8Eh0dPZ4Uo447+4sXL76QFKMHfIMTQK9evT7DlX8KKUYFp/gf212LFi26jBSjK3yVE0DPnj2/wJV/OilGCXf2Fy5ceCUpRhf4MhScZidmfybphB0OgOthJdwJM+Fg0pdCTvEWi0W32e8GS+DN/3IVfh3Gkj50hi8yz33NZ9/6S/pPM77A87CtnrPPuPJfThrih2dDEYSVOsz+S5zP7t279xd4359GGtIERZC+pPF6W0UQz/znk8ZcCoWEezR86nuT85n9+vX7JD4+foy+rWa+b8F+oXrmt9vtYsWKFaeRDjjhjZIhnBCqNz50ez7E7A8lnVgkGcAncDzJs4Pb61u1atWxpCNWeLVkCFc2o9vzAbPT+05CQkJf0pnp8FuJAL6FMiuwhznju91usXbt2v0UIs6EQsL7oYv4jISfcsYeNWrUq0lJSV1D2YL+VDKEYuJzKmdMn8/34wpvLYWYvZIBvAZ7M8afCr/mjDlx4sRnMPttKcR0h69IhnAKY43vSs5Y7dq1E1u3bs2kFiJDMoCv4EL6d1Zyd3fMmjXrnuTkZAe1EB54p2QI90H3v4x5H2eMbt26fYdG52pqYebB7yRDaKS/k8V93V26dOnlZBBOkgzgYziVfqcnfJ3zv4MHD/4Ih/4oMgh9cT6+IRnCPX84FY5yb3vY1rabDEYqQhCSIeyA0+GXnL+fMWPGo+np6QEyGLZmvCd8CV9hrvB8h3v+KjIow3EUvMcoREqXyyXWr19/EhmcTNlTgXHoP5mZmdmJDI4FAVyodfHY2PB1WlraMgoTeiGEZ7QqvkOHDgId3rDb0jYfIfz3piXmbk7c8s7Oy8uzUBiS05zrQfv27QV+xHBBUVGRj8IV9Oh3YI0u6OLR3PwaT3qHy8rKXBTuBAKBBo/Hw3q379q164/P+HcWFxevJZXAYsV6LFfdjf35P93PcVT8JDq5Px3qAwcOfB/9/Btzc3NTKyoqfKQi2JvvmzNnziosWR/9ccvKsmXLrty0adNJONRz8vPzR5WXl9vJIESIECHyE/IfAP6pHI7qYho0AAAAAElFTkSuQmCC"
      />
    </defs>
  </svg>
);
