import LockIcon from '@mui/icons-material/LockOutlined';
import { FormHelperText, Link } from '@mui/material';
import {
  CardElement,
  CardElementProps as StripeCardElementProps,
} from '@stripe/react-stripe-js';
import React from 'react';

import { theme } from '../theme';
// @ts-ignore
import styles from './CardElementWithError.module.css';

interface CardElementProps {
  onChange?: StripeCardElementProps['onChange'];
  stripeError?: string;
  setStripeError: (errorMessage?: string) => void;
}
export const CardElementWithError: React.FC<
  React.PropsWithChildren<CardElementProps>
> = ({ onChange, stripeError, setStripeError }) => {
  return (
    <div data-testid="creditCardInput">
      <CardElement
        options={{
          classes: {
            base: `${styles.wrapper} ${stripeError ? 'Mui-error' : ''}`,
            invalid: 'Mui-error',
          },
        }}
        onChange={(e) => {
          onChange?.(e);
          setStripeError(e.error?.message);
        }}
      />
      {stripeError && (
        <FormHelperText variant="standard" sx={{ color: theme.color.error }}>
          {stripeError}
        </FormHelperText>
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginTop: theme.space.xs,
        }}
      >
        <LockIcon
          color="action"
          fontSize="small"
          css={{ marginRight: theme.space.xs2 }}
        />
        <FormHelperText variant="standard" id="standard-weight-helper-text">
          Your payment info will be stored securely by{' '}
          <Link
            href="https://stripe.com"
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            Stripe.
          </Link>
        </FormHelperText>
      </div>
    </div>
  );
};
