import { css } from '@emotion/react';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form as HelixForm } from '@headway/helix/Form';
import { Menu, MenuItem, MenuTrigger, Section } from '@headway/helix/Menu';
import { theme } from '@headway/helix/theme';

import {
  ComponentMap,
  FormFile,
  FormInfo,
  FormState,
} from '../schema/schema.types';
import { Component, Form } from '../schema/schema.types';
import { getBlocks } from './blocks';
import { blockDefinitions } from './blocks/BlockDefinitions';
import Paragraph from './blocks/default/Paragraph';
import { FormStateEdit } from './edit/FormState';
import Delete from './layout/Delete';
import MoveOutOfSection from './layout/MoveComponentOutOfSection';
import MoveDown from './layout/MoveDown';
import MoveToSectionAbove from './layout/MoveToAboveSection';
import MoveToBelowSection from './layout/MoveToBelowSection';
import MoveUp from './layout/MoveUp';
import { LayoutTreeNode } from './layoutTree';
import {
  createLocalStorageFormFileName,
  deleteForm,
  getNewFileName,
  getNewLocalId,
  Project,
  saveFormFile,
} from './localSaving';
import { RendererContext } from './RendererContext';
import { convertBlocksToSchema, convertSchemaToBlocks } from './utils';

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

export const Builder = ({
  formFile,
  form,
  info,
  project,
  onBuilderClose,
  stickyToolBoxTop,
  defaultState,
}: {
  project: Project;
  formFile: FormFile;
  form: Form;
  info?: FormInfo;
  onBuilderClose: () => void;
  defaultState: FormState;
  stickyToolBoxTop: number;
}) => {
  const editorEL = useRef(null);
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [editor, setEditor] = useState<any>(undefined);
  const [editorInitStarted, setEditorInitStarted] = useState(false);
  const forceUpdate = useForceUpdate();

  const [formState, setFormState] = useState<FormState | undefined>(
    formFile?.state ?? defaultState ?? {}
  );
  const [showFormState, setShowFormState] = useState(false);

  const [formInfo, setFormInfo] = useState<FormInfo>({
    name: info ? info.name : getNewFileName(project).formName,
    id: info ? info.id : getNewLocalId(project),
    version: 1,
  });

  const [currentEditingBlock, setCurrentEditingBlock] = useState<
    React.ReactNode | undefined
  >(undefined);
  const onBlockFocused = (component: React.ReactNode) => {
    setCurrentEditingBlock(component);
  };

  const [showLayoutTree, setShowLayoutTree] = useState(false);
  const [refreshLayoutTree, setRefreshLayoutTree] = useState(1);

  const updateFormState = (state: FormState) => {
    setFormState(state);
    RendererContext.state = state;
  };

  useEffect(() => {
    const fetchPackagesAndInitEditor = async () => {
      /*
        We need to fetch those packages dynamically so that it still works on our Patient app
        (with Next JS being server-side rendering and requiring the window object)
      */
      const { default: EditorJS } = await import('@editorjs/editorjs');

      // TODO: Plug in drag and drop to the layout tree
      //const { default: DragDrop } = await import('editorjs-drag-drop');

      if (editorEL.current && !isEditorLoaded) {
        const editorSchemaRepresentation = convertSchemaToBlocks(
          form as Component[]
        );

        RendererContext.layoutTree = editorSchemaRepresentation.layoutTree;
        RendererContext.state = formState ?? {};
        RendererContext.componentDefinitions = blockDefinitions;
        RendererContext.componentMap = ComponentMap;

        const newEditor = new EditorJS({
          holder: 'editor',
          data: {
            blocks: editorSchemaRepresentation.blocks,
          },
          tools: {
            ...getBlocks(onBlockFocused, blockDefinitions, ComponentMap),
            paragraph: Paragraph,
            moveToSectionAbove: MoveToSectionAbove as any,
            moveToBelowSection: MoveToBelowSection as any,
            moveOutOfSection: MoveOutOfSection as any,
            moveUp: MoveUp as any,
            moveDown: MoveDown as any,
            delete: Delete as any,
          },
          placeholder: 'Click the plus icon to add a component',
          autofocus: true,
          tunes: [
            'moveToSectionAbove',
            'moveToBelowSection',
            'moveOutOfSection',
            'moveUp',
            'moveDown',
            'delete',
          ],
          onChange: async () => {
            forceUpdate();
          },
        });

        const waitTillReady = async () => {
          await newEditor.isReady;
          setEditor(newEditor as any);

          RendererContext.getBlockByComponentId = (id: string) => {
            if (!editorEL.current) {
              return;
            }

            const blockContainer = (editorEL.current as HTMLElement).firstChild
              ?.firstChild;

            if (!blockContainer?.childNodes) {
              return;
            }

            for (let i = 0; i < blockContainer?.childNodes.length; i++) {
              const holder =
                blockContainer.childNodes[i].firstChild?.firstChild;

              if (holder) {
                const schemaId = (holder as any).getAttribute('fb-id');
                if (schemaId === id) {
                  return newEditor.blocks.getBlockByIndex(i);
                }
              }
            }
          };
        };

        waitTillReady();
        setIsEditorLoaded(true);
      }
    };

    if (editorInitStarted) {
      return;
    }

    setEditorInitStarted(true);
    fetchPackagesAndInitEditor().catch((error) => console.log(error));
  }, [forceUpdate, form, isEditorLoaded]);

  const onFileOption = async (key: 'save' | 'delete') => {
    if (!editor) {
      return;
    }

    const saveData = await (editor as any).save();
    const form = convertBlocksToSchema(
      RendererContext.layoutTree,
      saveData.blocks
    );

    switch (key) {
      case 'save':
        saveFormFile(project, formInfo, form, formState ?? {});
        break;

      case 'delete':
        deleteForm(
          createLocalStorageFormFileName(
            project,
            formInfo.name,
            formInfo.id,
            formInfo.version
          )
        );
        break;
    }

    onBuilderClose();
  };

  return (
    <div>
      <div css={editorCss.header}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f6f6f6',
            padding: theme.spacing.x4,
          }}
        >
          <Button variant="link" onPress={onBuilderClose}>
            Back
          </Button>
          <div css={{ marginLeft: theme.spacing.x10 }}>
            <div
              contentEditable
              onBlur={(e) => {
                setFormInfo({
                  ...formInfo,
                  name: e.currentTarget.innerText,
                });
              }}
              css={{ ...theme.typography.header }}
              suppressContentEditableWarning={true}
            >
              {formInfo ? formInfo.name : ''}
            </div>
          </div>
        </div>
        <div
          css={{
            borderBottom: '1px solid #dadce0',
            backgroundColor: 'white',
          }}
        >
          <div
            css={{
              padding: theme.spacing.x4,
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
            }}
          >
            <MenuTrigger>
              <Button variant="link">File</Button>
              <Menu onAction={(key) => onFileOption(key as 'save' | 'delete')}>
                <MenuItem key="save">Save</MenuItem>
                <MenuItem key="delete">Delete</MenuItem>
              </Menu>
            </MenuTrigger>
            <MenuTrigger>
              <Button variant="link">Edit</Button>
              <Menu onAction={(key) => {}}>
                <MenuItem key="Undo">Undo (coming soon)</MenuItem>
                <MenuItem key="Redo">Redo (coming soon)</MenuItem>
              </Menu>
            </MenuTrigger>
            <MenuTrigger>
              <Button variant="link">Tools</Button>
              <Menu
                onAction={(key) => {
                  setShowFormState(true);
                }}
              >
                <MenuItem key="Edit form state">Edit form state</MenuItem>
              </Menu>
            </MenuTrigger>
            <MenuTrigger>
              <Button variant="link">Help</Button>
              <Menu
                onAction={(key) => {
                  setShowLayoutTree(true);
                }}
              >
                <Section aria-label="Advanced">
                  <MenuItem key="View Layout Tree">View Layout Tree</MenuItem>
                </Section>
              </Menu>
            </MenuTrigger>
          </div>
        </div>
      </div>
      <div css={editorCss.editorContainer}>
        <div css={{ flex: 0.7, minWidth: 780 }}>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <HelixForm name="builder">
              {RendererContext.portals.length > 0
                ? RendererContext.portals.map((portal: any) => {
                    return createPortal(
                      <div
                        css={{
                          border:
                            portal.component &&
                            portal.component.props.data &&
                            portal.component.props.data.id ===
                              (currentEditingBlock as any)?.props.component.id
                              ? `solid ${theme.color.primary.blue} 1px`
                              : `solid transparent 1px`,
                          padding: theme.spacing.x2,
                        }}
                      >
                        {portal.component}
                      </div>,
                      portal.holder
                    );
                  })
                : []}
              <div ref={editorEL} id="editor" css={editorCss.editor} />
            </HelixForm>
          </Formik>
        </div>

        <div
          css={[
            editorCss.blockEditPopup,
            {
              alignItems:
                currentEditingBlock || showLayoutTree ? 'flex-start' : 'center',
              justifyContent:
                currentEditingBlock || showLayoutTree ? 'flex-start' : 'center',
              top: 24 + stickyToolBoxTop,
            },
          ]}
        >
          {showLayoutTree && refreshLayoutTree ? (
            <div
              css={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                overflow: 'scroll',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: theme.spacing.x2,
                }}
              >
                <Button
                  onPress={() => setRefreshLayoutTree(refreshLayoutTree + 1)}
                >
                  Refresh
                </Button>
                <Button onPress={() => setShowLayoutTree(false)}>Close</Button>
              </div>
              <pre>{JSON.stringify(RendererContext.layoutTree, null, 2)}</pre>
            </div>
          ) : showFormState ? (
            <FormStateEdit
              state={formState}
              setFormState={(state) => updateFormState(state)}
              onClose={() => setShowFormState(false)}
            />
          ) : currentEditingBlock ? (
            <div css={{ display: 'flex', flex: 1 }}>
              {window.screen.width >= theme.breakpoints.desktop ? (
                <div css={{ flex: 1 }}>{currentEditingBlock}</div>
              ) : (
                <div css={{ flex: 1 }}>
                  {createPortal(currentEditingBlock, document.body)}
                </div>
              )}
            </div>
          ) : (
            <div css={{ textAlign: 'center' }}>
              <BodyText>
                Pick a component on the left side to see it's properties here
              </BodyText>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const editorCss = {
  blockEditPopup: css({
    position: 'relative',
    display: 'flex',
    flex: 0.3,
    marginRight: 24,
    minWidth: 300,
    height: 'calc(100vh - 230px)',
    overflowY: 'auto',
    padding: '16px',
    backgroundColor: 'white',
    border: '1px solid #e8e8eb',
    boxShadow: '0 3px 15px -3px rgba(13,20,33,.13)',
    borderRadius: '6px',
  }),
  editorContainer: css({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    height: '100%',
  }),
  editor: css({
    flex: 1,
    height: '100%',
    padding: '8px',
    paddingTop: '24px',
  }),
  header: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
  }),
};
