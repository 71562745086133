import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Button } from '@headway/helix/Button';
import { ListRow } from '@headway/helix/List';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';

import { useGroupPracticeProviders } from 'hooks/useGroupPracticeProviders';
import { useAuthStore } from 'stores/AuthStore';
import { useSetSelectedProvider } from 'utils/practice';

import { ProviderAvatar } from './ProviderAvatar';

export const ManageTeamModal = ({
  isOpen,
  onDismiss,
}: {
  isOpen: boolean;
  onDismiss: () => void;
}) => {
  const AuthStore = useAuthStore();
  const { data: providers } = useGroupPracticeProviders(
    {
      groupPracticeId: AuthStore.user?.group_practice?.id,
      query: { is_active: true },
    },
    {
      select: (providers) =>
        sortBy(providers, (provider) =>
          getProviderDisplayFirstAndLast(provider)
        ),
    }
  );

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} title="Manage team">
      <ModalContent>
        <ul className="p-0">
          {(providers || []).map((provider: ProviderRead) => (
            <ProviderListRow
              key={provider.id}
              provider={provider}
              onDismiss={onDismiss}
            />
          ))}
        </ul>
      </ModalContent>
      <ModalFooter>
        <Button onPress={onDismiss}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

const ProviderListRow = ({
  provider,
  onDismiss,
}: {
  provider: ProviderRead;
  onDismiss: () => void;
}) => {
  const setSelectedProvider = useSetSelectedProvider();
  const handleProviderClick = () => {
    setSelectedProvider(provider);
    onDismiss();
  };
  return (
    <ListRow key={provider.id}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <ProviderAvatar provider={provider} size="medium" />
          <div className="flex-col">
            <div>
              <Button variant="link" onPress={handleProviderClick}>
                {getProviderDisplayFirstAndLastWithPrenomial(provider)}
              </Button>
            </div>
            <SubBodyText>{provider.email}</SubBodyText>
          </div>
        </div>
        <Button variant="secondary">Archive</Button>
      </div>
    </ListRow>
  );
};
