import moment from 'moment';
import React from 'react';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';

import { formatPhoneNumberToString } from 'utils/providerQuestionnaire';
import {
  CaqhKeyValuePair,
  CaqhSection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';

export const PersonalInformationHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  const AliasNamesCaqhValuePair = () => {
    const filteredAliases = initialValues?.aliases
      ?.filter((alias) => alias.firstName && alias.lastName)
      .map((alias) =>
        [alias.firstName, alias.middleName, alias.lastName, alias.suffix]
          .filter((token) => !!token)
          .join(' ')
      );
    if (!filteredAliases || filteredAliases.length === 0) {
      return <></>;
    }
    const aliasSection = (
      <div>
        {filteredAliases.map((alias) => (
          <div>{alias}</div>
        ))}
      </div>
    );
    return <CaqhKeyValuePair label="Aliases" value={aliasSection} />;
  };

  return (
    <CaqhSection wrapColumns>
      <CaqhKeyValuePair
        label="Legal First Name"
        value={initialValues?.firstName}
      />
      {initialValues?.middleName && (
        <CaqhKeyValuePair
          label="Legal Middle Name"
          value={initialValues?.middleName}
        />
      )}
      <CaqhKeyValuePair
        label="Legal Last Name"
        value={initialValues?.lastName}
      />
      {initialValues?.suffix && (
        <CaqhKeyValuePair label="Suffix" value={initialValues?.suffix} />
      )}
      <AliasNamesCaqhValuePair />
      {/* We are not collecting SSN anymore */}
      <CaqhKeyValuePair
        label="Birth Date"
        value={moment(initialValues.birthDate).format('MM/DD/YYYY')}
      />
      <CaqhKeyValuePair label="Email" value={initialValues.email} />
      <CaqhKeyValuePair
        label="Phone"
        value={formatPhoneNumberToString(initialValues.phone)}
      />
    </CaqhSection>
  );
};
