import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ProviderAttestationResponse } from '@headway/api/models/ProviderAttestationResponse';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageHeader } from '@headway/helix/PageHeader';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Loader } from '@headway/ui/Loader';

import { useTailwindGlobals } from '../../utils/css';

export const AttestationConfirmation = () => {
  const { search } = useLocation();
  const { token, provider_id } = qs.parse(search, { ignoreQueryPrefix: true });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showErrorView, setShowErrorView] = useState<boolean>(false);

  const confirmProviderAttestation = async () => {
    const isInvalidToken = !token || typeof token !== 'string';
    const isInvalidProviderId = !provider_id || typeof provider_id !== 'string';

    if (isInvalidToken || isInvalidProviderId) {
      handleError(
        isInvalidToken ? 'Invalid token format' : 'Invalid provider_id format'
      );
      return;
    }

    let last_attested_date = undefined;
    try {
      const response_data: ProviderAttestationResponse =
        await AuthApi.confirmProviderAttestation({
          token: token,
          provider_id: Number(provider_id),
        });
      last_attested_date = response_data.lastAttestedOn;
    } catch (error: any) {
      handleError(error.toString());
      return;
    }

    setIsLoading(false);
    trackAnalyticsEvent(undefined, last_attested_date);
  };

  useEffect(() => {
    confirmProviderAttestation();
  });

  useTailwindGlobals();

  const handleError = (exception?: string) => {
    setShowErrorView(true);
    trackAnalyticsEvent(exception);
    setIsLoading(false);
  };

  const trackAnalyticsEvent = (
    errorMessage?: string,
    last_attested_date?: string
  ) => {
    trackEvent({
      name: 'Provider Attestation Confirmed',
      properties: {
        numDaysSinceLastAttested: last_attested_date
          ? moment()
              .startOf('day')
              .diff(moment(last_attested_date).startOf('day'), 'days')
          : undefined,
        lastAttestedDate: last_attested_date,
        errorMessage: errorMessage,
      },
    });
  };

  return (
    <div className="mx-auto w-full px-4 tablet:w-[970px] desktop:w-[1020px]">
      <div className="mx-auto flex max-w-2xl flex-col justify-center gap-4 bg-system-white p-5 shadow-medium">
        <div>
          <h1>
            <PageHeader>Verifying your information</PageHeader>
          </h1>
          <p>
            <BodyText>This helps us keep your records up to date</BodyText>
          </p>
        </div>
        {isLoading ? (
          <div className="relative mt-12 flex min-h-[80px] items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            {showErrorView ? (
              <GuidanceCard variant="error" layout="vertical">
                <BodyText>
                  <strong>We couldn't save your information: </strong>
                  You can submit your information again through your provider
                  portal. Heads up, you might need to log into your account
                  first.
                </BodyText>
                <LinkButton
                  component={Link}
                  elementType="a"
                  to="/home"
                  variant="link"
                >
                  Go to provider portal
                </LinkButton>
              </GuidanceCard>
            ) : (
              <GuidanceCard variant="positive" title="You're all set!">
                <BodyText>
                  Feel free to close this page or view any updated information
                  in your account.
                </BodyText>

                <LinkButton
                  component={Link}
                  elementType="a"
                  to="/home"
                  variant="link"
                  size="large"
                >
                  View account
                </LinkButton>
              </GuidanceCard>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AttestationConfirmation;
