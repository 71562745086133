import * as React from 'react';

export const Loading = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.498 1.665v1.667a6.665 6.665 0 016.667 6.666 6.665 6.665 0 01-6.666 6.667 6.665 6.665 0 01-6.667-6.667H2.165c0 4.6 3.733 8.334 8.333 8.334s8.334-3.734 8.334-8.334-3.734-8.333-8.334-8.333z"
      fill="currentColor"
    />
  </svg>
);
