import { FormikErrors, useFormikContext } from 'formik';
import React from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import {
  PracticeLocation,
  PracticeLocationArray,
  SetSelectedPracticeLocations,
} from './Practice';
import { PracticeLocationInfo } from './PracticeLocationInfo';
import { getSelectedLocationsForState } from './util';

/**
 *
 * Upates the selectedProviderLocations changing,
 * -  updating the selected location isPrimaryPracticeState to true
 * -  updating other locations for the state isPrimaryPracticeState to true
 *
 * @param selectedPracticeLocations
 * @param setSelectedPracticeLocations
 * @param state
 * @param newLocationHash
 * @returns
 */
const setNewProviderSelectedLocations = (
  selectedPracticeLocations: PracticeLocationArray,
  setSelectedPracticeLocations: SetSelectedPracticeLocations,
  state: UnitedStates,
  newLocationHash: string
) => {
  const selectedLocationsForState = getSelectedLocationsForState(
    selectedPracticeLocations,
    state
  );

  const currentSelectedLocationIndex = selectedPracticeLocations.findIndex(
    (location) => location.locationHash === newLocationHash
  );

  if (currentSelectedLocationIndex === -1) {
    return;
  }

  setSelectedPracticeLocations((locations: PracticeLocationArray) => {
    const updatedLocations: PracticeLocationArray = [...locations];

    const nonPrimaryLocations: number[] = [];

    updatedLocations.forEach((location: PracticeLocation, index: number) => {
      if (
        selectedLocationsForState.find(
          (stateLocation) =>
            stateLocation.locationHash === location.locationHash
        )
      ) {
        nonPrimaryLocations.push(index);
      }
    });

    updatedLocations[currentSelectedLocationIndex].isPrimaryPracticeForState =
      true;
    nonPrimaryLocations.forEach((index) => {
      if (index !== currentSelectedLocationIndex) {
        updatedLocations[index].isPrimaryPracticeForState = false;
      }
    });

    return updatedLocations;
  });
};

export const PracticeLocationInfoByState = ({
  selectedPracticeLocations,
  providerSelectedStates,
  setSelectedPracticeLocations,
}: {
  selectedPracticeLocations: PracticeLocationArray;
  providerSelectedStates: UnitedStates[];
  setSelectedPracticeLocations: any;
}) => {
  const { errors } = useFormikContext();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {providerSelectedStates?.map((state) => {
        const selectedLocationsForState = getSelectedLocationsForState(
          selectedPracticeLocations,
          state
        );

        const numberOfLocationsSelected = selectedLocationsForState?.length;
        const primaryAddress =
          numberOfLocationsSelected > 0
            ? selectedLocationsForState.find(
                (location) => location.isPrimaryPracticeForState
              )?.locationHash
            : '';

        const primaryNameField = `primarySelection[${state}]`;
        const missingPrimaryError = (
          errors as FormikErrors<{
            primarySelection: { [key: string]: string };
          }>
        )?.primarySelection?.[state];

        return (
          <div key={state} className="mt-8">
            <SectionHeader>
              {statesToDisplayNames[state]}:{' '}
              {numberOfLocationsSelected
                ? `${numberOfLocationsSelected} location selected`
                : 'No locations selected'}
            </SectionHeader>

            {selectedLocationsForState !== undefined &&
              selectedLocationsForState.length > 1 && (
                <div className="mb-6 mt-4">
                  <Select
                    name={primaryNameField}
                    selectionMode="single"
                    label="Which address would you like to use as your primary location in this state?"
                    menuWidth="stretch"
                    validation={
                      missingPrimaryError
                        ? {
                            validity: 'invalid',
                            message: missingPrimaryError,
                          }
                        : {
                            validity: 'valid',
                          }
                    }
                    selectedKeys={primaryAddress ? [primaryAddress] : []}
                    onSelectionChange={(change) =>
                      setNewProviderSelectedLocations(
                        selectedPracticeLocations,
                        setSelectedPracticeLocations,
                        state,
                        Array.from(change.values())[0]
                      )
                    }
                  >
                    {selectedLocationsForState.map((location) => {
                      return (
                        <Item key={location.locationHash}>
                          {location.locationHash}
                        </Item>
                      );
                    })}
                  </Select>
                </div>
              )}

            {selectedLocationsForState === undefined ||
              (selectedLocationsForState.length === 0 && (
                <div className="mb-4 mt-4">
                  <FormControl
                    name={`telehealthStates[${state}]`}
                    component={Checkbox}
                  >
                    By checking this box, I confirm that I will only be seeing
                    clients in {statesToDisplayNames[state]} virtually via
                    Telehealth.
                  </FormControl>
                </div>
              ))}

            {selectedLocationsForState &&
              selectedLocationsForState.length > 0 && (
                <div className="flex flex-col gap-2">
                  {selectedLocationsForState.map((location) => {
                    const practiceValueIndex =
                      selectedPracticeLocations.findIndex(
                        (selectedLocation) =>
                          selectedLocation.locationHash ===
                          location.locationHash
                      );
                    return (
                      <PracticeLocationInfo
                        p={location}
                        idx={practiceValueIndex}
                      />
                    );
                  })}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};
