import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';

import { theme } from '@headway/helix/theme';

import { FormValues } from '../../schema/schema.types';

export const FormError = ({ id }: { id: string }) => {
  const { errors, submitCount } = useFormikContext<FormValues>();

  const formError = submitCount > 0 ? _.get(errors, id) : undefined;

  if (!formError) {
    return null;
  }

  return (
    <div
      css={{
        color: theme.color.primary.red,
        ...theme.typography.caption.medium,
      }}
    >
      {formError as string}
    </div>
  );
};
