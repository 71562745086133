import { createContext } from 'react';

export const HEADER_HEIGHT = 50;

export const BannersContext = createContext(0);

export const calculateBannerHeight = (numBanners?: number) => {
  if (!numBanners) {
    return 0;
  }
  return HEADER_HEIGHT * numBanners;
};
