import axios from 'axios';

import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';
import { UserUploadApi } from '@headway/api/resources/UserUploadApi';

export interface UploadedFile {
  name: string;

  link: string;

  s3ObjectKey: string;

  size?: number;
}

export const uploadFileToS3: (
  file: File,
  type: UserUploadTypes,
  ownerId: number,
  publicRead?: boolean,
  includeSizeInResponse?: boolean
) => Promise<UploadedFile> = async (
  file,
  type,
  ownerId,
  publicRead = false,
  includeSizeInResponse = false
) => {
  const { signedUrl, formFieldsToSubmit, objectKey, getUrl } =
    await UserUploadApi.generatePresignedPostUrl({
      type,
      owner_id: ownerId,
      object_name: file.name,
      public_read: publicRead,
    });
  const form = new FormData();
  Object.keys(formFieldsToSubmit).forEach((key) => {
    form.append(key, formFieldsToSubmit[key]);
  });
  form.append('file', file);

  await axios.post(signedUrl, form, {
    transformRequest: (data, headers) => {
      delete headers['authorization'];
      return data;
    },
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return {
    name: file.name as string,
    link: getUrl,
    s3ObjectKey: objectKey,
    size: includeSizeInResponse ? file.size : undefined,
  };
};

export const uploadFilesToS3: (
  files: any,
  type: UserUploadTypes,
  owner_id: number,
  publicRead?: boolean,
  includeSizeInResponse?: boolean
) => Promise<ReadonlyArray<UploadedFile>> = async (
  files: any,
  type: UserUploadTypes,
  owner_id: number,
  publicRead?: boolean,
  includeSizeInResponse?: boolean
) => {
  return await Promise.all(
    files.map((file: any) =>
      uploadFileToS3(file, type, owner_id, publicRead, includeSizeInResponse)
    )
  );
};
