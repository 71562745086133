import { AriaButtonProps } from '@react-types/button';
import React, { forwardRef } from 'react';
import { mergeProps, useButton } from 'react-aria';
import { Simplify } from 'type-fest';

import { DATA } from './consts';
import { InteractionProps, useInteraction } from './useInteraction';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type IconButtonProps = Simplify<
  {
    size?: 'small' | 'medium' | 'large';
    variant?: 'default' | 'transparent';
    form?: string;
    value?: HTMLButtonElement['value'];
    name?: HTMLButtonElement['name'];
  } & InteractionProps &
    PickAndConfigure<
      AriaButtonProps<'button'>,
      'type' | 'onPress' | { children: 'children' } | 'excludeFromTabOrder'
    >
>;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      size = 'large',
      variant = 'default',
      disabled: isDisabled = false,
      name,
      value,
      ...props
    },
    ref
  ) => {
    const { ariaProps, hoverProps, focusProps, isHovered, isFocusVisible } =
      useInteraction(props);
    const { buttonProps, isPressed } = useButton(
      { ...ariaProps, isDisabled },
      ref as React.RefObject<HTMLButtonElement>
    );

    return (
      <button
        className="hlx-icon-button"
        type="button"
        {...mergeProps(buttonProps, focusProps, hoverProps, {
          [DATA.DISABLED]: isDisabled,
          [DATA.HOVERED]: isHovered,
          [DATA.FOCUSED]: isFocusVisible,
          'data-hlx-pressed': isPressed,
          'data-hlx-variant': variant,
          'data-hlx-size': size,
        })}
        name={name}
        value={value}
        form={props.form}
        ref={ref}
      >
        {props.children}
      </button>
    );
  }
);
