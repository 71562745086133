/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum SessionUnconfirmableReason {
    ALL_PAYMENT_METHODS_HAVE_INVALID_PRE_AUTHS = 'ALL_PAYMENT_METHODS_HAVE_INVALID_PRE_AUTHS',
    ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE = 'ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE',
    EXPIRED_LICENSE = 'EXPIRED_LICENSE',
    NO_ANTHEM_EAP_FOUND = 'NO_ANTHEM_EAP_FOUND',
    NO_LICENSE_FOUND_FOR_STATE = 'NO_LICENSE_FOUND_FOR_STATE',
    NOT_AN_APPOINTMENT = 'NOT_AN_APPOINTMENT'
}
