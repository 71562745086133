/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ClaimSubmissionStatus {
    PENDING = 'PENDING',
    AWAITING_RESUBMIT = 'AWAITING_RESUBMIT',
    SUBMITTED = 'SUBMITTED',
    FAILED_TO_SUBMIT = 'FAILED_TO_SUBMIT',
    CANCELED = 'CANCELED',
    HELD_BY_HEADWAY = 'HELD_BY_HEADWAY',
    PENDING_PAYER_SYSTEM_READY = 'PENDING_PAYER_SYSTEM_READY',
    DO_NOT_SUBMIT = 'DO_NOT_SUBMIT',
    RESUBMITTED = 'RESUBMITTED',
    MANUALLY_SUBMITTED = 'MANUALLY_SUBMITTED'
}
