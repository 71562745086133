import Add from '@mui/icons-material/Add';
import {
  Divider,
  IconButton,
  Switch,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/ui/theme';

import {
  ProviderWorkingHour,
  useWorkingHours,
  Weekday,
} from 'hooks/useWorkingHours';
import { useUiStore } from 'stores/UiStore';

import { WorkingHourEdit } from './WorkingHourEditForm';

export const WorkingHoursMultiSet = () => {
  return (
    <PageSection>
      <div>
        <h2>
          <SectionHeader>Working hours</SectionHeader>
        </h2>
        <PageSectionSubText>
          <BodyText>
            Set your working hours so new clients can book with you. The more
            availability you have, the more referrals you’ll receive &#8211;
            clients are 4x more likely to book with a provider who has 5+ weekly
            openings.
          </BodyText>
        </PageSectionSubText>
      </div>
      <WorkingHoursMultiEdit />
    </PageSection>
  );
};

export const WorkingHoursMultiEdit = () => {
  const [weekdayLoading, setWeekdayLoading] = React.useState(
    new Map<Weekday, boolean>()
  );
  const isSmall = useMediaQuery(theme.media.smallDown);

  const ui = useUiStore();

  const {
    workingHours,
    locations,
    createWorkingHour,
    deleteWorkingHours,
    updateWorkingHour,
    isLoading,
  } = useWorkingHours();
  return (
    <section css={{ overflowX: 'auto' }}>
      {isLoading ? (
        <React.Fragment>
          <Skeleton variant="rectangular" height={100} />
        </React.Fragment>
      ) : (
        <div>
          <div
            css={{
              display: 'flex',
              gap: theme.space.base,
              padding: theme.space.sm,
              fontSize: theme.fontSize.sm,
              fontWeight: theme.fontWeight.bold,
              borderBottom: `1px solid ${theme.color.lightGray}`,
              marginBottom: theme.space.lg,
            }}
          >
            <div css={{ width: '80px' }}>Weekday</div>
            <div>Working hours</div>
          </div>

          {Object.values(Weekday)
            .filter((w) => typeof w !== 'string')
            .map((day) => {
              const hours = workingHours[day as Weekday];
              const hasHours = !!(hours && hours.length > 0);
              return (
                <React.Fragment key={day}>
                  <div
                    css={{
                      display: 'flex',
                      gap: theme.space.base,
                      padding: theme.space.sm,
                    }}
                  >
                    <div
                      css={{
                        width: '110px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {Weekday[day as number]}
                    </div>
                    {!hours && (
                      <div
                        css={{
                          color: theme.color.textGray,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Unavailable
                      </div>
                    )}
                    <div
                      css={{
                        alignSelf: 'flex-start',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {!!(hours && hours.length > 0) ? (
                        <Tooltip
                          title="Add working hour"
                          placement="left"
                          arrow
                          enterDelay={1000}
                        >
                          <IconButton
                            disabled={weekdayLoading.get(day as Weekday)}
                            onClick={async () => {
                              if (weekdayLoading.get(day as Weekday)) {
                                return;
                              }

                              let loadingState = new Map<Weekday, boolean>(
                                weekdayLoading
                              );
                              loadingState.set(day as Weekday, true);
                              setWeekdayLoading(loadingState);
                              try {
                                const { startDate, endDate } =
                                  getNewWorkingHourTimes(
                                    hours[hours.length - 1].endDate,
                                    day as Weekday
                                  );

                                await createWorkingHour({
                                  weekday: day as Weekday,
                                  startDate,
                                  endDate,
                                  telehealth: true,
                                });
                              } catch (e) {
                                ui.showWarningSnackbar(
                                  'Working hour creation failed.'
                                );
                              }

                              loadingState = new Map<Weekday, boolean>(
                                weekdayLoading
                              );
                              loadingState.set(day as Weekday, false);
                              setWeekdayLoading(loadingState);
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      <Tooltip
                        title={
                          hasHours
                            ? 'Disable working hours'
                            : 'Enable working hours'
                        }
                        placement="left"
                        arrow
                        enterDelay={1000}
                      >
                        <Switch
                          checked={hasHours}
                          onChange={async () => {
                            if (!hours) {
                              const { startDate, endDate } =
                                getDefaultWorkingHourTimes(day as Weekday);
                              try {
                                await createWorkingHour({
                                  weekday: day as Weekday,
                                  startDate,
                                  endDate,
                                  telehealth: true,
                                });
                              } catch (e) {
                                ui.showWarningSnackbar(
                                  'Enabling working hours failed.'
                                );
                              }
                            } else {
                              try {
                                await deleteWorkingHours(
                                  hours.map((hour) => hour.id)
                                );
                              } catch (e) {
                                ui.showWarningSnackbar(
                                  'Deleting working hours failed.'
                                );
                              }
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {hours && (
                    <>
                      <Divider
                        css={{
                          marginLeft: theme.space.sm,
                          marginRight: theme.space.lg,
                        }}
                      />
                      <ul css={{ paddingLeft: isSmall ? 0 : theme.space.xl2 }}>
                        {hours.map((hour: ProviderWorkingHour) => (
                          <li
                            key={hour.id}
                            css={{
                              display: 'flex',
                              paddingLeft: isSmall ? 0 : theme.space.xl5,
                              paddingRight: isSmall ? 0 : theme.space.base,
                              listStyle: 'none',
                              marginBottom: '10px',
                            }}
                          >
                            <WorkingHourEdit
                              hour={hour}
                              weekday={day as Weekday}
                              workingHours={workingHours}
                              updateWorkingHour={updateWorkingHour}
                              deleteWorkingHours={deleteWorkingHours}
                              locations={locations}
                            />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      )}{' '}
    </section>
  );
};

/**
 * Utility Functions
 */

const getDefaultWorkingHourTimes = (day: Weekday) => ({
  startDate: moment()
    .set('hour', 9)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .set('day', day)
    .toDate(),
  endDate: moment()
    .set('hour', 17)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .set('day', day)
    .toDate(),
});

const getNewWorkingHourTimes = (lastHourEndDate: string, day: Weekday) => {
  const startHour = moment(lastHourEndDate).get('hour');
  const startMinute = moment(lastHourEndDate).get('minute');

  return {
    startDate: moment()
      .set('hour', startHour)
      .set('minute', startMinute)
      .set('second', 0)
      .set('millisecond', 0)
      .set('day', day)
      .toDate(),
    endDate: moment()
      .set('hour', startHour + 1)
      .set('minute', startMinute)
      .set('second', 0)
      .set('millisecond', 0)
      .set('day', day)
      .toDate(),
  };
};
