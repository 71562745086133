import { Dialog, Popover, PopoverProps, Slide } from '@mui/material';
import { SlideProps } from '@mui/material';
import React from 'react';

import { useMediaQuery, useResizeObserver } from '@headway/helix/utils';
import { CALENDAR_APPOINTMENT_POPOVER_DYNAMIC_REPOSITIONING } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { theme } from '@headway/ui/theme';

const SlideTransition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" timeout={150} ref={ref} {...props} />;
});

export type DetailPopoverProps = {
  open: boolean;
  onClose: PopoverProps['onClose'];
  anchorEl: PopoverProps['anchorEl'];
  PaperProps?: PopoverProps['PaperProps'];
  action?: PopoverProps['action'];
};

export function DetailPopover(
  props: React.PropsWithChildren<DetailPopoverProps>
) {
  const { open, onClose, children, PaperProps, anchorEl, action, ...rest } =
    props;

  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver({
    ref,
    onResize: () => {
      // Expect error because the types for action are :🚮
      // @ts-expect-error
      if (action && action.current) {
        // @ts-expect-error
        action.current.updatePosition();
      }
    },
  });

  const isDynamicRepositioningEnabled = useFlag(
    CALENDAR_APPOINTMENT_POPOVER_DYNAMIC_REPOSITIONING,
    false
  );

  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);

  return matches ? (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition}
      keepMounted={false}
      fullWidth={true}
      scroll="body"
      onClose={onClose}
      fullScreen={matches}
      {...rest}
    >
      <div>{children}</div>
    </Dialog>
  ) : (
    <Popover
      open={open && !!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      css={{
        '> .MuiPaper-root': {
          margin: `${theme.space.xs} 0`,
          border: `1px solid ${theme.color.border}`,
        },
        zIndex: 5,
      }}
      PaperProps={
        isDynamicRepositioningEnabled ? { ...PaperProps, ref: ref } : PaperProps
      }
      anchorEl={anchorEl}
      disableEnforceFocus={true}
      action={action}
      {...rest}
    >
      {children}
    </Popover>
  );
}
