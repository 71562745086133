export enum ProviderReminderQueryParam {
  WD = 'wd',
  NB = 'nb',
  CN = 'cn',
  SR = 'sr',
  OC = 'oc',
}

export const PROVIDER_REMINDER_SOURCE_MAP = {
  [ProviderReminderQueryParam.WD]: 'WEEKLY_DIGEST',
  [ProviderReminderQueryParam.NB]: 'NEW_SESSION',
  [ProviderReminderQueryParam.CN]: 'CANCELED_SESSION',
  [ProviderReminderQueryParam.SR]: 'CALENDAR_SYNC',
  [ProviderReminderQueryParam.OC]: 'OPT_OUT',
};
