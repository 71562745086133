import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';

import { PatientAddressCreate } from '@headway/api/models/PatientAddressCreate';
import { PatientAddressSourceType } from '@headway/api/models/PatientAddressSourceType';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { useMutation } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import useScript from '@headway/shared/utils/useScript';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import {
  HandleLocationSelectProps,
  HelixLocationFilter,
} from '@headway/ui/HelixLocationFilter';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddress } from 'hooks/usePatientAddress';
import { useProvider } from 'hooks/useProvider';

import { AddressCard } from '../AddressCard';
import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';

const STATES_ARRAY = Object.entries(statesToDisplayNames);

export interface TelehealthLocationsFormIntakeFlowProps {
  providerId: number;
  client: UserRead;
  apiKey: string;
}

export type TelehealthLocationsFormIntakeFlowValues = {
  telehealthLocationAddressLine1: string;
  telehealthLocationAddressLine2?: string;
  telehealthLocationCity: string;
  telehealthLocationState: string;
  telehealthLocationZipCode: string;
  telehealthLocationDescription?: PatientAddressType;
};

const initialValues = {
  telehealthLocationCity: '',
  telehealthLocationState: '',
  telehealthLocationAddressLine1: '',
  telehealthLocationAddressLine2: '',
  telehealthLocationZipCode: '',
  telehealthLocationDescription: undefined,
};

const validationSchema = Yup.object({
  telehealthLocationCity: Yup.string().required('City is required.'),
  telehealthLocationState: Yup.string().required('State is required.'),
  telehealthLocationAddressLine1: Yup.string().required(
    'Address line 1 is required.'
  ),
  telehealthLocationAddressLine2: Yup.string().nullable().default(null),
  telehealthLocationZipCode: Yup.string().required('Zip code is required.'),
  telehealthLocationDescription: Yup.string().required(
    'Description is required.'
  ),
});

export const TelehealthLocationsFormIntakeFlow = ({
  providerId,
  client,
  apiKey,
}: TelehealthLocationsFormIntakeFlowProps) => {
  const firstName = formatFirstName(client);
  const provider = useProvider();

  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();

  const [showNewLocationForm, setShowNewLocationForm] = useState(false);

  const { setCurrentStep } = useContext(AddPatientModalContext);

  const { data: patientAddress } = usePatientAddress(
    {
      addressId: client.activePatientAddressId,
    },
    {
      enabled: !!client.activePatientAddressId,
    }
  );

  const gmapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3&libraries=places`
  );

  const createPatientAddressMutation = useMutation(
    (variables: PatientAddressCreate) => {
      return PatientAddressApi.createPatientAddress(variables);
    }
  );

  const handleTelehealthLocationsFormSubmit = async (
    values: TelehealthLocationsFormIntakeFlowValues
  ) => {
    trackEvent({
      name: 'Provider Save and Verify Telehealth Button Clicked',
      properties: {
        providerId: provider.id,
        providerPatientId: client.id,
      },
    });

    if (patientAddress && !showNewLocationForm) {
      onAddressFormSkipOrComplete();
    }

    try {
      await createPatientAddressMutation.mutateAsync({
        patientUserId: client.id,
        city: values.telehealthLocationCity,
        state: values.telehealthLocationState as UnitedStates,
        streetLine1: values.telehealthLocationAddressLine1,
        streetLine2: values.telehealthLocationAddressLine2,
        zipCode: values.telehealthLocationZipCode,
        addressType: values.telehealthLocationDescription,
      });

      onAddressFormSkipOrComplete();
    } catch (e) {
      logException(e);
    }
  };

  const onAddressFormSkipOrComplete = () => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Add Telehealth Address',
        stepName: 'TELEHEALTH_LOCATIONS',
      },
    });

    setCurrentStep(AddPatientModalPage.SEND_ACCOUNT_INVITE);
  };

  return (
    <Formik
      validateOnMount={true}
      onSubmit={handleTelehealthLocationsFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        const isProviderCredentialedInGivenState =
          values.telehealthLocationState
            ? provider.activeProviderLicenseStates.filter(
                (pls) => pls.state === values.telehealthLocationState
              ).length > 0
            : true;

        return (
          <>
            <ModalContent>
              <SafeFormikForm
                id="telehealth-locations-form"
                style={{
                  gap: 0,
                  height: '100%',
                }}
              >
                <PageSection>
                  <SectionHeader>
                    Confirm where {firstName} will be during telehealth sessions
                  </SectionHeader>
                  <BodyText>
                    Why we need this information:
                    <ol>
                      <li>
                        For your claim to be approved, you must be licensed and
                        credentialed in this location.
                      </li>
                      <li>
                        In the case of an emergency, it's important to know
                        where you or first responders can find your client.
                      </li>
                    </ol>
                    Don't have this info? You can skip for now. We'll also ask
                    your client to provide these details.
                  </BodyText>
                  {patientAddress && (
                    <div>
                      <AddressCard
                        patientAddress={patientAddress}
                        deletable={false}
                        withBorder
                        withIcon={false}
                        subText={
                          patientAddress.source ===
                          PatientAddressSourceType.ELIGIBILITY_LOOKUP
                            ? `We found this address in ${firstName}'s insurance details.`
                            : `This is the address you provided.`
                        }
                      />
                      {!showNewLocationForm && (
                        <div css={{ marginTop: theme.spacing.x2 }}>
                          <Button
                            onPress={() => {
                              trackEvent({
                                name: 'Provider Add New Address Button Clicked',
                                properties: {
                                  providerId: provider.id,
                                  providerPatientId: client.id,
                                },
                              });
                              setShowNewLocationForm(true);
                            }}
                            variant="link"
                          >
                            Add new location
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                  {(!patientAddress || showNewLocationForm) && (
                    <div>
                      <div
                        css={{
                          marginTop: theme.spacing.x1,
                          marginBottom: theme.spacing.x3,
                        }}
                      >
                        <BodyText>
                          <strong>
                            Add where your client will be located during their
                            virtual telehealth sessions:
                          </strong>
                        </BodyText>
                      </div>
                      <div
                        css={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing.x3,
                        }}
                      >
                        {gmapsScriptStatus === 'ready' ? (
                          <FormControl
                            component={HelixLocationFilter}
                            label="Address"
                            name="telehealthLocationAddress"
                            GOOGLE_MAPS_API_KEY={apiKey}
                            handleLocationSelect={({
                              streetLine1,
                              city,
                              state,
                              zipCode,
                            }: HandleLocationSelectProps) => {
                              setFieldValue(
                                'telehealthLocationAddressLine1',
                                streetLine1
                              );
                              setFieldValue('telehealthLocationState', state);
                              setFieldValue('telehealthLocationCity', city);
                              setFieldValue(
                                'telehealthLocationZipCode',
                                zipCode
                              );
                            }}
                            selectionMode={'single'}
                          />
                        ) : gmapsScriptStatus === 'loading' ? (
                          <div>loading...</div>
                        ) : null}
                        <FormControl
                          component={TextField}
                          label="Address line 1"
                          name="telehealthLocationAddressLine1"
                        />
                        <FormControl
                          component={TextField}
                          label="Address line 2"
                          name="telehealthLocationAddressLine2"
                          optionalityText="Optional"
                        />
                        <FormControl
                          component={TextField}
                          label="City"
                          name="telehealthLocationCity"
                        />
                        <FormControl
                          component={Select}
                          label="State"
                          name="telehealthLocationState"
                          selectionMode="single"
                          selectedKeys={
                            values.telehealthLocationState
                              ? [values.telehealthLocationState]
                              : []
                          }
                        >
                          {STATES_ARRAY.map(([key, value]) => (
                            <Item key={key}>{value}</Item>
                          ))}
                        </FormControl>
                        <FormControl
                          component={TextField}
                          label="Zip code"
                          name="telehealthLocationZipCode"
                        />
                        <FormControl
                          component={Select}
                          label="Describe the client's location"
                          name="telehealthLocationDescription"
                          selectionMode="single"
                        >
                          {(
                            Object.keys(
                              addressTypeDisplayNames
                            ) as PatientAddressType[]
                          ).map((addressType) => (
                            <Item key={addressType}>
                              {addressTypeDisplayNames[addressType]}
                            </Item>
                          ))}
                        </FormControl>
                        {!isProviderCredentialedInGivenState &&
                          (isMSCGuardrailWarning ? (
                            <GuidanceCard variant="warning">
                              <BodyText>
                                You must be licensed and credentialed on Headway
                                in the state where {firstName} joined from.
                                Starting{' '}
                                <span className="font-bold">
                                  {restrictionDate}
                                </span>
                                , we'll block telehealth session confirmation if
                                there is a mismatch.{' '}
                                <Link
                                  href={CREDENTIALING_REQS_LINK}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <strong>Learn more</strong>
                                </Link>
                              </BodyText>
                            </GuidanceCard>
                          ) : (
                            <GuidanceCard variant="warning">
                              Please note, you must be licensed and credentialed
                              on Headway in the state where {firstName} joined
                              from in order to confirm their session.
                            </GuidanceCard>
                          ))}
                      </div>
                    </div>
                  )}
                </PageSection>
              </SafeFormikForm>
            </ModalContent>
            <ModalFooter>
              <Button
                variant="secondary"
                onPress={() => {
                  trackEvent({
                    name: 'Provider Skip Telehealth Button Clicked',
                    properties: {
                      providerId: provider.id,
                      providerPatientId: client.id,
                    },
                  });
                  onAddressFormSkipOrComplete();
                }}
              >
                Skip
              </Button>
              <Button
                disabled={isSubmitting}
                variant="primary"
                type="submit"
                form="telehealth-locations-form"
              >
                {isSubmitting ? 'Saving' : 'Save and continue'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
