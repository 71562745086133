import { useSlotId } from '@react-aria/utils';
import React from 'react';
import {
  AriaNumberFieldProps,
  FocusRing,
  mergeProps,
  useNumberField,
} from 'react-aria';
import { useNumberFieldState } from 'react-stately';

import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { FormInputProps, useFormInput } from './useFormInput';

type NumberFieldProps = {
  optionalityText?: React.ReactNode;
} & FormInputProps<number> &
  Pick<
    AriaNumberFieldProps,
    'formatOptions' | 'step' | 'minValue' | 'maxValue'
  >;

let currencySymbol: string;

if (typeof Intl.NumberFormat.prototype.formatToParts === 'function') {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
    .formatToParts(0)
    .find((part) => part.type === 'currency');

  currencySymbol = currency ? currency.value : '$';
} else {
  // Fallback for older browsers
  currencySymbol = '$';
}

const NumberField = (props: NumberFieldProps) => {
  const controlRef = React.useRef<HTMLInputElement>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { ariaProps, hoverProps, rootProps, focusProps, isFocused } =
    useFormInput({
      ...props,
      isTextInput: true,
    });

  let state = useNumberFieldState({ ...ariaProps, locale: 'en-US' });

  let {
    labelProps,
    groupProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
  } = useNumberField(props, state, controlRef);

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  useAssertFormParentEffect(controlRef, 'NumberField');

  const isCurrencyField = props.formatOptions?.style === 'currency';

  return (
    <div ref={rootRef} className="hlx-number-field-root" {...rootProps}>
      <label className="hlx-number-field-label" {...labelProps}>
        {props.label}
      </label>
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        // isTextInput={true}
        // autoFocus={props.autoFocus}
      >
        <div
          className="hlx-number-field-control-group"
          {...mergeProps(groupProps, hoverProps)}
        >
          {isCurrencyField && (
            <span
              className="hlx-number-field-currency-symbol"
              style={{
                display:
                  state.inputValue !== '' || isFocused ? 'block' : 'none',
              }}
            >
              {currencySymbol}
            </span>
          )}
          <input
            className="hlx-number-field-control"
            ref={controlRef}
            {...mergeProps(
              (props.optionalityText
                ? { 'aria-describedby': optionalityId }
                : {}) as { 'aria-describedby'?: string },
              inputProps,
              focusProps,
              isCurrencyField
                ? {
                    value: currencySymbol
                      ? state.inputValue.replace(currencySymbol, '')
                      : state.inputValue,
                  }
                : undefined
            )}
          />
        </div>
      </FocusRing>
      {props.optionalityText && (
        <div id={optionalityId} className="hlx-number-field-optionality-text">
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-number-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-number-field-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
};

export { NumberField };
