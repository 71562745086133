import { UnitedStates } from '@headway/api/models/UnitedStates';

export const CAQH_WEBSITE_URL = 'https://proview.caqh.org/PR';
export const CAQH_ZENDESK_ARTICLE =
  'https://findheadway.zendesk.com/hc/en-us/articles/10029399566612-CAQH';

//Welcome Step
export const CREDENTIALING_REQUIREMENTS_ZENDESK_ARTICLE =
  'https://help.headway.co/hc/en-us/articles/360058294552-Credentialing-with-Headway#h_01GA6NGF0SAE8QK6JYN9BKBJ0Q';
export const PRESCRIBER_REGISTRATION_REQUIREMENTS_ZENDESK_ARTICLE =
  'https://help.headway.co/hc/en-us/articles/360058294552-Credentialing-with-Headway#h_01G0DBS82Q8NR5M9W6E04M2VK9:~:text=Prescriber%20registration%20requirements';

//Education Step
export const statesThatRequireCertificateAwarded = [
  UnitedStates.COLORADO,
  UnitedStates.TEXAS,
];
