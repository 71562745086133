/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* this enum maps to the only potential value for the Provider Issue Type zendesk field. */    /**
    * this enum maps to the only potential value for the Provider Issue Type zendesk field.
    */
export enum ProviderIssueType {
    ACCOUNT_ACCESS = 'pit_account_access',
    CALENDAR_PROFILE_SETTINGS = 'pit_calendar___profile_settings',
    CLIENT_BILLING_CHARGES = 'pit_client_referrals',
    CLIENT_HEALTH_SPENDING_ACCOUNTS = 'client_healthcare_cost-saving_benefits__eap/fsa/hsa/hra_',
    CLIENT_INSURANCE_VERIFICATION = 'client_insurance_verification',
    CLIENT_MANAGEMENT = 'pit_managing_clients',
    COMPLIANCE = 'pit_compliane',
    CREDENTIALING_PAYER_NETWORKS = 'pit_credentialing___payer_networks',
    GROUP_PRACTICES = 'pit_group_practices',
    JOINING_HEADWAY = 'pit_joining_headway',
    LEAVING_HEADWAY = 'pit_leaving_headway',
    MANAGING_AND_SCHEDULING_APPOINTMENT = 'pit_managing_and_scheduling_appointment',
    PAYMENTS_OR_BONUSES = 'pit_payments_or_bonuses',
    PRODUCT_ISSUES = 'pit_technical_issues',
    CLIENT_BILLING_INSURANCE_ISSUES = 'pit_client_billing___insurance_issues',
    OTHER = 'pit_other'
}
