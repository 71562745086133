import { createContext, ReactNode, useMemo, useState } from 'react';

export interface SelectedEventStore {
  selectedEventVirtualId: string | undefined;
  setSelectedEventVirtualId: (newEventVirtualId: string | undefined) => void;
}

export const SelectedEventContext = createContext<SelectedEventStore>({
  selectedEventVirtualId: undefined,
  setSelectedEventVirtualId: () => {},
});

export interface SelectedEventContextProviderProps {
  children: ReactNode;
}

export const SelectedEventContextProvider = ({
  children,
}: SelectedEventContextProviderProps) => {
  const [selectedEventVirtualId, setSelectedEventVirtualId] = useState<
    string | undefined
  >();
  const value = useMemo(() => {
    return {
      selectedEventVirtualId,
      setSelectedEventVirtualId,
    };
  }, [selectedEventVirtualId, setSelectedEventVirtualId]);

  return (
    <SelectedEventContext.Provider value={value}>
      {children}
    </SelectedEventContext.Provider>
  );
};
