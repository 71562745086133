import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  PROMISSubmission,
  PROMISSubmissionAssessmentType,
} from '@headway/api/models/PROMISSubmission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsByQuestionSchema,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const PROMIS_QUESTIONS_1_2: QuestionsSchema = [
  {
    key: 'PROMIS_1',
    questionText: 'In general, how would you rate your physical health?',
  },
  {
    key: 'PROMIS_2',
    questionText:
      'To what extent are you able to carry out your everyday physical activities such as walking, climbing stairs, carrying groceries, or moving a chair?',
  },
];

const PROMIS_QUESTIONS_3_4: QuestionsSchema = [
  {
    key: 'PROMIS_3',
    questionText: 'How would you rate your fatigue on average?',
  },
  {
    key: 'PROMIS_4',
    questionText: 'How would you rate your pain on average?',
  },
];

const PROMIS_OPTIONS_BY_QUESTION: OptionsByQuestionSchema = {
  PROMIS_1: [
    {
      displayText: 'Poor',
      value: '1',
    },
    {
      displayText: 'Fair',
      value: '2',
    },
    {
      displayText: 'Good',
      value: '3',
    },
    {
      displayText: 'Very Good',
      value: '4',
    },
    {
      displayText: 'Excellent',
      value: '5',
    },
  ],
  PROMIS_2: [
    {
      displayText: 'Not at all',
      value: '1',
    },
    {
      displayText: 'A little',
      value: '2',
    },
    {
      displayText: 'Moderately',
      value: '3',
    },
    {
      displayText: 'Mostly',
      value: '4',
    },
    {
      displayText: 'Completely',
      value: '5',
    },
  ],
  PROMIS_3: [
    {
      displayText: 'None',
      value: '5',
    },
    {
      displayText: 'Mild',
      value: '4',
    },
    {
      displayText: 'Moderate',
      value: '3',
    },
    {
      displayText: 'Severe',
      value: '2',
    },
    {
      displayText: 'Very Severe',
      value: '1',
    },
  ],
  PROMIS_4: [
    {
      displayText: '0 (No Pain)',
      value: '0',
    },
    {
      displayText: '1',
      value: '1',
    },
    {
      displayText: '2',
      value: '2',
    },
    {
      displayText: '3',
      value: '3',
    },
    {
      displayText: '4',
      value: '4',
    },
    {
      displayText: '5',
      value: '5',
    },
    {
      displayText: '6',
      value: '6',
    },
    {
      displayText: '7',
      value: '7',
    },
    {
      displayText: '8',
      value: '8',
    },
    {
      displayText: '9',
      value: '9',
    },
    {
      displayText: '10 (Worst Pain Imaginable)',
      value: '10',
    },
  ],
};

const INSTRUCTION = (
  <>Please respond to each question or statement by marking one item below.</>
);

const SEVEN_DAYS = <> In the past seven days...</>;

const PROMIS_VALIDATION_SCHEMA = Yup.object().shape(
  [...PROMIS_QUESTIONS_1_2, ...PROMIS_QUESTIONS_3_4].reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type PROMISFormValues = {
  [key in keyof Omit<PROMISSubmission, 'assessmentType'>]: string;
};

export const PROMISAssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: PROMISFormValues) => {
    return onSubmit({
      assessmentType: PROMISSubmissionAssessmentType.PROMIS,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<PROMISFormValues>
      onSubmit={innerOnSubmit}
      validationSchema={PROMIS_VALIDATION_SCHEMA}
      initialValues={{
        PROMIS_1: '',
        PROMIS_2: '',
        PROMIS_3: '',
        PROMIS_4: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={PROMIS_QUESTIONS_1_2}
            optionsByQuestion={PROMIS_OPTIONS_BY_QUESTION}
          />
          {SEVEN_DAYS}
          <ScorableQuestionListForm
            questions={PROMIS_QUESTIONS_3_4}
            optionsByQuestion={PROMIS_OPTIONS_BY_QUESTION}
            offset={PROMIS_QUESTIONS_1_2.length}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyPROMISAssessmentForm = ({
  response,
}: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={PROMIS_QUESTIONS_1_2}
        optionsByQuestion={PROMIS_OPTIONS_BY_QUESTION}
        response={scorableResponseJson}
      />
      {SEVEN_DAYS}
      <ReadonlyScorableQuestionList
        questions={PROMIS_QUESTIONS_3_4}
        optionsByQuestion={PROMIS_OPTIONS_BY_QUESTION}
        response={scorableResponseJson}
        offset={PROMIS_QUESTIONS_1_2.length}
      />
    </section>
  );
};
