import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';

import { useAuthStore } from 'stores/AuthStore';

import { AgreementCheckbox } from './components/AcceptModalContent/AgreementCheckbox';
import { IroncladAgreementsList } from './components/IroncladAgreementsList';
import { IroncladAgreementStatus } from './types/IroncladAgreementStatus';

export const GroupPracticeRegistrationContent = ({
  onSubmit,
}: {
  onSubmit: (ironcladAgreementStatus: IroncladAgreementStatus) => void;
}) => {
  const { user } = useAuthStore();

  return (
    <>
      <ModalContent>
        <div className="p-x-1 mb-2 grid gap-y-2 overflow-hidden">
          <p>
            <BodyText>Welcome {user.first_name}!</BodyText>
          </p>
          <p>
            <BodyText>
              We are so excited to work with you and your group practice!
            </BodyText>
          </p>
          <p>
            <BodyText>
              Before you begin, we need you to review and attest to our
              agreements:
            </BodyText>
          </p>

          <IroncladAgreementsList />
        </div>

        <AgreementCheckbox onSubmit={onSubmit} />
      </ModalContent>
      <ModalFooter>
        <Button
          type="submit"
          variant="primary"
          form="amendment-acceptance-form"
        >
          Agree
        </Button>
      </ModalFooter>
    </>
  );
};
