import { BodyText } from '@headway/helix/BodyText';
import { SubBodyText } from '@headway/helix/SubBodyText';

export const HumanTouchpoint = ({
  imageSources,
  primaryLabel,
  secondaryLabel,
}: {
  imageSources: string[];
  primaryLabel?: string;
  secondaryLabel?: string;
}) => {
  return (
    <div className="flex flex-row">
      {imageSources?.map((src: string, i) => (
        <img
          src={src}
          alt="Headway team member"
          width="44px"
          height="44px"
          className={`${
            i === 1 && 'ml-[-10px]'
          } border-hue-lightYellow rounded-full border-4 border-solid`}
        />
      ))}
      <div className="ml-2 flex flex-col justify-center align-middle">
        <BodyText>
          <strong>{primaryLabel}</strong>
        </BodyText>
        {secondaryLabel && <SubBodyText>{secondaryLabel}</SubBodyText>}
      </div>
    </div>
  );
};
