import { css } from '@emotion/react';
import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { FormControl } from '@headway/helix/FormControl';
import { RichTextArea, RichTextHTMLContent } from '@headway/helix/RichTextArea';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '../../schema/schema.types';
import { getValue } from '../../view/utils';

const MemoizedRichTextArea = React.memo(RichTextArea);
export const FormRichTextArea = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, placeholder, defaultContent } = element;

  if (defaultContent) {
    return <RichTextHTMLContent html={defaultContent} />;
  }

  if (readOnly) {
    const value = getValue(id);
    return (
      <div>
        <BodyText>
          <strong>{title}</strong>
        </BodyText>
        <RichTextHTMLContent html={value ?? ''} />
      </div>
    );
  }

  return (
    <FormControl
      component={MemoizedRichTextArea}
      label={title}
      name={id}
      key={id}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
