import * as React from 'react';

export const Dashboard = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.625 8.125V2.5H17.5v5.625h-6.875zm-8.125 2.5V2.5h6.875v8.125H2.5zm8.125 6.875V9.375H17.5V17.5h-6.875zm-8.125 0v-5.625h6.875V17.5H2.5z"
      fill="currentColor"
    />
  </svg>
);
