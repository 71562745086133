import { BillingType } from '@headway/api/models/BillingType';
import { useUser } from '@headway/shared/hooks/useUser';
import { isClientMedicareOrMedicaid } from '@headway/shared/utils/insuranceUtils';

import { useProvider } from './useProvider';
import { useProviderPatient } from './useProviderPatient';

export const useMedicareOrMedicaid = (clientId?: number) => {
  const provider = useProvider();

  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });

  const { data: client } = useUser(
    { userId: clientId! },
    { enabled: !!clientId }
  );

  const isMedicareOrMedicaid = isClientMedicareOrMedicaid(client);
  return (
    isMedicareOrMedicaid &&
    providerPatient?.billingTypeDefault === BillingType.INSURANCE
  );
};
