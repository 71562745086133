import { InsuranceAuthorizationApi } from '@headway/api/resources/InsuranceAuthorizationApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

type FindInsuranceAuthorizationsQuery = Parameters<
  (typeof InsuranceAuthorizationApi)['findInsuranceAuthorizations']
>[0];
export interface UseInsuranceAuthorizationsQueryKeyArgs {
  query: FindInsuranceAuthorizationsQuery;
}

export const getUseInsuranceAuthorizationsQueryKey = ({
  query,
}: UseInsuranceAuthorizationsQueryKeyArgs): [
  string,
  FindInsuranceAuthorizationsQuery,
] => ['find-insurance-authorizations', query];

const {
  useSingleQuery: useInsuranceAuthorizations,
  useListQuery: useInsuranceAuthorizationsList,
} = createBasicApiHooks(
  getUseInsuranceAuthorizationsQueryKey,
  ({ query }) => InsuranceAuthorizationApi.findInsuranceAuthorizations(query),
  ({ query }) => !!query,
  () => 'Failed to fetch insurance authorization'
);

export { useInsuranceAuthorizations, useInsuranceAuthorizationsList };
