import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { startCase } from 'lodash';
import React, { useContext } from 'react';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { Button } from '@headway/ui/Button';
import { theme } from '@headway/ui/theme';

import { WizardContext } from 'components/Wizard/context';
import { Wizard } from 'components/Wizard/Wizard';
import { WizardStep } from 'components/Wizard/WizardStep';
import { useProvider } from 'hooks/useProvider';
import { useWorkingHours } from 'hooks/useWorkingHours';
import { getEventNameFromEnum } from 'utils/analytics';
import {
  CalendarExportButton,
  CalendarExportHelp,
} from 'views/SettingsHelix/CalendarSettings/CalendarExportSettings';
import {
  CalendarImportButton,
  CalendarImportHelp,
} from 'views/SettingsHelix/CalendarSettings/CalendarImportSettings';
import { WorkingHoursMultiEdit } from 'views/SettingsHelix/CalendarSettings/WorkingHoursMultiSet';

const WorkingHoursStep = () => {
  const { workingHours } = useWorkingHours();
  const hasAnyWorkingHours = Object.values(workingHours).some(
    (hours) => hours.length > 0
  );

  return (
    <WizardStep
      height={800}
      primaryContent={
        <>
          <h5
            css={{
              color: theme.color.black,
              fontFamily: theme.fontFamily.postGrotesk,
            }}
          >
            Set your availability
          </h5>
          <p css={{ color: theme.color.textGray }}>
            Set your working hours so new clients can book with you. The more
            availability you have, the more referrals you'll receive. We'll
            always notify you of new referrals at least 48 hours ahead of time.
          </p>
          <p css={{ color: theme.color.textGray }}>
            <b>TIP:</b> Clients are 4x more likely to book with a provider who
            has at least 5 weekly openings. Afternoons are most popular,
            especially Mon-Thurs.
          </p>
          <WorkingHoursMultiEdit />
        </>
      }
      areRequirementsMet={hasAnyWorkingHours}
    />
  );
};

const ImportStep = () => {
  const { currentStep, module, title } = useContext(WizardContext);
  const provider = useProvider();
  return (
    <WizardStep
      primaryContent={
        <>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <h5
              css={{
                color: theme.color.black,
                fontFamily: theme.fontFamily.postGrotesk,
              }}
            >
              Import External Calendars
            </h5>
          </div>
          <p
            css={{
              color: theme.color.textGray,
              marginBottom: theme.space.lg,
              display: 'flex',
              flexDirection: 'column',
              gap: theme.space.sm,
              alignItems: 'flex-start',
            }}
          >
            Sync other calendars you have (Google, SimplePractice, etc.) to
            maximize your availability and keep it up to date. We'll
            automatically remove openings if they conflict with your external
            events.
            <CalendarImportHelp
              displaySource="ONBOARDING"
              providerId={provider.id}
            />
          </p>
          <CalendarImportButton
            displaySource="ONBOARDING"
            eventTrackingData={{
              name: `Setup ${
                getEventNameFromEnum(module) as 'Availability'
              } Step`,
              properties: {
                screenName: `${startCase(title)}: Step ${currentStep + 1}`,
                stepName: 'calendar_imported',
              },
            }}
          />
        </>
      }
    />
  );
};

const ExportStep = () => {
  const { currentStep, module, title } = useContext(WizardContext);
  return (
    <WizardStep
      primaryContent={
        <>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <h5
              css={{
                color: theme.color.black,
                fontFamily: theme.fontFamily.postGrotesk,
              }}
            >
              Export your Headway calendar
            </h5>
          </div>
          <p
            css={{
              color: theme.color.textGray,
              marginBottom: theme.space.lg,
              display: 'flex',
              flexDirection: 'column',
              gap: theme.space.sm,
              alignItems: 'flex-start',
            }}
          >
            If you use other calendars, you can export your Headway calendar to
            see your schedule in one place. Generate and copy the URL into your
            external calendar system to show event type, location, and time. No
            client information will be shown in exported events.
            <CalendarExportHelp />
          </p>
          <CalendarExportButton
            eventTrackingData={{
              name: `Setup ${
                getEventNameFromEnum(module) as 'Availability'
              } Step`,
              properties: {
                screenName: `${startCase(title)}: Step ${currentStep + 1}`,
                stepName: 'calendar_exported',
              },
            }}
          />
        </>
      }
    />
  );
};
const Completed = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        padding: `0 ${theme.space.xl5}`,
      }}
    >
      <CheckCircleOutlineIcon
        css={{ fontSize: 64, marginBottom: theme.space.xl2 }}
        color="primary"
        fontSize="inherit"
      />
      <h4 css={{ marginTop: 0 }}>Your calendar is complete!</h4>
      <p css={{ color: theme.color.textGray, marginBottom: theme.space.xl2 }}>
        Next, connect your bank details to receive your payments.
      </p>
      <Button
        css={{ alignSelf: 'stretch', marginBottom: theme.space.xs }}
        variant="contained"
        href="/settings/billing?product_tour=true"
      >
        Next
      </Button>
      <Button css={{ alignSelf: 'stretch' }} href="/">
        Save And Exit
      </Button>
    </div>
  );
};

export const AvailabilityWizard = () => {
  return (
    <Wizard
      steps={[<WorkingHoursStep />, <ImportStep />, <ExportStep />]}
      title="Build your calendar"
      endContent={<Completed />}
      module={ProviderModule.AVAILABILITY}
    />
  );
};
