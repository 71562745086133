import { stringToHslColor } from '~/legacy/utils/stringToHslColor';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Avatar } from '@headway/helix/Avatar';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import {
  formatProviderInitials,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';

const SIZE_TO_WIDTH: Record<'small' | 'medium' | 'large', number> = {
  small: 32,
  medium: 40,
  large: 56,
};

export const ProviderAvatar = ({
  provider,
  size,
}: {
  provider: ProviderRead;
  size: 'small' | 'medium' | 'large';
}) => {
  if (provider.photoUrl) {
    return (
      <img
        src={transformCloudflareImg({
          src: provider.photoUrl,
          width: SIZE_TO_WIDTH[size],
        })}
        className="rounded-full"
        width={SIZE_TO_WIDTH[size]}
        height={SIZE_TO_WIDTH[size]}
        alt=""
      />
    );
  }
  const backgroundColor = stringToHslColor(
    getProviderDisplayFirstAndLastWithPrenomial(provider),
    70,
    96
  );
  return (
    <Avatar size={size} backgroundColor={backgroundColor} aria-hidden>
      {formatProviderInitials(provider)}
    </Avatar>
  );
};
