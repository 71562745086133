import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import moment from 'moment';

import { DegreeTypes } from '@headway/api/models/DegreeTypes';
import { Education } from '@headway/api/models/Education';
import { Malpractice } from '@headway/api/models/Malpractice';
import { Practice } from '@headway/api/models/Practice';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireType } from '@headway/api/models/ProviderQuestionnaireType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { MarketApi } from '@headway/api/resources/MarketApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';

export const getHeadwayAddress = (useNewOfficeAddress: boolean) => {
  return useNewOfficeAddress
    ? {
        street1: '205 Hudson Street',
        street2: 'Floor 9',
        city: 'New York',
        state: 'New York',
        zip: '10013',
        county: 'New York County',
      }
    : {
        street1: '114 Fifth Ave',
        street2: '2nd Floor',
        city: 'New York',
        state: 'New York',
        zip: '10011',
        county: 'New York County',
      };
};

export const HEADWAY_CONTACT = 'Inez Lee';
export const PC_NAME = 'New York Medical Behavioral Health Services';
export const PC_NPI = '1235600834';
export const PC_TAX_ID = '832675429';
export const HEADWAY_PHONE = '(646) 453-6777';
export const HEADWAY_FAX = '(917) 525-4985';
export const HEADWAY_SUPPORT_EMAIL = 'hello@headway.co';
export const HEADWAY_PAYERS_EMAIL = 'payers@headway.co';
export const HEADWAY_TIN = '832675429';

export const CLINICAL_PSYCHOLOGIST = 'Clinical Psychologist';
export const PHYSICIAN = 'Physician';
export const OTHER_THERAPISTS = 'Other Therapists';
export const NURSE_PRACTITIONER = 'Nurse Practitioner';

export const statesRequiringResumes = [
  UnitedStates.ARIZONA,
  UnitedStates.FLORIDA,
  UnitedStates.NEW_JERSEY,
  UnitedStates.WASHINGTON,
  UnitedStates.LOUISIANA,
  UnitedStates.OREGON,
];

export const doesProviderAddressExist = (
  providerAddress: ProviderAddressRead,
  address: any
) => {
  return (
    providerAddress.streetLine1 === address.street1 &&
    providerAddress.streetLine2 === address.street2 &&
    providerAddress.city === address.city &&
    providerAddress.state === address.state &&
    providerAddress.zipCode === address.zip
  );
};

export const issues: any = {
  'ADD / ADHD': 2,
  'Anxiety Disorder': 5,
  Depression: 11,
  'Eating Disorder': 12,
  'Family Therapy': 14,
  'Gay / Lesbian Issues': 17,
  'Gender Identity': 17,
  'Grief Counseling': 6,
  'Marital / Couples Therapy': 9,
  "Men's Issues": 18,
  'Obsessive Compulsive Disorder': 20,
  'Post Traumatic Stress Disorder': 25,
  'Sexual Abuse': 22,
  'Sexual Disorders': 27,
  "Women's Issues": 15,
};

const degreeValues = Object.values(DegreeTypes);

export const getBestDegree = (providerEducation: Education[]): Education => {
  if (!providerEducation.length) {
    throw new Error(`Provider does not have education listed.`);
  }

  for (const degreeValue of degreeValues) {
    for (const education of providerEducation) {
      if (education.degreeType === degreeValue) {
        return education;
      }
    }
  }

  return Object.assign(providerEducation[0], {
    degreeType: providerEducation[0].degreeTypeOther || 'Other',
  });
};

export const getMarketByState = async (state: UnitedStates) => {
  const markets = await MarketApi.getAllMarkets({ state: state });
  return markets[0];
};

export const getDisclosureFields = (data: { [x: string]: string }) => {
  let disclosureFields: { [x: string]: string } = {};

  Object.keys(data).forEach((key) => {
    if (key.startsWith('disclosure')) {
      if (Array.isArray(data[key])) {
        const yesNoDisclosureKey = key.replace('Explanation', '');
        const answeredYesToDisclosure = data[yesNoDisclosureKey] === 'YES';
        if (answeredYesToDisclosure) {
          const arr: any = data[key];
          for (let i = 0; i < arr.length; i++) {
            for (const property in arr[i]) {
              disclosureFields[`${key}.${i + 1}.${property}`] =
                arr[i][property];
            }
          }
        }
      } else if (data[key] != '') {
        disclosureFields[key] = data[key];
      }
    }
  });

  return disclosureFields;
};

export const getActiveMalpracticeInsurance = (
  malpracticeInsurances: Malpractice[] = []
) => {
  if (malpracticeInsurances.length === 1) {
    return malpracticeInsurances[0];
  }

  const sortedMalpracticeInsurances = malpracticeInsurances.sort(
    (a: any, b: any) =>
      a?.liabilityInsuranceEffectiveDate > b?.liabilityInsuranceEffectiveDate
        ? 1
        : b?.liabilityInsuranceEffectiveDate >
          a?.liabilityInsuranceEffectiveDate
        ? -1
        : 0
  );

  const activeMalpracticeInsurance = sortedMalpracticeInsurances.find(
    (malpractice) => {
      // check to see if it's active based on todays date
      return moment().isBetween(
        malpractice.liabilityInsuranceEffectiveDate,
        malpractice.liabilityInsuranceExpirationDate
      );
    }
  );

  if (!!activeMalpracticeInsurance) {
    return activeMalpracticeInsurance;
  } else {
    throw new Error('Could not find an active malpractice insurance');
  }
};

export const isRecredQuestionnaire = (pq?: ProviderQuestionnaireReadV2) =>
  pq?.type === ProviderQuestionnaireType.RECREDENTIALING;

export const races = [
  'Asian',
  'Black or African descent',
  'Caucasian',
  'Native American',
  'Pacific Islander',
  'South Asian',
  'Other Racial or Ethnic Background',
];

export const NOT_HISPANIC_OR_LATINX = 'Not Hispanic or Latinx';

export const ethnicities = ['Hispanic/Latinx', NOT_HISPANIC_OR_LATINX];
export const split_ethnicities = ['Hispanic', 'Latinx', NOT_HISPANIC_OR_LATINX];

export const getHeadwayEthnicities = (
  races: string[],
  ethnicity: string | string[],
  hispanic_latinx_migration_flag: boolean = false
): string[] => {
  if (hispanic_latinx_migration_flag && Array.isArray(ethnicity)) {
    return [...races, ...ethnicity.filter((e) => e !== NOT_HISPANIC_OR_LATINX)];
  } else {
    if (ethnicity === 'Hispanic/Latinx') return [...races, ethnicity];
  }
  return races;
};

/**
 * Fetches all the questionnaires for the current provider.
 */
export const getLatestQuestionnaire = async (providerId: number) => {
  const existingQuestionnaires =
    await ProviderQuestionnaireApi.findProviderQuestionnaires({
      provider_id: providerId,
    });
  const mostRecent = last(
    sortBy(existingQuestionnaires, (q) => moment(q.createdOn).toDate())
  );
  return {
    providerQuestionnaires: existingQuestionnaires,
    selectedQuestionnaire: mostRecent,
  };
};
