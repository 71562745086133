import React from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames, {
  abbreviationToStateEnum,
} from '@headway/shared/constants/unitedStatesDisplayNames';

import { YesNo } from '../../../../utils/providerQuestionnaire';
import { PracticeLocation } from './Practice';
import {
  getAdaOptions,
  getLocationHash,
  isPracticeLocationCaqhPracticeType,
  statesThatRequireAdaAccessibleProperties,
} from './util';

export const PracticeLocationInfo = ({
  p,
  idx,
}: {
  p: PracticeLocation;
  idx: number;
}) => {
  const isCAQHLocation = isPracticeLocationCaqhPracticeType(p);
  const meetsADARequirements = isCAQHLocation
    ? p.meetsADARequirements
    : YesNo.NO;
  const hasServicesForDisabled = isCAQHLocation
    ? p.hasServicesForDisabled
    : YesNo;

  const state = p.state
    ? (abbreviationToStateEnum[p.state] as UnitedStates)
    : undefined;

  if (!state) {
    return null;
  }

  const shouldShowAdaQuestions =
    meetsADARequirements === YesNo.YES &&
    statesThatRequireAdaAccessibleProperties.includes(state);

  const locationHashString =
    p.locationHash ??
    (isPracticeLocationCaqhPracticeType(p)
      ? getLocationHash(p.street1, p.street2 ?? '', p.city, p.state, p.zip)
      : getLocationHash(
          p.streetLine1 ?? '',
          p.streetLine2 ?? '',
          p.city ?? '',
          p.state ?? '',
          p.zipCode ?? ''
        ));

  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: theme.spacing.x4,
      }}
    >
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x1,
          width: '60%',
          marginTop: theme.spacing.x4,
        }}
      >
        <BodyText color="textBlack">
          <strong>{locationHashString}</strong>
        </BodyText>
      </div>
      <FormControl
        name={`practice[${idx}].attachedToHome`}
        component={RadioGroup}
        label="Is the service location attached to a home/residence or on the immediate premise of my home?"
      >
        <Radio value={YesNo.YES}>Yes</Radio>
        <Radio value={YesNo.NO}>No</Radio>
      </FormControl>
      <FormControl
        name={`practice[${idx}].hasServicesForDisabled`}
        component={RadioGroup}
        label="Does this office offer other services for the disabled?"
      >
        <Radio value={YesNo.YES}>Yes</Radio>
        <Radio value={YesNo.NO}>No</Radio>
      </FormControl>
      {hasServicesForDisabled === YesNo.YES ? (
        <FormControl
          component={CheckboxGroup}
          name={`practice[${idx}].servicesForDisabled`}
          label="Please specify other services for the disabled."
        >
          <Checkbox value={'TEXT_TELEPHONY'}>Text Telephony (TTL)</Checkbox>
          <Checkbox value={'SIGN_LANGUAGE'}>American Sign Language</Checkbox>
          <Checkbox value={'IMPAIRMENT_SERVICES'}>
            Mental/Physical Impairment Services
          </Checkbox>
        </FormControl>
      ) : null}
      <FormControl
        name={`practice[${idx}].hasPublicTransportation`}
        component={RadioGroup}
        label="Is this office accessible by public transportation?"
      >
        <Radio value={YesNo.YES}>Yes</Radio>
        <Radio value={YesNo.NO}>No</Radio>
      </FormControl>
      {shouldShowAdaQuestions && (
        <FormControl
          component={CheckboxGroup}
          name={`practice[${idx}].adaAccessibleProperties`}
          label="Please specify what aspects of this location
          are ADA accessible."
        >
          {Object.entries(
            getAdaOptions(statesToDisplayNames[state] as UnitedStates)
          ).map(([value, label]) => (
            <Checkbox value={value}>{label}</Checkbox>
          ))}
        </FormControl>
      )}
    </div>
  );
};
