import { css } from '@emotion/react';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { Alert, AlertTitle, TablePagination } from '@mui/material';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment-timezone';
import React, { useState } from 'react';

import { MambaApiError } from '@headway/api/axios.config';
import { PaymentSummary } from '@headway/api/models/PaymentSummary';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { UserUploadApi } from '@headway/api/resources/UserUploadApi';
import { Badge } from '@headway/helix/Badge';
import { LinkButton } from '@headway/helix/LinkButton';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { downloadFile } from '@headway/shared/utils/download';
import { formatPrice } from '@headway/shared/utils/payments';
import SkeletonTableRow from '@headway/ui/SkeletonTableRow';
import { theme } from '@headway/ui/theme';

import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';

import { FormattedAppointmentInfo } from './FormattedAppointmentInfo';
import { PaymentFeesTooltip } from './PaymentInfoTooltip';
import { PaymentsDownloadErrorModal } from './PaymentsDownloadErrorModal';

const DEFAULT_PAGE_SIZE = 25;

interface PaymentSummaryRowProps {
  summary: PaymentSummary;
}

export function PaymentSummaryRow({ summary }: PaymentSummaryRowProps) {
  const provider = useProvider();
  const { user } = useAuthStore();
  const [open, setOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const paymentDate = moment(summary.paymentDate).format(moment.HTML5_FMT.DATE);

  const paymentsDownloadsFlagEnabled = useFlag('providerPaymentsDownloads');

  const [
    isPaymentsDownloadErrorModalOpen,
    setIsPaymentsDownloadErrorModalOpen,
  ] = useState<boolean>(false);

  const {
    isLoading: transactionsLoading,
    data: transactions,
    error: transactionsError,
  } = useQuery(
    [
      'transactionDetails',
      { paymentDate: summary.paymentDate, page, pageSize },
    ],
    () =>
      ProviderApi.getTransactionsForProviderByPaymentDate(provider.id, {
        payment_date: summary.paymentDate,
        offset: pageSize * page,
        limit: pageSize,
      }),
    { enabled: open, keepPreviousData: true }
  );

  async function handlePaymentsDownload(startDate: string) {
    setIsDownloading(true);
    try {
      trackEvent({
        name: 'Download Invoice CSV Button Clicked',
        properties: {
          individualPayment: true,
          providerId: provider.id,
          toggleValueSelection: '',
          userId: user?.id,
        },
      });
      const providerPaymentsDownload =
        await ProviderApi.downloadPaymentsForProvider(provider.id, {
          provider_name: provider.name,
          start_date: startDate,
          end_date: undefined,
        });
      if (providerPaymentsDownload.s3ObjectKey) {
        const downloadUrl =
          await UserUploadApi.generatePresignedGetUrlForObjectKey({
            object_key: providerPaymentsDownload.s3ObjectKey,
          });
        downloadFile(
          {
            link: downloadUrl.signedUrl,
            name: providerPaymentsDownload.filename,
          },
          false
        );
      }
    } catch (error) {
      if (error instanceof MambaApiError) {
        if (error.response?.status === 413) {
          setIsPaymentsDownloadErrorModalOpen(true);

          trackEvent({
            name: 'Payment Download Export Too Large Modal Viewed',
            properties: {
              providerId: provider.id,
              toggleValueSelection: '',
              userId: user?.id,
            },
          });
        }
      }
    }
    setIsDownloading(false);
  }

  function handlePaymentsDownloadErrorModalDismiss() {
    setIsPaymentsDownloadErrorModalOpen(false);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
        <TableCell padding="checkbox">
          <IconButton
            aria-label={`${open ? 'collapse' : 'expand'} row`}
            size="small"
            onClick={() => setOpen(!open)}
            aria-expanded={open}
            aria-controls={`${summary.paymentDate}-details`}
          >
            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {moment(paymentDate).format('M/D/YYYY')}
        </TableCell>
        <TableCell>
          Payment for {summary.transactionCount}{' '}
          {summary.transactionCount === 1 ? 'transaction' : 'transactions'}
        </TableCell>
        <TableCell align="right">
          {formatPrice(summary.totalAmount, false)}
        </TableCell>
      </TableRow>
      <TableRow aria-hidden={!open} id={`${summary.paymentDate}-details`}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={4}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <div css={paymentSummaryRowCss.titleContainer}>
                <div
                  id={`${summary.paymentDate}-label`}
                  css={paymentSummaryRowCss.title}
                >
                  Transactions for {moment(paymentDate).format('M/D/YYYY')}{' '}
                  payment
                </div>
                {paymentsDownloadsFlagEnabled && (
                  <LinkButton
                    variant="secondary"
                    size="large"
                    aria-label="export this payment button"
                    onPress={() => handlePaymentsDownload(paymentDate)}
                    disabled={isDownloading}
                  >
                    Export this payment
                  </LinkButton>
                )}
              </div>
              <Table
                size="small"
                aria-labelledby={`${summary.paymentDate}-label`}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={paymentSummaryRowCss.subheader}>
                      Description
                    </TableCell>
                    <TableCell
                      style={paymentSummaryRowCss.subheader}
                      align="right"
                    >
                      Amount
                      <span css={{ marginRight: theme.space.xl }} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                {!transactionsError && (
                  <TableBody>
                    {transactionsLoading ? (
                      <>
                        <SkeletonTableRow numColumns={2} />
                        <SkeletonTableRow numColumns={2} />
                        <SkeletonTableRow numColumns={2} />
                        <SkeletonTableRow numColumns={2} />
                        <SkeletonTableRow numColumns={2} />
                      </>
                    ) : (
                      transactions &&
                      transactions.data.map((tx) => (
                        <TableRow key={tx.id}>
                          <TableCell>
                            {tx.appointmentInfo ? (
                              <FormattedAppointmentInfo
                                {...tx.appointmentInfo}
                                isProviderIncentiveBonus={
                                  tx.isProviderIncentiveBonus
                                }
                              />
                            ) : (
                              tx.notes
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <div className="flex min-w-max justify-end">
                              {tx.isProviderIncentiveBonus && (
                                <Badge variant="info">Rate boost</Badge>
                              )}
                              <span
                                className={
                                  tx.isProviderIncentiveBonus
                                    ? 'ml-2 text-primary-blue'
                                    : undefined
                                }
                              >
                                {formatPrice(tx.amount, false)}
                              </span>
                              <PaymentFeesTooltip
                                fees={
                                  tx.appointmentInfo?.providerPaymentFeesAmount
                                }
                                billingType={tx.appointmentInfo?.billingType}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                )}
              </Table>
              {!transactionsError && transactions ? (
                <TablePagination
                  component="div"
                  css={{
                    '& p': {
                      margin: 0,
                    },
                  }}
                  rowsPerPageOptions={[25, 50, 100]}
                  count={transactions.totalCount}
                  rowsPerPage={pageSize}
                  page={page}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  onRowsPerPageChange={(e) =>
                    setPageSize(Number(e.target.value))
                  }
                />
              ) : null}
              {transactionsError ? (
                <div
                  css={{ margin: `${theme.space.xl2} auto`, maxWidth: '50rem' }}
                >
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    We can't load your transaction details. Refresh the page to
                    see if this is an ongoing issue.
                  </Alert>
                </div>
              ) : null}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {paymentsDownloadsFlagEnabled && (
        <PaymentsDownloadErrorModal
          isModalOpen={isPaymentsDownloadErrorModalOpen}
          allTransactionsRequested={false}
          onDismiss={handlePaymentsDownloadErrorModalDismiss}
        />
      )}
    </React.Fragment>
  );
}

const paymentSummaryRowCss = {
  titleContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.space.sm,
    width: '100%',
  }),
  title: css({
    fontSize: theme.fontSize.lg,
    fontWeight: theme.fontWeight.bold,
    marginLeft: theme.space.base,
  }),
  subheader: {
    fontWeight: 'bold',
  },
};
