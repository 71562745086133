import React from 'react';

import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { theme } from '@headway/helix/theme';

export const KeyUpdates = () => {
  return (
    <CollapsibleDisclosure
      label="Other recent updates" // TO DO: update in sc-142500
      defaultExpanded
    >
      <div css={{}}>
        <ul>
          <li
            css={{
              fontWeight: 'bold',
            }}
          >
            Sessions will need to be confirmed within 30 days of occurring*.
            This is to ensure smooth claim processing and an optimal experience
            for clients.
          </li>
          <li>
            Clearer expectations and guidelines to ensure compliance with
            insurance company requirements.
          </li>
        </ul>
        <p
          css={{
            ...theme.typography.subbody.regular,
            fontWeight: 'bold',
          }}
        >
          *Note: If you need to confirm any sessions older than 30 days, you
          must do so before accepting this agreement
        </p>
      </div>
    </CollapsibleDisclosure>
  );
};
