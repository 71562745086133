import { useFormikContext } from 'formik';
import * as React from 'react';

/**
 * Add this component at the same level of the Form component.
 * This allows for a run of an onChange function when a value
 * changes in the form.
 */

interface ChangeListenerProps {
  onChange: (hasChanges: boolean) => void;
  ignoreFields?: string[];
}

export const ChangeListener = ({
  onChange,
  ignoreFields = [],
}: ChangeListenerProps) => {
  const formik = useFormikContext<any>();
  const [hasChanges, setHasChanges] = React.useState(false);

  React.useEffect(() => {
    const valuesExist = Object.entries<string | string[]>(formik.values).some(
      ([key, value]) => {
        return (
          !ignoreFields.includes(key) &&
          value !== undefined &&
          value !== null &&
          value !== '' &&
          !(Array.isArray(value) && value.length <= 0)
        );
      }
    );

    // Trigger onChange when we switch from having values to not having values, or vice-versa
    if (valuesExist !== hasChanges) {
      setHasChanges(valuesExist);
      onChange(valuesExist);
    }
  }, [formik.values]);

  return null;
};
