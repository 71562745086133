import {
  AriaLabelingProps,
  HelpTextProps,
  InputBase,
  ValueBase,
} from '@react-types/shared';
import { ReactNode } from 'react';

import { DATA } from './consts';
import { ValidationState } from './forms';
import { InteractionProps, useInteraction } from './useInteraction';

export type FormInputProps<ValueType, ChangeType = ValueType> = {
  name: string;
  label?: ReactNode;
  validation?: ValidationState;
  readonly?: InputBase['isReadOnly'];
  helpText?: HelpTextProps['description'];
  inputElementType?: 'input' | 'textarea';
} & InteractionProps &
  AriaLabelingProps &
  ValueBase<ValueType, ChangeType>;

type InferFormInputTypes<T> = T extends FormInputProps<
  infer ValueType,
  infer ChangeType
>
  ? { value: ValueType; change: ChangeType }
  : never;

export const useFormInput = <
  Props extends FormInputProps<ValueType, ChangeType>,
  ValueType = InferFormInputTypes<Props>['value'],
  ChangeType = InferFormInputTypes<Props>['change'],
>({
  readonly,
  helpText,
  validation,
  name,
  label,
  inputElementType = 'input',
  ...props
}: Props) => {
  const { ariaProps, rootProps, ...interactionPack } = useInteraction(props);
  const isInvalid = validation?.validity === 'invalid';
  return {
    validation,
    ariaProps: {
      name,
      label,
      inputElementType,
      isReadOnly: readonly,
      description: helpText,
      validationState: validation?.validity,
      errorMessage: isInvalid ? validation.message : undefined,
      isInvalid,
      ...ariaProps,
    },
    rootProps: {
      ...rootProps,
      [DATA.READONLY]: readonly,
      [DATA.VALIDATION]: validation?.validity,
      ...(inputElementType !== 'input'
        ? { [DATA.INPUT_TYPE]: inputElementType }
        : {}),
    },
    ...interactionPack,
  };
};
