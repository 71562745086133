import React from 'react';

function set100vhVar() {
  document.documentElement.style.setProperty(
    '--real100vh',
    `${window.innerHeight}px`
  );
}

export const SetViewHeightVar = () => {
  React.useLayoutEffect(() => {
    set100vhVar();
    window.addEventListener('resize', set100vhVar);
    return () => window.removeEventListener('resize', set100vhVar);
  }, []);

  return null;
};
