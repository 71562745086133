// @ts-nocheck
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { InputProps, PopoverProps, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC, useRef } from 'react';

import { FieldControlContext } from './FieldControl';
import { useField } from './useField';
import { noop } from './utils';

type Props = Partial<DatePickerProps> &
  Partial<InputProps> & {
    clearable?: boolean;
    component?: typeof DatePicker;
    format?: string;
    label: string;
    onChange?: (e: any) => any;
    variant?: string;
    error?: boolean;
  };

export const FieldDatePicker: FC<React.PropsWithChildren<Props>> = ({
  component: Component = DatePicker,
  onChange = noop,
  clearable,
  label,
  ref,
  variant = 'standard',
  size = 'medium',
  error,
  ...props
}) => {
  const inputRef = useRef(null);
  const { name, id } = React.useContext(FieldControlContext);
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({
    name,
    ref: inputRef,
    ...props,
  });
  const hasError = error || (meta.error !== undefined && meta.touched);
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <Component
        id={id}
        label={label}
        inputRef={inputRef}
        {...props}
        {...field}
        error={hasError}
        // If field isn't on the rawData object, we need to default
        // to '' in order to display an empty date field
        value={field.value !== undefined ? field.value : ''}
        renderInput={(props) => (
          <TextField
            variant={variant}
            size={size}
            data-testid={name}
            {...props}
            error={hasError}
          />
        )}
        labelFunc={(date) => {
          if (date && date.isValid()) {
            return date.format(props.format);
          }

          return '';
        }}
        onChange={(date: MaterialUiPickersDate) => {
          onChange(date);
          if (date && date.isValid()) {
            setFieldValue(name, date.toISOString());
          } else {
            setFieldValue(name, '');
          }
        }}
        css={{ margin: 0 }}
        components={{
          LeftArrowIcon: (props) => (
            <ChevronLeftIcon
              {...props}
              aria-label="Previous month"
              data-testid="fieldDatePickerPreviousButton"
            />
          ),
          RightArrowIcon: (props) => (
            <ChevronRightIcon
              {...props}
              aria-label="Next month"
              data-testid="fieldDatePickerNextButton"
            />
          ),
          ...(props.components || {}),
        }}
        PopperProps={
          {
            'data-testid': 'fieldDatePicker',
            'aria-label': label,
            'aria-expanded': 'true',
            role: 'dialog',
          } as Partial<PopoverProps>
        }
      />
    </LocalizationProvider>
  );
};
