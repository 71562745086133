import { css } from '@emotion/react';
import { Card } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

const cardRadioButtonContainerStyle = css`
  font-weight: ${theme.fontWeight.bold};
  font-size: ${theme.fontSize.sm};
  color: ${theme.color.darkGray};
  width: 160px;
  padding-top: ${theme.space.base};
  padding-bottom: ${theme.space.base};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${theme.space.sm};
  border: solid 1px transparent;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
  ${theme.media.smallDown} {
    width: 200px;
    padding-top: ${theme.space.lg};
    padding-bottom: ${theme.space.lg};
    font-size: ${theme.fontSize.base};
  }
`;

const cardRadioButtonContainerSelectedStyle = css`
  background-color: #e9f6e9;
  border: solid 1px ${theme.color.primary};
  color: #0c965b;
`;

interface CardRadioButtonProps {
  label: string;
  value: string;
  selected?: boolean;
  onClick?(): void;
}

export const CardRadioButton: React.FC<
  React.PropsWithChildren<CardRadioButtonProps>
> = (props) => {
  return (
    <Card
      css={[
        cardRadioButtonContainerStyle,
        props.selected ? cardRadioButtonContainerSelectedStyle : null,
      ]}
      onClick={props.onClick}
    >
      {props.label}
    </Card>
  );
};
