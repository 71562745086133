import React from 'react';

import { BodyText } from '@headway/helix/BodyText';

export interface TreatmentPlansRequirementsListProps {
  title: string;
}

export const TreatmentPlansRequirementsList = ({
  title,
}: TreatmentPlansRequirementsListProps) => {
  return (
    <div>
      <BodyText>
        <strong>{title}</strong>
      </BodyText>
      <ul>
        <li>
          <BodyText>Chief complaint</BodyText>
        </li>
        <ul css={{ listStyleType: 'disc' }}>
          <li>
            <BodyText>Diagnosis (including DSM-V or ICD-10 code)</BodyText>
          </li>
          <li>
            <BodyText>Symptoms</BodyText>
          </li>
          <li>
            <BodyText>Presenting concerns</BodyText>
          </li>
        </ul>
        <li>
          <BodyText>
            One or more goals, with a few objectives for each. Objectives should
            be tied directly to treating diagnoses and symptoms.
          </BodyText>
        </li>
        <li>
          <BodyText>Timeline for completing goals</BodyText>
        </li>
        <li>
          <BodyText>Confirmation of client's verbal agreement to plan</BodyText>
        </li>
      </ul>
    </div>
  );
};
