import React from 'react';

import { ProviderModule } from '@headway/api/models/ProviderModule';

export interface WizardContextState {
  title: string;
  module: ProviderModule;
  currentStep: number;
  totalSteps: number;
  setStep: (step: number) => void;
  setWidthOverride: (width?: number) => void;
  setHeightOverride: (height?: number) => void;
}

/**
 * Context used for communicating between Wizard and WizardStep. Other components should not use
 * this.
 */
export const WizardContext = React.createContext<WizardContextState>(
  {} as WizardContextState
);
