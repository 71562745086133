import { PatientAssessmentRecurrenceScheduleApi } from '@headway/api/resources/PatientAssessmentRecurrenceScheduleApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAssessmentRecurrenceSchedulesQueryKeyArgs {
  providerPatientId: number | undefined;
}
export const getUsePatientAssessmentRecurrenceSchedulesQueryKey = ({
  providerPatientId,
}: UsePatientAssessmentRecurrenceSchedulesQueryKeyArgs) =>
  ['patient-assessment-recurrence-schedules', providerPatientId] as const;
const {
  useSingleQuery: usePatientAssessmentRecurrenceSchedules,
  useListQuery: usePatientAssessmentRecurrenceSchedulesList,
  useCachedQuery: usePatientAssessmentRecurrenceSchedulesCache,
} = createBasicApiHooks(
  getUsePatientAssessmentRecurrenceSchedulesQueryKey,
  ({ providerPatientId }) =>
    PatientAssessmentRecurrenceScheduleApi.getPatientAssessmentRecurrenceSchedules(
      providerPatientId!
    ),
  ({ providerPatientId }) => providerPatientId != null,
  () => 'Failed to fetch patient assessment recurrence schedules'
);
export {
  usePatientAssessmentRecurrenceSchedules,
  usePatientAssessmentRecurrenceSchedulesList,
  usePatientAssessmentRecurrenceSchedulesCache,
};
