export function stringToHslColor(
  hueInput: string,
  saturation: number,
  lightness: number,
  alpha: number = 1
) {
  let hash = 0;

  for (let i = 0; i < hueInput.length; i++) {
    hash = Math.abs(Math.sin(hueInput.charCodeAt(i) + hash) * 360);
  }

  return `hsla(${hash},${saturation}%,${lightness}%,${alpha})`;
}
