import React from 'react';

import countries from '@headway/shared/constants/countries';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { FormComboBox, FormMultipleComboBox } from '.';
import { SchemaComponent } from '../../schema/schema.types';
import { getComponentData } from '../../schema/utils';
import {
  LocationSelectDataInterface,
  LocationType,
  SelectType,
} from './LocationSelect.types';

const stateList = Object.entries(statesToDisplayNames).map((value) => value[1]);
const countriesList = countries.map((countryInfo) => countryInfo.name);

export const LocationSelect = ({ element, injectedData }: SchemaComponent) => {
  const { locationSelectType, comboBoxSelectionType } =
    getComponentData<LocationSelectDataInterface>(element, injectedData);

  console.log('comboBOxSelectionTYpe: ', comboBoxSelectionType);

  let options: string[] = [];
  switch (locationSelectType) {
    case LocationType.COUNTRY:
      options = [...countriesList, 'Other'];
      break;
    case LocationType.STATE:
      options = [...stateList, 'Other'];
      break;
  }

  if (comboBoxSelectionType === SelectType.MULTIPLE) {
    return <FormMultipleComboBox element={{ ...element, options: options }} />;
  } else {
    return <FormComboBox element={{ ...element, options: options }} />;
  }
};
