/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderDocumentRemediationRead } from '../models/ProviderDocumentRemediationRead';
import { ProviderDocumentRemediationUpdate } from '../models/ProviderDocumentRemediationUpdate';
import { ProviderDocumentRemediationWithAuditResults } from '../models/ProviderDocumentRemediationWithAuditResults';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderDocumentRemediationApiResource {

    /**
     * Bulk Add Document Remediations
     * Endpoint that takes in:     - a csv of 3 columns: appointment id, semicolon delimited remediation descriptions and deadline if communication type is Medicare and Medicaid.     - a csv of 4 columns: appointment id, semicolon delimited remediation descriptions, deadline, and front end carrier id if communication type is PPR. Aggregates the csv info, to send emails to providers notifying them of remediations (or changes) needed to their documents.
     * @param communicationType 
     * @param file 
     
     */
    public bulkAddDocumentRemediations(file: any, query?: { communication_type: string }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-remediations/bulk-add-document-remediations';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Delete Document Remediations
     * Endpoint that takes in a csv of 1 column: provider appointment id Aggregates the csv info, to soft delete document remediations.
     * @param file 
     
     */
    public bulkDeleteDocumentRemediations(file: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-remediations/bulk-delete-document-remediations';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Update Document Remediations
     * Endpoint that takes in a csv of 2 columns: provider appointment id,deadline Aggregates the csv info to update the deadline for document remediations.
     * @param file 
     
     */
    public bulkUpdateDocumentRemediations(file: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-remediations/bulk-update-document-remediations';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Provider Document Remediations
     * 
     * @param providerId 
     * @param providerAppointmentId 
     
     */
    public findProviderDocumentRemediations(query?: { provider_id?: number, provider_appointment_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderDocumentRemediationWithAuditResults>> {
        const apiRoute = '/provider-document-remediations';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Document Remediation
     * 
     * @param providerDocumentRemediationId 
     * @param providerDocumentRemediationUpdate 
     
     */
    public updateProviderDocumentRemediation(providerDocumentRemediationId: number, body: ProviderDocumentRemediationUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderDocumentRemediationRead> {
        const apiRoute = '/provider-document-remediations/{provider_document_remediation_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_document_remediation_id}', String(providerDocumentRemediationId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderDocumentRemediationApi = new ProviderDocumentRemediationApiResource();
