import { Formik } from 'formik';
import React from 'react';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Modal } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { logException } from '@headway/shared/utils/sentry';
import { Form } from '@headway/ui/form/Form';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { redirectToAuth0Logout, useAuth0Client } from 'utils/auth';

interface MFAModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  AuthStore: any;
}

type Props = MFAModalProps;

const ResetMFAModalImpl: React.FC<Props> = (props) => {
  const auth0Client = useAuth0Client();
  const handleOnSubmit = async (values: any) => {
    try {
      const res = await AuthApi.auth0DeleteMfaMethods({
        password: values.password,
      });
      notifySuccess('Two-Factor Authentication has been reset.');
      setTimeout(() => {}, 1000);
      props.onClose();
      // if the above succceeds, the user will be logged out
      await redirectToAuth0Logout(auth0Client!);
    } catch (e: any) {
      if (e.response?.data.detail.includes('loginFailed')) {
        notifyError('Invalid password.');
      } else {
        notifyError('Failed to reset two-factor authentication.');
      }
      logException(e);
      props.onClose();
    }
  };
  return (
    <Modal
      variant="content"
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      title="Reset Two-Factor Authentication (2FA)?"
    >
      <div css={{ padding: theme.spacing.x6 }}>
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={handleOnSubmit}
          render={({ isSubmitting }) => (
            <Form>
              <GuidanceCard variant="info">
                Resetting your Two-Factor Authentication will log you out and
                prompt you to re-enter your password.
              </GuidanceCard>
              <FormControl
                name="password"
                component={TextField}
                label="Password"
                type={'password'}
                autoComplete="current-password"
                data-testid="password-input"
              />

              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.spacing.x6,
                  gap: theme.spacing.x2,
                }}
              >
                <Button
                  variant="secondary"
                  disabled={isSubmitting}
                  onPress={props.onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="medium"
                  disabled={isSubmitting}
                >
                  Reset 2FA
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

interface EnrollMFAModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  AuthStore: any;
}

export const EnrollMFAModal: React.FC<EnrollMFAModalProps> = ({
  isOpen,
  onClose,
  userId,
  AuthStore,
}) => {
  const auth0Client = useAuth0Client();
  const handleOnSubmit = async (values: any) => {
    try {
      await UserApi.enableUserMfa(userId);
      notifySuccess('Two-Factor Authentication has been enabled.');
      onClose();
      // if the above succceeds, the user will be logged out
      await redirectToAuth0Logout(auth0Client!);
    } catch (e: any) {
      notifyError('Failed to enroll in two-factor authentication.');
      logException(e);
    }
  };

  return (
    <Modal
      variant="content"
      isOpen={isOpen}
      onDismiss={onClose}
      title="Enroll in Two-Factor Authentication (2FA)"
    >
      <div css={{ padding: theme.spacing.x6 }}>
        <Formik
          initialValues={{
            password: '',
          }}
          onSubmit={handleOnSubmit}
          render={({ isSubmitting }) => (
            <Form>
              <div>
                When you're logging into Headway, we can send a unique
                verification code to your phone or email. Enrolling in 2FA will
                help make your account more secure.
              </div>
              <div
                css={{
                  marginTop: theme.spacing.x3,
                }}
              >
                <FormControl
                  name="password"
                  component={TextField}
                  label="Password"
                  type={'password'}
                  autoComplete="current-password"
                  data-testid="password-input"
                />
              </div>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.spacing.x6,
                  gap: theme.spacing.x2,
                }}
              >
                <Button variant="secondary" onPress={() => {}}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" size="medium">
                  Enroll
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

export const ResetMFAModal = ResetMFAModalImpl;
