import createCache from '@emotion/cache';
import { CacheProvider, Global } from '@emotion/react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Script from 'react-load-script';

import { UserRead } from '@headway/api/models/UserRead';
import { AuthApi } from '@headway/api/resources/AuthApi';
import { useQuery } from '@headway/shared/react-query';
import { isServer } from '@headway/shared/utils/isServer';
import { logException } from '@headway/shared/utils/sentry';

interface SolvvyOffset {
  horizontal?: string;
  vertical?: string;
}

export interface SolvvySettings {
  offset?: SolvvyOffset;
  position?: 'bottom-left' | 'bottom-right';
  showWidget?: 'always' | 'never' | 'after-open';
}

export interface SolvvyWidgetProps {
  user: UserRead;
  settings?: SolvvySettings;
}

// Global flag to check if Solvvy has ever been initialized on the page.
let solvvyInitialized = false;

/** Loads a Solvvy widget onto the page and exposes an auth token for it. */
export const SolvvyWidget: React.FC<
  React.PropsWithChildren<SolvvyWidgetProps>
> = ({ user, settings = {} }) => {
  const [isSolvvyReady, setIsSolvvyReady] =
    useState<boolean>(solvvyInitialized);
  const email = user.email;

  const { data: solvvyAuthToken } = useQuery(
    ['solvvy-auth-token', email],
    async () => {
      const token = await AuthApi.getSolvvyAuthToken();
      return { token: token, owner: email };
    },
    { refetchOnWindowFocus: false }
  );

  const { setController } = useContext(SolvvyControllerContext);

  // Listen for custom Solvvy event to know when the Solvvy window object is available.
  // https://developers.solvvy.com/web-widget-api/getting-started
  useEffect(() => {
    const onSolvvyReady = () => {
      solvvyInitialized = true;
      setIsSolvvyReady(true);
      setController((window as any)['Solvvy'] as SolvvyController);
    };

    if (!isSolvvyReady) {
      document.addEventListener('solvvy_ready', onSolvvyReady);
      return () => {
        document.removeEventListener('solvvy_ready', onSolvvyReady);
      };
    } else {
      onSolvvyReady();
    }
  });

  useEffect(() => {
    // Use string literals to guard against automated variable renaming
    (window as any)['SolvvySettings'] = settings;
    if (isSolvvyReady) {
      (window as any)['Solvvy'].updateSettings(settings);
    }
    return () => {
      // When the component unmounts, hide the widget because the global styling will go away.
      if (isSolvvyReady) {
        (window as any)['Solvvy'].updateSettings({
          ...(settings || {}),
          showWidget: 'never',
        });
      }
    };
  }, [settings, isSolvvyReady]);

  useEffect(() => {
    if (!solvvyAuthToken) {
      return;
    }

    // Use session storage so that Solvvy doesn't get reset when refreshing the page.
    const lastUsedTokenOwner = window.sessionStorage.getItem(
      'solvvy-lastUsedTokenOwner'
    );
    (window as any)['solvvyAuthToken'] = solvvyAuthToken.token;
    // If the logged in user has changed, reset Solvvy after updating the token.
    if (solvvyAuthToken.owner !== lastUsedTokenOwner && isSolvvyReady) {
      (window as any)['Solvvy'].reset();
    }
    window.sessionStorage.setItem(
      'solvvy-lastUsedTokenOwner',
      solvvyAuthToken.owner!
    );
  }, [solvvyAuthToken, isSolvvyReady]);

  // Create an Emotion cache which can be used to place CSS inside the Solvvy iframe.
  const solvvyIframeCache = useMemo(() => {
    if (!isSolvvyReady || isServer) {
      return undefined;
    }
    const solvvyIframe = document.getElementById(
      'solvvy-widget-iframe'
    ) as HTMLIFrameElement | null;
    if (!solvvyIframe?.contentDocument?.body) {
      return undefined;
    }
    return createCache({
      key: 'solvvy-iframe',
      container: solvvyIframe.contentDocument.body,
    });
  }, [isSolvvyReady]);

  const onError = () => {
    logException('Unable to load Solvvy widget');
  };

  if (!solvvyAuthToken) {
    return null;
  }

  return (
    <>
      <Global
        styles={{
          '#solvvy-widget-iframe': {
            height: '46px !important',
            width: '46px !important',
            zIndex: '5 !important',
          },
          '#solvvy-ui-iframe:not(.solvvy-mobile)': {
            bottom: `calc(58px + ${
              settings.offset?.vertical || '0px'
            }) !important`,
          },
          '#solvvy-ui-iframe.solvvy-position-bottom-right:not(.solvvy-mobile)':
            {
              right: settings.offset?.horizontal
                ? `${settings.offset.horizontal} !important`
                : 'unset',
            },
          '#solvvy-ui-iframe.solvvy-position-bottom-left:not(.solvvy-mobile)': {
            left: settings.offset?.horizontal
              ? `${settings.offset.horizontal} !important`
              : 'unset',
          },
        }}
      />
      {solvvyIframeCache && (
        <CacheProvider value={solvvyIframeCache}>
          <Global
            styles={{
              'button.position-bottom-right': {
                bottom: '0 !important',
                right: '0 !important',
              },
              'button.position-bottom-left': {
                bottom: '0 !important',
                left: '0 !important',
              },
            }}
          />
        </CacheProvider>
      )}
      <Script
        url="https://cdn.solvvy.com/deflect/customization/headway/solvvy.js"
        onError={onError}
      />
    </>
  );
};

/** See https://developers.solvvy.com/web-widget-api/getting-started */
export interface SolvvyController {
  open: () => void;
  close: () => void;
}

export const SolvvyControllerContext = createContext<{
  controller: SolvvyController | undefined;
  setController: (controller: SolvvyController | undefined) => void;
}>({ controller: undefined, setController: () => {} });

/**
 * Exposes a controller for the Solvvy widget. The controller's value will automatically be set
 * by SolvvyWidget as long as it is a child of this provider.
 */
export const SolvvyControllerProvider: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => {
  const [controller, setController] = useState<SolvvyController | undefined>();
  return (
    <SolvvyControllerContext.Provider
      value={{
        controller,
        setController,
      }}
      {...props}
    />
  );
};

/**
 * Hook for getting a controller for the Solvvy widget. Can only be used by descendants of a
 * SolvvyControllerProvider.
 */
export const useSolvvyController = () =>
  useContext(SolvvyControllerContext).controller;
