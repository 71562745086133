import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import React from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { PageHeader } from '@headway/helix/PageHeader';
import { TextField } from '@headway/helix/TextField';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { useTailwindGlobals } from '../../utils/css';

interface ResetPasswordProps {
  AuthStore?: any;
}

const ResetPasswordWithAuthStore: React.FC<
  React.PropsWithChildren<ResetPasswordProps>
> = (props) => {
  useTailwindGlobals();
  const { search } = useLocation();
  const navigate = useNavigate();

  const { token } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  if (!token || typeof token !== 'string') {
    return <Navigate to="/auth/login" replace />;
  }

  const onSubmit = async ({ password }: { password: string }) => {
    try {
      await AuthApi.resetPasswordViaToken(
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate('/', { replace: true });
    } catch (e) {
      notifyError('There was a problem resetting your password.');
      logException(e);
    }
  };

  return (
    <div className="rounded-sm mx-auto w-[512px] self-center justify-self-center bg-system-white px-6 py-5 shadow-medium">
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .max(64, 'Passwords cannot be more than 64 characters')
            .required('Please provide a password'),
          confirmPassword: Yup.string()
            .oneOf(
              [Yup.ref('password'), undefined],
              `These passwords don't match`
            )
            .required('Please confirm the password'),
        })}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <h1 className="m-0">
                <PageHeader>Reset your password</PageHeader>
              </h1>
              <FormControl
                name="password"
                component={TextField}
                label="New password"
                autoComplete="new-password"
                type="password"
              />

              <FormControl
                name="confirmPassword"
                component={TextField}
                label="Confirm new password"
                autoComplete="new-password"
                type="password"
              />

              <Button variant="primary" type="submit">
                Reset
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const ResetPassword = inject('AuthStore')(
  // without this observer, mobx won't re-render this component on store changes
  observer(
    class ResetPassword extends React.Component<{ AuthStore?: any }> {
      render() {
        const { AuthStore } = this.props;
        if (AuthStore) {
          // eslint-disable-next-line
          const { user } = AuthStore; // without unnecessarily referencing user, mobx won't re-render this component on store changes

          return <ResetPasswordWithAuthStore AuthStore={AuthStore} />;
        }

        return null;
      }
    }
  )
);
