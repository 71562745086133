import React from 'react';

import { theme } from '@headway/ui/theme';

export const EventContent: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <div
      css={{
        padding: theme.space.base,
      }}
    >
      {children}
    </div>
  );
};
