import React from 'react';

export function IconChevronLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 13.7075L8.80167 9.5L13 5.2925L11.7075 4L6.2075 9.5L11.7075 15L13 13.7075Z"
        fill="currentColor"
      />
    </svg>
  );
}
