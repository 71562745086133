import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { useProvider } from 'hooks';
import { useContext, useRef } from 'react';
import { useIntersection } from 'react-use';

import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useUser } from '@headway/shared/hooks/useUser';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { HeadwayLogo } from '@headway/ui/icons/HeadwayLogo';

import { InfoBox } from '../Components/InfoBox';
import { TreatmentPlanPreview } from '../Components/TreatmentPlanPreview';
import { firstStepWithErrors, getErrors } from '../Template/errors';
import { trackSaveButtonClickedEvent } from '../TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from '../TreatmentPlanContext';
import {
  firstStepWithMissingFields,
  stepCharacterCount,
  stepHasAllValues,
} from '../TreatmentPlanUtils';
import { Page } from './Page';

export interface SummaryPageProps {
  closeModal: () => void;
}

export const SummaryPage = ({ closeModal }: SummaryPageProps) => {
  const {
    setPage,
    setCurrentStep,
    treatmentPlan,
    steps,
    providerPatient,
    setInitialValues,
  } = useContext(TreatmentPlanContext);
  const { values, submitForm, validateForm } = useFormikContext<any>();

  const provider = useProvider();
  const { data: client } = useUser({ userId: providerPatient!.userId });
  const checkboxRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(checkboxRef, {
    root: null,
    rootMargin: '0px',
  });
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery({
      providerId: provider.id,
      patientUserId: providerPatient?.userId,
    });
  const matchingFrontendCarrierName =
    matchingProviderFrontEndCarrier?.frontEndCarrier?.name;

  const hasMissingValues =
    treatmentPlan?.planType === TreatmentPlanType.TEMPLATE &&
    (steps || []).some((step) => !stepHasAllValues(values, step));

  const hasErrors =
    Object.keys(
      (treatmentPlan?.planType === TreatmentPlanType.TEMPLATE &&
        getErrors(values, steps)) ||
        {}
    ).length > 0;

  const handlePressCompleteMissingFields = async () => {
    setInitialValues(values);
    setPage(TREATMENT_PLAN_PAGES.TEMPLATE);
    setCurrentStep(firstStepWithMissingFields(values, steps));
    validateForm();
  };

  const handlePressFixErrorFields = async () => {
    setInitialValues(values);
    setPage(TREATMENT_PLAN_PAGES.TEMPLATE);
    setCurrentStep(firstStepWithErrors(values, steps));
    validateForm();
  };

  const handleScrollToConfirmation = () => {
    checkboxRef.current?.scrollIntoView();
  };

  return (
    <Page
      buttons={[
        <Button
          disabled={
            hasMissingValues || hasErrors || !values?.treatmentPlanAttestation
          }
          key="sign-and-save"
          variant="primary"
          onPress={async () => {
            trackSaveButtonClickedEvent({
              providerPatient: providerPatient!,
              treatmentPlanType: TreatmentPlanType.TEMPLATE,
              characterCount: (steps || []).reduce(
                (charCount, step) =>
                  charCount + stepCharacterCount(values, step),
                0
              ),
            });
            await submitForm();
            closeModal();
          }}
        >
          Sign and save
        </Button>,
      ]}
      hint="Once signed, this plan cannot be edited"
    >
      <SafeFormikForm className="template-treatment-plan-review-and-sign">
        <div css={summaryPageCss.headerContainer}>
          <div>
            <div css={summaryPageCss.headerText}>
              <SectionHeader>Review, sign, and save</SectionHeader>
            </div>
            {hasMissingValues ? (
              <GuidanceCard variant="error" layout="vertical">
                <BodyText>
                  Missing fields: Please complete the highlighted fields below
                  to sign and save.
                </BodyText>
                <Button
                  variant="link"
                  onPress={handlePressCompleteMissingFields}
                >
                  Complete missing fields
                </Button>
              </GuidanceCard>
            ) : (
              hasErrors && (
                <GuidanceCard variant="error" layout="vertical">
                  <BodyText>
                    Form errors: Please fix the highlighted fields below to sign
                    and save.
                  </BodyText>
                  <Button variant="link" onPress={handlePressFixErrorFields}>
                    Fix fields
                  </Button>
                </GuidanceCard>
              )
            )}
          </div>
          <HeadwayLogo css={summaryPageCss.headwayLogo} />
          {client && <InfoBox clientId={client.id} />}
        </div>
        <TreatmentPlanPreview closeModal={closeModal} />
        <div ref={checkboxRef}>
          <FormControl component={Checkbox} name="treatmentPlanAttestation">
            <div>
              I confirm that...
              <ul css={{ marginTop: theme.spacing.x1 }}>
                <li>
                  I have discussed the risks and benefits of the treatment plan
                  with the client, and the client has acknowledged and agreed to
                  this plan.
                </li>
                <li>
                  My plan is true, accurate and meets{' '}
                  {matchingFrontendCarrierName ?? 'the payer'}
                  's requirements, and — if requested by Headway — I'll provide
                  extra information on request within three business days.
                </li>
              </ul>
            </div>
          </FormControl>
        </div>
      </SafeFormikForm>
      {intersection && !intersection.isIntersecting && (
        <div css={summaryPageCss.floatingButton}>
          <Button variant="secondary" onPress={handleScrollToConfirmation}>
            Scroll to confirmation
          </Button>
        </div>
      )}
    </Page>
  );
};

const summaryPageCss = {
  headerText: css({
    padding: `${theme.spacing.x5} 0`,
  }),
  headerContainer: css({
    ...theme.stack.vertical,
    gap: theme.spacing.x5,
  }),
  headwayLogo: css({
    width: '174px',
  }),
  floatingButton: css({
    position: 'fixed',
    bottom: theme.spacing.x8,
    right: theme.spacing.x9,
  }),
};
