import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { Button } from '@headway/helix/Button';
import { Modal } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useSelectedEvent } from 'hooks/useSelectedEvent';

type DuplicateAppointmentWarningModalProps = {
  open: boolean;
  onClose: () => void;
};

export const DuplicateAppointmentWarningModal: React.FC<
  React.PropsWithChildren<DuplicateAppointmentWarningModalProps>
> = ({ open, onClose }) => {
  const { event } = useSelectedEvent();
  const isSelfPay =
    event?.providerAppointment?.billingType === BillingType.SELF_PAY;
  const isEAP = event?.providerAppointment?.billingType === BillingType.EAP;
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Duplicate Appointment Warning"
      closeable={false}
    >
      {isSelfPay || isEAP ? (
        <p>
          It looks like you already have an appointment scheduled with this
          client on this date. If this was an error, you can cancel directly
          from your calendar.
        </p>
      ) : (
        <p>
          You already have an appointment scheduled with this client on this
          date. Insurance companies do not allow billing multiple sessions for
          the same client on the same day unless one is a family psychotherapy
          session and the other is an individual psychotherapy session.
          <br />
          <br />
          Because of this, you will only be able to confirm and get paid for
          these sessions if you confirm one with a family psychotherapy CPT code
          (90846 or 90847) and the other with an individual psychotherapy CPT
          code (90832, 90833, 90834, 90836, 90837, or 90838).
          <br />
          <br />
          If you scheduled this appointment in error, you can cancel it from
          your calendar.
        </p>
      )}

      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.space.xl2,
        }}
      >
        <Button
          variant="primary"
          data-testid="duplicateAppointmentWarningOkayButton"
          onPress={onClose}
        >
          Okay
        </Button>
      </div>
    </Modal>
  );
};
