/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum PatientPaymentStatus {
    PENDING_PARTIAL_REFUND = 'Pending Partial Refund',
    WAIVED = 'Waived',
    PAYMENT_FAILED = 'Payment Failed',
    PENDING_REFUND = 'Pending Refund',
    UNPAID = 'Unpaid',
    PAID = 'Paid',
    PARTIAL_REFUND = 'Partial Refund',
    NOT_CHARGED = 'Not Charged',
    PENDING = 'Pending',
    REFUNDED = 'Refunded',
    PARTIAL_REFUND_FAILED = 'Partial Refund Failed',
    REFUND_FAILED = 'Refund Failed',
    PARTIALLY_PAID = 'Partially Paid'
}
