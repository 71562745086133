import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  HandleLocationSelectProps,
  HelixLocationFilter,
} from '@headway/ui/HelixLocationFilter';

import { SchemaComponent } from '../../schema/schema.types';
import { getComponentData } from '../../schema/utils';
import { AddressInputDataInterface } from './AddressInput.types';
import { FormError } from './FormError';
import { FormTitle } from './FormTitle';

const AddressDisplay = ({
  address,
  Component = SubBodyText,
}: {
  address: HandleLocationSelectProps;
  Component?: typeof SubBodyText | typeof BodyText;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing.x2,
      }}
    >
      <div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Component>{address.streetLine1}</Component>
          <Component>{address.streetLine2}</Component>
        </div>
        <Component>
          {address.city ? (
            <span>
              {address.city},{' '}
              {statesToDisplayNames[address.state as UnitedStates]}
            </span>
          ) : (
            <span>{statesToDisplayNames[address.state as UnitedStates]}</span>
          )}{' '}
          {address.zipCode}
        </Component>
      </div>
    </div>
  );
};

export const AddressInput = ({
  element,
  injectedData,
  readOnly,
}: SchemaComponent) => {
  const { id, title, subTitle, placeholder } = element;
  const { maxAddressNumber } = getComponentData<AddressInputDataInterface>(
    element,
    injectedData
  );
  const { values, setFieldValue } = useFormikContext<any>();
  const addresses: any[] = _.get(values, id) ?? [];
  const maxAddressNumberParsed = maxAddressNumber;

  const [potentialAddress, setPotentialAddress] = useState<
    HandleLocationSelectProps | undefined
  >(undefined);
  const [secondAddressLine, setSecondAddressLine] = useState<
    string | undefined
  >(undefined);

  const removeAddress = (idx: number) => {
    const updatedAddress = [...addresses];
    updatedAddress.splice(idx, 1);
    setFieldValue(id, updatedAddress);
  };

  const onAddressSave = () => {
    const newAddress = {
      ...potentialAddress,
      streetLine2: secondAddressLine,
    };
    setFieldValue(id, [...addresses, newAddress]);
    setPotentialAddress(undefined);
    setSecondAddressLine(undefined);
  };

  return (
    <div>
      {readOnly && <FormTitle title={title} />}
      {!readOnly && (
        <HelixLocationFilter
          label={title}
          instructionalText={subTitle}
          placeholder={placeholder}
          GOOGLE_MAPS_API_KEY={process.env.REACT_APP_GOOGLE_MAPS_API_ID ?? ''}
          name={'addressLookup'}
          selectionMode="single"
          handleLocationSelect={(address, clearInput) => {
            setPotentialAddress(address);
            clearInput();
          }}
          disabled={addresses && addresses.length === maxAddressNumberParsed}
        />
      )}
      <FormError id={id} />
      {potentialAddress && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing.x2,
            marginTop: theme.spacing.x2,
          }}
        >
          <AddressDisplay address={potentialAddress} Component={BodyText} />
          <TextField
            name="secondAddressLine"
            placeholder="(Optional) Apartment number or suite"
            value={secondAddressLine}
            onChange={(value) => setSecondAddressLine(value)}
          />
          <Button onPress={onAddressSave}>Save</Button>
        </div>
      )}
      {addresses && (
        <div
          css={{
            padding: theme.spacing.x3,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing.x4,
            marginTop: potentialAddress ? theme.spacing.x4 : 0,
          }}
        >
          {addresses.map((address, idx) => {
            return (
              <div
                key={idx}
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <AddressDisplay address={address} />
                {!readOnly && (
                  <Button
                    variant="link"
                    size="medium"
                    onPress={() => removeAddress(idx)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
