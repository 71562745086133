import { Ethnicity } from '@headway/api/models/Ethnicity';
import { GenderStatus } from '@headway/api/models/GenderStatus';
import { Language } from '@headway/api/models/Language';
import { PatientGender } from '@headway/api/models/PatientGender';

export const GENDER_DISPLAY_NAMES: {
  [gender in PatientGender]: string;
} = {
  [PatientGender.FEMALE]: 'Female',
  [PatientGender.MALE]: 'Male',
  [PatientGender.NON_BINARY]: 'Non-binary',
  [PatientGender.NOT_DISCLOSED]: 'Prefer not to answer',
};

export const GENDER_STATUS_DISPLAY_NAMES: {
  [status in GenderStatus]: string;
} = {
  [GenderStatus.CISGENDER]: 'Cisgender',
  [GenderStatus.TRANSGENDER]: 'Transgender',
  [GenderStatus.NOT_DISCLOSED]: 'Prefer not to answer',
};

export const ETHNICITY_DISPLAY_NAMES: {
  [ethnicity in Ethnicity]: string;
} = {
  [Ethnicity.BLACK_OR_AFRICAN_DESCENT]: 'Black or African Descent',
  [Ethnicity.CAUCASIAN]: 'Caucasian',
  [Ethnicity.EAST_ASIAN]: 'East Asian',
  [Ethnicity.HISPANIC]: 'Hispanic',
  [Ethnicity.LATINX]: 'Latinx',
  [Ethnicity.NATIVE_AMERICAN]: 'Native American',
  [Ethnicity.PACIFIC_ISLANDER]: 'Pacific Islander',
  [Ethnicity.SOUTH_ASIAN]: 'South Asian',
  [Ethnicity.SOUTHEAST_ASIAN]: 'Southeast Asian',
  [Ethnicity.OTHER_RACIAL_OR_ETHNIC_BACKGROUND]:
    'Other Racial or Ethnic Background',
};

export const LANGUAGE_DISPLAY_NAMES: {
  [language in Language]: string;
} = {
  // top two most used languages on Headway pinned up top
  [Language.ENGLISH]: 'English',
  [Language.SPANISH]: 'Spanish',

  [Language.ALBANIAN]: 'Albanian',
  [Language.AMERICAN_SIGN_LANGUAGE]: 'American Sign Language',
  [Language.ARABIC]: 'Arabic',
  [Language.ARMENIAN]: 'Armenian',
  [Language.ASSAMESE]: 'Assamese',
  [Language.BENGALI]: 'Bengali',
  [Language.BOSNIAN]: 'Bosnian',
  [Language.BULGARIAN]: 'Bulgarian',
  [Language.CANTONESE]: 'Cantonese',
  [Language.CROATIAN]: 'Croatian',
  [Language.DANISH]: 'Danish',
  [Language.DUTCH]: 'Dutch',
  [Language.FARSI]: 'Farsi',
  [Language.FLEMISH]: 'Flemish',
  [Language.FRENCH]: 'French',
  [Language.FUZHOUNESE]: 'Fuzhounese',
  [Language.GERMAN]: 'German',
  [Language.GREEK]: 'Greek',
  [Language.GUJARATI]: 'Gujarati',
  [Language.HAITIAN_CREOLE]: 'Haitian Creole',
  [Language.HEBREW]: 'Hebrew',
  [Language.HINDI]: 'Hindi',
  [Language.HUNGARIAN]: 'Hungarian',
  [Language.ICELANDIC]: 'Icelandic',
  [Language.IGBO]: 'Igbo',
  [Language.ITALIAN]: 'Italian',
  [Language.JAPANESE]: 'Japanese',
  [Language.KASHMIRI]: 'Kashmiri',
  [Language.KOREAN]: 'Korean',
  [Language.KURDISH]: 'Kurdish',
  [Language.MALAYALAM]: 'Malayalam',
  [Language.MANDARIN]: 'Mandarin',
  [Language.MARATHI]: 'Marathi',
  [Language.NORWEGIAN]: 'Norwegian',
  [Language.POLISH]: 'Polish',
  [Language.PORTUGUESE]: 'Portuguese',
  [Language.PUNJABI]: 'Punjabi',
  [Language.ROMANIAN]: 'Romanian',
  [Language.RUSSIAN]: 'Russian',
  [Language.SERBIAN]: 'Serbian',
  [Language.SHONA]: 'Shona',
  [Language.SOMALI]: 'Somali',
  [Language.SWAHILI]: 'Swahili',
  [Language.TAGALOG]: 'Tagalog',
  [Language.THAI]: 'Thai',
  [Language.TURKISH]: 'Turkish',
  [Language.TWI]: 'Twi',
  [Language.UKRAINIAN]: 'Ukrainian',
  [Language.URDU]: 'Urdu',
  [Language.VIETNAMESE]: 'Vietnamese',
  [Language.YIDDISH]: 'Yiddish',
  [Language.YORUBA]: 'Yoruba',
};
