import { VisibilityTwoTone } from '@mui/icons-material';
import moment from 'moment';
import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Tooltip } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import {
  getBookingNotificationEnd,
  getBookingWindowEnd,
} from '../events/util/events';

function getDiffFromBookingEnd(date: Date) {
  return moment(date).diff(getBookingWindowEnd());
}

function getDiffFromBookingNotificationEnd(date: Date) {
  return moment(date).diff(getBookingNotificationEnd());
}

function isWithinNotificationWindow(date: Date) {
  return (
    getDiffFromBookingEnd(date) > 0 &&
    getDiffFromBookingNotificationEnd(date) < 0
  );
}

function getVisibleDate(date: Date) {
  return moment().add(getDiffFromBookingEnd(date)).toDate();
}

type CalendarDayVisibileNotificationProps = {
  date: Date;
  provider: ProviderRead;
};

export const CalendarDayVisibileNotification: React.FC<
  React.PropsWithChildren<CalendarDayVisibileNotificationProps>
> = ({ date, provider, ...rest }) => {
  if (
    !isWithinNotificationWindow(date) ||
    !provider.providerLicenseState.shownInSearch
  ) {
    return null;
  }

  const label = `Openings on this day will be visible to clients on ${moment(
    getVisibleDate(date)
  ).format('dddd, MMMM Do')}.`;

  return (
    <Tooltip title={label}>
      <VisibilityTwoTone
        css={{
          fontSize: theme.fontSize.base,
          color: theme.color.black,
        }}
        aria-label={label}
        {...rest}
      />
    </Tooltip>
  );
};
