import { Form as FormikForm, FormikFormProps, useFormikContext } from 'formik';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

export type MuiRefHandle = {
  focus: (options?: FocusOptions) => void;
  node: HTMLElement;
};

export type RegisteredRef = HTMLFormElement | MuiRefHandle;

type FocusableRef = {
  name: string;
  ref: React.RefObject<RegisteredRef>;
  focus: (options?: FocusOptions) => void;
};

type FieldMap = {
  [name: string]: FocusableRef;
};

export const FormContext = React.createContext({
  registerField: (
    name: string,
    ref: React.RefObject<RegisteredRef>,
    focus: (options?: FocusOptions) => void
  ) => {
    // TODO (Stephen): After we migrate all existing instances of Formik's Form to this Form component log an error to explain that they must use Form @headway/ui/form instead
  },
  unregisterField: (name: string) => {},
});

function getFocusableRefNode(focusableRef: FocusableRef): HTMLElement {
  return focusableRef.ref.current && 'node' in focusableRef.ref.current
    ? focusableRef.ref.current.node
    : focusableRef.ref.current;
}

// This form component allows fields that need to be focused on error to be registered
// so that it can scroll it into view and call .focus() on them when there is an error and the form is submitted
export function Form(props: FormikFormProps) {
  const { isSubmitting, isValidating, errors, submitCount } = useFormikContext<{
    [key: string]: any;
  }>();
  const [sortedNodes, setSortedNodes] = useState<
    { name: string; node: HTMLElement }[]
  >([]);
  const [fieldMap, setFieldMap] = useState<FieldMap>({});

  useLayoutEffect(() => {
    // When a field is registered and after the DOM is updated, sort registered fields based
    // on natural DOM order
    const nodes = Object.entries(fieldMap)
      .filter(([_, val]) => !!val.ref.current)
      .map(([key, val]) => ({
        name: key,
        node: getFocusableRefNode(val),
      }))
      .sort(compareNodes);

    setSortedNodes(nodes);
  }, [fieldMap]);

  const totalSubmitCountRef = React.useRef<number>(submitCount);
  useEffect(() => {
    // On submit, focus the first registered field with an error in the form
    if (
      submitCount > totalSubmitCountRef.current &&
      !isValidating &&
      Object.keys(errors).length
    ) {
      const erroredNode = sortedNodes.find((n) => !!errors[n.name]);
      const toFocus = erroredNode && fieldMap[erroredNode.name];
      if (toFocus) {
        const scrollTo =
          getFocusableRefNode(toFocus)?.closest('.MuiFormControl-root') ||
          getFocusableRefNode(toFocus);

        if (scrollTo) {
          scrollTo.scrollIntoView();
        }
        toFocus.focus({ preventScroll: true });
      }

      totalSubmitCountRef.current = submitCount;
    }
  }, [submitCount, isValidating, errors, fieldMap, sortedNodes]);

  const registerField = useCallback(
    (
      name: string,
      ref: React.RefObject<RegisteredRef>,
      focus: (options?: FocusOptions) => void
    ) => {
      setFieldMap((newValue) => ({
        ...newValue,
        [name]: { name, ref, focus },
      }));
    },
    []
  );

  const unregisterField = useCallback((name: string) => {
    setFieldMap((newValue) => {
      const { [name]: _, ...rest } = newValue;
      return rest;
    });
  }, []);

  return (
    <FormContext.Provider
      value={{
        registerField,
        unregisterField,
      }}
    >
      <FormikForm {...props} method={props.method || 'POST'} />
    </FormContext.Provider>
  );
}

// sort an array of DOM nodes according to the HTML tree order
// http://www.w3.org/TR/html5/infrastructure.html#tree-order
function compareNodes(
  a: { name: string; node: HTMLElement },
  b: { name: string; node: HTMLElement }
) {
  const posCompare = a.node.compareDocumentPosition(b.node);

  if (posCompare & 4 || posCompare & 16) {
    // a < b
    return -1;
  } else if (posCompare & 2 || posCompare & 8) {
    // a > b
    return 1;
  } else if (posCompare & 1 || posCompare & 32) {
    throw 'Cannot sort the given nodes.';
  } else {
    return 0;
  }
}
