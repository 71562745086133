import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodApi } from '@headway/api/resources/UserPaymentMethodApi';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@headway/shared/react-query';

export const getUseUserPaymentMethodQueryKey = (
  userPaymentMethodId: number | undefined
): QueryKey => ['user-payment-method', userPaymentMethodId];

export const useUserPaymentMethod = (
  userPaymentMethodId: number | undefined,
  options?: UseQueryOptions<UserPaymentMethodRead | undefined>
) => {
  return useQuery(
    getUseUserPaymentMethodQueryKey(userPaymentMethodId),
    () => UserPaymentMethodApi.getUserPaymentMethod(userPaymentMethodId!),
    {
      staleTime: Infinity,
      ...options,
      enabled: !!userPaymentMethodId && (options?.enabled ?? true),
    }
  );
};

export const getUseUserPaymentMethodsQueryKey = (
  userId: number | undefined
): QueryKey => ['UserPaymentMethods', userId];

export const useUserPaymentMethods = (
  userId: number | undefined,
  options?: UseQueryOptions<UserPaymentMethodRead[] | undefined>
) => {
  return useQuery(
    getUseUserPaymentMethodsQueryKey(userId),
    () => {
      return UserPaymentMethodApi.getUserPaymentMethodsForUser({
        user_id: userId!,
      });
    },
    {
      staleTime: Infinity,
      ...options,
      enabled: !!userId && (options?.enabled ?? true),
    }
  );
};
