import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
} from '@mui/material';
import React from 'react';

import { theme } from './theme';

interface RadioProps {
  label: React.ReactElement | string;
  classes?: object;
}

export const Radio: React.FC<
  React.PropsWithChildren<RadioProps & MuiRadioProps>
> = ({ label, classes = {}, ...rest }) => (
  <FormControlLabel
    label={label}
    css={{
      cursor: 'pointer',
      paddingLeft: '0',
      color: theme.color.black,
      fontFamily: theme.fontFamily.postGrotesk,
      lineHeight: '1.5',
      fontWeight: theme.fontWeight.base,
      display: 'inline-flex',
      transition: '0.3s ease all',
    }}
    control={
      <MuiRadio
        icon={
          <FiberManualRecord
            sx={{
              width: '0px',
              height: '0px',
              padding: '8px',
              border: `1px solid ${theme.color.gray}`,
              borderRadius: '50%',
            }}
          />
        }
        checkedIcon={
          <FiberManualRecord
            sx={{
              color: theme.color.primary,
              width: '18px',
              height: '18px',
              border: '1px solid ' + theme.color.primary,
              borderRadius: '50%',
            }}
          />
        }
        {...rest}
      />
    }
  />
);
