/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* Enum for the types of onboarding modules that can be offered to a provider in the home page. Each module represents a set of setup tasks to help the provider get started in Sigmund. The modules shown are tied to the provider\'s archetype column. */    /**
    * Enum for the types of onboarding modules that can be offered to a provider in the home page. Each module represents a set of setup tasks to help the provider get started in Sigmund. The modules shown are tied to the provider\'s archetype column.
    */
export enum ProviderModule {
    PROFILE = 'PROFILE',
    AVAILABILITY = 'AVAILABILITY',
    PAYMENT = 'PAYMENT',
    PORT_PATIENTS = 'PORT_PATIENTS'
}
