import axios from 'axios';
import download from 'downloadjs';

import { UserUploadApi } from '@headway/api/resources/UserUploadApi';

export async function downloadFile(
  attachment: {
    link: string;
    name?: string;
  },
  getSignedUrl: boolean = true
) {
  let downloadUrl;
  if (getSignedUrl) {
    const { signedUrl } = await UserUploadApi.generatePresignedGetUrl({
      url: attachment.link,
    });
    downloadUrl = signedUrl;
  } else {
    downloadUrl = attachment.link;
  }

  const res = await axios.get(downloadUrl, {
    transformRequest: (data, headers) => {
      delete headers['authorization'];
      return data;
    },
    responseType: 'blob',
  });
  const contentType = res.headers['content-type'];
  download(res.data, attachment.name, contentType);
}

export function getDownloadNameWithExtensionFromUrl(
  downloadName: string,
  url: string = ''
) {
  const split_url = url.split('/');
  const filename = split_url[split_url.length - 1].split('?')[0];
  const split_filename = filename.split('.');
  const file_extension =
    split_filename.length > 1
      ? `.${split_filename[split_filename.length - 1]}`
      : '';

  return `${downloadName}${file_extension}`;
}

export function getFileNameFromS3Key(s3Key: string) {
  // S3 keys are in the form of {folder}/{provider_id}/{uuid}-{file-name}
  // This parses it down to the file name
  return s3Key
    .split('/')
    [s3Key.split('/').length - 1].split('-')
    .slice(5)
    .join('-');
}

export async function downloadFileWithCustomName(
  fileUrl: string = '',
  customName: string
) {
  await downloadFile({
    link: fileUrl,
    name: getDownloadNameWithExtensionFromUrl(customName, fileUrl),
  });
}
