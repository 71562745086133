import _ from 'lodash';

import { ComponentNames, ComponentTypes } from '../renderer/components/index';
import { FormFile, FormState } from '../schema/schema.types';
import { Component, Data, Form, Section } from '../schema/schema.types';

export function isElementASection(
  element: Section | Component
): element is Section {
  if ('header' in element) {
    return true;
  } else {
    return false;
  }
}

export const getComponentIdToComponentMap = (
  form: FormFile['form']
): { [key: string]: Component } => {
  return (form as (Section | Component)[]).reduce(
    (acc: any, sectionOrElement: Section | Component) => {
      if (isElementASection(sectionOrElement)) {
        (sectionOrElement.components as Component[]).forEach(
          (component) => (acc[component.id] = component)
        );
      } else {
        acc[sectionOrElement.id] = sectionOrElement;
      }

      return acc;
    },
    {}
  );
};

export const getComponentIdToTypeMap = (
  form: Form
): { [key: string]: ComponentTypes } =>
  (form as (Section | Component)[]).reduce(
    (acc: any, sectionOrElement: Section | Component) => {
      if (isElementASection(sectionOrElement)) {
        (sectionOrElement.components as Component[]).forEach(
          (component) => (acc[component.id] = component.type)
        );
      } else {
        acc[sectionOrElement.id] = sectionOrElement.type;
      }

      return acc;
    },
    {}
  );

export const doesComponentExpectAnArrayResponse = (component: Component) =>
  [ComponentNames.checklist, ComponentNames.dropdownMulti].includes(
    component.type as ComponentTypes
  );

/**
 * Extracts needed component data from either the injected data passed to the form or
 * from the value set in the components state.
 *
 * @returns a map that of propName -> value
 */
export const getComponentData = <T extends object>(
  component: Component,
  injectedData: Object | undefined
): T => {
  const requestedDataMap: { [key: string]: Data } = {};
  component.data?.forEach((data) => {
    requestedDataMap[data.propName] = data;
  });

  const dynamicData: { [key: string]: string | string[] | undefined } = {};

  for (let prop in requestedDataMap) {
    const data = requestedDataMap[prop];
    if (data.isMappedToInjectedData && data.value) {
      dynamicData[prop] = _.get(injectedData, data.value);
    } else {
      dynamicData[prop] = data.value;
    }
  }

  // Add default injected data in
  dynamicData['environment'] = _.get(injectedData, 'environment');

  return dynamicData as T;
};

/**
 *
 * Compares the declared form state to the injected data to see if
 * required form state properties are included in the injected data.
 *
 * @returns true/false  depending if it passes the check or not.
 */
export const doesDataHaveRequiredStateProperties = (
  state: FormState,
  data: Object
) => {
  const getRequiredPaths = (
    obj: { [key: string]: any },
    parentPath: string | undefined,
    paths: string[]
  ): string[] => {
    for (const key of Object.keys(obj)) {
      const newPath = parentPath ? parentPath + '.' + key : key;
      if (typeof obj[key] === 'object') {
        return getRequiredPaths(obj[key], newPath, paths);
      } else {
        if (obj[key]) {
          paths.push(newPath);
        }
      }
    }

    return paths;
  };

  const requiredPaths = getRequiredPaths(state, undefined, []);

  for (const path of requiredPaths) {
    if (!_.get(data, path)) {
      return false;
    }
  }

  return true;
};
