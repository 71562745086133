import React, { useState } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Modal } from '@headway/helix/Modal';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { useQueryClient } from '@headway/shared/react-query';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { useProvider } from 'hooks/useProvider';
import { useProviderHasSentAssessments } from 'hooks/useProviderHasSentAssessments';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';
import { isPatientInNoDataPrelimPricing } from 'utils/prelimPricing';
import { ManageAssessmentsModal } from 'views/Clients/Assessments/components/ManageAssessmentsModal';
import { AssessmentProductAnnouncementModal } from 'views/Patients/AddPatient/AssessmentProductAnnouncementModal';

import { NoDataPrelimPricingVerificationInProgressModal } from '../../Clients/NoDataPrelimPricingVerificationInProgressModal';
import { AddBillingInformationForm } from './AddBillingInformationForm';
import {
  AddPatientModalContextProvider,
  AddPatientModalPage,
} from './AddPatientModalContext';
import { InviteClientSetup } from './InviteClientSetup';
import { PatientAddressFormIntakeFlow } from './PatientAddressFormIntakeFlow';
import { PatientDemographicsFormIntakeFlow } from './PatientDemographicsFormIntakeFlow';
import { PatientInsuranceReviewIntakeFlow } from './PatientInsuranceReviewIntakeFlow';
import { PatientInsuranceVerifyMatch } from './PatientInsuranceVerifyMatch';
import { TelehealthLocationsFormIntakeFlow } from './TelehealthLocationsFormIntakeFlow';

export interface HandleUserAndProviderPatientArgs {
  user: UserRead;
  providerPatient: ProviderPatientRead;
  hasDisplayNameChecked: boolean;
  displayNameCheckedCount: number;
}

interface AddPatientModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialUser?: UserRead;
  onClientAdded: () => void;
  onComplete: (updatedUser: UserRead) => void;
}

export const AddPatientModal = ({
  isOpen,
  onClose,
  onClientAdded,
  onComplete,
}: AddPatientModalProps) => {
  const provider = useProvider();
  const queryClient = useQueryClient();
  const [providerPatient, setProviderPatient] = useState<ProviderPatientRead>();
  const [client, setClient] = useState<UserRead | undefined>(undefined);

  const [
    isVerificationInProgressModalOpen,
    setIsVerificationInProgressModalOpen,
  ] = React.useState<boolean>(false);

  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpooferWithoutAssessmentsPermission =
    isSpoofing &&
    !isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles);
  const { data: providerHasSentAssessments } = useProviderHasSentAssessments(
    { providerId: provider.id },
    {
      refetchOnWindowFocus: false,
      enabled: !isSpooferWithoutAssessmentsPermission,
    }
  );

  return (
    <div data-testid="add-client-modal">
      <AddPatientModalContextProvider isOpen={isOpen} onClose={onClose}>
        {({ currentStep, closeAddPatientModal, modalProgressProps }) => (
          <>
            <Modal
              data-testid="add-client-modal"
              variant="fullscreen"
              layout="contained"
              isOpen={isOpen}
              onDismiss={closeAddPatientModal}
              title="Add Client"
              progress={modalProgressProps}
            >
              {currentStep === AddPatientModalPage.BASIC_INFO ? (
                <PatientDemographicsFormIntakeFlow
                  setClient={setClient}
                  setProviderPatient={setProviderPatient}
                  onClientAdded={onClientAdded}
                />
              ) : !provider || !client || !providerPatient ? (
                <div className="mt-8 flex flex-col items-center gap-8">
                  <SectionHeader>Saving...</SectionHeader>
                  <div>
                    <LogoLoader />
                  </div>
                </div>
              ) : (
                <>
                  {currentStep === AddPatientModalPage.BILLING_INFORMATION && (
                    <AddBillingInformationForm
                      client={client}
                      providerPatient={providerPatient}
                      onBillingAdded={(client) => {
                        setClient(client);
                        if (
                          isPatientInNoDataPrelimPricing(
                            client.activeUserInsurance,
                            client,
                            provider.id
                          )
                        ) {
                          setIsVerificationInProgressModalOpen(true);
                        }
                      }}
                    />
                  )}
                  {currentStep ===
                    AddPatientModalPage.VERIFY_INSURANCE_MATCH && (
                    <PatientInsuranceVerifyMatch client={client} />
                  )}
                  {currentStep === AddPatientModalPage.EXISTING_INSURANCE && (
                    <PatientInsuranceReviewIntakeFlow client={client} />
                  )}
                  {currentStep === AddPatientModalPage.PATIENT_ADDRESS && (
                    <PatientAddressFormIntakeFlow
                      providerId={provider.id}
                      apiKey={
                        process.env.REACT_APP_GOOGLE_MAPS_API_ID as string
                      }
                      client={client}
                      onSuccess={(user) => {
                        queryClient.invalidateQueries([
                          'patient-address',
                          user.activePatientAddressId,
                        ]);
                        setClient(user);
                      }}
                    />
                  )}
                  {currentStep === AddPatientModalPage.TELEHEALTH_LOCATIONS && (
                    <TelehealthLocationsFormIntakeFlow
                      providerId={provider.id}
                      client={client}
                      apiKey={
                        process.env.REACT_APP_GOOGLE_MAPS_API_ID as string
                      }
                    />
                  )}
                  {currentStep === AddPatientModalPage.SEND_ACCOUNT_INVITE && (
                    <InviteClientSetup
                      client={client}
                      providerPatient={providerPatient}
                    />
                  )}
                  {currentStep ===
                    AddPatientModalPage.ASSESSMENTS_PRODUCT_ANNOUNCEMENT &&
                    !providerHasSentAssessments && (
                      <AssessmentProductAnnouncementModal
                        client={client}
                        onDismiss={() => {
                          onComplete(client);
                          closeAddPatientModal();
                        }}
                      />
                    )}
                  {currentStep === AddPatientModalPage.SEND_ASSESSMENTS && (
                    <ManageAssessmentsModal
                      isOpen
                      onDismiss={() => {
                        onComplete(client);
                        closeAddPatientModal();
                      }}
                      clientId={client.id}
                      isIntakeFlow={true}
                    />
                  )}
                </>
              )}
            </Modal>
          </>
        )}
      </AddPatientModalContextProvider>
      {client && (
        <NoDataPrelimPricingVerificationInProgressModal
          open={isVerificationInProgressModalOpen}
          onClose={() => {
            setIsVerificationInProgressModalOpen(false);
          }}
          client={client}
        />
      )}
    </div>
  );
};
