import React from 'react';

import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';

export const FieldArraySection: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
          marginBottom: theme.spacing.x4,
        }}
      >
        {children}
      </div>
    </>
  );
};

export const FieldArraySectionItemContainer: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  return (
    <>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
          marginTop: theme.spacing.x2,
        }}
      >
        {children}
      </div>
    </>
  );
};

export const AddButton: React.FC<
  React.PropsWithChildren<{
    buttonText: string;
    onPress: (obj: any) => void;
  }>
> = ({ buttonText, onPress }) => {
  return (
    <div
      css={{
        ...theme.stack.horizontal,
        justifyContent: 'center',
        paddingBottom: theme.spacing.x8,
        borderBottom: `1px solid ${theme.color.system.borderGray}`,
      }}
    >
      <Button variant="secondary" onPress={onPress}>
        {buttonText}
      </Button>
    </div>
  );
};
