import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '../../schema/schema.types';

const MemoizedCheckbox = React.memo(Checkbox);

export const AcknowledgeAndSign = ({ element, readOnly }: SchemaComponent) => {
  const { id, title, subTitle } = element;

  if (readOnly) return null;

  return (
    <FormControl component={MemoizedCheckbox} name={id}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.x1,
        }}
      >
        <BodyText>
          <strong>{title}</strong>
        </BodyText>
        <BodyText>{subTitle}</BodyText>
      </div>
    </FormControl>
  );
};
