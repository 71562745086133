import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Grid, ModalProps, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';

import { ProviderAttestationStatus } from '@headway/api/models/ProviderAttestationStatus';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { trackEvent } from '@headway/shared/utils/analytics';
import { AddressCard, Button, Modal } from '@headway/ui';
import {
  MarketConsumer,
  MarketProvider,
} from '@headway/ui/providers/MarketProvider';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';
import { theme } from '@headway/ui/theme';
import { Tooltip } from '@headway/ui/Tooltip';
import { notifySuccess } from '@headway/ui/utils/notify';

import EditForm from './EditForm';

type PDMModalProps = ModalProps & {
  AuthStore: any;
  UiStore: any;
  providerLastAttestedDate: string;
  numDaysSinceLastAttestedDate: number;
  hideDataManagementBanner: () => void;
};

enum ModalStates {
  Edit = 'Edit',
  Read = 'Read',
  Blocked = 'Blocked',
}

const copyForModalStates = {
  [ModalStates.Edit]: {
    heading: 'We need your help!',
    subHeading: null,
    description: `
      To help us keep your records up to date, please verify your information by confirming or editing any details below.
    `,
  },
  [ModalStates.Read]: {
    heading: 'We need your help!',
    subHeading: 'Please verify your information in order to access Sigmund',
    description: `
      Help us keep your records up to date by confirming or editing any details below.
    `,
  },
  [ModalStates.Blocked]: {
    heading: 'Your Headway profile is hidden',
    subHeading: null,
    description: `
      We’re missing confirmation of your information, which is required to keep your credentialing status up to date. 
      Without this, we are not able to display your profile to prospective clients or send you referrals.
    `,
  },
};

export const ProviderDataManagementModalImpl = (props: PDMModalProps) => {
  const {
    UiStore,
    AuthStore,
    providerLastAttestedDate,
    numDaysSinceLastAttestedDate,
    ...rest
  } = props;
  const { provider } = AuthStore;
  const { onClose, hideDataManagementBanner } = rest;
  const [isEditing, setIsEditing] = useState(false);
  const EDIT_BUTTON_COPY = 'Edit my information';
  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      trackEvent({
        name: 'Provider Attestation Step Viewed',
        properties: {
          lastAttestedDate: providerLastAttestedDate,
          numDaysSinceLastAttested: numDaysSinceLastAttestedDate,
          stepName: 'continued',
          ctaButtonCopy: EDIT_BUTTON_COPY,
        },
      });
    }
  };
  const modalState = useMemo(() => {
    if (isEditing) return ModalStates.Edit;
    if (provider.attestationStatus === ProviderAttestationStatus.HIDDEN)
      return ModalStates.Blocked;
    return ModalStates.Read;
  }, [isEditing, provider]);
  const handleClose = useCallback(
    (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (modalState === ModalStates.Blocked) return;
      if (onClose) return onClose(event, reason);
    },
    [modalState, onClose]
  );

  const copyObj = copyForModalStates[modalState];
  const handleConfirmInformation = useCallback(
    (event: {}) => {
      UiStore.enableLoading();
      return ProviderApi.updateProvider(provider.id, {
        lastAttestedOn: moment().utc().toISOString(),
      })
        .then(() => {
          onClose && onClose(event, 'backdropClick');
          hideDataManagementBanner();
        })
        .then(() => AuthStore.fetchMe())
        .catch((e) => {
          console.error(e);
          UiStore.showWarningSnackbar(
            'Error: Confirming your information failed.'
          );
        })
        .finally(() => {
          notifySuccess("Thanks! You've confirmed your information.");
          UiStore.disableLoading();
          trackEvent({
            name: 'Provider Attestation Completed',
            properties: {
              lastAttestedDate: providerLastAttestedDate,
              numDaysSinceLastAttested: numDaysSinceLastAttestedDate,
            },
          });
        });
    },
    [
      UiStore,
      provider,
      onClose,
      hideDataManagementBanner,
      AuthStore,
      providerLastAttestedDate,
      numDaysSinceLastAttestedDate,
    ]
  );

  const missingFields = useMemo(() => {
    return [
      { field: 'stateOfResidence', label: 'State of Residence' },
      { field: 'firstName', label: 'First Name' },
      { field: 'lastName', label: 'Last Name' },
    ]
      .filter((obj) => !provider[obj.field])
      .map((obj) => obj.label);
  }, [provider]);

  return (
    <Modal
      closeable={provider.daysSinceLastAttestedOn <= 83}
      title={copyObj.heading}
      {...rest}
      onClose={handleClose}
    >
      {copyObj?.subHeading && (
        <>
          <h5>{copyObj.subHeading}</h5>
          <br />
        </>
      )}
      <Typography>
        {copyObj.description}{' '}
        <a
          href="https://findheadway.zendesk.com/hc/en-us/articles/8153795667476"
          rel="noreferrer"
          target="_blank"
        >
          Learn more
        </a>
      </Typography>
      <br />
      {modalState === ModalStates.Blocked && (
        <>
          <Typography>
            <b>
              To turn your profile back on, please review and edit or confirm
              the following information:
            </b>
          </Typography>
          <hr />
        </>
      )}
      <ProviderAddressContext.Consumer>
        {({
          providerAddresses,
          createProviderAddress,
          updateProviderAddress,
        }) => {
          const activeProviderAddresses = providerAddresses?.filter(
            (ad) => ad.isActive
          );
          return (
            <MarketProvider>
              <MarketConsumer>
                {({ markets }) =>
                  !isEditing ? (
                    <Box>
                      <Grid container sx={{ paddingBottom: theme.space.base }}>
                        <Grid item xs={4}>
                          <Typography>
                            <b>Legal name:</b>
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <span>
                            {provider?.firstName}
                            {provider?.middleName && ` ${provider?.middleName}`}
                            {` ${provider?.lastName}`}
                            {provider?.suffix && ` ${provider?.suffix}`}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          paddingBottom: theme.space.base,
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography>
                            <b>Practice address(es):</b>
                          </Typography>
                        </Grid>
                        {!activeProviderAddresses.length && (
                          <Grid item xs>
                            <span
                              color="textSecondary"
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              Virtual visits only{' '}
                              <Tooltip
                                title={
                                  "You're only set up for virtual appointments. Add a practice location address to start seeing patients in person."
                                }
                              >
                                <HelpOutlineIcon
                                  sx={{
                                    stroke: theme.highContrastColors.black,
                                    strokeWidth: 0.5,
                                  }}
                                  css={{
                                    fontSize: '1rem',
                                    marginLeft: theme.space.xs2,
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </Grid>
                        )}
                      </Grid>
                      {!!activeProviderAddresses &&
                        activeProviderAddresses.map((address) => (
                          <Box>
                            <AddressCard
                              key={`${address?.id} ${address?.streetLine1}`}
                              hasActions={false}
                              address={address}
                            />
                          </Box>
                        ))}
                      <Grid container sx={{ paddingBottom: theme.space.base }}>
                        <Grid item xs={3}>
                          <Typography>
                            <b>Accepting new patients?: </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs>
                          <span>
                            {capitalize(
                              String(
                                !!provider?.providerLicenseState?.shownInSearch
                              )
                            )}
                          </span>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ paddingBottom: theme.space.base }}>
                        <Grid item xs={3}>
                          <Typography>
                            <b>State of residence: </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs>
                          <span>
                            {markets.find(
                              (market) =>
                                market.state === provider?.stateOfResidence
                            )?.displayName || null}
                          </span>
                        </Grid>
                      </Grid>

                      {missingFields.length > 0 && (
                        <>
                          <Typography align="center" color="error">
                            One of the following fields is missing:{' '}
                            {missingFields.join(', ')}
                          </Typography>
                          <br />
                        </>
                      )}

                      <Button
                        onClick={handleConfirmInformation}
                        disabled={missingFields.length > 0}
                        variant="contained"
                        size="large"
                        fullWidth
                      >
                        I attest that this information is correct
                      </Button>
                      <Box sx={{ padding: '2px' }} />
                      <Button
                        onClick={toggleEdit}
                        size="large"
                        fullWidth
                        sx={{ color: theme.color.black }}
                      >
                        {EDIT_BUTTON_COPY}
                      </Button>
                    </Box>
                  ) : (
                    <EditForm
                      AuthStore={AuthStore}
                      UiStore={UiStore}
                      providerAddresses={providerAddresses}
                      provider={provider}
                      createProviderAddress={createProviderAddress}
                      updateProviderAddress={updateProviderAddress}
                      markets={markets}
                      toggleEdit={toggleEdit}
                      providerLastAttestedDate={providerLastAttestedDate}
                      numDaysSinceLastAttestedDate={
                        numDaysSinceLastAttestedDate
                      }
                    />
                  )
                }
              </MarketConsumer>
            </MarketProvider>
          );
        }}
      </ProviderAddressContext.Consumer>
    </Modal>
  );
};

class ProviderDataManagement extends React.Component<PDMModalProps> {
  render() {
    return (
      <ProviderDataManagementModalImpl
        {...this.props}
        AuthStore={this.props.AuthStore}
        UiStore={this.props.UiStore}
      />
    );
  }
}

export default inject('AuthStore')(observer(ProviderDataManagement));
