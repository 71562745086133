import moment from 'moment';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { useProvider } from 'hooks/useProvider';

import { CalendarDayVisibileNotification } from './CalendarDayVisibileNotification';

type CalendarMonthDateHeaderProps = {
  date: Date;
};

export const CalendarMonthDateHeader: React.FC<
  React.PropsWithChildren<CalendarMonthDateHeaderProps>
> = ({ date }) => {
  const provider = useProvider();
  return (
    <div
      css={{
        position: 'relative',
        padding: theme.space.xs2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 'calc(100% + 5px)',
      }}
    >
      <div>{moment(date).format('D')}</div>
      <CalendarDayVisibileNotification date={date} provider={provider} />
    </div>
  );
};
