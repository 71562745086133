import { Formik } from 'formik';
import { FC } from 'react';

import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { Modal } from '@headway/helix/Modal';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { logException } from '@headway/shared/utils/sentry';
import { Form } from '@headway/ui/form';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { redirectToAuth0Logout, useAuth0Client } from 'utils/auth';

interface UpdateLoginEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: number;
  AuthStore: any;
}

export const UpdateLoginEmailModal: FC<UpdateLoginEmailModalProps> = ({
  isOpen,
  onClose,
  userId,
  AuthStore,
}) => {
  const auth0Client = useAuth0Client();

  const handleOnSubmit = async (values: any) => {
    try {
      const userUpdateResult = await UserApi.updateEmailInSigmund(userId, {
        changeEmail: values.email,
        password: values.password,
      });

      notifySuccess('Email updated successfully.');
      onClose();

      if (auth0Client) {
        redirectToAuth0Logout(auth0Client);
      } else {
        AuthStore.logout();
      }
    } catch (e: any) {
      if (e.response?.data.detail.includes('Incorrect password')) {
        notifyError('Invalid password.');
      } else {
        notifyError('Failed to update email.');
      }
      logException(e);
      onClose();
    }
  };

  return (
    <Modal
      variant="content"
      isOpen={isOpen}
      onDismiss={onClose}
      title="Update login email"
    >
      <div css={{ padding: theme.spacing.x6 }}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={handleOnSubmit}
          render={({ isSubmitting }) => (
            <Form>
              <div>
                Updating your login email will require you to verify your new
                email address and reset your two-factor authentication (2FA)
                settings. We'll send a verification link to your new email.
                Follow the link and login using your new email. We do this to
                keep your account more secure.
              </div>
              <div
                css={{
                  marginTop: theme.spacing.x3,
                }}
              >
                <FormControl
                  name="email"
                  component={TextField}
                  label="Email"
                  type={'email'}
                  placeholder="Enter your new email"
                  data-testid="email-input"
                />
              </div>
              <div
                css={{
                  marginTop: theme.spacing.x3,
                }}
              >
                <FormControl
                  name="password"
                  component={TextField}
                  label="Password"
                  type={'password'}
                  autoComplete="current-password"
                  data-testid="password-input"
                  helpText="We need your password to keep your account secure"
                />
              </div>

              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.spacing.x6,
                  gap: theme.spacing.x2,
                }}
              >
                <Button
                  variant="secondary"
                  disabled={isSubmitting}
                  onPress={onClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                  size="medium"
                >
                  Update and reset 2FA
                </Button>
              </div>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};
