import React, { ReactNode } from 'react';

import { theme } from './theme';

export interface BrandTextProps {
  children: ReactNode;
  variant?: keyof (typeof theme)['typography']['brand'];
}

const variantToClassName: Record<
  NonNullable<BrandTextProps['variant']>,
  string
> = {
  content: 'hlx-typography-brand-content',
  header: 'hlx-typography-brand-heading',
  section: 'hlx-typography-brand-section',
};

export const BrandText = ({ children, variant = 'header' }: BrandTextProps) => {
  return <span className={variantToClassName[variant]}>{children}</span>;
};
