/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface PaymentMethodReadinessIssuePreAuthChargeFailed {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: PaymentMethodReadinessIssuePreAuthChargeFailedType;

}


    export enum PaymentMethodReadinessIssuePreAuthChargeFailedType {
            PRE_AUTH_CHARGE_FAILED = 'PRE_AUTH_CHARGE_FAILED'
    }

