import { Input, InputProps } from '@mui/material';
import React, { useContext, useRef } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import { theme } from '../theme';
import { FieldControlContext } from './FieldControl';
import { useField } from './useField';

export type FieldInputProps = InputProps &
  Partial<InputMaskProps> & {
    maskChar?: string | null;
  };

const disabledStyle = {
  ' *:disabled': {
    color: theme.color.gray,
  },
};

export const FieldInput = ({
  autoComplete,
  mask,
  ref,
  ...props
}: FieldInputProps) => {
  const inputRef = useRef<HTMLFormElement>(null);
  const { name, id } = useContext(FieldControlContext);
  const [field, meta] = useField({
    name,
    onChange: props.onChange,
    onBlur: props.onBlur,
    ref: inputRef,
  });

  if (mask) {
    return (
      <InputMask mask={mask} {...props} {...field}>
        {/* ts types for react-input-mask don't include children? */}
        {/* @ts-expect-error */}
        {(inputProps: Partial<InputProps>) => (
          <Input
            id={id}
            disabled={props.disabled}
            sx={disabledStyle}
            data-testid={name}
            {...inputProps}
            autoComplete={autoComplete}
            ref={ref}
            inputRef={inputRef}
          />
        )}
      </InputMask>
    );
  }

  return (
    <Input
      id={id}
      sx={disabledStyle}
      data-testid={name}
      {...props}
      {...field}
      autoComplete={autoComplete}
      error={meta.error !== undefined && meta.touched}
      ref={ref}
      inputRef={inputRef}
    />
  );
};
