import React from 'react';

import { FormControl } from '@headway/helix/FormControl';
import { TextField } from '@headway/helix/TextField';

import { SchemaComponent } from '../../schema/schema.types';
import { ViewComponent } from './ViewComponent';

export const FormTextField = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, optionalityText } = element;

  if (readOnly) {
    return <ViewComponent title={title} id={id} />;
  }

  return (
    <FormControl
      component={TextField}
      label={title}
      name={id}
      key={id}
      optionalityText={
        isOptional ? (optionalityText ? optionalityText : 'Optional') : ''
      }
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={subTitle}
    />
  );
};
