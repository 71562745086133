import { Alert, FormHelperText, Skeleton } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { PatientAddressCreate } from '@headway/api/models/PatientAddressCreate';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button, ButtonProps } from '@headway/helix/Button';
import { IconLock } from '@headway/helix/icons/Lock';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import {
  abbreviationToStateEnum,
  statesToDisplayNames,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import useScript from '@headway/shared/utils/useScript';
import {
  FieldAutocomplete,
  FieldControl,
  FieldErrorText,
  FieldFormLabel,
  FieldInput,
  FieldInputLabel,
  FieldRadioGroup,
} from '@headway/ui/form';
import { LocationFilter } from '@headway/ui/LocationFilter';
import { Radio } from '@headway/ui/Radio';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { SafeFormikForm } from './form/SafeFormikForm';
import { theme } from './theme';

const ValidationSchema = {
  city: Yup.string().required('City is required.'),
  state: Yup.string().nullable().required('State is required.'),
  streetLine1: Yup.string().required('Street line 1 is required.'),
  streetLine2: Yup.string().nullable().default(null),
  zipCode: Yup.string().required('Zip code is required.'),
};

interface LocationFilterAddress {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface PatientAddressFormImplProps {
  onSuccess?: (address: PatientAddressRead, user: UserRead, args?: any) => void;
  apiKey: string;
  user: UserRead;
  isActiveAddress?: boolean;
  showAddressType?: boolean;
  requireAddressType?: boolean;
  isTelehealth?: boolean;
  onCancel?: () => void;
  variant?: ButtonProps['variant'];
}

export const PatientAddressForm = ({
  onSuccess,
  apiKey,
  user,
  onCancel,
  showAddressType,
  requireAddressType,
  isTelehealth,
  isActiveAddress = true,
  variant = 'primary',
}: PatientAddressFormImplProps) => {
  const AddressSchema = Yup.object().shape({
    ...ValidationSchema,
    ...(isTelehealth
      ? {
          telehealth: Yup.bool().nullable().required('Choose an option.'),
        }
      : {}),
    ...(showAddressType && requireAddressType
      ? {
          addressType: Yup.string()
            .nullable()
            .required('Description is required.'),
        }
      : {}),
  });

  const gmapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3&libraries=places`
  );

  const createPatientAddressMutation = useMutation(
    (variables: PatientAddressCreate) => {
      return PatientAddressApi.createPatientAddress(variables);
    }
  );

  const [selectedAddress, setSelectedAddress] =
    React.useState<LocationFilterAddress>();

  const queryClient = useQueryClient();

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={AddressSchema}
        initialValues={{
          city: selectedAddress?.city ?? '',
          state: selectedAddress?.state ?? null,
          streetLine1: selectedAddress?.streetLine1 ?? '',
          streetLine2: selectedAddress?.streetLine2 ?? '',
          zipCode: selectedAddress?.zipCode ?? '',
          addressType: isActiveAddress ? PatientAddressType.HOME : null,
          telehealth: null,
        }}
        onSubmit={async (values) => {
          try {
            const address = await createPatientAddressMutation.mutateAsync({
              patientUserId: user.id,
              addressType: values.addressType
                ? PatientAddressType[values.addressType as PatientAddressType]
                : undefined,
              city: values.city,
              state: values.state as UnitedStates,
              streetLine1: values.streetLine1,
              streetLine2: values.streetLine2,
              zipCode: values.zipCode,
            });

            const args = isTelehealth
              ? {
                  telehealth: values.telehealth === 'true',
                }
              : undefined;

            if (isActiveAddress) {
              const updatedUser = await UserApi.updateUser(user.id, {
                activePatientAddressId: address.id,
              });
              onSuccess && onSuccess(address, updatedUser, args);
            } else {
              onSuccess && onSuccess(address, user, args);
            }

            await queryClient.invalidateQueries(['claim-readiness']);
          } catch (e) {
            logException(e);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <SafeFormikForm css={{ marginBottom: 0 }}>
              <fieldset
                css={{
                  border: 'none',
                  marginTop: theme.space.base,
                  padding: 0,
                }}
                disabled={isSubmitting}
              >
                {gmapsScriptStatus === 'ready' ? (
                  <div css={{ marginBottom: theme.space.sm }}>
                    <LocationFilter
                      autoFocus={true}
                      handleLocationSelect={(
                        address: LocationFilterAddress
                      ) => {
                        setSelectedAddress({
                          ...address,
                          state: abbreviationToStateEnum[address.state],
                        });
                      }}
                      google={window.google}
                      placeType="address"
                    />
                    {isMSCEnabled ? (
                      <FormHelperText>
                        If {formatPatientName(user, { firstNameOnly: true })} is
                        a Telehealth client, use the address where they join
                        virtual sessions from.
                      </FormHelperText>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : gmapsScriptStatus === 'loading' ? (
                  <Skeleton variant="rectangular" height={40} />
                ) : null}
                <legend css={{ margin: 0 }}>
                  <VisuallyHidden>Address</VisuallyHidden>
                </legend>
                <FieldControl name="streetLine1" fullWidth={true}>
                  <FieldInputLabel>Street Line 1</FieldInputLabel>
                  <FieldInput />
                  <FieldErrorText />
                </FieldControl>
                <FieldControl name="streetLine2" fullWidth={true}>
                  <FieldInputLabel>Street Line 2</FieldInputLabel>
                  <FieldInput />
                  <FieldErrorText />
                </FieldControl>
                <FieldControl name="city" fullWidth={true}>
                  <FieldInputLabel>City</FieldInputLabel>
                  <FieldInput />
                  <FieldErrorText />
                </FieldControl>
                <FieldControl name="state" fullWidth={true}>
                  <FieldAutocomplete
                    label="State"
                    options={
                      Object.keys(statesToDisplayNames) as UnitedStates[]
                    }
                    getOptionLabel={(key: UnitedStates) =>
                      statesToDisplayNames[key]
                    }
                  />
                  <FieldErrorText />
                </FieldControl>
                <FieldControl name="zipCode" fullWidth={true}>
                  <FieldInputLabel>Zip code</FieldInputLabel>
                  <FieldInput />
                  <FieldErrorText />
                </FieldControl>
                {showAddressType && (
                  <FieldControl name="addressType" fullWidth={true}>
                    <FieldAutocomplete
                      label="Describe this location"
                      options={
                        Object.keys(
                          addressTypeDisplayNames
                        ) as PatientAddressType[]
                      }
                      getOptionLabel={(key: PatientAddressType) =>
                        addressTypeDisplayNames[key]
                      }
                    />
                    <FieldErrorText />
                  </FieldControl>
                )}
                {isMSCEnabled && (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: theme.space.xs,
                    }}
                  >
                    <IconLock color={theme.color.gray} />
                    <FormHelperText>
                      We will not use this information otherwise, nor will we
                      share it with anyone besides the insurance and provider.
                    </FormHelperText>
                  </div>
                )}
              </fieldset>
              {isTelehealth && (
                <fieldset
                  css={{ border: 'none', padding: 0 }}
                  disabled={isSubmitting}
                >
                  <FieldControl name="telehealth">
                    <FieldRadioGroup>
                      <FieldFormLabel
                        css={{
                          color: theme.color.black,
                          fontWeight: theme.fontWeight.base,
                          marginTop: theme.space.base,
                        }}
                      >
                        Is the above address where you plan to join video
                        telehealth sessions?
                      </FieldFormLabel>
                      <Radio
                        value={true}
                        label={
                          'Yes, I plan to join video sessions from the address above'
                        }
                      />
                      <Radio
                        value={false}
                        label={'No, I plan to join from another location'}
                      />
                    </FieldRadioGroup>
                    <FieldErrorText />
                  </FieldControl>
                </fieldset>
              )}
              {createPatientAddressMutation.status === 'error' && (
                <Alert severity="error">
                  We couldn't save this address. Please try again.
                </Alert>
              )}
              <div
                css={{
                  paddingTop: theme.space.base,
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                  gap: theme.space.base,
                }}
              >
                <Button disabled={isSubmitting} variant={variant} type="submit">
                  {isSubmitting ? 'Saving' : 'Save'}
                </Button>
                {onCancel && (
                  <Button variant="secondary" onPress={onCancel}>
                    Cancel
                  </Button>
                )}
              </div>
            </SafeFormikForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
