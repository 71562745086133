import { Formik } from 'formik';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as Yup from 'yup';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageHeader } from '@headway/helix/PageHeader';
import { TextField } from '@headway/helix/TextField';
import { getApiErrorMessage } from '@headway/shared/utils/api';

import { useTailwindGlobals } from '../../utils/css';

interface FormValues {
  email: string;
}

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Email is required.'),
});

const ForgotPassword = () => {
  useTailwindGlobals();
  const initialValues: FormValues = {
    email: '',
  };

  const [message, setMessage] = useState<
    { text: string; status: 'error' | 'success' } | undefined
  >();
  const clearMessage = () => setMessage(undefined);

  const onSubmit = async (values: FormValues) => {
    clearMessage();
    try {
      await AuthApi.sendPasswordResetEmail({ email: values.email });
      setMessage({
        text: 'If the email you provided is associated with a Headway Sigmund account, we have sent you an email with instructions to reset your password.',
        status: 'success',
      });
    } catch (error: AnyTS4TryCatchUnknownError) {
      setMessage({ text: getApiErrorMessage(error), status: 'error' });
    }
  };

  return (
    <div className="mx-auto w-full px-4 tablet:w-[970px] desktop:w-[1020px]">
      <div className="rounded-sm mx-auto w-[512px] self-center justify-self-center bg-system-white px-6 py-5 shadow-medium">
        <h1>
          <PageHeader>Forgot Password</PageHeader>
        </h1>

        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {message ? (
                <GuidanceCard
                  variant={message.status === 'error' ? 'error' : 'positive'}
                >
                  <BodyText>{message.text}</BodyText>
                </GuidanceCard>
              ) : null}
              <FormControl
                name="email"
                type="email"
                data-testid="forgotPasswordEmail"
                component={TextField}
                label="Email"
                disabled={isSubmitting}
              />
              <Button
                type="submit"
                size="large"
                variant="primary"
                data-testid="forgotPasswordSubmitButton"
                disabled={isSubmitting}
              >
                Request password reset
              </Button>
              <div>
                <LinkButton
                  component={RouterLink}
                  elementType="a"
                  variant="link"
                  to="/auth/auth/login"
                >
                  Log in instead
                </LinkButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
