import { useSearchParam } from 'react-use';

import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';

import { useInsuranceStatusList } from '../../../hooks/useInsuranceStatus';
import { usePatientAddresses } from '../../../hooks/usePatientAddresses';

export const useIsSelfPayEligibleEvenIfInNetwork = (patient: UserRead) => {
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const selfPayAppointmentIsTelehealth =
    useSearchParam('selfPayAppointmentIsTelehealth') !== null;
  const selfPayAppointmentStateParam = useSearchParam(
    'selfPayAppointmentState'
  );
  const selfPayAppointmentState =
    selfPayAppointmentStateParam &&
    Object.values<string>(UnitedStates).includes(selfPayAppointmentStateParam)
      ? (selfPayAppointmentStateParam as UnitedStates)
      : null;

  // don't query if msc flag is off because we're returning false no matter what
  // don't query if we have an appointment state because we're just gonna use that anyway
  const shouldCheckStatesFromPatientAddresses =
    isMSCEnabled && selfPayAppointmentIsTelehealth && !selfPayAppointmentState;
  const { data: patientAddresses } = usePatientAddresses(
    {
      patientId: patient?.id,
    },
    {
      enabled: shouldCheckStatesFromPatientAddresses,
    }
  );
  const patientAddressStates =
    shouldCheckStatesFromPatientAddresses && patientAddresses
      ? patientAddresses?.map((patientAddress) => patientAddress.state)
      : [];

  const states = selfPayAppointmentState
    ? [selfPayAppointmentState]
    : patientAddressStates;
  const { insuranceStatuses } = useInsuranceStatusList(
    states.map((state) => ({
      isTelehealthAppointment: selfPayAppointmentIsTelehealth,
      appointmentState: state,
    })),
    patient,
    patient?.activeUserInsurance
  );
  const selfPayStatesNotInNetwork =
    insuranceStatuses.length > 0 &&
    insuranceStatuses.every(
      (status) => status !== PatientInsuranceOrEAPStatus.IN_NETWORK
    );

  return isMSCEnabled && selfPayStatesNotInNetwork;
};
