import { ErrorTwoTone } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import React from 'react';

import { theme } from '@headway/ui/theme';

interface PatientBillingUnconfirmedWarningProps {
  error: any;
  unconfirmedCount: number;
}

export const PatientBillingUnconfirmedWarning: React.FC<
  React.PropsWithChildren<PatientBillingUnconfirmedWarningProps>
> = ({ error, unconfirmedCount }) => {
  return (
    <Alert
      color="warning"
      icon={<ErrorTwoTone />}
      css={{ marginBottom: theme.space.base }}
    >
      <div>
        {error ? (
          <>This client may have sessions pending your confirmation.</>
        ) : (
          <>
            This client has <b>{unconfirmedCount}</b> session
            {unconfirmedCount !== 1 && 's'} pending your confirmation.
          </>
        )}
        <br />
        These updates will apply when confirmed.
      </div>
    </Alert>
  );
};
