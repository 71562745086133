import React from 'react';

import { theme } from '@headway/ui/theme';

interface AddressList {
  title: React.ReactNode;
}

export const AddressList: React.FC<React.PropsWithChildren<AddressList>> = ({
  children,
}) => (
  <div>
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        [theme.media.small]: {
          // minWidth: '450px'
          marginTop: 0,
        },
      }}
    >
      {children}
    </div>
  </div>
);
