import moment from 'moment';
import React, { useState } from 'react';

import { Modal } from '@headway/helix/Modal';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { useIroncladAgreement } from 'hooks/useIroncladAgreement';
import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess, isGroupAdmin } from 'utils/access';

import { AcceptModalContent } from './components/AcceptModalContent/AcceptModalContent';
import { BlockedGroupPracticeProviderModalContent } from './components/BlockedGroupPracticeProviderModalContent';
import { DeclineModalContent } from './components/DeclineModalContent';
import { GroupPracticeAddingNewStateContent } from './GroupPracticeAddingNewStateContent';
import { GroupPracticeRegistrationContent } from './GroupPracticeRegistrationContent';
import {
  AMENDMENT_DATE_OF_NOTICE,
  NUMBER_OF_DAYS_TO_BLOCKED,
} from './helpers/constants';
import {
  calculateDaysRemaining,
  isGPAddingNewState,
  isGPPendingInitialContractAcceptance,
  isGroupPracticeProviderAfter30Days,
  useSendIroncladResponseMutation,
} from './helpers/utils';
import { IroncladAgreementStatus } from './types/IroncladAgreementStatus';

interface IroncladAmendmentsModalProps {
  isOpen: boolean;
  closeIroncladAmendmentsModal: () => {};
  closeIroncladAmendmentsBanner: () => {};
}

export const IroncladAmendmentsModal = ({
  isOpen,
  closeIroncladAmendmentsModal,
  closeIroncladAmendmentsBanner,
}: IroncladAmendmentsModalProps) => {
  const { user, provider } = useAuthStore();
  const [showDeclineContent, setShowDeclineContent] = useState<boolean>(false);
  const [isLoadingDeclinedState, setIsLoadingDeclinedState] =
    useState<boolean>(false);
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);

  const isMSCGroupPracticePendingInitialContractAcceptance =
    isMSCBetaEnabled && isGPPendingInitialContractAcceptance(provider);
  const hasSignedGroupPracticeAgreement =
    provider.groupPracticeId && !!provider.groupPractice.contractSignedOn;

  const { ironcladAgreementInfo } = useIroncladAgreementInfo(
    isMSCGroupPracticePendingInitialContractAcceptance
  );
  const { groupKey } = ironcladAgreementInfo || {};

  const {
    ironcladAgreement,
    isLoading: isLoadingIroncladAgreement,
    isError: isErrorIroncladAgreement,
  } = useIroncladAgreement();

  const {
    ironcladAgreementStatus,
    statesPendingAgreement,
    isLoading: isLoadingIroncladAgreementStatus,
    isError: isErrorIroncladAgreementStatus,
  } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates: hasSignedGroupPracticeAgreement,
  });

  const isMSCGroupPracticeAddingNewState =
    isMSCBetaEnabled && isGPAddingNewState(provider, statesPendingAgreement);

  const { mutateAsync, isLoading: isLoadingSendIroncladResponseMutation } =
    useSendIroncladResponseMutation(
      user,
      provider,
      isMSCGroupPracticePendingInitialContractAcceptance
    );

  const isLoading =
    isLoadingIroncladAgreement || isLoadingIroncladAgreementStatus;
  const isError = isErrorIroncladAgreement || isErrorIroncladAgreementStatus;

  const today = moment();

  const hasSigmundAccess =
    useFlag('ironcladAllowSigmundAccess') ||
    (!isMSCGroupPracticePendingInitialContractAcceptance &&
      today <
        moment(AMENDMENT_DATE_OF_NOTICE).add(
          NUMBER_OF_DAYS_TO_BLOCKED,
          'days'
        ));

  const onSubmit = async (ironcladAgreementStatus: IroncladAgreementStatus) => {
    if (groupKey) {
      await mutateAsync(
        {
          ironcladAgreementStatus,
        },

        {
          onSuccess: () => {
            if (ironcladAgreementStatus === IroncladAgreementStatus.AGREED) {
              closeIroncladAmendmentsModal();
              closeIroncladAmendmentsBanner();
            } else if (
              ironcladAgreementStatus === IroncladAgreementStatus.DISAGREED &&
              calculateDaysRemaining(NUMBER_OF_DAYS_TO_BLOCKED) === 0 // If declined after 30 day period is over, redirect back to modal
            ) {
              setIsLoadingDeclinedState(true);
              setShowDeclineContent(false);
            } else if (
              ironcladAgreementStatus === IroncladAgreementStatus.DISAGREED
            ) {
              closeIroncladAmendmentsModal();
            }
          },
          onError: (err) => {
            notifyError(
              'An error occurred while submitting your response. Please try again later. If the problem persists please contact hello@findheadway.com'
            );
            logException(err);
          },
        }
      );
    } else {
      notifyError(
        'An error occurred while submitting your response. Please try again later. If the problem persists please contact hello@findheadway.com'
      );
    }
  };

  if (
    isError ||
    (isLoading && !isLoadingSendIroncladResponseMutation) ||
    (ironcladAgreementStatus &&
      (ironcladAgreementStatus === IroncladAgreementStatus.AGREED ||
        ironcladAgreementStatus === IroncladAgreementStatus.NOT_FOUND))
  ) {
    return null;
  }

  const getModalTitle = () => {
    if (
      isMSCGroupPracticePendingInitialContractAcceptance ||
      isMSCGroupPracticeAddingNewState
    ) {
      return 'Review Headway agreement';
    } else if (showDeclineContent) {
      return 'Are you sure you want to decline the agreement?';
    } else if (
      ironcladAgreementStatus === IroncladAgreementStatus.DISAGREED ||
      calculateDaysRemaining(NUMBER_OF_DAYS_TO_BLOCKED) <= 0
    ) {
      return 'Additional updates to our agreement: account inactive'; // Temp - "Additional" will be removed in advance of next amendment
    } else if (calculateDaysRemaining(NUMBER_OF_DAYS_TO_BLOCKED) <= 10) {
      return `Additional updates to our agreement: ${calculateDaysRemaining(
        NUMBER_OF_DAYS_TO_BLOCKED
      )} days left to agree`; // Temp - "Additional" will be removed in advance of next amendment
    } else return 'Additional updates to our agreement'; // Temp - "Additional" will be removed in advance of next amendment
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={closeIroncladAmendmentsModal}
      isDismissable={hasSigmundAccess}
      title={getModalTitle()}
    >
      {isMSCGroupPracticeAddingNewState && isGroupAdmin(user) ? (
        <GroupPracticeAddingNewStateContent onSubmit={onSubmit} />
      ) : isGroupPracticeProviderAfter30Days(provider, user) ? (
        <BlockedGroupPracticeProviderModalContent />
      ) : showDeclineContent ? (
        <DeclineModalContent
          setShowDeclineContent={setShowDeclineContent}
          hasSigmundAccess={hasSigmundAccess}
          remainingDaysToAccept={calculateDaysRemaining(
            NUMBER_OF_DAYS_TO_BLOCKED
          )}
          onSubmit={onSubmit}
        />
      ) : isMSCGroupPracticePendingInitialContractAcceptance &&
        isGroupAdmin(user) ? (
        <GroupPracticeRegistrationContent onSubmit={onSubmit} />
      ) : (
        <AcceptModalContent
          isLoadingDeclinedState={isLoadingDeclinedState}
          setIsLoadingDeclinedState={setIsLoadingDeclinedState}
          ironcladAgreement={ironcladAgreement}
          isLoadingIroncladAgreement={isLoadingIroncladAgreement}
          hasSigmundAccess={hasSigmundAccess}
          setShowDeclineContent={setShowDeclineContent}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};
