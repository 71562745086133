import React from 'react';

import { toasts } from '@headway/helix/Toast';

const UiStoreContext = React.createContext();

export const useUiStore = () => {
  const store = React.useContext(UiStoreContext);

  return store;
};

export const withUiStore = (Comp) => {
  return React.forwardRef((props, ref) => {
    const store = useUiStore();
    return <Comp ref={ref} {...props} UiStore={store} />;
  });
};

export const UiStoreProvider = ({ children }) => {
  const [loadingState, setLoading] = React.useState('IDLE');

  const [ironcladAmendmentLastRenderedOn, setIroncladAmendmentLastRenderedOn] =
    React.useState(null);

  const store = {
    // LOADING
    isLoading: loadingState === 'LOADING',
    enableLoading() {
      setLoading('LOADING');
    },
    disableLoading() {
      setLoading('IDLE');
    },

    // SNACKBAR
    showWarningSnackbar(message) {
      toasts.add(message, {
        variant: 'negative',
      });
    },
    showSuccessSnackbar(message) {
      toasts.add(message, {
        variant: 'positive',
      });
    },

    // IRONCLAD AMENDMENTS MODAL
    ironcladAmendmentLastRenderedOn,
    setIroncladAmendmentLastRenderedOn,
  };

  return (
    <UiStoreContext.Provider value={store}>{children}</UiStoreContext.Provider>
  );
};
