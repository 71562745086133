import { Link as RouterLink } from 'react-router-dom';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link as HelixLink } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { isClientMedicare } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';

import { TreatmentPlanRequirementModalProps } from '../Modals/RequiredInfoTreatmentPlanModal';

export const useMedicareOrMedicaidTreatmentPlanRequirement = (
  clientId?: number
) => {
  const isMMTreatmentPlanRequirementEnabled = useFlag(
    'mmTreatmentPlanRequirement',
    false
  );
  const isMedicareOrMedicaid = useMedicareOrMedicaid(clientId);
  return isMMTreatmentPlanRequirementEnabled && isMedicareOrMedicaid;
};

export const MedicareMedicaidRequirementModalContent = ({
  hasCompletedIntakeTherapyNote,
  hasPriorActiveTreatmentPlan,
  hasOnlyExpiredActiveTreatmentPlans,
}: TreatmentPlanRequirementModalProps) => (
  <>
    <GuidanceCard variant="compliance">
      <strong>
        Medicare and Medicaid require you to create and sign a treatment plan on
        Headway
      </strong>
      <p>
        You’ll need to complete this plan before confirming your next session,
        or within 14 days of your intake session, whichever comes first.
      </p>
    </GuidanceCard>
    {hasOnlyExpiredActiveTreatmentPlans ? (
      <div css={{ marginTop: theme.spacing.x4 }}>
        Since you’ve previously completed a treatment plan for this client, you
        can prefill most of the last plan’s information to save time.
      </div>
    ) : !hasPriorActiveTreatmentPlan ? (
      !hasCompletedIntakeTherapyNote ? (
        <div css={{ marginTop: theme.spacing.x4 }}>
          Most treatment plans only take a few minutes to complete. You can also
          update with a new plan at any time in the future.
        </div>
      ) : (
        <div css={{ marginTop: theme.spacing.x4 }}>
          Since you already completed a full intake note, we can prefill some
          plan information to save you time.
        </div>
      )
    ) : null}
  </>
);

export const MedicareMedicaidRequirementClientBanner = ({
  clientId,
}: {
  clientId: number;
}) => (
  <GuidanceCard variant="compliance">
    <BodyText>
      Medicare and Medicaid require you to create and sign a treatment plan on
      Headway before confirming your first non-intake session (therapy session),
      or within 14 days of your intake session, whichever comes first.
    </BodyText>
    <LinkButton
      variant="link"
      component={RouterLink}
      elementType="a"
      to={`/clients/${clientId}/clinical?treatmentPlan=true`}
    >
      Create plan
    </LinkButton>
  </GuidanceCard>
);

export const MedicareMedicaidRequirementClientDetailAlert = ({
  clientId,
  isExpiring,
}: {
  clientId: number;
  isExpiring?: boolean;
}) => (
  <>
    <div>
      {isExpiring
        ? `Medicare and Medicaid requires you to create an updated
            treatment plan every 90 days. Updated treatment plans are
            required to confirm session details.`
        : `Medicare and Medicaid require you to create and sign a treatment plan
            within 14 days of your intake session, or before confirming your first
            non-intake session, whichever comes first.`}
    </div>
    <div className="mt-4">
      <HelixLink href={`clients/${clientId}/clinical?treatmentPlan=true`}>
        {isExpiring ? 'Update plan now' : 'Create plan now'}
      </HelixLink>
    </div>
  </>
);

export const MedicareMedicaidRequirementWarning = ({
  patient,
}: {
  patient?: UserRead;
}) => (
  <BodyText>
    Since {formatPatientName(patient, { firstNameOnly: true })} is a{' '}
    {isClientMedicare(patient) ? 'Medicare' : 'Medicaid'} client, you're
    required to create and sign a treatment plan on Headway before confirming
    this session.
  </BodyText>
);

export const MedicareMedicaidRequirementWarningBanner = ({
  patient,
  onOpenTreatmentPlanModal,
}: {
  patient?: UserRead;
  onOpenTreatmentPlanModal: () => void;
}) => (
  <>
    <span>
      Since {formatPatientName(patient, { firstNameOnly: true })} is a{' '}
      {isClientMedicare(patient) ? 'Medicare' : 'Medicaid'} client, you're
      required to create and sign a treatment plan on Headway before confirming
      this session.
    </span>
    <span className="ms-4">
      <Button variant="link" onPress={() => onOpenTreatmentPlanModal()}>
        Create plan
      </Button>
    </span>
  </>
);
