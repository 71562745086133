import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

import { useQuestionnaireContext } from '../QuestionnaireV2Context';

// TODO(Rev): Deprecate when WelcomeV2 step is fully rolled out
export function Introduction(props: { onPressStart: () => void }) {
  const { isRecredentialing } = useQuestionnaireContext();

  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: '16px',
      }}
    >
      <h1>
        <PageHeader>
          {isRecredentialing
            ? 'Congratulations on 3 years with Headway!'
            : 'Welcome to Headway!'}
        </PageHeader>
      </h1>
      <p>
        <BodyText>
          We’re so excited to work with you. Here are your next steps:
        </BodyText>
      </p>
      <section
        css={{
          ...theme.stack.vertical,
          gap: '16px',
        }}
      >
        <SectionHeader>1. Complete intake form</SectionHeader>
        <p>
          <BodyText>
            {!isRecredentialing ? (
              <span>
                From this page, we'll take you through our intake form. These
                answers give us the details we need to fill out and submit your
                applications to insurance companies.
              </span>
            ) : (
              <span>
                From this page, we'll take you through our recredentialing form.
                You'll be asked to verify details you've provided in the past,
                as well as update fields that are likely to have changed. We'll
                use your responses to fill out and submit your recredentialing
                applications to insurance companies.
              </span>
            )}
          </BodyText>
        </p>
        <p>
          <BodyText>
            To complete the form, you'll need a few things:
            <ul>
              <li>PDF copy and details of your professional license(s)</li>
              <li>PDF copy and details of your liability insurance</li>
              <li>
                If applicable, we may also need PDF copies and details of your:{' '}
                <ul>
                  <li>Prescriptive Authority Agreement</li>
                  <li>DEA Registration Certificate</li>
                  <li>CDS Registration Certificate</li>
                </ul>
              </li>
            </ul>
          </BodyText>
        </p>
        {!isRecredentialing && (
          <>
            <SectionHeader>
              2. Headway handles the insurance paperwork
            </SectionHeader>
            <p>
              <BodyText>
                With your intake answers, we'll get started with your insurance
                paperwork and handle anything administrative that comes up along
                the way so you're able to focus on your practice. Note that we
                won't be able to start the process until you've completed the
                form, so we appreciate you doing so as soon as feasible.
              </BodyText>
            </p>
            <SectionHeader>3. See clients</SectionHeader>
            <p>
              <BodyText>
                Once your applications are approved and you're credentialed,
                you'll be able to start seeing clients through Headway.
              </BodyText>
            </p>

            <SectionHeader>4. Get Paid</SectionHeader>
            <p>
              The final step is for our insurance partners to add you into their
              system so that claims can be processed and you can get paid. This
              can take up to eight weeks. Once completed, Headway will pay you
              for any appointments you’ve already held during this time, and on
              a bi-weekly basis moving forward.
            </p>
          </>
        )}
      </section>
      <section
        css={{
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          padding: '24px 0',
          ...theme.stack.vertical,
          gap: '16px',
        }}
      >
        <GuidanceCard variant="info" layout="vertical">
          <span>
            All information you share with us on our HIPAA-compliant platform
            will only be used to get you credentialed & onboarded.{' '}
            <Link
              href="https://findheadway.zendesk.com/hc/articles/360058922872"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about how we comply with HIPAA requirements.
            </Link>
          </span>
        </GuidanceCard>
        <p>
          <BodyText>
            Each provider applying or reapplying for credentialing or
            recredentialing with Headway has the following rights regarding the
            credentialing process:
            <ul>
              <li>To review information submitted</li>
              <li>To correct erroneous information</li>
              <li>
                To receive the status of their credentialing or recredentialing
                application, upon request
              </li>
            </ul>
          </BodyText>
        </p>
      </section>
      <div
        css={{
          textAlign: 'right',
          marginBottom: '32px',
        }}
      >
        <Button onPress={props.onPressStart}>Let's get started!</Button>
      </div>
    </div>
  );
}
