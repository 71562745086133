import { ModalProps } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ProviderQuestionnaireRecredSummary } from '@headway/api/models/ProviderQuestionnaireRecredSummary';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';

import { CHICAGO_MANUAL_DATE_FORMAT } from 'constants/date';
import { getDaysLeftCopy, getDaysUntilDate } from 'utils/date';

import {
  getDaysUntilTerminationCopy,
  isPastRecredDeadline,
} from './recred_utils';

type RecredentialingModalProps = ModalProps & {
  isOpen: boolean;
  recredSummary?: ProviderQuestionnaireRecredSummary;
  onClose: () => void;
};

export const RecredentialingModal: React.FC<RecredentialingModalProps> = ({
  isOpen,
  recredSummary,
  onClose,
}) => {
  const navigate = useNavigate();

  if (
    !recredSummary ||
    !recredSummary.recredDeadline ||
    !recredSummary.isEligibleForRecred ||
    recredSummary.isLatestRecredComplete
  ) {
    return <></>;
  }
  const getDeadline = () => {
    const deadline = moment(recredSummary.recredDeadline);
    const today = moment();

    if (deadline < today) {
      return today;
    }
    return deadline;
  };

  const recredEligibleOnDate = moment(
    recredSummary.recredEligibleOnDate
  ).startOf('day');
  const isFirstEligibleDate =
    recredEligibleOnDate.diff(moment().startOf('day'), 'days') === 0;

  const daysUntilDeadline = getDaysUntilDate(getDeadline());

  const getRecredText = () => {
    const formattedDeadline = getDeadline().format(CHICAGO_MANUAL_DATE_FORMAT);

    return (
      <>
        {!isFirstEligibleDate && (
          <div css={{ marginBottom: theme.spacing.x3 }}>
            <GuidanceCard variant="warning">
              You have {getDaysLeftCopy(daysUntilDeadline)} to submit your
              application. After {getDaysLeftCopy(daysUntilDeadline)}, your
              insurance credentials will expire and your account will be
              terminated.
            </GuidanceCard>
          </div>
        )}
        <p>
          Insurance companies periodically require renewed insurance
          credentials. <b>Submit your application by {formattedDeadline}</b> to
          maintain your insurance status with our insurance partners.
        </p>
        <p>
          Please update any information that has changed. Once you complete the
          form, we'll handle the rest of the administrative work.
        </p>
        {!isFirstEligibleDate && (
          <div css={{ marginTop: theme.spacing.x5 }}>
            <b>By not completing the credentialing form, you will:</b>
            <ul className="list-inside list-disc">
              <li>Be terminated from Headway</li>
              {!!recredSummary.terminationDate && (
                <li>
                  Have until{' '}
                  {moment(recredSummary.terminationDate).format(
                    CHICAGO_MANUAL_DATE_FORMAT
                  )}{' '}
                  to migrate any data you need off the platform
                </li>
              )}
            </ul>
          </div>
        )}
      </>
    );
  };

  const IncomingTerminationModal = () => {
    return (
      <Modal
        isOpen={isOpen}
        onDismiss={onClose}
        title={`Missed deadline: Your account will deactivate in ${getDaysUntilTerminationCopy(
          recredSummary
        )}`}
      >
        <ModalContent>
          <BodyText>
            <div css={{ marginBottom: theme.spacing.x3 }}>
              <GuidanceCard variant="warning">
                You did not submit an application to renew your credentials in
                time. We'll complete the deactivation process in{' '}
                {getDaysUntilTerminationCopy(recredSummary)}.
              </GuidanceCard>
            </div>

            <p>
              If you would like to continue working with Headway, we'd love to
              open a new account for you.
            </p>
          </BodyText>
        </ModalContent>
        <ModalFooter>
          <LinkButton
            variant="primary"
            href="https://headway.co/for-providers#get-started"
            target="_blank"
            rel="noreferrer"
          >
            Contact us to reopen your account
          </LinkButton>
        </ModalFooter>
      </Modal>
    );
  };

  const TimeToRecredModal = () => {
    const getTitle = () => {
      let title = "It's time to get recredentialed";

      if (isFirstEligibleDate) {
        return title;
      }
      if (daysUntilDeadline > 1) {
        return title + `: ${daysUntilDeadline} days left`;
      }

      return title + ': Last day';
    };

    return (
      <Modal isOpen={isOpen} onDismiss={onClose} title={getTitle()}>
        <ModalContent>
          <BodyText>{getRecredText()}</BodyText>
        </ModalContent>
        <ModalFooter>
          <Button variant="secondary" onPress={onClose}>
            Remind me later
          </Button>
          <Button
            variant="primary"
            onPress={() => {
              onClose();
              navigate('/credentials');
            }}
          >
            Apply now
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  return isPastRecredDeadline(recredSummary) ? (
    <IncomingTerminationModal />
  ) : (
    <TimeToRecredModal />
  );
};
