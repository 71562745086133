const DATA_PREFIX = 'data-hlx-';

export const DATA = {
  VALIDATION: `${DATA_PREFIX}validation`,
  READONLY: `${DATA_PREFIX}readonly`,
  HOVERED: `${DATA_PREFIX}hovered`,
  FOCUSED: `${DATA_PREFIX}focused`,
  DISABLED: `${DATA_PREFIX}disabled`,
  INPUT_TYPE: `${DATA_PREFIX}input-type`,
} as const;
