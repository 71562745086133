import { FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useContext } from 'react';

import { Button } from '@headway/helix/Button';
import { formatPatientName } from '@headway/shared/utils/patient';
import { MessageBubble, Modal } from '@headway/ui';
import { FieldControl, FieldInput, FieldInputLabel } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

import { Message } from 'api/MessagesApi';
import { useSelectedEvent } from 'hooks/useSelectedEvent';

import { PatientsContext } from '../../../providers/PatientsProvider';

type NotifyPatientConfirmationModalProps = {
  open: boolean;
  messageType: keyof typeof Message.Type | undefined;
  onClose: () => void;
  notifyForSelectedEvent: (
    content: string,
    messageType: keyof typeof Message.Type,
    additionalFields: any
  ) => Promise<void>;
  additionalMessageFields: any;
};

export const NotifyPatientConfirmationModal = ({
  open,
  messageType,
  onClose,
  notifyForSelectedEvent,
  additionalMessageFields,
}: NotifyPatientConfirmationModalProps) => {
  const { patientsById } = useContext(PatientsContext);
  const { event } = useSelectedEvent();

  const handleSubmit = async ({ content }: { content: string }) => {
    if (!messageType) {
      return;
    }

    await notifyForSelectedEvent(content, messageType, additionalMessageFields);
    onClose();
  };

  if (!event) return null;

  const patient =
    patientsById && event?.patientUserId
      ? patientsById[event.patientUserId]
      : undefined;
  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Would you like to notify ${patientName}?`}
    >
      <>
        <p
          css={{
            fontFamily: theme.fontFamily.postGrotesk,
            fontSize: theme.fontSize.base,
          }}
        >
          We can let {patientName} know that your session on{' '}
          <strong>{moment(event?.startDate).format('dddd, MMMM Do')}</strong>{' '}
          was{' '}
          <strong>
            {messageType === Message.Type.PROVIDER_CANCELLATION
              ? 'canceled'
              : messageType === Message.Type.PROVIDER_RESCHEDULE
              ? 'rescheduled'
              : 'scheduled'}
            .
          </strong>{' '}
          You can also add any additional information below.
        </p>
        <Formik
          initialValues={{
            content: '',
          }}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => {
            return (
              <SafeFormikForm>
                <MessageBubble>
                  <FieldControl
                    name="content"
                    fullWidth={true}
                    css={{ marginBottom: 0 }}
                  >
                    <FieldInputLabel>Add a note (optional)</FieldInputLabel>
                    <FieldInput
                      multiline={true}
                      rows={2}
                      maxRows={10}
                      data-testid="notifyPatientMessageField"
                    />
                  </FieldControl>
                </MessageBubble>
                <FormHelperText>will be emailed to the client.</FormHelperText>
                <div className="mt-8 flex justify-end gap-2">
                  <Button
                    variant="secondary"
                    onPress={onClose}
                    data-testid="notifyPatientCancelButton"
                  >
                    Don't notify
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    data-testid="notifyPatientSubmitButton"
                  >
                    Notify
                  </Button>
                </div>
              </SafeFormikForm>
            );
          }}
        </Formik>
      </>
    </Modal>
  );
};
