import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '@headway/ui';

type RefreshPageAlertProps = {
  open: boolean;
};

const RefreshPageAlert: FC<RefreshPageAlertProps> = ({ open }) => {
  const navigate = useNavigate();

  return (
    <Modal closeable={false} open={open} title="New features available">
      <p>
        The provider portal has been updated with new features. Please refresh
        the page to continue using the app.
      </p>
      <ButtonContainer>
        <Button color="primary" onClick={() => navigate(0)} variant="contained">
          Refresh
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

const ButtonContainer = styled.div`
  text-align: right;
`;

export { RefreshPageAlert };
