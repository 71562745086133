import upperFirst from 'lodash/upperFirst';

import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { InsuranceAuthorizationApi } from '@headway/api/resources/InsuranceAuthorizationApi';

export interface FormatPatientNameOptions {
  firstInitial?: boolean;
  lastInitial?: boolean;
  initialsWithoutPeriodOrSpace?: boolean;
  firstNameOnly?: boolean;
  appendPronouns?: boolean;
  appendLegalName?: boolean;
}

/**
 * Format patient name for display consistently.
 */
export const formatPatientName = (
  patient?: Pick<
    UserRead,
    | 'firstName'
    | 'lastName'
    | 'pronouns'
    | 'displayFirstName'
    | 'displayLastName'
  >,
  options?: FormatPatientNameOptions
): string => {
  if (!patient) {
    return '';
  }
  const firstName = patient.displayFirstName
    ? upperFirst(patient.displayFirstName?.trim())
    : upperFirst(patient.firstName?.trim());
  const formattedFirstName =
    firstName && options?.firstInitial
      ? `${firstName[0]}${options?.initialsWithoutPeriodOrSpace ? '' : '.'}`
      : firstName;

  const lastName = patient.displayLastName
    ? upperFirst(patient.displayLastName?.trim())
    : upperFirst(patient.lastName?.trim());
  let formattedLastName = '';
  if (lastName) {
    if (options?.lastInitial) {
      formattedLastName = `${lastName[0]}${
        options?.initialsWithoutPeriodOrSpace ? '' : '.'
      }`;
    } else if (!options?.firstNameOnly) {
      formattedLastName = lastName;
    }
  }

  const displayPronouns = options?.appendPronouns
    ? formatPatientPronouns(patient, { prependSpace: true })
    : '';

  const displayLegalName = options?.appendLegalName
    ? getLegalNameIfDifferent(patient)
    : '';

  return `${formattedFirstName}${
    formattedFirstName &&
    formattedLastName &&
    !options?.initialsWithoutPeriodOrSpace
      ? ' '
      : ''
  }${formattedLastName} ${displayLegalName}${displayPronouns}`.trim();
};

export const checkEapAuthorization = async (
  patient: UserRead,
  dateOfService: Date
) => {
  if (!patient.activeUserInsuranceId) {
    throw new Error('Patient must have insurance to have EAP authorizations');
  }
  const authorizations =
    await InsuranceAuthorizationApi.findInsuranceAuthorizations({
      user_insurance_id: patient.activeUserInsuranceId,
      date_of_service: dateOfService.toISOString(),
      has_unused_sessions: true,
    });
  return (
    authorizations && authorizations.some((auth) => auth.isEapAuthorization)
  );
};

export const getLegalNameIfDifferent = (
  patient?: Pick<
    UserRead,
    'firstName' | 'lastName' | 'displayFirstName' | 'displayLastName'
  >
): string => {
  if (!patient) {
    return '';
  }
  if (
    patient.firstName != patient.displayFirstName ||
    patient.lastName != patient.displayLastName
  ) {
    return `(${upperFirst(patient.firstName?.trim())} ${upperFirst(
      patient.lastName?.trim()
    )})`;
  }
  return '';
};

export const formatPatientPronouns = (
  patient?: Pick<UserRead, 'pronouns'>,
  options?: {
    prependSpace?: boolean;
  }
): string => {
  if (!patient) {
    return '';
  }
  if (patient.pronouns) {
    return `${options?.prependSpace ? ' ' : ''}(${patient.pronouns})`;
  }
  return '';
};

export const shouldDisplayAccountNotificationBadge = (
  appointmentReadiness: UserAppointmentReadiness
): boolean => {
  const hasBlockingIssue = !appointmentReadiness.isReady;
  const hasIncompleteAssessment =
    (appointmentReadiness.assessments || []).length > 0;
  return hasBlockingIssue || hasIncompleteAssessment;
};
