import { ComponentMap } from '../renderer/components';
import { FunctionMap } from '../renderer/functions';

export { ComponentMap };

export type ComponentMapDeclaration = Record<string, FormComponent>;
export interface FormComponent extends React.FC<SchemaComponent> {
  validate?: (component: Component) => string[];
}

export interface SchemaComponent {
  isOptional?: boolean;
  disabled?: boolean;
  readOnly?: boolean;

  element: Component;
  form?: Form;
  injectedData?: Object & { environment?: 'development' | 'production' };

  isInBuilder?: boolean;
}

export interface DefaultInjectedData {
  environment: FormBuilderEnvironment;
}
export interface FormComponentBlockDeclaration<T> {
  formComponent: T;
  toolboxName: string;
  defaultState?: any;
  data?: Data[];
  toolboxIcon?: string;
  hasSideEdit?: boolean;
  requiredDefaultStateChange?: {
    properties: string[];
    bounds: 'atLeastOne' | 'all';
  };
}

export type BlockDefinitions<T> = {
  [key: string]: FormComponentBlockDeclaration<T>;
};

export type FormBuilderEnvironment = 'development' | 'production';

export interface BuilderError {
  componentId: string;
  message: string;
}

export interface BuilderErrors {
  allErrors: BuilderError[];
  unknownSystemErrors: BuilderError[];
}

export interface OnSaveData {
  form: FormFile;
  injectedData?: object;
  isValid: boolean;
  errors: BuilderErrors;
}

export type FormState = { [key: string]: Object };

export interface FormInfo {
  id: number;
  name: string;
  version: number;
}

export interface FormFile {
  info?: FormInfo;
  state?: FormState;
  form: Form;
}

export interface FormValues {
  [id: string]: string | string[] | any;
}

/**
 * Info Component
 */
export interface DoAndDontData {
  do: string[];
  dont: string[];
}

export interface InfoListData {
  title: string;
  items: string[];
}

export enum InfoComponentTypes {
  doAndDont = 'doAndDont',
  infoList = 'infoList',
}

export type InfoComponentData = DoAndDontData | InfoListData;

export interface InfoComponent {
  type: InfoComponentTypes;
  data: InfoComponentData;
}

/**
 * Section extension types
 */

export interface CollapsibleData {
  defaultOpen?: boolean;
}

export interface DefaultOptionBehaviorData {
  buttonText: string;
}

/**
 * Core Form Types
 */

export type RequiredValidation = {
  type: 'required';
  params: string[];
};

export type MinTextValidation = {
  type: 'min';
  params: [number, string];
};

export type Validation = RequiredValidation | MinTextValidation;
export type Validations = Validation[];

export interface Condition {
  componentId: string;
  responseValue: string | string[];
}

export interface Style {
  numberOfColumns?: number;
  fontSize?: 'small' | 'regular' | 'large';
  fontWeight?: 'regular' | 'bold';
  fontColor?: 'black' | 'gray';
}

export type Data = {
  propDisplayName: string;
  propName: string;

  isMappedToInjectedData: boolean;
  isRequired: boolean;

  isUserGenerated?: boolean;
  options?: string | string[];
  optionType?: 'single' | 'multiple';

  value: true extends Data['isRequired']
    ? string | string[]
    : string | string[] | undefined;

  isNumber?: boolean;
  isList?: boolean;
};

export interface Component {
  id: string;
  type: keyof ComponentMapDeclaration;
  title?: string;
  subTitle?: string;
  textContent?: string;
  htmlContent?: string;
  description?: string;
  placeholder?: string;
  options?: any;
  metadata?: unknown;
  style?: Style;
  defaultContent?: string;
  defaultResponse?: string;
  shouldDisplay?: FunctionMap[];
  readonly?: boolean;
  validations?: Validations;
  conditions?: Condition[];
  data?: Data[];
  optionalityText?: string;
}

interface SectionStyle {
  spacing: 'regular' | 'small';
}

export interface Section {
  id: string;
  header?: string;
  components?: Component[];
  subHeader?: string;
  infoComponent?: InfoComponent;
  allowMultiple?: boolean;
  metadata?: unknown;
  collapsible?: CollapsibleData;
  defaultOptionBehavior?: DefaultOptionBehaviorData;
  shouldDisplay?: FunctionMap[];
  style?: SectionStyle;
  conditions?: Condition[];
}

export type Form = Section[] | Component[] | (Component & Section)[];

export type FormResponse = { [key: string]: string | string[] };
