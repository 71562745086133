import { Popover } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { ScheduleEventMenuLive } from './ScheduleEventMenuLive';
import { ScheduleEventMenuPreLive } from './ScheduleEventMenuPreLive';

export const ScheduleEventMenu = ({
  top,
  left,
  open,
  onClose,
  handleEventTypeClick,
  provider,
  selectedDateIsValid,
  patient,
  minEstimatedLiveDate,
  ...rest
}) => {
  if (!top || !left) {
    return null;
  }
  const providerEarliestActiveLiveOn = provider.earliestActiveLiveOn;

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{
        top,
        left,
      }}
      css={{
        '.MuiPaper-root': {
          padding: `${theme.space.xs} 0`,
        },
      }}
    >
      {selectedDateIsValid ? (
        <ScheduleEventMenuLive
          open={open}
          handleEventTypeClick={handleEventTypeClick}
          onClose={onClose}
        />
      ) : (
        <ScheduleEventMenuPreLive
          open={open}
          handleEventTypeClick={handleEventTypeClick}
          onClose={onClose}
          providerEarliestActiveLiveOn={providerEarliestActiveLiveOn}
          minEstimatedLiveDate={minEstimatedLiveDate}
        />
      )}
    </Popover>
  );
};
