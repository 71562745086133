import * as React from 'react';

export const Bold = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.896 15.584V4h4.687c.973 0 1.775.288 2.407.865.631.576.947 1.309.947 2.198 0 .555-.135 1.045-.406 1.469-.27.423-.67.753-1.198.99v.124c.64.195 1.132.528 1.48 1 .347.472.52 1.042.52 1.708 0 .959-.337 1.737-1.01 2.334-.674.597-1.538.896-2.594.896H5.896zm1.708-6.667h2.854c.528 0 .962-.156 1.302-.469.34-.312.51-.725.51-1.24 0-.513-.17-.933-.51-1.26-.34-.326-.774-.49-1.302-.49H7.604v3.459zm0 5.146h3c.625 0 1.115-.167 1.469-.5.354-.334.531-.785.531-1.354 0-.57-.177-1.021-.531-1.354-.354-.334-.837-.5-1.448-.5h-3.02v3.708z"
      fill="currentColor"
    />
  </svg>
);
