import { autorun } from 'mobx';
import { useEffect, useState } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';

import { useAuthStore } from 'stores/AuthStore';

/**
 * Convenience wrapper for useAuthStore that just returns the current provider. This hook will
 * also automatically rerender the component if the provider object in AuthStore changes.
 */
export function useProvider(): ProviderRead {
  const authStore = useAuthStore();
  const [internalProvider, setInternalProvider] = useState<ProviderRead>(
    authStore.provider
  );
  useEffect(
    () =>
      // autorun will run this function any time authStore.provider changes. It returns a disposal
      // function so useEffect will clean it up on unmount.
      autorun(() => {
        setInternalProvider(authStore.provider);
      }),
    // autorun handles dependencies for us
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return internalProvider;
}
