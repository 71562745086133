import { css } from '@emotion/react';
import { Loader } from '@googlemaps/js-api-loader';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import snakeCase from 'lodash/snakeCase';
import toUpper from 'lodash/toUpper';
import React, { useEffect, useRef, useState } from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ComboBox } from '@headway/helix/ComboBox';
import { validity } from '@headway/helix/FormControl';
import { Item } from '@headway/helix/Select';

import { VisuallyHidden } from './VisuallyHidden';

type ComboBoxProps = React.ComponentProps<typeof ComboBox>;

export type HandleLocationSelectProps = {
  state?: string;
  address?: string;
  streetLine1?: string;
  streetLine2?: string;
  city?: string;
  zipCode?: string;
  formattedAddress?: string;
  lat?: number;
  lng?: number;
};

interface HelixLocationFilterProps extends ComboBoxProps {
  GOOGLE_MAPS_API_KEY: string;
  // called when the input is cleared
  handleClear?: () => void;
  handleLocationSelect: (
    location: HandleLocationSelectProps,
    clearInput: () => void
  ) => void;
}

enum NameType {
  LONG_NAME = 'long_name',
  SHORT_NAME = 'short_name',
}

type AddressComponentProps = {
  long_name: NameType.LONG_NAME;
  short_name: NameType.SHORT_NAME;
  types: string[];
};

const componentForm: Record<string, NameType> = {
  street_number: NameType.SHORT_NAME,
  route: NameType.LONG_NAME,
  sublocality_level_1: NameType.LONG_NAME,
  sublocality: NameType.LONG_NAME,
  locality: NameType.LONG_NAME,
  neighborhood: NameType.LONG_NAME,
  administrative_area_level_1: NameType.LONG_NAME,
  postal_code: NameType.SHORT_NAME,
};

const parseAddressComponents = (addressComponents: AddressComponentProps[]) => {
  const keys = Object.keys(componentForm);

  let address: Record<string, string | undefined> = {};
  for (const component of addressComponents) {
    const key = keys.find((key) => component.types.includes(key));

    if (key) {
      const field = componentForm[key];
      address[key] = component[field];
    }
  }
  return address;
};

export const HelixLocationFilter = ({
  GOOGLE_MAPS_API_KEY,
  handleClear,
  handleLocationSelect,
  name,
  label,
  defaultValue,
  onInputChange,
  ...rest
}: HelixLocationFilterProps) => {
  const formik = useFormikContext<{
    [name: string]: string;
  }>();
  const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  const autocompleteService = useRef<google.maps.places.AutocompleteService>();
  const placeService = useRef<google.maps.places.PlacesService>();

  const [locationSearchResults, setLocationSearchResults] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const debouncedSearch = debounce((searchTerm) => {
    autocompleteService.current?.getPlacePredictions(
      {
        input: searchTerm,
        types: ['address'],
        componentRestrictions: { country: 'us' },
      },
      (results) => {
        setLocationSearchResults(results || []);
      }
    );
  }, 100);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  if (!autocompleteService.current) {
    loader.load().then((google) => {
      autocompleteService.current =
        new google.maps.places.AutocompleteService();
      placeService.current = new google.maps.places.PlacesService(
        document.getElementById('placeDiv') as HTMLDivElement
      );
      if (defaultValue) {
        autocompleteService.current?.getPlacePredictions(
          {
            input: defaultValue,
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
          (results) => {
            if (results?.length) {
              setSelectedKeys([results[0]?.place_id]);
            }
            setLocationSearchResults(results || []);
          }
        );
      }
    });
  }

  const clearInput = () => {
    setSelectedKeys([]);
    setLocationSearchResults([]);
  };

  return (
    <>
      <VisuallyHidden>
        <div id="placeDiv"></div>
      </VisuallyHidden>
      <ComboBox
        name={name}
        label={label}
        onInputChange={(value) => {
          if (onInputChange) {
            onInputChange(value);
          }
          return debouncedSearch(value);
        }}
        menuWidth="stretch"
        selectedKeys={selectedKeys}
        onSelectionChange={(keys) => {
          const keysAsArray = Array.from(keys);
          if (keysAsArray.length) {
            const placeId = keysAsArray[0];
            setSelectedKeys([placeId]);
            placeService.current?.getDetails({ placeId }, (result) => {
              const addressComponents = result?.address_components;
              const geometry = result?.geometry;
              const addressObj = parseAddressComponents(
                addressComponents as AddressComponentProps[]
              );

              const street_number = addressObj.street_number || '';
              const street_name = addressObj.route;
              const city =
                addressObj.locality ||
                addressObj.sublocality ||
                addressObj.sublocality_level_1 ||
                addressObj.neighborhood;
              const state = addressObj.administrative_area_level_1;
              const enumState = toUpper(snakeCase(state)) as UnitedStates;
              const zipCode = addressObj.postal_code;
              const formattedAddress = result?.formatted_address;

              handleLocationSelect(
                {
                  address: result?.formatted_address || '',
                  state: enumState,
                  streetLine1: `${street_number}${
                    street_number ? ' ' : ''
                  }${street_name}`,
                  city,
                  zipCode,
                  formattedAddress,
                  lat: geometry?.location?.lat(),
                  lng: geometry?.location?.lng(),
                },
                clearInput
              );
            });
          } else {
            // if we have a clear function, run it
            setSelectedKeys([]);
            setLocationSearchResults([]);
            if (handleClear) {
              handleClear();
            }
          }
        }}
        searchable
        validation={validity(name, formik)}
        {...rest}
      >
        {locationSearchResults.map((result) => {
          return (
            <Item key={result.place_id} textValue={result.description}>
              <span>
                {result.structured_formatting.main_text},{' '}
                {result.structured_formatting.secondary_text}
              </span>
            </Item>
          );
        })}
      </ComboBox>
    </>
  );
};
