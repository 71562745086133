import * as React from 'react';

export const MessagesOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.667 16.666V3.334h16.667v13.332H1.666zM10 10.521l-6.938-4.48v9.23h13.876v-9.23L10 10.521zm0-1.375l6.896-4.417H3.125L10 9.146zM3.062 6.041V4.73v1.313z"
      fill="currentColor"
    />
  </svg>
);
