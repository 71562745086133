import { grey } from '@mui/material/colors';
import {
  alpha,
  createTheme,
  PaletteColor,
  PaletteColorOptions,
  Theme,
} from '@mui/material/styles';

import { ThemeChoice } from './constants';
import { legacyTheme as sharedTheme } from './theme';

// Extend types to include custom theme colors.
declare module '@mui/material/styles' {
  interface Palette {
    danger: PaletteColor;
    gray: PaletteColor;
  }
  interface PaletteOptions {
    danger?: PaletteColorOptions;
    gray?: PaletteColorOptions;
    black?: PaletteColorOptions;
  }
}

// Allow selecting custom theme colors in components.
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    danger: true;
    gray: true;
    black: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    danger: true;
    gray: true;
  }
}

const baseTheme = createTheme({
  typography: {
    fontFamily: sharedTheme.fontFamily.postGrotesk,
    button: {
      fontFamily: sharedTheme.fontFamily.postGrotesk,
      textTransform: 'none',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
  },
  palette: {
    primary: {
      main: sharedTheme.defaultColors.primary,
      contrastText: sharedTheme.defaultColors.white,
    },
    secondary: {
      main: sharedTheme.defaultColors.white,
    },
    warning: {
      main: sharedTheme.defaultColors.warning,
    },
    danger: {
      main: sharedTheme.defaultColors.danger,
    },
    error: {
      main: sharedTheme.defaultColors.error,
    },
    success: {
      main: sharedTheme.defaultColors.success,
      contrastText: sharedTheme.defaultColors.white,
    },
    gray: {
      main: grey[500],
      dark: grey[600],
      contrastText: sharedTheme.defaultColors.white,
    },
    black: {
      main: sharedTheme.color.black,
      dark: sharedTheme.color.black,
      contrastText: sharedTheme.defaultColors.white,
    },
  },
});

// Adapt Material UI 4 'default' button themes into a 'gray' theme.
const standardTheme = createTheme(baseTheme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'gray' },
          style: {
            color: baseTheme.palette.text.primary,
            borderColor:
              baseTheme.palette.mode === 'light'
                ? 'rgba(0, 0, 0, 0.23)'
                : 'rgba(255, 255, 255, 0.23)',
            '&.Mui-disabled': {
              border: `1px solid ${baseTheme.palette.action.disabledBackground}`,
            },
            '&:hover': {
              borderColor:
                baseTheme.palette.mode === 'light'
                  ? 'rgba(0, 0, 0, 0.23)'
                  : 'rgba(255, 255, 255, 0.23)',
              backgroundColor: alpha(
                baseTheme.palette.text.primary,
                baseTheme.palette.action.hoverOpacity
              ),
              color: baseTheme.palette.text.primary,
            },
          },
        },
        {
          props: { color: 'gray', variant: 'text' },
          style: {
            color: baseTheme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha(
                baseTheme.palette.text.primary,
                baseTheme.palette.action.hoverOpacity
              ),
            },
          },
        },
      ],
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            '& .MuiChip-label': {
              color: baseTheme.palette.text.primary,
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          right: '9px',
        },
      },
    },
  },
});

const highContrastTheme = createTheme(standardTheme, {
  palette: {
    primary: {
      main: sharedTheme.highContrastColors.primary,
      dark: sharedTheme.highContrastColors.darkGray,
    },
    success: {
      main: sharedTheme.highContrastColors.success,
    },
  },
});

const themeEnumToMuiTheme: { [theme in ThemeChoice]: Theme } = {
  [ThemeChoice.STANDARD]: standardTheme,
  [ThemeChoice.HIGH_CONTRAST]: highContrastTheme,
};

/** Returns the MUI theme config associated with the given theme choice. */
export function getMuiTheme(theme: ThemeChoice): Theme {
  return themeEnumToMuiTheme[theme];
}
