import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import {
  MaterialDesignContent,
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
  SnackbarProvider,
  useSnackbar,
} from 'notistack';
import React from 'react';

import { IconCircleCheck } from '@headway/helix/icons/CircleCheck';
import { IconClose } from '@headway/helix/icons/Close';
import { IconError } from '@headway/helix/icons/Error';
import { theme } from '@headway/helix/theme';

interface InnerSnackbarRefUtilProps {
  setUseSnackbarRef: typeof setUseSnackbarRef;
}

const InnerSnackbarRefUtil: React.FC<
  React.PropsWithChildren<InnerSnackbarRefUtilProps>
> = ({ setUseSnackbarRef }) => {
  setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: ProviderContext;
const setUseSnackbarRef = (useSnackbarRefProp: ProviderContext) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarRefUtil: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return <InnerSnackbarRefUtil setUseSnackbarRef={setUseSnackbarRef} />;
};

class Toaster {
  success = (message: SnackbarMessage, options: OptionsObject = {}) => {
    return this.toast(message, {
      variant: 'success',
      ...options,
    });
  };

  warning = (message: SnackbarMessage, options: OptionsObject = {}) => {
    return this.toast(message, {
      variant: 'warning',
      ...options,
    });
  };

  info = (message: SnackbarMessage, options: OptionsObject = {}) => {
    return this.toast(message, {
      variant: 'info',
      ...options,
    });
  };

  error = (message: SnackbarMessage, options: OptionsObject = {}) => {
    return this.toast(message, {
      variant: 'error',
      ...options,
    });
  };

  toast = (message: SnackbarMessage, options: OptionsObject) => {
    return useSnackbarRef.enqueueSnackbar(message, options);
  };

  close = (key: SnackbarKey) => {
    useSnackbarRef.closeSnackbar(key);
  };
}

const toaster = new Toaster();

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    backgroundColor: theme.color.system.textBlack,
    color: theme.color.system.white,
    padding: `0 ${theme.spacing.x4}`,
    minWidth: 'auto',
    ...theme.typography.body.regular,
  },
}));

const ToastManager: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = ({ children, ...rest }) => {
  const snackbarProviderRef = React.createRef<ProviderContext>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    if (snackbarProviderRef.current) {
      snackbarProviderRef.current.closeSnackbar(key);
    }
  };

  return (
    <SnackbarProvider
      // ref is missing from the SnackbarProvider types, we are using it in a slightly hacky
      // way here to access the methods on the mounted SnackbarProvider component
      // @ts-ignore
      ref={snackbarProviderRef}
      iconVariant={{
        error: (
          <IconError
            style={{
              color: theme.color.system.gray,
              marginRight: theme.spacing.x2,
            }}
            aria-hidden
          />
        ),
        success: (
          <IconCircleCheck
            style={{
              color: theme.color.system.gray,
              marginRight: theme.spacing.x2,
            }}
            aria-hidden
          />
        ),
        info: null,
        warning: null,
      }}
      classes={{
        root: 'min-w-[auto]',
      }}
      Components={{
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      action={(key) => (
        <IconButton
          sx={{ padding: theme.spacing.x3 }}
          color="inherit"
          disableRipple
          onClick={onClickDismiss(key)}
          size="small"
        >
          <IconClose width={18} />
        </IconButton>
      )}
      {...rest}
    >
      <SnackbarRefUtil />
      {children}
    </SnackbarProvider>
  );
};

function ToastManagerWithGlobalCSS(
  props: React.ComponentProps<typeof ToastManager>
) {
  return (
    <>
      <Global
        styles={{
          '.notistack-Snackbar': { minWidth: 'auto !important' },
        }}
      />
      <ToastManager {...props}>{props.children}</ToastManager>
    </>
  );
}

export { toaster, ToastManagerWithGlobalCSS as ToastManager };
