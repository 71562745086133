import { css } from '@emotion/react';
import React from 'react';
import { useParams } from 'react-router-dom';

import { PatientFormRead } from '@headway/api/models/PatientFormRead';
import { Form } from '@headway/form-builder';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';

import { usePatientFormSchema } from 'hooks/usePatientFormSchema';

interface IntakeFormContentPreviewProps {
  form?: PatientFormRead;
}

export const IntakeFormContentPreview = ({
  form,
}: IntakeFormContentPreviewProps) => {
  const { patientFormSchemaId } = useParams<'patientFormSchemaId'>();
  const { data: patientFormSchema } = usePatientFormSchema({
    patientFormSchemaId: Number(patientFormSchemaId),
  });
  const formRef = React.useRef();

  return (
    <>
      <div
        css={{
          maxWidth: '800px',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '1.5em',
          paddingRight: '1.5em',
        }}
      >
        <div css={intakeForm.headerCss}>
          <PageHeader>{patientFormSchema?.name}</PageHeader>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 0,
            height: '100%',
            marginBottom: theme.spacing.x12,
          }}
        >
          {patientFormSchema?.attachmentLink && (
            <iframe
              title={patientFormSchema.attachmentLink}
              src={`${patientFormSchema.attachmentLink}#view=fitH`}
              css={{
                backgroundColor: 'white',
                height: 1000,
                marginTop: theme.spacing.x6,
                marginBottom: theme.spacing.x6,
                [theme.__futureMedia.below('tablet')]: {
                  height: 500,
                },
              }}
            />
          )}
          {patientFormSchema && (
            <Form.View
              ref={formRef}
              form={
                patientFormSchema.formSchemaJson as {
                  form: object;
                }
              }
              response={form?.jsonResponse ?? undefined}
              readOnly={form?.sentOn ? true : false}
            />
          )}
        </div>
      </div>
    </>
  );
};

const intakeForm = {
  headerCss: css({
    marginTop: theme.spacing.x6,
    marginBottom: theme.spacing.x6,
  }),
  disclaimerCss: css({
    marginTop: theme.spacing.x6,
    marginBottom: theme.spacing.x12,
    color: theme.color.system.textBlack,
  }),
};
