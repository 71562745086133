import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { CDSCertificate } from '@headway/api/models/CDSCertificate';
import { DEACertificate } from '@headway/api/models/DEACertificate';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  getStatesDisplayNames,
  getSupportedStates,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { FieldControl, FieldDropzone, FieldErrorText } from '@headway/ui/form';
import { FieldDatePicker } from '@headway/ui/form';

import {
  getStatesMissingDeaOrCds,
  needsAlternateCoverage,
  onDropFiles,
  YesNo,
} from '../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { transformDateToNullOrStartOfDay } from '../utils/transformDateToNullOrStartOfDay';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';
import { IdentificationHeader } from './components/Headers/IdentificationHeader';

export enum IDCertificationType {
  CDS = 'CDS',
  DEA = 'DEA',
}

export const idCertificationTypeToDisplayNames: {
  [key in IDCertificationType]: string;
} = {
  [IDCertificationType.CDS]: 'Controlled Dangerous Substances',
  [IDCertificationType.DEA]: 'Drug Enforcement Agency',
};

const getIndexListInState = (
  state: UnitedStates,
  certificates?: (DEACertificate | CDSCertificate)[]
) => {
  if (!certificates) {
    return [];
  }

  let certificateIndices: number[] = [];
  certificates.forEach((cert, index) => {
    if (cert.state === state) {
      certificateIndices.push(index);
    }
  });
  return certificateIndices;
};

export const getCertsInState = (
  state: UnitedStates,
  certificates?: (DEACertificate | CDSCertificate)[]
) => {
  if (!certificates) {
    return [];
  }

  return certificates.filter((cert) => cert.state === state);
};

const StateCertsForm = ({
  state,
  rawData,
}: {
  state: UnitedStates;
  rawData: ProviderQuestionnaireRawData;
}) => {
  const deaCerts = getCertsInState(state, rawData.deaCertificates);
  const cdsCerts = getCertsInState(state, rawData.cdsCertificates);

  if (deaCerts.length === 0 && cdsCerts.length === 0) {
    return <></>;
  }

  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: theme.spacing.x2,
      }}
    >
      <SectionHeader>{statesToDisplayNames[state]}</SectionHeader>
      <IDCertificationForm
        state={state}
        idCertType={IDCertificationType.DEA}
        rawData={rawData}
      />
      <IDCertificationForm
        state={state}
        idCertType={IDCertificationType.CDS}
        rawData={rawData}
      />
    </div>
  );
};
const IDCertificationForm = ({
  state,
  idCertType,
  rawData,
}: {
  state: UnitedStates;
  idCertType: IDCertificationType;
  rawData: ProviderQuestionnaireRawData;
}) => {
  const { provider } = useQuestionnaireContext();
  let certList: (DEACertificate | CDSCertificate)[];
  if (idCertType === IDCertificationType.DEA) {
    certList = rawData.deaCertificates || [];
  } else {
    certList = rawData.cdsCertificates || [];
  }

  const indexList = getIndexListInState(state, certList);
  return (
    <div>
      {indexList.map((index) => {
        const cert = certList[index];
        const formPrefix = `${idCertType.toLowerCase()}Certificates[${index}]`;
        return (
          <>
            {!cert.issueDate && (
              <FieldControl name={`${formPrefix}issueDate`} fullWidth={true}>
                <span>
                  <b>{idCertType} date issued</b>
                </span>
                <FieldDatePicker
                  inputFormat="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  variant="outlined"
                  size="small"
                  maxDate={moment()}
                />
                <FieldErrorText />
              </FieldControl>
            )}
            <FieldControl name={`${formPrefix}uploadUrl`} fullWidth={true}>
              <span>
                <b>
                  Please upload a copy of your {idCertType} Registration
                  Certificate for {statesToDisplayNames[state]}.
                </b>
              </span>
              <FieldDropzone
                accept="application/pdf,image/*"
                onDrop={(file: any) => onDropFiles(provider.id, file)}
                css={{ marginBottom: '0', marginTop: '0' }}
              />
              <FieldErrorText />
            </FieldControl>
          </>
        );
      })}
    </div>
  );
};

const Identification = ({
  initialValues,
  formikHelpers,
}: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const providerStates = getSupportedStates(provider);
  const statesMissingDeaOrCds = getStatesMissingDeaOrCds(
    providerStates,
    initialValues
  );

  return (
    <>
      <IdentificationHeader initialValues={initialValues} />
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x1,
            width: '60%',
          }}
        >
          <SectionHeader>General</SectionHeader>
        </div>
        <FormControl
          name="medicareOptOut"
          component={RadioGroup}
          label="Have you ever voluntarily opted out of Medicare?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values?.medicareOptOut === YesNo.YES && (
          <FormControl
            name="medicareOptOutExplanation"
            component={TextField}
            label="Please explain"
          />
        )}
        {providerStates.map((state) => (
          <StateCertsForm key={state} state={state} rawData={initialValues} />
        ))}
        {needsAlternateCoverage(initialValues, providerStates) && (
          <FieldControl
            name="alternateCoverageUrl"
            fullWidth
            css={{ marginBottom: '0' }}
          >
            <SectionHeader>Alternate Coverage</SectionHeader>
            <p>
              Looks like you don’t hold a DEA or CDS in{' '}
              {getStatesDisplayNames(statesMissingDeaOrCds)}. Please submit a
              written statement that includes:
            </p>
            <ul>
              <li>
                Statement that you do not prescribe controlled substances in
                these states
              </li>
              <li>
                Statement that patients in these states under your care do not
                require controlled substances
              </li>
              <li>
                Your process for handling instances when a patient in these
                states requires a controlled substance
              </li>
              <li>
                The name of a designated alternate prescriber in each state that
                can prescribe controlled substances on your behalf
              </li>
            </ul>
            <p>
              If we do not receive a satisfactory written statement, you will
              not be qualified to join Headway and we will not be able to
              proceed with your credentialing.
            </p>
            {/*TODO(justin): Allow for downloadable templates here*/}
            {/*<Button*/}
            {/*  variant="link"*/}
            {/*  onPress={async () => {*/}
            {/*    await downloadFile({*/}
            {/*      link: 'https://headway-redacted-malpractice.s3.amazonaws.com/cph-and-associates.jpg',*/}
            {/*      name: 'alternate-coverage-letter-template.png',*/}
            {/*    });*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Download template*/}
            {/*</Button>*/}
            <FieldDropzone
              accept="application/pdf,image/*"
              onDrop={(file: any) => onDropFiles(provider.id, file)}
              css={{ marginBottom: '0' }}
            />
            <FieldErrorText />
          </FieldControl>
        )}
      </div>
    </>
  );
};

const getCertificateValidationSchema = (
  type: string,
  providerStates: UnitedStates[]
) => {
  return Yup.object().shape({
    number: Yup.string().required(`${type} Registration number is required.`),
    state: Yup.string().required(`${type} Registration state is required.`),
    issueDate: Yup.date()
      .nullable()
      .transform(transformDateToNullOrStartOfDay)
      .when('state', (state, schema) =>
        providerStates.includes(state)
          ? schema
              .required(`${type} Registration issue date is required.`)
              .max(new Date(), 'Issue date cannot be in the future.')
          : schema
      ),
    expirationDate: Yup.date()
      .nullable()
      .transform(transformDateToNullOrStartOfDay)
      .required(`${type} Registration expiration date is required.`),
    uploadUrl: Yup.array()
      .nullable()
      .when('state', (state, schema) =>
        providerStates.includes(state)
          ? schema
              .of(
                Yup.object().shape({
                  link: Yup.string(),
                  name: Yup.string(),
                })
              )
              .required(
                `Please upload a copy of your ${type} Registration Certificate.`
              )
          : schema
      ),
  });
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Identification Numbers',
  description:
    'In this section we ask that you share details about your provider identification numbers.',
  recredDescription: 'Please verify the following fields:',
  Component: Identification,
  getFormMeta: ({ provider, providerQuestionnaire }) => {
    const providerStates = provider.activeProviderLicenseStates.map(
      (pls) => pls.state
    );
    const validationSchema = Yup.object().shape({
      medicaidProvider: Yup.string().required('This question is required.'),
      medicaidNumber: Yup.string().when(
        'medicaidProvider',
        (medicaidProvider, schema) =>
          medicaidProvider === YesNo.YES
            ? schema.required('Medicaid number is required.')
            : schema
      ),
      medicareProvider: Yup.string().required('This question is required.'),
      medicareOptOut: Yup.string().required('This question is required.'),
      medicareOptOutExplanation: Yup.string().when(
        'medicareOptOut',
        (medicareOptOut, schema) =>
          medicareOptOut === YesNo.YES
            ? schema.required('Medicare opt out explanation is required.')
            : schema
      ),
      medicareNumber: Yup.string().when(
        'medicareProvider',
        (medicareProvider, schema) =>
          medicareProvider === YesNo.YES
            ? schema.required('Medicare number is required.')
            : schema
      ),
      deaCertificates: Yup.array().of(
        getCertificateValidationSchema('DEA', providerStates)
      ),
      cdsCertificates: Yup.array().of(
        getCertificateValidationSchema('CDS', providerStates)
      ),
      alternateCoverageUrl: Yup.array()
        .of(
          Yup.object().shape({
            link: Yup.string(),
            name: Yup.string(),
          })
        )
        .when(
          [
            'deaCertificates',
            'cdsCertificates',
            'prescribesControlledSubstances',
          ],
          (
            deaCertificates,
            cdsCertificates,
            prescribesControlledSubstances,
            schema
          ) =>
            needsAlternateCoverage(
              {
                deaCertificates,
                cdsCertificates,
                prescribesControlledSubstances,
              },
              providerStates
            )
              ? schema.required(
                  'Please upload a copy of your Alternative Coverage Document.'
                )
              : schema
        ),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;
