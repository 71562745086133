import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useQuery } from '@headway/shared/react-query';

import { PATIENT_APPOINTMENT_READINESS_CUTOFF_DATE } from '../utils/cacheKeys';

export interface UsePatientAppointmentReadinessCutoffDate {
  cancellationDate?: string | undefined | null;
  isLoading: boolean;
}

export const usePatientAppointmentReadinessCutoffDate = (
  eventIdOrVirtualEventId: any
): UsePatientAppointmentReadinessCutoffDate => {
  const { data, isLoading } = useQuery(
    [PATIENT_APPOINTMENT_READINESS_CUTOFF_DATE, eventIdOrVirtualEventId],
    async () =>
      await ProviderEventApi.getPatientAppointmentReadinessCutoffDate(
        eventIdOrVirtualEventId
      )
  );

  return {
    cancellationDate: data?.cancellationDate,
    isLoading: isLoading,
  };
};
