import * as React from 'react';

export const HighlightShield = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_5527_72920)">
      <path d="M48 0H0V48H48V0Z" fill="#fff" />
      <path
        d="M25.278 22.3411L40.3113 31.1089C41.0743 31.5637 41.0743 32.3068 40.3113 32.7616L25.6359 41.2363C25.0767 41.691 24.1646 41.691 23.6055 41.2363L8.57223 32.4684C7.80926 32.0136 7.80926 31.2705 8.57223 30.8157L23.2476 22.3411C23.8068 21.8863 24.7189 21.8863 25.278 22.3411Z"
        fill="#E7FBF2"
      />
      <path
        d="M37.8365 8.6232C29.4703 3.79227 19.0865 3.79227 10.7203 8.6232L10.3501 8.84532V9.28954C10.3501 18.9514 15.5512 27.9469 23.9175 32.7779L24.2877 33L24.6579 32.7779C33.0241 27.9469 38.2252 18.9514 38.2252 9.28954V8.84532L37.855 8.6232H37.8365ZM24.2692 31.2231C16.7173 26.6883 11.9974 18.5072 11.8494 9.69674C19.5492 5.42109 28.989 5.42109 36.6889 9.69674C36.5408 18.5072 31.821 26.6883 24.2692 31.2231ZM24.2692 10.4371C24.6764 10.4371 25.028 10.7703 25.028 11.196V16.138H29.97C30.3772 16.138 30.7289 16.4712 30.7289 16.8969C30.7289 17.3226 30.3957 17.6558 29.97 17.6558H25.028V22.5978C25.028 23.005 24.6949 23.3566 24.2692 23.3566C23.8434 23.3566 23.5103 23.0235 23.5103 22.5978V17.6558H18.5682C18.161 17.6558 17.8094 17.3226 17.8094 16.8969C17.8094 16.4712 18.1425 16.138 18.5682 16.138H23.5103V11.196C23.5103 10.7703 23.8434 10.4371 24.2692 10.4371Z"
        fill="#128756"
      />
    </g>
    <defs>
      <clipPath id="clip0_5527_72920">
        <rect width="48" height="48" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
