import styled from '@emotion/styled';
import {
  Card as MuiCard,
  CardActions as MuiCardActions,
  CardContent as MuiCardContent,
  CardProps as MuiCardProps,
} from '@mui/material';
import React from 'react';

import { theme } from '../theme';

export const Cards = styled.div({
  display: 'grid',
  gridGap: theme.space.lg,
  gridTemplateColumns: `repeat(1, 1fr)`,
  width: '100%',
  [theme.media.small]: {
    gridTemplateColumns: `repeat(2, 1fr)`,
    width: '100%',
  },
});

export const Card: React.FC<React.PropsWithChildren<MuiCardProps>> = (
  props
) => (
  <MuiCard
    variant="outlined"
    css={{ padding: theme.space.base, textAlign: 'left' }}
    {...props}
  />
);

export const CardTitle = styled.h1({
  marginTop: 0,
  fontSize: theme.fontSize.xl2,
  marginBottom: theme.space.xl,
  fontFamily: theme.fontFamily.canelaText,
  // fontWeight: theme.fontWeight.bold,
});

export const CardContent = MuiCardContent;
export const CardActions = MuiCardActions;
