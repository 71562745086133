import moment from 'moment-timezone';
import { useCallback, useEffect, useRef } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { useInViewport } from '@headway/shared/hooks/useInViewport';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';

/**
 * Banner to temporarily show providers message to check expriring insurance
 * for PCI (Plan Coverage Inactive) denial prevention
 */
export const AutoPayPCIBanner = () => {
  const [autoPayPCIBannerViewCount, setAutoPayPCIBannerViewCount] =
    useLocalStorage('autoPayPCIBannerViewedCount');
  const guidanceContainerRef = useRef<HTMLDivElement>(null);
  const onIntersectionCallback = useCallback(
    (entry: IntersectionObserverEntry, observer: IntersectionObserver) => {
      if (entry.isIntersecting) {
        observer.unobserve(guidanceContainerRef.current!);
      }
    },
    [guidanceContainerRef]
  );
  // we want to only track the first time the banner is scrolled into view
  //   per page load and only when the banner is fully in view
  const [isIntersecting] = useInViewport({
    ref: guidanceContainerRef,
    options: {
      threshold: 1,
    },
    onIntersection: onIntersectionCallback,
  });
  useEffect(() => {
    const viewCount =
      autoPayPCIBannerViewCount === null
        ? 1
        : parseInt(autoPayPCIBannerViewCount) + 1;
    if (!isIntersecting) return;
    setAutoPayPCIBannerViewCount(viewCount.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting]);
  const providerHitViewThreshold =
    autoPayPCIBannerViewCount &&
    parseInt(autoPayPCIBannerViewCount) >= 3 &&
    !isIntersecting;
  const bannerIsStillRelevant = moment().isBefore(moment('2024-07-01'));
  return (
    <>
      {!providerHitViewThreshold && bannerIsStillRelevant && (
        <div ref={guidanceContainerRef}>
          <GuidanceCard variant="info">
            <BodyText>
              Please make sure your clients have active, up-to-date insurance.
              For any appointments held on or after June 1, your clients will be
              automatically charged the full cost for any sessions held under
              expired insurance.
            </BodyText>
            <LinkButton
              href={
                'https://help.headway.co/hc/en-us/articles/360039125771-Using-insurance-on-Headway#h_01HDRK2G7MMZGJ8R4KB71FQB01'
              }
              target="_blank"
              variant="link"
            >
              See details
            </LinkButton>
          </GuidanceCard>
        </div>
      )}
    </>
  );
};
