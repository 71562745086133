import moment from 'moment';
import React from 'react';

import { License } from '@headway/api/models/License';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { BodyText } from '@headway/helix/BodyText';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import {
  CaqhKeyValuePair,
  CaqhSection,
  CaqhSubsection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';
import { useQuestionnaireContext } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Context';
import { getUniqueLicenses } from 'views/IntakeQuestionnaireV2/steps/LicenseStep/LicenseStepHelper';

export const LicenseStepHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  const { provider } = useQuestionnaireContext();
  const states = getSupportedStates(provider);
  const uniqueLicenses = getUniqueLicenses(initialValues?.caqhLicenses);

  return (
    <CaqhSection>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x5,
        }}
      >
        {states.map((state, i) => {
          const stateLicenses = uniqueLicenses.filter(
            (license) => license.licenseState === state
          );
          return (
            <div
              css={{
                ...theme.stack.vertical,
                gap: theme.spacing.x2,
              }}
            >
              <BodyText>
                <b>{statesToDisplayNames[state]}</b>
              </BodyText>

              {stateLicenses.map((license: License) => (
                <div
                  key={license.licenseNumber}
                  css={{
                    ...theme.stack.vertical,
                    gap: theme.spacing.x2,
                  }}
                >
                  <SubBodyText>
                    <b>License number: {license.licenseNumber}</b>
                  </SubBodyText>
                  <CaqhSubsection>
                    <CaqhKeyValuePair
                      label="Effective date"
                      value={
                        license.licenseEffectiveDate
                          ? moment(license.licenseEffectiveDate).format(
                              'MM/DD/YYYY'
                            )
                          : 'None'
                      }
                    />
                    <CaqhKeyValuePair
                      label="Expiration date"
                      value={moment(license.licenseExpirationDate).format(
                        'MM/DD/YYYY'
                      )}
                    />
                  </CaqhSubsection>
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </CaqhSection>
  );
};
