import uniq from 'lodash/uniq';

import { Ethnicity } from '@headway/api/models/Ethnicity';

const otherEthnicityOption = 'Other Racial or Ethnic Background';

// ensure we are accounting for all ethnicities in the enum by which the user should be able to filter
export const ETHNICITY_TO_PROVIDER_ETHNICITY_FILTER: Record<Ethnicity, string> =
  {
    [Ethnicity.BLACK_OR_AFRICAN_DESCENT]: 'Black or African descent',
    [Ethnicity.CAUCASIAN]: 'Caucasian',
    [Ethnicity.EAST_ASIAN]: 'Asian',
    [Ethnicity.HISPANIC]: 'Hispanic',
    [Ethnicity.LATINX]: 'Latinx',
    [Ethnicity.NATIVE_AMERICAN]: 'Native American',
    [Ethnicity.PACIFIC_ISLANDER]: 'Pacific Islander',
    [Ethnicity.SOUTH_ASIAN]: 'South Asian',
    [Ethnicity.SOUTHEAST_ASIAN]: 'Asian',
    [Ethnicity.OTHER_RACIAL_OR_ETHNIC_BACKGROUND]: otherEthnicityOption,
  };

// this is an array of the unqiue ethnicity options that the user can filter by,
// sorted alphabetically, with the "Other" option at the end
export const SORTED_ETHNICITY_OPTIONS = uniq(
  Object.values(ETHNICITY_TO_PROVIDER_ETHNICITY_FILTER).filter(
    (ethnicity): ethnicity is string => {
      return ethnicity !== undefined && ethnicity !== otherEthnicityOption;
    }
  )
)
  .sort((a, b) => {
    return a.localeCompare(b, 'en');
  })
  .concat([otherEthnicityOption]);
