import { EstimatedPrice } from '@headway/api/models/EstimatedPrice';
import { UserApi } from '@headway/api/resources/UserApi';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@headway/shared/react-query';

export const getPatientGeneralPriceEstimateQueryKey = (
  userId: number,
  providerId?: number | undefined
): QueryKey => ['patient-general-price-estimate', userId, providerId];

export const usePatientGeneralPriceEstimate = (
  userId: number,
  options?: UseQueryOptions<EstimatedPrice | undefined>,
  providerId?: number | undefined
): UseQueryResult<EstimatedPrice | undefined> =>
  useQuery(
    getPatientGeneralPriceEstimateQueryKey(userId, providerId),
    () => UserApi.getGeneralPriceEstimate(userId, { provider_id: providerId }),
    {
      staleTime: Infinity,
      ...options,
    }
  );
