import React, { useState } from 'react';

import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';

/**
 * Context for determining whether an onboarding completion card should be shown on the home page.
 * This occurs if the provider completed their last onboarding module during this session. This must
 * be provided as a global context so that we can detech this completion regardless of where it
 * occurs in the application.
 *
 * The first element signifies whether the completion card should be shown. The second is a function
 * which can be called to dismiss the completion card.
 */
export const OnboardingCompletionCardContext = React.createContext<
  [boolean, () => void]
>([false, () => {}]);

export const OnboardingCompletionCardProvider: React.FC<
  React.PropsWithChildren<{}>
> = ({ children }) => {
  const { data } = useOnboardingModuleStatuses();
  const [initialCompletionStatus, setInitialCompletionStatus] = useState<
    boolean | undefined
  >(undefined);
  const [cardDismissed, setCardDismissed] = useState<boolean>(false);

  const currentCompletionStatus =
    data != null &&
    data.length > 0 &&
    data.every(({ isComplete }) => isComplete);
  if (data != null && initialCompletionStatus === undefined) {
    setInitialCompletionStatus(currentCompletionStatus);
  }

  // Show the completion card if the completion status flipped from false to true during this session.
  const showCard =
    !cardDismissed &&
    initialCompletionStatus === false &&
    currentCompletionStatus;
  return (
    <OnboardingCompletionCardContext.Provider
      value={[showCard, () => setCardDismissed(true)]}
    >
      {children}
    </OnboardingCompletionCardContext.Provider>
  );
};
