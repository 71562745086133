import React from 'react';

export function IconCircleCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path fill="currentColor" d="M10 18a8 8 0 100-16 8 8 0 000 16z"></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.4 11.33l5.3-5.29 1.31 1.31-6.6 6.6-3.4-3.4 1.3-1.3 2.1 2.08z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
