import isEqual from 'lodash/isEqual';
import { createContext, ReactNode, useState } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderTreatmentPlanAttachmentRead } from '@headway/api/models/ProviderTreatmentPlanAttachmentRead';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { useMedicareOrMedicaid } from 'hooks/useMedicareOrMedicaid';
import { useProviderTreatmentPlanAttachmentsList } from 'hooks/useProviderTreatmentPlanAttachments';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';

import { useTemplate } from './Template';
import { PlanJson, Step } from './Template/types';
import { firstStepWithMissingFields } from './TreatmentPlanUtils';

export enum TREATMENT_PLAN_PAGES {
  START = 'START',
  TEMPLATE = 'TEMPLATE',
  SUMMARY = 'SUMMARY',
  TEXT = 'TEXT',
  UPLOAD = 'UPLOAD',
  PREVIEW = 'PREVIEW',
}

export type TreatmentPlanStore = {
  providerPatient?: ProviderPatientRead;
  steps?: Step[];
  currentStep: number;
  furthestStep: number;
  nextStep: () => void;
  previousStep: () => void;
  setCurrentStep: (step: number) => void;

  page: TREATMENT_PLAN_PAGES;
  setPage: (page: TREATMENT_PLAN_PAGES) => void;
  resetTreatmentPlanAndPage: () => void;

  treatmentPlan?: ProviderTreatmentPlanRead;
  treatmentPlans: ProviderTreatmentPlanRead[];
  isNewTreatmentPlan: boolean;
  setIsNewTreatmentPlan: (value: boolean) => void;
  setTreatmentPlan: (treatmentPlan?: ProviderTreatmentPlanRead) => void;
  editTreatmentPlan: (treatmentPlan: ProviderTreatmentPlanRead) => void;
  initializeTemplateForm: (initialValues: object) => void;
  prefillTreatmentPlan?: ProviderTreatmentPlanRead;
  setPrefillTreatmentPlan: (plan?: ProviderTreatmentPlanRead) => void;
  initialValues?: any;
  setInitialValues: (values?: any) => void;

  hasUnsavedChanges: (values?: any) => boolean;
};

export const TreatmentPlanContext = createContext<TreatmentPlanStore>({
  providerPatient: undefined,
  steps: [],
  currentStep: 0,
  furthestStep: 0,
  nextStep: () => {},
  previousStep: () => {},
  setCurrentStep: () => {},

  page: TREATMENT_PLAN_PAGES.START,
  setPage: () => {},
  resetTreatmentPlanAndPage: () => {},

  treatmentPlan: undefined,
  treatmentPlans: [],
  isNewTreatmentPlan: true,
  setIsNewTreatmentPlan: () => {},
  setTreatmentPlan: () => {},
  editTreatmentPlan: () => {},
  initializeTemplateForm: () => {},
  prefillTreatmentPlan: undefined,
  setPrefillTreatmentPlan: () => {},
  initialValues: undefined,
  setInitialValues: () => {},

  hasUnsavedChanges: () => false,
});

export interface TreatmentPlanContextProviderProps {
  children: ReactNode;
  providerPatient: ProviderPatientRead;
}

export const TreatmentPlanContextProvider = ({
  children,
  providerPatient,
}: TreatmentPlanContextProviderProps) => {
  const [treatmentPlan, setTreatmentPlan] =
    useState<ProviderTreatmentPlanRead>();
  const [steps, setSteps] = useState<Step[]>();
  const [currentStep, setCurrentStep] = useState(0);
  const [furthestStep, setFurthestStep] = useState(0);

  const [initialValues, setInitialValues] = useState<any>();
  const [isNewTreatmentPlan, setIsNewTreatmentPlan] = useState<boolean>(false);
  const [prefillTreatmentPlan, setPrefillTreatmentPlan] =
    useState<ProviderTreatmentPlanRead>();

  const { data: treatmentPlans = [] } = useProviderTreatmentPlans({
    providerPatientId: providerPatient!.id,
  });

  const isMMTreatmentPlanRequirementEnabled = useFlag(
    'mmTreatmentPlanRequirement',
    false
  );
  const isMedicareOrMedicaid = useMedicareOrMedicaid(providerPatient.userId);
  const template = useTemplate(providerPatient.userId);

  const [page, setPage] = useState<TREATMENT_PLAN_PAGES>(
    isMMTreatmentPlanRequirementEnabled && isMedicareOrMedicaid
      ? TREATMENT_PLAN_PAGES.TEMPLATE
      : TREATMENT_PLAN_PAGES.START
  );

  const attachmentsByTreatmentPlanId: {
    [key: number]: ProviderTreatmentPlanAttachmentRead[];
  } = {};
  const treatmentPlanAttachmentsQuery = useProviderTreatmentPlanAttachmentsList(
    treatmentPlans?.map((plan) => {
      return { queryKeyArgs: { treatmentPlanId: plan.id } };
    }) ?? []
  );
  treatmentPlanAttachmentsQuery.forEach((query) => {
    if (query && query.data && query.data.length > 0) {
      attachmentsByTreatmentPlanId[query.data[0].providerTreatmentPlanId] =
        query.data;
    }
  });

  const initializeTemplateForm = (initialValues: object) => {
    setInitialValues(initialValues);
    setSteps(template.template.steps);
    const furthestStep = firstStepWithMissingFields(
      initialValues,
      template.template.steps
    );
    setCurrentStep(furthestStep);
    setFurthestStep(furthestStep);
  };

  const editTreatmentPlan = (treatmentPlan: ProviderTreatmentPlanRead) => {
    setTreatmentPlan(treatmentPlan);
    if (treatmentPlan.planType === TreatmentPlanType.TEMPLATE) {
      initializeTemplateForm(
        (treatmentPlan.planJson as { template: object }).template
      );
      setPage(TREATMENT_PLAN_PAGES.TEMPLATE);
    } else if (treatmentPlan.planType === TreatmentPlanType.UPLOAD) {
      setInitialValues({
        treatmentPlanAttachments:
          attachmentsByTreatmentPlanId[treatmentPlan.id],
        treatmentPlanAttestation: false,
      });
      setPage(TREATMENT_PLAN_PAGES.UPLOAD);
    } else {
      setInitialValues({
        treatmentPlanText: (treatmentPlan.planJson as PlanJson).text,
        treatmentPlanAttestation: false,
      });
      setPage(TREATMENT_PLAN_PAGES.TEXT);
    }
  };

  const resetTreatmentPlanAndPage = () => {
    setPage(
      isMMTreatmentPlanRequirementEnabled && isMedicareOrMedicaid
        ? TREATMENT_PLAN_PAGES.TEMPLATE
        : TREATMENT_PLAN_PAGES.START
    );
    setCurrentStep(0);
    setFurthestStep(0);

    setIsNewTreatmentPlan(false);
    setTreatmentPlan(undefined);
    setInitialValues(undefined);
    setPrefillTreatmentPlan(undefined);
  };

  const nextStep = () => {
    if (steps && currentStep === steps.length - 1) {
      setPage(TREATMENT_PLAN_PAGES.SUMMARY);
      setFurthestStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep + 1);
      setFurthestStep((furthestStep) =>
        Math.max(furthestStep, currentStep + 1)
      );
    }
  };

  const previousStep = () => {
    if (currentStep === 0) {
      setPage(TREATMENT_PLAN_PAGES.START);
    } else {
      setCurrentStep(Math.max(currentStep - 1, 0));
    }
  };

  const hasUnsavedChanges = (values?: any) => {
    if (isNewTreatmentPlan) {
      if (treatmentPlan?.planType === TreatmentPlanType.TEMPLATE) {
        return !isEqual(initialValues, values);
      }
      return (
        !!values?.treatmentPlanText ||
        values?.treatmentPlanAttachments?.length > 0
      );
    }

    if (treatmentPlan) {
      if (treatmentPlan.planType === TreatmentPlanType.TEMPLATE) {
        return !isEqual(values, (treatmentPlan.planJson as PlanJson).template);
      } else if (treatmentPlan.planType === TreatmentPlanType.TEXT) {
        return (
          values?.treatmentPlanText !==
          (treatmentPlan.planJson as PlanJson).text
        );
      } else if (treatmentPlan.planType === TreatmentPlanType.UPLOAD) {
        return !isEqual(
          values?.treatmentPlanAttachments,
          attachmentsByTreatmentPlanId[treatmentPlan.id]
        );
      }
    }

    return false;
  };

  const store: TreatmentPlanStore = {
    providerPatient,
    steps,
    currentStep,
    setCurrentStep,
    furthestStep,
    nextStep,
    previousStep,
    page,
    setPage,
    resetTreatmentPlanAndPage,

    treatmentPlan,
    treatmentPlans,
    setTreatmentPlan,
    editTreatmentPlan,
    isNewTreatmentPlan,
    setIsNewTreatmentPlan,
    initializeTemplateForm,
    hasUnsavedChanges,
    prefillTreatmentPlan,
    setPrefillTreatmentPlan,
    initialValues,
    setInitialValues,
  };

  return (
    <TreatmentPlanContext.Provider value={store}>
      {children}
    </TreatmentPlanContext.Provider>
  );
};
