import { FocusableProvider } from '@react-aria/focus';
import { AriaButtonProps } from '@react-types/button';
import clsx from 'clsx';
import React, { useRef } from 'react';
import {
  mergeProps,
  OverlayContainer,
  useButton,
  useOverlayPosition,
  useTooltipTrigger,
} from 'react-aria';
import { useTooltipTriggerState } from 'react-stately';
import { Simplify } from 'type-fest';

import { Tray } from './collections/Tray';
import { Tooltip, TooltipContext } from './Tooltip';
import { InteractionProps, useInteraction } from './useInteraction';
import { useIsMobileDevice } from './utils';
import { PickAndConfigure } from './utils/PickAndConfigure';

interface GlossaryTermTriggerProps {
  children: [React.ReactElement, React.ReactElement];
  disabled?: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

const GlossaryTermTrigger = ({
  children,
  disabled: isDisabled,
  placement: tooltipPlacement,
}: GlossaryTermTriggerProps) => {
  const isMobile = useIsMobileDevice();
  const [trigger, content] = React.Children.toArray(children);
  const state = useTooltipTriggerState({
    delay: isMobile ? 0 : 500,
    isDisabled,
  });

  const tooltipTriggerRef = useRef<HTMLElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const { triggerProps, tooltipProps } = useTooltipTrigger(
    {
      isDisabled,
    },
    state,
    tooltipTriggerRef
  );

  const { overlayProps, arrowProps, placement, updatePosition } =
    useOverlayPosition({
      placement: tooltipPlacement ?? 'bottom',
      targetRef: tooltipTriggerRef,
      overlayRef,
      offset: 4,
      crossOffset: 0,
      isOpen: state.isOpen,
      onClose: state.close,
      shouldFlip: true,
    });

  React.useEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  return (
    <FocusableProvider {...triggerProps}>
      {React.cloneElement(trigger as React.ReactElement, {
        ref: tooltipTriggerRef,
        onPress: () => {
          if (!state.isOpen) {
            state.open();
          }
        },
      })}
      <TooltipContext.Provider
        value={{
          state,
          placement,
          ref: overlayRef,
          arrowProps,
          style: overlayProps.style,
          ...tooltipProps,
        }}
      >
        {state.isOpen && (
          <>
            {isMobile ? (
              <div className="hlx-glossary-term-backdrop">
                <Tray
                  state={{
                    ...state,
                    setOpen: state.open,
                    toggle: state.isOpen ? state.close : state.open,
                  }}
                  height="fit-content"
                >
                  <div className="hlx-glossary-term-tray-content">
                    {content}
                  </div>
                </Tray>
              </div>
            ) : (
              <OverlayContainer>
                <Tooltip>{content}</Tooltip>
              </OverlayContainer>
            )}
          </>
        )}
      </TooltipContext.Provider>
    </FocusableProvider>
  );
};

export type GlossaryTermButtonProps = Simplify<
  InteractionProps &
    PickAndConfigure<
      AriaButtonProps<'button'>,
      'onPress' | { children: 'children' }
    >
>;

const GlossaryTermButton = React.forwardRef<
  HTMLButtonElement,
  GlossaryTermButtonProps
>(({ ...props }, ref) => {
  const { ariaProps, hoverProps, focusProps, isFocusVisible } =
    useInteraction(props);
  const { buttonProps } = useButton(
    { ...ariaProps },
    ref as React.RefObject<HTMLButtonElement>
  );

  return (
    <button
      className={clsx('hlx-glossary-term-button', {
        'focus-ring': isFocusVisible,
      })}
      type="button"
      {...mergeProps(buttonProps, focusProps, hoverProps)}
      ref={ref}
      aria-describedby="hlx-glossary-term-description"
    >
      {props.children}
    </button>
  );
});

export type GlossaryTermProps = {
  term: string | React.ReactNode;
  children?: React.ReactNode;
};

export const GlossaryTerm = ({ term, children }: GlossaryTermProps) => {
  return (
    <GlossaryTermTrigger>
      <GlossaryTermButton>{term}</GlossaryTermButton>
      <div id="hlx-glossary-term-description">{children}</div>
    </GlossaryTermTrigger>
  );
};
