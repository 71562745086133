import { Skeleton } from '@mui/material';
import { useProvider } from 'hooks';
import React, { useState } from 'react';
import { useSearchParam } from 'react-use';

import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { PatientAddressSourceType } from '@headway/api/models/PatientAddressSourceType';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard, GuidanceCardProps } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { Warning } from '@headway/icons/dist/Warning';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  TELEHEALTH_LOCATIONS_SIGMUND,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { getUseUserQueryKey } from '@headway/shared/hooks/useUser';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import {
  isAddressUsedForClaims,
  sortPatientAddresses,
} from '@headway/shared/utils/address';
import { trackEvent } from '@headway/shared/utils/analytics';
import { isStatusOON } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';

import { useClaimReadiness } from 'hooks/useClaimReadiness';
import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import {
  getUsePatientAddressesQueryKey,
  usePatientAddresses,
} from 'hooks/usePatientAddresses';
import { AddressModal } from 'views/Patients/AddressModal';

const usePatientDisplayName = (patient: UserRead | undefined) => {
  return (
    formatPatientName(patient, {
      firstNameOnly: true,
    }) || 'your patient'
  );
};

interface AddressDisplayProps {
  client: UserRead;
  address: PatientAddressRead;
  onDelete?: (id: number) => Promise<void>;
  isFromEligibilityLookup?: boolean;
  isHomeAddress?: boolean;
  isDeletable?: boolean;
}

const InvalidLocationWarning = ({
  client,
  state,
}: {
  client: UserRead;
  state: UnitedStates;
}) => {
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const { insuranceStatus, isLoading } = useInsuranceStatus(
    client,
    client.activeUserInsurance,
    true,
    state,
    isMSCGuardrailWarning || isMSCGuardrailRestriction || isMSCEnabled
  );
  const clientDisplayName = usePatientDisplayName(client);
  const isOutOfNetwork = isStatusOON(insuranceStatus);
  if (!client.activeUserInsurance || isLoading || !isOutOfNetwork) {
    return null;
  }

  const isNotLicensedInState =
    insuranceStatus === PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED;
  const stateDisplayName = statesToDisplayNames[state as UnitedStates];
  const bodyText = isNotLicensedInState
    ? `You are not credentialed to practice in ${stateDisplayName} on Headway.`
    : `You cannot see ${clientDisplayName} through their insurance plan in ${stateDisplayName}.`;

  return (
    <span css={{ display: 'flex', alignItems: 'center' }}>
      <span css={{ height: 20, width: 20 }}>
        <Warning color={theme.color.primary.brown} />
      </span>
      <SubBodyText>
        <b
          css={{
            color: theme.color.primary.brown,
            marginLeft: theme.spacing.x2,
          }}
        >
          {bodyText}
        </b>
      </SubBodyText>
    </span>
  );
};

const AddressDisplay = ({
  client,
  address,
  onDelete,
  isFromEligibilityLookup = false,
  isHomeAddress = false,
  isDeletable = false,
}: AddressDisplayProps) => {
  const clientDisplayName = usePatientDisplayName(client);
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: theme.spacing.x3,
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {address.addressType && (
          <BodyText>{addressTypeDisplayNames[address.addressType]}</BodyText>
        )}
        <div>
          <BodyText>{address.streetLine1}</BodyText>
          {address.streetLine2 && (
            <>
              <br />
              <BodyText>{address.streetLine2}</BodyText>
            </>
          )}
          <br />
          <BodyText>
            {address.city ? (
              <span>
                {address.city},{' '}
                {statesToDisplayNames[address.state as UnitedStates]}
              </span>
            ) : (
              <span>{statesToDisplayNames[address.state as UnitedStates]}</span>
            )}{' '}
            {address.zipCode}
          </BodyText>
        </div>
        {isFromEligibilityLookup && (
          <SubBodyText color="gray">
            We found this address in {clientDisplayName}'s insurance details.
          </SubBodyText>
        )}

        {!isHomeAddress && (
          <InvalidLocationWarning client={client} state={address.state} />
        )}
      </div>
      {isDeletable && onDelete && (
        <div>
          <Button variant="link" onPress={() => onDelete(address.id)}>
            Remove
          </Button>
        </div>
      )}
    </div>
  );
};

const getHomeAddress = (
  client: UserRead,
  addresses: PatientAddressRead[]
): PatientAddressRead | undefined => {
  const activePatientAddress = client?.activePatientAddressId
    ? addresses.find((address) => address.id === client.activePatientAddressId)
    : undefined;

  if (activePatientAddress) {
    return activePatientAddress;
  }

  const eligibilityLookupAddress = addresses?.find(
    (address) => address.source === PatientAddressSourceType.ELIGIBILITY_LOOKUP
  );

  if (eligibilityLookupAddress) {
    return eligibilityLookupAddress;
  }

  return undefined;
};

export const MissingAddressBannerWithAddLocation = ({
  client,
  onAddressAddSuccess,
  variant = 'error',
  updateActiveAddress = false,
  children,
}: {
  client: UserRead;
  onAddressAddSuccess?: () => void;
  children?: React.ReactNode;
  variant?: GuidanceCardProps['variant'];
  updateActiveAddress?: boolean;
}) => {
  const provider = useProvider();
  const clientDisplayName = usePatientDisplayName(client);
  const [showAddressModal, setShowAddressModal] = useState(false);

  return (
    <GuidanceCard variant={variant} dataTestId="missingAddressError">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {children || (
          <BodyText>
            We need {clientDisplayName}
            's home address to submit claims. You can either add this now or ask{' '}
            {clientDisplayName} to add this information with their Headway
            account, but you won’t be able to confirm sessions until we get this
            information.
          </BodyText>
        )}
        <div css={{ alignSelf: 'flex-start', marginTop: theme.spacing.x2 }}>
          <Button
            size="large"
            variant="link"
            onPress={() => {
              trackEvent({
                name: 'Provider Add New Address Button Clicked',
                properties: {
                  providerId: provider.id,
                  providerPatientId: client.id,
                },
              });
              setShowAddressModal(true);
            }}
          >
            Add address
          </Button>
        </div>
      </div>
      <AddressModal
        open={showAddressModal}
        patientUser={client}
        isActiveAddress={updateActiveAddress}
        onClose={() => {
          trackEvent({
            name: 'Provider Skip Telehealth Button Clicked',
            properties: {
              providerId: provider.id,
              providerPatientId: client.id,
            },
          });
          setShowAddressModal(false);
        }}
        onSuccess={() => {
          trackEvent({
            name: 'Provider Save and Verify Telehealth Button Clicked',
            properties: {
              providerId: provider.id,
              providerPatientId: client.id,
            },
          });
          onAddressAddSuccess && onAddressAddSuccess();
          setShowAddressModal(false);
        }}
      />
    </GuidanceCard>
  );
};

interface AddressManagementProps {
  client: UserRead | undefined;
}

export const AddressManagement = ({ client }: AddressManagementProps) => {
  const provider = useProvider();
  const shouldShowNewLocationModal =
    useSearchParam('showNewLocationModal') !== null;
  const [showNewLocationModal, setShowNewLocationModal] = useState(
    shouldShowNewLocationModal
  );
  const queryClient = useQueryClient();
  const clientDisplayName = usePatientDisplayName(client);

  const isTelehealthLocationsFlagEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );

  const { isLoading, data: patientAddresses } = usePatientAddresses({
    patientId: client?.id,
  });

  const insurance = client?.activeUserInsurance;
  const { insuranceStatus } = useInsuranceStatus(client, insurance);
  const { data: claimReadiness } = useClaimReadiness({ patientUser: client });
  const { isMSCGuardrailWarning, restrictionDate } = useMSCGuardrail();
  const patientAddressRequired =
    claimReadiness?.requirements?.includes(
      UserClaimReadinessCheck.PATIENT_ADDRESS
    ) ?? false;
  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  const needsHomeAddress = patientAddressRequired && !isInNoDataPrelimPricing;

  const homePatientAddressRead: PatientAddressRead | undefined =
    client && patientAddresses
      ? getHomeAddress(client, patientAddresses)
      : undefined;

  const showHomeAddress = needsHomeAddress || homePatientAddressRead;

  const onAddSuccess = () => {
    queryClient.invalidateQueries(
      getUsePatientAddressesQueryKey({ patientId: client?.id })
    );

    if (client?.id) {
      queryClient.invalidateQueries(getUseUserQueryKey({ userId: client.id }));
    }

    setShowNewLocationModal(false);
  };

  const deleteAddressMutation = useMutation(
    async (id: number) => {
      return await PatientAddressApi.deletePatientAddress(id);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(
          getUsePatientAddressesQueryKey({ patientId: client?.id })
        );
      },
      onError(err) {
        logException(err);
      },
    }
  );

  const onDelete = async (id: number) => {
    if (
      isTelehealthLocationsFlagEnabled &&
      id !== client?.activePatientAddressId
    ) {
      await deleteAddressMutation.mutateAsync(id);
    }
  };
  return (
    <>
      {showHomeAddress && (
        <PageSection>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing.x2,
            }}
          >
            <h2
              css={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <SectionHeader>Home address</SectionHeader>
            </h2>
            <BodyText>
              Insurance companies require residence verification for eligibility
              and claims.
            </BodyText>
          </div>
          {isLoading || !client ? (
            <Skeleton variant="rectangular" height={64} />
          ) : (
            <div>
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing.x4,
                }}
              >
                {homePatientAddressRead ? (
                  <div css={{ display: 'flex', flexDirection: 'column' }}>
                    <AddressDisplay
                      client={client}
                      address={homePatientAddressRead}
                      isFromEligibilityLookup={
                        homePatientAddressRead.source ===
                        PatientAddressSourceType.ELIGIBILITY_LOOKUP
                      }
                      isHomeAddress
                    />
                  </div>
                ) : (
                  <MissingAddressBannerWithAddLocation
                    client={client}
                    onAddressAddSuccess={onAddSuccess}
                    updateActiveAddress={true}
                  />
                )}
              </div>
            </div>
          )}
        </PageSection>
      )}
      <PageSection>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing.x2,
          }}
        >
          <h2
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <SectionHeader>Virtual session locations</SectionHeader>
            <Button
              variant="link"
              onPress={() => {
                trackEvent({
                  name: 'Provider Add New Address Button Clicked',
                  properties: {
                    providerId: provider.id,
                    providerPatientId: client!.id,
                  },
                });
                setShowNewLocationModal(true);
              }}
            >
              New location
            </Button>
          </h2>
          <BodyText>
            {isMSCGuardrailWarning ? (
              <span>
                Your client must be physically located (during your session) in
                a state where you are credentialed with their insurance plan on
                Headway. Starting after{' '}
                <span className="font-bold">{restrictionDate}</span>, we'll
                block telehealth session confirmation if there is a mismatch.
              </span>
            ) : (
              <span>
                As a reminder, you must be licensed and credentialed in the
                state where {clientDisplayName} is located during the session to
                practice on Headway.
              </span>
            )}{' '}
            <Link
              href={CREDENTIALING_REQS_LINK}
              target="_blank"
              rel="noreferrer"
            >
              <strong>Learn more</strong>
            </Link>
          </BodyText>
        </div>

        {isLoading || !client ? (
          <Skeleton variant="rectangular" height={64} />
        ) : (
          <div>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing.x3,
                '> div:not(:first-child)': {
                  borderTop: `1px solid ${theme.color.system.borderGray}`,
                },
              }}
            >
              {patientAddresses && patientAddresses.length > 0 ? (
                sortPatientAddresses(patientAddresses, client).map(
                  (address, index) => {
                    return (
                      <AddressDisplay
                        key={index}
                        client={client}
                        address={address}
                        isFromEligibilityLookup={
                          address.source ===
                          PatientAddressSourceType.ELIGIBILITY_LOOKUP
                        }
                        isDeletable={
                          isTelehealthLocationsFlagEnabled &&
                          !isAddressUsedForClaims(address, client)
                        }
                        onDelete={onDelete}
                      />
                    );
                  }
                )
              ) : (
                <SubBodyText color="gray">No locations added</SubBodyText>
              )}
            </div>
          </div>
        )}
      </PageSection>
      {client && (
        <AddressModal
          open={showNewLocationModal}
          patientUser={client}
          isActiveAddress={false}
          onClose={() => {
            trackEvent({
              name: 'Provider Skip Telehealth Button Clicked',
              properties: {
                providerId: provider.id,
                providerPatientId: client.id,
              },
            });
            setShowNewLocationModal(false);
          }}
          onSuccess={() => {
            trackEvent({
              name: 'Provider Save and Verify Telehealth Button Clicked',
              properties: {
                providerId: provider.id,
                providerPatientId: client.id,
              },
            });
            onAddSuccess();
          }}
        />
      )}
    </>
  );
};
