import axiosModule, { AxiosRequestConfig, Method } from 'axios';

import { ContactInfo } from '@headway/api/models/ContactInfo';

export const axios = axiosModule.create({
  baseURL: process.env.REACT_APP_API_URL || process.env.API_URL,
  withCredentials: true,
  paramsSerializer: {
    indexes: null, // format array query params as `?a=1&a=2`
  },
});

export default axios;

export class ContactInformationApiResource {
  /**
     * Batch Upload Providers To Platform
     * 
     * @param externalPlatformId 
     * @param file 
     
     */
  public parseFile(
    files: Array<any>,
    provider_id: number,
    axiosConfig?: AxiosRequestConfig
  ): Promise<Array<ContactInfo>> {
    const reqPath = `/contact-information?provider_id=${provider_id}`;
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    let reqConfig = {
      ...axiosConfig,
      method: 'POST' as Method,
      url: reqPath,
      headers: { 'Content-Type': 'multipart/form' },
      data: formData,
    };
    return axios.request(reqConfig).then((res) => {
      return res.data;
    });
  }
}

export const ContactInformationApi = new ContactInformationApiResource();
