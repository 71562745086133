import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { trackEvent } from '@headway/shared/utils/analytics';

export const trackBackButtonClickedEvent = ({
  providerPatient,
  pageHeader,
}: {
  providerPatient: ProviderPatientRead;
  pageHeader: string;
}) => {
  trackEvent({
    name: 'Treatment Plan Back Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      providerPatientId: providerPatient.id,
      pageHeader: pageHeader,
    },
  });
};

export const trackBeginButtonClickedEvent = ({
  providerPatient,
  treatmentPlanType,
}: {
  providerPatient: ProviderPatientRead;
  treatmentPlanType: string;
}) => {
  trackEvent({
    name: 'Treatment Plan Begin Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      providerPatientId: providerPatient.id,
      treatmentPlanRadioButton: treatmentPlanType,
    },
  });
};

export const trackNextButtonClickedEvent = ({
  providerPatient,
  pageHeader,
}: {
  providerPatient: ProviderPatientRead;
  pageHeader: string;
}) => {
  trackEvent({
    name: 'Treatment Plan Next Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      providerPatientId: providerPatient.id,
      pageHeader: pageHeader,
    },
  });
};

export const trackCompleteDraftButtonClickedEvent = (
  providerPatient: ProviderPatientRead
) => {
  trackEvent({
    name: 'Treatment Plan Complete Draft Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      providerPatientId: providerPatient.id,
    },
  });
};

export const trackSaveAndExitButtonClickedEvent = (
  providerPatient: ProviderPatientRead
) => {
  trackEvent({
    name: 'Treatment Plan Save and Exit Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      providerPatientId: providerPatient.id,
    },
  });
};

export const trackSaveButtonClickedEvent = ({
  providerPatient,
  treatmentPlanType,
  characterCount,
}: {
  providerPatient: ProviderPatientRead;
  treatmentPlanType: string;
  characterCount?: number;
}) => {
  trackEvent({
    name: 'Treatment Plan Save Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      treatmentPlanRadioButton: treatmentPlanType,
      characterCount: characterCount ?? 0,
    },
  });
};

export const trackSummaryEditButtonClickedEvent = (
  providerPatient: ProviderPatientRead
) => {
  trackEvent({
    name: 'Treatment Plan Summary Edit Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
      providerPatientId: providerPatient.id,
    },
  });
};

export const trackEditButtonClickedEvent = (
  providerPatient: ProviderPatientRead
) => {
  trackEvent({
    name: 'Treatment Plan Edit Button Clicked',
    properties: {
      patientUserId: providerPatient.userId,
      providerId: providerPatient.providerId,
    },
  });
};
