import { Box } from '@mui/material';
import React from 'react';

import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent } from '@headway/helix/Modal';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';

import { ConsolidatedProviderPanelabilityEvaluation } from 'views/Panelability/consolidateProviderPanelabilityEvaluation';
import { PanelabilityIconHeaderFromStatus } from 'views/Panelability/PanelabilityIconHeaders';

export const PanelabilityExplanationModalAndButton = ({
  consolidatedPanelabilityEvaluation,
}: {
  consolidatedPanelabilityEvaluation: ConsolidatedProviderPanelabilityEvaluation;
}) => {
  const [
    isPanelabilityExplanationModalOpen,
    setIsPanelabilityExplanationModalOpen,
  ] = React.useState(false);
  if (
    !consolidatedPanelabilityEvaluation.panelingInfoForPayers.length ||
    !consolidatedPanelabilityEvaluation.panelingInfoForPayers.some(
      (evaluation) =>
        evaluation.status == PanelabilityStatus.NOT_PANELABLE ||
        evaluation.status == PanelabilityStatus.MAYBE_PANELABLE
    )
  ) {
    return null;
  }
  return (
    <>
      <Button
        size="medium"
        variant="link"
        onPress={async () => {
          setIsPanelabilityExplanationModalOpen(true);
        }}
      >
        What impacts my eligibility?
      </Button>
      <PanelabilityExplanationModal
        isOpen={isPanelabilityExplanationModalOpen}
        onClose={() => setIsPanelabilityExplanationModalOpen(false)}
        consolidatedPanelabilityEvaluation={consolidatedPanelabilityEvaluation}
      />
    </>
  );
};
const PanelabilityExplanationModal = ({
  isOpen,
  onClose,
  consolidatedPanelabilityEvaluation,
}: {
  isOpen: boolean;
  onClose: () => void;
  consolidatedPanelabilityEvaluation: ConsolidatedProviderPanelabilityEvaluation;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      title={'Learn more about eligibility requirements'}
    >
      <ModalContent>
        <PanelabilityExplanationTable
          status={PanelabilityStatus.NOT_PANELABLE}
          consolidatedPanelabilityEvaluation={
            consolidatedPanelabilityEvaluation
          }
          idx={0}
        />
        <PanelabilityExplanationTable
          status={PanelabilityStatus.MAYBE_PANELABLE}
          consolidatedPanelabilityEvaluation={
            consolidatedPanelabilityEvaluation
          }
          idx={1}
        />
      </ModalContent>
    </Modal>
  );
};

const PanelabilityExplanationTable = ({
  status,
  consolidatedPanelabilityEvaluation,
  idx,
}: {
  status: PanelabilityStatus;
  consolidatedPanelabilityEvaluation: ConsolidatedProviderPanelabilityEvaluation;
  idx: number;
}) => {
  const panelabilityEvaluations =
    consolidatedPanelabilityEvaluation.panelingInfoForPayers.filter(
      (panelingEvaluation) => panelingEvaluation.status == status
    );
  if (!panelabilityEvaluations?.length) {
    return null;
  }
  return (
    <>
      {idx > 0 && <Box height={theme.spacing.x5} />}
      <PanelabilityIconHeaderFromStatus status={status} />
      <Box height={theme.spacing.x5} />
      <div
        css={{
          ['table']: {
            tableLayout: 'fixed',
          },
          ['tbody tr:nth-child(even)']: {
            backgroundColor: theme.color.system.lightGray,
          },
          ['thead td:first-child']: {
            width: '33%',
            borderRight: `1px solid ${theme.color.system.borderGray}`,
          },
          ['thead th button']: {
            textWrap: 'nowrap',
          },
          ['tbody td:first-child']: {
            width: '33%',
            borderRight: `1px solid ${theme.color.system.borderGray}`,
          },
          display: 'grid',
          gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
          overflow: 'auto',
        }}
      >
        <Table>
          <TableHeader>
            <Column key={`column-header-audit-result-${0}`}>{'Plan'}</Column>
            <Column key={`column-header-audit-result-${1}`}>
              {'Eligibility Requirements'}
            </Column>
          </TableHeader>
          <TableBody>
            {panelabilityEvaluations.map((panelingEvaluation, idx) => (
              <Row key={`paneling-evaluation-result-row-${idx}`}>
                <Cell key={`paneling-evaluation-result-row-${idx}-cell-${0}`}>
                  {panelingEvaluation.displayName}
                </Cell>
                <Cell key={`paneling-evaluation-result-row-${idx}-cell-${0}`}>
                  <ul>
                    {panelingEvaluation.messages.map((message, idx) => (
                      <li css={{ paddingTop: 4, paddingBottom: 4 }}>
                        {message}
                      </li>
                    ))}
                  </ul>
                </Cell>
              </Row>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
