import { theme } from '@headway/helix/theme';

import { MOST_RECENT_ASSESSMENT_CLASSNAME } from '../../helpers/constants';

export const SafetyRiskGlyph = ({
  cx,
  cy,
  setIsTooltipOpen,
  active,
  isMostRecentAssessment,
}: {
  cx: number;
  cy: number;
  setIsTooltipOpen: (toChange: boolean) => {};
  active?: boolean;
  isMostRecentAssessment: boolean;
}) => (
  <svg
    x={cx - 11.5}
    y={cy - 10.5}
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onMouseEnter={() => setIsTooltipOpen(true)}
    onMouseLeave={() => setIsTooltipOpen(false)}
    className={isMostRecentAssessment ? MOST_RECENT_ASSESSMENT_CLASSNAME : ''}
  >
    <path
      d="M4.06152 16.9999L11.39486 4.33327L18.7282 16.9999H4.06152Z"
      stroke={
        active ? theme.color.hue.lightSystemRed : theme.color.system.white
      }
      strokeWidth={active ? '6px' : '2px'}
    />
    <path d="M4.06152 16.9999L11.39486 4.33327L18.7282 16.9999H4.06152Z" />
    <path
      d="M4.06152 16.9999L11.39486 4.33327L18.7282 16.9999H4.06152Z"
      fill="#E32C02"
    />
    <path
      d="M11.41457 15.7999C11.18801 15.7999 10.99473 15.7199 10.83473 15.5599C10.67473 15.3999 10.59473 15.2067 10.59473 14.9801C10.59473 14.7532 10.67473 14.5598 10.83473 14.3998C10.99473 14.2398 11.18801 14.1598 11.41457 14.1598C11.64113 14.1598 11.83441 14.2398 11.99441 14.3998C12.1544 14.5598 12.2344 14.7532 12.2344 14.9801C12.2344 15.2067 12.1544 15.3999 11.99441 15.5599C11.83441 15.7199 11.64113 15.7999 11.41457 15.7999ZM10.59473 13.3999V8.59993H12.2344V13.3999H10.59473Z"
      fill="white"
    />
  </svg>
);
