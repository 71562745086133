import { Info } from '@mui/icons-material';
import {
  Alert,
  Checkbox,
  FormHelperText,
  ListItemText,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { PrescriberReferralApi } from '@headway/api/resources/PrescriberReferralApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { SpecialtyApi } from '@headway/api/resources/SpecialtyApi';
import {
  GENDERS_TO_DISPLAY_NAMES,
  shouldIncludeProviderGender,
} from '@headway/shared/constants/gender';
import { SORTED_ETHNICITY_OPTIONS } from '@headway/shared/utils/ethnicity';
import { Button } from '@headway/ui';
import { Chip } from '@headway/ui/Chip';
import { diagnosisOptions } from '@headway/ui/DiagnosisCodeInput';
import {
  FieldAutocompleteFuzzy,
  FieldControl,
  FieldInputLabel,
  FieldSelect,
  FieldTextField,
} from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

const referralReasonDropdownItems = [
  'Psychiatric eval to assess if med management is recommended',
  'On-going med management',
  'Prolonged depression - higher level of care needed',
  'Suicidal thoughts or attempts - higher level of care needed',
  'Active addiction - higher level of care needed',
  'Eating disorder - higher level of care needed',
  'OCD - higher level of care needed',
  'Bipolar I - higher level of care needed',
  'Bipolar II - higher level of care needed',
  'History of psychosis/psychotic disorder - higher level of care needed',
  'Other',
];
const appointmentPreferenceDropdownItems = [
  'No preference or unknown',
  'Morning',
  'Afternoon',
  'Evening',
  'Weekend',
];
const ethnicityPreferenceDropdownItems = [
  'No preference',
  ...SORTED_ETHNICITY_OPTIONS,
];
const MULTI_SELECT_HELPER_TEXT = 'Select multiple if applicable.';
interface PrescriberReferralFormProps {
  onClose: () => void;
  provider: ProviderRead;
  patientUserId: number;
}
interface DiagnosisCode {
  description: string;
  value: string;
  display: string;
}

export type PrescriberFormValues = {
  referralReason: string;
  referralCommonDxCodes: DiagnosisCode[];
  primaryReferralTreatment: string;
  secondaryReferralTreatment: string;
  genderPreference: string[];
  preferredAppointmentTime: string[];
  ethnicityPreference: string[];
  additionalNotes: string;
};

const renderMultiSelectValues = (selected: string[]) => selected.join(', ');

export const PrescriberReferralForm: React.FC<
  React.PropsWithChildren<PrescriberReferralFormProps>
> = ({ provider, onClose, patientUserId }) => {
  const [suggestedDiagnosisCodes, setSuggestedDiagnosisCodes] = useState<
    DiagnosisCode[]
  >([]);
  const [specialties, setSpecialties] = useState<string[]>([]);

  useEffect(() => {
    const fetchSuggestedDiagnosisCodes = () => {
      ProviderApi.getSuggestedDiagnosisCodes(provider.id, patientUserId).then(
        (res) => {
          if (res.length) {
            setSuggestedDiagnosisCodes(
              diagnosisOptions(true).filter((o) => res.includes(o.value))
            );
          }
        }
      );
    };

    const fetchSpecialties = () => {
      SpecialtyApi.getSpecialties({
        get_available_to_patients_only: true,
      }).then((res) => {
        setSpecialties(
          res
            .map((specialty) =>
              specialty.patientDisplayName
                ? specialty.patientDisplayName
                : specialty.clinicalDisplayName
            )
            .sort()
        );
      });
    };

    fetchSuggestedDiagnosisCodes();
    fetchSpecialties();
  }, [provider.id, patientUserId]);

  const initialValues: PrescriberFormValues = {
    referralReason: '',
    referralCommonDxCodes: suggestedDiagnosisCodes,
    primaryReferralTreatment: '',
    secondaryReferralTreatment: '',
    genderPreference: [],
    preferredAppointmentTime: [],
    ethnicityPreference: [],
    additionalNotes: '',
  };

  const validationSchema = Yup.object().shape({
    referralReason: Yup.string().required('Reason for referral is required.'),
    referralCommonDxCodes: Yup.array(Yup.string()),
    primaryReferralTreatment: Yup.string().required(
      'Primary treatment need is required.'
    ),
    secondaryReferralTreatment: Yup.string(),
    genderPreference: Yup.array(Yup.string()).required(
      'Psychiatric care provider gender preference is required.'
    ),
    preferredAppointmentTime: Yup.array(Yup.string()).required(
      'Preferred appointment time is required.'
    ),
    ethnicityPreference: Yup.array(Yup.string()),
    additionalNotes: Yup.string(),
  });

  const handleSubmit = async (values: PrescriberFormValues) => {
    const prescriberReferralValues = {
      patientId: patientUserId,
      referringProviderId: provider.id,
      referralReason: values.referralReason,
      referralCommonDxCodes: values.referralCommonDxCodes.map(
        (code: any) => code.value
      ),
      primaryReferralTreatment: values.primaryReferralTreatment,
      secondaryReferralTreatment: values.secondaryReferralTreatment,
      genderPreference: values.genderPreference,
      preferredAppointmentTime: values.preferredAppointmentTime,
      ethnicityPreference: values.ethnicityPreference,
      additionalNotes: values.additionalNotes,
    };
    try {
      await PrescriberReferralApi.createPrescriberReferral(
        prescriberReferralValues
      );
      notifySuccess(
        'Psychiatric care referral was submitted successfully. Headway will reach out to your client within 48 hours.'
      );
    } catch (err: any) {
      notifyError(err.toString());
    }

    onClose();
  };

  const genderPreferenceDropdownItems = [
    'No preference or unknown',
    ...Object.entries(GENDERS_TO_DISPLAY_NAMES)
      .filter(([key, _]) => shouldIncludeProviderGender(key))
      .map(([, value]) => {
        return value;
      }),
  ];

  return (
    <Formik
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ handleChange, values }) => {
        return (
          <SafeFormikForm>
            <div>
              Once submitted, Headway will reach out to your client within 48
              hours to help connect them to a psychiatric NP or psychiatrist.
              We'll follow up with you once a match is made.
            </div>
            <FieldControl name="referralReason" fullWidth={true}>
              <FieldInputLabel>
                What is your reason for referral?
              </FieldInputLabel>
              <FieldSelect>
                {referralReasonDropdownItems.map((reason, idx) => {
                  return (
                    <MenuItem key={idx} value={reason}>
                      {reason}
                    </MenuItem>
                  );
                })}
              </FieldSelect>
            </FieldControl>
            <FieldControl name="referralCommonDxCodes" fullWidth={true}>
              {/* TODO: implement DXCodeInput */}
              {/* <DXCodeInput label="What Dx codes have you used most frequently for this patient?" /> */}
              <FieldAutocompleteFuzzy
                label="What Dx codes have you used most frequently for this client?"
                fuzzySearchOptions={{
                  keys: ['value', 'display'],
                  useExtendedSearch: true,
                  ignoreLocation: true,
                }}
                multiple={true}
                options={diagnosisOptions()}
                groupBy={(option: any) => option.description}
                getOptionLabel={(option: any) =>
                  `${option.value} — ${option.display}`
                }
                renderTags={(value: any, getTagProps: any) =>
                  value.map((option: any, idx: number) => (
                    <Chip
                      {...getTagProps({ idx })}
                      label={`${option.value} — ${option.display}`}
                    />
                  ))
                }
              />
              <FormHelperText>{MULTI_SELECT_HELPER_TEXT}</FormHelperText>
            </FieldControl>
            <FieldControl name="primaryReferralTreatment" fullWidth={true}>
              <FieldInputLabel>
                What is the primary treatment need for referral?
              </FieldInputLabel>
              <FieldSelect>
                {specialties.map((specialty) => (
                  <MenuItem key={specialty} value={specialty}>
                    {specialty}
                  </MenuItem>
                ))}
              </FieldSelect>
            </FieldControl>
            <FieldControl name="secondaryReferralTreatment" fullWidth={true}>
              <FieldInputLabel>
                What is the secondary treatment need for referral?
              </FieldInputLabel>
              <FieldSelect>
                {specialties
                  .filter(
                    (specialty) =>
                      specialty !== values['primaryReferralTreatment']
                  )
                  .map((specialty) => (
                    <MenuItem key={specialty} value={specialty}>
                      {specialty}
                    </MenuItem>
                  ))}
              </FieldSelect>
            </FieldControl>
            <FieldControl name="genderPreference" fullWidth={true}>
              <FieldInputLabel>
                What is the client's provider gender preference?
              </FieldInputLabel>
              <FieldSelect
                multiple={true}
                onChange={handleChange}
                renderValue={renderMultiSelectValues}
              >
                {genderPreferenceDropdownItems.map((gender) => {
                  return (
                    <MenuItem key={gender} value={gender}>
                      <Checkbox
                        checked={
                          values['genderPreference'].indexOf(gender) > -1
                        }
                      />
                      <ListItemText primary={gender} />
                    </MenuItem>
                  );
                })}
              </FieldSelect>
              <FormHelperText>{MULTI_SELECT_HELPER_TEXT}</FormHelperText>
            </FieldControl>
            <FieldControl name="preferredAppointmentTime" fullWidth={true}>
              <FieldInputLabel>
                What is the client's preferred appointment time?
              </FieldInputLabel>
              <FieldSelect
                multiple={true}
                onChange={handleChange}
                renderValue={renderMultiSelectValues}
              >
                {appointmentPreferenceDropdownItems.map((appointment) => {
                  return (
                    <MenuItem key={appointment} value={appointment}>
                      <Checkbox
                        checked={
                          values['preferredAppointmentTime'].indexOf(
                            appointment
                          ) > -1
                        }
                      />
                      <ListItemText primary={appointment} />
                    </MenuItem>
                  );
                })}
              </FieldSelect>
              <FormHelperText>{MULTI_SELECT_HELPER_TEXT}</FormHelperText>
            </FieldControl>
            <FieldControl name="ethnicityPreference" fullWidth={true}>
              <FieldInputLabel>
                What is the client's provider ethnicity preference?
              </FieldInputLabel>
              <FieldSelect
                multiple={true}
                onChange={handleChange}
                renderValue={renderMultiSelectValues}
              >
                {ethnicityPreferenceDropdownItems.map((ethnicity) => {
                  return (
                    <MenuItem key={ethnicity} value={ethnicity}>
                      <Checkbox
                        checked={
                          values['ethnicityPreference'].indexOf(ethnicity) > -1
                        }
                      />
                      <ListItemText primary={ethnicity} />
                    </MenuItem>
                  );
                })}
              </FieldSelect>
              <FormHelperText>{MULTI_SELECT_HELPER_TEXT}</FormHelperText>
            </FieldControl>
            <FieldControl name="additionalNotes" fullWidth={true}>
              <FieldTextField label="Any additional notes that would be helpful?" />
            </FieldControl>
            <Alert severity="warning" icon={<Info />}>
              <span>
                <strong>Please note:</strong> if this client is experiencing
                active suicidal ideation*, please direct them to the nearest
                emergency room. Otherwise, we will be in touch with your client
                within 48 hours.
              </span>
              <br />
              <br />
              <span>
                *Client has a plan, has been engaging in self-harming behaviors,
                or has had a suicide attempt in the past 2.5 weeks
              </span>
            </Alert>
            <div
              css={{
                padding: theme.space.base,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button type="submit" color="primary" variant="contained">
                Submit
              </Button>
            </div>
          </SafeFormikForm>
        );
      }}
    </Formik>
  );
};
