import { css } from '@emotion/react';
import { CaptureContext } from '@sentry/types';
import { useFormikContext } from 'formik';
import React, { FC, useState } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Modal } from '@headway/ui';
import { FieldControl, FieldErrorText } from '@headway/ui/form';
import { ProviderProfilePhoto } from '@headway/ui/providers/ProviderProfilePhoto';
import { theme } from '@headway/ui/theme';

import badPhotoSrc from 'assets/img/profile-photo-bad.png';
import goodPhotoSrc from 'assets/img/profile-photo-good.png';

const inputCss = {
  height: '100%',
  marginTop: theme.space.base,
  [theme.media.small]: { marginTop: 0 },
  '& .MuiInputBase': {
    fontSize: theme.fontSize.xs,
  },
};

interface Props {
  urlFieldName: string;
  s3ObjectKeyFieldName: string;
  onError: (err: string, additionalContext?: CaptureContext) => void;
  onSave?: (photoUrl: string, photoS3ObjectKey: string) => void;
  onWarning?: (message: string) => void;
  provider: ProviderRead;
  showTipsInModal?: boolean;
  uploadButtonText?: string;
}

export const ProfilePhotoInput: FC<React.PropsWithChildren<Props>> = ({
  urlFieldName,
  s3ObjectKeyFieldName,
  onError,
  onSave,
  onWarning,
  provider,
  showTipsInModal = true,
  uploadButtonText,
}) => {
  const { values: formValues, setFieldValue } = useFormikContext<{
    [key: string]: string | undefined;
  }>();
  const photoUrl = formValues[urlFieldName];

  const [showTips, setShowTips] = useState(false);

  return (
    <>
      <FieldControl css={inputCss} name={urlFieldName}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ProviderProfilePhoto
            provider={provider}
            photoUrl={photoUrl}
            onCancel={() => {
              setFieldValue(urlFieldName, provider.photoUrl);
              setFieldValue(s3ObjectKeyFieldName, provider.photoS3ObjectKey);
            }}
            onCropFinish={onSave}
            onUploadFinish={(url, s3ObjectKey) => {
              setFieldValue(urlFieldName, url);
              setFieldValue(s3ObjectKeyFieldName, s3ObjectKey);
            }}
            onError={onError}
            onWarning={onWarning}
            supportUploads
            css={{ width: '150px', marginTop: theme.space.xs }}
            uploadButtonText={uploadButtonText}
          />
        </div>
        <FieldErrorText />
      </FieldControl>

      {showTipsInModal ? (
        <Modal
          title="Profile Photo Tips"
          open={showTips}
          onClose={() => setShowTips(false)}
        >
          <ProfilePhotoTips />
        </Modal>
      ) : (
        <div css={photoTipsEmbeddedContainerCss}>
          <ProfilePhotoTips />
        </div>
      )}
    </>
  );
};

const ProfilePhotoTips: FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <div css={{ marginBottom: theme.space.xl2 }}>
      <p css={photoTipsTitleCss}>
        <strong>What a great photo looks like:</strong>
      </p>
      <div css={photoTipsCss}>
        <div css={photoExampleContainerCss}>
          <img
            alt="Example of a good headshot with appropriate sizing, quality, and style"
            src={goodPhotoSrc}
            css={photoExampleCss}
          />
        </div>
        <div css={photoTipsListCss}>
          <ol>
            <li>
              <strong>Sizing:</strong> The preview you see in your profile tab
              is also what clients see, so make sure your picture is not too
              zoomed in or cut off.
            </li>
            <li>
              <strong>Quality:</strong> Make sure your picture is clear,
              well-lit, and has a simple background. Clients find busy
              backgrounds less appealing.
            </li>
            <li>
              <strong>Style:</strong> Clients want to see an eye-level
              professional shot of you looking your sharpest with a warm,
              welcoming smile — selfies should be avoided.
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div css={{ marginBottom: theme.space.base }}>
      <p css={photoTipsTitleCss}>
        <strong>Photos to avoid:</strong>
      </p>
      <div css={photoTipsCss}>
        <div css={photoExampleContainerCss}>
          <img
            alt="Example of a bad headshot selfie"
            src={badPhotoSrc}
            css={photoExampleCss}
          />
        </div>
        <div css={photoTipsListCss}>
          <ol>
            <li>
              <strong>Avoid</strong> selfies and casual attire
            </li>
            <li>
              <strong>Avoid</strong> distracting backgrounds
            </li>
            <li>
              <strong>Avoid</strong> blurry or low resolution photos
            </li>
          </ol>
        </div>
      </div>
    </div>
  </>
);

const photoExampleContainerCss = css`
  box-sizing: content-box;
  height: 100%;
  margin: auto ${theme.space.base};
  width: 130px;
`;
const photoExampleCss = css`
  height: 130px;
  width: 130px;
`;

const photoTipsEmbeddedContainerCss = css`
  background: ${theme.color.background};
  border-radius: 3px;
  margin: 0 ${theme.space.base} ${theme.space.base} ${theme.space.base};
  padding: ${theme.space.base} 0 ${theme.space.sm} ${theme.space.lg};
`;

const photoTipsCss = css`
  display: flex;
  flex-wrap: wrap;
`;
const photoTipsListCss = css`
  align-self: center;
  width: 70%;
  ol {
    margin: 0;
  }
`;
const photoTipsTitleCss = css`
  margin-bottom: ${theme.space.base};
`;
