import { FormHelperText, MenuItem } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { useQueryClient } from '@headway/shared/react-query';
import {
  getUsePatientAddressesQueryKey,
  usePatientAddresses,
} from '@headway/sigmund/app/legacy/hooks/usePatientAddresses';
import {
  FieldControl,
  FieldErrorText,
  FieldInputLabel,
  FieldSelect,
} from '@headway/ui/form';

import { AddressModal } from '../../../../../Patients/AddressModal';

// gemini.link(web/apps/sigmund/app/legacy/views/Calendar/components/AppointmentConfirmation/Forms/FormComponents/PatientAddressDropdownHelix.tsx)
const PatientAddressDropdown = ({ patient }: { patient: UserRead }) => {
  const { isLoading, data: patientAddresses } = usePatientAddresses({
    patientId: patient?.id,
  });
  const queryClient = useQueryClient();

  const onAddSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      getUsePatientAddressesQueryKey({ patientId: patient.id })
    );
  }, [queryClient, patient.id]);
  const [showAddressModal, setShowAddressModal] = useState(false);

  return useMemo(() => {
    if (isLoading) {
      return <></>;
    }

    return (
      <>
        <div
          style={{
            marginLeft: '50px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <FieldControl
            name="appointmentLocationPatientAddressId"
            fullWidth={true}
          >
            <FieldInputLabel>
              Your client will join from (Optional)
            </FieldInputLabel>

            <FieldSelect>
              {patientAddresses?.map((address) => (
                <MenuItem value={address.id} key={address.id}>
                  {address.addressType &&
                    `${addressTypeDisplayNames[address.addressType]}: `}
                  {address.streetLine1}
                  {address.streetLine2 && ` ${address.streetLine2}`},{' '}
                  {address.city}, {statesToDisplayNames[address.state]}{' '}
                  {address.zipCode}
                </MenuItem>
              ))}
              <MenuItem value={-1} key={-1}>
                Select client location later
              </MenuItem>
            </FieldSelect>
            <FormHelperText style={{ marginLeft: '0px' }}>
              Your client must be physically located (during your session) in a
              state where you are credentialed with their insurance plan on
              Headway.
            </FormHelperText>
            <FieldErrorText />
          </FieldControl>
          <div style={{ padding: 10 }} />
          <div
            className={'hlx-combobox-actions button'}
            style={{ paddingTop: 18 }}
          >
            <Button
              size="medium"
              variant={'secondary'}
              onPress={() => setShowAddressModal(true)}
            >
              + New location
            </Button>
          </div>
        </div>
        <AddressModal
          open={showAddressModal}
          patientUser={patient}
          isActiveAddress={false}
          onClose={() => setShowAddressModal(false)}
          onSuccess={() => {
            onAddSuccess && onAddSuccess();
            setShowAddressModal(false);
          }}
        />
      </>
    );
  }, [isLoading, patientAddresses, showAddressModal]);
};

export default PatientAddressDropdown;
