import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import React, { useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconCreditCardCheck } from '@headway/helix/icons/CreditCardCheck';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { Warning } from '@headway/icons/dist/Warning';
import { CHC_OUTAGE_EXPEDITED_VERIFICATION_REQUEST_FLOW } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import clockImage from 'assets/img/clock.png';
import clinicalLeadHeadshotImage from 'assets/img/michael_heckendorn.png';

interface NoDataPrelimPricingVerificationInProgressModalProps {
  client: UserRead | undefined;
  open: boolean;
  onClose: () => void;
}

export const NoDataPrelimPricingVerificationInProgressModal = ({
  client,
  open,
  onClose,
}: NoDataPrelimPricingVerificationInProgressModalProps) => {
  const provider = useProvider();

  useEffect(() => {
    if (open && client)
      trackPageView({
        name: 'Verification In Progress Modal Viewed',
        properties: {
          patientUserId: client.id,
          providerId: provider.id,
          userInsuranceId: `${client.activeUserInsuranceId}`,
          prelimPricingType: `${client.activeUserInsurance?.prelimPricingType}`,
          presumptiveAccumulatorsApplied: [],
        },
      });
  }, [open, client, provider.id]);

  const isCHCOutageExpeditedVerificationRequestFlow = useFlag(
    CHC_OUTAGE_EXPEDITED_VERIFICATION_REQUEST_FLOW,
    false
  );

  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  if (!client) {
    return null;
  }
  return (
    <Modal
      title={
        <div css={title}>
          <Warning color={theme.color.primary.yellow} />
          Verification in progress
        </div>
      }
      isOpen={open}
      onDismiss={onClose}
    >
      <ModalContent>
        <div css={clockSection}>
          <img alt="Clock" src={clockImage} />
        </div>
        <div css={greetingText}>
          <BodyText>Hi {provider.displayFirstName},</BodyText>
        </div>
        <div css={section}>
          <BodyText>
            <>
              {client?.activeUserInsurance?.billingFrontEndCarrierName ||
                `${clientFirstName}’s insurer`}
              ’s system is experiencing a high volume of plan updates, and needs
              more time to get us the information we need to verify{' '}
              {clientFirstName}’s benefits. Our team is working on a resolution
              and has shared this same information with {clientFirstName}. In
              the meantime, you can:
            </>
          </BodyText>
        </div>
        <div css={[section, calloutContainer]}>
          <div css={calloutListItem}>
            <IconCreditCardCheck />
            <div css={calloutContent}>
              <BodyText>
                <strong>
                  <>
                    Ask {clientFirstName} if they’re okay paying full in-network
                    cost for now,
                  </>
                </strong>{' '}
                with a refund later if{' '}
                {client?.activeUserInsurance?.billingFrontEndCarrierName ||
                  'their insurer'}{' '}
                confirms the final cost is lower.
              </BodyText>
            </div>
          </div>
          <div css={calloutListItem}>
            <IconCreditCardCheck />
            <div css={calloutContent}>
              <BodyText>
                {isCHCOutageExpeditedVerificationRequestFlow ? (
                  <>
                    {' '}
                    <strong>
                      Remind {clientFirstName} they can cancel their session if
                      they’d prefer to wait until our team verifies the cost,{' '}
                    </strong>
                    a process which typically takes up to 7 days but might be
                    longer. If your client urgently needs care and can’t afford
                    full in-network cost, you can{' '}
                    <Link
                      target="_blank"
                      onClick={() => {
                        trackEvent({
                          name: 'Expedited Verification Request Modal Entrypoint Link Clicked',
                          properties: {
                            providerId: Number(provider.id),
                            expeditedVerificationRequestEntrypoint:
                              'SIGMUND_MODAL',
                          },
                        });
                      }}
                      href={`${process.env.REACT_APP_MAIN_URL}/contact?primaryIssueType=pit_client_billing___insurance_issues&secondaryIssueType=inactive_insurance_details&activeChcOutageModal=EXPEDITED_MANUAL_VERIFICATION&clientId=${client.id}`}
                    >
                      request an expedited verification
                    </Link>
                    .
                  </>
                ) : (
                  <>
                    Remind {clientFirstName} they can cancel their session if
                    they’d prefer to wait.
                  </>
                )}{' '}
                <>
                  If {clientFirstName} cancels within your cancellation window
                  while verification is in progress, or up to 3 days after,
                  Headway will cover the cancellation fee for them.
                </>
              </BodyText>
            </div>
          </div>
        </div>
        <div css={section}>
          <BodyText>
            {isCHCOutageExpeditedVerificationRequestFlow ? (
              <>
                We apologize for the delay and thank you for your understanding!
              </>
            ) : (
              <>
                As soon as we have more information (typically 2-7 days), we’ll
                email you with an update. Thanks for your understanding!
              </>
            )}
          </BodyText>
        </div>
        <div>
          <div css={footer}>
            <div>
              <img
                alt="Clinical Lead Headshot"
                src={clinicalLeadHeadshotImage}
                css={clinicalLeadHeadshot}
              />
            </div>
            <div css={signature}>
              <div css={signatureName}>Michael and team</div>
              <BodyText>Clinical Lead at Headway</BodyText>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <div css={footerButton}>
          <Button onPress={onClose} variant="primary" size="large">
            Got it
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const title = css`
  display: flex;
  align-items: center;
  color: ${theme.color.system.textBlack};
  svg {
    margin-right: ${theme.spacing.x2};
  }
`;
const clockSection = css`
  background-color: ${theme.color.hue.lightYellow};
  text-align: center;
  margin-bottom: ${theme.spacing.x7};
  padding: ${theme.spacing.x6};
  margin-top: -${theme.spacing.x5};
  margin-left: -${theme.spacing.x5};
  margin-right: -${theme.spacing.x5};
`;
const greetingText = css`
  margin-top: ${theme.spacing.x7};
  margin-bottom: ${theme.spacing.x4};
`;
const calloutContainer = css`
  background-color: ${theme.color.hue.lightYellow};
  border-radius: 10px;
`;
const calloutListItem = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.x3};
  padding: ${theme.spacing.x4} ${theme.spacing.x6};
`;
const calloutContent = css`
  flex: 1;
  display: flex;
  box-sizing: border-box;
`;
const section = css`
  margin-bottom: ${theme.spacing.x8};
`;
const footer = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: ${theme.spacing.x3};
`;
const signatureName = css`
  font-weight: bold;
  font-size: 17px;
  display: block;
`;
const clinicalLeadHeadshot = css`
  width: 48px;
  height: 48px;
`;
const signature = css`
  display: flex;
  flex-direction: column;
`;
const footerButton = css`
  text-align: right;
`;
