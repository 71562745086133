import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import React from 'react';
import { useToggle } from 'react-use';

import { ProviderBillingAccountRead } from '@headway/api/models/ProviderBillingAccountRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { StripeAccountCreateRequest } from '@headway/api/models/StripeAccountCreateRequest';
import { ProviderBillingAccountApi } from '@headway/api/resources/ProviderBillingAccountApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { ALWAYS_USE_GP_BILLING_ACCOUNT } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import { Modal, Tooltip } from '@headway/ui';
import { theme as legacyTheme } from '@headway/ui/theme';
import { notifyWarning } from '@headway/ui/utils/notify';

import {
  getBillingAccountQueryKey,
  useBillingAccountForBillingSettings,
} from '../../../../hooks/useBillingAccount';
import {
  hasUnmetStripeAccountRequirements,
  hasUnmetW9StripeRequirement,
} from '../../../../utils/billing';
import { BankAccountManagementSection } from './BankAccountManagementSection';
import { BillingAccountRequirementsWarning } from './BillingAccountRequirementsWarning';
import { StripeOnboardingButtonsSection } from './StripeOnboardingButtonsSection';
import { UpdateStripeAccountForm } from './UpdateStripeAccountForm';

interface BillingAccountVerificationDescriptionProps {
  billingAccountInfo: ProviderBillingAccountRead | null;
}

const BillingAccountVerificationDescription: React.FC<
  React.PropsWithChildren<BillingAccountVerificationDescriptionProps>
> = ({ billingAccountInfo }) => (
  <div css={{ maxWidth: '35ch' }}>
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <h3 css={{ marginTop: 0, ...theme.typography.list }}>
        <span css={theme.typography.list}>Verification</span>
      </h3>

      {billingAccountInfo?.stripeAccount &&
        hasUnmetStripeAccountRequirements(
          billingAccountInfo?.stripeAccount
        ) && (
          <BillingAccountRequirementsWarning
            description="Our payment processor has informed us that your account has
                      unmet verification requirements. This could impact your
                      ability to receive payouts."
          />
        )}
    </div>
    <div>
      In order to issue payments, our payment processor,
      <Tooltip
        title="Plaid is an industry-standard payment processing partner that allows you to link your bank information directly, rather than providing account details manually."
        placement="top"
      >
        <span
          css={{
            marginLeft: legacyTheme.space.xs2,
            textDecorationStyle: 'dotted',
            textDecorationLine: 'underline',
            textUnderlineOffset: 2,
          }}
        >
          Plaid*
        </span>
      </Tooltip>
      , requires verification of your individual or company information such as
      your SSN or EIN. This will be used for your 1099 form at the end of the
      year.
    </div>
  </div>
);

interface ProviderBillingAccountsManagementProps {
  provider: ProviderRead;
  providerName: string;
  onBankAccountError: (e: string) => void;
  onStripeOnboardError: (e: string) => void;
  onBillingAccountCreate: (
    account: ProviderBillingAccountRead,
    isGroupPracticeAccount: boolean
  ) => void;
}

export const ProviderBillingAccountsManagement: React.FC<
  React.PropsWithChildren<ProviderBillingAccountsManagementProps>
> = ({
  provider,
  providerName,
  onBankAccountError,
  onStripeOnboardError,
  onBillingAccountCreate,
}) => {
  const providerId = provider.id;

  const { billingAccountInfo } = useBillingAccountForBillingSettings();
  const isGroupPracticeBillingAccount =
    billingAccountInfo?.isGroupPracticeBillingAccount;
  const alwaysUseGpBillingAccount = useFlag(ALWAYS_USE_GP_BILLING_ACCOUNT);

  const { billingAccountInfo: previousBillingAccountInfo } =
    useBillingAccountForBillingSettings({
      useGroupPracticeBillingAccount: false,
      queryEnabled:
        !!isGroupPracticeBillingAccount && !alwaysUseGpBillingAccount,
    });

  const queryClient = useQueryClient();

  const billingAccountQueryKey = getBillingAccountQueryKey({
    providerId,
    shouldQueryGroupPractice: !!isGroupPracticeBillingAccount, // key for should query GP is whether it did query GP
  });

  const replaceStripeAccountMutation = useMutation(
    (variables: Omit<StripeAccountCreateRequest, 'providerId'>) => {
      const providerOrGroupPracticeId = isGroupPracticeBillingAccount
        ? {
            groupPracticeId: provider.groupPracticeId,
          }
        : { providerId };
      return ProviderBillingAccountApi.replaceStripeAccount({
        ...providerOrGroupPracticeId,
        ...variables,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(billingAccountQueryKey, data);
        onBillingAccountCreate(data, !!isGroupPracticeBillingAccount);
      },
    }
  );

  const [isUpdateStripeModalOpen, toggleUpdateStripeModalOpen] =
    useToggle(false);

  const showStripeIdentityNameSection =
    billingAccountInfo?.stripeAccount.status === 'verified' ||
    // Special W9 case: TODO remove once all providers have W9 in Stripe
    (billingAccountInfo?.stripeAccount.status === 'unverified' &&
      hasUnmetW9StripeRequirement(billingAccountInfo?.stripeAccount));

  const shouldShowGroupPracticeUpdateBillingInfoWarning =
    isGroupPracticeBillingAccount &&
    !alwaysUseGpBillingAccount &&
    (billingAccountInfo?.hasUnmetBankAccountRequirements ||
      billingAccountInfo?.hasUnmetStripeAccountRequirements);

  return (
    <PageSection data-testid="verification-section">
      <h2
        css={{
          display: 'flex',
        }}
      >
        <SectionHeader>
          {isGroupPracticeBillingAccount
            ? 'Group payment deposit account'
            : 'Payment deposit account'}
        </SectionHeader>
        {isGroupPracticeBillingAccount && (
          <span
            css={{
              marginLeft: theme.spacing.x5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Badge variant="neutral">Group admins only</Badge>
          </span>
        )}
      </h2>
      {shouldShowGroupPracticeUpdateBillingInfoWarning && (
        <GuidanceCard variant="warning">
          <BodyText>
            <strong>Update billing info by December 1, 2023:</strong> To prepare
            your 1099 for the 2023 tax year, we need you to:
            <ol
              css={{
                display: 'inline',
                listStyleType: 'decimal',
                listStylePosition: 'inside',
                textIndent: '0.5rem',
              }}
            >
              <li>First, re-verify your group practice </li>
              <li>Then, update your practice’s bank account</li>
            </ol>
            Headway requires that all payments for your group practice are
            deposited into one bank account. After you update your practice’s
            billing information, we will deposit all payments moving forward for
            your group practice into the saved bank account.
          </BodyText>
        </GuidanceCard>
      )}
      <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
        <section
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridTemplateRows: '1fr auto',
            gap: theme.spacing.x6,
          }}
        >
          <BillingAccountVerificationDescription
            billingAccountInfo={billingAccountInfo}
          />
          <div>
            {billingAccountInfo?.status !== 'success' ? (
              <Skeleton variant="rectangular" height={40} />
            ) : (
              <div
                css={{
                  ...theme.stack.vertical,
                  gap: theme.spacing.x4,
                  alignItems: 'end',
                }}
              >
                {showStripeIdentityNameSection && (
                  <div
                    css={{
                      alignSelf: 'stretch',
                      ...theme.stack.horizontal,
                      width: '100%',
                      justifyContent: 'space-between',
                      border: `1px solid ${theme.color.system.borderGray}`,
                      borderRadius: 2,
                      padding: theme.spacing.x2,
                    }}
                  >
                    <BodyText>
                      {billingAccountInfo.stripeAccount.businessProfile?.name ||
                        providerName}
                    </BodyText>
                    {/* TODO: Remove this once all providers have W9 in Stripe */}
                    {hasUnmetW9StripeRequirement(
                      billingAccountInfo.stripeAccount
                    ) ? (
                      <Badge variant="warning">Missing W-9</Badge>
                    ) : (
                      <Badge variant="positive">Verified</Badge>
                    )}
                  </div>
                )}

                <StripeOnboardingButtonsSection
                  billingAccountInfo={billingAccountInfo}
                />
                <Button
                  variant="link"
                  onPress={() => {
                    toggleUpdateStripeModalOpen();
                  }}
                >
                  Change account type
                </Button>
                <Modal
                  title="Account Type"
                  open={isUpdateStripeModalOpen}
                  onClose={toggleUpdateStripeModalOpen}
                >
                  <div css={{ ...theme.stack.vertical, gap: theme.spacing.x4 }}>
                    <GuidanceCard variant="warning">
                      <BodyText>
                        Please Note: Making changes to your Account Type will
                        require you to re-complete your identity verification
                        with our payment processor.
                      </BodyText>
                    </GuidanceCard>
                    <UpdateStripeAccountForm
                      initialValues={{
                        type: billingAccountInfo.stripeAccount.type,
                      }}
                      onSubmit={async (values) => {
                        try {
                          await replaceStripeAccountMutation.mutateAsync(
                            values
                          );

                          toggleUpdateStripeModalOpen();
                        } catch (err) {
                          notifyWarning(
                            `There was a problem changing your account type`
                          );
                          logException(err);
                        }
                      }}
                      onCancel={toggleUpdateStripeModalOpen}
                    />
                  </div>
                </Modal>
              </div>
            )}
          </div>
          <div
            css={{
              marginBottom: theme.spacing.x4,
              gridColumnEnd: 'span 2',
            }}
          >
            <b>You are categorized as:</b>
            <ul className="m-2">
              <CollapsibleDisclosure label="An Individual if...">
                <p css={collapsibleBody}>
                  you are a solo practitioner or own a Single Member LLC. You’ll
                  use your Social Security number to verify your identity. If
                  you are a sole proprietor and you have an EIN, you may enter
                  either your SSN or EIN when prompted for your SSN. If you own
                  a Single Member LLC that is disregarded you must enter your
                  SSN (see IRS Form W-9 Part 1 instructions for further
                  information).
                </p>
              </CollapsibleDisclosure>
            </ul>
            <ul className="m-2">
              <CollapsibleDisclosure label="A Company if...">
                <p css={collapsibleBody}>
                  your practice is a registered business entity (i.e.
                  Corporation, Partnership, or Multi-Member LLC.). Your Employer
                  ID Number (EIN) and information about your business will
                  verify your identity.
                </p>
              </CollapsibleDisclosure>
            </ul>
          </div>
        </section>

        {billingAccountInfo && (
          <BankAccountManagementSection
            providerId={providerId}
            billingAccountInfo={billingAccountInfo}
            isGroupPracticeBillingAccount={isGroupPracticeBillingAccount}
            previousBillingAccountInfo={previousBillingAccountInfo}
          />
        )}
      </div>
    </PageSection>
  );
};

const collapsibleBody = css`
  margin-top: ${theme.spacing.x2};
`;
