import React from 'react';

import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup, RadioGroupProps } from '@headway/helix/RadioGroup';

import { YesNo } from 'utils/providerQuestionnaire';

type YesNoQuestionProps = Pick<RadioGroupProps, 'name' | 'label'>;

export function YesNoQuestion({ name, label }: YesNoQuestionProps) {
  return (
    <FormControl name={name} label={label} component={RadioGroup}>
      <Radio value={YesNo.YES}>Yes</Radio>
      <Radio value={YesNo.NO}>No</Radio>
    </FormControl>
  );
}
