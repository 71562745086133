import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import moment from 'moment';
import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';

import { useProviderCorporateEntity } from 'hooks/useProviderCorporateEntity';

export interface InfoBoxProps {
  clientId: number;
}

const RowSection = ({ children }: { children: React.ReactNode }) => (
  <div css={rowSectionCss}>{children}</div>
);

const Column = ({ children }: { children: React.ReactNode }) => (
  <div css={columnCss}>{children}</div>
);

const ItemDefinition = ({
  item,
  definition,
}: {
  item: string;
  definition: string;
}) => (
  <div css={itemDefinitionCss}>
    <BodyText>
      <b>{item}</b>
    </BodyText>
    <div css={definitionCss}>
      <BodyText>{definition}</BodyText>
    </div>
  </div>
);

/** Info box used on treatment plan pages. */
export const InfoBox = ({ clientId }: InfoBoxProps) => {
  const { data: client, isLoading: clientIsLoading } = useUser({
    userId: clientId,
  });
  const provider = useProvider();
  const { data: corporateEntity, isLoading: corporateEntityIsLoading } =
    useProviderCorporateEntity({
      primaryStateId: provider.primaryStateId,
    });

  // Prefer pulling info from insurance if it exists, otherwise use client.
  const insurance = client?.activeUserInsurance;
  const clientName = insurance
    ? `${insurance.firstName} ${insurance.lastName}`
    : `${client?.firstName} ${client?.lastName}`;
  const clientDob = insurance?.dob || client?.dob;

  return (
    <section css={containerCss} data-testid="clientInfoBox">
      <RowSection>
        <Column>
          {!clientIsLoading && (
            <ItemDefinition item="Client" definition={clientName} />
          )}
        </Column>
        <Column>
          {!clientIsLoading && (
            <ItemDefinition
              item="Client DOB"
              definition={
                clientDob ? moment(clientDob).format('MM/DD/YYYY') : '—'
              }
            />
          )}
        </Column>
      </RowSection>
      <div css={lineDividerCss} />
      {!corporateEntityIsLoading && (
        <RowSection>
          <Column>
            <ItemDefinition
              item="Billing provider"
              definition={corporateEntity?.name ?? ''}
            />
          </Column>
          <RowSection>
            <Column>
              <ItemDefinition
                item="Provider"
                definition={`${provider.firstName} ${provider.lastName}`}
              />
              <ItemDefinition
                item="Billing provider NPI"
                definition={corporateEntity?.npi ?? ''}
              />
            </Column>
            <Column>
              <ItemDefinition
                item="Provider NPI"
                definition={provider.npi ?? ''}
              />
              <ItemDefinition
                item="Billing provider EIN"
                definition={corporateEntity?.taxId ?? ''}
              />
            </Column>
          </RowSection>
        </RowSection>
      )}
      <SubBodyText color="gray">
        This information is automatically included in your plan and can be
        edited separately in the client tab.
      </SubBodyText>
    </section>
  );
};

const containerCss = css({
  padding: `${theme.spacing.x5} ${theme.spacing.x4}`,
  borderRadius: '6px',
  border: `1px solid ${theme.color.system.borderGray}`,
  boxSizing: 'border-box',
});

const lineDividerCss = css({
  borderTop: `1px solid ${theme.color.system.borderGray}`,
  marginBottom: theme.spacing.x5,
});

const rowSectionCss = css({
  display: 'grid',
  gridTemplateColumns: '45% auto',
  gap: theme.spacing.x3,
  marginTop: 0,
  marginBottom: theme.spacing.x5,
  overflowX: 'auto',
});

const columnCss = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  float: 'left',
  gap: theme.spacing.x3,
});

const itemDefinitionCss = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: theme.spacing.x1,
});

const definitionCss = css({
  display: 'flex',
  flexDirection: 'column',
});
