import { keyframes } from '@emotion/react';
import React from 'react';

import { theme } from '@headway/helix/theme';

const dash = keyframes`
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 600;
  }
`;

interface LogoLoaderProps {
  size?: number | string;
  stroke?: string;
  strokeWidth?: number;
}

export const LogoLoader = ({
  size = 70,
  stroke = theme.color.primary.brandGreen,
  strokeWidth,
}: LogoLoaderProps) => (
  <svg width={size} height="auto" viewBox="-3 -3 110 85" fill="none">
    <path
      css={{
        strokeDasharray: 305,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        animation: `1.4s ease-in-out infinite ${dash}`,
      }}
      d="M103.7 52.3603C103.702 44.4817 101.89 36.7084 98.4062 29.642C94.9221 22.5756 89.8587 16.4057 83.6079 11.6098C77.3572 6.81391 70.0867 3.52066 62.3592 1.98495C54.6317 0.449242 46.6545 0.712262 39.0449 2.75365C31.4354 4.79505 24.3976 8.56006 18.4763 13.7573C12.5551 18.9545 7.90913 25.4446 4.89808 32.7251C1.88704 40.0056 0.591661 47.8814 1.11221 55.7428C1.63276 63.6042 3.95528 71.2404 7.90001 78.0603H96.77C101.314 70.2581 103.706 61.3894 103.7 52.3603V52.3603Z"
      stroke={stroke}
      strokeWidth={strokeWidth ?? '6'}
    />
  </svg>
);
