import React, { useId } from 'react';

import { EXTOLE_REFERRAL_PROGRAM } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import useScript from '@headway/shared/utils/useScript';

interface ExtoleUserData {
  email?: string;
  first_name?: string;
  last_name?: string;
  /**
   * The user's unique identifier in our system.  This is how Extole will relate their
   * user back to Headway's user.
   */
  partner_user_id: string;
}

function useExtole() {
  const isExtoleEnabled = useFlag(EXTOLE_REFERRAL_PROGRAM, false);

  const scriptStatus = useScript(
    isExtoleEnabled ? 'https://headway.extole.io/core.js' : '',
    {
      async: true,
      type: 'text/javascript',
    }
  );

  // @ts-expect-error
  return scriptStatus === 'ready' ? window.extole : null;
}

interface ExtoleOptions {
  name: string;
  element: HTMLElement;
  jwt?: string;
  data:
    | {
        container?: 'test';
      }
    | (ExtoleUserData & { container?: 'test' });
}
/**
 * Returns a callback that can be used to track a lead form conversion in Extole.
 */
function useExtoleLeadFormConversion(): (data: ExtoleOptions['data']) => void {
  const extole = useExtole();

  return React.useCallback(
    (data) => {
      if (!extole) {
        return;
      }

      if (
        (process.env.REACT_APP_ENVIRONMENT ?? process.env.ENVIRONMENT) !==
        'production'
      ) {
        data.container = 'test';
      }
      extole.createZone({
        name: 'lead_form_submitted',
        data: data,
      });
    },
    [extole]
  );
}

/**
 * Returns a ref that can be attached to an element that you'd like to embed an Extole zone in.
 * If a token is provided, it will be used to authenticate the user with Extole.
 */
function useExtoleEmbed(
  token: string | undefined
): React.RefObject<HTMLDivElement> {
  const extoleZoneRef = React.useRef<HTMLDivElement | null>(null);

  const extole = useExtole();

  React.useEffect(() => {
    if (!extole || !extoleZoneRef.current) {
      return;
    }

    (function (c, b, f, k, a) {
      // @ts-expect-error
      c[b] = c[b] || {};
      // @ts-expect-error
      for (c[b].q = c[b].q || []; a < k.length; ) f(k[a++], c[b]);
    })(
      window,
      'extole',
      // @ts-expect-error
      function (c, b) {
        b[c] =
          b[c] ||
          function () {
            b.q.push([c, arguments]);
          };
      },
      ['createZone'],
      0
    );

    const opts: ExtoleOptions = {
      name: 'embedded_microsite',
      element: extoleZoneRef.current,
      jwt: token,
      data: {},
    };

    if (
      (process.env.REACT_APP_ENVIRONMENT ?? process.env.ENVIRONMENT) !==
      'production'
    ) {
      opts.data.container = 'test';
    }

    extole.createZone(opts);
  }, [extole]);

  return extoleZoneRef;
}

export { useExtoleEmbed, useExtoleLeadFormConversion };
