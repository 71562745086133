import React from 'react';

export function IconCreditCard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66797 16.6666V3.33325H18.3346V16.6666H1.66797ZM2.91797 6.85409H17.0846V4.58325H2.91797V6.85409ZM2.91797 9.54158V15.4166H17.0846V9.54158H2.91797ZM2.91797 15.4166V4.58325V15.4166Z"
        fill="currentColor"
      />
    </svg>
  );
}
