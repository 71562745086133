import _ from 'lodash';

import { Practice } from '@headway/api/models/Practice';
import { ProviderPanelabilityEvaluation } from '@headway/api/models/ProviderPanelabilityEvaluation';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface QueryKeyArgs {
  providerQuestionnaire: ProviderQuestionnaireReadV2;
}
export const getProviderPanelabilityFromQuestionnaireKey = ({
  providerQuestionnaire,
}: QueryKeyArgs): string[] => {
  const key: string[] = [];
  if (!providerQuestionnaire || !providerQuestionnaire.rawData) {
    return [];
  }
  key
    .concat(
      providerQuestionnaire.rawData.practice?.map(
        (practice: Practice) => practice.state + practice.zip + practice.county
      ) ?? ''
    )
    .concat(providerQuestionnaire.rawData.state ?? '')
    .concat(providerQuestionnaire.rawData.zip ?? '')
    .concat(providerQuestionnaire.providerId?.toString() ?? '')
    .concat(
      providerQuestionnaire.rawData.previouslyInNetworkCarrierIds?.map(
        (carrier) => carrier.toString()
      ) ?? ''
    )
    .concat(
      providerQuestionnaire.rawData.licenses?.map(
        (license) => license.licenseType
      ) ?? ''
    );
  return _.compact(key);
};

const { useSingleQuery: useProviderPanelabilityEvaluation } =
  createBasicApiHooks(
    getProviderPanelabilityFromQuestionnaireKey,
    async ({
      providerQuestionnaire,
    }): Promise<ProviderPanelabilityEvaluation | null> => {
      return await ProviderQuestionnaireApi.getProviderPanelabilityByQuestionnaireId(
        providerQuestionnaire.id
      );
    },
    ({ providerQuestionnaire }) => {
      return !!providerQuestionnaire;
    },
    () => 'Failed to fetch Panelability evaluation'
  );

export { useProviderPanelabilityEvaluation };
