import styled from '@emotion/styled';
import React from 'react';

import { theme } from './theme';

export const Banner = styled.div(
  {
    fontFamily: theme.fontFamily.postGrotesk,
    fontSize: theme.fontSize.base,
    textAlign: 'center',
    padding: theme.space.base,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1000,
  },
  (props: any) => ({
    background: props.background || theme.color.primaryBackground,
    color: props.color || theme.color.primaryDark,
    cursor: props.onClick ? 'pointer' : 'initial',
  })
);
