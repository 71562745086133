import React from 'react';

import { Checkbox as HelixCheckbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';

import { SchemaComponent } from '../../schema/schema.types';
import { getValue } from '../../view/utils';
import { ViewComponent } from './ViewComponent';

const MemoizedCheckbox = React.memo(HelixCheckbox);

export const Checkbox = ({
  element,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title } = element;

  if (readOnly) {
    const value = getValue(id);
    return <ViewComponent title={title} value={value} />;
  }

  return (
    <FormControl component={MemoizedCheckbox} name={id} disabled={disabled}>
      {title}
    </FormControl>
  );
};
