import React from 'react';

import { Banner } from '@headway/helix/Banner';

import { useProvider } from 'hooks/useProvider';

export const GroupPracticeAdminBanner = () => {
  const provider = useProvider();

  return (
    <Banner variant="neutral">
      <b>Group admin view:</b> Only {provider.displayName}, the provider present
      at the client's sessions, can write a new progress note. That said, group
      admins may still upload an existing note or add addendum files to uploaded
      notes.
    </Banner>
  );
};
