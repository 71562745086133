import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';
import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import { UserPaymentMethodApi } from '@headway/api/resources/UserPaymentMethodApi';
import { getErrorMessage } from '@headway/shared/utils/error';
import { logException } from '@headway/shared/utils/sentry';
import { Button, CardElementWithError } from '@headway/ui';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_ID!);

interface PatientPaymentFormWithStripeProps {
  patient: UserRead;
  onComplete: (updatedUser: UserRead, newPaymentMethod: any) => void;
  providerId: number;
  paymentMethods?: UserPaymentMethodRead[];
}

export const PatientPaymentFormWithStripe: React.FC<
  React.PropsWithChildren<PatientPaymentFormWithStripeProps>
> = ({ patient, onComplete, providerId, paymentMethods }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = React.useState<string>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const sendPatientPaymentAddedEmail = async (userPaymentMethodId: number) => {
    try {
      await UserPaymentMethodApi.sendUserPaymentMethodAddedEmail(
        userPaymentMethodId,
        providerId
      );
    } catch (error) {
      logException(error);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      try {
        const { error, token } = await stripe.createToken(cardElement, {});
        if (error) {
          setStripeError(error.message);
          throw error;
        }
        if (patient.defaultUserPaymentMethodId) {
          // delete the current payment method
          await UserPaymentMethodApi.deleteUserPaymentMethod(
            patient.defaultUserPaymentMethodId
          );
        }
        const userPaymentMethod =
          await UserPaymentMethodApi.createUserPaymentMethod({
            userId: patient.id,
            type: UserPaymentMethodType.CARD,
            card: {
              source: token?.id,
            },
          });
        const updatedPatientUser = await UserApi.updateUser(patient.id, {
          defaultUserPaymentMethodId: userPaymentMethod.id,
        });
        await sendPatientPaymentAddedEmail(userPaymentMethod.id);

        onComplete(updatedPatientUser, userPaymentMethod);
        setIsSubmitting(false);
      } catch (error: AnyTS4TryCatchUnknownError) {
        notifyError(getErrorMessage(error, 'Failed to update the payment.'));
        logException(error);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <div
        css={{
          padding: `${theme.space.xl} 0`,
        }}
        data-testid="patientCreditCardInput"
      >
        <CardElementWithError
          stripeError={stripeError}
          setStripeError={setStripeError}
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting || !stripe}
          type="submit"
          onClick={handleSubmit}
          data-testid="savePatientCreditCardButton"
        >
          {isSubmitting
            ? 'Saving and verifying...'
            : !!paymentMethods && paymentMethods.length > 0
            ? 'Update'
            : 'Add'}
        </Button>
      </div>
    </>
  );
};

export const PatientPaymentForm: React.FC<
  React.PropsWithChildren<PatientPaymentFormWithStripeProps>
> = (props) => (
  <Elements stripe={stripePromise}>
    <PatientPaymentFormWithStripe {...props} />
  </Elements>
);
