import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

import { LinkButton } from '@headway/helix/LinkButton';

import { Task } from './Task';

interface BillingTaskProps {
  stripeOnboardingLink: string | undefined;
}

export const BillingTask = ({ stripeOnboardingLink }: BillingTaskProps) => {
  // TODO Group Admins- Task will have require group admins to go to the billing page to re-complete their Stripe verification + Bank Account

  return (
    <>
      <Task
        icon={<ErrorOutlineIcon />}
        listHeaderText={<>Complete your practice’s W-9 form</>}
        subBodyText="We’ll use this information to prepare your 1099 for the 2023 tax year. This will take you 5 minutes or less."
        secondaryAction={
          <LinkButton
            href={`/settings/billing?product_tour_id=334103`}
            variant="secondary"
            size="medium"
          >
            Learn more
          </LinkButton>
        }
        primaryAction={
          <LinkButton
            href={stripeOnboardingLink}
            variant="primary"
            size="medium"
          >
            Complete on Stripe↗
          </LinkButton>
        }
      />
    </>
  );
};
