import { css } from '@emotion/react';
import { Formik } from 'formik';
import { sortBy } from 'lodash';
import React, { useContext } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { UserRead } from '@headway/api/models/UserRead';
import { Banner, BannerProps } from '@headway/helix/Banner';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import { MM_TREATMENT_PLAN } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useProviderTreatmentPlanAttachments } from 'hooks/useProviderTreatmentPlanAttachments';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import { StepPreview } from 'views/Clients/TreatmentPlan/Components/StepPreview/StepPreview';
import { downloadAttachment } from 'views/Clients/TreatmentPlan/Components/TreatmentPlanPreview';
import { PlanJson } from 'views/Clients/TreatmentPlan/Template/types';
import {
  TreatmentPlanContext,
  TreatmentPlanContextProvider,
} from 'views/Clients/TreatmentPlan/TreatmentPlanContext';
import { isTreatmentPlanExpired } from 'views/Clients/TreatmentPlan/TreatmentPlanUtils';
import { Attachment } from 'views/Patients/AttachmentsList';
import { AttachmentsListHelixList } from 'views/Patients/AttachmentsList';

import { TemplateAnalyticsNames } from '../ProgressNotesForm';

export interface InlineTreatmentPlanProps {
  patient: UserRead;
  providerPatient: ProviderPatientRead;
  sendTemplateAnalytics: (trackingEventName: TemplateAnalyticsNames) => void;
}

const TreatmentPlanAttachmentsPreview = ({
  treatmentPlan,
}: {
  treatmentPlan: ProviderTreatmentPlanRead;
}) => {
  const treatmentPlansAttachmentsQuery = useProviderTreatmentPlanAttachments({
    treatmentPlanId: treatmentPlan.id,
  });

  const treatmentPlanAttachments =
    treatmentPlansAttachmentsQuery.data ??
    ([] as Attachment<number | string>[]);

  if (treatmentPlanAttachments.length === 0) {
    return null;
  }

  return (
    <div css={helixAttachmentsCss.container}>
      <AttachmentsListHelixList
        attachments={treatmentPlanAttachments}
        onDownloadAttachment={downloadAttachment}
      />
    </div>
  );
};

const TreatmentPlanPreviewImpl = ({
  treatmentPlan,
}: {
  treatmentPlan: ProviderTreatmentPlanRead;
}) => {
  const { steps, initializeTemplateForm, initialValues } =
    useContext(TreatmentPlanContext);

  if (treatmentPlan.planType === TreatmentPlanType.TEXT) {
    const planJson = treatmentPlan.planJson as PlanJson;
    return (
      <div
        className="ph-no-capture"
        css={staticDisplayCss}
        dangerouslySetInnerHTML={{
          __html: sanitize(planJson.text || ''),
        }}
      />
    );
  } else if (treatmentPlan.planType === TreatmentPlanType.UPLOAD) {
    return <TreatmentPlanAttachmentsPreview treatmentPlan={treatmentPlan} />;
  } else if (treatmentPlan.planType === TreatmentPlanType.TEMPLATE) {
    initializeTemplateForm(
      (treatmentPlan.planJson as { template: object }).template
    );
  }

  if (!steps) {
    return null;
  }

  const goalStep = steps.find((step) => step.title === 'Goals');

  if (!goalStep) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {}}
    >
      <StepPreview
        alert
        displayErrors={false}
        step={goalStep}
        variant="large"
      />
    </Formik>
  );
};

export const TreatmentPlanPreview = ({
  treatmentPlan,
  providerPatient,
}: {
  treatmentPlan: ProviderTreatmentPlanRead;
  providerPatient: ProviderPatientRead;
}) => {
  return (
    <TreatmentPlanContextProvider providerPatient={providerPatient}>
      <div css={{ marginTop: theme.spacing.x4 }}>
        <TreatmentPlanPreviewImpl treatmentPlan={treatmentPlan} />
      </div>
    </TreatmentPlanContextProvider>
  );
};

export const TreatmentPlanBanner = ({
  text,
  linkText,
  link,
  variant,
  analyticsName,
  sendTemplateAnalytics,
  showInfoBanner = true,
}: {
  text?: string;
  linkText?: string;
  link?: string;
  variant?: BannerProps['variant'];
  analyticsName?: TemplateAnalyticsNames;
  sendTemplateAnalytics: InlineTreatmentPlanProps['sendTemplateAnalytics'];
  showInfoBanner?: boolean;
}) => (
  <>
    {showInfoBanner && (
      <Banner variant="neutral">
        The objectives section from your latest signed treatment plan is
        displayed below as a reference—it will not show up on your signed note.
        You can copy and paste any objectives that you discussed in the session,
        then comment on them in your note.
      </Banner>
    )}
    {text && linkText && link && (
      <div css={{ marginTop: theme.spacing.x4 }}>
        <Banner variant={variant ? variant : 'info'}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing.x4,
              alignItems: 'center',
            }}
          >
            <span>{text}</span>
            <LinkButton
              variant="link"
              size="large"
              target="_blank"
              href={link}
              onPress={() => {
                if (analyticsName) {
                  sendTemplateAnalytics(analyticsName);
                }
              }}
            >
              {linkText}
            </LinkButton>
          </div>
        </Banner>
      </div>
    )}
  </>
);

export const DisplayTreatmentPlanWithBanners = ({
  providerPatient,
  patient,
  sendTemplateAnalytics,
}: InlineTreatmentPlanProps) => {
  const isTreatmentPlanRequirementEnabled = useFlag(MM_TREATMENT_PLAN, false);
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });

  const sortedTreatmentPlans = sortBy(treatmentPlans, 'updatedOn').reverse();
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const noTreatmentPlans =
    !sortedTreatmentPlans || sortedTreatmentPlans.length === 0;

  // No treatment plans at all
  if (noTreatmentPlans) {
    return (
      <TreatmentPlanBanner
        sendTemplateAnalytics={sendTemplateAnalytics}
        analyticsName={
          'Create Treatment Plan or Upload Existing Button Clicked'
        }
        text={`No treatment plan for ${patientFirstName} available`}
        linkText="Create a treatment plan or upload existing"
        link={`/clients/${patient.id}/clinical?treatmentPlan=true`}
        showInfoBanner={false}
      />
    );
  }

  const isMostRecentTreatmentPlanADraft = !sortedTreatmentPlans[0].attestedOn;
  const mostRecentAttestedTreatmentPlan = sortedTreatmentPlans?.find(
    (plan) => plan.attestedOn
  );
  const isMostRecentSignedTreatmentPlanExpired = mostRecentAttestedTreatmentPlan
    ? isTreatmentPlanExpired(
        sortedTreatmentPlans.filter((plan) => plan.attestedOn)[0],
        isTreatmentPlanRequirementEnabled
      )
    : false;

  if (isMostRecentTreatmentPlanADraft) {
    const recentDraftTreatmentPlan = sortedTreatmentPlans[0];
    if (!mostRecentAttestedTreatmentPlan) {
      // only has a draft template
      return (
        <TreatmentPlanBanner
          sendTemplateAnalytics={sendTemplateAnalytics}
          analyticsName={'Complete and Sign Draft Button Clicked'}
          text="No signed treatment plan available. You have a draft of a plan that hasn’t been signed."
          linkText="Complete and sign draft"
          link={`/clients/${patient.id}/clinical?treatmentPlan=true&treatmentPlanId=${recentDraftTreatmentPlan.id}`}
        />
      );
    } else {
      // is a draft
      // most recent treatment plan is expired
      if (isMostRecentSignedTreatmentPlanExpired) {
        return (
          <TreatmentPlanBanner
            sendTemplateAnalytics={sendTemplateAnalytics}
            text={`Your most recent signed plan for ${patientFirstName} is more than 6 months old. You have a draft of a plan that hasn’t been signed.`}
            linkText="Complete and sign draft"
            link={`/clients/${patient.id}/clinical?treatmentPlan=true&treatmentPlanId=${recentDraftTreatmentPlan.id}`}
          />
        );
      } else {
        // has a recent draft
        // has a active treatment plan
        return (
          <>
            <TreatmentPlanBanner
              sendTemplateAnalytics={sendTemplateAnalytics}
              text="You have a draft of a more recent plan that hasn’t been signed yet."
              linkText="Go to most recent draft"
              link={`/clients/${patient.id}/clinical?treatmentPlan=true&treatmentPlanId=${recentDraftTreatmentPlan.id}`}
              variant="warning"
            />
            <TreatmentPlanPreview
              treatmentPlan={mostRecentAttestedTreatmentPlan}
              providerPatient={providerPatient}
            />
          </>
        );
      }
    }
  } else {
    // has a most recent signed note
    // it is expired
    if (isMostRecentSignedTreatmentPlanExpired) {
      return (
        <TreatmentPlanBanner
          sendTemplateAnalytics={sendTemplateAnalytics}
          analyticsName={'Add or Update Treatment Plan Button Clicked'}
          text={`Your treatment plan for ${patientFirstName} is more than 6 months old. Insurers typically look for updates every 3-6 months.`}
          linkText="Add or update plan"
          link={`/clients/${patient.id}/clinical?treatmentPlan=true`}
        />
      );
    } else {
      // recent treatmentPlan is signed
      return (
        <>
          <TreatmentPlanBanner sendTemplateAnalytics={sendTemplateAnalytics} />
          <TreatmentPlanPreview
            treatmentPlan={sortedTreatmentPlans[0]}
            providerPatient={providerPatient}
          />
        </>
      );
    }
  }
};

export const InlineTreatmentPlan = ({
  patient,
  providerPatient,
  sendTemplateAnalytics,
}: InlineTreatmentPlanProps) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  return (
    <CollapsibleDisclosure
      expanded={isOpened}
      label={`${
        isOpened ? `Hide` : `Show`
      } ${patientFirstName}'s treatment plan`}
      onToggle={(value) => {
        setIsOpened(value);
        if (value) {
          sendTemplateAnalytics('Show Treatment Plan Button Clicked');
        }
      }}
    >
      <div css={{ marginTop: theme.spacing.x4 }}>
        <DisplayTreatmentPlanWithBanners
          patient={patient}
          providerPatient={providerPatient}
          sendTemplateAnalytics={sendTemplateAnalytics}
        />
      </div>
    </CollapsibleDisclosure>
  );
};

const helixAttachmentsCss = {
  container: css({
    borderRadius: theme.spacing.x1,
    border: `1px solid ${theme.color.system.borderGray}`,
    padding: theme.spacing.x2,
    width: '100%',
  }),
};

const staticDisplayCss = css({
  maxHeight: 'unset',
  overflowY: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 'unset',
  ...theme.typography.body.regular,
  ' p': {
    margin: 0,
    marginTop: theme.spacing.x3,
    ...theme.typography.body.regular,
  },
  marginTop: theme.spacing.x3,
});
