import moment from 'moment';
import React from 'react';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { theme } from '@headway/helix/theme';

import { getDisplayYesNo, YesNo } from 'utils/providerQuestionnaire';
import {
  CaqhKeyValuePair,
  CaqhSection,
  CaqhSubsection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';

export const BoardCertificationHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  return (
    <CaqhSection>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x5,
        }}
      >
        <CaqhSubsection label={'Past exams'}>
          <CaqhKeyValuePair
            label="Have you ever failed to pass a specialty board examination?"
            value={getDisplayYesNo(initialValues.hasFailedBoardCertification)}
          />
          {initialValues.hasFailedBoardCertification === YesNo.YES && (
            <>
              <CaqhKeyValuePair
                label="Certifying Board"
                value={initialValues?.certifyingBoardName}
              />
              <CaqhKeyValuePair
                label="Board Exam Date"
                value={initialValues?.failedExamDate}
              />
              <CaqhKeyValuePair
                label="Reason for Failure"
                value={initialValues?.failedExamReason}
              />
            </>
          )}
        </CaqhSubsection>

        {initialValues.isBoardCertified === YesNo.YES && (
          <>
            <CaqhSubsection label={'Certification'}>
              <CaqhKeyValuePair
                label="Certifying Board"
                value={initialValues?.certifyingBoardName}
              />
              <CaqhKeyValuePair
                label="Issue Date"
                value={moment(initialValues?.boardIssueDate).format(
                  'MM/DD/YYYY'
                )}
              />
              <CaqhKeyValuePair
                label="Expiration Date"
                value={
                  !!initialValues?.boardExpirationDate
                    ? moment(initialValues?.boardExpirationDate).format(
                        'MM/DD/YYYY'
                      )
                    : 'None'
                }
              />
              <CaqhKeyValuePair
                label="Specialty"
                value={initialValues?.boardSpecialty}
              />
            </CaqhSubsection>
          </>
        )}
      </div>
    </CaqhSection>
  );
};
