import React from 'react';

import { theme } from '@headway/ui/theme';

interface EmptyViewProps {
  title: string;
  description?: string | React.ReactNode;
  action?: React.ReactNode;
}

export const EmptyView: React.FC<EmptyViewProps> = ({
  title,
  description,
  action,
}) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
      height: '450px',
    }}
  >
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
      }}
    >
      <h3>{title}</h3>
      {description ? (
        <p
          css={{
            fontFamily: theme.fontFamily.postGrotesk,
            fontSize: theme.fontSize.base,
            marginBottom: theme.space.xl,
            textAlign: 'center',
          }}
        >
          {description}
        </p>
      ) : null}
      {action}
    </div>
  </div>
);
