import * as React from 'react';

export const Notes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.75 13.2499C12.75 13.4488 12.671 13.6395 12.5303 13.7802C12.3897 13.9208 12.1989 13.9999 12 13.9999H6C5.80109 13.9999 5.61032 13.9208 5.46967 13.7802C5.32902 13.6395 5.25 13.4488 5.25 13.2499C5.25 13.051 5.32902 12.8602 5.46967 12.7195C5.61032 12.5789 5.80109 12.4999 6 12.4999H12C12.1989 12.4999 12.3897 12.5789 12.5303 12.7195C12.671 12.8602 12.75 13.051 12.75 13.2499ZM12 9.49986H6C5.80109 9.49986 5.61032 9.57888 5.46967 9.71953C5.32902 9.86019 5.25 10.051 5.25 10.2499C5.25 10.4488 5.32902 10.6395 5.46967 10.7802C5.61032 10.9208 5.80109 10.9999 6 10.9999H12C12.1989 10.9999 12.3897 10.9208 12.5303 10.7802C12.671 10.6395 12.75 10.4488 12.75 10.2499C12.75 10.051 12.671 9.86019 12.5303 9.71953C12.3897 9.57888 12.1989 9.49986 12 9.49986ZM17.25 3.49986V19.2499C17.25 19.6477 17.092 20.0292 16.8107 20.3105C16.5294 20.5918 16.1478 20.7499 15.75 20.7499H2.25C1.85218 20.7499 1.47064 20.5918 1.18934 20.3105C0.908035 20.0292 0.75 19.6477 0.75 19.2499V3.49986C0.75 3.10204 0.908035 2.72051 1.18934 2.4392C1.47064 2.1579 1.85218 1.99986 2.25 1.99986H5.64937C6.07079 1.52804 6.58709 1.15054 7.1645 0.892075C7.7419 0.633607 8.36738 0.5 9 0.5C9.63262 0.5 10.2581 0.633607 10.8355 0.892075C11.4129 1.15054 11.9292 1.52804 12.3506 1.99986H15.75C16.1478 1.99986 16.5294 2.1579 16.8107 2.4392C17.092 2.72051 17.25 3.10204 17.25 3.49986ZM6 4.99986H12C12 4.20421 11.6839 3.44115 11.1213 2.87854C10.5587 2.31593 9.79565 1.99986 9 1.99986C8.20435 1.99986 7.44129 2.31593 6.87868 2.87854C6.31607 3.44115 6 4.20421 6 4.99986ZM15.75 3.49986H13.2422C13.4128 3.98157 13.5 4.48884 13.5 4.99986V5.74986C13.5 5.94878 13.421 6.13954 13.2803 6.28019C13.1397 6.42085 12.9489 6.49986 12.75 6.49986H5.25C5.05109 6.49986 4.86032 6.42085 4.71967 6.28019C4.57902 6.13954 4.5 5.94878 4.5 5.74986V4.99986C4.50002 4.48884 4.58721 3.98157 4.75781 3.49986H2.25V19.2499H15.75V3.49986Z"
      fill="#2C98EF"
    />
  </svg>
);
