import { MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';

import { FieldControlContext, FieldSelect } from '@headway/ui/form';
import { theme } from '@headway/ui/theme';

export function generateFiveMinuteIntervals(date) {
  // there are 288 five minute periods in a day
  return Array.from(Array(288)).map((_, index) =>
    moment(date)
      .startOf('day')
      .add(index * 5, 'minutes')
      .toDate()
  );
}
export const FieldFiveMinuteIntervalSelect = ({ ...props }) => {
  const { name } = React.useContext(FieldControlContext);
  const { values } = useFormikContext();
  const date = values[name];

  const items = React.useMemo(
    () =>
      generateFiveMinuteIntervals(date).map((interval) => {
        const label = moment(interval).format('h:mma');
        return (
          <MenuItem
            key={interval.toISOString()}
            value={interval.toISOString()}
            css={
              interval.getMinutes() === 0
                ? {
                    fontWeight: 'bold',
                    background: theme.color.background,
                    borderTop: `1px solid ${theme.color.border}`,
                    borderBottom: `1px solid ${theme.color.border}`,
                  }
                : {}
            }
            data-testid={`fiveMinuteIntervalOption-${label}`}
          >
            {label}
          </MenuItem>
        );
      }),
    [date]
  );

  return (
    <FieldSelect
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 700,
          },
        },
      }}
      {...props}
    >
      {items}
    </FieldSelect>
  );
};
