import moment from 'moment';

import { IroncladAgreementInfo } from '@headway/api/models/IroncladAgreementInfo';
import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderLicenseStateApi } from '@headway/api/resources/ProviderLicenseStateApi';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMutation } from '@headway/shared/react-query';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { hasRateAccess } from 'utils/access';

import { getSignerId } from '../../../utils/ironcladAgreement';
import { IroncladAgreementStatus } from '../types/IroncladAgreementStatus';
import {
  AMENDMENT_DATE_OF_NOTICE,
  NUMBER_OF_DAYS_TO_BLOCKED,
  NUMBER_OF_DAYS_TO_TERMINATED,
} from './constants';

const today = moment();

export type StateAddendumContractIds = { [key in UnitedStates]: number };

export const isNonAdminGroupPracticeProvider = (
  provider: ProviderRead,
  user: UserRead
) =>
  provider &&
  !hasRateAccess(provider, user) &&
  provider.groupPracticeId !== null;

export const isGroupPracticeProviderAfter30Days = (
  provider: ProviderRead,
  user: UserRead
) => {
  return (
    provider &&
    !hasRateAccess(provider, user) &&
    provider.groupPracticeId !== null &&
    today >
      moment(AMENDMENT_DATE_OF_NOTICE).add(NUMBER_OF_DAYS_TO_BLOCKED, 'days')
  );
};

export const isGroupPracticeProviderBefore30Days = (
  provider: ProviderRead,
  user: UserRead
) => {
  return (
    provider &&
    !hasRateAccess(provider, user) &&
    provider.groupPracticeId !== null &&
    today <
      moment(AMENDMENT_DATE_OF_NOTICE).add(NUMBER_OF_DAYS_TO_BLOCKED, 'days')
  );
};
export const getIroncladSignerId = (user: any) => {
  const isGroupPracticeAdmin = !!user.group_practice;

  return isGroupPracticeAdmin ? `group-${user.group_practice.id}` : user?.id;
};

export const calculateDaysRemaining = (
  daysFromNotice:
    | typeof NUMBER_OF_DAYS_TO_BLOCKED
    | typeof NUMBER_OF_DAYS_TO_TERMINATED
) => {
  const daysRemaining = moment(AMENDMENT_DATE_OF_NOTICE)
    .add(daysFromNotice, 'days')
    .diff(today, 'days');

  if (daysRemaining >= 0) return daysRemaining;
  else return 0;
};

export const fetchIroncladGroupID = async (groupKey?: string) => {
  //groupID should be null for non-MSC beta providers
  if (!groupKey) return '';

  const res = await fetch(
    `https://pactsafe.io/load/json?sid=${process.env.REACT_APP_IRONCLAD_SITE_ID}&gkey=${groupKey}`,
    {
      method: 'GET',
      headers: { accept: 'application/json' },
    }
  );

  if (res.status !== 200) {
    throw new Error(`${res.status}: ${res.statusText}`);
  }

  const resJson = await res.json();
  return resJson.group;
};

export const getContractIds = (
  provider: ProviderRead,
  stateswithAcceptedAgreements: StateAddendumContractIds | null,
  isAdminAcceptingNewStateAddendums?: boolean,
  ironcladAgreementInfo?: IroncladAgreementInfo | null
): { [key in string]: number } | {} => {
  if (
    !ironcladAgreementInfo ||
    !ironcladAgreementInfo.mainContractId ||
    !ironcladAgreementInfo.stateAddendumContractIds
  )
    return {};

  const { mainContractId, stateAddendumContractIds } = ironcladAgreementInfo;

  if (isAdminAcceptingNewStateAddendums && stateswithAcceptedAgreements) {
    const activeProviderLicenseStates = getSupportedStates(provider);
    // Fetch contract ids for the states that haven't yet been accepted for the current provider
    const currentProviderStatesPendingAgreement = Object.keys(
      stateAddendumContractIds
    )
      .filter((state) =>
        activeProviderLicenseStates.includes(state as UnitedStates)
      )
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: stateAddendumContractIds[key],
        };
      }, {});

    return {
      mainContractId,
      ...stateswithAcceptedAgreements,
      ...currentProviderStatesPendingAgreement,
    };
  }

  return { mainContractId, ...stateAddendumContractIds };
};

// Fetch version IDs based on contract ID for MSC beta providers and group key for non MSC beta providers
const fetchLatestAmendmentVersionIds = async (
  groupKey?: string,
  contractIds?: number[]
): Promise<any> => {
  const queryParams = groupKey
    ? `&gkey=${groupKey}`
    : contractIds?.length
    ? `&cid=${encodeURIComponent(contractIds.join(','))}`
    : null;

  if (!queryParams) return;

  const res = await fetch(
    `https://pactsafe.io/published?sid=${process.env.REACT_APP_IRONCLAD_SITE_ID}${queryParams}`,
    {
      method: 'GET',
      headers: { accept: 'application/json' },
    }
  );

  if (res.status !== 200) {
    throw new Error(`${res.status}: ${res.statusText}`);
  }

  const latestVersionIdsJSON = await res.json();
  const latestVersionIds = Object.values(latestVersionIdsJSON);

  return latestVersionIds;
};

const getSendIroncladResponseQueryParams = async (
  contractIds?: number[] | null,
  groupKey?: string | null
) => {
  if (contractIds) {
    const versionIds: string[] = await fetchLatestAmendmentVersionIds(
      undefined,
      contractIds
    );

    return `&cid=${encodeURIComponent(
      contractIds?.join(',')
    )}&vid=${encodeURIComponent(versionIds.join(','))}`;
  }

  if (groupKey) {
    const ironcladGroupID = await fetchIroncladGroupID(groupKey);
    const versionIds = await fetchLatestAmendmentVersionIds(
      groupKey,
      undefined
    );

    return `&gid=${ironcladGroupID}&vid=${encodeURIComponent(
      versionIds.join(',')
    )}`;
  }

  return;
};

export const isGPPendingInitialContractAcceptance = (
  provider: ProviderRead
) => {
  return (
    !!provider.groupPracticeId && !provider.groupPractice?.contractSignedOn
  );
};

export const isGPAddingNewState = (
  provider: ProviderRead,
  statesPendingAgreement: StateAddendumContractIds | null
) => {
  const pendingStates = statesPendingAgreement
    ? Object.keys(statesPendingAgreement)
    : [];

  return (
    !!provider.groupPracticeId &&
    provider.groupPractice?.contractSignedOn &&
    !!pendingStates?.length
  );
};

export const getProviderLicenseStatesPendingAgreement = (
  provider: ProviderRead,
  statesPendingAgreement: StateAddendumContractIds | null
) => {
  return statesPendingAgreement
    ? provider.activeProviderLicenseStates.filter(
        (pls) =>
          !pls.liveOn &&
          Object.keys(statesPendingAgreement)?.includes(pls.state)
      )
    : [];
};

export const sendIroncladResponse = async (
  ironcladAgreementInfo: IroncladAgreementInfo | undefined | null,
  ironcladAgreementStatus: IroncladAgreementStatus,
  provider: ProviderRead,
  user: any,
  isMSCBetaEnabled: boolean,
  contractIds?: number[]
) => {
  if (!ironcladAgreementInfo) return;

  const { groupKey } = ironcladAgreementInfo;

  // Fetch query params based on contract ID for MSC providers and group key for non MSC providers
  let queryParams;
  if (isMSCBetaEnabled && contractIds) {
    queryParams = await getSendIroncladResponseQueryParams(contractIds);
  } else if (groupKey) {
    queryParams = await getSendIroncladResponseQueryParams(null, groupKey);
  }

  if (!queryParams) return;

  const isGroupPractice =
    !!provider.groupPracticeId && !!provider.groupPractice;
  const hasProviderPreviouslySignedContract = () =>
    provider.activeProviderLicenseStates.some((pls) => !!pls.contractSignedOn);

  const isAmendment = isGroupPractice
    ? !!provider.groupPractice?.contractSignedOn
    : hasProviderPreviouslySignedContract();
  const customData = {
    customer_id: `user_id-${user.id}`,
  };

  // Only response to Ironclad if accepting amendment or if accepting agreement via Registration page when MSC beta flag is on
  if (isAmendment || isMSCBetaEnabled) {
    const res = await fetch(
      `https://pactsafe.io/send?sid=${
        process.env.REACT_APP_IRONCLAD_SITE_ID
      }&sig=${getSignerId(
        user,
        isGroupPractice
      )}${queryParams}&et=${ironcladAgreementStatus}&cus=${encodeURIComponent(
        JSON.stringify(customData)
      )}`,
      {
        method: 'POST',
        headers: { accept: 'application/json' },
      }
    );

    if (res.status !== 200) {
      throw new Error(`${res.status}: ${res.statusText}`);
    }
  }

  if (ironcladAgreementStatus === IroncladAgreementStatus.AGREED) {
    // Only send acceptance email if provider is accepting agreement via amendments flow
    if (isAmendment) {
      await ProviderApi.sendAgreementAcceptanceConfirmationEmail(provider.id);
    }

    if (isGroupPractice) {
      await GroupPracticeApi.patchGroupPractice(user.group_practice.id, {
        contractSignedOn: moment().format(),
      });
    } else {
      const plsUpdates = provider.activeProviderLicenseStates.map(
        async (providerLicenseState: ProviderLicenseStateRead) =>
          await ProviderLicenseStateApi.updateProviderLicenseState(
            providerLicenseState.id,
            {
              contractSignedOn: moment().format(),
              hasCustomContract: false,
            }
          )
      );

      await Promise.all(plsUpdates);
    }
  } else {
    await ProviderApi.sendTerminationConfirmationEmail(provider.id);
  }
};

export const useSendIroncladResponse = (
  user: any,
  provider: ProviderRead,
  includeAllActiveProviderLicenseStates?: boolean,
  isAdminAcceptingNewStateAddendums: boolean = false
) => {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const { ironcladAgreementInfo } = useIroncladAgreementInfo(
    includeAllActiveProviderLicenseStates
  );
  const { stateswithAcceptedAgreements } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates,
  });

  const contractIds = Object.values(
    getContractIds(
      provider,
      stateswithAcceptedAgreements,
      isAdminAcceptingNewStateAddendums,
      ironcladAgreementInfo
    )
  );

  return async (ironcladAgreementStatus: IroncladAgreementStatus) =>
    sendIroncladResponse(
      ironcladAgreementInfo,
      ironcladAgreementStatus,
      provider,
      user,
      isMSCBetaEnabled,
      contractIds
    );
};

export const useSendIroncladResponseMutation = (
  user: any,
  provider: ProviderRead,
  includeAllActiveProviderLicenseStates?: boolean,
  isAdminAcceptingNewStateAddendums: boolean = false
) => {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const { ironcladAgreementInfo } = useIroncladAgreementInfo(
    includeAllActiveProviderLicenseStates
  );
  const { stateswithAcceptedAgreements } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates,
  });

  const contractIds = Object.values(
    getContractIds(
      provider,
      stateswithAcceptedAgreements,
      isAdminAcceptingNewStateAddendums,
      ironcladAgreementInfo
    )
  );

  return useMutation(
    async ({
      ironcladAgreementStatus,
    }: {
      ironcladAgreementStatus: IroncladAgreementStatus;
    }) => {
      return sendIroncladResponse(
        ironcladAgreementInfo,
        ironcladAgreementStatus,
        provider,
        user,
        isMSCBetaEnabled,
        contractIds
      );
    },
    {
      mutationKey: ['updateIroncladAgreementStatus'],
    }
  );
};
