import { useProvider } from 'hooks';
import React, {
  Key,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderType } from '@headway/api/models/ProviderType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { WrapNetwork } from '@headway/api/models/WrapNetwork';
import { BodyText } from '@headway/helix/BodyText';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { Link } from '@headway/helix/Link';
import { ListHeader } from '@headway/helix/ListHeader';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item } from '@headway/helix/Select';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';
import { ToggleButtonGroup } from '@headway/helix/ToggleButton';
import {
  NONPRESCRIBER_COMMON_CODES,
  NONPRESCRIBER_LESS_COMMON_CODES,
  PRESCRIBER_COMMON_CODES,
  PRESCRIBER_LESS_COMMON_CODES,
} from '@headway/shared/constants/cptCodes';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  ConsolidatedRates,
  Rates as RatesInterface,
} from '@headway/shared/types/rates';
import { LogoLoader } from '@headway/ui';

const cptCodeMap: { [key: string]: string } = [
  ...NONPRESCRIBER_COMMON_CODES,
  ...NONPRESCRIBER_LESS_COMMON_CODES,
  ...PRESCRIBER_COMMON_CODES,
  ...PRESCRIBER_LESS_COMMON_CODES,
].reduce((acc, code) => ({ ...acc, [code.value]: code.display }), {});

const RatesTable = ({
  rates,
  carriers,
  providerFrontEndCarriers,
}: {
  rates: RatesInterface;
  carriers: string[];
  providerFrontEndCarriers: ProviderFrontEndCarrierRead[] | undefined;
}) => {
  const cptCodes = Object.keys(rates);

  const carriersInRates = new Set<string>();
  Object.values(rates).forEach((ratesForCptCode) => {
    Object.keys(ratesForCptCode).forEach((carrier) =>
      carriersInRates.add(carrier)
    );
  });
  const carriersExcludingNoRates = carriers.filter((carrier) =>
    carriersInRates.has(carrier)
  );

  const tableHeaderColumns = [
    <Column minWidth={105}>CPT Code</Column>,
    <Column minWidth={400}>Description</Column>,
    carriersExcludingNoRates.map((carrier) => (
      <Column width={110}>
        {carrier}
        {((carrier === 'Blue Cross Blue Shield of Massachusetts' &&
          providerFrontEndCarriers?.find(
            (pfec) => pfec.wrapNetwork === WrapNetwork.BCBS_MA
          )) ||
          (carrier === 'Horizon Blue Cross and Blue Shield of New Jersey' &&
            providerFrontEndCarriers?.find(
              (pfec) => pfec.wrapNetwork === WrapNetwork.BCBS_NJ
            ))) && (
          <GlossaryTerm term="(Virtual network)">
            You’re automatically opted into this virtual network, but not fully
            credentialed with this panel.
          </GlossaryTerm>
        )}
      </Column>
    )),
  ].flat();

  const getCells = (cptCode: string) => {
    return [
      <Cell>{cptCode}</Cell>,
      <Cell>{cptCodeMap[cptCode.toString()] ?? '-'}</Cell>,
      carriersExcludingNoRates.map((carrier) => (
        <Cell>
          {rates[cptCode][carrier]
            ? `$${rates[cptCode][carrier].toFixed(2)}`
            : null}
        </Cell>
      )),
    ].flat();
  };

  return (
    <div
      css={{
        marginTop: theme.spacing.x2,
        ['tbody tr:nth-child(even)']: {
          backgroundColor: theme.color.system.lightGray,
        },
        ['thead th']: {
          borderRight: `1px solid ${theme.color.system.borderGray}`,
        },
        ['thead th button']: {
          textWrap: 'nowrap',
        },
        ['tbody td']: {
          borderRight: `1px solid ${theme.color.system.borderGray}`,
        },
        ['tbody td:first-child']: {
          borderLeft: `1px solid ${theme.color.system.borderGray}`,
        },
        display: 'grid',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        overflow: 'auto',
        maxHeight: '700px',
      }}
    >
      <Table aria-label="Rates for Provider Type">
        <TableHeader>{tableHeaderColumns}</TableHeader>
        <TableBody>
          {cptCodes.map((cptCode) => (
            <Row>{getCells(cptCode)}</Row>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

interface RatesProps {
  user: any;
  state: UnitedStates;
  rates: ConsolidatedRates;
  carriers: string[];
  providerFrontEndCarriers: ProviderFrontEndCarrierRead[] | undefined;
  isRatesLoading: boolean;
  showHelpText?: boolean;
}

export const Rates: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<RatesProps>>
> = ({
  user,
  rates,
  providerFrontEndCarriers,
  carriers,
  state,
  isRatesLoading,
  showHelpText = false,
}) => {
  const isGroupPractice = !!user.group_practice;
  const provider = useProvider();
  const providerTypes = !isRatesLoading ? Object.keys(rates) : [];
  const providerTypeRefs: MutableRefObject<HTMLDivElement[]> = useRef([]);
  const [stickyClass, setStickyClass] = useState<boolean>(false);

  const formatProviderTypeToString = (providerType: string): string => {
    let string = providerType.toString();

    if (providerType === ProviderType.OTHER_THERAPISTS) {
      return string.slice(0, -1);
    }

    return string;
  };

  // Enables turning the menu into a sticky header when the user scrolls past the page header
  useEffect(() => {
    const stickNavbar = () => {
      if (window !== undefined) {
        let windowHeight = window.scrollY;
        // 550px is roughly the length of the page header on desktop
        windowHeight > 550 ? setStickyClass(true) : setStickyClass(false);
      }
    };

    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  return (
    <>
      {providerTypes.length > 1 && (
        <span
          className={`${
            stickyClass ? 'fixed top-11 pb-3' : 'relative'
          } z-[1] flex w-full flex-row items-center gap-4 bg-system-white pt-5`}
        >
          <b>See rates for:</b>
          <ToggleButtonGroup
            selectionMode="single"
            size="medium"
            aria-label="Provider type"
            onSelectionChange={(selectedProviderType) => {
              const index =
                selectedProviderType instanceof Set
                  ? selectedProviderType.values().next().value
                  : null;

              if (index && providerTypeRefs.current[index]) {
                providerTypeRefs.current[index].scrollIntoView({
                  block: 'center',
                  behavior: 'smooth',
                  inline: 'nearest',
                });
              }
            }}
          >
            {providerTypes.map((providerType: string, index) => (
              <Item key={index}>
                {formatProviderTypeToString(providerType)}s
              </Item>
            ))}
          </ToggleButtonGroup>
        </span>
      )}
      {showHelpText && (
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: stickyClass ? theme.spacing.x12 : theme.spacing.x8,
            paddingTop: stickyClass ? theme.spacing.x5 : 0,
            justifyContent: 'space-between',
            alignContent: 'center',
            width: '100%',
            gap: theme.spacing.x4,

            [theme.__futureMedia.phone]: {
              flexDirection: 'column',
            },
          }}
        >
          <SectionHeader>{statesToDisplayNames[state]} Rates</SectionHeader>
        </div>
      )}
      {isRatesLoading ? (
        <div
          css={{
            display: 'flex',
            justifyContent: 'center',
            margin: `calc(2 * ${theme.spacing.x4}) 0`,
          }}
        >
          <LogoLoader />
        </div>
      ) : (
        <>
          {providerTypes.map((providerType: string, index) => (
            <div
              ref={(ref: HTMLDivElement) =>
                (providerTypeRefs.current[index] = ref)
              }
              className="relative scroll-mt-[120px]"
              key={index}
            >
              {providerTypes.length > 1 ? (
                <>
                  <ListHeader>
                    {formatProviderTypeToString(providerType)} rates
                  </ListHeader>
                  <BodyText>
                    <p className="mt-2">
                      Rates for{' '}
                      {provider.providerType === providerType &&
                        ` ${provider.displayFirstName} ${provider.displayLastName} and other`}{' '}
                      {formatProviderTypeToString(providerType).toLowerCase()}s
                      in your group in when they get credentialed and take
                      sessions in {statesToDisplayNames[state]}.
                    </p>
                  </BodyText>
                </>
              ) : null}
              <RatesTable
                rates={rates[providerType]}
                carriers={carriers}
                providerFrontEndCarriers={providerFrontEndCarriers}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};
