import React from 'react';
import { useToggle } from 'react-use';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Modal as HelixModal } from '@headway/helix/Modal';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui';
import { theme } from '@headway/ui/theme';
import { notifyWarning } from '@headway/ui/utils/notify';

import { SelfPayOptInForm } from 'components/SelfPayOptInForm/SelfPayOptInForm';

import { SelfPayRateForm } from './SelfPayRateForm';

export interface ProviderSelfPayProps {
  provider: ProviderRead;
  onUpdateSuccess: (update: ProviderRead) => void;
}

export const ProviderSelfPay = ({
  provider,
  onUpdateSuccess,
}: ProviderSelfPayProps) => {
  const [isRateModalOpen, toggleRateModalOpen] = useToggle(false);
  const [isEnableSelfPayModalOpen, toggleEnableSelfPayModalOpen] =
    useToggle(false);

  const selfPayEnabled = !!provider.selfPayTermsAcceptanceDate;

  return (
    <PageSection layout="grid.two-column">
      <div>
        <h2>
          <SectionHeader>Private pay rate</SectionHeader>
        </h2>
        <PageSectionSubText>
          <BodyText>
            Bring your private pay clients to Headway and keep your practice in
            one place. Simply set your rate and schedule sessions, and we’ll
            include these payments with your existing Headway payouts.
            {/*<a href="TODO_INSERT_ZENDESK_LINK">Learn More.</a>*/}
          </BodyText>
        </PageSectionSubText>
      </div>
      <div css={{ justifySelf: 'end' }}>
        <div>
          {selfPayEnabled ? (
            <>
              <div css={{ marginBottom: theme.space.lg, textAlign: 'end' }}>
                <strong>${provider.selfPayRateDefault}</strong> per session
              </div>
              <Button
                variant="secondary"
                onPress={() => {
                  toggleRateModalOpen();
                }}
              >
                Update private pay rate
              </Button>
            </>
          ) : (
            <Button
              variant="secondary"
              onPress={() => {
                toggleEnableSelfPayModalOpen();
              }}
            >
              Enable private pay
            </Button>
          )}
        </div>

        <HelixModal
          title="Private pay with headway"
          isOpen={isEnableSelfPayModalOpen}
          onDismiss={toggleEnableSelfPayModalOpen}
        >
          <SelfPayOptInForm
            provider={provider}
            onSubmit={() => {
              toggleEnableSelfPayModalOpen(false);
              toggleRateModalOpen(true);
            }}
          />
        </HelixModal>

        <Modal
          title="Update private pay rate"
          open={isRateModalOpen}
          onClose={toggleRateModalOpen}
        >
          <p>
            Set your default session rate. This default rate can also be changed
            for each client and overridden on each appointment.
          </p>
          <SelfPayRateForm
            selfPayRateDefault={provider.selfPayRateDefault}
            onSubmit={async (values) => {
              try {
                const updatedProvider = await ProviderApi.updateProvider(
                  provider.id,
                  values
                );
                onUpdateSuccess(updatedProvider);
                toggleRateModalOpen();
              } catch (err) {
                notifyWarning(
                  `There was a problem saving your private pay rate`
                );
                logException(err);
              }
            }}
            onCancel={toggleRateModalOpen}
          />
        </Modal>
      </div>
    </PageSection>
  );
};
