import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';

import { getValue } from '../../view/utils';

interface ViewComponentProps {
  title?: string;
  id?: string;
  value?: string;
}

export const ViewComponent = ({
  title = '',
  id,
  value,
}: ViewComponentProps) => {
  let val = value;
  if (id) {
    val = getValue(id);
  }
  if (!val) {
    return (
      <div css={{ ...theme.stack.vertical, whiteSpace: 'pre-wrap' }}>
        <BodyText>
          <strong>{title}</strong>
        </BodyText>
        <BodyText>
          <i>No answer provided</i>
        </BodyText>
      </div>
    );
  }
  return (
    <div css={{ ...theme.stack.vertical, whiteSpace: 'pre-wrap' }}>
      <BodyText>
        <strong>{title}</strong>
      </BodyText>
      <BodyText>{val}</BodyText>
    </div>
  );
};
