/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { CAQHGetBatchStatusResponse } from '../models/CAQHGetBatchStatusResponse';
import { CAQHGetDocumentsListResponse } from '../models/CAQHGetDocumentsListResponse';
import { CAQHLoginRequest } from '../models/CAQHLoginRequest';
import { CAQHLookupResponse } from '../models/CAQHLookupResponse';
import { CAQHRosterAdditionRequest } from '../models/CAQHRosterAdditionRequest';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { HelloSignResponse } from '../models/HelloSignResponse';
import { MalpracticeMinimums } from '../models/MalpracticeMinimums';
import { ProviderPanelabilityEvaluation } from '../models/ProviderPanelabilityEvaluation';
import { ProviderQuestionnaireCaqhProfileRead } from '../models/ProviderQuestionnaireCaqhProfileRead';
import { ProviderQuestionnaireCreate } from '../models/ProviderQuestionnaireCreate';
import { ProviderQuestionnaireRead } from '../models/ProviderQuestionnaireRead';
import { ProviderQuestionnaireReadV2 } from '../models/ProviderQuestionnaireReadV2';
import { ProviderQuestionnaireRecredSummary } from '../models/ProviderQuestionnaireRecredSummary';
import { ProviderQuestionnaireType } from '../models/ProviderQuestionnaireType';
import { ProviderQuestionnaireUpdate } from '../models/ProviderQuestionnaireUpdate';
import { ProviderQuestionnaireVerificationCreate } from '../models/ProviderQuestionnaireVerificationCreate';
import { ProviderQuestionnaireVerificationRead } from '../models/ProviderQuestionnaireVerificationRead';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderQuestionnaireApiResource {

    /**
     * Add Provider To Caqh Roster
     * 
     * @param providerQuestionnaireId 
     * @param cAQHRosterAdditionRequest 
     
     */
    public addProviderToCaqhRoster(providerQuestionnaireId: number, body: CAQHRosterAdditionRequest, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/add-to-roster';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider Questionnaire
     * 
     * @param providerQuestionnaireCreate 
     
     */
    public createProviderQuestionnaire(body: ProviderQuestionnaireCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireRead> {
        const apiRoute = '/provider-questionnaire';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider Questionnaire Verification
     * 
     * @param providerQuestionnaireId 
     * @param providerQuestionnaireVerificationCreate 
     
     */
    public createProviderQuestionnaireVerification(providerQuestionnaireId: number, body: ProviderQuestionnaireVerificationCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireVerificationRead> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/verifications';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Questionnaire Release Form
     * 
     * @param providerQuestionnaireId 
     
     */
    public createQuestionnaireReleaseForm(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<HelloSignResponse> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/release-form';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Provider Questionnaires
     * 
     * @param providerId 
     * @param type 
     
     */
    public findProviderQuestionnaires(query?: { provider_id?: number, type?: ProviderQuestionnaireType }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderQuestionnaireRead>> {
        const apiRoute = '/provider-questionnaire';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Caqh Batch Status
     * 
     * @param providerQuestionnaireId 
     * @param batchId 
     
     */
    public getCaqhBatchStatus(providerQuestionnaireId: number, query?: { batch_id: string }, axiosConfig?: AxiosRequestConfig): Promise<CAQHGetBatchStatusResponse> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/batch-status';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Caqh Id By Npi
     * 
     * @param providerQuestionnaireId 
     * @param npi 
     
     */
    public getCaqhIdByNpi(providerQuestionnaireId: number, query?: { npi: string }, axiosConfig?: AxiosRequestConfig): Promise<CAQHLookupResponse> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Document By Doc Url
     * 
     * @param providerQuestionnaireId 
     * @param caqhProviderId 
     * @param docUrl 
     
     */
    public getDocumentByDocUrl(providerQuestionnaireId: number, query?: { caqh_provider_id: number, doc_url: string }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/documents/download';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Documents List By Caqh Provider Id
     * 
     * @param providerQuestionnaireId 
     * @param caqhProviderId 
     
     */
    public getDocumentsListByCaqhProviderId(providerQuestionnaireId: number, query?: { caqh_provider_id: number }, axiosConfig?: AxiosRequestConfig): Promise<CAQHGetDocumentsListResponse> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/documents/list';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Latest Provider Questionnaire Caqh Profile
     * 
     * @param providerQuestionnaireId 
     
     */
    public getLatestProviderQuestionnaireCaqhProfile(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireCaqhProfileRead> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh-profile';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Malpractice Minimums
     * 
     * @param providerId 
     
     */
    public getProviderMalpracticeMinimums(query?: { provider_id: number }, axiosConfig?: AxiosRequestConfig): Promise<MalpracticeMinimums> {
        const apiRoute = '/provider-questionnaire/malpractice-minimums';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Panelability By Questionnaire Id
     * 
     * @param providerQuestionnaireId 
     
     */
    public getProviderPanelabilityByQuestionnaireId(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderPanelabilityEvaluation> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/provider_panelability';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Questionnaire
     * 
     * @param providerQuestionnaireId 
     
     */
    public getProviderQuestionnaire(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireRead> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Questionnaire Recred Summary
     * 
     * @param providerId 
     
     */
    public getProviderQuestionnaireRecredSummary(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireRecredSummary> {
        const apiRoute = '/provider/{provider_id}/provider-questionnaire/recred-summary';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Questionnaire V2
     * 
     * @param providerQuestionnaireId 
     
     */
    public getProviderQuestionnaireV2(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireReadV2> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/v2';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Questionnaire Verifications
     * 
     * @param providerQuestionnaireId 
     
     */
    public getProviderQuestionnaireVerifications(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderQuestionnaireVerificationRead>> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/verifications';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Roster Status
     * 
     * @param providerQuestionnaireId 
     * @param caqhProviderId 
     
     */
    public getProviderRosterStatus(providerQuestionnaireId: number, query?: { caqh_provider_id: number }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/provider-roster-status';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Questionnaire Release Form Url
     * 
     * @param providerQuestionnaireId 
     
     */
    public getQuestionnaireReleaseFormUrl(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<HelloSignResponse> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/release-form';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Map Provider Data By Caqh Provider Id
     * 
     * @param providerQuestionnaireId 
     * @param caqhProviderId 
     
     */
    public mapProviderDataByCaqhProviderId(providerQuestionnaireId: number, query?: { caqh_provider_id: number }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/provider-data';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Submit Provider Questionnaire
     * 
     * @param providerQuestionnaireId 
     
     */
    public submitProviderQuestionnaire(providerQuestionnaireId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireRead> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/submit';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Credentialed On Date
     * 
     * @param providerQuestionnaireId 
     * @param approvalDate 
     
     */
    public updateCredentialedOnDate(providerQuestionnaireId: number, query?: { approval_date?: string }, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireRead> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/update_credentialed_on';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Questionnaire
     * 
     * @param providerQuestionnaireId 
     * @param providerQuestionnaireUpdate 
     
     */
    public updateProviderQuestionnaire(providerQuestionnaireId: number, body: ProviderQuestionnaireUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderQuestionnaireRead> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Verify Caqh Credentials
     * 
     * @param providerQuestionnaireId 
     * @param cAQHLoginRequest 
     
     */
    public verifyCaqhCredentials(providerQuestionnaireId: number, body: CAQHLoginRequest, axiosConfig?: AxiosRequestConfig): Promise<CAQHLookupResponse> {
        const apiRoute = '/provider-questionnaire/{provider_questionnaire_id}/caqh/verify-credentials';
        const reqPath = apiRoute
                    .replace('{' + 'provider_questionnaire_id}', String(providerQuestionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderQuestionnaireApi = new ProviderQuestionnaireApiResource();
