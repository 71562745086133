/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { UserPaymentMethodCreateRequest } from '../models/UserPaymentMethodCreateRequest';
import { UserPaymentMethodRead } from '../models/UserPaymentMethodRead';
import { UserPaymentMethodUpdateBankAccountVerificationRequest } from '../models/UserPaymentMethodUpdateBankAccountVerificationRequest';
import { UserPaymentMethodUpdateFundingAttestationRequest } from '../models/UserPaymentMethodUpdateFundingAttestationRequest';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class UserPaymentMethodApiResource {

    /**
     * Create User Payment Method
     * 
     * @param userPaymentMethodCreateRequest 
     
     */
    public createUserPaymentMethod(body: UserPaymentMethodCreateRequest, axiosConfig?: AxiosRequestConfig): Promise<UserPaymentMethodRead> {
        const apiRoute = '/user-payment-method';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete User Payment Method
     * 
     * @param userPaymentMethodId 
     
     */
    public deleteUserPaymentMethod(userPaymentMethodId: number, axiosConfig?: AxiosRequestConfig): Promise<UserPaymentMethodRead> {
        const apiRoute = '/user-payment-method/{user_payment_method_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_payment_method_id}', String(userPaymentMethodId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User Payment Method
     * 
     * @param userPaymentMethodId 
     
     */
    public getUserPaymentMethod(userPaymentMethodId: number, axiosConfig?: AxiosRequestConfig): Promise<UserPaymentMethodRead> {
        const apiRoute = '/user-payment-method/{user_payment_method_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_payment_method_id}', String(userPaymentMethodId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User Payment Methods For User
     * 
     * @param userId 
     
     */
    public getUserPaymentMethodsForUser(query?: { user_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<UserPaymentMethodRead>> {
        const apiRoute = '/user-payment-methods';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send User Payment Method Added Email
     * 
     * @param userPaymentMethodId 
     * @param createdByProviderId 
     
     */
    public sendUserPaymentMethodAddedEmail(userPaymentMethodId: number, createdByProviderId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/user-payment-method/{user_payment_method_id}/send-payment-method-added-provider-email/{created_by_provider_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_payment_method_id}', String(userPaymentMethodId))
                    .replace('{' + 'created_by_provider_id}', String(createdByProviderId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update User Payment Method
     * 
     * @param userPaymentMethodId 
     * @param userPaymentMethodUpdateBankAccountVerificationRequestUserPaymentMethodUpdateFundingAttestationRequest 
     
     */
    public updateUserPaymentMethod(userPaymentMethodId: number, body: UserPaymentMethodUpdateBankAccountVerificationRequest | UserPaymentMethodUpdateFundingAttestationRequest, axiosConfig?: AxiosRequestConfig): Promise<UserPaymentMethodRead> {
        const apiRoute = '/user-payment-method/{user_payment_method_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_payment_method_id}', String(userPaymentMethodId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const UserPaymentMethodApi = new UserPaymentMethodApiResource();
