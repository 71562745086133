import React from 'react';
import * as Yup from 'yup';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { IconLock } from '@headway/helix/icons/Lock';
import { LinkButton } from '@headway/helix/LinkButton';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { LEARN_ABOUT_GETTING_CREDENTIALED_LINK } from '@headway/shared/constants/zendesk';
import { isExistingProvider } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { HumanTouchpoint } from '@headway/ui/HumanTouchpoint';

import CredentialingSteps from 'assets/img/CredentialingSteps.png';
import Katie from 'assets/img/Katie.png';
import Mehek from 'assets/img/Mehek.png';

import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';

const convertStateListToString = (states: UnitedStates[]) => {
  if (states.length === 0) {
    return '';
  }

  if (states.length === 1) {
    return statesToDisplayNames[states[0]];
  }

  const stateReadableNames = states.map((state) => statesToDisplayNames[state]);
  const stateAnd = stateReadableNames.pop();

  return `${stateReadableNames.join(', ')} and ${stateAnd}`;
};

const WelcomeBanner = ({ states }: { states: UnitedStates[] }) => {
  const { provider } = useQuestionnaireContext();
  return (
    <div className="flex  w-full bg-hue-lightYellow phone:justify-start tablet:justify-start desktop:justify-center">
      <div className="flex min-h-[250px] w-full max-w-[950px] gap-[100px] overflow-hidden bg-hue-lightYellow p-4 pt-10 phone:pt-4 desktop:min-h-[300px]">
        <div className="mb-6 mt-10 flex flex-col gap-8 phone:mt-6 tablet:max-w-none desktop:max-w-[450px]">
          <div className="flex flex-col gap-2">
            <BrandText variant="section">
              Hi {provider.displayFirstName},
            </BrandText>
            <BrandText variant="section">
              Let's get you credentialed in record-time.
            </BrandText>
          </div>
          {isExistingProvider(provider) ? (
            <BodyText>
              Looks like you’ve gone through this process with us before! We’ll
              prefill information you shared with us in the past to make this
              process as easy as possible for you.
            </BodyText>
          ) : (
            <div className="flex flex-col gap-2">
              <BodyText>
                The credentialing process usually takes months. My team works
                with insurers to get your application approved in a fraction of
                that time!
              </BodyText>
              <BodyText>
                I’ll walk you through all the key steps to complete your
                application for getting credentialed
                {states.length > 0
                  ? ` in ${convertStateListToString(states)}.`
                  : '.'}
              </BodyText>
            </div>
          )}
          <div className="text-left">
            <LinkButton
              variant="link"
              href={LEARN_ABOUT_GETTING_CREDENTIALED_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Learn more about what to expect ↗
            </LinkButton>
          </div>
          <HumanTouchpoint
            imageSources={[Mehek, Katie]}
            primaryLabel="Credentialing Team"
            secondaryLabel="at Headway"
          />
        </div>
        <div className=" relative hidden w-[420px] desktop:block">
          <img
            src={CredentialingSteps}
            className="absolute left-[-75px] top-[50px] h-[150px] w-[auto] object-fill"
          />
        </div>
      </div>
    </div>
  );
};

export const WelcomeStep = ({ formikHelpers }: CustomComponentProps) => {
  const { values } = formikHelpers;

  return (
    <div className="overflow-hidden">
      <WelcomeBanner states={values.providerSelectedPracticeStates ?? []} />
      <div className="flex flex-col items-center p-4 pt-10">
        <div className="flex flex-row gap-2">
          <IconLock fill={theme.color.system.black} />
          <div className="flex flex-col items-start gap-2">
            <SubBodyText>
              <strong>
                Headway is HIPAA-compliant. All information you share with us
                will only be used to get you credentialed and onboarded.
              </strong>
            </SubBodyText>
            <SubBodyText>
              Each provider applying or reapplying for credentialing or
              recredentialing with Headway has the following rights regarding
              the credentialing process:
              <ul className="mt-0">
                <li>To review information submitted</li>
                <li>To correct erroneous information</li>
                <li>
                  To receive the status of their credentialing or
                  recredentialing application, upon request
                </li>
              </ul>
            </SubBodyText>
            <LinkButton
              size="medium"
              variant="link"
              href="https://help.headway.co/hc/en-us/articles/360058922872-HIPAA-compliance-and-PHI"
              target="_blank"
              rel="noreferrer"
            >
              Learn more about how we comply with HIPAA requirements
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const welcomeStepConfig: QuestionnaireV2Step = {
  Component: WelcomeStep,
  css: {
    mainContainer: { flex: '1 1 auto' },
    stepContainer: {},
    formikContainer: {},
    submissionContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginLeft: theme.spacing.x9,
      marginBottom: theme.spacing.x10,
    },
    submissionButtonContainer: {
      width: '950px',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing.x4,
      [theme.__futureMedia.below('desktop')]: {
        maxWidth: '950px',
        width: '100%',
      },
    },
  },
  getFormMeta: ({ provider, providerQuestionnaire }) => {
    return {
      initialValue: {},
      validationSchema: Yup.object(),
    };
  },
};

export default welcomeStepConfig;
