import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Modal } from '@headway/ui';

import { PrescriberReferralForm } from './PrescriberReferralForm';

interface PrescriberReferralModalProps {
  open: boolean;
  onClose: () => void;
  provider: ProviderRead;
  patientUserId: number;
}

export const PrescriberReferralModal: React.FC<
  React.PropsWithChildren<PrescriberReferralModalProps>
> = ({ open, onClose, provider, patientUserId }) => {
  return (
    <Modal open={open} onClose={onClose} title="Psychiatric Care Referral">
      <PrescriberReferralForm
        onClose={onClose}
        provider={provider}
        patientUserId={patientUserId}
      />
    </Modal>
  );
};
