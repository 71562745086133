import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export type UsePatientAssessmentsQueryKeyArgs = NonNullable<
  Parameters<typeof PatientAssessmentApi.getPaginatedPatientAssessments>[0]
>;

export const getUsePatientAssessmentsQueryKey = (
  filters: UsePatientAssessmentsQueryKeyArgs
) => ['patient-assessments', filters] as const;

const {
  useSingleQuery: usePatientAssessments,
  useListQuery: usePatientAssessmentsList,
  useCachedQuery: usePatientAssessmentsCache,
} = createBasicApiHooks(
  getUsePatientAssessmentsQueryKey,
  (filters) => PatientAssessmentApi.getPaginatedPatientAssessments(filters),
  ({ provider_patient_id, patient_id }: UsePatientAssessmentsQueryKeyArgs) =>
    provider_patient_id != null || patient_id != null,
  () => 'Failed to fetch patient assessments'
);

export {
  usePatientAssessments,
  usePatientAssessmentsList,
  usePatientAssessmentsCache,
};
