/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum CaqhErrorType {
    PROFILE_MISSING_INTAKE_STATE = 'PROFILE_MISSING_INTAKE_STATE',
    LICENSE_MISSING_INTAKE_STATE = 'LICENSE_MISSING_INTAKE_STATE',
    DEA_MISSING_INTAKE_STATE = 'DEA_MISSING_INTAKE_STATE',
    CDS_MISSING_INTAKE_STATE = 'CDS_MISSING_INTAKE_STATE',
    BOARD_CERT_MISSING_ANCC = 'BOARD_CERT_MISSING_ANCC',
    BOARD_CERT_MISSING_EXPIRATION_DATE = 'BOARD_CERT_MISSING_EXPIRATION_DATE',
    BOARD_CERT_EXPIRED = 'BOARD_CERT_EXPIRED',
    EDUCATION_MISSING_DEGREE_MASTERS_OR_HIGHER = 'EDUCATION_MISSING_DEGREE_MASTERS_OR_HIGHER',
    EMPLOYMENT_MISSING_WORK_GAP_RECORD = 'EMPLOYMENT_MISSING_WORK_GAP_RECORD',
    MALPRACTICE_CURRENT_MISSING = 'MALPRACTICE_CURRENT_MISSING',
    MALPRACTICE_CURRENT_OCCURRENCE_LIMIT = 'MALPRACTICE_CURRENT_OCCURRENCE_LIMIT',
    MALPRACTICE_CURRENT_AGGREGATE_LIMIT = 'MALPRACTICE_CURRENT_AGGREGATE_LIMIT',
    MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_MISSING = 'MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_MISSING',
    MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_NOT_OVERLAPPING = 'MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_NOT_OVERLAPPING',
    MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_OCCURRENCE_LIMIT = 'MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_OCCURRENCE_LIMIT',
    MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_AGGREGATE_LIMIT = 'MALPRACTICE_CURRENT_EXPIRING_SOON_FUTURE_AGGREGATE_LIMIT',
    MALPRACTICE_CHECKBOX = 'MALPRACTICE_CHECKBOX',
    DISCLOSURE_CO_ABLE_TO_PERFORM_NO = 'DISCLOSURE_CO_ABLE_TO_PERFORM_NO',
    DISCLOSURE_GA_ABLE_TO_PERFORM_NO = 'DISCLOSURE_GA_ABLE_TO_PERFORM_NO',
    PRACTICE_INVALID_ADDRESS = 'PRACTICE_INVALID_ADDRESS',
    MALPRACTICE_MISSING = 'MALPRACTICE_MISSING',
    MALPRACTICE_OCCURRENCE_LIMIT = 'MALPRACTICE_OCCURRENCE_LIMIT',
    MALPRACTICE_AGGREGATE_LIMIT = 'MALPRACTICE_AGGREGATE_LIMIT',
    LICENSE_MISSING_EFFECTIVE_DATE = 'LICENSE_MISSING_EFFECTIVE_DATE',
    PRACTICE_MISSING_INSTATE_ADDRESS_WITH_HEADWAY_PRACTICE_NAME = 'PRACTICE_MISSING_INSTATE_ADDRESS_WITH_HEADWAY_PRACTICE_NAME',
    PRACTICE_MISSING_HEADWAY_PRACTICE_PHONE = 'PRACTICE_MISSING_HEADWAY_PRACTICE_PHONE',
    PRACTICE_MISSING_HEADWAY_PRACTICE_FAX = 'PRACTICE_MISSING_HEADWAY_PRACTICE_FAX',
    PRACTICE_MISSING_HEADWAY_TIN = 'PRACTICE_MISSING_HEADWAY_TIN',
    PRACTICE_MISSING_HEADWAY_TAX_TYPE = 'PRACTICE_MISSING_HEADWAY_TAX_TYPE',
    PRACTICE_MISSING_HEADWAY_NPI = 'PRACTICE_MISSING_HEADWAY_NPI',
    PRACTICE_INCORRECT_AFFILIATION_DESCRIPTION = 'PRACTICE_INCORRECT_AFFILIATION_DESCRIPTION',
    PRACTICE_MISSING_START_DATE = 'PRACTICE_MISSING_START_DATE',
    PRACTICE_YES_ALL_NEW_PATIENTS = 'PRACTICE_YES_ALL_NEW_PATIENTS',
    PRACTICE_YES_NEW_PATIENTS = 'PRACTICE_YES_NEW_PATIENTS',
    PRACTICE_YES_EXISTING_PATIENTS_WITH_CHANGE_OF_PAYOR = 'PRACTICE_YES_EXISTING_PATIENTS_WITH_CHANGE_OF_PAYOR',
    PRACTICE_YES_ACCEPT_REFERRALS_FROM_PHYSICIAN = 'PRACTICE_YES_ACCEPT_REFERRALS_FROM_PHYSICIAN',
    PRACTICE_NO_ACCEPT_MEDICARE = 'PRACTICE_NO_ACCEPT_MEDICARE',
    PRACTICE_NO_ACCEPT_MEDICAID = 'PRACTICE_NO_ACCEPT_MEDICAID',
    PRACTICE_NO_INFORMATION_VARY_BY_HEALTH_PLAN = 'PRACTICE_NO_INFORMATION_VARY_BY_HEALTH_PLAN',
    PRACTICE_MISSING_PAYMENT_INFO = 'PRACTICE_MISSING_PAYMENT_INFO',
    PRACTICE_MISSING_HEADWAY_OFFICE_PERSONNEL = 'PRACTICE_MISSING_HEADWAY_OFFICE_PERSONNEL',
    PRACTICE_MISSING_HEADWAY_MAILING_ADDRESS = 'PRACTICE_MISSING_HEADWAY_MAILING_ADDRESS',
    PRACTICE_MISSING_HEADWAY_ADDRESS = 'PRACTICE_MISSING_HEADWAY_ADDRESS',
    PRACTICE_MISSING_HEADWAY_PRACTICE_NAME = 'PRACTICE_MISSING_HEADWAY_PRACTICE_NAME'
}
