import { useState } from 'react';

import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdmin } from 'utils/access';

import { EnrollMFAModal, ResetMFAModal } from './components/MFAModal';
import { UpdateLoginEmailModal } from './components/UpdateLoginEmailModal';

export function Login() {
  const [isMFAModalOpen, setIsMFAModalOpen] = useState(false);
  const [isEnrollMFAModalOpen, setIsEnrollMFAModalOpen] = useState(false);
  const [isUpdateLoginEmailModalOpen, setIsUpdateLoginEmailModalOpen] =
    useState(false);

  const AuthStore = useAuthStore();
  const userName = AuthStore.user?.first_name;
  const email = AuthStore.user?.email;
  const isGroupPracticeAdmin = isGroupAdmin(AuthStore.user);

  return (
    <PageSection>
      <h2>
        <SectionHeader>Login</SectionHeader>
      </h2>
      {isGroupPracticeAdmin && (
        <BodyText>
          Manage login settings for <strong>{userName}</strong>
        </BodyText>
      )}
      <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
        <section
          css={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: theme.spacing.x6,
          }}
        >
          <PageSectionSubText>
            <strong>Login email</strong>
          </PageSectionSubText>
          <div
            css={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
              gap: theme.spacing.x3,
            }}
          >
            <BodyText>{email}</BodyText>
            <Button
              variant="secondary"
              size="medium"
              onPress={() => setIsUpdateLoginEmailModalOpen(true)}
            >
              Update login email
            </Button>
            <UpdateLoginEmailModal
              isOpen={isUpdateLoginEmailModalOpen}
              onClose={() => setIsUpdateLoginEmailModalOpen(false)}
              AuthStore={AuthStore}
              userId={AuthStore.user.id}
            />
          </div>
        </section>
        {AuthStore.user?.use_mfa && (
          <section
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: theme.spacing.x6,
            }}
          >
            <div>
              <div
                css={{
                  display: 'flex',
                  gap: theme.spacing.x2,
                  alignItems: 'end',
                }}
              >
                <PageSectionSubText>
                  <strong>Two-Factor Authentication (2FA)</strong>
                </PageSectionSubText>
                {AuthStore.user?.use_mfa && (
                  <Badge variant="positive">Enabled</Badge>
                )}
              </div>
              <PageSectionSubText>
                To login to Headway, we’ll send you a unique verification code
                to keep your account more secure. Manage your preferred delivery
                method to receive codes.
              </PageSectionSubText>
            </div>
            <div
              css={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Button
                variant="secondary"
                size="medium"
                onPress={() => setIsMFAModalOpen(true)}
              >
                Reset MFA
              </Button>
              <ResetMFAModal
                isOpen={isMFAModalOpen}
                onClose={() => setIsMFAModalOpen(false)}
                userId={AuthStore.user.id}
                AuthStore={AuthStore}
              />
            </div>
          </section>
        )}
        {!AuthStore.user?.use_mfa && (
          <section
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: theme.spacing.x6,
            }}
          >
            <div>
              <PageSectionSubText>
                <strong>Two-factor authentication (2FA)</strong>
              </PageSectionSubText>
              <PageSectionSubText>
                When you're logging into Headway, we can send a unique
                verification code to your phone or email. Enroll in 2FA here to
                make your account more secure.
              </PageSectionSubText>
            </div>
            <div
              css={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Button
                variant="secondary"
                size="medium"
                onPress={() => setIsEnrollMFAModalOpen(true)}
              >
                Enroll in 2FA
              </Button>
              <EnrollMFAModal
                isOpen={isEnrollMFAModalOpen}
                onClose={() => setIsEnrollMFAModalOpen(false)}
                userId={AuthStore.user.id}
                AuthStore={AuthStore}
              />
            </div>
          </section>
        )}
      </div>
    </PageSection>
  );
}
