import {
  CheckCircleOutline,
  ErrorOutline,
  FileCopyOutlined,
  Security,
} from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Skeleton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useToggle } from 'react-use';
import * as Yup from 'yup';

import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderCalendarSyncStatus } from '@headway/api/models/ProviderCalendarSyncStatus';
import {
  ImportExternalCalendarsAddNewCalendarClickedEvent,
  ImportExternalCalendarsAPICallToCreateCalendarInitiatedEvent,
  ImportExternalCalendarsAPICallToCreateCalendarRespondedEvent,
  ImportExternalCalendarsAPICallToManuallySyncCalendarInitiatedEvent,
  ImportExternalCalendarsAPICallToManuallySyncCalendarRespondedEvent,
  ImportExternalCalendarsAPICallToRemoveCalendarInitiatedEvent,
  ImportExternalCalendarsAPICallToRemoveCalendarRespondedEvent,
  ImportExternalCalendarsAPICallToStartSyncInitiatedEvent,
  ImportExternalCalendarsAPICallToStartSyncRespondedEvent,
  ImportExternalCalendarsFormSubmittedEvent,
  ImportExternalCalendarsFormValidationFailedEvent,
  ImportExternalCalendarsHelpCenterURLClickedEvent,
  ImportExternalCalendarsImportCalendarButtonClickedEvent,
  ImportExternalCalendarsLearnMoreButtonClickedEvent,
  ImportExternalCalendarsModalClosedEvent,
  ImportExternalCalendarsRemoveCalendarClickedEvent,
  ImportExternalCalendarsSyncNowButtonClickedEvent,
  SetupAvailabilityStepEvent,
} from '@headway/avo';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { trackEvent } from '@headway/shared/utils/analytics';
import { getErrorMessage } from '@headway/shared/utils/error';
import { logException } from '@headway/shared/utils/sentry';
import { Modal } from '@headway/ui';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsHeader,
  AccordionSummary,
} from '@headway/ui/Accordion';
import { theme as legacyTheme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { useProvider } from 'hooks/useProvider';
import {
  useAddExternalCalendarMutation,
  useDeleteExternalCalendarMutation,
  useSyncExternalCalendarMutation,
  useUpdateExternalCalendarMutation,
} from 'mutations/externalCalendar';
import { useProviderCalendarsQuery } from 'views/Calendar/utils/queries';

const IMPORT_HC_LINK =
  'https://findheadway.zendesk.com/hc/articles/4409497691540';

const IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX = 'Import External Calendars';

const schema = Yup.object().shape({
  url: Yup.string()
    .url('Must be a valid URL')
    .required('Please provide a calendar URL'),
  calendarName: Yup.string().required('Please provide a calendar name'),
});

// the component(s) to used to allow a provider to import an external calendar
// are currently rendered in two places: the provider onboarding flow and their
// profile settings. we want to be able to understand the engagement with each of
// these separately
type CalendarImportDisplaySource = 'PROFILE' | 'ONBOARDING';

export const CalendarImportSettings: React.FC<
  React.PropsWithChildren<{ displaySource: CalendarImportDisplaySource }>
> = ({ displaySource }) => {
  const providerId = useProvider().id;
  const calendarsQuery = useProviderCalendarsQuery(providerId);

  return (
    <PageSection layout="grid.two-column">
      <div>
        <h2>
          <SectionHeader>Import external calendars</SectionHeader>
        </h2>

        <PageSectionSubText>
          <p>
            <BodyText>
              Avoid appointment conflicts by importing external calendars to
              Headway.
            </BodyText>
          </p>
          <p>
            <BodyText>
              Once you import calendars, Headway will automatically remove any
              listed openings that conflict with your external events so you
              won’t be double-booked.{' '}
              {calendarsQuery.data && calendarsQuery.data?.length > 0 ? (
                <>
                  Visit our help center for a{' '}
                  <Link
                    href={IMPORT_HC_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    step-by-step guide on importing external calendars.
                  </Link>
                </>
              ) : null}
            </BodyText>
            <p>
              <CalendarImportHelp
                providerId={providerId}
                displaySource={displaySource}
              />
            </p>
          </p>
        </PageSectionSubText>
      </div>
      <div
        css={{
          justifySelf: 'end',
        }}
      >
        <CalendarImportButton displaySource={displaySource} />
      </div>
    </PageSection>
  );
};

const TrackCalendarImportFormErrors = ({
  providerId,
  displaySource,
}: {
  providerId: number;
  displaySource: CalendarImportDisplaySource;
}) => {
  // this is a weird component. we render it inside the formik form but this
  // component doesn't actually render anything. we just use it
  // to track the state of the form as provider completes it

  const { isValid, errors } = useFormikContext<{
    calendarName: string;
    url: string;
  }>();

  useEffect(() => {
    if (!isValid) {
      trackEvent({
        name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Form Validation Failed`,
        properties: {
          providerId: providerId,
          calendarConfigurationSource: displaySource,
          nameFailed: !!errors.calendarName,
          urlFailed: !!errors.url,
        },
      } as ImportExternalCalendarsFormValidationFailedEvent);
    }
  }, [isValid, errors, providerId, displaySource]);

  return null;
};

export const CalendarImportButton = ({
  eventTrackingData,
  displaySource,
}: {
  eventTrackingData?: SetupAvailabilityStepEvent;
  displaySource: CalendarImportDisplaySource;
}) => {
  const providerId = useProvider().id;
  const calendarsQuery = useProviderCalendarsQuery(providerId);

  const syncExternalCalendarMutation = useSyncExternalCalendarMutation();
  const addExternalCalendarMutation = useAddExternalCalendarMutation({
    async onSuccess(createdCalendar, _variables, _context) {
      let initiatedSyncSuccessfully = false;
      trackEvent({
        name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Start Sync Initiated`,
        properties: {
          providerId: providerId,
          calendarConfigurationSource: displaySource,
          calendarId: createdCalendar.id,
        },
      } as ImportExternalCalendarsAPICallToStartSyncInitiatedEvent);
      try {
        await syncExternalCalendarMutation.mutateAsync({
          calendarId: createdCalendar.id,
        });
        initiatedSyncSuccessfully = true;
      } catch (e: unknown) {
        initiatedSyncSuccessfully = false;
        const message = getErrorMessage(e);
        notifyError(
          `Unable to sync this calendar.\n\n${message}`,
          { style: { whiteSpace: 'pre-line' } } // necessary to render linebreaks
        );
        logException(e);
      } finally {
        trackEvent({
          name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Start Sync Responded`,
          properties: {
            providerId: providerId,
            calendarConfigurationSource: displaySource,
            calendarId: createdCalendar.id,
            wasSuccessful: initiatedSyncSuccessfully,
          },
        } as ImportExternalCalendarsAPICallToStartSyncRespondedEvent);
      }
    },
  });

  const [isAddCalendarModalOpen, toggleAddCalendarModalOpen] = useToggle(false);
  return (
    <div css={{ ...theme.stack.vertical, gap: theme.spacing.x4 }}>
      {calendarsQuery.status === 'loading' || calendarsQuery.data?.length ? (
        <section css={{ ...theme.stack.vertical, gap: theme.spacing.x1 }}>
          {calendarsQuery.status === 'loading' ? (
            <React.Fragment>
              <Skeleton variant="rectangular" height={100} />
            </React.Fragment>
          ) : (
            calendarsQuery.data?.map((calendar, idx) => {
              return (
                <ProviderCalendarEditable
                  providerId={providerId}
                  calendar={calendar}
                  key={calendar.id}
                  initialExpanded={false}
                  displaySource={displaySource}
                />
              );
            })
          )}
        </section>
      ) : null}

      <Button
        variant="secondary"
        onPress={() => {
          trackEvent({
            name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Import Calendar Button Clicked`,
            properties: {
              providerId: providerId,
              calendarConfigurationSource: displaySource,
            },
          } as ImportExternalCalendarsImportCalendarButtonClickedEvent);
          toggleAddCalendarModalOpen();
        }}
      >
        Import calendar
      </Button>
      <Modal
        title="Import calendar"
        open={isAddCalendarModalOpen}
        onClose={() => {
          trackEvent({
            name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Modal Closed`,
            properties: {
              providerId: providerId,
              calendarConfigurationSource: displaySource,
            },
          } as ImportExternalCalendarsModalClosedEvent);
          toggleAddCalendarModalOpen();
        }}
      >
        <Alert
          css={{ marginBottom: legacyTheme.space.base }}
          severity="info"
          icon={<Security />}
        >
          Headway will periodically access this calendar to sync events to your
          Headway calendar. For privacy, only the scheduled event time will be
          considered.
        </Alert>
        <Formik
          initialValues={{ url: '', calendarName: '' }}
          validationSchema={schema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (eventTrackingData) {
              trackEvent(eventTrackingData);
            }
            trackEvent({
              name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Form Submitted`,
              properties: {
                providerId: providerId,
                calendarConfigurationSource: displaySource,
              },
            } as ImportExternalCalendarsFormSubmittedEvent);

            let createdCalendar: ProviderCalendarRead | undefined = undefined;
            try {
              trackEvent({
                name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Create Calendar Initiated`,
                properties: {
                  providerId: providerId,
                  calendarConfigurationSource: displaySource,
                },
              } as ImportExternalCalendarsAPICallToCreateCalendarInitiatedEvent);
              createdCalendar = await addExternalCalendarMutation.mutateAsync({
                providerId,
                url: values.url,
                calendarName: values.calendarName,
              });
              resetForm();
              toggleAddCalendarModalOpen();
            } catch (e: unknown) {
              const message = getErrorMessage(e);
              notifyError(
                `Unable to add this calendar.\n\n${message}`,
                { style: { whiteSpace: 'pre-line' } } // necessary to render linebreaks
              );
              logException(e);
            } finally {
              trackEvent({
                name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Create Calendar Responded`,
                properties: {
                  providerId: providerId,
                  calendarId: createdCalendar?.id,
                  calendarConfigurationSource: displaySource,
                  wasSuccessful: !!createdCalendar,
                },
              } as ImportExternalCalendarsAPICallToCreateCalendarRespondedEvent);
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form>
                <TrackCalendarImportFormErrors
                  providerId={providerId}
                  displaySource={displaySource}
                />
                <FormControl
                  data-testid="importCalendarName"
                  autoComplete="off"
                  name="calendarName"
                  label={<>Name</>}
                  component={TextField}
                />

                <FormControl
                  name="url"
                  data-testid="importCalendarUrl"
                  label={<>URL</>}
                  helpText={
                    <>
                      Need help finding this URL? Visit our{' '}
                      <Link
                        onClick={() => {
                          trackEvent({
                            name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Help Center URL Clicked`,
                            properties: {
                              providerId: providerId,
                              calendarConfigurationSource: displaySource,
                            },
                          } as ImportExternalCalendarsHelpCenterURLClickedEvent);
                        }}
                        href={IMPORT_HC_LINK}
                      >
                        help center
                      </Link>
                      .
                    </>
                  }
                  component={TextField}
                />

                <div
                  css={{
                    marginTop: legacyTheme.space.base,
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                    data-testid="importCalendarSubmit"
                    onPress={() => {
                      trackEvent({
                        name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Add New Calendar Clicked`,
                        properties: {
                          providerId: providerId,
                          calendarConfigurationSource: displaySource,
                          formValidationSuccess: isValid,
                        },
                      } as ImportExternalCalendarsAddNewCalendarClickedEvent);
                    }}
                  >
                    Add
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

interface ProviderCalendarEditableProps {
  providerId: number;
  calendar: ProviderCalendarRead;
  initialExpanded: boolean;
  displaySource: CalendarImportDisplaySource;
}

const ProviderCalendarEditable: React.FC<
  React.PropsWithChildren<ProviderCalendarEditableProps>
> = ({ providerId, calendar, initialExpanded, displaySource }) => {
  const formId = `calendar-update-${calendar.id}`;

  const syncCalendarMutation = useSyncExternalCalendarMutation({
    onError(e) {
      notifyError(`Unable to sync ${calendar.calendarName}`);
      logException(e);
    },
  });
  const updateCalendarMutation = useUpdateExternalCalendarMutation({
    onError(e) {
      notifyError('Unable to update the calendar settings');
      logException(e);
    },
  });

  const deleteCalendarMutation = useDeleteExternalCalendarMutation({
    onError(e) {
      notifyError('Unable to delete.');
      logException(e);
    },
  });

  return (
    <Accordion defaultExpanded={initialExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        css={{
          '& .MuiAccordionSummary-content': {
            width: '100%',
          },
        }}
      >
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <div css={{ fontWeight: 'bold' }}>{calendar.calendarName}</div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: legacyTheme.space.xs2,
              ' svg': {
                fontSize: legacyTheme.fontSize.base,
              },
            }}
          >
            {!calendar.isActive ? (
              'Paused'
            ) : calendar.syncStatus === ProviderCalendarSyncStatus.PENDING ? (
              <>
                Sync in progress
                <CircularProgress
                  size={legacyTheme.fontSize.base}
                  css={{ marginLeft: legacyTheme.space.xs2 }}
                />
              </>
            ) : calendar.syncStatus === ProviderCalendarSyncStatus.FAILURE ? (
              <>
                Unable to sync
                <ErrorOutline
                  css={{
                    marginLeft: legacyTheme.space.xs2,
                    color: legacyTheme.color.error,
                  }}
                />
              </>
            ) : calendar.syncedAt ? (
              <>
                Last synced {moment(calendar.syncedAt).fromNow()}
                <CheckCircleOutline
                  css={{
                    marginLeft: legacyTheme.space.xs2,
                    color: legacyTheme.color.primary,
                  }}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails css={{ display: 'block' }}>
        <section>
          <AccordionDetailsHeader>Calendar Details</AccordionDetailsHeader>
          <Formik
            initialValues={{
              calendarName: calendar.calendarName,
              subscriptionUrl: calendar.subscriptionUrl,
            }}
            onSubmit={async (values) => {
              const updatingUrl =
                values.subscriptionUrl !== calendar.subscriptionUrl;
              await updateCalendarMutation.mutateAsync({
                calendarId: calendar.id,
                updateData: values,
              });
              if (updatingUrl) {
                await syncCalendarMutation.mutateAsync({
                  calendarId: calendar.id,
                });
              }
            }}
            enableReinitialize={true}
          >
            {({ isSubmitting, dirty, resetForm }) => (
              <Form id={formId}>
                <FormControl
                  autoComplete="off"
                  name="calendarName"
                  label={<>Name</>}
                  component={TextField}
                />

                <FormControl
                  name="subscriptionUrl"
                  label={<>URL</>}
                  component={TextField}
                />

                <div
                  css={{
                    ...theme.stack.horizontal,
                    gap: theme.spacing.x2,
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    variant="secondary"
                    onPress={() => {
                      resetForm();
                    }}
                    disabled={!dirty || isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    form={formId}
                    type="submit"
                    variant="primary"
                    disabled={!dirty || isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </AccordionDetails>
      <AccordionDetails>
        <section>
          <AccordionDetailsHeader>Sync</AccordionDetailsHeader>
          <div>
            Start a sync of this calendar to pull in event changes made since
            our last automatic sync.
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: legacyTheme.space.base,
            }}
          >
            <Button
              type="submit"
              variant="primary"
              // endIcon={
              //   syncCalendarMutation.status === 'loading' ||
              //   calendar.syncStatus === ProviderCalendarSyncStatus.PENDING ? (
              //     <CircularProgress size={20} />
              //   ) : null
              // }
              disabled={
                syncCalendarMutation.status === 'loading' ||
                calendar.syncStatus === ProviderCalendarSyncStatus.PENDING
              }
              onPress={async () => {
                let initiatedSyncSuccessfully = false;
                trackEvent({
                  name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Sync Now Button Clicked`,
                  properties: {
                    providerId: providerId,
                    calendarId: calendar.id,
                    calendarConfigurationSource: displaySource,
                    lastSyncStatus: calendar.syncStatus,
                  },
                } as ImportExternalCalendarsSyncNowButtonClickedEvent);
                try {
                  trackEvent({
                    name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Manually Sync Calendar Initiated`,
                    properties: {
                      providerId: providerId,
                      calendarConfigurationSource: displaySource,
                      calendarId: calendar.id,
                    },
                  } as ImportExternalCalendarsAPICallToManuallySyncCalendarInitiatedEvent);
                  await syncCalendarMutation.mutateAsync({
                    calendarId: calendar.id,
                  });
                  initiatedSyncSuccessfully = true;
                } catch (e: unknown) {
                  initiatedSyncSuccessfully = false;
                  const message = getErrorMessage(e);
                  notifyError(
                    `Unable to sync this calendar.\n\n${message}`,
                    { style: { whiteSpace: 'pre-line' } } // necessary to render linebreaks
                  );
                  logException(e);
                } finally {
                  trackEvent({
                    name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Manually Sync Calendar Responded`,
                    properties: {
                      providerId: providerId,
                      calendarId: calendar.id,
                      calendarConfigurationSource: displaySource,
                      wasSuccessful: initiatedSyncSuccessfully,
                    },
                  } as ImportExternalCalendarsAPICallToManuallySyncCalendarRespondedEvent);
                }
              }}
            >
              Sync now
            </Button>
          </div>
        </section>
        <section css={{ marginTop: legacyTheme.space.lg }}>
          <AccordionDetailsHeader>Remove Calendar</AccordionDetailsHeader>
          <div>
            Remove this calendar and all of its events from your Sigmund
            calendar.
          </div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginTop: legacyTheme.space.base,
            }}
          >
            <Button
              variant="primary"
              type="submit"
              disabled={deleteCalendarMutation.status === 'loading'}
              onPress={async () => {
                trackEvent({
                  name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Remove Calendar Clicked`,
                  properties: {
                    providerId: providerId,
                    calendarId: calendar.id,
                    lastSyncStatus: calendar.syncStatus,
                    calendarConfigurationSource: displaySource,
                  },
                } as ImportExternalCalendarsRemoveCalendarClickedEvent);
                let deletedSuccessfully = false;

                try {
                  trackEvent({
                    name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Remove Calendar Initiated`,
                    properties: {
                      providerId: providerId,
                      calendarId: calendar.id,
                      calendarConfigurationSource: displaySource,
                    },
                  } as ImportExternalCalendarsAPICallToRemoveCalendarInitiatedEvent);
                  await deleteCalendarMutation.mutateAsync({
                    providerId,
                    calendarId: calendar.id,
                  });
                  deletedSuccessfully = true;
                } catch (e: unknown) {
                  deletedSuccessfully = false;
                  const message = getErrorMessage(e);
                  notifyError(
                    `Unable to remove the calendar.\n\n${message}`,
                    { style: { whiteSpace: 'pre-line' } } // necessary to render linebreaks
                  );
                  logException(e);
                } finally {
                  trackEvent({
                    name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - API Call to Remove Calendar Responded`,
                    properties: {
                      providerId: providerId,
                      calendarId: calendar.id,
                      calendarConfigurationSource: displaySource,
                      wasSuccessful: deletedSuccessfully,
                    },
                  } as ImportExternalCalendarsAPICallToRemoveCalendarRespondedEvent);
                }
              }}
              // endIcon={
              //   deleteCalendarMutation.status === 'loading' ? (
              //     <CircularProgress size={20} />
              //   ) : null
              // }
            >
              Remove
            </Button>
          </div>
        </section>
      </AccordionDetails>
    </Accordion>
  );
};

export const CalendarImportHelp: React.FC<
  React.PropsWithChildren<{
    providerId: number;
    displaySource: CalendarImportDisplaySource;
  }>
> = ({ providerId, displaySource }) => {
  const [isOpen, toggle] = useToggle(false);
  const [expandedAccordion, setExpandedAccordion] = React.useState<
    'google' | 'simplepractice' | 'outlook'
  >('google');

  const handleAccordionExpand = (panel: typeof expandedAccordion) => () => {
    setExpandedAccordion(panel);
  };

  return (
    <React.Fragment>
      <Button
        variant="link"
        onPress={() => {
          trackEvent({
            name: `${IMPORT_CALENDAR_ANALYTICS_EVENT_PREFIX} - Learn More Button Clicked`,
            properties: {
              providerId,
              calendarConfigurationSource: displaySource,
            },
          } as ImportExternalCalendarsLearnMoreButtonClickedEvent);
          toggle();
        }}
      >
        Learn more
      </Button>

      <Modal title="How to Import your Calendar" open={isOpen} onClose={toggle}>
        <div css={{ minHeight: 726, display: 'flex', flexDirection: 'column' }}>
          <div>
            <Accordion
              expanded={expandedAccordion === 'google'}
              onChange={handleAccordionExpand('google')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Google Calendar
              </AccordionSummary>
              <AccordionDetails css={{ display: 'block' }}>
                <section>
                  <strong>Within Google:</strong>
                  <ol>
                    <li>Log into Google calendar</li>
                    <li>Find “My Calendars” on the left-hand menu</li>
                    <li>
                      Hover over the calendar you’d like to share and click the
                      three vertical dots
                    </li>
                    <li>
                      Click “Settings and sharing” &#8212; then, choose
                      “Integrate calendar” on the left-hand menu
                    </li>
                    <li>
                      <p>
                        Copy the “Secret address in iCal format” link with the
                        clipboard icon ({' '}
                        <FileCopyOutlined css={{ width: 18, height: 18 }} />)
                      </p>
                      <p
                        css={{
                          color: legacyTheme.color.textGray,
                          fontSize: legacyTheme.fontSize.sm,
                        }}
                      >
                        <strong>Note:</strong> You’ll be asked to approve the
                        security message before sharing. As a reminder, we will
                        not be collecting or displaying personal data or
                        information.
                      </p>
                    </li>
                  </ol>
                  <strong>Within Headway:</strong>
                  <ol>
                    <li>
                      Within your provider portal, click “Calendar” on the
                      left-hand menu
                    </li>
                    <li>Click “Import calendar”</li>
                    <li>Paste your Google calendar URL</li>
                  </ol>
                </section>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === 'simplepractice'}
              onChange={handleAccordionExpand('simplepractice')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                SimplePractice
              </AccordionSummary>
              <AccordionDetails css={{ display: 'block' }}>
                <section>
                  <strong>Within SimplePractice:</strong>
                  <ol>
                    <li>Log into your SimplePractice account.</li>
                    <li>
                      Click My Account, then navigate to Settings and choose
                      “Calendar”
                    </li>
                    <li>
                      Toggle the <strong>Calendar Sync</strong> on. This will
                      generate a secure calendar URL you’ll use to sync with
                      calendar apps.
                    </li>
                  </ol>
                  <strong>Within Headway:</strong>
                  <ol>
                    <li>
                      Within your provider portal, click “Calendar” on the
                      left-hand menu
                    </li>
                    <li>Click “Import calendar”</li>
                    <li>Paste your Google calendar URL</li>
                  </ol>
                </section>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expandedAccordion === 'outlook'}
              onChange={handleAccordionExpand('outlook')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Outlook
              </AccordionSummary>
              <AccordionDetails css={{ display: 'block' }}>
                <section>
                  <strong>Within Outlook:</strong>
                  <ol>
                    <li>Log into your Outlook calendar.</li>
                    <li>
                      Click the <strong>Settings</strong> wheel, then{' '}
                      <strong>“View all Outlook settings”</strong>
                    </li>
                    <li>
                      Click <strong>Shared calendars</strong>
                    </li>
                    <li>
                      Scroll down to <strong>”Publish a calendar”</strong> and
                      select your calendar from the drop-down menu.
                    </li>
                    <li>
                      Click <strong>“Publish”</strong> &#8212; from there, two
                      links will pop up. Be sure to copy the{' '}
                      <strong>ICS link</strong>
                    </li>
                  </ol>
                  <strong>Within Headway:</strong>
                  <ol>
                    <li>
                      Within your provider portal, click “Calendar” on the
                      left-hand menu
                    </li>
                    <li>Click “Import Calendar”</li>
                    <li>Paste your Google calendar URL</li>
                  </ol>
                </section>
              </AccordionDetails>
            </Accordion>
          </div>
          <Button
            variant="primary"
            onPress={() => {
              toggle();
            }}
          >
            Done
          </Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
