import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { getUserPaymentMethodLast4 } from '@headway/shared/utils/userPaymentMethods';
import { theme } from '@headway/ui/theme';

export const PaymentFailedAlert = ({
  patientFirstName,
  paymentMethod,
  variant,
}) => {
  const last4 = paymentMethod ? getUserPaymentMethodLast4(paymentMethod) : '';

  const text = (
    <>
      {patientFirstName}’s payment method {last4 ? `(ending in ${last4})` : ''}{' '}
      failed. Please contact them to update their payment method. You will not
      be paid for this session until their payment succeeds.
    </>
  );

  return variant === 'mui' ? (
    <Alert severity="error" css={{ marginTop: theme.space.base }}>
      <AlertTitle>Client Payment Failed</AlertTitle>
      {text}
    </Alert>
  ) : (
    <GuidanceCard variant="error">{text}</GuidanceCard>
  );
};
