import { Card, CardContent } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';

import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/ui/theme';

import { useProvider } from 'hooks/useProvider';

import { DataField } from './CoiDataFields';
import {
  CoiPathwayType,
  formatNumber,
  MalpracticeValuesType,
} from './CoiUtils';

const TextFields = ({
  pqv,
}: {
  pqv?: ProviderQuestionnaireVerificationRead;
}) => {
  const provider = useProvider();
  return (
    <div>
      <CardContent>
        <div className="grid gap-y-4">
          <div className="text-base">
            <strong>Your coverage details:</strong>
          </div>
          <FormControl
            name="malpracticeProvider"
            component={TextField}
            label="Malpractice insurance provider"
          />
          <FormControl
            name="nameInsured"
            component={DataField}
            fieldContent={provider.name}
            fieldName="Name insured"
          />
          <FormControl
            name="effectiveDate"
            component={DataField}
            fieldContent={moment(
              pqv?.responseJson?.coverageStartDateFound
            ).format('MM/DD/YYYY')}
            fieldName="Effective date"
          />
          <FormControl
            name="expirationDate"
            component={DataField}
            fieldContent={moment(
              pqv?.responseJson?.coverageEndDateFound
            ).format('MM/DD/YYYY')}
            fieldName="Expiration date"
          />
          <FormControl
            name="occurrenceLimit"
            component={DataField}
            fieldContent={
              formatNumber(pqv?.responseJson?.perClaimCoverageLimitFound) ?? ''
            }
            fieldName="Occurrence limit"
          />
          <FormControl
            name="aggregateLimit"
            component={DataField}
            fieldContent={
              formatNumber(pqv?.responseJson?.aggregateCoverageLimitFound) ?? ''
            }
            fieldName="Aggregate limit"
          />
        </div>
      </CardContent>
    </div>
  );
};

export const CoiSuccess = ({
  coiVerification,
  coiPathway,
}: {
  coiVerification?: ProviderQuestionnaireVerificationRead;
  coiPathway: CoiPathwayType;
}) => {
  const provider = useProvider();
  const { values, setFieldValue } = useFormikContext<MalpracticeValuesType>();
  if (coiPathway === CoiPathwayType.SUCCESS) {
    values.nameInsured = coiVerification?.responseJson?.nameInsuredFound
      ? provider.name
      : '';
    values.aggregateLimit =
      coiVerification?.responseJson?.aggregateCoverageLimitFound;
    values.occurrenceLimit =
      coiVerification?.responseJson?.perClaimCoverageLimitFound;
    values.expirationDate = coiVerification?.responseJson?.coverageEndDateFound;
    values.effectiveDate =
      coiVerification?.responseJson?.coverageStartDateFound;
  }
  return (
    <Form>
      {coiPathway === CoiPathwayType.SUCCESS && (
        <Card variant="outlined" sx={{ marginBottom: 1 }}>
          {<TextFields pqv={coiVerification} />}
        </Card>
      )}
      {coiPathway === CoiPathwayType.SUBMITTED && (
        <GuidanceCard variant="positive" layout="vertical">
          Your Certificate of Insurance has been successfully submitted!
        </GuidanceCard>
      )}
    </Form>
  );
};
