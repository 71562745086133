import React, { useState } from 'react';

import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { UserRead } from '@headway/api/models/UserRead';
import { Badge } from '@headway/helix/Badge';
import { IconError } from '@headway/helix/icons/Error';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { trackPageView } from '@headway/shared/utils/analytics';
import { Tooltip } from '@headway/ui';

import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import {
  wasPatientInNoDataPrelimPricing,
  wasPatientInOldDataPrelimPricing,
} from 'utils/prelimPricing';

import { useProvider } from '../../hooks';
import { useProviderPrice } from '../../hooks/useProviderPrice';
import { PatientInsuranceStatusToConfigMap } from './utils/patientInsuranceStatus';

interface PatientInsuranceStatusLabelProps {
  providerId: number;
  insuranceStatus: PatientInsuranceOrEAPStatus;
  insuranceStatusCheckState: PatientInsuranceOrEAPStatus;
  patientUser?: UserRead;
}

export const PatientInsuranceStatusLabel: React.FC<
  React.PropsWithChildren<PatientInsuranceStatusLabelProps>
> = ({
  providerId,
  insuranceStatus,
  insuranceStatusCheckState,
  patientUser,
}) => {
  const provider = useProvider();
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction, restrictionDate } =
    useMSCGuardrail();

  const isNonMSCProviderWillNeedCredentialing =
    insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK &&
    (insuranceStatusCheckState === PatientInsuranceOrEAPStatus.OUT_OF_NETWORK ||
      insuranceStatusCheckState ===
        PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE ||
      insuranceStatusCheckState ===
        PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE ||
      insuranceStatusCheckState ===
        PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED) &&
    !isMSCEnabled;

  const { chipLabel, badgeVariant, getExplanation } =
    isNonMSCProviderWillNeedCredentialing
      ? PatientInsuranceStatusToConfigMap[insuranceStatusCheckState]
      : PatientInsuranceStatusToConfigMap[insuranceStatus];
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId,
        patientUserId: patientUser?.id,
        includeHiddenCarriers: true,
      },
      { enabled: !!patientUser?.id }
    );
  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;
  const { data: providerPriceData, isLoading: isProviderPriceLoading } =
    useProviderPrice(
      { provider, client: patientUser },
      { enabled: isInOldDataPrelimPricing }
    );
  const wasInNoDataPrelimPricing = wasPatientInNoDataPrelimPricing(
    patientUser?.activeUserInsurance,
    patientUser,
    providerId
  );
  const wasInOldDataPrelimPricing = wasPatientInOldDataPrelimPricing(
    patientUser?.activeUserInsurance,
    patientUser,
    providerId
  );
  const [open, setOpen] = useState<boolean>();

  return (
    <Tooltip
      title={getExplanation(
        patientUser,
        isMSCEnabled || isMSCGuardrailWarning || isMSCGuardrailRestriction,
        undefined,
        matchingProviderFrontEndCarrier?.frontEndCarrier.name,
        isMSCGuardrailWarning,
        restrictionDate
      )}
      open={open}
      onOpen={() => {
        setOpen(true);
        if (
          (isInNoDataPrelimPricing || isInOldDataPrelimPricing) &&
          !isProviderPriceLoading &&
          patientUser &&
          patientUser.activeUserInsurance
        )
          trackPageView({
            name: 'Verification In Progress Banner Viewed',
            properties: {
              providerId: providerId,
              patientUserId: patientUser.id,
              userInsuranceId: `${patientUser.activeUserInsuranceId}`,
              prelimPricingType: `${patientUser.activeUserInsurance.prelimPricingType}`,
              copyVariant: '',
              presumptiveAccumulatorsApplied:
                providerPriceData?.priceCalculationInfo
                  ?.presumptiveAccumulators ?? [],
            },
          });
      }}
      onClose={() => setOpen(false)}
    >
      <div>
        <Badge
          variant={badgeVariant}
          icon={badgeVariant === 'negative' ? IconError : undefined}
        >
          {(wasInNoDataPrelimPricing || wasInOldDataPrelimPricing) &&
          insuranceStatus ===
            PatientInsuranceOrEAPStatus.MATCHING_FAILED_VERIFICATION
            ? 'Ineligible'
            : isNonMSCProviderWillNeedCredentialing
            ? 'Missing telehealth address'
            : chipLabel}
        </Badge>
      </div>
    </Tooltip>
  );
};
