import React from 'react';

import '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';

export const AdmittingProviderInfoQuestions = () => {
  return (
    <>
      <SectionHeader>Hospital Affilitations</SectionHeader>
      <FormControl
        name="admittingProviderName"
        component={TextField}
        label="Please specify the name of the provider who admits for you."
      />
      <FormControl
        name="admittingProviderSpecialty"
        component={TextField}
        label="Please specify the specialty of the provider who admits for you."
      />
      <FormControl
        name="admittingProviderHospitalName"
        component={TextField}
        label="Please specify the name of the hospital to which this provider admits for you."
      />
    </>
  );
};
