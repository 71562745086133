import { useContext } from 'react';

import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';

import { useProviderEvent } from './useProviderEvent';

export const useSelectedEvent = () => {
  const { selectedEventVirtualId } = useContext(SelectedEventContext);
  const { data: event, isLoading } = useProviderEvent({
    eventIdOrVirtualId: selectedEventVirtualId,
  });

  return { event, isLoading };
};
