import { useFormikContext } from 'formik';
import React from 'react';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';

import {
  PracticeLocationArray,
  PracticeLocationMap,
  SetSelectedPracticeLocations,
  TelehealthStates,
} from './Practice';
import { getProviderAddedLocationArray } from './util';

/**
 * Updates the selectedProviderLocations changing,
 * -  asserts that the providerAddresses are always selected
 * -  sets isPrimaryPracticeState = true
 * -  if there are more than one address per state, isPrimaryPracticeState = false
 * -  updates the telehealthStates map accordingly
 *
 * @param locationMap
 * @param selectedPracticeLocations
 * @param setSelectedPracticeLocations
 * @param provider
 * @param selectedOptions
 */
const setNewProviderSelectedLocations = (
  telehealthStates: TelehealthStates,
  providerSelectedStates: UnitedStates[],
  locationMap: PracticeLocationMap,
  selectedPracticeLocations: PracticeLocationArray,
  provider: ProviderRead,
  selectedOptions: string[],
  setSelectedPracticeLocations: SetSelectedPracticeLocations,
  setTelehealthStates: (telehealthStates: TelehealthStates) => void
) => {
  // Grab our current selected provider address location info
  const providerAddressesLocationArray = getProviderAddedLocationArray(
    provider
  )?.map((location) => {
    const selectedPracticeInfo = selectedPracticeLocations.find(
      (selectedLocation) =>
        selectedLocation.locationHash === location.locationHash
    );
    return selectedPracticeInfo ?? location;
  });

  // Filter out the provider address location hash
  const selectedOptionsProviderAddressRemoved = selectedOptions.filter(
    (option) =>
      !providerAddressesLocationArray?.find(
        (location) => location.locationHash === option
      )
  );

  // Create our new selected locations array
  // 1. The providerAddresses will always be selected, so we add that to
  // the beginning of the array
  // 2. We grab our location objects from the map and add that in
  const newSelectedPracticeLocations: PracticeLocationArray = [
    ...(providerAddressesLocationArray ?? []),
    ...selectedOptionsProviderAddressRemoved.reduce(
      (arr: PracticeLocationArray, option: string) => {
        const existingLocationData = selectedPracticeLocations.find(
          (location) => location.locationHash === option
        );
        arr.push({
          ...(existingLocationData ?? locationMap[option]),
          isPrimaryPracticeForState: true,
        });
        return arr;
      },
      []
    ),
  ];

  // Count which states have more than one address
  const statesWithMultipleAddresses = Object.entries(
    newSelectedPracticeLocations.reduce(
      (stateMap, value) => {
        if (value.state) {
          stateMap[value.state] = (stateMap[value.state] ?? 0) + 1;
        }
        return stateMap;
      },
      {} as { [key: string]: number }
    )
  )
    .filter((value) => value[1] > 1)
    .map((value) => value[0]);

  //If there are multiple selected for a state we want to set all of their location.isPrimary to false
  const parsedNewSelectedPracticeLocations = newSelectedPracticeLocations.map(
    (value) => {
      const addressInfo = { ...value };
      if (value.state && !statesWithMultipleAddresses.includes(value.state)) {
        addressInfo.isPrimaryPracticeForState = false;
      }

      return addressInfo;
    }
  );

  setSelectedPracticeLocations(parsedNewSelectedPracticeLocations);

  // Recompute the telehealth states
  const newTelehealthStates: TelehealthStates = {};

  providerSelectedStates.forEach((state) => {
    if (!state) {
      return;
    }

    const stateExistsInNewLocationPick =
      parsedNewSelectedPracticeLocations.find((location) =>
        location.state
          ? abbreviationToStateEnum[location.state] === state
          : false
      );

    const stateExistsInPreviousLocationPick = selectedPracticeLocations.find(
      (location) =>
        location.state
          ? abbreviationToStateEnum[location.state] === state
          : false
    );

    if (!stateExistsInNewLocationPick && !stateExistsInPreviousLocationPick) {
      newTelehealthStates[state] = telehealthStates[state];
    } else {
      newTelehealthStates[state] = false;
    }
  });

  setTelehealthStates(newTelehealthStates);
};

export const PracticeLocationsSelection = ({
  provider,
  locationMap,
  selectedPracticeLocations,
  setSelectedPracticeLocations,
  providerSelectedStates,
}: {
  provider: ProviderRead;
  selectedPracticeLocations: PracticeLocationArray;
  locationMap: PracticeLocationMap;
  setSelectedPracticeLocations: any;
  providerSelectedStates: UnitedStates[];
}) => {
  const { values, setFieldValue } =
    useFormikContext<ProviderQuestionnaireRawData>();
  const selectedPracticeLocationsHashes = selectedPracticeLocations
    .filter((location) => !!location.locationHash)
    .map((location) => location.locationHash);

  if (Object.keys(locationMap).length === 0) {
    return null;
  }

  return (
    <>
      <SectionHeader>
        Next, select the locations you’d like to use on Headway
      </SectionHeader>
      <CheckboxGroup
        name="selectedPracticeLocations"
        label="Which practice locations would you like to use with Headway?"
        size="small"
        value={selectedPracticeLocationsHashes as string[]}
        onChange={(selectedOptions) =>
          setNewProviderSelectedLocations(
            values['telehealthStates'] ?? {},
            providerSelectedStates,
            locationMap,
            selectedPracticeLocations,
            provider,
            selectedOptions,
            setSelectedPracticeLocations,
            (telehealthStates) =>
              setFieldValue('telehealthStates', telehealthStates)
          )
        }
      >
        {Object.entries(locationMap).map((practiceLocation) => {
          const locationHash = practiceLocation[0];
          const practice = practiceLocation[1];
          return (
            <>
              <Checkbox value={locationHash} disabled={!practice.isCaqhAddress}>
                {locationHash}
              </Checkbox>
              {!practice.isCaqhAddress && (
                <div
                  css={{
                    marginLeft: theme.spacing.x7,
                    marginTop: '-8px',
                  }}
                >
                  <SubBodyText color="disabledGray">
                    We found this location in your settings.
                  </SubBodyText>
                </div>
              )}
            </>
          );
        })}
      </CheckboxGroup>
    </>
  );
};
