import { RadioGroup } from '@mui/material';
import React from 'react';

import { FieldControlContext } from '@headway/ui/form/FieldControl';

import { useField } from './useField';

export const FieldRadioGroup = (props: any) => {
  const groupRef = React.useRef(null);
  const focusRef = React.useRef(null);
  const { name, id } = React.useContext(FieldControlContext);
  const [field] = useField({ name, ref: groupRef, focusRef, ...props });

  return (
    <RadioGroup
      ref={groupRef}
      actions={focusRef}
      id={id}
      data-testid={name}
      aria-labelledby={`${id}-label`}
      {...props}
      {...field}
      // MUI assumes a RadioGroup is uncontrolled if it's value is undefined so explicitly check for it here
      // https://github.com/mui-org/material-ui/blob/10453c477f122b08a73d99783723c2a3d3f389b3/packages/material-ui-utils/src/useControlled.js#L6
      value={field.value === undefined ? null : field.value}
    />
  );
};
