import {
  FieldInputProps,
  FieldMetaProps,
  useField as useFieldFormik,
} from 'formik';
import React, { useContext, useEffect } from 'react';

import { FormContext, RegisteredRef } from './Form';
import { noop } from './utils';

export const useField = <Val = any>({
  name,
  onChange = noop,
  onBlur = noop,
  ref,
  focusRef,
}: {
  name: string;
  onChange?: (...e: any) => any;
  onBlur?: (e: any) => any;
  ref?: React.RefObject<RegisteredRef>;
  focusRef?: React.RefObject<{ focus: (options?: FocusOptions) => void }>;
}): [FieldInputProps<Val>, FieldMetaProps<Val>] => {
  const { registerField, unregisterField } = useContext(FormContext);
  const [field, meta] = useFieldFormik(name);

  useEffect(() => {
    if (!ref) return;

    const focus = (options?: FocusOptions) => {
      // focusRef exists because MUI's RadioGroup only exposes a .focus() method on
      // its actions ref prop but the dom node is from a different ref.
      if (focusRef && focusRef.current) {
        focusRef.current.focus(options);
      } else if (ref.current) {
        ref.current.focus(options);
      }
    };
    registerField(name, ref, focus);
    return () => {
      unregisterField(name);
    };
  }, []);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent) => {
      onChange(event);
      field.onChange(event);
    },
    [onChange, field.onChange]
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent) => {
      onBlur(event);
      field.onBlur(event);
    },
    [onBlur, field.onBlur]
  );

  return [
    {
      ...field,
      onChange: handleChange,
      onBlur: handleBlur,
    },
    meta,
  ];
};
