import { CircularProgress, FormLabel } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { FieldArray, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { License } from '@headway/api/models/License';
import { Malpractice } from '@headway/api/models/Malpractice';
import { MalpracticeMinimums } from '@headway/api/models/MalpracticeMinimums';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { VerificationType } from '@headway/api/models/VerificationType';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { ValidationState } from '@headway/helix/forms';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { statesToDisplayNames } from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  getSelectedLicenseList,
  hasPrescriberLicenseType,
} from '@headway/shared/utils/licenseHelper';
import { licenseTypes } from '@headway/shared/utils/marketLicenseTypes';
import {
  getSupportedStates,
  isProviderInAnyStates,
  isProviderInState,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  getCurrentCoiPqvs,
  getInsuranceCoiS3Key,
} from '@headway/shared/utils/providerQuestionnaireVerification';
import { Modal } from '@headway/ui';
import { FieldControl, FieldDropzone, FieldErrorText } from '@headway/ui/form';

import { onDropFiles, YesNo } from '../../../../utils/providerQuestionnaire';
import {
  CoiErrorType,
  CoiProviderQuestionnaireVerificationError,
  filterCoiPqvErrorsBasedOnCurrentPqvs,
  NonBlockingCoiErrorTypes,
} from '../../../../utils/questionnaireVerificationHelpers';
import {
  FieldArraySection,
  FieldArraySectionItemContainer,
} from '../../FieldArraySection';
import { OnBeforeSubmitError } from '../../OnBeforeSubmitErrorCard';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { CAQH_WEBSITE_URL } from '../../utils/intakeQuestionnaireConstants';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { ConfirmDocuments } from '../components/confirmationModal/ConfirmDocuments';
import { ConfirmName } from '../components/confirmationModal/ConfirmName';
import { MalpracticeHeader } from './MalpracticeHeader';

export enum ConfirmationModalPage {
  CONFIRM_DOCUMENTS = 'CONFIRM_DOCUMENTS',
  CONFIRM_NAME = 'CONFIRM_NAME',
  PAYMENT_DETAIL = 'PAYMENT_DETAIL',
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const statesThatRequiresSoverignImmunityDoc = [
  UnitedStates.WASHINGTON,
  UnitedStates.OREGON,
];
const isSovereignImmunityDocRequiredForProvider = (provider: ProviderRead) =>
  isProviderInAnyStates(provider, statesThatRequiresSoverignImmunityDoc);

const isInsuranceNonExpired = (insurance: Malpractice) => {
  return (
    !!insurance.liabilityInsuranceExpirationDate &&
    moment(insurance.liabilityInsuranceExpirationDate).isAfter()
  );
};

const isInsuranceInFuture = (insurance: Malpractice) => {
  return (
    !!insurance.liabilityInsuranceEffectiveDate &&
    moment(insurance.liabilityInsuranceEffectiveDate).isAfter()
  );
};

const isInsuranceCurrentlyActive = (insurance: Malpractice) => {
  return (
    isInsuranceNonExpired(insurance) &&
    !!insurance.liabilityInsuranceEffectiveDate &&
    moment(insurance.liabilityInsuranceEffectiveDate)
      .subtract(3, 'days')
      .isBefore()
  );
};

export const getLiabilityLimitsSchema = (
  malpracticeMinimums?: MalpracticeMinimums
) => {
  let liabilityInsuranceOccurrenceLimit = Yup.number().required(
    'Occurrence Limit is required'
  );
  let liabilityInsuranceAggregateLimit = Yup.number().required(
    'Aggregate Limit is required'
  );

  if (malpracticeMinimums) {
    liabilityInsuranceOccurrenceLimit = liabilityInsuranceOccurrenceLimit.min(
      malpracticeMinimums.occurrenceLimit,
      `You're required to have coverage of at least ${formatter.format(
        malpracticeMinimums.occurrenceLimit
      )} per occurrence.`
    );
    liabilityInsuranceAggregateLimit = liabilityInsuranceAggregateLimit.min(
      malpracticeMinimums.aggregateLimit,
      `You're required to have coverage of at least ${formatter.format(
        malpracticeMinimums.aggregateLimit
      )} in aggregate.`
    );
  }

  return {
    liabilityInsuranceOccurrenceLimit,
    liabilityInsuranceAggregateLimit,
  };
};

export const createCoiPqv = async (
  coiS3Key: string,
  questionnaireId: number
) => {
  return await ProviderQuestionnaireApi.createProviderQuestionnaireVerification(
    questionnaireId,
    {
      type: VerificationType.CERTIFICATE_OF_INSURANCE,
      requestJson: {
        coiS3Key,
      },
    }
  );
};

const MALPRACTICE_EXAMPLES: { [key: string]: string } = {
  ACE: 'https://headway-redacted-malpractice.s3.amazonaws.com/ace.png',
  'Allied World Insurance':
    'https://headway-redacted-malpractice.s3.amazonaws.com/allied-world-insurance.jpg',
  'American Casualty':
    'https://headway-redacted-malpractice.s3.amazonaws.com/american-casualty.png',
  'American Home Assurance':
    'https://headway-redacted-malpractice.s3.amazonaws.com/american-casualty.png',
  'American Professional':
    'https://headway-redacted-malpractice.s3.amazonaws.com/american-professional.png',
  Berxi: 'https://headway-redacted-malpractice.s3.amazonaws.com/berxi.png',
  'Brown & Brown of Florida':
    'https://headway-redacted-malpractice.s3.amazonaws.com/Brown-&-Brown-of-Florida.jpg',
  "Certain Underwriters at Lloyd's, London":
    'https://headway-redacted-malpractice.s3.amazonaws.com/certain-underwriters-lloyds-london.png',
  'CM&F Group':
    'https://headway-redacted-malpractice.s3.amazonaws.com/cm&f-group.png',
  CoverWallet:
    'https://headway-redacted-malpractice.s3.amazonaws.com/cover-wallet.png',
  'CPH and Associates':
    'https://headway-redacted-malpractice.s3.amazonaws.com/cph-and-associates.jpg',
  'Fair American':
    'https://headway-redacted-malpractice.s3.amazonaws.com/fair-american',
  'Great American':
    'https://headway-redacted-malpractice.s3.amazonaws.com/great-american.png',
  'Healthcare Providers Service Organization':
    'https://headway-redacted-malpractice.s3.amazonaws.com/healthcare-providers-service-organization.jpg',
  Hiscox: 'https://headway-redacted-malpractice.s3.amazonaws.com/hiscox.png',
  'Liberty Insurance Underwriters':
    'https://headway-redacted-malpractice.s3.amazonaws.com/liberty-insurance-underwriters.png',
  'Lockton Affinity':
    'https://headway-redacted-malpractice.s3.amazonaws.com/Lockton-Affinity.jpg',
  'Mercer Consumer':
    'https://headway-redacted-malpractice.s3.amazonaws.com/mercer-consumer.png',
  'NASW Risk Retention Group':
    'https://headway-redacted-malpractice.s3.amazonaws.com/nasw-rrg.png',
  'NOW Insurance':
    'https://headway-redacted-malpractice.s3.amazonaws.com/now-insurance.png',
  'Nurses Service Organization (NSO)':
    'https://headway-redacted-malpractice.s3.amazonaws.com/nso.png',
  'Philadelphia Indemnity Insurance Company':
    'https://headway-redacted-malpractice.s3.amazonaws.com/philadelphia-indemnity.png',
  PRMS: 'https://headway-redacted-malpractice.s3.amazonaws.com/prms.png',
  Proliability:
    'https://headway-redacted-malpractice.s3.amazonaws.com/proliability.png',
  'Proselect Insurance':
    'https://headway-redacted-malpractice.s3.amazonaws.com/proselect-insurance.png',
  'Trust Risk Management':
    'https://headway-redacted-malpractice.s3.amazonaws.com/trust.png',
  // Show CPH Document by default
  Other:
    'https://headway-redacted-malpractice.s3.amazonaws.com/cph-and-associates.jpg',
};

const getCoiUploadCountStorageKey = (questionnaireId: number) =>
  `coiUploadCount-${questionnaireId}`;

const coiAlgorithmStatuses = {
  IN_PROGRESS: 'IN_PROGRESS',
  COI_VALIDATION_COMPLETE: 'COI_VALIDATION_COMPLETE',
};

const nonCnaNameInsuredEmail = (name: string) =>
  `Hi, \n\nI am currently in the process of being credentialed as a part of Headway’s provider group. One of the requirements is proof that I am an individual covered by my professional liability insurance policy. \n\nAs a result, I would like a revised copy of my certificate of insurance, listing me under the Insured section. \n\nThis requirement is currently holding up my credentialing process, so any quick resolution would be greatly appreciated. Thank you in advance for your help!\n\nBest,\n${name}`;
const cnaNameInsuredEmail = (name: string) =>
  `Hi,\n\nI am currently in the process of being credentialed as a part of Headway’s provider group. One of the requirements is proof that I am an individual covered by my professional liability insurance policy.\n\nAs a result, I would like a revised copy of my certificate of insurance, listing me under Named Insured. Alternatively, Headway will also accept an attachment to the policy that lists me as an employee covered by the policy.\n\nThis requirement is currently holding up my credentialing process, so any quick resolution would be greatly appreciated. Thank you in advance for your help!\n\nBest,\n${name}`;
const coverageLimitEmail = (
  name: string,
  perClaimLimit: string,
  aggregateLimit: string
) =>
  `Hi,\n\nI am currently in the process of being credentialed as a part of Headway’s provider group. One of the requirements is that I have at least ${perClaimLimit} in each claim/occurrence coverage limits and at least ${aggregateLimit} in aggregate coverage.\n\nAs a result, I would like a revised policy and copy of my certificate of insurance, with the updated coverage limits.\n\nThis requirement is currently holding up my credentialing process, so any quick resolution would be greatly appreciated. Thank you in advance for your help!\n\nBest,\n${name}`;

const occupationEmail = (name: string, licenseTypeDisplayNames?: Set<string>) =>
  `Hi, 
I am currently in the process of being credentialed as a part of Headway’s provider group. One of the requirements is that my policy offers coverage as at least a Master’s level clinician. 
  
As a result, I would like a revised copy of my certificate of insurance, listing my occupation as ${
    licenseTypeDisplayNames && licenseTypeDisplayNames.size > 0
      ? 'an ' + Array.from(licenseTypeDisplayNames).join(', an ')
      : 'my current license'
  }. 
  
This requirement is currently holding up my credentialing process, so any quick resolution would be greatly appreciated. Thank you in advance for your help!
  
Best,
${name}`;
const CoiErrorMessage: React.FC<
  React.PropsWithChildren<{
    error: CoiProviderQuestionnaireVerificationError;
    providerStates: UnitedStates[];
    malpracticeMinimums?: MalpracticeMinimums;
    isPrescriber: boolean;
    providerName: string;
    insuranceCarrier: string;
    policyNumber: string;
    licenseTypeDisplayNames?: Set<string>;
    openExampleModal: () => void;
  }>
> = ({
  error,
  openExampleModal,
  providerStates,
  malpracticeMinimums,
  isPrescriber,
  providerName,
  insuranceCarrier,
  policyNumber,
  licenseTypeDisplayNames,
}) => {
  let message: React.ReactChild = <span>{error.errorMessage}</span>;
  switch (error.errorType) {
    case CoiErrorType.BadDocument:
      message = (
        <span>
          This document doesn't match the qualifications for a Certificate of
          Insurance. Please review and resubmit. You can find examples of
          Certificates of Insurance{' '}
          <Button variant="link" onPress={openExampleModal}>
            here
          </Button>
          .
        </span>
      );
      break;
    case CoiErrorType.BadExpirationDate:
      message = (
        <span>
          This policy uploaded has expired. Please add a current and active
          professional liability coverage.
        </span>
      );
      break;
    case CoiErrorType.BadExpiringSoon:
      message = (
        <span>
          This policy is expiring soon. To move forward, please also add a copy
          of your professional liability insurance that provides coverage when
          this one expires.
        </span>
      );
      break;
    case CoiErrorType.BadEffectiveDate:
      message = (
        <span>
          It looks like the coverage you uploaded goes into effect at a future
          date. Please upload a copy of your current, active professional
          liability coverage.
        </span>
      );
      break;
    case CoiErrorType.BadPerClaimLimit:
    case CoiErrorType.BadAggregateLimit:
      if (!malpracticeMinimums) {
        break;
      }
      const perClaimLimit = formatter.format(
        malpracticeMinimums.occurrenceLimit
      );
      const aggregateLimit = formatter.format(
        malpracticeMinimums.aggregateLimit
      );
      const limitsSubject = `Coverage Limits${
        policyNumber ? ` - Policy #:${policyNumber}` : ''
      }`;
      const limitsEmailBody = coverageLimitEmail(
        providerName,
        perClaimLimit,
        aggregateLimit
      );
      message = (
        <span>
          This policy has an issue with the coverage limits specified. We
          require {isPrescriber ? 'prescribing' : '(non) prescribing'} providers
          for{' '}
          {providerStates
            .map((state) => statesToDisplayNames[state])
            .join(', ')}{' '}
          to have coverage limits of at least {perClaimLimit} per claim and{' '}
          {aggregateLimit} in aggregate coverage. Please{' '}
          <a
            href={`mailto:?to=&body=${encodeURI(
              limitsEmailBody
            )}&subject=${encodeURI(limitsSubject)}`}
            target="_blank"
            rel="noreferrer"
          >
            contact your professional liability insurance provider
          </a>{' '}
          to raise your limits.
        </span>
      );
      break;
    case CoiErrorType.BadCoverageType:
      message = (
        <span>
          This policy does not provide professional liability insurance. Please
          upload a new file.
        </span>
      );
      break;
    case CoiErrorType.BadOccupation:
      const occupationSubject = `Updated Occupation${
        policyNumber ? ` - Policy #:${policyNumber}` : ''
      }`;
      message = (
        <span>
          The certificate of insurance uploaded offers coverage for an
          occupation that does not match your current licensure. Please upload a
          certificate of insurance that provides coverage for your current
          license and occupation.
          <br />
          If you do not have a copy that meets this requirement, please{' '}
          <a
            href={`mailto:?to=&body=${encodeURI(
              occupationEmail(providerName, licenseTypeDisplayNames)
            )}&subject=${encodeURI(occupationSubject)}`}
            target="_blank"
            rel="noreferrer"
          >
            contact your professional liability insurance provider
          </a>
          .
        </span>
      );
      break;
    case CoiErrorType.BadNameInsured:
      const nameInsuredEmailBody =
        insuranceCarrier === 'Healthcare Providers Service Organization' ||
        insuranceCarrier === 'Nurses Service Organization (NSO)'
          ? cnaNameInsuredEmail(providerName)
          : nonCnaNameInsuredEmail(providerName);
      const nameInsuredSubject = `Proof of Individual Coverage${
        policyNumber ? ` - Policy #:${policyNumber}` : ''
      }`;
      message = (
        <span>
          This Certificate of Insurance doesn't list your name is an Owner or
          Name Insured.
          <br />
          We require providers to have their individual name listed under "Name
          Insured" or additional insured section of the policy. If you do not
          have a copy that meets this requirement, please{' '}
          <a
            href={`mailto:?to=&body=${encodeURI(
              nameInsuredEmailBody
            )}&subject=${encodeURI(nameInsuredSubject)}`}
            target="_blank"
            rel="noreferrer"
          >
            contact your professional liability insurance provider
          </a>
          .
        </span>
      );
      break;
  }
  return message;
};

const overlapsWithCurrentInsurance = (
  insurance: Malpractice,
  currentInsurance?: Malpractice
) => {
  const currentMalpracticeInsuranceExpiration =
    currentInsurance?.liabilityInsuranceExpirationDate;
  if (
    currentMalpracticeInsuranceExpiration &&
    insurance.liabilityInsuranceEffectiveDate
  ) {
    return moment(currentMalpracticeInsuranceExpiration)
      .add(1, 'days')
      .isSameOrAfter(moment(insurance.liabilityInsuranceEffectiveDate));
  } else {
    return false;
  }
};

const getComplianceErrorMessage = (
  index: number,
  selectedInsurance?: Malpractice,
  malpracticeMinimums?: MalpracticeMinimums
): string | undefined => {
  if (!selectedInsurance) {
    return;
  }
  const isOccurrenceMinimumViolated =
    !!selectedInsurance &&
    Number(selectedInsurance?.liabilityInsuranceOccurrenceLimit || 0) <
      (malpracticeMinimums?.occurrenceLimit || 0);
  const isAggregateMinimumViolated =
    !!selectedInsurance &&
    Number(selectedInsurance?.liabilityInsuranceAggregateLimit || 0) <
      (malpracticeMinimums?.aggregateLimit || 0);

  const complianceErrorMessagePrefix =
    'This policy does not meet insurer requirements';
  let complianceErrorMessage;
  if (index === 0 && !isInsuranceCurrentlyActive(selectedInsurance)) {
    complianceErrorMessage =
      'This policy isn’t active yet. Please select a policy that is either currently active or going to be active within 3 days.';
  } else if (!malpracticeMinimums) {
    complianceErrorMessage = complianceErrorMessagePrefix + '.';
  } else {
    let minimumViolatedErrors = [];
    if (isOccurrenceMinimumViolated) {
      minimumViolatedErrors.push(
        ' an occurrence limit of at least ' +
          formatter.format(malpracticeMinimums.occurrenceLimit)
      );
    }
    if (isAggregateMinimumViolated) {
      minimumViolatedErrors.push(
        ' an aggregate limit of at least ' +
          formatter.format(malpracticeMinimums.aggregateLimit)
      );
    }

    if (minimumViolatedErrors.length > 0) {
      complianceErrorMessage =
        complianceErrorMessagePrefix +
        ' of' +
        minimumViolatedErrors.join(' and') +
        '.';
    }
  }
  return complianceErrorMessage;
};

export const getValidityForSelectedInsurance = (
  hasSubmitted: boolean,
  index: number,
  selectedInsurance?: Malpractice
): ValidationState => {
  return !selectedInsurance && hasSubmitted
    ? {
        validity: 'invalid',
        message: index
          ? 'Future insurance policy is required'
          : 'Current insurance policy is required',
      }
    : { validity: 'valid' };
};

const getInsuranceUniqueKey = (insurance?: Malpractice): string => {
  if (!insurance) {
    return '';
  }
  return (
    insurance.liabilityInsurancePolicyNumber +
    ' ' +
    insurance.liabilityInsuranceEffectiveDate +
    '-' +
    insurance.liabilityInsuranceExpirationDate
  );
};

const ProfessionalLiabilityInsuranceSection: React.FC<
  React.PropsWithChildren<{
    index: number;
    selectedInsurances?: Malpractice[];
    selectableMalpracticeInsurances: Malpractice[];
    currentCoiPqvs: ProviderQuestionnaireVerificationRead[];
    shouldValidateCoiUploads: boolean;
    numberOfCoiUploads: number;
    removeSelf: () => void;
    setModalInsuranceCarrier: (carrier: any) => void;
    setIsExampleModalOpen: (isOpen: boolean) => void;
    incrementNumberOfCoiUploads: () => void;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void;
    providerStates: UnitedStates[];
    malpracticeMinimums?: MalpracticeMinimums;
    isPrescriber: boolean;
    providerName: string;
    questionnaireId: number;
    providerLicenses: License[];
    isExpiringSoon?: boolean;
    complianceErrorMessage?: string;
    hasSubmitted: boolean;
  }>
> = ({
  index,
  selectedInsurances,
  selectableMalpracticeInsurances,
  currentCoiPqvs,
  shouldValidateCoiUploads,
  numberOfCoiUploads,
  removeSelf,
  setModalInsuranceCarrier,
  setIsExampleModalOpen,
  incrementNumberOfCoiUploads,
  setFieldValue,
  providerStates,
  malpracticeMinimums,
  isPrescriber,
  providerName,
  questionnaireId,
  providerLicenses,
  isExpiringSoon,
  complianceErrorMessage,
  hasSubmitted,
}) => {
  const selectedInsurance = selectedInsurances?.[index];
  const { provider } = useQuestionnaireContext();
  const [isCoiUploading, setIsCoiUploading] = useState(false);
  const insuranceCoiS3Key = getInsuranceCoiS3Key(selectedInsurance);
  const pqv = currentCoiPqvs.filter(
    (verification) =>
      verification.type === VerificationType.CERTIFICATE_OF_INSURANCE &&
      verification.requestJson.coiS3Key === insuranceCoiS3Key
  )[0];
  const isInsuranceCompliant = !complianceErrorMessage;
  const pqvErrors = filterCoiPqvErrorsBasedOnCurrentPqvs(pqv, currentCoiPqvs);
  const pqvIsInProgress =
    pqv?.responseJson.jobStatus === coiAlgorithmStatuses.IN_PROGRESS;

  const hasNonBlockingError = !!pqvErrors.filter((error) =>
    NonBlockingCoiErrorTypes.includes(error.errorType as CoiErrorType)
  ).length;
  if (
    !isCoiUploading &&
    pqv?.responseJson?.jobStatus ===
      coiAlgorithmStatuses.COI_VALIDATION_COMPLETE
  ) {
    if (
      pqvErrors.length &&
      (numberOfCoiUploads >= 3 || hasNonBlockingError) &&
      !selectedInsurance?.allowSkipCoiVerification
    ) {
      // allowSkipCoiVerification is enabled if there are non blocking error types or the provider has tried 3 times
      setFieldValue(
        `malpracticeInsurances[${index}].allowSkipCoiVerification`,
        true
      );
    } else if (
      !pqvErrors.length &&
      selectedInsurance?.allowSkipCoiVerification
    ) {
      // If another COI is uploaded that fixes the errors found in this COI, then allowing skip is no longer needed
      // Ex. this COI is expiring soon and the other COI uploaded is in the future.
      setFieldValue(
        `malpracticeInsurances[${index}].allowSkipCoiVerification`,
        false
      );
    }
  }
  const licenseTypeDisplayNames = new Set<string>(
    providerLicenses
      .filter((license) => !!license)
      .map((license) => licenseTypes[license.licenseType].displayName)
  );
  const formatInsuranceDisplayName = (insurance: Malpractice) => {
    return (
      insurance.liabilityInsuranceCarrier +
      ' (Policy number: ' +
      insurance.liabilityInsurancePolicyNumber +
      ', Expiration date: ' +
      moment(insurance.liabilityInsuranceExpirationDate).format('MM/DD/YYYY') +
      ')'
    );
  };
  return (
    <FieldArraySectionItemContainer>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div css={{ ...theme.stack.horizontal }}>
          <div>
            <h2>
              <div>
                <SectionHeader>
                  {!index
                    ? `Next, select a malpractice policy to use on Headway`
                    : `Add a future policy`}
                </SectionHeader>
              </div>
            </h2>
          </div>
          <div css={{ marginLeft: theme.spacing.x2 }}>
            {shouldValidateCoiUploads &&
              (isCoiUploading || pqvIsInProgress) && (
                <Badge variant="warning">Verifying...</Badge>
              )}
          </div>
        </div>
      </div>
      <Select
        name={`malpracticeInsurancePolicy${index}`}
        label={
          index
            ? 'Select a future malpractice policy from CAQH overlapping with your current policy to use on Headway'
            : 'Select a malpractice policy from CAQH to use on Headway'
        }
        menuWidth="stretch"
        selectionMode="single"
        placeholder={
          index
            ? 'Select a future overlapping malpractice policy'
            : 'Select a malpractice policy'
        }
        selectedKeys={
          !!selectedInsurance ? [getInsuranceUniqueKey(selectedInsurance)] : []
        }
        validation={getValidityForSelectedInsurance(
          hasSubmitted,
          index,
          selectedInsurance
        )}
        onSelectionChange={(keys: Set<string>) => {
          const newSelectedInsurance = selectableMalpracticeInsurances.find(
            (insurance) =>
              getInsuranceUniqueKey(insurance) === keys.values().next().value
          );
          if (
            index === 0 &&
            newSelectedInsurance &&
            isInsuranceExpiringSoon(newSelectedInsurance)
          ) {
            setFieldValue(`malpracticeInsurances`, [
              newSelectedInsurance,
              undefined,
            ]);
          } else if (index === 0 && newSelectedInsurance) {
            setFieldValue('malpracticeInsurances', [newSelectedInsurance]);
          } else if (newSelectedInsurance) {
            setFieldValue(
              `malpracticeInsurances[${index}]`,
              newSelectedInsurance
            );
          } else if (index === 1 && selectedInsurances?.[0]) {
            setFieldValue('malpracticeInsurances', [selectedInsurances[0]]);
          } else {
            setFieldValue('malpracticeInsurances', []);
          }
        }}
      >
        {selectableMalpracticeInsurances.map((insurance) => (
          <Item key={getInsuranceUniqueKey(insurance)}>
            {formatInsuranceDisplayName(insurance)}
          </Item>
        ))}
      </Select>
      {!selectableMalpracticeInsurances?.length && (
        <GuidanceCard variant={'error'}>
          <BodyText>
            No selectable insurances, go to the CAQH Account Updates page to see
            more info
          </BodyText>
        </GuidanceCard>
      )}
      {!isInsuranceCompliant && selectedInsurance && (
        <GuidanceCard variant={'error'}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            <BodyText>{complianceErrorMessage}</BodyText>
            <Link href={CAQH_WEBSITE_URL} target="_blank">
              <Button size="large" variant="link">
                Go to CAQH to update these details
              </Button>
            </Link>
          </div>
        </GuidanceCard>
      )}
      {isInsuranceCompliant && selectedInsurance && isExpiringSoon && (
        <GuidanceCard variant={'warning'}>
          <BodyText>
            Looks like your malpractice policy is going to expire within 21
            days. To ensure a smooth credentialing process, add an additional
            future policy that overlaps with the first so you don't have any
            gaps in coverage.
          </BodyText>
        </GuidanceCard>
      )}
      {isInsuranceCompliant &&
        selectedInsurance &&
        selectedInsurance.liabilityInsuranceCarrier === 'Other' && (
          <FormControl
            component={TextField}
            name={`malpracticeInsurances[${index}].liabilityInsuranceCarrierOther`}
            label="Malpractice Carrier Other"
            helpText="Please list the name of your Malpractice Carrier."
          />
        )}
      {isInsuranceCompliant &&
        !!selectedInsurance?.liabilityInsuranceCarrier &&
        !!MALPRACTICE_EXAMPLES[selectedInsurance.liabilityInsuranceCarrier] && (
          <Button
            variant="link"
            onPress={() => {
              setModalInsuranceCarrier(
                selectedInsurance.liabilityInsuranceCarrier
              );
              setIsExampleModalOpen(true);
            }}
          >
            Click here to see an example document
          </Button>
        )}
      {isInsuranceCompliant && selectedInsurance && (
        <FormControl
          component={TextField}
          name={`malpracticeInsurances[${index}].liabilityInsuranceNameInsured`}
          label="Name Insured"
          helpText="Please list the name printed under the “Name Insured” section of the policy."
        />
      )}
      {isInsuranceCompliant && selectedInsurance && (
        <FieldControl
          name={`malpracticeInsurances[${index}].liabilityInsuranceUrl`}
          fullWidth={true}
          css={{ marginBottom: 0 }}
        >
          <FormLabel>
            Please ensure that the document has the words 'Certificate of
            Insurance' or ‘Memorandum of Insurance’ printed on the page and
            contains all the information listed above:
          </FormLabel>
          <FieldDropzone
            accept="application/pdf,image/*"
            onDrop={async (files: any) => {
              setIsCoiUploading(true);
              const processedFiles = await onDropFiles(provider.id, files);
              if (!processedFiles || !processedFiles.length) {
                setIsCoiUploading(false);
                return processedFiles;
              }

              const coiS3Key = processedFiles[0].s3ObjectKey;
              if (!coiS3Key) {
                setIsCoiUploading(false);
                return processedFiles;
              }

              incrementNumberOfCoiUploads();
              setFieldValue(
                `malpracticeInsurances[${index}].allowSkipCoiVerification`,
                false
              );
              setFieldValue(
                `malpracticeInsurances[${index}].confirmSkipCoiVerification`,
                false
              );
              await createCoiPqv(coiS3Key, questionnaireId).then(() => {
                setIsCoiUploading(false);
              });

              return processedFiles;
            }}
            inputTestId={`malpracticeInsurances[${index}].liabilityInsuranceUrl`}
            css={{ marginBottom: '0' }}
          />
          <FieldErrorText />
        </FieldControl>
      )}
      {isInsuranceCompliant &&
        selectedInsurance &&
        shouldValidateCoiUploads &&
        !isCoiUploading &&
        !!pqvErrors.length && (
          <GuidanceCard
            variant="error"
            layout="vertical"
            css={{ marginBottom: theme.spacing.x2 }}
          >
            <BodyText>
              We found an issue with this Certificate of Insurance:
            </BodyText>
            <ul>
              {pqvErrors.map((error) => (
                <li>
                  <CoiErrorMessage
                    error={error}
                    isPrescriber={isPrescriber}
                    malpracticeMinimums={malpracticeMinimums}
                    providerStates={providerStates}
                    providerName={providerName}
                    insuranceCarrier={
                      selectedInsurance.liabilityInsuranceCarrier || ''
                    }
                    policyNumber={
                      selectedInsurance.liabilityInsurancePolicyNumber || ''
                    }
                    openExampleModal={() => {
                      setModalInsuranceCarrier(
                        selectedInsurance.liabilityInsuranceCarrier
                      );
                      setIsExampleModalOpen(true);
                    }}
                    licenseTypeDisplayNames={licenseTypeDisplayNames}
                  />
                </li>
              ))}
            </ul>
            <p>
              For more details, please review the Certificate of Insurance guide{' '}
              <Link
                href="https://findheadway.zendesk.com/hc/articles/4406639198484"
                target="_blank"
                rel="noreferrer"
              >
                here.
              </Link>
            </p>
            {selectedInsurance.allowSkipCoiVerification && (
              <FormControl
                component={Checkbox}
                name={`malpracticeInsurances[${index}].confirmSkipCoiVerification`}
              >
                {hasNonBlockingError
                  ? 'It looks like you will need to reach out to your insurance provider to solve this issue. Please check this box to attest that you have contacted your professional liability insurance provider and understand that failure to do so will result in a delay of your credentialing process.'
                  : 'I wish to skip this step. I understand that I will be required to provide a valid certificate of insurance in order to complete my credentialing process and understand that Headway cannot start my credentialing process without this document.'}
              </FormControl>
            )}
          </GuidanceCard>
        )}
      {isInsuranceCompliant &&
        shouldValidateCoiUploads &&
        insuranceCoiS3Key &&
        !isCoiUploading &&
        !pqvIsInProgress &&
        pqv &&
        !pqvErrors.length && (
          <Alert severity="success" css={{ marginBottom: theme.spacing.x2 }}>
            <AlertTitle>
              Successfully verified your uploaded document!
            </AlertTitle>
          </Alert>
        )}
      {isInsuranceCompliant &&
        shouldValidateCoiUploads &&
        (isCoiUploading || pqvIsInProgress) && (
          <Alert
            severity="success"
            css={{ marginBottom: theme.spacing.x2 }}
            icon={<CircularProgress size={20} />}
          >
            <AlertTitle>Verifying your document</AlertTitle>
          </Alert>
        )}
    </FieldArraySectionItemContainer>
  );
};

const getSelectableMalpracticeInsurances = (
  index: number,
  caqhMalpracticeInsurances: Malpractice[],
  currentInsurance?: Malpractice
) => {
  if (index === 0) {
    return caqhMalpracticeInsurances.filter(isInsuranceNonExpired);
  } else {
    return caqhMalpracticeInsurances.filter(
      (insurance) =>
        isInsuranceInFuture(insurance) &&
        overlapsWithCurrentInsurance(insurance, currentInsurance)
    );
  }
};

const mergeUpdatesFromCaqh = (
  selectedInsurance: Malpractice,
  selectedInsuranceFromCaqh: Malpractice
) => {
  return {
    ...selectedInsuranceFromCaqh,
    liabilityInsuranceCarrier: selectedInsurance.liabilityInsuranceCarrier,
    liabilityInsuranceCarrierOther:
      selectedInsurance.liabilityInsuranceCarrierOther,
    liabilityInsuranceNameInsured:
      selectedInsurance.liabilityInsuranceNameInsured,
    liabilityInsuranceUrl: selectedInsurance.liabilityInsuranceUrl,
    allowSkipCoiVerification: selectedInsurance.allowSkipCoiVerification,
    confirmSkipCoiVerification: selectedInsurance.confirmSkipCoiVerification,
  };
};

const isInsuranceExpiringSoon = (insurance: Malpractice) => {
  return moment(insurance.liabilityInsuranceExpirationDate).isBefore(
    moment().add(21, 'days')
  );
};

const maybeUpdateInsurancesFromCaqh = (
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>
): Malpractice[] => {
  if (!formikHelpers.values?.malpracticeInsurances?.length) {
    return [];
  }
  const selectedCurrentInsuranceKey = getInsuranceUniqueKey(
    formikHelpers.values?.malpracticeInsurances?.[0]
  );
  const selectedFutureInsuranceKey = getInsuranceUniqueKey(
    formikHelpers.values?.malpracticeInsurances?.[1]
  );
  let selectedCurrentCaqhInsurance;
  let selectedFutureCaqhInsurance;
  const nonExpiredCaqhInsurances = (
    formikHelpers.values?.caqhMalpracticeInsurances || []
  ).filter(isInsuranceNonExpired);
  selectedCurrentCaqhInsurance = nonExpiredCaqhInsurances.find(
    (ins) => getInsuranceUniqueKey(ins) === selectedCurrentInsuranceKey
  );
  selectedFutureCaqhInsurance = nonExpiredCaqhInsurances.find(
    (ins) => getInsuranceUniqueKey(ins) === selectedFutureInsuranceKey
  );
  const selectedInsurances = [];
  if (selectedCurrentCaqhInsurance) {
    selectedInsurances.push(
      mergeUpdatesFromCaqh(
        formikHelpers.values.malpracticeInsurances[0],
        selectedCurrentCaqhInsurance
      )
    );
  }
  if (selectedFutureCaqhInsurance) {
    selectedInsurances.push(
      mergeUpdatesFromCaqh(
        formikHelpers.values.malpracticeInsurances[1],
        selectedFutureCaqhInsurance
      )
    );
  }
  return selectedInsurances;
};

const MalpracticeStep = ({
  initialValues,
  formikHelpers,
  showConfirmationModal,
  setShowConfirmationModal,
  onStepComplete,
}: CustomComponentProps) => {
  const {
    provider,
    providerQuestionnaire,
    pqvs,
    shouldValidateCoiUploads,
    malpracticeMinimums,
  } = useQuestionnaireContext();
  const [isExampleModalOpen, setIsExampleModalOpen] = useState(false);
  const [confirmationModalPage, setConfirmationModalPage] = useState(
    ConfirmationModalPage.CONFIRM_DOCUMENTS
  );
  const [modalInsuranceCarrier, setModalInsuranceCarrier] = useState('false');
  const [numberOfCoiUploads, setNumberOfCoiUploads] = useState<number>(
    parseInt(
      localStorage.getItem(
        getCoiUploadCountStorageKey(providerQuestionnaire.id)
      ) || '0'
    )
  );

  useEffect(() => {
    formikHelpers.setFieldValue(
      'malpracticeInsurances',
      maybeUpdateInsurancesFromCaqh(formikHelpers)
    );
  }, [formikHelpers?.values?.caqhMalpracticeInsurances]);

  const currentCoiPqvs = getCurrentCoiPqvs(
    pqvs || [],
    formikHelpers.values?.malpracticeInsurances || []
  );

  const providerStates = getSupportedStates(provider);

  const providerLicenses: License[] = getSelectedLicenseList(
    initialValues.selectedLicenses
  );
  const currentInsurance = formikHelpers.values?.malpracticeInsurances?.[0];
  const futureInsurance = formikHelpers.values?.malpracticeInsurances?.[1];

  const isCurrentInsuranceExpiringSoon =
    !!currentInsurance && isInsuranceExpiringSoon(currentInsurance);

  const caqhMalpracticeInsurances =
    formikHelpers?.values?.caqhMalpracticeInsurances || [];

  return (
    <>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
        }}
      >
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x6,
          }}
        >
          <MalpracticeHeader
            nonExpiredMalpracticeInsurances={(
              formikHelpers.values?.caqhMalpracticeInsurances || []
            ).filter(isInsuranceNonExpired)}
            malpracticeMinimums={malpracticeMinimums}
          />
          <div
            css={{
              ...theme.stack.vertical,
              gap: theme.spacing.x6,
              marginTop: '16px',
              borderTop: `1px solid ${theme.color.system.borderGray}`,
              paddingTop: '24px',
            }}
          >
            <Modal
              open={isExampleModalOpen}
              onClose={() => setIsExampleModalOpen(false)}
              title={`${modalInsuranceCarrier} Example`}
              css={{ width: '100%' }}
            >
              <img
                alt={`${modalInsuranceCarrier} Example Document`}
                css={{ maxWidth: '500px' }}
                src={MALPRACTICE_EXAMPLES[modalInsuranceCarrier]}
              />
            </Modal>
            <Modal
              open={showConfirmationModal}
              onClose={() => setShowConfirmationModal(false)}
              title={`Confirm Liability Insurance Information`}
              css={{ width: '100%' }}
            >
              {confirmationModalPage ===
                ConfirmationModalPage.CONFIRM_DOCUMENTS && (
                <ConfirmDocuments
                  values={formikHelpers.values}
                  setConfirmationModalPage={setConfirmationModalPage}
                  closeModal={() => setShowConfirmationModal(false)}
                  next={() => {
                    setConfirmationModalPage(
                      ConfirmationModalPage.CONFIRM_NAME
                    );
                  }}
                  shouldValidateCoiUploads={shouldValidateCoiUploads}
                />
              )}
              {confirmationModalPage === ConfirmationModalPage.CONFIRM_NAME && (
                <ConfirmName
                  initialValues={formikHelpers.values}
                  closeModal={() => setShowConfirmationModal(false)}
                  next={() => {
                    onStepComplete(formikHelpers.values);
                  }}
                />
              )}
            </Modal>
            <FieldArray
              name="malpracticeInsurances"
              render={(arrayHelpers) => (
                <React.Fragment>
                  <FieldArraySection>
                    <ProfessionalLiabilityInsuranceSection
                      selectedInsurances={
                        formikHelpers.values?.malpracticeInsurances
                      }
                      selectableMalpracticeInsurances={getSelectableMalpracticeInsurances(
                        0,
                        caqhMalpracticeInsurances,
                        currentInsurance
                      )}
                      index={0}
                      setIsExampleModalOpen={setIsExampleModalOpen}
                      setModalInsuranceCarrier={setModalInsuranceCarrier}
                      removeSelf={() => arrayHelpers.remove(0)}
                      currentCoiPqvs={currentCoiPqvs}
                      shouldValidateCoiUploads={shouldValidateCoiUploads}
                      numberOfCoiUploads={numberOfCoiUploads}
                      incrementNumberOfCoiUploads={() => {
                        localStorage.setItem(
                          getCoiUploadCountStorageKey(providerQuestionnaire.id),
                          (numberOfCoiUploads + 1).toString()
                        );
                        setNumberOfCoiUploads(numberOfCoiUploads + 1);
                      }}
                      setFieldValue={formikHelpers.setFieldValue}
                      providerStates={getSupportedStates(provider)}
                      malpracticeMinimums={malpracticeMinimums}
                      isPrescriber={hasPrescriberLicenseType(
                        formikHelpers.values?.selectedLicenses
                      )}
                      providerName={`${initialValues.firstName} ${initialValues.lastName}`}
                      questionnaireId={providerQuestionnaire.id}
                      providerLicenses={providerLicenses}
                      isExpiringSoon={isCurrentInsuranceExpiringSoon}
                      complianceErrorMessage={getComplianceErrorMessage(
                        0,
                        currentInsurance,
                        malpracticeMinimums
                      )}
                      hasSubmitted={formikHelpers.submitCount > 0}
                    />
                    {isCurrentInsuranceExpiringSoon && (
                      <ProfessionalLiabilityInsuranceSection
                        selectedInsurances={
                          formikHelpers.values?.malpracticeInsurances
                        }
                        selectableMalpracticeInsurances={getSelectableMalpracticeInsurances(
                          1,
                          caqhMalpracticeInsurances,
                          currentInsurance
                        )}
                        index={1}
                        setIsExampleModalOpen={setIsExampleModalOpen}
                        setModalInsuranceCarrier={setModalInsuranceCarrier}
                        removeSelf={() => arrayHelpers.remove(0)}
                        currentCoiPqvs={currentCoiPqvs}
                        shouldValidateCoiUploads={shouldValidateCoiUploads}
                        numberOfCoiUploads={numberOfCoiUploads}
                        incrementNumberOfCoiUploads={() => {
                          localStorage.setItem(
                            getCoiUploadCountStorageKey(
                              providerQuestionnaire.id
                            ),
                            (numberOfCoiUploads + 1).toString()
                          );
                          setNumberOfCoiUploads(numberOfCoiUploads + 1);
                        }}
                        setFieldValue={formikHelpers.setFieldValue}
                        providerStates={getSupportedStates(provider)}
                        malpracticeMinimums={malpracticeMinimums}
                        isPrescriber={hasPrescriberLicenseType(
                          formikHelpers.values?.selectedLicenses
                        )}
                        providerName={`${initialValues.firstName} ${initialValues.lastName}`}
                        questionnaireId={providerQuestionnaire.id}
                        providerLicenses={providerLicenses}
                        complianceErrorMessage={getComplianceErrorMessage(
                          1,
                          futureInsurance,
                          malpracticeMinimums
                        )}
                        hasSubmitted={formikHelpers.submitCount > 0}
                      />
                    )}
                  </FieldArraySection>
                </React.Fragment>
              )}
            />
            {isSovereignImmunityDocRequiredForProvider(provider) && (
              <>
                <div css={{ display: 'flex', marginTop: theme.spacing.x4 }}>
                  <h2>
                    <SectionHeader>Sovereign Immunity Document</SectionHeader>
                  </h2>
                </div>
                <FormControl
                  name="hasSovereignImmunity"
                  component={RadioGroup}
                  label="Do you have a Sovereign Immunity document?"
                >
                  <Radio value={YesNo.YES}>Yes</Radio>
                  <Radio value={YesNo.NO}>No</Radio>
                </FormControl>
                {formikHelpers.values?.hasSovereignImmunity === YesNo.YES && (
                  <FieldControl name="sovereignImmunityUrl" fullWidth={true}>
                    <FormLabel>
                      Please upload a copy of your Sovereign Immunity document
                      in pdf format
                    </FormLabel>
                    <FieldDropzone
                      accept="application/pdf,image/*"
                      onDrop={(files: any) => onDropFiles(provider.id, files)}
                    />
                    <FieldErrorText />
                  </FieldControl>
                )}
              </>
            )}
            {providerStates.includes(UnitedStates.LOUISIANA) && (
              <>
                <div css={{ display: 'flex', marginTop: theme.spacing.x4 }}>
                  <h2>
                    <SectionHeader>
                      Louisiana Liability Insurance Questions
                    </SectionHeader>
                  </h2>
                </div>
                <FormControl
                  name="lousianaPatientsCompensationFund"
                  component={RadioGroup}
                  label="Do you participate in the Louisiana Patients’ Compensation Fund?"
                >
                  <Radio value={YesNo.YES}>Yes</Radio>
                  <Radio value={YesNo.NO}>No</Radio>
                </FormControl>
                <FormControl
                  name="lousianaMedicalMalpracticeAct"
                  component={RadioGroup}
                  label="Are you self-insured in accordance with the Louisiana Medical Malpractice Act?"
                >
                  <Radio value={YesNo.YES}>Yes</Radio>
                  <Radio value={YesNo.NO}>No</Radio>
                </FormControl>

                <FormControl
                  name="lousianaLiabilityInsuranceRequiredExclusions"
                  component={RadioGroup}
                  label="Has current liability insurance carrier required exclusion of any procedures from insurance coverage?"
                >
                  <Radio value={YesNo.YES}>Yes</Radio>
                  <Radio value={YesNo.NO}>No</Radio>
                </FormControl>
                {formikHelpers.values
                  ?.lousianaLiabilityInsuranceRequiredExclusions ===
                  YesNo.YES && (
                  <FieldControl
                    name={`lousianaLiabilityInsuranceRequiredExclusionsUrl`}
                    fullWidth={true}
                  >
                    <FormLabel>
                      Please upload a pdf with an explanation of required
                      exclusions.
                    </FormLabel>
                    <FieldDropzone
                      accept="application/pdf"
                      onDrop={(files: any) => onDropFiles(provider.id, files)}
                      css={{ marginBottom: '0' }}
                    />
                    <FieldErrorText />
                  </FieldControl>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Malpractice Information',
  description:
    'In this section we ask that you upload forms needed for your CAQH profile.',
  Component: MalpracticeStep,
  onBeforeSubmit: async (
    values,
    setBeforeSubmitError,
    setShowConfirmationModal,
    context
  ) => {
    setBeforeSubmitError(null);
    const beginningOfToday = moment().hour(0).minute(0).second(0);
    const currentInsurance = values.malpracticeInsurances?.[0];
    const hasCurrentlyEffectiveInsurance =
      !!currentInsurance &&
      moment(currentInsurance.liabilityInsuranceEffectiveDate)
        .subtract(1, 'days')
        .isBefore(beginningOfToday) &&
      moment(currentInsurance.liabilityInsuranceExpirationDate)
        .add(1, 'days')
        .isAfter(beginningOfToday);

    if (!hasCurrentlyEffectiveInsurance) {
      setBeforeSubmitError(OnBeforeSubmitError.MalpracticeRequireActivePolicy);
      return !!hasCurrentlyEffectiveInsurance;
    }
    const isFutureInsuranceNeeded = moment(
      currentInsurance.liabilityInsuranceExpirationDate
    ).isBefore(moment().add(21, 'days'));
    if (
      isFutureInsuranceNeeded &&
      (values.malpracticeInsurances?.length || 0) < 2
    ) {
      setBeforeSubmitError(OnBeforeSubmitError.MalpracticeRequireFuturePolicy);
      return false;
    }
    values.malpracticeInsurances?.forEach((malpractice: Malpractice) => {
      const insuranceCoiS3Key = getInsuranceCoiS3Key(malpractice);
      // If a coi pqv hasn't been created for an uploaded insurance for some reason, create one.
      if (
        insuranceCoiS3Key &&
        !context.pqvs?.filter(
          (verification) =>
            verification.type === VerificationType.CERTIFICATE_OF_INSURANCE &&
            verification.requestJson.coiS3Key === insuranceCoiS3Key
        ).length
      ) {
        createCoiPqv(insuranceCoiS3Key, context.providerQuestionnaire.id);
      }
    });
    setShowConfirmationModal(true);
    return false;
  },
  getFormMeta: ({ provider, providerQuestionnaire, malpracticeMinimums }) => {
    const validationSchema = Yup.object().shape({
      malpracticeInsurances: Yup.array()
        .required()
        .min(1, 'Must choose a compliant malpractice insurance')
        .of(
          Yup.object().shape({
            liabilityInsuranceUrl: Yup.array()
              .of(
                Yup.object().shape({
                  link: Yup.string(),
                  name: Yup.string(),
                })
              )
              .required('A copy of your insurance policy is required.'),
            liabilityInsuranceCarrier: Yup.string()
              .oneOf(Object.keys(MALPRACTICE_EXAMPLES), 'Carrier is required.')
              .required('Carrier is required.'),
            liabilityInsuranceCarrierOther: Yup.string().when(
              'liabilityInsuranceCarrier',
              (liabilityInsuranceCarrier: string, schema: Yup.ObjectSchema) =>
                liabilityInsuranceCarrier === 'Other'
                  ? schema.required('Please specify Malpractice Carrier.')
                  : schema
            ),
            liabilityInsuranceNameInsured: Yup.string().required(
              'Named insured is required.'
            ),
            liabilityInsurancePolicyNumber: Yup.string().required(
              'Policy number is required.'
            ),
            liabilityInsuranceExpirationDate: Yup.date()
              .min(
                Yup.ref('liabilityInsuranceEffectiveDate'),
                'Expiration date must be after effective date.'
              )
              .required('Expiration date is required.'),
            involvedInMalpracticeClaim: Yup.string().required(
              'This question is required'
            ),
            liabilityInsuranceEffectiveDate: Yup.date().required(
              'This question is required'
            ),
            allowSkipCoiVerification: Yup.boolean().required(
              'This question is required'
            ),
            confirmSkipCoiVerification: Yup.boolean().when(
              'allowSkipCoiVerification',
              (allowSkipCoiVerification, schema) =>
                allowSkipCoiVerification
                  ? schema.oneOf(
                      [true],
                      'You must read and agree to the statement above.'
                    )
                  : schema
            ),
            ...getLiabilityLimitsSchema(malpracticeMinimums),
          })
        ),
      hasSovereignImmunity: isSovereignImmunityDocRequiredForProvider(provider)
        ? Yup.string().required('This question is required.')
        : Yup.string(),
      sovereignImmunityUrl: Yup.array().when(
        'hasSovereignImmunity',
        (hasSovereignImmunity: string, schema: Yup.StringSchema) =>
          hasSovereignImmunity === YesNo.YES
            ? Yup.array()
                .of(
                  Yup.object().shape({
                    link: Yup.string(),
                    name: Yup.string(),
                  })
                )
                .required(
                  'Please upload a copy of your sovereign immunity document.'
                )
            : schema.nullable()
      ),
      lousianaPatientsCompensationFund: isProviderInState(
        provider,
        UnitedStates.LOUISIANA
      )
        ? Yup.string().required('This question is required.')
        : Yup.string(),
      lousianaMedicalMalpracticeAct: isProviderInState(
        provider,
        UnitedStates.LOUISIANA
      )
        ? Yup.string().required('This question is required.')
        : Yup.string(),
      lousianaLiabilityInsuranceRequiredExclusions: isProviderInState(
        provider,
        UnitedStates.LOUISIANA
      )
        ? Yup.string().required('This question is required.')
        : Yup.string(),
      lousianaLiabilityInsuranceRequiredExclusionsUrl: Yup.array().when(
        'lousianaLiabilityInsuranceRequiredExclusions',
        (
          lousianaLiabilityInsuranceRequiredExclusions: string,
          schema: Yup.StringSchema
        ) =>
          lousianaLiabilityInsuranceRequiredExclusions === YesNo.YES
            ? Yup.array()
                .of(
                  Yup.object().shape({
                    link: Yup.string(),
                    name: Yup.string(),
                  })
                )
                .required('Please upload a pdf with an explanation.')
            : schema.nullable()
      ),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;

export const MalpracticeRequireActivePolicy = () => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      You must provide a liability insurance policy that is active.
    </GuidanceCard>
  );
};

export const MalpracticeRequireFuturePolicy = () => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      You must provide a future overlapping insurance policy.
    </GuidanceCard>
  );
};
