import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Role } from '@headway/api/models/Role';
import { UserRead } from '@headway/api/models/UserRead';

export function isProviderLive(provider: ProviderRead) {
  const { earliestActiveLiveOn } = provider;
  return earliestActiveLiveOn && new Date(earliestActiveLiveOn) <= new Date();
}

export function hasRateAccess(provider?: ProviderRead, user?: any) {
  return (
    (provider && !provider.groupPracticeId) || (user && user?.group_practice)
  );
}

export function hasContractAccess(provider?: ProviderRead, user?: any) {
  return (
    provider &&
    !provider.providerLicenseState.hasCustomContract &&
    hasRateAccess(provider, user)
  );
}

export function hasIntakeAccess(provider?: ProviderRead, user?: any) {
  return provider && user && (user.provider_id || user.group_practice);
}

export function hasDashboardAccess(provider?: ProviderRead) {
  if (!provider) {
    return false;
  }

  return provider.activeProviderLicenseStates.some(
    (pls) => !!pls.questionnaireCompletedOn
  );
}

export function hasCustomBilling(provider?: ProviderRead) {
  return !!provider?.customBilling;
}

/**
 * Returns whether the logged in user is an Atlas user spoofing a provider
 * @param provider
 * @param impersonatingUser - impersonatingUser object retrieved from AuthStore()
 * @returns bool
 */
export function isAdminImpersonatingProviderUser(
  user?: UserRead,
  impersonatingUser?: UserRead
): boolean {
  return !!(impersonatingUser && impersonatingUser.id !== user?.id);
}

/**
 * Returns whether the logged in user is a group practice admin impersonating a provider in their group
 * @param provider
 * @param user - user object retrieved from AuthStore()
 * @returns bool
 */
export function isGroupAdminImpersonatingProvider(
  provider?: ProviderRead,
  user?: any
): boolean {
  return !!(user && provider && user.provider_id !== provider?.id);
}

/**
 * Returns whether the user is the group admin
 * @param user
 * @returns bool
 */
export function isGroupAdmin(user?: any): boolean {
  return !!user?.group_practice;
}

export function isReferralReady(provider: ProviderRead) {
  return (
    provider.photoUrl &&
    provider.statementHtml &&
    provider.highlights?.length &&
    provider.highlights.some((s) => !!s.trim())
  );
}

/**
 * Returns whether the logged in user is a Medical Record Auditor
 *
 * 2024-04-12 (sargunv):
 * This function doesn't actually check if anyone is impersonating?
 * Only that the user is a MRA. Leaving the logic untouched for now.
 * I suppose that a MRA could only be using Sigmund as an impersonator.
 *
 * @param provider
 * @param impersonatingUser - impersonatingUser object retrieved from AuthStore()
 * @returns bool
 */
export function isMedicalRecordAuditorImpersonatingProvider(
  impersonatingUserRoles?: Role[]
): boolean {
  return !!impersonatingUserRoles?.includes(Role.MEDICAL_RECORD_AUDITOR);
}

/**
 * Returns whether the logged in user is impersonating the provider from offshore
 *
 * Used to hide broken links for offshore users.
 */
export function isOffshoreAgentImpersonatingProviderUser(
  impersonatingUserRoles?: Role[]
): boolean {
  return !!impersonatingUserRoles?.includes(Role.NON_US_BASED_AGENT);
}
