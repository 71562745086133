import React from 'react';

export function IconInfo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 2C5.58173 2 2 5.58172 2 10C2 14.4183 5.58173 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2Z"
        fill="currentColor"
      />
      <path
        d="M9.9752 5.5C10.2584 5.5 10.5 5.6 10.7 5.8C10.9 6 11 6.2416 11 6.5248C11 6.8084 10.9 7.0502 10.7 7.2502C10.5 7.4502 10.2584 7.5502 9.9752 7.5502C9.692 7.5502 9.4504 7.4502 9.2504 7.2502C9.0504 7.0502 8.9504 6.8084 8.9504 6.5248C8.9504 6.2416 9.0504 6 9.2504 5.8C9.4504 5.6 9.692 5.5 9.9752 5.5ZM11 8.5V14.5H8.9504V8.5H11Z"
        fill="#fff"
      />
    </svg>
  );
}
