import styled from '@emotion/styled';
import React from 'react';

import { theme } from '../theme';
import { PrimaryBackground, VectorBackground } from './Background';
import { CopyBody, CopyCaption, CopyTitle } from './Copy';
import { Logo } from './Logos';
import { Metrics } from './Metrics';

export const MaxWidthSection = styled.div({
  maxWidth: 1200,
  padding: `0 ${theme.space.xl}`,
  margin: '0 auto',
  width: '100%',
});

const DefaultSectionContainer = styled.div({
  padding: `${theme.space.xl6} ${theme.space.xl}`,
  [theme.media.small]: {
    padding: `${theme.space.xl7} ${theme.space.xl}`,
  },
});

export interface SectionContainerProps {
  maxWidth?: number;
  component?: React.ComponentType<React.PropsWithChildren<unknown>>;
  id?: string;
}

export const SectionContainer: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<SectionContainerProps>>
> = ({
  maxWidth = 1200,
  component: Component = DefaultSectionContainer,
  ...rest
}) => (
  <div css={{ width: '100%', overflow: 'hidden' }}>
    <Component
      css={{
        maxWidth,
        margin: '0 auto',
        width: '100%',
      }}
      {...rest}
    />
  </div>
);

export const VectorBackgroundSectionContainer: React.FC<
  React.PropsWithChildren<SectionContainerProps>
> = (props) => (
  <SectionContainer
    component={VectorBackground}
    css={{
      padding: `${theme.space.xl6} ${theme.space.xl}`,
      [theme.media.small]: {
        padding: `${theme.space.xl7} ${theme.space.xl}`,
      },
    }}
    {...props}
  />
);

interface SectionProps {
  centered?: boolean;
  centeredBreakpoint?: string;
}

export const Section: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<SectionProps>>
> = ({ children, centered = false, centeredBreakpoint, ...rest }) => {
  return (
    <div
      css={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: centered ? 'center' : 'initial',
        ...(centered
          ? {
              ['.Metrics']: {
                justifyContent: 'center',
                textAlign: 'center',
              },
              ['.Logo']: {
                alignItems: 'center',
              },
            }
          : {}),
        ...(centeredBreakpoint
          ? {
              [centeredBreakpoint]: {
                alignItems: 'center',
                textAlign: 'center',
                ['.Metrics']: {
                  justifyContent: 'center',
                  textAlign: 'center',
                },
                ['.Logo']: {
                  alignItems: 'center',
                },
              },
            }
          : {}),
      }}
    >
      <section
        css={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'minmax(0,1fr)',
          gridTemplateRows: 'repeat(auto)',
          columnGap: 0,
          rowGap: theme.space.xl,
          [theme.media.small]: {
            rowGap: theme.space.xl2,
          },
          textAlign: centered ? 'center' : 'left',
          '> *': {
            marginLeft: centered ? 'auto' : undefined,
            marginRight: centered ? 'auto' : undefined,
          },
          ...(centeredBreakpoint
            ? {
                [centeredBreakpoint]: {
                  textAlign: 'center',
                },
              }
            : {}),
        }}
        {...rest}
      >
        {children}
      </section>
    </div>
  );
};

interface SectionPairProps {
  children: [
    React.ReactElement<typeof Section>,
    React.ReactElement<typeof Section>,
  ];
  reverse?: boolean;
}

export const SectionPair: React.FC<
  React.PropsWithChildren<SectionPairProps>
> = ({ children, reverse = false, ...props }) => {
  return (
    <div
      css={{
        display: 'grid',
        columnGap: theme.space.xl3,
        rowGap: theme.space.xl3,
        gridTemplateColumns: 'repeat(1,1fr)',
        '> div': {
          padding: 0,
          '&:first-child': {
            gridRow: 1,
          },
          '&:last-child': {
            gridRow: 2,
          },
        },
        [theme.media.small]: {
          rowGap: 0,
          gridTemplateColumns: 'repeat(2,1fr)',
          '> div': {
            padding: 0,
            '&:first-child': {
              gridColumn: reverse ? 2 : 1,
            },
            '&:last-child': {
              gridRow: 1,
              gridColumn: reverse ? 1 : 2,
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export const HeroSection: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<SectionProps>>
> = (props) => {
  return (
    <Section
      {...props}
      css={{
        ['.CopyCaption']: {
          fontSize: theme.fontSize.lg,
          [theme.media.small]: {
            fontSize: theme.fontSize.xl,
          },
        },
        ['.CopyTitle']: {
          fontSize: theme.fontSize.xl4,
          [theme.media.small]: {
            fontSize: theme.fontSize.xl5,
          },
        },
        ['.CopyBody']: {
          fontSize: theme.fontSize.lg,
          [theme.media.small]: {
            fontSize: theme.fontSize.xl,
          },
        },
      }}
    />
  );
};
