import React from 'react';

import { ModalContent } from '@headway/helix/Modal';

export const BlockedGroupPracticeProviderModalContent = () => {
  return (
    <ModalContent>
      <p>
        To keep using Headway, your group practice administrator must accept the
        new terms and conditions.
      </p>
    </ModalContent>
  );
};
