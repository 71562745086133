import { useField } from 'formik';
import React from 'react';

import { MemberIdValidationGuidance } from '../MemberIdValidationGuidance';
import { FieldControlContext } from './FieldControl';
import { FieldInput } from './FieldInput';

interface FieldMemberIdAdvancedValidationProps {
  frontEndCarrierId: number;
  inputProps?: React.ComponentProps<typeof FieldInput>['inputProps'];
}

export const FieldMemberIdAdvancedValidation: React.FC<
  FieldMemberIdAdvancedValidationProps
> = ({ frontEndCarrierId, inputProps = {}, ...props }) => {
  const { name } = React.useContext(FieldControlContext);
  const [field, meta] = useField(name);
  const memberId = field.value;

  return (
    <>
      <FieldInput
        data-testid="memberIdField"
        inputProps={{ ...inputProps, autoComplete: 'off' }}
        {...props}
      />
      {meta.touched && (
        <MemberIdValidationGuidance
          memberId={memberId}
          frontEndCarrierId={frontEndCarrierId}
        />
      )}
    </>
  );
};
