import React from 'react';

import { IconError } from '@headway/helix/icons/Error';
import { theme } from '@headway/helix/theme';
import { Tooltip, VisuallyHidden } from '@headway/ui';
import { theme as legacyTheme } from '@headway/ui/theme';

interface BillingAccountRequirementsWarningProps {
  description: string;
}

export const BillingAccountRequirementsWarning: React.FC<
  React.PropsWithChildren<BillingAccountRequirementsWarningProps>
> = ({ description }) => {
  return (
    <Tooltip title={description}>
      <div
        css={{
          marginLeft: legacyTheme.space.xs,
          color: theme.color.primary.blue,
        }}
      >
        <IconError />
        <VisuallyHidden>Action required</VisuallyHidden>
      </div>
    </Tooltip>
  );
};
