import React from 'react';

export function IconCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M7.875 14.875L3.20834 10.2083L4.10417 9.3125L7.875 13.0833L15.875 5.08334L16.7708 5.97917L7.875 14.875Z"
        fill="currentColor"
      />
    </svg>
  );
}
