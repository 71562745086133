import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Collapse, IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { theme } from '@headway/ui/theme';

import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { useProvider } from 'hooks/useProvider';

import { getWorkingHoursQueryKeyArgs } from '../utils/queries';

type HWMAAlertsProps = {
  lastClosed: string | null;
  onClose: () => void;
};

const HWMAAlerts = ({ lastClosed, onClose }: HWMAAlertsProps) => {
  const provider = useProvider();
  const workingHoursQuery = useFindProviderEvents(
    getWorkingHoursQueryKeyArgs(provider.id)
  );

  const [isDismissed, setIsDismissed] = React.useState(false);

  if (workingHoursQuery.isLoading || !workingHoursQuery.data) {
    return null;
  }

  if (workingHoursQuery.data.data.length > 0 || lastClosed !== null) {
    return null;
  }

  return (
    <Collapse in={isDismissed ? false : true}>
      <Alert
        severity={'warning'}
        action={
          <>
            <Link to={'/settings/calendar'}>
              <Button
                size="small"
                sx={{ color: theme.color.black }}
                variant="text"
              >
                Update
              </Button>
            </Link>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              data-testid="hwma-alert-close-icon"
              onClick={() => {
                setIsDismissed(true);
                onClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </>
        }
      >
        It looks like there are no openings on your calendar right now. Add in
        your working hours so new clients can book with you!
      </Alert>
    </Collapse>
  );
};

export { HWMAAlerts };
