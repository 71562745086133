import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { Button } from '@headway/helix/Button';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { useProviderTreatmentPlanAttachments } from 'hooks/useProviderTreatmentPlanAttachments';
import { Attachment } from 'views/Patients/AttachmentsList';
import { AttachmentsListHelixList } from 'views/Patients/AttachmentsList';

import { PlanJson } from '../Template/types';
import { trackSummaryEditButtonClickedEvent } from '../TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from '../TreatmentPlanContext';
import { StepPreview } from './StepPreview/StepPreview';

export const downloadAttachment = async (
  attachment: Attachment<number | string>
) => {
  try {
    await downloadFile(attachment);
  } catch (err) {
    notifyError('There was an issue downloading your attachment');
    logException(err);
  }
};

export interface TreatmentPlanPreviewProps {
  closeModal: () => void;
}

export const TreatmentPlanPreview = ({
  closeModal,
}: TreatmentPlanPreviewProps) => {
  const { treatmentPlan } = useContext(TreatmentPlanContext);

  const treatmentPlansAttachmentsQuery = useProviderTreatmentPlanAttachments({
    treatmentPlanId: treatmentPlan?.id,
  });
  const treatmentPlanAttachments =
    treatmentPlansAttachmentsQuery.data ??
    ([] as Attachment<number | string>[]);

  return (
    <div>
      {treatmentPlan && (
        <>
          <TreatmentPlanText treatmentPlan={treatmentPlan} />
          {treatmentPlanAttachments.length > 0 && (
            <div css={helixAttachmentsCss.container}>
              <AttachmentsListHelixList
                attachments={treatmentPlanAttachments}
                onDownloadAttachment={downloadAttachment}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const TreatmentPlanText = ({
  treatmentPlan,
}: {
  treatmentPlan: ProviderTreatmentPlanRead;
}) => {
  const {
    steps,
    setCurrentStep,
    page,
    setPage,
    setInitialValues,
    providerPatient,
  } = useContext(TreatmentPlanContext);
  const { values, validateForm } = useFormikContext<any>();
  if (treatmentPlan.planType === TreatmentPlanType.TEXT) {
    const planJson = treatmentPlan.planJson as PlanJson;
    return (
      <div
        className="ph-no-capture"
        css={staticDisplayCss}
        dangerouslySetInnerHTML={{
          __html: sanitize(planJson.text || ''),
        }}
      />
    );
  } else if (treatmentPlan.planType === TreatmentPlanType.TEMPLATE) {
    const goToStep = (step: number) => {
      setInitialValues(values);
      setPage(TREATMENT_PLAN_PAGES.TEMPLATE);
      setCurrentStep(step);
      validateForm();
    };
    const isEditable =
      !treatmentPlan.attestedOn && page === TREATMENT_PLAN_PAGES.SUMMARY;
    return (
      <>
        {(steps || []).map((step, idx) => {
          return (
            <PageSection key={idx}>
              <div css={treatmentPlanTextCss.header}>
                <h2>
                  <SectionHeader>{step.title}</SectionHeader>
                </h2>
                {isEditable && (
                  <Button
                    variant="link"
                    onPress={() => {
                      trackSummaryEditButtonClickedEvent(providerPatient!);
                      goToStep(idx);
                    }}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <StepPreview
                alert
                displayErrors={isEditable}
                step={step}
                variant="large"
              />
            </PageSection>
          );
        })}
      </>
    );
  }
  return null;
};

const treatmentPlanTextCss = {
  header: css({
    ...theme.reset,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
};

const helixAttachmentsCss = {
  container: css({
    borderRadius: theme.spacing.x1,
    border: `1px solid ${theme.color.system.borderGray}`,
    padding: theme.spacing.x2,
    width: '100%',
    marginTop: theme.spacing.x8,
  }),
};

const staticDisplayCss = css({
  maxHeight: 'unset',
  overflowY: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 'unset',
  ...theme.typography.body.regular,
  ' p': {
    margin: 0,
    marginTop: theme.spacing.x3,
    ...theme.typography.body.regular,
  },
  marginTop: theme.spacing.x3,
});
