/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderAccountInviteCreate } from '../models/ProviderAccountInviteCreate';
import { ProviderAccountInviteRead } from '../models/ProviderAccountInviteRead';
import { ProviderAccountInviteUpdate } from '../models/ProviderAccountInviteUpdate';
import { UserRead } from '../models/UserRead';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderAccountInviteApiResource {

    /**
     * Accept Provider Account Invite
     * Update \&quot;accepted_on\&quot; column of the given provider_account_invite Also link provider to this user by adding provider_id to the user Add \&quot;provider\&quot; role to this user\&#39;s roles :param auth: user :param invite_id: id of the account invitation :return:
     * @param inviteId 
     
     */
    public acceptProviderAccountInvite(inviteId: number, axiosConfig?: AxiosRequestConfig): Promise<UserRead> {
        const apiRoute = '/provider-account-invites/{invite_id}/accept';
        const reqPath = apiRoute
                    .replace('{' + 'invite_id}', String(inviteId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider Account Invite
     * 
     * @param providerAccountInviteCreate 
     
     */
    public createProviderAccountInvite(body: ProviderAccountInviteCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderAccountInviteRead> {
        const apiRoute = '/provider-account-invites';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Provider Account Invites
     * 
     * @param providerId 
     * @param userId 
     * @param includeRevoked 
     * @param includeAccepted 
     
     */
    public findProviderAccountInvites(query?: { provider_id?: number, user_id?: number, include_revoked?: boolean, include_accepted?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderAccountInviteRead>> {
        const apiRoute = '/provider-account-invites';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Provider Account Invite
     * 
     * @param inviteId 
     
     */
    public sendProviderAccountInvite(inviteId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-account-invites/{invite_id}/send-email';
        const reqPath = apiRoute
                    .replace('{' + 'invite_id}', String(inviteId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Account Invite
     * 
     * @param inviteId 
     * @param providerAccountInviteUpdate 
     
     */
    public updateProviderAccountInvite(inviteId: number, body: ProviderAccountInviteUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderAccountInviteRead> {
        const apiRoute = '/provider-account-invites/{invite_id}';
        const reqPath = apiRoute
                    .replace('{' + 'invite_id}', String(inviteId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderAccountInviteApi = new ProviderAccountInviteApiResource();
