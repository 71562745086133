import { useProvider } from 'hooks';
import capitalize from 'lodash/capitalize';
import { useContext } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { ModalContent } from '@headway/helix/Modal';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { PROMS_ONLY_RATE_BOOST } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import AssessmentsProductAnnouncementScreenshotSrc from 'assets/img/assessments-product-screenshot.svg';
import TherapistImage from 'assets/img/therapist-study.png';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from 'views/Patients/AddPatient/AddPatientModalContext';

import { INCENTIVE_BONUS_AMOUNT_DOLLARS } from '../../Incentives/constants';

interface AssessmentProductAnnouncementModalProps {
  onDismiss: () => void;
  client: UserRead;
}

export const AssessmentProductAnnouncementModal = ({
  onDismiss,
  client,
}: AssessmentProductAnnouncementModalProps) => {
  const { setCurrentStep } = useContext(AddPatientModalContext);
  const provider = useProvider();

  const { data: providerEnrollmentSummary } =
    useProviderIncentiveProgramEnrollment({
      providerId: provider.id,
    });

  const promsOnlyRateBoostEnabled = useFlag(PROMS_ONLY_RATE_BOOST, false);

  return (
    <ModalContent>
      <div className="flex flex-row">
        <div
          className="flex flex-col py-8 pl-[138px] pr-[40px] max-tablet:p-8"
          css={{ maxWidth: 700 }}
        >
          {providerEnrollmentSummary?.isProviderEnrolled ? (
            <div>
              {promsOnlyRateBoostEnabled ? (
                <div>
                  <SectionHeader>
                    Earn a ${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost for
                    sessions with {capitalize(client.firstName)}
                  </SectionHeader>
                  <div className="pb-2 pt-12">
                    <BodyText>
                      Have your client complete an assessment once every 30 days
                      and earn ${INCENTIVE_BONUS_AMOUNT_DOLLARS} for{' '}
                      <strong>each and every session</strong> with that client —
                      on top of your standard rate.
                    </BodyText>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    <SectionHeader>
                      Have your client complete assessments to meet  1 of 2
                      criteria for rate boosts
                    </SectionHeader>
                  </div>
                  <div className="pb-2 pt-6">
                    <BodyText>
                      <strong>How to unlock rate boosts:</strong>
                    </BodyText>
                  </div>
                  <NumberedList>
                    <NumberedListItem>
                      Your client completes any assessment of your choosing on
                      Headway 30 days before or after the session
                    </NumberedListItem>
                    <NumberedListItem>
                      When confirming session details, upload an existing note
                      OR use a Headway template
                    </NumberedListItem>
                  </NumberedList>
                </div>
              )}
              <div
                className="hlx-typography-subbody text-system-gray"
                css={{ marginTop: theme.spacing.x6 }}
              >
                Review FAQs about this incentive program{' '}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="/incentives/rate-boost"
                >
                  <span className="text-system-gray">here</span>
                </Link>
              </div>
              <div className="my-6 flex flex-col gap-2 tablet:flex-row ">
                <Button variant="secondary" onPress={onDismiss}>
                  Skip for now
                </Button>
                <Button
                  variant="primary"
                  onPress={() =>
                    setCurrentStep(AddPatientModalPage.SEND_ASSESSMENTS)
                  }
                >
                  {`Send assessments to ${capitalize(client.firstName)}`}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-row gap-[10px]">
                <SectionHeader>Send assessments</SectionHeader>
              </div>
              <div className="pb-2 pt-6">
                <BodyText>
                  <strong>What's new:</strong>
                </BodyText>
              </div>
              <ol className="hlx-typography-body my-2 space-y-1 ps-5 text-system-textBlack">
                <li>
                  Choose from a list of evidence-based assessments commonly used
                  by providers
                </li>
                <li>
                  Customize for each client's needs: pause or adjust frequency
                  as necessary
                </li>
                <li>Track progress and understand client-reported concerns</li>
              </ol>
              <div className="my-6 flex flex-col gap-2 tablet:flex-row ">
                <Button variant="secondary" onPress={onDismiss}>
                  Skip for now
                </Button>
                <Button
                  variant="primary"
                  onPress={() =>
                    setCurrentStep(AddPatientModalPage.SEND_ASSESSMENTS)
                  }
                >
                  See list of assessments
                </Button>
              </div>
              <div className="hlx-typography-subbody text-system-gray">
                The feature is not recommended for adolescents or minors at this
                time. Get answers to{' '}
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href="https://help.headway.co/hc/en-us/articles/22620367351956-Sending-assessments-to-clients-on-Headway#h_01HTCY4XARS8DEPF3REEJ3578K"
                >
                  <span className="text-system-gray">
                    common questions about assessments.
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
        {providerEnrollmentSummary?.isProviderEnrolled ? (
          <div
            aria-hidden
            className="relative right-0 w-1/2 min-w-[480px] max-tablet:hidden"
          >
            <div className="absolute right-0 top-8">
              <img
                width={480}
                height={370}
                src={TherapistImage}
                alt="therapist"
                css={{
                  objectFit: 'cover',
                  objectPosition: '100% 0',
                }}
              />
            </div>
          </div>
        ) : (
          <div
            aria-hidden
            className="relative right-0 h-screen w-1/2 min-w-[710px] bg-[#7BCBFF] max-tablet:hidden"
          >
            <div className="absolute right-0 top-8">
              <img src={AssessmentsProductAnnouncementScreenshotSrc} alt="" />
            </div>
          </div>
        )}
      </div>
    </ModalContent>
  );
};
