import { css, cx } from '@emotion/css';
import { AriaLabelingProps } from '@react-types/shared';
import React from 'react';

import { theme } from '@headway/helix/theme';

interface BadgeDotProps extends AriaLabelingProps {
  id?: string;
  hidden: boolean;
  variant: 'neutral' | 'info' | 'positive' | 'warning' | 'negative';
}

const VARIANT_STYLE_MAP: Record<BadgeDotProps['variant'], string> = {
  neutral: css({
    backgroundColor: theme.color.system.textBlack,
    color: theme.color.system.textBlack,
  }),
  info: css({
    backgroundColor: theme.color.primary.blue,
    color: theme.color.primary.blue,
  }),
  positive: css({
    backgroundColor: theme.color.primary.brandGreen,
    color: theme.color.system.green,
  }),
  warning: css({
    backgroundColor: theme.color.primary.yellow,
    color: '#7E5C00',
  }),
  negative: css({
    backgroundColor: theme.color.primary.red,
    color: theme.color.primary.red,
  }),
};

function BadgeDot(props: BadgeDotProps) {
  return (
    <div
      id={props.id}
      className={cx(
        'hlx-badge-dot',
        badgeDotCss,
        !props.hidden && VARIANT_STYLE_MAP[props.variant]
      )}
      data-variant={props.variant}
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      aria-describedby={props['aria-describedby']}
      aria-details={props['aria-details']}
    ></div>
  );
}

const badgeDotCss = css({
  borderRadius: '50%',
  width: '6pt',
  height: '6pt',
  margin: '0 0 0 4px',
});

export { BadgeDot };
