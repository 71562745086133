import * as Yup from 'yup';

export function yupSchemaToDefaultValue(
  schema: Yup.ObjectSchema,
  initialValueArr?: any[]
): {
  [key: string]: any;
} {
  if (!schema.fields) {
    return {};
  }

  return Object.entries(schema.fields).reduce(
    (agg, [key, val]) => {
      if (val instanceof Yup.string) {
        agg[key] = '';
      } else if (val instanceof Yup.array) {
        agg[key] = !!initialValueArr ? initialValueArr : [];
      } else if (val instanceof Yup.boolean) {
        agg[key] = false;
      }
      return agg;
    },
    {} as {
      [key: string]: any;
    }
  );
}
