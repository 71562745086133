import moment from 'moment';

import { Button } from '@headway/ui/Button';
import { theme } from '@headway/ui/theme';

export const ProviderDataManagementBanner = ({
  lastAttestedOn,
  onClick,
}: {
  lastAttestedOn: string;
  onClick: () => void;
}) => {
  //TODO: Change this to use Headway Design System global banner component after SC-43853 complete
  return (
    <div
      css={{
        position: 'fixed',
        background: theme.color.primaryBackground,
        padding: `0 ${theme.space.base}`,
        height: 50,
        top: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 17,
        borderBottom: `1px solid ${theme.color.border}`,
        color: theme.color.black,
      }}
    >
      Verify your information before{' '}
      {moment(lastAttestedOn).add(90, 'days').format('MMMM D')} to keep your
      insurance records up to date.
      <Button
        variant="text"
        css={{
          color: theme.color.black,
          fontWeight: theme.fontWeight.bold,
          borderBottom: '1px solid #BEBEBE',
          borderRadius: 0,
          padding: 0,
          marginLeft: theme.space.base,
          marginTop: 1,
          fontSize: theme.fontSize.base,
          lineHeight: 1.25,
        }}
        onClick={onClick}
      >
        Verify information
      </Button>
    </div>
  );
};
