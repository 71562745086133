import { useQuery } from '@tanstack/react-query';

import { getLatestQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { logException } from '@headway/shared/utils/sentry';

import { useAuthStore } from 'stores/AuthStore';

export const useIntakeCompletionStatus = () => {
  const { provider } = useAuthStore();
  const {
    data: isIntakeIncomplete,
    isLoading: isIntakeIncompleteLoading,
    isError: isIntakeIncompleteError,
  } = useQuery(
    ['latest-questionnaire', provider.id],
    async () => {
      const latestQuestionnaireObject = await getLatestQuestionnaire(
        provider.id
      );
      return (
        latestQuestionnaireObject?.selectedQuestionnaire?.completedOn === null
      );
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        logException(error);
      },
    }
  );

  return {
    isIntakeIncomplete,
    isIntakeIncompleteLoading,
    isIntakeIncompleteError,
  };
};
