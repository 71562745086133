import React, { useEffect } from 'react';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';

export const insuranceShouldBlockTicketCreation = (
  insurance: UserInsuranceRead | undefined
) => {
  // TODO THIS IS A TEMPORARY CONDITION ADDED DURING BSC REMEDIATION
  // CONTACT SHIKHA FOR MORE INFO
  return false;
  if (!insurance) {
    return false;
  }
  return isMagellanInsurance(insurance);
};

export const InsuranceIsMedicareOrMedicaidAlert: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  insurance,
  contactUs = false,
  isProvider = false,
  baseUrl,
  ...rest
}) => {
  return (
    <GuidanceCard layout="vertical" variant="warning" {...rest}>
      <BodyText>
        Looks like {isProvider ? 'your client’s' : 'your'} plan is through
        Medicare / Medicaid. We don’t support this plan at this time, even those
        administered through{' '}
        {insurance?.frontEndCarrierName ||
          `${isProvider ? "your client's" : 'your'} plan`}
        .
      </BodyText>
      {contactUs && (
        <BodyText>
          {' '}
          Not insured through Medicare or Medicaid?&nbsp;
          <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
            Contact us.
          </Link>
        </BodyText>
      )}
    </GuidanceCard>
  );
};

export const InsuranceIsInactiveAlert: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  contactUs,
  insuranceLocation = 'above',
  baseUrl,
  onOpenInsuranceModal,
  isSiteMessagingOppsEnabled = false,
  ...rest
}) => {
  if (isSiteMessagingOppsEnabled) {
    return (
      <GuidanceCard variant="warning">
        <BodyText>
          Your plan is showing as "inactive". This could be a temporary problem
          with the info we received from your insurer. If you have new insurance
          details, <Link onClick={onOpenInsuranceModal}>add them here</Link>.
          {contactUs && (
            <>
              {' '}
              If you believe this plan is active,{' '}
              <Link href={`${baseUrl}/contact`} target="_blank">
                request a manual verification
              </Link>{' '}
              and we’ll get back to you.
            </>
          )}
        </BodyText>
      </GuidanceCard>
    );
  }

  return (
    <GuidanceCard layout="vertical" variant="warning" {...rest}>
      <BodyText>
        It looks like this plan is no longer active. If there are new insurance
        details,&nbsp;
        <Button variant="link" size="medium" onPress={onOpenInsuranceModal}>
          add them here
        </Button>
        &nbsp;to instantly verify coverage.
        {contactUs && (
          <>
            {' '}
            If this sounds incorrect and you believe the plan is still
            active,&nbsp;
            <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
              contact us
            </Link>
            &nbsp;to report an insurance issue and we’ll get back to you.
          </>
        )}
      </BodyText>
    </GuidanceCard>
  );
};

const HeadwayOONAlert = ({
  clientId,
  providerId,
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  clientId: number;
  providerId: number;
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  useEffect(() => {
    trackPageView({
      name: 'OON Banner Viewed',
      properties: {
        providerId: providerId,
        patientUserId: clientId,
      },
    });
  }, [providerId, clientId]);

  return (
    <GuidanceCard variant="error">
      <BodyText>
        This plan is not eligible for in-network care on Headway.
      </BodyText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

const TerminatedAlert = ({
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <BodyText>This plan is not active.</BodyText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

const NoMentalHealthBenefitsAlert = ({
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <BodyText>This plan does not include mental health coverage.</BodyText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

const ManualVerificationRequiredAlert = ({
  clientName,
  onClickOpenInsuranceIneligibilityExplainerModal,
}: {
  clientName?: string;
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <BodyText>
        {clientName ? clientName : `Your client`}'s plan needs to be manually
        verified.
      </BodyText>
      {onClickOpenInsuranceIneligibilityExplainerModal && (
        <Button
          variant="link"
          onPress={() => {
            onClickOpenInsuranceIneligibilityExplainerModal();
          }}
        >
          Learn more
        </Button>
      )}
    </GuidanceCard>
  );
};

export const InsuranceIsMagellanAlert: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  insurance,
  contactUs = false,
  isProvider = false,
  baseUrl,
  ...rest
}) => {
  return (
    <GuidanceCard layout="vertical" variant="warning" {...rest}>
      {insurance?.frontEndCarrierName ? (
        <BodyText>
          We detected that {isProvider ? 'your client is' : 'you’re'} on a{' '}
          <b>{insurance.frontEndCarrierName}</b> plan with{' '}
          <b>Behavioral Health benefits</b> through <b>Magellan</b>. Your
          insurance will be inactive at this time since Headway does not offer
          coverage to this specific plan.
        </BodyText>
      ) : (
        <BodyText>
          We detected that {isProvider ? "your client's" : 'your'} plan has{' '}
          <b>Behavioral Health benefits</b> through <b>Magellan</b>. Your
          insurance will be inactive at this time since Headway does not offer
          coverage to this specific plan.
        </BodyText>
      )}
      {contactUs && (
        <BodyText>
          {' '}
          Not insured through Magellan?&nbsp;
          <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
            Contact us
          </Link>
        </BodyText>
      )}
    </GuidanceCard>
  );
};

export const InsuranceIsBCBSInfo: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <GuidanceCard variant="info">
      <BodyText>
        If you are a BlueCard member and your member ID contains an alphanumeric
        identification number, please include the first three letters, for
        example: “XYZ123456789”
      </BodyText>
    </GuidanceCard>
  );
};

export const InsuranceIsInactiveBCBSAlert: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <GuidanceCard layout="vertical" variant="warning">
      <BodyText>
        Looks like your Blue Cross Blue Shield insurance details are showing as
        ineligible.
      </BodyText>
      <br />
      <br />
      <BodyText>
        If you are a BlueCard member, please make sure to include the full
        alphanumeric identification number, including the first three letters,
        for example “XYZ123456789”
      </BodyText>
    </GuidanceCard>
  );
};

export const InsuranceInformationIncorrect: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  contactUs = false,
  baseUrl,
  isHumanErrorBannerEnabled = false,
  insurance,
  isProvider,
  providerUserId,
  ...rest
}) => {
  const {
    onClickOpenBillingMethodModal,
    onOpenInsuranceModal,
    isHwInputError,
  } = rest;

  React.useEffect(() => {
    if (isHumanErrorBannerEnabled) {
      trackPageView({
        name: 'Inactive Insurance Messaging Viewed',
        properties: {
          lookupStatus: LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
          userId:
            isProvider && providerUserId ? providerUserId : insurance!.userId,
          patientUserId: isProvider ? insurance!.userId : null,
          confettiHumanInputErrorExperimentGroup: 'treatment',
          confettiHumanInputErrorSurfacedBy: isHwInputError ? 'HW' : 'CHC',
        },
      });
    }
  }, [isHumanErrorBannerEnabled]);

  // TODO(soleil/sc-128914): cleanup during flag cleanup
  if (isHumanErrorBannerEnabled) {
    return (
      <GuidanceCard variant="error" layout="vertical">
        <BodyText>
          <strong>Did you type that correctly?</strong> Please check for typos
          and confirm the details match the details on the insurance card for
          the client (the person receiving care). If everything matches,{' '}
          <Link
            href={`${baseUrl}/contact`}
            target="_blank"
            onClick={() => {
              trackEvent({
                name: 'Contact Form Link Clicked',
                properties: {
                  lookupStatus:
                    LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                  userId: insurance!.userId,
                  confettiHumanInputErrorExperimentGroup: 'treatment',
                  confettiHumanInputErrorSurfacedBy: isHwInputError
                    ? 'HW'
                    : 'CHC',
                },
              });
            }}
          >
            request a manual verification
          </Link>
          .
        </BodyText>
        {onClickOpenBillingMethodModal ? (
          <Button
            variant="link"
            onPress={() => {
              trackEvent({
                name: 'Inactive Insurance Messaging CTA Clicked',
                properties: {
                  lookupStatus:
                    LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                  userId: providerUserId!,
                  patientUserId: insurance!.userId,
                  confettiHumanInputErrorExperimentGroup: 'treatment',
                  confettiHumanInputErrorSurfacedBy: isHwInputError
                    ? 'HW'
                    : 'CHC',
                },
              });
              onClickOpenBillingMethodModal();
            }}
          >
            Update insurance information
          </Button>
        ) : onOpenInsuranceModal ? (
          <Button
            variant="link"
            onPress={() => {
              trackEvent({
                name: 'Inactive Insurance Messaging CTA Clicked',
                properties: {
                  lookupStatus:
                    LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION,
                  userId: insurance!.userId,
                  confettiHumanInputErrorExperimentGroup: 'treatment',
                  confettiHumanInputErrorSurfacedBy: isHwInputError
                    ? 'HW'
                    : 'CHC',
                },
              });
              onOpenInsuranceModal();
            }}
          >
            Update insurance information
          </Button>
        ) : null}
      </GuidanceCard>
    );
  }
  return (
    <GuidanceCard layout="vertical" variant="warning">
      <BodyText>
        We had trouble finding a patient from the provided information
      </BodyText>
      <br />
      <br />
      <BodyText>
        Please double check that the information provided matches what the
        insurer has on file for that patient.
      </BodyText>
      {contactUs && (
        <BodyText>
          {' '}
          Patient information looks right to you?&nbsp;
          <Link href={`${baseUrl}/contact`} target="_blank" rel="noreferrer">
            Contact us
          </Link>
        </BodyText>
      )}
    </GuidanceCard>
  );
};

interface ContactFormInsuranceAlertsProps {
  insurance?: UserInsuranceRead;
  contactUs?: boolean;
  isProvider?: boolean;
  providerUserId?: number;
  providerId?: number;
  baseUrl: string;
  insuranceLocation?: 'above' | 'below';

  //Call back for triggering billing method modal
  onClickOpenBillingMethodModal?: () => void;
  onOpenInsuranceModal?: () => void;
  onClickOpenInsuranceIneligibilityExplainerModal?: () => void;
  isOONBannerEnabled?: boolean;
  isHumanErrorBannerEnabled?: boolean;
  isSiteMessagingOppsEnabled?: boolean;
  isHwInputError?: boolean;
}

const isMagellanInsurance = (insurance?: UserInsuranceRead) =>
  insurance?.correctedFrontEndCarrierId ===
    FrontEndCarrierIdentifier.MAGELLAN ||
  insurance?.latestEligibilityLookup?.frontEndCarrierId ===
    FrontEndCarrierIdentifier.MAGELLAN;
const isInactiveInsurance = (insurance: UserInsuranceRead) =>
  !insurance.latestEligibilityLookup?.isInsuranceActive;

const isOONForHeadway = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.outOfNetwork;

const isTerminated = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.terminated;

const isNoMentalHealthBenefits = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.hasNoMentalHealthBenefits;

const isManualVerificationRequired = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.manualVerificationRequired;

const isMedicareOrMedicaidInsurance = (insurance: UserInsuranceRead) =>
  insurance.latestEligibilityLookup?.isMedicaid ||
  insurance.latestEligibilityLookup?.isMedicare;

export const ContactFormInsuranceAlerts: React.FC<
  React.PropsWithChildren<ContactFormInsuranceAlertsProps>
> = ({
  isHumanErrorBannerEnabled = false,
  isSiteMessagingOppsEnabled = false,
  ...rest
}) => {
  const { insurance, isProvider, providerId, isHwInputError } = rest;
  if (!insurance) {
    return <></>;
  }

  if (isMagellanInsurance(insurance)) {
    return <InsuranceIsMagellanAlert {...rest} />;
  }

  if (isMedicareOrMedicaidInsurance(insurance)) {
    return <InsuranceIsMedicareOrMedicaidAlert {...rest} />;
  }

  if (
    !isProvider &&
    isFrontEndCarrierIdBcbs(insurance.frontEndCarrierId) && //TODO SC-263184
    isInactiveInsurance(insurance)
  ) {
    return <InsuranceIsInactiveBCBSAlert />;
  }

  if (
    insurance.latestEligibilityLookup?.lookupStatus ===
      LookupStatus.INSUFFICIENT_OR_INCORRECT_INFORMATION ||
    isHumanErrorBannerEnabled
  ) {
    return (
      <InsuranceInformationIncorrect
        isHumanErrorBannerEnabled={isHumanErrorBannerEnabled}
        insurance={insurance}
        isHwInputError={isHwInputError}
        {...rest}
      />
    );
  }
  if (isProvider && providerId && isTerminated(insurance)) {
    return <TerminatedAlert {...rest} />;
  }
  if (isProvider && providerId && isNoMentalHealthBenefits(insurance)) {
    return <NoMentalHealthBenefitsAlert {...rest} />;
  }
  if (isProvider && providerId && isManualVerificationRequired(insurance)) {
    return (
      <ManualVerificationRequiredAlert
        clientName={insurance.firstName}
        {...rest}
      />
    );
  }

  if (isInactiveInsurance(insurance)) {
    return (
      <InsuranceIsInactiveAlert
        {...rest}
        isSiteMessagingOppsEnabled={isSiteMessagingOppsEnabled}
      />
    );
  }

  return <></>;
};
