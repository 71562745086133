import { BankAccountVerificationStatus } from '@headway/api/models/BankAccountVerificationStatus';
import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';

export function getUserPaymentMethodName(
  userPaymentMethod: UserPaymentMethodRead | undefined
) {
  if (!!userPaymentMethod) {
    if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
      return userPaymentMethod.card?.brand || 'Credit Card';
    } else if (userPaymentMethod.type === UserPaymentMethodType.BANK_ACCOUNT) {
      return userPaymentMethod?.bankAccount?.bankName || 'Bank Account';
    }
  }
  return 'Account';
}

export function getUserPaymentMethodLast4(
  userPaymentMethod: UserPaymentMethodRead | undefined
) {
  if (!!userPaymentMethod) {
    if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
      return userPaymentMethod.card?.last4 || '';
    }
    return userPaymentMethod.bankAccount?.last4 || '';
  }
  return '';
}

export function getUserPaymentMethodVerificationStatus(
  userPaymentMethod: UserPaymentMethodRead
) {
  if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
    return BankAccountVerificationStatus.VERIFIED;
  }

  return userPaymentMethod.bankAccount?.verificationStatus;
}

export function plaidStatusToBankAccountStatus(status?: string) {
  if (status === 'pending_automatic_verification') {
    return BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION;
  } else if (status === 'pending_manual_verification') {
    return BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION;
  }

  return BankAccountVerificationStatus.VERIFIED;
}
