import {
  CheckCircleOutlineTwoTone,
  ErrorOutlineTwoTone,
  ScheduleTwoTone,
} from '@mui/icons-material';

import { BankAccountVerificationStatus } from '@headway/api/models/BankAccountVerificationStatus';
import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';
import { UserRead } from '@headway/api/models/UserRead';
import { formatPatientName } from '@headway/shared/utils/patient';
import { theme } from '@headway/ui/theme';

import { PatientStatusConfig } from './patientStatus';

export type PatientPaymentStatus =
  | 'VERIFIED'
  | 'NOT_NEEDED'
  | 'NO_PAYMENT_METHODS_ON_FILE'
  | 'NO_VERIFIED_PAYMENT_METHODS_ON_FILE'
  | 'BANK_ACCOUNT_PENDING_AUTOMATIC_VERIFICATION'
  | 'BANK_ACCOUNT_PENDING_MANUAL_VERIFICATION'
  | 'BANK_ACCOUNT_VERIFICATION_FAILED';

export const PatientPaymentStatusToConfigMap: {
  [key in PatientPaymentStatus]: PatientStatusConfig;
} = {
  VERIFIED: {
    guidanceCardVariant: 'info',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'positive',
    colorDescriptor: 'success',
    color: theme.color.success,
    chipLabel: 'Verified',
    icon: CheckCircleOutlineTwoTone,
    getExplanation: (patientUser?: UserRead) => '',
  },
  NOT_NEEDED: {
    guidanceCardVariant: 'info',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'positive',
    colorDescriptor: 'success',
    color: theme.color.success,
    chipLabel: 'Not Needed',
    icon: CheckCircleOutlineTwoTone,
    getExplanation: (patientUser?: UserRead) =>
      'Payment method not required for EAP patient.',
  },
  NO_PAYMENT_METHODS_ON_FILE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'vertical',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Missing',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} is missing payment information.`,
    bannerCta: 'Add Payment',
    getExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} has not provided payment information. In order to confirm session details, 
      add a credit card for ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} by clicking Add or ask them to add a form of payment to their account.`,
  },
  NO_VERIFIED_PAYMENT_METHODS_ON_FILE: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'vertical',
    guidanceCardLayoutForBanner: 'vertical',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Verification failed',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} does not have a valid payment method on file. Please make sure one is added or you will not be able to confirm any sessions for this client.`,
    getExplanation: (patientUser?: UserRead) => '',
  },
  BANK_ACCOUNT_PENDING_AUTOMATIC_VERIFICATION: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'vertical',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Pending automatic verification',
    icon: ScheduleTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s bank account is not yet verified.`,
    bannerCta: 'Add a different payment',
    getExplanation: (patientUser?: UserRead) =>
      `We are waiting on automatic verification from the bank for ${formatPatientName(
        patientUser,
        { firstNameOnly: true }
      )}'s bank account. This typically takes upto 3 days. 
      If you want to add a new credit card for ${formatPatientName(
        patientUser,
        { firstNameOnly: true }
      )}, you can do so here.`,
  },
  BANK_ACCOUNT_PENDING_MANUAL_VERIFICATION: {
    guidanceCardVariant: 'warning',
    guidanceCardLayout: 'horizontal',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'warning',
    colorDescriptor: 'warning',
    color: theme.color.warning,
    chipLabel: 'Pending manual verification',
    icon: ScheduleTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s bank account is not yet verified.`,
    bannerCta: 'Add a different payment',
    getExplanation: (patientUser?: UserRead) =>
      `We are waiting for ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} to verify their bank account.
      If you want to add a new credit card for ${formatPatientName(
        patientUser,
        { firstNameOnly: true }
      )}, you can do so here.`,
  },
  BANK_ACCOUNT_VERIFICATION_FAILED: {
    guidanceCardVariant: 'error',
    guidanceCardLayout: 'vertical',
    guidanceCardLayoutForBanner: 'horizontal',
    badgeVariant: 'negative',
    colorDescriptor: 'error',
    color: theme.color.error,
    chipLabel: 'Verification failed',
    icon: ErrorOutlineTwoTone,
    getBannerExplanation: (patientUser?: UserRead) =>
      `We were not able to verify ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s bank account.`,
    bannerCta: 'Add a different payment',
    getExplanation: (patientUser?: UserRead) =>
      `We couldn't verify ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })}'s bank account. 
      You will not be able to confirm session details with them until they have a verified payment method. 
      In order to confirm session details, add a credit card for ${formatPatientName(
        patientUser,
        { firstNameOnly: true }
      )} by clicking Add or 
      contact ${formatPatientName(patientUser, {
        firstNameOnly: true,
      })} to add a valid payment to their account.`,
  },
};

export function getUserPaymentStatus(
  userPaymentMethod?: UserPaymentMethodRead,
  applyEAP?: boolean
): PatientPaymentStatus {
  if (applyEAP) {
    return 'NOT_NEEDED';
  }
  if (!userPaymentMethod) {
    return 'NO_PAYMENT_METHODS_ON_FILE';
  }
  if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
    return 'VERIFIED';
  } else if (userPaymentMethod.type === UserPaymentMethodType.BANK_ACCOUNT) {
    const verificationStatus =
      userPaymentMethod.bankAccount!.verificationStatus;
    switch (verificationStatus) {
      case BankAccountVerificationStatus.VERIFIED:
        return 'VERIFIED';
      case BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION:
        return 'BANK_ACCOUNT_PENDING_AUTOMATIC_VERIFICATION';
      case BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION:
        return 'BANK_ACCOUNT_PENDING_MANUAL_VERIFICATION';
      case BankAccountVerificationStatus.VERIFICATION_FAILED:
        return 'BANK_ACCOUNT_VERIFICATION_FAILED';
    }
  }
  return 'BANK_ACCOUNT_VERIFICATION_FAILED';
}

export function getUserPaymentsStatus(
  userPaymentMethods?: UserPaymentMethodRead[],
  applyEAP?: boolean
): PatientPaymentStatus {
  if (applyEAP) {
    return 'NOT_NEEDED';
  }

  if (!userPaymentMethods || userPaymentMethods.length === 0) {
    return 'NO_PAYMENT_METHODS_ON_FILE';
  }

  if (
    userPaymentMethods.every(
      (paymentMethod) => paymentMethod.isVerified === false
    )
  ) {
    return 'NO_VERIFIED_PAYMENT_METHODS_ON_FILE';
  }

  const defaultPaymentMethod = userPaymentMethods.find(
    (paymentMethod) => paymentMethod.isDefault
  );

  if (defaultPaymentMethod?.type === UserPaymentMethodType.BANK_ACCOUNT) {
    const verificationStatus =
      defaultPaymentMethod.bankAccount!.verificationStatus;
    switch (verificationStatus) {
      case BankAccountVerificationStatus.VERIFIED:
        return 'VERIFIED';
      case BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION:
        return 'BANK_ACCOUNT_PENDING_AUTOMATIC_VERIFICATION';
      case BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION:
        return 'BANK_ACCOUNT_PENDING_MANUAL_VERIFICATION';
      case BankAccountVerificationStatus.VERIFICATION_FAILED:
        return 'BANK_ACCOUNT_VERIFICATION_FAILED';
    }
  }

  return 'VERIFIED';
}
