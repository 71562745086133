import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { EventFilterFunction } from '.';

export interface AppointmentStatusData {
  hideCanceled?: Boolean;
}

export const filterByAppointmentStatus: EventFilterFunction<
  AppointmentStatusData
> = (event: ProviderEventRead, filterData: AppointmentStatusData) => {
  if (!event.providerAppointment) {
    return false;
  }

  if (
    filterData.hideCanceled &&
    event.providerAppointment.status === ProviderAppointmentStatus.CANCELED
  ) {
    return true;
  }

  return false;
};
