import { intersection } from 'lodash';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import {
  ENABLE_PROVIDER_PANELABILITY_FOR_NEW_PROVIDERS,
  ENABLE_PROVIDER_PANELABILITY_INTAKE_FORM_V2,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';

export const useIsPanelabilityIntakeFormEnabled = (
  providerSelectedPracticeStates: UnitedStates[] | undefined
): boolean => {
  const providerPanelabilityFlag = useFlag(
    ENABLE_PROVIDER_PANELABILITY_INTAKE_FORM_V2,
    { enabled: false }
  );

  if (!providerPanelabilityFlag.enabled) {
    return false;
  }

  return (
    // NONE of the providerSelectedPracticeStates can be in disabledStates
    intersection(
      providerSelectedPracticeStates || [],
      providerPanelabilityFlag.disabledStates || []
    ).length === 0
  );
};

export const useIsPanelabilityEnabledForNewProviders = (
  providerSelectedPracticeStates: UnitedStates[] | undefined
): boolean => {
  const shouldUseProviderPanelability = useIsPanelabilityIntakeFormEnabled(
    providerSelectedPracticeStates
  );
  const isNewProviderFlagEnabled = useFlag(
    ENABLE_PROVIDER_PANELABILITY_FOR_NEW_PROVIDERS
  );
  return (
    shouldUseProviderPanelability &&
    isNewProviderFlagEnabled &&
    !!providerSelectedPracticeStates?.length
  );
};
