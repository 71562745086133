import clsx from 'clsx';
import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListHeader } from '@headway/helix/ListHeader';
import { PageSection } from '@headway/helix/Page';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPriceFromCents } from '@headway/shared/utils/payments';

import TherapistImage from 'assets/img/cropped-therapist-study.png';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';
import {
  INCENTIVE_BONUS_AMOUNT_DOLLARS,
  RateBoostBannerCopyVariant,
} from 'views/Incentives/constants';

export const EnrollmentSummaryCard = () => {
  const provider = useProvider();
  const [didTrackCardViewEvent, setDidTrackCardViewEvent] = useState(false);
  const isBelowTabletMedia = useMediaQuery(theme.__futureMedia.below('tablet'));
  const {
    data: providerEnrollmentSummary,
    isLoading: isEnrollmentSummaryLoading,
  } = useProviderIncentiveProgramEnrollment({
    providerId: provider.id,
  });

  const [lastClosedMichiganPilotCardDate, setLastClosedMichiganPilotCardDate] =
    useLocalStorage('lastClosedMichiganPilotCardDate');

  const [
    numTimesProviderClosedIncentiveProgramCard,
    setNumTimesProviderClosedIncentiveProgramCard,
  ] = useLocalStorage('numTimesProviderClosedIncentiveProgramCard');

  const hasBeenLessThan7daysSinceLastClosedCard =
    !!lastClosedMichiganPilotCardDate &&
    moment().diff(moment(lastClosedMichiganPilotCardDate), 'days') <= 7;

  let showCard = true;
  // don't show card if user has closed the card twice
  // or it has not been more than 7 days since the last time the user closed the card
  if (
    Number(numTimesProviderClosedIncentiveProgramCard) == 2 ||
    (hasBeenLessThan7daysSinceLastClosedCard &&
      Number(numTimesProviderClosedIncentiveProgramCard) < 2)
  ) {
    showCard = false;
  }

  if (
    isEnrollmentSummaryLoading ||
    !providerEnrollmentSummary ||
    !providerEnrollmentSummary?.isProviderEnrolled
  ) {
    showCard = false;
  }

  useEffect(() => {
    if (showCard && !didTrackCardViewEvent) {
      trackEvent({
        name: 'Rate Boost Banner Viewed',
        properties: {
          copyVariant: RateBoostBannerCopyVariant.LARGE_GUIDANCE_CARD,
        },
      });
      setDidTrackCardViewEvent(true);
    }
  }, [showCard, didTrackCardViewEvent]);

  if (!showCard) {
    return null;
  }

  const {
    estimatedRateBoostAmountInCents,
    totalIncentiveAmountEarnedInCents,
    numAptsMetRequirements,
    numClientsSeenLastMonth,
  } = providerEnrollmentSummary!;

  const providerRateEligible = totalIncentiveAmountEarnedInCents > 0;

  const closeBanner = () => {
    if (!numTimesProviderClosedIncentiveProgramCard) {
      setNumTimesProviderClosedIncentiveProgramCard('1');
      setLastClosedMichiganPilotCardDate(new Date().toISOString());
    } else {
      setNumTimesProviderClosedIncentiveProgramCard('2');
    }
    trackEvent({ name: 'Close Rate Boost Button Clicked' });
  };

  const headerContent = () => {
    if (providerRateEligible) {
      return `Congrats! You've unlocked ${formatPriceFromCents(
        totalIncentiveAmountEarnedInCents
      )} in rate boosts so far`;
    } else if (estimatedRateBoostAmountInCents >= 10000) {
      return `Earn up to ${formatPriceFromCents(
        estimatedRateBoostAmountInCents
      )}* more per month with rate boosts for clients in Michigan`;
    } else {
      return `Earn a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost for sessions with clients in Michigan`;
    }
  };

  const bodyContent = () => {
    if (providerRateEligible) {
      return `You met both requirements for ${numAptsMetRequirements} ${
        numAptsMetRequirements > 1 ? 'sessions' : 'session'
      }!`;
    } else if (estimatedRateBoostAmountInCents >= 10000) {
      return (
        <>
          <ul className="mb-3 ml-5 list-disc">
            <li>
              Last month, you saw {numClientsSeenLastMonth} clients in Michigan
            </li>
            <li>
              Each could qualify for this boost if they complete assessments and
              you add notes for their sessions on Headway
            </li>
          </ul>
          <BodyText>
            *Estimate based on the total number of sessions you held with these
            clients last month
          </BodyText>
        </>
      );
    } else {
      return `Starting today, if you meet assessments and notes requirements, you'll get a $${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost per session`;
    }
  };

  const buttonContent = () => {
    if (providerRateEligible) {
      return (
        <>
          <LinkButton
            variant="primary"
            href={'/payments/upcoming'}
            target="_blank"
          >
            View Payments
          </LinkButton>
          <Button variant="secondary" onPress={closeBanner}>
            Close
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button variant="secondary" onPress={closeBanner}>
            Close
          </Button>
          <LinkButton
            variant="primary"
            href={'/incentives/rate-boost'}
            target="_blank"
            onPress={() =>
              trackEvent({ name: 'Learn More Rate Boost Button Clicked' })
            }
          >
            Learn more
          </LinkButton>
        </>
      );
    }
  };

  return (
    <PageSection>
      <div className="flex-between flex rounded-[8px] bg-hue-lightBlue">
        <div className={clsx('p-5', !isBelowTabletMedia && 'w-2/3')}>
          <ListHeader>{headerContent()}</ListHeader>
          <div className="pt-3">{bodyContent()}</div>
          <div className="flex items-center gap-3 pt-5">{buttonContent()}</div>
        </div>
        {!isBelowTabletMedia && (
          <div className="w-1/3">
            <img
              role="presentation"
              src={TherapistImage}
              className="h-full w-full rounded-r-[8px]"
            />
          </div>
        )}
      </div>
    </PageSection>
  );
};
