import styled from '@emotion/styled';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

import { useMediaQuery } from '@headway/helix/utils';

import { theme } from '../theme';

const SliderButtonContainer = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  WebkitTransform: 'translateY(-50%)',
  background: theme.color.white,
  border: `1px solid ${theme.color.border}`,
  borderRadius: '50%',
  boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 1px 1px',
  '&.swiper-button-disabled': {
    display: 'none',
  },
  display: 'none',
  [theme.media.medium]: {
    display: 'block',
  },
});

interface Details {
  widthOrHeight: number;
  positions: { distance: number; portion: number }[];
}

// smoothly transition each elements opacity
function opacityStyle(idx: number, details?: Details) {
  if (details) {
    const position = details?.positions[idx];
    const scale_size = 1.1;
    const scale = 1 - (scale_size - scale_size * (position?.portion ?? 1));
    return {
      opacity: Math.max(scale, 0.2),
    };
  }
  return {};
}

export const Slider: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = ({ children, ...rest }) => {
  // render slightly different slide configuration on small
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);

  // track current slide so we can navigate with buttons
  const [currentSlide, setCurrentSlide] = React.useState(0);

  // setup state to track position of each slider
  const [details, setDetails] = React.useState<Details | undefined>();

  // configure slider
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: isSmall ? 1.3 : 1.5,
    spacing: isSmall ? 15 : 30,
    initial: 1,
    centered: true,
    slideChanged(localSlider) {
      setCurrentSlide(localSlider.details().relativeSlide);
    },
    move(localSlider) {
      setDetails(localSlider.details());
    },
  });

  return (
    <React.Fragment>
      <section
        css={{
          position: 'relative',
          width: '100%',
          minWidth: 0,
        }}
        {...rest}
      >
        <div
          ref={sliderRef}
          className="keen-slider"
          css={{ overflow: 'visible !important' }}
        >
          {React.Children.map(children, (child, index) => {
            const style = opacityStyle(index, details);
            const isHidden =
              !style || !style.opacity ? true : style.opacity <= 0.2;
            return (
              <div
                className="keen-slider__slide"
                css={{
                  overflow: 'visible',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid transparent',
                  cursor: 'pointer',
                  '> *': {
                    pointerEvents: index !== currentSlide ? 'none' : 'initial',
                  },
                }}
                // using the `style` prop directly improves performance since the opacity style changes frequently
                style={style}
                aria-hidden={isHidden}
                role="group"
                aria-label={`slide ${index + 1} of ${React.Children.count(
                  children
                )}`}
                onClick={(e: any) => {
                  if (index < currentSlide) {
                    e.stopPropagation();
                    slider.prev();
                  } else if (index > currentSlide) {
                    e.stopPropagation();
                    slider.next();
                  }
                }}
              >
                {React.isValidElement(child)
                  ? React.cloneElement<any>(child, { isHidden })
                  : null}
              </div>
            );
          })}
        </div>
        {slider && (
          <>
            <SliderButtonContainer
              css={{
                left: 5,
              }}
            >
              <IconButton
                aria-label="previous slide"
                onClick={(e: any) => {
                  e.stopPropagation();
                  slider.prev();
                }}
                color="primary"
                disabled={currentSlide === 0}
                size="large"
              >
                <ArrowBack />
              </IconButton>
            </SliderButtonContainer>
            <SliderButtonContainer
              css={{
                left: 'auto',
                right: 5,
              }}
            >
              <IconButton
                aria-label="next slide"
                onClick={(e: any) => {
                  e.stopPropagation();
                  slider.next();
                }}
                color="primary"
                disabled={currentSlide === slider.details().size - 1}
                size="large"
              >
                <ArrowForward />
              </IconButton>
            </SliderButtonContainer>
          </>
        )}
      </section>
    </React.Fragment>
  );
};
