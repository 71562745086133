import moment from 'moment';

import { ProviderPaymentPauseRead } from '@headway/api/models/ProviderPaymentPauseRead';

const getLatestProviderPaymentPause = (
  providerPaymentPauses: ProviderPaymentPauseRead[]
) => {
  if (!providerPaymentPauses || providerPaymentPauses.length === 0) {
    return null;
  }

  // Just like in the backend, let's sort those by startedOn dates
  const sortedPaymentPauses = providerPaymentPauses.sort((pauseA, pauseB) =>
    moment(pauseB.startedOn).diff(moment(pauseA.startedOn))
  );
  return sortedPaymentPauses[0];
};

const getActiveProviderPaymentPause = (
  providerPaymentPauses: ProviderPaymentPauseRead[]
) => {
  const latestProviderPaymentPause = getLatestProviderPaymentPause(
    providerPaymentPauses
  );

  const now = moment().startOf('day');
  const startedOn = moment(latestProviderPaymentPause?.startedOn).startOf(
    'day'
  );
  const endedOn = latestProviderPaymentPause?.endedOn
    ? moment(latestProviderPaymentPause.endedOn).startOf('day')
    : null;
  const isActive = startedOn <= now && (!endedOn || now < endedOn);

  if (latestProviderPaymentPause && isActive) {
    return latestProviderPaymentPause;
  }
  return null;
};

export { getLatestProviderPaymentPause, getActiveProviderPaymentPause };
