import { BillingType } from '@headway/api/models/BillingType';

export const formatBillingType = (billingType?: BillingType) => {
  switch (billingType) {
    case BillingType.INSURANCE:
      return 'Insurance';
    case BillingType.SELF_PAY:
      return 'Private Pay';
    case BillingType.EAP:
      return 'EAP';
    default:
      return '';
  }
};
