import JsZip from 'jszip';

import { Button, ButtonProps } from '@headway/helix/Button';

import { IroncladVersionData } from 'hooks/useIroncladVersionData';

interface DownloadProviderAgreementButtonProps {
  downloadData: IroncladVersionData[];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
}

const createAndDownloadZip = async (files: { blob: Blob; title: string }[]) => {
  const zip = JsZip();
  files.forEach((file, i) => {
    zip.file(`${file.title}.pdf`, file.blob);
  });
  const zipFile = await zip.generateAsync({ type: 'blob' });
  var downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(zipFile);
  downloadLink.download = 'provider-agreements';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const downloadFile = async (url: string) => {
  const resp = await fetch(url);
  return resp.blob();
};

export const DownloadProviderAgreementButton = ({
  downloadData,
  variant = 'primary',
  size = 'large',
  ...props
}: DownloadProviderAgreementButtonProps) => {
  const downloadIroncladAgreements = async () => {
    const downloadFilePromises: Promise<Blob>[] = [];
    downloadData.forEach((data) =>
      downloadFilePromises.push(downloadFile(data.downloadEndpoint))
    );
    const downloadedFiles = await Promise.all(downloadFilePromises);
    await createAndDownloadZip(
      downloadedFiles.map((blob, index) => ({
        blob,
        title: downloadData[index].title,
      }))
    );
  };

  return (
    <div {...props}>
      <Button
        variant={variant}
        size={size}
        onPress={downloadIroncladAgreements}
      >
        Download Full Terms
      </Button>
    </div>
  );
};
