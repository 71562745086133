import { captureException, setUser } from '@sentry/core';
import { CaptureContext, SeverityLevel } from '@sentry/types';

const errorDestinationOptions = ['CONSOLE', 'SENTRY'] as const;

type ErrorLogDestination = (typeof errorDestinationOptions)[number];

let errorLogDestination: ErrorLogDestination = 'SENTRY';

export const setErrorLogDestination = (destination: ErrorLogDestination) => {
  if (!errorDestinationOptions.includes(destination)) {
    throw new Error(`Invalid error log destination '${destination}'`);
  }

  errorLogDestination = destination;
};

export const logException = (
  exception: any,
  additionalContext: CaptureContext = {}
) => {
  if (errorLogDestination === 'SENTRY') {
    captureException(exception, additionalContext);

    return;
  }

  logExceptionToConsole(exception, 'error', additionalContext);
};

export const logWarning = (
  message: string,
  additionalContext: CaptureContext = {}
) => {
  if (errorLogDestination === 'SENTRY') {
    captureException(message, {
      ...additionalContext,
      level: 'warning',
    });

    return;
  }

  logExceptionToConsole(message, 'warning', additionalContext);
};

export const identifyUser = ({ id }: { id: string }) => {
  setUser({ id });
};

const logExceptionToConsole = (
  exception: any,
  level: SeverityLevel,
  additionalContext: CaptureContext = {}
) => {
  const log = level === 'warning' ? console.warn : console.error;
  const formattedLevel = level.charAt(0).toUpperCase() + level.slice(1);

  console.group(`${formattedLevel}!`);
  if (exception instanceof Error) {
    log(exception, exception.stack);
  } else if (typeof exception === 'object') {
    log(JSON.parse(JSON.stringify(exception)));
  } else {
    log(exception);
  }

  if (Object.keys(additionalContext).length > 0) {
    log('Additional context:');
    // https://developer.mozilla.org/en-US/docs/Web/API/console/log#logging_objects
    log(JSON.parse(JSON.stringify(additionalContext)));
  }
  console.groupEnd();
};
// gemini.endlink
