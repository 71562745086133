import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SubBodyText } from '@headway/helix/SubBodyText';

import blueArt from 'assets/img/blue-art.jpeg';
import getPaid from 'assets/img/get-paid.svg';
import MollyStien from 'assets/img/molly-stien.png';

export const RatesInfoButtonAndModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button
        variant="link"
        onPress={() => {
          setIsOpen(true);
        }}
      >
        Learn more about how rates are determined
      </Button>
      <Modal
        title="How rates are determined"
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
      >
        <ModalContent>
          <div className="flex flex-col gap-5">
            <BrandText variant="section">
              Headway’s team works with insurance partners to get you enhanced
              rates
            </BrandText>
            <ul className="my-0">
              <li>
                <strong>Headway’s team</strong> negotiates with insurers to get
                enhanced rates
              </li>
              <li>
                <strong>Insurers</strong> are willing to pay more for Headway’s
                high-quality provider network
              </li>
              <li>
                <strong>Providers </strong>benefit by making more money per
                session
              </li>
            </ul>
            <div className="flex flex-col">
              <BodyText>
                <strong>How rates are determined across state lines</strong>
              </BodyText>
              <BodyText>
                The mental health system operates on a state-by-state level,
                which means rates paid by health plans differ across state
                lines.
              </BodyText>
            </div>
            <div className="mt-2 flex flex-row">
              <img
                src={MollyStien}
                width="50px"
                height="50px"
                className={`ml-[-10px] rounded-full border-4 border-solid border-hue-lightYellow`}
              />
              <div className="ml-2 flex flex-col justify-center align-middle">
                <BodyText>
                  <strong>Molly Stein</strong>
                </BodyText>
                <SubBodyText>Head of Partnerships at Headway</SubBodyText>
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            variant="primary"
            onPress={() => {
              setIsOpen(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
