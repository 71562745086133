import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderByIdQueryKeyArgs {
  providerId: number;
}

export const getUseProviderByIdQueryKey = ({
  providerId,
}: UseProviderByIdQueryKeyArgs): [string, number] => [
  'provider-by-id',
  providerId,
];

const {
  useSingleQuery: useProviderById,
  useListQuery: useProviderByIdList,
  useCachedQuery: useProviderByIdCache,
} = createBasicApiHooks(
  getUseProviderByIdQueryKey,
  ({ providerId }) => {
    return ProviderApi.getProvider(providerId);
  },
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider'
);

export { useProviderById, useProviderByIdList, useProviderByIdCache };
