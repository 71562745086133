import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';

import { AgreementCheckbox } from './components/AcceptModalContent/AgreementCheckbox';
import { IroncladAgreementsList } from './components/IroncladAgreementsList';
import { IroncladAgreementStatus } from './types/IroncladAgreementStatus';

export const GroupPracticeAddingNewStateContent = ({
  onSubmit,
}: {
  onSubmit: (ironcladAgreementStatus: IroncladAgreementStatus) => void;
}) => (
  <>
    <ModalContent>
      <div className="p-x-1 mb-2 grid gap-y-2 overflow-hidden">
        <p>
          <BodyText>You've added a new state to your group practice!</BodyText>
        </p>
        <p>
          <BodyText>
            Before you begin, we need you to review and attest to your updated
            agreement:
          </BodyText>
        </p>

        <IroncladAgreementsList />
      </div>

      <AgreementCheckbox onSubmit={onSubmit} />
    </ModalContent>
    <ModalFooter>
      <Button type="submit" variant="primary" form="amendment-acceptance-form">
        Agree
      </Button>
    </ModalFooter>
  </>
);
