import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import React from 'react';

import { BankAccountCreateRequest } from '@headway/api/models/BankAccountCreateRequest';
import { BankAccountUpdateRequest } from '@headway/api/models/BankAccountUpdateRequest';
import { BankAccountVerificationStatus } from '@headway/api/models/BankAccountVerificationStatus';
import { ProviderBillingAccountRead } from '@headway/api/models/ProviderBillingAccountRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';
import { PlaidApi } from '@headway/api/resources/PlaidApi';
import { ProviderBillingAccountApi } from '@headway/api/resources/ProviderBillingAccountApi';
import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconInfo } from '@headway/helix/icons/Info';
import { theme } from '@headway/helix/theme';
import { ALWAYS_USE_GP_BILLING_ACCOUNT } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@headway/shared/react-query';
import { FinancialAccount, Tooltip } from '@headway/ui';
import { theme as legacyTheme } from '@headway/ui/theme';

import { getBillingAccountQueryKey } from '../../../../hooks/useBillingAccount';
import { hasUnmetBankAccountRequirements } from '../../../../utils/billing';
import { BillingAccountRequirementsWarning } from './BillingAccountRequirementsWarning';
import { PlaidLinkButtonWithError } from './PlaidLinkButtonWithError';

function plaidStatusToBankAccountStatus(status: string) {
  if (status === 'pending_automatic_verification') {
    return BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION;
  } else if (status === 'pending_manual_verification') {
    return BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION;
  }

  return BankAccountVerificationStatus.VERIFIED;
}

/* Styles */
const buttonWrapperSpanCss = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${theme.spacing.x4};
`;

interface BankAccountManagementSectionProps {
  providerId: number;
  billingAccountInfo: ProviderBillingAccountRead & {
    isLoadingBillingAccount: boolean;
    status: string;
  };
  isGroupPracticeBillingAccount: boolean | undefined;
  previousBillingAccountInfo:
    | (ProviderBillingAccountRead & {
        isLoadingBillingAccount: boolean;
        status: string;
      })
    | null;
}

export const BankAccountManagementSection: React.FC<
  React.PropsWithChildren<BankAccountManagementSectionProps>
> = ({
  providerId,
  billingAccountInfo,
  isGroupPracticeBillingAccount,
  previousBillingAccountInfo,
}) => {
  const billingAccountId = billingAccountInfo.id;
  const queryClient = useQueryClient();

  const shouldAlwaysUseGPBillingAccount = useFlag(
    ALWAYS_USE_GP_BILLING_ACCOUNT
  );
  const billingAccountQueryKey = getBillingAccountQueryKey({
    providerId,
    shouldQueryGroupPractice: !!isGroupPracticeBillingAccount, // key for should query GP is whether it did query GP
  });

  const createProviderBankAccountMutation = useMutation(
    (variables: BankAccountCreateRequest) => {
      return ProviderBillingAccountApi.createProviderBankAccount(
        billingAccountId,
        variables
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(billingAccountQueryKey, data);
      },
    }
  );

  const updateProviderBankAccountMutation = useMutation(
    (variables: BankAccountUpdateRequest) => {
      return ProviderBillingAccountApi.updateProviderBankAccount(
        billingAccountId,
        variables
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(billingAccountQueryKey, data);
      },
    }
  );

  const plaidCreateTokenQuery = useQuery(['plaid-token'], async () => {
    const { linkToken } = await PlaidApi.createPlaidLinkToken({});

    return linkToken;
  });

  const plaidVerifyTokenQuery = useQuery(
    ['plaid-token', billingAccountInfo.bankAccount?.id],
    async () => {
      const bankAccount = billingAccountInfo.bankAccount;

      if (
        !bankAccount ||
        bankAccount.verificationStatus !==
          BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION
      ) {
        return null;
      }

      const { linkToken } = await PlaidApi.createPlaidLinkToken({
        bankAccountId: bankAccount.id,
      });

      return linkToken;
    },
    {
      enabled:
        billingAccountInfo.bankAccount?.verificationStatus ===
        BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION,
    }
  );

  let createBankAccountButtonText;
  if (isGroupPracticeBillingAccount) {
    createBankAccountButtonText =
      billingAccountInfo.bankAccount || previousBillingAccountInfo?.bankAccount
        ? 'Update group practice bank account'
        : 'Add group practice bank account';
  } else {
    createBankAccountButtonText = billingAccountInfo.bankAccount
      ? 'Update bank account'
      : 'Add bank account';
  }

  return (
    <section
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing.x6,
      }}
    >
      <div
        css={{
          maxWidth: '35ch',
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
        }}
      >
        <div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h3 css={{ marginTop: 0, ...theme.typography.list }}>
              <span css={theme.typography.list}>Bank account</span>
            </h3>
            {billingAccountInfo &&
              hasUnmetBankAccountRequirements(billingAccountInfo) && (
                <BillingAccountRequirementsWarning description="A bank account is required in order to receive payouts" />
              )}
          </div>
          <div>
            Headway will deposit payments from your sessions into this account.
          </div>
        </div>
        {!billingAccountInfo.isLoadingBillingAccount &&
          !billingAccountInfo.bankAccount && (
            <GuidanceCard variant="warning">
              <BodyText>
                Please ensure that you or your business is the owner of the bank
                account being added.
              </BodyText>
            </GuidanceCard>
          )}
      </div>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
          alignItems: 'end',
        }}
      >
        {billingAccountInfo.isLoadingBillingAccount ? (
          <Skeleton variant="rectangular" height={40} />
        ) : billingAccountInfo.bankAccount ? (
          <div css={{ alignSelf: 'stretch' }}>
            <FinancialAccount
              userPaymentMethod={{
                type: UserPaymentMethodType.BANK_ACCOUNT,
                bankAccount: billingAccountInfo.bankAccount,
                isDefault: false,
                userId: providerId,
              }}
            />
          </div>
        ) : (
          isGroupPracticeBillingAccount &&
          !shouldAlwaysUseGPBillingAccount &&
          previousBillingAccountInfo?.bankAccount && (
            <div css={{ alignSelf: 'stretch' }}>
              <CaptionText>
                <strong>Previous bank account</strong>
              </CaptionText>
              <FinancialAccount
                disabled
                userPaymentMethod={{
                  type: UserPaymentMethodType.BANK_ACCOUNT,
                  bankAccount: previousBillingAccountInfo.bankAccount,
                  isDefault: false,
                  userId: providerId,
                }}
              />
            </div>
          )
        )}
        {plaidCreateTokenQuery.status !== 'success' ||
        createProviderBankAccountMutation.status === 'loading' ||
        updateProviderBankAccountMutation.status === 'loading' ? (
          <Skeleton
            css={{
              marginTop: legacyTheme.space.xl,
            }}
            variant="rectangular"
            height={40}
          />
        ) : (
          <div>
            <span css={buttonWrapperSpanCss}>
              {plaidVerifyTokenQuery.data && (
                <PlaidLinkButtonWithError
                  variant="secondary"
                  disabled={
                    billingAccountInfo.stripeAccount.status !== 'verified'
                  }
                  css={{
                    marginRight: theme.spacing.x4,
                  }}
                  linkOptions={{
                    token: plaidVerifyTokenQuery.data,
                    onSuccess: (token, { accounts }) => {
                      updateProviderBankAccountMutation.mutate({
                        verificationStatus: plaidStatusToBankAccountStatus(
                          accounts[0].verification_status
                        ),
                      });
                    },
                    onExit: (err: any) => {
                      if (err && err.error_code === 'INVALID_LINK_TOKEN') {
                        plaidVerifyTokenQuery.refetch();
                      }
                    },
                  }}
                >
                  Verify bank account
                </PlaidLinkButtonWithError>
              )}
              <Tooltip
                title={
                  billingAccountInfo.stripeAccount.status !== 'verified'
                    ? 'Please verify your identity before adding a bank account'
                    : null
                }
              >
                <span>
                  <PlaidLinkButtonWithError
                    key={plaidCreateTokenQuery.data}
                    variant="secondary"
                    disabled={
                      billingAccountInfo.stripeAccount?.status !== 'verified' ||
                      !plaidCreateTokenQuery.data
                    }
                    linkOptions={{
                      token: plaidCreateTokenQuery.data,
                      onSuccess: (token, meta) => {
                        const account = meta.accounts[0];

                        createProviderBankAccountMutation.mutate({
                          publicToken: token,
                          accountId: account.id,
                          verificationStatus: plaidStatusToBankAccountStatus(
                            account.verification_status
                          ),
                          bankName: meta.institution?.name,
                          last4: account.mask,
                        });
                      },
                      onExit: (err: any) => {
                        if (err && err.error_code === 'INVALID_LINK_TOKEN') {
                          plaidCreateTokenQuery.refetch();
                        }
                      },
                    }}
                  >
                    {createBankAccountButtonText}
                  </PlaidLinkButtonWithError>
                </span>
              </Tooltip>
              {billingAccountInfo.status === 'success' &&
                billingAccountInfo.bankAccount && (
                  <Tooltip
                    title={
                      'Please ensure that you or your business is the owner of the bank account being added.'
                    }
                    placement="top"
                  >
                    <span css={{ marginLeft: theme.spacing.x2, fontSize: 0 }}>
                      <IconInfo />
                    </span>
                  </Tooltip>
                )}
            </span>
          </div>
        )}
      </div>
    </section>
  );
};
