import moment from 'moment';

import { IroncladAgreementStatus } from 'components/IroncladAmendmentsModal/types/IroncladAgreementStatus';

export const shouldBlockProviderWithIroncladAgreement = (
  isIroncladBlockAppointmentConfirmationEnabled: boolean,
  status: IroncladAgreementStatus,
  startDate?: Date
) => {
  return startDate
    ? isIroncladBlockAppointmentConfirmationEnabled &&
        status === IroncladAgreementStatus.AGREED &&
        moment(startDate) < moment().subtract(30, 'days')
    : isIroncladBlockAppointmentConfirmationEnabled &&
        status === IroncladAgreementStatus.AGREED;
};

export const getSignerId = (user: any, isGroupPractice: boolean) => {
  return isGroupPractice
    ? `group-${user.group_practice.id}`
    : (user?.id as string);
};
