import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useQuery } from '@headway/shared/react-query';
import { RatesWithState } from '@headway/shared/types/rates';
import { consolidateRates, getCarriers } from '@headway/shared/utils/rates';

import { ProviderFeeScheduleApi } from 'api/ProviderFeeScheduleApi';

import { useIroncladAgreementStatus } from './useIroncladAgreementStatus';

/**
 * Builds an array of promises for fetching the fee schedules for a provider for all their
 * provider license states.
 *
 * @param provider
 * @returns RatesWithState
 */
const fetchProviderRatesPromises = (
  provider: ProviderRead,
  includeOnlyContractSignedStates: boolean = false,
  contractSignedStates?: UnitedStates[]
): Promise<RatesWithState>[] => {
  return provider.activeProviderLicenseStates
    .filter((providerLicenseState) =>
      includeOnlyContractSignedStates
        ? !!contractSignedStates?.includes(providerLicenseState.state)
        : true
    )
    .map((providerLicenseState) => {
      return (async (): Promise<RatesWithState> => {
        const rates = await ProviderFeeScheduleApi.findProviderFeeSchedules({
          only_most_recent: true,
          max_effective_date: new Date().toISOString(),
          front_end_carrier_ids: provider.providerFrontEndCarriers
            ?.filter(
              (pfec) => pfec.providerLicenseStateId === providerLicenseState.id
            )
            .map((pfec) => pfec.frontEndCarrierId),
          provider_license_state_id: providerLicenseState.id,
        });

        return {
          state: providerLicenseState.state,
          providerType: provider.providerType || '',
          rates: rates,
        };
      })();
    });
};

/**
 * Fetches the rates of the group practice.
 *
 * This is done by,
 * 1. Getting the providers of the group practice
 * 2. Fetching the rates for each provider
 *
 *
 * @param groupPracticeId
 * @returns RatesWithState
 */
const fetchGroupPracticeRates = async (
  groupPracticeId: number,
  includeOnlyContractSignedStates: boolean = false,
  contractSignedStates?: UnitedStates[]
) => {
  const groupPracticeProvidersRes = await ProviderApi.findProviders({
    group_practice_id: groupPracticeId,
  });

  const groupPracticeProviderRatesPromises = groupPracticeProvidersRes.data.map(
    (provider) =>
      fetchProviderRatesPromises(
        provider,
        includeOnlyContractSignedStates,
        contractSignedStates
      )
  );

  const groupPracticeProviderRates: RatesWithState[] = await Promise.all(
    groupPracticeProviderRatesPromises.flat()
  );

  return groupPracticeProviderRates;
};

const fetchProviderRates = async (
  provider: ProviderRead,
  includeOnlyContractSignedStates: boolean = false,
  contractSignedStates?: UnitedStates[]
) =>
  await Promise.all(
    fetchProviderRatesPromises(
      provider,
      includeOnlyContractSignedStates,
      contractSignedStates
    )
  );

export const useRates = (
  provider: ProviderRead,
  isGroupPractice: boolean,
  includeOnlyContractSignedStates?: boolean
) => {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { stateswithAcceptedAgreements } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates: true,
  });
  // If MSC Beta flag is off, fetch rates for the provider's primary license state
  const contractSignedStates =
    isMSCBetaEnabled && stateswithAcceptedAgreements
      ? (Object.keys(stateswithAcceptedAgreements) as UnitedStates[])
      : [provider.providerLicenseState.state];
  const groupPracticeId = provider.groupPracticeId;

  const {
    data: groupPracticeRateData,
    isLoading: isGroupPracticeRateLoading,
    isError: isGroupPracticeRateError,
  } = useQuery(
    [
      'groupPracticeRates',
      groupPracticeId,
      includeOnlyContractSignedStates,
      stateswithAcceptedAgreements,
    ],
    () =>
      groupPracticeId
        ? fetchGroupPracticeRates(
            groupPracticeId,
            includeOnlyContractSignedStates,
            contractSignedStates
          )
        : [],
    { enabled: isGroupPractice && !!groupPracticeId }
  );

  const {
    data: providerRateData,
    isLoading: isProviderRatesLoading,
    isError: isProviderRateError,
  } = useQuery(
    [
      'providerRates',
      provider.id,
      includeOnlyContractSignedStates,
      stateswithAcceptedAgreements,
    ],
    () =>
      fetchProviderRates(
        provider,
        includeOnlyContractSignedStates,
        contractSignedStates
      ),
    { enabled: !isGroupPractice }
  );

  return {
    isLoading: isProviderRatesLoading || isGroupPracticeRateLoading,
    isError: isProviderRateError || isGroupPracticeRateError,
    rates: consolidateRates(
      isGroupPractice ? groupPracticeRateData ?? [] : providerRateData ?? []
    ),
    carriers: getCarriers(
      isGroupPractice ? groupPracticeRateData ?? [] : providerRateData ?? []
    ),
  };
};
