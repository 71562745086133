import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderProgressNotesQueryKeyArgs {
  providerAppointmentId?: number;
  patientId?: number;
}

export const getUseProviderProgressNotesQueryKey = ({
  providerAppointmentId,
  patientId,
}: UseProviderProgressNotesQueryKeyArgs): [
  string,
  number | undefined,
  number | undefined,
] => ['provider-progress-notes', providerAppointmentId, patientId];

const {
  useSingleQuery: useProviderProgressNotes,
  useListQuery: useProviderProgressNotesList,
  useCachedQuery: useProviderProgressNotesCache,
} = createBasicApiHooks(
  getUseProviderProgressNotesQueryKey,
  ({ providerAppointmentId, patientId }) =>
    ProviderProgressNotesApi.findProviderProgressNotes({
      appointment_id: providerAppointmentId,
      patient_id: patientId,
    }),
  ({ providerAppointmentId }) => !!providerAppointmentId,
  () => 'Failed to fetch provider progress notes'
);

export {
  useProviderProgressNotes,
  useProviderProgressNotesList,
  useProviderProgressNotesCache,
};
