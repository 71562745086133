import useMediaQuery from '@mui/material/useMediaQuery';
import queryString from 'query-string';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { theme } from '@headway/ui/theme';

import { CalendarExportSettings } from './CalendarExportSettings';
import { CalendarImportSettings } from './CalendarImportSettings';
import { WorkingHoursMultiSet } from './WorkingHoursMultiSet';

const startHWMAProductTour = () => {
  if (typeof window.Intercom === 'function') {
    Intercom('startTour', 310096);
  }
};

export const CalendarSettings: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(theme.media.smallDown);

  React.useEffect(() => {
    if (location.search) {
      const params = queryString.parse(location?.search);
      if (params.hwmaTour) {
        startHWMAProductTour();
      }
      navigate('/settings/calendar', { replace: true });
    }
  }, [navigate, location.search]);

  return (
    <>
      <div css={{ width: 'min(100%, 927px)', margin: '0 auto' }}>
        {isMobileView && (
          <GuidanceCard variant="warning">
            For an optimal experience, view this page on a desktop.
          </GuidanceCard>
        )}
        <GuidanceCard variant="info">
          <BodyText>
            Take a quick tour of your updated calendar and availability options.
          </BodyText>
          <Button
            onPress={() => {
              startHWMAProductTour();
            }}
            variant="link"
          >
            Get started
          </Button>
        </GuidanceCard>
      </div>

      <WorkingHoursMultiSet />

      <CalendarImportSettings displaySource="PROFILE" />
      <CalendarExportSettings />
    </>
  );
};
