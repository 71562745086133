import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseClaimReadinessQueryKeyArgs {
  patientUser: UserRead | undefined;
}
export type UseClaimReadinessQueryKey = [
  string,
  number | undefined,
  number | undefined,
];

export const getUseClaimReadinessQueryKey = ({
  patientUser,
}: UseClaimReadinessQueryKeyArgs): UseClaimReadinessQueryKey => [
  'claim-readiness',
  patientUser?.id,
  patientUser?.activeUserInsuranceId,
];

const {
  useSingleQuery: useClaimReadiness,
  useListQuery: useClaimReadinessList,
} = createBasicApiHooks(
  getUseClaimReadinessQueryKey,
  ({ patientUser }) => {
    if (!patientUser?.activeUserInsuranceId) {
      throw new Error('insurance_id required for getClaimReadiness');
    }

    return UserApi.getClaimReadiness(patientUser.id);
  },
  ({ patientUser }) => !!(patientUser?.id && patientUser.activeUserInsuranceId),
  () => 'Failed to fetch claim readiness'
);

export { useClaimReadiness, useClaimReadinessList };
