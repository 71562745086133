import { InputLabel, Select, SelectProps } from '@mui/material';
import React, { useRef } from 'react';

import { FieldControlContext } from './FieldControl';
import { useField } from './useField';

export interface CustomSelectProps {
  hasInputLabel?: boolean;
  testId?: string;
}

export const FieldSelect = ({
  hasInputLabel = true,
  testId,
  ...props
}: SelectProps & CustomSelectProps) => {
  const selectRef = useRef<HTMLFormElement>(null);

  const { name, id } = React.useContext(FieldControlContext);
  const [field, meta] = useField({
    name,
    onChange: props.onChange,
    onBlur: props.onBlur,
    ref: selectRef,
  });

  return (
    <>
      {hasInputLabel && (
        <InputLabel id="select-label">{props.label}</InputLabel>
      )}
      <Select
        inputRef={selectRef}
        aria-required={true}
        labelId={hasInputLabel ? 'select-label' : `${id}-label`}
        label={hasInputLabel ? props.label : undefined}
        variant="standard"
        displayEmpty={true}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300,
              '& .MuiList-root': {
                paddingBottom: 0,
              },
            },
          },
        }}
        id={id}
        data-testid={testId ?? name}
        {...props}
        {...field}
        error={meta.error !== undefined && meta.touched}
      />
    </>
  );
};
