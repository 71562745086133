import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

import { FieldControlContext } from '@headway/ui/form/FieldControl';

import { useField } from './useField';

export const FieldTextField = ({ autoComplete, ...props }: TextFieldProps) => {
  const { name, id } = React.useContext(FieldControlContext);
  const [field] = useField({
    name,
    onChange: props.onChange,
    onBlur: props.onBlur,
  });

  return (
    <TextField id={id} {...props} {...field} autoComplete={autoComplete} />
  );
};
