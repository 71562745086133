import React from 'react';

import { theme } from './theme';

const leftBubbleStyles = {
  ':after': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: -10,
    width: 10,
    height: 20,
    background: 'white',
    borderBottomRightRadius: 10,
  },
  ':before': {
    content: '""',
    position: 'absolute',
    zIndex: 0,
    bottom: 0,
    left: -7,
    height: 20,
    width: 10,
    background: '#eee',
    borderBottomRightRadius: 5,
  },
} as const;

const rightBubbleStyles = {
  ':after': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    right: -10,
    width: 10,
    height: 20,
    background: 'white',
    borderBottomLeftRadius: 10,
  },
  ':before': {
    content: '""',
    position: 'absolute',
    zIndex: 0,
    bottom: '0',
    right: -8,
    height: 20,
    width: 20,
    background: '#f2f2f2',
    backgroundAttachment: 'fixed',
    borderBottomLeftRadius: 15,
  },
} as const;

type MessageBubbleProps = {
  position?: 'left' | 'right';
};

export const MessageBubble: React.FC<
  React.PropsWithChildren<MessageBubbleProps>
> = ({ position = 'right', children }) => {
  return (
    <div
      css={{
        background: '#f2f2f2',
        padding: `${theme.space.xs2} ${theme.space.xs}`,
        borderRadius: 7,
        position: 'relative',
        ...(position === 'left' ? leftBubbleStyles : rightBubbleStyles),
      }}
    >
      {children}
    </div>
  );
};
