import { ChartReviewApi } from '@headway/api/resources/ChartReviewApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePaginatedProviderDocumentRequestsAndRemediationsQueryKeyArgs {
  providerId: number;
  offset?: number;
  limit?: number;
}
export const getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey = ({
  providerId,
  offset,
  limit,
}: UsePaginatedProviderDocumentRequestsAndRemediationsQueryKeyArgs): [
  string,
  number,
  number | undefined,
  number | undefined,
] => [
  'paginated-provider-document-request-remediations',
  providerId,
  offset,
  limit,
];

const { useSingleQuery: usePaginatedProviderDocumentRequestsAndRemediations } =
  createBasicApiHooks(
    getUsePaginatedProviderDocumentRequestsAndRemediationsQueryKey,
    ({ providerId, offset, limit }) =>
      ChartReviewApi.getPaginatedProviderDocumentRequestsAndRemediations({
        provider_id: providerId,
        offset: offset,
        limit: limit,
      }),
    ({ providerId }) => !!providerId,
    () =>
      'Failed to fetch paginated provider document requests and remediations'
  );

export { usePaginatedProviderDocumentRequestsAndRemediations };
