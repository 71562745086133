import partial from 'lodash/partial';

import { toasts } from '@headway/helix/Toast';
import { CacheWrapper } from '@headway/shared/hooks/utils';
import type {
  MutationFunction,
  UseMutationOptions,
} from '@headway/shared/react-query';
import { useMutation } from '@headway/shared/react-query';
import { throttledNotifyOffline } from '@headway/shared/utils/network';
import { logException } from '@headway/shared/utils/sentry';

export type UseMutationSideEffectMethods<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
> = Partial<
  Pick<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'onError' | 'onMutate' | 'onSettled' | 'onSuccess'
  >
>;

type LooseUseMutationSideEffectMethods<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
> = Partial<
  Pick<
    UseMutationOptions<TData, TError, TVariables, any>,
    'onError' | 'onMutate' | 'onSettled' | 'onSuccess'
  >
>;

/**
 * Builder which allows registering multiple sets of mutation side effects.
 * Utility methods are included for common tasks.
 */
export class SideEffectsBuilder<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
> {
  // Each set of side effect methods could have a different type for TContext, but TypeScript does not
  // support existential types, so it's difficult to represent this. Instead, we just loosen the
  // type of TContext to `any` inside this class.
  protected sideEffects: LooseUseMutationSideEffectMethods<
    TData,
    TError,
    TVariables
  >[] = [];

  /**
   * Adds a new side effect for a mutation.
   * @param sideEffect
   * @returns
   */
  add<TContext>(
    sideEffect: UseMutationSideEffectMethods<
      TData,
      TError,
      TVariables,
      TContext
    >
  ): this {
    this.sideEffects.push(sideEffect);
    return this;
  }

  /**
   * Adds a side effect to optimistically update cached data.
   * See https://tanstack.com/query/v4/docs/react/guides/optimistic-updates
   * @param cache The cache wrapper for the data to update.
   * @param getQueryKeyArgs A function which returns the query key args used for calling the cache.
   * @param updater A function which receives the mutation arguments and the current value of the
   *   cached data, and behaves like a `QueryClient.setQueryData` updater.
   */
  addOptimisticUpdate<QueryKeyArgs extends {}, QueryFnReturn>(
    cache: CacheWrapper<QueryKeyArgs, QueryFnReturn, unknown[]>,
    getQueryKeyArgs: (variables: TVariables) => QueryKeyArgs,
    updater: (
      variables: TVariables,
      current: QueryFnReturn | undefined
    ) => QueryFnReturn | undefined
  ): this {
    this.add({
      onMutate: async (variables: TVariables) => {
        const queryKeyArgs = getQueryKeyArgs(variables);
        await cache.cancelQueries(queryKeyArgs);
        const previous = cache.get(queryKeyArgs);
        cache.set(queryKeyArgs, partial(updater, variables));
        return previous;
      },
      onError: (err, variables, prev) => {
        cache.set(getQueryKeyArgs(variables), prev);
      },
      onSettled: (data, error, variables) => {
        cache.invalidate(getQueryKeyArgs(variables));
      },
    });
    return this;
  }

  /**
   * Adds error logging and a user-facing error toast if this mutation fails.
   */
  addErrorLogging(
    getErrorMessage: (variables: TVariables, err: TError) => string
  ): this {
    this.add({
      onError: (err, variables) => {
        if (!window.navigator.onLine) {
          throttledNotifyOffline();
        } else {
          const message = getErrorMessage(variables, err);
          toasts.add(message, {
            variant: 'negative',
          });
          logException(err);
        }
        throw err;
      },
    });
    return this;
  }

  merge(
    builder: SideEffectsBuilder<TData, TError, TVariables> | undefined
  ): this {
    if (builder) {
      this.sideEffects = this.sideEffects.concat(builder.sideEffects);
    }
    return this;
  }

  build() {
    return this.sideEffects;
  }
}

export type UseMutationWithSideEffectsOptions<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
> = Omit<
  UseMutationOptions<TData, TError, TVariables, unknown>,
  'mutationFn' | 'onError' | 'onSettled' | 'onSuccess'
> & {
  sideEffects?: SideEffectsBuilder<TData, TError, TVariables>;
};

/**
 * A wrapper for useMutation which enables building extensible, reusable mutations by managing side
 * effects via a SideEffectsBuilder.
 * @param mutationFn Same as useMutation
 * @param sideEffectsBuilder Side effects to run on the mutation.
 * @param options Same as useMutation, but in place of `onError`, `onMutation`, etc. it accepts a
 *   `sideEffects` property which is then used to create the final on
 */
export function useMutationWithSideEffects<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options: UseMutationWithSideEffectsOptions<TData, TError, TVariables> = {}
) {
  const sideEffects = (
    options.sideEffects || new SideEffectsBuilder<TData, TError, TVariables>()
  ).build();

  return useMutation(mutationFn, {
    ...options,
    onMutate: async (variables) => {
      // List of contexts from each side effect we run.
      const context: any[] = [];

      for (const sideEffect of sideEffects) {
        if (sideEffect.onMutate) {
          context.push(await sideEffect.onMutate(variables));
        } else {
          context.push(undefined);
        }
      }

      return context;
    },
    onError: async (error, variables, context: any[] | undefined) => {
      for (let i = 0; i < sideEffects.length; i++) {
        const sideEffect = sideEffects[i];
        if (sideEffect.onError) {
          await sideEffect.onError(error, variables, context && context[i]);
        }
      }
    },
    onSuccess: async (data, variables, context: any[] | undefined) => {
      for (let i = 0; i < sideEffects.length; i++) {
        const sideEffect = sideEffects[i];
        if (sideEffect.onSuccess) {
          await sideEffect.onSuccess(
            data,
            variables,
            context && context[i] ? context[i] : undefined
          );
        }
      }
    },
    onSettled: async (data, error, variables, context: any[] | undefined) => {
      for (let i = 0; i < sideEffects.length; i++) {
        const sideEffect = sideEffects[i];
        if (sideEffect.onSettled) {
          await sideEffect.onSettled(
            data,
            error,
            variables,
            context && context[i]
          );
        }
      }
    },
  });
}
