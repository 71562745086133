/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { FeedbackSystemActionType } from '../models/FeedbackSystemActionType';
import { FeedbackSystemQuestionnaireAnswers } from '../models/FeedbackSystemQuestionnaireAnswers';
import { FeedbackSystemQuestionnaireRead } from '../models/FeedbackSystemQuestionnaireRead';
import { FeedbackSystemRecipientType } from '../models/FeedbackSystemRecipientType';
import { FeedbackSystemStudyCreateRequest } from '../models/FeedbackSystemStudyCreateRequest';
import { FeedbackSystemStudyRead } from '../models/FeedbackSystemStudyRead';
import { FeedbackSystemStudyUpdateStateRequest } from '../models/FeedbackSystemStudyUpdateStateRequest';
import { FeedbackSystemSurveyRead } from '../models/FeedbackSystemSurveyRead';
import { FeedbackSystemSurveyUpdate } from '../models/FeedbackSystemSurveyUpdate';
import { HTTPValidationError } from '../models/HTTPValidationError';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class FeedbackSystemApiResource {

    /**
     * Create Feedback System Study
     * 
     * @param feedbackSystemStudyCreateRequest 
     
     */
    public createFeedbackSystemStudy(body: FeedbackSystemStudyCreateRequest, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemStudyRead> {
        const apiRoute = '/feedback-system/studies';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Questionnaire
     * Creates and returns a user_questionnaire for client usage if one is needed for this appointment_id. For custom survey UX, where the questionnaire is assumed to be opened immediately when created. Assumes appointment_id has had its session details confirmed in the db, Otherwise no questionnaire will be created and returned.
     * @param recipientUserId 
     * @param recipientType 
     * @param trigger 
     * @param appointmentId 
     
     */
    public createQuestionnaire(query?: { recipient_user_id: number, recipient_type: FeedbackSystemRecipientType, trigger?: FeedbackSystemActionType, appointment_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemQuestionnaireRead> {
        const apiRoute = '/feedback-system/questionnaires';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Feedback System Questionnaire
     * 
     * @param questionnaireSlug 
     
     */
    public getFeedbackSystemQuestionnaire(questionnaireSlug: string, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemQuestionnaireRead> {
        const apiRoute = '/feedback-system/questionnaires/{questionnaire_slug}';
        const reqPath = apiRoute
                    .replace('{' + 'questionnaire_slug}', String(questionnaireSlug));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Feedback System Studies
     * 
     
     */
    public getFeedbackSystemStudies(axiosConfig?: AxiosRequestConfig): Promise<Array<FeedbackSystemStudyRead>> {
        const apiRoute = '/feedback-system/studies';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Feedback System Study
     * 
     * @param studyId 
     
     */
    public getFeedbackSystemStudy(studyId: number, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemStudyRead> {
        const apiRoute = '/feedback-system/studies/{study_id}';
        const reqPath = apiRoute
                    .replace('{' + 'study_id}', String(studyId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Feedback System Survey
     * 
     * @param surveyId 
     
     */
    public getFeedbackSystemSurvey(surveyId: number, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemSurveyRead> {
        const apiRoute = '/feedback-system/surveys/{survey_id}';
        const reqPath = apiRoute
                    .replace('{' + 'survey_id}', String(surveyId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get User Feedback System Questionnaires
     * Retrieves a user\&#39;s questionnaires for a particular trigger action and recipient_type
     * @param userId 
     * @param recipientType 
     * @param trigger 
     
     */
    public getUserFeedbackSystemQuestionnaires(userId: number, query?: { recipient_type: FeedbackSystemRecipientType, trigger: FeedbackSystemActionType }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/feedback-system/users/{user_id}/questionnaires';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Submit Feedback System Questionnaire Answers
     * 
     * @param questionnaireId 
     * @param feedbackSystemQuestionnaireAnswers 
     
     */
    public submitFeedbackSystemQuestionnaireAnswers(questionnaireId: number, body: FeedbackSystemQuestionnaireAnswers, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/feedback-system/questionnaires/{questionnaire_id}/answers';
        const reqPath = apiRoute
                    .replace('{' + 'questionnaire_id}', String(questionnaireId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Feedback System Study
     * 
     * @param studyId 
     * @param feedbackSystemStudyUpdateStateRequest 
     
     */
    public updateFeedbackSystemStudy(studyId: number, body: FeedbackSystemStudyUpdateStateRequest, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemStudyRead> {
        const apiRoute = '/feedback-system/studies/{study_id}';
        const reqPath = apiRoute
                    .replace('{' + 'study_id}', String(studyId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Feedback System Survey
     * 
     * @param surveyId 
     * @param feedbackSystemSurveyUpdate 
     
     */
    public updateFeedbackSystemSurvey(surveyId: number, body: FeedbackSystemSurveyUpdate, axiosConfig?: AxiosRequestConfig): Promise<FeedbackSystemSurveyRead> {
        const apiRoute = '/feedback-system/surveys/{survey_id}';
        const reqPath = apiRoute
                    .replace('{' + 'survey_id}', String(surveyId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const FeedbackSystemApi = new FeedbackSystemApiResource();
