import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Formik } from 'formik';
import startCase from 'lodash/startCase';
import React, { useContext, useState } from 'react';

import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { trackEvent } from '@headway/shared/utils/analytics';
import { transformCloudflareImg } from '@headway/shared/utils/cloudflareImage';
import { logException } from '@headway/shared/utils/sentry';
import { FieldControl } from '@headway/ui/form';
import { ProviderProfilePhoto } from '@headway/ui/providers/ProviderProfilePhoto';
import { notifyError } from '@headway/ui/utils/notify';

import badPhotoSrc from 'assets/img/profile-bad-photo.png';
import goodPhotoSrc from 'assets/img/profile-good-photo.png';
import { WizardContext } from 'components/Wizard/context';
import { WizardStep } from 'components/Wizard/WizardStep';
import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';
import { getEventNameFromEnum } from 'utils/analytics';

import { PrimaryContentHeader, SecondaryContent } from './ProfileWizard';

type photoFormValues = {
  photoUrl: string;
  photoS3ObjectKey: string;
};

export const PhotoStep = () => {
  const provider = useProvider();
  const authStore = useAuthStore();
  const { currentStep, title, module } = useContext(WizardContext);
  const [finishedUploadingPhoto, setFinishedUploadingPhoto] = useState(false);

  const initialValues: photoFormValues = {
    photoUrl: provider.photoUrl || '',
    photoS3ObjectKey: provider.photoS3ObjectKey || '',
  };

  const onSubmit = async ({ photoS3ObjectKey }: photoFormValues) => {
    try {
      const updated = await ProviderApi.updateProvider(provider.id, {
        photoS3ObjectKey,
      });
      authStore.setProvider(updated);
    } catch (err) {
      notifyError('An error occurred while saving your progress.');
      logException(err);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ submitForm, values, setFieldValue }) => {
        return (
          <WizardStep
            onSaveAndContinue={async () => await submitForm()}
            height={680}
            primaryContent={
              <>
                <PrimaryContentHeader
                  title="Add your photo"
                  description={
                    <span>
                      A high-quality photo increases the likelihood of new
                      referrals by 80%. Need help with a professional headshot?{' '}
                      <a
                        href="https://headshots.studio/headway-headshots"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>Use this free tool</u>
                      </a>
                      . This service is offered by{' '}
                      <a
                        href="https://snapbar.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>Snapbar</u>
                      </a>{' '}
                      and is subject to the terms and conditions on their site.
                      By using the tool, you allow Snapbar to use your photo.
                      See their website for additional details.
                    </span>
                  }
                />
                <FieldControl
                  name="photoUrl"
                  css={{
                    marginBottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <ProviderProfilePhoto
                    data-testid="uploadProfilePhoto"
                    supportUploads
                    onCropFinish={() => setFinishedUploadingPhoto(true)}
                    addPhotoIcon={<AddToPhotosIcon color="primary" />}
                    hideUploadButton
                    provider={provider}
                    photoUrl={transformCloudflareImg({
                      src: values.photoUrl || provider.photoUrl,
                      width: 400,
                    })}
                    onUploadFinish={(url, s3ObjectKey) => {
                      setFieldValue('photoUrl', url);
                      setFieldValue('photoS3ObjectKey', s3ObjectKey);
                      trackEvent({
                        name: `Setup ${
                          getEventNameFromEnum(module) as 'Profile'
                        } Step`,
                        properties: {
                          screenName: `${startCase(title)}: Step ${
                            currentStep + 1
                          }`,
                          stepName: 'photo_uploaded',
                        },
                      });
                    }}
                    onCancel={() => {
                      setFieldValue('photoUrl', provider.photoUrl);
                      setFieldValue(
                        'photoS3ObjectKey',
                        provider.photoS3ObjectKey
                      );
                    }}
                  />
                </FieldControl>
              </>
            }
            secondaryContentWrapperCss={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
            secondaryContent={
              <SecondaryContent
                title={'What makes a great photo?'}
                doContent={[
                  'Use a simple background',
                  'Make sure your photo is taken at eye-level, and shows your full face',
                  'Smile to show warmth and approachability',
                ]}
                badPhotoIcon={badPhotoSrc}
                goodPhotoIcon={goodPhotoSrc}
                avoidContent={[
                  'Use a busy or distracting background',
                  'Use a selfie or low-quality photo',
                  'Zoom in or cut off parts of the photo',
                ]}
              />
            }
            areRequirementsMet={
              !!provider.photoUrl ||
              (!!values.photoUrl && finishedUploadingPhoto)
            }
          />
        );
      }}
    </Formik>
  );
};
