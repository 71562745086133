import React from 'react';

import { Banner } from '@headway/helix/Banner';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';

import { useBillingAccountForBillingSettings } from 'hooks/useBillingAccount';

interface W9BannerProps {
  bannerOffset: number;
}

export const W9Banner = ({ bannerOffset }: W9BannerProps) => {
  const { billingAccountInfo } = useBillingAccountForBillingSettings();

  return (
    <div
      css={{
        position: 'fixed',
        background: theme.color.hue.lightSystemRed,
        padding: `0 ${theme.spacing.x1}`,
        height: 50,
        top: 0 + bannerOffset,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 17,
        color: theme.color.system.black,
      }}
    >
      <Banner
        adornment={
          <Link href={billingAccountInfo?.stripeOnboardingLink}>
            Complete W-9 form on Stripe ↗
          </Link>
        }
        variant="warning"
      >
        You won’t be able to confirm sessions until you complete your practice’s
        W-9 form
      </Banner>
    </div>
  );
};
