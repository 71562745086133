import React from 'react';

export function IconClear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M7.49998 13.2002L9.99998 10.7002L12.5 13.2002L13.2 12.5002L10.7 10.0002L13.2 7.50016L12.5 6.80016L9.99998 9.30016L7.49998 6.80016L6.79998 7.50016L9.29998 10.0002L6.79998 12.5002L7.49998 13.2002ZM9.99998 16.6668C9.08887 16.6668 8.22776 16.4918 7.41665 16.1418C6.60554 15.7918 5.8972 15.3141 5.29165 14.7085C4.68609 14.1029 4.20831 13.3946 3.85831 12.5835C3.50831 11.7724 3.33331 10.9113 3.33331 10.0002C3.33331 9.07794 3.50831 8.21127 3.85831 7.40016C4.20831 6.58905 4.68609 5.8835 5.29165 5.2835C5.8972 4.6835 6.60554 4.2085 7.41665 3.8585C8.22776 3.5085 9.08887 3.3335 9.99998 3.3335C10.9222 3.3335 11.7889 3.5085 12.6 3.8585C13.4111 4.2085 14.1166 4.6835 14.7166 5.2835C15.3166 5.8835 15.7916 6.58905 16.1416 7.40016C16.4916 8.21127 16.6666 9.07794 16.6666 10.0002C16.6666 10.9113 16.4916 11.7724 16.1416 12.5835C15.7916 13.3946 15.3166 14.1029 14.7166 14.7085C14.1166 15.3141 13.4111 15.7918 12.6 16.1418C11.7889 16.4918 10.9222 16.6668 9.99998 16.6668Z"
        fill="currentColor"
      />
    </svg>
  );
}
