import moment from 'moment';
import React from 'react';

import { Employment } from '@headway/api/models/Employment';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { theme } from '@headway/helix/theme';

import {
  CaqhKeyValuePair,
  CaqhSection,
  CaqhSubsection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';

export const EmploymentHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  return (
    <CaqhSection>
      <>
        {initialValues?.employment?.map((job: Employment) => (
          <CaqhSubsection label={job.employer}>
            <div css={{ ...theme.stack.vertical, gap: theme.spacing.x2 }}>
              <CaqhKeyValuePair
                label="Duration"
                value={`${moment(job.startDate).format('MMMM YYYY')} - 
                                ${
                                  !!job.endDate
                                    ? moment(job.endDate).format('MMMM YYYY')
                                    : 'Present'
                                }`}
              />

              {!!job.endDate && (
                <div>
                  <CaqhKeyValuePair
                    label="Reason For Leaving"
                    value={job.reasonForLeaving}
                  />
                </div>
              )}
            </div>

            <CaqhKeyValuePair
              label="Address"
              value={
                <>
                  <div>{job.street1}</div>
                  {job.street2 && <div>{job.street2}</div>}
                  <div>
                    {job.city}, {job.state} {job.zip}{' '}
                  </div>
                  <div>{job.country}</div>
                </>
              }
            />
          </CaqhSubsection>
        ))}
      </>
    </CaqhSection>
  );
};
