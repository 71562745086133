import React from 'react';

import { theme } from '@headway/ui/theme';

export const EventFooter: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        borderTop: `1px solid ${theme.color.border}`,
        padding: `${theme.space.xs} ${theme.space.base}`,
      }}
    >
      {children}
    </div>
  );
};
