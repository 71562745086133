import { HospitalAffiliation } from '@headway/api/models/HospitalAffiliation';
import { Practice } from '@headway/api/models/Practice';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import statesToDisplayNames, {
  abbreviationToStateEnum,
} from '@headway/shared/constants/unitedStatesDisplayNames';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import {
  PracticeLocation,
  PracticeLocationArray,
  PracticeLocationMap,
  PracticeLocationMeta,
} from './Practice';

export const isPracticeLocationCaqhPracticeType = (
  practiceLocation: PracticeLocation
): practiceLocation is Practice & PracticeLocationMeta => {
  if ('street1' in practiceLocation) {
    return true;
  }

  return false;
};

export const statesThatRequireAdmittingProviderInfo = [UnitedStates.LOUISIANA];
export const statesThatRequireAdaAccessibleProperties = [
  UnitedStates.TEXAS,
  UnitedStates.ILLINOIS,
];

export const statesThatRequireAttachedToHome = [UnitedStates.CALIFORNIA];
export const statesThatRequireTimelyAccessToCareAttest = [
  UnitedStates.CALIFORNIA,
];

export const GeneralAdaOptions = {
  RESTROOM: 'Restroom',
  EXAM_ROOM: 'Exam Room',
  EXAM_TABLE: 'Exam Table',
  SCALE: 'Scale',
};

export const IllinoisAdaOptions = {
  HANDICAP_ACCESSIBLE: 'Handicap Accessible',
  PUBLIC_TRANSPORTATION: 'Public Transportation',
  LANG_LINE_INTERPRETER: 'Lang Line Interpreter',
  OFFICE_RECEPTION_AREA: 'Office Reception Area',
  ACCESSIBLE_GRAB_BARS: 'Accessible Grab Bars',
  WHEELCHAIR_ACCESSIBLE_DRINKING_FOUNTAINS:
    'Wheelchair accessible drinking fountains',
  WHEELCHAIR_ACCESSIBLE_HALLWAYS: 'Wheelchair accessible hallways',
  WHEELCHAIR_ACCESSIBLE_SERVICE_COUNTERS:
    'Wheelchair accessible service counters',
  WIDE_DOORWAYS_AND_PASSAGEWAYS: 'Wide doorways and passageways',
};

export const TexasAdaOptions = {
  SITE_ACCESSIBLE: 'Site accessible',
  PARKING_ACCESSIBLE: 'Parking accessible',
  EXTERIOR_BUILDING: 'Exterior building',
  INTERIOR_BUILDING: 'Interior building',
  OFFICE_RECEPTION_AREA: 'Office reception area',
  CLOSE_PROXIMITY_TO_PUBLIC_TRANSPORTATION:
    'Close proximity to public transportation',
};

export const getAdaOptions = (state: UnitedStates) => {
  if (state === UnitedStates.ILLINOIS) {
    return Object.assign(GeneralAdaOptions, IllinoisAdaOptions);
  } else if (state === UnitedStates.TEXAS) {
    return Object.assign(GeneralAdaOptions, TexasAdaOptions);
  } else {
    return GeneralAdaOptions;
  }
};

export const shouldAskForAdmittingProviderInfo = (
  provider: ProviderRead,
  hospitalAffiliations?: HospitalAffiliation[]
) => {
  const isPhysician = provider.providerType === 'Physician';
  const hasAdmittingPrivileges = hospitalAffiliations?.some(
    (ha) => ha.affiliationType === 'Admitting Privileges'
  );
  return (
    isPhysician &&
    isProviderInAnyStates(provider, statesThatRequireAdmittingProviderInfo) &&
    !hasAdmittingPrivileges
  );
};

// Capitalizes the first letter of each word in a string and lowercases the rest
export const capitalizeFirstLetterAndLowerRest = (str: string) => {
  const splitStr = str.split(' ');
  const capitalizedWords = splitStr.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalizedWords.join(' ');
};

export const getLocationHash = (
  st1: string,
  st2: string,
  city: string,
  state: string,
  zip: string
) =>
  `${st1} ${st2} ${city}, ${
    statesToDisplayNames[abbreviationToStateEnum[state] as UnitedStates]
  } ${zip}`;

export const getProviderAddedLocationArray = (
  provider: ProviderRead
): PracticeLocationArray =>
  provider.providerAddresses
    ?.filter((address) => address.isActive)
    .reduce(
      (locationArray: PracticeLocationArray, address: ProviderAddressRead) => {
        locationArray.push({
          ...address,
          city: address.city
            ? capitalizeFirstLetterAndLowerRest(address.city)
            : '',
          isCaqhAddress: false,
          isPrimaryPracticeForState: false,
          locationHash: getLocationHash(
            address.streetLine1 ?? '',
            address.streetLine2 ?? '',
            address.city ? capitalizeFirstLetterAndLowerRest(address.city) : '',
            address.state ?? '',
            address.zipCode ?? ''
          ),
        });
        return locationArray;
      },
      []
    ) ?? [];

export const getCaqhPracticeLocationsToMap = (
  selectedPracticeStates: UnitedStates[],
  caqhPracticeLocations?: Practice[]
) => {
  let locationMap: PracticeLocationMap = {};
  caqhPracticeLocations?.forEach((practice) => {
    if (
      !selectedPracticeStates.includes(
        abbreviationToStateEnum[practice.state] as UnitedStates
      )
    ) {
      return;
    }

    const hash = getLocationHash(
      practice.street1,
      practice.street2 ?? '',
      practice.city,
      practice.state,
      practice.zip
    );

    locationMap[hash] = {
      ...practice,
      locationHash: hash,
      isCaqhAddress: true,
      isPrimaryPracticeForState: false,
    };
  });

  return locationMap;
};

export const getProviderPracticeLocationsToMap = (
  selectedPracticeStates: UnitedStates[],
  provider: ProviderRead
) => {
  let locationMap: PracticeLocationMap = {};
  provider.providerAddresses
    ?.filter((address) => address.isActive)
    .forEach((address: any) => {
      if (
        !selectedPracticeStates.includes(
          abbreviationToStateEnum[address.state] as UnitedStates
        )
      ) {
        return;
      }

      const key = getLocationHash(
        address.streetLine1,
        address.streetLine2,
        address.city ? capitalizeFirstLetterAndLowerRest(address.city) : '',
        address.state,
        address.zipCode
      );
      locationMap[key] = {
        ...address,
        city: address.city
          ? capitalizeFirstLetterAndLowerRest(address.city)
          : '',
        isCaqhAddress: false,
        locationHash: key,
      };
    });

  return locationMap;
};

export const convertPracticeLocationArrayToPracticeType = (
  locations: PracticeLocationArray
): Practice[] => {
  return locations.map((location) => {
    if (isPracticeLocationCaqhPracticeType(location)) {
      return location;
    } else {
      const convertedLocation = {
        ...location,
        street1: location.streetLine1 ?? '',
        street2: location.streetLine2 ?? '',
        city: location.city ?? '',
        state: location.state ?? '',
        zip: location.zipCode ?? '',
      };

      delete convertedLocation.streetLine1;
      delete convertedLocation.streetLine2;
      delete convertedLocation.zipCode;

      return convertedLocation;
    }
  });
};

export const getSelectedLocationsForState = (
  locations: PracticeLocationArray,
  state: UnitedStates
) => {
  return locations?.filter((location) =>
    location.state ? abbreviationToStateEnum[location.state] === state : false
  );
};
