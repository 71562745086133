import { Auth0Client } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/browser';
import { InternalAxiosRequestConfig } from 'axios';
import React from 'react';

import { decodePath, REDIRECT_TO_QUERY_KEY } from 'utils/redirect';

export const addAuth0TokenToConfig = async (
  config: InternalAxiosRequestConfig,
  client: Auth0Client
): Promise<InternalAxiosRequestConfig> => {
  // Fetch Auth0 Token, Either from Auth0 Cache or from Auth0's Server
  const token = await client
    .getTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scopes: 'default openid offline_access',
      },
    })
    .catch(async (err) => {
      console.error('Exception while fetching token for user', err);
      await redirectToAuth0Login(client);
    });

  // Manually set Authorization header
  config.headers['authorization'] = `bearer ${token}`;

  return config;
};

export const redirectToAuth0Login = async (client: Auth0Client) => {
  const start = performance.now();

  // Check for the Redirect Query Key and save that to the State for Redirection post login
  const params = new URLSearchParams(window.location.search);
  const rawRedirectTo = params.get(REDIRECT_TO_QUERY_KEY);
  const postLogInRedirectTo = rawRedirectTo ? decodePath(rawRedirectTo) : '/';
  await client.loginWithRedirect({
    appState: { returnTo: postLogInRedirectTo },
  });

  const end = performance.now();
  Sentry.metrics.distribution('redirect_to_auth_login_time', end - start, {
    unit: 'millisecond',
  });
};

export const redirectToAuth0Logout = async (client: Auth0Client | null) => {
  const start = performance.now();

  client
    ?.logout({
      logoutParams: { returnTo: process.env.REACT_APP_SIGMUND_URL },
    })
    .catch((err) => {
      console.error('Exception while logging out Auth0 User', err);
    });

  const end = performance.now();
  Sentry.metrics.distribution('redirect_to_auth_logout_time', end - start, {
    unit: 'millisecond',
  });
};

export const Auth0ClientContext = React.createContext<Auth0Client | null>(null);

export function useAuth0Client(): Auth0Client | null {
  return React.useContext(Auth0ClientContext);
}
