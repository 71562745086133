import keyBy from 'lodash/keyBy';
import React from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { FrontEndCarrierApi } from '@headway/api/resources/FrontEndCarrierApi';

interface FrontEndCarrierProviderState {
  isLoading: boolean;
  error?: Error;
  frontEndCarriers: FrontEndCarrierRead[];
  inNetworkCarriers: FrontEndCarrierRead[];
  frontEndCarriersById: { [key: number]: FrontEndCarrierRead };
  allFrontEndCarriersById: { [key: number]: FrontEndCarrierRead };
}

export type FrontEndCarrierContextState = FrontEndCarrierProviderState & {
  reload: () => void;
};

export const FrontEndCarrierContext =
  React.createContext<FrontEndCarrierContextState>({
    isLoading: true,
    frontEndCarriers: [],
    inNetworkCarriers: [],
    frontEndCarriersById: {},
    allFrontEndCarriersById: {},
    reload: () => {},
  });

export class FrontEndCarrierProvider extends React.Component<
  React.PropsWithChildren<{}>,
  FrontEndCarrierProviderState
> {
  state: FrontEndCarrierProviderState = {
    isLoading: true,
    frontEndCarriers: [],
    inNetworkCarriers: [],
    frontEndCarriersById: {},
    allFrontEndCarriersById: {},
  };

  componentDidMount() {
    this.fetchFrontEndCarriers();
  }

  fetchFrontEndCarriers = async () => {
    this.setState({ error: undefined, isLoading: true });

    try {
      const frontEndCarriers = await FrontEndCarrierApi.getFrontEndCarriers();

      const inNetworkCarriers = frontEndCarriers.filter(
        (fec) => !fec.fullyOutOfNetwork
      );

      this.setState({
        frontEndCarriers,
        inNetworkCarriers,
        frontEndCarriersById: keyBy(frontEndCarriers, 'id'),
      });
    } catch (error: any) {
      this.setState({ error });
    }
    this.setState({ isLoading: false });
  };

  reload = () => {
    this.fetchFrontEndCarriers();
  };

  render() {
    return (
      <FrontEndCarrierContext.Provider
        value={{
          ...this.state,
          reload: this.reload,
        }}
      >
        {this.props.children}
      </FrontEndCarrierContext.Provider>
    );
  }
}
