import React from 'react';

import { FormControl } from '@headway/helix/FormControl';
import { TextArea } from '@headway/helix/TextArea';

import { SchemaComponent } from '../../schema/schema.types';
import { ViewComponent } from './ViewComponent';

const MemoizedTextArea = React.memo(TextArea);

export const FormTextArea = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, description } = element;

  if (readOnly) {
    return <ViewComponent title={title} id={id} />;
  }

  return (
    <FormControl
      component={MemoizedTextArea}
      label={title}
      name={id}
      key={id}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={subTitle}
      helpText={description}
    />
  );
};
