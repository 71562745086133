import CheckIcon from '@mui/icons-material/Check';
import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material';
import React from 'react';

import { theme } from './theme';

export const Checkbox = (props: CheckboxProps) => (
  <MuiCheckbox
    checkedIcon={
      <CheckIcon
        sx={{
          fill: theme.color.white,
          height: '20px',
          width: '20px',
          border: '1px solid rgba(0, 0, 0, 0.84)',
          background: theme.color.primary,
        }}
      />
    }
    icon={
      <CheckIcon
        sx={{
          width: 0,
          height: 0,
          padding: '9px',
          border: '1px solid rgba(0, 0, 0, .54)',
        }}
      />
    }
    {...props}
  />
);
