import React from 'react';

import { theme } from '@headway/helix/theme';

export const FormTitle = ({
  title,
  subTitle,
}: {
  title?: string;
  subTitle?: string;
}) => {
  return (
    <>
      {title && !!title.trim() && (
        <label
          css={{
            ...theme.typography.caption.medium,
            color: theme.color.system.textBlack,
          }}
        >
          {title}
        </label>
      )}
      {subTitle && !!subTitle.trim() && (
        <div
          css={{
            ...theme.typography.caption.regular,
            color: theme.color.system.textBlack,
          }}
        >
          {subTitle}
        </div>
      )}
    </>
  );
};
