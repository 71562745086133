import { useProvider } from 'hooks';
import type { Dictionary } from 'lodash';
import keyBy from 'lodash/keyBy';
import range from 'lodash/range';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GetPaginatedUserReadResponse } from '@headway/api/models/GetPaginatedUserReadResponse';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { notifyError } from '@headway/ui/utils/notify';

interface PatientsContextValue {
  isLoading: boolean;
  patients: UserRead[] | null;
  patientsById: Dictionary<UserRead> | null;
  reload: () => Promise<void>;
}

const DEFAULT_PAGE_SIZE = 200;

export const PatientsContext = React.createContext<PatientsContextValue>({
  isLoading: true,
  patients: null,
  patientsById: null,
  reload: async () => {},
});

interface PatientsProviderProps {
  children: React.ReactNode;
}

export const PatientsProvider = ({ children }: PatientsProviderProps) => {
  // Page size is configured via feature flag, which must be >0.
  const pageSize = useFlag('patientsProviderPageSize', DEFAULT_PAGE_SIZE);
  const [isLoading, setIsLoading] = useState(true);
  const [patients, setPatients] = useState<UserRead[] | null>(null);
  const provider = useProvider();

  /**
   * Since /provider/:id/patients is slow as of 2023-11-06, we attempt to speed up loading by using
   * several parallel requests.
   */
  const fetchPatients = useCallback(async () => {
    if (pageSize === 0) {
      return;
    }

    setIsLoading(true);
    try {
      const allPages: GetPaginatedUserReadResponse[] = [];
      // Fetch the first page to learn how many total users there are.
      const firstPage = await ProviderApi.getPatientsPaginatedForProvider(
        provider.id,
        { limit: pageSize }
      );
      allPages.push(firstPage);
      if (firstPage.totalCount > pageSize) {
        // Fetch any remaining pages in parallel
        const otherPages = await Promise.all(
          range(1, Math.ceil(firstPage.totalCount / pageSize)).map((page) =>
            ProviderApi.getPatientsPaginatedForProvider(provider.id, {
              limit: pageSize,
              offset: pageSize * page,
            })
          )
        );
        allPages.push(...otherPages);
      }
      setPatients(allPages.map((pageData) => pageData.data).flat());
    } catch (e) {
      if (e instanceof Error) {
        notifyError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [provider.id, pageSize]);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  const patientsById = useMemo(() => keyBy(patients, 'id'), [patients]);

  return (
    <PatientsContext.Provider
      value={{
        patients: patients,
        patientsById: patientsById,
        isLoading: isLoading,
        reload: fetchPatients,
      }}
    >
      {children}
    </PatientsContext.Provider>
  );
};
