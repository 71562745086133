import React from 'react';

import { TitledSection } from '@headway/ui';
import { theme } from '@headway/ui/theme';

export interface LegalSectionProps {
  title: string;
  action?: React.ReactNode;
}

export const LegalSection: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<LegalSectionProps>>
> = (props) => (
  <div
    css={{
      margin: `${theme.space.base} 0`,
      [theme.media.small]: { margin: `${theme.space.xl6} 0` },
    }}
  >
    <TitledSection {...props} />
  </div>
);
