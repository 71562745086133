import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import {
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Skeleton,
} from '@mui/material';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import React, { useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { BillingType } from '@headway/api/models/BillingType';
import { GetPaginatedUserReadResponse } from '@headway/api/models/GetPaginatedUserReadResponse';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconButton } from '@headway/helix/IconButton';
import { IconChevronDown } from '@headway/helix/icons/ChevronDown';
import { Menu, MenuItem, MenuTrigger } from '@headway/helix/Menu';
import { PageHeader } from '@headway/helix/PageHeader';
import { Pagination } from '@headway/helix/Pagination';
import { SearchField } from '@headway/helix/SearchField';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { useProviderUserFreezes } from '@headway/shared/hooks/useProviderUserFreezes';
import { useQuery, useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import {
  useNumberQueryParam,
  useQueryParam,
} from '@headway/shared/utils/queryParams';
import { isUserSelfPayWithRespectToProvider } from '@headway/shared/utils/selfPay';
import { logException } from '@headway/shared/utils/sentry';
import { joinWithOxfordComma } from '@headway/shared/utils/stringFormatting';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { getUsePatientsQueryKey } from 'hooks/usePatients';
import {
  getUseProviderPatientQueryKey,
  useProviderPatientList,
} from 'hooks/useProviderPatient';
import { PanelLayout } from 'layouts/PanelLayout';
import { useAuthStore } from 'stores/AuthStore';
import {
  hasCoordinationOfBenefitsAwaitingUserFreeze,
  hasCoordinationOfBenefitsFreeze,
  hasOtherFreeze,
  hasOutOfNetworkFreeze,
  hasTermedPlanFreeze,
} from 'utils/freeze';
import { AddPatientModal } from 'views/Patients/AddPatient/AddPatientModal';
import { BulkPatientPortingWizard } from 'views/Patients/bulkPatientPorting/BulkPatientPorting';

import { BulkClientEmailConfirmationModal } from './BulkClientEmailConfirmationModal';
import { ClientListRow } from './ClientListRow';

const NB_CLIENTS_PER_PAGE = 25;

export const Clients = () => {
  const AuthStore = useAuthStore();
  const navigate = useNavigate();
  const [pageParam, setPageParam] = useNumberQueryParam('page');

  const [searchParam] = useQueryParam('search');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchParam = useCallback(
    debounce(
      (value: string | undefined) => {
        // Revert back to page 1 when the search changes
        navigate(`/clients?search=${value}`, { replace: true });
      },
      300,
      { maxWait: 500 }
    ),
    [navigate]
  );

  const page = pageParam || 1;

  const [isAddPatientOpen, toggleIsAddPatientOpen] = useToggle(false);
  const [showArchived, toggleShowArchived] = useToggle(false);
  const queryClient = useQueryClient();

  const [showBulkPatientWizard, setShowBulkPatientWizard] =
    React.useState(false);
  const [showBulkEmailConfirmationModal, setShowBulkEmailConfirmationModal] =
    React.useState(false);

  const { carriersById } = useFrontEndCarriers();
  const isMobileView = useMediaQuery(theme.mediaQuery.mobile);

  const patientsQueryKey = getUsePatientsQueryKey(AuthStore.provider.id, {
    hidden: showArchived,
    page,
    search: searchParam,
  });

  const {
    isFetching,
    isLoading,
    data: patientsQueryResult,
  } = useQuery(
    patientsQueryKey,
    async () => {
      return await ProviderApi.getPatientsPaginatedForProvider(
        AuthStore.provider.id,
        {
          offset: (page - 1) * NB_CLIENTS_PER_PAGE,
          limit: NB_CLIENTS_PER_PAGE,
          hidden: showArchived,
          search: searchParam,
        }
      );
    },
    {
      onError: (err) => {
        notifyError(
          'Error loading your patients. Please reload and try again.'
        );
        logException(err);
      },
      refetchOnWindowFocus: false,
      cacheTime: 60000, // Keep cached data for 1 minute
      staleTime: 60000,
      keepPreviousData: true,
    }
  );

  const patients = patientsQueryResult?.data || [];
  const totalClients = patientsQueryResult?.totalCount || 0;

  const newlyBookablePatientsQueryKey = [
    'provider',
    AuthStore.provider.id,
    'newly-bookable-patients',
  ];
  const { data: newlyBookablePatients } = useQuery<UserRead[], Error>(
    newlyBookablePatientsQueryKey,
    async () => {
      const result = await ProviderApi.getNewlyBookablePatients(
        AuthStore.provider.id
      );
      return result;
    },
    {
      onError: (err) => {
        notifyError(
          `Error loading patient's information. Please reload and try again.`
        );
        logException(err);
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleToggleArchivedClick = async (user: UserRead) => {
    try {
      const providerPatientQueryKey = getUseProviderPatientQueryKey({
        providerId: AuthStore.provider.id,
        patientId: user.id,
      });
      const providerPatient = await queryClient.fetchQuery<ProviderPatientRead>(
        providerPatientQueryKey
      );
      await ProviderPatientApi.updateProviderPatient(providerPatient.id, {
        hidden: !showArchived,
      });
      if (
        newlyBookablePatients &&
        newlyBookablePatients.filter((patient) => patient.id === user.id)
          .length === 1
      ) {
        queryClient.setQueryData<UserRead[]>(
          newlyBookablePatientsQueryKey,
          (patients) =>
            (patients || []).filter((patient) => patient.id !== user.id)
        );
      }

      queryClient.invalidateQueries(patientsQueryKey);
      const hiddenPatientsQueryKey = {
        ...patientsQueryKey,
        params: { ...patientsQueryKey[3], hidden: !showArchived },
      };
      queryClient.invalidateQueries(hiddenPatientsQueryKey);
      queryClient.invalidateQueries([
        'patientMissingSchedulingInfoForProvider',
        AuthStore.provider.id,
      ]);

      if (patients!.filter((patient) => patient.id !== user.id).length === 0) {
        /* we archived the sole client on the current page so we need 
        to show the previous page (or 1) since there are no more to show 
        on the current one */
        setPageParam(Math.max(1, page - 1));
      }

      notifySuccess(
        `${formatPatientName(user)} was ${
          showArchived ? 'unarchived' : 'archived'
        }`
      );
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyError(error instanceof Error ? error.message : error);
    }
  };

  const getNewlyBookablePatientName = (patientId: number): string => {
    if (newlyBookablePatients) {
      const patient = newlyBookablePatients.find(
        (patient) => patient.id === patientId
      );
      if (!patient) {
        throw new Error(
          `Expected ${patientId} to exist in newly bookable patients list`
        );
      }
      return formatPatientName(patient);
    }
    return '';
  };

  const patientIds = patients.map((p) => p.id);

  const patientClaimReadinessQuery = useQuery(
    ['patient-readiness', patientIds],
    () => {
      return ProviderApi.getProviderPatientClaimReadiness(
        AuthStore.provider.id,
        patientIds
      );
    },
    {
      enabled: patientIds.length > 0,
    }
  );

  const patientClaimReadinessByPatientId = keyBy(
    patientClaimReadinessQuery.data ?? [],
    'userId'
  );

  const providerPatientListQuery = useProviderPatientList<ProviderPatientRead>(
    patients.map((patient) => ({
      queryKeyArgs: {
        providerId: AuthStore.provider.id,
        patientId: patient.id,
      },
      options: {
        onError: (err) => {
          notifyError(
            `Error loading ${formatPatientName(patient, {
              firstNameOnly: true,
            })}'s information. Please reload and try again.`
          );
          logException(err);
        },
        refetchOnWindowFocus: false,
      },
    }))
  );

  let patientBillingTypeByPatientId: { [key: number]: BillingType } = {};
  providerPatientListQuery.forEach((query) => {
    if (query && query.data) {
      patientBillingTypeByPatientId[query.data.userId] =
        query.data.billingTypeDefault;
    }
  });

  const { freezeReasonsByUser } = useProviderUserFreezes(AuthStore.provider.id);

  const userFreezeIds = Object.keys(freezeReasonsByUser).map((userid) =>
    parseInt(userid)
  );

  const { isLoading: frozenPatientsLoading, data: frozenPatientsData } =
    useQuery(
      ['frozen-users', userFreezeIds],
      async () => {
        return await UserApi.findUsers({
          ids: userFreezeIds,
        });
      },
      {
        onError: (err) => {
          notifyError(
            `Error loading patient insurance's information. Please reload and try again.`
          );
          logException(err);
        },
        refetchOnWindowFocus: false,
        enabled: userFreezeIds.length > 0,
      }
    );

  const frozenUserSet: Set<number> = new Set(
    Object.keys(freezeReasonsByUser).map((userId) => parseInt(userId))
  );

  const cobFrozenUserIds = Object.keys(freezeReasonsByUser).filter(
    (userId) =>
      hasCoordinationOfBenefitsFreeze(freezeReasonsByUser[userId]) ||
      hasCoordinationOfBenefitsAwaitingUserFreeze(freezeReasonsByUser[userId])
  );

  const oonFrozenUserIds = Object.keys(freezeReasonsByUser).filter((userId) =>
    hasOutOfNetworkFreeze(freezeReasonsByUser[userId])
  );

  const termedPlanFrozenUserIds = Object.keys(freezeReasonsByUser).filter(
    (userId) => hasTermedPlanFreeze(freezeReasonsByUser[userId])
  );

  const otherFrozenUserIds = Object.keys(freezeReasonsByUser).filter((userId) =>
    hasOtherFreeze(freezeReasonsByUser[userId])
  );

  let cobFrozenUsers: UserRead[] = [];
  let otherFrozenUsers: UserRead[] = [];
  let termedPlanFrozenUsers: UserRead[] = [];
  let oonFrozenUsers: UserRead[] = [];
  if (frozenPatientsData?.length) {
    cobFrozenUsers = frozenPatientsData.filter((patient) => {
      return cobFrozenUserIds.includes(String(patient.id));
    });
    otherFrozenUsers = frozenPatientsData.filter((patient) => {
      return otherFrozenUserIds.includes(String(patient.id));
    });
    termedPlanFrozenUsers = frozenPatientsData.filter((patient) => {
      return termedPlanFrozenUserIds.includes(String(patient.id));
    });
    oonFrozenUsers = frozenPatientsData.filter((patient) => {
      return (
        oonFrozenUserIds.includes(String(patient.id)) &&
        !isUserSelfPayWithRespectToProvider(patient, AuthStore.provider.id)
      );
    });
  }

  const cobFrozenUserNames = cobFrozenUsers.map((user) =>
    formatPatientName(user)
  );

  const otherFrozenUserNames = otherFrozenUsers.map((user) =>
    formatPatientName(user)
  );

  const oonFrozenUserNames = oonFrozenUsers.map((user) =>
    formatPatientName(user)
  );

  const termedPlanFrozenUserNames = termedPlanFrozenUsers.map((user) =>
    formatPatientName(user)
  );

  const commaDelimitedCobFrozenUsers = joinWithOxfordComma(cobFrozenUserNames);
  const commaDelimitedOONFrozenUsers = joinWithOxfordComma(oonFrozenUserNames);
  const commaDelimitedTermedPlanFrozenUsers = joinWithOxfordComma(
    termedPlanFrozenUserNames
  );
  const commaDelimitedOtherFrozenUsers =
    joinWithOxfordComma(otherFrozenUserNames);

  if (
    newlyBookablePatients &&
    newlyBookablePatients.filter((patient) => frozenUserSet.has(patient.id))
      .length > 0
  ) {
    queryClient.setQueryData<UserRead[]>(
      newlyBookablePatientsQueryKey,
      (patients) =>
        (patients || []).filter((patient) => !frozenUserSet.has(patient.id))
    );
  }

  if (page < 1 || pageParam === 0) {
    return <Navigate replace to="/clients?page=1" />;
  }

  return (
    <PanelLayout>
      {showBulkPatientWizard && (
        <BulkPatientPortingWizard
          onClientsAdded={async () => {
            queryClient.invalidateQueries(patientsQueryKey);
          }}
          onClientsNotified={(notifiedClients) => {
            queryClient.setQueryData<GetPaginatedUserReadResponse>(
              patientsQueryKey,
              (paginatedPatients) => ({
                ...paginatedPatients!,
                data: patients!.map((patient) => {
                  const foundClient = notifiedClients.find(
                    (client) => client.id === patient.id
                  );
                  return foundClient ? foundClient : patient;
                }),
              })
            );
          }}
          onClose={() => {
            setShowBulkPatientWizard(false);
          }}
        />
      )}
      <div
        css={{
          marginBottom: theme.spacing.x4,
          overflow: 'hidden',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: theme.spacing.x1,
          paddingRight: theme.spacing.x1,
          width: 820,
          [theme.media.mobile]: {
            width: '100%',
          },
        }}
      >
        {frozenPatientsData?.length &&
          !frozenPatientsLoading &&
          cobFrozenUsers.length > 0 && (
            <GuidanceCard variant="warning">
              <div>
                Session details can't be confirmed for{' '}
                {commaDelimitedCobFrozenUsers} because of an issue with their
                coordination of benefits. See client details for more
                information.
              </div>
            </GuidanceCard>
          )}

        {frozenPatientsData?.length &&
          !frozenPatientsLoading &&
          oonFrozenUsers.length > 0 && (
            <GuidanceCard variant="warning">
              <div>
                Session details can't be confirmed for{' '}
                {commaDelimitedOONFrozenUsers} because their plan is out of
                network. See client details for more information.
              </div>
            </GuidanceCard>
          )}
        {frozenPatientsData?.length &&
          !frozenPatientsLoading &&
          termedPlanFrozenUsers.length > 0 && (
            <GuidanceCard variant="warning">
              <div>
                Session details can't be confirmed for{' '}
                {commaDelimitedTermedPlanFrozenUsers} because their plan is no
                longer active. See client details for more information.
              </div>
            </GuidanceCard>
          )}

        {frozenPatientsData?.length &&
          !frozenPatientsLoading &&
          otherFrozenUsers.length > 0 && (
            <div
              css={{
                marginTop: cobFrozenUsers.length > 0 ? theme.spacing.x3 : '',
              }}
            >
              <GuidanceCard variant="warning">
                The Headway account{otherFrozenUsers.length > 1 ? 's' : ''} for{' '}
                {commaDelimitedOtherFrozenUsers}{' '}
                {otherFrozenUsers.length > 1 ? 'are' : 'is'} paused because
                there's an issue verifying their insurance plan coverage. See
                client details for more information.
              </GuidanceCard>
            </div>
          )}
        <div
          css={{
            display: 'flex',
            alignItems: 'stretch',
            paddingTop: isMobileView ? theme.spacing.x4 : theme.spacing.x5,
            paddingBottom: isMobileView ? theme.spacing.x3 : theme.spacing.x5,
            gap: theme.spacing.x2,
          }}
        >
          <h4
            css={{
              margin: 0,
              flexGrow: 1,
              alignSelf: 'center',
            }}
          >
            <PageHeader>Clients</PageHeader>
          </h4>
          {!isMobileView && (
            <>
              <CircularProgress
                size={16}
                color="inherit"
                css={{ color: 'black', alignSelf: 'center' }}
                style={{
                  visibility: isFetching && searchParam ? 'visible' : 'hidden',
                }}
              />
              <div css={{ flexBasis: 360, display: 'grid' }}>
                <SearchField
                  onChange={(value: string) => {
                    debouncedSetSearchParam(value);
                  }}
                  placeholder="Search name or email"
                  defaultValue={searchParam}
                />
              </div>
            </>
          )}
          <MenuTrigger>
            <IconButton variant="default" size="large" aria-label="more-btn">
              <MoreHorizIcon />
            </IconButton>
            <Menu
              onAction={(key) => {
                if (key === 'show-archived') {
                  navigate('/clients', { replace: true });
                  toggleShowArchived();
                } else if (key === 'send-email-reminder') {
                  setShowBulkEmailConfirmationModal(true);
                }
              }}
            >
              <MenuItem key="show-archived">
                {showArchived
                  ? 'View unarchived clients'
                  : 'View archived clients'}
              </MenuItem>
              <MenuItem key="send-email-reminder">
                Send reminder email to all clients with incomplete accounts
              </MenuItem>
            </Menu>
          </MenuTrigger>
          <MenuTrigger>
            <Button variant="brand" size="large">
              Add client(s)
              <IconChevronDown
                css={{
                  marginLeft: theme.spacing.x1,
                  verticalAlign: 'bottom',
                }}
              />
            </Button>
            <Menu
              onAction={(key) => {
                if (key === 'add-client') {
                  toggleIsAddPatientOpen();
                } else if (key === 'import-client-list') {
                  setShowBulkPatientWizard(true);
                  trackEvent({
                    name: 'Add Patient Started',
                    properties: {
                      providerFlow: 'multiple_patients',
                    },
                  });
                }
              }}
            >
              <MenuItem key="add-client">Add client</MenuItem>
              <MenuItem key="import-client-list">Import client list</MenuItem>
            </Menu>
          </MenuTrigger>
        </div>
        {isMobileView && (
          <div css={{ marginBottom: theme.spacing.x3 }}>
            <SearchField
              onChange={(value: string) => {
                debouncedSetSearchParam(value);
              }}
              placeholder="Search name or email"
              defaultValue={searchParam}
            />
          </div>
        )}
        {!showArchived &&
          !searchParam &&
          newlyBookablePatients?.length! > 0 && (
            <GuidanceCard variant="info">
              {newlyBookablePatients?.length === 1
                ? `You can now schedule a session with ${getNewlyBookablePatientName(
                    newlyBookablePatients[0].id
                  )}.`
                : 'You have new clients ready to be scheduled.'}
              <Button
                variant="link"
                size="large"
                onPress={() => {
                  trackEvent({
                    name: 'Newly Bookable Patients Alert',
                  });
                  navigate(`/calendar`);
                }}
              >
                Go to calendar
              </Button>
            </GuidanceCard>
          )}
        <div>
          {!patients.length && isLoading ? (
            new Array(3).fill('_').map((_, idx) => (
              <React.Fragment key={`placeholder-${idx}`}>
                <Card elevation={0}>
                  <CardHeader
                    avatar={
                      <Skeleton variant="circular" width={40} height={40} />
                    }
                    title={<Skeleton variant="text" css={{ maxWidth: 50 }} />}
                    subheader={
                      <Skeleton variant="text" css={{ maxWidth: 100 }} />
                    }
                  />
                </Card>
                <Divider />
              </React.Fragment>
            ))
          ) : !patients.length ? (
            searchParam ? (
              <div
                css={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  gap: theme.spacing.x2,
                  marginTop: theme.spacing.x10,
                }}
              >
                <PersonOutlinedIcon
                  css={{
                    marginBottom: theme.spacing.x2,
                    backgroundColor: theme.color.hue.lightGreen,
                    padding: theme.spacing.x3,
                    borderRadius: '50%',
                    fontSize: '50px',
                  }}
                  color="primary"
                />
                <div>
                  <SectionHeader>
                    No clients matching "{searchParam}"
                  </SectionHeader>
                </div>
                <div>
                  <BodyText>
                    Try searching for another client by name or email.
                  </BodyText>
                </div>
              </div>
            ) : showArchived ? (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  gap: theme.spacing.x3,
                  marginTop: 300,
                }}
              >
                <BodyText>
                  Once you archive a client, they will be shown here.
                </BodyText>
                <Button
                  variant="link"
                  onPress={() => {
                    navigate('/clients', { replace: true });
                    toggleShowArchived();
                  }}
                  size="large"
                >
                  View unarchived clients
                </Button>
              </div>
            ) : (
              <div
                css={{
                  marginTop: 300,
                  padding: theme.spacing.x8,
                  textAlign: 'center',
                }}
              >
                <BodyText>
                  To add a client or import your caseload, click “Add client(s)”
                  above.
                </BodyText>
              </div>
            )
          ) : (
            <ul css={{ paddingInlineStart: 0 }}>
              {patients.map((patient, index) => (
                <ClientListRow
                  key={`client-item-${patient.id}`}
                  claimReadiness={patientClaimReadinessByPatientId[patient.id]}
                  client={patient}
                  billingType={patientBillingTypeByPatientId[patient.id]}
                  isArchived={showArchived}
                  providerFrontEndCarriers={
                    AuthStore.provider.providerFrontEndCarriers
                  }
                  handleToggleArchiveClick={() => {
                    handleToggleArchivedClick(patient);
                  }}
                  carriersById={carriersById}
                  freezeReasonsByUser={freezeReasonsByUser}
                  page={page}
                  provider={AuthStore.provider}
                  search={searchParam}
                />
              ))}
            </ul>
          )}
        </div>
        {totalClients > NB_CLIENTS_PER_PAGE && (
          <div css={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              page={page}
              totalPages={Math.ceil(totalClients / NB_CLIENTS_PER_PAGE)}
              onPageChange={(newPage) => {
                setPageParam(newPage);
              }}
            />
          </div>
        )}
      </div>
      <AddPatientModal
        isOpen={isAddPatientOpen}
        onClose={toggleIsAddPatientOpen}
        onClientAdded={() => {
          queryClient.invalidateQueries(patientsQueryKey);
        }}
        onComplete={(updatedUser) => {
          navigate(`/clients/${updatedUser.id}`);
        }}
      />
      <BulkClientEmailConfirmationModal
        open={showBulkEmailConfirmationModal}
        onClose={() => setShowBulkEmailConfirmationModal(false)}
        showArchived={showArchived}
        page={page}
        search={searchParam}
      />
    </PanelLayout>
  );
};
