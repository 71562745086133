import { css } from '@emotion/react';
import { FormikContextType, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { theme } from '@headway/helix/theme';

import { DefaultOptionBehaviorData } from '../../schema/schema.types';
import { Component } from '../../schema/schema.types';

export interface CollapsibleSectionProps {
  isCollapsibleOpen?: boolean;
  header?: string;
  subHeader?: string;
  disabled?: boolean;
  defaultOptionBehavior?: DefaultOptionBehaviorData;
  components?: Component[];
  children: React.ReactNode;
}

const doAllComponentsEqualTheirDefaultValue = <T,>(
  formik: FormikContextType<any>,
  components?: Component[]
) => {
  if (!components || !components.length) return false;

  for (let component of components) {
    if (!component.defaultResponse) {
      continue;
    }
    if (formik.values[component.id] instanceof Array) {
      if (
        !formik.values[component.id].length ||
        formik.values[component.id].find(
          (item: string) => item !== component.defaultResponse
        )
      ) {
        return false;
      }
    } else {
      if (formik.values[component.id] !== component.defaultResponse) {
        return false;
      }
    }
  }

  return true;
};

const setAllComponentsToTheirDefaultValue = <T,>(
  formik: FormikContextType<any>,
  components?: Component[]
) => {
  if (!components || !components.length) return;

  for (let component of components) {
    if (!component.defaultResponse) {
      continue;
    }
    formik.setFieldValue(
      component.id,
      formik.values[component.id] instanceof Array
        ? [component.defaultResponse]
        : component.defaultResponse
    );
  }
};

const setAllComponentsToTheirEmptyalue = <T,>(
  formik: FormikContextType<any>,
  components?: Component[]
) => {
  if (!components || !components.length) return;

  for (let component of components) {
    if (!component.defaultResponse) {
      continue;
    }
    formik.setFieldValue(
      component.id,
      formik.values[component.id] instanceof Array ? [] : ''
    );
  }
};

export const CollapsibleSection = <T,>({
  isCollapsibleOpen,
  header,
  subHeader,
  disabled,
  defaultOptionBehavior,
  components,
  children,
}: CollapsibleSectionProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext();

  const [isOpen, setIsOpen] = useState(
    defaultOptionBehavior &&
      doAllComponentsEqualTheirDefaultValue(formik, components)
      ? false
      : isCollapsibleOpen
  );
  const [height, setHeight] = useState<number | undefined>(
    isOpen ? undefined : 0
  );

  useEffect(() => {
    if (!height || !isOpen || !contentRef.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(contentRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(contentRef.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <div css={sectionContainerCss}>
      <div css={sectionHeaderContainerCss}>
        <div
          css={sectionHeaderTitleContainerCss}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={(event) => {
            if (event.code === 'Enter') setIsOpen(!isOpen);
          }}
          tabIndex={0}
        >
          <div css={sectionHeaderCss}>
            <div css={sectionHeaderArrowContainerCss(isOpen)}>
              <div css={sectionHeaderArrowCss(isOpen)} />
            </div>
            <span css={sectionHeaderTextCss(disabled)}>{header}</span>
          </div>
          {subHeader && (
            <span css={sectionSubHeaderTextCss(disabled)}>{subHeader}</span>
          )}
        </div>
        {defaultOptionBehavior &&
          components &&
          components.filter((component) => component.defaultResponse).length >
            0 && (
            <CheckboxGroup
              name={`defaultOption-${defaultOptionBehavior.buttonText}-checkboxGroup`}
              value={
                doAllComponentsEqualTheirDefaultValue(formik, components)
                  ? [`defaultOption-${defaultOptionBehavior.buttonText}`]
                  : []
              }
              disabled={disabled}
            >
              <Checkbox
                value={`defaultOption-${defaultOptionBehavior.buttonText}`}
                onChange={(checked) => {
                  if (checked) {
                    setAllComponentsToTheirDefaultValue(formik, components);
                    setIsOpen(false);
                  } else {
                    setAllComponentsToTheirEmptyalue(formik, components);
                    setIsOpen(true);
                  }
                }}
                disabled={disabled}
              >
                {defaultOptionBehavior.buttonText}
              </Checkbox>
            </CheckboxGroup>
          )}
      </div>
      <div css={sectionContentContainerCss} style={{ height }}>
        <div ref={contentRef}>
          <div css={{ paddingBottom: theme.spacing.x5 }}>{children}</div>
        </div>
      </div>
    </div>
  );
};

const sectionContainerCss = css`
  display: flex;
  flex-direction: column;
`;
const sectionHeaderContainerCss = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const sectionHeaderTitleContainerCss = css`
  display: flex;
  width: fit-content;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
`;
const sectionHeaderCss = css`
  display: flex;
  align-items: center;
`;
const sectionHeaderArrowContainerCss = (isOpened: boolean | undefined) => css`
  min-width: 16px;
  position: absolute;
  // This is to fix the visual shift between the arrow down (opened) vs the arrow right
  left: ${isOpened ? '-20px' : '-16px'};
`;
const sectionHeaderArrowCss = (isOpened: boolean | undefined) => css`
  width: 0;
  height: 0;
  border-top: ${isOpened
    ? `5px solid ${theme.color.system.black} `
    : '5px solid transparent'};
  border-bottom: ${isOpened ? 'none' : '5px solid transparent'};
  border-right: ${isOpened ? '5px solid transparent' : 'none'};
  border-left: ${isOpened
    ? '5px solid transparent'
    : `5px solid ${theme.color.system.black} `};
  margin-right: ${theme.spacing.x3};
`;
const sectionHeaderTextCss = (disabled: boolean | undefined) => css`
  ${theme.typography.sectionHeader};
  color: ${disabled
    ? theme.color.system.disabledGray
    : theme.color.system.textBlack};
`;
const sectionSubHeaderTextCss = (disabled: boolean | undefined) => css`
  ${theme.typography.subbody.regular};
  color: ${disabled
    ? theme.color.system.disabledGray
    : theme.color.system.textBlack};
`;
const sectionContentContainerCss = css`
  overflow: hidden;
  transition: height 0.6s ease-in-out;
`;
