export const joinWithOxfordComma = (strings: string[]): string => {
  if (strings.length === 0) {
    return '';
  } else if (strings.length === 1) {
    return strings[0];
  } else if (strings.length === 2) {
    return strings.join(' and ');
  } else {
    const lastElement = strings.pop();
    return strings.join(', ') + ', and ' + lastElement;
  }
};
