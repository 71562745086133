import { Item, PartialNode, Section } from '@react-stately/collections';
import { TreeState } from '@react-stately/tree';
import {
  CollectionBase,
  ItemElement,
  ItemProps,
  Node,
} from '@react-types/shared';
import React, { ReactElement, useLayoutEffect, useRef } from 'react';
import {
  FocusRing,
  mergeProps,
  OverlayContainer,
  useHover,
  useMenu,
  useMenuItem,
  useMenuSection,
  useMenuTrigger,
  useOverlayPosition,
  useSeparator,
} from 'react-aria';
import { useMenuTriggerState, useTreeState } from 'react-stately';
import { Simplify } from 'type-fest';

import { Popover } from './collections/Popover';
import { IconCheck } from './icons/Check';
import { formatError } from './utils/errorFormatter';

type HelixMenuTriggerProps = {
  /** Width of the menu. */
  menuWidth?: 'small' | 'medium' | 'stretch';
  /**
   * Where the Menu opens relative to its trigger.
   * @default 'bottom'
   */
  direction?: 'bottom' | 'top';
  children: React.ReactElement[];
};

function MenuTrigger(props: HelixMenuTriggerProps) {
  const { menuWidth = 'medium' } = props;

  const popoverRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);

  let [menuTrigger, menu] = React.Children.toArray(props.children) as [
    React.ReactElement,
    React.ReactElement,
  ];

  let state = useMenuTriggerState({
    trigger: 'press',
  });

  if (!menuTrigger || !menu) {
    throw new Error(
      formatError(
        'MenuTrigger should wrap both a Menu and the corresponding Button'
      )
    );
  }

  let { menuTriggerProps, menuProps } = useMenuTrigger(
    { trigger: 'press' },
    state,
    triggerRef
  );

  // TODO: Determine via hook or similar once we know more about what mobile
  // selects should look like
  const isMobile = false;

  const { overlayProps, placement, updatePosition } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef: popoverRef,
    scrollRef: menuRef,
    // TODO: Do we want to support consumers controlling the placement of the popover?
    // TODO: Support more placements if we run into cases during migration
    placement: 'bottom left',
    shouldFlip: true,
    isOpen: state.isOpen && !isMobile,

    onClose: state.close,
  });

  useLayoutEffect(() => {
    if (state.isOpen) {
      requestAnimationFrame(() => {
        updatePosition();
      });
    }
  }, [state.isOpen, updatePosition]);

  let menuContext = {
    ...menuProps,
    ref: menuRef,
    onClose: state.close,
    closeOnSelect: true,
    autoFocus: true,
  };

  return (
    <>
      {React.cloneElement(menuTrigger as ReactElement, {
        ref: triggerRef,
        ...mergeProps(menuTrigger.props, menuTriggerProps),
      })}

      <MenuContext.Provider value={menuContext}>
        {state.isOpen && (
          <OverlayContainer>
            <Popover
              __style={{
                ...overlayProps.style,
                width: menuWidthStyle[menuWidth],
              }}
              popoverRef={popoverRef}
              isOpen={state.isOpen}
              onClose={state.close}
            >
              {menu}
            </Popover>
          </OverlayContainer>
        )}
      </MenuContext.Provider>
    </>
  );
}

export interface HelixMenuProps<T> extends CollectionBase<T> {
  /** Handler that is called when an item is selected. */
  onAction?: (key: React.Key) => void;
  /** Handler that is called when the menu should close after selecting an item. */
  onClose?: () => void;
}

function Menu<T extends object>(props: HelixMenuProps<T>) {
  let contextProps = useMenuContext();
  const listRef = contextProps.ref;
  if (!listRef) {
    throw new Error(
      formatError('Expected menu ref to be passed with menu context')
    );
  }
  let allProps = {
    ...mergeProps(contextProps, props),
  };

  let state = useTreeState(allProps);
  let { menuProps } = useMenu(allProps, state, listRef);
  return (
    <ul {...menuProps} ref={listRef} className="hlx-menu">
      {[...state.collection].map((item) => {
        if (item.type === 'section') {
          return (
            <MenuSection
              key={item.key}
              item={item}
              state={state}
              onAction={allProps.onAction}
            />
          );
        }

        let menuItem = (
          <MenuItem
            key={item.key}
            item={item}
            state={state}
            onAction={allProps.onAction}
          />
        );

        if (item.wrapper) {
          menuItem = item.wrapper(menuItem);
        }

        return menuItem;
      })}
    </ul>
  );
}

interface MenuItemProps<T> {
  item: Node<T>;
  state: TreeState<T>;
  onAction?: (key: React.Key) => void;
}

function MenuItem<T>(props: MenuItemProps<T>) {
  let { item, state, onAction } = props;

  let { onClose, closeOnSelect } = useMenuContext();

  let { rendered, key } = item;

  let isSelected = state.selectionManager.isSelected(key);
  let isDisabled = state.disabledKeys.has(key);

  let ref = useRef<HTMLLIElement>(null);
  let { menuItemProps, labelProps, descriptionProps, keyboardShortcutProps } =
    useMenuItem(
      {
        isSelected,
        isDisabled,
        'aria-label': item['aria-label'],
        key,
        onClose,
        closeOnSelect,
        isVirtualized: false,
        onAction,
      },
      state,
      ref
    );
  let { hoverProps, isHovered } = useHover({ isDisabled });

  let contents =
    typeof rendered === 'string' ? <span>{rendered}</span> : rendered;

  const refProp = item.props.isLink
    ? {
        role: null,
      }
    : {
        ref,
      };

  return (
    <FocusRing within focusRingClass="focus-ring">
      <li
        {...mergeProps(menuItemProps, hoverProps, refProp as any)}
        className={`hlx-menu-item ${item.props?.className ?? ''}`}
        data-disabled={isDisabled}
        data-selected={isSelected}
        data-hovered={isHovered}
      >
        {item.props.isLink
          ? React.cloneElement(contents as React.ReactElement, { ref })
          : contents}
        {isSelected && <IconCheck />}
      </li>
    </FocusRing>
  );
}
interface MenuSectionProps<T> {
  item: Node<T>;
  state: TreeState<T>;
  onAction?: (key: React.Key) => void;
}

function MenuSection<T>(props: MenuSectionProps<T>) {
  let { item, state, onAction } = props;
  let { itemProps, headingProps, groupProps } = useMenuSection({
    heading: item.rendered,
    'aria-label': item['aria-label'],
  });

  let { separatorProps } = useSeparator({
    elementType: 'li',
  });

  return (
    <React.Fragment>
      {item.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className="hlx-menu-section-separator" />
      )}
      <li {...itemProps}>
        {item.rendered && (
          <span {...headingProps} className="hlx-menu-section-heading">
            {item.rendered}
          </span>
        )}
        <ul {...groupProps} className="hlx-menu-section">
          {[...item.childNodes].map((node) => {
            let item = (
              <MenuItem
                key={node.key}
                item={node}
                state={state}
                onAction={onAction}
              />
            );

            if (node.wrapper) {
              item = node.wrapper(item);
            }

            return item;
          })}
        </ul>
      </li>
    </React.Fragment>
  );
}

type LinkLike = 'a' | React.JSXElementConstructor<any>;
type MenuLinkProps<T, Component extends LinkLike> = Simplify<
  {
    component?: Component;
    children: React.ReactNode;
  } & React.ComponentProps<Component> &
    ItemProps<T>
>;

function MenuLink<T, Component extends LinkLike = 'a'>(
  props: MenuLinkProps<T, Component>
) {
  return null;
}

// see https://github.dev/adobe/react-spectrum/blob/c5427ecd276ea72d2150654737c35fe336d2d8e5/packages/%40react-stately/collections/src/Item.ts
MenuLink.getCollectionNode = function* getCollectionNode<T, C extends LinkLike>(
  props: MenuLinkProps<T, C>
): Generator<PartialNode<T>> {
  let {
    childItems,
    title,
    children,
    component: Component = 'a',
    ...rest
  } = props;

  let rendered = props.title || props.children;
  let textValue =
    props.textValue ||
    (typeof rendered === 'string' ? rendered : '') ||
    props['aria-label'] ||
    '';

  const { textValue: _textValue, ...menuLinkProps } = rest;

  yield {
    type: 'item',
    props: {
      ...props,
      className: 'hlx-menu-item-link',
      isLink: true,
    },
    rendered: (
      <Component role="menuitem" tabIndex="-1" {...menuLinkProps}>
        {rendered}
      </Component>
    ),
    textValue,
    'aria-label': props['aria-label'],
    hasChildNodes: hasChildItems(props),
    *childNodes() {
      if (childItems) {
        for (let child of childItems) {
          yield {
            type: 'item',
            value: child,
          };
        }
      } else if (title) {
        let items: PartialNode<T>[] = [];
        React.Children.forEach(children, (child) => {
          items.push({
            type: 'item',
            element: child as ItemElement<T>,
          });
        });

        yield* items;
      }
    },
  };
};

function hasChildItems<T, C extends LinkLike>(props: MenuLinkProps<T, C>) {
  if (props.hasChildItems != null) {
    return props.hasChildItems;
  }

  if (props.childItems) {
    return true;
  }

  if (props.title && React.Children.count(props.children) > 0) {
    return true;
  }

  return false;
}

interface MenuContextValue extends React.HTMLAttributes<HTMLElement> {
  onClose?: () => void;
  closeOnSelect?: boolean;
  autoFocus?: boolean;
  ref?: React.RefObject<HTMLUListElement>;
}

export const MenuContext = React.createContext<MenuContextValue>({});

export function useMenuContext(): MenuContextValue {
  return React.useContext(MenuContext);
}

//////////////////////////
// STYLES
//////////////////////////

type MenuWidth = NonNullable<HelixMenuTriggerProps['menuWidth']>;
const menuWidthStyle: Record<MenuWidth, React.CSSProperties['width']> = {
  small: 160,
  medium: 240,
  stretch: '100%',
};

export { MenuTrigger, Menu, Item as MenuItem, Section, MenuLink };
