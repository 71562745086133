import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAssessmentCountByTypeQueryKeyArgs {
  providerPatientId: number | undefined;
}

export const getUsePatientAssessmentCountByTypeQueryKey = ({
  providerPatientId,
}: UsePatientAssessmentCountByTypeQueryKeyArgs) =>
  ['patient-assessment-count-by-type', providerPatientId] as const;

const {
  useSingleQuery: usePatientAssessmentCountByType,
  useListQuery: usePatientAssessmentCountByTypeList,
  useCachedQuery: usePatientAssessmentCountByTypeCache,
} = createBasicApiHooks(
  getUsePatientAssessmentCountByTypeQueryKey,
  ({ providerPatientId }) =>
    PatientAssessmentApi.getPatientAssessmentCountByType(providerPatientId!),
  ({ providerPatientId }) => providerPatientId != null,
  () => 'Failed to fetch patient assessment counts'
);

export {
  usePatientAssessmentCountByType,
  usePatientAssessmentCountByTypeList,
  usePatientAssessmentCountByTypeCache,
};
