import { ProviderCredentialApi } from '@headway/api/resources/ProviderCredentialApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderCredentialsQueryKeyArgs {
  providerId: number;
}
export const getUseProviderCredentialsQueryKey = ({
  providerId,
}: UseProviderCredentialsQueryKeyArgs): [string, number | undefined] => [
  'provider-credentials',
  providerId,
];

const {
  useSingleQuery: useProviderCredentials,
  useCachedQuery: useProviderCredentialsCache,
} = createBasicApiHooks(
  getUseProviderCredentialsQueryKey,
  ({ providerId }) => ProviderCredentialApi.getProviderLicenses(providerId),
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider document requests'
);

export { useProviderCredentials, useProviderCredentialsCache };
