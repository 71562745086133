import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { ListHeader } from '@headway/helix/ListHeader';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { toasts } from '@headway/helix/Toast';
import { getUseUserQueryKey } from '@headway/shared/hooks/useUser';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { usePatientAssessments } from '@headway/ui/hooks/usePatientAssessments';

type NqfConsentFormValues = {
  confirmation: string;
};

export enum NqfConsentFormMode {
  EDIT = 'EDIT',
  SUBMITTED = 'SUBMITTED',
  PREVIEW = 'PREVIEW',
}
interface NqfConsentFormProps {
  // Client associated with the assessment.
  clientId: number;
  // Callback to continue from consent form without submitting.
  onDismiss?: () => void;
  // Number of steps in assessment flow including NQF consent form and unique assessment count.
  numAssessmentFlowSteps?: number;
  // Mode of the consent form.
  mode?: NqfConsentFormMode;
  // Side effect to update user agreements.
  onSubmit?: (updatedUser: UserRead) => void;
  // Provider patient associated with the consent form.
  providerPatientId?: number;
  // Determines if get patient assessments is called with provider patient id.
  isSigmund?: boolean;
}

/**
 * Displays patient consent form for NQF Aligned Innovation study.
 */
export const NqfConsentForm = ({
  clientId,
  onDismiss,
  numAssessmentFlowSteps = 0,
  mode = NqfConsentFormMode.EDIT,
  onSubmit = () => {},
  providerPatientId,
  isSigmund,
}: NqfConsentFormProps) => {
  const queryClient = useQueryClient();

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const patientAssessmentFilters = isSigmund
    ? { provider_patient_id: providerPatientId, include_completed: false }
    : { patient_id: clientId, include_completed: false };
  const { data: assessmentIdList, isLoading: assessmentIdListIsLoading } =
    usePatientAssessments(patientAssessmentFilters, {
      select: ({ data }) => data.map((assessment) => assessment.id),
    });

  const updateNqfConsentMutation = useMutation(
    async () => {
      const updatedUser = await UserApi.updateUser(clientId, {
        consentedToNqfOn: new Date().toISOString(),
      });
      return updatedUser;
    },
    {
      onSuccess: (updatedUser) => {
        toasts.add('Your consent has been recorded', {
          variant: 'positive',
        });
        queryClient.invalidateQueries({
          queryKey: getUseUserQueryKey({ userId: clientId }),
        });
        onSubmit(updatedUser);
      },

      onError: () => {
        toasts.add('There was a problem updating your information', {
          variant: 'negative',
        });
      },
    }
  );

  const handleSubmit = async () => {
    updateNqfConsentMutation.mutateAsync();
    onDismiss?.();
    trackEvent({
      name: 'NQF Assessment Consent Form Continue Button Clicked',
      properties: {
        patientUserId: clientId,
        assessmentIdList: assessmentIdList || [],
      },
    });
  };

  const handleSkip = () => {
    onDismiss?.();
    trackEvent({
      name: 'NQF Assessment Consent Form Skip Button Clicked',
      properties: {
        patientUserId: clientId,
        assessmentIdList: assessmentIdList || [],
      },
    });
  };

  const nqfConsentFormSchema = Yup.object().shape({
    confirmation: Yup.boolean()
      .oneOf(
        [true],
        'Please click the checkbox to consent or click “Skip” below if you would not like to participate in this study.'
      )
      .required(),
  });

  // If submitted mode, render Checkbox with disabled true, checked true. If previewing, disabled. Else use default checkbox to allow editing.
  const ConsentCheckbox = () => {
    const CHECKBOX_TEXT = (
      <>
        <div className="flex flex-col">
          <BodyText>
            <strong>
              By clicking the checkbox you confirm that you have read and
              understood the information provided in this consent form.
            </strong>
          </BodyText>
          <BodyText>
            You agree to participate in the Aligned Innovation study as
            described, and you understand that you can withdraw from the study
            at any time.
          </BodyText>
        </div>
      </>
    );
    if (mode === NqfConsentFormMode.PREVIEW) {
      return (
        <FormControl component={Checkbox} name="confirmation" disabled>
          {CHECKBOX_TEXT}
        </FormControl>
      );
    } else if (mode === NqfConsentFormMode.SUBMITTED) {
      return (
        <FormControl component={Checkbox} name="confirmation" checked disabled>
          {CHECKBOX_TEXT}
        </FormControl>
      );
    } else {
      return (
        <FormControl component={Checkbox} name="confirmation">
          {CHECKBOX_TEXT}
        </FormControl>
      );
    }
  };

  useEffect(() => {
    if (isFirstRender && !assessmentIdListIsLoading) {
      setIsFirstRender(false);
      trackPageView({
        name: 'NQF Assessment Consent Form Viewed',
        properties: {
          patientUserId: clientId,
          assessmentIdList: assessmentIdList || [],
        },
      });
    }
  }, [isFirstRender, assessmentIdListIsLoading, clientId, assessmentIdList]);

  return (
    <Formik<NqfConsentFormValues>
      onSubmit={() => handleSubmit()}
      validationSchema={nqfConsentFormSchema}
      initialValues={{
        confirmation: '',
      }}
      initialErrors={{
        confirmation:
          'Please click the checkbox to consent or click “Skip” below if you would not like to participate in this study.',
      }}
    >
      <>
        <div className="mx-auto my-0 flex w-[690px] max-w-full flex-col gap-6 px-4 py-10">
          <h1 className="flex items-baseline gap-1">
            <SectionHeader>
              Consent to Participate in the Aligned Innovation Study with
              Headway
            </SectionHeader>

            {!!numAssessmentFlowSteps && (
              <BodyText>1 of {numAssessmentFlowSteps}</BodyText>
            )}
          </h1>
          <Form id="nqf-consent-form">
            <section className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <ListHeader>Study Title</ListHeader>
                <BodyText>
                  Aligned Innovation Initiative for Behavioral Health Quality
                  Measurement
                </BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Sponsor</ListHeader>
                <BodyText>National Quality Forum (NQF)</BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Purpose of the Study</ListHeader>
                <BodyText>
                  This study aims to develop a new tool to measure and track
                  symptoms of anxiety and depression to improve healthcare
                  quality and patient outcomes. By gathering information from
                  clients like you, we hope to create a measure that is
                  practical and beneficial for both clinicians and patients.
                </BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Description of Participation</ListHeader>
                <BodyText>
                  If you choose to participate in this study, you will be asked
                  to complete short assessments about your symptoms of anxiety
                  and/or depression as well as functional impairment. The
                  assessments include questions about your feelings, mood, and
                  how your symptoms affect your daily life. It should take about
                  10 minutes to complete, and you will receive it via your
                  Headway account.
                </BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Confidentiality and Privacy</ListHeader>
                <BodyText>
                  Your privacy is important to us. The information you provide
                  will be kept confidential and will not include personally
                  identifiable information such as your name or address. The
                  data collected will be stored securely and used only for the
                  purpose of this study. Your survey responses will be combined
                  with others' responses to create a summary that helps us
                  improve our understanding of anxiety and depression symptoms.
                </BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Risks and Benefits</ListHeader>
                <BodyText>
                  The risks associated with this study are minimal. The
                  questions may prompt you to reflect on your symptoms, which
                  might cause mild discomfort. If you experience distress or
                  need support, please contact your healthcare provider or a
                  mental health professional.
                </BodyText>
                <BodyText>
                  The benefits of participating include contributing to the
                  development of a new tool to help improve the quality of care
                  for patients with anxiety and depression. Your participation
                  can also help your healthcare provider better understand your
                  symptoms and tailor your treatment.
                </BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Voluntary Participation</ListHeader>
                <BodyText>
                  Your participation in this study is entirely voluntary. You
                  may choose to withdraw from the study at any time without
                  affecting your healthcare or relationship with your provider.
                  If you decide to withdraw, you can do so without any
                  consequences.
                </BodyText>
              </div>
              <div className="flex flex-col gap-2">
                <ListHeader>Contacts for Questions or Concerns</ListHeader>
                <BodyText>
                  If you have any questions about the study, or if you would
                  like to withdraw your consent, please contact your healthcare
                  provider. 
                </BodyText>
              </div>
              <ConsentCheckbox />
            </section>
          </Form>
        </div>
        {mode === NqfConsentFormMode.EDIT && (
          <div className="border-t-system-borderGray flex flex-row-reverse items-end gap-4 border-t p-5">
            <Button
              type="submit"
              form="nqf-consent-form"
              variant="primary"
              disabled={updateNqfConsentMutation.isLoading}
            >
              Continue
            </Button>
            <Button variant="secondary" onPress={handleSkip}>
              Skip
            </Button>
          </div>
        )}
      </>
    </Formik>
  );
};

interface NqfConsentFormModalProps {
  // Client associated with the assessment.
  clientId: number;
  // Determines if modal should be active.
  isOpen: boolean;
  // Callback to continue from consent form without submitting.
  onDismiss?: () => void;
  // Mode of the consent form.
  mode?: NqfConsentFormMode;
  // Side effect to update user agreement form row on submission.
  onSubmit?: (updatedUser: UserRead) => void;
  // Provider patient associated with the consent form.
  providerPatientId?: number;
  // Determines if get patient assessments is called with provider patient id.
  isSigmund?: boolean;
}

/**
 * Displays patient consent form for NQF Aligned Innovation study in a modal.
 */
export const NqfConsentFormModal = ({
  clientId,
  isOpen,
  onDismiss,
  mode = NqfConsentFormMode.EDIT,
  onSubmit = () => {},
  providerPatientId,
  isSigmund = false,
}: NqfConsentFormModalProps) => {
  return (
    <Modal
      variant="fullscreen"
      isOpen={isOpen}
      onDismiss={onDismiss}
      title="NQF Research Study"
    >
      <ModalContent>
        <NqfConsentForm
          clientId={clientId}
          onDismiss={onDismiss}
          numAssessmentFlowSteps={0}
          mode={mode}
          onSubmit={onSubmit}
          providerPatientId={providerPatientId}
          isSigmund={isSigmund}
        />
      </ModalContent>
    </Modal>
  );
};
