import React from 'react';

import { IconError } from './icons/Error';
import { IconInfo } from './icons/Info';
import { IconInsight } from './icons/Insight';
import { IconShield } from './icons/Shield';

export interface GuidanceCardCommonProps {
  children: React.ReactNode;
  layout?: 'horizontal' | 'vertical';
  dataTestId?: string;
}

export interface BaseGuidanceCardProps extends GuidanceCardCommonProps {
  variant: 'info' | 'neutral' | 'positive';
  icon?: React.ReactNode;
}
export interface AlertGuidanceCardProps extends GuidanceCardCommonProps {
  variant: 'warning' | 'error';
  title?: never;
  icon?: never;
}

export interface SpecialityGuidanceCardProps extends GuidanceCardCommonProps {
  variant: 'insight' | 'compliance';
  title?: React.ReactNode;
}

export type GuidanceCardProps =
  | BaseGuidanceCardProps
  | AlertGuidanceCardProps
  | SpecialityGuidanceCardProps;

function GuidanceCard(props: GuidanceCardProps) {
  const verticalVariants = ['insight', 'compliance'];
  const defaultLayout = verticalVariants.includes(props.variant)
    ? 'vertical'
    : 'horizontal';
  const { layout = defaultLayout } = props;
  const Icon = iconByVariant[props.variant];
  return (
    <div
      className="hlx-guidance-card"
      data-hlx-layout={layout}
      data-hlx-variant={props.variant}
      data-testid={props.dataTestId}
    >
      {'icon' in props && props.icon ? (
        <div className="hlx-guidance-card-icon" aria-hidden={true}>
          {props.icon}
        </div>
      ) : (
        <Icon className="hlx-guidance-card-icon" aria-hidden={true} />
      )}
      <div className="hlx-guidance-card-layout">
        {'title' in props && props.title && (
          <span className="hlx-guidance-card-title">{props.title}</span>
        )}
        <span className="hlx-guidance-card-content">{props.children}</span>
      </div>
    </div>
  );
}

const iconByVariant = {
  info: IconInfo,
  warning: IconError,
  error: IconError,
  neutral: IconInfo,
  positive: IconInfo,
  compliance: IconShield,
  insight: IconInsight,
} as const;

export { GuidanceCard };
