/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { EligibilityLookupNested } from '../models/EligibilityLookupNested';
import { EligibilityLookupRead } from '../models/EligibilityLookupRead';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { LookupSource } from '../models/LookupSource';
import { PriceChangeDetailsResponse } from '../models/PriceChangeDetailsResponse';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class EligibilityLookupApiResource {

    /**
     * Background Refresh Eligibility Lookups
     * 
     
     */
    public backgroundRefreshEligibilityLookups(axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/internal-webhooks/background-lookup-refresh';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Move Patients To Previous Lookup
     * 
     * @param requestBody 
     
     */
    public bulkMovePatientsToPreviousLookup(body: Array<number>, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/eligibility-lookups/bulk-move-to-patients-previous-lookup';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Refresh Eligibility Lookups
     * Bulk refresh lookups for these patient users
     * @param requestBody 
     * @param hardRefresh 
     * @param suppressComms 
     
     */
    public bulkRefreshEligibilityLookups(body: Array<number>, query?: { hard_refresh?: boolean, suppress_comms?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/eligibility-lookups/bulk-refresh';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Eligibility Lookup
     * 
     * @param eligibilityLookupId 
     
     */
    public getEligibilityLookup(eligibilityLookupId: number, axiosConfig?: AxiosRequestConfig): Promise<EligibilityLookupRead> {
        const apiRoute = '/eligibility-lookup/{eligibility_lookup_id}';
        const reqPath = apiRoute
                    .replace('{' + 'eligibility_lookup_id}', String(eligibilityLookupId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Eligibility Lookups
     * 
     * @param userId 
     * @param userInsuranceId 
     
     */
    public getEligibilityLookups(query?: { user_id?: number, user_insurance_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<EligibilityLookupRead>> {
        const apiRoute = '/eligibility-lookups';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Eligibility Lookups Low Data
     * 
     * @param eligibilityLookupIds 
     
     */
    public getEligibilityLookupsLowData(query?: { eligibility_lookup_ids?: Array<number> }, axiosConfig?: AxiosRequestConfig): Promise<Array<EligibilityLookupNested>> {
        const apiRoute = '/eligibility-lookup/get-low-data-lookups';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Most Recent Price Change Details
     * 
     * @param userId 
     * @param userInsuranceId 
     
     */
    public getMostRecentPriceChangeDetails(userId: number, userInsuranceId: number, axiosConfig?: AxiosRequestConfig): Promise<PriceChangeDetailsResponse> {
        const apiRoute = '/eligibility-lookup/price-change-details/user/{user_id}/insurance_id/{user_insurance_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId))
                    .replace('{' + 'user_insurance_id}', String(userInsuranceId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Manually Stabilize Lookups For Users With Upcoming Appointments
     * 
     
     */
    public manuallyStabilizeLookupsForUsersWithUpcomingAppointments(axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/internal-webhooks/stabilize-lookups';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Refresh User Eligibility Lookup
     * 
     * @param userId 
     * @param userInsuranceId 
     * @param lookupSource 
     * @param hardRefresh 
     * @param suppressComms 
     
     */
    public refreshUserEligibilityLookup(userId: number, userInsuranceId: number, query?: { hard_refresh?: boolean, lookup_source: LookupSource, suppress_comms?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<EligibilityLookupRead> {
        const apiRoute = '/eligibility-lookup/refresh/user/{user_id}/insurance_id/{user_insurance_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId))
                    .replace('{' + 'user_insurance_id}', String(userInsuranceId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const EligibilityLookupApi = new EligibilityLookupApiResource();
