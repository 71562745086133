import React from 'react';

export function IconSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.16 14.315l-.215.338 4.056 4.055-1.043 1.043-4.1-4.099-.35.302a5.044 5.044 0 01-1.726.987 6.37 6.37 0 01-1.991.309c-1.814 0-3.363-.63-4.673-1.9-1.303-1.265-1.952-2.815-1.952-4.684 0-1.838.647-3.367 1.95-4.618 1.31-1.258 2.86-1.881 4.675-1.881s3.333.623 4.584 1.874c1.25 1.25 1.874 2.78 1.874 4.625 0 .677-.09 1.309-.27 1.897a7.125 7.125 0 01-.818 1.752zm-8.985.005c.998.952 2.213 1.43 3.616 1.43s2.597-.479 3.544-1.441c.95-.965 1.414-2.193 1.414-3.643 0-1.425-.467-2.632-1.417-3.582-.948-.948-2.14-1.417-3.541-1.417-1.4 0-2.614.468-3.613 1.406-1.01.947-1.512 2.158-1.512 3.593 0 1.46.5 2.691 1.51 3.654z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
