/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* BE WARNED - DO NOT REMOVE VALUES FORM THIS ENUM if you choose to disregard, then ensure that you implement a _missing_ function https://docs.python.org/3/library/enum.html#enum.Enum._missing_ */    /**
    * BE WARNED - DO NOT REMOVE VALUES FORM THIS ENUM if you choose to disregard, then ensure that you implement a _missing_ function https://docs.python.org/3/library/enum.html#enum.Enum._missing_
    */
export enum LookupStatus {
    SUCCESSFUL = 'SUCCESSFUL',
    PENDING = 'PENDING',
    MISSING_INFORMATION = 'MISSING_INFORMATION',
    LOOKUP_PARSING_ERROR = 'LOOKUP_PARSING_ERROR',
    PAUSING_LOOKUP_LOGIC = 'PAUSING_LOOKUP_LOGIC',
    INVALID_INSURANCE_ERROR = 'INVALID_INSURANCE_ERROR',
    NO_PAYER_CONNECTION_AVAILABLE = 'NO_PAYER_CONNECTION_AVAILABLE',
    NO_CALLABLE_NPI = 'NO_CALLABLE_NPI',
    API_EXCEPTION = 'API_EXCEPTION',
    INSUFFICIENT_OR_INCORRECT_INFORMATION = 'INSUFFICIENT_OR_INCORRECT_INFORMATION',
    CHANGE_SYSTEM_EXCEPTION = 'CHANGE_SYSTEM_EXCEPTION',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    PRELIMINARY_REFRESH_INACTIVE = 'PRELIMINARY_REFRESH_INACTIVE',
    PRELIMINARY_FIRST_OF_THE_MONTH_REFRESH_INACTIVE = 'PRELIMINARY_FIRST_OF_THE_MONTH_REFRESH_INACTIVE',
    MANUAL_ENTRY = 'MANUAL_ENTRY',
    UNSTABLE_INVALIDATED = 'UNSTABLE_INVALIDATED',
    AETNA_HMO_AFFECTED = 'AETNA_HMO_AFFECTED',
    FLAKEY_ACTIVE_BUT_NO_INN_BENEFITS = 'FLAKEY_ACTIVE_BUT_NO_INN_BENEFITS',
    FLAKEY_INN_BENEFITS_BUT_NOT_ACTIVE = 'FLAKEY_INN_BENEFITS_BUT_NOT_ACTIVE',
    FLAKEY_NO_ACTIVE_OR_INACTIVE_COVERAGE_ELEMENTS = 'FLAKEY_NO_ACTIVE_OR_INACTIVE_COVERAGE_ELEMENTS'
}
