import { Card, Skeleton } from '@mui/material';
import moment from 'moment-timezone';
import { useMemo } from 'react';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { BodyText } from '@headway/helix/BodyText';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { useMatchingProviderFrontendCarriersForEvents } from 'hooks/useMatchingProviderFrontendCarriersForEvents';
import { useProvider } from 'hooks/useProvider';

import { AutoPayPCIBanner } from './AutoPayPCIBanner';
import { UpcomingAppointmentCard } from './UpcomingAppointmentCard';

export const UpcomingAppointments = () => {
  const provider = useProvider();

  const [dateRangeStart, dateRangeEnd] = useMemo(
    () => [moment().toISOString(), moment().add(1, 'M').toISOString()],
    [provider.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    data: upcomingAppointmentsExpanded,
    isLoading: isFindProviderEventsLoading,
  } = useFindProviderEvents(
    {
      expand: true,
      event_types: [ProviderEventType.APPOINTMENT],
      appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
      provider_id: provider.id,
      date_range_start: dateRangeStart,
      date_range_end: dateRangeEnd,
      expand_estimated_prices: false,
      order_by: 'start_date',
      order: 'asc',
      limit: 3,
    },
    {
      select: ({ data }) => data,
      refetchOnWindowFocus: false,
    }
  );

  const {
    eventIdsToMatchingProviderFrontEndCarriers,
    isLoading: isProviderFrontendCarriersLoading,
  } = useMatchingProviderFrontendCarriersForEvents(
    provider.id,
    upcomingAppointmentsExpanded ?? []
  );

  const isLoading =
    isFindProviderEventsLoading || isProviderFrontendCarriersLoading;

  const { earliestActiveLiveOn } = provider;
  const isProviderCredentialed = earliestActiveLiveOn
    ? moment(earliestActiveLiveOn) < moment()
    : false;

  return (
    <PageSection>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <SectionHeader>Upcoming Sessions</SectionHeader>
        {!!upcomingAppointmentsExpanded?.length && isProviderCredentialed && (
          <div>
            <LinkButton href={'/calendar'} variant="link">
              All sessions
            </LinkButton>
          </div>
        )}
      </div>
      {!isProviderCredentialed ? (
        <NotCredentialedView />
      ) : isLoading ? (
        <Skeleton variant="rectangular" height={230} />
      ) : !upcomingAppointmentsExpanded?.length ? (
        <NoUpcomingAppointments />
      ) : (
        <>
          <AutoPayPCIBanner />
          <div
            className="grid grid-cols-1 gap-4 tablet:grid-cols-3"
            data-testid="upcomingAppointments"
          >
            {upcomingAppointmentsExpanded.map((appointment) => {
              const isAnthemEAPAppointment =
                appointment.id &&
                eventIdsToMatchingProviderFrontEndCarriers.get(appointment.id)
                  ?.frontEndCarrierId === FrontEndCarrierIdentifier.ANTHEM_EAP;
              return (
                <div key={appointment.virtualId} className="mb-1">
                  <Card variant="outlined" css={{ height: '100%' }}>
                    <UpcomingAppointmentCard
                      upcomingAppointment={appointment}
                      infoBadgeText={
                        isAnthemEAPAppointment ? 'Anthem' : undefined
                      }
                    />
                  </Card>
                </div>
              );
            })}
          </div>
        </>
      )}
    </PageSection>
  );
};

const NotCredentialedView = () => {
  return (
    <div
      css={{
        padding: theme.spacing.x2,
      }}
      data-testid="nothingForYou"
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: theme.spacing.x3,
          marginTop: theme.spacing.x6,
        }}
      >
        <span
          css={{
            ...theme.typography.list,
            marginBottom: theme.spacing.x2,
          }}
        >
          None yet!
        </span>
        <p
          css={{
            color: theme.color.system.textBlack,
            width: '60%',
            textAlign: 'center',
          }}
        >
          As soon as you're credentialed, we'll publish your profile so you can
          receive referrals.
        </p>
      </div>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <LinkButton variant="brand" size="medium" href={`/insurance-status`}>
          Check My Progress
        </LinkButton>
      </div>
    </div>
  );
};

const NoUpcomingAppointments = () => {
  return (
    <div
      css={{
        padding: theme.spacing.x6,
      }}
      data-testid="noAppointments"
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          padding: theme.spacing.x8,
          color: theme.color.system.textBlack,
        }}
      >
        <span
          css={{
            marginBottom: theme.spacing.x2,
          }}
        >
          <BodyText> No upcoming sessions this month.</BodyText>
        </span>
        <span
          css={{
            marginBottom: theme.spacing.x2,
          }}
        >
          <Link
            href="https://findheadway.zendesk.com/hc/en-us/articles/5323188807060"
            target="_blank"
            rel="noopener noreferrer"
          >
            Promote your practice
          </Link>{' '}
          externally to spread the word to prospective clients.
        </span>

        <BodyText>
          Want some help?{' '}
          <Link
            href="https://headway6.typeform.com/to/pXNml7m5"
            target="_blank"
            rel="noopener noreferrer"
          >
            Share your marketing pages and links with us for feedback here.
          </Link>
        </BodyText>
      </div>
    </div>
  );
};
