import { useMemo } from 'react';

import { IroncladApi } from '@headway/api/resources/IroncladApi';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import { getIroncladSignerId } from 'components/IroncladAmendmentsModal/helpers/utils';
import { useAuthStore } from 'stores/AuthStore';

export const useLastSignedIroncladAgreement = () => {
  const shouldUseIroncladAgreements = useFlag('ironcladProviderAgreements');
  const { user, provider } = useAuthStore();
  const signerId = getIroncladSignerId(user);

  const {
    isLoading,
    isError,
    data: ironcladAgreementRes,
  } = useQuery(
    ['ironcladAgreementHtml', signerId],
    async () => {
      const res = await IroncladApi.getUserLastSignedVersionData({
        signer_id: signerId,
        provider_id: provider.id,
      });

      if (res.status === 404) {
        throw new Error(`Latest signed version data wasn't found in Ironclad`);
      }

      return res;
    },
    {
      enabled: shouldUseIroncladAgreements,
      refetchOnWindowFocus: false,
      onError: (e) => {
        logException(e);
      },
    }
  );

  const ironcladAgreement = useMemo(() => {
    if (ironcladAgreementRes && ironcladAgreementRes.length > 0) {
      const agreementToNameMap: { [key: string]: string } = {};
      for (let i = 0; i < ironcladAgreementRes.length; i++) {
        agreementToNameMap[ironcladAgreementRes[i].title] =
          ironcladAgreementRes[i].body;
      }

      return agreementToNameMap;
    }
  }, [ironcladAgreementRes]);

  return {
    isLoading,
    isError,
    ironcladAgreement,
  };
};
