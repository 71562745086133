/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* These values map to the tag values for the Payments & Bonuses Secondary Issue Type zendesk field */    /**
    * These values map to the tag values for the Payments & Bonuses Secondary Issue Type zendesk field
    */
export enum PaymentsBonusesSecondaryIssueType {
    BONUS_PAYOUTS = 'bonus_programs___payouts',
    SESSION_PAYOUTS = 'session_payouts',
    SETTING_UP_DIRECT_DEPOSIT = 'setting_up_direct_deposit',
    TAXES = 'taxes'
}
