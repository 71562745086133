import * as React from 'react';

export const ListBulleted = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.604 16.167a1 1 0 01-.719-.292.948.948 0 01-.302-.708c0-.264.101-.5.302-.709a.966.966 0 01.72-.312c.277 0 .513.1.708.302a1 1 0 01.291.719.983.983 0 01-1 1zm3.125-.417v-1.188h10.709v1.188H6.729zM3.604 11a.999.999 0 01-.719-.292.972.972 0 01-.302-.729c0-.264.101-.496.302-.698a.982.982 0 01.72-.302c.277 0 .513.1.708.302a.975.975 0 01.291.698 1 1 0 01-.292.719.948.948 0 01-.708.302zm3.125-.417V9.396h10.709v1.187H6.729zm-3.146-4.75a.964.964 0 01-.708-.291.99.99 0 01-.292-.73c0-.263.098-.496.292-.697a.972.972 0 01.73-.303c.277 0 .513.101.708.303a1 1 0 01.291.718.964.964 0 01-.292.709.99.99 0 01-.729.291zm3.146-.416V4.229h10.709v1.188H6.729z"
      fill="currentColor"
    />
  </svg>
);
