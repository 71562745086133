import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback, useState } from 'react';

import { ComboBox, Item } from '@headway/helix/ComboBox';
import { validity } from '@headway/helix/FormControl';

import { SchemaComponent } from '.';
import { hasValue } from './ComboBox';
import { OtherTextField } from './OtherTextField';

export const FormSelect = ({
  element,
  isOptional = false,
  disabled = false,
  template,
}: SchemaComponent) => {
  const { id, title, options, placeholder, subTitle } = element;
  const formik = useFormikContext();

  const selectOptions = Array.from(options as string[]).map((option) => {
    return { key: option };
  });

  const selectedKey = get(formik.values, id);

  const setFieldValue = formik.setFieldValue;
  const [showOtherFieldInput, setShowOtherFieldInput] = useState(
    selectedKey ? selectedKey.toLowerCase().includes('other') : false
  );

  const onSelectionChange = useCallback(
    (value: Set<string>) => {
      if (!disabled) {
        setShowOtherFieldInput(hasValue(value, 'Other'));
        setFieldValue(id, Array.from(value)[0]);
      }
    },
    [setFieldValue, id, disabled]
  );

  let selectedKeys: string[] = [];

  if (showOtherFieldInput) {
    selectedKeys = ['Other'];
  } else {
    selectedKeys = selectedKey ? [selectedKey] : [];
  }

  return (
    <>
      <ComboBox
        name={id}
        label={title}
        items={selectOptions}
        selectedKeys={selectedKeys}
        selectionMode="single"
        menuWidth="stretch"
        validation={validity(id, formik)}
        onSelectionChange={onSelectionChange}
        optionalityText={isOptional ? 'Optional' : ''}
        disabled={disabled}
        placeholder={placeholder}
        instructionalText={subTitle}
      >
        {(item: { key: string }) => <Item>{item.key}</Item>}
      </ComboBox>
      {showOtherFieldInput && (
        <OtherTextField
          id={id}
          optionType="single"
          option={'Other'}
          template={template}
          disabled={disabled}
        />
      )}
    </>
  );
};
