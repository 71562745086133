import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAssessmentScoresQueryKeyArgs {
  providerPatientId: number | undefined;
  assessmentType: PatientAssessmentType | undefined;
}

export const getUsePatientAssessmentScoresQueryKey = ({
  providerPatientId,
  assessmentType,
}: UsePatientAssessmentScoresQueryKeyArgs) =>
  ['patient-assessment-scores', providerPatientId, assessmentType] as const;

const {
  useSingleQuery: usePatientAssessmentScores,
  useListQuery: usePatientAssessmentScoresList,
  useCachedQuery: usePatientAssessmentScoresCache,
} = createBasicApiHooks(
  getUsePatientAssessmentScoresQueryKey,
  ({ providerPatientId, assessmentType }) =>
    PatientAssessmentApi.getPatientAssessmentScores(providerPatientId!, {
      assessment_type: assessmentType!,
    }),
  ({
    providerPatientId,
    assessmentType,
  }: UsePatientAssessmentScoresQueryKeyArgs) =>
    providerPatientId != null && assessmentType != null,
  () => 'Failed to fetch patient assessment scores'
);

export {
  usePatientAssessmentScores,
  usePatientAssessmentScoresList,
  usePatientAssessmentScoresCache,
};
