import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryParams<T>(): T {
  const { search } = useLocation();

  return useMemo(() => {
    const parsedParams = queryString.parse(search) as unknown;
    return parsedParams as T;
  }, [search]);
}
