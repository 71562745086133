export function convertListToSentence(
  stringList: string[] | undefined,
  conjunction: string = 'and'
) {
  if (!stringList || !stringList.length) {
    return '';
  }

  if (stringList.length === 1) {
    return stringList[0];
  }

  if (stringList.length === 2) {
    return `${stringList[0]} ${conjunction} ${stringList[1]}`;
  }

  return `${stringList
    .slice(0, stringList.length - 1)
    .join(', ')}, ${conjunction} ${stringList.slice(-1)}`;
}

/**
 * Removes any HTML tags that aren't encoded and decodes encoded HTML tags
 */
export function getContentOfHtml(html: string) {
  return (
    new DOMParser().parseFromString(html, 'text/html').documentElement
      .textContent || ''
  );
}

/**
 * Applies formatting to 10-digit phone numbers to match the PHONE_MASK we use
 * in Formik/yup: (888) 123-0000. If the provided number is not valid, returns
 * an empty string.
 * @param phone The 10-digit phone number you want to mask. This can be in any
 * format--we'll only use the numbers
 */
export const maskPhoneNumber = (phone: string) => {
  const digits = phone.replace(/\D/g, '');
  return digits.length === 10
    ? `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`
    : '';
};
