import React from 'react';

export function IconDocument(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.64587 14.7917H13.3542V13.5417H6.64587V14.7917ZM6.64587 11.25H13.3542V9.99999H6.64587V11.25ZM3.33337 18.3333V1.66666H12.1042L16.6667 6.22916V18.3333H3.33337ZM11.4792 6.79166V2.91666H4.58337V17.0833H15.4167V6.79166H11.4792ZM4.58337 2.91666V6.79166V2.91666V17.0833V2.91666Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </svg>
  );
}
