import React from 'react';

import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { theme } from '@headway/helix/theme';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';

import { LoadingState } from '../LoadingState';

interface IroncladAgreementProps {
  ironcladAgreement: any;
  isLoading: boolean;
}

export const IroncladAgreement = ({
  ironcladAgreement,
  isLoading: isLoadingIroncladAgreement,
}: IroncladAgreementProps) => {
  const { ironcladAgreementInfo } = useIroncladAgreementInfo();
  const { groupKey } = ironcladAgreementInfo || {};

  return (
    <div>
      <CollapsibleDisclosure label="Full terms below" defaultExpanded>
        {isLoadingIroncladAgreement || !groupKey ? (
          <LoadingState />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitize(ironcladAgreement.contract_html),
            }}
            css={{
              [`#ps-group-${groupKey}`]: {
                marginTop: '10px',
                padding: '0 30px',
                '& div.ps-contract-title': {
                  display: 'none',
                },
                '& div.ps-contract-options': {
                  display: 'none',
                },
                '& > div.ps-contract': {
                  display: 'block !important',
                  padding: '0',
                },
                '& label.ps-checkbox-container': {
                  display: 'none',
                },
                '& div.ps-contract-body': {
                  border: 'none',
                  padding: '0',
                  '& p, & div': {
                    ...theme.typography.body.regular,
                  },
                },
                '& a': {
                  ...theme.typography.hyperlink,
                  color: theme.color.system.textBlack,
                  '&:hover': {
                    textDecoration: 'none !important',
                  },
                },
              },
            }}
          />
        )}
      </CollapsibleDisclosure>
    </div>
  );
};
