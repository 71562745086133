import { useRef, useState } from 'react';

import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { NumberedList, NumberedListItem } from '@headway/helix/List';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { KAISER_PERMANENTE_NORCAL_IDS } from '@headway/shared/constants/carrierIds';

type InsuranceAuthorizationInstructionsProps = {
  carrier: FrontEndCarrierRead | FrontEndCarrierNested;
  clientDisplayName?: string;
  closeModal: () => void;
};

const InsuranceAuthorizationInstructionsModalContent = ({
  carrier,
  clientDisplayName,
  closeModal,
}: InsuranceAuthorizationInstructionsProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const instructionsRef = useRef<HTMLOListElement>(null);
  const copyInstructions = async () => {
    if (!instructionsRef?.current) {
      return;
    }
    await navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([instructionsRef.current.outerHTML], {
            type: 'text/html',
          }),
          'text/plain': new Blob([instructionsRef.current.innerText], {
            type: 'text/plain',
          }),
        }),
      ])
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 1000);
      });
  };
  return (
    <>
      <ModalContent>
        <div className="gap-4">
          <BodyText>
            In order to see you, {clientDisplayName || 'the patient'} needs to
            get prior authorization from their insurer first. To help, you can
            copy these instructions and share them directly.
          </BodyText>
          <span ref={instructionsRef}>
            <NumberedList>
              {carrier.id &&
                KAISER_PERMANENTE_NORCAL_IDS.includes(carrier.id) && (
                  <NumberedListItem>
                    <BodyText>
                      Visit{' '}
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        href={
                          'https://healthy.kaiserpermanente.org/northern-california/health-wellness/mental-health/services'
                        }
                      >
                        Kaiser’s mental health directory.
                      </Link>
                    </BodyText>
                  </NumberedListItem>
                )}
              <NumberedListItem>
                <BodyText>
                  Find a clinic in your area and give them a call.
                </BodyText>
              </NumberedListItem>
              <NumberedListItem>
                <BodyText>
                  Request a referral to “Headway” for mental health services.
                  They will evaluate your care options and note your preference.
                </BodyText>
              </NumberedListItem>
              <NumberedListItem>
                <BodyText>
                  If you receive the referral, Headway will email you a link to
                  continue booking. Referrals are not guaranteed.
                </BodyText>
              </NumberedListItem>
            </NumberedList>
          </span>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          variant="secondary"
          onPress={copyInstructions}
          disabled={isCopied}
        >
          {isCopied ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
        <Button variant="primary" onPress={closeModal}>
          Done
        </Button>
      </ModalFooter>
    </>
  );
};

const getAuthorizationInstructionsModalTitle = (patientDisplayName: string) => {
  return `How ${patientDisplayName} can request a referral`;
};

export {
  InsuranceAuthorizationInstructionsModalContent,
  getAuthorizationInstructionsModalTitle,
};
