import _ from 'lodash';
import keyBy from 'lodash/keyBy';
import React from 'react';

import { ProviderAddressCreate } from '@headway/api/models/ProviderAddressCreate';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAddressUpdate } from '@headway/api/models/ProviderAddressUpdate';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderAddressApi } from '@headway/api/resources/ProviderAddressApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import statesToDisplayNames, {
  abbreviationToStateEnum,
} from '@headway/shared/constants/unitedStatesDisplayNames';

function createdOnDesc(
  addressA: ProviderAddressRead,
  addressB: ProviderAddressRead
) {
  return (
    new Date(addressB.createdOn!).getTime() -
    new Date(addressA.createdOn!).getTime()
  );
}

interface ProviderAddressProviderProps {
  providerIds: number[];
}

interface ProviderAddressProviderState {
  isLoading: boolean;
  error?: Error;
  providerAddresses: ProviderAddressRead[];
  providerAddressesById: { [key: number]: ProviderAddressRead };
}

export type ProviderAddressContextState = ProviderAddressProviderState & {
  createProviderAddress: (
    address: ProviderAddressCreate
  ) => Promise<ProviderAddressRead>;
  updateProviderAddress: (
    id: number,
    address: ProviderAddressUpdate
  ) => Promise<ProviderAddressRead>;
  reload: () => void;
};

export const ProviderAddressContext =
  React.createContext<ProviderAddressContextState>({
    isLoading: true,
    providerAddresses: [],
    providerAddressesById: {},
    createProviderAddress: () => Promise.reject('Not Implemented'),
    updateProviderAddress: () => Promise.reject('Not Implemented'),
    reload: () => {},
  });

export class ProviderAddressProvider extends React.Component<
  React.PropsWithChildren<ProviderAddressProviderProps>,
  ProviderAddressProviderState
> {
  state: ProviderAddressProviderState = {
    isLoading: true,
    providerAddresses: [],
    providerAddressesById: {},
  };

  componentDidMount() {
    if (this.props.providerIds.length === 0) {
      return;
    }

    this.fetchProviderAddresses();
  }

  componentDidUpdate(prevProps: ProviderAddressProviderProps) {
    if (!_.isEqual(prevProps.providerIds, this.props.providerIds)) {
      if (this.props.providerIds.length === 0) {
        return;
      }

      this.fetchProviderAddresses();
    }
  }

  fetchProviderAddresses = async () => {
    this.setState({ error: undefined, isLoading: true });

    try {
      const providerAddresses =
        await ProviderAddressApi.getAllProviderAddresses({
          provider_ids: this.props.providerIds,
        });

      this.setState({
        providerAddresses,
        providerAddressesById: keyBy(providerAddresses, 'id'),
      });
    } catch (error: any) {
      this.setState({ error });
    }

    this.setState({ isLoading: false });
  };

  updateAddresses = (address: ProviderAddressRead) => {
    const providerAddresses = [
      address,
      ...this.state.providerAddresses.filter((a) => a.id !== address.id),
    ].sort(createdOnDesc);
    const providerAddressesById = keyBy(providerAddresses, 'id');

    this.setState({
      providerAddresses,
      providerAddressesById,
    });
  };

  createProviderAddress = async (create: ProviderAddressCreate) => {
    if (!create.state) {
      throw new Error(`A state is required to process your request`);
    }

    const provider = await ProviderApi.getProvider(create.providerId);
    const state = abbreviationToStateEnum[create.state] as UnitedStates;
    const providerLicenseState = provider.activeProviderLicenseStates.find(
      (pls) => pls.state === state
    );

    if (providerLicenseState) {
      const address = await ProviderAddressApi.createProviderAddress({
        ...create,
        providerLicenseStateId: providerLicenseState.id,
      });
      this.updateAddresses(address);
      return address;
    } else {
      throw new Error(
        `User is not licensed to practice in ${statesToDisplayNames[state]}`
      );
    }
  };

  updateProviderAddress = async (id: number, update: ProviderAddressUpdate) => {
    const address = await ProviderAddressApi.updateProviderAddress(id, update);
    this.updateAddresses(address);
    return address;
  };

  reload = () => {
    this.fetchProviderAddresses();
  };

  render() {
    return (
      <ProviderAddressContext.Provider
        value={{
          ...this.state,
          createProviderAddress: this.createProviderAddress,
          updateProviderAddress: this.updateProviderAddress,
          reload: this.reload,
        }}
      >
        {this.props.children}
      </ProviderAddressContext.Provider>
    );
  }
}
