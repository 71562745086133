import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderAppointmentRead } from '@headway/api/models/ProviderAppointmentRead';
import { ProviderDocumentRequestRead } from '@headway/api/models/ProviderDocumentRequestRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';

import { useProviderDocumentRequests } from 'hooks/useProviderDocumentRequests';

export const useRequestingDocument = (appointmentId: number | undefined) => {
  return useProviderDocumentRequests(
    { providerAppointmentId: appointmentId },
    {
      enabled: !!appointmentId,
      select: (results) => (results.length ? results[0] : undefined),
    }
  );
};

/**
 * Checks whether progress notes should be required for a provider-patient relationship, and also
 * provides the carrier which is requiring the note.
 * If appointmentId is supplied, the hook additionally checks whether there is a document request
 * existing for that appointment.
 * This hook fails closed; if data has not yet been fetched, we assume that progress notes are
 * required until we have enough data to prove otherwise.
 */
export const useDocumentationRequirement = (
  provider: ProviderRead | undefined,
  patient: UserRead | undefined,
  appointment?: ProviderAppointmentRead | undefined
): {
  isRequired: boolean;
  carrier?: FrontEndCarrierRead;
  requestedDocument?: ProviderDocumentRequestRead;
} => {
  const { carriersById, isCarriersLoading } = useFrontEndCarriers();

  const documentationRequirementPilotEnabled = useFlag(
    'documentationRequirementPilot',
    false
  );

  const { data: requestedDocument, isLoading: isDocumentRequestsLoading } =
    useRequestingDocument(appointment?.id);

  if (
    documentationRequirementPilotEnabled &&
    appointment?.billingType === BillingType.SELF_PAY
  ) {
    return {
      isRequired: false,
      carrier: undefined,
      requestedDocument,
    };
  }

  if (!provider || !patient || isDocumentRequestsLoading || isCarriersLoading) {
    return {
      isRequired: true,
      carrier: undefined,
      requestedDocument: undefined,
    };
  }
  if (requestedDocument) {
    // we now have the case where isRequired is true but carrier could be undefined if it's a Headway-initiated audit
    return {
      isRequired: true,
      carrier: requestedDocument.frontEndCarrierId
        ? carriersById[requestedDocument.frontEndCarrierId]
        : undefined,
      requestedDocument,
    };
  }

  if (documentationRequirementPilotEnabled) {
    return {
      isRequired: true,
      carrier: undefined,
      requestedDocument: undefined,
    };
  }

  return {
    isRequired: false,
    carrier: undefined,
    requestedDocument: undefined,
  };
};
