import * as React from 'react';

export const Underline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.787 16.712v-1.068h10.444v1.068H4.787zm5.213-3c-1.262 0-2.322-.425-3.178-1.274-.856-.85-1.285-1.9-1.285-3.15v-6.02h1.444v6.057c0 .838.288 1.538.863 2.1.575.563 1.293.844 2.156.844.875 0 1.6-.281 2.175-.844.575-.563.862-1.263.862-2.1V3.269h1.444v6.018c0 1.25-.431 2.3-1.293 3.15-.863.85-1.926 1.276-3.188 1.276z"
      fill="currentColor"
    />
  </svg>
);
