import { UserRead } from '@headway/api/models/UserRead';

export function hasNoRemainingSessions(
  client: UserRead,
  benefitsMaxEnabled: boolean
): boolean {
  const remainingSessions =
    client.activeUserInsurance?.latestEligibilityLookup
      ?.remainingCoveredSessions;
  return (
    typeof remainingSessions === 'number' &&
    remainingSessions <= 0 &&
    benefitsMaxEnabled
  );
}
