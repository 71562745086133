import React from 'react';

import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { theme } from '@headway/helix/theme';
import { useMediaQuery } from '@headway/helix/utils';

import { SchemaComponent } from '../../schema/schema.types';
import { getValue } from '../../view/utils';
import { ViewComponent } from './ViewComponent';

const MemoizedCheckbox = React.memo(Checkbox);
const MemoizedCheckboxGroup = React.memo(CheckboxGroup);

/**
 * This component is heavily memoized in order to prevent unnecessary rerenders of the child
 * checkbox components on large forms. If making changes to this component, you should validate that
 * there are no performance regressions.
 */
export const Checklist = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, options, style } = element;
  const isBelowTabletMedia = useMediaQuery(theme.__futureMedia.below('tablet'));

  const numberOfColumns = isBelowTabletMedia ? 2 : style?.numberOfColumns || 2;
  const optionsColumns: string[][] = [];

  let newOptions: string[] = [...options];

  for (let i = 0; i < numberOfColumns; i++) {
    optionsColumns.push(
      newOptions.slice(
        i * Math.ceil(newOptions.length / numberOfColumns),
        i * Math.ceil(newOptions.length / numberOfColumns) +
          Math.ceil(newOptions.length / numberOfColumns)
      )
    );
  }

  const columns: any[] = [];

  for (let optionsColumn of optionsColumns) {
    columns.push(
      optionsColumn.map((option: string, i: number) => (
        <MemoizedCheckbox
          key={i}
          value={option || 'My custom option'}
          disabled={disabled}
        >
          {option || 'My custom option'}
        </MemoizedCheckbox>
      ))
    );
  }

  if (readOnly) {
    const values = getValue(id);
    return <ViewComponent title={title} value={(values ?? []).join(', ')} />;
  }

  return (
    <FormControl
      component={MemoizedCheckboxGroup}
      name={id}
      label={title}
      optionalityText={isOptional ? 'Optional' : null}
      disabled={disabled}
    >
      <div
        css={{
          all: 'inherit',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        {columns.map((column, index) => (
          <div
            key={`checkbox-column-${index}`}
            css={{ all: 'inherit', display: 'flex', flexDirection: 'column' }}
          >
            {column}
          </div>
        ))}
      </div>
    </FormControl>
  );
};
