import React from 'react';

export function IconDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0002 13.4792L5.97933 9.45833L6.87516 8.56249L9.37516 11.0625V3.33333H10.6252V11.0625L13.1252 8.56249L14.021 9.45833L10.0002 13.4792ZM3.3335 16.6667V12.4375H4.5835V15.4167H15.4168V12.4375H16.6668V16.6667H3.3335Z"
        fill="currentColor"
      />
    </svg>
  );
}
