import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { formatPatientName } from '@headway/shared/utils/patient';

export const NotCredentialedInAnyPatientStateWarning = ({
  patient,
  noPatientAddressOnFile,
  onClickToReschedule,
  isMSCGuardrailWarning,
  mscGuardrailRestrictionDate,
}: {
  patient: UserRead;
  noPatientAddressOnFile: boolean;
  onClickToReschedule?: () => void;
  isMSCGuardrailWarning: boolean;
  mscGuardrailRestrictionDate?: string;
}) => {
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const buttonHandlerProp = !!onClickToReschedule
    ? { onClick: onClickToReschedule }
    : { href: CREDENTIALING_REQS_LINK, target: '_blank', rel: 'noreferrer' };
  const buttonCtaText = !!onClickToReschedule
    ? 'Update session location'
    : 'Learn more';
  return (
    <GuidanceCard variant="warning" layout="vertical">
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {noPatientAddressOnFile ? (
          <BodyText>
            Heads up, the location your client will join from is required to
            bill this session through insurance.
            {!onClickToReschedule &&
              ' The location must also be in a state where you are credentialed. You can add their location later if you do not know it now.'}
          </BodyText>
        ) : (
          <>
            <BodyText>
              Looks like {patientFirstName} doesn’t have any locations on file
              where you are credentialed to practice on Headway.{' '}
              {isMSCGuardrailWarning
                ? `Starting after ${mscGuardrailRestrictionDate}, we'll block session confirmation if there is a mismatch.`
                : 'Make sure your client is physically located in a state where you are credentialed during your session.'}
              {!onClickToReschedule &&
                ` You can add this location later if you do not know it now.`}
            </BodyText>
          </>
        )}
        <div css={{ marginTop: theme.spacing.x2 }}>
          <LinkButton variant="link" {...buttonHandlerProp}>
            {buttonCtaText}
          </LinkButton>
        </div>
      </div>
    </GuidanceCard>
  );
};
