import moment from 'moment';

import { ProviderBillingAccountRead } from '@headway/api/models/ProviderBillingAccountRead';
import { StripeAccountResponse } from '@headway/api/models/StripeAccountResponse';
import { UnitedStates } from '@headway/api/models/UnitedStates';

export enum TrueFalse {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

// To be removed once all providers have completed their W9s
export function getIndividualW9Deadline(providerState: UnitedStates) {
  // Rollout states TBD
  const firstIndividualW9RolloutState = UnitedStates.MASSACHUSETTS;

  if (providerState === firstIndividualW9RolloutState) {
    return moment('2023-11-15');
  }

  return moment('2023-12-13');
}

export function getRemainingDaysToIndividualW9Deadline(
  providerState: UnitedStates
) {
  const deadline = getIndividualW9Deadline(providerState);
  return deadline.diff(moment().startOf('d'), 'days');
}

export function isPastW9Deadline(providerState: UnitedStates) {
  return getRemainingDaysToIndividualW9Deadline(providerState) < 0;
}

export function shouldShowW9Components(
  groupPracticeId: number | undefined,
  shouldCollectIndividualW9FromGroupFlag: boolean | undefined,
  stripeAccount: StripeAccountResponse | undefined
) {
  if (!stripeAccount) return false;

  const hasNoGroupOrIsValidGroup =
    !groupPracticeId || shouldCollectIndividualW9FromGroupFlag;

  return hasNoGroupOrIsValidGroup && hasUnmetW9StripeRequirement(stripeAccount);
}

export function shouldShowBankAccountComponents(
  billingAccount: ProviderBillingAccountRead | undefined
) {
  if (!billingAccount) return false;

  return hasUnmetBankAccountRequirements(billingAccount);
}

export function hasUnmetStripeAccountRequirements(
  account: StripeAccountResponse | undefined
) {
  if (!account) {
    return false;
  }

  const stripeRequirements = [
    ...account.requirements.currentlyDue,
    ...account.requirements.eventuallyDue,
    ...account.requirements.pastDue,
  ];

  if (stripeRequirements.length === 0) {
    return false;
  } else if (stripeRequirements.every((r) => r === 'external_account')) {
    return false;
  }

  return true;
}

export function hasUnmetW9StripeRequirement(account: StripeAccountResponse) {
  const stripeRequirements = new Set([
    ...account.requirements.currentlyDue,
    ...account.requirements.eventuallyDue,
    ...account.requirements.pastDue,
  ]);

  return (
    stripeRequirements.size === 1 &&
    stripeRequirements.has('us_w8_or_w9_tax_form')
  );
}

export function hasUnmetBankAccountRequirements(
  billingAccount: ProviderBillingAccountRead
) {
  const stripeRequirements = [
    ...billingAccount.stripeAccount.requirements.currentlyDue,
    ...billingAccount.stripeAccount.requirements.eventuallyDue,
    ...billingAccount.stripeAccount.requirements.pastDue,
  ];

  return (
    !billingAccount.bankAccount ||
    stripeRequirements.some((r) => r === 'external_account')
  );
}

export const twoColumnGridCss = {
  width: '100%',
  display: 'grid',
  gap: '20px',
  alignItems: 'start',
  gridTemplateColumns: '1fr',
  '@media (min-width: 768px)': {
    gridTemplateColumns: '1fr 1fr',
    '& .hlx-page-section-subtext': {
      maxWidth: '35ch',
    },
  },
};
