import { css } from '@emotion/react';
import { Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Form as HelixForm } from '@headway/helix/Form';
import { theme } from '@headway/helix/theme';

import {
  BlockDefinitions,
  ComponentMap,
  ComponentMapDeclaration,
  FormFile,
  FormState,
} from '../../schema/schema.types';
import { Component } from '../../schema/schema.types';
import { getBlocks } from '../blocks';
import { blockDefinitions } from '../blocks/BlockDefinitions';
import Paragraph from '../blocks/default/Paragraph';
import Delete from '../layout/Delete';
import MoveOutOfSection from '../layout/MoveComponentOutOfSection';
import MoveDown from '../layout/MoveDown';
import MoveToSectionAbove from '../layout/MoveToAboveSection';
import MoveToBelowSection from '../layout/MoveToBelowSection';
import MoveUp from '../layout/MoveUp';
import { RendererContext } from '../RendererContext';
import { convertSchemaToBlocks } from '../utils';

export const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
};

export const BuilderCore = ({
  form,
  setEditor,
  currentEditingBlock,
  setCurrentEditingBlock,

  activeComponentDefinitions,
  activeComponentMap,
}: {
  form: FormFile;
  setEditor: any;
  currentEditingBlock: any;
  setCurrentEditingBlock: any;

  activeComponentDefinitions: BlockDefinitions<keyof ComponentMapDeclaration>;
  activeComponentMap: ComponentMapDeclaration;
}) => {
  const editorEL = useRef(null);
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [editorInitStarted, setEditorInitStarted] = useState(false);
  const forceUpdate = useForceUpdate();

  const [formState, setFormState] = useState<FormState | undefined>(
    form?.state ?? {}
  );
  const onBlockFocused = (component: React.ReactNode) => {
    setCurrentEditingBlock(component);
  };

  useEffect(() => {
    const fetchPackagesAndInitEditor = async () => {
      /*
        We need to fetch those packages dynamically so that it still works on our Patient app
        (with Next JS being server-side rendering and requiring the window object)
      */
      const { default: EditorJS } = await import('@editorjs/editorjs');

      // TODO: Plug in drag and drop to the layout tree
      //const { default: DragDrop } = await import('editorjs-drag-drop');

      const componentDefinitionsToUse =
        activeComponentDefinitions ?? blockDefinitions;
      const componentMapToUse = activeComponentMap ?? ComponentMap;
      // We only include the default paragraph block if the componentMap has it
      const shouldIncludeParagraph = 'paragraph' in activeComponentMap;

      if (editorEL.current && !isEditorLoaded) {
        const editorSchemaRepresentation = convertSchemaToBlocks(
          form.form as Component[]
        );

        RendererContext.layoutTree = editorSchemaRepresentation.layoutTree;
        RendererContext.state = formState ?? {};

        RendererContext.componentMap = componentMapToUse;
        RendererContext.componentDefinitions = componentDefinitionsToUse;

        const newEditor = new EditorJS({
          holder: 'editor',
          data: {
            blocks: editorSchemaRepresentation.blocks,
          },
          tools: {
            ...getBlocks(
              onBlockFocused,
              componentDefinitionsToUse,
              componentMapToUse
            ),
            ...(shouldIncludeParagraph ? { paragraph: Paragraph } : {}),
            moveToSectionAbove: MoveToSectionAbove as any,
            moveToBelowSection: MoveToBelowSection as any,
            moveOutOfSection: MoveOutOfSection as any,
            moveUp: MoveUp as any,
            moveDown: MoveDown as any,
            delete: Delete as any,
          },
          placeholder: 'Click the plus icon to add a component',
          autofocus: true,
          tunes: [
            'moveToSectionAbove',
            'moveToBelowSection',
            'moveOutOfSection',
            'moveUp',
            'moveDown',
            'delete',
          ],
          onChange: async () => {
            forceUpdate();
          },
        });

        const waitTillReady = async () => {
          await newEditor.isReady;
          setEditor(newEditor as any);

          RendererContext.getBlockByComponentId = (id: string) => {
            if (!editorEL.current) {
              return;
            }

            const blockContainer = (editorEL.current as HTMLElement).firstChild
              ?.firstChild;

            if (!blockContainer?.childNodes) {
              return;
            }

            for (let i = 0; i < blockContainer?.childNodes.length; i++) {
              const holder =
                blockContainer.childNodes[i].firstChild?.firstChild;

              if (holder) {
                const schemaId = (holder as any).getAttribute('fb-id');
                if (schemaId === id) {
                  return newEditor.blocks.getBlockByIndex(i);
                }
              }
            }
          };
        };

        waitTillReady();
        setIsEditorLoaded(true);
      }
    };

    if (editorInitStarted) {
      return;
    }

    setEditorInitStarted(true);
    fetchPackagesAndInitEditor().catch((error) => console.log(error));
  }, [forceUpdate, form, isEditorLoaded]);

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      <HelixForm name="builder">
        {RendererContext.portals.length > 0
          ? RendererContext.portals.map((portal: any) => {
              return createPortal(
                <div
                  css={{
                    border:
                      portal.component &&
                      portal.component.props.data &&
                      portal.component.props.data.id ===
                        (currentEditingBlock as any)?.props.component.id
                        ? `dotted black 1px`
                        : `solid transparent 1px`,
                    padding: theme.spacing.x2,
                  }}
                >
                  {portal.component}
                </div>,
                portal.holder
              );
            })
          : []}
        <div ref={editorEL} id="editor" css={editorCss.editor} />
      </HelixForm>
    </Formik>
  );
};

const editorCss = {
  editor: css({
    flex: 1,
    height: '100%',
    padding: '8px',
    paddingTop: '24px',
    paddingLeft: '50px',
  }),
};
