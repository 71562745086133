import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '../../schema/schema.types';

export const InfoList = ({ element }: SchemaComponent) => {
  const { options, style } = element;

  return (
    <div>
      <ul
        css={{ marginTop: 0, marginBottom: 0, paddingLeft: theme.spacing.x7 }}
      >
        {options.map((option: string, index: number) => {
          return (
            <li key={`info-list-${index}-${option}`}>
              <BodyText>
                <div
                  css={{
                    color:
                      style?.fontColor === 'gray'
                        ? theme.color.system.gray
                        : theme.color.system.black,
                  }}
                >
                  {option}
                </div>
              </BodyText>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
