import { InfoTwoTone } from '@mui/icons-material';
import { Divider, MenuItem } from '@mui/material';
import { Alert } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { theme } from '@headway/ui/theme';

import { dateToUSFormat } from '../events/util/events';
import { EstimatedLiveDateWithCarrier } from '../LiveDateCalculator';

interface ScheduleEventMenuPreLiveProps {
  open: boolean;
  handleEventTypeClick: (eventType: ProviderEventType) => void;
  onClose: () => void;
  providerEarliestActiveLiveOn: string;
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier;
}

export const ScheduleEventMenuPreLive: FC<
  React.PropsWithChildren<ScheduleEventMenuPreLiveProps>
> = ({
  open,
  handleEventTypeClick,
  onClose,
  providerEarliestActiveLiveOn,
  minEstimatedLiveDate,
}) => {
  return (
    <>
      <MenuItem
        onClick={() => {
          handleEventTypeClick(ProviderEventType.INTAKE_CALL);
          onClose();
        }}
      >
        Add phone consultation
      </MenuItem>
      <Divider css={{ margin: `${theme.space.xs} 0` }} />
      <MenuItem
        onClick={() => {
          handleEventTypeClick(ProviderEventType.BUSY);
          onClose();
        }}
        data-testid="calendarMenuAddUnavailabilityOption"
      >
        Add unavailability
      </MenuItem>

      <MenuItem
        component={Link}
        to="/settings/calendar"
        data-testid="calendarMenuUpdateWorkingHoursOption"
      >
        Update working hours
      </MenuItem>

      <Alert
        color="info"
        variant="outlined"
        icon={<InfoTwoTone />}
        css={{
          margin: `${theme.space.xs} ${theme.space.xs} 0`,
          maxWidth: 350,
          padding: `0 ${theme.space.base} !important`,
        }}
      >
        {`You cannot schedule a session prior to your ${
          providerEarliestActiveLiveOn ? `` : `estimated `
        }go-live date on ${
          providerEarliestActiveLiveOn
            ? dateToUSFormat(providerEarliestActiveLiveOn)
            : dateToUSFormat(minEstimatedLiveDate.estimatedLiveDate)
        }`}
      </Alert>
    </>
  );
};
