/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface InsuranceReadinessNoRemainingSessions {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: InsuranceReadinessNoRemainingSessionsType;

}


    export enum InsuranceReadinessNoRemainingSessionsType {
            NO_REMAINING_COVERED_SESSIONS = 'NO_REMAINING_COVERED_SESSIONS'
    }

