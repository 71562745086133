/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ProviderTaskStatus {
    ACTION_NEEDED = 'ACTION_NEEDED',
    UPCOMING = 'UPCOMING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETE = 'COMPLETE',
    ACTION_NEEDED_HIGH_PRIORITY = 'ACTION_NEEDED_HIGH_PRIORITY'
}
