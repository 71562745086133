import { PatientMismatchInputField } from '@headway/api/models/PatientMismatchInputField';

export const MismatchedInsuranceFieldType = {
  [PatientMismatchInputField.FIRST_NAME]: 'first name',
  [PatientMismatchInputField.LAST_NAME]: 'last name',
  [PatientMismatchInputField.DOB]: 'date of birth',
  [PatientMismatchInputField.MEMBER_ID]: 'member ID',
};

export const getHumanInputErrorMismatchString = (
  patientMismatchInputFields?: PatientMismatchInputField[]
): String => {
  const fields = patientMismatchInputFields?.map(
    (field) => MismatchedInsuranceFieldType[field]
  );

  switch (fields?.length) {
    case 1:
      return `Your client's ${fields[0]} doesn't match their insurance card`;
    case 2:
      return `Both your client's ${fields[0]} and ${fields[1]} don't match their insurance card`;
    case 3:
      return `Your client's ${fields[0]}, ${fields[1]}, and ${fields[2]} don't match their insurance card`;
    case 0:
    case undefined:
      return "Likely a typo in your client's insurance details";
    default:
      return "Several items do not match your client's insurance card";
  }
};
