/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum SourceAttribution {
    ALREADY_IN_CASELOAD = 'ALREADY_IN_CASELOAD',
    REFERRAL_FROM_COLLEAGUE_OR_PHYSICIAN = 'REFERRAL_FROM_COLLEAGUE_OR_PHYSICIAN',
    PSYCHOLOGY_TODAY = 'PSYCHOLOGY_TODAY',
    ZOCDOC = 'ZOCDOC',
    GOOGLE_MY_BUSINESS = 'GOOGLE_MY_BUSINESS',
    ALMA_SONDERMIND_GROW_PATH = 'ALMA_SONDERMIND_GROW_PATH',
    MY_OWN_WEBSITE = 'MY_OWN_WEBSITE',
    BULK_IMPORT = 'BULK_IMPORT',
    MENTAL_HEALTH_MATCH = 'MENTAL_HEALTH_MATCH',
    OTHER = 'OTHER'
}
