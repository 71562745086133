/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { ForethoughtProviderPatientRead } from '../models/ForethoughtProviderPatientRead';
import { ForethoughtProviderPatientsBody } from '../models/ForethoughtProviderPatientsBody';
import { ForethoughtTicketCreatedBody } from '../models/ForethoughtTicketCreatedBody';
import { HTTPValidationError } from '../models/HTTPValidationError';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ForethoughtExternalWebhookApiResource {

    /**
     * Forethought Ticket Created
     * 
     * @param forethoughtTicketCreatedBody 
     
     */
    public forethoughtTicketCreated(body: ForethoughtTicketCreatedBody, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/external-webhooks/forethought/ticket-created';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Chatbot Token
     * Returns a special JWT that is only used for validating someone is using the chatbot when forethought  makes a request. The user must be logged in to retrieve this, but it is purposefully NOT giving a \&quot;real\&quot; token  that can be used for anything else.   It will store it in a cookie and if that is still valid the next time this endpoint is called  it will return the existing token
     
     */
    public getChatbotToken(axiosConfig?: AxiosRequestConfig): Promise<string> {
        const apiRoute = '/external-webhooks/forethought/chatbot_token';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Patients
     * 
     * @param forethoughtProviderPatientsBody 
     
     */
    public getProviderPatients(body: ForethoughtProviderPatientsBody, axiosConfig?: AxiosRequestConfig): Promise<ForethoughtProviderPatientRead> {
        const apiRoute = '/external-webhooks/forethought/provider/patient';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ForethoughtExternalWebhookApi = new ForethoughtExternalWebhookApiResource();
