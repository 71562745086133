import React from 'react';

import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { Badge } from '@headway/helix/Badge';
import { theme } from '@headway/helix/theme';
import { MM_TREATMENT_PLAN } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { isTreatmentPlanExpired } from './TreatmentPlanUtils';

interface BadgeInfo {
  label: string;
  chipType: 'positive' | 'warning' | 'neutral';
}

const getBadgeInfo = (
  treatmentPlan: ProviderTreatmentPlanRead,
  isLatestAttestedPlan: boolean,
  isTreatmentPlanRequirementEnabled?: boolean
): BadgeInfo[] => {
  if (!treatmentPlan.attestedOn) {
    return [
      {
        label: 'Draft',
        chipType: 'neutral',
      },
    ];
  }

  const badges: BadgeInfo[] = [
    {
      label: 'Signed',
      chipType: 'positive',
    },
  ];
  if (
    isTreatmentPlanExpired(treatmentPlan, isTreatmentPlanRequirementEnabled)
  ) {
    return [
      ...badges,
      {
        label: 'Expired',
        chipType: 'warning',
      },
    ];
  }
  return badges;
};

export interface TreatmentPlanStatusBadgesProps {
  treatmentPlan: ProviderTreatmentPlanRead;
  isLatestAttestedPlan: boolean;
}

export const TreatmentPlanStatusBadges = ({
  treatmentPlan,
  isLatestAttestedPlan,
}: TreatmentPlanStatusBadgesProps) => {
  const isTreatmentPlanRequirementEnabled = useFlag(MM_TREATMENT_PLAN, false);
  const treatmentPlanBadges = getBadgeInfo(
    treatmentPlan,
    isLatestAttestedPlan,
    isTreatmentPlanRequirementEnabled
  );

  return (
    <>
      {treatmentPlanBadges.map((badgeInfo, index) => (
        <div key={index} css={{ marginRight: theme.spacing.x2 }}>
          <Badge variant={badgeInfo.chipType}>{badgeInfo.label}</Badge>
        </div>
      ))}
    </>
  );
};
