import { FormHelperText } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';

import { Button } from '@headway/helix/Button';
import { MessageBubble } from '@headway/ui';
import { FieldControl, FieldInput, FieldInputLabel } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

interface AppointmentToIntakeCallFormProps {
  onSubmit: (values: any) => Promise<void>;
}

export const AppointmentToIntakeCallForm = ({
  onSubmit,
}: AppointmentToIntakeCallFormProps) => {
  return (
    <Formik
      initialValues={{
        content: '',
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <SafeFormikForm>
            <MessageBubble>
              <FieldControl
                name="content"
                fullWidth={true}
                css={{ marginBottom: 0 }}
              >
                <FieldInputLabel>
                  Add explanation for phone consultation(optional)
                </FieldInputLabel>
                <FieldInput multiline={true} rows={2} maxRows={10} />
              </FieldControl>
            </MessageBubble>
            <FormHelperText>
              This explanation will be emailed to the client.
            </FormHelperText>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.space.xl2,
              }}
            >
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Change
              </Button>
            </div>
          </SafeFormikForm>
        );
      }}
    </Formik>
  );
};
