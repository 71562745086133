// Any updates to tier fees in self_pay_fees.py should be reflected here as well
// gemini.link(mamba/app/shared/constants/self_pay_fees.py)
export const SELF_PAY_FEES = {
  cc: {
    pct: 1.023,
  },
  bank: {
    pct: 1.008,
  },
  flat: 3.3,
};
// gemini.endlink
