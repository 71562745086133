import { toaster } from '@headway/ui/ToastManager';

export const notifySuccess: typeof toaster.success = (
  message,
  options = {}
) => {
  return toaster.success(message, options);
};

export const notifyWarning: typeof toaster.warning = (
  message,
  options = {}
) => {
  return toaster.warning(message, options);
};

export const notifyError: typeof toaster.error = (message, options = {}) => {
  return toaster.error(message, options);
};

export const notifyInfo: typeof toaster.info = (message, options = {}) => {
  return toaster.info(message, options);
};

export const closeToast: typeof toaster.close = (key) => {
  toaster.close(key);
};
