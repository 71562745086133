import { inject } from 'mobx-react';
import React from 'react';

import { ReferProvider } from '@headway/ui/providers/ReferProvider';

interface ReferAProviderProps {
  AuthStore?: any;
}

export const ReferAProvider: React.FC<
  React.PropsWithChildren<ReferAProviderProps>
> = inject('AuthStore')(({ AuthStore }) => {
  return <ReferProvider AuthStore={AuthStore} />;
});
