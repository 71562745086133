import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';

export const telehealthPlatformDisplayNames: {
  [key in TelehealthPlatform]: string;
} = {
  [TelehealthPlatform.AUDIO]: 'Audio only',
  [TelehealthPlatform.VIDEO]: 'Audiovisual',
  [TelehealthPlatform.AUDIO_ONLY_NO_ACCESS]:
    'Audio only - client does not have access to video',
  [TelehealthPlatform.AUDIO_ONLY_REFUSAL]:
    'Audio only - client refuses to use video',
};
