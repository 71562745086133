const REDIRECT_TO_QUERY_KEY = 'redirect-to';

const decodePath = (rawRedirectTo: string): string => {
  const decoded = decodeURIComponent(rawRedirectTo);
  return getRedirectPath(decoded);
};
const getRedirectPath = (rawRedirectTo: string): string => {
  const destination = new URL(rawRedirectTo ?? '/', window.location.origin);

  const queryString = destination.searchParams.toString()
    ? `?${destination.searchParams.toString()}`
    : '';

  return `${destination.pathname}${queryString}${destination.hash}`;
};

export { REDIRECT_TO_QUERY_KEY, decodePath };
