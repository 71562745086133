import * as React from 'react';

export const Settings = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.958 18.334l-.396-2.646a3.974 3.974 0 01-.718-.344 8.832 8.832 0 01-.678-.448L3.708 16l-2.062-3.604 2.229-1.646a1.964 1.964 0 01-.052-.375 7.527 7.527 0 010-.75c.007-.139.024-.264.052-.375L1.646 7.604 3.709 4l2.458 1.104c.208-.153.437-.302.687-.448.25-.146.486-.26.708-.343l.396-2.646h4.083l.396 2.646c.237.083.48.194.73.333s.472.291.667.458L16.29 4l2.063 3.604-2.229 1.605c.028.125.045.256.052.395a7.89 7.89 0 010 .782c-.007.132-.024.26-.052.385l2.209 1.625L16.29 16l-2.457-1.104c-.209.153-.435.305-.678.458a2.875 2.875 0 01-.719.334l-.396 2.646H7.958zm2.063-5.563c.764 0 1.416-.27 1.958-.813A2.67 2.67 0 0012.792 10a2.67 2.67 0 00-.813-1.959 2.669 2.669 0 00-1.958-.812c-.778 0-1.438.27-1.98.812A2.67 2.67 0 007.23 10c0 .764.27 1.417.812 1.959a2.694 2.694 0 001.98.812z"
      fill="currentColor"
    />
  </svg>
);
