import { ModalProvider, OverlayProvider, useModalProvider } from 'react-aria';
import { SSRProvider } from 'react-aria';

function HelixProviderImpl({ children }: { children: React.ReactNode }) {
  let { modalProviderProps } = useModalProvider();

  return <div {...modalProviderProps}>{children}</div>;
}

interface HelixProviderProps {
  children: React.ReactNode;
}

function HelixProvider({ children }: HelixProviderProps) {
  return (
    <OverlayProvider>
      <HelixProviderImpl>{children}</HelixProviderImpl>
    </OverlayProvider>
  );
}

export { HelixProvider, SSRProvider as HelixSSRProvider };
