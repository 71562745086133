import React from 'react';

export function IconChevronDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 12.8125L5 7.8125L5.89583 6.91667L10 11.0417L14.1042 6.9375L15 7.83334L10 12.8125Z"
        fill="currentColor"
      />
    </svg>
  );
}
