import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAddressQueryKeyArgs {
  addressId?: number;
}

export const getUsePatientAddressQueryKey = ({
  addressId,
}: UsePatientAddressQueryKeyArgs): [string, number | undefined] => [
  'patient-address',
  addressId,
];

const {
  useSingleQuery: usePatientAddress,
  useListQuery: usePatientAddressList,
} = createBasicApiHooks(
  getUsePatientAddressQueryKey,
  ({ addressId }) => {
    return PatientAddressApi.getPatientAddress(addressId!);
  },
  ({ addressId }) => !!addressId,
  () => 'Failed to fetch patient address'
);

export { usePatientAddress, usePatientAddressList };
