import React from 'react';

import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { trackEvent } from '@headway/shared/utils/analytics';
import { theme } from '@headway/ui/theme';

type UpdatedIdentityModalProps = {
  open: boolean;
  onClose: () => void;
  providerId: any;
};

export const UpdatedIdentityModal: React.FC<UpdatedIdentityModalProps> = ({
  open,
  onClose,
  providerId,
}) => {
  function trackProviderClickedGoToProfile() {
    trackEvent({
      name: 'Provider Identity Update Your Profile Button Clicked',
      properties: {
        providerId: providerId,
      },
    });
  }

  return (
    <Modal
      isOpen={open}
      isDismissable={true}
      onDismiss={onClose}
      title="We’ve added identity options"
    >
      <ModalContent>
        <div css={{ marginBottom: theme.space.base }}>
          We’ve made updates to how providers and clients identify themselves on
          Headway to create a more inclusive experience.
        </div>

        <div css={{ fontWeight: theme.fontWeight.bold }}>
          What’s new for providers?
        </div>
        <div css={{ marginBottom: theme.space.base }}>
          Visit{' '}
          <Link
            href={`/settings/profile#demographics`}
            onClick={() => {
              onClose();
              trackProviderClickedGoToProfile();
            }}
          >
            Profile Settings
          </Link>{' '}
          any time to update your gender identity with the expanded options.
          This will be visible to clients on your Headway profile, and will help
          those who have a gender identity preference when choosing a provider.
        </div>

        <div css={{ fontWeight: theme.fontWeight.bold }}>
          What’s new for clients?
        </div>
        <div>
          Clients who use a different name from their legal name can now
          indicate this in their account. We’ll use their indicated first name
          in your portal and in communications from Headway, and only use their
          legal name on insurance claims.
        </div>
      </ModalContent>

      <ModalFooter>
        <Button
          variant="secondary"
          size="large"
          type="submit"
          onPress={onClose}
        >
          Not now
        </Button>
        <Button
          variant="primary"
          size="large"
          type="submit"
          onPress={() => {
            onClose();
            trackProviderClickedGoToProfile();
            window.location.href = `/settings/profile#demographics`;
          }}
        >
          Update your profile
        </Button>
      </ModalFooter>
    </Modal>
  );
};
