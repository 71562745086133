import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';

import { SchemaComponent } from '.';
import { OtherTextField } from './OtherTextField';

export const FormRadio = ({
  element,
  isOptional = false,
  template,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, options } = element;

  return (
    <FormControl
      label={title}
      name={id}
      key={id}
      component={RadioGroup}
      optionalityText={isOptional ? 'Optional' : null}
      disabled={disabled}
    >
      {options.map((option: string, i: number) => {
        return option.includes('Other') ? (
          <OtherTextField
            id={id}
            optionType="single"
            option={option}
            template={template}
            disabled={disabled}
          >
            {(value: any) => (
              <Radio key={i} value={option} disabled={disabled}>
                {option}
              </Radio>
            )}
          </OtherTextField>
        ) : (
          <Radio key={i} value={option} disabled={disabled}>
            {option}
          </Radio>
        );
      })}
    </FormControl>
  );
};
