import React from 'react';
import { useContext } from 'react';

import { theme } from '@headway/ui/theme';

import { BannersContext } from './utils';
import { calculateBannerHeight, HEADER_HEIGHT } from './utils';

export const PaddedPanelLayout = ({ children, ...rest }) => {
  const numBanners = useContext(BannersContext);
  const bannerHeight = calculateBannerHeight(numBanners + 1);

  return (
    <div
      css={{
        marginTop: 50,
        padding: theme.space.xs,
        height: `calc(var(--real100vh) - ${HEADER_HEIGHT}px)`,
        [theme.media.medium]: {
          height: `calc(100vh - ${bannerHeight}px)`,
          padding: theme.space.xl,
        },
      }}
      {...rest}
    >
      {children}
    </div>
  );
};
