import { Block } from '@mui/icons-material';
import { PopoverActions } from '@mui/material';
import { Alert } from '@mui/material';
import React, { FC, useLayoutEffect, useRef } from 'react';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { Button } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useSelectedEvent } from 'hooks/useSelectedEvent';

import { getEventIdentifierClass } from '../events/util/events';
import { DetailPopover } from './DetailPopover';
import { EventFooter } from './EventFooter';
import { EventHeader } from './EventHeader';

interface Props {
  isOpen: boolean;
  onClose: ContentsProps['onClose'];
  checkRecurringAndDelete: ContentsProps['checkRecurringAndDelete'];
  handleRescheduleClick: ContentsProps['handleRescheduleClick'];
}

const UnavailabilityDetail: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  checkRecurringAndDelete,
  handleRescheduleClick,
}) => {
  const action = useRef<PopoverActions | null>(null);
  const { event } = useSelectedEvent();

  useLayoutEffect(() => {
    if (isOpen && event && action.current) {
      action.current.updatePosition();
    }
  }, [event, isOpen]);

  if (!event) return null;

  const anchorEl = document.querySelector(`.${getEventIdentifierClass(event)}`);

  return (
    <DetailPopover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      action={action}
      PaperProps={{
        style: {
          width: 448,
        },
      }}
    >
      {event ? (
        <Contents
          event={event}
          onClose={onClose}
          checkRecurringAndDelete={checkRecurringAndDelete}
          handleRescheduleClick={handleRescheduleClick}
        />
      ) : null}
    </DetailPopover>
  );
};

interface ContentsProps {
  onClose: () => void;
  event: ProviderEventRead;
  checkRecurringAndDelete: (updateValues?: any) => Promise<void>;
  handleRescheduleClick: (type: ProviderEventType) => void;
}

const Contents: FC<React.PropsWithChildren<ContentsProps>> = ({
  event,
  onClose,
  checkRecurringAndDelete,
  handleRescheduleClick,
}) => (
  <React.Fragment>
    <EventHeader
      title={event.title ? `Unavailable (${event.title})` : 'Unavailable'}
      onClose={onClose}
      startDate={event.startDate!}
      recurrence={event.recurrence}
      eventHasContents={false}
    >
      <Alert
        color="info"
        variant="outlined"
        icon={<Block />}
        css={{ marginTop: theme.space.base }}
      >
        Clients will not be able to book appointments with you during this time.
      </Alert>
    </EventHeader>
    <EventFooter>
      <Button color="gray" onClick={handleRescheduleClick}>
        Reschedule →
      </Button>
      <Button
        color="gray"
        onClick={async () => {
          await checkRecurringAndDelete();
          onClose();
        }}
      >
        Remove →
      </Button>
    </EventFooter>
  </React.Fragment>
);

export { UnavailabilityDetail };
