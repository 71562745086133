/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderDocumentRequestRead } from '../models/ProviderDocumentRequestRead';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderDocumentRequestApiResource {

    /**
     * Bulk Delete Document Requests
     * Endpoint that takes in a csv of 1 column: provider appointment id Aggregates the csv info, to soft delete document requests.
     * @param file 
     
     */
    public bulkDeleteDocumentRequests(file: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-requests/bulk-delete-document-requests';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Send Document Request Emails Internal Audit
     * Endpoint that takes in:  - a csv of 2 columns: provider appointment id and deadline.  - a default communication tone (for headway-initiated audits, the ones with empty front end carrier id)  - a boolean to know whether to send the emails or not (they could decide to send it manually instead) Aggregates the csv info, to send emails to providers notifying them of document requests (if needed).
     * @param communicationTone 
     * @param sendEmails 
     * @param file 
     
     */
    public bulkSendDocumentRequestEmailsInternalAudit(file: any, query?: { communication_tone: string, send_emails: boolean }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-requests/bulk-send-document-request-emails-internal-audit';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Send Document Request Emails Payers Audit
     * Endpoint that takes in a csv of 3 columns: provider appointment id, front end carrier id and deadline. Aggregates the csv info, to send emails to providers notifying them of document requests (if needed).
     * @param file 
     
     */
    public bulkSendDocumentRequestEmailsPayersAudit(file: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-requests/bulk-send-document-request-emails-payers-audit';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Update Document Requests
     * Endpoint that takes in a csv of 2 columns: provider appointment id,deadline Aggregates the csv info to update the deadline for document requests.
     * @param file 
     
     */
    public bulkUpdateDocumentRequests(file: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-document-requests/bulk-update-document-requests';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Provider Document Requests
     * 
     * @param providerId 
     * @param providerAppointmentId 
     
     */
    public findProviderDocumentRequests(query?: { provider_id?: number, provider_appointment_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderDocumentRequestRead>> {
        const apiRoute = '/provider-document-requests';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderDocumentRequestApi = new ProviderDocumentRequestApiResource();
