import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Header } from 'components/Header/Header';
import { RemoveInitialLoadStyles } from 'utils/RemoveInitialLoadStyles';
import ForgotPassword from 'views/Auth/ForgotPassword';
import LoginPage from 'views/Auth/LoginPage';
import RegistrationPage from 'views/Auth/RegistrationPage.jsx';
import { ResetPassword } from 'views/Auth/ResetPassword';

import AttestationConfirmation from '../views/Auth/AttestationConfirmation';

class Auth extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'unset';
  }
  render() {
    return (
      <React.Fragment>
        <Header />
        <RemoveInitialLoadStyles />
        <div>
          <div className="relative top-0 min-h-screen">
            <div className="relative flex h-fit items-center py-12 tablet:min-h-screen tablet:py-[120px]">
              <Routes>
                <Route path="login" element={<LoginPage />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="register" element={<RegistrationPage />} />
                <Route
                  path="attest-confirmation"
                  element={<AttestationConfirmation />}
                />
                <Route
                  path="callback"
                  element={
                    <Navigate
                      replace
                      to={window.history.state?.returnTo || '/'}
                    />
                  }
                />
                <Route
                  path="*"
                  element={<Navigate replace to="/auth/login" />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Auth;
