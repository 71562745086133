import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import React from 'react';

import { InsuranceReadinessIssueManualVerificationRequiredType } from '@headway/api/models/InsuranceReadinessIssueManualVerificationRequired';
import { InsuranceReadinessIssueNoMentalHealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueNoMentalHealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { InsuranceReadinessIssueTerminatedType } from '@headway/api/models/InsuranceReadinessIssueTerminated';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconError } from '@headway/helix/icons/Error';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { trackPageView } from '@headway/shared/utils/analytics';
import { getIneligibleLookupTypeFromReadinessIssues } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';

import headshotImage from 'assets/img/michael_heckendorn.png';

interface PrelimPriceToIneligibleAlertModalProps {
  client: UserRead | undefined;
  clientInsurance: UserInsuranceRead | undefined;
  open: boolean;
  onClose: () => void;
  onUpdateInsurance: () => void;
  issues: UserAppointmentReadiness['insurance'];
}

export const PrelimPriceToIneligibleAlertModal = ({
  client,
  clientInsurance,
  open,
  onClose,
  onUpdateInsurance,
  issues,
}: PrelimPriceToIneligibleAlertModalProps) => {
  const provider = useProvider();
  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });
  const providerName = provider.displayFirstName ?? provider.firstName;

  const isOON = issues.find(
    (issue) =>
      issue.type === InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK
  );

  const hasNoMentalHealthBenefits = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueNoMentalHealthBenefitsType.HAS_NO_MENTAL_HEALTH_BENEFITS
  );

  const isTerminated = issues.find(
    (issue) => issue.type === InsuranceReadinessIssueTerminatedType.TERMINATED
  );

  const isManualVerificationRequired = issues.find(
    (issue) =>
      issue.type ===
      InsuranceReadinessIssueManualVerificationRequiredType.MANUAL_VERIFICATION_REQUIRED
  );

  const showManualVerificationRequiredExperience =
    isManualVerificationRequired ||
    (!isTerminated && !isOON && !hasNoMentalHealthBenefits);

  React.useEffect(() => {
    if (open && client && provider && clientInsurance && issues.length) {
      trackPageView({
        name: 'Ineligible Insurance Modal Viewed',
        properties: {
          patientUserId: client.id,
          providerId: provider.id,
          userInsuranceId: String(clientInsurance.id),
          eligibilityLookupId: clientInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType:
            getIneligibleLookupTypeFromReadinessIssues(issues),
        },
      });
    }
  }, [open, client, provider, clientInsurance, issues]);

  if (!client) {
    return null;
  }

  let titleContent;
  let bodyContent;
  if (isOON) {
    titleContent = `${clientFirstName}’s plan is not eligible for in-network care`;
    bodyContent = (
      <>
        <div css={section}>
          <BodyText>
            We’ve been working to get the right cost estimate for their
            sessions, and have received an update that{' '}
            <strong>
              their plan is not eligible for in-network care on Headway
            </strong>
            . This is because Headway’s agreement with{' '}
            {clientInsurance?.billingFrontEndCarrierName || 'their insurer'}{' '}
            does not currently include this plan.
          </BodyText>
        </div>
        <div css={sectionHeader}>What happens next</div>
        <div css={section}>
          <ul css={sectionList}>
            <li>
              <BodyText>
                If you have future sessions booked with {clientFirstName}, we
                recommend cancelling these sessions.
              </BodyText>
            </li>
            <li>
              <BodyText>
                If your client would like to keep seeing you out-of-network, you
                can switch to private pay in the client tab.
              </BodyText>
            </li>
          </ul>
        </div>
        <div css={sectionHeader}>If something looks wrong</div>
        <div css={section}>
          <ul css={sectionList}>
            <li>
              <BodyText>
                Ask your client to double check their insurance details.
              </BodyText>
            </li>
            <li>
              <BodyText>
                Have {clientFirstName} contact{' '}
                {clientInsurance?.billingFrontEndCarrierName || 'their insurer'}{' '}
                directly for a reference number confirming their eligibility,
                and then have them reach out to us with this number ready.{' '}
                <Link
                  href="https://help.headway.co/hc/en-us/articles/21556524888596"
                  target="_blank"
                >
                  Learn more
                </Link>
                .
              </BodyText>
            </li>
          </ul>
        </div>
      </>
    );
  }

  if (hasNoMentalHealthBenefits) {
    titleContent = `${clientFirstName}’s plan doesn’t include mental health coverage`;
    bodyContent = (
      <>
        <div css={section}>
          <BodyText>
            We’ve been working to get the updated cost estimate for their
            sessions, and have received an update that{' '}
            <strong>their plan does not include mental health coverage</strong>,
            based on the details we have today. This happens when their benefits
            are set up to cover most physical health needs, but do not cover
            behavioral health care, such as therapy sessions.{' '}
            <Link
              href="https://headway.co/clients/understanding-health-insurance"
              target="_blank"
            >
              Learn more about insurance benefits here
            </Link>
            .
          </BodyText>
        </div>
        <div css={sectionHeader}>What you can do</div>
        <div css={section}>
          <ul css={sectionList}>
            <li>
              <BodyText>
                If you have future sessions booked with {clientFirstName}, we
                recommend cancelling these sessions.
              </BodyText>
            </li>
            <li>
              <BodyText>
                If your client would like to keep seeing you out-of-network, you
                can switch to private pay in the client tab.
              </BodyText>
            </li>
          </ul>
        </div>
        <div css={sectionHeader}>If something still seems wrong</div>
        <div css={section}>
          <ul css={sectionList}>
            <li>
              <BodyText>
                Ask your client to double check their insurance details.
              </BodyText>
            </li>
            <li>
              <BodyText>
                Have {clientFirstName} contact{' '}
                {clientInsurance?.billingFrontEndCarrierName || 'their insurer'}{' '}
                directly for a reference number confirming their eligibility,
                and then have them reach out to us with this number ready.{' '}
                <Link
                  href="https://help.headway.co/hc/en-us/articles/21556524888596"
                  target="_blank"
                >
                  Learn more
                </Link>
                .
              </BodyText>
            </li>
          </ul>
        </div>
      </>
    );
  }

  if (isTerminated) {
    titleContent = `${clientFirstName}’s plan is no longer active`;
    bodyContent = (
      <>
        <div css={section}>
          <BodyText>
            We’ve been working to get the updated cost estimate for their
            sessions, and have received an update that{' '}
            <strong>their plan is no longer active</strong>, based on the
            details we have today.{' '}
            <Link
              href="https://headway.co/clients/understanding-health-insurance"
              target="_blank"
            >
              Learn more about insurance benefits here
            </Link>
            .
          </BodyText>
        </div>
        <div css={sectionHeader}>What happens next</div>
        <div css={section}>
          <BodyText>
            It’s likely your client has old details for a past plan. Ask them to
            double check their latest insurance details.
          </BodyText>
        </div>
        <div css={sectionHeader}>
          Other options if updating doesn’t solve the issue
        </div>
        <div css={section}>
          <ul css={sectionList}>
            <li>
              <BodyText>
                Switch to private pay. If your client would like to keep seeing
                you out-of-network, you can switch to private pay in the client
                tab.
              </BodyText>
            </li>
            <li>
              <BodyText>
                Have {clientFirstName} contact{' '}
                {clientInsurance?.billingFrontEndCarrierName || 'their insurer'}{' '}
                directly for a reference number confirming their eligibility,
                and then have them reach out to us with this number ready.{' '}
                <Link
                  href="https://help.headway.co/hc/en-us/articles/21556524888596"
                  target="_blank"
                >
                  Learn more
                </Link>
                .
              </BodyText>
            </li>
          </ul>
        </div>
      </>
    );
  }

  if (showManualVerificationRequiredExperience) {
    titleContent = `${clientFirstName}’s plan needs to be manually verified`;
    bodyContent = (
      <>
        <div css={section}>
          <BodyText>
            We’ve been working to get the updated cost estimate for their
            sessions, and have received an update that{' '}
            <strong>we can’t automatically verify their benefits</strong> based
            on the details we have today.
          </BodyText>
        </div>
        <div css={sectionHeader}>Next steps</div>
        <div css={section}>
          <ol css={sectionList}>
            <li>
              <BodyText>
                Ask your client to double check their insurance details.
                Sometimes a quick update is all it takes to resolve the issue.
              </BodyText>
            </li>
            <li>
              <BodyText>
                Request a manual verification from Headway.{' '}
                <Link
                  href="https://headway.co/contact?submitterType=provider_on_behalf_of_client&primaryIssueType=rit_billing___insurance_issues"
                  target="_blank"
                >
                  Contact our team
                </Link>{' '}
                to get started.
              </BodyText>
            </li>
          </ol>
        </div>
        <div css={section}>
          <BodyText>
            Please note it can take 7-15 days to manually verify insurance, but
            rest assured my team will do our best to resolve the issue as
            quickly as possible!
          </BodyText>
        </div>
      </>
    );
  }

  return (
    <div data-testid="prelimPriceToIneligibleAlertModal">
      <Modal
        title={
          <div
            css={{
              display: 'flex',
            }}
          >
            <IconError
              color={theme.color.primary.red}
              height={theme.spacing.x7}
              css={{
                marginRight: theme.spacing.x2,
              }}
            />
            {titleContent}
          </div>
        }
        isOpen={open}
        onDismiss={onClose}
      >
        <ModalContent>
          <div css={greetingText}>
            <BodyText>Hi {providerName},</BodyText>
          </div>
          {bodyContent}
          <div>
            <div css={footer}>
              <div>
                <img
                  alt="Clinical Lead Headshot"
                  src={headshotImage}
                  css={headshot}
                />
              </div>
              <div css={signature}>
                <div css={signatureName}>Michael and team</div>
                <BodyText>Clinical Lead at Headway</BodyText>
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div css={footerButtons}>
            {hasNoMentalHealthBenefits ||
            isTerminated ||
            showManualVerificationRequiredExperience ? (
              <>
                <div css={updateInsuranceButton}>
                  <Button
                    onPress={onUpdateInsurance}
                    variant="secondary"
                    size="large"
                  >
                    Update insurance
                  </Button>
                </div>

                <Button onPress={onClose} variant="primary" size="large">
                  Got it
                </Button>
              </>
            ) : (
              <LinkButton
                href="/calendar"
                variant="primary"
                size="large"
                onClick={onClose}
              >
                View appointments
              </LinkButton>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const greetingText = css`
  margin-top: ${theme.spacing.x4};
  margin-bottom: ${theme.spacing.x4};
`;
const sectionHeader = css`
  font-family: PostGrotesk, Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;
const section = css`
  margin-bottom: ${theme.spacing.x8};
`;
const sectionList = css`
  margin-top: ${theme.spacing.x1};
  padding-left: ${theme.spacing.x6};
`;
const footer = css`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: ${theme.spacing.x3};
`;
const signatureName = css`
  font-weight: bold;
  font-size: 17px;
  display: block;
`;
const headshot = css`
  width: 48px;
  height: 48px;
`;
const signature = css`
  display: flex;
  flex-direction: column;
`;
const updateInsuranceButton = css`
  margin-right: ${theme.spacing.x5};
`;
const footerButtons = css`
  display: flex;
`;
