import { Skeleton } from '@mui/material';
import { useProvider, useProviderPatient } from 'hooks';

import { ProviderPatientAttachmentType } from '@headway/api/models/ProviderPatientAttachmentType';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@headway/shared/react-query';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { notifyError } from '@headway/ui/utils/notify';

import { Attachment, AttachmentsList } from 'views/Patients/AttachmentsList';

export interface ProviderPatientAttachmentsProps {
  clientId: number;
}

/** Displays all attachments associated with a ProviderPatient, and a dropzone for addding more. */
export const ProviderPatientAttachments = ({
  clientId,
}: ProviderPatientAttachmentsProps) => {
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });
  const attachmentsQueryKey = [
    'provider-patient-attachments',
    providerPatient?.id,
  ];
  const { data: attachments, isLoading: isAttachmentsLoading } = useQuery(
    attachmentsQueryKey,
    () => ProviderPatientApi.getProviderPatientAttachments(providerPatient!.id),
    { enabled: !!providerPatient }
  );
  const queryClient = useQueryClient();

  const downloadAttachment = async (attachment: Attachment) => {
    try {
      await downloadFile(attachment);
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  };

  const addAttachmentsMutation = useMutation(
    async (files: UploadedFile[]) => {
      return await Promise.all(
        files.map((file) =>
          ProviderPatientApi.createProviderPatientAttachment(
            providerPatient?.id!,
            {
              name: file.name,
              link: file.link,
              providerPatientId: providerPatient?.id!,
              attachmentType: ProviderPatientAttachmentType.UNCATEGORIZED,
            }
          )
        )
      );
    },
    {
      onError: (err: any) => {
        notifyError('There was an issue uploading your attachment');
        logException(err);
      },
      onSuccess: (addedAttachments) => {
        queryClient.setQueryData(attachmentsQueryKey, [
          ...(attachments || []),
          ...addedAttachments,
        ]);
        queryClient.invalidateQueries(attachmentsQueryKey);
      },
    }
  );

  const deleteAttachmentMutation = useMutation(
    (attachmentId: number) =>
      ProviderPatientApi.deleteProviderPatientAttachment(
        providerPatient?.id!,
        attachmentId
      ),
    {
      onMutate: (attachmentId: number) => {
        queryClient.setQueryData(
          attachmentsQueryKey,
          attachments?.filter((attachment) => attachment.id !== attachmentId)
        );
        return { previous: attachments };
      },
      onError: (err, id, context) => {
        notifyError('There was an issue deleting your attachment');
        logException(err);
        queryClient.setQueryData(attachmentsQueryKey, context?.previous);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(attachmentsQueryKey);
      },
    }
  );

  return isAttachmentsLoading ? (
    <Skeleton height={64} variant="rectangular" />
  ) : (
    <AttachmentsList
      provider={provider}
      attachments={attachments!}
      onAddAttachments={async (files) => {
        await addAttachmentsMutation.mutateAsync(files);
      }}
      onDeleteAttachment={deleteAttachmentMutation.mutate}
      onDownloadAttachment={downloadAttachment}
      variant="helix"
    />
  );
};
