import { theme } from '@headway/helix/theme';
import { LogoLoader } from '@headway/ui/LogoLoader';

export const LoadingState = () => {
  return (
    <div
      css={{
        padding: '10px 0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.__futureMedia.above('tablet')]: {
          height: '75px',
        },
      }}
    >
      <LogoLoader size={40} /> :
    </div>
  );
};
