import { css } from '@emotion/react';
import { SaveAltTwoTone } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import React from 'react';

import { PatientFormSchemaNestedRead } from '@headway/api/models/PatientFormSchemaNestedRead';
import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';
import { BodyText } from '@headway/helix/BodyText';
import { IconButton as HelixIconButton } from '@headway/helix/IconButton';
import { IconCircleCheck } from '@headway/helix/icons/CircleCheck';
import { IconDelete } from '@headway/helix/icons/Delete';
import { IconDocument } from '@headway/helix/icons/Document';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile, uploadFilesToS3 } from '@headway/shared/utils/upload';
import { Dropzone } from '@headway/ui/Dropzone';
import { notifyError } from '@headway/ui/utils/notify';

import { useProvider } from 'hooks/useProvider';
import { FileInfo } from 'utils/types';

import { PracticePoliciesFormValues } from '../PracticePolicies';

const formatFileSize = (fileSizeInBytes?: number): string => {
  if (!fileSizeInBytes) return '';
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;

  if (fileSizeInBytes < kilobyte) {
    return fileSizeInBytes + ' B';
  } else if (fileSizeInBytes < megabyte) {
    return (fileSizeInBytes / kilobyte).toFixed() + ' KB';
  } else {
    return (fileSizeInBytes / megabyte).toFixed(1) + ' MB';
  }
};

interface PracticePoliciesUploadProps {
  policy?: PatientFormSchemaNestedRead;
}

export const PracticePoliciesUpload = (props: PracticePoliciesUploadProps) => {
  const { errors, setFieldValue, touched } =
    useFormikContext<PracticePoliciesFormValues>();

  const [uploadingAttachment, setUploadingAttachment] = React.useState(false);
  const [attachment, setAttachment] = React.useState<UploadedFile | undefined>(
    props.policy && props.policy.attachmentS3ObjectKey
      ? ({
          name: props.policy.attachmentName,
          link: props.policy.attachmentLink,
          s3ObjectKey: props.policy.attachmentS3ObjectKey,
          size: props.policy.attachmentSize,
        } as UploadedFile)
      : undefined
  );
  const provider = useProvider();

  const onDropFiles = async (files: FileInfo[]) => {
    setUploadingAttachment(true);
    const processedFiles = await uploadFilesToS3(
      files,
      UserUploadTypes.PROVIDER_UPLOADS_PUBLIC,
      provider.id,
      true
    );
    const successfullyProcessedFiles = processedFiles.filter((file) => !!file);
    setAttachment(successfullyProcessedFiles[0]);
    setFieldValue('attachmentPolicyName', successfullyProcessedFiles[0].name);
    setFieldValue(
      'attachmentPolicyS3ObjectKey',
      successfullyProcessedFiles[0].s3ObjectKey
    );
    setUploadingAttachment(false);
  };

  const handleDownloadAttachment = async (attachment: {
    link: string;
    name?: string;
  }) => {
    try {
      await downloadFile(attachment);
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  };

  const handleOnDeleteAttachment = () => {
    setAttachment(undefined);
    setFieldValue('attachmentPolicyName', undefined);
    setFieldValue('attachmentPolicyS3ObjectKey', undefined);
  };

  return (
    <div>
      {attachment ? (
        <div css={attachmentInfoAndPreviewContainer}>
          <div css={attachmentInfoContainer}>
            <div css={attachmentInfo}>
              <IconDocument width={36} height={36} />
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <div css={{ marginLeft: 2 }}>
                  <BodyText>{attachment.name}</BodyText>
                </div>
                <div
                  css={{
                    display: 'flex',
                    gap: theme.spacing.x1,
                    alignItems: 'center',
                  }}
                >
                  <IconCircleCheck color={theme.color.system.green} />
                  <SubBodyText>{formatFileSize(attachment.size)}</SubBodyText>
                </div>
              </div>
            </div>
            <div css={{ display: 'flex', gap: theme.spacing.x2 }}>
              <HelixIconButton
                aria-label={`Download ${attachment.name}`}
                onPress={() => handleDownloadAttachment(attachment)}
              >
                <SaveAltTwoTone />
              </HelixIconButton>
              <HelixIconButton
                aria-label={`Delete ${attachment.name}`}
                onPress={handleOnDeleteAttachment}
              >
                <IconDelete />
              </HelixIconButton>
            </div>
          </div>
          <PdfPreview src={attachment.link} title={attachment.name} />
        </div>
      ) : (
        <div>
          <div css={{ marginTop: theme.spacing.x6 }}>
            <BodyText>You may upload PDFs under 10 MB.</BodyText>
          </div>
          <div css={{ marginTop: theme.spacing.x4 }}>
            <Dropzone
              height={60}
              onDrop={onDropFiles}
              variant="helix"
              accept="application/pdf"
              disabled={uploadingAttachment}
            />
          </div>
          {errors.attachmentPolicyS3ObjectKey && touched.entryMethod && (
            <div css={{ marginTop: theme.spacing.x2 }}>
              <SubBodyText>
                <span css={{ color: theme.color.primary.red }}>
                  {errors.attachmentPolicyS3ObjectKey}
                </span>
              </SubBodyText>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface PdfPreviewProps {
  title: string;
  src: string;
}

function PdfPreview({ src, title }: PdfPreviewProps) {
  return (
    <iframe
      title={title}
      src={`${src}#view=fitH`}
      css={{
        backgroundColor: 'white',
        height: '1000px',
        marginTop: theme.spacing.x6,
      }}
    />
  );
}

const attachmentInfoAndPreviewContainer = css`
  margin-top: ${theme.spacing.x6};
  display: flex;
  flex-direction: column;
`;
const attachmentInfoContainer = css`
  margin-left: ${theme.spacing.x2};
  display: flex;
  justify-content: space-between;
`;
const attachmentInfo = css`
  display: flex;
  gap: ${theme.spacing.x1};
  align-items: center;
`;
