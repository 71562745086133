import React, { createContext, ReactNode, useState } from 'react';

import {
  getModalProgressProps,
  useAddPatientModalSteps,
} from '../utils/addPatientModalUtils';

export enum AddPatientModalPage {
  BASIC_INFO = 'BASIC_INFO',
  EXISTING_INSURANCE = 'EXISTING_INSURANCE',
  BILLING_INFORMATION = 'BILLING_INFORMATION',
  TELEHEALTH_LOCATIONS = 'TELEHEALTH_LOCATIONS',
  SEND_ACCOUNT_INVITE = 'SEND_ACCOUNT_INVITE',
  PATIENT_ADDRESS = 'PATIENT_ADDRESS',
  ASSESSMENTS_PRODUCT_ANNOUNCEMENT = 'ASSESSMENTS_PRODUCT_ANNOUNCEMENT',
  SEND_ASSESSMENTS = 'SEND_ASSESSMENTS',
  VERIFY_INSURANCE_MATCH = 'VERIFY_INSURANCE_MATCH',
}

export enum AddPatientModalStep {
  BASIC_INFO = 'BASIC_INFO',
  INSURANCE = 'INSURANCE',
  TELEHEALTH = 'TELEHEALTH',
  INVITE = 'INVITE',
  ASSESSMENTS = 'ASSESSMENTS',
}

export const mapPageToStep: {
  [page in AddPatientModalPage]: AddPatientModalStep;
} = {
  [AddPatientModalPage.BASIC_INFO]: AddPatientModalStep.BASIC_INFO,
  [AddPatientModalPage.EXISTING_INSURANCE]: AddPatientModalStep.INSURANCE,
  [AddPatientModalPage.BILLING_INFORMATION]: AddPatientModalStep.INSURANCE,
  [AddPatientModalPage.PATIENT_ADDRESS]: AddPatientModalStep.INSURANCE,
  [AddPatientModalPage.VERIFY_INSURANCE_MATCH]: AddPatientModalStep.INSURANCE,
  [AddPatientModalPage.TELEHEALTH_LOCATIONS]: AddPatientModalStep.TELEHEALTH,
  [AddPatientModalPage.SEND_ACCOUNT_INVITE]: AddPatientModalStep.INVITE,
  [AddPatientModalPage.ASSESSMENTS_PRODUCT_ANNOUNCEMENT]:
    AddPatientModalStep.ASSESSMENTS,
  [AddPatientModalPage.SEND_ASSESSMENTS]: AddPatientModalStep.ASSESSMENTS,
};

export type AddPatientModalContextType = {
  isOpen: boolean;
  closeAddPatientModal: () => void;
  steps: AddPatientModalStep[];
  currentStep: AddPatientModalPage;
  setCurrentStep: (step: AddPatientModalPage) => void;
  modalProgressProps?: {
    value: number;
    min: number;
    max: number;
    valueLabel: string;
  };
};

export const AddPatientModalContext = createContext<AddPatientModalContextType>(
  {
    isOpen: false,
    closeAddPatientModal: () => {},
    steps: [],
    currentStep: AddPatientModalPage.BASIC_INFO,
    setCurrentStep: () => {},
    modalProgressProps: undefined,
  }
);

export const AddPatientModalContextProvider = ({
  children,
  isOpen,
  onClose,
  onStepChange,
}: {
  children: ({
    currentStep,
    closeAddPatientModal,
    modalProgressProps,
  }: {
    currentStep: AddPatientModalContextType['currentStep'];
    closeAddPatientModal: AddPatientModalContextType['closeAddPatientModal'];
    modalProgressProps: AddPatientModalContextType['modalProgressProps'];
  }) => ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onStepChange?: (step: AddPatientModalPage) => void;
}) => {
  const [currentStep, setCurrentStep] = useState<AddPatientModalPage>(
    AddPatientModalPage.BASIC_INFO
  );

  const steps = useAddPatientModalSteps();

  const modalProgressProps = getModalProgressProps(currentStep, steps);

  const closeAddPatientModal = () => {
    setCurrentStep(AddPatientModalPage.BASIC_INFO);
    onClose();
  };

  const contextValue: AddPatientModalContextType = {
    isOpen,
    closeAddPatientModal,
    steps,
    currentStep,
    setCurrentStep: (step: AddPatientModalPage) => {
      setCurrentStep(step);
      if (onStepChange) {
        onStepChange(step);
      }
    },
    modalProgressProps,
  };

  return (
    <AddPatientModalContext.Provider value={contextValue}>
      {children({
        currentStep,
        closeAddPatientModal,
        modalProgressProps,
      })}
    </AddPatientModalContext.Provider>
  );
};
