import { ProviderTreatmentPlanApi } from '@headway/api/resources/ProviderTreatmentPlanApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface useProviderTreatmentPlanAttachmentsQueryKeyArgs {
  treatmentPlanId: number | undefined;
}

export const getUseTreatmentPlanAttachmentsQueryKey = ({
  treatmentPlanId,
}: useProviderTreatmentPlanAttachmentsQueryKeyArgs): [
  string,
  number | undefined,
] => ['get-provider-treatment-plan-attachments', treatmentPlanId];

const {
  useSingleQuery: useProviderTreatmentPlanAttachments,
  useListQuery: useProviderTreatmentPlanAttachmentsList,
  useCachedQuery: useProviderTreatmentPlanAttachmentsCache,
} = createBasicApiHooks(
  getUseTreatmentPlanAttachmentsQueryKey,
  ({ treatmentPlanId }) =>
    ProviderTreatmentPlanApi.getTreatmentPlanAttachments(treatmentPlanId!),
  ({ treatmentPlanId }) => !!treatmentPlanId,
  () => 'Failed to fetch treatment plan attachments'
);

export {
  useProviderTreatmentPlanAttachments,
  useProviderTreatmentPlanAttachmentsList,
  useProviderTreatmentPlanAttachmentsCache,
};
