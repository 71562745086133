import React from 'react';

import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

interface SaveAndExitTreatmentPlanModalProps {
  open: boolean;
  onClose: () => void;
  onSaveAndExit: () => void;
}

export const SaveAndExitTreatmentPlanModal = ({
  open,
  onClose,
  onSaveAndExit,
}: SaveAndExitTreatmentPlanModalProps) => {
  return (
    <Modal title="Exit treatment Plan" isOpen={open} onDismiss={onClose}>
      <ModalContent>Your progress will be saved as a draft.</ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          onPress={() => {
            onSaveAndExit();
            onClose();
          }}
        >
          Exit
        </Button>
      </ModalFooter>
    </Modal>
  );
};
