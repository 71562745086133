import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';

import { SchemaComponent } from '../../schema/schema.types';
import { ViewComponent } from './ViewComponent';

export const FormRadio = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, options } = element;

  if (readOnly) {
    return <ViewComponent title={title} id={id} />;
  }

  return (
    <FormControl
      label={title}
      name={id}
      key={id}
      component={RadioGroup}
      optionalityText={isOptional ? 'Optional' : null}
      disabled={disabled}
    >
      {(options ?? []).map((option: string, i: number) => (
        <Radio key={i} value={option} disabled={disabled}>
          {option}
        </Radio>
      ))}
    </FormControl>
  );
};
