/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum SmsOptOutType {
    ALL = 'ALL',
    ALL_BLOCKING = 'ALL_BLOCKING',
    CANCELED_SESSION = 'CANCELED_SESSION',
    CAL_DIGEST = 'CAL_DIGEST',
    NEW_SESSION = 'NEW_SESSION'
}
