export const FRONT_END_CARRIER_CACHE_KEY = 'front-end-carriers';

export const SEARCHABLE_FRONT_END_CARRIER_CACHE_KEY =
  'searchable-front-end-carriers';

export const STATE_INSURANCE_CARRIER_CACHE_KEY = 'state-insurance-carriers';
export const FRONT_END_CARRIER_WITH_SUBSIDIARIES_CACHE_KEY =
  'front-end-carriers-with-subsidiaries';
export const USER_FREEZES_CACHE_KEY = 'user-freezes';
export const ALL_USER_FREEZES_CACHE_KEY = 'all-user-freezes';
export const PATIENT_APPOINTMENT_READINESS_CUTOFF_DATE =
  'event-autocancellation-date';
export const PROVIDER_USER_FREEZES_CACHE_KEY = 'provider-user-freezes';
