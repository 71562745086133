//// This module serves as a facade for the react-query module, exporting the same
//// api as the react-query module, but with some logic to ease the migration from
//// between versions of react-query.
import * as reactQueryV4 from '@tanstack/react-query';
import React from 'react';

export * from '@tanstack/react-query';

export const useQuery = function useQueryFacade(...args: [any, ...any]) {
  const query = reactQueryV4.useQuery(...args);

  // if options has the enabled key defined we need to map the new
  // isInitialLoading value to the old isLoading value
  let options = args[2];

  // if the first arg is an object literal then assume it is
  // the options object
  if (args[0] && typeof args[0] === 'object' && !Array.isArray(args[0])) {
    options = args[0];
  }

  if (options?.enabled !== undefined) {
    return {
      ...query,
      isLoading: query.isInitialLoading,
    };
  }

  return query;
} as typeof reactQueryV4.useQuery;

export const useQueries = function useQueriesFacade(options: any) {
  const all = reactQueryV4.useQueries(options);

  return all.map((query, index) => {
    const optionsForQuery = options.queries[index];

    // if options has the enabled key defined we need to map the new
    // isInitialLoading value to the old isLoading value
    if (optionsForQuery?.enabled !== undefined) {
      return {
        ...query,
        isLoading: query.isInitialLoading,
      };
    }

    return query;
  });
} as typeof reactQueryV4.useQueries;

export function makeQueryClient(): reactQueryV4.QueryClient {
  return new reactQueryV4.QueryClient();
}
