import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseGroupPracticeProvidersQueryKeyArgs {
  groupPracticeId: number;
  query?: Parameters<typeof GroupPracticeApi.getProvidersInGroupPractice>[1];
}

export const getUseGroupPracticeProvidersQueryKey = ({
  groupPracticeId,
  query,
}: UseGroupPracticeProvidersQueryKeyArgs): [
  string,
  number,
  (
    | Parameters<typeof GroupPracticeApi.getProvidersInGroupPractice>[1]
    | undefined
  ),
] => ['group-practice-providers', groupPracticeId, query];

const {
  useSingleQuery: useGroupPracticeProviders,
  useListQuery: useGroupPracticeProvidersList,
  useCachedQuery: useGroupPracticeProvidersCache,
} = createBasicApiHooks(
  getUseGroupPracticeProvidersQueryKey,
  ({ groupPracticeId, query }) => {
    return GroupPracticeApi.getProvidersInGroupPractice(groupPracticeId, query);
  },
  ({ groupPracticeId }) => !!groupPracticeId,
  () => 'Failed to fetch group practice providers'
);

export {
  useGroupPracticeProviders,
  useGroupPracticeProvidersList,
  useGroupPracticeProvidersCache,
};
