import * as React from 'react';

export const CredentialFormOutlined = (
  props: React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.938 11.938h4.166V3.896H3.896v12.208h8.041v-4.166zM2.5 17.5v-15h15v10l-5 5h-10zm3.604-6.104V10h3.75v1.396h-3.75zm0-3.334V6.668h7.792v1.396H6.104zm-2.208 8.042V3.896v12.208z"
      fill="currentColor"
    />
  </svg>
);
