import { css } from '@emotion/react';

import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { theme } from '@headway/ui/theme';

import {
  getDurationMinutes,
  getEventIdentifierClass,
  isAvailability,
  isPatientBooked,
} from './events/util/events';

export const calendarStyles = css`
  .rbc-calendar {
    --hour-height: 80px;
  }

  .rbc-event svg {
    pointer-events: none;
  }

  .rbc-selected:not(.rbc-event-external):not(.rbc-background-event) {
    box-shadow:
      0px 4px 1px -3px rgba(0, 0, 0, 0.2),
      0px 1px 7px 0px rgba(0, 0, 0, 0.24),
      0px 1px 7px 0px rgba(0, 0, 0, 0.12);
  }

  .rbc-date-cell.rbc-now.rbc-current {
    color: ${theme.color.primary};
  }

  .rbc-event-availability .rbc-addons-dnd-resize-ns-icon {
    display: none !important;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-toolbar-label {
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.bold};
  }

  .rbc-time-view,
  .rbc-month-view {
    height: calc(100vh - 299px);
  }

  .rbc-time-content {
    border: none;
    background-color: var(--calendar-background);
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-current-time-indicator {
    height: 1px;
    color: ${theme.color.primary};
    background: currentcolor;
    box-shadow: currentcolor 0px 0px 4px;
    width: 100%;
  }

  .rbc-current-time-indicator::after {
    top: 0;
    left: 0;
    position: absolute;
    content: '';
    background-color: currentcolor;
    display: block;
    transform: translate(-5px, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  ${theme.media.medium} {
    .rbc-time-view,
    .rbc-month-view {
      height: calc(100vh - 218px);
    }
  }

  .rbc-timeslot-group {
    min-height: var(--hour-height);
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid var(--grid-line-color);
  }

  ${theme.media.medium} {
    .rbc-time-header.rbc-overflowing {
      margin-right: 15px;
    }
  }

  .rbc-time-header.rbc-overflowing {
    border-right: none;
    margin-right: 0;
  }

  .rbc-time-header-cell {
    flex-shrink: 0;
  }

  .rbc-allday-cell {
    border-bottom: 1px solid #ddd;

    .rbc-row-content {
      height: auto;

      .rbc-addons-dnd-row-body {
        height: auto;
        display: inline;

        .rbc-row {
          min-height: 0px;
        }
      }
    }
  }

  .rbc-time-view .rbc-header {
    border-bottom: none;
  }

  .rbc-view-week .rbc-header + .rbc-header,
  .rbc-view-work_week .rbc-header + .rbc-header {
    border: none;
  }

  .rbc-view-week .rbc-header.rbc-today,
  .rbc-view-work_week .rbc-header.rbc-today {
    border-bottom: 4px solid ${theme.color.primary};
  }

  .rbc-header > a:hover,
  .rbc-header > a:active {
    color: ${theme.color.primary};
  }

  .rbc-row-content {
    height: 100%;
  }

  .rbc-addons-dnd-row-body {
    overflow: scroll;
    height: calc(100% - 19px);
  }

  .rbc-time-view {
    border: none;
  }

  .rbc-time-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
    background: ${theme.color.background};
  }

  ${theme.media.medium} {
    .rbc-time-gutter {
      padding-left: 0;
      padding-right: 1rem;
    }
  }

  .rbc-timeslot-group:first-child .rbc-time-slot .rbc-label {
    visibility: hidden;
  }

  .rbc-time-gutter .rbc-timeslot-group {
    border: none;
  }

  .rbc-time-slot .rbc-label {
    font-size: ${theme.fontSize.xs};
    font-weight: bold;
    transform: translateY(-50%);
    display: block;
    text-align: center;
    color: ${theme.color.primaryDark};
    border-radius: 12px;
    padding: 5px;
  }

  .rbc-events-container {
    border: none;
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }

  .rbc-time-content > * + * > * {
    border-left: none;
  }

  .rbc-day-slot {
    border-left: 1px solid #ddd;
  }

  .rbc-time-gutter + .rbc-day-slot {
    border-left: none;
  }

  .rbc-time-slot {
    position: relative;
  }

  .rbc-time-header-content {
    border-left: none;
  }

  .rbc-time-gutter + .rbc-day-slot .rbc-time-slot:not(:first-child)::before {
    top: 0;
    left: 0;
    position: absolute;
    content: '';
    background-color: var(--grid-dot-color);

    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: block;
    transform: translate(calc(-50% - 8px), -50%);
  }

  .rbc-time-header-gutter {
    font-size: ${theme.fontSize.xs};
  }

  .rbc-event:not(.rbc-event-external) {
    box-shadow:
      rgba(18, 18, 18, 0.1) 0px 4px 5px -1px,
      rgba(18, 18, 18, 0.06) 0px 2px 4px -1px;
  }

  .rbc-view-week .rbc-event,
  .rbc-view-work_week .rbc-event {
    transition: transform 25ms ease-in;
  }

  .rbc-view-week .rbc-event:not(.rbc-event-working-hours):hover,
  .rbc-view-work_week .rbc-event:not(.rbc-event-working-hours):hover {
    transform: scale(1.02);
    box-shadow:
      rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }

  .rbc-event-external {
    border-radius: 0;
  }

  .rbc-calendar:not(.rbc-view-month) .rbc-event.rbc-event-duration-15 {
    padding-top: 1px;
  }
`;

export function eventPropGetter(event: ProviderEventRead) {
  // hack to place event popover over calendar event
  const eventIdClass = getEventIdentifierClass(event);

  const durationClass = `rbc-event-duration-${getDurationMinutes(event)}`;

  if (isAvailability(event)) {
    return {
      className: `rbc-event-working-hours ${eventIdClass} ${durationClass}`,
      style: {
        color: theme.color.black,
        border: 'none',
      },
    };
  }

  if (isAvailability(event)) {
    return {
      className: `rbc-event-availability ${eventIdClass} ${durationClass}`,
      style: {
        background: theme.color.white,
        color: theme.color.black,
        border: `1px solid ${theme.color.border}`,
      },
    };
  }

  if (event.channel === ProviderEventChannel.EXTERNAL_CALENDAR) {
    return {
      className: `rbc-event-external ${eventIdClass} ${durationClass}`,
      style: {
        width: '100% !important',
        color: theme.color.textGray,
        border: `1px solid ${theme.color.border}`,
        background: `repeating-linear-gradient(
          -45deg,
          hsl(154, 2%, 92%),
          hsl(154, 2%, 92%) 10px,
          hsl(154, 0%, 95%) 10px,
          hsl(154, 0%, 95%) 20px
        )`,
      },
    };
  }

  if (event.type === ProviderEventType.BUSY) {
    return {
      className: `rbc-event-busy ${eventIdClass} ${durationClass}`,
      style: {
        background: 'hsl(154, 4%, 96%)',
        color: theme.color.textGray,
        border: `1px solid ${theme.color.border}`,
      },
    };
  }

  return {
    className: `rbc-event-appointment ${eventIdClass} ${durationClass}`,
    style: isPatientBooked(event)
      ? {
          background: 'hsla(204, 88%, 95%)',
          color: 'hsla(204, 95%, 20%)',
          border: '1px solid hsla(204, 95%, 35%)',
        }
      : {
          background: 'hsla(154, 88%, 95%)',
          color: 'hsla(154, 95%, 20%)',
          border: '1px solid hsla(154, 95%, 35%)',
        },
  };
}

export const managedAvailabilityStyle = css`
  .rbc-calendar {
    --calendar-background: hsl(0deg, 0%, 91%);
    --grid-line-color: hsl(0deg, 0%, 86%);
    --grid-dot-color: var(--grid-line-color);
  }

  .rbc-day-slot .rbc-event {
    z-index: 2;
  }

  .rbc-day-slot .rbc-background-event.rbc-event-working-hours {
    border-radius: 0;
    background: repeating-linear-gradient(
      0deg,
      hsl(0deg, 0%, 92%),
      hsl(0deg, 0%, 92%) 1px,
      hsl(0deg, 0%, 98%) 1px,
      hsl(0deg, 0%, 98%) calc(var(--hour-height) / 4)
    );
    cursor: auto;
    opacity: 1;
    overflow: visible;
    z-index: 1;
    box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;

export const legacyCalendarStyle = css`
  .rbc-calendar {
    --calendar-background: ${theme.color.background};
    --grid-line-color: #efefef;
    --grid-dot-color: #e4e4e4;
  }

  .rbc-view-week .rbc-day-slot.rbc-today,
  .rbc-view-work_week .rbc-day-slot.rbc-today {
    background: #eee;
  }

  .rbc-view-week .rbc-day-slot.rbc-today .rbc-time-slot,
  .rbc-view-work_week .rbc-day-slot.rbc-today .rbc-time-slot {
    --grid-line-color: #e4e4e4;
  }
`;
