import { AxiosResponse } from 'axios';

import { PlanType } from '@headway/api/models/PlanType';

import { axios } from './axios.config';

export class ProviderFeeScheduleApi {
  static findProviderFeeSchedules(
    params: ProviderFeeScheduleFind
  ): Promise<ProviderFeeSchedule[]> {
    return axios
      .get('/provider-fee-schedules', { params })
      .then((res: AxiosResponse) => {
        return res.data;
      });
  }
}

interface FrontEndCarrier {
  id: number;
  name: string;
}

export interface ProviderFeeSchedule {
  id: number;
  provider_id: number;
  effective_date: string;
  cpt_code: string;
  amount: number;
  front_end_carrier: FrontEndCarrier;
  plan_type?: PlanType;
  provider_license_state_id: number;
}

interface ProviderFeeScheduleFind {
  provider_id?: number;
  only_most_recent?: boolean;
  max_effective_date?: string;
  front_end_carrier_ids?: number[];
  provider_license_state_id?: number;
  plan_types?: PlanType[];
}
