import React from 'react';

import { SHOW_PROVIDER_MESSAGING_UI_BADGE_DOT } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { BadgeDot } from '@headway/ui/BadgeDot';

import { useUnreadProviderMessages } from '../../hooks/useProviderUnreadMessages';
import { useAuthStore } from '../../stores/AuthStore';

const ProviderMessagesBadgeDotImpl: React.FC = (props) => {
  const AuthStore = useAuthStore();
  const providerId = AuthStore.provider.id;

  const badgeEnabled = useFlag(SHOW_PROVIDER_MESSAGING_UI_BADGE_DOT, false);

  const result = useUnreadProviderMessages(
    {
      providerId: providerId,
    },
    {
      enabled: badgeEnabled,
    }
  );

  const visible = result.data !== undefined && result.data.length > 0;

  return <BadgeDot hidden={!visible} variant="positive" />;
};

export const ProviderMessagesBadgeDot = ProviderMessagesBadgeDotImpl;
