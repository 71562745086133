/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { AppointmentInfoForClaimCorrections } from '../models/AppointmentInfoForClaimCorrections';
import { ChargePartiallyPaidPatientRequest } from '../models/ChargePartiallyPaidPatientRequest';
import { ChargePatientRequest } from '../models/ChargePatientRequest';
import { ClaimCorrectionsRequest } from '../models/ClaimCorrectionsRequest';
import { ClaimRead } from '../models/ClaimRead';
import { ClaimStatusRead } from '../models/ClaimStatusRead';
import { ClaimSubmissionPlatformVersion } from '../models/ClaimSubmissionPlatformVersion';
import { CodingEnforcementIssue } from '../models/CodingEnforcementIssue';
import { EnforceAppointmentCodingRulesInput } from '../models/EnforceAppointmentCodingRulesInput';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { InvoicesRead } from '../models/InvoicesRead';
import { PatientResponsibilityUpdateRequest } from '../models/PatientResponsibilityUpdateRequest';
import { ProviderAppointmentAddendumCreate } from '../models/ProviderAppointmentAddendumCreate';
import { ProviderAppointmentAddendumRead } from '../models/ProviderAppointmentAddendumRead';
import { ProviderAppointmentBulkClearRecoupBalanceResponse } from '../models/ProviderAppointmentBulkClearRecoupBalanceResponse';
import { ProviderAppointmentBulkRefundRequest } from '../models/ProviderAppointmentBulkRefundRequest';
import { ProviderAppointmentBulkRefundResponse } from '../models/ProviderAppointmentBulkRefundResponse';
import { ProviderAppointmentBulkUpdateProviderPaymentStatusResponse } from '../models/ProviderAppointmentBulkUpdateProviderPaymentStatusResponse';
import { ProviderAppointmentPatientResponsibilityPreview } from '../models/ProviderAppointmentPatientResponsibilityPreview';
import { ProviderAppointmentRead } from '../models/ProviderAppointmentRead';
import { ProviderAppointmentRefundRequest } from '../models/ProviderAppointmentRefundRequest';
import { UpdateChargeDateRequest } from '../models/UpdateChargeDateRequest';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderAppointmentApiResource {

    /**
     * Bulk Clear Recoup Balances
     * 
     * @param requestBody 
     
     */
    public bulkClearRecoupBalances(body: Array<number>, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentBulkClearRecoupBalanceResponse> {
        const apiRoute = '/provider-appointment/clear-recoup-balances';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Refund Patient
     * 
     * @param providerAppointmentBulkRefundRequest 
     
     */
    public bulkRefundPatient(body: ProviderAppointmentBulkRefundRequest, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentBulkRefundResponse> {
        const apiRoute = '/provider-appointment/bulk-refund-patient';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Resubmit Claims
     * 
     * @param requestBody 
     
     */
    public bulkResubmitClaims(body: Array<number>, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-appointment/resubmit-claims';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Update Provider Payment Statuses
     * 
     * @param file 
     * @param token 
     
     */
    public bulkUpdateProviderPaymentStatuses(file: any, query?: { token?: string }, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentBulkUpdateProviderPaymentStatusResponse> {
        const apiRoute = '/provider-appointment/update-provider-payment-statuses';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Charge Partially Paid Appointment
     * 
     * @param providerAppointmentId 
     * @param chargePartiallyPaidPatientRequest 
     
     */
    public chargePartiallyPaidAppointment(providerAppointmentId: number, body?: ChargePartiallyPaidPatientRequest, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/charge-partially-paid';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Charge Patient For Appointment
     * 
     * @param providerAppointmentId 
     * @param chargePatientRequest 
     
     */
    public chargePatientForAppointment(providerAppointmentId: number, body: ChargePatientRequest, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentRead> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/charge-patient';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Appointment Validation Details
     * 
     * @param enforceAppointmentCodingRulesInput 
     
     */
    public createAppointmentValidationDetails(body: EnforceAppointmentCodingRulesInput, axiosConfig?: AxiosRequestConfig): Promise<Array<CodingEnforcementIssue>> {
        const apiRoute = '/provider-appointment/validation-details';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider Appointment Addendum
     * 
     * @param providerAppointmentId 
     * @param providerAppointmentAddendumCreate 
     
     */
    public createProviderAppointmentAddendum(providerAppointmentId: number, body: ProviderAppointmentAddendumCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentAddendumRead> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/addendums';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Claim Report Statuses For Provider Appointment
     * 
     * @param providerAppointmentId 
     
     */
    public getClaimReportStatusesForProviderAppointment(providerAppointmentId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ClaimStatusRead>> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/claim-report-statuses';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Claim Statuses For Provider Appointment
     * 
     * @param providerAppointmentId 
     
     */
    public getClaimStatusesForProviderAppointment(providerAppointmentId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ClaimStatusRead>> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/claim-statuses';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Claims For Provider Appointment
     * 
     * @param providerAppointmentId 
     
     */
    public getClaimsForProviderAppointment(providerAppointmentId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ClaimRead>> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/claims';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Info For Claim Correction
     * 
     * @param submissionPlatform 
     * @param requestBody 
     
     */
    public getInfoForClaimCorrection(body: Array<number>, query?: { submission_platform: ClaimSubmissionPlatformVersion }, axiosConfig?: AxiosRequestConfig): Promise<AppointmentInfoForClaimCorrections> {
        const apiRoute = '/provider-appointment/get-claim-correction-info';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Invoices For Provider Appointment
     * 
     * @param providerAppointmentId 
     
     */
    public getInvoicesForProviderAppointment(providerAppointmentId: number, axiosConfig?: AxiosRequestConfig): Promise<InvoicesRead> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/invoices';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Latest Claims With R5
     * 
     * @param requestBody 
     
     */
    public getLatestClaimsWithR5(body: Array<number>, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-appointment/latest-claims-with-r5';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Appointment Addendums
     * 
     * @param providerAppointmentId 
     
     */
    public getProviderAppointmentAddendums(providerAppointmentId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderAppointmentAddendumRead>> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/addendums';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Preview Appointment Patient Responsibility With Lookup
     * 
     * @param providerAppointmentId 
     * @param eligibilityLookupId 
     
     */
    public previewAppointmentPatientResponsibilityWithLookup(providerAppointmentId: number, eligibilityLookupId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentPatientResponsibilityPreview> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/preview-new-lookup/{eligibility_lookup_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId))
                    .replace('{' + 'eligibility_lookup_id}', String(eligibilityLookupId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Process Patient Responsibility Update
     * 
     * @param patientResponsibilityUpdateRequest 
     
     */
    public processPatientResponsibilityUpdate(body: PatientResponsibilityUpdateRequest, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-appointment/process-patient-responsibility-update';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Refile Claims With Corrections
     * 
     * @param claimCorrectionsRequest 
     
     */
    public refileClaimsWithCorrections(body: ClaimCorrectionsRequest, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-appointment/refile-claims-with-corrections';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Refund Patient
     * 
     * @param providerAppointmentRefundRequest 
     
     */
    public refundPatient(body: ProviderAppointmentRefundRequest, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-appointment/refund-patient';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Submit Test Claims V3
     * Submit test claims to the Change Healthcare v3 professional claims submission API. Test payer claims are not sent to real payers. Used for manual verification of the new v3 claims request building as part of the Claim Filing Overhaul (Q3 2022).
     * @param requestBody 
     
     */
    public submitTestClaimsV3(body: Array<number>, axiosConfig?: AxiosRequestConfig): Promise<Array<ClaimRead>> {
        const apiRoute = '/provider-appointment/submit-test-claims-v3';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Autopay Charge Details
     * 
     * @param providerAppointmentId 
     * @param updateChargeDateRequest 
     
     */
    public updateAutopayChargeDetails(providerAppointmentId: number, body: UpdateChargeDateRequest, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentRead> {
        const apiRoute = '/provider-appointment/{provider_appointment_id}/update-autopay-charge-details';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderAppointmentApi = new ProviderAppointmentApiResource();
