import * as React from 'react';

export const Pencil = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3113 4.87821L14.1216 0.689461C13.9823 0.550137 13.8169 0.439617 13.6349 0.364213C13.4529 0.28881 13.2578 0.25 13.0608 0.25C12.8638 0.25 12.6687 0.28881 12.4867 0.364213C12.3047 0.439617 12.1393 0.550137 12 0.689461L0.439695 12.2498C0.299801 12.3886 0.188889 12.5538 0.113407 12.7358C0.0379245 12.9178 -0.000621974 13.113 7.58901e-06 13.3101V17.4998C7.58901e-06 17.8976 0.158043 18.2791 0.439347 18.5604C0.720652 18.8417 1.10218 18.9998 1.50001 18.9998H17.25C17.4489 18.9998 17.6397 18.9208 17.7803 18.7801C17.921 18.6395 18 18.4487 18 18.2498C18 18.0509 17.921 17.8601 17.7803 17.7194C17.6397 17.5788 17.4489 17.4998 17.25 17.4998H7.81126L18.3113 6.99977C18.4506 6.86048 18.5611 6.69511 18.6365 6.5131C18.7119 6.33109 18.7507 6.136 18.7507 5.93899C18.7507 5.74198 18.7119 5.5469 18.6365 5.36489C18.5611 5.18288 18.4506 5.0175 18.3113 4.87821ZM9.75001 5.06009L11.3147 6.62477L3.37501 14.5645L1.81032 12.9998L9.75001 5.06009ZM1.50001 17.4998V14.8101L4.18969 17.4998H1.50001ZM6.00001 17.1895L4.43626 15.6248L12.375 7.68509L13.9397 9.24977L6.00001 17.1895ZM15 8.18946L10.8113 3.99977L13.0613 1.74977L17.25 5.93946L15 8.18946Z"
      fill="#2C98EF"
    />
  </svg>
);
