import { KeyboardArrowRight } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';

interface SkeletonTableRowProps {
  numColumns: number;
  hasCheckboxColumn?: boolean;
}

export default function SkeletonTableRow({
  numColumns,
  hasCheckboxColumn = false,
}: SkeletonTableRowProps) {
  const cells = [];
  for (let i = 0; i < numColumns; i++) {
    cells.push(
      <TableCell key={i}>
        <Skeleton variant="text" />
      </TableCell>
    );
  }
  return (
    <TableRow>
      {hasCheckboxColumn && (
        <TableCell padding="checkbox">
          <IconButton disabled size="small">
            <KeyboardArrowRight />
          </IconButton>
        </TableCell>
      )}
      {cells}
    </TableRow>
  );
}
