import React from 'react';

import { Banner } from '@headway/helix/Banner';

export const GroupPracticeAdminBanner = () => {
  return (
    <Banner variant="neutral">
      <b>Group admin view:</b> Only the provider present at the client's
      sessions can write treatment plans. That said, a group admin may upload an
      existing plan.
    </Banner>
  );
};
