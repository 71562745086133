import { useProvider } from 'hooks';
import React, { useContext } from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { StateInsuranceCarrierRead } from '@headway/api/models/StateInsuranceCarrierRead';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import { Modal } from '@headway/ui';

import { Message } from 'api/MessagesApi';
import {
  useProviderEvent,
  useProviderEventCache,
} from 'hooks/useProviderEvent';
import {
  UpdateProviderEventMutationArgs,
  UpdateRecurringInstanceMutationArgs,
  useCreateProviderEventMutation,
  useUpdateProviderEventMutation,
  useUpdateRecurringInstanceAndAllFollowingMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';
import { NullableKeys } from 'utils/types';

import { isIntakeCall } from '../events/util/events';
import {
  AppointmentScheduleForm,
  AppointmentScheduleFormSubmitValues,
} from '../form/AppointmentScheduleForm';
import {
  EstimatedLiveDatesWithCarrierMap,
  EstimatedLiveDateWithCarrier,
} from '../LiveDateCalculator';
import { CalendarSlot } from '../utils/Calendar';
import {
  useCreateProviderEventSideEffectsForCalendar,
  useUpdateProviderEventSideEffectsForCalendar,
  useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar,
} from '../utils/queries';

interface AppointmentScheduleModalProps {
  isRescheduling: boolean;
  open: boolean;
  calendarSlot?: CalendarSlot;
  timeZone: string;
  onClose: () => void;
  checkNotify: any;
  minEstimatedLiveDate: EstimatedLiveDateWithCarrier;
  maxEstimatedLiveDate: EstimatedLiveDateWithCarrier;
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap;
  stateInsuranceCarriers: StateInsuranceCarrierRead[];
  eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
}

export const AppointmentScheduleModal = ({
  isRescheduling,
  open,
  calendarSlot,
  timeZone,
  onClose,
  checkNotify,
  minEstimatedLiveDate,
  maxEstimatedLiveDate,
  estimatedLiveDates,
  stateInsuranceCarriers,
  eventIdsToMatchingProviderFrontEndCarriers,
}: AppointmentScheduleModalProps) => {
  const { selectedEventVirtualId, setSelectedEventVirtualId } =
    useContext(SelectedEventContext);
  const { data: event } = useProviderEvent(
    {
      eventIdOrVirtualId: selectedEventVirtualId,
    },
    {
      enabled: isRescheduling || !calendarSlot,
    }
  );

  const provider = useProvider();
  const providerEventCache = useProviderEventCache();

  const createEventMutation = useCreateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      ProviderEventCreate
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useCreateProviderEventSideEffectsForCalendar()),
  });
  const updateEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar()),
  });
  const updateEventAndAllFollowingMutation =
    useUpdateRecurringInstanceAndAllFollowingMutation({
      sideEffects: new SideEffectsBuilder<
        ProviderEventRecurrenceUpdateResponse,
        unknown,
        UpdateRecurringInstanceMutationArgs
      >()
        .add({
          onSuccess: (result) => {
            providerEventCache.set(
              { eventIdOrVirtualId: result.updatedInstance.virtualId },
              result.updatedInstance
            );
          },
        })
        .merge(
          useUpdateRecurringInstanceAndAllFollowingMutationSideEffectsForCalendar()
        ),
    });

  const handleSubmit = async ({
    recurringUpdateApplyTo,
    ...values
  }: AppointmentScheduleFormSubmitValues) => {
    const { appointmentLocationPatientAddressId, ...providerEvent } = values;
    const scheduleValues: ProviderEventCreate = {
      ...providerEvent,
      providerAppointment: {
        status: ProviderAppointmentStatus.SCHEDULED,
        providerId: provider.id,
        userId: values.patientUserId,
        appointmentLocationPatientAddressId:
          appointmentLocationPatientAddressId,
      },
      providerId: provider.id,
    } as NullableKeys<ProviderEventCreate> as ProviderEventCreate;
    const created = await createEventMutation.mutateAsync(scheduleValues);
    setSelectedEventVirtualId(created.virtualId);

    onClose();
    created && checkNotify(Message.Type.PROVIDER_BOOKING);
  };

  const handleRescheduleSubmit = async ({
    recurringUpdateApplyTo,
    recurrence,
    ...updateValues
  }: AppointmentScheduleFormSubmitValues) => {
    if (isRescheduling && event) {
      const { appointmentLocationPatientAddressId, ...updatedEvent } =
        updateValues;
      const originalStartDate = event.startDate;
      const originalTimezone = event.timeZone;
      let updated;

      if (recurringUpdateApplyTo === RecurringUpdateApplyTo.FOLLOWING_EVENTS) {
        updated = await updateEventAndAllFollowingMutation.mutateAsync({
          virtualId: event.virtualId,
          update: {
            ...updatedEvent,
            providerAppointment: {
              appointmentLocationPatientAddressId:
                appointmentLocationPatientAddressId,
            },
          } as NullableKeys<ProviderEventUpdate> as ProviderEventUpdate,
        });
        setSelectedEventVirtualId(updated.updatedInstance.virtualId);
      } else {
        updated = await updateEventMutation.mutateAsync({
          eventIdOrVirtualId: event.virtualId,
          update: {
            ...updatedEvent,
            providerAppointment: {
              appointmentLocationPatientAddressId:
                appointmentLocationPatientAddressId,
            },
          } as NullableKeys<ProviderEventUpdate> as ProviderEventUpdate,
        });
        setSelectedEventVirtualId(updated.virtualId);
      }

      onClose();
      updated &&
        checkNotify(Message.Type.PROVIDER_RESCHEDULE, {
          old_start_date: originalStartDate,
          old_time_zone: originalTimezone,
        });
    }
  };

  const calendarSlotOrEvent = isRescheduling ? event : calendarSlot ?? event;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${isRescheduling ? 'Update' : 'Add'} ${
        isIntakeCall(event as ProviderEventRead)
          ? 'phone consultation'
          : 'session'
      }`}
    >
      {calendarSlotOrEvent ? (
        <AppointmentScheduleForm
          isRescheduling={isRescheduling}
          event={calendarSlotOrEvent}
          provider={provider}
          timeZone={timeZone}
          onSubmit={isRescheduling ? handleRescheduleSubmit : handleSubmit}
          submitLabel="Schedule"
          minEstimatedLiveDate={minEstimatedLiveDate}
          maxEstimatedLiveDate={maxEstimatedLiveDate}
          estimatedLiveDates={estimatedLiveDates}
          stateInsuranceCarriers={stateInsuranceCarriers}
          eventIdsToMatchingProviderFrontEndCarriers={
            eventIdsToMatchingProviderFrontEndCarriers
          }
        />
      ) : null}
    </Modal>
  );
};
