import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { PatientFormRead } from '@headway/api/models/PatientFormRead';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPatientUpdate } from '@headway/api/models/ProviderPatientUpdate';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { UserCommunicationRead } from '@headway/api/models/UserCommunicationRead';
import { PatientFormApi } from '@headway/api/resources/PatientFormApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useUser } from '@headway/shared/hooks/useUser';
import { getUseUserQueryKey } from '@headway/shared/hooks/useUser';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { useQueryParams } from '@headway/shared/utils/queryParams';
import { logException } from '@headway/shared/utils/sentry';
import { usePatientAssessments } from '@headway/ui/hooks/usePatientAssessments';
import { notifyError } from '@headway/ui/utils/notify';

import { useFindPastSessionsQueryKeyArgs } from 'hooks/useFindPastSessionsQueryKeyArgs';
import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { usePatientForms } from 'hooks/usePatientForm';
import { useProvider } from 'hooks/useProvider';
import { getUseProviderPatientQueryKey } from 'hooks/useProviderPatient';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';
import { PrescriberReferralModal } from 'views/Patients/PrescriberReferralModal';

import { ClinicalText } from './ClinicalText';
import { IntakeFormsTable } from './IntakeFormsTable';
import { PastSessions } from './PastSessions';
import { ProgressNoteDownloadAgreementModal } from './ProgressNotesDownloads/ProgressNoteDownloadAgreementModal';
import { ProviderPatientAttachments } from './ProviderPatientAttachments';
import { ResendEmailReminderModal } from './ResendEmailReminderModal';
import { TreatmentPlan as StructuredTreatmentPlan } from './TreatmentPlan/TreatmentPlan';
import { trackEditButtonClickedEvent } from './TreatmentPlan/TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
  TreatmentPlanContextProvider,
} from './TreatmentPlan/TreatmentPlanContext';

export interface ClinicalDetailProps {
  clientId: number;
  providerPatient: ProviderPatientRead;
}

interface TreatmentPlanURLParams {
  treatmentPlan?: string;
  treatmentPlanId?: string;
}

export const ClinicalDetailImp = ({
  clientId,
  providerPatient,
}: ClinicalDetailProps) => {
  const [isPrescriberReferralModalOpen, setIsPrescriberReferralModalOpen] =
    useState<boolean>(false);
  const [
    isProgressNoteDownloadAgreementModalOpen,
    setIsProgressNoteDownloadAgreementModalOpen,
  ] = useState<boolean>(false);
  const [isEditingIntakeNote, setIsEditingIntakeNote] =
    useState<boolean>(false);
  const [isEditingTreatmentPlan, setIsEditingTreatmentPlan] =
    useState<boolean>(false);
  const [isResendEmailReminderModalOpen, setIsResendEmailReminderModalOpen] =
    useState<boolean>(false);
  const { editTreatmentPlan, setTreatmentPlan, setPage } =
    useContext(TreatmentPlanContext);
  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpoofingAsMedicalRecordAuditor = isSpoofing
    ? isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles)
    : false;

  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();

  const isPrescriberReferralFlagEnabled = useFlag('prescriberReferral');
  const queryClient = useQueryClient();
  const intakeNotesRef = useRef<HTMLHeadingElement>(null);
  const treatmentPlanRef = useRef<HTMLHeadingElement>(null);
  const progressNotesTemplatesFF = useFlag('progressNoteTemplates', false);
  const navigate = useNavigate();
  const params = useQueryParams<TreatmentPlanURLParams>();
  const { isLoading, data: treatmentPlansQuery } = useProviderTreatmentPlans(
    {
      providerPatientId: providerPatient?.id,
    },
    {
      enabled: !!progressNotesTemplatesFF.enabled,
    }
  );

  useEffect(() => {
    if (params.treatmentPlan && !isLoading) {
      const treatmentPlanId = params.treatmentPlanId;
      if (
        treatmentPlanId &&
        treatmentPlansQuery &&
        !!progressNotesTemplatesFF.enabled
      ) {
        const treatmentPlanById = treatmentPlansQuery.find(
          (template) => template.id === Number(treatmentPlanId)
        );
        if (treatmentPlanById) {
          editTreatmentPlan(treatmentPlanById);
          setTreatmentPlan(treatmentPlanById);
          if (treatmentPlanById.attestedOn) {
            setPage(TREATMENT_PLAN_PAGES.PREVIEW);
          } else {
            switch (treatmentPlanById.planType) {
              case TreatmentPlanType.TEMPLATE:
                setPage(TREATMENT_PLAN_PAGES.SUMMARY);
                break;
              case TreatmentPlanType.TEXT:
                setPage(TREATMENT_PLAN_PAGES.TEXT);
                break;
              case TreatmentPlanType.UPLOAD:
                setPage(TREATMENT_PLAN_PAGES.UPLOAD);
                break;
            }
          }

          setIsEditingTreatmentPlan(true);
        }
      } else {
        setIsEditingTreatmentPlan(true);
      }
      navigate(`/clients/${clientId}/clinical`, { replace: true });
    }
  }, [
    navigate,
    params,
    clientId,
    providerPatient,
    treatmentPlansQuery,
    progressNotesTemplatesFF,
    editTreatmentPlan,
    isLoading,
    setPage,
    setTreatmentPlan,
  ]);

  const providerPatientQueryKey = getUseProviderPatientQueryKey({
    providerId: provider.id,
    patientId: clientId,
  });
  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });

  const canViewTreatmentPlan = !(
    providerPatient?.treatmentPlanLength && !providerPatient?.treatmentPlan
  );

  const updateProviderPatient = useMutation(
    (update: ProviderPatientUpdate) =>
      ProviderPatientApi.updateProviderPatient(providerPatient!.id, update),
    {
      onMutate: (update: ProviderPatientUpdate) => {
        queryClient.setQueryData(providerPatientQueryKey, {
          ...providerPatient,
          ...update,
        });
        return { previous: providerPatient };
      },
      onError: (err: any, update, context) => {
        if (context) {
          queryClient.setQueryData(providerPatientQueryKey, context.previous);
        }
        notifyError('Failed to save changes');
        logException(err);
      },
      onSettled: () => {
        if (client) {
          queryClient.invalidateQueries(providerPatientQueryKey);
        }
      },
    }
  );

  const pastSessionQueryKeyArgs = useFindPastSessionsQueryKeyArgs({
    providerId: provider.id,
    clientId: clientId,
  });

  const { isLoading: appointmentAreLoading, data: pastAppointments } =
    useFindProviderEvents(pastSessionQueryKeyArgs, {
      select: ({ data }) => data,
    });

  const pastAppointmentIdsWithNotes = pastAppointments?.filter((event) => {
    return (
      event.providerAppointment?.progressNoteType !== ProgressNoteType.NONE
    );
  });

  const allAppointmentIdsWithNotes =
    pastAppointmentIdsWithNotes?.reduce<number[]>((accum, datum) => {
      if (datum.providerAppointment) {
        return accum.concat(datum.providerAppointment.id);
      }

      return accum;
    }, []) || [];

  const {
    mutate: sendIntakeFormEmailToPatient,
    isLoading: isSendIntakeFormEmailToPatient,
  } = useMutation(
    async () => {
      await PatientFormApi.sendIntakeFormEmailToPatient(providerPatient.id);
    },
    {
      onSuccess: async () => {
        await UserApi.updateUser(clientId, { isInvited: true });
        queryClient.invalidateQueries(getUseUserQueryKey({ userId: clientId }));
      },
    }
  );

  const handleCloseAgreementModal = () => {
    setIsProgressNoteDownloadAgreementModalOpen(false);
  };

  const { data: intakeForms, isLoading: isPatientFormsLoading } =
    usePatientForms({
      providerPatientIds: [providerPatient.id],
      deletionStatus: ['ACTIVE', 'ARCHIVED'],
    });
  const handleSendIntakeFormEmailToPatient = async () => {
    await sendIntakeFormEmailToPatient();
    /**
     * Map over all intake forms associated with this provider and patient pair to update sentOn.
     * sentOn is used to track the most recent time an intake form email was sent this patient,
     * regardless of the specific intake form.
     */
    if (intakeForms) {
      await Promise.all(
        intakeForms.map(async (intakeForm: PatientFormRead) => {
          await PatientFormApi.updatePatientForm(
            providerPatient.id,
            Number(intakeForm.id),
            {
              sentOn: new Date().toISOString(),
            }
          );
        })
      );
    }

    await queryClient.invalidateQueries({
      queryKey: ['patient-forms'],
    });
  };

  const handleSentReminderPress = async () => {
    if (intakeEmailSentRecently(intakeForms, 24)) {
      setIsResendEmailReminderModalOpen(true);
    } else if (!intakeEmailSentRecently(intakeForms, 1)) {
      try {
        await handleSendIntakeFormEmailToPatient();

        toasts.add(
          `We sent a reminder to ${client?.displayFirstName} to complete their intake forms`,
          {
            variant: 'positive',
          }
        );
      } catch (e) {
        toasts.add('Failed to send a reminder to complete intake forms', {
          variant: 'negative',
        });
      }
    }
  };

  const areIntakeFormsIncomplete =
    (intakeForms &&
      !isPatientFormsLoading &&
      intakeForms.some((patientForm) => !patientForm.submittedOn)) ||
    false;

  const { data: sentWhodasToPatient } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      assessment_type: PatientAssessmentType.WHODAS_2,
      include_expired: true,
      limit: 1,
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.totalCount > 0;
      },
    }
  );

  const { data: sentPromisToPatient } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      assessment_type: PatientAssessmentType.PROMIS,
      include_expired: true,
      limit: 1,
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.totalCount > 0;
      },
    }
  );

  const isNqfConsentFormIncomplete =
    (sentWhodasToPatient || sentPromisToPatient) && !user.consentedToNqfOn;

  const showSendReminderButton =
    areIntakeFormsIncomplete || isNqfConsentFormIncomplete;

  return (
    <div css={clinicalDetailCss.container}>
      {client && (
        <div css={clinicalDetailCss.intakeForms}>
          <h2>
            <SectionHeader>Intake forms</SectionHeader>
            {showSendReminderButton && (
              <Button
                size="large"
                variant="link"
                disabled={intakeEmailSentRecently(intakeForms, 1)}
                onPress={handleSentReminderPress}
              >
                Send reminder
              </Button>
            )}
          </h2>
          <IntakeFormsTable
            client={client}
            providerPatient={providerPatient}
            aria-labelledby="intake-forms-label"
            isSendIntakeFormEmailToPatient={isSendIntakeFormEmailToPatient}
            handleSendIntakeFormEmailToPatient={
              handleSendIntakeFormEmailToPatient
            }
          />
        </div>
      )}
      {client && isResendEmailReminderModalOpen && (
        <ResendEmailReminderModal
          open={isResendEmailReminderModalOpen}
          onClose={() => setIsResendEmailReminderModalOpen(false)}
          client={client}
          isSendIntakeFormEmailToPatient={isSendIntakeFormEmailToPatient}
          handleSendIntakeFormEmailToPatient={
            handleSendIntakeFormEmailToPatient
          }
        />
      )}
      {(providerPatient?.referrerNote || providerPatient?.referralIssues) && (
        <>
          <PageSection css={clinicalDetailCss.referralInfo}>
            <h2>
              <SectionHeader>Referral Information</SectionHeader>
            </h2>
            <GuidanceCard variant="warning">
              These notes are provided by the physician who referred the client.
              Please take care not to share the exact notes with the patient.
            </GuidanceCard>
            <p>
              <BodyText>
                Patient concerns: {providerPatient?.referralIssues?.join(', ')}
              </BodyText>
            </p>
            <p>
              <BodyText>{providerPatient?.referrerNote}</BodyText>
            </p>
          </PageSection>
        </>
      )}
      {isPrescriberReferralFlagEnabled && (
        <>
          <PageSection css={clinicalDetailCss.psychiatricCareReferral}>
            <h2>
              <SectionHeader>Psychiatric care referral</SectionHeader>
              <Badge variant="positive">Beta</Badge>
              <Button
                size="large"
                variant="link"
                onPress={() => setIsPrescriberReferralModalOpen(true)}
              >
                Refer client
              </Button>
            </h2>
            <div>
              <BodyText>
                Refer to an in-network Headway psychiatric NP or psychiatrist.
              </BodyText>
            </div>
          </PageSection>
          <PrescriberReferralModal
            open={isPrescriberReferralModalOpen}
            onClose={() => setIsPrescriberReferralModalOpen(false)}
            provider={provider}
            patientUserId={clientId}
          />
        </>
      )}
      <PageSection css={clinicalDetailCss.clinicalText}>
        <h2 ref={intakeNotesRef}>
          <SectionHeader>
            <span id="intake-note-label">Intake notes</span>
          </SectionHeader>
          {providerPatient && !isEditingIntakeNote && (
            <Button
              size="large"
              variant="link"
              onPress={() => setIsEditingIntakeNote(true)}
            >
              {providerPatient.intakeNote ? 'Edit' : 'Add'}
            </Button>
          )}
        </h2>
        {providerPatient ? (
          <ClinicalText
            fieldId="intakeNote"
            editing={isEditingIntakeNote}
            setEditing={(editing: boolean) => setIsEditingIntakeNote(editing)}
            clinicalText={providerPatient.intakeNote}
            onSave={async (textHtml: string) => {
              await updateProviderPatient.mutateAsync({
                intakeNote: textHtml,
              });
              intakeNotesRef.current?.scrollIntoView();
            }}
            emptyStateExplanation="You haven’t added any intake notes yet."
            aria-labelledby="intake-note-label"
          />
        ) : (
          <Skeleton variant="rectangular" height={64} />
        )}
      </PageSection>
      <PageSection
        className="intercom-treatment-plan-tour-target"
        css={clinicalDetailCss.clinicalText}
      >
        <h2 ref={treatmentPlanRef}>
          <SectionHeader>
            <span id="treatment-plan-label">Treatment plan</span>
          </SectionHeader>
          {providerPatient &&
            !isEditingTreatmentPlan &&
            canViewTreatmentPlan && (
              <Button
                size="large"
                variant="link"
                onPress={() => {
                  trackEditButtonClickedEvent(providerPatient);
                  setIsEditingTreatmentPlan(true);
                }}
              >
                {treatmentPlans && treatmentPlans.length > 0
                  ? 'Add or update plan'
                  : 'Create plan'}
              </Button>
            )}
        </h2>
        {providerPatient ? (
          <StructuredTreatmentPlan
            isEditing={isEditingTreatmentPlan}
            setIsEditing={setIsEditingTreatmentPlan}
            providerPatient={providerPatient}
          />
        ) : (
          <Skeleton variant="rectangular" height={64} />
        )}
      </PageSection>
      <PageSection css={clinicalDetailCss.attachments}>
        <h2>
          <SectionHeader>Attachments</SectionHeader>
        </h2>
        <ProviderPatientAttachments clientId={clientId} />
      </PageSection>
      <PageSection css={clinicalDetailCss.pastSessions}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>
            <SectionHeader>Past confirmed sessions</SectionHeader>
          </h2>
          {!appointmentAreLoading &&
            allAppointmentIdsWithNotes &&
            allAppointmentIdsWithNotes.length > 0 && (
              <Button
                variant="link"
                disabled={isSpoofing && !isSpoofingAsMedicalRecordAuditor}
                onPress={() => {
                  setIsProgressNoteDownloadAgreementModalOpen(true);
                  trackEvent({
                    name: 'Download All Progress Note Button Clicked',
                    properties: {
                      providerId: provider.id,
                      providerAppointmentIdList: allAppointmentIdsWithNotes,
                    },
                  });
                }}
              >
                Download all notes
              </Button>
            )}
        </div>
        {provider && client && (
          <ProgressNoteDownloadAgreementModal
            open={isProgressNoteDownloadAgreementModalOpen}
            onClose={async () => handleCloseAgreementModal()}
            providerId={provider.id}
            clientId={client.id}
            providerAppointmentIds={allAppointmentIdsWithNotes}
          />
        )}
        <div>
          <PastSessions clientId={clientId} />
        </div>
      </PageSection>
    </div>
  );
};

export const ClinicalDetail = (props: ClinicalDetailProps) => {
  return (
    <TreatmentPlanContextProvider providerPatient={props.providerPatient}>
      <ClinicalDetailImp {...props} />
    </TreatmentPlanContextProvider>
  );
};

export const intakeEmailSentRecently = (
  intakeForms: PatientFormRead[] | undefined,
  numHours: number
) =>
  intakeForms?.some(
    (intakeForm) =>
      moment(intakeForm.sentOn) >= moment().add(-numHours, 'hours')
  );

const clinicalDetailCss = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  referralInfo: css({
    gap: theme.spacing.x3,
    '& > p': { margin: 0 },
  }),
  psychiatricCareReferral: css({
    '& > h2': {
      display: 'flex',
      gap: theme.spacing.x2,
      alignItems: 'center',
      '& > :last-child': { marginLeft: 'auto' },
    },
    gap: theme.spacing.x3,
  }),
  clinicalText: css({
    '& > h2': {
      display: 'flex',
      justifyContent: 'space-between',
      scrollMarginTop: 100,
    },
    gap: theme.spacing.x3,
  }),
  clientAssessments: css({
    '& > h2': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gap: theme.spacing.x3,
  }),
  attachments: css({
    gap: theme.spacing.x3,
  }),
  pastSessions: css({
    gap: theme.spacing.x3,
  }),
  intakeForms: css({
    '& > h2': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing.x2,
      marginBottom: theme.spacing.x2,
    },
    padding: theme.spacing.x5,
    gap: theme.spacing.x3,
  }),
};
