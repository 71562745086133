import { css } from '@emotion/react';

import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';

export const StepIndicator = ({ children }: { children: React.ReactNode }) => (
  <div css={StepIndicatorCss.circle}>
    <SubBodyText>
      <strong>{children}</strong>
    </SubBodyText>
  </div>
);

const StepIndicatorCss = {
  circle: css({
    backgroundColor: theme.color.system.lightGray,
    borderRadius: '100%',
    padding: theme.spacing.x1,
    width: theme.spacing.x6,
    height: theme.spacing.x6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};
