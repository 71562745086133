import { Global } from '@emotion/react';
import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  InfoWindow,
  Map,
  MapControl,
  MapProps,
  Marker,
  useMap,
  useMapsLibrary,
  useMarkerRef,
} from '@vis.gl/react-google-maps';
import React from 'react';

import mapMarkerSelected from '@headway/icons/assets/marker-selected.svg';
import { logException } from '@headway/shared/utils/sentry';

interface GoogleMapEmbedProps extends MapProps {
  children?: React.ReactNode;
}

const mapStyles = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative',
    elementType: 'labels.text',
    stylers: [{ weight: 1 }],
  },
];

const ZOOM_CONTROL_OPTIONS = {
  position: ControlPosition.TOP_LEFT,
};
export function GoogleMapEmbed(props: GoogleMapEmbedProps) {
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <ErrorBoundaryNull>
      {!isClient ? (
        <div data-testid="map" />
      ) : (
        <Map
          scrollwheel={true}
          zoomControl={true}
          zoomControlOptions={ZOOM_CONTROL_OPTIONS}
          fullscreenControl={false}
          mapTypeControl={false}
          streetViewControl={false}
          styles={mapStyles}
          {...props}
        />
      )}
    </ErrorBoundaryNull>
  );
}
/**
 * This is a null error boundary that does not render anything.
 * It will still error in the console and report to Sentry, but it will
 * not render anything to the user.  In development, it will still render
 * the error message.
 */
class ErrorBoundaryNull extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    logException(error, {
      contexts: { react: { componentStack: info.componentStack } },
    });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

function InfoWindowStyled(props: React.ComponentProps<typeof InfoWindow>) {
  return (
    <>
      <Global
        styles={{
          '.gm-style-iw button[aria-label="Close"]': {
            top: '0 !important',
            right: '0 !important',
          },
        }}
      />
      <InfoWindow {...props} />
    </>
  );
}

function HeadwayMarker(props: React.ComponentProps<typeof Marker>) {
  if (typeof window === 'undefined' || !window.google?.maps?.Size) {
    return null;
  }

  return (
    <Marker
      icon={{
        url: mapMarkerSelected,
        // eslint-disable-next-line no-undef
        scaledSize: new google.maps.Size(50, 50),
      }}
      {...props}
    />
  );
}

export {
  AdvancedMarker,
  ControlPosition,
  InfoWindowStyled as InfoWindow,
  MapControl,
  Marker,
  HeadwayMarker,
  useMap,
  useMarkerRef,
  useMapsLibrary,
  APIProvider as GoogleMapsApiProvider,
};
