import { MarketRead } from '@headway/api/models/MarketRead';

export const MILES_PER_DEGREE = 69.0;
export const DEFAULT_SMALL_RADIUS_MILES = 1;
export const DEFAULT_LARGE_RADIUS_MILES = 5;
export const DEFAULT_EXLARGE_RADIUS_MILES = 1000;
export const DEFAULT_SMALL_ZOOM = 15;
export const DEFAULT_LARGE_ZOOM = 13;

export type Bounds = {
  lat: number;
  lon: number;
  lowerLat: number;
  lowerLon: number;
  upperLat: number;
  upperLon: number;
};

export const getMarketBounds = (market: MarketRead) => {
  const { latitude, longitude, searchRadiusMiles, searchDefaultZoom } = market;

  if (!latitude || !longitude) {
    throw new Error(
      `No latitude or longitude for market ${market.displayName}`
    );
  }
  return {
    ...latLngToBounds(latitude, longitude, searchRadiusMiles),
    state: market.state,
    zoom: searchDefaultZoom,
  };
};

export const latLngToBounds = (
  latitude: number,
  longitude: number,
  searchRadiusMiles: number = DEFAULT_SMALL_RADIUS_MILES
): Bounds => {
  return {
    lat: latitude,
    lon: longitude,
    lowerLat: latitude - searchRadiusMiles / MILES_PER_DEGREE,
    lowerLon: longitude - searchRadiusMiles / MILES_PER_DEGREE,
    upperLat: latitude + searchRadiusMiles / MILES_PER_DEGREE,
    upperLon: longitude + searchRadiusMiles / MILES_PER_DEGREE,
  };
};
