import { theme } from '@headway/helix/theme';

import { MOST_RECENT_ASSESSMENT_CLASSNAME } from '../../helpers/constants';

export const NegativeGlyph = ({
  cx,
  cy,
  setIsTooltipOpen,
  active,
  isMostRecentAssessment,
}: {
  cx: number;
  cy: number;
  setIsTooltipOpen: (toChange: boolean) => {};
  active?: boolean;
  isMostRecentAssessment: boolean;
}) => (
  <svg
    x={cx - 9}
    y={cy - 9}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onMouseEnter={() => setIsTooltipOpen(true)}
    onMouseLeave={() => setIsTooltipOpen(false)}
    className={isMostRecentAssessment ? MOST_RECENT_ASSESSMENT_CLASSNAME : ''}
  >
    <rect
      x="3"
      y="3"
      width="12"
      height="12"
      rx="1"
      fill={theme.color.primary.red}
      stroke={
        active ? theme.color.hue.lightSystemRed : theme.color.system.white
      }
      strokeWidth={active ? '6px' : '2px'}
    />
    <rect
      x="3"
      y="3"
      width="12"
      height="12"
      rx="1"
      fill={theme.color.primary.red}
    />
    {!active && (
      <rect x="6" y="6" width="6" height="6" fill={theme.color.system.white} />
    )}
  </svg>
);
