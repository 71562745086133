import HelloSignSDK from 'hellosign-embedded';
import React, { useRef } from 'react';
import * as Yup from 'yup';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { PayerQuestionnaireResponseApi } from '@headway/api/resources/PayerQuestionnaireResponseApi';
import { Form } from '@headway/form-builder';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';
import {
  UseFrontEndCarriers,
  useFrontEndCarriers,
} from '@headway/shared/hooks/useFrontEndCarriers';
import { isMedicareAdvantage } from '@headway/shared/utils/postIntakeInfoCollection';
import { MedicareAdvantageContent } from '@headway/ui/postIntakeInfoCollection/PostIntakeInfoCollectionContent';
import { PostIntakeInfoCollectionForm } from '@headway/ui/postIntakeInfoCollection/PostIntakeInfoCollectionForm';

import { useAuthStore } from 'stores/AuthStore';

import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';

interface AddendumStepProps {
  payerQuestionnaire: PayerQuestionnaire;
  refetchPayerQuestionnaires: () => void;
  currentStep: any;
  setCurrentStep: any;
}

const getAddendumStepTitleAndDescription = (
  payerQuestionnaire: PayerQuestionnaire,
  carriersById: UseFrontEndCarriers['carriersById']
) => {
  const { name: frontEndCarrierName } =
    carriersById[payerQuestionnaire.template.frontEndCarrierId];
  return {
    title: `Addendum: ${frontEndCarrierName}`,
    description: isMedicareAdvantage(frontEndCarrierName) ? (
      <div className="my-4 grid gap-4">
        <MedicareAdvantageContent />
      </div>
    ) : (
      'Insurance companies will occasionally add new requirements to the credentialing process. In this section, we ask for additional information to meet these new requirements.'
    ),
  };
};

export const AddendumStep = ({
  payerQuestionnaire,
  refetchPayerQuestionnaires,
  currentStep,
  setCurrentStep,
}: AddendumStepProps) => {
  const formRef = useRef(null);
  const { provider, user } = useAuthStore();
  const { carriersById } = useFrontEndCarriers();
  const { title, description } = getAddendumStepTitleAndDescription(
    payerQuestionnaire,
    carriersById
  );

  const onStepComplete = async (values: any) => {
    await PayerQuestionnaireResponseApi.updateProviderPayerQuestionnaireResponse(
      payerQuestionnaire.response.id,
      { response: values }
    );
    await PayerQuestionnaireResponseApi.submitProviderPayerQuestionnaireResponse(
      payerQuestionnaire.response.id
    );

    refetchPayerQuestionnaires();
    setCurrentStep(currentStep + 1);

    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  };

  const onStepGoBack = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <h1>
        <PageHeader>{title}</PageHeader>
      </h1>
      <p>
        <BodyText>{description}</BodyText>
      </p>
      <BodyText>
        Not sure how to fill out this form? Check out our{' '}
        <Link
          href="https://findheadway.zendesk.com/hc/en-us/articles/10029399566612-CAQH"
          target="_blank"
          rel="noreferrer"
        >
          Help Center Article
        </Link>
        .
      </BodyText>

      <div
        css={{
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          padding: '24px 16px',
        }}
      >
        <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
          <PostIntakeInfoCollectionForm
            onSubmit={async (values: any) => onStepComplete(values)}
            payerQuestionnaire={payerQuestionnaire}
            ref={formRef}
            formState={{
              userId: user.id,
              providerId: provider.id,
              name: `${provider.displayFirstName} ${provider.displayLastName}`,
              email: provider.email,
              helloSignEmbedded: HelloSignSDK,
              helloSignSdkClientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
            }}
          />
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: theme.spacing.x4,
              marginTop: theme.spacing.x4,
              gap: theme.spacing.x2,
              borderTop: `1px solid ${theme.color.system.borderGray}`,
            }}
          >
            <Button
              variant="secondary"
              type="button"
              onPress={() => {
                onStepGoBack();
              }}
            >
              Back
            </Button>
            <Button type="submit" onPress={() => Form.Submit(formRef)}>
              Save and Continue
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const AddendumStepConfig = (
  payerQuestionnaires: PayerQuestionnaire[],
  carriersById: UseFrontEndCarriers['carriersById']
): QuestionnaireV2Step[] =>
  payerQuestionnaires.map((payerQuestionnaire) => {
    const { title, description } = getAddendumStepTitleAndDescription(
      payerQuestionnaire,
      carriersById
    );
    return {
      title,
      description,
      Component: AddendumStep,
      getFormMeta: () => {
        return {
          validationSchema: Yup.object().shape({}), //Validation handled by form builder
          initialValue: {},
          payerQuestionnaire: payerQuestionnaire,
        } as FormMeta;
      },
    };
  });

export default AddendumStepConfig;
