import { ComponentMap } from '../../components';

export enum ComponentTypes {
  checkbox = 'checkbox',
  checklist = 'checklist',
  radio = 'radio',
  date = 'date',
  dropdown = 'dropdown',
  dropdownMulti = 'dropdownMulti',
  dropdownDiagnosis = 'dropdownDiagnosis',
  longFreeText = 'longFreeText',
  longFreeTextWithCheckbox = 'longFreeTextWithCheckbox',
  shortFreeText = 'shortFreeText',
  richFreeText = 'richFreeText',
  infoGuidanceCard = 'infoGuidanceCard',
}

export interface Component<T> {
  id: string;
  type: keyof typeof ComponentMap;
  title: string;
  description?: string;
  placeholder?: string;
  options?: any;
  metadata?: T;
}

export interface Section<T> {
  header: string;
  components: (Component<T> | Section<T>)[];
  id?: string;
  allowMultiple?: boolean;
  metadata?: T;
}

export type TemplateV1<T> = Array<Section<T>> | Array<Component<T>>;
