import Preview from '@mui/icons-material/Preview';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import React, { useEffect } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Item } from '@headway/helix/collections';
import { Divider } from '@headway/helix/Divider';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import { SHORT_ASSESSMENT_NAMES } from '@headway/shared/constants/patientAssessments';
import { NQF_OPT_IN } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { trackEvent } from '@headway/shared/utils/analytics';
import { ReadonlyAssessmentRenderer } from '@headway/ui/assessments/renderer';

import { ASSESSMENT_DESCRIPTIONS } from '../helpers/constants';
import { ManageAssessmentsModalFormValues } from '../helpers/types';
import { getSortedAssessmentTypes } from '../helpers/utils';

interface ManageAssessmentsModalPreviewPanelProps {
  clientId: number;
  providerId: number;
}

export const ManageAssessmentsModalPreviewPanel = ({
  clientId,
  providerId,
}: ManageAssessmentsModalPreviewPanelProps) => {
  const isProviderOptedIntoNQF = useFlag(NQF_OPT_IN, false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [sentAnalyticsEvent, setSentAnalyticsEvent] = useState<boolean>(false);
  const {
    values: { enabledAssessmentTypes },
  } = useFormikContext<ManageAssessmentsModalFormValues>();

  useEffect(() => {
    if (!sentAnalyticsEvent && isExpanded) {
      trackEvent({
        name: 'Client Assessment Preview Panel Viewed',
        properties: {
          providerId: providerId,
          patientUserId: clientId,
        },
      });
      setSentAnalyticsEvent(true);
    }
  }, [isExpanded, sentAnalyticsEvent, providerId, clientId]);

  const sortedAssessmentTypes = getSortedAssessmentTypes(
    [...enabledAssessmentTypes],
    isProviderOptedIntoNQF
  );

  return (
    <div className="sticky top-8 hidden max-h-[calc(100vh-285px)] basis-[50%] flex-col self-start overflow-y-hidden rounded shadow-light phone:flex tablet:max-h-[calc(100vh-222px)] tablet:w-[453px] tablet:basis-auto">
      <h3 className="flex items-center gap-1 p-6">
        <Preview />
        <BodyText>
          <b>Preview selected assessments</b>
        </BodyText>
        <div className="ml-auto">
          <Button
            variant="secondary"
            onPress={() => setIsExpanded((isExpanded) => !isExpanded)}
          >
            {isExpanded ? 'Hide' : 'View'}
          </Button>
        </div>
      </h3>
      {isExpanded && (
        <Tabs aria-label="Selected assessments">
          <div className="px-6">
            <TabList>
              {sortedAssessmentTypes.map((assessmentType) => (
                <Item key={assessmentType}>
                  {SHORT_ASSESSMENT_NAMES[assessmentType]}
                </Item>
              ))}
            </TabList>
          </div>
          <div
            className="relative overflow-y-auto p-6"
            // Tailwind doesn't support the mask-image property; we use this to add a fade effect on scroll
            style={{
              maskImage:
                'linear-gradient(to bottom, transparent 0%, black 24px, black calc(100% - 24px), transparent 100%), linear-gradient(to left, black 24px, transparent 6px), linear-gradient(to right, black 24px, transparent 6px)',
            }}
          >
            <TabPanels>
              {sortedAssessmentTypes.map((assessmentType) => (
                <Item
                  key={assessmentType}
                  textValue={`About ${SHORT_ASSESSMENT_NAMES[assessmentType]}`}
                >
                  <section>
                    <h4>
                      <SubBodyText color="gray">
                        <b>About {SHORT_ASSESSMENT_NAMES[assessmentType]}</b>
                      </SubBodyText>
                    </h4>
                    <p className="mt-4">
                      <SubBodyText>
                        {ASSESSMENT_DESCRIPTIONS[assessmentType]}
                      </SubBodyText>
                    </p>
                  </section>
                  <Divider />
                  <section>
                    <h4 className="mb-4">
                      <SubBodyText color="gray">
                        <b>
                          {SHORT_ASSESSMENT_NAMES[assessmentType]} assessment
                        </b>
                      </SubBodyText>
                    </h4>
                    <ReadonlyAssessmentRenderer
                      assessmentType={assessmentType}
                    />
                  </section>
                </Item>
              ))}
            </TabPanels>
          </div>
        </Tabs>
      )}
    </div>
  );
};
