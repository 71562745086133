import { useProvider } from 'hooks';
import React from 'react';

import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';

import { IroncladAgreementStatus } from '../types/IroncladAgreementStatus';

export const DeclineModalContent = ({
  setShowDeclineContent,
  hasSigmundAccess,
  remainingDaysToAccept,
  onSubmit,
}: any) => {
  const provider = useProvider();

  return (
    <>
      <ModalContent>
        {hasSigmundAccess ? (
          <>
            <b>By declining you will:</b>
            <ul>
              <li>Be terminated from Headway</li>
              <li>
                Have {remainingDaysToAccept} days to migrate any data you need
                off the platform
              </li>
            </ul>
          </>
        ) : (
          <p>By declining you will be terminated from Headway.</p>
        )}
        <p>
          Clicking “confirm” will send an email to {provider.email} with steps
          to deactivate your Headway account
        </p>
      </ModalContent>

      <ModalFooter>
        <Button
          variant="secondary"
          onPress={() => setShowDeclineContent(false)}
        >
          {' '}
          Go Back
        </Button>
        <Button
          variant="primary"
          onPress={() => onSubmit(IroncladAgreementStatus.DISAGREED)}
        >
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};
