export enum RuntimeErrorType {
  MISSING_REQUIRED_INJECTED_DATA = 'MISSING_REQUIRED_INJECTED_DATA',
  FAILED_CREATING_YUP_VALIDATION_SCHEMA = 'FAILED_CREATING_YUP_VALIDATION_SCHEMA',
  UNKNOWN_RUNTIME_FAILURE = 'UNKNOWN_RUNTIME_FAILURE',
}

export const getRuntimeErrorMessage = (error: RuntimeErrorType): string => {
  switch (error) {
    case RuntimeErrorType.MISSING_REQUIRED_INJECTED_DATA:
      return 'Form.View is missing a required injected state';
    case RuntimeErrorType.FAILED_CREATING_YUP_VALIDATION_SCHEMA:
      return 'Failed creating the YUP validation schema';
    case RuntimeErrorType.UNKNOWN_RUNTIME_FAILURE:
      return 'An unknown runtime failure occurred';
    default:
      return '';
  }
};

export const addRuntimeErrorToSet = (
  runtimeErrorSet: Set<RuntimeErrorType> | undefined,
  runtimeError: RuntimeErrorType
) => {
  const newErrorSet = new Set(runtimeErrorSet ?? []);
  newErrorSet.add(runtimeError);
  return newErrorSet;
};

export const runtimeErrorSetHasError = (
  runtimeErrorSet: Set<RuntimeErrorType> | undefined,
  runtimeError: RuntimeErrorType
) => {
  if (!runtimeErrorSet) {
    return false;
  }

  return runtimeErrorSet.has(runtimeError);
};

export const removeRuntimeErrorFromSet = (
  runtimeErrorSet: Set<RuntimeErrorType> | undefined,
  runtimeError: RuntimeErrorType
) => {
  const newErrorSet = new Set(runtimeErrorSet ?? []);
  if (newErrorSet.has(runtimeError)) {
    newErrorSet.delete(runtimeError);
  }
  return newErrorSet;
};
