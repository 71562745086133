import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { useQuery } from '@headway/shared/react-query';

export const useSuggestedDiagnosisCodes = (
  provider: ProviderRead,
  patient: UserRead
) =>
  useQuery(
    ['suggested-diagnosis-codes', provider?.id, patient?.id],
    () => {
      return ProviderApi.getSuggestedDiagnosisCodes(provider.id, patient.id);
    },
    {
      enabled: !!(provider?.id, patient?.id),
    }
  );
