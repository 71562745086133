import { ProviderLicenseStateApi } from '@headway/api/resources/ProviderLicenseStateApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderCorporateEntityQueryKeyArgs {
  primaryStateId: number | undefined;
}
export const getUseProviderCorporateEntityQueryKey = ({
  primaryStateId,
}: UseProviderCorporateEntityQueryKeyArgs): [string, number | undefined] => [
  'get-provider-license-state-corporate-entity',
  primaryStateId,
];

const {
  useSingleQuery: useProviderCorporateEntity,
  useListQuery: useProviderCorporateEntityList,
} = createBasicApiHooks(
  getUseProviderCorporateEntityQueryKey,
  ({ primaryStateId }) =>
    ProviderLicenseStateApi.getProviderLicenseStateCorporateEntity(
      primaryStateId!
    ),
  ({ primaryStateId }) => !!primaryStateId,
  () => 'Failed to fetch provider corporate entity'
);

export { useProviderCorporateEntity, useProviderCorporateEntityList };
