import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useQueries, UseQueryOptions } from '@headway/shared/react-query';

export type UseSessionDetailsEditabilityQueryKey = [string, number | undefined];
export type UseSessionDetailsEditabilityOptions<T> = UseQueryOptions<
  SessionDetailsEditabilityStatus,
  unknown,
  T,
  UseSessionDetailsEditabilityQueryKey
>;
export const getUseSessionDetailsEditabilityQueryKey = (
  eventId: number | undefined
): [string, number | undefined] => [
  'provider-event-session-details-editability',
  eventId,
];

export const useSessionDetailsEditability = <
  T = SessionDetailsEditabilityStatus,
>(
  eventId: number | undefined,
  options?: UseSessionDetailsEditabilityOptions<T>
) => useSessionDetailsEditabilityList([{ eventId, options }])[0];

export const useSessionDetailsEditabilityList = <
  T = SessionDetailsEditabilityStatus,
>(
  queryArgs: Array<{
    eventId: number | undefined;
    options?: UseSessionDetailsEditabilityOptions<T>;
  }>
) =>
  useQueries({
    queries: queryArgs.map(({ eventId, options }) => ({
      ...(options || {}),
      queryKey: getUseSessionDetailsEditabilityQueryKey(eventId),
      queryFn: () => ProviderEventApi.getSessionDetailsEditability(eventId!),
      enabled: options?.enabled !== false && !!eventId,
    })),
  });
