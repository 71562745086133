import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { ProviderBillingAccountApi } from '@headway/api/resources/ProviderBillingAccountApi';
import {
  ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT,
  ALWAYS_USE_GP_BILLING_ACCOUNT,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';

import { useAuthStore } from 'stores/AuthStore';
import { useUiStore } from 'stores/UiStore';

import { isGroupAdmin } from '../utils/access';
import {
  hasUnmetBankAccountRequirements,
  hasUnmetStripeAccountRequirements,
} from './../utils/billing';

export const getBillingAccountQueryKey = ({
  providerId,
  shouldQueryGroupPractice,
}: {
  providerId: number | undefined;
  shouldQueryGroupPractice: boolean;
}) => {
  return [
    'billing-account',
    {
      providerId,
      shouldQueryGroupPractice,
    },
  ];
};

// useBillingAccount, but for GP auto falls back to provider billing account if GP billing account isn't verified yet,
// unless ALWAYS_USE_GP_BILLING_ACCOUNT flag is on
export const useBillingAccountForPayment = () => {
  const { provider } = useAuthStore();
  const isInGP = !!provider?.groupPracticeId;
  const shouldAlwaysUseGPBillingAccount = useFlag(
    ALWAYS_USE_GP_BILLING_ACCOUNT
  );
  const allowGPToConfigureOwnBillingAccount = useFlag(
    ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT
  );
  const gpBillingAccount = useBillingAccount({
    useGroupPracticeBillingAccount: true,
    queryEnabled:
      isInGP &&
      (allowGPToConfigureOwnBillingAccount || shouldAlwaysUseGPBillingAccount),
  });
  const providerBillingAccount = useBillingAccount({
    useGroupPracticeBillingAccount: false,
    queryEnabled:
      !isInGP ||
      !shouldAlwaysUseGPBillingAccount ||
      !allowGPToConfigureOwnBillingAccount,
  });

  if (!isInGP || !allowGPToConfigureOwnBillingAccount) {
    return providerBillingAccount;
  }

  return gpBillingAccount;
};

// useBillingAccount, but doesn't use GP billing account if GP billing settings feature flag is off
export const useBillingAccountForBillingSettings = ({
  useGroupPracticeBillingAccount = true,
  queryEnabled = true,
} = {}) => {
  const allowGroupPracticeToConfigureOwnBillingAccount = useFlag(
    ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT
  );
  return useBillingAccount({
    useGroupPracticeBillingAccount:
      useGroupPracticeBillingAccount &&
      allowGroupPracticeToConfigureOwnBillingAccount,
    queryEnabled,
  });
};

export const useBillingAccount = ({
  useGroupPracticeBillingAccount = true,
  queryEnabled = true,
} = {}) => {
  const { user, provider } = useAuthStore();
  const shouldQueryGroupPractice =
    useGroupPracticeBillingAccount && !!provider?.groupPracticeId;
  const providerBillingAccountId = provider?.activeBillingAccountId;
  const providerGroupPracticeId = provider?.groupPracticeId;
  const uiStore = useUiStore();

  const {
    data: billingAccount,
    status,
    isLoading: isLoadingBillingAccount,
  } = useQuery(
    getBillingAccountQueryKey({
      providerId: provider?.id,
      shouldQueryGroupPractice,
    }),
    async () => {
      if (shouldQueryGroupPractice) {
        return GroupPracticeApi.getGroupPracticeBillingAccount(
          providerGroupPracticeId
        );
      }

      // This path can be triggered because LaunchDarkly can first return false even
      // if it's actually configured to be true
      if (!providerBillingAccountId) {
        return null;
      }

      return ProviderBillingAccountApi.getProviderBillingAccount(
        providerBillingAccountId
      );
    },
    {
      onError: (error: any) => {
        uiStore.showWarningSnackbar(error.toString());
      },
      refetchOnWindowFocus: false,
      retry: false,
      enabled: queryEnabled,
    }
  );

  const accountHasUnmetStripeAccountRequirements =
    !!billingAccount &&
    hasUnmetStripeAccountRequirements(billingAccount?.stripeAccount);

  const {
    data: stripeOnboardingLink,
    isLoading: isLoadingStripeOnboardingLink,
  } = useQuery(
    ['stripe-onboarding-link', billingAccount?.id],
    () => {
      return (
        billingAccount &&
        ProviderBillingAccountApi.getStripeOnboardingLink(billingAccount.id)
      );
    },
    {
      onError: (error: any) => {
        uiStore.showWarningSnackbar(error.toString());
      },
      retry: false,
      enabled:
        queryEnabled &&
        accountHasUnmetStripeAccountRequirements &&
        (!shouldQueryGroupPractice ||
          // when getting GP billing account, non-admin providers shouldn't configure stripe
          isGroupAdmin(user)),
    }
  );

  if (!billingAccount) {
    return {
      billingAccountInfo: null,
    };
  }

  return {
    billingAccountInfo: {
      isLoadingBillingAccount,
      isLoadingStripeOnboardingLink,
      status,
      ...billingAccount,
      isGroupPracticeBillingAccount: shouldQueryGroupPractice,
      hasUnmetBankAccountRequirements:
        hasUnmetBankAccountRequirements(billingAccount),
      hasUnmetStripeAccountRequirements:
        accountHasUnmetStripeAccountRequirements,
      stripeOnboardingLink: stripeOnboardingLink?.url,
    },
  };
};
