import React from 'react';

import { ProviderLicenseStateReadByUser } from '@headway/api/models/ProviderLicenseStateReadByUser';
import { LinkButton } from '@headway/helix/LinkButton';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { convertListToSentence } from '@headway/shared/utils/strings';

type PracticeStatesProps = {
  providerLicenseStates: ProviderLicenseStateReadByUser[];
};

export const PracticeStates = ({
  providerLicenseStates,
}: PracticeStatesProps) => {
  const practiceStates = providerLicenseStates.map(
    (providerLicenseState) => statesToDisplayNames[providerLicenseState.state]
  );
  const formatStatesToString = (states: string[]) => {
    if (states.length <= 3) {
      return convertListToSentence(states);
    }

    return `${states.slice(0, 3).join(', ')}, and ${states.length - 3} more`;
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.x1,
      }}
    >
      <SubBodyText>States</SubBodyText>
      <SectionHeader>{formatStatesToString(practiceStates)}</SectionHeader>
      <SubBodyText>
        <LinkButton
          variant="link"
          href="/legal/rates"
          target="_blank"
          rel="noreferrer"
        >
          <strong>View rates and agreements</strong>
        </LinkButton>
      </SubBodyText>
    </div>
  );
};
