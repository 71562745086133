import Delete from '@mui/icons-material/Delete';
import { IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as yup from 'yup';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { FieldAutocomplete, FieldControl } from '@headway/ui/form';
import { FieldErrorText } from '@headway/ui/form/FieldErrorText';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { SubmitListener } from '@headway/ui/form/SubmitListener';
import { theme } from '@headway/ui/theme';

import {
  ProviderWorkingHour,
  UseWorkingHours,
  Weekday,
} from 'hooks/useWorkingHours';
import { useUiStore } from 'stores/UiStore';
import {
  eventContainsEvent,
  eventsHaveOverlap,
} from 'views/Calendar/events/util/events';
import { FieldFiveMinuteIntervalSelect } from 'views/Calendar/form/FieldFiveMinuteIntervalSelect';

/**
 * Types
 */

export const TELEHEALTH_LOCATION_OPTION = {
  id: -1,
  name: 'Telehealth',
} as const;

type WorkingHourLocationAutocompleteOption =
  | ProviderAddressRead
  | typeof TELEHEALTH_LOCATION_OPTION;

interface WorkingHourEditForm {
  startDate: string;
  endDate: string;
  locations: (typeof TELEHEALTH_LOCATION_OPTION | ProviderAddressRead)[];
  id: number;
  telehealth: boolean;
  location?: ProviderAddressRead | undefined;
  providerEvent: ConcreteProviderEventRead;
}

const workingHourValidationSchema = yup.object().shape({
  locations: yup.array().min(1, 'Must select at least one location.'),
  startDate: yup.date().when('isAvailable', {
    is: true,
    then: yup.date().required('Start date is required.'),
  }),
  endDate: yup
    .date()
    .when(['startDate'], (startDate: string, schema: yup.DateSchema) =>
      schema
        .min(
          startDate,
          `End time must be after ${moment(startDate).format('hh:mm A')}`
        )
        .required('End time is required.')
    ),
});

/**
 * Utility Functions
 */

function isTelehealthOption(
  option: WorkingHourLocationAutocompleteOption
): option is typeof TELEHEALTH_LOCATION_OPTION {
  return option.id === TELEHEALTH_LOCATION_OPTION.id;
}

const getInitialValues = (event: ProviderWorkingHour) => {
  let locations: (ProviderAddressRead | typeof TELEHEALTH_LOCATION_OPTION)[] =
    [];

  if (event.location) {
    locations.push(event.location);
  }

  if (event.telehealth) {
    locations.push(TELEHEALTH_LOCATION_OPTION);
  }

  return {
    ...event,
    startDate: moment(event.startDate).toISOString(),
    endDate: moment(event.endDate).toISOString(),
    locations,
  };
};

export const WorkingHourEdit = ({
  hour,
  weekday,
  workingHours,
  updateWorkingHour,
  deleteWorkingHours,
  locations,
}: {
  hour: ProviderWorkingHour;
  weekday: Weekday;
  workingHours: any;
  updateWorkingHour: UseWorkingHours['updateWorkingHour'];
  deleteWorkingHours: UseWorkingHours['deleteWorkingHours'];
  locations: ProviderAddressRead[];
}) => {
  const ui = useUiStore();
  const isSmall = useMediaQuery(theme.media.smallDown);
  return (
    <Formik<WorkingHourEditForm>
      initialValues={getInitialValues(hour)}
      validationSchema={workingHourValidationSchema}
      onSubmit={async (values, { setSubmitting, setErrors, setFieldError }) => {
        setSubmitting(true);

        const updateStartDateMoment = moment(values.startDate);

        const hasOverlap = workingHours[weekday].find(
          (otherHour: ProviderWorkingHour) => {
            if (otherHour.id === values.id) {
              return false;
            }

            // Bring the event start/end dates to the same day
            // as the updating working hour, so we can compare the times
            const normalizeStartDate = moment(otherHour.providerEvent.startDate)
              .set('year', updateStartDateMoment.year())
              .set('month', updateStartDateMoment.month())
              .set('date', updateStartDateMoment.date());

            const normalizeEndDate = moment(otherHour.providerEvent.endDate)
              .set('year', updateStartDateMoment.year())
              .set('month', updateStartDateMoment.month())
              .set('date', updateStartDateMoment.date());

            const eventUpdate = {
              startDate: values.startDate,
              endDate: values.endDate,
            };

            const existingEvent = {
              startDate: normalizeStartDate.toISOString(),
              endDate: normalizeEndDate.toISOString(),
            };

            if (
              eventsHaveOverlap(
                eventUpdate as ProviderEventRead,
                existingEvent as ProviderEventRead,
                '()',
                '()'
              ) ||
              eventContainsEvent(
                eventUpdate as ProviderEventRead,
                existingEvent as ProviderEventRead
              ) ||
              eventContainsEvent(
                existingEvent as ProviderEventRead,
                eventUpdate as ProviderEventRead
              )
            ) {
              return true;
            }

            return false;
          }
        );

        if (!!hasOverlap) {
          setFieldError('startDate', 'Working hour overlaps with another hour');
          setFieldError('endDate', '');
          return;
        }

        try {
          await updateWorkingHour({
            ...values,
            telehealth: !!values.locations.find(
              (l) => l.id === TELEHEALTH_LOCATION_OPTION.id
            ),
            location:
              (values.locations.find(
                (l) => l.id !== TELEHEALTH_LOCATION_OPTION.id
              ) as ProviderAddressRead) ?? null,
          });
        } catch (e) {
          ui.showWarningSnackbar('Error updating working hour.');
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, values }) => {
        const locationField = (
          <FieldControl name="locations">
            <FieldAutocomplete
              label="Locations"
              openOnFocus={true}
              filterSelectedOptions
              disabled={isSubmitting}
              multiple
              filterOptions={(
                options: WorkingHourLocationAutocompleteOption[]
              ) => {
                const nonTelehealthLocations = values.locations.filter((l) => {
                  return l !== TELEHEALTH_LOCATION_OPTION;
                });

                if (nonTelehealthLocations.length > 0) {
                  return options.filter((o) => {
                    return o === TELEHEALTH_LOCATION_OPTION;
                  });
                }

                return options;
              }}
              getOptionLabel={(
                option: WorkingHourLocationAutocompleteOption
              ) => {
                if (isTelehealthOption(option)) {
                  return option.name;
                }

                return option.streetLine1 ?? option.address;
              }}
              isOptionEqualToValue={(
                option: WorkingHourLocationAutocompleteOption,
                value: WorkingHourLocationAutocompleteOption
              ) => {
                return option.id === value.id;
              }}
              options={
                [
                  TELEHEALTH_LOCATION_OPTION,
                  ...locations,
                ] as WorkingHourLocationAutocompleteOption[]
              }
              textFieldProps={{
                variant: 'outlined',
              }}
            />
            <FieldErrorText />
          </FieldControl>
        );
        return (
          <>
            <SubmitListener />
            <SafeFormikForm css={{ width: '100%' }}>
              <div
                css={{
                  display: 'flex',
                  gap: '15px',
                }}
              >
                <div>
                  <FieldControl name="startDate">
                    <FieldFiveMinuteIntervalSelect
                      disabled={isSubmitting}
                      css={{ width: 'fit-content' }}
                      inputProps={{ width: '110px' }}
                      SelectDisplayProps={{ width: '110px' }}
                      variant="outlined"
                      label="Start time"
                    />
                    <FieldErrorText
                      css={{
                        whiteSpace: 'break-spaces',
                        bottom: '0px !important',
                        transform: 'translateY(100%)',
                        width: '250px',
                      }}
                    />
                  </FieldControl>
                </div>
                <div css={{ width: '100px' }}>
                  <FieldControl name="endDate">
                    <FieldFiveMinuteIntervalSelect
                      disabled={isSubmitting}
                      css={{ width: 'fit-content' }}
                      variant="outlined"
                      label="End time"
                    />
                    <FieldErrorText
                      css={{
                        whiteSpace: 'break-spaces',
                        bottom: '0px !important',
                        transform: 'translateY(100%)',
                        width: '250px',
                      }}
                    />
                  </FieldControl>
                </div>
                {!isSmall && locationField}
                <Tooltip
                  title="Delete working hour"
                  placement="left"
                  arrow
                  enterDelay={1000}
                >
                  <IconButton
                    disabled={isSubmitting}
                    css={{ height: '40px', marginTop: '10px' }}
                    onClick={async () => {
                      try {
                        await deleteWorkingHours([values.id]);
                      } catch (e) {
                        ui.showWarningSnackbar('Error deleting working hour.');
                      }
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </div>
              {isSmall && <div>{locationField}</div>}
            </SafeFormikForm>
          </>
        );
      }}
    </Formik>
  );
};
