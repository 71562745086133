import React, { useEffect, useMemo } from 'react';

import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';

import {
  NUMBER_OF_DAYS_TO_BLOCKED,
  NUMBER_OF_DAYS_TO_TERMINATED,
} from 'components/IroncladAmendmentsModal/helpers/constants';
import { calculateDaysRemaining } from 'components/IroncladAmendmentsModal/helpers/utils';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';

import { IroncladAgreementStatus } from '../../types/IroncladAgreementStatus';
import { LoadingState } from '../LoadingState';
import { AgreementCheckbox } from './AgreementCheckbox';
import { DownloadProviderAgreementButton } from './DownloadProviderAgreementButton';
import { IroncladAgreement } from './IroncladAgreement';
import { KeyUpdates } from './KeyUpdates';
import { ProviderClientRelationships } from './ProviderClientRelationships';

export const AcceptModalContent = ({
  isLoadingDeclinedState,
  setIsLoadingDeclinedState,
  ironcladAgreement,
  isLoadingIroncladAgreement,
  hasSigmundAccess,
  setShowDeclineContent,
  onSubmit,
}: any) => {
  const { user, provider } = useAuthStore();
  const { ironcladAgreementStatus } = useIroncladAgreementStatus();
  const ironcladDownloadUrls: any = useMemo(() => {
    const urls = [];

    if (ironcladAgreement) {
      const { contract_data = {} } = ironcladAgreement;

      if (Object.keys(contract_data).length) {
        for (const contract of Object.keys(contract_data)) {
          urls.push(contract_data[contract].download_url);
        }
      }
    }

    return urls;
  }, [ironcladAgreement]);

  // If declined after 30 day period, implement loading state while fetching newly declined status
  useEffect(() => {
    if (
      isLoadingDeclinedState &&
      ironcladAgreementStatus === IroncladAgreementStatus.DISAGREED
    ) {
      setIsLoadingDeclinedState(false);
    }
  }, [
    isLoadingDeclinedState,
    setIsLoadingDeclinedState,
    ironcladAgreementStatus,
  ]);

  if (isLoadingDeclinedState || isLoadingIroncladAgreement)
    return <LoadingState />;

  return (
    <>
      <ModalContent>
        <div
          css={{
            ...theme.typography.body.regular,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: '100%',
            [theme.__futureMedia.above('tablet')]: {
              height: '475px',
            },
          }}
        >
          <div
            css={{
              overflow: 'scroll',
            }}
          >
            {ironcladAgreementStatus === IroncladAgreementStatus.DISAGREED ? (
              <GuidanceCard variant="warning">
                You previously declined the new terms and your account is no
                longer active. We’ll complete the deactivation process within{' '}
                {calculateDaysRemaining(NUMBER_OF_DAYS_TO_TERMINATED)} days,
                unless you’ve changed your mind and would like to agree.
              </GuidanceCard>
            ) : (
              <p>
                We’ve added language to your provider agreement to clarify that
                clients you bring to Headway are yours, and that if you leave
                Headway, you can take them with you. You also do not have to
                delete or return any clinical documentation to Headway.{' '}
                {hasSigmundAccess
                  ? `You have ${calculateDaysRemaining(
                      NUMBER_OF_DAYS_TO_BLOCKED
                    )}
                days to agree to these updates to keep using Headway.`
                  : 'You must agree to these updates to continue using Headway.'}
              </p>
            )}

            {/* // TO DO: Remove section in sc-142500 */}
            <ProviderClientRelationships />

            <KeyUpdates />

            {!!ironcladDownloadUrls.length && (
              <DownloadProviderAgreementButton
                downloadData={ironcladDownloadUrls}
                variant="link"
                size="medium"
                css={{
                  marginTop: theme.spacing.x3,
                  marginBottom: theme.spacing.x3,
                }}
              />
            )}

            <IroncladAgreement
              ironcladAgreement={ironcladAgreement}
              isLoading={isLoadingIroncladAgreement}
            />
          </div>
          <AgreementCheckbox onSubmit={onSubmit} />
        </div>
      </ModalContent>
      {hasRateAccess(provider, user) && (
        <ModalFooter>
          {ironcladAgreementStatus !== IroncladAgreementStatus.DISAGREED && (
            <Button
              variant="secondary"
              onPress={() => setShowDeclineContent(true)}
            >
              Decline
            </Button>
          )}
          <Button
            type="submit"
            variant="primary"
            form="amendment-acceptance-form"
          >
            Agree
          </Button>
        </ModalFooter>
      )}
    </>
  );
};
