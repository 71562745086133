import { FormikProps } from 'formik';
import { MutableRefObject } from 'react';

import { PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES } from '@headway/shared/constants/cptCodes';

export const validateAndSubmit = async (
  ref: MutableRefObject<FormikProps<any> | undefined>
) => {
  if (!ref.current) {
    return;
  }

  const form = ref.current;
  const validationErrors = await form.validateForm();
  await form.submitForm();

  return {
    success: Object.keys(validationErrors).length === 0,
    errors: validationErrors,
    values: form.values,
  };
};

export const isBillingAddOnPsychotherapy = (cptCodes: string[]) =>
  cptCodes.some((code) => PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES.includes(code));
