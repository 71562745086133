import { AssignmentTwoTone } from '@mui/icons-material';
import { Alert } from '@mui/material';

import { theme } from '@headway/ui/theme';

import { TreatmentPlanRequirementModalProps } from '../Modals/RequiredInfoTreatmentPlanModal';
import {
  MedicareMedicaidRequirementClientBanner,
  MedicareMedicaidRequirementClientDetailAlert,
  MedicareMedicaidRequirementModalContent,
  useMedicareOrMedicaidTreatmentPlanRequirement,
} from './MedicareMedicaidRequirements';

export enum TreatmentPlanRequirement {
  MEDICARE_MEDICAID = 'MEDICARE_MEDICAID',
}

// gemini.link(web/apps/sigmund/app/legacy/views/Calendar/components/AppointmentConfirmation/TreatmentPlanRequirementWarning.tsx)
export const useTreatmentPlanRequirements = (clientId?: number) => {
  let treatmentPlanRequirementType;

  // Medicare & Medicaid
  const isRequiredByMedicareOrMedicaid =
    useMedicareOrMedicaidTreatmentPlanRequirement(clientId);

  treatmentPlanRequirementType = isRequiredByMedicareOrMedicaid
    ? TreatmentPlanRequirement.MEDICARE_MEDICAID
    : undefined;

  // Other requirements
  // TODO: Anthem PPR Requirements

  const isTreatmentPlanRequired = isRequiredByMedicareOrMedicaid;
  return { isTreatmentPlanRequired, treatmentPlanRequirementType };
};

export const TreatmentPlanRequirementModalContent = ({
  contentProps,
}: {
  contentProps: TreatmentPlanRequirementModalProps;
}) => {
  const { requirementType } = contentProps;

  switch (requirementType) {
    case TreatmentPlanRequirement.MEDICARE_MEDICAID:
      return <MedicareMedicaidRequirementModalContent {...contentProps} />;
    default:
      return null;
  }
};

export const TreatmentPlanRequirementClientBanner = ({
  clientId,
  requirementType,
}: {
  clientId?: number;
  requirementType?: TreatmentPlanRequirement;
}) => {
  if (!clientId || !requirementType) return null;

  switch (requirementType) {
    case TreatmentPlanRequirement.MEDICARE_MEDICAID:
      return <MedicareMedicaidRequirementClientBanner clientId={clientId} />;
    default:
      return null;
  }
};

export const TreatmentPlanRequirementClientDetailAlert = ({
  clientId,
  requirementType,
  expirationDate,
}: {
  clientId?: number;
  requirementType?: TreatmentPlanRequirement;
  expirationDate?: string;
}) => {
  if (!clientId || !requirementType) return null;

  return (
    <Alert
      color="info"
      variant="outlined"
      icon={<AssignmentTwoTone />}
      css={{ marginTop: theme.space.base }}
    >
      <div>
        {expirationDate ? (
          <div className="font-bold">
            Reminder: Updated treatment plan due {expirationDate}.
          </div>
        ) : (
          <div className="font-bold">Reminder: Treatment plan required</div>
        )}
        {requirementType === TreatmentPlanRequirement.MEDICARE_MEDICAID ? (
          <MedicareMedicaidRequirementClientDetailAlert
            clientId={clientId}
            isExpiring={!!expirationDate}
          />
        ) : null}
      </div>
    </Alert>
  );
};
