import React from 'react';

import { ProviderFrontEndCarrierNested } from '@headway/api/models/ProviderFrontEndCarrierNested';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { logException } from '@headway/shared/utils/sentry';
import { notifyWarning } from '@headway/ui/utils/notify';

export const OptOutModalContent = ({
  frontEndCarrierId,
  frontEndCarrierName,
  onClose,
  providerFrontEndCarriers,
  setCurrentModalContent,
  refetchIncompletePayerQuestionnaires,
}: {
  frontEndCarrierId: number;
  frontEndCarrierName: string;
  onClose: () => void;
  providerFrontEndCarriers: ProviderFrontEndCarrierNested[];
  setCurrentModalContent: () => void;
  refetchIncompletePayerQuestionnaires: () => void;
}) => {
  const submitOptOutRequest = async () => {
    const providerFrontEndCarrier = providerFrontEndCarriers.find(
      (pfec) => pfec.frontEndCarrierId === frontEndCarrierId
    );
    if (!providerFrontEndCarrier) {
      notifyWarning("We aren't able to process this opt out request.");
      return undefined;
    }
    try {
      await ProviderFrontEndCarrierApi.deleteProviderFrontEndCarrier(
        providerFrontEndCarrier.id
      );
      refetchIncompletePayerQuestionnaires();
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyWarning('There was an error opting out of this network.');
      logException(error);
    }
  };

  return (
    <>
      <ModalContent>
        By opting out of the {frontEndCarrierName} network, you won't be able to
        see any clients through this network. To opt back into this network, you
        will need to reach out to a practice consultant.
      </ModalContent>
      <ModalFooter>
        <Button
          variant="secondary"
          size="large"
          type="submit"
          onPress={setCurrentModalContent}
        >
          Go back
        </Button>
        <Button
          variant="primary"
          size="large"
          type="submit"
          onPress={() => {
            submitOptOutRequest();
            onClose();
          }}
        >
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};
