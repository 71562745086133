import { omit } from 'lodash';
import moment from 'moment';
import { rrulestr } from 'rrule';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';

import { toFloatingDateTime, toLocalizedDateTime } from '../utils/dates';
import {
  adjustAllDayEventToTimezone,
  isAllDayEvent,
} from './expandedEventsUtils';

export function recurringEventToCalendarEvent(
  event,
  index,
  startDate,
  eventStartDate,
  eventEndDate,
  duration
) {
  let timezone = event.time_zone;
  if (isAllDayEvent(eventStartDate, eventEndDate)) {
    timezone = moment.tz.guess();
  }

  const instanceStartDate = toLocalizedDateTime(startDate, timezone);
  const instanceEndDate = moment(toLocalizedDateTime(startDate, timezone))
    .add(duration)
    .toDate();
  let estimatedPrice = event.estimated_price;
  let priceChangedWithinPastMonth = event.price_changed_within_past_month;
  if (event.recurrence_pricing_info) {
    // Estimated prices for instances of a recurring appointment may change because the
    // price is locked in 2 days before appointment start date.
    const instancePricingInfo = event.recurrence_pricing_info.find(
      (pricingInfo) =>
        new Date(pricingInfo.instance_start_date).getTime() ===
        instanceStartDate.getTime()
    );
    if (instancePricingInfo) {
      if (instancePricingInfo.estimated_price) {
        estimatedPrice = instancePricingInfo.estimated_price;
      }
      if (instancePricingInfo.price_changed_within_past_month) {
        priceChangedWithinPastMonth =
          instancePricingInfo.price_changed_within_past_month;
      }
    }
    delete event.recurrence_pricing_info;
  }
  return {
    ...event,
    recurringEventId: event.id,
    id: index === 0 ? event.id : `${event.id}_${startDate.toISOString()}`,
    // the floating start date is useful for adding exdates if we modify or delete this instance
    floatingStartDate: startDate,
    start_date: instanceStartDate,
    end_date: instanceEndDate,
    estimated_price: estimatedPrice,
    price_changed_within_past_month: priceChangedWithinPastMonth,
    virtual_id: `${event.id}_${moment(instanceStartDate)
      .utc()
      .format()
      .replace('Z', '+00:00')}`,
  };
}

export function eventToCalendarEvents(
  event,
  dateRangeStart,
  dateRangeEnd,
  tzid
) {
  let eventStartDate = new Date(event.start_date);
  let eventEndDate = new Date(event.end_date);

  if (isAllDayEvent(eventStartDate, eventEndDate)) {
    ({ startDate: eventStartDate, endDate: eventEndDate } =
      adjustAllDayEventToTimezone(
        eventStartDate,
        eventEndDate,
        event.time_zone
      ));
  }

  if (event.recurrence) {
    const rule = rrulestr(event.recurrence);
    const duration = moment.duration(
      moment(event.end_date).diff(moment(event.start_date))
    );

    const dateRangeStartUTC = toFloatingDateTime(dateRangeStart, tzid);
    const dateRangeEndUTC = toFloatingDateTime(dateRangeEnd, tzid);

    return [
      ...rule
        .between(dateRangeStartUTC, dateRangeEndUTC)
        .map((startDate, index) => {
          return recurringEventToCalendarEvent(
            event,
            index,
            startDate,
            eventStartDate,
            eventEndDate,
            duration
          );
        }),
    ];
  } else {
    return [
      {
        ...event,
        start_date: eventStartDate,
        end_date: eventEndDate,
      },
    ];
  }
}

export function eventsToCalendarEvents(
  rawEvents,
  dateRangeStart,
  dateRangeEnd,
  tzid
) {
  const events = [];

  rawEvents.forEach((event) => {
    events.push(
      ...eventToCalendarEvents(event, dateRangeStart, dateRangeEnd, tzid)
    );
  });

  return events;
}

export function filterUpdatableEventProperties(event) {
  const {
    type,
    title,
    start_date,
    end_date,
    recurrence,
    recurrence_end_date,
    provider_address_id,
    telehealth,
    provider_appointment,
    time_zone,
  } = event;

  return {
    type,
    title,
    start_date,
    end_date,
    recurrence,
    recurrence_end_date,
    provider_address_id,
    telehealth,
    provider_appointment:
      provider_appointment &&
      omit(provider_appointment, 'progress_note_type', 'patient'),
    time_zone,
  };
}

export function filterCreatableEventProperties(event) {
  const {
    type,
    title,
    start_date,
    end_date,
    recurrence,
    provider_address_id,
    telehealth,
    provider_id,
    provider_license_state_id,
    patient_user_id,
    provider_appointment,
    time_zone,
    original_recurring_event_id,
    original_start_date,
  } = event;

  return {
    type,
    title,
    start_date,
    end_date,
    recurrence,
    provider_address_id,
    telehealth,
    provider_id,
    provider_license_state_id,
    patient_user_id,
    provider_appointment:
      provider_appointment &&
      omit(provider_appointment, 'progress_note_type', 'patient'),
    time_zone,
    original_recurring_event_id,
    original_start_date,
  };
}

export function parseRRule(rruleString) {
  // always parse as an RRuleSet so we can use consistently
  const ruleset = rrulestr(rruleString, {
    forceset: true,
  });
  // our events only have one RRule, pull out the first one
  const [rule] = ruleset.rrules();

  return [rule, ruleset];
}

export function diffStartDate(instance, update) {
  // the amount of time this event was shifted by, can be 0
  const diff = moment(update.start_date).diff(instance.start_date);

  return diff;
}

export const getAppointmentIdForCalendarEvent = (calendarEvent) => {
  if (
    calendarEvent.recurrence &&
    calendarEvent.provider_appointment?.status ===
      ProviderAppointmentStatus.SCHEDULED
  ) {
    // Recurring scheduled events don't have an appointment yet, they're linked to the original appointment of the reccurrence
    return '';
  }
  if (calendarEvent.provider_appointment?.id) {
    return calendarEvent.provider_appointment.id;
  }
  return '';
};
