import { useEffect, useRef } from 'react';

/**
 * Returns the value that was passed into this hook on the previous render. For usage examples, see:
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
