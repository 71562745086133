interface UsePatientsQueryParams {
  hidden?: boolean;
  page?: number;
  search?: string;
}

export const getUsePatientsQueryKey = (
  providerId: number,
  params: UsePatientsQueryParams = {}
): [string, number, string, UsePatientsQueryParams] => [
  'provider',
  providerId,
  'patients',
  params,
];
