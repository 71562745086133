// https://developers.cloudflare.com/images/transform-images/integrate-with-frameworks/#nextjs

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src;
};

export const transformCloudflareImg = ({
  src,
  width,
  height,
  quality = 100,
  format = 'auto',
  fit = 'contain',
}: {
  src?: string;
  width: number;
  height?: number;
  quality?: number;
  format?: string;
  fit?: string;
}) => {
  if (!src) return '';

  const params = [`width=${width}`];
  if (height) {
    params.push(`height=${height}`);
  }
  if (quality) {
    params.push(`quality=${quality}`);
  }
  if (format) {
    params.push(`format=${format}`);
  }
  if (fit) {
    params.push(`fit=${fit}`);
  }
  const paramsString = params.join(',');
  return `/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};
