import React from 'react';

import { theme } from './theme';

export interface ProgressBarProps {
  /** The step to fill the progress bar up to. This should be 0-indexed. */
  currentStep: number;
  /** The total number of steps the progress bar should represent. */
  totalSteps: number;
  /** If true, the progress bar will be fixed to the top of the screen. Defaults to false. */
  fixed?: boolean;
  /** The color to use for the background of the progress bar. Defaults to 'gray'.*/
  backgroundColor?: 'primary' | 'gray';
  /** If true, the progress bar will have rounded corners. Defaults to false. */
  rounded?: boolean;
}

export const ProgressBar: React.FC<
  React.PropsWithChildren<ProgressBarProps>
> = ({
  currentStep,
  totalSteps,
  fixed = false,
  backgroundColor = 'gray',
  rounded = false,
  ...rest
}) => {
  return (
    <div
      css={
        fixed
          ? {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: 12,
              zIndex: 99,
            }
          : {
              width: '100%',
              height: 5,
              borderRadius: rounded ? 5 : 0,
              overflow: 'hidden',
              backgroundColor:
                backgroundColor === 'gray'
                  ? theme.color.backgroundGray
                  : theme.color.primaryLight,
            }
      }
      {...rest}
    >
      <div
        css={{
          position: 'relative',
          height: '100%',
          background: theme.color.primary,
          width: `${(currentStep / totalSteps) * 100}%`,
          transition: 'width 300ms ease-in-out',
          borderRadius: rounded ? 5 : 0,
        }}
      ></div>
    </div>
  );
};
