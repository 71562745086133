import { License } from '@headway/api/models/License';
import { LicenseTypes } from '@headway/api/models/LicenseTypes';
import { UnitedStates } from '@headway/api/models/UnitedStates';

interface Postnomials {
  [key: string]: string;
}

export const nurseLicenseTypes = [
  LicenseTypes.APRN,
  LicenseTypes.APRN_OUT_OF_STATE,
  LicenseTypes.APRN_FPA,
  LicenseTypes.APN,
  LicenseTypes.CNS,
  LicenseTypes.PCNS,
  LicenseTypes.NP,
  LicenseTypes.NP_COMPACT,
  LicenseTypes.CRNP,
  LicenseTypes.APNP,
  LicenseTypes.ARNP,
  LicenseTypes.APRN_CNS,
  LicenseTypes.APRN_CNP,
  LicenseTypes.APRN_NP,
  LicenseTypes.TLHT_APRN_CNP,
  LicenseTypes.CNP,
];

export const requiresNPPrescriptiveAgreementStates = [
  UnitedStates.ALABAMA,
  UnitedStates.CALIFORNIA,
  UnitedStates.CONNECTICUT,
  UnitedStates.FLORIDA,
  UnitedStates.ILLINOIS,
  UnitedStates.INDIANA,
  UnitedStates.LOUISIANA,
  UnitedStates.MASSACHUSETTS,
  UnitedStates.MISSISSIPPI,
  UnitedStates.MISSOURI,
  UnitedStates.NEW_JERSEY,
  UnitedStates.OKLAHOMA,
  UnitedStates.PENNSYLVANIA,
  UnitedStates.SOUTH_CAROLINA,
  UnitedStates.TEXAS,
  UnitedStates.TENNESSEE,
  UnitedStates.WEST_VIRGINIA,
  UnitedStates.WISCONSIN,
  UnitedStates.WYOMING,
];

export const physicianLicenseTypes = [LicenseTypes.MD, LicenseTypes.DO];

export const prescriberLicenseTypes: string[] = [
  ...physicianLicenseTypes,
  ...nurseLicenseTypes,
];

export const getLowestLevelLicense = (
  selectedLicenses: { [key: string]: License } = {},
  states?: UnitedStates[]
) => {
  const filteredLicenses = states?.length
    ? states.map((state) => selectedLicenses[state])
    : getSelectedLicenseList(selectedLicenses);
  const rankByLicenseType: { [key: string]: number } = {
    CP: 1,
    MD: 4,
    DO: 3,
  };
  for (let nurseLicenseType of nurseLicenseTypes) {
    rankByLicenseType[nurseLicenseType] = 2;
  }

  let minLicense = filteredLicenses[0];
  for (const license of filteredLicenses) {
    if (
      (rankByLicenseType[license.licenseType] || 0) <
      (rankByLicenseType[minLicense.licenseType] || 0)
    ) {
      minLicense = license;
    }
  }

  return minLicense;
};

export const getAllPostnomials = (selectedLicenses?: {
  [key: string]: License;
}): string[] => {
  const postnomials: Postnomials = {
    MD: 'Psychiatrist',
    DO: 'Doctor of Osteopathic Medicine',
    CP: 'Psychologist',
    NP: 'Nurse Practitioner',
    LCAT: 'Licensed Creative Arts Therapist',
    LCPC: 'Licensed Clinical Professional Counselor',
    LCSW: 'Licensed Clinical Social Worker',
    LCMHC: 'Licensed Clinical Mental Health Counselor',
    LMHC: 'Licensed Mental Health Counselor',
    LP: 'Licensed Psychoanalyst',
    LPC: 'Licensed Professional Counselor',
    MFT: 'Marriage and Family Therapist',
  };

  return getSelectedLicenseTypes(selectedLicenses)
    .map((licenseType: LicenseTypes) => {
      const postnomial = postnomials[licenseType];
      if (!!postnomial) return postnomial;
      return '';
    }, [])
    .filter((postnomial) => !!postnomial);
};

const isAnyOfLicenseType = (
  licenseTypes: string[],
  licenseMap?: { [key: string]: License }
): boolean => {
  return getSelectedLicenseTypes(licenseMap).some((licenseType) =>
    licenseTypes.includes(licenseType)
  );
};

export const hasPrescriberLicenseType = (
  licenseMap?: {
    [key: string]: License;
  },
  states_filter?: UnitedStates[]
) => hasLicenseTypeInStates(prescriberLicenseTypes, states_filter, licenseMap);

export const hasPhysicianLicenseType = (
  licenseMap?: {
    [key: string]: License;
  },
  states_filter?: UnitedStates[]
) => hasLicenseTypeInStates(physicianLicenseTypes, states_filter, licenseMap);

export const hasNurseLicenseType = (
  licenseMap?: { [key: string]: License },
  states_filter?: UnitedStates[]
) => hasLicenseTypeInStates(nurseLicenseTypes, states_filter, licenseMap);

export const hasNPLicenseInStatesRequiringNPPrescriberAgreements =
  (licenseMap?: { [key: string]: License }) =>
    hasNurseLicenseType(licenseMap, requiresNPPrescriptiveAgreementStates);

const hasLicenseTypeInStates = (
  licenseTypes: string[],
  states_filter?: string[],
  selectedLicenses?: {
    [key: string]: License;
  }
) => {
  if (states_filter === undefined) {
    return isAnyOfLicenseType(licenseTypes, selectedLicenses);
  }
  return getSelectedLicenseList(selectedLicenses).some((license) =>
    isLicenseOfStateAndType(license, states_filter, licenseTypes)
  );
};

const isLicenseOfStateAndType = (
  license: License,
  states: string[],
  licenseTypes: string[]
) =>
  states.includes(license.licenseState) &&
  licenseTypes.includes(license.licenseType);

export const isOfLicenseTypes = (license: License, licenseTypes: string[]) =>
  licenseTypes?.includes(license.licenseType);

export const getSelectedLicenseTypes = (licenseMap?: {
  [key: string]: License;
}) => {
  const licenseTypes = getSelectedLicenseList(licenseMap).map(
    (license) => license.licenseType
  );
  return Array.from(new Set(licenseTypes));
};
export const getSelectedLicenseList = (licenseMap?: {
  [key: string]: License;
}) => {
  if (!licenseMap) {
    return [];
  }
  return Object.keys(licenseMap).map((state) => licenseMap[state]);
};
