import {
  CheckCircleTwoTone,
  InfoTwoTone,
  WarningOutlined,
} from '@mui/icons-material';
import {
  Alert,
  CircularProgress,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { EmailValidationResponse } from '@headway/api/models/EmailValidationResponse';
import { ProviderPatientCreate } from '@headway/api/models/ProviderPatientCreate';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPatientUpdate } from '@headway/api/models/ProviderPatientUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SourceAttribution } from '@headway/api/models/SourceAttribution';
import { UserCreate } from '@headway/api/models/UserCreate';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { UserUpdate } from '@headway/api/models/UserUpdate';
import { EmailValidationApi } from '@headway/api/resources/EmailValidationApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button as HelixButton } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import {
  PHONE_MASK,
  YUP_NO_AT_SIGN_MATCH,
  YUP_NO_AT_SIGN_MESSAGE,
  YUP_PHONE_MATCH,
} from '@headway/shared/constants/format';
import { formatBillingType } from '@headway/shared/utils/appointments';
import { getErrorMessage } from '@headway/shared/utils/error';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { Button } from '@headway/ui';
import {
  FieldCheckbox,
  FieldControl,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
  FieldToggleButton,
} from '@headway/ui/form';
import { FormRow } from '@headway/ui/form';
import { FieldSelect } from '@headway/ui/form/FieldSelect';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';
import { notifyError, notifyWarning } from '@headway/ui/utils/notify';

import { PROVIDER_SELECTABLE_BILLING_TYPES } from 'constants/providerSelectableBillingTypes';
import { useAuthStore } from 'stores/AuthStore';

import { mapBillingTypeToDisplayNameDescriptive } from './utils/billingType';

const patientSourcedOptions: {
  [value in Exclude<SourceAttribution, SourceAttribution.BULK_IMPORT>]: string;
} = {
  [SourceAttribution.ALREADY_IN_CASELOAD]: 'They are already in my caseload',
  [SourceAttribution.REFERRAL_FROM_COLLEAGUE_OR_PHYSICIAN]:
    'Referral from a colleague or physician',
  [SourceAttribution.PSYCHOLOGY_TODAY]: 'Psychology Today',
  [SourceAttribution.ZOCDOC]: 'Zocdoc',
  [SourceAttribution.GOOGLE_MY_BUSINESS]: 'Google My Business',
  [SourceAttribution.ALMA_SONDERMIND_GROW_PATH]:
    'Alma / Sondermind / Grow / Path',
  [SourceAttribution.MENTAL_HEALTH_MATCH]: 'Mental Health Match',
  [SourceAttribution.MY_OWN_WEBSITE]: 'My own website',
  [SourceAttribution.OTHER]: 'Other',
};

export interface PatientDemographicsFormProps {
  onSubmit: (
    patient: UserRead,
    providerPatient: ProviderPatientRead,
    hasDisplayNameChecked: boolean,
    displayNameCheckedCount: number,
    notifyUser?: boolean
  ) => void;
  provider?: ProviderRead;
  initialUser?: UserRead;
}

interface PatientDemographicsFormImplProps
  extends PatientDemographicsFormProps {
  AuthStore: any;
  navigate: NavigateFunction;
}

type PatientDemographicsFormState = EmailValidationResponse & {
  existingUser?: UserRead;
  validatingEmail?: boolean;
  isEmailChecked?: boolean;
  notifyUser: boolean;
};

type UserFormUpdate = UserUpdate &
  Pick<ProviderPatientUpdate, 'billingTypeDefault'>;
type UserFormCreate = UserCreate &
  Pick<ProviderPatientCreate, 'billingTypeDefault'> & {
    providerPatientSourceAttribution?: ProviderPatientCreate['sourceAttribution'];
    otherProviderPatientSourceAttribution?: ProviderPatientCreate['otherSourceAttribution'];
  };

type FormValues = UserFormUpdate &
  UserFormCreate & {
    hasDisplayNameChecked?: boolean;
    displayNameCheckedCount?: number;
    notifyPatient?: boolean;
  };

export const sendPatientAccountInviteEmail = async (
  providerPatientId: number
) => {
  try {
    await ProviderPatientApi.sendProviderPatientAccountInvite(
      providerPatientId
    );
  } catch (error: AnyTS4TryCatchUnknownError) {
    notifyWarning(
      getErrorMessage(
        error,
        'Failed to send account invitation email to client.'
      )
    );
    logException(error);
  }
};

class PatientDemographicsFormImpl extends React.Component<
  PatientDemographicsFormImplProps,
  PatientDemographicsFormState
> {
  state: PatientDemographicsFormState = {
    isValidEmail: true,
    notifyUser: false,
  };

  updatePatient = async (
    values: UserFormUpdate,
    notifyUser: boolean,
    hasDisplayNameChecked: boolean,
    displayNameCheckedCount: number
  ) => {
    const { billingTypeDefault } = values;

    try {
      const initialUser: NonNullable<UserRead> = this.props.initialUser!;
      const providerPatient = await ProviderPatientApi.getProviderPatientByIds({
        provider_id: this.props.AuthStore.provider?.id,
        patient_user_id: initialUser.id,
      });
      const updateUserData: UserUpdate = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
      };
      if (hasDisplayNameChecked) {
        updateUserData.displayFirstName = values.displayFirstName;
        updateUserData.displayLastName = values.displayLastName;
      }

      if (
        this.state.existingUser &&
        this.state.existingUser.email !== initialUser.email
      ) {
        let existingUser = this.state.existingUser;

        const providerLicenseStateId = this.props.AuthStore.provider
          ? this.props.AuthStore.provider.providerLicenseState.id
          : undefined;
        const providerPatientPayload = {
          providerId: this.props.AuthStore.provider?.id,
          userId: existingUser.id,
          providerLicenseStateId,
          billingTypeDefault,
        };

        const newProviderPatient =
          await ProviderPatientApi.createProviderPatient(
            providerPatientPayload
          );
        // update the new patient if anything else changed
        if (
          existingUser.firstName !== values.firstName ||
          existingUser.lastName !== values.lastName ||
          existingUser.displayFirstName !== values.displayFirstName ||
          existingUser.displayLastName !== values.displayLastName ||
          (existingUser.phone
            ? existingUser.phone !== values.phone
            : values.phone)
        ) {
          existingUser = await UserApi.updateUser(
            existingUser.id,
            updateUserData
          );
        }

        // Archive the initial patient
        await ProviderPatientApi.updateProviderPatient(providerPatient.id, {
          hidden: true,
        });
        // close / redirect to the new patient page
        this.props.navigate(`/clients/${existingUser.id}`);
        this.props.onSubmit(
          existingUser,
          newProviderPatient,
          hasDisplayNameChecked,
          displayNameCheckedCount,
          notifyUser
        );
      } else {
        // happy path
        if (
          initialUser.email !== values.email ||
          initialUser.firstName !== values.firstName ||
          initialUser.lastName !== values.lastName ||
          initialUser.displayFirstName !== values.displayFirstName ||
          initialUser.displayLastName !== values.displayLastName ||
          (initialUser.phone
            ? initialUser.phone !== values.phone
            : values.phone)
        ) {
          const updatedUser = await UserApi.updateUser(
            initialUser.id,
            updateUserData
          );
          this.props.onSubmit(
            updatedUser,
            providerPatient,
            hasDisplayNameChecked,
            displayNameCheckedCount,
            notifyUser
          );
        } else {
          this.props.onSubmit(
            initialUser,
            providerPatient,
            hasDisplayNameChecked,
            displayNameCheckedCount,
            notifyUser
          );
        }
      }
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyError(getErrorMessage(error, 'Failed to update the client.'));
      logException(error);
    }
  };

  addPatient = async (
    values: UserFormCreate,
    notifyUser: boolean,
    hasDisplayNameChecked: boolean,
    displayNameCheckedCount: number
  ) => {
    try {
      const {
        billingTypeDefault,
        providerPatientSourceAttribution,
        otherProviderPatientSourceAttribution,
        ...userCreate
      } = values;
      let user;

      // Set display name to legal name if hasDisplayNameChecked is unchecked.
      if (!hasDisplayNameChecked) {
        userCreate.displayFirstName = values.firstName;
        userCreate.displayLastName = values.lastName;
      }

      if (this.state.existingUser) {
        user = this.state.existingUser;
      } else {
        user = await UserApi.createUser({
          ...userCreate,
          // This is confusing as we've not yet invited the user but we need to set this to true
          // in order for the email to be sent as expected. See token generation logic in Mamba.
          isInvited: true,
          inviteChannel: UserInviteChannel.PROVIDER_PORTAL,
        });
      }

      const providerLicenseStateId = this.props.AuthStore?.provider
        ? this.props.AuthStore?.provider.providerLicenseState.id
        : undefined;
      const providerPatientPayload = {
        providerId: this.props.AuthStore?.provider?.id,
        userId: user.id,
        providerLicenseStateId,
        billingTypeDefault,
        sourceAttribution: providerPatientSourceAttribution,
        ...(providerPatientSourceAttribution === SourceAttribution.OTHER
          ? { otherSourceAttribution: otherProviderPatientSourceAttribution }
          : {}),
      };

      const createdProviderPatient =
        await ProviderPatientApi.createProviderPatient(providerPatientPayload);

      if (
        user.firstName !== values.firstName ||
        user.lastName !== values.lastName ||
        user.displayFirstName !== values.displayFirstName ||
        user.displayLastName !== values.displayLastName ||
        (user.phone ? user.phone !== values.phone : values.phone)
      ) {
        const updateUserData: UserUpdate = {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
        };
        if (hasDisplayNameChecked) {
          updateUserData.displayFirstName = values.displayFirstName;
          updateUserData.displayLastName = values.displayLastName;
        }
        user = await UserApi.updateUser(user.id, updateUserData);
      }

      if (notifyUser)
        await sendPatientAccountInviteEmail(createdProviderPatient.id);

      this.props.onSubmit(
        user,
        createdProviderPatient,
        hasDisplayNameChecked,
        displayNameCheckedCount,
        notifyUser
      );
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyError(getErrorMessage(error, 'Failed to add client.'));
      logException(error);
    }
  };

  handleSubmit = async (values: FormValues) => {
    const shouldNotifyPatient = !!values.notifyPatient;
    delete values.notifyPatient;
    const hasDisplayNameChecked = !!values.hasDisplayNameChecked;
    delete values.hasDisplayNameChecked;
    const displayNameCheckedCount =
      values.displayNameCheckedCount === undefined
        ? -1
        : values.displayNameCheckedCount;
    delete values.displayNameCheckedCount;

    if (!this.state.isEmailChecked) {
      await this.checkEmailStatus(values.email);
      if (!this.state.existingUser && !this.hasUnresolvedEmail()) {
        this.createOrUpdatePatient(
          values,
          shouldNotifyPatient,
          hasDisplayNameChecked,
          displayNameCheckedCount
        );
      }
    } else {
      await this.createOrUpdatePatient(
        values,
        shouldNotifyPatient,
        hasDisplayNameChecked,
        displayNameCheckedCount
      );
    }
  };

  createOrUpdatePatient = async (
    values: UserFormUpdate | UserFormCreate,
    notifyUser: boolean,
    hasDisplayNameChecked: boolean,
    displayNameCheckedCount: number
  ) => {
    try {
      if (this.props.initialUser) {
        await this.updatePatient(
          values,
          notifyUser,
          hasDisplayNameChecked,
          displayNameCheckedCount
        );
      } else {
        await this.addPatient(
          values,
          notifyUser,
          hasDisplayNameChecked,
          displayNameCheckedCount
        );
      }
    } catch (error: AnyTS4TryCatchUnknownError) {
      notifyError(getErrorMessage(error, 'Failed to add client.'));
      logException(error);
    }
  };

  checkEmailStatus = async (enteredEmail: string | undefined) => {
    if (enteredEmail && enteredEmail !== this.props.initialUser?.email) {
      this.setState({ validatingEmail: true });
      await this.checkForExistingUser(enteredEmail);
      await this.validateEmail(enteredEmail);
    } else {
      this.setState({ isValidEmail: true });
    }
    this.setState({ isEmailChecked: true });
  };

  checkForExistingUser = async (email: string) => {
    try {
      const existingUsers = await UserApi.findUsers({ email });
      this.setState({
        existingUser: existingUsers.length ? existingUsers[0] : undefined,
      });
    } catch (err) {
      logException(err);
    }
  };

  validateEmail = async (email: string) => {
    try {
      const emailValidationResult = await EmailValidationApi.validateEmail({
        email,
      });
      this.setState({
        ...emailValidationResult,
        validatingEmail: false,
      });
    } catch (err) {
      this.setState({ validatingEmail: false });
      logException(err);
    }
  };

  hasUnresolvedEmail = () => {
    return (
      !this.state.validatingEmail &&
      (!this.state.isValidEmail || this.state.suggestedEmail)
    );
  };

  render() {
    const { initialUser } = this.props;
    const { existingUser } = this.state;

    return (
      <Formik
        initialValues={{
          email: (existingUser ? existingUser.email : initialUser?.email) || '',
          phone: (existingUser ? existingUser.phone : initialUser?.phone) || '',
          firstName:
            (existingUser ? existingUser.firstName : initialUser?.firstName) ||
            '',
          lastName:
            (existingUser ? existingUser.lastName : initialUser?.lastName) ||
            '',
          displayFirstName:
            (existingUser
              ? existingUser.displayFirstName
              : initialUser?.displayFirstName) || '',
          displayLastName:
            (existingUser
              ? existingUser.displayLastName
              : initialUser?.displayLastName) || '',
          hasDisplayNameChecked:
            initialUser?.displayFirstName !== initialUser?.firstName ||
            initialUser?.displayLastName !== initialUser?.lastName,
          displayNameCheckedCount: 0,
          billingTypeDefault: BillingType.INSURANCE,
          notifyPatient: false,
          ...(!initialUser
            ? {
                providerPatientSourceAttribution: undefined,
                otherProviderPatientSourceAttribution: '',
              }
            : {}),
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Please enter a valid email.')
            .required('Email is required.'),
          phone: Yup.string().matches(YUP_PHONE_MATCH, {
            message: 'Phone number must be 10 digits.',
            excludeEmptyString: true,
          }),
          firstName: Yup.string().required('First name is required.'),
          lastName: Yup.string().required('Last name is required.'),
          displayFirstName: Yup.string().matches(
            YUP_NO_AT_SIGN_MATCH,
            YUP_NO_AT_SIGN_MESSAGE
          ),
          displayLastName: Yup.string().matches(
            YUP_NO_AT_SIGN_MATCH,
            YUP_NO_AT_SIGN_MESSAGE
          ),
          ...(!initialUser
            ? {
                providerPatientSourceAttribution:
                  Yup.string().required('Field is required'),
                otherProviderPatientSourceAttribution: Yup.string().when(
                  'providerPatientSourceAttribution',
                  {
                    is: SourceAttribution.OTHER,
                    then: Yup.string().required('Field is required'),
                  }
                ),
              }
            : {}),
        })}
        onSubmit={(values) => this.handleSubmit(values)}
      >
        {({
          isSubmitting,
          handleBlur,
          errors,
          touched,
          setFieldValue,
          submitForm,
          values,
        }) => {
          return (
            <SafeFormikForm
              css={{
                marginTop: theme.space.base,
                '& > .hlx-guidance-card': {
                  marginBottom: theme.space.base,
                },
              }}
            >
              {existingUser ? (
                !initialUser ? (
                  <GuidanceCard variant="info">
                    This client already has a Headway account, so you don’t need
                    to add any more details. You can add this client to your
                    practice here.
                  </GuidanceCard>
                ) : (
                  <GuidanceCard variant="warning">
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        [theme.media.small]: {
                          alignItems: 'center',
                        },
                      }}
                    >
                      <div>
                        This email address is in our system already — if this is
                        the right client, you can add them here. The existing
                        record of this client will be archived if you need it
                        later.
                      </div>
                    </div>
                  </GuidanceCard>
                )
              ) : null}
              <FieldControl
                name="email"
                disabled={
                  initialUser?.isVerified || (initialUser && existingUser)
                }
              >
                <FieldInputLabel>Email</FieldInputLabel>
                <FieldInput
                  data-testid="addPatientEmailField"
                  onBlur={(e: any) => {
                    if (!errors.email) {
                      this.checkEmailStatus(e.currentTarget.value);
                    }
                    handleBlur(e);
                  }}
                  endAdornment={
                    this.state.validatingEmail ? (
                      <CircularProgress color="primary" size={20} />
                    ) : !errors.email && this.hasUnresolvedEmail() ? (
                      <InfoTwoTone css={{ color: theme.color.warning }} />
                    ) : touched.email &&
                      !errors.email &&
                      this.state.isEmailChecked ? (
                      <CheckCircleTwoTone
                        css={{ color: theme.color.primary }}
                      />
                    ) : null
                  }
                  onChange={(e: any) => {
                    this.setState({
                      existingUser: undefined,
                      suggestedEmail: undefined,
                      isValidEmail: true,
                      isEmailChecked: false,
                    });
                  }}
                  css={{
                    ' *:disabled': {
                      color: theme.color.gray,
                    },
                  }}
                />
                {initialUser?.isVerified && (
                  <FormHelperText>
                    We’ve already verified{' '}
                    {formatPatientName(initialUser, {
                      firstNameOnly: true,
                    })}
                    ’s account, so the email address can’t be changed.
                  </FormHelperText>
                )}
                <FieldErrorText />
              </FieldControl>
              {!errors.email && !existingUser && this.hasUnresolvedEmail() ? (
                <Alert
                  color="warning"
                  icon={<WarningOutlined />}
                  css={{
                    marginBottom: theme.space.base,
                  }}
                  action={
                    <React.Fragment>
                      <Button
                        color="inherit"
                        onClick={() => {
                          if (this.state.suggestedEmail) {
                            setFieldValue('email', this.state.suggestedEmail);
                          }
                          this.setState({
                            suggestedEmail: undefined,
                            isValidEmail: true,
                          });
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        color="inherit"
                        onClick={() => {
                          if (!this.state.suggestedEmail) {
                            setFieldValue('email', '');
                          }
                          this.setState({
                            suggestedEmail: undefined,
                            isValidEmail: true,
                          });
                        }}
                      >
                        No
                      </Button>
                    </React.Fragment>
                  }
                >
                  <div
                    css={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      [theme.media.small]: {
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                    }}
                  >
                    <div>
                      {this.state.suggestedEmail ? (
                        <span>
                          We couldn’t verify this email address. Did you mean{' '}
                          <b>{this.state.suggestedEmail}</b>?
                        </span>
                      ) : (
                        <span>
                          We couldn’t validate this email address. Are you sure
                          it’s correct?
                        </span>
                      )}
                    </div>
                  </div>
                </Alert>
              ) : null}
              <FieldControl name="phone" disabled={initialUser && existingUser}>
                <FieldInputLabel>Phone</FieldInputLabel>
                <FieldInput
                  data-testid="addPatientPhoneField"
                  mask={PHONE_MASK}
                  maskChar={null}
                  css={{
                    ' *:disabled': {
                      color: theme.color.gray,
                    },
                  }}
                />
                <FieldErrorText />
              </FieldControl>
              <>
                <FormGroup>
                  <FormRow>
                    <FieldControl
                      name="firstName"
                      disabled={initialUser && existingUser}
                      css={{ marginBottom: theme.space.sm }}
                      fullWidth={true}
                    >
                      <FieldInputLabel>Legal first name</FieldInputLabel>
                      <FieldInput
                        data-testid="addPatientFirstNameField"
                        css={{
                          ' *:disabled': {
                            color: theme.color.gray,
                          },
                        }}
                      />
                      <FieldErrorText />
                    </FieldControl>
                    <FieldControl
                      name="lastName"
                      data-testid="addPatientLastNameField"
                      disabled={initialUser && existingUser}
                      fullWidth={true}
                      css={{ marginBottom: theme.space.sm }}
                    >
                      <FieldInputLabel>Legal last name</FieldInputLabel>
                      <FieldInput
                        disabled={initialUser && existingUser}
                        css={{
                          ' *:disabled': {
                            color: theme.color.gray,
                          },
                        }}
                      />
                      <FieldErrorText />
                    </FieldControl>
                  </FormRow>
                  <FormHelperText>
                    Enter the name as it’s listed on your client’s insurance
                    card.
                  </FormHelperText>
                </FormGroup>
                <FieldControl
                  name="hasDisplayNameChecked"
                  css={{ marginBottom: theme.space.xs3 }}
                >
                  <FieldCheckbox
                    fieldType="boolean"
                    label="My client goes by a different name."
                    labelCss={{ color: theme.color.textGray }}
                    onClick={() => (values.displayNameCheckedCount += 1)}
                  />
                </FieldControl>
                {values.hasDisplayNameChecked && (
                  <>
                    <FormRow>
                      <FieldControl
                        name="displayFirstName"
                        fullWidth={true}
                        css={{ marginBottom: theme.space.sm }}
                      >
                        <FieldInputLabel>First Name</FieldInputLabel>
                        <FieldInput />
                        <FieldErrorText />
                      </FieldControl>
                      <FieldControl
                        name="displayLastName"
                        fullWidth={true}
                        css={{ marginBottom: theme.space.sm }}
                      >
                        <FieldInputLabel>Last Name</FieldInputLabel>
                        <FieldInput />
                        <FieldErrorText />
                      </FieldControl>
                    </FormRow>
                    <FormHelperText>
                      Enter the name your client goes by. This is how we’ll
                      address them in communications from Headway, and how they
                      will appear in your portal.
                    </FormHelperText>
                  </>
                )}
              </>
              {!initialUser && (
                <>
                  <FieldControl name="providerPatientSourceAttribution">
                    <FieldInputLabel>
                      How did this client find you/your practice?
                    </FieldInputLabel>
                    <FieldSelect data-testid="addPatientSourceAttributionField">
                      {Object.keys(patientSourcedOptions).map((key) => (
                        <MenuItem key={key} value={key}>
                          {
                            patientSourcedOptions[
                              key as Exclude<
                                SourceAttribution,
                                SourceAttribution.BULK_IMPORT
                              >
                            ]
                          }
                        </MenuItem>
                      ))}
                    </FieldSelect>
                    <FieldErrorText />
                  </FieldControl>
                  {values.providerPatientSourceAttribution ===
                    SourceAttribution.OTHER && (
                    <FieldControl name="otherProviderPatientSourceAttribution">
                      <FieldInputLabel>Other (please specify):</FieldInputLabel>
                      <FieldInput />
                      <FieldErrorText />
                    </FieldControl>
                  )}
                </>
              )}
              {!initialUser ? (
                <FieldControl
                  name="billingTypeDefault"
                  css={{ marginTop: theme.space.xl2 }}
                >
                  <FormLabel>Billing type</FormLabel>
                  <FieldToggleButton
                    css={{ marginTop: theme.space.sm }}
                    exclusive
                    options={Object.values(BillingType)
                      .filter((b) =>
                        PROVIDER_SELECTABLE_BILLING_TYPES.includes(b)
                      )
                      .map((type) => ({
                        key: type,
                        label: formatBillingType(type),
                        display: mapBillingTypeToDisplayNameDescriptive(type),
                      }))}
                  />
                </FieldControl>
              ) : null}
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.space.xl,
                }}
              >
                {existingUser && initialUser && (
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    css={{
                      padding: '1px 3px',
                      marginRight: theme.space.xs,
                    }}
                    type="button"
                    onClick={() => {
                      this.setState({
                        existingUser: undefined,
                      });
                    }}
                  >
                    Go back
                  </Button>
                )}
                <HelixButton
                  disabled={
                    isSubmitting ||
                    (!existingUser && !!this.hasUnresolvedEmail())
                  }
                  onPress={() => {
                    submitForm();
                  }}
                  variant="primary"
                >
                  {existingUser ? 'Add Client' : 'Save'}
                </HelixButton>
              </div>
            </SafeFormikForm>
          );
        }}
      </Formik>
    );
  }
}

export const PatientDemographicsForm = (
  props: PatientDemographicsFormProps
) => {
  const AuthStore = useAuthStore();
  const navigate = useNavigate();

  return (
    <PatientDemographicsFormImpl
      AuthStore={AuthStore}
      navigate={navigate}
      {...props}
    />
  );
};
