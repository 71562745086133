import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { getErrorMessage } from '@headway/shared/utils/error';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { Button, Modal } from '@headway/ui';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

interface ResendEmailModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSendEmail: (patient: UserRead) => Promise<void>;
  patient: UserRead | undefined;
}

export const ResendEmailModal: React.FC<
  React.PropsWithChildren<ResendEmailModalProps>
> = ({ open, onClose, onCancel, onSendEmail, patient }) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const closeAndClearState = () => {
    onClose();
  };

  const handleOnSubmit = () => {
    if (patient) {
      setIsSubmitting(true);
      onSendEmail(patient)
        .then((res) => {
          setIsSubmitting(false);
          closeAndClearState();
        })
        .catch((err) => {
          setIsSubmitting(false);
          notifyError(getErrorMessage(err, 'Error sending email'));
          logException(err);
        });
    }
  };

  return (
    <Modal open={open} onClose={closeAndClearState} title={`Re-send email`}>
      <div>
        You sent{' '}
        {formatPatientName(patient, {
          firstNameOnly: true,
        })}{' '}
        a welcome email less than 3 days ago - are you sure you want to send it
        again?
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.space.xl,
        }}
      >
        <Button
          color="gray"
          css={{ marginRight: theme.space.xs }}
          variant="outlined"
          disabled={isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          type="submit"
          onClick={handleOnSubmit}
        >
          Re-send email
        </Button>
      </div>
    </Modal>
  );
};
