import { Tune } from '@mui/icons-material';
import { Badge, IconButton, PopoverActions } from '@mui/material';
import React, { useLayoutEffect, useRef } from 'react';
import { View } from 'react-big-calendar';

import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { Tooltip, VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { DetailPopoverProps } from './DetailPopover';
import { FilterPopover } from './FilterPopover';

interface FilterProps {
  handleToggleCancelledAppointments: () => void;
  hideCancelledAppointments: boolean;
  providerId: number;
  calendars: ProviderCalendarRead[];
  selectedCalendarIds: number[];
  onFilterByCalendar: (exclusions: number[]) => void;
  view: View;
}

export const Filter: React.FC<React.PropsWithChildren<FilterProps>> = ({
  handleToggleCancelledAppointments,
  hideCancelledAppointments,
  providerId,
  selectedCalendarIds,
  calendars,
  onFilterByCalendar,
  view,
}) => {
  const action = useRef<PopoverActions | null>(null);

  useLayoutEffect(() => {
    if (action.current) {
      action.current.updatePosition();
    }
  }, []);

  const [anchorEl, setAnchorEl] =
    React.useState<DetailPopoverProps['anchorEl']>(null);
  const title = 'Filter';

  return (
    <>
      <FilterPopover
        action={action}
        calendars={calendars ?? []}
        selectedCalendarIds={selectedCalendarIds}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        handleToggleCancelledAppointments={handleToggleCancelledAppointments}
        hideCancelledAppointments={hideCancelledAppointments}
        onFilterByCalendar={onFilterByCalendar}
        view={view}
      />
      <Badge color="error">
        <Tooltip title={title}>
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            aria-label="Filter calendar"
            data-testid="calendarFilterAppointmentsButton"
            size="large"
          >
            <Tune
              css={{
                fontSize: theme.fontSize.xl,
                color: hideCancelledAppointments ? '#13aa65' : '#373a3c',
              }}
            />
            <VisuallyHidden>${title}</VisuallyHidden>
          </IconButton>
        </Tooltip>
      </Badge>
    </>
  );
};
