import { Global } from '@emotion/react';
import { PlayCircleFilled } from '@mui/icons-material';
import { Modal } from '@mui/material';
import React from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';

import { theme } from '@headway/ui/theme';

interface YoutubeVideoProps {
  videoId: string;
  /** Video title shown to screenreaders and optionally on the video thumbnail. */
  title: string;
  /** Whether to show the title on the video thumbnail. True by default. */
  showTitle?: boolean;
}

export const YoutubeVideo: React.FC<
  React.PropsWithChildren<YoutubeVideoProps>
> = ({ videoId, title, showTitle = true, ...rest }) => {
  const [isVideoOpen, setIsVideoOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div css={{ overflow: 'hidden', borderRadius: 6, width: '100%' }}>
        <div
          role="button"
          aria-label={`Open a YouTube video dialog: ${title}`}
          onClick={() => setIsVideoOpen(true)}
          css={{
            paddingBottom: '60%',
            position: 'relative',
            cursor: 'pointer',
            overflow: 'hidden',
          }}
          {...rest}
        >
          <div
            css={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              backgroundImage: `url(https://i.ytimg.com/vi/${videoId}/sddefault.jpg)`,
              backgroundPosition: '50%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'auto 140%', // YouTube adds a black border, zoom in a bit to remove it
              backgroundColor: theme.color.black,
              '::after': {
                content: "''",
                position: 'absolute',
                left: 0,
                width: '100%',
                height: '100%',
                background:
                  'linear-gradient(rgba(0, 0, 0, .1) 60%, rgba(0, 0, 0, .8)) 80%',
              },
            }}
          />
          <div
            css={{
              height: '100%',
              position: 'absolute',
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'space-between',
              padding: theme.space.base,
              background: 'transparent',
            }}
          >
            <PlayCircleFilled
              sx={{
                fill: theme.color.primary,
                background: theme.color.white,
                borderRadius: '50%',
                width: 35,
                height: 35,
              }}
            />
            <div
              css={{
                fontFamily: theme.fontFamily.postGrotesk,
                color: theme.color.white,
                fontWeight: theme.fontWeight.bold,
                fontSize: theme.fontSize.base,
              }}
            >
              {showTitle && title}
            </div>
          </div>
        </div>
      </div>
      <Global
        styles={{
          '.modal-video-close-btn': {
            right: 0,
            '& *:focus': {
              outline: `2px solid ${theme.color.primary} !important`,
            },
          },
        }}
      />
      <Modal open={isVideoOpen} keepMounted={true}>
        <div>
          <ModalVideo
            channel="youtube"
            isOpen={isVideoOpen}
            videoId={videoId}
            youtube={{
              autoplay: 1,
              color: 'white',
              rel: 0,
            }}
            onClose={() => setIsVideoOpen(false)}
            aria={{ openMessage: `YouTube Video: ${title}` }}
            role="dialog"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
