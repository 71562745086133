import { action } from 'mobx';
import moment from 'moment';
import React, { useState } from 'react';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderQuestionnaireRecredSummary } from '@headway/api/models/ProviderQuestionnaireRecredSummary';
import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderFrontEndCarrierApi } from '@headway/api/resources/ProviderFrontEndCarrierApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageHeader } from '@headway/helix/PageHeader';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { CARRIERS_REQUIRING_AUTHORIZATION } from '@headway/shared/constants/carrierIds';
import {
  BCBSMA_VIRTUAL_NETWORK_LINK,
  BCBSNJ_VIRTUAL_NETWORK_LINK,
} from '@headway/shared/constants/zendesk';
import {
  ENABLE_PROVIDER_TASK_INSURANCE_STATUS_PAGE,
  MULTI_STATE_CREDENTIALING_BETA,
  MULTI_STATE_CREDENTIALING_ENTRY_POINTS,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { Loader } from '@headway/ui/Loader';

import { PanelLayout } from 'layouts/PanelLayout';
import { isProviderLive } from 'utils/access';
import { MscPromotionalCard } from 'views/InsuranceStatus/MscPromotionalCard';

import { useProvider } from '../../hooks';
import {
  CoiProviderTaskModal,
  GenericProviderTaskModal,
} from './CoiComponents/ProviderTaskModals';
import { GuidanceCards, ProviderTaskGuidanceCard } from './GuidanceCards';
import { PayerTimeline } from './PayerTimeline';
import { PayerTimelineGroupComponent } from './PayerTimelineGroup';
import { PracticeStates } from './PracticeStates';
import { Subheader } from './Subheader';
import {
  getSharedDataFromPayerTimeline,
  useInsuranceStatus,
} from './UseInsuranceStatus';

export function InsuranceStatus({
  recredSummary,
}: {
  recredSummary?: ProviderQuestionnaireRecredSummary;
}) {
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isMSCEntryPointsEnabled = useFlag(
    MULTI_STATE_CREDENTIALING_ENTRY_POINTS
  );
  const isMSCOnboardingEnabled = useFlag(MULTI_STATE_CREDENTIALING_ONBOARDING);
  const provider = useProvider();
  const {
    tasks,
    payerStatusMap,
    payerTimelines,
    groupedPayerTimelines,
    isLoading,
    isError,
  } = useInsuranceStatus(provider);
  const [optOutModalOpen, setOptOutModalOpen] = useState<boolean>(false);
  const [activeCoiModal, setActiveCoiModal] = useState<boolean>(false);
  const [fileDropped, setFileDropped] = useState<boolean>(false);
  const [activeProviderTask, setActiveProviderTask] = useState<
    ProviderTaskRead | undefined
  >(undefined);

  const [optedOutCarrierId, setOptedOutCarrierId] =
    useState<FrontEndCarrierIdentifier>(
      FrontEndCarrierIdentifier.NONE_OF_THESE
    );
  const [optedOutCarrierName, setOptedOutCarrierName] = useState<string>('');
  const queryClient = useQueryClient();
  const providerTaskModalEnabled = useFlag(
    ENABLE_PROVIDER_TASK_INSURANCE_STATUS_PAGE
  );

  const deletePfecMutation = useMutation(
    async (carrier: ProviderFrontEndCarrierRead) => {
      await ProviderFrontEndCarrierApi.updateProviderFrontEndCarrier(
        carrier.id,
        {
          deletedOn: moment().toISOString(),
        }
      );
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([
          'providerFrontEndCarriers',
          provider.id,
        ]);
        toasts.add(
          `You’ve opted out of the ${virtualNetworkAbbreviation} virtual network.`,
          {
            variant: 'neutral',
          }
        );
      },
    }
  );

  let virtualNetworkAbbreviation = '';
  let virtualNetworkHelpCenterLink = '';
  if (
    optedOutCarrierId ===
    FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS
  ) {
    virtualNetworkAbbreviation = 'BCBSMA';
    virtualNetworkHelpCenterLink = BCBSMA_VIRTUAL_NETWORK_LINK;
  } else if (
    optedOutCarrierId ===
    FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY
  ) {
    virtualNetworkAbbreviation = 'BCBSNJ';
    virtualNetworkHelpCenterLink = BCBSNJ_VIRTUAL_NETWORK_LINK;
  }

  const optedOutCarrier = provider.providerFrontEndCarriers?.find(
    (carrier) => carrier.frontEndCarrierId === optedOutCarrierId
  );

  return (
    <PanelLayout>
      <div
        css={{
          padding: `${theme.spacing.x5} 0`,
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
        }}
      >
        <h1>
          <PageHeader>Insurance status</PageHeader>
        </h1>

        <Modal
          isOpen={optOutModalOpen}
          onDismiss={() => {
            setOptOutModalOpen(false);
          }}
          title="Are you sure you want to opt out?"
        >
          <ModalContent>
            <BodyText>
              By opting out,{' '}
              {optedOutCarrier?.wrapNetwork && !optedOutCarrier.credentialed
                ? 'you won’t'
                : 'you will no longer'}{' '}
              be eligible to virtually see clients with {optedOutCarrierName}{' '}
              plans. Learn more details about how this program works in our{' '}
              {virtualNetworkHelpCenterLink && (
                <Link href={virtualNetworkHelpCenterLink} target="_blank">
                  Help Center
                </Link>
              )}
              .
            </BodyText>
          </ModalContent>
          <ModalFooter>
            <Button
              onPress={() => {
                setOptOutModalOpen(false);
              }}
              variant="secondary"
              size="large"
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              size="large"
              onPress={() => {
                if (optedOutCarrier) {
                  deletePfecMutation.mutate(optedOutCarrier);
                }
                setOptOutModalOpen(false);
              }}
            >
              Opt out of {virtualNetworkAbbreviation} virtual network
            </Button>
          </ModalFooter>
        </Modal>

        {providerTaskModalEnabled && (
          <GenericProviderTaskModal
            activeProviderTask={activeProviderTask}
            setActiveProviderTask={setActiveProviderTask}
          />
        )}

        {isLoading ? (
          <Loader />
        ) : isError ? (
          <GuidanceCard variant="error" layout="vertical">
            <>
              We encountered an error loading your insurance status. Try
              refreshing the page and report the problem if it persists.
              <LinkButton
                variant="link"
                href={`${process.env.REACT_APP_MAIN_URL}/contact`}
                target="_blank"
                rel="noreferrer"
              >
                Report the error
              </LinkButton>
            </>
          </GuidanceCard>
        ) : (
          <>
            <Subheader payerStatusMap={payerStatusMap} />
            <GuidanceCards
              payerStatusMap={payerStatusMap}
              taskStatusMap={tasks?.taskStatusMap}
            />
            {(isMSCBetaEnabled || isMSCOnboardingEnabled) && (
              <PracticeStates
                providerLicenseStates={provider.activeProviderLicenseStates.filter(
                  (pls) => pls.questionnaireCompletedOn
                )}
              />
            )}
            {!recredSummary?.isLatestRecredComplete &&
              isMSCEntryPointsEnabled &&
              isProviderLive(provider) && <MscPromotionalCard />}
            {providerTaskModalEnabled && (
              <ProviderTaskGuidanceCard
                setActiveProviderTask={setActiveProviderTask}
                setActiveCoiModal={setActiveCoiModal}
                tasks={tasks}
              />
            )}
            {providerTaskModalEnabled && (
              <CoiProviderTaskModal
                activeCoiModal={activeCoiModal}
                fileDropped={fileDropped}
                setFileDropped={setFileDropped}
                setActiveCoiModal={setActiveCoiModal}
                tasks={tasks}
              />
            )}
            {isMSCBetaEnabled || isMSCOnboardingEnabled
              ? Array.from(groupedPayerTimelines.entries()).map(
                  ([payerName, payerTimelineGroups]) => (
                    <PayerTimelineGroupComponent
                      key={payerName}
                      payerName={payerName}
                      payerTimelineGroups={payerTimelineGroups}
                      onOptOutClicked={() => setOptOutModalOpen(true)}
                    />
                  )
                )
              : payerTimelines?.map((payerTimeline) => (
                  <PayerTimeline
                    key={payerTimeline.id}
                    {...getSharedDataFromPayerTimeline(payerTimeline)}
                    payerTimelines={[payerTimeline]}
                    onOptOutClicked={() => {
                      setOptOutModalOpen(true);
                      setOptedOutCarrierId(payerTimeline.frontEndCarrierId);
                      setOptedOutCarrierName(payerTimeline.payerName);
                    }}
                    isAuthorizationRequired={CARRIERS_REQUIRING_AUTHORIZATION.includes(
                      Number(payerTimeline.frontEndCarrierId)
                    )}
                  />
                ))}
          </>
        )}
      </div>
    </PanelLayout>
  );
}
