/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum TemplateErrorMessages {
    TEMPLATE_DOES_NOT_EXIST = 'TEMPLATE_DOES_NOT_EXIST',
    MISSING_TEMPLATE_INFO = 'MISSING_TEMPLATE_INFO',
    MISSING_COMPONENT_RESPONSE = 'MISSING_COMPONENT_RESPONSE',
    COMPONENT_RESPONSE_DOES_NOT_MATCH_EXPECTED_TYPE = 'COMPONENT_RESPONSE_DOES_NOT_MATCH_EXPECTED_TYPE',
    ERROR_CREATING_COMPONENT_MAP = 'ERROR_CREATING_COMPONENT_MAP',
    ERROR_VALIDATING_SCHEMA = 'ERROR_VALIDATING_SCHEMA',
    MISSING_REQUIRED_COMPONENT = 'MISSING_REQUIRED_COMPONENT',
    RESPONSE_NOT_UNIQUE = 'RESPONSE_NOT_UNIQUE',
    RESPONSE_LENGTH_TOO_SHORT = 'RESPONSE_LENGTH_TOO_SHORT',
    MISSING_VALUE_FOR_OTHER = 'MISSING_VALUE_FOR_OTHER'
}
