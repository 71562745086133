import { LocationOnTwoTone } from '@mui/icons-material';
import React from 'react';

import { Tooltip } from '@headway/ui';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';
import { theme } from '@headway/ui/theme';

interface EventDetailProps {
  title: string;
  label: React.ReactElement | string;
  icon: React.ComponentType<React.PropsWithChildren<unknown>>;
}

export const EventDetail: React.FC<
  React.PropsWithChildren<EventDetailProps>
> = ({ title, label, icon: Icon }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'flex-start',
      fontFamily: theme.fontFamily.postGrotesk,

      fontSize: theme.fontSize.base,
      marginBottom: theme.space.xs,
      ':last-child': {
        marginBottom: 0,
      },
      color: theme.color.black,
    }}
  >
    <Tooltip title={title} placement="left">
      <Icon
        css={{
          width: 21,
          height: 21,
          marginRight: theme.space.base,
        }}
      />
    </Tooltip>
    {label}
  </div>
);

interface LocationEventDetailProps {
  event: any;
}

export const LocationEventDetail: React.FC<
  React.PropsWithChildren<LocationEventDetailProps>
> = ({ event }) => {
  const { providerAddresses } = React.useContext(ProviderAddressContext);
  const location = event.telehealth
    ? 'Virtual (Telehealth)'
    : providerAddresses && providerAddresses.length
    ? providerAddresses.find(
        (address) => address.id === event?.providerAddressId
      )?.streetLine1
    : undefined;

  return location ? (
    <EventDetail title="Location" icon={LocationOnTwoTone} label={location} />
  ) : null;
};
