import * as React from 'react';

export const HeadwayLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 505.51 96.08"
    {...props}
  >
    <path
      className="headway-logo_svg__cls-1"
      fill="#13aa65"
      d="M102.69 51.34a51.35 51.35 0 10-95.8 25.7h88.87a51 51 0 006.93-25.7z"
    />
    <path
      className="headway-logo_svg__cls-2"
      d="M149.09 19.97h11.33v23.07h24.94V19.97h11.32v57h-11.32V53.79h-24.94v23.25h-11.33zM242.35 60.72h-28.44c.89 5.62 6 7.41 10.43 7.41a13.4 13.4 0 0011.24-5.46l6.77 6.68a22.69 22.69 0 01-18.09 8.56c-10.92 0-21.68-6.93-21.68-21.35 0-14.26 10.51-21.68 21-21.68 10 0 19.08 6.85 19.08 20.62a45.49 45.49 0 01-.31 5.22zM223.2 44.25a8.91 8.91 0 00-9.29 8.07h18.25c-.49-5.22-4.07-8.07-8.96-8.07zM329.76 41.48V19.97h11v57h-11v-5.05a15.9 15.9 0 01-13 6c-10.43 0-20.78-7.25-20.78-21.6s10.1-21.43 20.29-21.43c6.03-.01 10.84 2.35 13.49 6.59zm-.09 14.92c0-9-5.94-12.23-11.4-12.23-6.36 0-11.17 4.32-11.17 12.14 0 7.58 4.65 12.31 11.33 12.31 6.07 0 11.2-3.99 11.2-12.22zM393.36 61.04l8.06-25.1h12.34l-15.27 41.1h-10.51l-9-24-8.88 24h-10.48l-15.08-41.1h12.22l8.06 25 8.81-25h10.75zM505.52 75.47c0 13.44-10.6 20.61-21.52 20.61-9.29 0-17.85-5.13-20.54-14.91l9.95-3.59c1.38 6.28 6 9.05 11 9.05 5.78 0 10.18-3.42 10.18-10.35v-6a13.8 13.8 0 01-11.56 6.27c-8.56 0-16.71-5.53-16.71-16.7v-24h11.08v22.49c0 5.22 2.69 8.81 8 8.81 5.62 0 9-4.32 9-11.17V35.94h11.09zM279.7 35.94v5c-2.53-4.15-6.93-6-12.23-6-10.51 0-21.27 7.42-21.27 21.76 0 13.69 9.94 21.27 20.21 21.27a17.05 17.05 0 0013.29-5.93v5h11v-41.1zm-11.74 32.43c-5.79 0-10.76-4.56-10.76-12 0-7.58 5.13-12.15 11-12.15s11.17 4.65 11.17 12c0 7.51-5.46 12.15-11.41 12.15zM446.63 35.94v5c-2.53-4.15-6.94-6-12.23-6-10.51 0-21.27 7.42-21.27 21.76 0 13.69 9.94 21.27 20.21 21.27a17.05 17.05 0 0013.29-5.93v5h11v-41.1zm-11.74 32.43c-5.79 0-10.76-4.56-10.76-12 0-7.58 5.13-12.15 11-12.15s11.17 4.65 11.17 12c0 7.51-5.46 12.15-11.41 12.15z"
    />
  </svg>
);
