import { EventAvailable } from '@mui/icons-material';

import { IconCheck } from '@headway/helix/icons/Check';
import { theme } from '@headway/helix/theme';
import { Loading } from '@headway/icons/dist/helix/Loading';
import { Monetization } from '@headway/icons/dist/helix/provider/Monetization';

import { PayerTimelineItemStatus } from './PayerTimelineItemStatus';

const STATUS_STYLES = {
  [PayerTimelineItemStatus.PAYMENT_READY_PENDING]: {
    backgroundColor: theme.color.system.lightGray,
    iconColor: theme.color.system.textBlack,
    icon: Monetization,
  },
  [PayerTimelineItemStatus.CREDENTIALING_PENDING]: {
    backgroundColor: theme.color.system.lightGray,
    iconColor: theme.color.system.textBlack,
    icon: EventAvailable,
  },
  [PayerTimelineItemStatus.IN_PROGRESS]: {
    backgroundColor: theme.color.system.textBlack,
    iconColor: theme.color.system.white,
    icon: Loading,
  },
  [PayerTimelineItemStatus.COMPLETE]: {
    backgroundColor: theme.color.primary.blue,
    iconColor: theme.color.system.white,
    icon: IconCheck,
  },
};

interface PayerTimelineStatusIndicatorProps {
  status: PayerTimelineItemStatus;
}

export function PayerTimelineStatusIndicator({
  status,
}: PayerTimelineStatusIndicatorProps) {
  const { backgroundColor, icon: Icon, iconColor } = STATUS_STYLES[status];
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        width: 36,
        height: 36,
        position: 'relative',
        zIndex: 1,
        backgroundColor,
        color: iconColor,
      }}
    >
      <Icon />
    </div>
  );
}
