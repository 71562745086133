import { css } from '@emotion/react';
import { Avatar, CircularProgress, IconButton } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { CaptureContext } from '@sentry/types';
import React, { useRef } from 'react';
import ReactDropzone from 'react-dropzone';
import ReactCrop, { ReactCropProps } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderSearchIndexRecordRead } from '@headway/api/models/ProviderSearchIndexRecordRead';
import { ProviderStateSearchIndexRecordRead } from '@headway/api/models/ProviderStateSearchIndexRecordRead';
import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';
import { Button } from '@headway/helix/Button';
import { getProviderId } from '@headway/shared/utils/providers';
import { uploadFileToS3 } from '@headway/shared/utils/upload';

import { theme } from '../theme';

interface ProviderProfilePhotoProps {
  photoUrl?: string;
  onCancel?: () => void;
  onCropFinish?: (publicUrl: string, s3ObjectKey: string) => void;
  onUploadFinish?: (publicUrl: string, s3ObjectKey: string) => void;
  onError?: (err: string, additionalContext?: CaptureContext) => void;
  onWarning?: (message: string) => void;
  provider?:
    | ProviderRead
    | ProviderSearchIndexRecordRead
    | ProviderStateSearchIndexRecordRead;
  supportUploads?: boolean;
  imgProps?: { [key: string]: any };
  uploadButtonText?: string;
  addPhotoIcon?: any;
  hideUploadButton?: boolean;
}

/**
 * Gets the cropped photo as a canvas so we can convert to a file for upload. Based on
 * https://github.com/DominicTobias/react-image-crop#what-about-showing-the-crop-on-the-client
 */
const getCroppedPhoto = (
  image: HTMLImageElement | undefined,
  crop: ReactCropProps['crop'],
  onError: ProviderProfilePhotoProps['onError']
): HTMLCanvasElement | undefined => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (
    !image ||
    !crop.width ||
    !crop.height ||
    crop.x === undefined ||
    crop.y === undefined ||
    !ctx
  ) {
    const photoInfo = {
      pixelRatio: window.devicePixelRatio,
      imageWidth: image?.width,
      imageHeight: image?.height,
      imageNaturalWidth: image?.naturalWidth,
      imageNaturalHeight: image?.naturalHeight,
      cropWidth: crop.width,
      cropHeight: crop.height,
      canvasWidth: canvas.width,
      canvasHeight: canvas.height,
    };
    onError?.('Cropped photo is empty', { extra: photoInfo });
    return;
  }

  // Ratio = how many of screen's actual pixels are used to draw a single CSS pixel
  // Usually 2 for high definition/retina displays, 3 for mobile
  const pixelRatio = window.devicePixelRatio;
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const idealCanvasWidth = crop.width * pixelRatio * scaleX;
  const idealCanvasHeight = crop.height * pixelRatio * scaleY;

  // HTMLCanvasElements have max width x height of ~16.7 million pixels
  const maxCanvasDimensions = 16777216;
  if (idealCanvasWidth * idealCanvasHeight <= maxCanvasDimensions) {
    canvas.width = idealCanvasWidth;
    canvas.height = idealCanvasHeight;
    // Scale the photo based on the device's pixel ratio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  } else {
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
  }

  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );

  return canvas;
};

const initialCrop: ReactCropProps['crop'] = {
  aspect: 1,
  unit: '%',
  width: 100,
  x: 0,
  y: 0,
};

const ProviderProfilePhoto: React.FC<
  React.PropsWithChildren<ProviderProfilePhotoProps>
> = ({
  photoUrl,
  onCancel,
  onCropFinish = () => {},
  onUploadFinish = () => {},
  onError = () => {},
  onWarning,
  provider,
  supportUploads,
  imgProps,
  uploadButtonText = 'Update Photo',
  addPhotoIcon,
  hideUploadButton,
  ...rest
}) => {
  const photoRef = useRef<HTMLImageElement>();
  const [uploadInProgress, setUploadInProgress] = React.useState(false);

  const [crop, setCrop] = React.useState<ReactCropProps['crop']>(initialCrop);
  const [cropInProgress, setCropInProgress] = React.useState(false);

  const onCropSave = async () => {
    const uploadCroppedBlobToS3: BlobCallback = async (blob) => {
      if (!blob || !provider) {
        onWarning?.(
          "There was an error cropping your photo — we're working on it! For now, you can use the preview shown or upload a new photo."
        );
        setCropInProgress(false);
        setCrop(initialCrop);
        return;
      }

      setUploadInProgress(true);
      const fileName = `${getProviderId(provider)}-${Number(
        new Date()
      ).toString()}.jpeg`;
      const file = new File([blob], fileName, {
        type: blob.type,
      });
      const uploadedPhoto = await uploadFileToS3(
        file,
        UserUploadTypes.PROVIDER_PHOTOS,
        getProviderId(provider),
        true
      );

      onUploadFinish(uploadedPhoto.link, uploadedPhoto.s3ObjectKey);
      setUploadInProgress(false);
      onCropFinish(uploadedPhoto.link, uploadedPhoto.s3ObjectKey);
      setCropInProgress(false);
      setCrop(initialCrop);
    };

    getCroppedPhoto(photoRef.current, crop, onError)?.toBlob(
      uploadCroppedBlobToS3,
      'image/jpeg',
      1
    );
  };

  const onCropCancel = () => {
    onCancel?.();
    setCropInProgress(false);
    setCrop(initialCrop);
  };

  const onFileDrop = async (files: File[]) => {
    if (!files.length) {
      return;
    }
    setUploadInProgress(true);
    try {
      const uploadedPhoto = await uploadFileToS3(
        files[0],
        UserUploadTypes.PROVIDER_PHOTOS,
        provider ? getProviderId(provider) : 0,
        true
      );
      onUploadFinish?.(uploadedPhoto.link, uploadedPhoto.s3ObjectKey);
      setCrop(initialCrop);
      setCropInProgress(true);
      setUploadInProgress(false);
    } catch (err: any) {
      onError(err);
      setUploadInProgress(false);
    }
  };

  if (!imgProps?.alt) {
    let avatarAltText = 'Headshot of provider';
    if (provider) {
      avatarAltText = `Headshot of ${provider.displayFirstName} ${
        provider.displayLastName
      }${provider.licenseType ? ', ' + provider.licenseType : ''}`;
    }
    imgProps = {
      ...imgProps,
      alt: avatarAltText,
    };
  }

  let profilePhoto: any;

  if (uploadInProgress) {
    profilePhoto = (
      <Skeleton variant="circular">
        <Avatar css={photoStyles} imgProps={imgProps} />
      </Skeleton>
    );
  } else if (addPhotoIcon && !photoUrl) {
    profilePhoto = (
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px',
        }}
        style={{
          background: theme.color.primaryBackground,
          borderRadius: '50%',
          height: '130px',
          width: '130px',
          border: `1px solid ${theme.color.primaryBackground}`,
        }}
      >
        {addPhotoIcon}
      </div>
    );
  } else {
    profilePhoto = (
      <Avatar
        data-testid="profilePhoto"
        css={photoStyles}
        imgProps={imgProps}
        src={photoUrl}
      />
    );
  }

  return (
    <div css={wrapperStyles} {...rest}>
      {cropInProgress && photoUrl ? (
        <div css={{ width: '250px' }}>
          <ReactCrop
            circularCrop
            crop={crop}
            crossorigin="anonymous"
            keepSelection
            minHeight={50}
            minWidth={50}
            onChange={(crop) => setCrop(crop)}
            onImageLoaded={(image) => {
              photoRef.current = image;
            }}
            src={photoUrl}
          />
          <div css={buttonWrapperStyles}>
            <Button
              css={{ marginRight: theme.space.xs }}
              disabled={uploadInProgress}
              onPress={() => {
                onCropCancel();
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={uploadInProgress}
              onPress={() => {
                onCropSave();
              }}
              variant="primary"
            >
              Save photo
            </Button>
          </div>
        </div>
      ) : (
        !supportUploads && profilePhoto
      )}

      {supportUploads && !cropInProgress && (
        <ReactDropzone onDrop={onFileDrop} accept={'image/*'}>
          {({ getRootProps, getInputProps, open }) => (
            <div {...getRootProps()} css={wrapperStyles}>
              <input {...getInputProps()} />
              <IconButton css={iconButtonStyles}>{profilePhoto}</IconButton>
              <div css={buttonWrapperStyles}>
                {!hideUploadButton && (
                  <Button
                    variant="secondary"
                    disabled={uploadInProgress}
                    onPress={() => {
                      open();
                    }}
                  >
                    {uploadButtonText}
                  </Button>
                )}
                {uploadInProgress && (
                  <CircularProgress size={24} css={circularProgressStyles} />
                )}
              </div>
            </div>
          )}
        </ReactDropzone>
      )}
    </div>
  );
};

const photoStyles = css`
  &.MuiAvatar-root {
    width: 100%;
    height: 100%;
  }
  position: relative;
  ::after {
    content: '';
    padding-bottom: 100%;
  }
  .MuiAvatar-img {
    position: absolute;
  }
  .MuiSvgIcon-root:not(.MuiAvatar-fallback) {
    width: 100%;
    height: 100%;
  }
`;

const circularProgressStyles = css`
  position: absolute;
  margin: auto;
  top: 12.5px;
`;

const wrapperStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
`;

const buttonWrapperStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: ${theme.space.xs};
  position: relative;
  width: 250px;
`;

const iconButtonStyles = css`
  padding: 0px;
  width: 130px;
`;

export { ProviderProfilePhoto };
