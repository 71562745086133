import { css } from '@emotion/react';

import { BodyText } from '@headway/helix/BodyText';
import { Divider } from '@headway/helix/Divider';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { CptCodeOption } from '@headway/ui/CPTCodeInput';
import { DiagnosisCodeOption } from '@headway/ui/DiagnosisCodeInput';

export interface NonEditableSessionDetailsSectionProps {
  sessionDate: string;
  sessionStartTime: string;
  sessionDuration: string;
  sessionExactStartTime: string;
  sessionExactStopTime: string;
  sessionLocation: string | undefined;
  sessionCptCodes: CptCodeOption[] | undefined;
  sessionDiagnosisCodes: DiagnosisCodeOption[] | undefined;
}

export const NonEditableSessionDetailsSection = ({
  sessionDate,
  sessionStartTime,
  sessionDuration,
  sessionExactStartTime,
  sessionExactStopTime,
  sessionLocation,
  sessionCptCodes,
  sessionDiagnosisCodes,
}: NonEditableSessionDetailsSectionProps) => {
  const topLevelItems = [
    {
      label: 'Date',
      value: sessionDate,
    },
    {
      label: 'Schedule start time',
      value: sessionStartTime,
    },
    {
      label: 'Schedule duration',
      value: sessionDuration,
    },
  ];
  const bottomLevelItems = [
    {
      label: 'Actual start time',
      value: sessionExactStartTime,
    },
    {
      label: 'Actual stop time',
      value: sessionExactStopTime,
    },
    {
      label: 'Session location',
      value: sessionLocation,
    },
    {
      label: 'CPT codes',
      value:
        sessionCptCodes
          ?.map((item) => `${item.value} \u2014 ${item.display}`)
          .join(', ') || '—',
    },
    {
      label: 'Diagnosis codes',
      value:
        sessionDiagnosisCodes
          ?.map((item) => `${item.value} \u2014 ${item.display}`)
          .join(', ') || '—',
    },
  ];

  return (
    <div css={nonEditableSessionDetailsViewCss.mainContainer}>
      <SectionHeader>Session details</SectionHeader>
      <dl css={nonEditableSessionDetailsViewCss.topContainer}>
        {topLevelItems.map((item) => (
          <div key={item.label}>
            <dt>
              <BodyText>
                <b>{item.label}</b>
              </BodyText>
            </dt>
            <dd>
              <BodyText>{item.value}</BodyText>
            </dd>
          </div>
        ))}
      </dl>
      <div css={{ height: theme.spacing.x4 }} />
      <Divider autoSpacing={false} />
      <div css={{ height: theme.spacing.x4 }} />
      <dl css={nonEditableSessionDetailsViewCss.bottomContainer}>
        {bottomLevelItems.map((item) => (
          <div key={item.label}>
            <dt>
              <BodyText>
                <b>{item.label}</b>
              </BodyText>
            </dt>
            <dd>
              <BodyText>{item.value}</BodyText>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

const nonEditableSessionDetailsViewCss = {
  mainContainer: css({
    border: `1px solid ${theme.color.system.borderGray}`,
    padding: theme.spacing.x5,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    marginBottom: theme.spacing.x5,
    dt: {
      minWidth: 200,
    },
  }),
  topContainer: css({
    display: 'flex',
    marginBottom: 0,
    dd: {
      margin: 0,
    },
    [theme.media.mobile]: {
      flexDirection: 'column',
      gap: theme.spacing.x3,
    },
  }),
  bottomContainer: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.x3,
    margin: 0,
    div: {
      display: 'flex',
    },
    dd: {
      margin: 0,
    },
    [theme.media.mobile]: {
      div: {
        flexDirection: 'column',
      },
    },
  }),
};
