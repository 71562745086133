import React from 'react';

import { useFlag as useFlagOther } from './react';

/**
 * Safely use a feature flag across all apps without worrying about SSR/CSR.  This is helpful
 * in shared components that are used in multiple apps.
 */
export function useFlag<T = boolean>(flag: string, defaultValue: T) {
  return useFlagOther(flag, defaultValue);
}
