import clsx from 'clsx';
import { Form as FormikForm, FormikFormProps, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

import { DATA } from './consts';
import { FormInputProps } from './useFormInput';

type FormProps = { name?: string } & FormikFormProps;

export const Form = ({ className, ...props }: FormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { isSubmitting, isValidating, errors, submitCount } = useFormikContext<{
    [key: string]: any;
  }>();

  const isInvalid = Object.keys(errors).length > 0;
  const totalSubmitCountRef = React.useRef<number>(submitCount);

  useEffect(() => {
    const form = formRef.current;

    if (!form) {
      return;
    }

    if (
      submitCount > totalSubmitCountRef.current &&
      !isValidating &&
      isInvalid
    ) {
      totalSubmitCountRef.current = submitCount;

      const invalidInputs = form.querySelectorAll(
        `[${DATA.VALIDATION}='invalid']`
      );

      if (invalidInputs.length === 0) {
        return;
      }

      const [first] = invalidInputs;
      const selector = getInputSelector(first);

      first.scrollIntoView();
      first.querySelector(selector)?.focus();
    }
  }, [submitCount, isValidating, isInvalid]);

  return (
    <FormikForm
      ref={formRef}
      className={clsx('hlx-form', className)}
      {...props}
      method={props.method || 'POST'}
    />
  );
};

function getInputSelector(
  el: Element
): NonNullable<FormInputProps<any>['inputElementType']> {
  const data = el.getAttribute(DATA.INPUT_TYPE);
  return data === 'textarea' ? 'textarea' : 'input';
}

interface FormStickyFooterProps {
  children: React.ReactNode;
}
export function FormStickyFooter(props: FormStickyFooterProps) {
  const { errors, submitCount } = useFormikContext<{ [key: string]: any }>();

  return (
    <div className="hlx-form-sticky-footer">
      <div className="hlx-form-sticky-footer-content">
        {!!Object.keys(errors).length && submitCount > 0 && (
          <span className="hlx-form-sticky-footer-error">
            There is an issue with a field above.
          </span>
        )}
        {props.children}
      </div>
    </div>
  );
}
