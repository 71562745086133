import React, { ReactNode } from 'react';

import { theme } from './theme';

export interface SubBodyTextProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'disabledGray' | 'red' | 'white';
}

export const SubBodyText = ({
  children,
  color = 'textBlack',
}: SubBodyTextProps) => {
  return (
    <span
      className="hlx-typography-subbody"
      style={{ color: COLOR_STYLE_MAP[color] }}
    >
      {children}
    </span>
  );
};

const COLOR_STYLE_MAP: Record<
  NonNullable<SubBodyTextProps['color']>,
  string
> = {
  disabledGray: theme.color.system.disabledGray,
  textBlack: theme.color.system.textBlack,
  gray: theme.color.system.gray,
  red: theme.color.primary.red,
  white: theme.color.system.white,
};
