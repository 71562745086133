import { useResizeObserver, useSlotId } from '@react-aria/utils';
import { AriaTextFieldProps } from '@react-types/textfield';
import React from 'react';
import { FocusRing, mergeProps, useTextField } from 'react-aria';
import { Simplify } from 'type-fest';

import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { FormInputProps, useFormInput } from './useFormInput';
import { PickAndConfigure } from './utils/PickAndConfigure';

type TextAreaSizingConstraint = {
  min?: number;
  max?: number;
};

type TextAreaSizing =
  | undefined
  | number
  | 'fill'
  | 'content'
  | TextAreaSizingConstraint;

type TextAreaProps = Simplify<
  {
    /**
     * When set to a number, sets the height in rows (lines of text) for the text area. Minimum
     * and default value is 3.
     *
     * When set to "fill", sets the height of the text area to fill the height of its container.
     *
     * When set to "content", sets the height of the text area to automatically resize to fit all
     * of the input value text.
     *
     * When set to an object, with `min` and/or `max` keys, the height will auto-size to the fit
     * the content (like the "content" option), but will be limited by the supplied constraints.
     * Min and max are specified in rows (lines of text). Min defaults to 3x and max defaults to
     * no limit.
     */
    sizing?: Simplify<TextAreaSizing>;
    optionalityText?: React.ReactNode;
    /**
     * Text displayed beneath the input label to provider instructions on how the input should be
     * filled out. This differs from `helpText` in that it is meant to be read by the user prior to
     * filling out the input and should provide precise and actionable instructions. Conversely,
     * `helpText` is meant to be read after the input has been filled out and should provide
     * hints and tips on how to fill out the input.
     *
     * Additionally, `instructionalText` should only be used on block level inputs (i.e. inputs that
     * span the full width of the form) and should not be used on inline inputs (i.e. inputs that
     * are displayed alongside other inputs).
     */
    instructionalText?: React.ReactNode;
  } & FormInputProps<string> &
    PickAndConfigure<
      AriaTextFieldProps,
      'autoComplete' | 'type' | 'placeholder'
    >
>;

type BorderBoxHeights = {
  borderHeight: number;
  paddingHeight: number;
};

const MIN_TEXTAREA_ROWS = 3;

const parsePxValue = (value: string) => parseInt(value, 10) || 0;

const getHeightForRows = (
  rows: number,
  { borderHeight, paddingHeight }: BorderBoxHeights
) => `calc(${rows}lh + ${borderHeight + paddingHeight}px)`;

const getSizingConstraint = (
  sizing: TextAreaSizing,
  constraint: keyof TextAreaSizingConstraint
) =>
  typeof sizing === 'object' && typeof sizing[constraint] === 'number'
    ? sizing[constraint]
    : undefined;

const getBorderBoxHeights = (element: HTMLElement | null): BorderBoxHeights => {
  if (!element) {
    return { borderHeight: 0, paddingHeight: 0 };
  }

  const {
    boxSizing,
    borderTopWidth,
    borderBottomWidth,
    paddingTop,
    paddingBottom,
  } = getComputedStyle(element);

  if (boxSizing !== 'border-box') {
    return { borderHeight: 0, paddingHeight: 0 };
  }

  const borderHeight =
    parsePxValue(borderTopWidth) + parsePxValue(borderBottomWidth);

  const paddingHeight = parsePxValue(paddingTop) + parsePxValue(paddingBottom);

  return { borderHeight, paddingHeight };
};

const useBorderBoxHeights = (ref: React.RefObject<HTMLElement>) => {
  // ref is not set on initial render, but needed to calculate box sizes for
  // styling, so force an initial re-render
  const [_isRendered, setIsRendered] = React.useState(false);
  React.useEffect(() => {
    setIsRendered(true);
  }, []);

  const { borderHeight, paddingHeight } = getBorderBoxHeights(ref.current);

  return React.useMemo(
    () => ({ borderHeight, paddingHeight }),
    [borderHeight, paddingHeight]
  );
};

export const useTextAreaSize = (
  ref: React.RefObject<HTMLTextAreaElement>,
  sizing: TextAreaSizing
) => {
  const boxHeights = useBorderBoxHeights(ref);

  const resizeToFitContent = React.useCallback(() => {
    const textArea = ref.current;
    const isAutoSizing = sizing === 'content' || typeof sizing === 'object';

    if (!textArea || !isAutoSizing) {
      return;
    }

    // Set to 0 first to ensure scrollheight is the minimum necessary for the content
    // (i.e. to allow shrinking)
    textArea.style.height = '0px';
    const { scrollHeight } = textArea;

    textArea.style.height = `${scrollHeight + boxHeights.borderHeight}px`;
  }, [sizing, boxHeights]);

  React.useEffect(() => {
    resizeToFitContent();
  }, [ref.current?.value]);

  useResizeObserver({
    onResize: resizeToFitContent,
    ref,
  });

  return React.useMemo(() => {
    const minConstraint = getSizingConstraint(sizing, 'min');
    const maxConstraint = getSizingConstraint(sizing, 'max');

    const minRows = Math.max(
      typeof sizing === 'number' ? sizing : minConstraint ?? MIN_TEXTAREA_ROWS,
      MIN_TEXTAREA_ROWS
    );

    const maxRows =
      typeof maxConstraint === 'number'
        ? Math.max(minRows, maxConstraint)
        : undefined;

    return {
      minHeight: getHeightForRows(minRows, boxHeights),
      maxHeight: maxRows ? getHeightForRows(maxRows, boxHeights) : undefined,
    };
  }, [sizing, boxHeights]);
};

export function TextArea(props: TextAreaProps) {
  const { ariaProps, rootProps, hoverProps } = useFormInput({
    ...props,
    inputElementType: 'textarea',
    isTextInput: true,
  });
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(ariaProps, ref);
  const optionalityId = useSlotId([Boolean(props.optionalityText)]);
  const instructionalTextId = useSlotId([Boolean(props.instructionalText)]);

  useAssertFormParentEffect(ref, 'TextArea');

  const { minHeight, maxHeight } = useTextAreaSize(ref, props.sizing);

  const textAreaProps = mergeProps(inputProps, hoverProps);
  textAreaProps['aria-describedby'] =
    [instructionalTextId, optionalityId, textAreaProps['aria-describedby']]
      .filter(Boolean)
      .join(' ') || undefined;

  return (
    <div
      className="hlx-text-area-root"
      data-hlx-height={props.sizing}
      {...rootProps}
    >
      <div className="hlx-text-area-descriptors">
        <label className="hlx-text-area-label" {...labelProps}>
          {props.label}
        </label>
        {props.optionalityText && (
          <div id={optionalityId} className="hlx-text-area-optionality-text">
            {props.optionalityText}
          </div>
        )}
        {props.instructionalText && (
          <div
            className="hlx-text-area-instructional-text"
            id={instructionalTextId}
          >
            {props.instructionalText}
          </div>
        )}
      </div>
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        autoFocus={props.autoFocus}
      >
        <textarea
          className="hlx-text-area-control"
          ref={ref}
          style={{ minHeight, maxHeight }}
          {...textAreaProps}
        />
      </FocusRing>

      {props.helpText && (
        <div className="hlx-text-area-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-text-area-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
}
