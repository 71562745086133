import { TimeFields } from '@internationalized/date';
import { Alert, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import {
  useExistingVerificationRequest,
  useProviderPatient,
  useSuggestedDiagnosisCodes,
} from 'hooks';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { CodingEnforcementErrorType } from '@headway/api/models/CodingEnforcementErrorType';
import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';
import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderAppointmentUpdate } from '@headway/api/models/ProviderAppointmentUpdate';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { TelehealthPlatform } from '@headway/api/models/TelehealthPlatform';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { InsuranceAuthorizationApi } from '@headway/api/resources/InsuranceAuthorizationApi';
import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { Checkbox } from '@headway/helix/Checkbox';
import { Item } from '@headway/helix/collections';
import { CurrencyField } from '@headway/helix/CurrencyField';
import { DatePickerField } from '@headway/helix/DatePickerField';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconInfo } from '@headway/helix/icons/Info';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { Section, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextArea } from '@headway/helix/TextArea';
import { theme } from '@headway/helix/theme';
import { TimeField } from '@headway/helix/TimeField';
import {
  CONTROLLED_SUBSTANCE_DISPLAY_NAMES,
  MAX_DOSAGE,
} from '@headway/shared/constants/controlledSubstances';
import {
  CIGNA_EAP_CODE,
  CPTCodeInfo,
  PRESCRIBER_ADD_ON_PSYCHOTHERAPY_CODES,
} from '@headway/shared/constants/cptCodes';
import { DXCodeInfo } from '@headway/shared/constants/diagnosisCodes';
import {
  CONTROLLED_SUBSTANCE_DATA_COLLECTION,
  PRESCRIBER_PSYCHOTHERAPY_TIMES,
  PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
  TELEHEALTH_LOCATIONS_SIGMUND,
} from '@headway/shared/FeatureFlags/flagNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { CPTCodeComboBox } from '@headway/ui/CPTCodeComboBox';
import {
  CptCodeOption,
  getCptOptionsForProvider,
} from '@headway/ui/CPTCodeInput';
import { DiagnosisCodeComboBox } from '@headway/ui/DiagnosisCodeComboBox';
import {
  DiagnosisCodeOption,
  diagnosisOptions,
} from '@headway/ui/DiagnosisCodeInput';
import { FormRow } from '@headway/ui/form';
import { SubmitListener } from '@headway/ui/form/SubmitListener';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';

import { useFindPastSessionsQueryKeyArgs } from 'hooks/useFindPastSessionsQueryKeyArgs';
import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { useProviderEvent } from 'hooks/useProviderEvent';
import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';
import {
  convertDateToTimeFields,
  convertTimeFieldsToDateString,
} from 'utils/date';
import { NullableKeys } from 'utils/types';
import { isPast } from 'views/Calendar/events/util/events';
import { useDocumentationRequirement } from 'views/Calendar/utils/documentationRequirement';
import { getAuditResult } from 'views/Home/utils';

import { generateFiveMinuteIntervals } from '../../../../form/FieldFiveMinuteIntervalSelect';
import {
  AppointmentContext,
  AppointmentState,
} from '../../stores/AppointmentContext';
import { ProgressNoteContext } from '../../stores/ProgressNotesContext';
import { isBillingAddOnPsychotherapy } from '../../util';
import { InsuranceStatusBanner } from '../FormComponents/InsuranceStatusBanner';
import { InsuranceStatusBannerForWarning } from '../FormComponents/InsuranceStatusBannerForWarning';
import { PatientAddressDropdownHelix } from '../FormComponents/PatientAddressDropdownHelix';
import { TelehealthLocationsInputs } from '../FormComponents/TelehealthLocationsInputs';
import { ControlledSubstanceCombobox } from './ControlledSubstanceCombobox';
import {
  CodingError,
  CodingErrorLocation,
  MiscodingResults,
  useMiscodingResultsQuery,
  ValidationWarning,
  ValidationWarningLocation,
} from './FormValidation/miscoding';
import {
  checkIfExactDurationIsRequired,
  validationSchema,
} from './FormValidation/validationSchema';
import { ValidationWarningAlert } from './FormValidation/ValidationWarningAlert';
import { NonEditableSessionDetailsSection } from './NonEditableSessionDetailsSection';

export type SessionDetailsFormProps = {
  eventVirtualId: string;
  provider: ProviderRead;
  patient: UserRead;
  innerRef: React.Ref<any>;
  errorRef: React.Ref<any>;
  onOpenContactFormInsuranceIssues: () => void;
};

export type SessionDetailsFormValues = {
  providerAddressId?: number | null;
  appointmentLocationPatientAddressId?: number | null;
  startDate: string;
  endDate: string;
  duration?: string | undefined;
  exactStartTime?: TimeFields;
  exactEndTime?: TimeFields;
  billingType?: string;
  patientResponsibilityAmount?: number;
  cptCodes?: any[];
  diagnosisCodes?: any[];
  telehealth?: boolean;
  enforceMiscodingRules?: string[]; // See validation schema for custom error logic
  telehealthPlatform?: TelehealthPlatform;
  telehealthProviderState?: UnitedStates;
  telehealthAttestation?: boolean;
  didPrescribeControlledSubstance?: boolean;
  controlledSubstanceAttestation: {
    prescribedSubstances?: string[];
    notes?: string;
    exceedsRecommendedDosage?: boolean;
  };
  prescriberPsychotherapyStartTime?: TimeFields;
  prescriberPsychotherapyEndTime?: TimeFields;
};

export const isSessionDetailsFormValueTelehealth = (
  providerAddressId: SessionDetailsFormValues['providerAddressId']
) => providerAddressId === -1;

export const isFormValueTelehealthAndBilledWithInsuranceOrEAP = (
  providerAddressId: SessionDetailsFormValues['providerAddressId'],
  billingType: SessionDetailsFormValues['billingType']
) =>
  isSessionDetailsFormValueTelehealth(providerAddressId) &&
  (billingType === BillingType.INSURANCE || billingType === BillingType.EAP);

export const convertFormValuesToProviderEventUpdate = (
  rawValues: SessionDetailsFormValues
) => {
  return {
    startDate: new Date(rawValues.startDate).toISOString(),
    endDate: moment(rawValues.startDate)
      .add(rawValues.duration, 'minutes')
      .toISOString(),
    ...(rawValues.providerAddressId && rawValues.providerAddressId === -1
      ? {
          providerAddressId: null,
          telehealth: true,
        }
      : {
          providerAddressId: rawValues.providerAddressId
            ? rawValues.providerAddressId
            : null,
          telehealth: false,
        }),
    providerAppointment: {
      billingType: rawValues.billingType,
      patientResponsibilityAmount:
        rawValues.billingType === BillingType.SELF_PAY
          ? rawValues.patientResponsibilityAmount
          : undefined,
      cptCodes: rawValues?.cptCodes?.map((code: CptCodeOption) => code?.value),
      diagnosisCodes: rawValues?.diagnosisCodes?.map(
        (code: DiagnosisCodeOption) => code?.value
      ),
      exactStartTime: rawValues.exactStartTime
        ? convertTimeFieldsToDateString(
            new Date(rawValues.startDate),
            rawValues.exactStartTime
          )
        : undefined,
      exactEndTime: rawValues.exactEndTime
        ? convertTimeFieldsToDateString(
            new Date(rawValues.startDate),
            rawValues.exactEndTime
          )
        : undefined,
      // convert the form representation of address and telehealth
      ...(rawValues.providerAddressId && rawValues.providerAddressId === -1
        ? {
            providerAddressId: null,
            telehealth: true,
            appointmentLocationPatientAddressId:
              rawValues.appointmentLocationPatientAddressId,
            telehealthPlatform: rawValues.telehealthPlatform,
            telehealthProviderState: rawValues.telehealthProviderState,
            telehealthAttestation: rawValues.telehealthAttestation,
          }
        : {
            providerAddressId: rawValues.providerAddressId
              ? rawValues.providerAddressId
              : null,
            telehealth: false,
            appointmentLocationPatientAddressId: null,
            telehealthPlatform: null,
            telehealthProviderState: null,
            telehealthAttestation: null,
          }),
      ...(rawValues.didPrescribeControlledSubstance
        ? {
            controlledSubstanceAttestation:
              rawValues.controlledSubstanceAttestation,
          }
        : rawValues.didPrescribeControlledSubstance === false
        ? {
            controlledSubstanceAttestation: {
              prescribedSubstances: [],
              notes: null,
              exceedsRecommendedDosage: null,
            },
          }
        : {}),
      prescriberPsychotherapyStartTime:
        !rawValues.prescriberPsychotherapyStartTime
          ? undefined
          : isBillingAddOnPsychotherapy(
              rawValues.cptCodes?.map((code) => code.value) || []
            )
          ? convertTimeFieldsToDateString(
              new Date(rawValues.startDate),
              rawValues.prescriberPsychotherapyStartTime
            )
          : null,
      prescriberPsychotherapyEndTime: !rawValues.prescriberPsychotherapyEndTime
        ? undefined
        : isBillingAddOnPsychotherapy(
            rawValues.cptCodes?.map((code) => code.value) || []
          )
        ? convertTimeFieldsToDateString(
            new Date(rawValues.startDate),
            rawValues.prescriberPsychotherapyEndTime
          )
        : null,
    } as NullableKeys<ProviderAppointmentUpdate> as ProviderAppointmentUpdate,
  } as NullableKeys<ProviderEventUpdate> as ProviderEventUpdate;
};

export const SessionDetailsForm = ({
  innerRef,
  errorRef,
  eventVirtualId,
  provider,
  patient,
  onOpenContactFormInsuranceIssues,
}: SessionDetailsFormProps) => {
  const { updateAppointmentAddressState, ...appointmentContext } =
    useContext(AppointmentContext);
  const { progressNoteType, selectedTemplate, documentRemediation } =
    useContext(ProgressNoteContext);
  const [patientHasCignaEapAuthorization, setPatientHasCignaEapAuthorization] =
    useState<boolean>(false);
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);

  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const { providerAddresses } = useContext(ProviderAddressContext);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();
  const cptOptions = getCptOptionsForProvider(provider);

  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const isTelehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );
  const isControlledSubstanceDataCollectionEnabled = useFlag(
    CONTROLLED_SUBSTANCE_DATA_COLLECTION,
    false
  );

  const prescriberPsychotherapyTimesCarriers = useFlag(
    PRESCRIBER_PSYCHOTHERAPY_TIMES_CARRIERS,
    []
  );

  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery(
      {
        providerId: provider?.id!,
        patientUserId: patient?.id!,
        appointmentId: event?.providerAppointment?.id,
      },
      { enabled: !!provider && !!patient }
    );

  const matchingCarrierId = matchingProviderFrontEndCarrier?.frontEndCarrierId;
  const isPrescriberPsychotherapyTimesEnabled =
    prescriberPsychotherapyTimesCarriers?.includes(matchingCarrierId);

  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const providerPatientQuery = useProviderPatient({
    providerId: provider.id,
    patientId: patient.id,
  });
  const hasExistingVerificationRequestQuery =
    useExistingVerificationRequest(patient);
  const suggestedDiagnosisCodesQuery = useSuggestedDiagnosisCodes(
    provider,
    patient
  );

  const { isRequired: isDocumentationRequired } = useDocumentationRequirement(
    provider,
    patient,
    event?.providerAppointment
  );

  const providerPatient = providerPatientQuery.data;
  const suggestedDiagnosisCodes = suggestedDiagnosisCodesQuery.data || [];
  const hasExistingVerificationRequest =
    hasExistingVerificationRequestQuery.data;

  const initialDiagnosisCodes = event?.providerAppointment?.diagnosisCodes
    ?.length
    ? event?.providerAppointment.diagnosisCodes
    : suggestedDiagnosisCodes;

  const [pickedLocation, setPickedLocation] = React.useState<
    number | null | undefined
  >(undefined);

  const { data: editabilityStatus } = useSessionDetailsEditability(event?.id);
  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  const checkEapAuthorization = useCallback(
    async (dateOfService: string) => {
      // we do not need special logic if the patient does not have Cigna, or the provider is a prescriber
      if (
        patient?.activeUserInsurance?.frontEndCarrierId !== //TODO SC-263399
          FrontEndCarrierIdentifier.CIGNA ||
        !!provider.isPrescriber
      ) {
        setPatientHasCignaEapAuthorization(false);
      } else {
        try {
          const authorizations =
            await InsuranceAuthorizationApi.findInsuranceAuthorizations({
              user_insurance_id: patient.activeUserInsuranceId!,
              date_of_service: dateOfService,
              has_unused_sessions: true,
            });

          const res =
            authorizations &&
            authorizations.some((auth) => auth.isEapAuthorization);
          setPatientHasCignaEapAuthorization(res);
        } catch (err) {
          logException(err);
        }
      }
    },
    [
      patient?.activeUserInsurance?.frontEndCarrierId, //TODO SC-263399
      patient?.activeUserInsuranceId,
      provider?.isPrescriber,
    ]
  );

  useEffect(() => {
    checkEapAuthorization(new Date(event?.startDate!).toISOString());
  }, [checkEapAuthorization, event?.startDate]);

  // We set the location to null each time the provider
  // opens up the modal to assure that they pick the corect
  // location when they confirm the session.
  // If the session is confirmed, we use the value stored in the DB.
  const getLocationFieldValue = () => {
    if (pickedLocation) {
      return pickedLocation;
    }

    if (
      event?.providerAppointment?.status !==
      ProviderAppointmentStatus.DETAILS_CONFIRMED
    ) {
      return undefined;
    }

    return event?.telehealth
      ? -1
      : event?.providerAddressId ||
          providerAddresses.filter(
            (address: ProviderAddressRead) => address.isActive
          )[0]?.id;
  };

  const pastSessionsQueryKeyArgs = useFindPastSessionsQueryKeyArgs({
    providerId: provider.id,
    clientId: patient.id,
    limit: 10,
  });

  const { data: lastConfirmedTelehealthAppointment } = useFindProviderEvents(
    pastSessionsQueryKeyArgs,
    {
      select: ({ data }) =>
        data?.find((appointment) => appointment.telehealth === true)
          ?.providerAppointment,
      enabled:
        (isMSCEnabled ||
          isMSCGuardrailRestriction ||
          isTelehealthLocationsEnabled) &&
        !event?.providerAppointment?.appointmentLocationPatientAddressId &&
        event?.providerAppointment?.status ===
          ProviderAppointmentStatus.SCHEDULED,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        logException(error);
      },
    }
  );

  const { data: lastConfirmedAppointment } = useFindProviderEvents(
    pastSessionsQueryKeyArgs,
    {
      select: ({ data }) => data[0],
      enabled:
        provider.isPrescriber && isControlledSubstanceDataCollectionEnabled,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        logException(error);
      },
    }
  );

  //Only prepopulate patient address for telehealth appts
  const initialProviderAddressId = event?.telehealth
    ? -1
    : getLocationFieldValue();
  const initialAppointmentLocationPatientAddressId =
    event?.providerAppointment?.status === ProviderAppointmentStatus.SCHEDULED
      ? event?.providerAppointment?.appointmentLocationPatientAddressId ??
        lastConfirmedTelehealthAppointment?.appointmentLocationPatientAddressId
      : getLocationFieldValue() === -1
      ? event?.providerAppointment?.appointmentLocationPatientAddressId
      : null;

  useEffect(() => {
    updateAppointmentAddressState(
      initialProviderAddressId,
      initialAppointmentLocationPatientAddressId
    );
  }, [
    initialProviderAddressId,
    initialAppointmentLocationPatientAddressId,
    updateAppointmentAddressState,
  ]);

  if (!event) {
    return null;
  }
  const { providerAppointment, ...values } = event;

  const lastControlledSubstanceAttestation =
    lastConfirmedAppointment?.providerAppointment
      ?.controlledSubstanceAttestation;
  const initialControlledSubstanceAttestationValues =
    providerAppointment?.controlledSubstanceAttestation
      ? {
          didPrescribeControlledSubstance:
            providerAppointment.controlledSubstanceAttestation
              .prescribedSubstances.length > 0,
          controlledSubstanceAttestation: {
            prescribedSubstances:
              providerAppointment.controlledSubstanceAttestation
                .prescribedSubstances || [],
            notes: providerAppointment.controlledSubstanceAttestation.notes,
            exceedsRecommendedDosage:
              providerAppointment.controlledSubstanceAttestation
                .exceedsRecommendedDosage,
          },
        }
      : lastControlledSubstanceAttestation
      ? {
          didPrescribeControlledSubstance:
            lastControlledSubstanceAttestation.prescribedSubstances.length > 0,
          controlledSubstanceAttestation: {
            prescribedSubstances:
              lastControlledSubstanceAttestation.prescribedSubstances || [],
            notes: lastControlledSubstanceAttestation.notes,
            exceedsRecommendedDosage:
              lastControlledSubstanceAttestation.exceedsRecommendedDosage,
          },
        }
      : {
          didPrescribeControlledSubstance: undefined,
          controlledSubstanceAttestation: {
            prescribedSubstances: [],
            notes: undefined,
            exceedsRecommendedDosage: undefined,
          },
        };

  const formValues: SessionDetailsFormValues = {
    ...values,
    providerAddressId: initialProviderAddressId,
    appointmentLocationPatientAddressId:
      initialAppointmentLocationPatientAddressId,
    startDate: moment(event.startDate!).toISOString(),
    endDate: moment(event.endDate!).toISOString(),
    duration: event.startDate
      ? moment
          .duration(moment(event.endDate).diff(event.startDate))
          .asMinutes()
          .toString()
      : undefined,
    exactStartTime: providerAppointment?.exactStartTime
      ? convertDateToTimeFields(new Date(providerAppointment?.exactStartTime))
      : undefined,
    exactEndTime: providerAppointment?.exactEndTime
      ? convertDateToTimeFields(new Date(providerAppointment?.exactEndTime))
      : undefined,
    billingType: providerAppointment?.billingType,
    patientResponsibilityAmount:
      providerAppointment?.billingType === BillingType.SELF_PAY
        ? providerAppointment?.patientResponsibilityAmount ||
          providerPatient?.selfPayRateDefault
        : // We only allow editing patient_responsibility for self-pay
          // If billing switches from insurance to self-pay, show self-pay default
          providerPatient?.selfPayRateDefault,
    cptCodes: patientHasCignaEapAuthorization
      ? cptOptions.filter((o: CptCodeOption) => o.value === CIGNA_EAP_CODE)
      : providerAppointment?.cptCodes
      ? cptOptions.filter(
          (o: CptCodeOption) => providerAppointment?.cptCodes?.includes(o.value)
        )
      : [],
    diagnosisCodes: diagnosisOptions(true).filter((o: DiagnosisCodeOption) =>
      initialDiagnosisCodes.includes(o.value)
    ),
    telehealthPlatform: !isTelehealthLocationsEnabled
      ? undefined
      : // If current appointment has telehealthPlatform, use that
        providerAppointment?.telehealthPlatform ??
        // Otherwise prefill with last confirmed telehealth appointment
        lastConfirmedTelehealthAppointment?.telehealthPlatform,
    telehealthProviderState: !isTelehealthLocationsEnabled
      ? undefined
      : // If current appointment has telehealthProviderState, use that
        providerAppointment?.telehealthProviderState ??
        // Otherwise prefill with last confirmed telehealth appointment
        lastConfirmedTelehealthAppointment?.telehealthProviderState ??
        // Or provider's home state
        provider.stateOfResidence,
    telehealthAttestation: isTelehealthLocationsEnabled
      ? // If current appointment has the attestion, use that
        providerAppointment?.telehealthAttestation
      : // We don't prefill with the previous appointment's attestation, since it's a new session
        undefined,
    ...initialControlledSubstanceAttestationValues,
    prescriberPsychotherapyStartTime:
      providerAppointment?.prescriberPsychotherapyStartTime
        ? convertDateToTimeFields(
            new Date(providerAppointment?.prescriberPsychotherapyStartTime)
          )
        : undefined,
    prescriberPsychotherapyEndTime:
      providerAppointment?.prescriberPsychotherapyEndTime
        ? convertDateToTimeFields(
            new Date(providerAppointment?.prescriberPsychotherapyEndTime)
          )
        : undefined,
  };

  // Remove the updated_on property we get from mamba,
  // since this forms autosaves we don't want to pass in
  // an value that updates a value on the object, itll be
  // in an endless autosave loop
  delete (formValues as any)['updated_on'];

  const shouldRequirePrescriberPsychotherapyTimes =
    isPrescriberPsychotherapyTimesEnabled &&
    provider.isPrescriber &&
    isBillingAddOnPsychotherapy(
      formValues.cptCodes?.map((code) => code.value) || []
    );

  // ensure psychotherapy time window is within appointment window by requiring exact appointment start and end time
  const isExactDurationRequired =
    !!isDocumentationRequired ||
    checkIfExactDurationIsRequired(progressNoteType, selectedTemplate) ||
    shouldRequirePrescriberPsychotherapyTimes;

  const documentNeedsReviewRemediation =
    documentRemediation &&
    documentRemediation.status === DocumentRemediationStatus.NEEDS_REVIEW;
  const auditResultPerRemediation = getAuditResult(documentRemediation);

  return (
    <Formik
      enableReinitialize={!hasSubmittedOnce}
      innerRef={innerRef as any}
      initialValues={formValues}
      onSubmit={async (rawValues) => {
        setHasSubmittedOnce(true);
        await appointmentContext.updateAppointment({
          eventIdOrVirtualId: eventVirtualId,
          update: convertFormValuesToProviderEventUpdate(rawValues),
        });
      }}
      validationSchema={
        appointmentContext.confirmSessionDetailsAttempted
          ? validationSchema(
              provider,
              progressNoteType,
              selectedTemplate,
              isDocumentationRequired,
              isMSCEnabled || isMSCGuardrailRestriction,
              isTelehealthLocationsEnabled,
              isControlledSubstanceDataCollectionEnabled,
              shouldRequirePrescriberPsychotherapyTimes
            )
          : undefined
      }
    >
      {({ values, errors, setFieldValue, setFieldTouched, touched }) => (
        <MiscodingResultsProvider
          cptCodes={values.cptCodes}
          diagnosisCodes={values.diagnosisCodes}
          provider={provider}
          patient={patient}
          event={event}
          exactStartTime={values.exactStartTime}
          exactEndTime={values.exactEndTime}
        >
          {({
            miscodingResults: {
              warnings: validationWarnings,
              errors: appointmentCodingErrors,
            },
          }) => {
            const conflictingSessionError =
              appointmentCodingErrors.length > 0
                ? appointmentCodingErrors?.filter(
                    (error) => error.preferredLocation === 'SESSION_TIME'
                  )
                : [];

            // Filter out overlapping conflicting session errors so it does not show in the diagnosis code errors
            // Filter out coding cardinality errors as they will show up in field level errors
            const cardinalityErrorTypes = [
              CodingEnforcementErrorType.NO_CPT_CODES,
              CodingEnforcementErrorType.NO_DIAGNOSIS_CODES,
            ];
            const filteredAppointmentCodingErrors =
              appointmentCodingErrors?.filter(
                (error) =>
                  error.preferredLocation !== 'SESSION_TIME' &&
                  !cardinalityErrorTypes.includes(error.type)
              );

            const shouldShowAppointmentCodingErrors =
              filteredAppointmentCodingErrors.length > 0 &&
              isPast(event) &&
              ((!!values.cptCodes?.length && !!values.diagnosisCodes?.length) ||
                appointmentContext.confirmSessionDetailsAttempted);

            // If every coding error is related to CPT codes, then show it underneath the CPT code input; otherwise show it underneath the DX code input.
            const appointmentCodingErrorsLocation =
              filteredAppointmentCodingErrors.every(
                (error) =>
                  error.preferredLocation === CodingErrorLocation.CPT_CODES
              )
                ? CodingErrorLocation.CPT_CODES
                : CodingErrorLocation.DIAGNOSIS_CODES;

            const getValidationWarningAlert = (
              validationWarning: ValidationWarning,
              key: number
            ) =>
              hasExistingVerificationRequest ? (
                <></>
              ) : (
                <ValidationWarningAlert
                  onOpenContactFormInsuranceIssues={
                    onOpenContactFormInsuranceIssues
                  }
                  validationWarning={validationWarning}
                  id={key}
                />
              );

            if (touched['providerAddressId']) {
              setPickedLocation(values['providerAddressId']);
            }

            if (documentNeedsReviewRemediation || auditResultPerRemediation)
              return (
                <NonEditableSessionDetailsSection
                  sessionDate={moment(formValues.startDate).format(
                    'MMM Do, YYYY'
                  )}
                  sessionStartTime={moment(formValues.startDate).format(
                    'h:mma'
                  )}
                  sessionDuration={`${moment(formValues.endDate).diff(
                    formValues.startDate,
                    'minutes'
                  )} minutes`}
                  sessionExactStartTime={moment(
                    formValues.exactStartTime ?? formValues.startDate
                  ).format('h:mma')}
                  sessionExactStopTime={moment(
                    formValues.exactEndTime ?? formValues.endDate
                  ).format('h:mma')}
                  sessionLocation={
                    formValues.providerAddressId === -1
                      ? 'Virtual (Telehealth)'
                      : providerAddresses.filter(
                          (item) => item.id === formValues.providerAddressId
                        )[0].address
                  }
                  sessionCptCodes={formValues.cptCodes}
                  sessionDiagnosisCodes={formValues.diagnosisCodes}
                />
              );

            return (
              <>
                {appointmentContext.appointmentState !==
                  AppointmentState.DETAILS_CONFIRMED && (
                  <SubmitListener considerInitialValues={false} />
                )}
                <div ref={errorRef} />
                {conflictingSessionError.length > 0 && (
                  <div css={{ marginBottom: theme.spacing.x6 }}>
                    <GuidanceCard variant="error">
                      <BodyText>
                        <strong>Conflicting session during this time: </strong>
                        {conflictingSessionError[0].message}
                      </BodyText>
                    </GuidanceCard>
                  </div>
                )}
                <Form>
                  {hasExistingVerificationRequest &&
                    validationWarnings.filter(
                      (item) => item.showVerificationEntryPoint
                    ).length > 0 && (
                      <Alert
                        color="warning"
                        severity="error"
                        css={{ marginBottom: theme.spacing.x1 }}
                      >
                        We’re still verifying benefits related to this session.
                        We suggest waiting to hear back from us before
                        confirming session details in case{' '}
                        {
                          patient?.activeUserInsurance
                            ?.billingFrontEndCarrierName
                        }{' '}
                        denies the claim.
                      </Alert>
                    )}
                  <FormRow>
                    <FormControl
                      component={DatePickerField}
                      label={'Date'}
                      disabled={!canUpdateSessionDetails}
                      name={'startDate'}
                    />
                    <FormControl
                      component={Select}
                      selectionMode="single"
                      label={'Scheduled start time'}
                      disabled={!canUpdateSessionDetails}
                      name={'startDate'}
                    >
                      {generateFiveMinuteIntervals(values.startDate).reduce(
                        (intervals, interval) => {
                          const intervalDate = moment(interval);
                          const label = intervalDate.format('h:mma');
                          const key = intervalDate.toISOString();

                          if (key) {
                            intervals.push(<Item key={key}>{label}</Item>);
                          } else {
                            // TODO: For debugging purposes. Remove this after confirming
                            // that the interval date is always valid
                            logException(
                              new Error('Interval date is not valid'),
                              {
                                extra: {
                                  debug: {
                                    intervalDate,
                                    startDate: values.startDate,
                                  },
                                },
                              }
                            );
                          }
                          return intervals;
                        },
                        [] as any[]
                      )}
                    </FormControl>
                    <FormControl
                      component={Select}
                      selectionMode="single"
                      label={'Scheduled duration'}
                      name={'duration'}
                      disabled={!canUpdateSessionDetails}
                    >
                      <Section title="Common times">
                        {[15, 30, 45, 60].map((time, idx) => {
                          return (
                            <Item
                              key={time.toString()}
                              textValue={`${time} minutes`}
                            >
                              {time} minutes
                            </Item>
                          );
                        })}
                      </Section>
                      <Section title="All times">
                        {Array.from(Array(24), (_, idx) => (idx + 1) * 5)
                          .filter((time) => ![15, 30, 45, 60].includes(time))
                          .map((time, idx) => {
                            return (
                              <Item
                                key={time.toString()}
                                textValue={`${time} minutes`}
                              >
                                {time} minutes
                              </Item>
                            );
                          })}
                      </Section>
                    </FormControl>
                  </FormRow>
                  <div data-intercom-target="Exact Session Time">
                    <FormRow>
                      <div
                        data-testid="actualStartTime"
                        css={{ width: '100%', position: 'relative' }}
                      >
                        <FormControl
                          component={TimeField}
                          label={'Actual start time'}
                          disabled={!canUpdateSessionDetails}
                          name={'exactStartTime'}
                          helpText="Enter the actual start time, not just what was booked. Eg 10:02 AM"
                          optionalityText={
                            isExactDurationRequired ? '' : 'Optional'
                          }
                        />
                        {canUpdateSessionDetails && (
                          <div
                            css={{
                              position: 'absolute',
                              top: '-3px',
                              ...(isExactDurationRequired
                                ? { left: '100px' }
                                : { left: '160px' }),
                            }}
                          >
                            <Tooltip
                              placement="bottom"
                              title="Start/stop times help ensure compliance with coding standards"
                              arrow
                            >
                              <div
                                css={{ width: 'inherit', height: 'inherit' }}
                              >
                                <IconInfo />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div
                        data-testid="actualStopTime"
                        css={{ width: '100%', position: 'relative' }}
                      >
                        <FormControl
                          component={TimeField}
                          label={'Actual stop time'}
                          disabled={!canUpdateSessionDetails}
                          name={'exactEndTime'}
                          helpText="Enter the actual stop time, not just what was booked. Eg 10:58 AM"
                          optionalityText={
                            isExactDurationRequired ? '' : 'Optional'
                          }
                        />
                        {canUpdateSessionDetails && (
                          <div
                            css={{
                              position: 'absolute',
                              top: '-3px',
                              ...(isExactDurationRequired
                                ? { left: '100px' }
                                : { left: '160px' }),
                            }}
                          >
                            <Tooltip
                              placement="bottom"
                              title="Start/stop times help ensure compliance with coding standards"
                              arrow
                            >
                              <div
                                css={{ width: 'inherit', height: 'inherit' }}
                              >
                                <IconInfo />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </FormRow>
                  </div>

                  <FormControl
                    component={Select}
                    selectionMode="single"
                    label={'Session Location'}
                    disabled={!canUpdateSessionDetails}
                    name={'providerAddressId'}
                    menuWidth="stretch"
                    helpText={
                      "Remember to confirm the client's identity, location and physical safety at the start of the session"
                    }
                    onSelectionChange={(items: Set<string>) => {
                      const value = parseInt(Array.from(items)[0]);
                      setFieldValue('providerAddressId', value);
                    }}
                    selectedKeys={
                      values.providerAddressId
                        ? [values.providerAddressId.toString()]
                        : []
                    }
                  >
                    {providerAddresses
                      .filter(
                        (address) => !!address.streetLine1 && address.isActive
                      )
                      .map((address) => (
                        <Item key={address.id}>
                          {address.streetLine1}, {address.city}, {address.state}
                        </Item>
                      ))
                      .concat(<Item key={-1}>Virtual (Telehealth)</Item>)}
                  </FormControl>

                  {isTelehealthLocationsEnabled &&
                    isFormValueTelehealthAndBilledWithInsuranceOrEAP(
                      values.providerAddressId,
                      values.billingType
                    ) && (
                      <TelehealthLocationsInputs
                        canUpdateSessionDetails={canUpdateSessionDetails}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}

                  {(isMSCEnabled ||
                    isMSCGuardrailRestriction ||
                    isTelehealthLocationsEnabled) &&
                    isFormValueTelehealthAndBilledWithInsuranceOrEAP(
                      values.providerAddressId,
                      values.billingType
                    ) && (
                      <PatientAddressDropdownHelix
                        providerAppointmentId={event?.providerAppointment?.id}
                        patient={patient}
                        canUpdateSessionDetails={canUpdateSessionDetails}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    )}

                  {(isMSCEnabled || isMSCGuardrailRestriction) && (
                    <InsuranceStatusBanner
                      patient={patient}
                      sessionDetailsFormValues={values}
                    />
                  )}
                  {isMSCGuardrailWarning && (
                    <InsuranceStatusBannerForWarning
                      patient={patient}
                      sessionDetailsFormValues={values}
                    />
                  )}

                  {isTelehealthLocationsEnabled &&
                    isFormValueTelehealthAndBilledWithInsuranceOrEAP(
                      values.providerAddressId,
                      values.billingType
                    ) && (
                      <FormControl
                        component={Checkbox}
                        name="telehealthAttestation"
                        disabled={!canUpdateSessionDetails}
                      >
                        I have assessed that {patientFirstName} is in a safe,
                        private, and known location.
                      </FormControl>
                    )}

                  {values.billingType === BillingType.SELF_PAY && (
                    <FormControl
                      component={CurrencyField}
                      label={'Session Rate'}
                      name="patientResponsibilityAmount"
                      disabled={!providerPatient || !canUpdateSessionDetails}
                    />
                  )}

                  <CPTCodeComboBox
                    name="cptCodes"
                    patient={patient}
                    provider={provider}
                    patientHasCignaEapAuthorization={
                      patientHasCignaEapAuthorization
                    }
                    searchable
                    selectionMode="multiple"
                    disabled={!canUpdateSessionDetails}
                  />
                  {isPrescriberPsychotherapyTimesEnabled &&
                    provider.isPrescriber &&
                    isBillingAddOnPsychotherapy(
                      values.cptCodes?.map((code) => code.value) || []
                    ) && (
                      <FormRow>
                        <FormControl
                          component={TimeField}
                          label={'Psychotherapy start time'}
                          disabled={!canUpdateSessionDetails}
                          name={'prescriberPsychotherapyStartTime'}
                          helpText="When the psychotherapy portion of the session began"
                        />
                        <FormControl
                          component={TimeField}
                          label={'Psychotherapy stop time'}
                          disabled={!canUpdateSessionDetails}
                          name={'prescriberPsychotherapyEndTime'}
                          helpText="When the psychotherapy portion of the session ended"
                        />
                      </FormRow>
                    )}

                  {shouldShowAppointmentCodingErrors &&
                    appointmentCodingErrorsLocation ===
                      CodingErrorLocation.CPT_CODES && (
                      <MiscodingErrorsGuidanceCard
                        errors={filteredAppointmentCodingErrors}
                      />
                    )}

                  <DiagnosisCodeComboBox
                    helpText={
                      'Select multiple if applicable, with the primary diagnosis selected first'
                    }
                    name="diagnosisCodes"
                    searchable
                    selectionMode="multiple"
                    disabled={!canUpdateSessionDetails}
                  />

                  {validationWarnings
                    .filter(
                      ({ location }) =>
                        location === ValidationWarningLocation.CODES
                    )
                    .map((warning, i) => getValidationWarningAlert(warning, i))}

                  {shouldShowAppointmentCodingErrors &&
                    appointmentCodingErrorsLocation ===
                      CodingErrorLocation.DIAGNOSIS_CODES && (
                      <MiscodingErrorsGuidanceCard
                        errors={filteredAppointmentCodingErrors}
                      />
                    )}

                  {validationWarnings
                    .filter(
                      ({ location }) =>
                        location === ValidationWarningLocation.SUBMIT
                    )
                    .map((warning, i) => getValidationWarningAlert(warning, i))}

                  {isControlledSubstanceDataCollectionEnabled &&
                    provider.isPrescriber && (
                      <>
                        <FormControl
                          component={RadioGroup}
                          name="didPrescribeControlledSubstance"
                          disabled={!canUpdateSessionDetails}
                          label={
                            <>
                              Were any controlled substances prescribed for the
                              client?
                              <span className="ml-1">
                                <GlossaryTerm term="Why Headway is asking for this">
                                  To protect you, your patients, and Headway as
                                  a network, collecting this information helps
                                  us ensure compliant practices. It also allows
                                  us to identify increased risk of scrutiny by
                                  the DEA so that we can support you.
                                </GlossaryTerm>
                              </span>
                            </>
                          }
                          onChange={(value) => {
                            setFieldValue(
                              'didPrescribeControlledSubstance',
                              value === 'true' ? true : false
                            );
                          }}
                          value={String(values.didPrescribeControlledSubstance)}
                        >
                          <Radio value="false">No</Radio>
                          <Radio value="true">
                            Yes, the client received a prescription for a new
                            substance, had an updated dosage, or had their
                            existing prescription renewed/maintained this
                            session (or since last confirmed session)
                          </Radio>
                        </FormControl>
                        {values.didPrescribeControlledSubstance && (
                          <>
                            <FormControl
                              component={ControlledSubstanceCombobox}
                              label="Which substance(s)?"
                              disabled={!canUpdateSessionDetails}
                              name="controlledSubstanceAttestation.prescribedSubstances"
                              onSelectionChange={(keys: Set<string>) => {
                                setFieldValue(
                                  'controlledSubstanceAttestation.prescribedSubstances',
                                  Array.from(keys)
                                );
                              }}
                              selectedKeys={
                                new Set(
                                  values.controlledSubstanceAttestation
                                    ?.prescribedSubstances
                                )
                              }
                            />
                            {!!values.controlledSubstanceAttestation
                              .prescribedSubstances &&
                              values.controlledSubstanceAttestation.prescribedSubstances.filter(
                                (substance) =>
                                  substance !== ControlledSubstance.OTHER
                              ).length > 0 && (
                                <div>
                                  <CaptionText>
                                    <strong>
                                      Highest{' '}
                                      <GlossaryTerm term="recommended dose">
                                        <div>
                                          Dosage amounts shown are based on
                                          clinical review of existing FDA
                                          approved maximum daily dosages and
                                          expert consensus recommendations for
                                          management of neuropsychiatric
                                          conditions.
                                        </div>
                                        <br />
                                        <div>
                                          Prescribing above the dosages
                                          indicated may increase risk of adverse
                                          events and should be done with
                                          caution, clinical reasoning, and
                                          informed concent appropriately
                                          documented.
                                        </div>
                                      </GlossaryTerm>{' '}
                                      per day
                                    </strong>
                                  </CaptionText>
                                  <ul className="hlx-typography-subbody mb-0 mt-1 pl-6">
                                    {values.controlledSubstanceAttestation.prescribedSubstances
                                      .filter(
                                        (substance) =>
                                          substance !==
                                          ControlledSubstance.OTHER
                                      )
                                      .map((substance) => (
                                        <li key={substance}>
                                          {
                                            CONTROLLED_SUBSTANCE_DISPLAY_NAMES[
                                              substance as Exclude<
                                                ControlledSubstance,
                                                ControlledSubstance.OTHER
                                              >
                                            ]
                                          }
                                          :{' '}
                                          {
                                            MAX_DOSAGE[
                                              substance as Exclude<
                                                ControlledSubstance,
                                                ControlledSubstance.OTHER
                                              >
                                            ]
                                          }
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            <FormControl
                              component={RadioGroup}
                              name="controlledSubstanceAttestation.exceedsRecommendedDosage"
                              disabled={!canUpdateSessionDetails}
                              label="Did the client receive a prescription of any controlled substance with a higher dose per day than the recommended amount?"
                              onChange={(value) => {
                                setFieldValue(
                                  'controlledSubstanceAttestation.exceedsRecommendedDosage',
                                  value === 'true' ? true : false
                                );
                              }}
                              value={String(
                                values.controlledSubstanceAttestation
                                  ?.exceedsRecommendedDosage
                              )}
                            >
                              <Radio value="false">No</Radio>
                              <Radio value="true">
                                Yes (provide explanation)
                              </Radio>
                            </FormControl>
                            <FormControl
                              component={TextArea}
                              name="controlledSubstanceAttestation.notes"
                              disabled={!canUpdateSessionDetails}
                              label="Include frequency, dosage, and any other relevant notes for each substance"
                            />
                          </>
                        )}
                      </>
                    )}
                </Form>
              </>
            );
          }}
        </MiscodingResultsProvider>
      )}
    </Formik>
  );
};

const MiscodingErrorsGuidanceCard = ({ errors }: { errors: CodingError[] }) => (
  <div css={{ marginBottom: theme.spacing.x5 }}>
    <GuidanceCard key={'error'} variant={'error'}>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ ...theme.typography.list }}>
          Please fix the following{' '}
          {errors.length > 1 ? `${errors.length} errors` : 'error'}:
        </span>
        <ul>
          {errors.map((error, idx) => (
            <li key={idx}>{error.message}</li>
          ))}
        </ul>
      </div>
    </GuidanceCard>
  </div>
);

interface MiscodingResultsProviderProps {
  cptCodes?: CPTCodeInfo[];
  diagnosisCodes?: DXCodeInfo[];
  exactStartTime?: TimeFields;
  exactEndTime?: TimeFields;
  patient: UserRead;
  provider: ProviderRead;
  event: ProviderEventRead;
  children: React.FC<
    React.PropsWithChildren<{
      miscodingResults: MiscodingResults;
    }>
  >;
}

const MiscodingResultsProvider = ({
  cptCodes,
  diagnosisCodes,
  patient,
  provider,
  event,
  exactStartTime,
  exactEndTime,
  children,
}: MiscodingResultsProviderProps) => {
  const { data: miscodingResults } = useMiscodingResultsQuery(
    cptCodes || [],
    diagnosisCodes || [],
    exactStartTime,
    exactEndTime,
    patient,
    provider,
    event
  );
  return children({
    miscodingResults: miscodingResults || { errors: [], warnings: [] },
  });
};
