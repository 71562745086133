import { UnitedStates } from '@headway/api/models/UnitedStates';

export enum PayerStatus {
  PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED = 'payer_questionniare_pending_action_needed',
  APPLIED = 'applied',
  CREDENTIALED = 'credentialed',
  CREDENTIALING_DELAYED = 'credentialing_delayed',
  CREDENTIALING_DELAYED_ACTION_NEEDED = 'credentialing_delayed_action_needed',
  APPOINTMENT_READY = 'appointment_ready',
  PAYMENT_READY = 'payment_ready',
  COMPLETE = 'complete',
}

export type PayerStatesMap = Map<string, UnitedStates[]>;
export type PayerStatusMap = Map<PayerStatus, PayerStatesMap>;
