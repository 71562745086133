import React from 'react';

import { ProviderTreatmentPlanApi } from '@headway/api/resources/ProviderTreatmentPlanApi';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

interface DeleteTreatmentPlanModalProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  treatmentPlanId: number;
}

export const DeleteTreatmentPlanModal = ({
  open,
  onClose,
  onDelete,
  treatmentPlanId,
}: DeleteTreatmentPlanModalProps) => {
  return (
    <Modal title="Delete treatment plan" isOpen={open} onDismiss={onClose}>
      <ModalContent>
        Are you sure you want to delete this treatment plan.
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          onPress={async () => {
            await ProviderTreatmentPlanApi.deleteProviderTreatmentPlan(
              treatmentPlanId
            );
            onDelete();
            onClose();
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
