import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { useMutation } from '@headway/shared/react-query';
import { toaster } from '@headway/ui/ToastManager';

import { useAuthStore } from 'stores/AuthStore';
import { redirectToAuth0Logout, useAuth0Client } from 'utils/auth';

import { Task, TaskIntent } from './Task';

const taskHeaderText = 'Set up two-factor authentication (2FA)';
const taskSubBodyText =
  "To login to Headway, we'll send you a unique verification code to keep your account more secure. Manage your preferred delivery method to receive codes.";

export const MultiFactorSetupTask = () => {
  return (
    <>
      <Task
        icon={<NewReleasesOutlinedIcon />}
        listHeaderText={taskHeaderText}
        subBodyText={taskSubBodyText}
        primaryAction={<EnableMfaButton />}
        secondaryAction={<></>}
        taskIntent={TaskIntent.ALERT}
      />
    </>
  );
};

const EnableMfaButton = () => {
  const authStore = useAuthStore();
  const user = authStore.user;

  const auth0Client = useAuth0Client();

  const handleEnableUserMfaClicked = useMutation(
    async () => {
      await UserApi.enableUserMfa(user.id);
    },
    {
      onSuccess: async () => {
        // Log user out if successful
        if (auth0Client) {
          await redirectToAuth0Logout(auth0Client);
        }
      },
      onError: (error) => {
        toaster.error(
          `An error occurred while attempting to enroll you in 2FA`
        );
      },
    }
  );

  return (
    <>
      <Button
        variant="primary"
        size="medium"
        disabled={handleEnableUserMfaClicked.isLoading}
        onPress={() => handleEnableUserMfaClicked.mutateAsync()}
      >
        Enroll in 2FA
      </Button>
    </>
  );
};
