import { AssignmentTwoTone } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useProvider } from 'hooks';
import { zip } from 'lodash';
import moment from 'moment/moment';
import React from 'react';

import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { Tooltip, VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useCanConfirmSessionDetailsList } from 'hooks/useCanConfirmSessionDetails';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { PatientsContext } from 'providers/PatientsProvider';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';

import { DetailPopoverProps } from './DetailPopover';
import { TaskPopover, TaskPopoverProps } from './TaskPopover';

interface TaskProps {
  handleTaskConfirmDetailsClick: TaskPopoverProps['handleTaskConfirmDetailsClick'];
  handleTaskCancelClick: TaskPopoverProps['handleTaskCancelClick'];
}

export const Task: React.FC<React.PropsWithChildren<TaskProps>> = ({
  handleTaskConfirmDetailsClick,
  handleTaskCancelClick,
}) => {
  const { patients } = React.useContext(PatientsContext);
  const [anchorEl, setAnchorEl] =
    React.useState<DetailPopoverProps['anchorEl']>(null);

  const provider = useProvider();

  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const { ironcladAgreementStatus } = useIroncladAgreementStatus();
  const didProviderAgreeIronclad = shouldBlockProviderWithIroncladAgreement(
    isIroncladBlockAppointmentConfirmationEnabled,
    ironcladAgreementStatus
  );

  const didProviderAgreeIroncladKey = `didProviderAgreeIronclad-${didProviderAgreeIronclad}`;
  const sessionStartDate = didProviderAgreeIronclad
    ? moment().subtract(30, 'days').toISOString()
    : moment().subtract(90, 'days').toISOString();
  const fetchUnfilteredSessions = useQuery(
    ['sessionsToBeConfirmed', didProviderAgreeIroncladKey],
    async () => {
      const sessionDateRangeStart = sessionStartDate;
      const sessionDateRangeEnd = moment().toISOString();
      const potentialSessions = await ProviderEventApi.getEvents({
        provider_id: provider.id,
        event_types: [ProviderEventType.APPOINTMENT],
        appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
        date_range_start: sessionDateRangeStart,
        date_range_end: sessionDateRangeEnd,
        expand_estimated_prices: false,
        order_by: 'start_date',
        order: 'asc',
        expand: true,
      });
      return potentialSessions.data;
    }
  );

  const sessionConfirmabilityQueries = useCanConfirmSessionDetailsList(
    fetchUnfilteredSessions.data || []
  );
  const allSessionTasks: ProviderEventRead[] = [];
  for (const [session, confirmabilityQuery] of zip(
    fetchUnfilteredSessions.data || [],
    sessionConfirmabilityQueries
  )) {
    if (confirmabilityQuery?.canConfirmSessionDetails) {
      allSessionTasks.push(session!);
    }
  }
  allSessionTasks.sort(
    (eventA: ProviderEventRead, eventB: ProviderEventRead) => {
      return (
        new Date(eventA.startDate!).getTime() -
        new Date(eventB.startDate!).getTime()
      );
    }
  );

  const tasks = allSessionTasks.map((event: ProviderEventRead) => ({
    event,
    patient: patients?.find(
      (patient: any) => patient.id === event.patientUserId
    ),
  }));

  return (
    <>
      <TaskPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        tasks={tasks}
        handleTaskConfirmDetailsClick={handleTaskConfirmDetailsClick}
        handleTaskCancelClick={handleTaskCancelClick}
      />
      <Badge badgeContent={tasks.length} color="error">
        <Tooltip title="Tasks">
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            aria-label="Calendar tasks"
            data-testid="calendarTaskButton"
            size="large"
          >
            <AssignmentTwoTone css={{ fontSize: theme.fontSize.xl }} />
            <VisuallyHidden>Tasks</VisuallyHidden>
          </IconButton>
        </Tooltip>
      </Badge>
    </>
  );
};
