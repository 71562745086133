import React from 'react';

import { theme } from '../theme';
import { FormRow } from './FormRow';

export const FormIconRow = ({ children, icon: Icon, ...props }) => (
  <div css={{ display: 'flex', alignItems: 'center' }}>
    <Icon
      css={{
        width: 30,
        height: 30,
        marginRight: theme.space.base,
      }}
    />
    <FormRow {...props}>{children}</FormRow>
  </div>
);
