import { MessageApi } from '@headway/api/resources/MessageApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseMessagesQueryKeyArgs {
  providerId?: number;
  patientId?: number;
}
export const getUseMessagesQueryKey = (
  args: UseMessagesQueryKeyArgs
): [string, UseMessagesQueryKeyArgs] => ['get-messages', args];

const {
  useSingleQuery: useMessages,
  useListQuery: useMessagesList,
  useCachedQuery: useMessagesCache,
} = createBasicApiHooks(
  getUseMessagesQueryKey,
  ({ providerId, patientId }) =>
    MessageApi.getMessages({ provider_id: providerId, user_id: patientId }),
  ({ providerId, patientId }) => !!(providerId || patientId),
  () => 'Failed to fetch messages'
);

export { useMessages, useMessagesList, useMessagesCache };
