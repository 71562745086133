/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { AuthorizationType } from '../models/AuthorizationType';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { InsuranceAuthorizationCreate } from '../models/InsuranceAuthorizationCreate';
import { InsuranceAuthorizationRead } from '../models/InsuranceAuthorizationRead';
import { InsuranceAuthorizationUpdate } from '../models/InsuranceAuthorizationUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class InsuranceAuthorizationApiResource {

    /**
     * Create Insurance Authorization
     * 
     * @param insuranceAuthorizationCreate 
     
     */
    public createInsuranceAuthorization(body: InsuranceAuthorizationCreate, axiosConfig?: AxiosRequestConfig): Promise<InsuranceAuthorizationRead> {
        const apiRoute = '/insurance-authorization';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Insurance Authorization
     * 
     * @param insuranceAuthorizationId 
     
     */
    public deleteInsuranceAuthorization(insuranceAuthorizationId: number, axiosConfig?: AxiosRequestConfig): Promise<InsuranceAuthorizationRead> {
        const apiRoute = '/insurance-authorization/{insurance_authorization_id}';
        const reqPath = apiRoute
                    .replace('{' + 'insurance_authorization_id}', String(insuranceAuthorizationId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Insurance Authorizations
     * 
     * @param userId 
     * @param userInsuranceId 
     * @param dateOfService 
     * @param hasUnusedSessions 
     * @param authorizationType 
     
     */
    public findInsuranceAuthorizations(query?: { user_id?: number, user_insurance_id?: number, date_of_service?: string, has_unused_sessions?: boolean, authorization_type?: AuthorizationType }, axiosConfig?: AxiosRequestConfig): Promise<Array<InsuranceAuthorizationRead>> {
        const apiRoute = '/insurance-authorization';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Insurance Authorization
     * 
     * @param insuranceAuthorizationId 
     
     */
    public getInsuranceAuthorization(insuranceAuthorizationId: number, axiosConfig?: AxiosRequestConfig): Promise<InsuranceAuthorizationRead> {
        const apiRoute = '/insurance-authorization/{insurance_authorization_id}';
        const reqPath = apiRoute
                    .replace('{' + 'insurance_authorization_id}', String(insuranceAuthorizationId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Insurance Authorization
     * 
     * @param insuranceAuthorizationId 
     * @param insuranceAuthorizationUpdate 
     
     */
    public updateInsuranceAuthorization(insuranceAuthorizationId: number, body: InsuranceAuthorizationUpdate, axiosConfig?: AxiosRequestConfig): Promise<InsuranceAuthorizationRead> {
        const apiRoute = '/insurance-authorization/{insurance_authorization_id}';
        const reqPath = apiRoute
                    .replace('{' + 'insurance_authorization_id}', String(insuranceAuthorizationId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const InsuranceAuthorizationApi = new InsuranceAuthorizationApiResource();
