import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { LegalSection } from '../LegalSection';
import { PrivatePayProviderAddendum } from '../PrivatePayProviderAddendum';
import { SummarySection } from '../SummarySection';
import { StateAgreementProps } from './StateAgreementProps';

export const CaliforniaAgreement: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<StateAgreementProps>>
> = ({
  isGroupPractice,
  isNursePractitioner,
  isPhysician,
  licenseType,
  setSelectedPart,
}) => (
  <div>
    <LegalSection title="Recitals">
      <p>
        A. Practice is a California behavioral health practice duly authorized
        to practice in California and that is in the business of, among other
        things, establishing an administrative infrastructure and platform to
        facilitate the provision of behavioral health services by behavioral
        health therapists. Practice does not solely provide such administrative
        infrastructure but also engages in other aspects of developing a
        streamlined platform and other web services through which Practice
        operates such that the Practice is not financially dependent on the
        administrative infrastructure through which behavioral health services
        are provided.
      </p>
      {isGroupPractice ? (
        <p>
          B. PROVIDER is authorized by the State of California to provide
          behavioral health services by and through its licensed professional
          staff.
        </p>
      ) : isNursePractitioner ? (
        <p>
          PROVIDER is licensed by the State of California to practice as a nurse
          practitioner and desires to practice as an independent contractor of
          the Practice.
        </p>
      ) : isPhysician ? (
        <p>
          PROVIDER is licensed by the State of California to practice as a
          physician and desires to practice as an independent contractor of the
          Practice.
        </p>
      ) : (
        <p>
          B. PROVIDER is licensed by the State of California to practice as the
          behavioral health provider type listed on PROVIDER’s intake form and
          desires to practice as an independent contractor of the Practice.
        </p>
      )}

      <p>
        C. Practice wishes to furnish PROVIDER with the ability to see patients
        in-network as a provider of the Practice.
      </p>
      <p>
        D. PROVIDER wishes to make {isGroupPractice ? 'its' : 'their'} services
        available to Practice, in order to expand{' '}
        {isGroupPractice ? 'its' : 'their'} private practice by seeing patients
        in-network as part of the Practice.
      </p>
      <p>
        In consideration of these premises and the respective promises,
        undertakings and covenants of the parties set forth and exchanged
        herein, Practice and PROVIDER agree as follows:
      </p>
    </LegalSection>

    <LegalSection title="Independent Contractor">
      <p>
        A. Independent Contractor Status. Upon the date on which this Agreement
        is fully executed (the "Effective Date"), PROVIDER agrees to furnish
        behavioral health services as an independent contractor of Practice,
        subject to the terms and conditions set forth herein. PROVIDER expressly
        acknowledges and agrees that the PROVIDER is engaged in a business of
        the PROVIDER’s own, which is of the same nature as that of the services
        PROVIDER will provide hereunder. PROVIDER is not subject to the
        Practice’s control or direction with respect to the manner in which the
        details of the services are to be performed or what the PROVIDER must do
        as the work progresses. PROVIDER is engaged in work that is outside the
        usual course of the Practice’s business. PROVIDER agrees to furnish, at
        the PROVIDER’s own expense, the materials, equipment, and other
        resources necessary to perform the services. PROVIDER shall have no
        minimum hours requirement and is free to accept or refuse patients, and
        to determine the times at which PROVIDER sees patients, consistent with
        applicable law.
      </p>
      <p>
        B. Relationship of the Parties. PROVIDER and Practice expressly
        acknowledge and agree that this Agreement is not intended, nor shall be
        construed, to create an employer/employee relationship, a joint venture,
        a partnership or a landlord/tenant relationship between PROVIDER and
        Practice. Although Practice shall not have any right to exercise any
        control or direction over the manners, methods, details, procedures or
        decisions relating to performance of services which are the subject
        matter of this Agreement, the services to be provided by PROVIDER
        hereunder shall be provided in a manner consistent with the provisions
        of this Agreement, as well as all applicable laws, rules, regulations,
        and medical and ethical standards. PROVIDER understands and agrees that:
        (i) PROVIDER will not be eligible to participate in any vacation, group
        medical or life insurance, disability, profit sharing or retirement
        benefits, or any other fringe benefits offered by the Practice to its
        employees; (ii) Practice will not withhold on behalf of PROVIDER or
        PROVIDER’s employees any sums for income, payroll, Social Security, or
        other federal, state, or local taxes, making any insurance
        contributions, including for unemployment or disability, or obtaining
        workers’ compensation insurance on Provider’s behalf, or any other
        withholding pursuant to any law or requirement of any governmental body
        relating to Practice, PROVIDER, and PROVIDER’s employees; and (iii) all
        of such payments, withholding and benefits, if any, are the sole
        responsibility of PROVIDER. In the event that the Internal Revenue
        Service or any other governmental agency questions or challenges the
        independent contractor status of PROVIDER, the parties hereto mutually
        agree that both Practice and PROVIDER shall have the right to
        participate in any discussion or negotiation occurring with such
        governmental agency irrespective of by whom such discussions or
        negotiations are initiated. Moreover, the PROVIDER agrees to indemnify,
        defend, and hold harmless the Practice for any action, claim,
        administrative investigation, or hearing, including, without limitation,
        all damages, assessments, back taxes, workers’ compensation,
        unemployment insurance benefits, costs, or expenses (including actual
        and reasonable attorneys’ fees), arising out of or relating to any
        challenge to PROVIDER’s status as an independent contractor.
      </p>
      <p>
        C. PROVIDER and Practice expressly acknowledge and agree that the
        PROVIDER is engaged in an occupation that requires the PROVIDER’s use of
        the PROVIDER’s special skill, knowledge, and training. PROVIDER is
        performing specific services for a fixed amount of compensation as set
        forth on Attachment A. PROVIDER and Practice expressly acknowledge and
        agree that the PROVIDER is not in the regular employ of the Practice, is
        free to hire and control the PROVIDER’s own assistants, and PROVIDER is
        able, and expected, to accept other business.
      </p>
      <p>
        D. PROVIDER expressly acknowledges that PROVIDER is not relying solely
        on Practice as PROVIDER’s sole source of income and that PROVIDER is
        able to provide, and provides, services to patients outside of Practice.
      </p>
      <SummarySection>
        You are truly independent. You choose which patients you see, and when.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Responsibilities of Practice">
      <p>
        A. Practice will provide PROVIDER with its good faith efforts to get
        PROVIDER on managed care panels of PROVIDER's choice, provide reasonable
        assistance in PROVIDER credentialing and recredentialing with
        third-party payers, negotiate for fee schedules that are favorable to
        Practice and PROVIDER, provide reasonable assistance in administrative
        work that comes with complying with third-party payer rules, market
        PROVIDER's services for the Practice, and assist with placing patients
        with other providers (like prescribers who are also independent
        contractors of the Practice) if need be.
      </p>
      <SummarySection>
        When you work with Headway, we handle the administrative details.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Provider Representations and Warranties">
      <p>
        A. PROVIDER represents, warrants and covenants as follows, and PROVIDER
        agrees to immediately notify Practice if any of the following
        representations or warranties become inaccurate or false at any time
        during the Term of this Agreement:
      </p>
      <ol>
        {isGroupPractice ? (
          <React.Fragment>
            <li>
              PROVIDER is a professional corporation duly organized or otherwise
              qualified under the laws of the State of California.
            </li>
            <li>
              All individual health care providers who provide services on
              behalf of PROVIDER and are interested in joining Practice
              (“PROVIDER Personnel”) are duly licensed under the laws of the
              State of California to provide the health care services they
              provide, no PROVIDER Personnel have their license suspended,
              revoked or restricted in any manner, and PROVIDER has and all
              PROVIDER Personnel have not been excluded, suspended or debarred
              from any state or federal health care payment program, including
              the Medicare or Medicaid program.
            </li>
            <li>
              All PROVIDER Personnel have an individual National Provider
              Identifier (“NPI”).
            </li>
            <li>
              PROVIDER has Personnel who are nurse practitioners have an ANCC
              certificate in the behavioral health field.
            </li>
            <li>
              All PROVIDER Personnel with prescriptive authority have a current
              controlled substances registration issued by the United States
              Drug Enforcement Administration, which registration has not been
              surrendered, suspended, revoked or restricted in any manner.
            </li>
            <li>
              PROVIDER Personnel, if seeing patients of the Practice virtually
              through “tele-therapy” or “tele-psychiatry”, will render services
              through secure video equipment of PROVIDER's choice that is
              compliant with all applicable laws. Any costs associated with
              using said video equipment are the PROVIDER's sole responsibility.
            </li>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <li>
              PROVIDER is duly licensed under the laws of the State of
              California, as{' '}
              {isPhysician
                ? 'a physician'
                : isNursePractitioner
                ? 'a nurse practitioner'
                : '	PROVIDER is duly licensed under the laws of the State of California, as the behavioral health provider type listed on PROVIDER’s intake form,'}
              , PROVIDER's license has not been suspended, revoked or restricted
              in any manner, and PROVIDER has not been excluded, suspended or
              debarred from any state or federal health care payment program,
              including the Medicare or Medicaid program.
            </li>
            <li>
              PROVIDER has an individual National Provider Identifier ("NPI").
            </li>
            {isNursePractitioner ? (
              <React.Fragment>
                <li>
                  PROVIDER is and shall remain throughout the term of this
                  Agreement in compliance with all collaborative practice
                  agreement and physician supervision requirements. PROVIDER
                  acknowledges and agrees that it is PROVIDER's sole
                  responsibility to assure that appropriate physician
                  supervision is obtained and that collaborative practice
                  agreement requirements are observed.
                </li>
                <li>
                  PROVIDER has an ANCC certificate in the behavioral health
                  field.
                </li>
                <li>
                  PROVIDER has a current controlled substances registration
                  issued by the United States Drug Enforcement Administration,
                  which registration has not been surrendered, suspended,
                  revoked or restricted in any manner.
                </li>
              </React.Fragment>
            ) : isPhysician ? (
              <React.Fragment>
                <li>PROVIDER is Board Certified in Psychiatry</li>
                <li>
                  PROVIDER has a current controlled substances registration
                  issued by the United States Drug Enforcement Administration
                  for the state(s) in which he or she practices, which
                  registration has not been surrendered, suspended, revoked or
                  restricted in any manner.
                </li>
              </React.Fragment>
            ) : null}
            <li>
              PROVIDER, if seeing patients of the Practice virtually through
              “tele-therapy” or “tele-psychiatry”, will render services through
              secure video equipment of PROVIDER’s choice that is compliant with
              all applicable laws. Any costs associated with using said video
              equipment are the PROVIDER’s sole responsibility.
            </li>
          </React.Fragment>
        )}

        <li>
          PROVIDER has disclosed and will disclose to the Practice the following
          matters, whether occurring at any time prior to or during the Term of
          this Agreement:
          <ol type="a">
            <li>
              any malpractice suit, claim (whether or not filed in court),
              settlement, settlement allocation, judgment, verdict or decree
              against PROVIDER;
            </li>
            <li>
              any disciplinary, peer review or professional review
              investigation, proceeding or action instituted against PROVIDER by
              any licensure board, hospital, institution of higher education,
              health care facility or entity, professional society or
              association, third-party payer, peer review or professional review
              committee or body, or governmental agency;
            </li>
            <li>
              any criminal complaint, indictment or criminal proceeding in which
              PROVIDER is named as a defendant;
            </li>
            <li>
              any investigation or proceeding, whether administrative, civil or
              criminal, relating to an allegation against PROVIDER of filing
              false health care claims, violating anti-kickback laws, or
              engaging in other billing improprieties;
            </li>
            <li>
              any health condition that impairs or may impair PROVIDER's ability
              to provide{' '}
              {isPhysician || isNursePractitioner
                ? 'medical'
                : 'behavioral health care'}{' '}
              services;
            </li>
            <li>
              any allegation, or any investigation or proceeding based on any
              allegation, against PROVIDER, of violating professional ethics or
              standards, or engaging in illegal, immoral or other misconduct (of
              any nature or degree), relating to the practice of any licensed
              profession; and
            </li>
            <li>
              any denial or withdrawal of an application in any state for
              licensure, for medical staff privileges at any hospital or other
              health care entity, for board certification or recertification,
              for participation in any third party payment program, for state or
              federal controlled substances registration, or for malpractice
              insurance.
            </li>
          </ol>
        </li>
        <li>
          PROVIDER has the legal authority to enter into this Agreement and to
          fulfill all of its obligations under this Agreement.
        </li>
        <li>
          PROVIDER is authorized under applicable law to work in the United
          States.
        </li>
        <li>
          PROVIDER is engaged in PROVIDER’s own independently established
          business, occupation, trade, and profession relative to the services
          contemplated herein.
        </li>
      </ol>
    </LegalSection>
    <LegalSection title="Provider's Services and Responsibilities">
      <p>
        A. General. PROVIDER shall provide behavioral health services to
        Practice's patients or certain self-pay patients (see the “Self-Pay
        Terms” section below).
        {isNursePractitioner
          ? ' To the extent clinically appropriate and permitted by applicable collaborative practice laws, rules and agreements, PROVIDER shall use his or her prescriptive authority in the state(s) in which he or she practices. '
          : ' '}
        PROVIDER shall furnish Practice with documentation of PROVIDER's
        credentials upon reasonable request. PROVIDER shall complete and
        maintain, in a timely manner, adequate and legible treatment records
        with respect to all services rendered to patients of the Practice.
      </p>
      <SummarySection>
        As part of providing great care to patients, you’ll keep appropriate
        records.
      </SummarySection>
      <p>
        B. Third-Party Payer Requirements. While Practice will exercise
        commercially reasonable efforts to mitigate PROVIDER's obligations to
        interface with third-party payers, PROVIDER agrees to comply with
        third-party payer requirements (such as documentation, utilization
        review, credentialing and recredentialing) resulting from PROVIDER's
        joining third-party payer’s networks through the Practice.
        {isGroupPractice
          ? ' PROVIDER authorizes Practice to use and disclose PROVIDER Personnel’s personally identifiable information for credentialing and quality improvement purposes. PROVIDER agrees to provide and/or amend any third-party required documentation within three (3) days of receiving written notice from Practice. PROVIDER understands that timely submission of third-party required documentation is a prerequisite to receiving payment hereunder. PROVIDER represents and warrants that it has any necessary authorizations to comply with the provisions of this section.'
          : ' PROVIDER authorizes Practice to use and disclose PROVIDER’s personally identifiable information for credentialing and quality improvement purposes. PROVIDER agrees to provide and/or amend any third-party required documentation within three (3) days of receiving written notice from Practice. PROVIDER understands that timely submission of third-party required documentation is a prerequisite to receiving payment hereunder.'}
      </p>
      <SummarySection>
        We will make life as simple as possible for you by working with payers,
        but you’ll cooperate with us if we need information from you to meet
        applicable payer requirements.
      </SummarySection>
      <p>
        C. Independent Professional Judgment. This Agreement in no way
        constrains the exercise of PROVIDER's independent professional judgment
        in providing{' '}
        {isPhysician || isNursePractitioner ? 'medical' : 'behavioral health'}{' '}
        care to patients, so long as such judgment is consistent with the laws
        and regulations of the jurisdiction(s) in which the care is provided,
        community standards, sound professional practice, and the terms of this
        Agreement.
      </p>
      <SummarySection>
        Your practice is your practice. We won’t get between you and your
        patient.
      </SummarySection>
      <p>
        D. Performance Standards. In performing services under this Agreement,
        PROVIDER agrees at all times to (a) use diligent efforts and his or her
        best professional skills and judgment in accordance with the standards
        of PROVIDER's license; (b) perform in accordance with the recognized
        standards of his or her profession; (c) act in accordance with all
        applicable ethical standards; and; (d) comply with all applicable
        federal, state and local laws and regulations. Without limitation of the
        foregoing, PROVIDER shall not make or accept any referrals or generate
        any business in violation of any state or federal fraud and abuse law,
        including, without limitation, the federal Anti- Kickback Statute, 42
        U.S.C. § 1320a-7b, and the federal Stark Law, 42 U.S.C. § 1395nn.
      </p>
      <SummarySection>
        You will meet applicable legal, professional, and ethical standards.
      </SummarySection>
      <p>
        E. Emergency Services. PROVIDER will maintain a confidential voicemail
        telephone number and provide the number to all of PROVIDER's clients
        through Practice. The outgoing voice message of the voicemail box will
        advise the client that PROVIDER does not provide emergency services and
        will inform the client of appropriate crisis resources (e.g., hospital
        emergency departments, the National Suicide Prevention Lifeline).
      </p>
      <p>
        F. Limitation of Authority. Because PROVIDER is an independent
        contractor, PROVIDER is not authorized to enter into any contract or
        agreement or make any such undertakings on behalf of Practice.
      </p>
      <SummarySection>
        Your relationship with us is limited to providing great care. You can’t
        and won’t do anything else for us, like entering into contracts.
      </SummarySection>
      <p>
        G. Facility Access. PROVIDER shall use his or her own space for
        appointments with patients of the Practice, and PROVIDER and Practice
        mutually agree that the fees set forth in Attachment A have been
        calculated to provide PROVIDER with fair market value compensation in
        exchange for a license for the Practice to conduct business in such
        space for the sole purpose of PROVIDER seeing patients of the Practice.
      </p>
      <SummarySection>
        When you’re seeing a Headway patient, your practice location is
        considered a Headway location.
      </SummarySection>
      <p>
        H. Payment Information. PROVIDER agrees to record payment information in
        Practice’s payment processing system and to not otherwise store, record,
        or use such information.
      </p>
      <SummarySection>
        If you collect payment information from patients, you are only permitted
        to enter it into Headway's system.
      </SummarySection>
      <p>
        I. Self-Pay Terms. PROVIDER is able to negotiate and set Self-Pay rates
        for patients without insurance (the "Self-Pay Rate"). PROVIDER
        understands that the Practice will pass along to PROVIDER certain of the
        costs associated with seeing self-pay patients, including (i) a Stripe
        percentage fee that is variable to whether ACH (currently 0.8% of the
        Self-Pay Rate) or credit card (currently 2.3% of the Self-Pay Rate)
        payments are made, and (ii) a flat administrative fee, which is
        dependent on geographic location, and which can be found in the
        Practice’s electronic portal. By way of example, if PROVIDER negotiates
        a Self-Pay Rate of $100, Practice will charge the patient $100. If the
        patient pays $100 by credit card and the flat rate for PROVIDER's
        geographic location is set at $2.00, the Practice would pay the PROVIDER
        $95.70. ($100 - (($100 * 0.023) + $2.00) = $95.70).
        <br />
        The following requirements apply to PROVIDER’s use of Self-Pay Rates.
        Failure to observe any of these requirements will result in non-payment:
        (a) Self-Pay Rates are only available for patients who do not have
        insurance that can be used to see the PROVIDER through the Practice; (b)
        Self-Pay patients must pay the Self-Pay Rate in order for the PROVIDER
        to receive compensation for seeing the self-pay patient; (c) to charge a
        Self-Pay Rate, PROVIDER must either be credentialed with the Practice or
        in process to be credentialed with the Practice; and (d) Self-Pay Rates
        are only available to patients the PROVIDER has brought to the Practice;
        except that, a PROVIDER may charge a Self-Pay Rate to a Practice-sourced
        patient if that patient is no longer covered by an insurance that the
        Practice accepts.
        <br />
        In addition, PROVIDER and Practice agree that: (a) the Practice is not
        responsible for payment to the PROVIDER if a self-pay patient does not
        complete payment; and (b) Self-Pay patients will be able to download
        superbills from the Practice to submit to insurance companies that will
        contain PROVIDER's EIN (if provided) and NPI (and not the Practice's EIN
        or NPI).
      </p>
      <SummarySection>
        Self-pay: You can set your own self-pay (i.e. private pay) rates for
        self-pay patients you see through Headway. Self-pay is only available
        for uninsured patients or patients with an insurance plan that is
        out-of-network with Headway. There is a fee associated with the self-pay
        service, but you are not obligated to use the self-pay feature.
      </SummarySection>

      {isGroupPractice ? (
        <React.Fragment>
          <p>
            I. Compliance by Provider Personnel. PROVIDER shall bind all
            PROVIDER Personnel to the terms and conditions of this Agreement.
          </p>
          <SummarySection>
            You have authority to ensure members of your group seeing patients
            through Headway adhere to the terms and conditions laid out here.
          </SummarySection>
        </React.Fragment>
      ) : null}
    </LegalSection>
    <LegalSection title="Medical and Business Records">
      <p>
        A. PROVIDER acknowledges and agrees that Practice will have access to,
        and a perpetual license to use, records of services that are contained
        in Practice’s software. In addition, Practice may ask for additional
        information regarding PROVIDER’s treatment of Practice’s patient for
        various purposes, such as, for example, filing claims with third-party
        payers, and PROVIDER agrees to comply with such requests. Practice will
        make sure if information regarding Practice’s patients needs to be
        shared with other entities, such as third-party payer care reviews,
        Practice will do so in accordance with applicable law.
      </p>
      <SummarySection>
        Records entered into Headway’s system will stay with Headway if you
        leave the clinic. Of course, you have full access to the records during
        your time with us. If a payer asks for records, and there’s a problem
        with the records for your patients, you’ll work with us to make it
        right.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Charges and Billing">
      <p>
        A. As a service to PROVIDER, for therapy appointments that the PROVIDER
        takes on behalf of the Practice under this Agreement: (a) Practice, and
        not PROVIDER, shall, unless otherwise stated herein, negotiate fee
        schedules with third-party payers, establish charges for professional
        services, and handle all billing of patients and third-party payers; (b)
        PROVIDER will set a Self-Pay Rate, and Practice will handle all billing
        of such patients in accordance with the Self-Pay Terms contained herein;
        (c) PROVIDER shall not bill to or collect from any patient or third
        party payer any amount for services rendered under this Agreement; and
        (d) PROVIDER hereby irrevocably assigns and grants to the Practice the
        right to bill and collect from Practice’s patients and/or third-party
        payers that PROVIDER joins through Practice.
      </p>
      <p>
        B. PROVIDER acknowledge and understands that the Practice is in the
        business of establishing an administrative infrastructure to assist with
        the provision of behavioral health services, which necessarily includes
        negotiating third-party payer rates and confirming which patients are in
        network, and therefore, available for the PROVIDER to provide services
        at the PROVIDER’s choice.
      </p>
      <SummarySection>
        We deal with the insurance companies so you don’t have to, and we
        collect payment from them as well as from patients.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Compensation">
      <p>
        A. In consideration of all services provided by PROVIDER under this
        Agreement, Practice will pay PROVIDER the compensation set forth on
        Attachment A.
      </p>
      <SummarySection>
        We’ll pay you according to the fee schedule we’ve provided to you. This
        fee schedule is confidential.
      </SummarySection>
    </LegalSection>

    <LegalSection title="Insurance">
      {isGroupPractice ? (
        <React.Fragment>
          <p>
            A. PROVIDER has, and agrees to maintain during the Term of this
            Agreement, at its sole cost and expense, the following insurance
            coverages: (a) commercial general liability coverage covering
            PROVIDER (including the acts of all PROVIDER Personnel), with limits
            reasonably acceptable to Practice and (b) workers’ compensation
            coverage as required by state law. Upon request, PROVIDER agrees to
            (a) name Practice as an additional insured on commercial general
            liability coverage described by this section and (b) furnish
            Practice with a copy of PROVIDER’s certificates of insurance
            evidencing any of the coverages set forth above.
          </p>
          <SummarySection>
            You’ll keep liability insurance that meets our requirements. Your
            malpractice policy covers you; our malpractice policy covers us.
          </SummarySection>
          <p>
            B. PROVIDER represents, warrants and covenants that during the Term
            of this Agreement, all PROVIDER Personnel have, and agree to
            maintain, professional insurance covering such PROVIDER Personnel
            with minimum limits of (a) in the case of prescribers, $1,000,000
            per incident and $3,000,000 in the aggregate; and (b) in the case of
            nonprescribers, $1,000,000 per incident and $1,000,000 in the
            aggregate. If any PROVIDER Personnel obtains a claims-made policy to
            fulfill this requirement, then such PROVIDER Personnel will also pay
            the cost of a reporting endorsement for “tail” coverage for the
            duration of the statute of limitations for all services provided on
            behalf of Practice. Upon request, PROVIDER shall require PROVIDER
            Personnel to (a) name Practice as an additional insured on the
            professional malpractice coverage described by this section and (b)
            furnish Practice with a copy of PROVIDER’s certificates of insurance
            evidencing the coverages set forth in this section.
          </p>
          <SummarySection>
            Providers in their group must carry their own malpractice policy,
            consistent with the above limits laid out, in order to see patients
            through Headway.
          </SummarySection>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            A. PROVIDER has, and agrees to maintain during the Term of this
            Agreement, at its sole cost and expense, the following insurance
            coverages: (a) professional malpractice insurance covering PROVIDER,
            with minimum limits of $1,000,000 per incident and $3,000,000 in the
            aggregate (if PROVIDER obtains a claims-made policy to fulfill this
            requirement, then PROVIDER will also pay the cost of a reporting
            endorsement for “tail” coverage for the duration of the statute of
            limitations for all services provided on behalf of Practice); (b)
            commercial general liability coverage covering PROVIDER, with limits
            reasonably acceptable to Practice; and (c) workers’ compensation
            coverage as required by state law. Upon request, PROVIDER agrees to
            (a) name Practice as an additional insured on professional
            malpractice coverage and commercial general liability coverage
            described by this section and (b) furnish Practice with a copy of
            PROVIDER’s certificates of insurance evidencing any of the coverages
            set forth above.
          </p>
          <SummarySection>
            You’ll keep liability insurance that meets our requirements. Your
            malpractice policy covers you; our malpractice policy covers us.
          </SummarySection>
        </React.Fragment>
      )}
    </LegalSection>
    <LegalSection title="Term and Termination">
      <p>
        A. Term. This Agreement is effective on the Effective Date and shall
        continue until the services to be rendered hereunder are considered
        complete, unless earlier terminated in accordance with Sections IX(B) or
        IX(C), as the case may be (the “Term”).
      </p>
      <SummarySection>
        We hope to work with you for a long time! We are starting our
        relationship with a one-year agreement, but if things go well, the
        agreement will renew every year.
      </SummarySection>
      <p>
        B. Termination Without Cause. Practice may terminate this Agreement
        without cause upon ten (10) calendar days’ written notice to the other
        party to this Agreement. PROVIDER may terminate this Agreement without
        cause upon thirty (30) calendar days’ written notice to the other party
        to this Agreement. In the event of termination pursuant to this clause,
        Practice shall pay PROVIDER on a pro-rata basis any fees then due and
        payable for any services completed up to and including the date of such
        termination.
      </p>
      <SummarySection>
        We understand that we may go our separate ways at some point. Either one
        of us can choose to end our contractual relationship with the other on
        30 days’ notice.
      </SummarySection>
      <p>
        C. Termination for Cause. Either party may terminate this Agreement for
        cause at any time, effective upon delivery of notice of termination to
        the other party. For purposes of this Subsection IX(C), “cause” means
        the failure of the other party to comply with any material term of this
        Agreement within fifteen (15) calendar days after receipt of written
        notice that the other party is not in compliance with such material
        term. Notwithstanding anything herein to the contrary, Practice may
        immediately terminate this Agreement upon written notice to PROVIDER, in
        the event that action is taken against PROVIDER’s license, PROVIDER is
        excluded from any state or federal health care program, or PROVIDER
        engages in conduct that threatens the health or safety of Practice’s
        patients. In the event of termination pursuant to this clause, Practice
        shall pay PROVIDER on a pro-rata basis any fees then due and payable for
        any services completed up to and including the date of such termination.
      </p>
      <SummarySection>
        If we are ending our relationship because of one of us doing something
        bad, the agreement might be ended on shorter notice.
      </SummarySection>
      <p>
        D. Continuity of Care. Following any termination or expiration of this
        Agreement, PROVIDER agrees to provide continuity of care services to
        patients as required by the patient's coverage and applicable law.
        Practice will make other providers available to PROVIDER's patients, to
        the extent PROVIDER wishes to make referrals to Practice in order to
        transition patients in compliance with applicable requirements.
      </p>
      <SummarySection>
        It’s not likely to come up, but if a contract with a payer requires that
        you keep providing services to a patient for a period after you leave
        the group, you’ll keep doing so, and we’ll continue to pay you for it.
      </SummarySection>
      <p>
        E. Return of Confidential Information. Upon expiration or termination of
        this Agreement for any reason, or at any other time upon the Practice’s
        written request, PROVIDER must promptly within two (2) business days
        after such expiration or termination:
        <ol>
          <li>
            Permanently erase all of the Practice’s Confidential Information (as
            defined below) from the PROVIDER’s electronic devices, including,
            without limitation, computers, laptops, tablets, phones, and the
            like;
          </li>
          <li>
            Deliver to the Practice all tangible documents and other media,
            including any copies, containing, reflecting, incorporating, or
            based on the Confidential Information, and
          </li>
          <li>
            Certify in writing to the Practice that the PROVIDER has complied
            with the requirements of this clause.
          </li>
        </ol>
      </p>
      <SummarySection>
        If you have any of our confidential information, you'll return it to us
        once we part ways.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Confidentiality">
      <p>
        A. General. All records, files, reports and documents pertaining to
        services provided to patients of the Practice by PROVIDER hereunder or
        the Practice's business, to the extent such files, reports and documents
        would be considered by a reasonable person to be confidential or
        proprietary ("Confidential Information") shall be held in strict
        confidence by PROVIDER and shall remain the sole property of the
        Practice. PROVIDER shall not, during or after the Term, disclose the
        Practice’s Confidential Information to any other person, or entity for
        any reason or purpose whatsoever, without the written consent of the
        Practice. Notwithstanding anything herein to the contrary, PROVIDER has
        a limited license to use Confidential Information provided to it during
        the term of this Agreement for the sole purpose of providing services to
        patients of the Practice. The terms of this Section 10 survive
        termination of this Agreement for any reason.
      </p>
      <SummarySection>
        Some of the information we give you (like rates and patient information)
        is confidential. If it is, you agree that you’ll keep the information
        confidential and only use it in your practice with Headway.
      </SummarySection>
      <p>
        B. Legally Required Disclosures; Notice. Nothing herein shall be
        construed to prevent the disclosure of Confidential Information as may
        be required by applicable law or regulation, or pursuant to the valid
        order of a court of competent jurisdiction or an authorized government
        agency; provided that the disclosure does not exceed the extent of
        disclosure required by law, regulation, or order. PROVIDER agrees to
        give written notice of any such order to the Practice within two (2)
        business days of receiving such order, but in any event sufficiently in
        advance of making any disclosure to permit the Practice to contest the
        order or seek confidentiality protections, as determined in the
        Practice’s sole discretion.
      </p>
      <SummarySection>
        If you get a court or government orders to disclose our confidential
        information (e.g., subpoenas), you'll tell us in advance of the
        disclosure and cooperate with our efforts to maintain the
        confidentiality of our confidential information.
      </SummarySection>
      <p>
        C. Notice of Immunity Under the Defend Trade Secrets Act (“DTSA”).
        Notwithstanding any other provision of this Agreement:
        <ol>
          <li>
            PROVIDER will not be held criminally or civilly liable under any
            federal or state trade secret law for any disclosure of a trade
            secret that: (a) is made: (i) in confidence to a federal, state, or
            local government official, either directly or indirectly, or to an
            attorney; and (i) solely for the purpose of reporting or
            investigating a suspected violation of law; or (b) is made in a
            complaint or other document that is filed under seal in a lawsuit or
            other proceeding.
          </li>
          <li>
            If PROVIDER files a lawsuit for retaliation by the Practice for
            reporting a suspected violation of law, PROVIDER may disclose the
            Practice's trade secrets to the PROVIDER’s attorney and use the
            trade secret information in the court proceeding if PROVIDER: (a)
            files any document containing the trade secret under seal; and (b)
            does not disclose the trade secret, except pursuant to court order.
          </li>
        </ol>
      </p>
      <SummarySection>
        You can only disclose our trade secrets in very limited circumstances.
      </SummarySection>
      <p>
        D. Remedies. PROVIDER agrees that in the event of a breach of the
        confidentiality provisions of this Agreement, in addition to any other
        available remedies, an injunction, specific performance, or other
        equitable relief is available to Practice without requiring Practice to
        post bond or other security to obtain such relief.
      </p>
      <SummarySection>
        If we think you’re going to disclose our confidential information to
        someone, we can ask a court to order you not to do so.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Notices">
      <p>
        A. All notices and other communications required or permitted to be
        given to a party hereunder (each, a “Notice”) must be in writing and
        addressed to the (1) PROVIDER at the address or email address previously
        furnished to Practice; and (2) Practice at the email address in the
        Practice’s electronic portal.
      </p>
      <p>
        B. Notices shall be delivered by personal delivery, nationally
        recognized overnight courier (with all fees prepaid), certified or
        registered mail (in each case, return receipt requested postage
        prepaid), or by email. Notice is effective only if the receiving party
        has received the Notice, and the party giving Notice has complied with
        the requirements of this Section XI(A) hereof.
      </p>
      <SummarySection>
        For anything relating to our agreement with you, we’ll let you know by
        email or on our portal.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Indemnity">
      <p>
        A. Each party shall indemnify and hold the other party, its officers,
        directors, employees and agents, harmless from and against any and all
        actions, claims, demands and liabilities, and against all loss, damage,
        costs and expenses, including reasonable attorneys’ fees, arising
        directly or indirectly out of the indemnifying party’s negligence,
        willful misconduct, breach of this Agreement, or violation of applicable
        law.
      </p>
      <SummarySection>
        If we mess something up, we’ll make sure you don’t have to pay for it.
        If you mess something up, you’ll make sure we don’t have to pay for it.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Miscellaneous Provisions">
      <p>
        A. Governing Law. To the maximum extent permitted by law, this Agreement
        is construed, and the rights and liabilities of the parties hereto
        determined, according to the internal laws of, and subject exclusively
        to the jurisdiction of the courts of, the State of New York; provided,
        however, that PROVIDER's licensed health care services shall be
        regulated only by the laws of the jurisdiction(s) in which they are
        provided.
      </p>
      <SummarySection>
        This is a New York contract. If there’s a dispute, it will be tried in
        New York.
      </SummarySection>
      <p>
        B. Arbitration. Any dispute, controversy, or claim arising out of or
        related to this Agreement, the relationship of the parties, or any
        breach or termination of this Agreement, including but not limited to
        the services provided to the Practice, and any alleged violation of any
        federal, state, or local statute, regulation, common law, or public
        policy, whether sounding in contract, tort, or statute, shall be
        submitted to and decided by binding arbitration pursuant to the terms
        set forth in Attachment B.
      </p>
      <SummarySection>
        If there is a dispute under the agreement, it will be heard by an
        arbitrator, rather than a judge or a jury.
      </SummarySection>
      <p>
        C. No Third Party Beneficiaries. The parties to this Agreement have not
        entered into this Agreement for the benefit of any third party and do
        not intend that any benefits of this Agreement inure to the benefit of
        any third party.
      </p>
      <SummarySection>
        This agreement is just between you and Headway. No one else can bring a
        lawsuit under this contract.
      </SummarySection>
      <p>
        D. Entire Agreement. This Agreement constitutes the entire agreement of
        the parties hereto and supersedes and renders null and void all prior
        and contemporaneous agreements between the parties hereto with respect
        to the subject matter hereof.
      </p>
      <SummarySection>
        This agreement, rather than any side conversations, is the final word on
        our relationship.
      </SummarySection>
      <p>
        E. Amendments. No amendments or additions to this Agreement are binding
        unless set forth in a writing and signed by both parties.
        Notwithstanding the foregoing, Practice has the right to amend this
        Agreement on thirty (30) days’ written notice to PROVIDER.
      </p>
      <SummarySection>
        This agreement is subject to change, but we will tell you in advance if
        it’s going to change.
      </SummarySection>
      <p>
        F. Waiver. Waiver of breach of or default under any term or provision of
        this Agreement by either party, by course of dealing or otherwise, is
        not deemed a waiver of any other breach or default under the same or a
        different provision of this Agreement.
      </p>
      <SummarySection>
        If you violate the contract, but we tell you it’s okay, that doesn’t
        mean you can violate the contract again. The same goes for us.
      </SummarySection>
      <p>
        G. Severability. In the event any term or provision of this Agreement is
        rendered invalid or unenforceable by any valid act of Congress or a
        state legislature, or by any regulation duly promulgated by officers of
        the United States or a State regulator of competent jurisdiction acting
        in accordance with law, or is held by any court of competent
        jurisdiction to be invalid, void or unenforceable, the remaining
        provisions of this Agreement remain in full force and effect.
      </p>
      <SummarySection>
        If a court says something in this agreement is illegal, the court can
        change the contract to make it legal.
      </SummarySection>
      <p>
        H. Assignment and Binding Effect. PROVIDER may not assign this Agreement
        or delegate any of his/her duties under the Agreement.
      </p>
      <SummarySection>
        We are working with you for a reason! Only you can provide services
        under this contract.
      </SummarySection>
      <p>
        I. Counterparts. This Agreement may be executed in two counterparts,
        each of which separately shall constitute an original instrument but
        both of which together with this Agreement shall constitute one and the
        same instrument that is binding upon the parties notwithstanding that
        they each signed separate counterpart signature pages.
      </p>
      <SummarySection>
        We’re both okay with the fact that we’re not going to sign the contract
        at the same time, in the same place, on the same page.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Attachment A: Fee Schedule">
      <p>
        The below fee schedule lays out what you’ll earn for appointments
        conducted through the Practice. Payment for appointments occur. twice
        monthly, on the 15th of the month and on the last day of the month,
        anywhere between 14 and 30 days after you confirm the details of the
        appointment
      </p>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.space.base,
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          size="large"
          css={{
            textTransform: 'none',
          }}
          endIcon={<ArrowForward />}
          onClick={() => {
            setSelectedPart('rates');
          }}
        >
          See rates
        </Button>
      </div>
    </LegalSection>
    <LegalSection title="Attachment B: Arbitration Agreement">
      <p>
        This Mutual Agreement to Arbitrate Disputes (“Agreement”) is made and
        entered into as of [DATE] (the “Effective Date”) by and between Headway
        California Behavioral Health Services, P.C. (“Practice”), and [NAME]
        (the “Contractor”) (the Practice and the Contractor are collectively
        referred to herein as the “Parties”).
      </p>
      <ol>
        <li>
          Intent of Agreement. It is the intent of the Contractor and the
          Practice that this Agreement will govern the resolution of all
          disputes, claims and any other matters in question arising out of or
          relating to the Provider Independent Contractor Agreement (the
          “Contractor Agreement.”) The Parties shall resolve all disputes
          arising out of the Contractor Agreement in accordance with the
          provisions of this Agreement.
        </li>
        <li>
          Permanently erase all of the Practice’s Confidential Information (as
          defined below) from the PROVIDER’s electronic devices, including,
          without limitation, computers, laptops, tablets, phones, and the like;
        </li>
        <li>
          Mandatory Arbitration. The Practice and the Contractor agree that any
          claim, complaint, or dispute that relates in any way to the Contractor
          Agreement, the relationship of the Parties, or any breach or
          termination of this Agreement, whether based in contract, tort,
          statute, fraud, misrepresentation, misclassification, or any other
          legal theory, shall be submitted to binding arbitration administered
          by the Judicial Arbitration & Mediation Services (“JAMS”) in
          accordance with JAMS’ arbitration rules. A copy of the arbitration
          rules for JAMS are available online at:
          https://www.jamsadr.com/rules-download/. You can also call JAMS at
          800-352-5267 if you have questions about the arbitration process. If
          JAMS’ arbitration rules are inconsistent with the terms of
          this Agreement, the terms of this Agreement shall govern.
          <br />
          <br />
          The Parties understand and agree that the Practice is engaged in
          transactions involving interstate commerce. Thus, the Parties agree
          that the Federal Arbitration Act (9 U.S.C. §§ 1-16) governs the
          interpretation, enforcement, and all proceedings pursuant to this
          Agreement and the Contractor’s relationship with the Practice.
        </li>
        <li>
          Claims Not Covered. Notwithstanding the Contractor’s contractor
          relationship with the Practice, claims not covered by this Agreement
          are claims for temporary, preliminary or interim injunctive relief
          related to the period before the selection of an Arbitrator (including
          temporary restraining orders or preliminary injunctions), workers’
          compensation, unemployment compensation benefits, administrative
          charges for unfair labor practices brought before the National Labor
          Relations Board, Excluded Claims (defined in Paragraph 5 below), or
          any other claims that, as a matter of law, the Parties cannot agree to
          arbitrate. Notwithstanding the Contractor’s contractor relationship
          with the Practice, nothing in this Agreement shall be interpreted to
          mean that Practice employees are precluded from filing complaints with
          the California Department of Fair Employment and Housing and/or
          federal Equal Employment Opportunity Commission and National Labor
          Relations Board.
        </li>
        <li>
          Waiver of Class Action and Representative Action Claims. Except for
          representative claims which cannot be waived under applicable law and
          which are therefore excluded from this Agreement (“Excluded Claims”),
          the Contractor and the Practice expressly intend and agree that: (a)
          class action and representative action procedures are hereby waived
          and shall not be asserted, nor will they apply, in any arbitration
          pursuant to this Agreement; (b) each will not assert class action or
          representative action claims against the other in arbitration or
          otherwise; and (c) the Contractor and the Practice shall only submit
          their own, individual claims in arbitration and will not seek to
          represent the interests of any other person. To the extent that the
          Parties’ dispute involves both timely filed Excluded Claims and claims
          subject to this Agreement, the Parties agree to bifurcate and stay for
          the duration of the arbitration proceedings any such Excluded Claims.
        </li>
        <li>
          Waiver of Trial by Jury. The Parties understand and fully agree that
          by entering into this Agreement to arbitrate, they are giving up their
          constitutional right to have a trial by jury, and are giving up their
          normal rights of appeal following the rendering of a decision except
          as California law provides for judicial review of arbitration
          proceedings. The Parties anticipate that by entering into this
          Agreement, they will gain the benefits of a speedy and less expensive
          dispute resolution procedure.
        </li>
        <li>
          Claims Procedure. Arbitration shall be initiated upon the express
          written notice of either party. The aggrieved party must give written
          notice of any claim to the other party. Written notice of a
          Contractor’s claim shall be mailed by certified or registered mail,
          return receipt requested, to the Practice c/o General Counsel, 114 5th
          Avenue, New York, NY 10011 (“Notice Address”). Written notice of the
          Practice’s claim will be mailed to the last known address of the
          Contractor. The written notice shall identify and describe the nature
          of all claims asserted and the facts upon which such claims are based.
          Written notice of arbitration shall be initiated within the same time
          limitations that California law applies to those claim(s).
        </li>
        <li>
          Arbitrator. The Arbitrator shall be selected as provided in the JAMS
          Rules and Procedures. The Arbitrator must be a judge, retired judge,
          or attorney licensed to practice in the state where the Practice most
          recently contracted with the Contractor. Unless agreed to by the
          Parties, the Arbitrator must reside in the county in which the
          arbitration is to take place.
        </li>
        <li>
          Location of Arbitration. The Parties will conduct the arbitration,
          including the hearing if any, in the county or the city where the
          Contractor provided services under the Contractor Agreement, unless
          the Parties otherwise agree.
        </li>
        <li>
          Discovery. In arbitration, the Parties will have the right to conduct
          civil discovery and bring motions, as provided by the Federal Rules of
          Civil Procedure. The discovery process will proceed and be governed as
          follows:
          <ol type="a">
            <li>
              Parties may obtain discovery by any of the following methods:
              <ol type="i">
                <li>
                  Depositions upon oral examination, three fact witnesses and
                  one expert witness per side as of right, with more permitted
                  if leave is obtained from the Arbitrator;
                </li>
                <li>
                  Written interrogatories, up to a maximum combined total of
                  twenty (20), with the responding party having twenty (20) days
                  to respond;
                </li>
                <li>
                  Request for production of documents or things or permission to
                  enter upon land or other property for inspection, with the
                  responding party having twenty (20) days to serve a written
                  response, produce the documents and allow entry or to file
                  objections to the request. Any motion to compel production or
                  entry must be made to the Arbitrator within fifteen (15) days
                  of receipt of objections; and
                </li>
                <li>
                  Physical and mental examination, in accordance with Federal
                  Rule of Civil Procedure 35(a).
                </li>
              </ol>
            </li>
            <li>
              All discovery requests must be submitted no less than sixty (60)
              days before the arbitration hearing date.
            </li>
            <li>
              The scope of discoverable evidence will be determined in
              accordance with Federal Rule of Civil Procedure 26(b)(1).
            </li>
            <li>
              At least 30 days before the arbitration hearing, the Parties are
              to exchange lists of witnesses, including any experts, and copies
              of all exhibits intended to be used at the arbitration hearing.
            </li>
            <li>
              Each party will have the right to subpoena witnesses and documents
              for the arbitration as well as documents relevant to the case from
              third parties.
            </li>
            <li>
              The Arbitrator will have the power to enforce the aforementioned
              discovery rights and obligations by the imposition of the same
              terms, conditions, consequences, liabilities, sanctions and
              penalties as can or may be imposed in like circumstances in a
              civil action by a federal court under the Federal Rules of Civil
              Procedure, except the power to order the arrest or imprisonment of
              a person.
            </li>
          </ol>
        </li>
        <li>
          Compelling Arbitration/Enforcing Award. Either party may bring an
          action in court to compel arbitration under this Agreement or to
          otherwise determine the arbitrability of claims under this Agreement,
          and to confirm, vacate or enforce an arbitration award, and each party
          shall bear its own attorney fees and costs and other expenses of such
          action.
        </li>
        <li>
          Arbitration Fees and Costs. The Practice will be responsible for the
          arbitrator’s fees and expenses, unless applicable law allows for the
          arbitrator’s fees and expenses to be born equally. Each party shall
          pay its own costs and attorneys’ fees, if any. However, if any party
          prevails on a statutory claim which affords the prevailing party
          attorneys’ fees and costs, or if there is a written agreement
          providing for attorneys’ fees and costs, the Arbitrator may award
          reasonable attorneys’ fees and costs to the prevailing party. Any
          dispute as to the reasonableness of any fee or cost shall be resolved
          by the Arbitrator.
        </li>
        <li>
          Governing Law. This Contractor Agreement is governed by the FAA and,
          to the extent not inconsistent or preempted by the FAA, by the laws of
          the State of New York.
        </li>
        <li>
          Severability. If any provision of this Agreement to arbitrate is
          adjudged to be void or otherwise unenforceable, in whole or in part,
          the void or unenforceable provision shall be severed and such
          adjudication shall not affect the validity of the remainder of this
          Agreement to arbitrate.
        </li>
        <li>
          Voluntary Agreement. By executing this Agreement the Parties represent
          that they have been given the opportunity to fully review, comprehend
          and negotiate the terms of this Agreement. The Parties understand the
          terms of this Agreement and freely and voluntarily sign this
          Agreement.
        </li>
      </ol>
      <p>
        IN WITNESS WHEREOF, the Parties have executed this Agreement as of the
        Effective Date above.
      </p>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.space.base,
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          size="large"
          css={{
            textTransform: 'none',
          }}
          endIcon={<ArrowForward />}
          onClick={() => {
            setSelectedPart('rates');
          }}
        >
          See rates
        </Button>
      </div>
    </LegalSection>
    <PrivatePayProviderAddendum />
  </div>
);
