import { axios } from './axios.config';

const missingBaseUrlInterceptor = axios.interceptors.request.use(
  () => {
    throw new Error(
      'No baseUrl set for axios. Must call init() to set baseUrl. See @headway/api/index.ts'
    );
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function configureMambaUrl(url: string) {
  axios.interceptors.request.eject(missingBaseUrlInterceptor);
  axios.defaults.baseURL = url;
}

export { axios };
