import React from 'react';

import { Banner } from '@headway/helix/Banner';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';

interface BankAccountBannerProps {
  bannerOffset: number;
}

export const BankAccountBanner = ({ bannerOffset }: BankAccountBannerProps) => {
  return (
    <div
      css={{
        position: 'fixed',
        background: theme.color.hue.lightSystemRed,
        padding: `0 ${theme.spacing.x1}`,
        height: 50,
        top: 0 + bannerOffset,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 17,
        color: theme.color.system.black,
      }}
    >
      <Banner
        adornment={
          <Link href={`/settings/billing`}>
            Connect your bank account in Settings
          </Link>
        }
        variant="warning"
      >
        You won’t be able to receive payment for sessions until you connect your
        bank information
      </Banner>
    </div>
  );
};
