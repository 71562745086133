import { css, cx } from '@emotion/css';
import React from 'react';
import { mergeProps, useFocusRing, useHover, useLink } from 'react-aria';

import { Badge } from '@headway/helix/Badge';
import { IconCircleCheck } from '@headway/helix/icons/CircleCheck';
import { IconError } from '@headway/helix/icons/Error';
import { theme } from '@headway/helix/theme';

import { StepState } from './IntakeNavigation';

type IntakeNavigationItemProps = {
  text: string;
  active?: boolean;
  disabled?: boolean;
  stepState?: StepState;
  showState?: boolean;
  onPress: () => void;
};

export function IntakeNavigationItem({
  text,
  stepState,
  showState,
  disabled,
  active,
  onPress,
}: IntakeNavigationItemProps) {
  let ref = React.useRef<HTMLButtonElement>(null);
  const { isFocused, isFocusVisible, focusProps } = useFocusRing();
  const { hoverProps, isHovered } = useHover({ isDisabled: disabled });
  let { linkProps } = useLink(
    {
      isDisabled: disabled,
      elementType: 'button',
      onPress,
    },
    ref
  );

  const showStepState = !disabled && showState;
  return (
    <button
      {...mergeProps(linkProps, focusProps, hoverProps)}
      className={cx(navItemStyle, {
        [activeStyle]: active,
        [disabledStyle]: disabled,
        [focusRingStyle]: isFocused && isFocusVisible,
        [hoverStyle]: isHovered,
      })}
      ref={ref}
    >
      <span className={cx(navTitleStyle)}>{text}</span>
      {stepState === StepState.VALID && showStepState && (
        <IconCircleCheck
          aria-label="valid"
          css={{ color: theme.color.primary.brandGreen }}
        />
      )}
      {stepState === StepState.INVALID && showStepState && (
        <IconError
          aria-label="error"
          css={{ color: theme.color.primary.red }}
        />
      )}
      {stepState === StepState.PENDING && showStepState && (
        <Badge variant="warning">Verifying...</Badge>
      )}
    </button>
  );
}

const navTitleStyle = css({
  maxWidth: '85%',
});

const navItemStyle = css({
  padding: '11px 20px',
  border: 0,
  backgroundColor: theme.color.system.white,
  color: theme.color.system.textBlack,
  textAlign: 'left',
  ...theme.typography.body.regular,

  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  width: '100%',
  justifyContent: 'space-between',
});

const activeStyle = css({
  backgroundColor: theme.color.system.lightGray,
  color: theme.color.system.textBlack,
  ...theme.typography.body.medium,
});

const hoverStyle = css({
  backgroundColor: theme.color.system.backgroundGray,
  cursor: 'pointer',
});

const disabledStyle = css({
  pointerEvents: 'none',
  backgroundColor: theme.color.system.white,
  color: theme.color.system.disabledGray,
});

const focusRingStyle = css({
  outline: `2px solid ${theme.color.system.green} !important`,
  outlineOffset: '-1px',
  backgroundColor: theme.color.system.lightGray,
});
