import { CardContent, Divider } from '@mui/material';
import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { UserApi } from '@headway/api/resources/UserApi';
import benRobbinsPicture from '@headway/shared/assets/img/advisors/ben.robbins.jpg';
import elisabethHillPicture from '@headway/shared/assets/img/advisors/elisabeth.hill.jpg';
import sigmundPicture from '@headway/shared/assets/img/product/sigmund.png';
import { REFERRAL_BONUS_AMOUNT } from '@headway/shared/constants/referrals';
import { EXTOLE_REFERRAL_PROGRAM } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useMutation, useQuery } from '@headway/shared/react-query';
import { notifyError } from '@headway/ui/utils/notify';

import { Button } from '../Button';
import { useExtoleEmbed } from '../hooks/extole';
import {
  Card,
  CardTitle,
  CopyBody,
  CopyCaption,
  CopyTitle,
  HeroSection,
  ListItem,
  OrderedList,
  Section,
  SectionContainer,
  SectionPair,
  Slider,
  YoutubeVideo,
} from '../landing';
import { theme } from '../theme';

const advisorProfiles = [
  {
    photoSrc: benRobbinsPicture,
    name: `Dr. Ben Robbins`,
    bio: `Dr. Ben Robbins is a psychiatrist, scientist, and healthcare investor at Google Ventures. He completed psychiatric training at Massachusetts General Hospital and McLean Hospital, the top and second-ranked departments of psychiatry in the US. Ben received his M.D. from Harvard Medical School, M.B.A. from Harvard Business School, and B.A. with honors from Dartmouth College.`,
  },
  {
    photoSrc: elisabethHillPicture,
    name: `Dr. Elisabeth Hill`,
    bio: `Dr. Elisabeth Hill graduated from Yale University and worked for McKinsey & Company as a Business Analyst prior to attending medical school at the University of Pennsylvania. She is currently a senior resident at the Massachusetts General Hospital / McLean Hospital Adult Psychiatry Residency Program.`,
  },
];

interface ReferProviderProps {
  AuthStore: any;
  imgComponent?: React.FC<React.PropsWithChildren<any>> | 'img';
}

const isUserRegistered = (user?: UserRead): boolean => {
  return !!user?.email;
};

export const ReferProvider = ({
  AuthStore,
  imgComponent: ImgComponent = 'img',
}: ReferProviderProps) => {
  const user = AuthStore.user;

  const isExtoleEnabled = useFlag(EXTOLE_REFERRAL_PROGRAM, false);

  const referralLinkCode = user.referral_link_code
    ? user.referral_link_code
    : user.referralLinkCode;
  const referralPortalQuery = useQuery(
    ['referralPortal', user.id],
    () => UserApi.getReferralPortal(user.id),
    {
      enabled: isExtoleEnabled ? isUserRegistered(user) : !!referralLinkCode,
    }
  );

  const createReferralLinkMutation = useMutation(
    () => UserApi.createReferralLink(user.id),
    {
      onError() {
        notifyError('Error joining referral program');
      },
    }
  );

  const referralPortalUrl = referralPortalQuery.data?.fullEmbedUrl;
  const referralLinkLoading = createReferralLinkMutation.isLoading;

  if (isExtoleEnabled) {
    return (
      <div className="bg-system-white isolate min-h-screen w-full pt-[50px]">
        {isUserRegistered(user) && referralPortalQuery.data?.accessToken ? (
          <ExtoleReferralPortal token={referralPortalQuery.data.accessToken} />
        ) : !isUserRegistered(user) ? (
          <ExtoleReferralPortal token={undefined} />
        ) : null}
      </div>
    );
  }

  return (
    <React.Fragment>
      <SectionContainer>
        <HeroSection centered={true}>
          {referralLinkCode ? (
            <>
              <CopyTitle>Your Referrals</CopyTitle>
              <iframe
                src={referralPortalUrl}
                frameBorder="0"
                style={{
                  backgroundColor: 'white',
                  height: 800,
                  width: '100%',
                }}
              />
            </>
          ) : user.email ? (
            <>
              <CopyTitle>
                Refer a provider, get ${REFERRAL_BONUS_AMOUNT}.
              </CopyTitle>
              <CopyBody>
                Join our referral program to start referring providers to
                Headway. We'll send you ${REFERRAL_BONUS_AMOUNT} after each
                provider you refer joins.
              </CopyBody>{' '}
              <Button
                css={{
                  margin: '0 auto',
                }}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  createReferralLinkMutation.mutate();
                }}
                disabled={referralLinkLoading}
              >
                Join referral program
              </Button>
              <a
                href="https://findheadway.zendesk.com/hc/en-us/articles/4405522641428-Provider-referral-bonus-program"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </>
          ) : (
            <>
              <CopyTitle>
                Refer a provider, get ${REFERRAL_BONUS_AMOUNT}.
              </CopyTitle>
              <CopyBody>
                We'll send you ${REFERRAL_BONUS_AMOUNT} after each provider you
                refer joins Headway. Create an account or login with Headway to
                manage your provider referrals.
              </CopyBody>
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={AuthStore.openSignupModal}
                  css={{ marginRight: theme.space.sm }}
                >
                  Create account
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={AuthStore.openLoginModal}
                >
                  Login
                </Button>
              </div>
            </>
          )}
        </HeroSection>
      </SectionContainer>
      <SectionContainer>
        <SectionPair reverse={true}>
          <Section>
            <CopyCaption>Getting started</CopyCaption>
            <CopyTitle>How it works</CopyTitle>
            <CopyBody>
              <OrderedList>
                <ListItem>
                  <p>
                    <strong>Learn more</strong>
                  </p>
                  Your referral will speak with one of our Practice Consultants
                  to see if their practice is a good fit for Headway.
                </ListItem>
                <ListItem>
                  <p>
                    <strong>Onboard</strong>
                  </p>
                  Your referral will fill out a form online to provide the basic
                  info we need to get you up and running with Headway.
                </ListItem>
                <ListItem>
                  <p>
                    <strong>See clients through Headway</strong>
                  </p>
                  Within 4 – 8 weeks, your referral can officially use Headway
                  to see clients.
                </ListItem>
                <a
                  href="https://findheadway.zendesk.com/hc/en-us/articles/4405522641428-Provider-referral-bonus-program"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more about the referral program
                </a>
              </OrderedList>
            </CopyBody>
          </Section>
          <Section>
            <ImgComponent
              // in Patient the sigmundPicture import is StaticImageData which
              // can't be assigned to an img src. All usage in Patient is expected
              // to pass an imgComponent that knows how to handle StaticImageData.
              // @ts-ignore
              src={sigmundPicture}
              alt="Headway's provider technology products"
              css={{ width: '100%' }}
            />
          </Section>
        </SectionPair>
      </SectionContainer>
      <SectionContainer>
        <Section centered={true}>
          <CopyTitle>What providers are saying</CopyTitle>
          <Slider
            css={{ marginTop: theme.space.xl2 }}
            aria-label="testimonial carousel"
          >
            <Card css={{ width: '100%' }}>
              <CardContent>
                <CardTitle>{'Psychiatrists & PMHNPs'}</CardTitle>
              </CardContent>
              <YoutubeVideo videoId="LeVb1YkepsM" title="Abbey, NP" />
            </Card>
            <Card css={{ width: '100%' }}>
              <CardContent>
                <CardTitle>Out-of-network solo practices</CardTitle>
              </CardContent>
              <YoutubeVideo videoId="ZhmI0z7I8Js" title="Sheina, LMHC" />
            </Card>
            <Card css={{ width: '100%' }}>
              <CardContent>
                <CardTitle>Out-of-network group practices</CardTitle>
              </CardContent>
              <YoutubeVideo videoId="QdUURmyxbFo" title="Pete, Group Owner" />
            </Card>
            <Card css={{ width: '100%' }}>
              <CardContent>
                <CardTitle>In-network solo practices</CardTitle>
              </CardContent>
              <YoutubeVideo videoId="TY98VsSWnAM" title="Adriana, LCSW" />
            </Card>
            <Card css={{ width: '100%' }}>
              <CardContent>
                <CardTitle>In-network group practices</CardTitle>
              </CardContent>
              <YoutubeVideo videoId="L3J-vFe3wRQ" title="Mike, Group Owner" />
            </Card>
          </Slider>
        </Section>
      </SectionContainer>
      <Divider />
      <SectionContainer>
        <CopyTitle css={{ textAlign: 'center', marginBottom: theme.space.xl2 }}>
          Meet our Advisors
        </CopyTitle>
        <SectionPair>
          <Section>
            <div
              css={{
                height: '100%',
                paddingRight: theme.space.base,
                paddingLeft: theme.space.base,
                textAlign: 'center',
              }}
            >
              <ImgComponent
                // in Patient the sigmundPicture import is StaticImageData which
                // can't be assigned to an img src. All usage in Patient is expected
                // to pass an imgComponent that knows how to handle StaticImageData.
                // @ts-ignore
                src={advisorProfiles[0].photoSrc}
                alt={advisorProfiles[0].name}
                css={{
                  width: 240,
                  height: 240,
                  borderRadius: '50%',
                  margin: '0 auto',
                }}
              />
              <div
                css={{
                  fontFamily: theme.fontFamily.canelaText,
                  fontSize: theme.fontSize.xl2,
                  textAlign: 'center',
                  paddingTop: theme.space.sm,
                  paddingBottom: theme.space.sm,
                }}
              >
                {advisorProfiles[0].name}
              </div>
              <CopyBody>{advisorProfiles[0].bio}</CopyBody>
            </div>
          </Section>
          <Section>
            <div
              css={{
                height: '100%',
                paddingRight: theme.space.base,
                paddingLeft: theme.space.base,
                textAlign: 'center',
              }}
            >
              <ImgComponent
                // in Patient the sigmundPicture import is StaticImageData which
                // can't be assigned to an img src. All usage in Patient is expected
                // to pass an imgComponent that knows how to handle StaticImageData.
                // @ts-ignore
                src={advisorProfiles[1].photoSrc}
                alt={advisorProfiles[1].name}
                css={{
                  width: 240,
                  height: 240,
                  borderRadius: '50%',
                  margin: '0 auto',
                }}
              />
              <div
                css={{
                  fontFamily: theme.fontFamily.canelaText,
                  fontSize: theme.fontSize.xl2,
                  textAlign: 'center',
                  paddingTop: theme.space.sm,
                  paddingBottom: theme.space.sm,
                }}
              >
                {advisorProfiles[1].name}
              </div>
              <CopyBody>{advisorProfiles[1].bio}</CopyBody>
            </div>
          </Section>
        </SectionPair>
      </SectionContainer>
    </React.Fragment>
  );
};

interface ExtoleReferralPortalProps {
  token: string | undefined;
}

function ExtoleReferralPortal({ token }: ExtoleReferralPortalProps) {
  const ref = useExtoleEmbed(token);

  return <div ref={ref} />;
}
