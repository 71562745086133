import { PatientAddressType } from '@headway/api/models/PatientAddressType';

export const addressTypeDisplayNames: {
  [key in PatientAddressType]: string;
} = {
  [PatientAddressType.HOME]: 'Home',
  [PatientAddressType.WORK]: 'Work',
  [PatientAddressType.SCHOOL]: 'School',
  [PatientAddressType.COMMUNITY]: 'Community',
  [PatientAddressType.OTHER]: 'Other',
};
