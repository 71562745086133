import { flatten, maxBy, minBy } from 'lodash';
import React from 'react';

import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderFrontEndCarrierContext } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';
import { ProviderTaskContext } from '@headway/ui/providers/ProviderTaskProvider';

const getMinEstimatedLiveDate = (
  estimatedLiveDateMap: EstimatedLiveDatesWithCarrierMap
) => {
  if (Object.keys(estimatedLiveDateMap).length) {
    const allValues = flatten(Object.values(estimatedLiveDateMap));
    return minBy(allValues, (e) => e.estimatedLiveDate.getTime());
  }
  return undefined;
};
const getMaxEstimatedLiveDate = (
  estimatedLiveDateMap: EstimatedLiveDatesWithCarrierMap
) => {
  if (Object.keys(estimatedLiveDateMap).length) {
    const allValues = flatten(Object.values(estimatedLiveDateMap));
    return maxBy(allValues, (e) => e.estimatedLiveDate.getTime());
  }
  return undefined;
};

export interface EstimatedLiveDateWithCarrier {
  estimatedLiveDate: Date;
  providerFrontEndCarrier: ProviderFrontEndCarrierRead;
}

export interface EstimatedLiveDatesWithCarrierMap {
  // key is frontEndCarrierId.toString()
  [key: string]: EstimatedLiveDateWithCarrier[];
}

export interface LiveDateData {
  estimatedLiveDatesLoading: boolean;
  estimatedLiveDates: EstimatedLiveDatesWithCarrierMap;
  minEstimatedLiveDate?: EstimatedLiveDateWithCarrier;
  maxEstimatedLiveDate?: EstimatedLiveDateWithCarrier;
  providerFrontendCarriers: ProviderFrontEndCarrierRead[];
}

interface LiveDateCalculatorProps {
  children: (liveDates: LiveDateData) => React.ReactElement;
}

export const LiveDateCalculator: React.FC<LiveDateCalculatorProps> = ({
  children,
}) => {
  const providerFrontEndCarrierContext = React.useContext(
    ProviderFrontEndCarrierContext
  );
  const providerTaskContext = React.useContext(ProviderTaskContext);
  const estimatedLiveDates = React.useMemo(() => {
    const estimatedLiveDatesWithCarrierMap: EstimatedLiveDatesWithCarrierMap =
      {};
    providerFrontEndCarrierContext.providerFrontEndCarriers.forEach((pfec) => {
      if (pfec.estimatedAppointmentReadyDate) {
        const fecIdKey = pfec.frontEndCarrierId.toString();
        const estimatedLiveDateWithCarrier: EstimatedLiveDateWithCarrier = {
          estimatedLiveDate: new Date(pfec.estimatedAppointmentReadyDate),
          providerFrontEndCarrier: pfec,
        };
        if (estimatedLiveDatesWithCarrierMap[fecIdKey]) {
          estimatedLiveDatesWithCarrierMap[fecIdKey].push(
            estimatedLiveDateWithCarrier
          );
        } else {
          estimatedLiveDatesWithCarrierMap[fecIdKey] = [
            estimatedLiveDateWithCarrier,
          ];
        }
      }
    });
    return estimatedLiveDatesWithCarrierMap;
  }, [providerFrontEndCarrierContext]);
  const minEstimatedLiveDate = React.useMemo(
    () => getMinEstimatedLiveDate(estimatedLiveDates),
    [estimatedLiveDates]
  );
  const maxEstimatedLiveDate = React.useMemo(
    () => getMaxEstimatedLiveDate(estimatedLiveDates),
    [estimatedLiveDates]
  );
  const estimatedLiveDatesLoading =
    providerFrontEndCarrierContext.isLoading || providerTaskContext.isLoading;
  return children({
    estimatedLiveDatesLoading,
    estimatedLiveDates,
    minEstimatedLiveDate,
    maxEstimatedLiveDate,
    providerFrontendCarriers:
      providerFrontEndCarrierContext.providerFrontEndCarriers,
  });
};
