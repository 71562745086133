import { uniqBy } from 'lodash';
import zip from 'lodash/zip';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { useMatchingProviderFrontEndCarrierBatchQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierBatchQuery';

export const useMatchingProviderFrontendCarriersForEvents = (
  providerId: number,
  providerEvents: ProviderEventRead[]
) => {
  // because of recurring events, we can have the same event id (and patient id and appointment id) multiple times
  const deduplicatedEventIdPatientIdAppointmentId = uniqBy(
    providerEvents
      .filter((event) => !!event.id && !!event.patientUserId)
      .map((event) => ({
        eventId: event.id!,
        patientId: event.patientUserId!,
        appointmentId: event.providerAppointment?.id,
      })),
    (eventData) =>
      `${eventData.eventId}-${eventData.patientId}-${eventData.appointmentId}`
  );
  const { data: matchingProviderFrontEndCarriers, isLoading } =
    useMatchingProviderFrontEndCarrierBatchQuery(
      {
        providerId: providerId,
        patientRequests: deduplicatedEventIdPatientIdAppointmentId.map(
          (eventData) => ({
            patientUserId: eventData.patientId,
            appointmentId: eventData.appointmentId,
          })
        ),
      },
      { enabled: deduplicatedEventIdPatientIdAppointmentId.length > 0 }
    );
  const eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  > = matchingProviderFrontEndCarriers
    ? new Map(
        zip(
          deduplicatedEventIdPatientIdAppointmentId.map((e) => e.eventId),
          matchingProviderFrontEndCarriers
        )
      )
    : new Map();

  return {
    eventIdsToMatchingProviderFrontEndCarriers,
    isLoading,
  };
};
