import styled from '@emotion/styled';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps as MuiAccordionProps,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import React, { FC } from 'react';

import { theme } from './theme';

interface Props {
  noBorder?: boolean;
}

export const Accordion: FC<
  React.PropsWithChildren<Props & MuiAccordionProps>
> = ({ noBorder = false, TransitionProps, ...rest }) => {
  const accordionNode = React.useRef<HTMLDivElement>(null);
  return (
    <MuiAccordion
      ref={accordionNode}
      sx={{
        boxShadow: 'unset',
        border: noBorder ? 'none' : `1px solid ${theme.color.border}`,
        borderRadius: noBorder ? '0' : '4px',
        '::before': {
          display: 'none',
        },
      }}
      TransitionProps={{
        onEntered: () => {
          if (accordionNode?.current) {
            accordionNode.current.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest',
            });
          }
        },
        ...TransitionProps,
      }}
      {...rest}
    />
  );
};

export const AccordionSummary = MuiAccordionSummary;

export const AccordionDetails: typeof MuiAccordionDetails = (props) => (
  <MuiAccordionDetails
    sx={{
      borderTop: `1px solid ${theme.color.border}`,
      display: 'flex',
      flexDirection: 'column',
    }}
    {...props}
  />
);

export const AccordionDetailsHeader = styled.h6({
  fontFamily: theme.fontFamily.postGrotesk,
  fontSize: theme.fontSize.lg,
  marginBottom: theme.space.xs,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.color.textGray,
});
