import React from 'react';

export function IconUpload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.375 13.4792V5.75001L6.875 8.25001L5.97917 7.35418L10 3.33334L14.0208 7.35418L13.125 8.25001L10.625 5.75001V13.4792H9.375ZM3.33334 16.6667V12.4375H4.58334V15.4167H15.4167V12.4375H16.6667V16.6667H3.33334Z"
        fill="currentColor"
      />
    </svg>
  );
}
