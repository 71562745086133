import React from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { Badge } from '@headway/helix/Badge';

import { ASSESSMENT_TYPES_USING_SUBSCORES } from '../helpers/constants';
import { AssessmentBadgeInfo } from '../helpers/types';
import {
  getAssessmentScoreDescriptor,
  getBadgeVariantForAssessmentScoreDiff,
  getResultBadgeDataUsingSubscores,
  getScoreBadgeDataUsingSubscores,
  getScoreRepresentationForAssessmentType,
  getVariantForAssessmentResult,
} from '../helpers/utils';

interface ScoreDiffBadgeProps {
  assessmentType: PatientAssessmentType;
  prevScore: number;
  currentScore: number;
  // Text which prefixes the score if provided
  prefix?: string;
  // Text which suffixes the score if provided
  suffix?: string;
}

/** Badge which displays the difference between two assessment scores. */
export const ScoreDiffBadge = ({
  assessmentType,
  prevScore,
  currentScore,
  prefix,
}: ScoreDiffBadgeProps) => {
  const diff =
    getScoreRepresentationForAssessmentType(currentScore, assessmentType) -
    getScoreRepresentationForAssessmentType(prevScore, assessmentType);
  const diffRepresentation = getScoreRepresentationForAssessmentType(
    diff,
    assessmentType
  );
  return (
    <Badge
      variant={getBadgeVariantForAssessmentScoreDiff(
        assessmentType,
        diffRepresentation
      )}
    >
      {prefix ? `${prefix}: ` : ''}
      {diffRepresentation === 0
        ? 'No change'
        : diffRepresentation > 0
        ? `↑ ${diffRepresentation}`
        : `↓ ${-diffRepresentation}`}
    </Badge>
  );
};

interface ResultAndScoreAssessmentBadgeProps {
  assessment: AssessmentBadgeInfo;
}

/**
 * Displays badge for assessment score in assessment table.
 */
export const ScoreBadge = ({
  assessment,
}: ResultAndScoreAssessmentBadgeProps) => {
  const { assessmentType, score } = assessment;
  if (score == null) {
    return null;
  }
  return ASSESSMENT_TYPES_USING_SUBSCORES.includes(assessmentType) ? (
    <Badge
      variant={
        getScoreBadgeDataUsingSubscores(assessment)?.badgeVariant ?? 'neutral'
      }
    >
      {getScoreBadgeDataUsingSubscores(assessment)?.badgeText}
    </Badge>
  ) : (
    <Badge variant={getVariantForAssessmentResult(assessmentType, score)}>
      {getScoreRepresentationForAssessmentType(score, assessmentType)}
    </Badge>
  );
};

/**
 * Displays badge(s) for assessment score in assessment table and line chart.
 */
export const ResultBadges = ({
  assessment,
}: ResultAndScoreAssessmentBadgeProps) => {
  const { assessmentType, score } = assessment;
  return ASSESSMENT_TYPES_USING_SUBSCORES.includes(assessmentType) ? (
    <div className="flex flex-row gap-x-1">
      <ResultBadgesUsingSubscores assessment={assessment} />
    </div>
  ) : score != null ? (
    <div className="flex gap-1">
      <Badge variant={getVariantForAssessmentResult(assessmentType, score)}>
        {getAssessmentScoreDescriptor(assessmentType, score)}
      </Badge>
    </div>
  ) : assessmentType === PatientAssessmentType.ANCHOR ? (
    <div className="flex gap-1">
      <Badge variant="neutral">No result</Badge>
    </div>
  ) : null;
};

interface ResultBadgesUsingSubscoresProps {
  assessment: AssessmentBadgeInfo;
}

/**
 * Displays badges for assessments that use subscores to determine insights.
 */
const ResultBadgesUsingSubscores = ({
  assessment,
}: ResultBadgesUsingSubscoresProps) => {
  const resultBadgeData = getResultBadgeDataUsingSubscores(assessment);
  if (resultBadgeData == null) {
    return null;
  }
  return (
    <div className="flex flex-col gap-y-1">
      {resultBadgeData.map((data) => {
        return (
          <React.Fragment key={data.badgeText}>
            <Badge variant={data.badgeVariant}>{data.badgeText}</Badge>
          </React.Fragment>
        );
      })}
    </div>
  );
};
