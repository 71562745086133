import { css } from '@emotion/react';
import React, { useCallback, useState } from 'react';

import { Button } from '@headway/helix/Button';
import { IconButton } from '@headway/helix/IconButton';
import {
  SplitButton,
  SplitButtonMenu,
  SplitButtonMenuItem,
} from '@headway/helix/SplitButton';
import { theme } from '@headway/helix/theme';

import {
  BuilderError,
  BuilderErrors,
  OnSaveData,
} from '../../schema/schema.types';
import { runValidationOnForm } from '../../validate';
import { DeleteIcon } from '../assets/icons/Delete';
import { PeopleIcon } from '../assets/icons/People';
import { PreviewIcon } from '../assets/icons/Preview';
import { BuilderState } from '../BuilderEmbedded';
import { RendererContext } from '../RendererContext';
import { convertBlocksToSchema } from '../utils';
import { BuilderErrorDisplay } from './BuilderErrorDisplay';

const BuilderMenuDeleteIcon = ({
  setBuilderState,
  builderState,
}: {
  setBuilderState: (state: BuilderState) => void;
  builderState: BuilderState;
}) => (
  <IconButton
    size="medium"
    onPress={() =>
      setBuilderState({
        ...builderState,
        showDeleteModal: !builderState.showDeleteModal,
      })
    }
  >
    <DeleteIcon />
  </IconButton>
);

const BuilderMenuPreviewIcon = ({
  setBuilderState,
  builderState,
}: {
  setBuilderState: (state: BuilderState) => void;
  builderState: BuilderState;
}) => (
  <IconButton
    size="medium"
    onPress={() =>
      setBuilderState({
        ...builderState,
        showPreview: !builderState.showPreview,
      })
    }
  >
    <PreviewIcon />
  </IconButton>
);

const BuilderMenuUserGroupIcon = ({
  setBuilderState,
  builderState,
}: {
  setBuilderState: (state: BuilderState) => void;
  builderState: BuilderState;
}) => (
  <IconButton
    size="medium"
    onPress={() =>
      setBuilderState({
        ...builderState,
        showInjectedData: !builderState.showInjectedData,
      })
    }
  >
    <PeopleIcon />
  </IconButton>
);

export const BuilderMenu = ({
  editor,
  builderState,
  setBuilderState,
  onSave,
  onSaveCTAString,
  hasPreview,
}: {
  editor: EditorJS.default | undefined;
  builderState: BuilderState;
  setBuilderState: (state: BuilderState) => void;
  onSave: (onSaveData: OnSaveData) => void;
  onSaveCTAString?: string;
  hasPreview?: boolean;
}) => {
  const [errors, setErrors] = useState<BuilderError[]>([]);
  const onEditorOption = useCallback(
    async (key: 'save' | 'viewLayoutTree' | 'editFormState') => {
      if (!editor) {
        return;
      }

      const saveData = await editor.save();
      const form = convertBlocksToSchema(
        RendererContext.layoutTree,
        saveData.blocks
      );

      switch (key) {
        case 'save':
          const errors: BuilderErrors = runValidationOnForm(form);
          if (errors.allErrors.length > 0) {
            setErrors(errors.allErrors);
          } else {
            setErrors([]);
          }

          const isValid = errors.allErrors.length === 0;

          onSave({
            form: {
              form,
              state: RendererContext.state,
            },
            injectedData: RendererContext.injectedData,
            isValid,
            errors,
          });

          break;
        case 'viewLayoutTree':
          setBuilderState({
            showEditPanel: true,
            editPanelView: 'layoutTree',
          });
          break;
        case 'editFormState':
          setBuilderState({
            showEditPanel: true,
            editPanelView: 'formState',
          });
          break;
      }
    },
    [editor, RendererContext.layoutTree, onSave]
  );

  return (
    <div css={editorCss.header}>
      <div
        css={{
          width: '300px',
          height: '40px',
          marginLeft: '10px',
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          alignContent: 'center',
        }}
      >
        <BuilderMenuDeleteIcon
          setBuilderState={setBuilderState}
          builderState={builderState}
        />
        {hasPreview && (
          <BuilderMenuPreviewIcon
            setBuilderState={setBuilderState}
            builderState={builderState}
          />
        )}
        <BuilderMenuUserGroupIcon
          setBuilderState={setBuilderState}
          builderState={builderState}
        />
        <BuilderErrorDisplay errors={errors} />
      </div>
      <div css={editorCss.headerButtons}>
        <SplitButton variant="primary">
          <Button size="medium" onPress={() => onEditorOption('save')}>
            {onSaveCTAString ? onSaveCTAString : 'Save'}
          </Button>
          <SplitButtonMenu>
            <SplitButtonMenuItem
              onAction={() => onEditorOption('viewLayoutTree')}
            >
              View layout tree
            </SplitButtonMenuItem>
            <SplitButtonMenuItem
              onAction={() => onEditorOption('editFormState')}
            >
              Edit form state
            </SplitButtonMenuItem>
          </SplitButtonMenu>
        </SplitButton>
      </div>
    </div>
  );
};

const editorCss = {
  header: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #e8e8eb',
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    borderTop: '1px dotted #e8e8eb',
    borderRight: '1px solid #e8e8eb',
    paddingTop: '12px',
    boxShadow: '0 4px 2px -2px #80808014',
  }),
  headerButtons: css({
    marginRight: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.x2,
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
};
