import { CheckCircle } from '@mui/icons-material';
import { Formik } from 'formik';
import { useProvider } from 'hooks';
import { keyBy } from 'lodash';
import React, { useContext } from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { PatientFormSchemaRead } from '@headway/api/models/PatientFormSchemaRead';
import { PatientFormSchemaType } from '@headway/api/models/PatientFormSchemaType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientFormApi } from '@headway/api/resources/PatientFormApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { useMutation, useQuery } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { LogoLoader } from '@headway/ui/LogoLoader';

import { usePatientFormSchemas } from 'hooks/usePatientFormSchema';
import { useProviderHasSentAssessments } from 'hooks/useProviderHasSentAssessments';

import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';

export type InviteClientSetUpFormValues = {
  headwayInviteEmail: boolean;
  sendAll: boolean;
  [key: string]: boolean;
};

export interface InviteClientSetupProps {
  client: UserRead;
  providerPatient: ProviderPatientRead;
}

export const InviteClientSetup = ({
  client,
  providerPatient,
}: InviteClientSetupProps) => {
  const provider = useProvider();

  React.useEffect(() => {
    trackPageView({
      name: `Autopay Billing Model Enabled Banner Viewed`,
      properties: {
        providerId: provider.id,
        patientUserId: client.id,
        renderingLocation: 'Provider add client flow',
      },
    });
  }, [client.id, provider.id]);
  const { setCurrentStep } = useContext(AddPatientModalContext);

  React.useEffect(() => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Invite client to setup their Headway account',
        stepName: 'SEND_ACCOUNT_INVITE',
      },
    });
  }, []);

  const [didOpenAddPracticePolicyLink, setDidOpenAddPracticePolicyLink] =
    React.useState(false);
  const { data: patientFormSchemas, isLoading: isPatientFormSchemasLoading } =
    usePatientFormSchemas(
      { providerId: provider.id },
      {
        enabled: true,
        // if the user clicks the "Add your practice policies to Headway" button, we want to refetch the patient form schemas
        // at an interval of 5 seconds to check if the practice policies have been added.
        // We also refetch on window focus to check if the practice policies have been added some other way.
        refetchOnWindowFocus: true,
        refetchInterval: (data) => {
          const policy = data?.find(
            (schema) =>
              schema.formType === PatientFormSchemaType.PRACTICE_POLICIES
          );

          if (policy) {
            return false;
          }

          if (didOpenAddPracticePolicyLink) {
            return 5000;
          }

          return false;
        },
      }
    );

  const { data: providerHasSentAssessments } = useProviderHasSentAssessments(
    { providerId: provider.id },
    {
      refetchOnWindowFocus: false,
    }
  );

  const formSchemasByType = keyBy(patientFormSchemas, 'formType');

  const doesProviderHavePracticePolicy =
    !!formSchemasByType[PatientFormSchemaType.PRACTICE_POLICIES];

  const appointmentReadinessQuery = useQuery(
    ['appointment-readiness', client.id],
    () => UserApi.getUserAppointmentReadiness(client.id),
    {
      retry: false,
    }
  );

  const sendInvitationMutation = useMutation(
    async (sendIntakeForms: boolean) => {
      await ProviderPatientApi.sendProviderPatientAccountInvite(
        providerPatient.id,
        { sendIntakeForms: sendIntakeForms }
      );
    },
    {
      onSuccess: async () => {
        await UserApi.updateUser(client.id, { isInvited: true });
      },
    }
  );

  const getSelectedForms = (values: InviteClientSetUpFormValues) => {
    const { headwayInviteEmail, sendAll, ...clientIntakeFormValues } = values;

    const selectedPatientFormSchemas: PatientFormSchemaRead[] = [];
    const patientFormSchemaIds: number[] = [];
    for (const intakeForm in clientIntakeFormValues) {
      if (values[intakeForm]) {
        const form = patientFormSchemas?.find(
          (schema: PatientFormSchemaRead) => {
            return schema.formType === intakeForm;
          }
        );

        if (form) {
          selectedPatientFormSchemas.push(form);
          patientFormSchemaIds.push(form.id);
        }
      }
    }

    return {
      selectedPatientFormSchemas,
      patientFormSchemaIds,
    };
  };

  // if provider has not used PROMS product, render a product announcement modal
  // else take provider directly to send assessments modal
  const setNextStepOfAddPatient = () => {
    return providerHasSentAssessments
      ? setCurrentStep(AddPatientModalPage.SEND_ASSESSMENTS)
      : setCurrentStep(AddPatientModalPage.ASSESSMENTS_PRODUCT_ANNOUNCEMENT);
  };

  const firstName = formatFirstName(client);
  const handleInviteClientIntakeForms = async (
    values: InviteClientSetUpFormValues
  ) => {
    const isAppointmentReady = appointmentReadinessQuery.data?.isReady;

    const message = !isAppointmentReady
      ? `${firstName} has been invited to set up their account`
      : `${firstName} has been added as a client`;

    const { selectedPatientFormSchemas, patientFormSchemaIds } =
      getSelectedForms(values);

    const hasInformedConsentChecked = !!selectedPatientFormSchemas.find(
      (form) => {
        return form?.formType === PatientFormSchemaType.INFORMED_CONSENT;
      }
    );
    const hasBiopsychosocialChecked = !!selectedPatientFormSchemas.find(
      (form) => {
        return form?.formType === PatientFormSchemaType.BIOPSYCHOSOCIAL;
      }
    );

    const hasPracticePolicyChecked = !!selectedPatientFormSchemas.find(
      (form) => {
        return form?.formType === PatientFormSchemaType.PRACTICE_POLICIES;
      }
    );

    const sendIntake =
      values.sendAll ||
      hasInformedConsentChecked ||
      hasBiopsychosocialChecked ||
      hasPracticePolicyChecked;

    const sendOnlyInvitation = values.headwayInviteEmail && !sendIntake;
    const sendOnlyIntake = !values.headwayInviteEmail && sendIntake;
    const sendInvitationAndIntake = values.headwayInviteEmail && sendIntake;

    // create intake forms
    if (patientFormSchemaIds.length > 0) {
      await PatientFormApi.createPatientFormsBulk({
        providerPatientId: providerPatient.id,
        patientFormSchemaIds: patientFormSchemaIds,
      });
    }

    if (sendOnlyInvitation || sendInvitationAndIntake) {
      try {
        await sendInvitationMutation.mutateAsync(!!sendInvitationAndIntake);
        toasts.add(message);
      } catch (e) {
        toasts.add(`Failed to send invitation to ${firstName}`, {
          variant: 'negative',
        });
        logException(e);
      }
    } else if (sendOnlyIntake) {
      try {
        await PatientFormApi.sendIntakeFormEmailToPatient(providerPatient.id);
        toasts.add(`Intake tasks sent to ${firstName}`);
      } catch (e) {
        toasts.add(`Failed to send intake tasks to ${firstName}`, {
          variant: 'negative',
        });
        logException(e);
      }
    } else {
      const message = !isAppointmentReady
        ? `${firstName} has been added as a client. Some additional setup tasks are required.`
        : `${firstName} has been added as a client`;
      toasts.add(message);
    }

    trackEvent({
      name: 'Individual New Client Invite Email Done Button Clicked',
      properties: {
        sendEmailFlag: values.headwayInviteEmail === true,
      },
    });
    trackEvent({
      name: 'Send Intake Form Button Clicked',
      properties: {
        providerId: provider.id,
        providerPatientId: providerPatient.id,
      },
    });
    setNextStepOfAddPatient();
  };

  const onInviteClientSetupSkip = () => setNextStepOfAddPatient();

  let intakeFormInitialValues: { [key: string]: boolean } = {};
  if (!isPatientFormSchemasLoading) {
    intakeFormInitialValues = (patientFormSchemas ?? []).reduce(
      (accum: any, schema: PatientFormSchemaRead) => {
        accum[schema.formType] = false;
        return accum;
      },
      {} as { [key: string]: boolean }
    );

    intakeFormInitialValues[PatientFormSchemaType.PRACTICE_POLICIES] =
      intakeFormInitialValues[PatientFormSchemaType.PRACTICE_POLICIES] ?? false;
  }

  const initialValues: InviteClientSetUpFormValues = {
    headwayInviteEmail: true,
    sendAll: false,
    ...intakeFormInitialValues,
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount={true}
      onSubmit={handleInviteClientIntakeForms}
      initialValues={initialValues}
    >
      {({ values, setFieldValue, isSubmitting, submitForm }) => {
        const { headwayInviteEmail, sendAll, ...clientIntakeFormValues } =
          values;

        const checkAllIntakeForm = (toggleValue: boolean) => {
          const valueKeys = Object.keys(values);

          valueKeys.forEach((key) => {
            if (key !== 'headwayInviteEmail') {
              setFieldValue(key, toggleValue);
            }
          });
        };

        return isPatientFormSchemasLoading ? (
          <div className="mt-8 flex flex-col items-center gap-8">
            <SectionHeader>Saving...</SectionHeader>
            <div>
              <LogoLoader />
            </div>
          </div>
        ) : (
          <>
            <ModalContent>
              <SafeFormikForm
                style={{
                  gap: 0,
                  height: '100%',
                }}
              >
                <PageSection>
                  <div>
                    {providerPatient.billingTypeDefault ===
                      BillingType.INSURANCE &&
                      client.activeUserInsurance?.latestEligibilityLookup
                        ?.isClaimReady &&
                      firstName && (
                        <div
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginBottom: theme.spacing.x6,
                          }}
                        >
                          <CheckCircle
                            css={{
                              color: theme.color.system.green,
                              marginRight: theme.spacing.x1,
                            }}
                          />
                          <strong>{`${firstName}'s insurance has been verified.`}</strong>
                        </div>
                      )}

                    <div>
                      <SectionHeader>
                        Invite {firstName} to setup their Headway account
                      </SectionHeader>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          marginTop: theme.spacing.x6,
                          marginBottom: theme.spacing.x1,
                          gap: theme.spacing.x2,
                        }}
                      >
                        <BodyText>
                          <strong>Send {firstName} an invitation email</strong>
                        </BodyText>
                        <Badge variant="positive">Recommended</Badge>
                      </div>
                      <BodyText>
                        This email will prompt your client to set up their
                        Headway account and complete required tasks like
                        agreeing to{' '}
                        <Link
                          href={`${process.env.REACT_APP_MAIN_URL}/legal/privacy-practices`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </Link>{' '}
                        and{' '}
                        <Link
                          href={`${process.env.REACT_APP_MAIN_URL}/legal/responsibility`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Consent Forms
                        </Link>
                        .
                      </BodyText>
                    </div>
                    <div
                      css={{
                        marginTop: theme.spacing.x2,
                      }}
                    >
                      <FormControl
                        component={Checkbox}
                        disabled={false}
                        name="headwayInviteEmail"
                        aria-labelledby="invitation-email-opt-in"
                      >
                        <span>
                          <span id="invitation-email-opt-in">
                            <strong>Headway invitation email</strong>
                          </span>{' '}
                          <Link
                            href={`/intake-forms/invitation-email-preview/${providerPatient.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-describedby="invitation-email-opt-in"
                          >
                            Preview
                          </Link>
                        </span>
                      </FormControl>
                    </div>
                    {patientFormSchemas && (
                      <div css={{ marginTop: theme.spacing.x6 }}>
                        <div
                          css={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginBottom: theme.spacing.x1,
                          }}
                        >
                          <div css={{ marginRight: theme.spacing.x2 }}>
                            <strong>Send {firstName} intake forms</strong>
                          </div>
                          <Badge variant="neutral">Optional</Badge>
                        </div>
                        <div>
                          <BodyText>
                            You may also send some common intake forms used by
                            most providers.
                          </BodyText>
                          <div
                            css={{
                              marginTop: theme.spacing.x2,
                              display: 'flex',
                              flexDirection: 'column',
                              gap: theme.spacing.x2,
                              '& .hlx-checkbox-root:not(:first-child)': {
                                marginLeft: theme.spacing.x8,
                              },
                            }}
                          >
                            <FormControl
                              component={Checkbox}
                              disabled={false}
                              name="sendAll"
                              onChange={checkAllIntakeForm}
                              checked={Object.values(
                                clientIntakeFormValues
                              ).every((isSelected) => isSelected)}
                            >
                              Send all intake forms below
                            </FormControl>

                            {patientFormSchemas.map((form) => {
                              return (
                                <FormControl
                                  component={Checkbox}
                                  name={form.formType}
                                  key={form.id}
                                >
                                  <span>
                                    {form.name}{' '}
                                    <Link
                                      href={`/intake-forms/${form.id}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Preview
                                    </Link>
                                  </span>
                                </FormControl>
                              );
                            })}

                            {!doesProviderHavePracticePolicy && (
                              <FormControl
                                component={Checkbox}
                                name={PatientFormSchemaType.PRACTICE_POLICIES}
                              >
                                <span>
                                  Practice policies (Add your practice policies
                                  in the next step)
                                </span>
                              </FormControl>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {!!values.PRACTICE_POLICIES &&
                      !doesProviderHavePracticePolicy && (
                        <div
                          css={{
                            marginTop: theme.spacing.x6,
                          }}
                        >
                          <BodyText>
                            <strong>
                              Last step: Add your practice policies to Headway
                            </strong>
                          </BodyText>
                          <div css={{ marginTop: theme.spacing.x1 }}>
                            <BodyText>
                              Once you added your practice policies, return to
                              this page to continue to the next step.
                            </BodyText>
                          </div>
                          <div
                            css={{
                              marginTop: theme.spacing.x6,
                            }}
                          >
                            <LinkButton
                              href="/settings/practice-policies"
                              target="_blank"
                              variant="primary"
                              onClick={() => {
                                setDidOpenAddPracticePolicyLink(true);
                                trackEvent({
                                  name: 'Add Practice Policy Button Clicked',
                                  properties: {
                                    providerId: provider.id,
                                  },
                                });
                              }}
                            >
                              Add policies to Headway
                            </LinkButton>
                          </div>
                        </div>
                      )}
                  </div>
                </PageSection>
              </SafeFormikForm>
            </ModalContent>
            <ModalFooter>
              <Button variant="secondary" onPress={onInviteClientSetupSkip}>
                Skip
              </Button>
              <Button
                variant="primary"
                disabled={
                  (!doesProviderHavePracticePolicy &&
                    !!values.PRACTICE_POLICIES) ||
                  isSubmitting
                }
                onPress={submitForm}
              >
                {isSubmitting ? 'Sending...' : 'Continue'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
