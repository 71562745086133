import { FormikProps } from 'formik';
import React from 'react';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Item, Select } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { CAQH_WEBSITE_URL } from '../../utils/intakeQuestionnaireConstants';
import { getLicenseVerificationInstructionForState } from '../../utils/LicenseStateSuggestions';
import {
  getLicenseKey,
  getSelectedLicense,
  getUniqueLicenses,
} from './LicenseStepHelper';

export const LicenseSelectSection = ({
  formikHelpers,
  state,
}: {
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>;
  state: UnitedStates;
}) => {
  const caqhLicenses = formikHelpers.values.caqhLicenses;
  const uniqueLicenses = getUniqueLicenses(caqhLicenses);
  const stateLicenses = uniqueLicenses.filter(
    (license) => license.licenseState === state
  );
  const selectedLicense = getSelectedLicense(state, formikHelpers);
  const getSelectedKeys = () => {
    if (!selectedLicense) {
      return [];
    }

    return [getLicenseKey(selectedLicense)];
  };
  const stateSpecificInstruction = getLicenseVerificationInstructionForState(
    selectedLicense?.licenseState as UnitedStates
  );
  return (
    <div>
      <b>Select a {statesToDisplayNames[state]} license to use on Headway.</b>
      <FormControl
        aria-label="License Select"
        component={Select}
        menuWidth="stretch"
        name={`licenseSelectForm[${state}]`}
        placeholder="Select a license"
        selectedKeys={getSelectedKeys()}
        selectionMode="single"
        onSelectionChange={(keys: Set<string>) => {
          const key = keys.values().next().value;
          formikHelpers.setFieldValue(`licenseSelectForm[${state}]`, key);
          if (key === undefined) {
            formikHelpers.setFieldValue(`selectedLicenses[${state}]`, null);
          } else {
            stateLicenses?.forEach((license) => {
              if (getLicenseKey(license) === key) {
                // This needs to be reset since the license passed in doesn't have this override
                license.requiresSupervision = '';
                formikHelpers.setFieldValue(
                  `selectedLicenses[${state}]`,
                  license
                );
              }
            });
          }
        }}
      >
        {stateLicenses?.map((license) => (
          <Item key={getLicenseKey(license)}>
            {`License number: ${license.licenseNumber}`}
          </Item>
        ))}
      </FormControl>
      {stateSpecificInstruction && (
        <div
          css={{
            marginTop: theme.spacing.x2,
          }}
        >
          <GuidanceCard variant="info">
            <div
              css={{
                ...theme.stack.vertical,
                gap: theme.spacing.x2,
              }}
            >
              {stateSpecificInstruction}

              <Link href={CAQH_WEBSITE_URL} target="_blank" rel="noreferrer">
                <b>If needed, update in CAQH</b>
              </Link>
            </div>
          </GuidanceCard>
        </div>
      )}
    </div>
  );
};
