import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import React, { useContext } from 'react';

import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProviderDocumentAuditResultType } from '@headway/api/models/ProviderDocumentAuditResultType';
import { ProviderDocumentRemediationCommunicationType } from '@headway/api/models/ProviderDocumentRemediationCommunicationType';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { ListHeader } from '@headway/helix/ListHeader';
import { theme } from '@headway/helix/theme';
import { Warning } from '@headway/icons/dist/Warning';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';

import { useProviderPaymentPause } from 'hooks/useProviderPaymentPause';
import { getAuditResult } from 'views/Home/utils';

import { ProgressNoteContext } from './stores/ProgressNotesContext';

const capitalizeFirstLetterOnly = (str: string) => {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const FailedAuditResultGuidanceCard = ({
  isProviderInPausePayments,
}: {
  isProviderInPausePayments: boolean;
}) => {
  const failedAuditResultPaymentText = isProviderInPausePayments ? (
    <span>
      Your note didn't pass Headway's final review,{' '}
      <strong>so we won't complete this payment.</strong> That said, we've
      listed the items that were still missing from the note below, and you'll
      have a chance to address these in future notes and earn payments for those
      sessions.
    </span>
  ) : (
    <span>
      While your note didn’t pass Headway’s final review,{' '}
      <strong>we’ll still pay you for the session</strong>, as we appreciate
      your effort and collaboration. We’ve listed the items that were still
      missing from the note below. You’ll have a chance to address these in
      future notes.
    </span>
  );

  return (
    <GuidanceCard variant="warning">
      <BodyText>
        <div>{failedAuditResultPaymentText}</div>
        <div css={{ marginTop: theme.spacing.x6 }}>
          In the meantime, you can review requirements for{' '}
          <Link
            href="https://help.headway.co/hc/en-us/articles/24735863229844-Writing-compliant-documentation-with-Headway-s-templates#h_01HT66S5XQT7W7NF11VA6QQWEF"
            target="_blank"
          >
            a 100% compliant note
          </Link>
          , or sign up for{' '}
          <Link
            href="https://scheduler.zoom.us/jessica-belvin/coding-and-documentation-group-q-a-w-jessica-cpc-cpma"
            target="_blank"
          >
            clinical office hours
          </Link>
          .
        </div>
      </BodyText>
    </GuidanceCard>
  );
};

const PassedAuditResultGuidanceCard = () => {
  return (
    <GuidanceCard
      variant="compliance"
      title="Your note passed Headway’s review"
    >
      <BodyText>
        Thanks for working with us to meet the requirements. We’ll pay you on
        schedule for this session, even if the claim is rejected. Need more
        support? Review examples of how to ensure{' '}
        <Link
          href="https://help.headway.co/hc/en-us/articles/24735863229844-Writing-compliant-documentation-with-Headway-s-templates#h_01HT66S5XQT7W7NF11VA6QQWEF"
          target="_blank"
        >
          100% compliant notes.
        </Link>
      </BodyText>
    </GuidanceCard>
  );
};

export const ProgressNotesComplianceInfo = () => {
  const { documentRemediation } = useContext(ProgressNoteContext);
  const { carriersById } = useFrontEndCarriers();
  const provider = useProvider();

  const isPausedPaymentsEnabled: boolean = useFlag('pausedPayments', false);
  const { data: activeProviderPaymentPauses } = useProviderPaymentPause({
    providerId: provider.id,
    activeOnly: true,
  });
  const isProviderInPausePayments =
    isPausedPaymentsEnabled &&
    activeProviderPaymentPauses &&
    activeProviderPaymentPauses?.length > 0;

  const auditResultPerRemediation = getAuditResult(documentRemediation);

  const auditResultType = auditResultPerRemediation?.type;
  const needsFeedbackReview =
    documentRemediation?.status === DocumentRemediationStatus.NEEDS_REVIEW;
  const hasAuditResults =
    (documentRemediation?.status ===
      DocumentRemediationStatus.SUBMITTED_WITHOUT_ADDENDUM ||
      documentRemediation?.status ===
        DocumentRemediationStatus.SUBMITTED_WITH_ADDENDUM) &&
    auditResultType;

  if (!(needsFeedbackReview || hasAuditResults)) {
    return null;
  }

  const getFrontEndCarrierName = () => {
    if (
      documentRemediation?.communicationType ===
      ProviderDocumentRemediationCommunicationType.MEDICARE_AND_MEDICAID
    ) {
      return 'Medicare & Medicaid';
    }

    if (!documentRemediation?.frontEndCarrierId) {
      return 'an insurer';
    }

    const frontEndCarrierName =
      carriersById[documentRemediation?.frontEndCarrierId].name;
    return frontEndCarrierName.includes('Anthem')
      ? 'Anthem'
      : frontEndCarrierName;
  };

  const getPaymentText = () => {
    if (isProviderInPausePayments) {
      return (
        <span>
          <strong>
            Please note: the payment for session will be on hold until your note
            passes review.
          </strong>
        </span>
      );
    }

    return (
      <span>
        That said, if you continue to address your feedback on time,{' '}
        <strong>
          we'll keep paying you on your normal schedule, even if we don't get
          paid by {getFrontEndCarrierName()}.
        </strong>
      </span>
    );
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {auditResultType === ProviderDocumentAuditResultType.FAILED ? (
        <FailedAuditResultGuidanceCard
          isProviderInPausePayments={!!isProviderInPausePayments}
        />
      ) : auditResultType === ProviderDocumentAuditResultType.PASSED ? (
        <PassedAuditResultGuidanceCard />
      ) : (
        <GuidanceCard
          variant="compliance"
          title={`Let's work together to meet ${getFrontEndCarrierName()}'s requirements`}
        >
          <BodyText>
            A dedicated, specialized team at Headway identified the following
            unmet {getFrontEndCarrierName()} requirements on your documentation.
            We only share your documentation as allowed or required by HIPAA,
            according to the HIPAA "minimum necessary" rule. We're also working
            closely with {getFrontEndCarrierName()} to make sure we're asking
            only for what's required by their standards.
            {documentRemediation.communicationType !==
              ProviderDocumentRemediationCommunicationType.MEDICARE_AND_MEDICAID && (
              <div css={{ marginTop: theme.spacing.x5 }}>
                Your clients may notice a delay in their claims being processed.{' '}
                {getPaymentText()}
              </div>
            )}
          </BodyText>
        </GuidanceCard>
      )}

      <div css={{ padding: theme.spacing.x5 }}>
        {!(auditResultType === ProviderDocumentAuditResultType.PASSED) && (
          <>
            <h3 css={{ marginTop: 0 }}>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing.x2,
                }}
              >
                <Warning color="#FF9800" />
                <ListHeader>
                  {auditResultType === ProviderDocumentAuditResultType.FAILED
                    ? 'Requirements still missing:'
                    : 'Unmet requirements:'}
                </ListHeader>
              </div>
            </h3>
            <ol css={listStyles}>
              {auditResultType === ProviderDocumentAuditResultType.FAILED ? (
                <>
                  {auditResultPerRemediation?.resultReasons?.map(
                    (item, idx: number) => {
                      const items = item.trim().split(':');
                      if (items.length > 1) {
                        return (
                          <li key={idx}>
                            <BodyText>
                              <strong>
                                {capitalizeFirstLetterOnly(
                                  item.trim().split(':')[0]
                                )}
                                :
                              </strong>{' '}
                              {capitalizeFirstLetterOnly(
                                item.trim().split(':')[1].trim()
                              )}
                            </BodyText>
                          </li>
                        );
                      } else {
                        return (
                          <li key={idx}>
                            <BodyText>
                              {capitalizeFirstLetterOnly(item.trim())}
                            </BodyText>
                          </li>
                        );
                      }
                    }
                  )}
                </>
              ) : (
                <>
                  {documentRemediation?.remediations?.map(
                    (item, idx: number) => {
                      const items = item.trim().split(':');
                      if (items.length > 1) {
                        return (
                          <li key={idx}>
                            <BodyText>
                              <strong>
                                {capitalizeFirstLetterOnly(
                                  item.trim().split(':')[0]
                                )}
                                :
                              </strong>{' '}
                              {capitalizeFirstLetterOnly(
                                item.trim().split(':')[1].trim()
                              )}
                            </BodyText>
                          </li>
                        );
                      } else {
                        return (
                          <li key={idx}>
                            <BodyText>
                              {capitalizeFirstLetterOnly(item.trim())}
                            </BodyText>
                          </li>
                        );
                      }
                    }
                  )}
                </>
              )}
            </ol>
            {!auditResultType && (
              <BodyText>
                <Link
                  href="https://help.headway.co/hc/en-us/articles/24735863229844-What-good-looks-like"
                  target="_blank"
                >
                  See examples of how to update this information
                </Link>{' '}
                to make your note 100% compliant.
              </BodyText>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const listStyles = css`
  li::marker {
    font-weight: bold;
  }
  padding-left: ${theme.spacing.x5};
  margin: ${theme.spacing.x5} 0 ${theme.spacing.x5} 0;
`;
