import { theme } from '@headway/helix/theme';

import { PayerTimelineItemStatus } from './PayerTimelineItemStatus';
import { PayerTimelineStatusIndicator } from './PayerTimelineStatusIndicator';

interface PayerTimelineItemProps {
  status: PayerTimelineItemStatus;
  title: string;
  body: string;
  detail?: string | JSX.Element;
  bigTitle?: boolean;
  drawLine?: boolean;
}

export function PayerTimelineItem({
  status,
  title,
  body,
  detail,
  bigTitle = false,
  drawLine = true,
}: PayerTimelineItemProps) {
  return (
    <div css={{ width: drawLine ? '40%' : 'auto' }}>
      <div css={{ marginBottom: theme.spacing.x5 }}>
        <PayerTimelineStatusIndicator status={status} />
        {drawLine && (
          <div
            aria-hidden={true}
            css={{
              position: 'relative',
              zIndex: 0,
              bottom: '18px',
              borderBottom: `1px solid ${theme.color.system.borderGray}`,
            }}
          />
        )}
      </div>
      <div
        css={{
          ...(bigTitle
            ? { ...theme.typography.body.medium, marginRight: theme.spacing.x3 }
            : {
                ...theme.typography.subbody.medium,
                marginBottom: theme.spacing.x1,
              }),
        }}
      >
        {title}
      </div>
      <div
        css={{
          ...theme.typography.subbody.regular,
          marginBottom: theme.spacing.x5,
        }}
      >
        {body}
      </div>
      {detail && (
        <div
          css={{
            ...theme.typography.subbody.regular,
          }}
        >
          {detail}
        </div>
      )}
    </div>
  );
}
