import { shuffle } from 'lodash';
import React from 'react';
import * as Yup from 'yup';

import { StyleTag } from '@headway/api/models/StyleTag';
import { getStyleTagProperties } from '@headway/shared/utils/providerStyleTag';
import {
  FieldAutocomplete,
  FieldControl,
  FieldErrorText,
} from '@headway/ui/form';

type Props = {
  fieldName: string;
  noLabel?: boolean;
};

export const styleTagsValidator = Yup.array(
  Yup.mixed<StyleTag>()
    .oneOf(Object.values(StyleTag))
    .required('Style attribute is required.')
)
  .min(3, 'Please choose three style attributes to best reflect your style.')
  .max(3, 'Three style attributes are allowed to best reflect your style.');

export const StyleTagsInput: React.FC<React.PropsWithChildren<Props>> = ({
  fieldName,
  noLabel,
  ...props
}) => {
  // we memoize the randomization so the order of the tags doesn't change
  // every time the component rerenders
  const randomizedOptions = React.useMemo(
    () => shuffle(Object.values(StyleTag)),
    []
  );

  return (
    <FieldControl name={fieldName} key="styleTags" noMargin>
      <FieldAutocomplete
        label={!noLabel && 'Style attributes'}
        multiple
        options={randomizedOptions}
        getOptionLabel={(styleTag: StyleTag) => {
          const { name } = getStyleTagProperties(styleTag);

          return name;
        }}
        {...props}
      />
      <FieldErrorText />
    </FieldControl>
  );
};
