import { css } from '@emotion/react';
import { Alert, AlertTitle, TablePagination } from '@mui/material';
import { Skeleton } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MambaApiError } from '@headway/api/axios.config';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { UserUploadApi } from '@headway/api/resources/UserUploadApi';
import { Button } from '@headway/helix/Button';
import { ListHeader } from '@headway/helix/ListHeader';
import { Item, Section, Select } from '@headway/helix/Select';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { downloadFile } from '@headway/shared/utils/download';
import SkeletonTableRow from '@headway/ui/SkeletonTableRow';
import { theme } from '@headway/ui/theme';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';

import { PaymentsDownloadErrorModal } from './PaymentsDownloadErrorModal';
import { PaymentSummaryRow } from './PaymentSummaryRow';

const DEFAULT_PAGE_SIZE = 10;

type PaymentsDownloadOptions = { [key: string]: string };

export default function HistoryTab() {
  const provider = useProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthStore();

  const query = queryString.parse(location.search);
  const page = Number(query.page) || 0;
  const pageSize = Number(query.pageSize) || DEFAULT_PAGE_SIZE;

  const [paymentsDownloadOptionKey, setPaymentsDownloadOptionKey] =
    useState<string>('0');
  const [paymentsDownloadOptions, setPaymentsDownloadOptions] =
    useState<PaymentsDownloadOptions>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [
    isPaymentsDownloadErrorModalOpen,
    setIsPaymentsDownloadErrorModalOpen,
  ] = useState<boolean>(false);
  const now = moment().format(moment.HTML5_FMT.DATE);
  const [summaryStartDate, setSummaryStartDate] = useState<string>();
  const [summaryEndDate, setSummaryEndDate] = useState<string>(now);

  const paymentsDownloadsFlagEnabled = useFlag('providerPaymentsDownloads');

  const {
    isLoading: paymentSummariesLoading,
    data: paymentSummaries,
    error: paymentSummariesError,
  } = useQuery(
    [
      'paymentSummaryHistory',
      provider.id,
      summaryStartDate,
      summaryEndDate,
      { page, pageSize, summaryStartDate, summaryEndDate },
    ],
    () =>
      ProviderApi.getPaymentSummariesForProvider(provider.id, {
        offset: pageSize * page,
        limit: pageSize,
        date_start: summaryStartDate,
        date_end: summaryEndDate,
      }),
    { keepPreviousData: true }
  );

  const {
    isLoading: paymentsDownloadOptionsLoading,
    data: paymentsDownloadOptionsData,
    error: paymentsDownloadOptionsError,
  } = useQuery(
    ['paymentsDownloadOptions', provider.id],
    () => ProviderApi.getPaymentsDownloadOptions(provider.id),
    {
      enabled: paymentsDownloadsFlagEnabled,
      keepPreviousData: true,
    }
  );

  async function handlePaymentsDownload() {
    if (paymentsDownloadOptions) {
      let downloadStartDate: string | undefined;
      let downloadEndDate: string | undefined;
      let toggleValueSelection: string;

      if (paymentsDownloadOptionKey === '0') {
        downloadStartDate = undefined;
        downloadEndDate = undefined;
        toggleValueSelection = 'All transactions';
      } else {
        const downloadYear = paymentsDownloadOptions[
          paymentsDownloadOptionKey
        ].substring(0, 4);
        downloadStartDate = `${downloadYear}-01-01`;
        downloadEndDate = `${downloadYear}-12-31`;
        toggleValueSelection = downloadYear;
      }

      trackEvent({
        name: 'Download Invoice CSV Button Clicked',
        properties: {
          individualPayment: false,
          providerId: provider.id,
          toggleValueSelection: toggleValueSelection,
          userId: user?.id,
        },
      });

      setIsDownloading(true);
      try {
        const providerPaymentsDownload =
          await ProviderApi.downloadPaymentsForProvider(provider.id, {
            provider_name: provider.name,
            start_date: downloadStartDate,
            end_date: downloadEndDate,
          });
        if (providerPaymentsDownload.s3ObjectKey) {
          const downloadUrl =
            await UserUploadApi.generatePresignedGetUrlForObjectKey({
              object_key: providerPaymentsDownload.s3ObjectKey,
            });
          downloadFile(
            {
              link: downloadUrl.signedUrl,
              name: providerPaymentsDownload.filename,
            },
            false
          );
        }
      } catch (error) {
        if (error instanceof MambaApiError) {
          if (error.response?.status === 413) {
            setIsPaymentsDownloadErrorModalOpen(true);

            trackEvent({
              name: 'Payment Download Export Too Large Modal Viewed',
              properties: {
                providerId: provider.id,
                toggleValueSelection: toggleValueSelection,
                userId: user?.id,
              },
            });
          }
        }
      }
      setIsDownloading(false);
    }
  }

  function handlePaymentsDownloadErrorModalDismiss() {
    setIsPaymentsDownloadErrorModalOpen(false);
  }

  function handlePageChange(_: unknown, newPage: number) {
    navigate({
      pathname: location.pathname,
      search: `?page=${newPage}&pageSize=${pageSize}`,
    });
  }

  function handleRowsPerPageChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    navigate({
      pathname: location.pathname,
      search: `?page=0&pageSize=${e.target.value}`,
    });
  }

  useEffect(() => {
    if (paymentsDownloadOptionsData) {
      const options: PaymentsDownloadOptions = {};
      for (let i = 0; i < paymentsDownloadOptionsData.years.length; i++) {
        options[
          `${i + 1}`
        ] = `${paymentsDownloadOptionsData.years[i]} (Jan-Dec)`;
      }
      setPaymentsDownloadOptions(options);
    }
  }, [(paymentsDownloadOptionsData?.years ?? []).length]);

  return (
    <div>
      <div css={historyTabCss.titleContainer}>
        <div css={historyTabCss.title}>
          <ListHeader>Payment history</ListHeader>
        </div>
        {paymentsDownloadsFlagEnabled &&
          (paymentSummariesLoading ||
          !paymentSummaries ||
          paymentsDownloadOptionsLoading ||
          !paymentsDownloadOptions ? (
            <Skeleton variant="rectangular" height={61.5} />
          ) : (
            !paymentSummariesError &&
            paymentSummaries &&
            paymentSummaries.totalCount !== 0 &&
            !paymentsDownloadOptionsError &&
            paymentsDownloadOptions && (
              <div css={historyTabCss.selectionContainer}>
                <div css={historyTabCss.selectionTitle}>Time period</div>
                <div css={historyTabCss.selectAndExportContainer}>
                  <div css={historyTabCss.selectDropdown}>
                    <Select
                      name="selectPaymentsDownloadOption"
                      selectionMode="single"
                      aria-label="payments download time period selection"
                      selectedKeys={paymentsDownloadOptionKey}
                      menuWidth="medium"
                      onSelectionChange={([newPaymentsDownloadOptionKey]) => {
                        if (newPaymentsDownloadOptionKey) {
                          setPaymentsDownloadOptionKey(
                            newPaymentsDownloadOptionKey
                          );
                          if (newPaymentsDownloadOptionKey === '0') {
                            setSummaryStartDate(undefined);
                            setSummaryEndDate(now);
                          } else {
                            const selectedYear = paymentsDownloadOptions[
                              newPaymentsDownloadOptionKey
                            ].substring(0, 4);
                            setSummaryStartDate(`${selectedYear}-01-01`);
                            setSummaryEndDate(`${selectedYear}-12-31`);
                          }
                        }
                      }}
                    >
                      <Section>
                        <Item key={'0'} textValue={'All transactions'}>
                          {'All transactions'}
                        </Item>
                      </Section>
                      <Section>
                        {Object.entries(paymentsDownloadOptions).map(
                          ([key, value]) => (
                            <Item key={key} textValue={value}>
                              {value}
                            </Item>
                          )
                        )}
                      </Section>
                    </Select>
                  </div>
                  <div css={historyTabCss.exportButton}>
                    <Button
                      variant="primary"
                      size="large"
                      aria-label="export payments button"
                      onPress={handlePaymentsDownload}
                      disabled={isDownloading}
                    >
                      Export payments
                    </Button>
                  </div>
                </div>
              </div>
            )
          ))}
      </div>
      <TableContainer>
        <Table aria-labelledby="payments-history-title">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <VisuallyHidden>Actions</VisuallyHidden>
              </TableCell>
              <TableCell style={historyTabCss.subHeader}>
                Payment date
              </TableCell>
              <TableCell style={historyTabCss.subHeader}>Description</TableCell>
              <TableCell style={historyTabCss.subHeader} align="right">
                Total amount
              </TableCell>
            </TableRow>
          </TableHead>
          {!paymentSummariesError && (
            <TableBody>
              {paymentSummariesLoading ? (
                <>
                  <SkeletonTableRow numColumns={3} hasCheckboxColumn />
                  <SkeletonTableRow numColumns={3} hasCheckboxColumn />
                  <SkeletonTableRow numColumns={3} hasCheckboxColumn />
                  <SkeletonTableRow numColumns={3} hasCheckboxColumn />
                </>
              ) : paymentSummaries ? (
                paymentSummaries.data.map((s) => (
                  <PaymentSummaryRow key={s.paymentDate} summary={s} />
                ))
              ) : null}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {paymentsDownloadsFlagEnabled && (
        <PaymentsDownloadErrorModal
          isModalOpen={isPaymentsDownloadErrorModalOpen}
          allTransactionsRequested={paymentsDownloadOptionKey === '0'}
          onDismiss={handlePaymentsDownloadErrorModalDismiss}
        />
      )}
      {!paymentSummariesError &&
        paymentSummaries &&
        paymentSummaries.totalCount !== 0 && (
          <TablePagination
            component="div"
            css={{
              '& p': {
                margin: 0,
              },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            count={paymentSummaries.totalCount}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      {!paymentSummariesLoading &&
        !paymentSummariesError &&
        paymentSummaries &&
        paymentSummaries.totalCount === 0 && (
          <div
            css={{
              margin: `${theme.space.xl3} auto`,
              textAlign: 'center',
              maxWidth: '30rem',
            }}
          >
            No historic payments to display. You will see historic payments here
            after your first pay period once you start confirming sessions.
          </div>
        )}
      {paymentSummariesError && (
        <div css={{ margin: `${theme.space.xl4} auto`, maxWidth: '50rem' }}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We can't load your payment history. Refresh the page to see if this
            is an ongoing issue.
          </Alert>
        </div>
      )}
    </div>
  );
}

const historyTabCss = {
  titleContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.space.xs,
    width: '100%',
  }),
  title: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingLeft: theme.space.sm,
  }),
  selectionContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  selectionTitle: css({
    fontSize: theme.fontSize.sm,
    fontWeight: theme.fontWeight.bold,
  }),
  selectAndExportContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  }),
  selectDropdown: css({
    minWidth: theme.space.xl10,
  }),
  exportButton: css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.space.xs,
  }),
  subHeader: {
    fontWeight: 'bold',
    paddingBottom: theme.space.xs,
  },
};
