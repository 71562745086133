import React, { useState } from 'react';
import Script from 'react-load-script';
import * as Yup from 'yup';

import { Modal } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { LocationFilter } from '../LocationFilter';
import { AddressFormManual } from './AddressFormManual';
import { AddressFormWithMap } from './AddressFormWithMap';

export const AddressSchema = Yup.object().shape({
  city: Yup.string().required('city is required.'),
  state: Yup.string().required('State is required.'),
  streetLine1: Yup.string().required('Street line 1 is required.'),
  streetLine2: Yup.string().nullable().default(null),
  isActive: Yup.boolean().required('isActive is required.'),
  zipCode: Yup.string().required('zipCode is required.'),
});

const AutocompleteForm = ({ handleLocationSelect, google, providerStates }) => {
  return (
    <div css={{ marginBottom: theme.space.base }}>
      <LocationFilter
        handleLocationSelect={handleLocationSelect}
        google={google}
        providerStates={providerStates}
        placeType={'address'}
      />
    </div>
  );
};

const Forms = ({
  handleLocationSelect,
  selectedAddress,
  google,
  saveAddress,
  isManualEditing,
  setIsManualEditing,
  providerStates,
  addressFormWithMapProps = {},
}) => {
  return (
    <>
      {!isManualEditing ? (
        <>
          <AutocompleteForm
            handleLocationSelect={handleLocationSelect}
            google={google}
            providerStates={providerStates}
          />
          <AddressFormWithMap
            handleLocationSelect={handleLocationSelect}
            selectedAddress={selectedAddress}
            saveAddress={saveAddress}
            setIsManualEditing={setIsManualEditing}
            {...addressFormWithMapProps}
          />
        </>
      ) : (
        <AddressFormManual
          selectedAddress={selectedAddress}
          saveAddress={saveAddress}
          setIsManualEditing={setIsManualEditing}
          providerStates={providerStates}
        />
      )}
    </>
  );
};

export const AddressMapContent = ({
  selectedAddress,
  handleLocationSelect,
  REACT_APP_GOOGLE_MAPS_API_ID,
  saveAddress,
  providerStates,
  isManualEditing,
  setIsManualEditing,
  ...rest
}) => {
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_ID}&libraries=places`;
  const [google, setGoogle] = useState(null);
  const [error, setError] = useState(null);

  const onLoad = () => {
    setGoogle(window.google);
  };

  const onError = () => {
    setError('Could not load Google maps. Please refresh the page.');
  };

  return (
    <React.Fragment>
      <Script url={googleMapURL} onError={onError} onLoad={onLoad} />
      {!error && google ? (
        <Forms
          handleLocationSelect={handleLocationSelect}
          selectedAddress={selectedAddress}
          saveAddress={saveAddress}
          google={google}
          isManualEditing={isManualEditing}
          setIsManualEditing={setIsManualEditing}
          providerStates={providerStates}
          googleMapsApiKey={REACT_APP_GOOGLE_MAPS_API_ID}
          {...rest}
        />
      ) : (
        <div css={{ fontSize: theme.fontSize.base }}>{error}</div>
      )}
    </React.Fragment>
  );
};

export const AddressModal = ({
  open,
  onClose,
  modalAction,
  selectedAddress,
  handleLocationSelect,
  REACT_APP_GOOGLE_MAPS_API_ID,
  clearSelectedAddress,
  saveAddress,
  providerStates,
}) => {
  const [isManualEditing, setIsManualEditing] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => {
        clearSelectedAddress();
        setIsManualEditing(false);
        onClose();
      }}
      title={`${modalAction} Address`}
    >
      <AddressMapContent
        {...{
          isManualEditing,
          setIsManualEditing,
          selectedAddress,
          handleLocationSelect,
          REACT_APP_GOOGLE_MAPS_API_ID,
          saveAddress,
          providerStates,
        }}
      />
    </Modal>
  );
};
