import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';

export type ClientContextType = {
  client?: UserRead;
  hasAlerts?: boolean;
  setAlerts: (value: boolean) => void;
};

export const ClientContext = React.createContext<ClientContextType>({
  client: undefined,
  hasAlerts: false,
  setAlerts: () => {},
});

export const ClientContextProvider = ({
  client,
  children,
}: {
  client?: UserRead | undefined;
  children: React.ReactNode;
}) => {
  const [hasAlerts, setHasAlerts] = React.useState(false);

  const value: ClientContextType = {
    client,
    hasAlerts,
    setAlerts: setHasAlerts,
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};
