/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { GetProviderMessagesResponse } from '../models/GetProviderMessagesResponse';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { MarkMessageThreadReadRequest } from '../models/MarkMessageThreadReadRequest';
import { MessageCreate } from '../models/MessageCreate';
import { MessageRead } from '../models/MessageRead';
import { MessageThreadReadByPatient } from '../models/MessageThreadReadByPatient';
import { MessageThreadReadByProvider } from '../models/MessageThreadReadByProvider';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class MessageApiResource {

    /**
     * Create Message
     * 
     * @param providerId 
     * @param patientUserId 
     * @param messageCreate 
     
     */
    public createMessage(providerId: number, patientUserId: number, body: MessageCreate, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/messages/provider/{provider_id}/patient/{patient_user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId))
                    .replace('{' + 'patient_user_id}', String(patientUserId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Active Provider Message Threads
     * 
     * @param providerId 
     * @param includeOnlyArchived 
     
     */
    public getActiveProviderMessageThreads(providerId: number, query?: { include_only_archived?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<GetProviderMessagesResponse> {
        const apiRoute = '/messages/threads/provider/{provider_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Messages
     * 
     * @param providerId 
     * @param userId 
     
     */
    public getMessages(query?: { provider_id?: number, user_id?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<MessageRead>> {
        const apiRoute = '/messages';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Message Threads
     * 
     * @param patientId 
     
     */
    public getPatientMessageThreads(patientId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<MessageThreadReadByPatient>> {
        const apiRoute = '/messages/threads/patient/{patient_id}';
        const reqPath = apiRoute
                    .replace('{' + 'patient_id}', String(patientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Unread Provider Message Threads
     * 
     * @param providerId 
     
     */
    public getUnreadProviderMessageThreads(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<MessageThreadReadByProvider>> {
        const apiRoute = '/messages/threads/provider/{provider_id}/unread';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Mark Message Thread As Read
     * 
     * @param markMessageThreadReadRequest 
     
     */
    public markMessageThreadAsRead(body: MarkMessageThreadReadRequest, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/messages/threads/read';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const MessageApi = new MessageApiResource();
