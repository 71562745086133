import { FormLabel, FormLabelProps } from '@mui/material';
import React, { FC } from 'react';

import { FieldControlContext } from './FieldControl';

export const FieldFormLabel: FC<React.PropsWithChildren<FormLabelProps>> = (
  props
) => {
  const { id } = React.useContext(FieldControlContext);
  return <FormLabel id={`${id}-label`} htmlFor={id} {...props} />;
};
