import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useProviderPatient } from '~/legacy/hooks';
import { useShowAnthemEAPExperiences } from '~/legacy/hooks/useShowAnthemEAPExperiences';

import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { EligibilityLookupApi } from '@headway/api/resources/EligibilityLookupApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useUser } from '@headway/shared/hooks/useUser';
import { useQuery } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';
import { isStatusInNetwork } from '@headway/shared/utils/insuranceUtils';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useProvider } from 'hooks/useProvider';

import { useFindProviderEvents } from '../../hooks/useFindProviderEvents';
import { useProviderPrice } from '../../hooks/useProviderPrice';
import { PricingInfoInsightCard } from './PricingInfoInsightCard';
import { PricingInfoTable } from './PricingInfoTable';

const ContactHeadwayAboutPricing = ({
  providerId,
  clientId,
}: {
  providerId: number;
  clientId: number;
}) => {
  const { data: paginatedProviderEvents } = useFindProviderEvents({
    provider_id: providerId,
    patient_user_id: clientId,
    limit: 1,
  });
  const hasAnyEvents =
    !!paginatedProviderEvents && paginatedProviderEvents.totalCount > 0;
  return hasAnyEvents ? (
    <>
      If you have questions about what you’ll be earning,{' '}
      <Link
        href="https://calendly.com/emmy1/headway-check-in-with-emmy-zoom-clone"
        target="_blank"
      >
        set up a meeting with a practice consultant
      </Link>
      .
    </>
  ) : (
    <>
      Once you schedule a session, you’ll be able to reach out to our practice
      consultants if you have questions about what you’ll be earning.
    </>
  );
};

interface PricingInfoProps {
  clientId: number;
  onOpenNoDataPrelimPricingVerificationInProgressModal: () => void;
  onOpenOldDataPrelimPricingVerificationInProgressModal: () => void;
}

export const PricingInfo = ({
  clientId,
  onOpenNoDataPrelimPricingVerificationInProgressModal,
  onOpenOldDataPrelimPricingVerificationInProgressModal,
}: PricingInfoProps) => {
  const provider = useProvider();
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { data: client } = useUser({ userId: clientId });
  const insurance = client?.activeUserInsurance;
  const { insuranceStatus } = useInsuranceStatus(client, insurance);
  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;
  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;
  const { data: providerPriceData, isLoading: isProviderPriceLoading } =
    useProviderPrice(
      { provider, client },
      { enabled: isInOldDataPrelimPricing }
    );

  const { data: providerPatient } = useProviderPatient({
    patientId: clientId,
    providerId: provider.id,
  });

  const { showAnthemEAPExperiences } = useShowAnthemEAPExperiences(
    clientId,
    providerPatient,
    undefined
  );

  const { data: priceChangeDetails } = useQuery(
    ['priceChangeDetails', insurance?.id],
    () =>
      EligibilityLookupApi.getMostRecentPriceChangeDetails(
        client!.id,
        insurance!.id
      ),
    {
      enabled: (!!insurance || showAnthemEAPExperiences) && !!client,
      staleTime: Infinity,
    }
  );

  const priceChanges = priceChangeDetails?.priceChanges || [];

  const isInNetwork = isStatusInNetwork(insuranceStatus);

  useEffect(() => {
    if (
      client &&
      insurance &&
      !isInNetwork &&
      (isInNoDataPrelimPricing || isInOldDataPrelimPricing) &&
      !isProviderPriceLoading
    )
      trackPageView({
        name: 'Verification In Progress Banner Viewed',
        properties: {
          providerId: provider.id,
          patientUserId: client.id,
          userInsuranceId: `${insurance.id}`,
          prelimPricingType: `${insurance.prelimPricingType}`,
          copyVariant: '',
          presumptiveAccumulatorsApplied:
            providerPriceData?.priceCalculationInfo?.presumptiveAccumulators ??
            [],
        },
      });
  }, [
    isInNetwork,
    isInNoDataPrelimPricing,
    isInOldDataPrelimPricing,
    isProviderPriceLoading,
    client,
    insurance,
    provider.id,
    providerPriceData?.priceCalculationInfo?.presumptiveAccumulators,
  ]);
  if (!client || (!insurance && !showAnthemEAPExperiences)) {
    return null;
  }

  const frontEndCarrierName =
    client?.activeUserInsurance?.billingFrontEndCarrierName;
  const clientFirstName = formatPatientName(client, {
    firstNameOnly: true,
  });

  return (
    <PageSection css={pricingInfoCss.pricing}>
      <h2>
        <SectionHeader>Pricing</SectionHeader>
      </h2>
      {isInNetwork ? (
        <>
          <div className="w-fit">
            Here’s a breakdown of {clientFirstName}’s session cost and what you
            will earn under common CPT codes.{' '}
            {isMSCEnabled && (
              <>
                What you will earn is determined based on state and can vary
                based on the client’s location during the session.{' '}
                <ContactHeadwayAboutPricing
                  providerId={provider.id}
                  clientId={clientId}
                />
              </>
            )}{' '}
            <LinkButton
              component={RouterLink}
              elementType="a"
              variant="link"
              to="/legal"
            >
              Learn more about what you will earn
            </LinkButton>
          </div>
          <PricingInfoTable
            clientId={clientId}
            hasPriceChanged={priceChanges?.length > 0}
          />
          <PricingInfoInsightCard
            patient={client}
            providerId={provider.id}
            priceChangeDetails={priceChangeDetails}
          />
        </>
      ) : isInNoDataPrelimPricing || isInOldDataPrelimPricing ? (
        <>
          <GuidanceCard variant="warning">
            <BodyText>
              <>
                {frontEndCarrierName} is working to get us the information we
                need to verify {clientFirstName}’s benefits. Regardless, your
                payment will stay the same for each CPT code for any sessions
                you confirm.
              </>
            </BodyText>
            <Button
              size="large"
              variant="link"
              onPress={
                isInNoDataPrelimPricing
                  ? onOpenNoDataPrelimPricingVerificationInProgressModal
                  : isInOldDataPrelimPricing
                  ? onOpenOldDataPrelimPricingVerificationInProgressModal
                  : () => {}
              }
            >
              Learn more
            </Button>
          </GuidanceCard>
          <PricingInfoTable clientId={clientId} />
        </>
      ) : (
        <BodyText>
          We’re not able to get a price estimate with these insurance details.
        </BodyText>
      )}
    </PageSection>
  );
};

const pricingInfoCss = {
  pricing: css({
    h2: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gap: theme.spacing.x3,
  }),
};
