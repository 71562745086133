import { mergeProps, useSlotId } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';
import { useRadioGroup } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';
import { Simplify } from 'type-fest';

import { DATA } from './consts';
import { theme } from './theme';
import { FormInputProps, useFormInput } from './useFormInput';

export const RadioGroupContext = React.createContext<RadioGroupState | null>(
  null
);

export type RadioGroupProps = Simplify<
  {
    children: React.ReactNode;
    size?: 'small' | 'large';
    optionalityText?: React.ReactNode;
  } & FormInputProps<string>
>;

// Generate a random prop name to allow internal Helix components to pass in a className
// modifier without exposing it to the user.
const RadioGroupClassNameModifier = '__HLX__RadioGroup';

export const RadioGroup = ({
  size = 'small',
  // not including RadioGroupClassNameModifier in the props
  // because we don't want to expose it to the user
  // @ts-expect-error
  [RadioGroupClassNameModifier]: _classNameModifier,
  ...props
}: RadioGroupProps) => {
  const { ariaProps, rootProps } = useFormInput(props);

  const rgState = useRadioGroupState(ariaProps);
  const { radioGroupProps, labelProps } = useRadioGroup(ariaProps, rgState);

  const descriptionId = useSlotId([Boolean(props.helpText), props.validation]);
  const errorMessageId = useSlotId([Boolean(props.helpText), props.validation]);
  const optionalityId = useSlotId([Boolean(props.optionalityText)]);

  return (
    <div
      className={clsx('hlx-radio-group-root', _classNameModifier)}
      data-hlx-size={size}
      {...mergeProps(radioGroupProps, rootProps, {
        'aria-describedby':
          [
            descriptionId,
            errorMessageId,
            optionalityId,
            props['aria-describedby'],
          ]
            .filter(Boolean)
            .join(' ') || undefined,
        [DATA.DISABLED]: props.disabled,
      })}
    >
      <div className="hlx-radio-group-descriptors">
        {props.label && (
          <span
            className="hlx-radio-group-label"
            style={{
              marginRight: props.optionalityText ? theme.spacing.x2 : 0,
            }}
            {...labelProps}
          >
            {props.label}
          </span>
        )}
        {props.optionalityText && (
          <span id={optionalityId} className="hlx-radio-group-optionality-text">
            {props.optionalityText}
          </span>
        )}
      </div>
      <div className="hlx-radio-group-controls">
        <RadioGroupContext.Provider value={rgState}>
          {props.children}
        </RadioGroupContext.Provider>
      </div>
      {props.helpText && (
        <div className="hlx-radio-group-help-text" id={descriptionId}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-radio-group-error" id={errorMessageId}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
};

export function RadioCardGroup(props: RadioGroupProps) {
  return (
    <RadioGroup
      {...props}
      {...{
        [RadioGroupClassNameModifier]: 'hlx-radio-card-group',
      }}
    />
  );
}
