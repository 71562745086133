import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Item } from '@headway/helix/collections';
import { Link } from '@headway/helix/Link';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { ConsolidatedRatesByState } from '@headway/shared/types/rates';

import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';

import { useRates } from '../../../hooks/useRates';
import { Rates } from './Rates';
import { RatesInfoButtonAndModal } from './RatesInfoButtonAndModal';

export const RatesContainer = ({
  selectedState,
  onStateChange,
}: {
  selectedState?: string;
  onStateChange: (state: string) => void;
}) => {
  const authStore = useAuthStore();
  const isGroupPractice = !!authStore.user.group_practice;
  const provider = useProvider();

  const { rates, carriers, isLoading } = useRates(
    provider,
    isGroupPractice,
    true
  );

  if (isLoading || !authStore?.user) {
    return null;
  }

  return (
    <>
      <div className="pl-10 desktop:max-w-[1000px]">
        <h2>
          <BrandText variant="section">Your Rates</BrandText>
        </h2>
        <p>
          <BodyText>
            This is what {isGroupPractice ? 'your group will' : 'you’ll'} earn
            for appointments depending on the{' '}
            <Link
              href="https://findheadway.zendesk.com/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes"
              target="_blank"
              rel="noreferrer"
            >
              <strong>CPT code</strong>
            </Link>
            . Payment for appointments occur twice monthly, on the 15th of the
            month and on the last day of the month, anywhere between 14 and 30
            days after you confirm the details of the appointment.
          </BodyText>
        </p>
        <p>
          <RatesInfoButtonAndModal />
        </p>
      </div>
      <div className="pl-10">
        <RatesTableContainer
          rates={rates}
          user={authStore?.user}
          provider={provider}
          carriers={carriers}
          selectedState={selectedState}
          onStateChange={onStateChange}
          showHelpText={true}
        />
      </div>
    </>
  );
};

export const RatesTableContainer = ({
  rates,
  user,
  provider,
  carriers,
  selectedState,
  onStateChange,
  showHelpText = false,
}: {
  rates: ConsolidatedRatesByState;
  provider: ProviderRead;
  user: UserRead;
  carriers: string[];
  selectedState?: string;
  onStateChange?: (state: string) => void;
  showHelpText?: boolean;
}) => {
  const itemLists = Object.keys(rates).map((state) => (
    <Item key={state.toLowerCase()}>
      {statesToDisplayNames[state as UnitedStates]}
    </Item>
  ));
  const itemPanels = Object.keys(rates).map((state) => (
    <Item key={state.toLowerCase()}>
      <div
        css={{
          height: 'inherit',
          overflowY: 'auto',
        }}
      >
        <Rates
          user={user}
          state={state as UnitedStates}
          rates={rates[state as string]}
          carriers={carriers}
          isRatesLoading={false}
          providerFrontEndCarriers={provider.providerFrontEndCarriers}
          showHelpText={showHelpText}
        />
      </div>
    </Item>
  ));

  const controlledTabsProps =
    selectedState && onStateChange
      ? {
          selectedKey: selectedState,
          onSelectionChange: (state: any) =>
            onStateChange((state as string).toLowerCase()),
        }
      : {};

  return (
    <>
      <div className="desktop:max-w-[1500px]">
        <Tabs aria-label="Task" {...controlledTabsProps}>
          <TabList>{itemLists}</TabList>
          <TabPanels>{itemPanels}</TabPanels>
        </Tabs>
      </div>
    </>
  );
};
