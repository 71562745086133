import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { REACT_QUERY_STALE_TIME } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

export const QueryClientProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const staleTime = useFlag(REACT_QUERY_STALE_TIME, 0);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime,
          },
        },
      })
  );

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        staleTime,
      },
    });
  }, [queryClient, staleTime]);

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
};
