import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

import {
  TreatmentPlanRequirement,
  TreatmentPlanRequirementModalContent,
} from '../Requirements/TreatmentPlanRequirements';

export interface TreatmentPlanRequirementModalProps {
  hasCompletedIntakeTherapyNote: boolean;
  hasPriorActiveTreatmentPlan: boolean;
  hasOnlyExpiredActiveTreatmentPlans?: boolean;
  requirementType?: TreatmentPlanRequirement;
}

interface RequiredInfoTreatmentPlanModalProps
  extends TreatmentPlanRequirementModalProps {
  open: boolean;
  onClose: () => void;
  onCreatePlanNow: () => void;
}

export const RequiredInfoTreatmentPlanModal = ({
  open,
  onClose,
  onCreatePlanNow,
  ...contentProps
}: RequiredInfoTreatmentPlanModalProps) => {
  return (
    <Modal
      title="Required for next payment: treatment plan"
      isOpen={open}
      onDismiss={onCreatePlanNow}
    >
      <ModalContent>
        <TreatmentPlanRequirementModalContent contentProps={contentProps} />
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onClose}>
          Remind me to do this later
        </Button>
        <Button onPress={onCreatePlanNow}>Create plan now</Button>
      </ModalFooter>
    </Modal>
  );
};
