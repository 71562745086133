import { useFormikContext } from 'formik';
import { useProvider } from 'hooks';
import difference from 'lodash/difference';
import React, { useContext, useState } from 'react';

import { ProviderTreatmentPlanAttachmentRead } from '@headway/api/models/ProviderTreatmentPlanAttachmentRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useMatchingProviderFrontEndCarrierQuery } from '@headway/shared/hooks/useMatchingProviderFrontEndCarrierQuery';
import { useUser } from '@headway/shared/hooks/useUser';
import { downloadFile } from '@headway/shared/utils/download';
import { logException } from '@headway/shared/utils/sentry';
import { UploadedFile } from '@headway/shared/utils/upload';
import { FieldControl } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { HeadwayLogo } from '@headway/ui/icons/HeadwayLogo';
import { notifyError } from '@headway/ui/utils/notify';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { useProviderTreatmentPlanAttachmentsList } from 'hooks/useProviderTreatmentPlanAttachments';
import { TreatmentPlansRequirementsList } from 'views/Clients/TreatmentPlan/Components/TreatmentPlansRequirementsList';
import { CancelTreatmentPlanModal } from 'views/Clients/TreatmentPlan/Modals/CancelTreatmentPlanModal';
import { CANCEL_TREATMENT_PLAN_MODAL_TITLE } from 'views/Clients/TreatmentPlan/Modals/CancelTreatmentPlanModal';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from 'views/Clients/TreatmentPlan/TreatmentPlanContext';
import { Attachment, AttachmentsList } from 'views/Patients/AttachmentsList';

import { InfoBox } from '../Components/InfoBox';
import {
  trackBackButtonClickedEvent,
  trackSaveAndExitButtonClickedEvent,
  trackSaveButtonClickedEvent,
} from '../TreatmentPlanAnalyticsUtils';
import { Page } from './Page';

export interface UploadPageProps {
  closeModal: () => void;
}

export const UploadPage = ({ closeModal }: UploadPageProps) => {
  const {
    setPage,
    treatmentPlan,
    providerPatient,
    initialValues,
    treatmentPlans,
  } = useContext(TreatmentPlanContext);
  const { saveTreatmentPlan } = useProviderTreatmentPlan(providerPatient!.id);

  const [goBackModalOpen, setGoBackModalOpen] = useState<boolean>(false);

  const attachmentsByTreatmentPlanId: {
    [key: number]: ProviderTreatmentPlanAttachmentRead[];
  } = {};
  const treatmentPlanAttachmentsQuery = useProviderTreatmentPlanAttachmentsList(
    treatmentPlans.map((plan) => {
      return { queryKeyArgs: { treatmentPlanId: plan.id } };
    })
  );
  treatmentPlanAttachmentsQuery.forEach((query) => {
    if (query && query.data && query.data.length > 0) {
      attachmentsByTreatmentPlanId[query.data[0].providerTreatmentPlanId] =
        query.data;
    }
  });

  const initialTreatmentPlanAttachments =
    (treatmentPlan && attachmentsByTreatmentPlanId[treatmentPlan.id]) ??
    ([] as Attachment<number | string>[]);

  const provider = useProvider();
  const { data: client } = useUser({ userId: providerPatient!.userId });
  const { data: matchingProviderFrontEndCarrier } =
    useMatchingProviderFrontEndCarrierQuery({
      providerId: provider.id,
      patientUserId: providerPatient?.userId,
    });
  const matchingFrontendCarrierName =
    matchingProviderFrontEndCarrier?.frontEndCarrier?.name;

  const pageHeader = 'Upload existing plan';

  const downloadAttachment = async (
    attachment: Attachment<number | string>
  ) => {
    try {
      await downloadFile(attachment);
    } catch (err) {
      notifyError('There was an issue downloading your attachment');
      logException(err);
    }
  };

  const { values, setFieldValue, submitForm } = useFormikContext<any>();

  const hasAttachmentChanges =
    difference(
      values?.treatmentPlanAttachments ?? [],
      initialTreatmentPlanAttachments
    ).length > 0 ||
    difference(
      initialTreatmentPlanAttachments,
      values?.treatmentPlanAttachments ?? []
    ).length > 0;

  return (
    <Page
      buttons={[
        <Button
          key="back"
          variant="secondary"
          onPress={() => {
            if (hasAttachmentChanges) {
              trackBackButtonClickedEvent({
                providerPatient: providerPatient!,
                pageHeader: CANCEL_TREATMENT_PLAN_MODAL_TITLE,
              });
              setGoBackModalOpen(true);
            } else {
              trackBackButtonClickedEvent({
                providerPatient: providerPatient!,
                pageHeader: pageHeader,
              });
              treatmentPlan
                ? closeModal()
                : setPage(TREATMENT_PLAN_PAGES.START);
            }
          }}
        >
          Back
        </Button>,
        <Button
          variant="primary"
          onPress={async () => {
            trackSaveButtonClickedEvent({
              providerPatient: providerPatient!,
              treatmentPlanType: TreatmentPlanType.UPLOAD,
            });
            await submitForm();
            closeModal();
          }}
          disabled={
            values?.treatmentPlanAttachments?.length === 0 ||
            !values?.treatmentPlanAttestation
          }
        >
          Sign and save
        </Button>,
        <div css={{ marginLeft: 'auto', display: 'flex' }}>
          <Button
            variant="link"
            onPress={async () => {
              trackSaveAndExitButtonClickedEvent(providerPatient!);
              await saveTreatmentPlan({
                values,
                planType: TreatmentPlanType.UPLOAD,
                shouldAttestPlan: false,
                treatmentPlanId: treatmentPlan?.id,
                initialAttachments: initialValues?.treatmentPlanAttachments,
              });
              closeModal();
            }}
            disabled={!hasAttachmentChanges}
          >
            Save and Exit
          </Button>
        </div>,
      ]}
      hint="Once signed, this plan cannot be edited"
    >
      <SafeFormikForm className="free-text-treatment-plan">
        <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
          <div css={{ padding: `${theme.spacing.x5} 0` }}>
            <SectionHeader>{pageHeader}</SectionHeader>
          </div>
          <HeadwayLogo css={{ width: '174px' }} />
          {client && <InfoBox clientId={client.id} />}
          <TreatmentPlansRequirementsList title="Plans usually include:" />
        </div>
        <div css={{ marginBottom: theme.spacing.x8 }}>
          <FieldControl name="attachments" noMargin>
            <AttachmentsList
              provider={provider}
              attachments={values?.treatmentPlanAttachments ?? []}
              onAddAttachments={async (files: UploadedFile[]) => {
                const updatedAttachments = [
                  ...(values?.treatmentPlanAttachments ?? []),
                  ...files.map(
                    (file) =>
                      ({
                        ...file,
                        id: file.s3ObjectKey,
                      }) as Attachment<number | string>
                  ),
                ];
                setFieldValue('treatmentPlanAttachments', updatedAttachments);
              }}
              onDeleteAttachment={(attachmentId: number | string) => {
                const updatedAttachments =
                  values?.treatmentPlanAttachments?.filter(
                    (file: Attachment<number | string>) =>
                      file.id !== attachmentId
                  );
                setFieldValue('treatmentPlanAttachments', updatedAttachments);
              }}
              onDownloadAttachment={downloadAttachment}
              variant="helix"
              accept="application/pdf,application/vnd.apple.pages,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,image/jpeg,image/png"
              disabled={
                values?.treatmentPlanAttachments &&
                values?.treatmentPlanAttachments.length >= 5
              }
            />
            <CaptionText>
              Supported files: PDF, doc, text, JPEG, PNG (Max 5 files)
            </CaptionText>
          </FieldControl>
        </div>
        <div>
          <FormControl component={Checkbox} name="treatmentPlanAttestation">
            <div>
              I confirm that...
              <ul css={{ marginTop: theme.spacing.x1 }}>
                <li>
                  I have discussed the risks and benefits of the treatment plan
                  with the client, and the client has acknowledged and agreed to
                  this plan.
                </li>
                <li>
                  My plan is true, accurate and meets{' '}
                  {matchingFrontendCarrierName ?? 'the payer'}
                  's requirements, and — if requested by Headway — I'll provide
                  extra information on request within three business days.
                </li>
              </ul>
            </div>
          </FormControl>
        </div>
      </SafeFormikForm>
      <CancelTreatmentPlanModal
        open={goBackModalOpen}
        onClose={() => setGoBackModalOpen(false)}
        onCancel={() =>
          treatmentPlan ? closeModal() : setPage(TREATMENT_PLAN_PAGES.START)
        }
      />
    </Page>
  );
};
