/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderTreatmentPlanAttachmentCreate } from '../models/ProviderTreatmentPlanAttachmentCreate';
import { ProviderTreatmentPlanAttachmentRead } from '../models/ProviderTreatmentPlanAttachmentRead';
import { ProviderTreatmentPlanCreate } from '../models/ProviderTreatmentPlanCreate';
import { ProviderTreatmentPlanRead } from '../models/ProviderTreatmentPlanRead';
import { ProviderTreatmentPlanUpdateRequest } from '../models/ProviderTreatmentPlanUpdateRequest';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderTreatmentPlanApiResource {

    /**
     * Create Provider Treatment Plan
     * 
     * @param providerTreatmentPlanCreate 
     
     */
    public createProviderTreatmentPlan(body: ProviderTreatmentPlanCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderTreatmentPlanRead> {
        const apiRoute = '/provider-treatment-plans';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Treatment Plan Attachment
     * 
     * @param id 
     * @param providerTreatmentPlanAttachmentCreate 
     
     */
    public createTreatmentPlanAttachment(id: number, body: ProviderTreatmentPlanAttachmentCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderTreatmentPlanAttachmentRead> {
        const apiRoute = '/provider-treatment-plans/{id}/attachments';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Provider Treatment Plan
     * 
     * @param id 
     
     */
    public deleteProviderTreatmentPlan(id: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-treatment-plans/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Treatment Plan Attachment
     * 
     * @param treatmentPlanId 
     * @param attachmentId 
     
     */
    public deleteTreatmentPlanAttachment(treatmentPlanId: number, attachmentId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-treatment-plans/{treatment_plan_id}/attachments/{attachment_id}';
        const reqPath = apiRoute
                    .replace('{' + 'treatment_plan_id}', String(treatmentPlanId))
                    .replace('{' + 'attachment_id}', String(attachmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Provider Treatment Plans
     * 
     * @param providerPatientId 
     * @param templateVersion 
     
     */
    public findProviderTreatmentPlans(query?: { provider_patient_id?: number, template_version?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderTreatmentPlanRead>> {
        const apiRoute = '/provider-treatment-plans';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Treatment Plan
     * 
     * @param id 
     
     */
    public getProviderTreatmentPlan(id: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderTreatmentPlanRead> {
        const apiRoute = '/provider-treatment-plans/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Treatment Plan Attachments
     * 
     * @param id 
     
     */
    public getTreatmentPlanAttachments(id: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderTreatmentPlanAttachmentRead>> {
        const apiRoute = '/provider-treatment-plans/{id}/attachments';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Treatment Plan
     * 
     * @param id 
     * @param providerTreatmentPlanUpdateRequest 
     
     */
    public updateProviderTreatmentPlan(id: number, body: ProviderTreatmentPlanUpdateRequest, axiosConfig?: AxiosRequestConfig): Promise<ProviderTreatmentPlanRead> {
        const apiRoute = '/provider-treatment-plans/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderTreatmentPlanApi = new ProviderTreatmentPlanApiResource();
