import * as React from 'react';

export const PaymentsOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.396 11.104a2.411 2.411 0 01-1.771-.729 2.411 2.411 0 01-.729-1.771c0-.694.243-1.284.729-1.77a2.41 2.41 0 011.771-.73 2.41 2.41 0 011.77.73 2.41 2.41 0 01.73 1.77 2.41 2.41 0 01-.73 1.771 2.41 2.41 0 01-1.77.729zm-7.792 2.792V3.333h15.563v10.563H3.603zM6.937 12.5h8.896c0-.542.188-1 .563-1.375a1.87 1.87 0 011.375-.563V6.668c-.542 0-1-.188-1.375-.563a1.87 1.87 0 01-.563-1.375H6.938c0 .542-.187 1-.562 1.375A1.87 1.87 0 015 6.667v3.896c.542 0 1 .187 1.375.562.375.375.563.833.563 1.375zM.833 16.666V5.834H2.23v9.437h14.438v1.395H.832zM5 12.5V4.729 12.5z"
      fill="currentColor"
    />
  </svg>
);
