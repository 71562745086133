import { BodyText } from '@headway/helix/BodyText';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListHeader } from '@headway/helix/ListHeader';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { getListFormatter } from 'utils/formatters';
import {
  filterStatesByCdsRequirement,
  YesNo,
} from 'utils/providerQuestionnaire';

import { useQuestionnaireContext } from './QuestionnaireV2Context';
import { PRESCRIBER_REGISTRATION_REQUIREMENTS_ZENDESK_ARTICLE } from './utils/intakeQuestionnaireConstants';
import { YesNoQuestion } from './utils/YesNoQuestion';

export const PrescriberRequirements = ({ values }: any) => {
  const { provider } = useQuestionnaireContext();

  if (!provider.isPrescriber) return null;

  const cdsRequiredStates = filterStatesByCdsRequirement(
    values.providerSelectedPracticeStates,
    provider.licenseType
  );
  const listFormatter = getListFormatter('en-US');
  const currentStatesFormatted = listFormatter.format(
    cdsRequiredStates.map((state) => statesToDisplayNames[state])
  );

  return (
    <>
      {values.isLicensedInSelectedPracticeStates === YesNo.YES && (
        <div className="grid gap-2">
          <ListHeader>Prescriber Requirements: Registrations</ListHeader>
          <p>
            <BodyText>
              As a prescriber, you are required to have a DEA (Drug Enforcement
              Administration) registration in every state where you practice.
              Some states also require a CDS (Controlled Dangerous Substances)
              license.{' '}
              <LinkButton
                variant="link"
                href={PRESCRIBER_REGISTRATION_REQUIREMENTS_ZENDESK_ARTICLE}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about prescriber requirements
              </LinkButton>
            </BodyText>
          </p>

          <YesNoQuestion
            name="hasDeaInSelectedPracticeStates"
            label={
              <>
                Do you have a{' '}
                <GlossaryTerm
                  term="DEA (Drug Enforcement
                Administration) registration"
                >
                  A DEA registration is assigned by the United States Drug
                  Enforcement Administration allowing licensed health care
                  practitioners to write prescriptions for controlled
                  substances.
                </GlossaryTerm>{' '}
                in each of the selected states?
              </>
            }
          />
          {!!cdsRequiredStates.length && (
            <YesNoQuestion
              name="hasCdsInSelectedPracticeStates"
              label={
                <>
                  Do you have a{' '}
                  <GlossaryTerm term="CDS (Controlled Dangerous Substances) license">
                    A CDS license is assigned by the state allowing licensed
                    health care practitioners to work with controlled
                    substances.
                  </GlossaryTerm>{' '}
                  in {currentStatesFormatted}?
                </>
              }
            />
          )}
        </div>
      )}
    </>
  );
};
