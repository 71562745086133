import { useEffect, useState } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconCheck } from '@headway/helix/icons/Check';
import { IconCircleCheck } from '@headway/helix/icons/CircleCheck';
import { Link } from '@headway/helix/Link';
import { ListRow } from '@headway/helix/List';
import { Modal, ModalContent } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import TherapistImage from 'assets/img/therapist-study.png';
import { ManageAssessmentsModal } from 'views/Clients/Assessments/components/ManageAssessmentsModal';
import {
  INCENTIVE_BONUS_AMOUNT_DOLLARS,
  RateBoostBannerCopyVariant,
} from 'views/Incentives/constants';

interface SwoopUpsellModalProps {
  open: boolean;
  onClose: () => void;
  patient: UserRead;
}

function UncheckedDashedCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle
        cx="10"
        cy="10"
        r="8.5"
        stroke="currentColor"
        stroke-dasharray="2,2"
      />
    </svg>
  );
}

export const SwoopUpsellModal = ({
  open,
  onClose,
  patient,
}: SwoopUpsellModalProps) => {
  const [isManageAssementsModalOpened, setIsManageAssessmentsModalOpen] =
    useState(false);

  useEffect(() => {
    if (open) {
      trackEvent({
        name: 'Rate Boost Banner Viewed',
        properties: {
          copyVariant: RateBoostBannerCopyVariant.FULL_PAGE,
        },
      });
    }
  }, [open]);

  return (
    <div>
      <Modal
        title="Rate boost opportunity"
        onDismiss={onClose}
        isOpen={open}
        variant="fullscreen"
        layout="contained"
      >
        <ModalContent>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing.x2,
              marginTop: theme.spacing.x5,
            }}
          >
            <IconCircleCheck
              css={{
                color: theme.color.system.green,
              }}
              width={20}
            />
            <BodyText>
              <strong>
                Your session with {formatPatientName(patient)} is confirmed
              </strong>
            </BodyText>
          </div>
          <div css={{ display: 'flex', marginTop: theme.spacing.x4 }}>
            <div
              css={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: 128,
                backgroundColor: theme.color.hue.lightBlue,
                padding: theme.spacing.x5,
                borderTopLeftRadius: 6,
                borderBottomLeftRadius: 6,
              }}
            >
              <BodyText>
                <strong>
                  You’ve met 1 of 2 criteria to earn a rate boost for clients in
                  Michigan
                </strong>
              </BodyText>
              <BodyText>
                Have your client complete at least one assessment every 30 days
                to unlock a ${INCENTIVE_BONUS_AMOUNT_DOLLARS} rate boost per
                session.{' '}
                <Link href={'/incentives/rate-boost'} target="_blank">
                  Learn more
                </Link>
              </BodyText>
            </div>
            <img
              width={180}
              height={128}
              src={TherapistImage}
              alt="therapist"
              css={{
                objectFit: 'cover',
                objectPosition: '100% 0',
                borderTopRightRadius: 6,
                borderBottomRightRadius: 6,
              }}
            />
          </div>
          <ul css={{ paddingInlineStart: 0 }}>
            <ListRow
              children={
                <>
                  <div
                    css={{
                      marginLeft: theme.spacing.x7,
                      marginBottom: theme.spacing.x1,
                    }}
                  >
                    <Badge variant="info">You met this criteria</Badge>
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing.x2,
                    }}
                  >
                    <IconCheck
                      css={{ color: theme.color.primary.blue }}
                      width={20}
                    />
                    <BodyText>
                      Upload a copy of your notes OR use Headway templates for
                      session notes
                    </BodyText>
                  </div>
                </>
              }
            />
            <ListRow
              children={
                <>
                  <div
                    css={{
                      marginLeft: theme.spacing.x7,
                      marginBottom: theme.spacing.x1,
                    }}
                  >
                    <Badge variant="neutral">
                      Remaining rate boost criteria
                    </Badge>
                  </div>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing.x2,
                    }}
                  >
                    <UncheckedDashedCircle
                      css={{ color: theme.color.system.gray }}
                    />
                    <BodyText>
                      Your client completes any Headway assessments 30 days
                      before or after a session.*
                    </BodyText>
                  </div>
                </>
              }
            />
          </ul>
          <div
            css={{
              display: 'flex',
              gap: theme.spacing.x3,
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="secondary" onPress={onClose}>
              Skip
            </Button>
            <Button
              onPress={() => {
                setIsManageAssessmentsModalOpen(true);
              }}
            >
              Send assessments to {formatPatientName(patient)}
            </Button>
          </div>
        </ModalContent>
      </Modal>
      <ManageAssessmentsModal
        isOpen={isManageAssementsModalOpened}
        onDismiss={onClose}
        clientId={patient.id}
        isIntakeFlow={false}
      />
    </div>
  );
};
