import { Formik } from 'formik';
import * as Yup from 'yup';

import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { theme } from '@headway/helix/theme';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdmin } from 'utils/access';

import { IroncladAgreementStatus } from '../../types/IroncladAgreementStatus';

export const AgreementCheckbox = ({
  onSubmit,
}: {
  onSubmit: (ironcladAgreementStatus: IroncladAgreementStatus) => void;
}) => {
  const agreementCheckboxSchema = Yup.object().shape({
    acceptedAgreement: Yup.boolean().oneOf([true], ' ').required(),
  });
  const { user } = useAuthStore();
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  return (
    <Formik
      initialValues={{
        acceptedAgreement: false,
      }}
      validationSchema={agreementCheckboxSchema}
      onSubmit={() => onSubmit(IroncladAgreementStatus.AGREED)}
    >
      <Form id="amendment-acceptance-form">
        <div
          css={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}
        >
          <FormControl
            name="acceptedAgreement"
            aria-label="acceptedAgreement"
            component={Checkbox}
          >
            {}
          </FormControl>
          <div
            css={{
              ...theme.typography.subbody.regular,
              color: theme.color.system.textBlack,
              backgroundColor: theme.color.system.lightGray,
              padding: theme.spacing.x4,
              width: '100%',
            }}
          >
            {isMSCBetaEnabled && isGroupAdmin(user)
              ? 'By checking this box, I as the authorized group admin understand and agree to all of the above on behalf of the practice.'
              : 'I accept these updates to continue using Headway.'}
          </div>
        </div>
      </Form>
    </Formik>
  );
};
