import React from 'react';

import { Banner } from '@headway/helix/Banner';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';

import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';

import {
  NUMBER_OF_DAYS_TO_BLOCKED,
  NUMBER_OF_DAYS_TO_TERMINATED,
} from '../helpers/constants';
import { calculateDaysRemaining } from '../helpers/utils';
import { IroncladAgreementStatus } from '../types/IroncladAgreementStatus';

interface IroncladAmendmentsBannerProps {
  onPress: () => void;
  bannerOffset: number;
}

export const IroncladAmendmentsBanner = ({
  onPress,
  bannerOffset,
}: IroncladAmendmentsBannerProps) => {
  const { user, provider } = useAuthStore();
  const { ironcladAgreementStatus } = useIroncladAgreementStatus();

  return (
    <div
      css={{
        position: 'fixed',
        background: theme.color.hue.lightSystemRed,
        padding: `0 ${theme.spacing.x1}`,
        height: 50,
        top: 0 + bannerOffset,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 17,
        color: theme.color.system.black,
      }}
    >
      <Banner
        adornment={
          hasRateAccess(provider, user) && (
            <Button variant="link" onPress={onPress}>
              View Agreement
            </Button>
          )
        }
        variant="warning"
      >
        {ironcladAgreementStatus === IroncladAgreementStatus.DISAGREED
          ? `We’ll fully close your account within the next  ${calculateDaysRemaining(
              NUMBER_OF_DAYS_TO_TERMINATED
            )} days.`
          : hasRateAccess(provider, user)
          ? `Your account will become inactive in
        ${calculateDaysRemaining(NUMBER_OF_DAYS_TO_BLOCKED)} days unless you
        accept the updated agreement.`
          : `Your account will become inactive in
          ${calculateDaysRemaining(NUMBER_OF_DAYS_TO_BLOCKED)} days unless your
          group practice administrator accepts our updated agreement.`}
      </Banner>
    </div>
  );
};
