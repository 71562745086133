/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum DataWarningType {
    DUPLICATE_EMAIL = 'DUPLICATE_EMAIL',
    INVALID_EMAIL = 'INVALID_EMAIL',
    INVALID_PHONE = 'INVALID_PHONE',
    VERIFIED_CLIENT = 'VERIFIED_CLIENT'
}
