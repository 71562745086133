import { FormLabel } from '@mui/material';
import sortBy from 'lodash/sortBy';
import React from 'react';
import * as Yup from 'yup';

import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { ComboBox } from '@headway/helix/ComboBox';
import { FormControl, validity } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { hasPrescriberLicenseType } from '@headway/shared/utils/licenseHelper';
import {
  getSupportedStates,
  isProviderInState,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { FieldControl, FieldDropzone, FieldErrorText } from '@headway/ui/form';

import { onDropFiles, YesNo } from '../../../utils/providerQuestionnaire';
import { BlueCrossCaliforniaFollowupQuestions } from '../blueCrossCalifornia/BlueCrossCaliforniaFollowupQuestions';
import {
  BscaSpecialties,
  SectionStepForBscaSpecialty,
} from '../blueCrossCalifornia/constants';
import { getSchemaForStep as getBscaSchemaForStep } from '../blueCrossCalifornia/schema';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { YesNoQuestion } from '../utils/YesNoQuestion';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const focusAreaSources = [
  'Graduate Coursework',
  'Practicum or Internship',
  'Formal Supervision',
  'Fellowship or Residency',
  'CEU/CME',
  'Certificate',
  'Other',
];

const groupBy = (arr: any[], key: any) => {
  const res: any[] = [];
  arr.forEach((arrItem) => {
    let groupIndex = res.findIndex((obj) => {
      return obj.title === arrItem[key];
    });
    if (groupIndex > -1) {
      res[groupIndex].items.push(arrItem);
    } else {
      res.push({ title: arrItem[key], items: [arrItem] });
    }
  });
  res.sort((a, b) => {
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;
    return 0;
  });
  return res;
};

type FocusAreaStepProps = CustomComponentProps & {
  specialties: SpecialtyRead[];
};

function FocusAreas({ formikHelpers }: FocusAreaStepProps) {
  const { provider, specialties } = useQuestionnaireContext();

  function handleDropFiles(files: File[]) {
    onDropFiles(provider.id, files);
  }

  if (!specialties) {
    return null;
  }

  const { values, setFieldValue } = formikHelpers;

  return (
    <div css={{ ...theme.reset }}>
      <section css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
        <FormControl
          name="primaryFocusArea"
          label="Please choose your primary area of expertise."
          component={Select}
          selectionMode={'single'}
        >
          {sortBy(specialties, 'clinicalDisplayName').map((specialty) => (
            <Item key={specialty.key}>{specialty.clinicalDisplayName}</Item>
          ))}
        </FormControl>
        <FormControl
          name="primaryFocusAreaSource"
          label="Please choose where you received training in this area."
          component={Select}
          selectionMode={'single'}
        >
          {focusAreaSources.map((focusAreaSource) => (
            <Item key={focusAreaSource}>{focusAreaSource}</Item>
          ))}
        </FormControl>
        {values.primaryFocusArea === 'eating_disorders' &&
          values.primaryFocusAreaSource === 'Certificate' && (
            <FieldControl
              name={`eatingDisorderCertificate`}
              fullWidth={true}
              css={{ marginTop: theme.spacing.x5 }}
            >
              <FormLabel>
                Please upload a copy of this <b>Certificate</b>.
              </FormLabel>
              <FieldDropzone
                accept="application/pdf,image/*"
                onDrop={handleDropFiles}
              />
              <FieldErrorText />
            </FieldControl>
          )}
        {values.primaryFocusAreaSource === 'Other' && (
          <FormControl
            name="otherPrimaryFocusAreaSource"
            label="Please explain."
            component={TextField}
          />
        )}

        <ComboBox
          name="otherPrimaryFocusAreas"
          label="Please choose other areas of expertise (up to 4)."
          selectionMode="multiple"
          items={sortBy(specialties, 'clinicalDisplayName')}
          selectedKeys={values.otherPrimaryFocusAreas}
          onSelectionChange={(selected: Set<string>) => {
            const newValues = Array.from(selected);
            setFieldValue('otherPrimaryFocusAreas', newValues);
          }}
          validation={validity('otherPrimaryFocusAreas', formikHelpers)}
        >
          {(specialty: SpecialtyRead) => (
            <Item key={specialty.key}>{specialty.clinicalDisplayName}</Item>
          )}
        </ComboBox>

        {values?.otherPrimaryFocusAreas?.map((specialty) => (
          <>
            <FormControl
              name={`otherPrimaryFocusAreasSources[${specialty}]`}
              label={
                <>
                  Please choose where you received training in{' '}
                  {
                    specialties.find((s) => s.key === specialty)
                      ?.clinicalDisplayName
                  }
                  .
                </>
              }
              component={Select}
              selectionMode={'single'}
            >
              {focusAreaSources.map((focusAreaSource) => (
                <Item key={focusAreaSource}>{focusAreaSource}</Item>
              ))}
            </FormControl>

            {specialty === 'eating_disorders' &&
              values?.otherPrimaryFocusAreasSources?.[specialty] ===
                'Certificate' && (
                <FieldControl
                  name={`eatingDisorderCertificate`}
                  fullWidth={true}
                  css={{ marginTop: theme.spacing.x5 }}
                >
                  <FormLabel>
                    Please upload a copy of this <b>Certificate</b>.
                  </FormLabel>
                  <FieldDropzone
                    accept="application/pdf,image/*"
                    onDrop={handleDropFiles}
                  />
                  <FieldErrorText />
                </FieldControl>
              )}
            {values?.otherPrimaryFocusAreasSources?.[specialty] === 'Other' && (
              <FormControl
                name={`otherOtherPrimaryFocusAreasSources[${specialty}]`}
                label="Please explain."
                component={TextField}
              />
            )}
          </>
        ))}
      </section>
      <section
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: theme.spacing.x6,
        }}
      >
        <FormControl
          component={CheckboxGroup}
          name="practiceFocusAreas"
          label="Please choose the areas of expertise that best describe your
            practice."
        >
          {groupBy(specialties, 'category').map((item) => (
            <div>
              <span css={{ ...theme.typography.body.medium }}>
                {item.title}
              </span>
              <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridRowGap: theme.spacing.x1,
                }}
              >
                {item.items.map((specialty: SpecialtyRead) => (
                  <div key={specialty.key}>
                    <Checkbox value={specialty.key}>
                      {specialty.clinicalDisplayName}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </FormControl>
        {values?.practiceFocusAreas?.map((specialty) => (
          <>
            <FormControl
              name={`specialtyTrainingSources[${specialty}]`}
              label={
                <>
                  Please choose where you received training in{' '}
                  {
                    specialties.find((s) => s.key === specialty)
                      ?.clinicalDisplayName
                  }
                  .
                </>
              }
              selectionMode="single"
              component={Select}
            >
              {focusAreaSources.map((focusAreaSource) => (
                <Item key={focusAreaSource}>{focusAreaSource}</Item>
              ))}
            </FormControl>

            {values?.specialtyTrainingSources?.[specialty] === 'Other' && (
              <FormControl
                name={`otherSpecialtyTrainingSources[${specialty}]`}
                label="Please explain."
                component={TextField}
              />
            )}
            {specialty === 'eating_disorders' &&
              values?.specialtyTrainingSources?.[specialty] ===
                'Certificate' && (
                <FieldControl
                  name={`eatingDisorderCertificate`}
                  fullWidth={true}
                  css={{ marginTop: theme.spacing.x6 }}
                >
                  <FormLabel>
                    Please upload a copy of this <b>Certificate</b>.
                  </FormLabel>
                  <FieldDropzone
                    accept="application/pdf,image/*"
                    onDrop={handleDropFiles}
                  />
                  <FieldErrorText />
                </FieldControl>
              )}
          </>
        ))}
      </section>
      {isProviderInState(provider, UnitedStates.ILLINOIS) && (
        <section
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x6,
            borderTop: `1px solid ${theme.color.system.borderGray}`,
            marginTop: theme.spacing.x8,
            paddingTop: theme.spacing.x8,
          }}
        >
          <h2>
            <SectionHeader>
              Do you offer these services to patients?
            </SectionHeader>
          </h2>
          <YesNoQuestion
            name="offersBreastfeedingSupport"
            label="Do you provide lactation/breastfeeding support services, including counseling and education?"
          />
          <YesNoQuestion
            name="offersMATForOpioidUse"
            label="Is Medication Assisted Treatment for Opioid Use Disorders provided at this location?"
          />
          <YesNoQuestion
            name="isCounselingProvidedForOpioidUse"
            label="Is Counseling provided for Opioid Use Disorder at this location?"
          />
          <YesNoQuestion
            name="isAuthorizedToDispenseMATForOpioidUse"
            label="Is this Physician authorized to dispense Medication Assisted Treatment (MAT) for Opioid Use Disorders?"
          />
          <YesNoQuestion
            name="prefersToKeepMATPrivate"
            label="Would you prefer to keep the MAT answers private? You can choose to not disclose this information with our members."
          />
        </section>
      )}
      {hasPrescriberLicenseType(values.selectedLicenses, [
        UnitedStates.FLORIDA,
      ]) && (
        <section
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x6,
            borderTop: `1px solid ${theme.color.system.borderGray}`,
            marginTop: theme.spacing.x8,
            paddingTop: theme.spacing.x8,
          }}
        >
          <h2>
            <SectionHeader>Medication Assisted Treatment (MAT)</SectionHeader>
          </h2>

          <YesNoQuestion
            name="offersMATToSubstanceAbuseUseDisorder"
            label="Do you offer MAT services to patients diagnosed with substance abuse use disorder?"
          />

          {values.offersMATToSubstanceAbuseUseDisorder === YesNo.YES && (
            <FormControl
              name={`offersMATToAlcoholOpiateOrBoth`}
              label="Do you offer MAT to patients diagnosed with alcohol use, opiate use, or both?"
              selectionMode="single"
              component={Select}
            >
              <Item key="Alcohol">Alcohol</Item>
              <Item key="Opiates">Opiates</Item>
              <Item key="Both">Both</Item>
            </FormControl>
          )}
        </section>
      )}

      {isProviderInState(provider, UnitedStates.CALIFORNIA) && (
        <BlueCrossCaliforniaFollowupQuestions
          values={values}
          stepForBscaSpecialty={SectionStepForBscaSpecialty.FocusAreas}
          specialtyKeys={[
            BscaSpecialties.Addiction,
            BscaSpecialties.EatingDisorders,
          ]}
        />
      )}
    </div>
  );
}

const eatingDisorderCertificateRequired = (
  focusAreas: string[],
  focusAreaSources: { [key: string]: string }
) =>
  focusAreas.includes('eating_disorders') &&
  focusAreaSources['eating_disorders'] === 'Certificate';

const ServicesSectionSchema = {
  [UnitedStates.ILLINOIS]: {
    offersBreastfeedingSupport: Yup.string().required(
      'This question is required.'
    ),
    offersMATForOpioidUse: Yup.string().required('This question is required.'),
    isCounselingProvidedForOpioidUse: Yup.string().required(
      'This question is required.'
    ),
    isAuthorizedToDispenseMATForOpioidUse: Yup.string().when(
      'selectedLicenses',
      (selectedLicenses: any, schema: any) =>
        hasPrescriberLicenseType(selectedLicenses, [UnitedStates.ILLINOIS])
          ? schema.required('This question is required.')
          : schema
    ),
    prefersToKeepMATPrivate: Yup.string().required(
      'This question is required.'
    ),
  },
  [UnitedStates.FLORIDA.toString()]: {
    offersMATToSubstanceAbuseUseDisorder: Yup.string().when(
      'selectedLicenses',
      (selectedLicenses: any, schema: any) =>
        hasPrescriberLicenseType(selectedLicenses, [UnitedStates.FLORIDA])
          ? schema.required('This question is required.')
          : schema
    ),
    offersMATToAlcoholOpiateOrBoth: Yup.string().when(
      'offersMATToSubstanceAbuseUseDisorder',
      (offersMATToSubstanceAbuseUseDisorder: string, schema: any) =>
        offersMATToSubstanceAbuseUseDisorder === YesNo.YES
          ? schema.required('This question is required.')
          : schema
    ),
  },
} as const;

const ServicesSectionInitialValues = {
  [UnitedStates.ILLINOIS.toString()]: {
    offersBreastfeedingSupport: '',
    offersMATForOpioidUse: '',
    isCounselingProvidedForOpioidUse: '',
    isAuthorizedToDispenseMATForOpioidUse: '',
    prefersToKeepMATPrivate: '',
  },
  [UnitedStates.FLORIDA.toString()]: {
    offersMATToSubstanceAbuseUseDisorder: '',
    offersMATToAlcoholOpiateOrBoth: '',
  },
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Focus Areas',
  description:
    'In this section, we ask you to share details on your practice focus areas.',
  Component: FocusAreas,
  getFormMeta: ({
    provider,
    providerQuestionnaire: { rawData },
    specialties,
  }) => {
    let stateSpecificSchema = {};
    let stateSpecificInitialValues = {};

    getSupportedStates(provider).forEach((state) => {
      stateSpecificSchema = {
        ...stateSpecificSchema,
        ...ServicesSectionSchema[state],
      };
      stateSpecificInitialValues = {
        ...stateSpecificInitialValues,
        ...ServicesSectionInitialValues[state],
      };
    });

    const validationSchema = Yup.object().shape({
      primaryFocusArea: Yup.string().test(
        'is-valid specialty',
        'Primary area of expertise is required',
        (value) => !!value && specialties.map((s) => s.key).includes(value)
      ),
      primaryFocusAreaSource: Yup.string().required(
        'Primary area of expertise source is required.'
      ),
      otherPrimaryFocusAreas: Yup.array().max(
        4,
        'Please select up to a maximum of 4 practice areas.'
      ),
      otherPrimaryFocusAreasSources: Yup.object().when(
        'otherPrimaryFocusAreas',
        (otherPrimaryFocusAreas: string[], schema) => {
          return schema.shape(
            specialties.reduce<{ [key: string]: Yup.Schema<any> }>(
              (agg, specialty) => {
                agg[specialty.key] = otherPrimaryFocusAreas?.find(
                  (item) => item === specialty.key
                )
                  ? Yup.string().required(
                      `A training source for ${specialty.clinicalDisplayName} is required.`
                    )
                  : Yup.string();
                return agg;
              },
              {}
            )
          );
        }
      ),
      otherOtherPrimaryFocusAreasSources: Yup.object().when(
        'otherPrimaryFocusAreasSources',
        (otherPrimaryFocusAreasSources, schema) =>
          schema.shape(
            specialties.reduce<{ [key: string]: Yup.Schema<any> }>(
              (agg, specialty) => {
                agg[specialty.key] =
                  otherPrimaryFocusAreasSources[specialty.key] === 'Other'
                    ? Yup.string().required(
                        `You must specify details on where you received training for ${specialty.clinicalDisplayName}`
                      )
                    : Yup.string();
                return agg;
              },
              {}
            )
          )
      ),
      practiceFocusAreas: Yup.array().of(Yup.string()),
      specialtyTrainingSources: Yup.object().when(
        'practiceFocusAreas',
        (practiceFocusAreas: string[], schema) => {
          return schema.shape(
            specialties.reduce<{ [key: string]: Yup.Schema<any> }>(
              (agg, specialty) => {
                agg[specialty.clinicalDisplayName] = practiceFocusAreas?.find(
                  (item) => item === specialty.clinicalDisplayName
                )
                  ? Yup.string().required(
                      `A training source for ${specialty.clinicalDisplayName} is required.`
                    )
                  : Yup.string();
                return agg;
              },
              {}
            )
          );
        }
      ),
      otherSpecialtyTrainingSources: Yup.object().when(
        'specialtyTrainingSources',
        (specialtyTrainingSources, schema) =>
          schema.shape(
            specialties.reduce<{ [key: string]: Yup.Schema<any> }>(
              (agg, specialty) => {
                agg[specialty.clinicalDisplayName] =
                  specialtyTrainingSources[specialty.clinicalDisplayName] ===
                  'Other'
                    ? Yup.string().required(
                        `You must specify details on where you received training for ${specialty.clinicalDisplayName}`
                      )
                    : Yup.string();
                return agg;
              },
              {}
            )
          )
      ),
      eatingDisorderCertificate: Yup.string().when(
        [
          'primaryFocusArea',
          'primaryFocusAreaSource',
          'otherPrimaryFocusAreas',
          'otherPrimaryFocusAreasSources',
          'practiceFocusAreas',
          'specialtyTrainingSources',
        ],
        {
          is: (
            primaryFocusArea,
            primaryFocusAreaSource,
            otherPrimaryFocusAreas,
            otherPrimaryFocusAreasSources,
            practiceFocusAreas,
            specialtyTrainingSources
          ) => {
            (primaryFocusArea === 'eating_disorders' &&
              primaryFocusAreaSource === 'Certificate') ||
              (!!otherPrimaryFocusAreas &&
                eatingDisorderCertificateRequired(
                  otherPrimaryFocusAreas,
                  otherPrimaryFocusAreasSources
                )) ||
              (!!practiceFocusAreas &&
                eatingDisorderCertificateRequired(
                  practiceFocusAreas,
                  specialtyTrainingSources
                ));
          },

          then: Yup.string().required(
            'Please upload a copy of your certificate'
          ),
          otherwise: Yup.string(),
        }
      ),
      ...stateSpecificSchema,
      ...getBscaSchemaForStep(SectionStepForBscaSpecialty.FocusAreas),
    });

    return {
      validationSchema: validationSchema,
      initialValue: {
        ...stateSpecificInitialValues,
        ...yupSchemaToDefaultValue(validationSchema),
        ...rawData,
      },
    } as FormMeta;
  },
};
export default stepConfig;
