import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Link } from '@headway/helix/Link';
import {
  LEARN_ABOUT_GETTING_CREDENTIALED_LINK,
  LEARN_ABOUT_GETTING_PAID_LINK,
} from '@headway/shared/constants/zendesk';

import { PayerStatus, PayerStatusMap } from './PayerStatus';

type SubheaderContentProps = {
  payerStatusMap: PayerStatusMap;
};

export const Subheader = ({ payerStatusMap }: SubheaderContentProps) => {
  if (
    payerStatusMap.has(PayerStatus.PAYMENT_READY) ||
    payerStatusMap.has(PayerStatus.COMPLETE)
  ) {
    return (
      <BodyText>
        You’re taking appointments with in-network clients from these insurers.{' '}
        <Link href={LEARN_ABOUT_GETTING_PAID_LINK} target="_blank">
          Learn about getting paid.
        </Link>
      </BodyText>
    );
  } else {
    return (
      <BodyText>
        We’re working hard to get you up and running as soon as we can. It
        usually takes somewhere between 3 weeks and 4 months, and some insurers
        can be faster than others.{' '}
        <Link href={LEARN_ABOUT_GETTING_CREDENTIALED_LINK} target="_blank">
          Learn about getting credentialed.
        </Link>
      </BodyText>
    );
  }
};
