import React from 'react';

import { CustomerType } from '@headway/api/models/CustomerType';
import { PaymentsBonusesSecondaryIssueType } from '@headway/api/models/PaymentsBonusesSecondaryIssueType';
import { ProviderIssueType } from '@headway/api/models/ProviderIssueType';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

type PaymentsDownloadErrorModalProps = {
  isModalOpen: boolean;
  allTransactionsRequested: boolean;
  onDismiss: () => void;
};

export const PaymentsDownloadErrorModal: React.FC<
  React.PropsWithChildren<PaymentsDownloadErrorModalProps>
> = ({ isModalOpen, allTransactionsRequested, onDismiss }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      title={'Export size too large'}
      onDismiss={onDismiss}
    >
      <ModalContent>
        {`${
          allTransactionsRequested
            ? 'Try selecting a shorter time period or c'
            : 'C'
        }ontact 
                us to get a full copy of your payment transactions.`}
      </ModalContent>
      <ModalFooter>
        <LinkButton
          variant="primary"
          href={`${process.env.REACT_APP_MAIN_URL}/contact?submitterType=${CustomerType.PROVIDER_ON_BEHALF_OF_SELF}&primaryIssueType=${ProviderIssueType.PAYMENTS_OR_BONUSES}&secondaryIssueType=${PaymentsBonusesSecondaryIssueType.SESSION_PAYOUTS}`}
        >
          Contact us
        </LinkButton>
      </ModalFooter>
    </Modal>
  );
};
