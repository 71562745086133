import * as React from 'react';

export const HighlightHeadwayRelationship = (
  props: React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M14.413 42.329c7.408 0 13.413-6.005 13.413-13.413S21.82 15.503 14.413 15.503 1 21.508 1 28.916s6.005 13.413 13.413 13.413z"
      fill="#D7F7E5"
    />
    <path
      d="M38.05 29.719H13.916l-.234-.405a14.192 14.192 0 01-1.906-7.106C11.776 14.375 18.148 8 25.984 8s14.208 6.375 14.208 14.208c0 2.493-.658 4.95-1.906 7.106l-.233.405h-.003zM14.858 28.1h22.248a12.584 12.584 0 001.463-5.89c0-6.942-5.646-12.588-12.587-12.588-6.942 0-12.588 5.646-12.588 12.588 0 2.055.504 4.08 1.464 5.89z"
      fill="#128656"
    />
  </svg>
);
