import { View } from 'react-big-calendar';

import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { EventFilterFunction } from '.';

export interface CalendarViewData {
  calendarView?: View | string;
}

export const filterByCalendarView: EventFilterFunction<CalendarViewData> = (
  event: ProviderEventRead,
  filterData: CalendarViewData
) => {
  return (
    filterData.calendarView === 'month' &&
    event.channel === ProviderEventChannel.EXTERNAL_CALENDAR
  );
};
