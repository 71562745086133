import { Divider, Drawer } from '@mui/material';
import startCase from 'lodash/startCase';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { useMediaQuery } from '@headway/helix/utils';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Button } from '@headway/ui';
import { Modal } from '@headway/ui/Modal';
import { theme } from '@headway/ui/theme';

import { getEventNameFromEnum } from 'utils/analytics';

import { WizardContext } from './context';

export interface WizardStepProps {
  /** Main content shown on this wizard step. */
  primaryContent: ReactNode;
  /** Optional content to be displayed in a sidebar. */
  secondaryContent?: ReactNode;
  /**
   * If this prop is defined, then the user will be prevented from advancing to the next step of the
   * wizard until it is true. If the prop is not defined, then the step is considered optional.
   */
  areRequirementsMet?: boolean;
  /**
   * Optional callback that is invoked when user-created data in the step should be saved.
   * This occurs when the user completes and changes steps. Validation occurs when calling this callback,
   * returns a promise, and is awaited before continuing.
   */
  onSaveAndContinue?: () => Promise<void>;
  /** Overrides the default width of the wizard for this step. */
  width?: number;
  /** Overrides the default height of the wizard for this step. */
  height?: number;
  secondaryContentWrapperCss?: any;
  /**
   * Optional callback that is invoked when any user-created data in the step should be saved.
   * This occurs if the user saves and quits or goes back a step, bypassing any validations.
   * The callback can return a promise, which will be awaited before continuing.
   */
  onSaveAndExit?: () => Promise<void>;
}

/**
 * UI for a single step in a wizard. The component handles rendering of wizard controls (e.g. back
 * and next buttons) as well as layout.
 */
export const WizardStep = ({
  primaryContent,
  secondaryContent,
  areRequirementsMet,
  onSaveAndContinue,
  width,
  height,
  secondaryContentWrapperCss,
  onSaveAndExit,
}: WizardStepProps) => {
  const {
    currentStep,
    totalSteps,
    title,
    module,
    setStep,
    setWidthOverride,
    setHeightOverride,
  } = useContext(WizardContext);

  useEffect(() => {
    setWidthOverride(width);
    setHeightOverride(height);
  }, [setHeightOverride, setWidthOverride, width, height]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveAndExitDialogOpen, setIsSaveAndExitDialogOpen] =
    useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const saveThenRun = async (callback: () => void, isDraft?: boolean) => {
    if (onSaveAndContinue) {
      setIsSaving(true);
      try {
        if (isDraft && onSaveAndExit) {
          await onSaveAndExit();
        } else {
          await onSaveAndContinue();
        }
        callback();
      } finally {
        setIsSaving(false);
      }
    } else {
      callback();
    }
  };

  const handleBack = () => {
    saveThenRun(() => setStep(currentStep - 1), true);
  };
  const handleNext = () => {
    if (currentStep + 1 === totalSteps) {
      trackEvent({
        name: `Setup ${
          getEventNameFromEnum(module) as
            | 'Availability'
            | 'Payment'
            | 'Port Patients'
            | 'Profile'
        } Completed`,
        properties: {
          screenName: `Setup ${getEventNameFromEnum(module)} Completed`,
        },
      });
    } else {
      trackAnalyticsEvent('continued');
    }
    return saveThenRun(() => setStep(currentStep + 1));
  };

  const handleSaveAndExit = () => {
    saveThenRun(() => setIsSaveAndExitDialogOpen(true), true);
  };

  const trackAnalyticsEvent = (stepName: string) =>
    trackEvent({
      name: `Setup ${
        getEventNameFromEnum(module) as
          | 'Availability'
          | 'Payment'
          | 'Port Patients'
          | 'Profile'
      } Step`,
      properties: {
        screenName: `${startCase(title)}: Step ${currentStep + 1}`,
        stepName,
      },
    });

  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);

  return (
    <div
      css={{
        height: 'calc(100% - 5px)',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
      }}
    >
      <div
        css={{
          flexBasis: '50%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '100%',
        }}
      >
        <div
          css={{
            padding: theme.space.xl3,
            flexGrow: 1,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            css={{
              marginTop: 0,
              marginBottom: theme.space.lg,
              display: 'flex',
            }}
          >
            <h4 css={{ color: theme.color.black, margin: 0 }}>{title}</h4>
            <span css={{ flexGrow: 1 }}></span>
            {secondaryContent && isSmall && (
              <Button
                variant="outlined"
                onClick={() => setIsDrawerOpen(true)}
                css={{ marginRight: theme.space.sm }}
              >
                Tips
              </Button>
            )}
            <Button
              variant="outlined"
              onClick={handleSaveAndExit}
              disabled={isSaving}
            >
              Save &amp; Exit
            </Button>
          </div>
          <p
            css={{
              marginBottom: theme.space.xs2,
              color: theme.color.textGray,
            }}
          >
            STEP {currentStep + 1} OF {totalSteps}
            {areRequirementsMet === undefined && ' — OPTIONAL'}
          </p>
          {primaryContent}
        </div>
        <Divider />
        <div
          css={{
            padding: `${theme.space.lg} ${theme.space.xl3}`,
            display: 'flex',
          }}
        >
          {currentStep > 0 && (
            <Button size="large" onClick={handleBack} disabled={isSaving}>
              Back
            </Button>
          )}
          <span css={{ flexGrow: 1 }}></span>
          <Button
            data-testid="nextButton"
            variant="contained"
            onClick={handleNext}
            size="large"
            disabled={areRequirementsMet === false || isSaving}
          >
            Next
          </Button>
        </div>
      </div>
      {secondaryContent &&
        (isSmall ? (
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            PaperProps={{
              style: {
                width: '75%',
              },
            }}
          >
            <div
              css={{
                height: '100%',
                backgroundColor: theme.color.primaryBackground,
                padding: theme.space.xl2,
              }}
            >
              {secondaryContent}
            </div>
          </Drawer>
        ) : (
          <div
            css={{
              flexBasis: '350px',
              backgroundColor: theme.color.primaryBackground,
              padding: theme.space.xl2,
              ...secondaryContentWrapperCss,
            }}
          >
            {secondaryContent}
          </div>
        ))}
      <Modal
        open={isSaveAndExitDialogOpen}
        onClose={() => setIsSaveAndExitDialogOpen(false)}
        closeable={false}
        maxWidth="xs"
      >
        <div
          css={{
            padding: theme.space.lg,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h5 css={{ marginBottom: theme.space.base }}>
            Are you sure you want to exit?
          </h5>
          <p css={{ marginBottom: theme.space.xl3 }}>
            Your progress is saved, so you can come back anytime.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsSaveAndExitDialogOpen(false)}
          >
            Keep going
          </Button>
          <Button
            variant="outlined"
            color="primary"
            href="/"
            css={{
              marginTop: theme.space.sm,
              marginBottom: theme.space.sm,
            }}
            onClick={() => trackAnalyticsEvent('exited')}
          >
            Save and exit
          </Button>
          <p
            css={{
              marginTop: theme.space.sm,
              color: theme.color.textGray,
            }}
          >
            Questions? Let your Headway representative know so they can help.
          </p>
        </div>
      </Modal>
    </div>
  );
};
