import moment from 'moment';

export const getDayOccurrenceOfMonth = (startDate: string) => {
  const dayOfMonth = moment(startDate).date();
  return Math.floor((dayOfMonth - 1) / 7) + 1;
};

export const getDayOccurrenceOfMonthString = (startDate: string) => {
  const occurrenceMap = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'last',
  };
  const occurance = getDayOccurrenceOfMonth(
    startDate
  ) as keyof typeof occurrenceMap;

  return occurrenceMap[occurance];
};
