import invert from 'lodash/invert';

import { UnitedStates } from '@headway/api/models/UnitedStates';

export const statesToDisplayNames: Record<UnitedStates, string> = {
  [UnitedStates.ALABAMA]: 'Alabama',
  [UnitedStates.ALASKA]: 'Alaska',
  [UnitedStates.ARIZONA]: 'Arizona',
  [UnitedStates.ARKANSAS]: 'Arkansas',
  [UnitedStates.CALIFORNIA]: 'California',
  [UnitedStates.COLORADO]: 'Colorado',
  [UnitedStates.CONNECTICUT]: 'Connecticut',
  [UnitedStates.DELAWARE]: 'Delaware',
  [UnitedStates.DISTRICT_OF_COLUMBIA]: 'District of Columbia',
  [UnitedStates.FLORIDA]: 'Florida',
  [UnitedStates.GEORGIA]: 'Georgia',
  [UnitedStates.HAWAII]: 'Hawaii',
  [UnitedStates.IDAHO]: 'Idaho',
  [UnitedStates.ILLINOIS]: 'Illinois',
  [UnitedStates.INDIANA]: 'Indiana',
  [UnitedStates.IOWA]: 'Iowa',
  [UnitedStates.KANSAS]: 'Kansas',
  [UnitedStates.KENTUCKY]: 'Kentucky',
  [UnitedStates.LOUISIANA]: 'Louisiana',
  [UnitedStates.MAINE]: 'Maine',
  [UnitedStates.MARYLAND]: 'Maryland',
  [UnitedStates.MASSACHUSETTS]: 'Massachusetts',
  [UnitedStates.MICHIGAN]: 'Michigan',
  [UnitedStates.MINNESOTA]: 'Minnesota',
  [UnitedStates.MISSISSIPPI]: 'Mississippi',
  [UnitedStates.MISSOURI]: 'Missouri',
  [UnitedStates.MONTANA]: 'Montana',
  [UnitedStates.NEBRASKA]: 'Nebraska',
  [UnitedStates.NEVADA]: 'Nevada',
  [UnitedStates.NEW_HAMPSHIRE]: 'New Hampshire',
  [UnitedStates.NEW_JERSEY]: 'New Jersey',
  [UnitedStates.NEW_MEXICO]: 'New Mexico',
  [UnitedStates.NEW_YORK]: 'New York',
  [UnitedStates.NORTH_CAROLINA]: 'North Carolina',
  [UnitedStates.NORTH_DAKOTA]: 'North Dakota',
  [UnitedStates.OHIO]: 'Ohio',
  [UnitedStates.OKLAHOMA]: 'Oklahoma',
  [UnitedStates.OREGON]: 'Oregon',
  [UnitedStates.PENNSYLVANIA]: 'Pennsylvania',
  [UnitedStates.RHODE_ISLAND]: 'Rhode Island',
  [UnitedStates.SOUTH_CAROLINA]: 'South Carolina',
  [UnitedStates.SOUTH_DAKOTA]: 'South Dakota',
  [UnitedStates.TENNESSEE]: 'Tennessee',
  [UnitedStates.TEXAS]: 'Texas',
  [UnitedStates.UTAH]: 'Utah',
  [UnitedStates.VERMONT]: 'Vermont',
  [UnitedStates.VIRGINIA]: 'Virginia',
  [UnitedStates.WASHINGTON]: 'Washington',
  [UnitedStates.WEST_VIRGINIA]: 'West Virginia',
  [UnitedStates.WISCONSIN]: 'Wisconsin',
  [UnitedStates.WYOMING]: 'Wyoming',
  [UnitedStates.PUERTO_RICO]: 'Puerto Rico',
};

export const unitedStatesAbbreviations: Record<UnitedStates, string> = {
  [UnitedStates.ALASKA]: 'AK',
  [UnitedStates.ALABAMA]: 'AL',
  [UnitedStates.ARKANSAS]: 'AR',
  [UnitedStates.ARIZONA]: 'AZ',
  [UnitedStates.CALIFORNIA]: 'CA',
  [UnitedStates.COLORADO]: 'CO',
  [UnitedStates.CONNECTICUT]: 'CT',
  [UnitedStates.DISTRICT_OF_COLUMBIA]: 'DC',
  [UnitedStates.DELAWARE]: 'DE',
  [UnitedStates.FLORIDA]: 'FL',
  [UnitedStates.GEORGIA]: 'GA',
  [UnitedStates.HAWAII]: 'HI',
  [UnitedStates.IOWA]: 'IA',
  [UnitedStates.IDAHO]: 'ID',
  [UnitedStates.ILLINOIS]: 'IL',
  [UnitedStates.INDIANA]: 'IN',
  [UnitedStates.KANSAS]: 'KS',
  [UnitedStates.KENTUCKY]: 'KY',
  [UnitedStates.LOUISIANA]: 'LA',
  [UnitedStates.MASSACHUSETTS]: 'MA',
  [UnitedStates.MARYLAND]: 'MD',
  [UnitedStates.MAINE]: 'ME',
  [UnitedStates.MICHIGAN]: 'MI',
  [UnitedStates.MINNESOTA]: 'MN',
  [UnitedStates.MISSOURI]: 'MO',
  [UnitedStates.MISSISSIPPI]: 'MS',
  [UnitedStates.MONTANA]: 'MT',
  [UnitedStates.NORTH_CAROLINA]: 'NC',
  [UnitedStates.NORTH_DAKOTA]: 'ND',
  [UnitedStates.NEBRASKA]: 'NE',
  [UnitedStates.NEW_HAMPSHIRE]: 'NH',
  [UnitedStates.NEW_JERSEY]: 'NJ',
  [UnitedStates.NEW_MEXICO]: 'NM',
  [UnitedStates.NEVADA]: 'NV',
  [UnitedStates.NEW_YORK]: 'NY',
  [UnitedStates.OHIO]: 'OH',
  [UnitedStates.OKLAHOMA]: 'OK',
  [UnitedStates.OREGON]: 'OR',
  [UnitedStates.PENNSYLVANIA]: 'PA',
  [UnitedStates.RHODE_ISLAND]: 'RI',
  [UnitedStates.SOUTH_CAROLINA]: 'SC',
  [UnitedStates.SOUTH_DAKOTA]: 'SD',
  [UnitedStates.TENNESSEE]: 'TN',
  [UnitedStates.TEXAS]: 'TX',
  [UnitedStates.UTAH]: 'UT',
  [UnitedStates.VIRGINIA]: 'VA',
  [UnitedStates.VERMONT]: 'VT',
  [UnitedStates.WASHINGTON]: 'WA',
  [UnitedStates.WISCONSIN]: 'WI',
  [UnitedStates.WEST_VIRGINIA]: 'WV',
  [UnitedStates.WYOMING]: 'WY',
  [UnitedStates.PUERTO_RICO]: 'PR',
} as const;

export const displayNameToUsStateEnum = invert(statesToDisplayNames);
export const abbreviationToStateEnum = invert(unitedStatesAbbreviations);
export default statesToDisplayNames;
