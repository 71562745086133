import { FormLabel } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';

import { StripeAccountCreateRequest } from '@headway/api/models/StripeAccountCreateRequest';
import { StripeAccountType } from '@headway/api/models/StripeAccountType';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { theme } from '@headway/helix/theme';
import { Radio } from '@headway/ui';
import { FieldControl, FieldRadioGroup } from '@headway/ui/form';

interface UpdateStripeAccountFormProps {
  onSubmit: (values: Omit<StripeAccountCreateRequest, 'providerId'>) => void;
  onCancel: () => void;
  initialValues: Omit<StripeAccountCreateRequest, 'providerId'>;
}

export const UpdateStripeAccountForm: React.FC<
  React.PropsWithChildren<UpdateStripeAccountFormProps>
> = ({ onSubmit, onCancel, initialValues }) => {
  return (
    <Formik<Omit<StripeAccountCreateRequest, 'providerId'>>
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <FieldControl name="type" component="fieldset">
              <FormLabel css={{ margin: 0 }} component="legend">
                Account type
              </FormLabel>
              <FieldRadioGroup name="type">
                <RadioWithDescription
                  label="Company"
                  value={StripeAccountType.COMPANY}
                  description="Choose “Company” if your practice is a registered business entity (i.e. Corporation, Partnership, or Multi-Member LLC.). Your Employer ID Number (EIN) and information about your business will verify your identity."
                />
                <RadioWithDescription
                  label="Individual"
                  value={StripeAccountType.INDIVIDUAL}
                  description="Choose “Individual” if you are a solo practitioner or own a Single Member LLC. You’ll use your Social Security number to verify your identity. If you are a sole proprietor and you have an EIN, you may enter either your SSN or EIN when prompted for your SSN. If you own a Single Member LLC that is disregarded you must enter your SSN (see IRS Form W-9 Part 1 instructions for further information)."
                />
              </FieldRadioGroup>
            </FieldControl>

            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: theme.spacing.x2,
              }}
            >
              <Button
                variant="secondary"
                disabled={isSubmitting}
                onPress={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" disabled={isSubmitting} type="submit">
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
// not guaranteed unique but likely good enough for our needs.  Collisions here would result in duplicate ids
// and some a11y confusion, but the app would still function.
function uuid() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}

interface RadioWithDescriptionProps extends React.ComponentProps<typeof Radio> {
  description: React.ReactNode;
}

const RadioWithDescription: React.FC<
  React.PropsWithChildren<RadioWithDescriptionProps>
> = ({ description, ...rest }) => {
  const descriptionId = React.useRef(uuid());

  return (
    <div
      css={{
        '& .MuiFormControlLabel-label': {
          fontWeight: 'bold',
        },
        '& .radio-description': {
          marginLeft: 27, // control width + 1/2 control padding
        },
      }}
    >
      <Radio
        inputProps={{
          'aria-describedby': descriptionId.current,
        }}
        {...rest}
      />
      <p
        id={descriptionId.current}
        className="radio-description"
        css={{ margin: 0 }}
      >
        {description}
      </p>
    </div>
  );
};
