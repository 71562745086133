import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React from 'react';

import { FieldControlContext } from '@headway/ui/form/FieldControl';

import { Dropzone } from '../Dropzone';

export const FieldDropzone = ({ onDrop, accept, ...props }) => {
  const { name } = React.useContext(FieldControlContext);
  const { setFieldValue, setFieldTouched, setFieldError, values } =
    useFormikContext();

  // Using lodash get in case field name is deeply nested
  // i.e. licenses[0]licenseUploadUrl
  const field = get(values, name);
  const hasUploaded = Array.isArray(field) ? field.length : !!field;
  const uploadedFileName = Array.isArray(field)
    ? field.length === 1
      ? field[0]?.name
      : field?.map((f) => f.name)
    : field?.name;
  const aria = {};
  if (!props['aria-label']) {
    aria['aria-labelledby'] = `${name}-label`;
  }
  return (
    <Dropzone
      {...props}
      {...aria}
      accept={accept}
      onDrop={async (files) => {
        try {
          setFieldError(name, false);
          const value = await onDrop(files);
          setFieldValue(name, value);
        } catch (error) {
          setFieldTouched(name, true);
          setFieldError(name, 'Error uploading file, please try again.');
        }
      }}
      hasUploaded={hasUploaded}
      uploadedFileName={uploadedFileName}
    />
  );
};
