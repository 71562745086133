import React from 'react';

import { MaxWidthContainer } from './MaxWidthContainer';
import { theme } from './theme';

export const TitledSection = ({ title, action, children, ...rest }) => (
  <MaxWidthContainer
    css={{
      display: 'flex',
      flexDirection: 'column',
      [theme.media.small]: {
        flexDirection: 'row',
      },
    }}
    {...rest}
  >
    <div
      css={{
        flexShrink: 0,
        marginBottom: theme.space.xl2,
        [theme.media.small]: {
          width: '33%',
          marginBottom: 0,
          marginRight: theme.space.base,
        },
      }}
    >
      <h2
        css={{
          marginTop: 0,
          marginBottom: theme.space.xl,
          fontSize: theme.fontSize.xl2,
          color: theme.color.primary,
          textAlign: 'center',
          [theme.media.small]: {
            textAlign: 'left',
          },
        }}
      >
        {title}
      </h2>
      <div css={{ display: 'none', [theme.media.small]: { display: 'block' } }}>
        {action}
      </div>
    </div>
    <div css={{ flexGrow: 1 }}>{children}</div>
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.space.xl6,
        [theme.media.small]: { display: 'none' },
      }}
    >
      {action}
    </div>
  </MaxWidthContainer>
);
