import * as React from 'react';

export const HighlightLocation = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M25.278 28.108l15.033 8.767c.763.455.763 1.198 0 1.653l-14.675 8.475c-.56.454-1.471.454-2.03 0L8.571 38.235c-.763-.455-.763-1.198 0-1.653l14.676-8.474c.559-.455 1.47-.455 2.03 0z"
      fill="#DCF3FF"
    />
    <path
      d="M24.44 2c-7.06 0-12.804 5.743-12.804 12.804 0 5.61 7.841 17.687 11.214 22.618a1.925 1.925 0 003.181 0c3.37-4.93 11.211-17.009 11.211-22.618C37.242 7.744 31.499 2 24.438 2h.003zm.256 34.508a.301.301 0 01-.256.134.304.304 0 01-.256-.134c-10.142-14.837-10.93-20.365-10.93-21.704 0-6.168 5.018-11.186 11.187-11.186 6.168 0 11.186 5.018 11.186 11.186 0 1.34-.788 6.867-10.93 21.704zM24.44 6.87c-4.311 0-7.82 3.51-7.82 7.821 0 1.372.362 2.724 1.048 3.912l.234.405h13.075l.233-.405a7.819 7.819 0 001.05-3.912c0-4.312-3.51-7.82-7.822-7.82h.003zm5.585 10.515H18.856a6.201 6.201 0 1111.169 0z"
      fill="#128656"
    />
  </svg>
);
