/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ChartReviewItemStatus {
    MISSING_DOCUMENT_REQUEST = 'MISSING_DOCUMENT_REQUEST',
    NEEDS_REVIEW = 'NEEDS_REVIEW',
    SUBMITTED_WITHOUT_ADDENDUM = 'SUBMITTED_WITHOUT_ADDENDUM',
    SUBMITTED_WITH_ADDENDUM = 'SUBMITTED_WITH_ADDENDUM',
    SUBMITTED = 'SUBMITTED',
    REMEDIATION_FAILED_AUDIT = 'REMEDIATION_FAILED_AUDIT',
    REMEDIATION_PASSED_AUDIT = 'REMEDIATION_PASSED_AUDIT'
}
