import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { AgeGroupRead } from '@headway/api/models/AgeGroupRead';
import { InsuranceWithPatientsToTransfer } from '@headway/api/models/InsuranceWithPatientsToTransfer';
import { ProviderFrontEndCarrierNested } from '@headway/api/models/ProviderFrontEndCarrierNested';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { AgeGroupApi } from '@headway/api/resources/AgeGroupApi';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { hasPrescriberLicenseType } from '@headway/shared/utils/licenseHelper';
import { isProviderInState } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { YesNo } from '../../../utils/providerQuestionnaire';
import { BlueCrossCaliforniaFollowupQuestions } from '../blueCrossCalifornia/BlueCrossCaliforniaFollowupQuestions';
import {
  BscaSpecialties,
  SectionStepForBscaSpecialty,
} from '../blueCrossCalifornia/constants';
import { getSchemaForStep as getBscaSchemaForStep } from '../blueCrossCalifornia/schema';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const allHighRiskPopulations = [
  'Suicidal patients',
  'Patients who use illicit substances or misuse prescribed medications',
  'Patients who exhibit self-injurious behavior',
  'Patients with active psychosis',
  'Patients with violent behavior',
];

const numberOfClientsRange = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1 - 5',
    value: '1',
  },
  {
    label: '6 - 15',
    value: '6',
  },
  {
    label: '16 - 25',
    value: '16',
  },
  {
    label: 'Over 25',
    value: '25',
  },
];

const numberOfClientsPerInsuranceRange = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1 - 5',
    value: 1,
  },
  {
    label: '6 and over',
    value: 6,
  },
];

const infantsAndChildrenPopulationNames = ['Infants / Toddlers', 'Children'];
const seniorsPopulationName = 'Seniors';

export const CurrentPatients = ({ formikHelpers }: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const [ageGroups, setAgeGroups] = useState<AgeGroupRead[]>([]);

  useEffect(() => {
    const fetchAgeGroups = async () => {
      try {
        const ageGroupsRes = await AgeGroupApi.getAgeGroups();
        setAgeGroups(sortBy(ageGroupsRes, 'minAge'));
      } catch (err) {
        notifyError(
          'Could not load client population data. Please refresh the page to continue.'
        );
        logException(err);
      }
    };

    fetchAgeGroups();
  }, []);

  const isNonPrescriberTreatingInfantsOrChildrenAndSeniors =
    !hasPrescriberLicenseType(formikHelpers.values?.selectedLicenses) &&
    !!formikHelpers.values?.practicePopulations?.filter(
      (practicePopulation: string) =>
        infantsAndChildrenPopulationNames.includes(practicePopulation)
    ).length &&
    formikHelpers.values?.practicePopulations?.includes(seniorsPopulationName);

  return (
    <div css={{ ...theme.stack.vertical, gap: theme.spacing.x8 }}>
      <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
        <h2>
          <SectionHeader>Client Population</SectionHeader>
        </h2>
        <FormControl
          name="practicePopulations"
          component={CheckboxGroup}
          label="Please select the populations you work with."
        >
          {ageGroups.map((ageGroup, index) => {
            return (
              <Checkbox key={ageGroup.id} value={ageGroup.displayName}>{`${
                ageGroup.displayName
              } (${ageGroup.minAge}${
                index === ageGroups.length - 1 ? `+` : `-${ageGroup.maxAge}`
              })`}</Checkbox>
            );
          })}
        </FormControl>
        {isNonPrescriberTreatingInfantsOrChildrenAndSeniors && (
          <FormControl
            component={Checkbox}
            name="supportInfantsOrChildrenAndSeniorsAttestion"
          >
            I attest that I specialize in treating the selected patient
            populations and can provide sound care to the listed age ranges.
          </FormControl>
        )}
        <FormControl
          name="practiceHighRiskPopulationSupport"
          component={RadioGroup}
          label="Do you work with high-risk patients in your private practice?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values?.practiceHighRiskPopulationSupport ===
          YesNo.YES && (
          <React.Fragment>
            <FormControl
              name="practiceHighRiskPopulations"
              component={CheckboxGroup}
              label="Which of the following high-risk populations do you work with?"
            >
              {allHighRiskPopulations.map(
                (population: string, index: number) => {
                  return (
                    <Checkbox key={population} value={population}>
                      {population}
                    </Checkbox>
                  );
                }
              )}
            </FormControl>
            <FormControl
              name="otherPracticeHighRiskPopulations"
              component={TextField}
              label="Other high-risk patient populations"
            />
            <FormControl
              component={Checkbox}
              name="practiceHighRiskPopulationAffirmation"
            >
              I affirm that I am treating these clients in accordance with
              professional and licensing guidelines.
            </FormControl>
          </React.Fragment>
        )}
        <FormControl
          name="individualPatients"
          component={RadioGroup}
          label="On average, how many clients are you currently seeing in your
          private practice?"
        >
          {numberOfClientsRange.map((item) => {
            return <Radio value={item.value}>{item.label}</Radio>;
          })}
        </FormControl>
        <FormControl
          name="individualSelfPayPatients"
          component={RadioGroup}
          label="Of these - how many are private pay clients?"
        >
          {numberOfClientsRange.map((item) => {
            return <Radio value={item.value}>{item.label}</Radio>;
          })}
        </FormControl>
        <FormControl
          name="plansToPortPatients"
          component={RadioGroup}
          label="Do you plan to see any of your current clients through Headway? If
          you do, our team is available to support you in making your
          transition as smooth as possible."
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values?.plansToPortPatients === YesNo.YES && (
          <React.Fragment>
            <SubBodyText>
              If so, approximately how many of your current clients do you plan
              to see on Headway through the insurance plans we're credentialing
              you with? Please note that Headway does not currently accept
              Medicare or Medicaid plans.
            </SubBodyText>
            {formikHelpers.values?.insurancesWithPatientsToTransfer?.map(
              (iwptt: InsuranceWithPatientsToTransfer, index: number) => (
                <FormControl
                  component={Select}
                  name={`insurancesWithPatientsToTransfer[${index}].numberOfClients`}
                  label={iwptt.name}
                  selectionMode="single"
                  menuWidth="stretch"
                >
                  {numberOfClientsPerInsuranceRange.map((option) => (
                    <Item key={option.value}>{option.label}</Item>
                  ))}
                </FormControl>
              )
            )}
          </React.Fragment>
        )}
      </div>
      <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
        <h2>
          <SectionHeader>Client Billing</SectionHeader>
        </h2>
        <FormControl
          name="privatePayPatientRate"
          component={TextField}
          label="For private pay clients, what is your rate per session?"
        />
        <FormControl
          name="privatePaySlidingScale"
          component={RadioGroup}
          label="Do you use a sliding scale for any clients?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values?.privatePaySlidingScale === YesNo.YES ? (
          <FormControl
            name="privatePaySlidingScaleRate"
            component={TextField}
            label="What is the minimum rate on your sliding scale?"
          />
        ) : null}

        <FormControl
          name="otherReferralPlatforms"
          component={TextField}
          label=" Please list any referral platforms (such as Psychology Today) that
          you are listed on."
        />
        {isProviderInState(provider, UnitedStates.CALIFORNIA) && (
          <BlueCrossCaliforniaFollowupQuestions
            values={formikHelpers.values}
            stepForBscaSpecialty={SectionStepForBscaSpecialty.CurrentClients}
            specialtyKeys={[
              BscaSpecialties.InfantsToddlers,
              BscaSpecialties.Children,
              BscaSpecialties.Adolescents,
              BscaSpecialties.Seniors,
            ]}
          />
        )}
      </div>
    </div>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Current Clients',
  description:
    "Tell us about your practice so we can be most helpful with next steps. This information won't impact your request to join Headway — it allows us to get you set up in a way that’s best for your practice.",
  Component: CurrentPatients,
  onBeforeSubmit: (values) => {
    values.insurancesWithPatientsToTransfer =
      values.plansToPortPatients === YesNo.YES
        ? values.insurancesWithPatientsToTransfer
        : values.insurancesWithPatientsToTransfer?.map((ins) => ({
            ...ins,
            numberOfClients: '0',
          }));
    return true;
  },
  getFormMeta: ({ provider, providerQuestionnaire }) => {
    const selectedLicenses = providerQuestionnaire.rawData?.selectedLicenses;
    const validationSchema = Yup.object().shape({
      practicePopulations: Yup.array().of(Yup.string()),
      supportInfantsOrChildrenAndSeniorsAttestion: Yup.boolean().when(
        'practicePopulations',
        (practicePopulations, schema) =>
          !hasPrescriberLicenseType(selectedLicenses) &&
          practicePopulations.filter((practicePopulation: string) =>
            infantsAndChildrenPopulationNames.includes(practicePopulation)
          ).length &&
          practicePopulations.includes(seniorsPopulationName)
            ? schema.oneOf(
                [true],
                'You must read and agree to the statement above.'
              )
            : schema
      ),
      practiceHighRiskPopulationSupport: Yup.string().required(
        'This question is required.'
      ),
      practiceHighRiskPopulations: Yup.array()
        .of(Yup.string().required('This cannot be blank.'))
        .when(
          [
            'practiceHighRiskPopulationSupport',
            'otherPracticeHighRiskPopulations',
          ],
          (
            practiceHighRiskPopulationSupport,
            otherPracticeHighRiskPopulations,
            schema
          ) =>
            practiceHighRiskPopulationSupport === YesNo.YES &&
            !otherPracticeHighRiskPopulations
              ? schema.required(
                  'You must select a population or type one in below.'
                )
              : schema
        ),
      otherPracticeHighRiskPopulations: Yup.string(),
      practiceHighRiskPopulationAffirmation: Yup.boolean().when(
        'practiceHighRiskPopulationSupport',
        (practiceHighRiskPopulationSupport, schema) =>
          practiceHighRiskPopulationSupport === YesNo.YES
            ? schema.oneOf(
                [true],
                'You must read and agree to the statement above.'
              )
            : schema
      ),
      individualPatients: Yup.string()
        .nullable()
        .required('This question is required.'),
      individualSelfPayPatients: Yup.string()
        .nullable()
        .required('This question is required.'),
      privatePayPatientRate: Yup.string().required(
        'This question is required.'
      ),
      privatePaySlidingScale: Yup.string().required(
        'This question is required.'
      ),
      privatePaySlidingScaleRate: Yup.string().when(
        'privatePaySlidingScale',
        (privatePaySlidingScale, schema) =>
          privatePaySlidingScale === YesNo.YES
            ? schema.required('This question is required.')
            : schema
      ),
      otherReferralPlatforms: Yup.string(),
      insurancesWithPatientsToTransfer: Yup.array().of(
        Yup.object().shape({
          numberOfClients: Yup.string().when(
            'plansToPortPatients',
            (plansToPortPatients, schema) =>
              plansToPortPatients === YesNo.YES
                ? schema.required('This question is required.')
                : schema
          ),
        })
      ),
      plansToPortPatients: Yup.string().required('This question is required.'),
      ...getBscaSchemaForStep(
        SectionStepForBscaSpecialty.CurrentClients,
        selectedLicenses
      ),
    });

    // get FECs from PFECs
    const pfecsByFrontEndCarrierId: Map<
      number,
      ProviderFrontEndCarrierNested[]
    > = new Map();
    provider.providerFrontEndCarriers?.forEach((pfec) => {
      if (pfecsByFrontEndCarrierId.has(pfec.frontEndCarrier.id)) {
        pfecsByFrontEndCarrierId.get(pfec.frontEndCarrier.id)!.push(pfec);
      } else {
        pfecsByFrontEndCarrierId.set(pfec.frontEndCarrier.id, [pfec]);
      }
    });
    // PFECs from the same key have the same FEC, so we can just take the first one
    const frontEndCarriers = Array.from(pfecsByFrontEndCarrierId.values())
      .map((pfecs) => pfecs[0].frontEndCarrier)
      .sort((fec1, fec2) => fec1.name.localeCompare(fec2.name));

    const insurancesWithPatientsToTransfer = frontEndCarriers.map(
      (frontEndCarrier) => ({
        id: frontEndCarrier.id,
        salesforceId: frontEndCarrier.salesforceId,
        name: frontEndCarrier.name,
        numberOfClients: '0',
      })
    );

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        {
          insurancesWithPatientsToTransfer,
        },
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;
