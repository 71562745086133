import { ControlledSubstance } from '@headway/api/models/ControlledSubstance';
import { ComboBox, Item, Section } from '@headway/helix/ComboBox';
import {
  BENZODIAZEPINES,
  BUPRENORPHINE_DERIVATIVES,
  COMMONLY_PRESCRIBED,
  CONTROLLED_SUBSTANCE_DISPLAY_NAMES,
  NON_BENZODIAZEPINE_HYPNOTICS,
  NON_BENZODIAZEPINES,
  OTHER,
  STIMULANTS,
} from '@headway/shared/constants/controlledSubstances';

const createOptionCategory = (
  categoryLabel: string,
  substances: ControlledSubstance[]
) => {
  return {
    categoryLabel,
    items: substances.map((substance) => ({
      label: CONTROLLED_SUBSTANCE_DISPLAY_NAMES[substance],
      value: substance,
    })),
  };
};

const OPTIONS = [
  createOptionCategory('Commonly Prescribed', COMMONLY_PRESCRIBED),
  createOptionCategory('Stimulants', STIMULANTS),
  createOptionCategory('Benzodiazepines', BENZODIAZEPINES),
  createOptionCategory('Non-Benzodiazepines', NON_BENZODIAZEPINES),
  createOptionCategory(
    'Non-Benzodiazepine Hypnotics',
    NON_BENZODIAZEPINE_HYPNOTICS
  ),
  createOptionCategory('Buprenorphine Derivatives', BUPRENORPHINE_DERIVATIVES),
  createOptionCategory('Other', OTHER),
];

type ComboBoxProps = React.ComponentProps<typeof ComboBox>;

export const ControlledSubstanceCombobox = (
  props: Omit<ComboBoxProps, 'items' | 'selectionMode' | 'children'>
) => {
  return (
    <ComboBox {...props} items={OPTIONS} selectionMode="multiple">
      {(section) => {
        return (
          <Section
            key={section.categoryLabel}
            title={section.categoryLabel}
            items={section.items}
          >
            {(item) => (
              <Item key={item.value} textValue={item.label}>
                {item.label}
              </Item>
            )}
          </Section>
        );
      }}
    </ComboBox>
  );
};
