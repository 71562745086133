import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { formatPrice } from '@headway/shared/utils/payments';
import { theme } from '@headway/ui/theme';

interface PaymentFeesTooltipProps {
  fees?: number;
  billingType?: BillingType;
}

export const PaymentFeesTooltip: React.FC<
  React.PropsWithChildren<PaymentFeesTooltipProps>
> = ({ fees, billingType }) => {
  if (billingType !== BillingType.SELF_PAY) {
    return <span css={{ marginRight: theme.space.xl }} />;
  }

  const title = fees
    ? `This amount reflects what you’ll earn with ${formatPrice(
        fees,
        false
      )} in fees from our payment processor (Stripe) and Headway’s software.`
    : `This amount reflects what you’ll earn with fees from our payment processor (Stripe) and a flat fee to cover Headway’s software.`;

  return (
    <Tooltip placement="top" arrow={true} title={title}>
      <InfoIcon
        fontSize="inherit"
        htmlColor={theme.color.textGray}
        css={{
          height: theme.space.lg,
          width: theme.space.lg,
          verticalAlign: 'middle',
          marginLeft: theme.space.xs2,
          marginBottom: theme.space.xs2,
        }}
      />
    </Tooltip>
  );
};
