/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderAppointmentBulkAddProviderPaymentPauseResponse } from '../models/ProviderAppointmentBulkAddProviderPaymentPauseResponse';
import { ProviderAppointmentBulkReleaseProviderPaymentPauseResponse } from '../models/ProviderAppointmentBulkReleaseProviderPaymentPauseResponse';
import { ProviderPaymentPauseRead } from '../models/ProviderPaymentPauseRead';
import { ProviderPaymentPauseUpdate } from '../models/ProviderPaymentPauseUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderPaymentPauseApiResource {

    /**
     * Bulk Add Provider Payment Pauses
     * 
     * @param file 
     * @param token 
     
     */
    public bulkAddProviderPaymentPauses(file: any, query?: { token?: string }, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentBulkAddProviderPaymentPauseResponse> {
        const apiRoute = '/provider-payment-pauses/bulk-add-payment-pauses';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Release Provider Payment Pauses
     * 
     * @param file 
     * @param token 
     
     */
    public bulkReleaseProviderPaymentPauses(file: any, query?: { token?: string }, axiosConfig?: AxiosRequestConfig): Promise<ProviderAppointmentBulkReleaseProviderPaymentPauseResponse> {
        const apiRoute = '/provider-payment-pauses/bulk-release-payment-pauses';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Payment Pauses
     * 
     * @param providerIds 
     * @param activeOnly 
     * @param token 
     
     */
    public getProviderPaymentPauses(query?: { provider_ids?: Array<number>, active_only?: boolean, token?: string }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderPaymentPauseRead>> {
        const apiRoute = '/provider-payment-pauses';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Payment Pause
     * 
     * @param id 
     * @param providerPaymentPauseUpdate 
     
     */
    public updateProviderPaymentPause(id: number, body: ProviderPaymentPauseUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderPaymentPauseRead> {
        const apiRoute = '/provider-payment-pauses/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderPaymentPauseApi = new ProviderPaymentPauseApiResource();
