import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { formatBillingType } from '@headway/shared/utils/appointments';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { Button } from '@headway/ui';
import { FieldControl, FieldToggleButton } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { Loader } from '@headway/ui/Loader';
import { theme } from '@headway/ui/theme';
import { notifyWarning } from '@headway/ui/utils/notify';

import { PROVIDER_SELECTABLE_BILLING_TYPES } from 'constants/providerSelectableBillingTypes';

import { PatientBillingUnconfirmedWarning } from './PatientBillingUnconfirmedWarning';
import { mapBillingTypeToDisplayNameDescriptive } from './utils/billingType';
import { useProviderEventsUnconfirmedQuery } from './utils/customQueries';

export interface PatientBillingTypeFormProps {
  onUpdateSuccess: (update: ProviderPatientRead) => void;
  onCancel: () => void;
  patient: UserRead;
  providerPatient: ProviderPatientRead;
  descriptive?: boolean;
}

export const patientBillingTypeFormValidationSchema = Yup.object().shape({
  billingTypeDefault: Yup.mixed<BillingType>()
    .oneOf(
      Object.values(BillingType).filter((b) =>
        PROVIDER_SELECTABLE_BILLING_TYPES.includes(b)
      )
    )
    .required('Billing type is required'),
});

export interface PatientBillingTypeFormValues {
  billingTypeDefault: BillingType;
}

export const billingTypeFormIntialValues = (
  billingTypeDefault: BillingType
) => ({
  billingTypeDefault: billingTypeDefault ?? BillingType.INSURANCE,
});

export const PatientBillingTypeFormFields = ({
  descriptive,
}: Pick<PatientBillingTypeFormProps, 'descriptive'>) => {
  return (
    <>
      <FieldControl name="billingTypeDefault">
        <FieldToggleButton
          label="Billing Type"
          exclusive
          options={Object.values(BillingType)
            .filter((b) => PROVIDER_SELECTABLE_BILLING_TYPES.includes(b))
            .map((type) => ({
              key: type,
              label: formatBillingType(type),
              display: descriptive
                ? mapBillingTypeToDisplayNameDescriptive(type)
                : formatBillingType(type),
            }))}
        />
      </FieldControl>
    </>
  );
};

export const PatientBillingTypeForm = ({
  patient,
  providerPatient,
  onUpdateSuccess,
  onCancel,
  descriptive,
}: PatientBillingTypeFormProps) => {
  const { isLoading, providerEventsUnconfirmed, error } =
    useProviderEventsUnconfirmedQuery(providerPatient);

  if (isLoading) return <Loader flex={true} />;

  const unconfirmedCount = providerEventsUnconfirmed
    ? providerEventsUnconfirmed.totalCount
    : 0;
  const shouldShowAlert = error || unconfirmedCount > 0;

  return (
    <Formik<PatientBillingTypeFormValues>
      initialValues={billingTypeFormIntialValues(
        providerPatient.billingTypeDefault
      )}
      enableReinitialize={true}
      validationSchema={patientBillingTypeFormValidationSchema}
      onSubmit={async (values) => {
        try {
          const updatedProviderPatient =
            await ProviderPatientApi.updateProviderPatient(
              providerPatient.id,
              values
            );

          onUpdateSuccess(updatedProviderPatient);
        } catch (err) {
          notifyWarning(
            `There was a problem updating ${formatPatientName(patient, {
              firstNameOnly: true,
            })}'s billing information`
          );
          logException(err);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <SafeFormikForm>
            {shouldShowAlert && (
              <PatientBillingUnconfirmedWarning
                error={error}
                unconfirmedCount={unconfirmedCount}
              />
            )}
            <PatientBillingTypeFormFields descriptive={descriptive} />
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: theme.space.xl,
              }}
            >
              <Button
                css={{ marginRight: theme.space.xs }}
                variant="outlined"
                color="gray"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                type="submit"
                data-testid="patientSelfPaySaveButton"
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
            </div>
          </SafeFormikForm>
        );
      }}
    </Formik>
  );
};
