import sortBy from 'lodash/sortBy';
import moment from 'moment';

import { ChartReviewItem } from '@headway/api/models/ChartReviewItem';
import { ChartReviewItemStatus } from '@headway/api/models/ChartReviewItemStatus';
import { ChartReviewType } from '@headway/api/models/ChartReviewType';
import { DocumentRemediationStatus } from '@headway/api/models/DocumentRemediationStatus';
import { ProviderDocumentRemediationWithAuditResults } from '@headway/api/models/ProviderDocumentRemediationWithAuditResults';
import { ProviderDocumentRequestRead } from '@headway/api/models/ProviderDocumentRequestRead';
import { ProviderModule } from '@headway/api/models/ProviderModule';
import { ProviderModuleStatus } from '@headway/api/models/ProviderModuleStatus';
import { trackEvent } from '@headway/shared/utils/analytics';

export type ProviderDocumentsRequestRemediations =
  | ProviderDocumentRequestRead
  | ProviderDocumentRemediationWithAuditResults;

export const isDocumentRequest = (document: ChartReviewItem): boolean => {
  return document.chartReviewType === ChartReviewType.DOCUMENT_REQUEST;
};

export const isDocumentRemediation = (document: ChartReviewItem): boolean => {
  return document.chartReviewType === ChartReviewType.DOCUMENT_REMEDIATION;
};

export const isDocumentCompleted = (document: ChartReviewItem) => {
  return (
    (isDocumentRequest(document) && document.documentProvidedOn) ||
    (isDocumentRemediation(document) &&
      (document.status === ChartReviewItemStatus.SUBMITTED_WITH_ADDENDUM ||
        document.status === ChartReviewItemStatus.SUBMITTED_WITHOUT_ADDENDUM ||
        document.status === ChartReviewItemStatus.REMEDIATION_FAILED_AUDIT ||
        document.status === ChartReviewItemStatus.REMEDIATION_PASSED_AUDIT))
  );
};

export const getAuditResult = (
  documentRemediation: ProviderDocumentRemediationWithAuditResults | undefined
) => {
  if (
    !documentRemediation ||
    documentRemediation.status === DocumentRemediationStatus.NEEDS_REVIEW ||
    documentRemediation.auditResults.length === 0
  )
    return null;

  const sortByCreatedOn = sortBy(
    documentRemediation.auditResults,
    (item) => new Date(item.createdOn!)
  );

  return sortByCreatedOn[sortByCreatedOn.length - 1];
};

/**
 * Returns true if the document request does not have a documentProvidedOn
 * and is due within daysWithinDue business days or is over due
 * @param documentRequest
 * @param daysDueWithin
 */
export const isDocumentDueWithinXBusinessDays = (
  document: ChartReviewItem,
  daysDueWithin: number = 2
) => {
  if (isDocumentCompleted(document)) {
    return false;
  }

  const Sunday = 0;
  const Saturday = 6;
  const currentDate = moment();
  while (daysDueWithin > 0) {
    currentDate.add(1, 'days');
    if (currentDate.day() !== Sunday && currentDate.day() !== Saturday) {
      daysDueWithin--;
    }
  }

  return moment(document.deadline).isSameOrBefore(currentDate, 'day');
};

/**
 * True if the onboarding statuses indicate that the provider has completed all the modules required
 * to enable dismissal of the onboarding card.
 *
 * If provider is a group practice member & not admin, we don't want to check if payment
 * is completed since they don't have access
 */
export const providerCompletedRequiredOnboardingModules = (
  onboardingStatuses: ProviderModuleStatus[],
  isProviderGroupPracticeMember?: boolean
) => {
  const completedModules = new Set(
    onboardingStatuses
      .filter(({ isComplete }) => isComplete)
      .map(({ module }) => module)
  );

  let conditionA = [ProviderModule.PORT_PATIENTS];
  let conditionB = [ProviderModule.PROFILE, ProviderModule.AVAILABILITY];

  if (!isProviderGroupPracticeMember) {
    conditionA.push(ProviderModule.PAYMENT);
    conditionB.push(ProviderModule.PAYMENT);
  }

  return (
    conditionA.every((module) => completedModules.has(module)) ||
    conditionB.every((module) => completedModules.has(module))
  );
};

/**
 * True if the onboarding statuses indicate that the provider has completed all the modules required
 * to display the New Client Referrals section.
 */
export const providerCompletedClientReferralOnboardingModules = (
  onboardingStatuses: ProviderModuleStatus[]
) => {
  const completedModules = new Set(
    onboardingStatuses
      .filter(({ isComplete }) => isComplete)
      .map(({ module }) => module)
  );
  return [ProviderModule.PROFILE, ProviderModule.AVAILABILITY].every((module) =>
    completedModules.has(module)
  );
};

export interface TrackProviderTaskAnalyticsEventOptions {
  providerAppointmentId?: number;
  providerEventId?: number;
  providerEventStartDate?: string;
  providerEventEndDate?: string;
  taskCategory: string;
  ctaButtonCopy: string;
  patientUserId?: number;
  missingSchedulingInfoTypes?: string[];
}

/**
 * Triggers analytics event whenever provider clicks on primary or secondary CTA in SessionTask, ClientTask, or NewClientReferrals.
 * These properties should only be submitted when taskCategory = SESSION or REFERRAL:
 * - providerEventId
 * - providerEventStartDate
 * - providerEventEndDate
 * - providerAppointmentId is optional, only submitted when the appointment is a non-recurring event
 * These properties should only be submitted when taskCategory = CLIENT:
 * - missingSchedulingInfoTypes
 * These properties should be always sent:
 * - taskCategory = SESSION, CLIENT, or REFERRAL
 * - ctaButtonCopy
 * - patientUserId
 */
export const trackProviderTaskAnalyticsEvent = ({
  providerAppointmentId,
  providerEventId,
  providerEventStartDate,
  providerEventEndDate,
  taskCategory,
  ctaButtonCopy,
  patientUserId,
  missingSchedulingInfoTypes,
}: TrackProviderTaskAnalyticsEventOptions) => {
  trackEvent({
    name: 'Provider Task Clicked',
    properties: {
      providerAppointmentId: providerAppointmentId,
      providerEventId: providerEventId,
      ctaButtonCopy: ctaButtonCopy,
      patientUserId: patientUserId,
      missingSchedulingInfoTypes: missingSchedulingInfoTypes,
    },
  });
};
