import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '../../schema/schema.types';

export const InfoOrderedList = ({ element }: SchemaComponent) => {
  const { options } = element;

  return (
    <div>
      <ol
        css={{ marginTop: 0, marginBottom: 0, paddingLeft: theme.spacing.x5 }}
      >
        {options.map((option: string, index: number) => {
          return (
            <li
              key={`info-ordered-list-${index}-${option}`}
              css={{
                marginBottom: theme.spacing.x2,
                ':last-child': {
                  marginBottom: 0,
                },
                listStyleType: 'decimal',
              }}
            >
              <BodyText>{option}</BodyText>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
