import { ProviderProgressNoteLateEntryReason } from '@headway/api/models/ProviderProgressNoteLateEntryReason';

export const lateEntryReasonDisplayNames: {
  [key in ProviderProgressNoteLateEntryReason]: string;
} = {
  [ProviderProgressNoteLateEntryReason.SUPERVISION_NEEDED]:
    'Supervision needed/required',
  [ProviderProgressNoteLateEntryReason.CONSULTATION]: 'Consultation needed',
  [ProviderProgressNoteLateEntryReason.TIME_CONSTRAINTS]: 'Time constraints',
  [ProviderProgressNoteLateEntryReason.PERSONAL_LIFE_EVENT]:
    'Personal life event',
  [ProviderProgressNoteLateEntryReason.OTHER]: 'Other',
};
