import { useSlotId } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';
import { mergeProps, useFocusRing, useHover, useLink } from 'react-aria';

import {
  Calendar,
  CalendarOutlined,
  CEU,
  CEUOutlined,
  Clients,
  ClientsOutlined,
  CredentialForm,
  CredentialFormOutlined,
  Dashboard,
  DashboardOutlined,
  HeadwayAcademy,
  HeadwayAcademyOutlined,
  HelpCenter,
  HelpCenterOutlined,
  InsuranceStatus,
  InsuranceStatusOutlined,
  Messages,
  MessagesOutlined,
  Payments,
  PaymentsOutlined,
  Person,
  PersonOutlined,
  Referral,
  ReferralOutlined,
  Settings,
  SettingsOutlined,
} from '@headway/icons/dist/helix/navigation';

import { LinkProps } from './Link';

type NavigationLinkProps<T extends 'a' | React.JSXElementConstructor<any>> =
  LinkProps<T> & {
    icon: React.ComponentType;
  };

function NavigationLink<T extends 'a' | React.JSXElementConstructor<any>>({
  component: Component = 'a',
  icon: Icon,
  elementType,
  ...props
}: NavigationLinkProps<T>) {
  const ref = React.useRef<HTMLAnchorElement>(null);

  const [content, decoration] = React.Children.toArray(props.children);
  const decorationId = useSlotId([Boolean(decoration)]);

  const { linkProps, isPressed } = useLink(
    {
      elementType: elementType,
      isDisabled: props.disabled,
      ...props,
      'aria-label': props['aria-label'] || (content as string),
      'aria-describedby':
        [decorationId, props['aria-describedby']].filter(Boolean).join(' ') ||
        undefined,
    },
    ref
  );
  const { hoverProps, isHovered } = useHover({ isDisabled: props.disabled });
  const { focusProps, isFocused, isFocusVisible } = useFocusRing({
    autoFocus: props.autoFocus,
  });

  return (
    <Component
      className={clsx('hlx-navigation-link', {
        'focus-ring': isFocusVisible,
        focused: isFocused && !props.disabled,
      })}
      data-hlx-hovered={isHovered}
      data-hlx-pressed={isPressed}
      data-hlx-disabled={props.disabled}
      {...props}
      {...mergeProps(linkProps, hoverProps, focusProps)}
      ref={ref}
    >
      <span className="hlx-navigation-link-icon" aria-hidden>
        <Icon />
      </span>
      {content}
      <div id={decorationId}>{decoration}</div>
    </Component>
  );
}

interface NavigationProps {
  children: React.ReactNode[];
  /**
   * Affects the layout of the component within its container. 'default' is a fixed width, while
   * 'full-width' fills the container.
   */
  layout?: 'default' | 'full-width';
}

function Navigation(props: NavigationProps) {
  return (
    <nav className="hlx-navigation" data-hlx-layout={props.layout || 'default'}>
      <ul className="hlx-navigation-list">
        {React.Children.map(props.children, (child) => {
          if (!child) {
            return null;
          }

          if (!React.isValidElement(child)) {
            throw new Error('Navigation children must be valid elements');
          }

          if (child.type === NavigationDivider) {
            return child;
          }

          return <li>{child}</li>;
        })}
      </ul>
    </nav>
  );
}

function NavigationDivider() {
  return (
    <li role="presentation">
      <hr className="hlx-navigation-divider" />
    </li>
  );
}

export function NavigationIconCalendar() {
  return (
    <>
      <CalendarOutlined />
      <Calendar />
    </>
  );
}
export function NavigationIconClients() {
  return (
    <>
      <ClientsOutlined />
      <Clients />
    </>
  );
}
export function NavigationIconCredentialForm() {
  return (
    <>
      <CredentialFormOutlined />
      <CredentialForm />
    </>
  );
}
export function NavigationIconDashboard() {
  return (
    <>
      <DashboardOutlined />
      <Dashboard />
    </>
  );
}
export function NavigationIconHelpCenter() {
  return (
    <>
      <HelpCenterOutlined />
      <HelpCenter />
    </>
  );
}
export function NavigationIconAcademy() {
  return (
    <>
      <HeadwayAcademyOutlined />
      <HeadwayAcademy />
    </>
  );
}
export function NavigationIconInsuranceStatus() {
  return (
    <>
      <InsuranceStatusOutlined />
      <InsuranceStatus />
    </>
  );
}
export function NavigationIconMessages() {
  return (
    <>
      <MessagesOutlined />
      <Messages />
    </>
  );
}
export function NavigationIconPayments() {
  return (
    <>
      <PaymentsOutlined />
      <Payments />
    </>
  );
}
export function NavigationIconPerson() {
  return (
    <>
      <PersonOutlined />
      <Person />
    </>
  );
}
export function NavigationIconReferral() {
  return (
    <>
      <ReferralOutlined />
      <Referral />
    </>
  );
}
export function NavigationIconSettings() {
  return (
    <>
      <SettingsOutlined />
      <Settings />
    </>
  );
}

export function NavigationIconCEU() {
  return (
    <>
      <CEUOutlined />
      <CEU />
    </>
  );
}

export { Navigation, NavigationDivider, NavigationLink };
