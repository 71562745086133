import React from 'react';

import { theme } from '../theme';

export interface FormRowProps {
  /** The space between items in the row when aligned horizontally. */
  spacing?: string;
}

export const FormRow: React.FC<React.PropsWithChildren<FormRowProps>> = ({
  spacing,
  children,
}) => (
  <div
    css={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '> *': {
        width: '100%',
      },
      [theme.media.medium]: {
        flexDirection: 'row',
        '> *': {
          flexGrow: 1,
          flexBasis: 0,
          ':not(:last-child)': { marginRight: spacing || theme.space.xs },
        },
      },
    }}
  >
    {children}
  </div>
);
