import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { isProviderInState } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { BscaSpecialties, SectionStepForBscaSpecialty } from './constants';
import { getAllSpecialties } from './helpers';

export const getfocusAreaInitialValues = (allSpecialties: string[]) => {
  return {
    ...(allSpecialties.includes(BscaSpecialties.Addiction) && {
      bcsaAddictionTraining: '',
      bcsaAddictionContinuingEducation: '',
      bcsaAddictionPracticeExperience: '',
    }),
    ...(allSpecialties.includes(BscaSpecialties.EatingDisorders) && {
      bcsaEatingDisorderTraining: '',
      bcsaEatingDisorderProfessionalExperience: '',
      bcsaEatingDisorderContinuingEducation: '',
    }),
  };
};

export const getModalitiesInitialValues = (allSpecialties: string[]) => {
  return {
    ...(allSpecialties.includes(BscaSpecialties.NeuropsychologicalTesting) && {
      bcsaNeuropsychTestingBoardMember: '',
      bcsaNeuropsychTestingCompletedCourses: '',
      bcsaNeuropsychTestingCompletedInternshipOrFellowship: '',
      bcsaNeuropsychTestingCompletedSupervisedExperience: '',
    }),
  };
};

export const getCurrentClientsInitialValues = (allSpecialties: string[]) => {
  return {
    ...(allSpecialties.includes(BscaSpecialties.InfantsToddlers) && {
      bcsaInfantsToddlersTraining: '',
      bcsaInfantsToddlersContinuingEducation: '',
      bcsaInfantsToddlersPracticeExperience: '',
    }),
    ...(allSpecialties.includes(BscaSpecialties.Children) && {
      bcsaChildrenTraining: '',
      bcsaChildrenContinuingEducation: '',
      bcsaChildrenPracticeExperience: '',
    }),
    ...(allSpecialties.includes(BscaSpecialties.Adolescents) && {
      bcsaAdolescentsTraining: '',
      bcsaAdolescentsContinuingEducation: '',
      bcsaAdolescentsPracticeExperience: '',
    }),
    ...(allSpecialties.includes(BscaSpecialties.Seniors) && {
      bcsaSeniorsTraining: '',
      bcsaSeniorsContinuingEducation: '',
      bcsaSeniorsPracticeExperience: '',
    }),
  };
};

const stepToInitialValuesMap = {
  [SectionStepForBscaSpecialty.FocusAreas]: getfocusAreaInitialValues,
  [SectionStepForBscaSpecialty.Modalities]: getModalitiesInitialValues,
  [SectionStepForBscaSpecialty.CurrentClients]: getCurrentClientsInitialValues,
};

export const getInitialValuesForStep = (
  provider: ProviderRead,
  step: SectionStepForBscaSpecialty,
  values: ProviderQuestionnaireRawData
) => {
  if (!isProviderInState(provider, UnitedStates.CALIFORNIA)) return {};

  const allSpecialties = getAllSpecialties(values);
  return stepToInitialValuesMap[step](allSpecialties);
};
