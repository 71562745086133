import { Chip } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

export const CanceledChip = () => (
  <Chip
    size="small"
    label="Canceled"
    // variant="outlined"
    css={{
      background: theme.color.error,
      color: theme.color.white,
      fontSize: theme.fontSize.xs,
    }}
  />
);
