import * as Yup from 'yup';

import { StyleTag } from '@headway/api/models/StyleTag';

export const styleTagsValidator = Yup.array(
  Yup.mixed<StyleTag>()
    .oneOf(Object.values(StyleTag))
    .required('Style attribute is required.')
)
  .min(3, 'Please choose three style attributes to best reflect your style.')
  .max(3, 'Three style attributes are allowed to best reflect your style.');
