import { useFormikContext } from 'formik';

import { FormFile, FormValues } from '../schema/schema.types';
import { Form } from '../schema/schema.types';

type LDFormInfo = {
  id: number;
  version: number;
};

export type LDFormRollout = {
  enabled: LDFormInfo[];
};

export function applyTemplateRolloutFilter(
  forms: FormFile[],
  featureFlagValue: LDFormRollout,
  forceForm?: { id: number; version: number }
): FormFile[] {
  const templatesToRender = forms.filter((template) => {
    return featureFlagValue.enabled?.some(
      (info) =>
        template?.info?.id === info.id && template.info.version === info.version
    );
  });

  if (forceForm) {
    if (
      !templatesToRender.find(
        (template) =>
          template?.info?.id === forceForm.id &&
          template?.info.version === forceForm.version
      )
    ) {
      const forcedTemplate = forms.find(
        (template) =>
          template?.info?.id === forceForm.id &&
          template?.info.version === forceForm.version
      );

      if (forcedTemplate) {
        templatesToRender.push(forcedTemplate);
      }
    }
  }

  return templatesToRender;
}

export const alphabeticallySortTemplates = (templates: FormFile[]) => {
  return templates.sort((a, b) => {
    if (!a.info || !b.info) {
      return 0;
    }
    return a?.info?.name.localeCompare(b?.info?.name ?? '');
  });
};

export const getValue = (id: string) => {
  const { values } = useFormikContext<FormValues>();
  if (!id || !values.hasOwnProperty(id)) {
    return '';
  }
  return values[id];
};
