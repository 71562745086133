import Check from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { Skeleton } from '@mui/material';
import { useProvider } from 'hooks';
import moment from 'moment-timezone';
import React from 'react';
import { useState } from 'react';

import { PatientAssessmentCreateRequest } from '@headway/api/models/PatientAssessmentCreateRequest';
import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PatientAssessmentRecurrenceScheduleRead } from '@headway/api/models/PatientAssessmentRecurrenceScheduleRead';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { IconButton } from '@headway/helix/IconButton';
import { Menu, MenuItem, MenuTrigger } from '@headway/helix/Menu';
import { toasts } from '@headway/helix/Toast';
import {
  FULL_ASSESSMENT_NAMES,
  SHORT_ASSESSMENT_NAMES,
} from '@headway/shared/constants/patientAssessments';
import { PATIENT_ASSESSMENTS_DATA_VIZ } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { trackEvent } from '@headway/shared/utils/analytics';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { usePatientAssessments } from 'hooks/usePatientAssessments';
import { usePatientAssessmentScores } from 'hooks/usePatientAssessmentScores';
import { useCreatePatientAssessmentsMutation } from 'mutations/assessments';
import { SideEffectsBuilder } from 'mutations/utils';

import { SelectedAssessmentInfo } from '../helpers/types';
import {
  recurrenceScheduleAsMetadata,
  useCreatePatientAssessmentsSideEffects,
} from '../helpers/utils';
import { AssessmentLineChart } from './AssessmentLineChart';
import { AssessmentResultsModal } from './AssessmentResultsModal';
import { AssessmentTable } from './AssessmentTable';

interface AssessmentHistoryProps {
  providerPatientId: number;
  clientId: number;
  assessmentType: PatientAssessmentType;
  onOpenManageAssessmentsModal: () => void;
  recurrence?: PatientAssessmentRecurrenceScheduleRead;
  addInitiallyVisibleAssessmentIds: (
    assessmentIdsByType: Set<number>,
    assessmentType: PatientAssessmentType
  ) => void;
}

enum AssessmentView {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

/**
 * Displays the history of a specific assessment type for a client. Can be viewed as a table or
 * line chart.
 */
export const AssessmentHistory = ({
  clientId,
  providerPatientId,
  assessmentType,
  onOpenManageAssessmentsModal,
  recurrence,
  addInitiallyVisibleAssessmentIds,
}: AssessmentHistoryProps) => {
  const isDataVizEnabled = useFlag(PATIENT_ASSESSMENTS_DATA_VIZ, false);
  const [view, setView] = useState<AssessmentView | undefined>(
    isDataVizEnabled ? undefined : AssessmentView.TABLE
  );
  const provider = useProvider();
  const [selectedAssessmentInfo, setSelectedAssessmentInfo] = useState<
    SelectedAssessmentInfo | undefined
  >();
  const { data: mostRecentAssessment } = usePatientAssessments(
    {
      provider_patient_id: providerPatientId,
      assessment_type: assessmentType,
      limit: 1,
      order_by: 'original_created_on',
      order: 'desc',
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.data[0];
      },
    }
  );

  const hasIncompleteAssessmentFromToday =
    !!mostRecentAssessment &&
    moment(mostRecentAssessment.originalCreatedOn).isSame(moment(), 'day');

  const sendAssessmentMutation = useCreatePatientAssessmentsMutation({
    sideEffects: new SideEffectsBuilder<
      PatientAssessmentRead[],
      unknown,
      PatientAssessmentCreateRequest
    >()
      .add({
        onSuccess: () => {
          toasts.add(
            hasIncompleteAssessmentFromToday
              ? 'Assessment resent'
              : 'Assessment sent',
            { variant: 'positive' }
          );
        },
      })
      .merge(useCreatePatientAssessmentsSideEffects()),
  });

  const { data: numCompletedAssessments } = usePatientAssessmentScores(
    {
      providerPatientId,
      assessmentType,
    },
    {
      select: (data) => {
        return data.length;
      },
    }
  );

  if (!view && numCompletedAssessments != null) {
    setView(
      isDataVizEnabled && numCompletedAssessments >= 2
        ? AssessmentView.CHART
        : AssessmentView.TABLE
    );
  }

  const labelId = `assessment-table-label-${providerPatientId}-${assessmentType}`;

  const onManageMenuAction = (key: React.Key) => {
    if (key === 'send') {
      trackEvent({
        name: 'Send Now Client Assessments Button Clicked',
        properties: {
          patientUserId: clientId,
          providerId: provider.id,
          assessmentType,
        },
      });
      sendAssessmentMutation.mutate({
        providerPatientId,
        assessmentTypes: [assessmentType],
      });
    } else {
      trackEvent({
        name: 'Manage Assessments Button Clicked',
        properties: {
          patientUserId: clientId,
          providerId: provider.id,
        },
      });
      onOpenManageAssessmentsModal();
    }
  };

  const onViewMenuAction = (key: React.Key) => {
    const viewKey = key as AssessmentView;
    setView(viewKey);
  };

  return (
    <div className="flex flex-col">
      <h3 className="flex items-center gap-2 p-4 pb-0">
        <span className="mr-auto">
          <BodyText>
            <b id={labelId}>
              {FULL_ASSESSMENT_NAMES[assessmentType]} |{' '}
              {SHORT_ASSESSMENT_NAMES[assessmentType]}
            </b>
          </BodyText>
        </span>
        {isDataVizEnabled && (numCompletedAssessments || 0) > 0 && (
          <MenuTrigger menuWidth="small">
            <IconButton aria-label="Open view menu">
              {view === AssessmentView.CHART ? (
                <ShowChartIcon aria-label="Line chart" />
              ) : (
                <TableChartOutlinedIcon aria-label="Table" />
              )}
            </IconButton>
            <Menu onAction={onViewMenuAction}>
              <MenuItem
                key={AssessmentView.CHART}
                textValue={
                  view === AssessmentView.CHART
                    ? 'Selected: Line chart'
                    : 'Line chart'
                }
              >
                <div className="flex w-full items-end gap-2">
                  <div
                    className={
                      view === AssessmentView.CHART ? 'visible' : 'invisible'
                    }
                  >
                    <Check sx={{ width: 20 }} aria-hidden />
                  </div>
                  <span className="grow">
                    {view === AssessmentView.CHART && (
                      <VisuallyHidden>Selected: </VisuallyHidden>
                    )}
                    Line chart
                  </span>
                  <ShowChartIcon sx={{ width: 20 }} aria-hidden />
                </div>
              </MenuItem>
              <MenuItem
                key={AssessmentView.TABLE}
                textValue={
                  view === AssessmentView.TABLE ? 'Selected: Table' : 'Table'
                }
              >
                <div className="flex w-full items-end gap-2">
                  <div
                    className={
                      view === AssessmentView.TABLE ? 'visible' : 'invisible'
                    }
                  >
                    <Check sx={{ width: 20 }} aria-hidden />
                  </div>
                  {view === AssessmentView.TABLE && (
                    <VisuallyHidden>Selected: </VisuallyHidden>
                  )}
                  <span className="grow">Table</span>
                  <TableChartOutlinedIcon sx={{ width: 20 }} aria-hidden />
                </div>
              </MenuItem>
            </Menu>
          </MenuTrigger>
        )}
        <MenuTrigger>
          <IconButton aria-label="Open management menu">
            <MoreHorizIcon />
          </IconButton>
          <Menu onAction={onManageMenuAction}>
            <MenuItem key="send">
              {hasIncompleteAssessmentFromToday ? 'Resend' : 'Send now'}
            </MenuItem>
            <MenuItem key="manage">Manage assessment</MenuItem>
          </Menu>
        </MenuTrigger>
      </h3>
      {recurrence && (
        <div className="mx-4 grid grid-cols-[max-content_1fr] gap-x-2">
          <CaptionText>
            <span className="text-system-gray">
              {recurrenceScheduleAsMetadata(recurrence, mostRecentAssessment)}
            </span>
          </CaptionText>
        </div>
      )}
      <div className="flex flex-col overflow-x-auto">
        {view === AssessmentView.CHART ? (
          <div className="mt-3">
            <AssessmentLineChart
              providerPatientId={providerPatientId}
              clientId={clientId}
              assessmentType={assessmentType}
              onAssessmentSelected={(info) => setSelectedAssessmentInfo(info)}
              addInitiallyVisibleAssessmentIds={
                addInitiallyVisibleAssessmentIds
              }
            />
          </div>
        ) : view === AssessmentView.TABLE ? (
          <AssessmentTable
            providerPatientId={providerPatientId}
            assessmentType={assessmentType}
            onAssessmentSelected={(info) => setSelectedAssessmentInfo(info)}
            addInitiallyVisibleAssessmentIds={addInitiallyVisibleAssessmentIds}
          />
        ) : (
          <div className="mt-4">
            <Skeleton variant="rectangular" height={60} />
          </div>
        )}
      </div>
      {selectedAssessmentInfo && (
        <AssessmentResultsModal
          clientId={clientId}
          assessmentId={selectedAssessmentInfo.id}
          previousScore={selectedAssessmentInfo.previousScore}
          isOpen={true}
          onDismiss={() => setSelectedAssessmentInfo(undefined)}
        />
      )}
    </div>
  );
};
