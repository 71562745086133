import { keyframes } from '@emotion/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { theme } from '@headway/helix/theme';
import { Loading } from '@headway/icons/dist/helix/Loading';
import { useQuery } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { logException } from '@headway/shared/utils/sentry';

export const InvitationEmailPreview = () => {
  useEffect(() => {
    trackPageView({
      name: 'Individual New Client Invite Email Modal Viewed',
    });
  }, []);

  let { providerPatientId } = useParams<'providerPatientId'>();

  const emailPreviewQuery = useQuery({
    queryKey: [
      'provider-patient-account-invite-email-preview',
      Number(providerPatientId),
    ],
    queryFn: () => {
      return ProviderPatientApi.getPatientInvitationEmailPreview(
        Number(providerPatientId)
      );
    },
    onError: (e) => {
      logException(e);
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return (
    <>
      {emailPreviewQuery.isSuccess ? (
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(emailPreviewQuery.data?.html || ''),
          }}
        />
      ) : emailPreviewQuery.isLoading ? (
        <div
          css={{
            display: 'grid',
            placeItems: 'center',
            color: theme.color.system.textBlack,
            padding: theme.spacing.x6,
            '& > *': {
              animation: `${rotate} 2s infinite linear`,
            },
          }}
        >
          <Loading />
        </div>
      ) : null}
    </>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
