import { css } from '@emotion/react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import { useFormikContext } from 'formik';
import React from 'react';

import { FieldControlContext } from '@headway/ui/form/FieldControl';
import { theme } from '@headway/ui/theme';

import { useField } from './useField';

interface ToggleOption {
  key: string;
  display: React.ReactNode;
  label: string;
}

export interface FieldToggleButtonProps extends ToggleButtonGroupProps {
  options: ToggleOption[];
}

export const FieldToggleButton = ({
  options,
  ...props
}: FieldToggleButtonProps) => {
  const { name, id } = React.useContext(FieldControlContext);
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name });

  const handleChange = (value: ToggleOption | ToggleOption[]) => {
    if (value === null || (Array.isArray(value) && value.length === 0)) {
      return;
    }

    setFieldValue(name, value);
  };

  return (
    <div>
      <ToggleButtonGroup
        id={id}
        onChange={(
          _event: React.MouseEvent<HTMLElement>,
          value: ToggleOption | ToggleOption[]
        ) => handleChange(value)}
        value={field.value}
        css={toggleButtonGroup}
        {...props}
      >
        {options.map((option) => (
          <ToggleButton
            css={toggleButton}
            data-testid={`${option.key}-toggle-button`}
            key={option.key}
            value={option.key}
            aria-label={option.label}
          >
            {option.display}
          </ToggleButton>
        ))}
        )
      </ToggleButtonGroup>
    </div>
  );
};

const toggleButtonGroup = css`
  width: 100%;
`;

const toggleButton = css`
  font-family: ${theme.fontFamily.postGrotesk};
  color: ${theme.color.black};
  text-transform: none;
  border-radius: 8px;
  flex-grow: 1;
  &.Mui-selected {
    color: ${theme.color.black};
    border-color: ${theme.color.primary};
    background: ${theme.color.primaryBackground};
    :hover {
      background: ${theme.color.primaryBackground};
    }
  }
`;
