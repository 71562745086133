import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseSessionDetailsConfirmabilityKeyArgs {
  providerEventId: number | undefined;
  appointmentDate: Date | undefined;
}
export type UseSessionDetailsConfirmabilityQueryKey = [
  string,
  number | undefined,
  Date | undefined,
];

export const getUseSessionDetailsConfirmabilityKey = ({
  providerEventId,
  appointmentDate,
}: UseSessionDetailsConfirmabilityKeyArgs): UseSessionDetailsConfirmabilityQueryKey => [
  'session-details-confirmability',
  providerEventId,
  appointmentDate,
];

const {
  useSingleQuery: useSessionDetailsConfirmability,
  useListQuery: useSessionDetailsConfirmabilityList,
} = createBasicApiHooks(
  getUseSessionDetailsConfirmabilityKey,
  ({ providerEventId, appointmentDate }) =>
    ProviderEventApi.getSessionDetailsConfirmability(providerEventId!, {
      appointment_date: appointmentDate!.toISOString(),
    }),
  ({ providerEventId, appointmentDate }) => {
    return !!(providerEventId || appointmentDate);
  },
  () => 'Failed to fetch session details confirmability'
);

export { useSessionDetailsConfirmability, useSessionDetailsConfirmabilityList };
