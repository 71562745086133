import { StyleTag } from '@headway/api/models/StyleTag';
import { Art } from '@headway/icons/dist/emoji/Art';
import { Bullseye } from '@headway/icons/dist/emoji/Bullseye';
import { Checkmark } from '@headway/icons/dist/emoji/Checkmark';
import { Direct } from '@headway/icons/dist/emoji/Direct';
import { Earth } from '@headway/icons/dist/emoji/Earth';
import { Flame } from '@headway/icons/dist/emoji/Flame';
import { Flex } from '@headway/icons/dist/emoji/Flex';
import { Handshake } from '@headway/icons/dist/emoji/Handshake';
import { Smiling } from '@headway/icons/dist/emoji/Smiling';
import { Sun } from '@headway/icons/dist/emoji/Sun';
import { ThoughtBubble } from '@headway/icons/dist/emoji/ThoughtBubble';
import { Zap } from '@headway/icons/dist/emoji/Zap';

export type StyleTagProperties = {
  name: string;
  IconComponent?: React.ComponentType;
};

type TagToStyleTagPropertiesMap = {
  [key in keyof typeof StyleTag]: StyleTagProperties;
};

// Manually map tags to displayable properties
const tagToProperties: TagToStyleTagPropertiesMap = {
  CHALLENGING: {
    name: 'Challenging',
    IconComponent: Zap,
  },
  AFFIRMING: {
    name: 'Affirming',
    IconComponent: Checkmark,
  },
  INQUISITIVE: {
    name: 'Inquisitive',
    IconComponent: ThoughtBubble,
  },
  DIRECT: {
    name: 'Direct',
    IconComponent: Direct,
  },
  HUMOROUS: {
    name: 'Humorous',
    IconComponent: Smiling,
  },
  WARM: {
    name: 'Warm',
    IconComponent: Sun,
  },
  OPEN_MINDED: {
    name: 'Open-minded',
    IconComponent: ThoughtBubble, // TODO: swap with non-duplicate
  },
  PARTICIPATORY: {
    name: 'Participatory',
    IconComponent: Handshake,
  },
  SOLUTION_ORIENTED: {
    name: 'Solution-oriented',
    IconComponent: Bullseye,
  },
  CREATIVE: {
    name: 'Creative',
    IconComponent: Art,
  },
  HOLISTIC: {
    name: 'Holistic',
    IconComponent: Earth,
  },
  ENERGETIC: {
    name: 'Energetic',
    IconComponent: Flame,
  },
  EMPOWERING: {
    name: 'Empowering',
    IconComponent: Flex,
  },
};

const styleTagValueToTagEnumKey = (
  styleTagValue: StyleTag
): keyof typeof StyleTag => {
  const styleTag = Object.keys(StyleTag).find(
    (key) =>
      key in StyleTag &&
      StyleTag[key as keyof typeof StyleTag] === styleTagValue
  );

  if (!styleTag) throw new Error(`${styleTagValue} is not a valid attribute`);

  return styleTag as keyof typeof StyleTag;
};

export const getStyleTagProperties = (
  styleTagValue: StyleTag
): StyleTagProperties => {
  // transform the value of the enum (e.g., 'nice') to the key (e.g., 'NICE')
  // which we then use to fetch the display properties
  const tagEnum = styleTagValueToTagEnumKey(styleTagValue);

  return tagToProperties[tagEnum];
};
