import { GenericTemplate } from './templateView/schemaRenderer/versions/types';
import { isEqual } from 'lodash';

import { ProgressNoteTemplateRollout } from './templateView/utils';
import { ProgressNoteComponentMetadata } from './types';

export const getRecommendedTemplateForCPTCode = (
  templateRollout: ProgressNoteTemplateRollout,
  selectedCptCodes: string[]
) => {
  if (templateRollout.enabled === undefined) {
    return;
  }

  for (let x = 0; x < templateRollout.enabled.length; x++) {
    const templateRolloutInfo = templateRollout.enabled[x];

    if (templateRolloutInfo.cptCodes === undefined) {
      continue;
    }

    for (let y = 0; y < templateRolloutInfo.cptCodes.length; y++) {
      if (isEqual(selectedCptCodes, templateRolloutInfo.cptCodes[y])) {
        return {
          id: templateRolloutInfo.id,
          version: templateRolloutInfo.version,
        };
      }
    }
  }

  return undefined;
};

export const getTemplate = (
  templates: GenericTemplate<ProgressNoteComponentMetadata>[],
  id?: number,
  version?: number
) =>
  id !== undefined && version !== undefined && templates !== undefined
    ? templates?.find((t) => {
        return t.templateInfo.id === id && t.templateInfo.version === version;
      })
    : undefined;
