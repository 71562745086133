import { clamp } from '@react-stately/utils';
import { AriaLabelingProps } from '@react-types/shared';
import React from 'react';
import { useProgressBar } from 'react-aria';

interface ProgressBarProps extends AriaLabelingProps {
  /** The content to display as the label. */
  label?: React.ReactNode;
  /** The content to display as the value's label (e.g. 1 of 4). */
  valueLabel?: React.ReactNode;

  /**
   * The current value (controlled).
   * @default 0
   */
  value?: number;
  /**
   * The smallest value allowed for the input.
   * @default 0
   */
  minValue?: number;
  /**
   * The largest value allowed for the input.
   * @default 100
   */
  maxValue?: number;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { progressBarProps, labelProps } = useProgressBar({
    ...props,
    // TODO: Support this
    isIndeterminate: false,
  });

  let {
    value = 0,
    minValue = 0,
    maxValue = 100,
    label,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
  } = props;

  value = clamp(value, minValue, maxValue);
  let percentage = (value - minValue) / (maxValue - minValue);

  return (
    <div {...progressBarProps} className="hlx-progress-bar">
      {label && (
        <span {...labelProps} className="hlx-progress-bar-label">
          {label}
        </span>
      )}

      <div className="hlx-progress-bar-value-label">
        {progressBarProps['aria-valuetext']}
      </div>

      <div className="hlx-progress-bar-track">
        <div
          className="hlx-progress-bar-fill"
          style={{
            width: `${Math.round(percentage * 100)}%`,
          }}
        />
      </div>
    </div>
  );
};

// This is basically a loading spinner.
interface ProgressCircleProps {}

const ProgressCircle = (props: ProgressCircleProps) => {
  throw new Error('Not Implemented');
};

export { ProgressBar, ProgressCircle };
