import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import { useProvider } from 'hooks';
import React from 'react';

import { IconButton } from '@headway/helix/IconButton';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { HighlightCalendar } from '@headway/icons/dist/provider/HighlightCalendar';
import { HighlightHeadwayRelationship } from '@headway/icons/dist/provider/HighlightHeadwayRelationship';
import { HighlightLocation } from '@headway/icons/dist/provider/HighlightLocation';
import { HighlightPeople } from '@headway/icons/dist/provider/HighlightPeople';
import { HighlightPhone } from '@headway/icons/dist/provider/HighlightPhone';
import { HighlightPrescriber } from '@headway/icons/dist/provider/HighlightPrescriber';
import { HighlightShield } from '@headway/icons/dist/provider/HighlightShield';
import { HighlightTherapyChair } from '@headway/icons/dist/provider/HighlightTherapyChair';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Carousel } from '@headway/ui/Carousel';
import { theme as legacyTheme } from '@headway/ui/theme';

interface ResourceCard {
  ImageCompmonent: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
  link: string;
}

enum ResourceType {
  COMPLIANCE_GUIDE = 'COMPLIANCE_GUIDE',
  CLIENT_SETUP = 'CLIENT_SETUP',
  INSURANCE_QUESTIONS = 'INSURANCE_QUESTIONS',
  MEDICAL_DECISION_MAKING = 'MEDICAL_DECISION_MAKING',
  GROW_YOUR_PRACTICE = 'GROW_YOUR_PRACTICE',
  RATES_BILLING_TIMELINES = 'RATES_BILLING_TIMELINES',
  ARCHIVED_WEBINARS = 'ARCHIVED_WEBINARS',
  LIVE_ONLINE_EVENTS = 'LIVE_ONLINE_EVENTS',
  MULTI_STATE_CREDENTIALING = 'MULTI_STATE_CREDENTIALING',
}

const RESOURCE_CARD_MAP: Record<ResourceType, ResourceCard> = {
  [ResourceType.COMPLIANCE_GUIDE]: {
    title: 'Compliance Guide',
    ImageCompmonent: HighlightShield,
    subtitle: "Meet insurers' standards on documentation and billing",
    link: `${process.env.REACT_APP_MAIN_URL}/resources/behavioral-health-compliance?utm_source=sigmund`,
  },
  [ResourceType.CLIENT_SETUP]: {
    title: 'Client Set-up',
    ImageCompmonent: HighlightCalendar,
    subtitle: 'A course on adding and scheduling clients',
    link: 'https://headway-university.northpass.com/c/9743c1c57a533b4f48c2f9286db2019194239487',
  },
  [ResourceType.INSURANCE_QUESTIONS]: {
    title: 'Insurance Questions',
    ImageCompmonent: HighlightHeadwayRelationship,
    subtitle: 'A course on the basics of accepting insurance',
    link: 'https://headway-university.northpass.com/c/fed02164cb9acec5f6aaa738c897abb1a7a1014a',
  },
  [ResourceType.GROW_YOUR_PRACTICE]: {
    title: 'Grow Your Practice',
    ImageCompmonent: HighlightPeople,
    subtitle: 'A course on how to expand your practice',
    link: 'https://headway-university.northpass.com/c/a995cc0e4acff2759defbb89b171b9ffddcb28d6',
  },
  [ResourceType.RATES_BILLING_TIMELINES]: {
    title: 'Rates, Billing, and Timelines',
    ImageCompmonent: HighlightTherapyChair,
    subtitle: 'A course teaching you how your payments work',
    link: 'https://headway-university.northpass.com/c/e0c323db487b13296f0d73c65222585ee15da609',
  },
  [ResourceType.LIVE_ONLINE_EVENTS]: {
    title: 'Live Online Events',
    ImageCompmonent: HighlightLocation,
    subtitle:
      'Register for clinical office hours and sign up for upcoming live events',
    link: 'https://headway-university.northpass.com/app/training_events',
  },
  [ResourceType.ARCHIVED_WEBINARS]: {
    title: 'Archived Webinars',
    ImageCompmonent: HighlightPhone,
    subtitle: 'Watch past Headway webinars',
    link: 'https://headway-university.northpass.com/p/7aa39c54af1bc9eeb184355d113f0197dfab541e',
  },
  [ResourceType.MEDICAL_DECISION_MAKING]: {
    title: 'E/M Coding and Medical Decision Making for Psychiatric Prescribers',
    ImageCompmonent: HighlightPrescriber,
    subtitle:
      'A course on MDM and E/M Coding for Psychiatrists and Nurse Practioners',
    link: 'https://headway-university.northpass.com/app/courses/c8fc63eb-0095-4d22-93fb-ddbc9cecffc1',
  },
  [ResourceType.MULTI_STATE_CREDENTIALING]: {
    title: 'Multi-State Credentialing',
    ImageCompmonent: HighlightLocation,
    subtitle:
      'A course explaining how to use multiple state licenses on Headway',
    link: 'https://headway-university.northpass.com/c/a0884f86183a7ebdab3b54208ba4057d81c69808',
  },
};

const EXISTING_PROVIDERS_RESOURCES: ResourceType[] = [
  ResourceType.COMPLIANCE_GUIDE,
  ResourceType.MULTI_STATE_CREDENTIALING,
  ResourceType.GROW_YOUR_PRACTICE,
  ResourceType.RATES_BILLING_TIMELINES,
  ResourceType.LIVE_ONLINE_EVENTS,
  ResourceType.ARCHIVED_WEBINARS,
  ResourceType.MEDICAL_DECISION_MAKING,
  ResourceType.INSURANCE_QUESTIONS,
  ResourceType.CLIENT_SETUP,
];

const NEW_PROVIDERS_RESOURCES: ResourceType[] = [
  ResourceType.CLIENT_SETUP,
  ResourceType.INSURANCE_QUESTIONS,
  ResourceType.MULTI_STATE_CREDENTIALING,
  ResourceType.COMPLIANCE_GUIDE,
  ResourceType.RATES_BILLING_TIMELINES,
  ResourceType.GROW_YOUR_PRACTICE,
  ResourceType.LIVE_ONLINE_EVENTS,
  ResourceType.ARCHIVED_WEBINARS,
  ResourceType.MEDICAL_DECISION_MAKING,
];

interface LearningHubProps {
  isOnboardingComplete: boolean;
}

export const LearningHub: React.FC<
  React.PropsWithChildren<LearningHubProps>
> = ({ isOnboardingComplete }) => {
  const provider = useProvider();
  const isSmall = useMediaQuery(legacyTheme.media.smallDown);

  const RESOURCES = isOnboardingComplete
    ? EXISTING_PROVIDERS_RESOURCES
    : NEW_PROVIDERS_RESOURCES;

  const CARDS = RESOURCES.map(
    (resourceType) => RESOURCE_CARD_MAP[resourceType]
  );
  return (
    <PageSection>
      <div className="grid grid-cols-1">
        <Carousel
          renderControls={({ next, prev, hasNext, hasPrev }) => (
            <div
              css={{
                display: 'flex',
                marginBottom: theme.spacing.x5,
              }}
            >
              <SectionHeader>Learning Hub</SectionHeader>
              <div css={{ flexGrow: 1 }}></div>
              {!isSmall && (
                <>
                  <div css={{ marginRight: theme.spacing.x1 }}>
                    <IconButton
                      size="medium"
                      disabled={!hasPrev}
                      onPress={() => {
                        prev();
                        trackEvent({
                          name: 'Headway How to Navigation Button Clicked',
                          properties: {
                            providerId: provider.id,
                          },
                        });
                      }}
                    >
                      <ChevronLeftOutlined
                        css={{
                          width: '1.5rem',
                          height: '1.5rem',
                        }}
                        aria-label="Previous page"
                      />
                    </IconButton>
                  </div>

                  <IconButton
                    size="medium"
                    disabled={!hasNext}
                    onPress={() => {
                      next();
                      trackEvent({
                        name: 'Headway How to Navigation Button Clicked',
                        properties: {
                          providerId: provider.id,
                        },
                      });
                    }}
                  >
                    <ChevronRightOutlined
                      css={{
                        width: '1.5rem',
                        height: '1.5rem',
                      }}
                      aria-label="Next page"
                    />
                  </IconButton>
                </>
              )}
            </div>
          )}
          items={CARDS.map(({ link, title, subtitle, ImageCompmonent }) => (
            <div
              css={{
                backgroundColor: theme.color.system.backgroundGray,
                height: '100%',
              }}
            >
              <a
                href={link}
                target="_blank"
                css={{
                  position: 'relative',
                  display: 'block',
                  height: '100%',
                  padding: theme.spacing.x1,
                  '::before': {
                    content: '" "',
                    zIndex: 1,
                    display: 'block',
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    left: 0,
                    right: 0,
                    background: 'rgba(0, 0, 0, 0.04)',
                    transition:
                      'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    opacity: 0,
                  },
                  ':hover::before': {
                    opacity: 1,
                  },
                  ':focus::before': {
                    opacity: 1,
                  },
                }}
                rel="noreferrer"
                onClick={() => {
                  trackEvent({
                    name: 'Headway How to Button Clicked',
                    properties: {
                      articleName: title,
                      providerId: provider.id,
                    },
                  });
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    minHeight: '97px',
                    backgroundColor: theme.color.system.white,
                    gap: theme.spacing.x1,
                    paddingTop: '13px',
                  }}
                >
                  <ImageCompmonent height={48} />
                  <p
                    css={{
                      ...theme.typography.body.medium,
                      textAlign: 'center',
                      color: theme.color.primary.brandGreen,
                    }}
                  >
                    {title}
                  </p>
                </div>
                <div
                  css={{
                    padding: theme.spacing.x5,
                    color: theme.color.system.black,
                    textAlign: 'center',
                  }}
                >
                  <p
                    css={{
                      margin: 0,
                      ...theme.typography.caption.regular,
                      lineHeight: theme.spacing.x5,
                    }}
                  >
                    {subtitle}
                  </p>
                </div>
              </a>
            </div>
          ))}
          itemsPerPage={isSmall ? 2.5 : 3.5}
          gap={theme.spacing.x4}
          css={{
            // Extra 3px padding allows focus outline to be visible
            marginLeft: -3,
            marginTop: -3,
            width: 'calc(100% + 3px)',
            '.carousel-inner': { padding: 3 },
          }}
        />
      </div>
    </PageSection>
  );
};
