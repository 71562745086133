import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import {
  isGPPendingInitialContractAcceptance,
  isGroupPracticeProviderAfter30Days,
} from 'components/IroncladAmendmentsModal/helpers/utils';
import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';

export const useIroncladAgreement = () => {
  const shouldUseIroncladAgreements = useFlag('ironcladProviderAgreements');
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const { user, provider } = useAuthStore();
  const { ironcladAgreementInfo } = useIroncladAgreementInfo();
  const { groupKey } = ironcladAgreementInfo || {};
  const isGPPendingInitialAcceptance =
    isMSCBetaEnabled && isGPPendingInitialContractAcceptance(provider);

  const enableIroncladAgreements = () => {
    if (
      !shouldUseIroncladAgreements ||
      isGPPendingInitialAcceptance ||
      !groupKey
    )
      return false;

    return !!(
      hasRateAccess(provider, user) ||
      isGroupPracticeProviderAfter30Days(provider, user)
    );
  };

  const {
    isLoading,
    isError,
    data: ironcladAgreement,
  } = useQuery(
    ['ironcladAgreementHtml', groupKey],
    async () => {
      try {
        const res = await fetch(
          `https://pactsafe.io/load/json?sid=${process.env.REACT_APP_IRONCLAD_SITE_ID}&gkey=${groupKey}&sty=full`,
          { method: 'GET', headers: { accept: 'text/plain' } }
        );

        if (res.status === 404) {
          throw new Error(`${groupKey} wasn't found in Ironclad`);
        }
        return await res.json();
      } catch (e) {
        notifyError(
          'An error occurred fetching your agreement. Please try again later. If the problem persists please contact hello@findheadway.com'
        );
        logException(e);
        return null;
      }
    },
    {
      enabled: enableIroncladAgreements(),
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    isError,
    ironcladAgreement,
  };
};
