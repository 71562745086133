import * as React from 'react';

export const Clients = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.813 16.666v-2.082c0-.487.121-.928.364-1.323.243-.396.58-.692 1.01-.886.973-.444 1.876-.767 2.709-.969a10.962 10.962 0 012.583-.302c.875 0 1.73.1 2.562.302.834.202 1.73.525 2.688.969.43.194.775.49 1.032.886.257.395.385.836.385 1.323v2.082H.812zm14.708 0v-2.145c0-.778-.205-1.448-.615-2.01-.41-.563-.955-1.032-1.635-1.407.875.111 1.698.271 2.468.48.771.208 1.42.45 1.948.729.473.277.84.6 1.104.968.264.368.396.782.396 1.24v2.145h-3.666zM7.479 9.98c-.917 0-1.68-.302-2.292-.906-.61-.604-.916-1.365-.916-2.282 0-.916.306-1.677.917-2.28.61-.605 1.374-.907 2.291-.907.917 0 1.677.302 2.282.907.604.603.905 1.364.905 2.28 0 .917-.302 1.678-.905 2.282-.605.604-1.365.906-2.282.906zm7.771-3.187c0 .916-.302 1.677-.906 2.281-.604.604-1.365.906-2.281.906-.153 0-.334-.01-.542-.031a2.271 2.271 0 01-.542-.114c.347-.375.615-.823.802-1.345.188-.52.281-1.086.281-1.698a4.71 4.71 0 00-.281-1.666 4.721 4.721 0 00-.802-1.375c.167-.056.347-.094.542-.115a5.06 5.06 0 01.541-.031c.917 0 1.678.302 2.282.907.604.603.906 1.364.906 2.28z"
      fill="currentColor"
    />
  </svg>
);
