import { useEffect } from 'react';

import { BrandText } from '@headway/helix/BrandText';
import { theme } from '@headway/helix/theme';

import therapistArt from 'assets/img/provider_speaking_with_patient.png';
import {
  filterStatesByCdsRequirement,
  YesNo,
} from 'utils/providerQuestionnaire';

import { OnBeforeSubmitError } from '../OnBeforeSubmitErrorCard';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import {
  onBeforePracticeStatesSubmit,
  practiceStatesFormMeta,
  PracticeStatesStepContent,
} from './PracticeStatesStep';

const WelcomeStepV2 = (props: CustomComponentProps) => {
  const { values } = props.formikHelpers;
  const {
    provider,
    disableSubmit,
    setDisableSubmit,
    onBeforeSubmitError,
    setOnBeforeSubmitError,
  } = useQuestionnaireContext();

  // Render error and block form submission if:
  // 1. Provider is not licensed or
  // 2. Provider is missing DEA or CDS

  useEffect(() => {
    const selectedStates = values.providerSelectedPracticeStates ?? [];
    const requiresCDS = !!filterStatesByCdsRequirement(
      selectedStates,
      provider.licenseType
    ).length;

    if (values.isLicensedInSelectedPracticeStates === YesNo.NO) {
      setDisableSubmit(true);
      setOnBeforeSubmitError(OnBeforeSubmitError.LicenseErrorGuidanceCard);
      return;
    }

    if (
      provider.isPrescriber &&
      (values.hasDeaInSelectedPracticeStates === YesNo.NO ||
        (requiresCDS && values.hasCdsInSelectedPracticeStates === YesNo.NO))
    ) {
      setDisableSubmit(true);
      setOnBeforeSubmitError(OnBeforeSubmitError.DeaCdsErrorGuidanceCard);
      return;
    }

    if (disableSubmit && !!onBeforeSubmitError) {
      setOnBeforeSubmitError(null);
      setDisableSubmit(false);
    }
  }, [values]);

  return (
    <div className="overflow-hidden">
      <div className="flex flex-row">
        <div className="space-between flex flex-col gap-6">
          <BrandText>Let’s get you credentialed in record time</BrandText>
          <PracticeStatesStepContent {...props} />
        </div>
        <img
          src={therapistArt}
          alt="Therapist speaking to patient"
          className="z-1 fixed right-0 top-0 hidden h-full w-[50%] translate-y-12 object-cover tablet:flex"
        />
      </div>
    </div>
  );
};

const WelcomeConfigV2: QuestionnaireV2Step = {
  Component: WelcomeStepV2,
  css: {
    mainContainer: { flex: '1 1 auto' },
    stepContainer: {},
    formikContainer: {
      padding: theme.spacing.x10,
      width: '50%',
      [theme.__futureMedia.below('tablet')]: {
        width: '100%',
      },
    },
    submissionContainer: {
      display: 'flex',
      justifyContent: 'start',
      marginBottom: theme.spacing.x10,
    },
    submissionButtonContainer: {
      width: '950px',
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: theme.spacing.x4,
      gap: theme.spacing.x2,
      [theme.__futureMedia.below('desktop')]: {
        maxWidth: '950px',
        width: '100%',
      },
    },
  },
  getFormMeta: practiceStatesFormMeta,
  onBeforeSubmit: onBeforePracticeStatesSubmit,
};

export default WelcomeConfigV2;
