import * as Components from '.';

export { Checklist } from './Checklist';
export { Checkbox } from './Checkbox';
export { FormRadio } from './Radio';
export { FormDate } from './Date';
export { FormComboBox } from './ComboBox';
export { FormMultipleComboBox } from './MultipleComboBox';
export { FormSelect } from './Select';
export { FormDiagnosisSelect } from './Diagnosis';
export { FormTextField } from './TextField';
export { FormTextArea } from './TextArea';
export { FormTextAreaWithCheckbox } from './TextAreaWithCheckbox';
export { FormRichTextArea } from './RichTextArea';
export { InfoGuidanceCard } from './InfoGuidanceCard';
export { InfoTitle } from './InfoTitle';
export { InfoParagraph } from './InfoParagraph';
export { InfoList } from './InfoList';
export { InfoOrderedList } from './InfoOrderedList';
export { AcknowledgeAndSign } from './AcknowledgeAndSign';
export { Upload } from './Upload';
export { EmbedVideo } from './EmbedVideo';
export { LocationSelect } from './LocationSelect';
export { AddressInput } from './AddressInput';
export { HelloSign } from './HelloSign';
export { Paragraph } from './Paragraph';

export const ComponentMap = {
  /** User Input */
  checkbox: Components.Checkbox,
  checklist: Components.Checklist,
  radio: Components.FormRadio,
  date: Components.FormDate,
  dropdown: Components.FormComboBox,
  dropdownMulti: Components.FormMultipleComboBox,
  dropdownDiagnosis: Components.FormDiagnosisSelect,
  longFreeText: Components.FormTextArea,
  longFreeTextWithCheckbox: Components.FormTextAreaWithCheckbox,
  shortFreeText: Components.FormTextField,
  richFreeText: Components.FormRichTextArea,
  acknowledgeAndSign: Components.AcknowledgeAndSign,
  select: Components.FormSelect,
  upload: Components.Upload,
  embedVideo: Components.EmbedVideo,
  locationSelect: Components.LocationSelect,
  addressInput: Components.AddressInput,
  helloSign: Components.HelloSign,
  paragraph: Components.Paragraph,

  /** Readonly */
  infoGuidanceCard: Components.InfoGuidanceCard,
  infoTitle: Components.InfoTitle,
  infoParagraph: Components.InfoParagraph,
  infoList: Components.InfoList,
  infoOrderedList: Components.InfoOrderedList,
};

export type ComponentTypes = keyof typeof ComponentMap;
export const ComponentNames: { [key in ComponentTypes]: string } = Object.keys(
  ComponentMap
).reduce(
  (map, name) => {
    map[name as ComponentTypes] = name;
    return map;
  },
  {} as { [key in ComponentTypes]: string }
);
