import moment from 'moment';
import { RRule } from 'rrule';

import { toFloatingDateTime } from '@headway/shared/utils/dates';

import { getDayOccurrenceOfMonth } from './momentUtils';

const getRRuleDay = (date: string) => {
  const dayMap = {
    0: RRule.SU,
    1: RRule.MO,
    2: RRule.TU,
    3: RRule.WE,
    4: RRule.TH,
    5: RRule.FR,
    6: RRule.SA,
  };

  return dayMap[moment(date).day() as keyof typeof dayMap];
};

export function getWeeklyRRuleString(startDate: string | Date, tzid: string) {
  return new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    dtstart: toFloatingDateTime(startDate, tzid),
  }).toString();
}

export function getBiWeeklyRRuleString(startDate: string, tzid: string) {
  return new RRule({
    freq: RRule.WEEKLY,
    interval: 2,
    dtstart: toFloatingDateTime(startDate, tzid),
  }).toString();
}

export function getDailyRRuleString(startDate: string, tzid: string) {
  return new RRule({
    freq: RRule.DAILY,
    interval: 1,
    dtstart: toFloatingDateTime(startDate, tzid),
  }).toString();
}

export function getMonthlyRRuleString(
  startDate: string,
  tzid: string,
  occurrence: number | undefined
) {
  const setPos =
    occurrence !== undefined
      ? occurrence
      : getDayOccurrenceOfMonth(startDate) < 5
      ? getDayOccurrenceOfMonth(startDate)
      : -1;
  return new RRule({
    freq: RRule.MONTHLY,
    interval: 1,
    dtstart: toFloatingDateTime(startDate, tzid),
    byweekday: getRRuleDay(startDate),
    bysetpos: setPos,
  }).toString();
}

export function getEveryThreeMonthsRRuleString(
  startDate: string,
  tzid: string,
  occurrence: number | undefined
) {
  const setPos =
    occurrence !== undefined
      ? occurrence
      : getDayOccurrenceOfMonth(startDate) < 5
      ? getDayOccurrenceOfMonth(startDate)
      : -1;
  return new RRule({
    freq: RRule.MONTHLY,
    interval: 3,
    dtstart: toFloatingDateTime(startDate, tzid),
    byweekday: getRRuleDay(startDate),
    bysetpos: setPos,
  }).toString();
}
