import React from 'react';

import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderTaskApi } from '@headway/api/resources/ProviderTaskApi';

interface ProviderTaskProviderProps {
  providerId: number;
}

interface ProviderTaskProviderState {
  isLoading: boolean;
  error?: Error;
  providerTasks: ProviderTaskRead[];
}

export type ProviderTaskContextState = ProviderTaskProviderState & {
  reload: () => void;
};

export const ProviderTaskContext =
  React.createContext<ProviderTaskContextState>({
    isLoading: true,
    providerTasks: [],
    reload: () => {},
  });

export class ProviderTaskProvider extends React.Component<
  React.PropsWithChildren<ProviderTaskProviderProps>,
  ProviderTaskProviderState
> {
  state: ProviderTaskProviderState = {
    isLoading: true,
    providerTasks: [],
  };

  componentDidMount() {
    this.fetchProviderTasks();
  }

  fetchProviderTasks = async () => {
    this.setState({ error: undefined, isLoading: true });

    try {
      const providerTasks = await ProviderTaskApi.getProviderTasks({
        provider_id: this.props.providerId,
      });

      this.setState({
        providerTasks,
      });
    } catch (error: unknown) {
      this.setState({ error: error as Error });
    }
    this.setState({ isLoading: false });
  };

  reload = () => {
    this.fetchProviderTasks();
  };

  render() {
    return (
      <ProviderTaskContext.Provider
        value={{
          ...this.state,
          reload: this.reload,
        }}
      >
        {this.props.children}
      </ProviderTaskContext.Provider>
    );
  }
}
