import * as React from 'react';

export const Warning = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={19}
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z"
      //fill="#FF9800"
      fill="currentColor"
    />
  </svg>
);
