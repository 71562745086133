import React, { Provider, useMemo } from 'react';

import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderTaskType } from '@headway/api/models/ProviderTaskType';
import { Button } from '@headway/helix/Button';
import { GuidanceCard, GuidanceCardProps } from '@headway/helix/GuidanceCard';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { convertListToSentence } from '@headway/shared/utils/strings';

import { TaskStatusMap } from 'views/InsuranceStatus/utils';

import { useProvider } from '../../hooks';
import { PayerStatesMap, PayerStatus, PayerStatusMap } from './PayerStatus';

const CoiTaskGuidanceCard = ({
  setActiveCoiModal,
}: {
  setActiveCoiModal: (arg: boolean) => void;
}) => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      <>
        <span>
          <b>Action Required:</b> There's an issue with your malpractice
          insurance.
        </span>
        <Button
          onPress={() => {
            setActiveCoiModal(true);
            console.log('set active coi');
          }}
          variant="link"
        >
          Learn more
        </Button>
      </>
    </GuidanceCard>
  );
};

const NonCoiTaskGuidanceCard = ({
  task,
  setActiveProviderTask,
}: {
  task: ProviderTaskRead;
  setActiveProviderTask: (arg: ProviderTaskRead | undefined) => void;
}) => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      <>
        <span>
          <b>Action Required:</b> We need more information on your {task?.title}{' '}
          to finish getting you credentialed. We'll share an updated ETA as soon
          as we can.
        </span>
        <Button
          onPress={() => {
            setActiveProviderTask(task);
          }}
          variant="link"
        >
          Learn more
        </Button>
      </>
    </GuidanceCard>
  );
};

export const ProviderTaskGuidanceCard = ({
  tasks,
  setActiveProviderTask,
  setActiveCoiModal,
}: {
  tasks: { taskStatusMap: TaskStatusMap } | undefined;
  setActiveProviderTask: (arg: ProviderTaskRead | undefined) => void;
  setActiveCoiModal: (arg: boolean) => void;
}) => {
  const actionNeededTasks = tasks?.taskStatusMap?.ACTION_NEEDED ?? [];
  const actionNeededHighPriorityTasks =
    tasks?.taskStatusMap?.ACTION_NEEDED_HIGH_PRIORITY ?? [];
  const totalTaskList = actionNeededHighPriorityTasks.concat(actionNeededTasks);
  const taskList = totalTaskList.filter(
    (task) => task.taskType !== ProviderTaskType.CERTIFICATE_OF_INSURANCE
  );

  return (
    <div className="grid gap-y-4">
      {taskList?.map((task) => (
        <div>
          <NonCoiTaskGuidanceCard
            task={task}
            setActiveProviderTask={setActiveProviderTask}
          />
        </div>
      ))}
      {taskList.length !== totalTaskList.length && (
        <div>
          <CoiTaskGuidanceCard setActiveCoiModal={setActiveCoiModal} />
        </div>
      )}
    </div>
  );
};

const shouldShowAppliedGuidanceCard = (payerStatusMap: PayerStatusMap) => {
  const totalAppliedCarriers =
    payerStatusMap.get(PayerStatus.APPLIED)?.size ?? 0;
  const totalCompleteCarriers =
    payerStatusMap.get(PayerStatus.COMPLETE)?.size ?? 0;

  let totalCarriers = 0;
  for (let carriers of payerStatusMap.values()) {
    totalCarriers += carriers.size;
  }

  return !!(
    totalAppliedCarriers &&
    totalAppliedCarriers + totalCompleteCarriers === totalCarriers
  );
};

const convertPayerStatesMapToStrings = (
  payerStatesMap: PayerStatesMap,
  isMscBetaEnabled: boolean
): string[] => {
  if (!isMscBetaEnabled) {
    return [convertListToSentence(Array.from(payerStatesMap.keys()))];
  }
  return Array.from(payerStatesMap.entries()).map(([payerName, states]) => {
    const stateDisplayNames = states.map(
      (state) => statesToDisplayNames[state]
    );
    return `${payerName} in ${convertListToSentence(stateDisplayNames)}`;
  });
};

type PayerStatusGuidanceCardProps = {
  variant: GuidanceCardProps['variant'];
  payerStatesMap: PayerStatesMap;
  isMSCBetaEnabled: boolean;
  copyBeforePayers: string;
  endOfSentencePunctuationForOnePayer: string;
  copyAfterPayers: string;
};

const PayerStatusGuidanceCard = ({
  variant,
  payerStatesMap,
  isMSCBetaEnabled,
  copyBeforePayers,
  endOfSentencePunctuationForOnePayer,
  copyAfterPayers,
}: PayerStatusGuidanceCardProps) => {
  const payerStateStrings = convertPayerStatesMapToStrings(
    payerStatesMap,
    isMSCBetaEnabled
  );
  const shouldDisplayList = payerStateStrings.length > 1;
  return (
    <GuidanceCard variant={variant}>
      <span>
        {copyBeforePayers}
        {shouldDisplayList ? (
          <>
            :
            <ul css={{ margin: 0 }}>
              {payerStateStrings.map((payerStateString, index) => (
                <li key={index}>{payerStateString}</li>
              ))}
            </ul>
          </>
        ) : (
          <>
            {' '}
            {payerStateStrings[0]}
            {endOfSentencePunctuationForOnePayer}{' '}
          </>
        )}
        {copyAfterPayers}
      </span>
    </GuidanceCard>
  );
};

type GuidanceCardsProps = {
  payerStatusMap: PayerStatusMap;
  taskStatusMap?: TaskStatusMap;
};

export const GuidanceCards = ({
  payerStatusMap,
  taskStatusMap,
}: GuidanceCardsProps) => {
  const { email } = useProvider();
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);

  const actionNeededTasks: string[] = useMemo(() => {
    if (taskStatusMap === undefined) return [];

    const actionNeeded =
      taskStatusMap.ACTION_NEEDED?.map(
        (task: ProviderTaskRead) => task.title
      ) ?? [];

    const actionNeededHighPriority =
      taskStatusMap.ACTION_NEEDED_HIGH_PRIORITY?.map(
        (task: ProviderTaskRead) => task.title
      ) ?? [];

    return actionNeeded.concat(actionNeededHighPriority);
  }, [taskStatusMap]);

  return (
    <>
      {payerStatusMap.has(PayerStatus.PAYMENT_READY) && (
        <PayerStatusGuidanceCard
          variant="info"
          payerStatesMap={payerStatusMap.get(PayerStatus.PAYMENT_READY)!}
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="Your first payment date is now confirmed with"
          endOfSentencePunctuationForOnePayer="!"
          copyAfterPayers="You’ll be paid on the 15th and the last day of each month from
            there."
        />
      )}

      {payerStatusMap.has(
        PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED
      ) && (
        <PayerStatusGuidanceCard
          variant="error"
          payerStatesMap={
            payerStatusMap.get(
              PayerStatus.PAYER_QUESTIONNAIRE_PENDING_ACTION_NEEDED
            )!
          }
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="Finish your application to start seeing clients with"
          endOfSentencePunctuationForOnePayer="."
          copyAfterPayers="We just need a few pieces of information."
        />
      )}

      {payerStatusMap.has(PayerStatus.CREDENTIALING_DELAYED) && (
        <PayerStatusGuidanceCard
          variant="warning"
          payerStatesMap={
            payerStatusMap.get(PayerStatus.CREDENTIALING_DELAYED)!
          }
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="The credentialing process has been delayed with"
          endOfSentencePunctuationForOnePayer="."
          copyAfterPayers="Thanks for your patience--you don’t need to do anything. We’re
          working on getting you credentialed as soon as possible."
        />
      )}

      {payerStatusMap.has(PayerStatus.APPOINTMENT_READY) ? (
        <PayerStatusGuidanceCard
          variant="info"
          payerStatesMap={payerStatusMap.get(PayerStatus.APPOINTMENT_READY)!}
          isMSCBetaEnabled={isMSCBetaEnabled}
          copyBeforePayers="You can now take appointments with"
          endOfSentencePunctuationForOnePayer="!"
          copyAfterPayers="We’ve added estimated dates for your first payment."
        />
      ) : (
        shouldShowAppliedGuidanceCard(payerStatusMap) && (
          <GuidanceCard variant="info">
            We’re working with your insurance companies to review your files.
            There’s nothing you need to do.
          </GuidanceCard>
        )
      )}
    </>
  );
};
