import { Skeleton } from '@mui/material';
import React from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useAuthStore } from 'stores/AuthStore';

import { getContractIds } from '../helpers/utils';

interface IroncladAgreementsListProps {
  styles?: any;
  isAdminAcceptingNewStateAddendums?: boolean;
}

const defaultCSS = {
  width: '100%',
  marginLeft: theme.spacing.x11,
  listStyleType: 'disc',
};

export const IroncladAgreementsList = ({
  isAdminAcceptingNewStateAddendums = false,
  ...props
}: IroncladAgreementsListProps) => {
  const { provider } = useAuthStore();
  const { ironcladAgreementInfo } = useIroncladAgreementInfo(true);
  const { stateswithAcceptedAgreements } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates: true,
  });
  const { mainContractId, stateAddendumContractIds, agreementType } =
    ironcladAgreementInfo || {};

  if (!mainContractId || !stateAddendumContractIds || !agreementType)
    return (
      <div
        css={{
          margin: `${theme.spacing.x4} 0`,
        }}
      >
        <Skeleton variant="rectangular" height={100} />
      </div>
    );

  const formattedAgreementType = agreementType.replaceAll('-', ' ');
  const currentStatesPendingAgreement = Object.keys(
    getContractIds(
      provider,
      stateswithAcceptedAgreements,
      isAdminAcceptingNewStateAddendums,
      ironcladAgreementInfo
    )
  ).filter((state) => stateAddendumContractIds[state]);

  return (
    <ul css={defaultCSS} {...props}>
      <li css={{ textTransform: 'capitalize' }}>
        <Link
          target="_blank"
          rel="noreferrer"
          href={`https://vault.pactsafe.io/s/${process.env.REACT_APP_IRONCLAD_SITE_ID}/legal.html?#${mainContractId}`}
        >
          Headway's {formattedAgreementType} Agreement
        </Link>
      </li>

      {currentStatesPendingAgreement.map((state: string) => (
        <li key={state}>
          <Link
            target="_blank"
            rel="noreferrer"
            href={`https://vault.pactsafe.io/s/${process.env.REACT_APP_IRONCLAD_SITE_ID}/legal.html?#${stateAddendumContractIds[state]}`}
          >
            {statesToDisplayNames[state as UnitedStates]} Addendum
          </Link>
        </li>
      ))}
    </ul>
  );
};
