import React, { useRef } from 'react';
import { mergeProps, useFocusRing, useHover, useLink } from 'react-aria';

import { DATA } from './consts';
import { LinkProps } from './Link';

export type LinkButtonProps<T extends 'a' | React.JSXElementConstructor<any>> =
  {
    size?: 'medium' | 'large';
    variant?: 'brand' | 'primary' | 'secondary' | 'link';
  } & LinkProps<T>;

export function LinkButton<T extends 'a' | React.JSXElementConstructor<any>>({
  component: Component = 'a',
  size = 'large',
  variant = 'primary',
  disabled: isDisabled = false,
  icon,
  elementType,
  ...props
}: LinkButtonProps<T>) {
  const ref = useRef<HTMLAnchorElement>(null);
  const { linkProps, isPressed } = useLink(
    { elementType: elementType, isDisabled, ...props },
    ref
  );
  const { hoverProps, isHovered } = useHover({ isDisabled: isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus: props.autoFocus,
  });

  return (
    <Component
      className="hlx-button hlx-link-button"
      {...props}
      {...mergeProps(linkProps, hoverProps, focusProps, {
        [DATA.DISABLED]: isDisabled,
        [DATA.HOVERED]: isHovered,
        [DATA.FOCUSED]: isFocusVisible,
        'data-hlx-pressed': isPressed,
        'data-hlx-variant': variant,
        'data-hlx-size': size,
      })}
      ref={ref}
    >
      {props.children}
    </Component>
  );
}
