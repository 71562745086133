import React from 'react';

import { DiagnosisCodeComboBox } from '@headway/ui/DiagnosisCodeComboBox';

import { SchemaComponent } from '../../schema/schema.types';
import { getValue } from '../../view/utils';
import { ViewComponent } from './ViewComponent';

export const FormDiagnosisSelect = ({
  element,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, placeholder, subTitle } = element;

  if (readOnly) {
    const code = getValue(id);
    return (
      <ViewComponent
        title={'Diagnosis codes'}
        value={`${code.value} — ${code.display}`}
      />
    );
  }

  return (
    <DiagnosisCodeComboBox
      name={id}
      disabled={disabled}
      instructionalText={subTitle}
      placeholder={placeholder}
      selectionMode="single"
    />
  );
};
