import React from 'react';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import Week from 'react-big-calendar/lib/Week';

// Based on https://github.com/jquense/react-big-calendar/blob/master/src/WorkWeek.js
export class CalendarThreeDayWeek extends Week {
  render() {
    let {
      date,
      localizer,
      min = localizer.startOf(new Date(), 'day'),
      max = localizer.endOf(new Date(), 'day'),
      scrollToTime = localizer.startOf(new Date(), 'day'),
      ...props
    } = this.props;
    let range = CalendarThreeDayWeek.range(date, this.props);

    return (
      <TimeGrid
        {...props}
        range={range}
        eventOffset={15}
        localizer={localizer}
        min={min}
        max={max}
        scrollToTime={scrollToTime}
      />
    );
  }
}

CalendarThreeDayWeek.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -3, 'day');
    case Navigate.NEXT:
      return localizer.add(date, 3, 'day');
    default:
      return date;
  }
};

CalendarThreeDayWeek.range = (date, { localizer }) => {
  let start = localizer.startOf(localizer.add(date, -1, 'day'), 'day');
  let end = localizer.endOf(localizer.add(date, 1, 'day'), 'day');

  return localizer.range(start, end);
};
