import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { FormikContextType } from 'formik';
import React from 'react';

import { Checkbox } from '@headway/helix/Checkbox';
import { validity } from '@headway/helix/FormControl';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import {
  MULTI_STATE_CREDENTIALING_BETA,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdmin } from 'utils/access';
import { getSignerId } from 'utils/ironcladAgreement';

import { IroncladAgreementsList } from './IroncladAmendmentsModal/components/IroncladAgreementsList';

export const IroncladAgreementCheckbox = ({
  formik,
  agreementFieldName = 'agreements',
  isAdminAcceptingNewStateAddendums = false,
}: {
  formik: FormikContextType<unknown>;
  agreementFieldName?: string;
  isAdminAcceptingNewStateAddendums?: boolean;
}) => {
  const { setFieldValue } = formik;
  const { user } = useAuthStore();
  const { ironcladAgreementInfo, isLoading } = useIroncladAgreementInfo(true);
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const isMSCOnboardingEnabled = useFlag(MULTI_STATE_CREDENTIALING_ONBOARDING);

  if (!ironcladAgreementInfo || isLoading) return <></>;

  const { groupKey, mainContractId, stateAddendumContractIds } =
    ironcladAgreementInfo || {};

  const isGroupPractice = !!user?.group_practice;
  const signerId = getSignerId(user, isGroupPractice);
  const customData = {
    customer_id: `user_id-${user.id}`,
  };

  return (
    <>
      {(isMSCBetaEnabled || isMSCOnboardingEnabled) &&
      mainContractId &&
      stateAddendumContractIds ? (
        <div>
          <Checkbox
            name={agreementFieldName}
            onChange={(checked) => {
              if (checked) {
                setFieldValue(agreementFieldName, true);
              } else {
                setFieldValue(agreementFieldName, false);
              }
            }}
          >
            {isGroupAdmin(user)
              ? 'By checking this box, I as the authorized group admin understand and agree to all of the following on behalf of the practice:'
              : 'By checking this box, I understand and agree to all of the following:'}
          </Checkbox>
          <IroncladAgreementsList
            isAdminAcceptingNewStateAddendums={
              isAdminAcceptingNewStateAddendums
            }
          />
        </div>
      ) : (
        <>
          <div
            css={{
              '& label': {
                color: theme.color.system.textBlack,
              },
              '.ps-contract': {
                paddingBottom: '0 !important',
              },
            }}
          >
            <PSClickWrap
              accessId={process.env.REACT_APP_IRONCLAD_SITE_ID}
              groupKey={groupKey}
              signerId={signerId.toString()}
              clickWrapStyle="combined"
              allowDisagreed
              onError={(message: any, event_type: any, context: any) => {
                notifyError(
                  'Something went wrong while agreeing. Please refresh and try again later.'
                );
                logException(new Error(message), {
                  extra: {
                    eventType: event_type,
                    context,
                  },
                });
              }}
              onValid={() => {
                setFieldValue(agreementFieldName, true);
              }}
              onInvalid={() => {
                setFieldValue(agreementFieldName, false);
              }}
              customData={customData}
            />
          </div>
        </>
      )}
      {validity(agreementFieldName, formik).validity === 'invalid' && (
        <SubBodyText color="red">
          <strong>{(formik as any).errors[agreementFieldName]}</strong>
        </SubBodyText>
      )}
    </>
  );
};
