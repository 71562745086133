import * as React from 'react';

export const IndentIncrease = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.325 16.675v-1.088h13.369v1.088H3.325zm0-4.181V7.45l2.587 2.475-2.587 2.569zm0-8.1V3.306h13.369v1.088H3.325zM9.531 13.6v-1.069h7.163V13.6H9.53zm0-3.075V9.456h7.163v1.069H9.53zm0-3.075V6.381h7.163V7.45H9.53z"
      fill="currentColor"
    />
  </svg>
);
