import { Divider, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { theme } from '@headway/ui/theme';

interface ScheduleEventMenuLiveProps {
  open: boolean;
  handleEventTypeClick: (eventType: ProviderEventType) => void;

  onClose: () => void;
}

export const ScheduleEventMenuLive: FC<
  React.PropsWithChildren<ScheduleEventMenuLiveProps>
> = ({ open, handleEventTypeClick, onClose }) => {
  return (
    <>
      <MenuItem
        onClick={() => {
          handleEventTypeClick(ProviderEventType.APPOINTMENT);
          onClose();
        }}
        data-testid="calendarMenuAddSessionOption"
      >
        Add session
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleEventTypeClick(ProviderEventType.INTAKE_CALL);
          onClose();
        }}
      >
        Add phone consultation
      </MenuItem>
      <Divider css={{ margin: `${theme.space.xs} 0` }} />

      <MenuItem
        onClick={() => {
          handleEventTypeClick(ProviderEventType.BUSY);
          onClose();
        }}
        data-testid="calendarMenuAddUnavailabilityOption"
      >
        Add unavailability
      </MenuItem>

      <MenuItem
        component={Link}
        to="/settings/calendar"
        data-testid="calendarMenuUpdateWorkingHoursOption"
      >
        Update working hours
      </MenuItem>
    </>
  );
};
