import { ChipProps, Chip as MuiChip } from '@mui/material';
import React, { FC } from 'react';

import { theme } from './theme';

export const Chip: FC<React.PropsWithChildren<ChipProps>> = React.forwardRef(
  (props, ref) => {
    return (
      <MuiChip
        ref={ref}
        sx={{
          fontFamily: theme.fontFamily.postGrotesk,
        }}
        {...props}
      />
    );
  }
);
