/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { PatientFormSchemaCreate } from '../models/PatientFormSchemaCreate';
import { PatientFormSchemaNestedRead } from '../models/PatientFormSchemaNestedRead';
import { PatientFormSchemaRead } from '../models/PatientFormSchemaRead';
import { PatientFormSchemaType } from '../models/PatientFormSchemaType';
import { PatientFormSchemaUpdate } from '../models/PatientFormSchemaUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class PatientFormSchemaApiResource {

    /**
     * Create Patient Form Schema
     * 
     * @param patientFormSchemaCreate 
     
     */
    public createPatientFormSchema(body: PatientFormSchemaCreate, axiosConfig?: AxiosRequestConfig): Promise<PatientFormSchemaRead> {
        const apiRoute = '/patient-form-schemas';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Patient Form Schema
     * 
     * @param patientFormSchemaId 
     
     */
    public deletePatientFormSchema(patientFormSchemaId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/patient-form-schemas/{patient_form_schema_id}';
        const reqPath = apiRoute
                    .replace('{' + 'patient_form_schema_id}', String(patientFormSchemaId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Patient Form Schemas
     * 
     * @param providerId 
     * @param deletionStatus 
     
     */
    public findPatientFormSchemas(query?: { provider_id?: number, deletion_status?: Array<'ACTIVE' | 'ARCHIVED'> }, axiosConfig?: AxiosRequestConfig): Promise<Array<PatientFormSchemaNestedRead>> {
        const apiRoute = '/patient-form-schemas';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Patient Form Schemas By Owner And Form Type
     * 
     * @param ownerId 
     * @param formType 
     
     */
    public findPatientFormSchemasByOwnerAndFormType(ownerId: number, formType: PatientFormSchemaType, axiosConfig?: AxiosRequestConfig): Promise<Array<PatientFormSchemaNestedRead>> {
        const apiRoute = '/patient-form-schemas/{owner_id}/{form_type}';
        const reqPath = apiRoute
                    .replace('{' + 'owner_id}', String(ownerId))
                    .replace('{' + 'form_type}', String(formType));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Form Schema
     * 
     * @param patientFormSchemaId 
     
     */
    public getPatientFormSchema(patientFormSchemaId: number, axiosConfig?: AxiosRequestConfig): Promise<PatientFormSchemaNestedRead> {
        const apiRoute = '/patient-form-schemas/{patient_form_schema_id}';
        const reqPath = apiRoute
                    .replace('{' + 'patient_form_schema_id}', String(patientFormSchemaId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Patient Form Schema
     * 
     * @param patientFormSchemaId 
     * @param patientFormSchemaUpdate 
     
     */
    public updatePatientFormSchema(patientFormSchemaId: number, body: PatientFormSchemaUpdate, axiosConfig?: AxiosRequestConfig): Promise<PatientFormSchemaRead> {
        const apiRoute = '/patient-form-schemas/{patient_form_schema_id}';
        const reqPath = apiRoute
                    .replace('{' + 'patient_form_schema_id}', String(patientFormSchemaId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const PatientFormSchemaApi = new PatientFormSchemaApiResource();
