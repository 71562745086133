import React from 'react';

export function IconLock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.3335 18.3335V6.79183H6.04183V4.79183C6.04183 3.69461 6.42725 2.76058 7.19808 1.98975C7.96891 1.21891 8.90294 0.833496 10.0002 0.833496C11.0974 0.833496 12.0314 1.21891 12.8022 1.98975C13.5731 2.76058 13.9585 3.69461 13.9585 4.79183V6.79183H16.6668V18.3335H3.3335ZM7.29183 6.79183H12.7085V4.79183C12.7085 4.04183 12.4446 3.40294 11.9168 2.87516C11.3891 2.34739 10.7502 2.0835 10.0002 2.0835C9.25016 2.0835 8.61127 2.34739 8.0835 2.87516C7.55572 3.40294 7.29183 4.04183 7.29183 4.79183V6.79183ZM4.5835 17.0835H15.4168V8.04183H4.5835V17.0835ZM10.0002 14.1668C10.4446 14.1668 10.8231 14.0141 11.1356 13.7085C11.4481 13.4029 11.6043 13.0349 11.6043 12.6043C11.6043 12.1877 11.4481 11.8092 11.1356 11.4689C10.8231 11.1286 10.4446 10.9585 10.0002 10.9585C9.55572 10.9585 9.17725 11.1286 8.86475 11.4689C8.55225 11.8092 8.396 12.1877 8.396 12.6043C8.396 13.0349 8.55225 13.4029 8.86475 13.7085C9.17725 14.0141 9.55572 14.1668 10.0002 14.1668ZM4.5835 17.0835V8.04183V17.0835Z"
        fill="#717171"
      />
    </svg>
  );
}
