export enum SelectType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum LocationType {
  STATE = 'state',
  COUNTRY = 'country',
}

export enum LocationSelectData {
  locationSelectType = 'locationSelectType',
  comboBoxSelectionType = 'comboBoxSelectionType',
}

export interface LocationSelectDataInterface {
  locationSelectType: LocationType;
  comboBoxSelectionType: SelectType;
}
