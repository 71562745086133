import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  IESRSubmission,
  IESRSubmissionAssessmentType,
} from '@headway/api/models/IESRSubmission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsSchema,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const IESR_OPTIONS: OptionsSchema = [
  {
    displayText: 'Not at all',
    value: 0,
  },
  {
    displayText: 'A little bit',
    value: 1,
  },
  {
    displayText: 'Moderately',
    value: 2,
  },
  {
    displayText: 'Quite a bit',
    value: 3,
  },
  {
    displayText: 'Extremely',
    value: 4,
  },
];

const IESR_QUESTIONS: QuestionsSchema = [
  {
    key: 'IESR_1',
    questionText: 'Any reminder brought back feelings about it.',
  },
  {
    key: 'IESR_2',
    questionText: 'I had trouble staying asleep.',
  },
  {
    key: 'IESR_3',
    questionText: 'Other things kept making me think about it.',
  },
  {
    key: 'IESR_4',
    questionText: 'I felt irritable and angry.',
  },
  {
    key: 'IESR_5',
    questionText:
      'I avoided letting myself get upset when I thought about it or was reminded of it.',
  },
  {
    key: 'IESR_6',
    questionText: "I thought about it when I didn't mean to.",
  },
  {
    key: 'IESR_7',
    questionText: "I felt as if it hadn't happened or wasn't real.",
  },
  {
    key: 'IESR_8',
    questionText: 'I stayed away from reminders of it.',
  },
  {
    key: 'IESR_9',
    questionText: 'Pictures about it popped into my mind.',
  },
  {
    key: 'IESR_10',
    questionText: 'I was jumpy and easily startled.',
  },
  {
    key: 'IESR_11',
    questionText: 'I tried not to think about it.',
  },
  {
    key: 'IESR_12',
    questionText:
      "I was aware that I still had a lot of feelings about it, but I didn't deal with them.",
  },
  {
    key: 'IESR_13',
    questionText: 'My feelings about it were kind of numb.',
  },
  {
    key: 'IESR_14',
    questionText:
      'I found myself acting or feeling like I was back at that time.',
  },
  {
    key: 'IESR_15',
    questionText: 'I had trouble falling asleep.',
  },
  {
    key: 'IESR_16',
    questionText: 'I had waves of strong feelings about it.',
  },
  {
    key: 'IESR_17',
    questionText: 'I tried to remove it from my memory',
  },
  {
    key: 'IESR_18',
    questionText: 'I had trouble concentrating.',
  },
  {
    key: 'IESR_19',
    questionText:
      'Reminders of it caused me to have physical reactions, such as sweating, trouble breathing, nausea, or a pounding heart.',
  },
  {
    key: 'IESR_20',
    questionText: 'I had dreams about it.',
  },
  {
    key: 'IESR_21',
    questionText: 'I felt watchful and on-guard.',
  },
  {
    key: 'IESR_22',
    questionText: 'I tried not to talk about it.',
  },
];

const INSTRUCTION = (
  <>
    Below is a list of difficulties people sometimes have after stressful life
    events. Please read each item, and then indicate how distressing each
    difficulty has been for you <em>during the past seven days</em> with respect
    to your most distressing event. How much have you been distressed or
    bothered by these difficulties?
  </>
);

const IESR_VALIDATION_SCHEMA = Yup.object().shape(
  IESR_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type IESRFormValues = {
  [key in keyof Omit<IESRSubmission, 'assessmentType'>]: string;
};

export const IESRAssessmentForm = ({ id, onSubmit }: AssessmentFormProps) => {
  const innerOnSubmit = (values: IESRFormValues) => {
    return onSubmit({
      assessmentType: IESRSubmissionAssessmentType.IESR,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<IESRFormValues>
      onSubmit={innerOnSubmit}
      validationSchema={IESR_VALIDATION_SCHEMA}
      initialValues={{
        IESR_1: '',
        IESR_2: '',
        IESR_3: '',
        IESR_4: '',
        IESR_5: '',
        IESR_6: '',
        IESR_7: '',
        IESR_8: '',
        IESR_9: '',
        IESR_10: '',
        IESR_11: '',
        IESR_12: '',
        IESR_13: '',
        IESR_14: '',
        IESR_15: '',
        IESR_16: '',
        IESR_17: '',
        IESR_18: '',
        IESR_19: '',
        IESR_20: '',
        IESR_21: '',
        IESR_22: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={IESR_QUESTIONS}
            options={IESR_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyIESRAssessment = (props: ReadonlyAssessmentProps) => {
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={IESR_QUESTIONS}
        options={IESR_OPTIONS}
        response={props.response?.scorableResponseJson}
      />
    </section>
  );
};
