import { Skeleton } from '@mui/material';
import { Formik } from 'formik';
import sortBy from 'lodash/sortBy';
import React, { useState } from 'react';
import Script from 'react-load-script';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderUpdate } from '@headway/api/models/ProviderUpdate';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { ComboBox, Item } from '@headway/helix/ComboBox';
import { Form, FormStickyFooter } from '@headway/helix/Form';
import { FormControl, validity } from '@headway/helix/FormControl';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { Modal, Button as MuiButton } from '@headway/ui';
import { FieldCheckbox, FieldControl } from '@headway/ui/form';
import {
  MarketConsumer,
  MarketProvider,
} from '@headway/ui/providers/MarketProvider';
import { theme as legacyTheme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { useAuthStore } from 'stores/AuthStore';
import { useUiStore } from 'stores/UiStore';

import { ProviderBillingAccountsManagement } from './components/Billing/ProviderBillingAccountsManagement';
import { CancellationPolicy } from './components/CancellationPolicy';
import { ProviderSelfPay } from './components/ProviderSelfPay';

const plaidScriptUrl =
  'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

interface BillingImplProps {
  AuthStore: any;
  UiStore: any;
}

function BillingImpl(props: BillingImplProps) {
  const [isPlaidScriptLoading, setIsPlaidScriptLoading] = useState(true);

  const { setProvider } = useAuthStore();
  const updateProvider = async (providerUpdate: ProviderUpdate) => {
    const res = await ProviderApi.updateProvider(
      props.AuthStore.provider.id,
      providerUpdate
    );
    setProvider({
      ...props.AuthStore.provider,
      ...res,
    });
    return res;
  };

  return (
    <>
      {/* Load plaid link script on initialize due to
       * https://github.com/plaid/react-plaid-link/issues/121
       */}
      <Script
        url={plaidScriptUrl}
        onLoad={() => setIsPlaidScriptLoading(false)}
      />
      <CancellationPolicy
        provider={props.AuthStore.provider}
        onUpdateSuccess={props.AuthStore.setProvider}
      />

      <PageSection layout="grid.two-column">
        <div>
          <h2>
            <SectionHeader>Insurance rates</SectionHeader>
          </h2>
          <PageSectionSubText>
            <BodyText>
              The rates you’ll earn for appointments conducted through Headway.
            </BodyText>
          </PageSectionSubText>
        </div>
        <div css={{ justifySelf: 'end' }}>
          <LinkButton
            elementType="a"
            component={Link}
            to="/legal/rates"
            target="_blank"
            variant="secondary"
          >
            See rates
          </LinkButton>
        </div>
      </PageSection>

      <>
        <ProviderSelfPay
          provider={props.AuthStore.provider}
          onUpdateSuccess={props.AuthStore.setProvider}
        />
      </>

      {isPlaidScriptLoading ? (
        <Skeleton variant="rectangular" height={40} />
      ) : (
        <ProviderBillingAccountsManagement
          provider={props.AuthStore.provider}
          providerName={`${props.AuthStore.provider.firstName} ${props.AuthStore.provider.lastName}`}
          onBankAccountError={(e) => {
            props.UiStore.showWarningSnackbar(e);
          }}
          onStripeOnboardError={(e) => {
            props.UiStore.showWarningSnackbar(e);
          }}
          onBillingAccountCreate={(account, isGroupPracticeAccount) => {
            if (!isGroupPracticeAccount) {
              props.AuthStore.provider.activeBillingAccountId = account.id;
            }
          }}
        />
      )}

      <ProviderBillingFields
        firstName={props.AuthStore.provider.firstName}
        middleName={props.AuthStore.provider.middleName}
        lastName={props.AuthStore.provider.lastName}
        suffix={props.AuthStore.provider.suffix}
        stateOfResidence={props.AuthStore.provider.stateOfResidence}
        updateProvider={updateProvider}
      />
    </>
  );
}

export function Billing() {
  const uiStore = useUiStore();
  const authStore = useAuthStore();

  return <BillingImpl UiStore={uiStore} AuthStore={authStore} />;
}

const validationSchema = Yup.object().shape({
  lastName: Yup.string().required('Last name is required.'),
  firstName: Yup.string().required('First name is required.'),
  middleName: Yup.string().nullable(true),
  stateOfResidence: Yup.mixed()
    .oneOf(Object.values(UnitedStates))
    .required('State of residence is required.'),
  suffix: Yup.string().nullable(true),
});

interface ProviderBillingFieldsProps {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  stateOfResidence?: UnitedStates;
  updateProvider: (provider: ProviderUpdate) => Promise<ProviderRead>;
}

type FormValues = Omit<ProviderBillingFieldsProps, 'updateProvider'>;

export const ProviderBillingFields: React.FC<
  React.PropsWithChildren<ProviderBillingFieldsProps>
> = ({
  firstName,
  middleName,
  lastName,
  suffix,
  stateOfResidence,
  updateProvider,
}) => {
  const [showProviderBillingFieldModal, setShowProviderBillingFieldModal] =
    useState(false);

  const initialValues: FormValues = {
    firstName: firstName ?? '',
    middleName: middleName,
    lastName: lastName ?? '',
    suffix: suffix,
    stateOfResidence: stateOfResidence ?? undefined,
  };

  const handleOnSubmit = async (
    values: FormValues,
    resetForm: (values: { values: FormValues }) => void
  ) => {
    try {
      const res = await updateProvider({
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        suffix: values.suffix,
        stateOfResidence: values.stateOfResidence,
      });

      resetForm({
        values: {
          lastName: res.lastName,
          middleName: res.middleName,
          firstName: res.firstName,
          suffix: res.suffix,
          stateOfResidence: res?.stateOfResidence,
        },
      });

      notifySuccess('Billing information has been updated successfully.');
    } catch (err: AnyTS4TryCatchUnknownError) {
      notifyError(err.toString());
    }
  };

  return (
    <Formik
      onSubmit={async (values, { resetForm }) => {
        if (
          values.firstName !== initialValues.firstName ||
          values.lastName !== initialValues.lastName
        ) {
          setShowProviderBillingFieldModal(true);
        } else {
          handleOnSubmit(values, resetForm);
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const { isSubmitting, setFieldValue, values, dirty, resetForm } =
          formik;
        return (
          <Form>
            <MarketProvider>
              <MarketConsumer>
                {({ markets }) => (
                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>State of residence</SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        <BodyText>
                          Headway will use this information to determine your
                          eligibility for insurance panels. This address must
                          reflect where you live 50% of the year or more.
                        </BodyText>
                      </PageSectionSubText>
                    </div>
                    <div>
                      <ComboBox
                        name="stateOfResidence"
                        selectionMode="single"
                        label="State of residence"
                        items={sortBy(markets, 'displayName')}
                        selectedKeys={
                          values.stateOfResidence
                            ? [values.stateOfResidence]
                            : []
                        }
                        onSelectionChange={([value]) => {
                          setFieldValue('stateOfResidence', value);
                        }}
                        validation={validity('stateOfResidence', formik)}
                      >
                        {(item) => (
                          <Item key={item.state}>{item.displayName}</Item>
                        )}
                      </ComboBox>
                    </div>
                  </PageSection>
                )}
              </MarketConsumer>
            </MarketProvider>
            <PageSection layout="grid.two-column">
              <div>
                <h2>
                  <SectionHeader>Legal name</SectionHeader>
                </h2>
                <PageSectionSubText>
                  <p>
                    This should match the name on your therapy and/or medical
                    license, the NPI registry, and your CAQH account. We use
                    your legal name to submit claims and keep you credentialed
                    with insurance companies.
                  </p>
                  <p>
                    Note: You can change the name clients see on Headway in the
                    Profile tab.
                  </p>
                </PageSectionSubText>
              </div>
              <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
                <FormControl
                  autoComplete="off"
                  name="firstName"
                  label="First name"
                  component={TextField}
                />
                <FormControl
                  autoComplete="off"
                  name="middleName"
                  label="Middle name"
                  component={TextField}
                />
                <FormControl
                  autoComplete="off"
                  name="lastName"
                  label="Last name"
                  component={TextField}
                />
                <FormControl
                  autoComplete="off"
                  name="suffix"
                  label="Suffix"
                  component={TextField}
                />
              </div>
            </PageSection>
            <FormStickyFooter>
              <Button
                type="submit"
                disabled={isSubmitting || !dirty}
                variant="primary"
              >
                Save
              </Button>
            </FormStickyFooter>

            <Modal
              open={showProviderBillingFieldModal}
              onClose={() => setShowProviderBillingFieldModal(false)}
              title="Did your legal name change?"
            >
              <LegalNameModal
                closeModal={() => setShowProviderBillingFieldModal(false)}
                onConfirm={() => {
                  handleOnSubmit(values, resetForm);
                  setShowProviderBillingFieldModal(false);
                }}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export interface LegalNameModalProps {
  closeModal: () => void;
  onConfirm: () => void;
}

const confirmNameSchema = Yup.object().shape({
  confirmNameChange: Yup.boolean().oneOf(
    [true],
    'You must read and agree to the statement above.'
  ),
});

export const LegalNameModal: React.FC<
  React.PropsWithChildren<LegalNameModalProps>
> = ({ closeModal, onConfirm }) => {
  return (
    <Formik
      onSubmit={async () => {
        onConfirm();
      }}
      initialValues={{ confirmNameChange: false }}
      validationSchema={confirmNameSchema}
    >
      {({ values }) => {
        return (
          <Form>
            <div css={{ marginBottom: theme.spacing.x4 }}>
              <span>
                This change can impact your payments and ability to take
                insurance, so make sure it matches your therapy and/or medical
                license, the NPI registry, and your CAQH account.
              </span>
            </div>
            <FieldControl
              css={{
                margin: `${legacyTheme.space.xl} ${legacyTheme.space.sm}`,
              }}
              name="confirmNameChange"
            >
              <FieldCheckbox
                fieldType="boolean"
                value={values.confirmNameChange}
                data-testid="confirmNameChange"
                label="I confirm my updated legal name is correct."
              />
            </FieldControl>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: legacyTheme.space.xl,
              }}
            >
              <MuiButton
                color="gray"
                css={{ marginRight: legacyTheme.space.xs }}
                variant="outlined"
                onClick={closeModal}
              >
                Cancel
              </MuiButton>
              <MuiButton
                color="primary"
                variant="contained"
                type="submit"
                data-testid="ConfirmDocumentsModalContinueButton"
                disabled={!values.confirmNameChange}
              >
                Confirm
              </MuiButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
