/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* These values map to the tag values for the Insurance Verification Secondary Issue Type zendesk field */    /**
    * These values map to the tag values for the Insurance Verification Secondary Issue Type zendesk field
    */
export enum InsuranceVerificationSecondaryIssueType {
    INACTIVE_INSURANCE_DETAILS = 'inactive_insurance_details',
    INCORRECT_INSURANCE_DETAILS = 'incorrect_insurance_details',
    VERIFYING_BENEFITS_ELIGIBILITY = 'verifying_benefits___eligibility',
    MET_DEDUCTIBLE = 'met_deductible',
    CHANGES_IN_SESSION_COST = 'changes_in_session_cost'
}
