import React from 'react';

import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

interface PreventDragDropAppointmentModalProps {
  open: boolean;
  onClose: () => void;
}

export const PreventDragDropAppointmentModal = ({
  open,
  onClose,
}: PreventDragDropAppointmentModalProps) => {
  return (
    <Modal
      title="Cannot add session more than 30 days in the past"
      isOpen={open}
      onDismiss={onClose}
    >
      <ModalContent>
        This session's date is more than 30 days in the past. To ensure you are
        paid in a timely manner, we only allow adding sessions within the past
        30 days.
      </ModalContent>
      <ModalFooter>
        <Button onPress={onClose} variant="primary" size="large">
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};
