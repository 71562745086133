import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Banner } from '@headway/helix/Banner';
import { useQueryParam } from '@headway/shared/utils/queryParams';

import { Header } from 'components/Header/Header';
import { RemoveInitialLoadStyles } from 'utils/RemoveInitialLoadStyles';

import { usePatientForm } from '../../hooks/usePatientForm';
import { IntakeFormContentPreview } from './IntakeFormContentPreview';
import { InvitationEmailPreview } from './InvitationEmailPreview';

export const IntakeFormPreviews = () => {
  const [responseFormId] = useQueryParam('responseFormId');
  const [providerPatientId] = useQueryParam('providerPatientId');

  const { data: patientForm } = usePatientForm(
    {
      providerPatientId: parseInt(providerPatientId!),
      patientFormId: parseInt(responseFormId!, 10),
    },
    {
      enabled: !!(responseFormId && providerPatientId),
    }
  );

  return (
    <>
      <RemoveInitialLoadStyles />
      <Header />
      <div css={{ paddingTop: '50px' }}>
        {!patientForm?.sentOn && (
          <Banner variant="neutral">
            This is a preview of what your client would see if you choose to
            send this intake form
          </Banner>
        )}
        <div
          css={{
            maxWidth: '800px',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingLeft: '1.5em',
            paddingRight: '1.5em',
          }}
        >
          <Routes>
            <Route
              path="invitation-email-preview/:providerPatientId"
              element={<InvitationEmailPreview />}
            />
            <Route
              path=":patientFormSchemaId/*"
              element={<IntakeFormContentPreview form={patientForm} />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
};
