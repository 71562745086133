import React, { Component, ErrorInfo, ReactNode } from 'react';

import { LinkButton } from '@headway/helix/LinkButton';

interface Props {
  children?: ReactNode;
  onError: (error: string) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError(error.message + ' -- ' + errorInfo.componentStack);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Encountered a fatal error. Please refresh the page!</h1>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
