import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import React from 'react';

import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { useUserPaymentMethod } from '@headway/shared/hooks/useUserPaymentMethod';

import { useFindPastSessionsQueryKeyArgs } from 'hooks/useFindPastSessionsQueryKeyArgs';
import {
  useFindProviderEvents,
  useFindProviderEventsCache,
} from 'hooks/useFindProviderEvents';
import { useProvider } from 'hooks/useProvider';
import { PastSessionsList } from 'views/Patients/PastSessionsList';

export interface PastSessionsProps {
  clientId: number;
}

export const PastSessions = ({ clientId }: PastSessionsProps) => {
  const provider = useProvider();

  const findProviderEventsCache = useFindProviderEventsCache();
  const pastSessionsQueryKeyArgs = useFindPastSessionsQueryKeyArgs({
    providerId: provider.id,
    clientId,
  });
  const { data: pastAppointments, isLoading: isPastAppointmentsLoading } =
    useFindProviderEvents(pastSessionsQueryKeyArgs, {
      select: ({ data }) => data,
    });

  const { data: client } = useUser({ userId: clientId });
  const { data: paymentMethod, isLoading: isPaymentMethodLoading } =
    useUserPaymentMethod(client?.defaultUserPaymentMethodId);
  const onCloseConfirmSessionModal = () => {
    findProviderEventsCache.invalidate(pastSessionsQueryKeyArgs);
  };

  return pastAppointments &&
    client &&
    !isPaymentMethodLoading &&
    !isPastAppointmentsLoading ? (
    <div
      css={containerCss}
      style={{
        margin: pastAppointments.length > 0 ? `0 -${theme.spacing.x4}` : 0,
      }}
    >
      <PastSessionsList
        pastSessions={pastAppointments}
        onCloseConfirmSessionModal={onCloseConfirmSessionModal}
        patientUser={client}
        paymentMethod={paymentMethod}
        isLoading={false}
        variant="helix"
      />
    </div>
  ) : (
    <Skeleton height={64} variant="rectangular" />
  );
};

const containerCss = css({
  display: 'grid',
});
