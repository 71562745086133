import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Modal } from '@headway/ui';

import { PatientDemographicsForm } from './PatientDemographicsForm';

interface EditPatientModalProps {
  patientUser: UserRead;
  open: boolean;
  onClose: () => void;
  onContactEdit: (updated: UserRead) => void;
}
export const EditContactModal: React.FC<
  React.PropsWithChildren<EditPatientModalProps>
> = ({ patientUser, open, onClose, onContactEdit }) => {
  return (
    <Modal open={open} onClose={onClose} title="Edit Contact Info">
      <PatientDemographicsForm
        initialUser={patientUser}
        onSubmit={(updatedPatient) => {
          onContactEdit(updatedPatient);
          onClose();
        }}
      />
    </Modal>
  );
};
