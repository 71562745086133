import styled from '@emotion/styled';
import MuiTab, { TabProps as MuiTabProps } from '@mui/material/Tab';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import React, { FC, useEffect, useState } from 'react';

import { CopyCaption } from '../landing';
import { theme } from '../theme';

type TTabValue = number | string;

type TTabsContext = {
  activeTab?: TTabValue;
  setActiveTab: (tab: TTabValue) => void;
};
const TabsContext = React.createContext<TTabsContext | undefined>(undefined);

type SwitchProps = Omit<MuiTabsProps, 'value'> & {
  defaultActiveTab: TTabValue;
  value?: TTabValue;
};

// @ts-ignore https://github.com/mui-org/material-ui/issues/17454 on why we omit onChange
type TabProps = Omit<MuiTabProps, 'onChange' | 'value'> & {
  onChange?: (e?: React.SyntheticEvent<any>, tab?: TTabValue) => void;
  value: TTabValue;
};

type PanelProps = {
  tab: TTabValue;
};

type TabsComposition = {
  Panel: FC<React.PropsWithChildren<PanelProps>>;
  Switch: FC<React.PropsWithChildren<SwitchProps>>;
  Tab: FC<React.PropsWithChildren<TabProps>>;
};

/** Tabs with a sliding line indicator at the bottom */
const TabsLine: FC<React.PropsWithChildren<unknown>> & TabsComposition = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState<TTabValue | undefined>();
  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </TabsContext.Provider>
  );
};

/**
 * Tab switcher. Use with TabsLine.Tab as children.
 * @param defaultActiveTab – value of the default active tab
 **/
const Switch: FC<React.PropsWithChildren<SwitchProps>> = ({
  children,
  defaultActiveTab,
  ...props
}) => {
  const tabsContext = React.useContext(TabsContext);

  useEffect(() => {
    if (tabsContext && tabsContext.activeTab === undefined) {
      tabsContext.setActiveTab(defaultActiveTab);
    }
  }, []);

  return (
    <SwitchContainer>
      <MuiTabs
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value={tabsContext?.activeTab || defaultActiveTab}
        variant="scrollable"
        {...props}
      >
        {children}
      </MuiTabs>
    </SwitchContainer>
  );
};

/**
 * A single tab for use as child of TabsLine.Switch.
 * @param value – used as an id to link the tab to corresponding panels
 **/
const Tab: FC<React.PropsWithChildren<TabProps>> = ({
  label,
  onChange,
  value,
  ...props
}) => {
  const tabsContext = React.useContext(TabsContext);
  const handleTabChange = (e: React.SyntheticEvent<any>, tab: TTabValue) => {
    onChange?.(e, tab);
    tabsContext?.setActiveTab(tab);
  };

  return (
    <TabStyled
      label={<TabName>{label}</TabName>}
      onChange={handleTabChange}
      value={value}
      {...a11yTabProps(value)}
      {...props}
    />
  );
};

/**
 * Panel that displays children if corresponding tab is selected.
 * @param tab – value of the tab that this panel corresponds to
 **/
const Panel: FC<React.PropsWithChildren<PanelProps>> = ({ children, tab }) => {
  const tabsContext = React.useContext(TabsContext);
  return tabsContext?.activeTab === tab ? (
    <div role="tabpanel" {...a11yTabPanelProps(tab)}>
      {children}
    </div>
  ) : null;
};

/* Helpers */
const a11yTabProps = (id: TTabValue) => ({
  'aria-controls': `scrollable-auto-tabpanel-${id}`,
  id: `scrollable-auto-tab-${id}`,
});

const a11yTabPanelProps = (id: TTabValue) => ({
  'aria-labelledby': `scrollable-auto-tab-${id}`,
  id: `scrollable-auto-tabpanel-${id}`,
});

/* Styled components */
const SwitchContainer = styled.div`
  box-shadow: 0px -2px 0px ${theme.color.border} inset;
  width: fit-content;
`;

const TabName = styled(CopyCaption)`
  &&& {
    margin: ${theme.space.xs} 0 ${theme.space.xs3} 0;
    padding: 0 ${theme.space.sm};
    text-transform: none;
  }
`;
const TabStyled = styled(MuiTab)`
  &&& {
    border-radius: ${theme.space.xs};
  }
`;

TabsLine.Panel = Panel;
TabsLine.Switch = Switch;
TabsLine.Tab = Tab;
export { TabsLine };
