import { FrontEndCarrierApi } from '@headway/api/resources/FrontEndCarrierApi';
import { PayerQuestionnaireApi } from '@headway/api/resources/PayerQuestionnaireApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePayerQuestionnaireKeyArgs {
  payerQuestionnaireResponseId?: string;
  incompleteOnly?: boolean;
}

export const getUsePayerQuestionnaireKey = ({
  payerQuestionnaireResponseId,
  incompleteOnly,
}: UsePayerQuestionnaireKeyArgs): [
  string,
  string | undefined,
  boolean | undefined,
] => ['payer-questionnaire', payerQuestionnaireResponseId, incompleteOnly];

const {
  useSingleQuery: usePayerQuestionnaire,
  useListQuery: usePayerQuestionnaireList,
} = createBasicApiHooks(
  getUsePayerQuestionnaireKey,
  async ({ payerQuestionnaireResponseId, incompleteOnly = true }) => {
    if (!payerQuestionnaireResponseId) return;

    const payerQuestionnaire =
      await PayerQuestionnaireApi.getPayerQuestionnaire({
        payer_questionnaire_response_id: Number(payerQuestionnaireResponseId),
        incomplete_only: incompleteOnly,
      });

    if (!payerQuestionnaire) {
      throw new Error('Payer questionnaire not found');
    }

    const frontEndCarrier = await FrontEndCarrierApi.getFrontEndCarrier(
      payerQuestionnaire.template.frontEndCarrierId
    );

    return {
      frontEndCarrier,
      payerQuestionnaire,
    };
  },
  ({ payerQuestionnaireResponseId }) => {
    return !!payerQuestionnaireResponseId;
  },
  () => 'Failed to fetch payer questionnaire'
);

export { usePayerQuestionnaire, usePayerQuestionnaireList };
