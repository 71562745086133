import React from 'react';

export function IconClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6.13661 18.9134L6.225 19.0018L6.31339 18.9134L12 13.2268L17.6866 18.9134L17.775 19.0018L17.8634 18.9134L18.9134 17.8634L19.0018 17.775L18.9134 17.6866L13.2268 12L18.9134 6.31338L19.0018 6.22499L18.9134 6.1366L17.8634 5.0866L17.775 4.99821L17.6866 5.0866L12 10.7732L6.31339 5.0866L6.225 4.99821L6.13661 5.0866L5.08661 6.1366L4.99823 6.22499L5.08661 6.31338L10.7732 12L5.08661 17.6866L4.99823 17.775L5.08661 17.8634L6.13661 18.9134Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.25"
      />
    </svg>
  );
}
