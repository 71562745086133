import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';

import { IconButton } from '@headway/helix/IconButton';
import { IconChevronLeft } from '@headway/helix/icons/ChevronLeft';
import { IconChevronRight } from '@headway/helix/icons/ChevronRight';

import { DATA } from './consts';
import { useInteraction } from './useInteraction';

export type PaginationProps = {
  totalPages: number;
  onPageChange?: (page: number) => void;
  page: number;
  name?: string;
};

const getRange = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(undefined)
    .map((v, i) => i + start);
};

const generatePagination = (currentPage: number, pageCount: number) => {
  let delta: number;
  if (pageCount <= 7) {
    // delta === 7: [1 2 3 4 5 6 7]
    delta = 7;
  } else {
    // delta === 3: [1 ... 4 5 6 7 ... 10]
    // delta === 4: [1 2 3 4 5 ... 10]
    delta = currentPage > 4 && currentPage < pageCount - 3 ? 3 : 4;
  }

  const range = {
    start: Math.round(currentPage - delta / 2),
    end: Math.round(currentPage + delta / 2),
  };

  if (range.start - 1 === 1 || range.end + 1 === pageCount) {
    range.start += 1;
    range.end += 1;
  }

  let pages: any =
    currentPage > delta
      ? getRange(
          Math.min(range.start, pageCount - delta),
          Math.min(range.end, pageCount)
        )
      : getRange(1, Math.min(pageCount, delta + 1));

  const withDots = (value: number, pair: any) =>
    pages.length + 1 !== pageCount ? pair : [value];

  if (pages[0] !== 1) {
    pages = withDots(1, [1, '...']).concat(pages);
  }

  if (pages[pages.length - 1] < pageCount) {
    pages = pages.concat(withDots(pageCount, ['...', pageCount]));
  }

  return pages;
};

export function Pagination(props: PaginationProps) {
  const paginationArray = generatePagination(props.page, props.totalPages);

  const onNextPageClick = (newPageNumber: number) => {
    const { onPageChange, page } = props;
    if (!onPageChange || newPageNumber === page) {
      return;
    }

    onPageChange(newPageNumber);
  };

  // use type button if there is a callback to handle
  // otherwise let the browser submit the form if any
  const buttonType = props.onPageChange ? 'button' : 'submit';

  const { totalPages, page, name } = props;
  return (
    <nav role="navigation" aria-label="pagination" className="hlx-pagination">
      <ul>
        <li className="hlx-pagination-item">
          <IconButton
            size="medium"
            disabled={page === 1}
            onPress={() => onNextPageClick(page - 1)}
            variant="transparent"
            type={buttonType}
            name={name}
            value={(page - 1).toString()}
          >
            <IconChevronLeft />
          </IconButton>
        </li>

        {paginationArray.length &&
          paginationArray.map((pageNumber: number | string, index: number) => {
            if (typeof pageNumber === 'number') {
              return (
                <li className="hlx-pagination-item" key={index}>
                  <PageButton
                    pageNumber={pageNumber}
                    currentPage={page}
                    onNextPageClick={onNextPageClick}
                    name={name}
                    type={buttonType}
                  />
                </li>
              );
            } else {
              return (
                <li className="hlx-pagination-item" key={index}>
                  <div className="hlx-pagination-ellipsis">
                    <span aria-label="ellipsis">&#8230;</span>
                  </div>
                </li>
              );
            }
          })}
        <li className="hlx-pagination-item">
          <IconButton
            size="medium"
            disabled={page === totalPages}
            onPress={() => onNextPageClick(page + 1)}
            variant="transparent"
            type={buttonType}
            name={name}
            value={(page + 1).toString()}
          >
            <IconChevronRight />
          </IconButton>
        </li>
      </ul>
    </nav>
  );
}

interface PageButtonProps {
  pageNumber: number;
  currentPage: number;
  onNextPageClick: (pageNumber: number) => void;
  name?: string;
  type: 'button' | 'submit';
}

const PageButton = ({
  pageNumber,
  currentPage,
  onNextPageClick,
  name,
  type,
}: PageButtonProps) => {
  const { hoverProps, focusProps, isHovered, isFocusVisible } = useInteraction({
    disabled: false,
  });
  const aria = currentPage == pageNumber ? { 'aria-current': true } : {};

  return (
    <button
      className={clsx('hlx-pagination-button', {
        'focus-ring': isFocusVisible,
      })}
      {...mergeProps(focusProps, hoverProps, {
        [DATA.HOVERED]: isHovered,
        'data-hlx-active': currentPage == pageNumber,
      })}
      onClick={() => {
        onNextPageClick(pageNumber);
      }}
      role="button"
      type={type}
      value={pageNumber}
      name={name}
      {...aria}
    >
      {pageNumber}
    </button>
  );
};
