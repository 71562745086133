import { css } from '@emotion/react';
import { Alert, AlertTitle, TablePagination } from '@mui/material';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { ListHeader } from '@headway/helix/ListHeader';
import { PageSection } from '@headway/helix/Page';
import { PageHeader } from '@headway/helix/PageHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { formatPatientName } from '@headway/shared/utils/patient';
import {
  formatPrice,
  getLastBusinessDayOnOrBefore,
} from '@headway/shared/utils/payments';

import { useProvider } from 'hooks/useProvider';
import { useProviderPayments } from 'hooks/useProviderPayments';

import {
  FormattedAppointmentInfo,
  getAdditionalTransactionAppointmentInfoFromAppointment,
} from './FormattedAppointmentInfo';
import { PaymentFeesTooltip } from './PaymentInfoTooltip';

const DEFAULT_PAGE_SIZE = 25;

type PaymentAggregate = Array<[string, number]>;

export default function UpcomingTab() {
  const provider = useProvider();
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentAggregate, setPaymentAggregate] = useState<
    PaymentAggregate | undefined
  >(undefined);
  const [pageSlice, setPageSlice] = useState<ConcreteProviderEventRead[]>([]);

  const query = queryString.parse(location.search);
  const page = Number(query.page) || 0;
  const pageSize = Number(query.pageSize) || DEFAULT_PAGE_SIZE;

  const isPausedPaymentsFlagEnabled = useFlag('pausedPayments', false);
  const providerPaymentDateStart = moment()
    .subtract(2, 'days')
    .format(moment.HTML5_FMT.DATE);

  const {
    isLoading,
    data: upcomingPaymentEvents,
    error,
  } = useProviderPayments(
    {
      providerId: provider.id,
      providerPaymentStatusEnums: [
        ProviderPaymentStatus.SCHEDULED,
        ProviderPaymentStatus.PAID,
      ],
      providerPaymentDateStart,
      isPausedPaymentsFlagEnabled,
    },
    {
      refetchOnWindowFocus: false, //Aggregating/paging can be expensive client side, don't refetch automatically
    }
  );

  const { data: underReviewPaymentEvents } = useProviderPayments(
    {
      providerId: provider.id,
      providerPaymentStatusEnums: [ProviderPaymentStatus.UNDER_REVIEW],
      isPausedPaymentsFlagEnabled,
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (!upcomingPaymentEvents || !upcomingPaymentEvents.data) {
      return;
    }

    const aggregate = upcomingPaymentEvents.data.reduce(
      (agg, event) => {
        const appt = event.providerAppointment;
        if (!appt || !appt.providerPaymentDate || !appt.providerPaymentAmount) {
          return agg;
        }

        const isPaid =
          appt.providerPaymentStatusEnum === ProviderPaymentStatus.PAID;

        let baseDate = moment(appt.providerPaymentDate);
        if (isPaid) {
          // Add 2 days for paid out appts to reflect when the payment should land in their account
          baseDate.add(2, 'days');
        } else {
          baseDate = getLastBusinessDayOnOrBefore(baseDate);
        }
        const dateFormat = baseDate.format('M/D/YYYY');
        agg[dateFormat] = agg[dateFormat] || 0;
        agg[dateFormat] += appt.providerPaymentAmount;

        return agg;
      },
      {} as { [key: string]: number }
    );

    const sorted = Object.entries(aggregate).sort(
      (a, b) =>
        moment(a[0]).toDate().getTime() - moment(b[0]).toDate().getTime()
    );

    const underReviewTotal =
      underReviewPaymentEvents?.data.reduce((total, event) => {
        return total + (event.providerAppointment?.providerPaymentAmount ?? 0);
      }, 0) ?? 0;

    if (isPausedPaymentsFlagEnabled && underReviewTotal > 0) {
      sorted.unshift(['Under review', underReviewTotal]);
    }
    setPaymentAggregate(sorted);
  }, [
    upcomingPaymentEvents,
    underReviewPaymentEvents,
    isPausedPaymentsFlagEnabled,
  ]);

  useEffect(() => {
    const events = [
      ...(underReviewPaymentEvents?.data ?? []),
      ...(upcomingPaymentEvents?.data ?? []),
    ];
    setPageSlice(events.slice(page * pageSize, page * pageSize + pageSize));
  }, [upcomingPaymentEvents, underReviewPaymentEvents, page, pageSize]);

  function handlePageChange(_: unknown, newPage: number) {
    navigate({
      pathname: location.pathname,
      search: `?page=${newPage}&pageSize=${pageSize}`,
    });
  }

  function handleRowsPerPageChange(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    navigate({
      pathname: location.pathname,
      search: `?page=0&pageSize=${e.target.value}`,
    });
  }

  const hasUpcomingPayments =
    !!upcomingPaymentEvents && upcomingPaymentEvents.data.length !== 0;

  return (
    <div>
      <div css={{ padding: theme.spacing.x2 }}>
        <ListHeader>Upcoming payments</ListHeader>
        {hasUpcomingPayments && paymentAggregate && (
          <>
            <ul
              css={{
                marginBottom: theme.spacing.x5,
                padding: 0,
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'space-around',
              }}
              aria-labelledby="upcoming-totals"
            >
              {paymentAggregate.map(([date, amount]) => (
                <li
                  key={date}
                  css={{
                    marginRight: theme.spacing.x6,
                    display: 'inline-block',
                    flexGrow: 1,
                  }}
                  aria-labelledby={`${date}-upcoming-total-date ${date}-upcoming-total-amount`}
                >
                  <div css={{ marginBottom: theme.spacing.x1 }}>
                    <SubBodyText>{date}</SubBodyText>
                  </div>
                  <div>
                    <PageHeader>{formatPrice(amount, false)}</PageHeader>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <PageSection css={paymentCss.table}>
        {pageSlice && (
          <Table aria-labelledby="upcoming-payments-title">
            <TableHeader>
              <Column>Payment date</Column>
              <Column>Description</Column>
              <Column align="right">
                Estimated amount
                <span css={{ marginRight: theme.spacing.x5 }} />
              </Column>
            </TableHeader>
            <TableBody>
              {pageSlice.map((e) => {
                const additionalTransactionInfo =
                  getAdditionalTransactionAppointmentInfoFromAppointment(e);

                return (
                  <Row key={e.id}>
                    <Cell>
                      {e &&
                        e.providerAppointment &&
                        (() => {
                          let baseDate = moment(
                            e.providerAppointment.providerPaymentDate
                          );

                          const isPaid =
                            e.providerAppointment.providerPaymentStatusEnum ===
                            ProviderPaymentStatus.PAID;

                          if (isPaid) {
                            baseDate.add(2, 'days');
                          } else {
                            baseDate = getLastBusinessDayOnOrBefore(baseDate);
                          }
                          if (
                            isPausedPaymentsFlagEnabled &&
                            e.providerAppointment.providerPaymentStatusEnum ===
                              ProviderPaymentStatus.UNDER_REVIEW
                          ) {
                            return 'Under review';
                          }
                          return baseDate.format('M/D/YYYY');
                        })()}
                    </Cell>
                    <Cell>
                      {e &&
                      e.providerAppointment &&
                      e.providerAppointment.status &&
                      e.providerAppointment.billingType &&
                      e.providerAppointment.patient &&
                      e.startDate ? (
                        <FormattedAppointmentInfo
                          status={e.providerAppointment.status}
                          billingType={e.providerAppointment.billingType}
                          appointmentStartAt={e.startDate}
                          patientName={formatPatientName(
                            e.providerAppointment.patient
                          )}
                          patientUserId={e.providerAppointment.patient.id}
                          frontEndCarrier={
                            additionalTransactionInfo.frontEndCarrier
                          }
                          appointmentStateLocation={
                            additionalTransactionInfo.appointmentStateLocation
                          }
                          isTelehealth={
                            !!additionalTransactionInfo.isTelehealth
                          }
                          isProviderIncentiveBonus={false}
                        />
                      ) : (
                        'Unable to format appointment details'
                      )}
                    </Cell>
                    <Cell>
                      {e && e.providerAppointment && (
                        <div css={{ marginLeft: 'auto' }}>
                          {formatPrice(
                            e.providerAppointment.providerPaymentAmount,
                            false
                          )}
                          <PaymentFeesTooltip
                            fees={
                              e.providerAppointment.providerPaymentFeesAmount
                            }
                            billingType={e.providerAppointment.billingType}
                          />
                        </div>
                      )}
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        )}
      </PageSection>
      {!error && hasUpcomingPayments ? (
        <TablePagination
          component="div"
          css={{
            '& p': {
              margin: 0,
            },
          }}
          rowsPerPageOptions={[25, 50, 100]}
          count={
            upcomingPaymentEvents.totalCount +
            (underReviewPaymentEvents?.totalCount ?? 0)
          }
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : null}
      {!isLoading && !error && !hasUpcomingPayments ? (
        <div
          css={{
            margin: `${theme.spacing.x10} auto`,
            textAlign: 'center',
            maxWidth: '30rem',
          }}
        >
          No upcoming payments to display. Confirm session details to see
          upcoming payments.
        </div>
      ) : null}
      {error ? (
        <div css={{ margin: `${theme.spacing.x12} auto`, maxWidth: '50rem' }}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We can't load your upcoming payments. Refresh the page to see if
            this is an ongoing issue.
          </Alert>
        </div>
      ) : null}
    </div>
  );
}

const paymentCss = {
  table: css({
    margin: 0,
    padding: 0,
  }),
};
