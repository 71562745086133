import clsx from 'clsx';
import React from 'react';

interface SkeletonBlockProps {
  className?: string;
}

export function SkeletonBlock({ className }: SkeletonBlockProps) {
  return (
    <div
      className={clsx(
        className,
        'block animate-pulse rounded bg-system-lightGray',
        className?.includes('h-') ? '' : 'h-[1em]'
      )}
    />
  );
}
