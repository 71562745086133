import { CustomerType } from '@headway/api/models/CustomerType';
import { InsuranceVerificationSecondaryIssueType } from '@headway/api/models/InsuranceVerificationSecondaryIssueType';
import { ProviderIssueType } from '@headway/api/models/ProviderIssueType';

let queryStringProvider = new URLSearchParams();
queryStringProvider.append(
  'submitterType',
  CustomerType.PROVIDER_ON_BEHALF_OF_CLIENT
);
queryStringProvider.append(
  'primaryIssueType',
  ProviderIssueType.CLIENT_BILLING_INSURANCE_ISSUES
);
queryStringProvider.append(
  'secondaryIssueType',
  InsuranceVerificationSecondaryIssueType.INACTIVE_INSURANCE_DETAILS
);
export const verifyInsuranceBenefitsProviderRedirectUrl = `${
  process.env.REACT_APP_MAIN_URL
}/contact?${queryStringProvider.toString()}`;
