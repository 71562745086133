import { get } from 'lodash';
import moment from 'moment';

import { ComponentTypes } from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/schemaRenderer/versions/1/types';
import {
  Component,
  Section,
} from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/schemaRenderer/versions/2/types';
import { isElementASection } from 'views/Calendar/components/AppointmentConfirmation/Forms/ProgressNotes/templateView/utils';

import { componentsWithMultipleValues } from '.';
import { Path, pathToString } from '../TreatmentPlanUtils';
import { Step, TreatmentPlanMetadata } from './types';

export enum TemplateErrorMessages {
  MISSING_REQUIRED_COMPONENT = 'MISSING_REQUIRED_COMPONENT',
  FUTURE_DATE_REQUIRED = 'FUTURE_DATE_REQUIRED',
  MISSING_VALUE_FOR_OTHER = 'MISSING_VALUE_FOR_OTHER',
  FUTURE_DATE_NO_MORE_THAN_90_DAYS = 'FUTURE_DATE_NO_MORE_THAN_90_DAYS',
}

type TemplateErrorCopyMap = {
  [key in TemplateErrorMessages]: { [key in ComponentTypes]?: string };
};

export const TEMPLATE_ERROR_MAP_COMPONENT_LEVEL: TemplateErrorCopyMap = {
  MISSING_REQUIRED_COMPONENT: {
    [ComponentTypes.checklist]: 'Select at least one option.',
    [ComponentTypes.dropdown]: 'Select an option.',
    [ComponentTypes.dropdownMulti]: 'Select at least one option.',
    [ComponentTypes.longFreeText]: 'Add details.',
    [ComponentTypes.shortFreeText]: 'Add details.',
    [ComponentTypes.richFreeText]: 'Add details.',
    [ComponentTypes.radio]: 'Select an option.',
    [ComponentTypes.date]: 'Enter a date.',
    [ComponentTypes.dropdownDiagnosis]: 'Select a diagnosis.',
  },
  FUTURE_DATE_REQUIRED: {
    [ComponentTypes.date]: 'Date must be in the future.',
  },
  MISSING_VALUE_FOR_OTHER: {
    [ComponentTypes.checklist]: 'Please tell why you chose Other.',
  },
  FUTURE_DATE_NO_MORE_THAN_90_DAYS: {
    [ComponentTypes.date]: 'Date must not be more than 90 days in the future.',
  },
};

export type ComponentError = { id: string; error: string };

const componentErrors = (
  values: object,
  component: Component<TreatmentPlanMetadata>,
  path: Path
) => {
  let error: string | undefined;
  const value = get(values, path);
  if (component.metadata?.required) {
    const errorMessage =
      TEMPLATE_ERROR_MAP_COMPONENT_LEVEL.MISSING_REQUIRED_COMPONENT[
        component.type
      ];

    if (errorMessage) {
      if (
        componentsWithMultipleValues.includes(component.type as ComponentTypes)
      ) {
        if (!value || value.length === 0) {
          error = errorMessage;
        } else if (
          // Check if 'Other' is selected and if the value is empty
          component.type === ComponentTypes.checklist &&
          Array.isArray(value) &&
          value.includes('Other')
        ) {
          const splitValues = value
            .find((value: string) => value.includes('Other-'))
            ?.split('-');
          if (!splitValues || splitValues <= 1 || splitValues[1] === '') {
            error =
              TEMPLATE_ERROR_MAP_COMPONENT_LEVEL.MISSING_VALUE_FOR_OTHER[
                component.type
              ];
          }
        }
      } else {
        if (!value || value === '') {
          error = errorMessage;
        }
      }
    }
  }

  if (component.metadata?.futureDateRequired) {
    if (component.type === ComponentTypes.date) {
      const errorMessage =
        TEMPLATE_ERROR_MAP_COMPONENT_LEVEL.FUTURE_DATE_REQUIRED[component.type];

      if (errorMessage) {
        if (value && moment(value).isBefore(moment())) {
          error = errorMessage;
        }
      }
    }
  }

  if (component.metadata?.futureDateNoMoreThan90Days) {
    if (component.type === ComponentTypes.date) {
      const errorMessage =
        TEMPLATE_ERROR_MAP_COMPONENT_LEVEL.FUTURE_DATE_NO_MORE_THAN_90_DAYS[
          component.type
        ];

      if (errorMessage) {
        if (value && moment(value).isAfter(moment().add(90, 'days'))) {
          error = errorMessage;
        }
      }
    }
  }

  return error;
};

const sectionErrors = (
  values: object,
  section: Section<TreatmentPlanMetadata>,
  path: Path
) => {
  const errorsList = (get(values, pathToString(path)) || []).map(
    (sectionInstance: any, idx: number) => {
      const errorObject = section.components.reduce(
        (
          errors: { [key: string]: any },
          element:
            | Section<TreatmentPlanMetadata>
            | Component<TreatmentPlanMetadata>
        ) => {
          const elementErrors = isElementASection(element)
            ? sectionErrors(values, element, [...path, idx, element.id!])
            : componentErrors(values, element, [...path, idx, element.id]);
          if (
            elementErrors &&
            (!Array.isArray(elementErrors) || elementErrors.length > 0)
          ) {
            errors[element.id!] = elementErrors;
          }
          return errors;
        },
        {}
      );
      return Object.keys(errorObject).length === 0 ? undefined : errorObject;
    }
  );
  return errorsList.every((error: any) => error === undefined)
    ? []
    : errorsList;
};

const stepErrors = (values: object, step: Step) => {
  return step.components.reduce((errors: { [key: string]: any }, element) => {
    const elementErrors = isElementASection(element)
      ? sectionErrors(values, element, [element.id!])
      : componentErrors(values, element, [element.id]);
    if (
      elementErrors &&
      (!Array.isArray(elementErrors) || elementErrors.length > 0)
    ) {
      errors[element.id!] = elementErrors;
    }
    return errors;
  }, {});
};

export const getErrors = (values: object, steps?: Step[]) =>
  steps?.reduce(
    (errorObject: { [key: string]: any }, step: Step) => ({
      ...errorObject,
      ...stepErrors(values, step),
    }),
    {}
  );

export const firstStepWithErrors = (values: object, steps?: Step[]) =>
  Math.max(
    (steps || []).findIndex((step) => stepHasErrors(values, step)),
    0
  );

export const stepHasErrors = (values: object, step: Step) => {
  return Object.keys(stepErrors(values, step)).length > 0;
};
