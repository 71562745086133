import { css } from '@emotion/react';
import React from 'react';

import { theme } from '@headway/helix/theme';

export interface IconListCardProps {
  iconTextPairings: Array<[JSX.Element, JSX.Element]>;
}

function IconListCard(props: IconListCardProps) {
  return (
    <div css={[calloutSection]}>
      {props.iconTextPairings.map(([icon, text], index) => (
        <div key={'icontextpair-' + index} css={calloutContainer}>
          {icon}
          <div css={calloutContent}>{text}</div>
        </div>
      ))}
    </div>
  );
}

const calloutSection = css`
  background-color: ${theme.color.system.backgroundGray};
  border-radius: 10px;
  padding: ${theme.spacing.x4} ${theme.spacing.x6} ${theme.spacing.x1}
    ${theme.spacing.x6};
  margin-bottom: ${theme.spacing.x4};
  margin-top: ${theme.spacing.x4};
`;
const calloutContainer = css`
  display: flex;
  margin-bottom: ${theme.spacing.x4};
  flex-wrap: wrap;
  gap: ${theme.spacing.x3};
`;
const calloutContent = css`
  flex: 1;
  display: flex;
  box-sizing: border-box;
`;

export { IconListCard };
