import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export const useCreateProviderEventMutation = (
  options: UseMutationWithSideEffectsOptions<
    ConcreteProviderEventRead,
    unknown,
    ProviderEventCreate
  > = {}
) => {
  return useMutationWithSideEffects(
    (providerEventCreate: ProviderEventCreate) =>
      ProviderEventApi.createEvent(providerEventCreate),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ConcreteProviderEventRead,
        unknown,
        ProviderEventCreate
      >()
        .addErrorLogging(() => 'There was a problem creating the event')
        .merge(options.sideEffects),
    }
  );
};

export const useDeleteProviderEventMutation = (
  options: UseMutationWithSideEffectsOptions<{}, unknown, string | number> = {}
) => {
  return useMutationWithSideEffects(
    (eventIdOrVirtualEventId: string | number) =>
      ProviderEventApi.deleteEvent(eventIdOrVirtualEventId),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<{}, unknown, string | number>()
        .addErrorLogging(() => 'There was a problem removing the event')
        .merge(options.sideEffects),
    }
  );
};

export interface UpdateProviderEventMutationArgs {
  eventIdOrVirtualId: string | number;
  update: ProviderEventUpdate;
}
export const useUpdateProviderEventMutation = (
  options: UseMutationWithSideEffectsOptions<
    ConcreteProviderEventRead,
    unknown,
    UpdateProviderEventMutationArgs
  > = {}
) => {
  return useMutationWithSideEffects(
    ({ eventIdOrVirtualId, update }: UpdateProviderEventMutationArgs) =>
      ProviderEventApi.updateEvent(eventIdOrVirtualId, update),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ConcreteProviderEventRead,
        unknown,
        UpdateProviderEventMutationArgs
      >()
        .addErrorLogging(() => 'There was a problem updating the event')
        .merge(options.sideEffects),
    }
  );
};

export interface UpdateRecurringInstanceMutationArgs {
  virtualId: string;
  update: ProviderEventUpdate;
}
export const useUpdateRecurringInstanceAndAllFollowingMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderEventRecurrenceUpdateResponse,
    unknown,
    UpdateRecurringInstanceMutationArgs
  > = {}
) => {
  return useMutationWithSideEffects(
    ({ virtualId, update }: UpdateRecurringInstanceMutationArgs) =>
      ProviderEventApi.updateRecurringInstanceAndAllFollowing(
        virtualId,
        update
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderEventRecurrenceUpdateResponse,
        unknown,
        UpdateRecurringInstanceMutationArgs
      >()
        .addErrorLogging(() => 'There was a problem updating the event')
        .merge(options.sideEffects),
    }
  );
};

export const useUpdateRecurringInstanceAndEndRecurrenceMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderEventRecurrenceUpdateResponse,
    unknown,
    UpdateRecurringInstanceMutationArgs
  > = {}
) => {
  return useMutationWithSideEffects(
    ({ virtualId, update }: UpdateRecurringInstanceMutationArgs) =>
      ProviderEventApi.updateRecurringInstanceAndEndRecurrence(
        virtualId,
        update
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderEventRecurrenceUpdateResponse,
        unknown,
        UpdateRecurringInstanceMutationArgs
      >()
        .addErrorLogging(() => 'There was a problem updating the event')
        .merge(options.sideEffects),
    }
  );
};

export const useDeleteRecurringInstanceAndAllFollowingMutation = (
  options: UseMutationWithSideEffectsOptions<
    ConcreteProviderEventRead,
    unknown,
    string
  > = {}
) => {
  return useMutationWithSideEffects(
    (virtualId: string) =>
      ProviderEventApi.deleteRecurringInstanceAndAllFollowing(virtualId),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ConcreteProviderEventRead,
        unknown,
        string
      >()
        .addErrorLogging(() => 'There was a problem updating the event')
        .merge(options.sideEffects),
    }
  );
};
