import { Skeleton } from '@mui/material';
import React from 'react';

export const LoadingWrapper = ({
  loading,
  children,
}: {
  loading: boolean;
  children: any;
}) => {
  return <>{loading ? <Skeleton>{children}</Skeleton> : <>{children}</>}</>;
};
