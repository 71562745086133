import { FormLabel, ModalProps } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';

import { Button } from '@headway/helix/Button';
import {
  RecurringUpdateApplyTo,
  RecurringUpdateType,
} from '@headway/shared/events/constants';
import { Modal, Radio } from '@headway/ui';
import { FieldControl, FieldRadioGroup } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { theme } from '@headway/ui/theme';

type RecurringEventConfirmationFormProps = {
  recurringUpdateApplyTo: RecurringUpdateApplyTo;
};

type RecurringEventConfirmationModalProps = Omit<
  ModalProps,
  'onSubmit' | 'children'
> & {
  open: boolean;
  confirmationType: RecurringUpdateType | undefined;
  eventTypeLabel: { single: string; plural: string };
  onSubmit: ({
    recurringUpdateApplyTo,
  }: RecurringEventConfirmationFormProps) => Promise<void>;
  onClose: () => void;
};

export const RecurringEventConfirmationModal: React.FC<
  React.PropsWithChildren<RecurringEventConfirmationModalProps>
> = ({
  open,
  confirmationType,
  eventTypeLabel,
  onSubmit,
  onClose,
  ...rest
}) => {
  const { single: eventTypeLabelSingular, plural: eventTypeLabelPlural } =
    eventTypeLabel;
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`${
        confirmationType === RecurringUpdateType.DELETE ? 'Remove' : 'Edit'
      } recurring ${eventTypeLabelSingular}`}
      {...rest}
    >
      <Formik
        initialValues={
          {
            recurringUpdateApplyTo: RecurringUpdateApplyTo.THIS_EVENT,
          } as RecurringEventConfirmationFormProps
        }
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting }) => {
          return (
            <SafeFormikForm>
              <FieldControl name="recurringUpdateApplyTo" fullWidth={true}>
                <FormLabel>{`Which ${eventTypeLabelPlural} should this ${
                  confirmationType === RecurringUpdateType.DELETE
                    ? 'removal'
                    : 'edit'
                } apply to?`}</FormLabel>
                <FieldRadioGroup>
                  <Radio
                    value={RecurringUpdateApplyTo.THIS_EVENT}
                    label={`Only this ${eventTypeLabelSingular}`}
                  />
                  <Radio
                    value={RecurringUpdateApplyTo.FOLLOWING_EVENTS}
                    label={`This and all following ${eventTypeLabelPlural}`}
                  />
                </FieldRadioGroup>
              </FieldControl>
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: theme.space.xl2,
                }}
              >
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {confirmationType === RecurringUpdateType.DELETE
                    ? 'Remove'
                    : 'Save'}
                </Button>
              </div>
            </SafeFormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
};
