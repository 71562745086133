import { ReactNode } from 'react';

import { theme } from '@headway/helix/theme';

export interface PageProps {
  buttons: ReactNode[];
  children: ReactNode;
  hint?: string;
}

export const Page = ({ buttons, hint, children }: PageProps) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '800px',
        padding: theme.spacing.x6,
      }}
    >
      <div
        css={{
          marginBottom: theme.spacing.x10,
          ' p': {
            margin: 0,
            ...theme.typography.body.regular,
          },
          ...theme.typography.body.regular,
        }}
      >
        {children}
      </div>
      <div css={{ paddingBottom: theme.spacing.x10 }}>
        <div
          css={{
            display: 'flex',
            width: '100%',
            '& Button': {
              marginRight: theme.spacing.x1,
            },
          }}
        >
          {buttons}
        </div>
        {hint && (
          <div css={{ marginTop: theme.spacing.x4 }}>
            <span
              css={{
                ...theme.typography.body.regular,
                color: theme.color.system.gray,
              }}
            >
              {hint}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
