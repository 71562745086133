import { FormLabel } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { FeedbackSystemQuestionnaireRead } from '@headway/api/models/FeedbackSystemQuestionnaireRead';
import { FeedbackSystemQuestionType } from '@headway/api/models/FeedbackSystemQuestionType';
import { FeedbackSystemApi } from '@headway/api/resources/FeedbackSystemApi';
import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';
import { getErrorMessage } from '@headway/shared/utils/error';
import { logException } from '@headway/shared/utils/sentry';
import { Button, Modal, Radio } from '@headway/ui';
import {
  FieldCheckbox,
  FieldControl,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
  FieldRadioGroup,
  FormRow,
} from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { toaster } from '@headway/ui/ToastManager';
import { notifyError } from '@headway/ui/utils/notify';

type AppointmentConfirmDetailsModalProps = {
  open: boolean;
  selectedAppointmentFeedbackSurvey:
    | FeedbackSystemQuestionnaireRead
    | undefined;
  onClose: () => void;
};

export const AppointmentFeedbackModal: React.FC<
  React.PropsWithChildren<AppointmentConfirmDetailsModalProps>
> = ({ selectedAppointmentFeedbackSurvey, onClose, open }) => {
  const schema = React.useMemo(() => {
    if (selectedAppointmentFeedbackSurvey) {
      const fields = selectedAppointmentFeedbackSurvey.questions.reduce(
        (all, question) => {
          all[question.id.toString()] = question.isRequired
            ? Yup.mixed().required('Required')
            : Yup.mixed();

          return all;
        },
        {} as Record<string, Yup.Schema<{}>>
      );
      return Yup.object().shape(fields);
    }
  }, [selectedAppointmentFeedbackSurvey]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={selectedAppointmentFeedbackSurvey?.title}
    >
      <Formik
        initialValues={{} as Record<string, string>}
        onSubmit={async (values) => {
          try {
            if (selectedAppointmentFeedbackSurvey?.questionnaireId) {
              await FeedbackSystemApi.submitFeedbackSystemQuestionnaireAnswers(
                selectedAppointmentFeedbackSurvey.questionnaireId,
                {
                  responses: values,
                }
              );
              toaster.success('Thanks for the feedback!');
              onClose();
            }
          } catch (err: unknown) {
            const defaultMessage = 'Unable to save your responses at this time';
            const message = getErrorMessage(err, defaultMessage);
            notifyError(message);
            logException(err);
          }
        }}
        validationSchema={schema}
      >
        {({ isSubmitting }) => {
          return (
            <SafeFormikForm>
              <div css={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
                <p>
                  <BodyText>
                    {selectedAppointmentFeedbackSurvey?.description}
                  </BodyText>
                </p>
                {selectedAppointmentFeedbackSurvey?.questions.map(
                  (question) => {
                    return question.questionType ===
                      FeedbackSystemQuestionType.RADIO ? (
                      <FormRow>
                        <FieldControl
                          name={question.id.toString()}
                          fullWidthb
                          required={question.isRequired}
                        >
                          <FormLabel
                            css={{
                              marginTop: theme.spacing.x1,
                              marginBottom: theme.spacing.x2,
                            }}
                          >
                            <BodyText>
                              <strong>{question.questionText}</strong>
                            </BodyText>
                          </FormLabel>
                          <FieldRadioGroup>
                            {question.allowedAnswers.map((answer) => {
                              return <Radio value={answer} label={answer} />;
                            })}
                          </FieldRadioGroup>
                          <FieldErrorText />
                        </FieldControl>
                      </FormRow>
                    ) : question.questionType ===
                      FeedbackSystemQuestionType.CHECKBOX ? (
                      <FormRow>
                        <FieldControl
                          name={question.id.toString()}
                          fullWidth
                          required={question.isRequired}
                        >
                          <FormLabel
                            css={{
                              marginTop: theme.spacing.x1,
                              marginBottom: theme.spacing.x2,
                            }}
                          >
                            <BodyText>
                              <strong>{question.questionText}</strong>
                            </BodyText>
                          </FormLabel>
                          {question.allowedAnswers.map((answer) => {
                            return (
                              <FieldCheckbox value={answer} label={answer} />
                            );
                          })}
                          <FieldErrorText />
                        </FieldControl>
                      </FormRow>
                    ) : question.questionType ===
                      FeedbackSystemQuestionType.FREE_TEXT ? (
                      <FieldControl
                        name={question.id.toString()}
                        fullWidth
                        required={question.isRequired}
                      >
                        <FieldInputLabel
                          css={{ marginBottom: theme.spacing.x2 }}
                        >
                          <BodyText>
                            <strong>{question.questionText}</strong>
                          </BodyText>
                        </FieldInputLabel>
                        <FieldInput />
                        <FieldErrorText />
                      </FieldControl>
                    ) : null;
                  }
                )}
              </div>
              <div
                css={{
                  display: 'flex',
                  borderTop: `1px solid ${theme.color.system.borderGray}`,
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: theme.spacing.x2,
                  padding: `${theme.spacing.x4} 0 ${theme.spacing.x4} 0`,
                }}
              >
                <Button variant="outlined" size="large" onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  size="large"
                >
                  Submit
                </Button>
              </div>
            </SafeFormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
};
