import moment from 'moment';
import React from 'react';

import { longTimeZoneAbbrs } from '@headway/shared/events/constants';
import { theme } from '@headway/ui/theme';
import { Tooltip } from '@headway/ui/Tooltip';

interface CalendarTimeGutterHeaderProps {
  timeZone: string;
}

export const CalendarTimeGutterHeader = ({
  timeZone,
}: CalendarTimeGutterHeaderProps) => {
  const timeZoneAbbr = moment.tz(timeZone).zoneAbbr();

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Tooltip
        title={`All calendar events are in ${
          longTimeZoneAbbrs[timeZoneAbbr as keyof typeof longTimeZoneAbbrs] ||
          timeZoneAbbr
        }`}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              fontFamily: theme.fontFamily.postGrotesk,
            }}
          >
            {timeZoneAbbr}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
