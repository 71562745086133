/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ErrorCode } from './ErrorCode';
import { PatientMismatchInputField } from './PatientMismatchInputField';
import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface InsuranceReadinessIssueInsufficientOrIncorrectInfo {
    clearinghouseErrors?: Array<ErrorCode>;

    impact: ReadinessIssueImpact;

    metadata?: object;

    otherErrorDescriptions?: Array<string>;

    patientMismatchInputFields?: Array<PatientMismatchInputField>;

    type: InsuranceReadinessIssueInsufficientOrIncorrectInfoType;

}


    export enum InsuranceReadinessIssueInsufficientOrIncorrectInfoType {
            INSUFFICIENT_OR_INCORRECT_INFORMATION = 'INSUFFICIENT_OR_INCORRECT_INFORMATION'
    }

