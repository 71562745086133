import * as React from 'react';

export const HelpCenter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 17.5v-15h15v15h-15zm7.459-2.584c.25 0 .458-.083.625-.25a.848.848 0 00.25-.625.848.848 0 00-.25-.625.848.848 0 00-.625-.25.848.848 0 00-.625.25.848.848 0 00-.25.625c0 .25.083.459.25.625.166.167.375.25.625.25zm-.709-3.125h1.313c0-.458.062-.815.187-1.072.125-.257.361-.56.709-.906.472-.473.801-.883.989-1.23.187-.347.281-.715.281-1.104 0-.736-.24-1.323-.718-1.76-.48-.438-1.13-.657-1.948-.657-.737 0-1.362.174-1.876.521-.513.348-.881.833-1.103 1.458l1.187.48c.167-.375.403-.67.708-.885.306-.216.66-.324 1.063-.324.416 0 .753.108 1.01.324.257.215.386.51.386.885 0 .264-.077.524-.23.781-.152.257-.41.56-.77.907-.5.458-.823.864-.969 1.218-.146.354-.219.81-.219 1.364z"
      fill="currentColor"
    />
  </svg>
);
