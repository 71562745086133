/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { CreateEmergencyContactRequestBody } from '../models/CreateEmergencyContactRequestBody';
import { EmergencyContactRead } from '../models/EmergencyContactRead';
import { EmergencyContactUpdate } from '../models/EmergencyContactUpdate';
import { HTTPValidationError } from '../models/HTTPValidationError';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class EmergencyContactApiResource {

    /**
     * Create Emergency Contact
     * 
     * @param userId 
     * @param createEmergencyContactRequestBody 
     
     */
    public createEmergencyContact(userId: number, body: CreateEmergencyContactRequestBody, axiosConfig?: AxiosRequestConfig): Promise<EmergencyContactRead> {
        const apiRoute = '/users/{user_id}/emergency-contacts';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Emergency Contact
     * 
     * @param userId 
     * @param emergencyContactId 
     
     */
    public deleteEmergencyContact(userId: any, emergencyContactId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/users/{user_id}/emergency-contacts/{emergency_contact_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId))
                    .replace('{' + 'emergency_contact_id}', String(emergencyContactId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Emergency Contact
     * 
     * @param userId 
     * @param emergencyContactId 
     
     */
    public getEmergencyContact(userId: number, emergencyContactId: number, axiosConfig?: AxiosRequestConfig): Promise<EmergencyContactRead> {
        const apiRoute = '/users/{user_id}/emergency-contacts/{emergency_contact_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId))
                    .replace('{' + 'emergency_contact_id}', String(emergencyContactId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Emergency Contacts By Patient Id
     * 
     * @param userId 
     
     */
    public getEmergencyContactsByPatientId(userId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<EmergencyContactRead>> {
        const apiRoute = '/users/{user_id}/emergency-contacts';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Emergency Contact
     * 
     * @param userId 
     * @param emergencyContactId 
     * @param emergencyContactUpdate 
     
     */
    public updateEmergencyContact(userId: number, emergencyContactId: number, body: EmergencyContactUpdate, axiosConfig?: AxiosRequestConfig): Promise<EmergencyContactRead> {
        const apiRoute = '/users/{user_id}/emergency-contacts/{emergency_contact_id}';
        const reqPath = apiRoute
                    .replace('{' + 'user_id}', String(userId))
                    .replace('{' + 'emergency_contact_id}', String(emergencyContactId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const EmergencyContactApi = new EmergencyContactApiResource();
