import axios from 'axios';

import { getApiErrorMessage } from './api';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

export const getErrorMessage = (
  error: any,
  defaultMessage: string = DEFAULT_ERROR_MESSAGE
): string => {
  if (!error) return defaultMessage;

  if (axios.isAxiosError(error)) {
    return getApiErrorMessage(error);
  } else if (error instanceof Error && error.message) {
    return error.message;
  }

  return defaultMessage;
};
