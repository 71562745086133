import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserPriceEstimateResponse } from '@headway/api/models/UserPriceEstimateResponse';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';

import { PROVIDER_PRICE_CACHE_KEY } from '../utils/cacheKeys';

export interface UseProviderPriceQueryKeyArgs {
  provider: ProviderRead | undefined;
  patient: UserRead | undefined;
}
export const getUseProviderPriceQueryKey = ({
  provider,
  patient,
}: Pick<UseProviderPriceQueryKeyArgs, 'provider' | 'patient'>): [
  string,
  number | undefined,
  number | undefined,
  number | undefined,
] => [
  PROVIDER_PRICE_CACHE_KEY,
  provider?.id,
  patient?.id,
  patient?.activeUserInsurance?.latestEligibilityLookup?.id,
];

export const useProviderPrice = (
  { provider, client }: { provider?: ProviderRead; client?: UserRead },
  options?: UseQueryOptions<
    UserPriceEstimateResponse,
    unknown,
    UserPriceEstimateResponse,
    ReturnType<typeof getUseProviderPriceQueryKey>
  >
) => {
  const queryKey = getUseProviderPriceQueryKey({ provider, patient: client });
  return useQuery(
    queryKey,
    () => ProviderApi.getProviderPrice(provider!.id, client!.id),
    {
      staleTime: Infinity,
      ...options,
      enabled: !!provider?.id && !!client?.id && (options?.enabled ?? true),
    }
  );
};
