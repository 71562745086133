import * as React from 'react';

export const SettingsOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.958 18.334l-.396-2.646a3.974 3.974 0 01-.718-.344 8.832 8.832 0 01-.678-.448L3.708 16l-2.062-3.604 2.229-1.646a1.964 1.964 0 01-.052-.375 7.527 7.527 0 010-.75c.007-.139.024-.264.052-.375L1.646 7.604 3.709 4l2.458 1.104a7.92 7.92 0 01.687-.448c.25-.146.486-.26.708-.343l.396-2.646h4.083l.396 2.646c.237.083.48.194.73.333s.472.291.667.458L16.29 4l2.063 3.604-2.229 1.605c.028.125.045.256.052.395a7.89 7.89 0 010 .782c-.007.132-.024.26-.052.385l2.209 1.625L16.29 16l-2.457-1.104c-.209.153-.435.305-.678.458a2.875 2.875 0 01-.719.334l-.396 2.646H7.958zm2.063-5.563c.764 0 1.416-.27 1.958-.813A2.67 2.67 0 0012.792 10a2.67 2.67 0 00-.813-1.959 2.669 2.669 0 00-1.958-.812c-.778 0-1.438.27-1.98.812A2.67 2.67 0 007.23 10c0 .764.27 1.417.812 1.959a2.694 2.694 0 001.98.812zm0-1.375c-.389 0-.719-.136-.99-.407-.27-.27-.406-.6-.406-.989s.135-.719.406-.99c.271-.27.601-.406.99-.406.375 0 .698.136.968.407.271.27.407.6.407.989s-.136.719-.407.99c-.27.27-.593.406-.968.406zm-.917 5.541h1.771l.291-2.291a4.815 4.815 0 001.282-.51c.396-.23.753-.504 1.073-.823l2.166.916.813-1.458-1.896-1.417c.056-.222.1-.444.136-.666.034-.223.051-.452.051-.688 0-.236-.013-.465-.041-.688a3.783 3.783 0 00-.146-.666L16.5 7.229l-.813-1.458-2.166.917a4.646 4.646 0 00-1.063-.865 3.831 3.831 0 00-1.292-.469l-.27-2.292H9.125l-.291 2.292c-.459.097-.886.26-1.282.49-.396.229-.753.51-1.073.843l-2.167-.916L3.5 7.229l1.896 1.417c-.056.222-.1.444-.135.666a4.457 4.457 0 00-.053.688c0 .236.018.465.053.688.034.222.08.444.135.666L3.5 12.771l.813 1.458 2.166-.916c.32.319.677.593 1.073.822.396.23.823.4 1.282.511l.27 2.291z"
      fill="currentColor"
    />
  </svg>
);
