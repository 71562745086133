import { useProvider } from 'hooks';
import { isEqual } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';

import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { logException } from '@headway/shared/utils/sentry';

import { useProviderCredentials } from 'hooks/useProviderCredentials';

import { AppointmentContext } from './stores/AppointmentContext';
import {
  ProgressNoteContext,
  ProgressNoteState,
} from './stores/ProgressNotesContext';

export const createAddendumSignedText = (
  addendum: ProviderAppointmentAddendumRead,
  provider: ProviderRead,
  addendumNumber: number,
  licenseType: string | undefined
) => {
  const addendumDate = moment(addendum.attestedOn).local().format('MM/DD/YY');
  const addendumTime = moment(addendum.attestedOn).local().format('hh:mm a');

  return `
    Addendum ${addendumNumber.toString()} signed by ${
      provider.name
    }, ${licenseType} on ${addendumDate} at ${addendumTime}
  `;
};

export const ProgressNoteSignedText = () => {
  const { progressNoteState, progressNote, addendums } =
    useContext(ProgressNoteContext);
  const isAddendumsEnabled = useFlag('addendums', false);
  const provider = useProvider();
  const { appointmentAddressState } = useContext(AppointmentContext);

  const { data: providerCredentials, isLoading: isProviderCredentialsLoading } =
    useProviderCredentials({
      providerId: provider.id,
    });

  // If still loading data, return null
  if (isProviderCredentialsLoading || !appointmentAddressState) {
    return null;
  }

  // Find the provider's credential for the appointment's state
  const providerCredential = providerCredentials
    ? providerCredentials.find(
        (license) =>
          license.license.state === appointmentAddressState &&
          license.license.isUsedForCredentialing
      )?.license
    : undefined;

  // If the progress note is not signed, or the note is empty, return null
  if (
    progressNoteState !== ProgressNoteState.SIGNED ||
    !progressNote ||
    isEqual(progressNote.noteJson, {})
  ) {
    return null;
  }

  // If we can't find a matching provider credential, log an error
  if (!providerCredential) {
    logException(
      new Error(
        `No matching provider credential found for ${appointmentAddressState} and provider ${provider.id} `
      )
    );
  }

  const licenseType = providerCredential
    ? providerCredential.licenseType
    : provider.licenseType;

  if (isAddendumsEnabled) {
    // The attestedOn column on the provider_progress_notes modal doesn't have timezone=True set,
    // so we use the updateOn column instead since the records last possible update is it being attested
    const progressNoteAttestedOn = progressNote.updatedOn;
    const signedDate = moment(progressNoteAttestedOn)
      .local()
      .format('MM/DD/YY');
    const signedTime = moment(progressNoteAttestedOn).local().format('hh:mm a');

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing.x4,
          marginTop: theme.spacing.x6,
          marginBottom: theme.spacing.x6,
        }}
      >
        <BodyText>
          <strong>
            Original note signed by {provider.name}, {licenseType} on{' '}
            {signedDate} at {signedTime}
          </strong>
        </BodyText>
        {addendums.map((addendum, idx) => (
          <BodyText key={`addendum-${addendum.id}`}>
            <strong>
              {createAddendumSignedText(
                addendum,
                provider,
                idx + 1,
                licenseType
              )}
            </strong>
          </BodyText>
        ))}
      </div>
    );
  } else {
    const signedDate = moment(progressNote.attestedOn).format('MM/DD/YY');
    const signedTime = moment(progressNote.attestedOn).format('hh:mm a');
    return (
      <div
        css={{ marginTop: theme.spacing.x6, marginBottom: theme.spacing.x6 }}
      >
        {progressNote.noteVersion === 1 ? (
          <BodyText>
            Signed on {signedDate} at {signedTime}
          </BodyText>
        ) : (
          <BodyText>
            Addendum signed on {signedDate} at {signedTime}
          </BodyText>
        )}
      </div>
    );
  }
};
