import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ProviderFunction } from '@headway/api/models/ProviderFunction';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { NpiApi } from '@headway/api/resources/NpiApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NPI_ERROR_MESSAGE,
  YUP_NPI_MATCH,
} from '@headway/shared/constants/format';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { LogoLoader, LogoLoaderWithText, Modal } from '@headway/ui';
import {
  FieldControl,
  FieldDatePicker,
  FieldErrorText,
} from '@headway/ui/form';

import { useAuthStore } from 'stores/AuthStore';

import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const statesThatRequireNpiEffectiveDate = [UnitedStates.ARIZONA];

const NPI_TYPE_1 = 'NPI-1';

enum NpiError {
  NpiLookUp,
  NpiAttestation,
  CaqhNotFound,
  CaqhServerError,
  CaqhDuplicate,
}

enum ConfirmationModalStep {
  NpiConfirmation,
  NpiVerification,
}

const shouldAskPrescribesControlledSubstances = [
  ProviderFunction.MEDICATION_MANAGEMENT,
  ProviderFunction.TALK_THERAPY_AND_MEDICATION_MANAGEMENT,
];

const doesProviderManageMedication = (prescriberOfferings?: ProviderFunction) =>
  prescriberOfferings !== undefined &&
  shouldAskPrescribesControlledSubstances.includes(prescriberOfferings);

export const NpiStep = ({
  initialValues,
  formikHelpers,
  showConfirmationModal,
  setShowConfirmationModal,
  onStepComplete,
}: CustomComponentProps) => {
  const authStore = useAuthStore();
  const { provider, providerQuestionnaire } = useQuestionnaireContext();
  const [confirmationModalStep, setConfirmationModalStep] =
    useState<ConfirmationModalStep | null>(
      ConfirmationModalStep.NpiConfirmation
    );
  const [npiResult, setNpiResult] = useState<any | null>(null);
  const [caqhNumbersfromNpi, setCaqhNumbersfromNpi] = useState<string[]>([]);
  const [npiError, setNpiError] = useState<NpiError | null>(null);

  const verifyNpi = async (npi: string) => {
    setNpiResult(null);
    let npiRes;
    try {
      npiRes = await NpiApi.getNpiLookup({
        npi,
      });
    } catch (e) {
      setNpiError(NpiError.NpiLookUp);
    }
    // Make sure it's NPI Type 1
    if (npiRes?.results[0]?.enumerationType !== NPI_TYPE_1) {
      setNpiError(NpiError.NpiLookUp);
      return;
    }

    setNpiResult({
      firstName: npiRes?.results[0].basic.first_name,
      lastName: npiRes?.results[0].basic.last_name,
      credential: npiRes?.results[0].basic.credential,
    });
  };

  const closeModal = () => {
    setShowConfirmationModal(false);
    setConfirmationModalStep(ConfirmationModalStep.NpiConfirmation);
    setNpiError(null);
  };

  const getCaqhNumberFromNpi = async () => {
    const maxRetryAttempts = 5;
    for (let i = 0; i < maxRetryAttempts; i++) {
      try {
        const { caqhNumbers } = await ProviderQuestionnaireApi.getCaqhIdByNpi(
          providerQuestionnaire.id,
          {
            npi: formikHelpers.values.npi || '',
          }
        );
        return caqhNumbers;
      } catch (e) {
        if (i === maxRetryAttempts - 1) {
          throw e;
        }
      }
    }
  };
  const confirmNpiIsCorrectAndGetCaqhNumber = async () => {
    // Provider already exists. We can skip the NPI verification.
    if (
      formikHelpers.values.npi === initialValues.npi &&
      formikHelpers.values.caqhNumberFromNpi
    ) {
      await ProviderApi.updateProvider(provider.id, {
        providerFunction: formikHelpers.values.prescriberOfferings,
      });
      onStepComplete(formikHelpers.values);
      await authStore.fetchMe();
      return;
    }

    setConfirmationModalStep(ConfirmationModalStep.NpiVerification);
    let caqhNumbers;
    try {
      caqhNumbers = await getCaqhNumberFromNpi();
    } catch (e) {
      setNpiError(NpiError.CaqhServerError);
      setConfirmationModalStep(ConfirmationModalStep.NpiConfirmation);
      return;
    }

    if (!caqhNumbers) {
      setNpiError(NpiError.CaqhNotFound);
      return;
    }

    if (caqhNumbers.length > 1) {
      setNpiError(NpiError.CaqhDuplicate);
      setCaqhNumbersfromNpi(caqhNumbers);
      return;
    }

    const newValues = {
      ...formikHelpers.values,
      caqhNumberFromNpi: caqhNumbers[0],
      npiFirstName: npiResult.firstName,
      npiLastName: npiResult.lastName,
    };

    await ProviderApi.updateProvider(provider.id, {
      npi: formikHelpers.values.npi,
      providerFunction: formikHelpers.values.prescriberOfferings,
    });
    onStepComplete(newValues);
    await authStore.fetchMe();
  };

  const memoizedVerifyNpi = useCallback(verifyNpi, []);
  const debounceVerifyNpi = debounce(async (npi) => await verifyNpi(npi), 1000);

  useEffect(() => {
    if (initialValues.npi) {
      memoizedVerifyNpi(initialValues.npi);
    }
  }, [memoizedVerifyNpi, initialValues.npi]);

  const foundValidNpi = npiResult;
  const NpiConfirmationContent = () => (
    <div>
      {npiError === NpiError.CaqhServerError ? (
        <div
          css={{
            marginTop: theme.spacing.x3,
            marginBottom: theme.spacing.x3,
          }}
        >
          <GuidanceCard variant="error">
            <SubBodyText>
              We're experiencing an error due to high volume. You may need to
              retry 1-2 times to submit.
            </SubBodyText>
          </GuidanceCard>
        </div>
      ) : npiError === NpiError.NpiLookUp ? (
        <GuidanceCard variant="error">
          <SubBodyText>
            We were unable to verify this NPI. Please try again.
          </SubBodyText>
        </GuidanceCard>
      ) : foundValidNpi ? (
        <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
          <SubBodyText>
            <b>Found: </b>
            {npiResult.firstName} {npiResult.lastName} {npiResult.credential}
          </SubBodyText>
        </div>
      ) : (
        <div
          css={{
            padding: theme.spacing.x6,
            ...theme.stack.horizontal,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LogoLoader />
        </div>
      )}
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.spacing.x4,
          gap: theme.spacing.x2,
        }}
      >
        <Button variant="secondary" type="button" onPress={() => closeModal()}>
          {npiError || !foundValidNpi ? 'Go Back' : 'No, go back'}
        </Button>
        {!npiError && foundValidNpi && (
          <Button onPress={async () => confirmNpiIsCorrectAndGetCaqhNumber()}>
            Yes, this is me
          </Button>
        )}
      </div>
    </div>
  );

  const NpiVerificationContent = () => (
    <div>
      {npiError === NpiError.CaqhNotFound ? (
        <div
          css={{
            marginTop: theme.spacing.x3,
            marginBottom: theme.spacing.x3,
          }}
        >
          <GuidanceCard variant="error">
            <SubBodyText>
              We were unable to find a CAQH account linked to the NPI you
              provided. Please try again.
            </SubBodyText>
          </GuidanceCard>
        </div>
      ) : npiError === NpiError.CaqhDuplicate ? (
        <div
          css={{
            marginTop: theme.spacing.x3,
            marginBottom: theme.spacing.x3,
          }}
        >
          <GuidanceCard variant="error">
            <SubBodyText>
              You have multiple CAQH accounts with the following IDs:
              <ul>
                {caqhNumbersfromNpi.length > 1 &&
                  caqhNumbersfromNpi.map((caqhNumber: string) => {
                    return <li>{caqhNumber}</li>;
                  })}
              </ul>
              You will need to merge the accounts in order to move forward with
              credentialing. You can do this by calling CAQH at 888-599-1771.
              This can take up to a few days.
            </SubBodyText>
          </GuidanceCard>
        </div>
      ) : (
        <div css={{ padding: theme.spacing.x6 }}>
          <LogoLoaderWithText
            loaderFont={theme.typography.body.regular}
            loadingTexts={[
              `Running additional verifications on your NPI.`,
              'Still working!',
              'Almost there!',
            ]}
          />
        </div>
      )}
      {npiError === NpiError.CaqhNotFound && (
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing.x4,
          }}
        >
          <Button
            variant="secondary"
            type="button"
            onPress={() => closeModal()}
          >
            Close
          </Button>
        </div>
      )}
    </div>
  );
  return (
    <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
      <Modal
        title={
          confirmationModalStep === ConfirmationModalStep.NpiVerification
            ? `Verifying NPI`
            : `Is This You?`
        }
        open={showConfirmationModal}
        onClose={() => closeModal()}
      >
        {confirmationModalStep === ConfirmationModalStep.NpiVerification && (
          <NpiVerificationContent />
        )}
        {confirmationModalStep === ConfirmationModalStep.NpiConfirmation && (
          <NpiConfirmationContent />
        )}
      </Modal>
      <FormControl
        component={TextField}
        name={'npi'}
        label="What is your Type 1 NPI?"
        type="number"
        onChange={(e: any) => {
          debounceVerifyNpi(e);
        }}
      />
      {isProviderInAnyStates(provider, statesThatRequireNpiEffectiveDate) && (
        <FieldControl name="npiEffectiveDate" fullWidth={true}>
          <FieldDatePicker
            label="NPI effective date"
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
          />
          <FieldErrorText />
        </FieldControl>
      )}
      <FormControl
        name="prescriberOfferings"
        component={RadioGroup}
        label="Are you currently accepting clients for talk therapy, medication
            management, or both?"
        helpText={
          <SubBodyText>
            If you don’t have an NPI,{' '}
            <Link
              href="https://nppes.cms.hhs.gov/#/"
              rel="noopener noreferrer"
              target="_blank"
            >
              you can register for an NPI here
            </Link>
            .
          </SubBodyText>
        }
      >
        <Radio value={ProviderFunction.TALK_THERAPY}>
          {ProviderFunction.TALK_THERAPY}
        </Radio>
        <Radio value={ProviderFunction.MEDICATION_MANAGEMENT}>
          {ProviderFunction.MEDICATION_MANAGEMENT}
        </Radio>
        <Radio value={ProviderFunction.TALK_THERAPY_AND_MEDICATION_MANAGEMENT}>
          {ProviderFunction.TALK_THERAPY_AND_MEDICATION_MANAGEMENT}
        </Radio>
      </FormControl>
      {doesProviderManageMedication(
        formikHelpers.values.prescriberOfferings
      ) && (
        <FormControl
          name="prescribesControlledSubstances"
          component={RadioGroup}
          label="Do you prescribe controlled substances? Please keep in mind that you will need to supply valid controlled substance licensure (DEA and CDS as applicable) in your practice state in order to enroll with Headway. If you do not prescribe controlled substances, you will need to provide a letter indicating as such."
        >
          <Radio value={'YES'}>{'Yes'}</Radio>
          <Radio value={'NO'}>{'No'}</Radio>
        </FormControl>
      )}
    </div>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'National Provider Identifier (NPI)',
  description: 'Please share your NPI details below.',
  Component: NpiStep,
  onBeforeSubmit: async (
    values,
    setBeforeSubmitError,
    showConfirmationModal
  ) => {
    showConfirmationModal(true);
    return false;
  },
  getFormMeta: ({ provider, providerQuestionnaire }) => {
    const validationSchema = Yup.object().shape({
      npi: Yup.string()
        .matches(YUP_NPI_MATCH, YUP_NPI_ERROR_MESSAGE)
        .required('National Provider Identifier is required.'),
      npiEffectiveDate: isProviderInAnyStates(
        provider,
        statesThatRequireNpiEffectiveDate
      )
        ? Yup.string().required(
            'National Provider Identifier effective date is required.'
          )
        : Yup.string(),
      prescriberOfferings: Yup.string().required('This question is required.'),
      prescribesControlledSubstances: doesProviderManageMedication(
        providerQuestionnaire.rawData?.prescriberOfferings
      )
        ? Yup.string().required('This question is required.')
        : Yup.string(),
    });
    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    };
  },
};

export default stepConfig;
