import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { theme } from '@headway/ui/theme';

// Session rate for self pay can be zero (to support pro bono work and
// appointments where payment is handled outside of Headway), but otherwise
// we want to enforce a minumum of $5 to avoid losing money on fees.
export const MIN_NONZERO_SELF_PAY_RATE = 5;
export const MIN_NONZERO_SELF_PAY_RATE_MESSAGE = `Session rate may be $0, but otherwise must be at least $${MIN_NONZERO_SELF_PAY_RATE}.`;
export const MAX_SELF_PAY_RATE = 500;
export const MAX_SELF_PAY_RATE_MESSAGE = `Session rate cannot be greater than $${MAX_SELF_PAY_RATE}.`;

export const mapBillingTypeToDisplayNameDescriptive = (type: BillingType) => {
  switch (type) {
    case BillingType.INSURANCE:
      return (
        <span>
          <div
            css={{
              fontWeight: theme.fontWeight.bold,
              fontSize: theme.fontSize.base,
            }}
          >
            Insurance
          </div>
          <div>Bill the client's insurance plan</div>
        </span>
      );
    case BillingType.SELF_PAY:
      return (
        <span>
          <div
            css={{
              fontWeight: theme.fontWeight.bold,
              fontSize: theme.fontSize.base,
            }}
          >
            Private Pay
          </div>
          <div>Only bill the client's payment method</div>
        </span>
      );
    default:
      return '';
  }
};
