import React from 'react';

import { theme } from '@headway/helix/theme';

type Section = {
  header: React.ReactNode;
  content: React.ReactNode;
  topOffset: number;
};

interface StackingHeaderProps {
  sections: Section[];
}

export const StackingHeader = ({ sections }: StackingHeaderProps) => {
  return (
    <div>
      {sections.map(({ header, content, topOffset }, key) => (
        <React.Fragment key={key}>
          <div
            css={{
              position: 'sticky',
              top: topOffset ? `${topOffset}px` : '0px',
              zIndex: theme.layers.modal + 1,
              backgroundColor: 'white',
            }}
          >
            {header}
          </div>
          <div>{content}</div>
        </React.Fragment>
      ))}
    </div>
  );
};
