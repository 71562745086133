import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { LegalSection } from '../LegalSection';
import { PrivatePayProviderAddendum } from '../PrivatePayProviderAddendum';
import { SummarySection } from '../SummarySection';
import { StateAgreementProps } from './StateAgreementProps';

export const FloridaAgreement: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<StateAgreementProps>>
> = ({
  isGroupPractice,
  isNursePractitioner,
  isPhysician,
  licenseType,
  setSelectedPart,
}) => (
  <div>
    <LegalSection title="Recitals">
      <p>A. Practice is a Florida behavioral health practice.</p>
      {isGroupPractice ? (
        <p>
          B. PROVIDER is a Florida behavioral health practice and desires to
          practice as an independent contractor of the Practice at PROVIDER's
          practice location (the "Facility/ies").
        </p>
      ) : (
        <p>
          B. PROVIDER is licensed by the State of Florida to practice as a{' '}
          {licenseType} and desires to practice as an independent contractor of
          the Practice at PROVIDER's practice location (the "Facility/ies").
        </p>
      )}

      <p>
        C. Practice wishes to furnish PROVIDER with the ability to see patients
        in-network as a provider of the Practice.
      </p>
      <p>
        D. PROVIDER wishes to make its services available to Practice, in order
        to expand his or her private practice by seeing patients in-network as
        part of the Practice.
      </p>
      <p>
        In consideration of these premises and the respective promises,
        undertakings and covenants of the parties set forth and exchanged
        herein, Practice and PROVIDER agree as follows:
      </p>
    </LegalSection>

    <LegalSection title="Independent Contractor">
      <p>
        A. Independent Contractor Status. Upon the date on which this Agreement
        is fully executed (the "Effective Date"), PROVIDER agrees to furnish
        behavioral health services as an independent contractor of Practice,
        subject to the terms and conditions set forth herein. PROVIDER expressly
        acknowledges and agrees that the PROVIDER is engaged in a business of
        the PROVIDER’s own and that the PROVIDER is not subject to the
        Practice’s control or direction with respect to the manner in which the
        details of the services are to be performed or what the PROVIDER must do
        as the work progresses. PROVIDER agrees to furnish, at the PROVIDER’s
        own expense, the materials, equipment, and other resources necessary to
        perform the services. PROVIDER shall have no minimum hours requirement
        and is free to accept or refuse patients, and to determine the times at
        which PROVIDER sees patients, consistent with applicable law.
      </p>
      <p>
        B. PROVIDER and Practice expressly acknowledge and agree that this
        Agreement is not intended, nor shall be construed, to create an
        employer/employee relationship, a joint venture, a partnership or a
        landlord/tenant relationship between PROVIDER and Practice. Although
        Practice shall not have any right to exercise any control or direction
        over the manners, methods, details, procedures or decisions relating to
        performance of services which are the subject matter of this Agreement,
        the services to be provided by PROVIDER hereunder shall be provided in a
        manner consistent with the provisions of this Agreement, as well as all
        applicable laws, rules, regulations, and medical and ethical standards.
        PROVIDER understands and agrees that: (i) Practice will not withhold on
        behalf of PROVIDER or PROVIDER’s employees any sums for income tax,
        unemployment insurance, social security or any other withholding
        pursuant to any law or requirement of any governmental body relating to
        Practice, PROVIDER and PROVIDER’s employees; and (ii) all of such
        payments, withholding and benefits, if any, are the sole responsibility
        of PROVIDER. In the event that the Internal Revenue Service or any other
        governmental agency questions or challenges the independent contractor
        status of PROVIDER, the parties hereto mutually agree that both Practice
        and PROVIDER shall have the right to participate in any discussion or
        negotiation occurring with such governmental agency irrespective of by
        whom such discussions or negotiations are initiated.
      </p>
      <p>
        C. PROVIDER and Practice expressly acknowledge and agree that the
        PROVIDER is engaged in an occupation that requires the PROVIDER’s use of
        the PROVIDER’s special skill, knowledge, and training. PROVIDER is
        performing specific services for a fixed amount of compensation as set
        forth on Attachment A. PROVIDER and Practice expressly acknowledge and
        agree that the PROVIDER is not in the regular employ of the Practice and
        is free to hire and control the PROVIDER’s own assistants.
      </p>
      <SummarySection>
        You are truly independent. You choose which patients you see, and when.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Responsibilities of Practice">
      <p>
        A. Practice will provide PROVIDER with its good faith efforts to get
        PROVIDER on managed care panels of PROVIDER's choice, provide reasonable
        assistance in PROVIDER credentialing and recredentialing with
        third-party payers, negotiate for fee schedules that are favorable to
        Practice and PROVIDER, provide reasonable assistance in administrative
        work that comes with complying with third-party payer rules, market
        PROVIDER's services for the Practice, and assist with placing patients
        with other providers (like prescribers who are also independent
        contractors of the Practice) if need be.
      </p>
      <SummarySection>
        When you work with Headway, we handle the administrative details.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Provider Representations and Warranties">
      <p>
        A. PROVIDER represents, warrants and covenants as follows, and PROVIDER
        agrees to immediately notify Practice if any of the following
        representations or warranties become inaccurate or false at any time
        during the Term of this Agreement:
      </p>
      <ol>
        {isGroupPractice ? (
          <React.Fragment>
            <li>
              PROVIDER is a professional corporation duly organized under the
              laws of the state of Florida and registered with the Florida
              Medical Board.
            </li>
            <li>
              All individual health care providers who provide services on
              behalf of PROVIDER and are interested in joining Practice
              ("PROVIDER Personnel") are duly licensed under the laws of the
              State of Florida to provide the health care services they provide,
              no PROVIDER Personnel have their license suspended, revoked or
              restricted in any manner, and PROVIDER and all PROVIDER Personnel
              have not been excluded, suspended or debarred from any state or
              federal health care payment program, including the Medicare or
              Medicaid program.
            </li>
            <li>All PROVIDER Personnel have individual NPIs.</li>
            <li>
              PROVIDER Personnel who are nurse practitioners have an ANCC
              certificate in the behavioral health field.
            </li>
            <li>
              All PROVIDER Personnel with prescriptive authority have a current
              controlled substances registration issued by the United States
              Drug Enforcement Administration, which registration has not been
              surrendered, suspended, revoked or restricted in any manner.
            </li>
            <li>
              PROVIDER Personnel, if seeing patients of the Practice virtually
              through “tele-therapy” or “tele-psychiatry”, will render services
              through secure video equipment of Provider’s choice that is
              compliant with all applicable laws. Any costs associated with
              using said video equipment are the Provider’s sole responsibility.
            </li>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <li>
              PROVIDER is duly licensed under the laws of the State of Florida,
              as a {licenseType}, PROVIDER's license has not been suspended,
              revoked or restricted in any manner, and PROVIDER has not been
              excluded, suspended or debarred from any state or federal health
              care payment program, including the Medicare or Medicaid program.
            </li>
            <li>
              PROVIDER has an individual National Provider Identifier ("NPI").
            </li>
            {isNursePractitioner ? (
              <React.Fragment>
                <li>
                  PROVIDER is and shall remain throughout the term of this
                  Agreement in compliance with all collaborative practice
                  agreement and physician supervision requirements. PROVIDER
                  acknowledges and agrees that it is PROVIDER's sole
                  responsibility to assure that appropriate physician
                  supervision is obtained and that collaborative practice
                  agreement requirements are observed.
                </li>
                <li>
                  PROVIDER has an ANCC certificate in the behavioral health
                  field.
                </li>
                <li>
                  PROVIDER has a current controlled substances registration
                  issued by the United States Drug Enforcement Administration
                  for the state(s) in which he or she practices, which
                  registration has not been surrendered, suspended, revoked or
                  restricted in any manner.
                </li>
              </React.Fragment>
            ) : isPhysician ? (
              <React.Fragment>
                <li>PROVIDER is Board Certified in Psychiatry</li>
                <li>
                  PROVIDER has a current controlled substances registration
                  issued by the United States Drug Enforcement Administration
                  for the state(s) in which he or she practices, which
                  registration has not been surrendered, suspended, revoked or
                  restricted in any manner.
                </li>
              </React.Fragment>
            ) : null}
            <li>
              PROVIDER, if seeing patients of the Practice virtually through
              “tele-therapy” or “tele-psychiatry”, will render services through
              secure video equipment of Provider’s choice that is compliant with
              all applicable laws. Any costs associated with using said video
              equipment are the Provider’s sole responsibility.
            </li>
          </React.Fragment>
        )}

        <li>
          PROVIDER has disclosed and will disclose to the Practice the following
          matters, whether occurring at any time prior to or during the Term of
          this Agreement:
          <ol type="a">
            <li>
              any malpractice suit, claim (whether or not filed in court),
              settlement, settlement allocation, judgment, verdict or decree
              against PROVIDER;
            </li>
            <li>
              any disciplinary, peer review or professional review
              investigation, proceeding or action instituted against PROVIDER by
              any licensure board, hospital, medical school, health care
              facility or entity, professional society or association,
              third-party payer, peer review or professional review committee or
              body, or governmental agency;
            </li>
            <li>
              any criminal complaint, indictment or criminal proceeding in which
              PROVIDER is named as a defendant;
            </li>
            <li>
              any investigation or proceeding, whether administrative, civil or
              criminal, relating to an allegation against PROVIDER of filing
              false health care claims, violating anti-kickback laws, or
              engaging in other billing improprieties;
            </li>
            <li>
              any health condition that impairs or may impair PROVIDER's ability
              to provide medical services;
            </li>
            <li>
              any allegation, or any investigation or proceeding based on any
              allegation, against PROVIDER, of violating professional ethics or
              standards, or engaging in illegal, immoral or other misconduct (of
              any nature or degree), relating to the practice of any licensed
              profession; and
            </li>
            <li>
              any denial or withdrawal of an application in any state for
              licensure, for medical staff privileges at any hospital or other
              health care entity, for board certification or recertification,
              for participation in any third party payment program, for state or
              federal controlled substances registration, or for malpractice
              insurance.
            </li>
          </ol>
        </li>
        <li>
          PROVIDER has the legal authority to enter into this Agreement and to
          fulfill all of its obligations under this Agreement.
        </li>
        <li>
          PROVIDER is authorized under applicable law to work in the United
          States.
        </li>
      </ol>
      <p>
        B. PROVIDER has disclosed to Practice, prior to entering into this
        Agreement, any and all{isGroupPractice ? '' : ' employment agreements,'}{' '}
        non-compete agreements, non-solicitation agreements, confidentiality
        agreements, and any other restrictions that might have limited
        PROVIDER’s ability to provide the behavioral health services hereunder,
        and PROVIDER represents that restrictions, if any, concerning PROVIDER’s
        provision of behavioral health services have been fully and completely
        eliminated.
      </p>
      <SummarySection>
        You meet the legal requirements to provide the services you’re going to
        provide with Headway. You don’t have a non-compete agreement that
        precludes you from working with us. To the extent that you have had
        malpractice allegations or a criminal or disciplinary history, you’ve
        disclosed that with us and answered any questions we had.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Provider's Services and Responsibilities">
      <p>
        A. General. PROVIDER shall provide behavioral health services to
        Practice's patients.
        {isNursePractitioner
          ? ' To the extent clinically appropriate and permitted by applicable collaborative practice laws, rules and agreements, PROVIDER shall use his or her prescriptive authority in the state(s) in which he or she practices. '
          : ' '}
        PROVIDER shall furnish Practice with documentation of PROVIDER's
        credentials upon reasonable request. PROVIDER shall complete and
        maintain, in a timely manner, adequate and legible treatment records
        with respect to all services rendered to patients of the Practice.
      </p>
      <SummarySection>
        As part of providing great care to patients, you’ll keep appropriate
        records.
      </SummarySection>
      <p>
        B. Third-Party Payer Requirements. While Practice will exercise
        commercially reasonable efforts to mitigate PROVIDER's obligations to
        interface with third-party payers, PROVIDER agrees to comply with
        third-party payer requirements (such as documentation, utilization
        review, credentialing and recredentialing) resulting from PROVIDER's
        joining third-party payer’s networks through the Practice.
        {isGroupPractice
          ? ' PROVIDER authorizes Practice to use and disclose PROVIDER Personnel’s personally identifiable information for credentialing and quality improvement purposes. PROVIDER represents and warrants that it has any necessary authorizations to comply with the provisions of this section.'
          : ' PROVIDER authorizes Practice to use and disclose PROVIDER’s personally identifiable information for credentialing and quality improvement purposes.'}
      </p>
      <SummarySection>
        We will make life as simple as possible for you by working with payers,
        but you’ll cooperate with us if we need information from you to meet
        applicable payer requirements.
      </SummarySection>
      <p>
        C. Independent Professional Judgment. This Agreement in no way
        constrains the exercise of PROVIDER's independent professional judgment
        in providing medical care to patients, so long as such judgment is
        consistent with the laws and regulations of the jurisdiction(s) in which
        the care is provided, community standards, sound professional practice,
        and the terms of this Agreement.
      </p>
      <SummarySection>
        Your practice is your practice. We won’t get between you and your
        patient.
      </SummarySection>
      <p>
        D. Care Reviews. PROVIDER agrees to cooperate with care reviews by
        third-party payers and the Practice's medical director, as reasonably
        requested. Practice anticipates that such consultations will not be
        frequently required.
      </p>
      <SummarySection>
        If we need to review records, you’ll let us.
      </SummarySection>
      <p>
        E. Performance Standards. In performing services under this Agreement,
        PROVIDER agrees at all times to (a) use diligent efforts and his or her
        best professional skills and judgment in accordance with the standards
        of PROVIDER's license; (b) perform in accordance with the recognized
        standards of his or her profession; (c) act in accordance with all
        applicable ethical standards; and; (d) comply with all applicable
        federal, state and local laws and regulations. Without limitation of the
        foregoing, PROVIDER shall not make or accept any referrals or generate
        any business in violation of any state or federal fraud and abuse law,
        including, without limitation, the federal Anti- Kickback Statute, 42
        U.S.C. § 1320a-7b, and the federal Stark Law, 42 U.S.C. § 1395nn.
      </p>
      <SummarySection>
        You will meet applicable legal, professional, and ethical standards.
      </SummarySection>
      <p>
        F. Emergency Services. PROVIDER will maintain a confidential voicemail
        telephone number and provide the number to all of PROVIDER's clients
        through Practice. The outgoing voice message of the voicemail box will
        advise the client that PROVIDER does not provide emergency services and
        will inform the client of appropriate crisis resources (e.g., hospital
        emergency departments, the National Suicide Prevention Lifeline).
      </p>
      <p>
        G. Limitation of Authority. Because PROVIDER is an independent
        contractor, PROVIDER is not authorized to enter into any contract or
        agreement or make any such undertakings on behalf of Practice.
      </p>
      <SummarySection>
        Your relationship with us is limited to providing great care. You can’t
        and won’t do anything else for us, like entering into contracts.
      </SummarySection>
      <p>
        H. Facility Access. PROVIDER shall use his or her own space for
        appointments with patients of the Practice, and PROVIDER and Practice
        mutually agree that the fees set forth in Attachment A have been
        calculated to provide PROVIDER with fair market value compensation in
        exchange for a license for the Practice to conduct business in such
        space for the sole purpose of PROVIDER seeing patients of the Practice.
      </p>
      <SummarySection>
        When you’re seeing a Headway patient, your practice location is
        considered a Headway location.
      </SummarySection>
      <p>
        I. Payment Information. PROVIDER agrees to record payment information in
        Practice’s payment processing system and to not otherwise store, record,
        or use such information.
      </p>
      <SummarySection>
        If you collect payment information from patients, you are only permitted
        to enter it into Headway's system.
      </SummarySection>
      <p>
        J. Non-Solicitation. During the term of this Agreement, and for a period
        of twelve (12) months following its termination for any reason, PROVIDER
        shall not contact, solicit, induce or attempt to influence any patient
        of the Practice to terminate his or her relationship with the Practice;
        or solicit, divert, or induce any employee or agent of the Practice to
        terminate his or her employment or service relationship with the
        Practice. The term “patient” means any person who has received services
        at any time within twelve (12) months prior to the effective date of
        this provision and about whom the PROVIDER has personal knowledge gained
        by PROVIDER’s services through this Agreement. The term “employee or
        agent” means any employee or agent of Practice with whom the PROVIDER
        has personal knowledge gained by PROVIDER’s services through this
        Agreement. Practice and PROVIDER agree that a court of competent
        jurisdiction is expressly authorized to blue pencil or modify any
        unenforceable provision of this Section H instead of severing this
        Section H in its entirety, whether by rewriting the offending provision,
        deleting any or all of the offending provision, adding additional
        language to this Agreement, or by making any other modifications it
        deems warranted to carry out the intent and agreement of the Parties as
        embodied in this Agreement to the maximum extent permitted by law.
      </p>
      <SummarySection>
        You agree to see patients that you get through Headway only as a Headway
        provider, and not outside of your work with Headway. If you leave
        Headway, you won't solicit Headway's patients or providers.
      </SummarySection>

      {isGroupPractice ? (
        <React.Fragment>
          <p>
            K. Compliance by Provider Personnel. PROVIDER shall bind all
            PROVIDER Personnel to the terms and conditions of this Agreement.
          </p>
          <SummarySection>
            You have authority to ensure members of your group seeing patients
            through Headway adhere to the terms and conditions laid out here.
          </SummarySection>
        </React.Fragment>
      ) : null}
    </LegalSection>
    <LegalSection title="Medical and Business Records">
      <p>
        A. PROVIDER acknowledges and agrees that PROVIDER will enter, and
        Practice will have access (and a perpetual license to use), clinical
        records of PROVIDER’s treatments of Practice’s patients in Practice's
        software. In addition, Practice may ask for additional information
        regarding PROVIDER’s treatment of Practice’s patients for various
        purposes, such as filing claims with third-party payers, and PROVIDER
        agrees to comply with such requests. Practice will make sure if
        information regarding Practice’s patients needs to be shared with other
        entities, such as third-party payers for care reviews, Practice will do
        so in accordance with applicable law.
      </p>
      <SummarySection>
        Records entered into Headway’s system will stay with Headway if you
        leave the clinic. Of course, you have full access to the records during
        your time with us. If a payer asks for records, and there’s a problem
        with the records for your patients, you’ll work with us to make it
        right.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Charges and Billing">
      <p>
        A. As a service to PROVIDER, for therapy appointments that the PROVIDER
        takes on behalf of the Practice under this Agreement: (a) Practice, and
        not PROVIDER, shall negotiate fee schedules, establish charges for
        professional services, and handle all billing of patients and
        third-party payers; and (b) PROVIDER shall not bill to or collect from
        any patient or third-party payer any amount for services rendered under
        this Agreement; and (c) PROVIDER hereby irrevocably assigns and grants
        to the Practice the right to bill and collect from Practice’s patients
        and/or third-party payers that PROVIDER joins through Practice.
      </p>
      <SummarySection>
        We deal with the insurance companies so you don’t have to, and we
        collect payment from them as well as from patients.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Compensation">
      <p>
        A. In consideration of all services provided by PROVIDER under this
        Agreement, Practice will pay PROVIDER the compensation set forth on
        Attachment A.
      </p>
      <SummarySection>
        We’ll pay you according to the fee schedule we’ve provided to you. This
        fee schedule is confidential.
      </SummarySection>
    </LegalSection>

    <LegalSection title="Insurance">
      {isGroupPractice ? (
        <React.Fragment>
          <p>
            A. PROVIDER has, and agrees to maintain during the Term of this
            Agreement, at its sole cost and expense, the following insurance
            coverages: (a) commercial general liability coverage covering
            PROVIDER (including the acts of all PROVIDER Personnel), with limits
            reasonably acceptable to Practice and (b) workers compensation
            coverage as required by state law. Upon request, PROVIDER agrees to
            (a) name Practice as an additional insured on commercial general
            liability coverage described by this section and (b) furnish
            Practice with a copy of PROVIDER's certificates of insurance
            evidencing any of the coverages set forth above.
          </p>
          <SummarySection>
            You’ll keep liability insurance that meets our requirements. Your
            malpractice policy covers you; our malpractice policy covers us.
          </SummarySection>
          <p>
            B. PROVIDER represents, warrants and covenants that during the Term
            of this Agreement, all PROVIDER Personnel have, and agree to
            maintain, professional insurance covering such PROVIDER Personnel
            with minimum limits of (a) in the case of prescribers, $1,000,000
            per incident and $3,000,000 in the aggregate; and (b) in the case of
            non-prescribers, $1,000,000 per incident and $1,000,000 in the
            aggregate. If any PROVIDER Personnel obtains a claims-made policy to
            fulfill this requirement, then such PROVIDER Personnel will also pay
            the cost of a reporting endorsement for “tail” coverage for the
            duration of the statute of limitations for all services provided on
            behalf of Practice. Upon request, PROVIDER shall require PROVIDER
            Personnel to (a) name Practice as an additional insured on the
            professional malpractice coverage described by this section and (b)
            furnish Practice with a copy of PROVIDER's certificates of insurance
            evidencing the coverages set forth in this section.
          </p>
          <SummarySection>
            Providers in their group must carry their own malpractice policy,
            consistent with the above limits laid out, in order to see patients
            through Headway.
          </SummarySection>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            A. PROVIDER has, and agrees to maintain during the Term of this
            Agreement, at its sole cost and expense, the following insurance
            coverages: (a) professional malpractice insurance covering
            PROVIDER’s acts or omissions when providing services on behalf of
            Practice (including, without limitation, services provided via
            telehealth, teletherapy, or telemedicine, as applicable), with
            minimum limits of $1,000,000 per incident and $1,000,000 in the
            aggregate (if PROVIDER obtains a claims-made policy to fulfill this
            requirement, then PROVIDER will also pay the cost of a reporting
            endorsement for “tail” coverage for the duration of the statute of
            limitations for all services provided on behalf of Practice; (b)
            commercial general liability coverage covering PROVIDER, with limits
            reasonably acceptable to Practice and (c) workers compensation
            coverage as required by state law. Upon request, PROVIDER agrees to
            (a) name Practice as an additional insured on professional
            malpractice coverage and commercial general liability coverage
            described by this section and (b) furnish Practice with a copy of
            PROVIDER's certificates of insurance evidencing any of the coverages
            set forth above.
          </p>
          <SummarySection>
            You’ll keep liability insurance that meets our requirements. Your
            malpractice policy covers you; our malpractice policy covers us.
          </SummarySection>
        </React.Fragment>
      )}
    </LegalSection>
    <LegalSection title="Term and Termination">
      <p>
        A. Term. This Agreement is effective on the Effective Date, has an
        initial term of one (1) year, and shall automatically renew for
        successive one (1) year renewal terms, unless earlier terminated as
        permitted herein (collectively, the "Term").
      </p>
      <SummarySection>
        We hope to work with you for a long time! We are starting our
        relationship with a one-year agreement, but if things go well, the
        agreement will renew every year.
      </SummarySection>
      <p>
        B. Termination Without Cause. Either party may terminate this Agreement
        on thirty (30) days' prior written notice to the other.
      </p>
      <SummarySection>
        We understand that we may go our separate ways at some point. Either one
        of us can choose to end our contractual relationship with the other on
        30 days’ notice.
      </SummarySection>
      <p>
        C. Termination for Cause. Either party may terminate this Agreement for
        cause at any time, effective upon delivery of notice of termination to
        the other party. For purposes of this Subsection 9.3, "cause" means the
        failure of the other party to comply with any material term of this
        Agreement within thirty (30) days after receipt of written notice that
        the other party is not in compliance with such material term.
        Notwithstanding anything herein to the contrary, Practice may
        immediately terminate this Agreement upon written notice to PROVIDER, in
        the event that action is taken against PROVIDER's license, PROVIDER is
        excluded from any state or federal health care program, or PROVIDER
        engages in conduct that threatens the health or safety of Practice's
        patients.
      </p>
      <SummarySection>
        If we are ending our relationship because of one of us doing something
        bad, the agreement might be ended on shorter notice.
      </SummarySection>
      <p>
        D. Continuity of Care. Following any termination or expiration of this
        Agreement, PROVIDER agrees to provide continuity of care services to
        patients as required by the patient's coverage and applicable law.
        Practice will make other providers available to PROVIDER's patients, to
        the extent PROVIDER wishes to make referrals to Practice in order to
        transition patients in compliance with applicable requirements.
      </p>
      <SummarySection>
        It’s not likely to come up, but if a contract with a payer requires that
        you keep providing services to a patient for a period after you leave
        the group, you’ll keep doing so, and we’ll continue to pay you for it.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Confidentiality">
      <p>
        A. General. All records, files, reports and documents pertaining to
        services provided to patients of the Practice by PROVIDER hereunder or
        the Practice's business, to the extent such files, reports and documents
        would be considered by a reasonable person to be confidential or
        proprietary ("Confidential Information") shall be held in strict
        confidence by PROVIDER and shall remain the sole property of the
        Practice. PROVIDER shall not, during or after the Term, disclose the
        Practice’s Confidential Information to any other person, or entity for
        any reason or purpose whatsoever, without the written consent of the
        Practice. Notwithstanding anything herein to the contrary, PROVIDER has
        a limited license to use Confidential Information provided to it during
        the term of this Agreement for the sole purpose of providing services to
        patients of the Practice. The terms of this Section 10 survive
        termination of this Agreement for any reason.
      </p>
      <SummarySection>
        Some of the information we give you (like rates and patient information)
        is confidential. If it is, you agree that you’ll keep the information
        confidential and only use it in your practice with Headway.
      </SummarySection>
      <p>
        B. Remedies. PROVIDER agrees that in the event of a breach of the
        confidentiality provisions of this Agreement, in addition to any other
        available remedies, an injunction, specific performance, or other
        equitable relief is available to Practice without requiring Practice to
        post bond or other security to obtain such relief.
      </p>
      <SummarySection>
        If we think you’re going to disclose our confidential information to
        someone, we can ask a court to order you not to do so.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Notices">
      <p>
        A. All notices and other communications required or permitted to be
        given to a party hereunder must be in writing and are considered given
        and received when furnished to the other party (a) at the email address
        furnished by that party in the electronic portal maintained by Practice
        on which PROVIDER has an account or (b) through such electronic portal.
      </p>
      <SummarySection>
        For anything relating to our agreement with you, we’ll let you know by
        email or on our portal.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Indemnity">
      <p>
        A. Each party shall indemnify and hold the other party, its officers,
        directors, employees and agents, harmless from and against any and all
        actions, claims, demands and liabilities, and against all loss, damage,
        costs and expenses, including reasonable attorneys’ fees, arising
        directly or indirectly out of the indemnifying party's negligence,
        willful misconduct, breach of this Agreement, or violation of applicable
        law.
      </p>
      <SummarySection>
        If we mess something up, we’ll make sure you don’t have to pay for it.
        If you mess something up, you’ll make sure we don’t have to pay for it.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Miscellaneous Provisions">
      <p>
        A. Governing Law. This Agreement is construed, and the rights and
        liabilities of the parties hereto determined, according to the internal
        laws of, and subject exclusively to the jurisdiction of the courts of,
        the State of New York; provided, however, that PROVIDER's licensed
        health care services shall be regulated only by the laws of the
        jurisdiction(s) in which they are provided.
      </p>
      <SummarySection>
        This is a New York contract. If there’s a dispute, it will be tried in
        New York.
      </SummarySection>
      <p>
        B. No Third Party Beneficiaries. The parties to this Agreement have not
        entered into this Agreement for the benefit of any third party and do
        not intend that any benefits of this Agreement inure to the benefit of
        any third party.
      </p>
      <SummarySection>
        This agreement is just between you and Headway. No one else can bring a
        lawsuit under this contract.
      </SummarySection>
      <p>
        C. Entire Agreement. This Agreement constitutes the entire agreement of
        the parties hereto and supersedes and renders null and void all prior
        and contemporaneous agreements between the parties hereto with respect
        to the subject matter hereof.
      </p>
      <SummarySection>
        This agreement, rather than any side conversations, is the final word on
        our relationship.
      </SummarySection>
      <p>
        D. Amendments. No amendments or additions to this Agreement are binding
        unless set forth in a writing and signed by both parties.
        Notwithstanding the foregoing, Practice has the right to amend this
        Agreement on thirty (30) days’ written notice to PROVIDER.
      </p>
      <SummarySection>
        This agreement is subject to change, but we will tell you in advance if
        it’s going to change.
      </SummarySection>
      <p>
        E. Waiver. Waiver of breach of or default under any term or provision of
        this Agreement by either party, by course of dealing or otherwise, is
        not deemed a waiver of any other breach or default under the same or a
        different provision of this Agreement.
      </p>
      <SummarySection>
        If you violate the contract, but we tell you it’s okay, that doesn’t
        mean you can violate the contract again. The same goes for us.
      </SummarySection>
      <p>
        F. Severability. In the event any term or provision of this Agreement is
        rendered invalid or unenforceable by any valid act of Congress or a
        state legislature, or by any regulation duly promulgated by officers of
        the United States or a State regulator of competent jurisdiction acting
        in accordance with law, or is held by any court of competent
        jurisdiction to be invalid, void or unenforceable, the remaining
        provisions of this Agreement remain in full force and effect.
      </p>
      <SummarySection>
        If a court says something in this agreement is illegal, the court can
        change the contract to make it legal.
      </SummarySection>
      <p>
        G. Assignment and Binding Effect. PROVIDER may not assign this Agreement
        or delegate any of his/her duties under the Agreement.
      </p>
      <SummarySection>
        We are working with you for a reason! Only you can provide services
        under this contract.
      </SummarySection>
      <p>
        H. Counterparts. This Agreement may be executed in two counterparts,
        each of which separately shall constitute an original instrument but
        both of which together with this Agreement shall constitute one and the
        same instrument that is binding upon the parties notwithstanding that
        they each signed separate counterpart signature pages.
      </p>
      <SummarySection>
        We’re both okay with the fact that we’re not going to sign the contract
        at the same time, in the same place, on the same page.
      </SummarySection>
    </LegalSection>
    <LegalSection title="Attachment A: Fee Schedule">
      <p>
        The below fee schedule lays out what you’ll earn for appointments
        conducted through the Practice. Payment for appointments occur. twice
        monthly, on the 15th of the month and on the last day of the month,
        anywhere between 14 and 30 days after you confirm the details of the
        appointment
      </p>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: theme.space.base,
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          size="large"
          css={{
            textTransform: 'none',
          }}
          endIcon={<ArrowForward />}
          onClick={() => {
            setSelectedPart('rates');
          }}
        >
          See rates
        </Button>
      </div>
    </LegalSection>
    <PrivatePayProviderAddendum />
  </div>
);
