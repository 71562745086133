import { InputLabel, InputLabelProps } from '@mui/material';
import React, { FC } from 'react';

import { FieldControlContext } from './FieldControl';

export const FieldInputLabel: FC<React.PropsWithChildren<InputLabelProps>> = (
  props
) => {
  const { id } = React.useContext(FieldControlContext);
  return (
    <InputLabel id={`${id}-label`} variant="standard" htmlFor={id} {...props} />
  );
};
