import React from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { CREDENTIALING_REQS_LINK } from '@headway/shared/constants/zendesk';
import { formatPatientName } from '@headway/shared/utils/patient';

export const NotCredentialedInStateError = ({
  patient,
  state,
  isMSCGuardrailWarning,
  mscGuardrailRestrictionDate,
  onClickToReschedule,
}: {
  patient: UserRead;
  state: UnitedStates;
  isMSCGuardrailWarning?: boolean;
  mscGuardrailRestrictionDate?: string;
  onClickToReschedule?: () => void;
}) => {
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });
  const buttonHandlerProp = !!onClickToReschedule
    ? { onClick: onClickToReschedule }
    : { href: CREDENTIALING_REQS_LINK, target: '_blank', rel: 'noreferrer' };
  const buttonCtaText = !!onClickToReschedule
    ? 'Update session location'
    : 'Learn more';
  return (
    <div css={{ marginTop: theme.spacing.x2 }}>
      <GuidanceCard
        variant={isMSCGuardrailWarning ? 'warning' : 'error'}
        layout="vertical"
      >
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <BodyText>
            You cannot see {patientFirstName} through their insurance plan in{' '}
            {statesToDisplayNames[state]} because you are not credentialed with{' '}
            {patientFirstName}’s health plan there.{' '}
            {isMSCGuardrailWarning
              ? `Starting after ${mscGuardrailRestrictionDate}, we'll block session confirmation if there is a mismatch.`
              : 'Your client must be physically located in a state where you are credentialed during your session.'}
          </BodyText>
          <div css={{ alignSelf: 'flex-start', marginTop: theme.spacing.x2 }}>
            <LinkButton variant="link" {...buttonHandlerProp}>
              {buttonCtaText}
            </LinkButton>
          </div>
        </div>
      </GuidanceCard>
    </div>
  );
};
