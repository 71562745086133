import _ from 'lodash';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';

import { FrontEndCarrierRead } from '../../api/src/models/FrontEndCarrierRead';
import { ProviderType } from '../../api/src/models/ProviderType';
import { UnitedStates } from '../../api/src/models/UnitedStates';
import { RegionalProviderFeeScheduleApi } from '../../api/src/resources/RegionalProviderFeeScheduleApi';
import {
  CarrierNames,
  ConsolidatedRatesByState,
  FeeSchedule,
  RatesWithState,
} from '../types/rates';
import {
  consolidateRates,
  getCarriers,
  getCarriersNames,
} from '../utils/rates';
import { createBasicApiHooks } from './utils';

export interface RegionalRateRequest {
  state: UnitedStates;
  zip_code?: string;
  is_telehealth?: boolean;
  provider_region_id?: number;
}

export interface RegionalRateData {
  rates: ConsolidatedRatesByState;
  names: CarrierNames[];
  carriers: string[];
  getStateWithSupportedCarriers: () => StateWithSupportedCarriers;
}

export interface QueryKeyArgs {
  regionalRateRequests: RegionalRateRequest[];
  carriersById: { [index: string]: FrontEndCarrierRead };
  providerType: string;
  providerResidenceState?: UnitedStates;
  isLiveProvider: boolean;
}

export const getRegionalRatesKey = ({
  regionalRateRequests,
  providerType,
  providerResidenceState,
}: QueryKeyArgs): string[] => {
  const key = _.compact(
    _.flatten(
      regionalRateRequests.map((request) => [
        request.state?.toString(),
        request.provider_region_id?.toString(),
      ])
    )
  );
  if (providerType) {
    key.push(providerType);
  }
  if (providerResidenceState) {
    key.push(providerResidenceState);
  }
  return key;
};

export type StateWithSupportedCarriers = {
  [state in UnitedStates]?: Set<number>;
};
const getStateWithSupportedCarriers = (
  ratesWithState: RatesWithState[]
): StateWithSupportedCarriers => {
  const stateWithSupportedCarriers: StateWithSupportedCarriers = {};

  ratesWithState.forEach(({ state, rates }) => {
    stateWithSupportedCarriers[state] = new Set(
      rates.map((rate) => rate.front_end_carrier.id)
    );
  });

  return stateWithSupportedCarriers;
};

/**
 * This function incompletely checks if a provider is eligible for the BCBS virtual wrap networks.
 * Specifically, it doesn't check license type for BCBSMA and doesn't check the NY counties where providers are credentialed for Horizon NJ.
 * This incomplete check will at least prevent the majority of incorrect PFECs being created, and the rest should be cleaned up as part of the credentialing process.
 * Maybe this could be moved to the backend eventually as part of the payer eligibility requirements project.
 * For full list of eligibility requirements, see https://findheadway.atlassian.net/wiki/spaces/CX/pages/341409797/BCBS+Virtual+Networks
 * @param frontEndCarrierId
 * @param providerPracticeState
 * @param providerStateOfResidence
 */
const isProviderEligibleForPayersWithPossibleVirtualNetwork = (
  frontEndCarrierId: number,
  providerPracticeState: UnitedStates,
  providerStateOfResidence?: UnitedStates
) => {
  switch (frontEndCarrierId) {
    case FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS:
      return isProviderEligibleForBCBSMA(
        providerPracticeState,
        providerStateOfResidence
      );
    case FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY:
      return isProviderEligibleForHorizonNJ(
        providerPracticeState,
        providerStateOfResidence
      );
    default:
      return false;
  }
};

function isProviderEligibleForBCBSMA(
  providerPracticeState: UnitedStates,
  providerStateOfResidence?: UnitedStates
) {
  const fecStates = [UnitedStates.MASSACHUSETTS];
  const eligibleForBCBSMAInPerson =
    providerStateOfResidence === providerPracticeState &&
    fecStates.includes(providerPracticeState);
  const eligibleForBCBSVirtual =
    !!providerStateOfResidence && !fecStates.includes(providerStateOfResidence);

  return eligibleForBCBSMAInPerson || eligibleForBCBSVirtual;
}

/**
 * Eligibility Requirements for regular Horizon:
 * Must have a NJ or NY license +
 * Must live in NJ or some parts of NY, PA and DE
 *
 * For Horizon VIRTUAL Network:
 * Provider’s state of residence and the state they practice in on Headway matches.(.e. State of residence is Pennsylvania and practicing on Headway in Pennsylvania)
 * Provider is not live / does not live in New Jersey.
 * @param providerPracticeState
 * @param providerStateOfResidence
 */
function isProviderEligibleForHorizonNJ(
  providerPracticeState: UnitedStates,
  providerStateOfResidence?: UnitedStates
) {
  const fecStatesOfResidence = [UnitedStates.NEW_JERSEY];
  const fecStatesOflicense = [UnitedStates.NEW_YORK, UnitedStates.NEW_JERSEY];

  const eligibleForHorizonNJInPerson =
    !!providerStateOfResidence &&
    fecStatesOfResidence.includes(providerStateOfResidence) &&
    fecStatesOflicense.includes(providerPracticeState);
  const eligibleForHorizonNJVirtual =
    !!providerStateOfResidence &&
    providerStateOfResidence === providerPracticeState &&
    !fecStatesOfResidence.includes(providerStateOfResidence);

  return eligibleForHorizonNJInPerson || eligibleForHorizonNJVirtual;
}

const { useSingleQuery: useRegionalRates } = createBasicApiHooks(
  getRegionalRatesKey,
  async ({
    regionalRateRequests,
    carriersById,
    providerType,
    providerResidenceState,
    isLiveProvider,
  }): Promise<RegionalRateData> => {
    const feeSchedules = _.compact(
      await Promise.all(
        regionalRateRequests.map((request) => {
          if (!request?.state) {
            return Promise.resolve(null);
          }
          return RegionalProviderFeeScheduleApi.getRegionalProviderFeeSchedules(
            {
              ...request,
              is_live_provider: isLiveProvider,
              provider_type: providerType as ProviderType,
              only_most_recent: true,
              max_effective_date: new Date().toISOString(),
              max_group_contract_effective_date: new Date().toISOString(),
              provider_state_of_residence: providerResidenceState,
            }
          );
        })
      )
    );

    const ratesWithState = feeSchedules.reduce(
      (ratesWithStateArray, rates, index) => {
        const bcbsVirtualNetworkIds = [
          FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS,
          FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY,
        ];
        const eligibleRates = rates.filter((rate) => {
          if (!bcbsVirtualNetworkIds.includes(rate.frontEndCarrierId)) {
            return true;
          } else
            return isProviderEligibleForPayersWithPossibleVirtualNetwork(
              rate.frontEndCarrierId,
              regionalRateRequests[index].state,
              providerResidenceState
            );
        });

        ratesWithStateArray.push({
          state: regionalRateRequests[index].state,
          providerType: providerType,
          rates: eligibleRates.map((rate): FeeSchedule => {
            return {
              effective_date: rate.effectiveDate,
              cpt_code: rate.cptCode,
              amount: rate.amount,
              plan_type: rate.planType,
              front_end_carrier: carriersById[rate.frontEndCarrierId],
            };
          }),
        });

        return ratesWithStateArray;
      },
      [] as RatesWithState[]
    );

    const consolidatedRatesByStates = consolidateRates(ratesWithState);
    return {
      rates: consolidatedRatesByStates,
      names: getCarriersNames(ratesWithState),
      carriers: getCarriers(ratesWithState),
      getStateWithSupportedCarriers: () =>
        getStateWithSupportedCarriers(ratesWithState),
    };
  },
  ({ carriersById }) => !!Object.keys(carriersById).length,
  () => {
    return 'Failed to fetch regional rates';
  }
);
export { useRegionalRates };
