import { ChevronLeft, ChevronRight, ErrorOutline } from '@mui/icons-material';
import {
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { DateLocalizer, NavigateAction, View } from 'react-big-calendar';
import { Link } from 'react-router-dom';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { ProviderCalendarSyncStatus } from '@headway/api/models/ProviderCalendarSyncStatus';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { Button, VisuallyHidden } from '@headway/ui';
import { usePrevious } from '@headway/ui/hooks/usePrevious';
import { theme } from '@headway/ui/theme';
import { notifySuccess } from '@headway/ui/utils/notify';

import { useProvider } from 'hooks/useProvider';

import { CalendarContext } from '../CalendarContext';
import { Filter } from '../components/Filter';
import { PatientBookedEvent } from '../components/PatientBookedEvent';
import { ScheduleEventButton } from '../components/ScheduleEventButton';
import { Task } from '../components/Task';
import { CALENDAR_VIEW_STORAGE_KEY } from '../utils/constants';
import { useProviderCalendarsQuery } from '../utils/queries';

const getFailedCalendarNames = (calendars: ProviderCalendarRead[]) =>
  calendars
    .filter((c) => c.syncStatus === ProviderCalendarSyncStatus.FAILURE)
    .map((c) => c.calendarName)
    .join(', ');

export interface CalendarToolbarProps {
  date: Date;

  onNavigate: (navigation: NavigateAction) => void;
  onView: (view: View) => void;

  label: React.ReactNode;

  views: string[];

  localizer: DateLocalizer & { messages: { [key: string]: string } };

  handleCreateEventClick: (event: ProviderEventType) => void;
  handleIntakeCallClick: (event: ProviderEventRead) => void;
  handleIntakeCallCancelClick: (event: ProviderEventRead) => void;
  handleTaskCancelClick: (event: ProviderEventRead) => void;
  handleTaskConfirmDetailsClick: (event: ProviderEventRead) => void;
  handleToggleCancelledAppointments: () => void;
  handleDetailClose: () => void;
  onFilterByCalendar: (exclusions: number[]) => any;

  carriersById: { [index: string]: FrontEndCarrierRead };
}

export const CalendarToolbar = ({
  localizer: { messages },
  onNavigate,
  onView,
  views,
  date,
  ...props
}: CalendarToolbarProps) => {
  const provider = useProvider();
  const calendarsQuery = useProviderCalendarsQuery(provider.id, {
    enabled: true,
  });
  const calendarContext = React.useContext(CalendarContext);
  const previousCalendarQueryData = usePrevious(calendarsQuery.data);

  const selectedCalendars = calendarContext.calendars.filter((c: any) =>
    calendarContext.filters.excludedCalendars.includes(c.id) ? false : true
  );

  useEffect(() => {
    if (previousCalendarQueryData?.length) {
      const isCalendarSyncedSuccess = calendarsQuery.data?.some(
        (c) => c.syncStatus === ProviderCalendarSyncStatus.SUCCESS
      );

      const isPrevCalendarQueryDataNotSynced = previousCalendarQueryData?.every(
        (c) => c.syncStatus !== ProviderCalendarSyncStatus.SUCCESS
      );

      if (isPrevCalendarQueryDataNotSynced && isCalendarSyncedSuccess) {
        notifySuccess('External calendar successfully imported.');
      }
    }
  }, [previousCalendarQueryData, calendarsQuery.data]);

  return (
    <div
      className="rbc-toolbar"
      css={{
        margin: `${theme.space.base} 0`,
        flexWrap: 'nowrap',
        flexDirection: 'column-reverse',
        ' button': {
          height: '100%',
          fontSize: theme.fontSize.base,
          display: 'inline-flex',
          boxShadow: 'none',
          ':hover': { boxShadow: 'none' },
        },
        [theme.media.medium]: {
          justifyContent: 'space-between',
          flexDirection: 'row',
        },
      }}
    >
      <div
        css={{
          display: 'none',
          [theme.media.medium]: { display: 'flex', flexWrap: 'nowrap' },
        }}
      >
        <div className="rbc-btn-group">
          <ScheduleEventButton
            provider={provider}
            handleCreateEventClick={props.handleCreateEventClick}
          />
        </div>

        <div className="rbc-btn-group">
          <IconButton
            onClick={() => {
              props.handleDetailClose();
              onNavigate('PREV');
            }}
            aria-label="Show previous week"
            data-testid="calendarNavigatePreviousButton"
            size="large"
          >
            <ChevronLeft css={{ fontSize: theme.fontSize.xl }} />
            <VisuallyHidden>{messages.previous}</VisuallyHidden>
          </IconButton>
          <Button
            color="gray"
            onClick={() => {
              props.handleDetailClose();
              onNavigate('TODAY');
            }}
            aria-label="Show today"
            data-testid="calendarNavigateTodayButton"
          >
            {messages.today}
          </Button>
          <IconButton
            onClick={() => {
              props.handleDetailClose();
              onNavigate('NEXT');
            }}
            aria-label="Show next week"
            data-testid="calendarNavigateNextButton"
            size="large"
          >
            <ChevronRight css={{ fontSize: theme.fontSize.xl }} />
            <VisuallyHidden>{messages.next}</VisuallyHidden>
          </IconButton>
        </div>
      </div>

      <div
        css={{
          padding: `0 ${theme.space.base}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: theme.space.base,
          [theme.media.medium]: {
            width: 'auto',
            padding: 0,
            marginBottom: 0,
          },
        }}
      >
        <IconButton
          onClick={() => onNavigate('PREV')}
          css={{ [theme.media.medium]: { display: 'none !important' } }}
          size="large"
        >
          <ChevronLeft />
          <VisuallyHidden>{messages.previous}</VisuallyHidden>
        </IconButton>
        <span className="rbc-toolbar-label">
          {calendarContext.view === 'day'
            ? moment(date).format('MMM Do')
            : moment(date).format('MMMM YYYY')}
        </span>
        <IconButton
          onClick={() => onNavigate('NEXT')}
          css={{ [theme.media.medium]: { display: 'none !important' } }}
          size="large"
        >
          <ChevronRight />
          <VisuallyHidden>{messages.next}</VisuallyHidden>
        </IconButton>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          width: '100%',
          marginBottom: theme.space.base,
          padding: `0 ${theme.space.base}`,
          [theme.media.medium]: {
            width: 'auto',
            marginBottom: 0,
            padding: 0,
          },
        }}
      >
        <div
          className="rbc-btn-group"
          css={{
            [theme.media.medium]: { display: 'none' },
          }}
        >
          <ScheduleEventButton
            provider={provider}
            handleCreateEventClick={props.handleCreateEventClick}
          />
        </div>
        {calendarsQuery.data?.some(
          (c) => c.syncStatus === ProviderCalendarSyncStatus.PENDING
        ) ? (
          <div className="rbc-btn-group" css={{ marginTop: '5px' }}>
            <Tooltip title="Syncing External Calendars">
              <CircularProgress size={20} />
            </Tooltip>
          </div>
        ) : calendarsQuery.data?.some(
            (c) => c.syncStatus === ProviderCalendarSyncStatus.FAILURE
          ) ? (
          <div className="rbc-btn-group" css={{ marginTop: '5px' }}>
            <Link to="/settings/calendar">
              <Tooltip
                title={`Failed to sync: ${getFailedCalendarNames(
                  calendarsQuery.data
                )}`}
              >
                <ErrorOutline
                  css={{
                    color: theme.color.error,
                  }}
                />
              </Tooltip>
            </Link>
          </div>
        ) : null}
        <div className="rbc-btn-group">
          <Filter
            providerId={provider.id}
            handleToggleCancelledAppointments={
              props.handleToggleCancelledAppointments
            }
            hideCancelledAppointments={
              calendarContext.filters.hideCancelledAppointments
            }
            selectedCalendarIds={selectedCalendars.map(
              (calendar) => calendar.id
            )}
            calendars={calendarContext.calendars}
            onFilterByCalendar={props.onFilterByCalendar}
            view={calendarContext.view}
          />
        </div>
        <div className="rbc-btn-group">
          <PatientBookedEvent
            handleIntakeCallClick={props.handleIntakeCallClick}
            handleCancelClick={props.handleIntakeCallCancelClick}
          />
        </div>
        <div className="rbc-btn-group">
          <Task
            handleTaskConfirmDetailsClick={props.handleTaskConfirmDetailsClick}
            handleTaskCancelClick={props.handleTaskCancelClick}
          />
        </div>
        <div className="rbc-btn-group">
          <FormControl variant="outlined">
            <Select
              value={calendarContext.view}
              onChange={({
                target: { value },
              }: {
                target: { value: unknown };
              }) => {
                onView(value as View);
                window.localStorage.setItem(
                  CALENDAR_VIEW_STORAGE_KEY,
                  value as string
                );
              }}
              css={{
                height: '100%',
                ' .MuiSelect-select': {
                  height: '100%',
                  display: 'inline-flex',
                  boxShadow: 'none',
                  padding: `${theme.space.xs} 30px ${theme.space.xs} ${theme.space.base}`,
                  fontFamily: theme.fontFamily.postGrotesk,

                  fontSize: theme.fontSize.base,
                  ':hover': { boxShadow: 'none' },
                },
              }}
            >
              {views.map((name) =>
                name === 'agenda' ? null : (
                  <MenuItem key={name} value={name}>
                    {name === 'week' && calendarContext.mobileView
                      ? 'Three Day'
                      : messages[name]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
};
