import { useSlotId } from '@react-aria/utils';
import { AriaTextFieldProps } from '@react-types/textfield';
import React from 'react';
import { FocusRing, mergeProps, useTextField } from 'react-aria';

import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { FormInputProps, useFormInput } from './useFormInput';

export type TextFieldProps = {
  adornment?: React.ReactElement | null;
  optionalityText?: React.ReactNode;
  /**
   * Text displayed beneath the input label to provider instructions on how the input should be
   * filled out. This differs from `helpText` in that it is meant to be read by the user prior to
   * filling out the input and should provide precise and actionable instructions. Conversely,
   * `helpText` is meant to be read after the input has been filled out and should provide
   * hints and tips on how to fill out the input.
   *
   * Additionally, `instructionalText` should only be used on block level inputs (i.e. inputs that
   * span the full width of the form) and should not be used on inline inputs (i.e. inputs that
   * are displayed alongside other inputs).
   */
  instructionalText?: React.ReactNode;
} & FormInputProps<string> &
  Pick<AriaTextFieldProps, 'autoComplete' | 'type' | 'placeholder'>;

function TextField(props: TextFieldProps) {
  const controlRef = React.useRef<HTMLInputElement>(null);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const { ariaProps, hoverProps, rootProps } = useFormInput({
    ...props,
    isTextInput: true,
  });

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        ...ariaProps,
        inputElementType: 'input',
      },
      controlRef
    );
  const optionalityId = useSlotId([Boolean(props.optionalityText)]);
  const instructionalTextId = useSlotId([Boolean(props.instructionalText)]);

  useAssertFormParentEffect(controlRef, 'TextField');

  React.useEffect(() => {
    const root = rootRef.current;

    if (!root) {
      return;
    }

    const adornment = root.querySelector<HTMLDivElement>(
      '.hlx-text-field-adornment'
    );

    if (!adornment) {
      return;
    }

    root.style.setProperty('--adornment-width', adornment.offsetWidth + 'px');
  });

  const controlProps = mergeProps(inputProps, hoverProps);
  controlProps['aria-describedby'] =
    [instructionalTextId, optionalityId, controlProps['aria-describedby']]
      .filter(Boolean)
      .join(' ') || undefined;

  return (
    <div ref={rootRef} className="hlx-text-field-root" {...rootProps}>
      <div className="hlx-text-field-descriptors">
        <label className="hlx-text-field-label" {...labelProps}>
          {props.label}
        </label>
        {props.optionalityText && (
          <div id={optionalityId} className="hlx-text-field-optionality-text">
            {props.optionalityText}
          </div>
        )}
        {props.instructionalText && (
          <div
            id={instructionalTextId}
            className="hlx-text-field-instructional-text"
          >
            {props.instructionalText}
          </div>
        )}
      </div>

      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        autoFocus={props.autoFocus}
      >
        <input
          className={`hlx-text-field-control ${
            props.adornment?.type === TextFieldAdornmentEnd
              ? 'hlx-text-field-adornment-end'
              : ''
          }`}
          ref={controlRef}
          {...controlProps}
        />
      </FocusRing>
      {props.adornment}

      {props.helpText && (
        <div className="hlx-text-field-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-text-field-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
}

interface TextFieldAdornmentEndProps {
  children: React.ReactNode;
}
function TextFieldAdornmentEnd(props: TextFieldAdornmentEndProps) {
  return <div className="hlx-text-field-adornment">{props.children}</div>;
}

export { TextField, TextFieldAdornmentEnd };
