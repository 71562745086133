import React from 'react';

export function IconDelete(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.1875 17.5V4.375H3.33334V3.125H7.25V2.5H12.75V3.125H16.6667V4.375H15.8125V17.5H4.1875ZM5.4375 16.25H14.5625V4.375H5.4375V16.25ZM7.64584 14.4583H8.89584V6.14583H7.64584V14.4583ZM11.1042 14.4583H12.3542V6.14583H11.1042V14.4583ZM5.4375 4.375V16.25V4.375Z"
        fill="currentColor"
      />
    </svg>
  );
}
