import * as React from 'react';

export const Calendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.76 5.278h-2.59V4.39a.392.392 0 00-.783 0v.887h-5.05V4.39a.392.392 0 00-.784 0v.887h-2.59A.965.965 0 005 6.24v11.796c0 .53.432.963.963.963H17.76c.531 0 .963-.432.963-.963V6.24a.965.965 0 00-.963-.963h.001zm.18 12.759c0 .1-.08.18-.18.18H5.964a.18.18 0 01-.18-.18V6.24c0-.1.08-.18.18-.18h2.59v.886a.392.392 0 00.784 0v-.887h5.05v.887a.392.392 0 00.783 0v-.887h2.59c.1 0 .18.08.18.18v11.798zm-2.537-7.796l-3.576 4.399c-.082.1-.2.16-.33.167h-.022a.454.454 0 01-.32-.134l-2.166-2.165a.392.392 0 01.554-.553l1.907 1.906 3.345-4.115a.39.39 0 01.55-.056.39.39 0 01.057.55h.001z"
      fill="#212326"
    />
  </svg>
);
