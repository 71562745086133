import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';
import {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@headway/shared/react-query';

import { PaginatedConcreteProviderEventRead } from 'utils/types';

export type UseFindProviderEventsQueryKeyArgs = NonNullable<
  Parameters<(typeof ProviderEventApi)['getEvents']>[0]
>;

export type UseFindConcreteProviderEventsQueryKeyArgs = Omit<
  UseFindProviderEventsQueryKeyArgs,
  'expand'
> & { expand?: false };

export const getUseFindProviderEventsQueryKey = (
  filters: UseFindProviderEventsQueryKeyArgs
) => ['provider-event-api-get-events', filters] as const;

/**
 * Returns whether the given query has a query key associated with useFindProviderEvents.
 */
export const isFindProviderEventsQueryKey = (
  queryKey: QueryKey
): queryKey is ReturnType<typeof getUseFindProviderEventsQueryKey> =>
  queryKey[0] === 'provider-event-api-get-events' && !!queryKey[1];

const {
  useSingleQuery: useFindProviderEvents,
  useListQuery: useFindProviderEventsList,
  useCachedQuery: useFindProviderEventsCache,
} = createBasicApiHooks(
  getUseFindProviderEventsQueryKey,
  (filters) => ProviderEventApi.getEvents(filters),
  (filters) => !!(filters.provider_id || filters.patient_user_id),
  () => 'Failed to fetch provider events'
);

/**
 * Narrower typing which disallows setting the `expand` parameter to `true` but returns events that
 * are guaranteed to have ids.
 */
const useFindConcreteProviderEvents = useFindProviderEvents as <
  T = PaginatedConcreteProviderEventRead,
>(
  queryKeyArgs: UseFindConcreteProviderEventsQueryKeyArgs,
  options?: UseQueryOptions<
    PaginatedConcreteProviderEventRead,
    unknown,
    T,
    ReturnType<typeof getUseFindProviderEventsQueryKey>
  >
) => UseQueryResult<PaginatedConcreteProviderEventRead>;

const useFindConcreteProviderEventsList = useFindProviderEventsList as <
  T = PaginatedConcreteProviderEventRead,
>(
  queryArgs: {
    queryKeyArgs: UseFindConcreteProviderEventsQueryKeyArgs;
    options?: UseQueryOptions<
      PaginatedConcreteProviderEventRead,
      unknown,
      T,
      ReturnType<typeof getUseFindProviderEventsQueryKey>
    >;
  }[]
) => UseQueryResult<PaginatedConcreteProviderEventRead>[];

export {
  useFindProviderEvents,
  useFindProviderEventsList,
  useFindProviderEventsCache,
  useFindConcreteProviderEvents,
  useFindConcreteProviderEventsList,
};
