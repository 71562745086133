import { chunk } from 'lodash';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

import { GetMatchingProviderFrontEndCarrierRequest } from '../../api/src/models/GetMatchingProviderFrontEndCarrierRequest';

interface UseMatchingProviderFrontEndCarrierBatchQueryKeyArgs {
  providerId: number;
  patientRequests: GetMatchingProviderFrontEndCarrierRequest[];
}

const getUseMatchingProviderFrontEndCarrierBatchQueryKey = ({
  providerId,
  patientRequests,
}: UseMatchingProviderFrontEndCarrierBatchQueryKeyArgs) => [
  'matching-provider-front-end-carrier-batch',
  providerId,
  patientRequests,
];

const MAX_BATCH_SIZE = 50;

const { useSingleQuery: useMatchingProviderFrontEndCarrierBatchQuery } =
  createBasicApiHooks(
    getUseMatchingProviderFrontEndCarrierBatchQueryKey,
    async ({
      providerId,
      patientRequests,
    }: UseMatchingProviderFrontEndCarrierBatchQueryKeyArgs) => {
      const patientRequestChunks = chunk(patientRequests, MAX_BATCH_SIZE);
      const responses = await Promise.all(
        patientRequestChunks.map((patientRequestChunk) =>
          ProviderApi.getMatchingProviderFrontEndCarrierBatch(
            providerId,
            patientRequestChunk
          )
        )
      );
      return responses.flat();
    },
    ({ patientRequests }) => patientRequests.length > 0,
    () => 'Failed to fetch matching provider front end carrier batch'
  );
export { useMatchingProviderFrontEndCarrierBatchQuery };
