import React from 'react';

import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { CARRIERS_REQUIRING_AUTHORIZATION } from '@headway/shared/constants/carrierIds';
import { UseFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';

import { PayerTimeline } from './PayerTimeline';
import { PayerTimelineGroup } from './UseInsuranceStatus';

interface PayerTimelineGroupProps {
  payerName: string;
  payerTimelineGroups: PayerTimelineGroup[];
  onOptOutClicked: () => void;
}

export function PayerTimelineGroupComponent({
  payerName,
  payerTimelineGroups,
  onOptOutClicked,
}: PayerTimelineGroupProps) {
  return (
    <div
      css={{
        border: `1px solid ${theme.color.system.borderGray}`,
        borderRadius: 4,
      }}
    >
      <h2 css={{ margin: `${theme.spacing.x4} ${theme.spacing.x5}` }}>
        <SectionHeader>{payerName}</SectionHeader>
      </h2>
      {payerTimelineGroups.map((payerTimelineGroup, idx) => (
        <PayerTimeline
          key={idx}
          {...payerTimelineGroup.sharedData}
          payerTimelines={payerTimelineGroup.payerTimelines}
          onOptOutClicked={onOptOutClicked}
          isAuthorizationRequired={CARRIERS_REQUIRING_AUTHORIZATION.includes(
            payerTimelineGroup.sharedData.frontEndCarrierId
          )}
        />
      ))}
    </div>
  );
}
