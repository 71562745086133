import { ElectronicSignatureRoles } from '@headway/api/models/ElectronicSignatureRoles';

import { DefaultInjectedData } from '../../schema/schema.types';

export enum HelloSignData {
  helloSignEmbedded = 'helloSignEmbedded',
  userId = 'userId',
  onSuccessMessage = 'onSuccessMessage',
  email = 'email',
  name = 'name',
  role = 'role',
  templateIds = 'templateIds',
  helloSignSdkClientId = 'helloSignSdkClientId',
}

export interface HelloSignDataInterface extends DefaultInjectedData {
  helloSignEmbedded: any;
  userId: number;
  onSuccessMessage: string;
  email: string;
  name: string;
  helloSignSdkClientId?: string;
  role: ElectronicSignatureRoles;
  templateIds: string[];
}
