import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import VideocamTwoToneIcon from '@mui/icons-material/VideocamTwoTone';
import { SvgIconProps } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderAddressApi } from '@headway/api/resources/ProviderAddressApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { theme } from '@headway/ui/theme';

import { useProvider } from 'hooks/useProvider';

interface UpcomingAppointmentCardProps {
  upcomingAppointment: ProviderEventRead;
  infoBadgeText?: string;
}

export const UpcomingAppointmentCard = ({
  upcomingAppointment,
  infoBadgeText,
}: UpcomingAppointmentCardProps) => {
  const [user, setUser] = useState<UserRead>();
  const [providerAddress, setProviderAddress] = useState<ProviderAddressRead>();
  const provider = useProvider();

  useEffect(() => {
    const fetchPatientInformation = async () => {
      try {
        if (!upcomingAppointment.patientUserId) return;

        const userRes = await UserApi.getUser(
          upcomingAppointment.patientUserId
        );
        setUser(userRes);
      } catch (err) {
        logException(err);
      }
    };

    const fetchProviderAddress = async () => {
      try {
        if (!upcomingAppointment.providerAddressId) return;

        const getProviderAddress =
          await ProviderAddressApi.getProviderAddressById(
            upcomingAppointment.providerAddressId
          );
        setProviderAddress(getProviderAddress);
      } catch (err) {
        logException(err);
      }
    };

    fetchPatientInformation();
    fetchProviderAddress();
  }, [upcomingAppointment]);

  const now = moment();
  const { startDate, endDate } = upcomingAppointment;
  const isToday = moment(startDate).isSame(now, 'd');
  const showTodayOrDay = isToday ? 'Today' : moment(startDate).format('dddd');
  const VideoOrPersonIcon = upcomingAppointment.telehealth
    ? VideocamTwoToneIcon
    : PersonOutlineTwoToneIcon;

  const acceptAnthemBankOfAmericaEAP = useFlag(
    ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP,
    false
  ); // TODO: Remove after project completion.

  return (
    <div
      css={{
        padding: theme.space.base,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {infoBadgeText && acceptAnthemBankOfAmericaEAP && (
          <div css={{ marginRight: theme.space.xs }}>
            <Badge variant="info">
              {
                <div
                  style={{
                    paddingTop: theme.space.xs4,
                    paddingBottom: theme.space.xs4,
                  }}
                >
                  {infoBadgeText}
                </div>
              }
            </Badge>
          </div>
        )}
        <div
          css={{
            padding: theme.space.xs3,
            backgroundColor: theme.color.primaryBackground,
            display: 'flex',
            justifyContent: 'center',
            width: '30px',
            borderRadius: '4px',
          }}
        >
          <VideoOrPersonIcon color="primary" fontSize="small" />
        </div>
      </div>
      <h6
        css={{
          fontFamily: theme.fontFamily.postGrotesk,
          marginTop: theme.space.xs2,
          marginBottom: theme.space.sm,
        }}
      >
        {'Appointment with '}

        <Link
          component={RouterLink}
          elementType="a"
          to={`/clients/${user?.id}/clinical`}
        >
          {formatPatientName(user, {
            firstNameOnly: true,
          })}
        </Link>
      </h6>
      <div
        css={{ marginBottom: theme.space.base, flexGrow: 1, minHeight: '72px' }}
      >
        <AppointmentDateTime
          Icon={CalendarTodayIcon}
          description={`${showTodayOrDay}, ${moment(startDate).format(
            'MMM DD'
          )}`}
        />
        <AppointmentDateTime
          Icon={AlarmOutlinedIcon}
          description={`${moment(startDate).format('h:mm a')} - ${moment(
            endDate
          ).format('h:mm a')}`}
        />
        {!upcomingAppointment.telehealth && providerAddress?.streetLine1 && (
          <AppointmentDateTime
            Icon={LocationOnIcon}
            description={providerAddress?.streetLine1}
          />
        )}
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {provider?.videoUrlDefault && upcomingAppointment.telehealth && (
          <LinkButton
            href={provider?.videoUrlDefault}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Session
          </LinkButton>
        )}
        <LinkButton
          component={RouterLink}
          elementType="a"
          to={`/calendar?event_id=${
            upcomingAppointment.id ||
            upcomingAppointment.originalRecurringEventId
          }&start_date=${moment(startDate).toISOString()}`}
          variant="secondary"
        >
          Manage
        </LinkButton>
      </div>
    </div>
  );
};

interface AppointmentDateTimeProps {
  Icon: React.FC<React.PropsWithChildren<SvgIconProps>>;
  description: string;
}

const AppointmentDateTime = ({
  Icon,
  description,
}: AppointmentDateTimeProps) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: theme.fontSize.sm,
        fontFamily: theme.fontFamily.postGrotesk,
        color: theme.color.textGray,
        marginBottom: theme.space.xs2,
      }}
    >
      <Icon fontSize="small" css={{ marginRight: theme.space.xs }} />
      {description}
    </div>
  );
};
