import React from 'react';

import { theme } from '@headway/ui/theme';

export const SummarySection: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = ({ children }) => (
  <div
    css={{
      background: theme.color.background,
      borderRadius: 6,
      padding: theme.space.base,
      margin: `${theme.space.xl} 0`,
    }}
  >
    <h4
      css={{
        marginTop: 0,
        color: theme.color.primary,
      }}
    >
      Summary
    </h4>
    <p css={{ marginBottom: 0 }}>{children}</p>
  </div>
);
