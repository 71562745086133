import React from 'react';

import { useId } from '@headway/helix/utils';

export function IconInsight(props: React.SVGProps<SVGSVGElement>) {
  const id = useId();
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_10448_106813)">
        <path
          d="M9.5651 20L6.6952 17.1301H3.1173C2.53129 17.1301 2.03192 16.9237 1.61918 16.511C1.20643 16.0982 1.00006 15.5989 1.00006 15.0129V2.11724C1.00006 1.53124 1.20643 1.03186 1.61918 0.619118C2.03192 0.206372 2.53129 0 3.1173 0H16.0129C16.5989 0 17.0983 0.206372 17.511 0.619118C17.9238 1.03186 18.1302 1.53124 18.1302 2.11724V15.0129C18.1302 15.5989 17.9238 16.0982 17.511 16.511C17.0983 16.9237 16.5989 17.1301 16.0129 17.1301H12.435L9.5651 20ZM9.49996 14.1546C9.84042 14.1546 10.1296 14.0356 10.3676 13.7977C10.6055 13.5599 10.7245 13.2707 10.7245 12.93C10.7245 12.5894 10.6055 12.3002 10.3676 12.0624C10.1296 11.8245 9.84042 11.7055 9.49996 11.7055C9.15934 11.7055 8.87006 11.8245 8.63212 12.0624C8.39434 12.3002 8.27545 12.5894 8.27545 12.93C8.27545 13.2707 8.39434 13.5599 8.63212 13.7977C8.87006 14.0356 9.15934 14.1546 9.49996 14.1546ZM8.60118 10.5715H10.4341C10.4341 10.3079 10.4467 10.0829 10.4718 9.89681C10.4969 9.71068 10.5483 9.53231 10.6258 9.36169C10.7034 9.19107 10.8022 9.03022 10.9222 8.87914C11.0423 8.72807 11.209 8.54768 11.4225 8.33797C11.9281 7.83216 12.2934 7.39514 12.5183 7.02691C12.7432 6.65868 12.8557 6.23267 12.8557 5.74889C12.8557 4.90447 12.5728 4.23114 12.0071 3.7289C11.4413 3.22666 10.6927 2.97554 9.76124 2.97554C8.88968 2.97554 8.15066 3.19881 7.54419 3.64537C6.93771 4.09209 6.5145 4.68957 6.27454 5.43782L7.9325 6.0816C8.0448 5.68514 8.25358 5.35336 8.55883 5.08626C8.86409 4.81916 9.23022 4.68561 9.65724 4.68561C10.0463 4.68561 10.3683 4.79248 10.6235 5.00622C10.8785 5.22012 11.006 5.49831 11.006 5.84079C11.006 6.09346 10.9216 6.3625 10.7529 6.6479C10.5841 6.9333 10.3222 7.23212 9.96715 7.54435C9.69245 7.7764 9.47181 7.99805 9.30522 8.2093C9.13863 8.42056 9.00105 8.64252 8.89247 8.87519C8.7839 9.10785 8.7082 9.35579 8.66539 9.61901C8.62258 9.88239 8.60118 10.1999 8.60118 10.5715Z"
          fill="#2C98EF"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
