import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

import { PaginatedConcreteProviderEventRead } from 'utils/types';

export interface UseProviderPaymentQueryKeyArgs {
  providerId?: number;
  providerPaymentStatusEnums?: ProviderPaymentStatus[];
  providerPaymentDateStart?: string;
  isPausedPaymentsFlagEnabled?: boolean;
}

export const getUseProviderPaymentQueryKey = ({
  providerId,
  providerPaymentStatusEnums,
}: UseProviderPaymentQueryKeyArgs) => [
  'get-provider-upcoming-payments',
  providerId,
  providerPaymentStatusEnums,
];

const {
  useSingleQuery: useProviderPayments,
  useListQuery: useProviderPaymentsList,
  useCachedQuery: useProviderPaymentsCache,
} = createBasicApiHooks<
  UseProviderPaymentQueryKeyArgs,
  (string | number | ProviderPaymentStatus[] | undefined)[],
  PaginatedConcreteProviderEventRead
>(
  getUseProviderPaymentQueryKey,
  ({
    providerId,
    providerPaymentStatusEnums,
    providerPaymentDateStart,
    isPausedPaymentsFlagEnabled,
  }) => {
    if (!providerId) {
      throw new Error('Missing provider id.');
    }

    if (
      !isPausedPaymentsFlagEnabled &&
      (providerPaymentStatusEnums?.includes(ProviderPaymentStatus.DENIED) ||
        providerPaymentStatusEnums?.includes(
          ProviderPaymentStatus.UNDER_REVIEW
        ))
    ) {
      return Promise.resolve({ data: [], totalCount: 0 });
    }

    return ProviderEventApi.getEvents({
      provider_id: providerId,
      event_types: [ProviderEventType.APPOINTMENT],
      appointment_statuses: [
        ProviderAppointmentStatus.DETAILS_CONFIRMED,
        ProviderAppointmentStatus.CANCELED,
      ],
      provider_payment_date_start: providerPaymentDateStart,
      expand_estimated_prices: false,
      order_by: 'start_date',
      order: 'desc',
      provider_payment_status_enums: isPausedPaymentsFlagEnabled
        ? providerPaymentStatusEnums
        : undefined,
    }) as Promise<PaginatedConcreteProviderEventRead>;
  },
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider payments'
);

export {
  useProviderPayments,
  useProviderPaymentsList,
  useProviderPaymentsCache,
};
