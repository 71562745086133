import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import { notifyWarning } from '@headway/ui/utils/notify';

export const useProviderEventsUnconfirmedQuery = (
  providerPatient: ProviderPatientRead
) => {
  const {
    isLoading,
    data: providerEventsUnconfirmed,
    error,
  } = useQuery(
    [
      'providerEventsUnconfirmed',
      {
        providerId: providerPatient.providerId,
        patientId: providerPatient.userId,
      },
    ],
    () =>
      ProviderEventApi.getEvents({
        provider_id: providerPatient.providerId,
        patient_user_id: providerPatient.userId,
        event_types: [ProviderEventType.APPOINTMENT],
        appointment_statuses: [ProviderAppointmentStatus.SCHEDULED],
        expand_estimated_prices: false,
        limit: 1,
      }),
    {
      onError(err: Error) {
        notifyWarning('Could not load appointments for this client.');
        logException(err);
      },
    }
  );

  return { isLoading, providerEventsUnconfirmed, error };
};
