import { UnitedStates } from '@headway/api/models/UnitedStates';
import { PatientInsuranceStatusApi } from '@headway/api/resources/PatientInsuranceStatusApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientInsuranceStatusQueryKeyArgs {
  patientUserId?: number;
  providerId: number;
  appointmentId?: number;
  isTelehealthAppointment?: boolean;
  appointmentState?: UnitedStates;
  includeHiddenCarriers?: boolean;
  checkAppointmentState?: boolean;
}

export const getUsePatientInsuranceStatusQueryKey = ({
  patientUserId,
  providerId,
  appointmentId,
  isTelehealthAppointment,
  appointmentState,
  includeHiddenCarriers,
  checkAppointmentState,
}: UsePatientInsuranceStatusQueryKeyArgs) => [
  'patient-insurance-status',
  patientUserId,
  providerId,
  appointmentId === undefined ? null : appointmentId,
  isTelehealthAppointment === undefined ? null : isTelehealthAppointment,
  appointmentState === undefined ? null : appointmentState,
  includeHiddenCarriers === undefined ? null : includeHiddenCarriers,
  checkAppointmentState === undefined ? null : checkAppointmentState,
];

const {
  useSingleQuery: usePatientInsuranceStatusQuery,
  useListQuery: usePatientInsuranceStatusListQuery,
} = createBasicApiHooks(
  getUsePatientInsuranceStatusQueryKey,
  async ({
    patientUserId,
    providerId,
    appointmentId,
    isTelehealthAppointment,
    appointmentState,
    includeHiddenCarriers,
    checkAppointmentState,
  }: UsePatientInsuranceStatusQueryKeyArgs) => {
    if (patientUserId) {
      return PatientInsuranceStatusApi.getPatientInsuranceStatus(
        patientUserId,
        providerId,
        {
          appointment_id: appointmentId,
          is_telehealth_appointment: isTelehealthAppointment,
          appointment_state: appointmentState,
          include_hidden_carriers: includeHiddenCarriers,
          check_appointment_state: checkAppointmentState,
        }
      );
    }
  },
  ({ patientUserId, providerId }) => !!patientUserId && !!providerId,
  () => 'Failed to fetch patient insurance status'
);
export { usePatientInsuranceStatusQuery, usePatientInsuranceStatusListQuery };
