import React, { useEffect } from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { BodyText } from '@headway/helix/BodyText';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { PiggyBank } from '@headway/icons/dist/provider/PiggyBank';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';

import { RateBoostBannerCopyVariant } from './constants';

const RateBoostGuidanceCard = ({
  bodyText,
  link,
  client,
}: {
  bodyText?: string;
  link?: string;
  client?: UserRead;
}) => {
  const bodyContent = bodyText
    ? bodyText
    : 'Earn rate boosts when your client completes assessments and you meet notes requirements.';
  const linkContent = link ? link : '/incentives/rate-boost';

  useEffect(() => {
    if (client?.id != null) {
      trackEvent({
        name: 'Rate Boost Banner Viewed',
        properties: {
          copyVariant: RateBoostBannerCopyVariant.SMALL_GUIDANCE_CARD,
        },
      });
    }
  }, [client?.id]);

  if (client) {
    return (
      <div className="flex items-center gap-3 rounded bg-hue-lightBlue px-4 py-3">
        <PiggyBank />
        <div>
          <div>
            <BodyText>{bodyContent}</BodyText>
          </div>
          <div className="flex gap-3">
            <LinkButton
              href={`/clients/${client.id}/assessments?manageAssessments=true`}
              target="_blank"
              rel="noreferrer"
              variant="link"
            >
              {`Send assessments to ${formatPatientName(client, {
                firstNameOnly: true,
              })}`}
            </LinkButton>
            <LinkButton
              href={linkContent}
              target="_blank"
              rel="noreferrer"
              variant="link"
              onPress={() =>
                trackEvent({ name: 'Learn More Rate Boost Button Clicked' })
              }
            >
              Learn more
            </LinkButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-3 rounded bg-hue-lightBlue px-4 py-3">
      <div className="flex flex-grow items-center gap-3">
        <PiggyBank />
        <div className="max-w-[500px]">
          <BodyText>{bodyContent}</BodyText>
        </div>
      </div>
      <div className="flex-shrink-0">
        <BodyText>
          <Link href={linkContent} target="_blank" rel="noreferrer">
            <strong>Learn more</strong>
          </Link>
        </BodyText>
      </div>
    </div>
  );
};

export default RateBoostGuidanceCard;
