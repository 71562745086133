import styled from '@emotion/styled';
import DoneIcon from '@mui/icons-material/Check';
import React from 'react';

import { theme } from '../theme';

const CheckContainer = styled.div({
  fontSize: theme.fontSize.base,
  width: `calc(18px + ${theme.space.xs2})`,
  height: `calc(18px + ${theme.space.xs2})`,
  borderRadius: '50%',
  background: theme.color.primaryBackground,
  left: `calc(-1 * ${theme.space.xl3})`,
  color: theme.color.primary,
  textAlign: 'center',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const OrderedList = styled.ol(
  {
    counterReset: 'list-counter',
    listStyle: 'none',
    padding: `0 0 0 ${theme.space.xl3}`,
    margin: 0,

    li: {
      counterIncrement: 'list-counter',
      position: 'relative',
      '::before': {
        content: 'counter(list-counter)',
        fontSize: theme.fontSize.base,
        fontWeight: theme.fontWeight.bold,
        width: `calc(18px + ${theme.space.xs2})`,
        height: `calc(18px + ${theme.space.xs2})`,
        borderRadius: '50%',
        background: theme.color.primaryBackground,
        left: `calc(-1 * ${theme.space.xl3})`,
        textAlign: 'center',
        position: 'absolute',
      },
      '& .hw-check-container': {
        display: 'none',
      },
    },
  },
  () => ({
    li: {
      '::before': {
        color: theme.color.primary,
      },
    },
  })
);

export const UnorderedList = styled.ul({
  counterReset: 'list-counter',
  listStyle: 'none',
  padding: `0 0 0 ${theme.space.xl3}`,
  margin: 0,
  fontSize: theme.fontSize.lg,
});

export const ListItem: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<{}>>
> = (props) => (
  <li
    css={{
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      color: theme.color.darkGray,
      fontFamily: theme.fontFamily.postGrotesk,
      fontSize: theme.fontSize.lg,
      ':not(:last-child)': {
        marginBottom: theme.space.base,
      },
    }}
  >
    <CheckContainer className="hw-check-container">
      <DoneIcon css={{ fontSize: theme.fontSize.lg }} />
    </CheckContainer>
    <div css={{ fontSize: theme.fontSize.lg, margin: 0 }}>{props.children}</div>
  </li>
);
