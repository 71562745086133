import { parseDate } from '@internationalized/date';
import { isSameDay } from '@internationalized/date';
import { FormikProps, useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback, useMemo } from 'react';
import { DatePickerState } from 'react-stately';

import { Item } from '@headway/helix/collections';
import { xFromNow } from '@headway/helix/date';
import { DatePickerField } from '@headway/helix/DatePickerField';
import { validity } from '@headway/helix/FormControl';
import { ToggleButtonGroup } from '@headway/helix/ToggleButton';

import { SchemaComponent } from '.';

const MemoizedDateField = React.memo(DatePickerField);

export const FormDate = ({
  element,
  isOptional = false,
  disabled = false,
}: SchemaComponent) => {
  const { id, title } = element;

  const { values, setFieldValue, touched, errors, submitCount } =
    useFormikContext<any>();
  const isoDateString: string | undefined = get(values, id);

  const onChange = useCallback(
    (v: any) => {
      setFieldValue(id, v?.toString());
    },
    [id, setFieldValue]
  );

  const value = useMemo(
    () => (isoDateString ? parseDate(isoDateString) : undefined),
    [isoDateString]
  );

  const validation = useMemo(
    () => validity(id, { touched, errors, submitCount } as FormikProps<any>),
    [id, touched, errors, submitCount]
  );

  return (
    <MemoizedDateField
      label={title}
      name={id}
      key={id}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      value={value}
      onChange={onChange}
      validation={validation}
      preset={(state: DatePickerState) => {
        const selection = [];

        if (state.dateValue) {
          if (isSameDay(state.dateValue, xFromNow({ days: 30 }))) {
            selection.push('days::30');
          } else if (isSameDay(state.dateValue, xFromNow({ days: 60 }))) {
            selection.push('days::60');
          } else if (isSameDay(state.dateValue, xFromNow({ days: 90 }))) {
            selection.push('days::90');
          }
        }

        return (
          <ToggleButtonGroup
            selectedKeys={selection}
            selectionMode="single"
            size="medium"
            onSelectionChange={(selection) => {
              const [key] = Array.from(selection);
              const [unit, value] = String(key).split('::');
              state.setDateValue(
                xFromNow({
                  [unit]: parseInt(value),
                })
              );
            }}
            aria-label="Days from now"
          >
            <Item key="days::30">30 days</Item>
            <Item key="days::60">60 days</Item>
            <Item key="days::90">90 days</Item>
          </ToggleButtonGroup>
        );
      }}
    />
  );
};
