import { Time } from '@internationalized/date';
import * as Yup from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';

import {
  MAX_SELF_PAY_RATE,
  MAX_SELF_PAY_RATE_MESSAGE,
} from 'views/Patients/utils/billingType';

import { isBillingAddOnPsychotherapy } from '../../../util';
import { TemplateInfo } from '../../ProgressNotes/templateView/schemaRenderer/versions/types';
import {
  isFormValueTelehealthAndBilledWithInsuranceOrEAP,
  SessionDetailsFormValues,
} from '../SessionDetailsForm';

const baseSchema = {
  billingType: Yup.mixed().oneOf(Object.values(BillingType)),
  patientResponsibilityAmount: Yup.number().when('billingType', {
    is: (billingType) => billingType === BillingType.SELF_PAY,
    then: Yup.number()
      .min(0, 'Must be $0 or more.')
      .max(MAX_SELF_PAY_RATE, MAX_SELF_PAY_RATE_MESSAGE)
      .required('Session rate is required.'),
  }),
  providerAddressId: Yup.number().required('Location is required'),
};

const appointmentConfirmDetailsPrescriberSchema = {
  ...baseSchema,
  diagnosisCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one diagnosis code is required.'),
  }),
  cptCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one CPT code is required.'),
  }),
};

const appointmentConfirmDetailsPrescriberSchemaWithControlledSubstanceAttestation =
  {
    ...appointmentConfirmDetailsPrescriberSchema,
    didPrescribeControlledSubstance: Yup.boolean().when('billingType', {
      is: (billingType) => billingType !== BillingType.SELF_PAY,
      then: Yup.boolean().required('A response is required'),
    }),
    controlledSubstanceAttestation: Yup.object().when(
      ['didPrescribeControlledSubstance', 'billingType'],
      {
        is: (didPrescribeControlledSubstance, billingType) =>
          billingType !== BillingType.SELF_PAY &&
          didPrescribeControlledSubstance,
        then: Yup.object().shape({
          prescribedSubstances: Yup.array().min(
            1,
            'Select all relevant controlled substances'
          ),
          exceedsRecommendedDosage: Yup.boolean()
            .nullable()
            .required('A response is required'),
          notes: Yup.string().nullable().required('A response is required'),
        }),
      }
    ),
  };

const appointmentConfirmDetailsNonPrescriberSchema = {
  ...baseSchema,
  diagnosisCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one diagnosis code is required.'),
  }),
  cptCodes: Yup.array().when('billingType', {
    is: (billingType) => billingType !== BillingType.SELF_PAY,
    then: Yup.array().min(1, 'At least one CPT code is required.'),
  }),
};

export const checkIfExactDurationIsRequired = (
  progressNoteType: ProgressNoteType,
  selectedTemplate: TemplateInfo | undefined
) => {
  if (
    progressNoteType === ProgressNoteType.TEMPLATE &&
    selectedTemplate !== undefined
  ) {
    return true;
  }

  return false;
};

export const validationSchema = (
  provider: ProviderRead,
  progressNoteType: ProgressNoteType,
  selectedTemplate: TemplateInfo | undefined,
  exactDurationRequired?: boolean | undefined,
  isMSCOrGuardrailRestrictionEnabled?: boolean | undefined,
  isTelehealthLocationsEnabled?: boolean | undefined,
  isControlledSubstanceDataCollectionEnabled?: boolean | undefined,
  shouldRequirePrescriberPsychotherapyTimes?: boolean | undefined
) => {
  const schemaDict = provider.isPrescriber
    ? isControlledSubstanceDataCollectionEnabled
      ? appointmentConfirmDetailsPrescriberSchemaWithControlledSubstanceAttestation
      : appointmentConfirmDetailsPrescriberSchema
    : appointmentConfirmDetailsNonPrescriberSchema;

  const prescriberPsychotherapyTimesSchema =
    shouldRequirePrescriberPsychotherapyTimes
      ? {
          prescriberPsychotherapyStartTime: Yup.object().when(['cptCodes'], {
            is: (cptCodes) =>
              isBillingAddOnPsychotherapy(
                cptCodes?.map((code: CPTCodeInfo) => code.value) || []
              ),
            then: Yup.object().required(
              'To ensure billing compliance, insurers require psychotherapy start times'
            ),
          }),
          prescriberPsychotherapyEndTime: Yup.object().when(['cptCodes'], {
            is: (cptCodes) =>
              isBillingAddOnPsychotherapy(
                cptCodes?.map((code: CPTCodeInfo) => code.value) || []
              ),
            then: Yup.object().required(
              'To ensure billing compliance, insurers require psychotherapy end times'
            ),
          }),
        }
      : {};

  // ensure psychotherapy time window is within appointment window by requiring exact appointment start and end time
  const isExactDurationRequired =
    !!exactDurationRequired ||
    checkIfExactDurationIsRequired(progressNoteType, selectedTemplate) ||
    shouldRequirePrescriberPsychotherapyTimes;

  const schema = Yup.object()
    .shape(schemaDict)
    .shape({
      exactStartTime: isExactDurationRequired
        ? Yup.object().required(
            'To ensure billing compliance, insurers require start times'
          )
        : Yup.object(),
      exactEndTime: isExactDurationRequired
        ? Yup.object().required(
            'To ensure billing compliance, insurers require stop times'
          )
        : Yup.object(),
    })
    .test({
      name: 'exactTimeRules',
      test: async function (vals: any) {
        const values = vals as SessionDetailsFormValues;
        if (!values.exactStartTime || !values.exactEndTime) {
          return true;
        }

        if (
          new Time(
            values.exactStartTime.hour,
            values.exactStartTime.minute
          ).compare(
            new Time(values.exactEndTime.hour, values.exactEndTime.minute)
          ) >= 0
        ) {
          return new Yup.ValidationError(
            ["End time can't be before start time"],
            undefined,
            'exactEndTime'
          );
        }

        return true;
      },
    })
    .test({
      name: 'appointmentLocationPatientAddressIdRequired',
      test: async function (vals: any) {
        const values = vals as SessionDetailsFormValues;
        if (
          !isMSCOrGuardrailRestrictionEnabled &&
          !isTelehealthLocationsEnabled
        ) {
          return true;
        }

        if (
          isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            values.providerAddressId,
            values.billingType
          ) &&
          !values.appointmentLocationPatientAddressId
        ) {
          return new Yup.ValidationError(
            ['Location is required'],
            undefined,
            'appointmentLocationPatientAddressId'
          );
        }

        return true;
      },
    })
    .test({
      name: 'telehealthPlatformRequired',
      test: async (vals: any) => {
        const values = vals as SessionDetailsFormValues;
        if (!isTelehealthLocationsEnabled) {
          return true;
        }

        if (
          isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            values.providerAddressId,
            values.billingType
          ) &&
          !values.telehealthPlatform
        ) {
          return new Yup.ValidationError(
            ['Choose your telehealth session type'],
            undefined,
            'telehealthPlatform'
          );
        }

        return true;
      },
    })
    .test({
      name: 'telehealthProviderStateRequired',
      test: async (vals: any) => {
        const values = vals as SessionDetailsFormValues;
        if (!isTelehealthLocationsEnabled) {
          return true;
        }

        if (
          isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            values.providerAddressId,
            values.billingType
          ) &&
          !values.telehealthProviderState
        ) {
          return new Yup.ValidationError(
            ['State is required'],
            undefined,
            'telehealthProviderState'
          );
        }

        return true;
      },
    })
    .test({
      name: 'telehealthAttestationRequired',
      test: async (vals: any) => {
        const values = vals as SessionDetailsFormValues;
        if (!isTelehealthLocationsEnabled) {
          return true;
        }

        if (
          isFormValueTelehealthAndBilledWithInsuranceOrEAP(
            values.providerAddressId,
            values.billingType
          ) &&
          !values.telehealthAttestation
        ) {
          return new Yup.ValidationError(
            ['Box must be checked to continue'],
            undefined,
            'telehealthAttestation'
          );
        }

        return true;
      },
    })
    .shape(prescriberPsychotherapyTimesSchema)
    .test({
      name: 'prescriberPsychotherapyEndTimeRules',
      test: async function (vals: any) {
        const values = vals as SessionDetailsFormValues;
        if (
          !values.prescriberPsychotherapyStartTime ||
          !values.prescriberPsychotherapyEndTime
        ) {
          return true;
        }

        if (
          new Time(
            values.prescriberPsychotherapyStartTime.hour,
            values.prescriberPsychotherapyStartTime.minute
          ).compare(
            new Time(
              values.prescriberPsychotherapyEndTime.hour,
              values.prescriberPsychotherapyEndTime.minute
            )
          ) >= 0
        ) {
          return new Yup.ValidationError(
            ["End time can't be before start time"],
            undefined,
            'prescriberPsychotherapyEndTime'
          );
        } else if (
          values.exactEndTime &&
          new Time(
            values.prescriberPsychotherapyEndTime.hour,
            values.prescriberPsychotherapyEndTime.minute
          ).compare(
            new Time(values.exactEndTime.hour, values.exactEndTime.minute)
          ) > 0
        ) {
          return new Yup.ValidationError(
            ["Psychotherapy end time can't be after appointment end time"],
            undefined,
            'prescriberPsychotherapyEndTime'
          );
        }

        return true;
      },
    })
    .test({
      name: 'prescriberPsychotherapyStartTimeRule',
      test: async function (vals: any) {
        const values = vals as SessionDetailsFormValues;
        if (
          !values.prescriberPsychotherapyStartTime ||
          !values.prescriberPsychotherapyEndTime ||
          !values.exactStartTime
        ) {
          return true;
        }

        if (
          new Time(
            values.prescriberPsychotherapyStartTime.hour,
            values.prescriberPsychotherapyStartTime.minute
          ).compare(
            new Time(values.exactStartTime.hour, values.exactStartTime.minute)
          ) < 0
        ) {
          return new Yup.ValidationError(
            ["Psychotherapy start time can't be before appointment start time"],
            undefined,
            'prescriberPsychotherapyStartTime'
          );
        }
        return true;
      },
    })
    .test({
      name: 'prescriberPsychotherapyDurationRule',
      test: async function (vals: any) {
        const values = vals as SessionDetailsFormValues;
        if (
          !values.prescriberPsychotherapyStartTime ||
          !values.prescriberPsychotherapyEndTime ||
          !values.exactStartTime ||
          !values.exactEndTime
        ) {
          return true;
        }

        const psychotherapyDuration = new Time(
          values.prescriberPsychotherapyEndTime.hour,
          values.prescriberPsychotherapyEndTime.minute
        ).compare(
          new Time(
            values.prescriberPsychotherapyStartTime.hour,
            values.prescriberPsychotherapyStartTime.minute
          )
        );
        const appointmentDuration = new Time(
          values.exactEndTime.hour,
          values.exactEndTime.minute
        ).compare(
          new Time(values.exactStartTime.hour, values.exactStartTime.minute)
        );
        if (psychotherapyDuration >= appointmentDuration) {
          return new Yup.ValidationError(
            [
              'Psychotherapy duration must be strictly less than appointment duration',
            ],
            undefined,
            'prescriberPsychotherapyEndTime'
          );
        }
        return true;
      },
    });
  return schema;
};
