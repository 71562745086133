import React from 'react';
import * as Yup from 'yup';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { BodyText } from '@headway/helix/BodyText';
import { Checkbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { LogoLoaderWithText, Modal } from '@headway/ui';

import { QuestionnaireV2ContextType } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Context';

import { YesNo } from '../../../utils/providerQuestionnaire';
import { OnBeforeSubmitError } from '../OnBeforeSubmitErrorCard';
import { QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const CAQH_ROSTER_ACTIVE = 'ACTIVE';

export const fetchAndIntegrateCaqhData = async (
  questionnaireId: number,
  caqhNumber: number
) => {
  const providerRosterStatus =
    await ProviderQuestionnaireApi.getProviderRosterStatus(questionnaireId, {
      caqh_provider_id: caqhNumber,
    });
  if (
    !!providerRosterStatus &&
    providerRosterStatus.roster_status !== CAQH_ROSTER_ACTIVE
  ) {
    const maxRetryAttempts = 5;
    for (let i = 0; i < maxRetryAttempts; i++) {
      try {
        await ProviderQuestionnaireApi.addProviderToCaqhRoster(
          questionnaireId,
          {
            caqhProviderId: caqhNumber,
          }
        );
        break;
      } catch (e) {
        if (i === maxRetryAttempts - 1) {
          throw e;
        }
      }
    }
  }

  await ProviderQuestionnaireApi.mapProviderDataByCaqhProviderId(
    questionnaireId,
    {
      caqh_provider_id: caqhNumber,
    }
  );

  return await ProviderQuestionnaireApi.getProviderQuestionnaire(
    questionnaireId
  );
};

export const CaqhAuthorizationStep = ({
  showConfirmationModal,
}: CustomComponentProps) => (
  <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
    <Modal title="Linking CAQH Account" open={showConfirmationModal}>
      <div css={{ padding: theme.spacing.x6 }}>
        <LogoLoaderWithText
          loaderFont={theme.typography.body.regular}
          loadingTexts={[
            `Verifying your responses with CAQH. This might take a few seconds.`,
            'Making sure we can get you credentialed with payers ASAP!',
            'Still working!',
          ]}
        />
      </div>
    </Modal>
    <FormControl
      name="caqhNumberFromNpi"
      component={TextField}
      disabled={true}
      label="CAQH Number"
      helpText="We retrieved your CAQH Number using the NPI you provided"
    />

    <FormControl
      name="caqhUsername"
      component={TextField}
      label="CAQH Username"
    />
    <FormControl
      name="caqhPassword"
      component={TextField}
      label="CAQH Password"
      type="password"
      helpText={
        <div css={{ ...theme.stack.vertical, gap: theme.spacing.x1 }}>
          <>
            We will use your CAQH username and password to ensure your CAQH
            profile has all the information our insurance partners need to
            process your application. We will not alter any existing details, we
            will only add new entries pertaining to Headway.
          </>
          <>
            <span>
              If you forgot your login information, you can reset it{' '}
              <Link
                href="https://proview.caqh.org/Login/ForgotPassword?Type=PR"
                rel="noopener noreferrer"
                target="_blank"
              >
                here
              </Link>
              .
            </span>
          </>
        </div>
      }
    />
    {/* TODO: caqhAuthorization should eventually just be a boolean, but leaving it as a YES/NO string to be consistent with the old form */}
    <div>
      <div
        css={{
          ...theme.stack.horizontal,
          gap: theme.spacing.x2,
          marginBottom: theme.spacing.x2,
        }}
      >
        <span
          css={{
            ...theme.typography.caption.medium,
            display: 'block',
            color: theme.color.system.textBlack,
            [theme.responsive.mobile]: {
              ...theme.typography.subbody.medium,
            },
          }}
          id="caqhAuthorizationHelpText"
        >
          To get credentialed, we’ll need to update your CAQH profile with the
          information you just provided. To make things easy, Headway will do
          this on your behalf.
        </span>
      </div>
      <FormControl
        component={Checkbox}
        name="caqhAuthorization"
        value={YesNo.YES}
        aria-labelledby="caqhAuthorizationHelpText"
      >
        Pursuant to my relationship with my Headway-affiliated PC (as listed in
        my Provider Agreement), I authorize Headway and its third-party vendors
        to access, use, modify, and disclose my information in my CAQH account
        in order to credential me with third-party payers. I acknowledge that I
        am solely responsible for any information I provide to Headway and will
        indemnify, defend, and hold Headway and its third-party vendors harmless
        from and against any loss arising out of the information I provide
      </FormControl>
    </div>
    <FormControl component={Checkbox} name="caqhAttest">
      I attest to the accuracy of my CAQH login credentials and the information
      I have provided in this questionnaire, and I give Headway permission to
      update my profile and attest on my behalf if necessary.
    </FormControl>
  </div>
);

const callVerifyCaqhCredentialsWithRetries = async (
  providerQuestionnaireId: number,
  caqhUsername: string,
  caqhPassword: string
) => {
  const maxRetryAttempts = 5;
  let caqhNumbers;
  for (let i = 0; i < maxRetryAttempts; i++) {
    try {
      ({ caqhNumbers } = await ProviderQuestionnaireApi.verifyCaqhCredentials(
        providerQuestionnaireId,
        {
          username: caqhUsername,
          password: caqhPassword,
        }
      ));
      return caqhNumbers;
    } catch (e: AnyTS4TryCatchUnknownError) {
      // don't retry if it's not 5xx, otherwise retry since there are known serverside errors with this endpoint
      if (e.code !== 'ERR_NETWORK' || i === maxRetryAttempts - 1) {
        throw e;
      }
    }
  }
};
const stepConfig: QuestionnaireV2Step = {
  title: 'CAQH Authorization',
  description:
    'In this section we ask that you share details about your CAQH profile. We ask for your CAQH details so that we can update your profile and move you through the next steps in the credentialing process.',
  Component: CaqhAuthorizationStep,
  onBeforeSubmit: async (
    values,
    setBeforeSubmitError,
    showConfirmationModal,
    context
  ) => {
    // Note that we're doing this validation for the username and password here rather than via formik
    // because we only want to do this validation on this step of the form rather than on every step.
    // This is because we will clear the CAQH password field from the UI after the user navigates to a
    // different step so that we're not exposing PHI in the frontend. The user will have to re-enter
    // the CAQH password if they want to submit this step again.
    if (!values.caqhUsername || !values.caqhPassword) {
      setBeforeSubmitError(OnBeforeSubmitError.CaqhEmptyCredentialsError);
      return false;
    }
    const { providerQuestionnaire } = context;
    showConfirmationModal(true);
    let caqhNumbers;
    try {
      caqhNumbers = await callVerifyCaqhCredentialsWithRetries(
        providerQuestionnaire.id,
        values.caqhUsername,
        values.caqhPassword
      );
    } catch (e) {
      setBeforeSubmitError(OnBeforeSubmitError.CaqhAuthorizationServerError);
      showConfirmationModal(false);
      return false;
    }

    if (!caqhNumbers) {
      setBeforeSubmitError(OnBeforeSubmitError.CaqhAuthorizationLoginError);
      showConfirmationModal(false);
      return false;
    }
    if (caqhNumbers[0] !== values.caqhNumberFromNpi) {
      setBeforeSubmitError(OnBeforeSubmitError.CaqhAuthorizationMismatch);
      showConfirmationModal(false);
      return false;
    }

    let mapped;
    try {
      mapped = await fetchAndIntegrateCaqhData(
        providerQuestionnaire.id,
        Number(caqhNumbers[0])
      );
    } catch (e: AnyTS4TryCatchUnknownError) {
      if (
        e?.response?.data?.detail === 'Provider is not current and complete'
      ) {
        setBeforeSubmitError(
          OnBeforeSubmitError.CaqhAuthorizationRequiresAttestation
        );
      } else if (
        e?.response?.data?.detail === 'Provider has not given authorization'
      ) {
        setBeforeSubmitError(
          OnBeforeSubmitError.CaqhAuthorizationRequiresAuthorization
        );
      } else {
        setBeforeSubmitError(OnBeforeSubmitError.CaqhAuthorizationServerError);
      }
      showConfirmationModal(false);
      return false;
    }

    showConfirmationModal(false);
    return Object.assign(mapped.rawData || {}, {
      caqhAuthorization: values.caqhAuthorization,
      caqhNumberFromNpi: values.caqhNumberFromNpi,
      caqhVerified: values.caqhVerified,
      caqhUsername: values.caqhUsername,
      caqhPassword: values.caqhPassword,
      caqhAttest: values.caqhAttest,
    });
  },
  getFormMeta: ({ providerQuestionnaire }) => {
    const validationSchema = Yup.object().shape({
      caqhAuthorization: Yup.string()
        .oneOf([YesNo.YES], 'You must read and agree to the statement above.')
        .required('This question is required.'),
      caqhVerified: Yup.boolean(),
      caqhNumberFromNpi: Yup.string().nullable(),
      caqhAttest: Yup.boolean().when(
        ['caqhHasProfile', 'caqhNumberFromNpi'],
        (caqhHasProfile, caqhNumberFromNpi, schema) =>
          caqhHasProfile === YesNo.YES || !!caqhNumberFromNpi
            ? schema.oneOf(
                [true],
                'You must read and agree to the statement above.'
              )
            : schema
      ),
    });
    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    };
  },
};

export default stepConfig;

export const CaqhEmptyCredentialsError = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>Please enter your CAQH username and password.</BodyText>
  </GuidanceCard>
);

export const CaqhAuthorizationLoginError = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>
      We weren't able to verify that username and password combination with
      CAQH. Please make sure they are correct.
    </BodyText>
  </GuidanceCard>
);

export const CaqhAuthorizationMismatch = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>
      This CAQH number is not associated with the NPI you shared. Please try
      again.
    </BodyText>
  </GuidanceCard>
);

export const CaqhAuthorizationRequiresAttestation = () => (
  <GuidanceCard variant="error" layout="vertical">
    <b>CAQH Re-Attestation Needed</b>
    <BodyText>
      It looks like you haven’t re-attested your CAQH profile. To do so, click
      the Review & Attest button at the top right of your profile.
    </BodyText>
    <BodyText>
      Note: If you have recently created and completed your profile for the
      first time, you may have to wait 48 hours before continuing.
    </BodyText>
  </GuidanceCard>
);

export const CaqhAuthorizationRequiresAuthorization = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>
      <b>Authorize access on CAQH to link your account</b>
    </BodyText>
    <BodyText>
      It looks like you haven’t authorized the release of your CAQH ProView
      data. Approve Headway’s access request on your CAQH profile by navigating
      to <b>Organizations {'>'} Authorize</b>. Then, wait a few minutes and
      submit your information on Headway again. You may need to upload any
      missing documents in the Documents tab in CAQH.
    </BodyText>
  </GuidanceCard>
);

export const CaqhAuthorizationServerError = () => (
  <GuidanceCard variant="error" layout="vertical">
    <BodyText>
      <em>
        We're experiencing an error due to high volume. You may need to retry
        1-2 times to submit.
      </em>
    </BodyText>
  </GuidanceCard>
);
