import * as React from 'react';
import type { SVGProps } from 'react';

export const Amex = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 16"
    {...props}
  >
    <g clipPath="url(#clip0_93_1037)">
      <path
        d="M22 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H22C23.1046 16 24 15.1046 24 14V2C24 0.89543 23.1046 0 22 0Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7641 13.3939V7.6925L23.9116 7.70161V9.27651L22.7387 10.5299L23.9116 11.7948V13.4031H22.039L21.0438 12.3049L20.0557 13.4072L13.7641 13.3939Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4418 12.7688V8.31995H18.2141V9.34483H15.6632V10.0405H18.1533V11.0483H15.6632V11.7317H18.2141V12.7688H14.4418Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1953 12.7687L20.2826 10.5417L18.1952 8.31995H19.8108L21.0863 9.72998L22.3654 8.31995H23.9116V8.35495L21.8687 10.5417L23.9116 12.7056V12.7687H22.3499L21.0518 11.3446L19.767 12.7687H18.1953Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2374 2.63196H16.6834L17.5426 4.58281V2.63196H20.5624L21.0832 4.09353L21.6057 2.63196H23.9116V8.33335H11.7251L14.2374 2.63196Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7005 3.25134L12.7264 7.69651H14.0803L14.4528 6.80635H16.4707L16.8429 7.69651H18.2304L16.2647 3.25134H14.7005ZM14.8701 5.80878L15.4621 4.39371L16.0536 5.80878H14.8701Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.212 7.69569V3.25061L20.1151 3.25715L21.0944 5.98988L22.08 3.25061H23.9116V7.69569L22.733 7.70612V4.65278L21.6204 7.69569H20.5446L19.409 4.64235V7.69569H18.212Z"
        fill="#016FD0"
      />
    </g>
    <defs>
      <clipPath id="clip0_93_1037">
        <rect width={24} height={16} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
