import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

import { LinkButton } from '@headway/helix/LinkButton';

import { Task } from './Task';

interface StripeRequirementsTaskProps {
  stripeOnboardingLink: string | undefined;
}

export const StripeRequirementsTask = ({
  stripeOnboardingLink,
}: StripeRequirementsTaskProps) => {
  return (
    <>
      <Task
        icon={<ErrorOutlineIcon />}
        listHeaderText={<>Complete your Stripe account setup</>}
        subBodyText="Complete your Stripe account setup to receive automatic payments for sessions."
        secondaryAction={<></>}
        primaryAction={
          <LinkButton
            href={stripeOnboardingLink}
            variant="primary"
            size="medium"
          >
            Complete on Stripe↗
          </LinkButton>
        }
      />
    </>
  );
};
