import { ProviderLicenseStateRead } from '../../api/src/models/ProviderLicenseStateRead';
import { ProviderRead } from '../../api/src/models/ProviderRead';
import { UnitedStates } from '../../api/src/models/UnitedStates';
import UnitedStatesDisplayNames from '../constants/unitedStatesDisplayNames';

export const isExistingProvider = (provider: ProviderRead) =>
  !!provider.activeProviderLicenseStates.find((pls) => pls.liveOn);

export const getSupportedStates = (provider: ProviderRead) => {
  let states = provider.activeProviderLicenseStates.map((pls) => pls.state);
  states.sort();
  return states;
};

export const isProviderInState = (
  provider: ProviderRead,
  state: UnitedStates
) => {
  return getSupportedStates(provider).includes(state);
};

export const isProviderInAnyStates = (
  provider: ProviderRead,
  states: UnitedStates[]
) => {
  return getSupportedStates(provider).some((supportedState) =>
    states.includes(supportedState)
  );
};

export const getProviderLiveProviderLicenseStates = (
  provider: ProviderRead
): ProviderLicenseStateRead[] => {
  return provider.activeProviderLicenseStates.filter(
    (pls) => !!pls.liveOn && new Date(pls.liveOn) <= new Date()
  );
};

export const getProviderLiveStates = (provider: ProviderRead) => {
  let states = getProviderLiveProviderLicenseStates(provider).map(
    (pls) => pls.state
  );

  states.sort();
  return states;
};

export const getStatesDisplayNames = (states: UnitedStates[]) => {
  const sortedStates = [...states].sort();
  return sortedStates
    .map((state) => UnitedStatesDisplayNames[state])
    .join(', ');
};

export const isProviderLiveInState = (
  providerLicenseState: ProviderLicenseStateRead
) => {
  return (
    !!providerLicenseState.liveOn &&
    providerLicenseState.isActive &&
    new Date(providerLicenseState.liveOn) <= new Date()
  );
};
