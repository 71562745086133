import React from 'react';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';

import { TemplateAnalyticsNames } from '../ProgressNotesForm';
import {
  getInitialTemplateValues as getInitialTemplateValuesV1,
  Renderer as V1,
} from './schemaRenderer/versions/1/renderer';
import { TemplateV1 } from './schemaRenderer/versions/1/types';
import {
  getInitialTemplateValues as getInitialTemplateValuesV2,
  Renderer as V2,
} from './schemaRenderer/versions/2/renderer';
import { TemplateV2 } from './schemaRenderer/versions/2/types';
import {
  getInitialTemplateValues as getInitialTemplateValuesV3,
  Renderer as V3,
} from './schemaRenderer/versions/3/renderer';
import {
  GenericTemplate,
  TemplateValues,
} from './schemaRenderer/versions/types';

export type TemplateProps = {
  template: GenericTemplate<any>;
  disabled?: boolean;
  event?: ProviderEventRead;
  patient: UserRead;
  providerPatient: ProviderPatientRead;
  sendTemplateAnalytics: (trackingEventName: TemplateAnalyticsNames) => void;
};

export function Template<T>({
  template,
  disabled,
  event,
  patient,
  providerPatient,
  sendTemplateAnalytics,
}: TemplateProps) {
  const schemaVersion = template.templateInfo.schemaVersion ?? 1;
  switch (schemaVersion) {
    case 1:
      return <V1<T> template={template?.template} disabled={disabled} />;
    case 2:
      return <V2<T> template={template?.template} disabled={disabled} />;
    case 3:
      return (
        <V3<T>
          template={template?.template}
          disabled={disabled}
          event={event}
          patient={patient}
          providerPatient={providerPatient}
          sendTemplateAnalytics={sendTemplateAnalytics}
        />
      );
    default:
      return <></>;
  }
}

export function getInitialTemplateValues<T>(
  template: GenericTemplate<unknown>,
  values?: TemplateValues
) {
  const schemaVersion = template.templateInfo.schemaVersion ?? 1;

  switch (schemaVersion) {
    case 1:
      const v1Template = template as GenericTemplate<TemplateV1<T>>;
      return getInitialTemplateValuesV1(v1Template.template, values);
    case 2:
      const v2Template = template as GenericTemplate<TemplateV2<T>>;
      return getInitialTemplateValuesV2(v2Template.template, values);
    case 3:
      const v3Template = template as GenericTemplate<TemplateV2<T>>;
      return getInitialTemplateValuesV3(v3Template.template, values);
  }
}
