import { MessageCreate } from '@headway/api/models/MessageCreate';
import { MessageRead } from '@headway/api/models/MessageRead';
import { MessageApi } from '@headway/api/resources/MessageApi';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export interface CreateMessageMutationArgs {
  providerId: number;
  patientId: number;
  message: MessageCreate;
}

export const useCreateMessageMutation = (
  options: UseMutationWithSideEffectsOptions<
    MessageRead,
    unknown,
    CreateMessageMutationArgs
  > = {}
) => {
  return useMutationWithSideEffects(
    ({ providerId, patientId, message }: CreateMessageMutationArgs) =>
      MessageApi.createMessage(providerId, patientId, message),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        MessageRead,
        unknown,
        CreateMessageMutationArgs
      >()
        .addErrorLogging(() => 'There was a problem sending the message')
        .merge(options.sideEffects),
    }
  );
};
