import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderHasSentAssessmentsQueryKeyArgs {
  providerId: number | undefined;
}

export const getUseProviderHasSentAssessmentsQueryKey = ({
  providerId,
}: UseProviderHasSentAssessmentsQueryKeyArgs) =>
  ['provider-has-sent-patient-assessments', providerId] as const;

const {
  useSingleQuery: useProviderHasSentAssessments,
  useCachedQuery: useProviderHasSentAssessmentsCache,
} = createBasicApiHooks(
  getUseProviderHasSentAssessmentsQueryKey,
  ({ providerId }) =>
    PatientAssessmentApi.getProviderHasSentPatientAssessments(providerId!),
  ({ providerId }) => providerId != null,
  () => 'Failed to get provider has sent assessments status'
);

export { useProviderHasSentAssessments, useProviderHasSentAssessmentsCache };
