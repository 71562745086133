import React from 'react';

import { theme } from '@headway/helix/theme';

export const SectionContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div
    css={{
      ...theme.stack.vertical,
      gap: theme.spacing.x6,
      marginTop: theme.spacing.x4,
      borderTop: `1px solid ${theme.color.system.borderGray}`,
      paddingTop: theme.spacing.x6,
    }}
  >
    {children}
  </div>
);
