import * as React from 'react';

export const ListNumbered = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.583 16.604v-.687h1.73v-.542h-.875v-.708h.874v-.521H2.583v-.709H5v3.167H2.583zm4.334-1.041v-1.188h10.52v1.188H6.918zm-4.334-3.98v-.666l1.542-1.813H2.583v-.687H5v.646l-1.563 1.833H5v.687H2.583zm4.334-1.041V9.354h10.52v1.188H6.918zm-3.48-3.938v-2.52h-.854v-.709h1.563v3.23h-.708zm3.48-1.083V4.333h10.52v1.188H6.918z"
      fill="currentColor"
    />
  </svg>
);
