import { ReactNode } from 'react';

import { theme } from './theme';

export interface CaptionTextProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'red' | 'green';
}

const COLOR_STYLE_MAP: Record<
  NonNullable<CaptionTextProps['color']>,
  string
> = {
  textBlack: theme.color.system.textBlack,
  gray: theme.color.system.gray,
  red: theme.color.primary.red,
  green: theme.color.system.green,
};

export const CaptionText = ({
  children,
  color = 'textBlack',
}: CaptionTextProps) => {
  return (
    <span
      className="hlx-typography-caption"
      style={{ color: COLOR_STYLE_MAP[color] }}
    >
      {children}
    </span>
  );
};
