import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UnreadAssessment } from '@headway/api/models/UnreadAssessment';
import { UnreadAssessmentSummary } from '@headway/api/models/UnreadAssessmentSummary';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconAssignment } from '@headway/helix/icons/Assignment';
import { Link } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import type { UseMutateFunction } from '@headway/shared/react-query';
import { trackPageView } from '@headway/shared/utils/analytics';

import { usePaginatedUnreadAssessmentsPerPatient } from 'hooks/usePaginatedUnreadAssessmentsPerPatient';
import { useProvider } from 'hooks/useProvider';
import {
  DismissUnreadAssessmentSummaryArgs,
  useDismissUnreadAssessmentSummaryMutation,
} from 'mutations/assessments';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';

import {
  getAssessmentInsights,
  latestCompletionDateAmongUnreadAssessments,
} from '../Clients/Assessments/helpers/utils';
import { Task } from './Task';

const ASSESSMENTS_MODULE_ID = 'assessments-module';
// Number of list items visible before clicking 'Show more'.
const INITIAL_VISIBLE_ITEMS = 3;

export const AssessmentsCard = () => {
  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpooferWithoutPermission =
    isSpoofing &&
    !isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles);
  const [sentTrackingEvent, setSentTrackingEvent] = useState<boolean>(false);
  const [scrolledToAssessments, setScrolledToAssessments] =
    useState<boolean>(false);
  const [showingMore, setShowingMore] = useState<boolean>(false);
  const provider = useProvider();

  const {
    data: unreadAssessmentSummaries,
    isFetchedAfterMount: assessmentsFetchedAfterMount,
    isPreviousData,
  } = usePaginatedUnreadAssessmentsPerPatient(
    {
      providerId: provider.id,
      // Fetching one additional item makes the Dismiss action more responsive.
      limit: showingMore ? undefined : INITIAL_VISIBLE_ITEMS + 1,
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !isSpooferWithoutPermission,
    }
  );

  const { mutate: updateProviderPatientAssessmentsLastViewed } =
    useDismissUnreadAssessmentSummaryMutation([
      { providerId: provider.id, limit: INITIAL_VISIBLE_ITEMS + 1 },
      { providerId: provider.id },
    ]);

  useEffect(() => {
    if (
      assessmentsFetchedAfterMount &&
      !sentTrackingEvent &&
      unreadAssessmentSummaries?.data &&
      unreadAssessmentSummaries.data.length > 0
    ) {
      const assessmentIdList = unreadAssessmentSummaries.data
        .flatMap((summary) =>
          summary.unreadAssessments.map((assessment) => assessment.id)
        )
        .sort();
      trackPageView({
        name: 'Launchpad Client Assessment Section Viewed',
        properties: {
          providerId: provider.id,
          assessmentIdList,
        },
      });
      setSentTrackingEvent(true);
    }
  }, [
    assessmentsFetchedAfterMount,
    unreadAssessmentSummaries,
    provider.id,
    sentTrackingEvent,
  ]);

  const hash = window.location.hash.substring(1);

  useEffect(() => {
    if (
      assessmentsFetchedAfterMount &&
      !scrolledToAssessments &&
      unreadAssessmentSummaries?.data &&
      unreadAssessmentSummaries.data.length > 0
    ) {
      if (hash === 'assessments') {
        // using a timeout here to give some of the other launchpad elements time to load -
        // if we scroll immediately, the window jumps around as content loads
        setTimeout(() => {
          const element = document.getElementById(ASSESSMENTS_MODULE_ID);
          if (element) {
            element.scrollIntoView({
              block: 'start',
              inline: 'center',
              behavior: 'smooth',
            });
          }
        }, 500);
      }
      setScrolledToAssessments(true);
    }
  }, [
    assessmentsFetchedAfterMount,
    unreadAssessmentSummaries,
    provider.id,
    scrolledToAssessments,
    hash,
  ]);

  if (isSpooferWithoutPermission) {
    return (
      <PageSection>
        <GuidanceCard variant="neutral">
          You do not have permission to view Assessments
        </GuidanceCard>
      </PageSection>
    );
  }

  if (!unreadAssessmentSummaries) {
    return null;
  }

  const shouldAllowShowingMore =
    unreadAssessmentSummaries.totalCount > INITIAL_VISIBLE_ITEMS;

  const providerId = provider.id;

  const showEmptyState = unreadAssessmentSummaries?.totalCount === 0;
  const showSummaries = !showEmptyState;

  return (
    <PageSection>
      <div
        id={ASSESSMENTS_MODULE_ID}
        css={{
          scrollMarginTop: 55,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing.x5,
          }}
        >
          <SectionHeader>Assessments</SectionHeader>
          {shouldAllowShowingMore &&
            (isPreviousData ? (
              <CircularProgress size={22} />
            ) : (
              <Button
                variant="link"
                onPress={() => setShowingMore((current) => !current)}
              >
                {showingMore ? 'Show less' : 'Show more'}
              </Button>
            ))}
        </div>
        {showEmptyState && <EmptyState />}
        {showSummaries && (
          <SummaryList
            summaries={
              showingMore && !isPreviousData
                ? unreadAssessmentSummaries.data
                : unreadAssessmentSummaries.data.slice(0, INITIAL_VISIBLE_ITEMS)
            }
            providerId={providerId}
            updateProviderPatientAssessmentsLastViewed={
              updateProviderPatientAssessmentsLastViewed
            }
          />
        )}
      </div>
    </PageSection>
  );
};

const EmptyState = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing.x4,
      }}
    >
      <div>
        <AssignmentTurnedInOutlinedIcon
          css={{
            margin: theme.spacing.x4,
            backgroundColor: theme.color.hue.lightGreen,
            padding: theme.spacing.x3,
            borderRadius: '50%',
            fontSize: '50px',
          }}
          color="primary"
        />
      </div>
      <SectionHeader>No assessments to review</SectionHeader>
      <span
        css={{ padding: theme.spacing.x2, color: theme.color.system.textBlack }}
      >
        You’re all caught up!
      </span>
    </div>
  );
};

interface SummaryListProps {
  summaries: UnreadAssessmentSummary[];
  providerId: number;
  updateProviderPatientAssessmentsLastViewed: UseMutateFunction<
    ProviderPatientRead,
    unknown,
    DismissUnreadAssessmentSummaryArgs,
    any[]
  >;
}
const SummaryList = ({
  summaries,
  providerId,
  updateProviderPatientAssessmentsLastViewed,
}: SummaryListProps) => {
  return (
    <ul>
      {summaries.map((summary) => (
        <SummaryView
          key={summary.providerPatientId}
          summary={summary}
          providerId={providerId}
          updateProviderPatientAssessmentsLastViewed={
            updateProviderPatientAssessmentsLastViewed
          }
        />
      ))}
    </ul>
  );
};

interface InsightBadgesProps {
  assessments: UnreadAssessment[];
}
const InsightBadges = ({ assessments }: InsightBadgesProps) => {
  const insights = getAssessmentInsights(assessments);
  return (
    <>
      {insights.map((insight) => (
        <span key={insight.key} css={{ paddingLeft: theme.spacing.x2 }}>
          <Badge variant={insight.badgeVariant}>{insight.badgeText}</Badge>
        </span>
      ))}
    </>
  );
};

interface SummaryViewProps {
  summary: UnreadAssessmentSummary;
  providerId: number;
  updateProviderPatientAssessmentsLastViewed: UseMutateFunction<
    ProviderPatientRead,
    unknown,
    DismissUnreadAssessmentSummaryArgs,
    any[]
  >;
}
const SummaryView = ({
  summary,
  providerId,
  updateProviderPatientAssessmentsLastViewed,
}: SummaryViewProps) => {
  const { patientFirstName, patientId, unreadAssessments, providerPatientId } =
    summary;
  const completedDate =
    latestCompletionDateAmongUnreadAssessments(unreadAssessments);
  return (
    <Task
      key={`${patientId}-assessment-summary`}
      icon={<IconAssignment />}
      listHeaderText={
        <>
          Review{' '}
          <Link
            to={`/clients/${patientId}/assessments`}
            component={RouterLink}
            elementType="a"
          >
            {patientFirstName}'s
          </Link>{' '}
          scores
        </>
      }
      subBodyText={`Results from ${completedDate}`}
      primaryAction={
        <LinkButton
          component={RouterLink}
          elementType="a"
          variant="primary"
          size="medium"
          to={`/clients/${patientId}/assessments`}
        >
          Review results
        </LinkButton>
      }
      secondaryAction={
        <LinkButton
          variant="secondary"
          size="medium"
          onClick={() => {
            updateProviderPatientAssessmentsLastViewed({
              providerPatientId,
              providerId,
            });
          }}
        >
          Dismiss
        </LinkButton>
      }
      childrenVerticalSpacing={`${theme.spacing.x2} 0`}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: theme.spacing.x12,
        }}
      >
        <InsightBadges assessments={unreadAssessments} />
      </div>
    </Task>
  );
};
