import { Close, HelpCenterOutlined } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import React, { ReactNode } from 'react';

import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { theme } from '@headway/helix/theme';

export const PanelabilityIconHeaderFromStatus = ({
  status,
}: {
  status: PanelabilityStatus;
}) => {
  switch (status) {
    case PanelabilityStatus.NOT_PANELABLE:
      return (
        <PanelabilityIconHeader
          icon={
            <Close
              sx={{
                width: '20px',
                height: '20px',
                fill: theme.color.primary.red,
              }}
            />
          }
          sectionTitleColor={theme.color.primary.red}
          sectionTitle={`You are not eligible for the following plans.`}
        />
      );
    case PanelabilityStatus.MAYBE_PANELABLE:
      return (
        <PanelabilityIconHeader
          icon={
            <HelpCenterOutlined
              sx={{
                width: '20px',
                height: '20px',
                fill: theme.color.system.gray,
              }}
            />
          }
          sectionTitleColor={theme.color.system.gray}
          sectionTitle={`We still need to verify your eligibility for the following plans. We’ll follow up when we have an update.`}
        />
      );
    case PanelabilityStatus.PANELABLE:
      return (
        <PanelabilityIconHeader
          icon={
            <CheckIcon
              sx={{
                width: '20px',
                height: '20px',
                fill: '#128756',
              }}
            />
          }
          sectionTitleColor={'#128756'}
          sectionTitle={'We have verified your eligibility for these plans.'}
        />
      );
  }
};

const PanelabilityIconHeader = ({
  icon,
  sectionTitleColor,
  sectionTitle,
}: {
  icon: ReactNode;
  sectionTitleColor: string;
  sectionTitle: string;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing.x2,
        alignItems: 'flex-start',
      }}
    >
      {icon}
      <span
        className="hlx-typography-body"
        style={{ color: sectionTitleColor }}
      >
        {sectionTitle}
      </span>
    </div>
  );
};
