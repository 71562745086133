import { useField } from 'formik';
import { useContext } from 'react';
import { AppointmentContext } from '~/legacy/views/Calendar/components/AppointmentConfirmation/stores/AppointmentContext';

import { isComponentRequired } from '../../../utils';
import { ComponentMap } from '../../components';
import { GenericTemplate } from '../types';
import { Component, ProgressNoteComponentMetadata, TemplateV3 } from './types';

type TemplateRenderedComponentProps<T> = {
  component: Component<T>;
  disabled?: boolean;
  template?: GenericTemplate<TemplateV3<T>>['template'];
};

const doesEnforcePrefillUniqueness = (
  componentType: keyof typeof ComponentMap
) =>
  componentType === 'shortFreeText' ||
  componentType === 'longFreeText' ||
  componentType === 'richFreeText';

export function TemplateRenderedComponent<T>({
  component,
  template,
  disabled,
}: TemplateRenderedComponentProps<T>) {
  const { selectedCptCodes } = useContext(AppointmentContext);

  const metadata = component.metadata as
    | ProgressNoteComponentMetadata
    | undefined;

  const isOptional = !isComponentRequired(selectedCptCodes, metadata);

  const { prefillFromLastSession, requirePrefillUniqueness = true } =
    metadata ?? {};

  const { id, type: componentType } = component;

  const [, { value, initialValue }] = useField(id);
  const [{ value: previousNote }] = useField('previousNote');

  const requiresEdit =
    previousNote !== null &&
    prefillFromLastSession &&
    requirePrefillUniqueness &&
    doesEnforcePrefillUniqueness(componentType) &&
    value === initialValue &&
    !(isOptional && !value);

  const Component = ComponentMap[componentType];

  return (
    <Component
      isOptional={isOptional}
      template={template}
      disabled={disabled}
      element={component}
      requiresEdit={requiresEdit}
    />
  );
}
