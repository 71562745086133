import throttle from 'lodash/throttle';

import { toasts } from '@headway/helix/Toast';

export const throttledNotifyOffline = throttle(
  () => {
    toasts.add('No network connection detected', { variant: 'negative' });
  },
  20000,
  { trailing: false }
);
