import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';

import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';

export const ProfileStatusAlert = () => {
  const { data } = useOnboardingModuleStatuses();
  const profileModule = data?.find((d) => d.module === 'PROFILE');

  if (profileModule?.isComplete) {
    return null;
  }

  return (
    <GuidanceCard variant="info">
      <BodyText>
        Complete your profile to get more referrals. If you need inspiration,{' '}
        <Link
          href="https://findheadway.zendesk.com/hc/en-us/articles/360061931931-Optimizing-profile-for-referrals"
          target="_blank"
          rel="noopener noreferrer"
        >
          check out our tips for a great profile.
        </Link>
      </BodyText>
    </GuidanceCard>
  );
};
