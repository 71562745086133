/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum UserUploadTypes {
    PROVIDER_UPLOADS = 'PROVIDER_UPLOADS',
    PROVIDER_UPLOADS_PUBLIC = 'PROVIDER_UPLOADS_PUBLIC',
    PROVIDER_PHOTOS = 'PROVIDER_PHOTOS',
    PROVIDER_QUESTIONNAIRE_UPLOADS = 'PROVIDER_QUESTIONNAIRE_UPLOADS',
    PROVIDER_PAYMENTS_DOWNLOADS = 'PROVIDER_PAYMENTS_DOWNLOADS',
    USER_INSURANCE_CARD_PHOTOS = 'USER_INSURANCE_CARD_PHOTOS'
}
