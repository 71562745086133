import React from 'react';

export function IconShield(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M23.5598 3.1056C16.3887 -1.0352 7.48835 -1.0352 0.317303 3.1056L0 3.29598V3.67675C0 11.9584 4.45811 19.6688 11.6292 23.8096L11.9465 24L12.2638 23.8096C19.4348 19.6688 23.8929 11.9584 23.8929 3.67675V3.29598L23.5756 3.1056H23.5598ZM11.9306 4.66039C12.2797 4.66039 12.5811 4.94596 12.5811 5.31086V9.54685H16.8171C17.1661 9.54685 17.4676 9.83242 17.4676 10.1973C17.4676 10.5622 17.182 10.8478 16.8171 10.8478H12.5811V15.0838C12.5811 15.4328 12.2955 15.7343 11.9306 15.7343C11.5657 15.7343 11.2802 15.4487 11.2802 15.0838V10.8478H7.04413C6.69509 10.8478 6.39365 10.5622 6.39365 10.1973C6.39365 9.83242 6.67923 9.54685 7.04413 9.54685H11.2802V5.31086C11.2802 4.94596 11.5657 4.66039 11.9306 4.66039Z"
        fill="currentColor"
      />
    </svg>
  );
}
