import { AriaButtonProps } from '@react-types/button';
import React, { forwardRef } from 'react';
import { mergeProps, useButton } from 'react-aria';
import { Simplify } from 'type-fest';

import { DATA } from './consts';
import { InteractionProps, useInteraction } from './useInteraction';
import { mergeRefs } from './utils';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type ButtonProps = Simplify<
  {
    /** The visual size of the button */
    size?: 'medium' | 'large';

    /** The style variant of the button */
    variant?: 'brand' | 'primary' | 'secondary' | 'link';

    /** The id of the HTML form that the button controls. Should be used with type="submit" */
    form?: string;
  } & InteractionProps &
    PickAndConfigure<
      AriaButtonProps<'button'>,
      'id' | 'type' | 'onPress' | { children: 'children' }
    >
>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'large',
      variant = 'primary',
      disabled: isDisabled = false,
      ...props
    },
    ref
  ) => {
    const { ariaProps, hoverProps, focusProps, isHovered, isFocusVisible } =
      useInteraction(props);
    const { buttonProps, isPressed } = useButton(
      { ...ariaProps, isDisabled },
      ref as React.RefObject<HTMLButtonElement>
    );
    const localRef = React.useRef<HTMLButtonElement>(null);

    // https://github.com/adobe/react-spectrum/issues/1279#issuecomment-1472634908
    React.useEffect(() => {
      let target = localRef.current;

      if (!target || target.type === 'submit') {
        return;
      }

      let onTouchStart = (e: TouchEvent) => {
        e.preventDefault();
      };
      target.addEventListener('touchstart', onTouchStart);
      return () => {
        target?.removeEventListener('touchstart', onTouchStart);
      };
    }, []);

    return (
      <button
        className="hlx-button"
        type="button"
        {...mergeProps(buttonProps, focusProps, hoverProps, {
          [DATA.DISABLED]: isDisabled,
          [DATA.HOVERED]: isHovered,
          [DATA.FOCUSED]: isFocusVisible,
          'data-hlx-pressed': isPressed,
          'data-hlx-variant': variant,
          'data-hlx-size': size,
        })}
        form={props.form}
        ref={mergeRefs([localRef, ref])}
      >
        {props.children}
      </button>
    );
  }
);

export { Button };
