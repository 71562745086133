import { UserRead } from '@headway/api/models/UserRead';
import { ZendeskTicketRead } from '@headway/api/models/ZendeskTicketRead';
import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { useQuery } from '@headway/shared/react-query';

export const useExistingVerificationRequest = (patient: UserRead) =>
  useQuery(
    ['has-existing-verification-request', patient?.activeUserInsuranceId],
    async () => {
      if (typeof patient.activeUserInsuranceId === 'undefined') {
        throw new Error('No active insurance');
      }

      const res = await UserInsuranceApi.getExistingZendeskVerificationRequests(
        patient.activeUserInsuranceId
      );

      if (res.tickets && res.count && res.count > 0) {
        const nonSolvedAndNonClosedTickets = res.tickets.filter(
          (ticket: ZendeskTicketRead) =>
            ticket.status !== 'solved' && ticket.status !== 'closed'
        );
        return nonSolvedAndNonClosedTickets.length > 0;
      }

      return false;
    },
    {
      enabled: !!patient?.activeUserInsuranceId,
    }
  );
