import { FormLabel } from '@mui/material';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { DegreeTypes } from '@headway/api/models/DegreeTypes';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  hasPhysicianLicenseType,
  hasPrescriberLicenseType,
} from '@headway/shared/utils/licenseHelper';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  FieldControl,
  FieldDatePicker,
  FieldDropzone,
  FieldErrorText,
} from '@headway/ui/form';

import { statesThatRequireCertificateAwarded } from 'views/IntakeQuestionnaireV2/utils/intakeQuestionnaireConstants';

import { onDropFiles, YesNo } from '../../../../utils/providerQuestionnaire';
import { OnBeforeSubmitError } from '../../OnBeforeSubmitErrorCard';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { transformDateToNullOrStartOfDay } from '../../utils/transformDateToNullOrStartOfDay';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { EducationHeader } from './EducationHeader';

enum DegreeLevels {
  Undergraduate = 'Undergraduate',
  Masters = 'Masters',
  Doctorate = 'Doctorate',
}

const minDate = moment('1900-01-01');

const EducationLocation = {
  US_CANADA: 'US_CANADA',
  OTHER: 'OTHER',
};

const statesThatRequireCulturalCompetancyTrainingDate = [UnitedStates.ILLINOIS];
const statesThatRequireEcfmgUrl = [UnitedStates.LOUISIANA];

export const Education: React.FC<
  React.PropsWithChildren<CustomComponentProps>
> = ({ initialValues, formikHelpers }) => {
  const { provider } = useQuestionnaireContext();
  return (
    <>
      <EducationHeader initialValues={initialValues} />
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: theme.spacing.x4,
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: theme.spacing.x4,
        }}
      >
        {formikHelpers.values.education?.map((school, idx) => {
          const schoolDegreeIsOther = school.degreeType === DegreeTypes.OTHER;

          return (
            <div
              css={{
                ...theme.stack.vertical,
                gap: theme.spacing.x6,
                marginTop: theme.spacing.x4,
              }}
            >
              <div
                css={{
                  ...theme.stack.vertical,
                  gap: theme.spacing.x1,
                  width: '60%',
                }}
              >
                <SectionHeader>{school.institutionName}</SectionHeader>
              </div>
              <FormControl
                name={`education[${idx}].programName`}
                label="Program Name"
                component={TextField}
              />
              <FormControl
                name={`education[${idx}].departmentName`}
                label="Department Name"
                component={TextField}
              />
              {schoolDegreeIsOther && (
                <>
                  <FormControl
                    component={Select}
                    name={`education[${idx}].degreeTypeOtherLevel`}
                    label="Degree Level"
                    selectionMode="single"
                    menuWidth="stretch"
                  >
                    <Item key={DegreeLevels.Undergraduate}>
                      {DegreeLevels.Undergraduate}
                    </Item>
                    <Item key={DegreeLevels.Masters}>
                      {DegreeLevels.Masters}
                    </Item>
                    <Item key={DegreeLevels.Doctorate}>
                      {DegreeLevels.Doctorate}
                    </Item>
                  </FormControl>
                  <FormControl
                    name={`education[${idx}].degreeTypeOther`}
                    label="Other Degree Type"
                    component={TextField}
                  />
                </>
              )}
              <FormControl
                name={`education[${idx}].areaOfTraining`}
                component={TextField}
                label="Area of Training/Major"
              />
              {isProviderInAnyStates(
                provider,
                statesThatRequireCertificateAwarded
              ) && (
                <FormControl
                  name={`education[${idx}].certificateAwarded`}
                  component={TextField}
                  label="Certificate Awarded"
                />
              )}
              {school.location === EducationLocation.OTHER &&
              school.degreeType === DegreeTypes.MD ? (
                <>
                  <FormControl
                    name={`education[${idx}].ECFMGNumber`}
                    label="Educational Commission for Foreign Medical Graduates (ECFMG) number"
                    component={TextField}
                  />
                  <FieldControl
                    name={`education[${idx}].dateECFMGIssued`}
                    fullWidth={true}
                  >
                    <FieldDatePicker
                      label="Date ECFMG issued"
                      inputFormat="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      variant="outlined"
                      size="small"
                    />
                    <FieldErrorText />
                  </FieldControl>
                  {isProviderInAnyStates(
                    provider,
                    statesThatRequireEcfmgUrl
                  ) && (
                    <FieldControl
                      name={`education[${idx}].ecfmgUrl`}
                      fullWidth={true}
                    >
                      <FormLabel css={{ marginBottom: theme.spacing.x4 }}>
                        Please upload a copy of your resume in{' '}
                        <strong>PDF format</strong>. This is required in order
                        to get you credentialed with payers.
                      </FormLabel>
                      <FieldDropzone
                        accept="application/pdf,image/*"
                        onDrop={(file: any) => onDropFiles(provider.id, file)}
                      />
                      <FieldErrorText />
                    </FieldControl>
                  )}
                </>
              ) : null}
            </div>
          );
        })}

        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x1,
            marginTop: theme.spacing.x4,
            paddingTop: theme.spacing.x8,
            borderTop: `1px solid ${theme.color.system.borderGray}`,
            width: '100%',
          }}
        >
          <SectionHeader>Additional Information</SectionHeader>
        </div>
        <FormControl
          name="hasWithdrawnOrSuspendedFromTraining"
          component={RadioGroup}
          label="Have you voluntarily or involuntarily withdrawn or been
          suspended from any internship, residency or fellowship
          training program?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values.hasWithdrawnOrSuspendedFromTraining ===
          YesNo.YES && (
          <FormControl
            name="trainingWithdrawalExplanation"
            component={TextField}
            label="Please explain."
          />
        )}
        <FormControl
          name="hasCulturalCompetencyTraining"
          component={RadioGroup}
          label="Have you completed cultural competency training?"
        >
          <Radio value={YesNo.YES}>Yes</Radio>
          <Radio value={YesNo.NO}>No</Radio>
        </FormControl>
        {formikHelpers.values.hasCulturalCompetencyTraining === YesNo.YES && (
          <FieldControl
            name="culturalCompetencyCompletionDate"
            fullWidth={true}
          >
            <FieldDatePicker
              label="Cultural Competency Completion Date"
              inputFormat="MM/DD/YYYY"
              placeholder="MM/DD/YYYY"
              variant="outlined"
              size="small"
              maxDate={moment()}
            />
            <FieldErrorText />
          </FieldControl>
        )}
      </div>
    </>
  );
};

const getProgramSchema = (endDateAlwaysRequired: boolean = false) =>
  Yup.object().shape({
    institutionName: Yup.string(),
    street1: Yup.string().required('Address is required.'),
    street2: Yup.string(),
    city: Yup.string().required('City is required.'),
    state: Yup.string(),
    zip: Yup.string(),
    country: Yup.string(),
    startDate: Yup.date()
      .min(minDate.toDate(), 'Date must be after 01/1900.')
      .required('Start date is required.'),
    completedProgram: endDateAlwaysRequired
      ? Yup.string()
      : Yup.string().required('This question is required.'),
    completionDate: Yup.date().when(
      ['completedProgram', 'startDate'],
      (completedProgram: string, startDate: string, schema: Yup.DateSchema) =>
        (completedProgram === YesNo.YES || endDateAlwaysRequired) && !!startDate
          ? schema
              .min(
                startDate,
                `Date must be after ${moment(startDate).format('MM/YYYY')}`
              )
              .required('Completion date is required.')
          : schema
              .nullable()
              .transform((curr, orig) => (orig === '' ? null : curr))
    ),
  });

const stepConfig: QuestionnaireV2Step = {
  title: 'Education',
  description:
    'In this section we ask that you confirm the details about your educational background.',
  Component: Education,
  onBeforeSubmit(
    values,
    setBeforeSubmitError,
    setShowConfirmationModal,
    context
  ) {
    const hasValidEducation = values.education?.some(
      (ed: any) =>
        ed.degreeType !== DegreeTypes.BA &&
        ed.degreeType !== DegreeTypes.BN &&
        ed.degreeTypeOtherLevel !== DegreeLevels.Undergraduate &&
        !!ed.completionDate
    );
    if (hasValidEducation) return true;

    setBeforeSubmitError(OnBeforeSubmitError.EducationRequireMastersOrHigher);
    return false;
  },
  getFormMeta: ({ provider, providerQuestionnaire: { rawData } }) => {
    const validationSchema = Yup.object().shape({
      education: Yup.array().of(
        Yup.object().shape({
          location: Yup.string().required('This question is required.'),
          institutionName: Yup.string().required(
            'Institution name is required.'
          ),
          programName: Yup.string().required('Program name is required.'),
          departmentName: Yup.string().required('Department name is required.'),
          street1: Yup.string().required('Address is required.'),
          street2: Yup.string(),
          city: Yup.string().required('City is required.'),
          state: Yup.string().when(
            'location',
            (location: string, schema: Yup.ObjectSchema) =>
              location !== EducationLocation.OTHER
                ? schema.required('State is required.')
                : schema
          ),
          zip: Yup.string(),
          country: Yup.string(),
          degreeType: Yup.string().required('Degree type is required.'),
          degreeTypeOtherLevel: Yup.string().when(
            'degreeType',
            (degreeType: string, schema: Yup.ObjectSchema) =>
              degreeType === 'Other'
                ? schema.required('Please specify your degree level.')
                : schema
          ),
          degreeTypeOther: Yup.string().when(
            'degreeType',
            (degreeType: string, schema: Yup.ObjectSchema) =>
              degreeType === 'Other'
                ? schema.required('Please specify your degree type.')
                : schema
          ),
          certificateAwarded: isProviderInAnyStates(
            provider,
            statesThatRequireCertificateAwarded
          )
            ? Yup.string().required('Please specify the certificate awarded.')
            : Yup.string(),
          areaOfTraining: Yup.string().required(
            'Please specify your Area of Training or Major.'
          ),
          ECFMGNumber: Yup.string().when(
            ['location', 'degreeType'],
            (location: string, degreeType: string, schema: Yup.ObjectSchema) =>
              location === EducationLocation.OTHER &&
              degreeType === DegreeTypes.MD
                ? schema.required('ECFMG number is required.')
                : schema
          ),
          dateECFMGIssued: Yup.string().when(
            ['location', 'degreeType'],
            (location: string, degreeType: string, schema: Yup.ObjectSchema) =>
              location === EducationLocation.OTHER &&
              degreeType === DegreeTypes.MD
                ? schema.required('Date issued is required.')
                : schema
          ),
          ecfmgUrl: Yup.array()
            .of(
              Yup.object().shape({
                link: Yup.string(),
                name: Yup.string(),
              })
            )
            .when(
              ['location', 'degreeType'],
              (
                location: string,
                degreeType: string,
                schema: Yup.ObjectSchema
              ) =>
                location === EducationLocation.OTHER &&
                degreeType === DegreeTypes.MD &&
                isProviderInAnyStates(provider, statesThatRequireEcfmgUrl)
                  ? schema.required('Please upload a copy of your ECFMG.')
                  : schema
            ),
          startDate: Yup.date()
            .min(minDate.toDate(), 'Date must be after 01/1900.')
            .required('Start date is required.'),
          completedDegree: Yup.string().required('This question is required.'),
          completionDate: Yup.date().when(
            ['completedDegree', 'startDate'],
            (
              completedDegree: string,
              startDate: string,
              schema: Yup.DateSchema
            ) =>
              completedDegree === YesNo.YES && !!startDate
                ? schema
                    .min(
                      startDate,
                      `Date must be after ${moment(startDate).format(
                        'MM/YYYY'
                      )}`
                    )
                    .required('Completion date is required.')
                : schema
                    .nullable()
                    .transform((curr, orig) => (orig === '' ? null : curr))
          ),
          completedUndergradHere: Yup.string().required(
            'This field is required'
          ),
        })
      ),
      hasInternshipExperience: Yup.string().required(
        'This question is required'
      ),
      internships: Yup.array().when(
        'hasInternshipExperience',
        (hasInternshipExperience: string, schema: Yup.ArraySchema<any>) =>
          hasInternshipExperience === YesNo.YES
            ? schema.of(getProgramSchema(true).clone())
            : schema
      ),
      hasResidencyExperience: !hasPhysicianLicenseType(
        rawData?.selectedLicenses
      )
        ? Yup.string().required('This question is required')
        : Yup.string(),

      residencies: Yup.array().when(
        ['hasResidencyExperience'],
        (hasResidencyExperience: string, schema: Yup.ArraySchema<any>) =>
          hasResidencyExperience === YesNo.YES ||
          hasPhysicianLicenseType(rawData?.selectedLicenses)
            ? schema.of(getProgramSchema().clone())
            : schema
      ),
      hasFellowshipTraining: hasPrescriberLicenseType(rawData?.selectedLicenses)
        ? Yup.string().required('This question is required')
        : Yup.string(),

      fellowships: Yup.array().when(
        'hasFellowshipTraining',
        (hasFellowshipTraining: string, schema: Yup.ArraySchema<any>) =>
          hasFellowshipTraining === YesNo.YES
            ? schema.of(getProgramSchema().clone())
            : schema
      ),
      wasPartOfTeachingProgram: Yup.string().required(
        'This question is required'
      ),
      teachingPrograms: Yup.array().when(
        'wasPartOfTeachingProgram',
        (wasPartOfTeachingProgram: string, schema: Yup.ArraySchema<any>) =>
          wasPartOfTeachingProgram === YesNo.YES
            ? schema.of(getProgramSchema().clone())
            : schema
      ),
      hasWithdrawnOrSuspendedFromTraining: Yup.string().required(
        'This question is required.'
      ),
      trainingWithdrawalExplanation: Yup.string().when(
        'hasWithdrawnOrSuspendedFromTraining',
        (
          hasWithdrawnOrSuspendedFromTraining: string,
          schema: Yup.ObjectSchema
        ) =>
          hasWithdrawnOrSuspendedFromTraining === YesNo.YES
            ? schema.required('Please provide an explanation.')
            : schema
      ),
      hasCulturalCompetencyTraining: Yup.string().required(
        'This question is required.'
      ),
      culturalCompetencyCompletionDate: Yup.date()
        .max(new Date(), 'Completion date cannot be in the future.')
        .nullable()
        .transform(transformDateToNullOrStartOfDay)
        .when(
          'hasCulturalCompetencyTraining',
          (hasCulturalCompetencyTraining: string, schema: Yup.ObjectSchema) =>
            hasCulturalCompetencyTraining === YesNo.YES &&
            isProviderInAnyStates(
              provider,
              statesThatRequireCulturalCompetancyTrainingDate
            )
              ? schema.required('This question is required.')
              : schema
        ),
    });
    return {
      validationSchema: validationSchema,
      initialValue: {
        ...yupSchemaToDefaultValue(validationSchema),
        ...rawData,
      },
    } as FormMeta;
  },
};

export default stepConfig;
export const EducationRequireMastersOrHigher = () => {
  return (
    <GuidanceCard variant="error" layout="vertical">
      A masters degree or higher with a completion date is required.
    </GuidanceCard>
  );
};
