import { FormControl, MenuItem, Select } from '@mui/material';
import sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { HeadwayLogo } from '@headway/icons/dist/HeadwayLogo';
import { MANAGE_TEAM_ACCESS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  getProviderDisplayFirstAndLast,
  getProviderDisplayFirstAndLastWithPrenomial,
} from '@headway/shared/utils/providers';
import { theme } from '@headway/ui/theme';

import { useGroupPracticeProviders } from 'hooks/useGroupPracticeProviders';
import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';
import { useSetSelectedProvider } from 'utils/practice';

import { isAdminImpersonatingProviderUser } from '../../utils/access';
import { ManageTeamModal } from './ManageTeamModal';
import { ProviderAvatar } from './ProviderAvatar';

const MANAGE_VALUE = 'MANAGE';

export const ProviderSelect = () => {
  const isManageTeamAccessEnabled = useFlag(MANAGE_TEAM_ACCESS, false);
  const AuthStore = useAuthStore();
  const [isManageTeamModalOpen, setIsManageTeamModalOpen] =
    React.useState(false);
  const selectedProvider = useProvider();
  const setSelectedProvider = useSetSelectedProvider();
  const { data: providers } = useGroupPracticeProviders(
    {
      groupPracticeId: AuthStore.user?.group_practice?.id,
      query: { is_active: true },
    },
    {
      select: (providers) =>
        sortBy(providers, (provider) =>
          getProviderDisplayFirstAndLast(provider)
        ),
    }
  );

  if (providers?.length) {
    return (
      <>
        <FormControl variant="outlined">
          <Select
            value={selectedProvider?.id as number}
            onChange={({ target: { value } }) => {
              if (value === MANAGE_VALUE) {
                setIsManageTeamModalOpen(true);
                return;
              }

              setSelectedProvider(providers.find((p) => p.id === value)!);
            }}
            css={{
              height: '100%',
              width: `calc(200px - 2 * ${theme.space.base})`,
              [theme.media.medium]: {
                width: `calc(260px - 2 * ${theme.space.base})`,
              },
              ' .MuiSelect-select': {
                height: '100%',
                display: 'inline-flex',
                boxShadow: 'none',
                padding: `${theme.space.xs2} 30px ${theme.space.xs2} ${theme.space.base}`,
                fontFamily: theme.fontFamily.postGrotesk,

                fontSize: theme.fontSize.base,
                ':hover': { boxShadow: 'none' },
                alignItems: 'center',
              },
            }}
          >
            {isManageTeamAccessEnabled && (
              <MenuItem value={MANAGE_VALUE}>Manage team</MenuItem>
            )}
            {providers.map((provider: ProviderRead) => (
              <MenuItem key={provider.id} value={provider.id}>
                <ProviderIndicator provider={provider} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ManageTeamModal
          isOpen={isManageTeamModalOpen}
          onDismiss={() => setIsManageTeamModalOpen(false)}
        />
      </>
    );
  }

  return isAdminImpersonatingProviderUser(
    AuthStore?.user,
    AuthStore?.impersonatingUser
  ) ? (
    // if this is a Headway employee, show them the provider's name
    <ProviderIndicator provider={AuthStore.provider} />
  ) : (
    <Link
      to="/"
      css={{
        alignItems: 'center',
        display: 'flex',
        [theme.media.small]: {
          display: 'none',
        },
      }}
    >
      <HeadwayLogo width={125} />
    </Link>
  );
};

interface ProviderIndicatorProps {
  provider: ProviderRead;
}

const ProviderIndicator: React.FC<ProviderIndicatorProps> = ({ provider }) => (
  <div css={{ display: 'flex', alignItems: 'center', gap: theme.space.base }}>
    <div css={{ flexShrink: 0 }}>
      <ProviderAvatar provider={provider} size="small" />
    </div>
    <div
      css={{
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {getProviderDisplayFirstAndLastWithPrenomial(provider)}
    </div>
  </div>
);
