/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum PatientInsuranceOrEAPStatus {
    IN_NETWORK = 'IN_NETWORK',
    IN_NETWORK_PENDING_CREDENTIALING = 'IN_NETWORK_PENDING_CREDENTIALING',
    VIRTUAL_ONLY_NETWORK = 'VIRTUAL_ONLY_NETWORK',
    MATCHING_FAILED_VERIFICATION = 'MATCHING_FAILED_VERIFICATION',
    MATCHING_PENDING_VERIFICATION = 'MATCHING_PENDING_VERIFICATION',
    NO_INSURANCE = 'NO_INSURANCE',
    OUT_OF_NETWORK = 'OUT_OF_NETWORK',
    OUT_OF_NETWORK_NOT_LICENSED = 'OUT_OF_NETWORK_NOT_LICENSED',
    OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE = 'OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE',
    OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE = 'OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE',
    MATCHING_INCOMPLETE = 'MATCHING_INCOMPLETE',
    SECONDARY_INSURANCE = 'SECONDARY_INSURANCE',
    COB_FREEZE = 'COB_FREEZE',
    COB_FREEZE_AWAITING_USER = 'COB_FREEZE_AWAITING_USER',
    OTHER_FREEZE = 'OTHER_FREEZE',
    OON_FREEZE = 'OON_FREEZE',
    TERMED_PLAN_FREEZE = 'TERMED_PLAN_FREEZE',
    WAIVED_SESSION_MAX_HIT_FREEZE = 'WAIVED_SESSION_MAX_HIT_FREEZE',
    NO_DATA_PRELIM_PRICING = 'NO_DATA_PRELIM_PRICING',
    OLD_DATA_PRELIM_PRICING = 'OLD_DATA_PRELIM_PRICING',
    NO_REMAINING_COVERED_SESSIONS = 'NO_REMAINING_COVERED_SESSIONS'
}
