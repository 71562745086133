import moment from 'moment';
import React from 'react';

import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { PropertyList, PropertyListItem } from '@headway/ui';
import { theme } from '@headway/ui/theme';

export const PatientInsurancePropertyList: React.FC<
  React.PropsWithChildren<{
    userInsurance?: UserInsuranceRead;
  }>
> = ({ userInsurance }) => {
  return (
    <PropertyList>
      <PropertyListItem
        label="Carrier"
        value={userInsurance?.billingFrontEndCarrierName}
        data-testid="insuranceCarrierItem"
      />
      <PropertyListItem
        label="Member name"
        value={`${userInsurance?.firstName} ${userInsurance?.lastName}`}
        data-testid="insuranceMemberNameItem"
      />
      <PropertyListItem
        label="Member ID"
        value={userInsurance?.memberId}
        data-testid="insuranceMemberIdItem"
      />
      <PropertyListItem
        label="Group number"
        value={userInsurance?.groupNumber || '-'}
        data-testid="insuranceGroupNumberItem"
      />
      <PropertyListItem
        label="Date of birth"
        data-testid="insuranceDobItem"
        value={
          userInsurance?.dob
            ? moment(userInsurance?.dob).format('M/DD/YYYY')
            : ''
        }
      />
      <p
        css={{
          columnSpan: 'all',
          fontSize: theme.fontSize.sm,
          margin: `${theme.space.xs} 0 0`,
        }}
      >
        Make sure this information matches that of the person being seen, even
        if they’re a minor.
      </p>
    </PropertyList>
  );
};
