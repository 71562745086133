import React from 'react';

export function IconError(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99999 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 9.99999 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 9.99999 18Z"
        fill="currentColor"
      />
      <path
        d="M10.0248 14.5C9.7416 14.5 9.5 14.4 9.3 14.2C9.1 14 9 13.7584 9 13.4752C9 13.1916 9.1 12.9498 9.3 12.7498C9.5 12.5498 9.7416 12.4498 10.0248 12.4498C10.308 12.4498 10.5496 12.5498 10.7496 12.7498C10.9496 12.9498 11.0496 13.1916 11.0496 13.4752C11.0496 13.7584 10.9496 14 10.7496 14.2C10.5496 14.4 10.308 14.5 10.0248 14.5ZM9 11.5V5.5H11.0496V11.5H9Z"
        fill="#fff"
      />
    </svg>
  );
}
