import * as React from 'react';

export const InsuranceStatus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.459 18.334L8.916 14.79l.98-.979 2.563 2.542 4.895-4.916.98 1.02-5.875 5.876zM3.063 9.709h13.874V6.667H3.063v3.042zm-1.397 6.957V3.334h16.668v6.375h-1.209l-4.666 4.707-2.543-2.582-2.958 2.957 1.875 1.875H1.667z"
      fill="#000"
    />
  </svg>
);
