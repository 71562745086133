import moment from 'moment';

import { ProviderQuestionnaireRecredSummary } from '@headway/api/models/ProviderQuestionnaireRecredSummary';

import { getDaysLeftCopy, getDaysUntilDate } from 'utils/date';

export const getDaysUntilTerminationCopy = (
  recredSummary?: ProviderQuestionnaireRecredSummary
) => {
  return getDaysLeftCopy(getDaysUntilTermination(recredSummary));
};

const getDaysUntilTermination = (
  recredSummary?: ProviderQuestionnaireRecredSummary
) => {
  return getDaysUntilDate(moment(recredSummary?.terminationDate));
};

export const isPastRecredDeadlineAndGracePeriod = (
  recredSummary?: ProviderQuestionnaireRecredSummary
) => {
  const tenDaysAgo = moment().startOf('day').subtract(10, 'days');
  return isPastRecredDeadline(recredSummary, tenDaysAgo);
};

export const isPastRecredDeadline = (
  recredSummary?: ProviderQuestionnaireRecredSummary,
  dayMoment?: moment.Moment
) => {
  if (
    !recredSummary ||
    !recredSummary.isEligibleForRecred ||
    recredSummary.isLatestRecredComplete
  ) {
    return false;
  }

  return (dayMoment || moment())
    .startOf('day')
    .isAfter(moment(recredSummary.recredDeadline).startOf('day'));
};
