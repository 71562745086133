/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum FilterType {
    PAST = 'past',
    ETHNICITY = 'ethnicity',
    CREDENTIAL = 'credential',
    GENDER = 'gender',
    AGE = 'age',
    ISSUE = 'issue',
    BOOKABLE_ISSUE = 'bookable_issue',
    INSURANCE = 'insurance',
    FEELING = 'feeling'
}
