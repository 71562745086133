import { Formik } from 'formik';
import mapValues from 'lodash/mapValues';
import React from 'react';
import * as Yup from 'yup';

import {
  WHODAS2Submission,
  WHODAS2SubmissionAssessmentType,
} from '@headway/api/models/WHODAS2Submission';
import { SubBodyText } from '@headway/helix/SubBodyText';

import { SafeFormikForm } from '../form/SafeFormikForm';
import { ErrorScrollListener } from './components/ErrorScrollListener';
import {
  OptionsByQuestionSchema,
  OptionsSchema,
  QuestionsSchema,
  ReadonlyScorableQuestionList,
  ScorableQuestionListForm,
} from './components/ScorableQuestionList';
import { AssessmentFormProps, ReadonlyAssessmentProps } from './types';

const WHODAS_2_OPTIONS: OptionsSchema = [
  {
    displayText: 'None',
    value: 1,
  },
  {
    displayText: 'Mild',
    value: 2,
  },
  {
    displayText: 'Moderate',
    value: 3,
  },
  {
    displayText: 'Severe',
    value: 4,
  },
  {
    displayText: 'Extremely or Cannot do',
    value: 5,
  },
];

const WHODAS_2_QUESTIONS: QuestionsSchema = [
  {
    key: 'WHODAS_2_1',
    questionText: 'Standing for long periods such as 30 minutes?',
  },
  {
    key: 'WHODAS_2_2',
    questionText: 'Taking care of your household responsibilities?',
  },
  {
    key: 'WHODAS_2_3',
    questionText:
      'Learning a new task, for example, learning how to get to a new place?',
  },
  {
    key: 'WHODAS_2_4',
    questionText:
      'How much of a problem did you have joining in community activities (for example, festivities, religious or other activities) in the same way as anyone else can?',
  },
  {
    key: 'WHODAS_2_5',
    questionText:
      'How much have you been emotionally affected by your health problems?',
  },
  {
    key: 'WHODAS_2_6',
    questionText: 'Concentrating on doing something for ten minutes?',
  },
  {
    key: 'WHODAS_2_7',
    questionText:
      'Walking a long distance such as a kilometre [or equivalent]?',
  },
  {
    key: 'WHODAS_2_8',
    questionText: 'Washing your whole body?',
  },
  {
    key: 'WHODAS_2_9',
    questionText: 'Getting dressed?',
  },
  {
    key: 'WHODAS_2_10',
    questionText: 'Dealing with people you do not know?',
  },
  {
    key: 'WHODAS_2_11',
    questionText: 'Maintaining a friendship?',
  },
  {
    key: 'WHODAS_2_12',
    questionText: 'Your day-to-day work?',
  },
];

const INSTRUCTION = (
  <>
    Think back over the past <em>30 days</em> and answer these questions,
    thinking about how much difficulty you had doing the following activities.
    For each question, please select <em>only one response</em>.
  </>
);

const WHODAS_2_VALIDATION_SCHEMA = Yup.object().shape(
  WHODAS_2_QUESTIONS.reduce(
    (acc, { key }) => {
      acc[key] = Yup.string().required('Select an option');
      return acc;
    },
    {} as { [key: string]: Yup.StringSchema }
  )
);

type WHODAS_2FormValues = {
  [key in keyof Omit<WHODAS2Submission, 'assessmentType'>]: string;
};

export const WHODAS_2AssessmentForm = ({
  id,
  onSubmit,
}: AssessmentFormProps) => {
  const innerOnSubmit = (values: WHODAS_2FormValues) => {
    return onSubmit({
      assessmentType: WHODAS2SubmissionAssessmentType.WHODAS2,
      ...mapValues(values, Number),
    });
  };

  return (
    <Formik<WHODAS_2FormValues>
      onSubmit={innerOnSubmit}
      validationSchema={WHODAS_2_VALIDATION_SCHEMA}
      initialValues={{
        WHODAS_2_1: '',
        WHODAS_2_2: '',
        WHODAS_2_3: '',
        WHODAS_2_4: '',
        WHODAS_2_5: '',
        WHODAS_2_6: '',
        WHODAS_2_7: '',
        WHODAS_2_8: '',
        WHODAS_2_9: '',
        WHODAS_2_10: '',
        WHODAS_2_11: '',
        WHODAS_2_12: '',
      }}
    >
      <SafeFormikForm id={id}>
        <ErrorScrollListener />
        <section className="flex flex-col gap-6">
          <b>{INSTRUCTION}</b>
          <ScorableQuestionListForm
            questions={WHODAS_2_QUESTIONS}
            options={WHODAS_2_OPTIONS}
          />
        </section>
      </SafeFormikForm>
    </Formik>
  );
};

export const ReadonlyWHODAS_2Assessment = ({
  response,
}: ReadonlyAssessmentProps) => {
  const scorableResponseJson = response?.scorableResponseJson;
  return (
    <section className="flex flex-col gap-6">
      <SubBodyText>
        <b>{INSTRUCTION}</b>
      </SubBodyText>
      <ReadonlyScorableQuestionList
        questions={WHODAS_2_QUESTIONS}
        options={WHODAS_2_OPTIONS}
        response={scorableResponseJson}
      />
    </section>
  );
};
