import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import partition from 'lodash/partition';
import React, { useState } from 'react';

import { AuthorizationType } from '@headway/api/models/AuthorizationType';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';

import { useInsuranceAuthorizations } from 'hooks/useInsuranceAuthorizations';
import { determineActiveEAPStatus } from 'views/Patients/utils/patientInsuranceStatus';

import { EmployeeAssistanceProgramDetail } from './EmployeeAssistanceProgramDetail';

interface EmployeeAssistanceProgramsProps {
  clientId: number;
}

export const EmployeeAssistancePrograms = ({
  clientId,
}: EmployeeAssistanceProgramsProps) => {
  const [isShowingInactiveAuthorizations, setIsShowingInactiveAuthorizations] =
    useState<boolean>(false);
  const { data: client } = useUser({ userId: clientId });
  const insurance = client?.activeUserInsurance;
  const { data: eapAuthorizations, isLoading: areEAPsLoading } =
    useInsuranceAuthorizations({
      query: {
        user_id: clientId,
        authorization_type: AuthorizationType.EAP,
        has_unused_sessions: true,
      },
    });
  const [activeAuthorizations, inactiveAuthorizations] = partition(
    eapAuthorizations || [],
    (auth) => determineActiveEAPStatus(auth)
  );

  const carrierId =
    activeAuthorizations[0]?.frontEndCarrierId || insurance?.frontEndCarrierId;

  if (!carrierId || areEAPsLoading) {
    return null;
  }

  return (
    <PageSection css={eapCss.container}>
      <h2>
        <SectionHeader>Employee Assistance Programs (EAP)</SectionHeader>
      </h2>
      {eapAuthorizations ? (
        <>
          {activeAuthorizations.length > 0 && (
            <div css={eapCss.detailList}>
              {activeAuthorizations.map((auth) => (
                <EmployeeAssistanceProgramDetail
                  auth={auth}
                  frontEndCarrierId={carrierId}
                  key={auth.authorizationNumber}
                />
              ))}
            </div>
          )}
          {inactiveAuthorizations.length > 0 && (
            <div css={eapCss.button}>
              <Button
                variant="link"
                onPress={() =>
                  setIsShowingInactiveAuthorizations((current) => !current)
                }
              >
                {isShowingInactiveAuthorizations ? 'Hide' : 'Show'} inactive
                programs
              </Button>
            </div>
          )}
          {inactiveAuthorizations.length > 0 &&
            isShowingInactiveAuthorizations && (
              <div
                css={eapCss.detailList}
                data-testid="inactive-programs-wrapper"
              >
                {inactiveAuthorizations.map((auth) => (
                  <EmployeeAssistanceProgramDetail
                    auth={auth}
                    frontEndCarrierId={carrierId}
                    key={auth.authorizationNumber}
                  />
                ))}
              </div>
            )}
          {activeAuthorizations.length === 0 && (
            <div>
              <BodyText>
                {formatPatientName(client, {
                  firstNameOnly: true,
                })}{' '}
                doesn't have active EAP benefits on file.
              </BodyText>{' '}
              <Link
                href="https://findheadway.zendesk.com/hc/en-us/articles/7473561009428-Seeing-a-client-with-an-Employee-Assistance-Program-EAP-"
                target="_blank"
                rel="noreferrer"
              >
                Learn how to add EAP benefits
              </Link>
            </div>
          )}
        </>
      ) : (
        <Skeleton variant="rectangular" height={64} />
      )}
    </PageSection>
  );
};

const eapCss = {
  container: css({
    gap: theme.spacing.x3,
  }),
  detailList: css({
    margin: `0 -${theme.spacing.x4}`,
  }),
  button: css({
    justifySelf: 'center',
  }),
};
