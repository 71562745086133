import { useContext, useEffect } from 'react';

import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TELEHEALTH_LOCATIONS_SIGMUND } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { trackEvent } from '@headway/shared/utils/analytics';

import { useUserInsurance } from 'hooks/useUserInsurance';
import { InsuranceVerifyMatch } from 'views/Clients/InsuranceVerifyMatch';

import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';

export interface PatientInsuranceVerifyMatchProps {
  client: UserRead;
}

export const PatientInsuranceVerifyMatch = ({
  client,
}: PatientInsuranceVerifyMatchProps) => {
  const { setCurrentStep } = useContext(AddPatientModalContext);

  const telehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );

  useEffect(() => {
    /*trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Client Billing Details',
        stepName: 'EXISTING_INSURANCE',
      },
    });
    */
  }, []);

  const onSuccess = () => {
    setCurrentStep(
      telehealthLocationsEnabled
        ? AddPatientModalPage.TELEHEALTH_LOCATIONS
        : AddPatientModalPage.SEND_ACCOUNT_INVITE
    );
  };
  const onEdit = () => {
    setCurrentStep(AddPatientModalPage.BILLING_INFORMATION);
  };

  return (
    <>
      <ModalContent>
        <PageSection>
          <div>
            <SectionHeader>Did you mean this?</SectionHeader>
            <InsuranceVerifyMatch patient={client} />
          </div>
        </PageSection>
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onEdit}>
          Go back and edit
        </Button>
        <Button variant="primary" onPress={onSuccess}>
          Yes, this is right
        </Button>
      </ModalFooter>
    </>
  );
};
