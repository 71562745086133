import moment from 'moment';
import React from 'react';

import { theme } from '@headway/ui/theme';

import { useProvider } from 'hooks/useProvider';

import { CalendarDayVisibileNotification } from './CalendarDayVisibileNotification';

type CalendarWeekHeaderProps = {
  date: Date;
};

export const CalendarWeekHeader: React.FC<
  React.PropsWithChildren<CalendarWeekHeaderProps>
> = ({ date }) => {
  const provider = useProvider();
  return (
    <div
      css={{
        position: 'relative',
        padding: theme.space.xs2,
      }}
    >
      <div>
        <div
          css={{
            textTransform: 'uppercase',
            fontSize: theme.fontSize.xs,
          }}
        >
          {moment(date).format('ddd')}
        </div>
      </div>
      <div
        css={{
          fontSize: theme.fontSize.xl2,
          fontWeight: theme.fontWeight.bold,
          padding: theme.space.xs2,
        }}
      >
        {moment(date).format('D')}
      </div>
      <CalendarDayVisibileNotification
        date={date}
        provider={provider}
        css={{
          fontSize: theme.fontSize.xl,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      />
    </div>
  );
};
