import React from 'react';

import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

interface CancelTreatmentPlanModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export const CANCEL_TREATMENT_PLAN_MODAL_TITLE = 'Go back?';

export const CancelTreatmentPlanModal = ({
  open,
  onClose,
  onCancel,
}: CancelTreatmentPlanModalProps) => {
  return (
    <Modal
      title={CANCEL_TREATMENT_PLAN_MODAL_TITLE}
      isOpen={open}
      onDismiss={onClose}
    >
      <ModalContent>Your treatment plan will not be saved.</ModalContent>
      <ModalFooter>
        <Button variant="secondary" onPress={onClose}>
          Cancel
        </Button>
        <Button
          onPress={() => {
            onCancel();
            onClose();
          }}
        >
          Go back
        </Button>
      </ModalFooter>
    </Modal>
  );
};
