import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { theme } from '@headway/helix/theme';
import UnitedStatesDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';

export const getLicenseVerificationInstructions = (states: UnitedStates[]) => {
  const stateInstructions = states
    .map((state) => ({
      state,
      instruction: getLicenseVerificationInstructionForState(state),
    }))
    .filter((stateInfo) => !!stateInfo.instruction);

  if (stateInstructions.length === 0) {
    return undefined;
  }

  return (
    <>
      {stateInstructions.map((stateInstruction) => {
        return (
          stateInstructions && (
            <div>
              <div css={{ margin: '4px 0 2px', fontWeight: 'bold' }}>
                {UnitedStatesDisplayNames[stateInstruction.state]} licenses
              </div>
              {stateInstruction.instruction}
            </div>
          )
        );
      })}
    </>
  );
};

export const getLicenseVerificationInstructionForState = (
  state: UnitedStates
) => {
  let message: string | ReactJSXElement;
  let detailsList: (string | ReactJSXElement)[];
  if (state === UnitedStates.FLORIDA) {
    message = 'Please ensure your Florida license number:';
    detailsList = [
      <>
        includes the prefix: common prefixes are MH, MT, and SW (ie.{' '}
        <b>'MH12345'</b>)
      </>,
      'does not include spaces',
    ];
  } else if (state === UnitedStates.INDIANA) {
    message = (
      <>
        If you have 2 Indiana license numbers, please ensure you input the
        number that ends with <b>'####A'.</b>
      </>
    );
    detailsList = [];
  } else if (state === UnitedStates.NEW_YORK) {
    message =
      'Please ensure your New York license number is 6 digits long and does not include any prefixes or suffixes. For instance:';
    detailsList = [
      <>
        If the license shows <b>'123456-01'</b>, input <b>'123456'</b>
      </>,
      <>
        If the license shows <b>'PR061234'</b>, input <b>'061234'</b>
      </>,
    ];
  } else if (state === UnitedStates.MINNESOTA) {
    message = (
      <>
        If your Minnesota license number looks like <b>'cc00123'</b>, please
        ensure you input it as <b>'123'</b>.
      </>
    );
    detailsList = [];
  } else if (state === UnitedStates.OHIO) {
    message =
      'If you have an LPCC-SUPV license, please input your license information as:';
    detailsList = [
      <>
        License type: <b>LPCC</b>
      </>,
      <>
        License number: <b>####-SUPV</b>
      </>,
    ];
  } else if (state === UnitedStates.TENNESSEE) {
    message = (
      <>
        Please ensure you input your Tennessee license number{' '}
        <b>'LSW0000001234'</b> as <b>'1234'</b>.
      </>
    );
    detailsList = [];
  } else {
    return undefined;
  }

  return (
    <div
      css={{
        ...theme.stack.vertical,
      }}
    >
      <span>{message}</span>
      {detailsList && (
        <ul
          css={{
            paddingLeft: '25px',
            margin: '5px 0',
            listStylePosition: 'inside',
            listStyleType: 'disc',
          }}
        >
          {detailsList.map((detail) => (
            <li css={{ textIndent: '-1.375em', paddingLeft: '-2em' }}>
              <span css={{ position: 'relative', left: '-8px' }}>{detail}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
