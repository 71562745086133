import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';

import { createJsonFromTemplate } from './Template';
import { getErrors } from './Template/errors';
import { PlanJson } from './Template/types';
import { TreatmentPlanContext } from './TreatmentPlanContext';
import { parseTextFromHtmlString } from './TreatmentPlanUtils';

type TreatmentPlanFormikProps = {
  onSubmit: (values: any) => Promise<void>;
  validate?: (values: any) => void;
  validationSchema?: any;
  initialTouched?: any;
  initialValues?: any;
};

interface TreatmentPlanFormProps {
  children: React.ReactNode;
}

export const TreatmentPlanForm = ({ children }: TreatmentPlanFormProps) => {
  const {
    furthestStep,
    initialValues,
    page,
    setTreatmentPlan,
    steps,
    currentStep,
    treatmentPlan,
    prefillTreatmentPlan,
    providerPatient,
  } = useContext(TreatmentPlanContext);
  const { saveTreatmentPlan } = useProviderTreatmentPlan(
    providerPatient?.userId
  );

  const formikProps: {
    [page: string]: TreatmentPlanFormikProps;
  } = {
    TEMPLATE: {
      onSubmit: async (values: any) => {},
      validate: (values) =>
        getErrors(values, steps?.slice(0, furthestStep) ?? []),
      initialTouched: createJsonFromTemplate(
        { steps: steps ?? [] },
        furthestStep > currentStep
      ),
    },
    SUMMARY: {
      onSubmit: async (values: any) => {
        const valuesCopy = { ...values };
        if (valuesCopy.treatmentPlanAttestation) {
          delete valuesCopy.treatmentPlanAttestation;
        }
        setTreatmentPlan(
          await saveTreatmentPlan({
            values: valuesCopy,
            planType: treatmentPlan?.planType!,
            shouldAttestPlan: true,
            treatmentPlanId: treatmentPlan?.id,
          })
        );
      },
      validationSchema: Yup.object().shape({
        treatmentPlanAttestation: Yup.bool()
          .required('Confirmation required to save.')
          .oneOf([true], 'Confirmation required to save.'),
      }),
      initialValues: {
        ...(treatmentPlan?.planJson as PlanJson)?.template,
        treatmentPlanAttestation: false,
      },
    },
    TEXT: {
      onSubmit: async (values: any) => {
        setTreatmentPlan(
          await saveTreatmentPlan({
            values,
            planType: TreatmentPlanType.TEXT,
            shouldAttestPlan: true,
            treatmentPlanId: treatmentPlan?.id,
          })
        );
      },
      validationSchema: Yup.object()
        .shape({
          treatmentPlanAttestation: Yup.bool()
            .required('Confirmation required to save.')
            .oneOf([true], 'Confirmation required to save.'),
        })
        .test({
          name: 'textChanged',
          test: async function (values: any) {
            if (parseTextFromHtmlString(values.treatmentPlanText) === '') {
              return new Yup.ValidationError(
                ['Please add details.'],
                undefined,
                'treatmentPlanText'
              );
            }
            return true;
          },
        }),
      initialValues: {
        treatmentPlanText: prefillTreatmentPlan?.planJson
          ? (prefillTreatmentPlan.planJson as PlanJson).text
          : (treatmentPlan?.planJson as PlanJson | undefined)
          ? (treatmentPlan?.planJson as PlanJson).text
          : '',
        treatmentPlanAttestation: false,
      },
    },
    UPLOAD: {
      onSubmit: async (values: any) => {
        setTreatmentPlan(
          await saveTreatmentPlan({
            values,
            planType: TreatmentPlanType.UPLOAD,
            shouldAttestPlan: true,
            treatmentPlanId: treatmentPlan?.id,
            initialAttachments: initialValues?.treatmentPlanAttachments,
          })
        );
      },
      validationSchema: Yup.object().shape({
        treatmentPlanAttestation: Yup.bool()
          .required('Confirmation required to save.')
          .oneOf([true], 'Confirmation required to save.'),
      }),
      initialValues: {
        ...initialValues,
        treatmentPlanAttestation: false,
      },
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      {...formikProps[page]}
    >
      {children}
    </Formik>
  );
};
