/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface InsuranceReadinessIssueNoMentalHealthBenefits {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: InsuranceReadinessIssueNoMentalHealthBenefitsType;

}


    export enum InsuranceReadinessIssueNoMentalHealthBenefitsType {
            HAS_NO_MENTAL_HEALTH_BENEFITS = 'HAS_NO_MENTAL_HEALTH_BENEFITS'
    }

