interface CarrierMemberIdData {
  front_end_carrier_id: number;
  prefixes: Array<{ prefix: string; frequency: number }>;
  suffixes: Array<{ suffix: string; frequency: number }>;
  formats: Array<{ format: string; frequency: number }>;
}

// copied and pasted out of the "combined_info" column
// https://findheadway.palantirfoundry.com/workspace/data-integration/dataset/preview/ri.foundry.main.dataset.d347560e-1d8d-4bac-8a66-139352ffee5f/rob-adam-member-id-regex
const data: CarrierMemberIdData[] = [
  {
    front_end_carrier_id: 1,
    prefixes: [
      { prefix: 'W27', frequency: 0.20135401 },
      { prefix: 'W28', frequency: 0.15744764 },
      { prefix: 'W26', frequency: 0.120775856 },
      { prefix: '101', frequency: 0.08282469 },
      { prefix: 'w2', frequency: 0.07139022 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.17391966 }],
    formats: [
      { format: 'addddddddd', frequency: 0.70478284 },
      { format: 'dddddddddddd', frequency: 0.086441986 },
      { format: 'addddsddddd', frequency: 0.079199776 },
      { format: 'dddddddddd', frequency: 0.039241966 },
      { format: 'addddddddddd', frequency: 0.026748963 },
      { format: 'ddddddddd', frequency: 0.014526306 },
      { format: 'adddddddddhdd', frequency: 0.012908041 },
      { format: 'addddsdddddhdd', frequency: 0.004831948 },
      { format: 'ddddddddddd', frequency: 0.003419298 },
      { format: 'dddddddda', frequency: 0.0028862227 },
      { format: 'adddddddd', frequency: 0.0027872229 },
      { format: 'adddddddddd', frequency: 0.0027491462 },
      { format: 'addddhddddd', frequency: 0.0018619563 },
      { format: 'aaddddddd', frequency: 0.0010471124 },
      { format: 'ddddddddddddd', frequency: 0.0010242664 },
    ],
  },
  {
    front_end_carrier_id: 3,
    prefixes: [
      { prefix: '10', frequency: 0.25099784 },
      { prefix: '108', frequency: 0.06237452 },
      { prefix: '109', frequency: 0.05645913 },
      { prefix: '107', frequency: 0.055945266 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.35432002 },
      { suffix: '2', frequency: 0.13151291 },
    ],
    formats: [
      { format: 'adddddddddd', frequency: 0.41177702 },
      { format: 'ddddddddd', frequency: 0.2673757 },
      { format: 'addddddddsdd', frequency: 0.10861616 },
      { format: 'adddddddd', frequency: 0.0991336 },
      { format: 'ddddddddddd', frequency: 0.039119262 },
      { format: 'dddddddddddd', frequency: 0.011203394 },
      { format: 'dddddddd', frequency: 0.009870937 },
      { format: 'dddddddddsdd', frequency: 0.007719885 },
      { format: 'dddddddddd', frequency: 0.005592734 },
      { format: 'addddddddhdd', frequency: 0.005509082 },
      { format: 'addddddddd', frequency: 0.004947419 },
      { format: 'addddddddddd', frequency: 0.003465583 },
      { format: 'ddddddd', frequency: 0.002623088 },
      { format: 'aaddddddd', frequency: 0.002425908 },
      { format: 'dddddddddhdd', frequency: 0.0018044933 },
      { format: 'aaadddddd', frequency: 0.0010934513 },
    ],
  },
  {
    front_end_carrier_id: 4,
    prefixes: [
      { prefix: 'K', frequency: 0.8684211 },
      { prefix: 'K90', frequency: 0.43718165 },
      { prefix: 'K60', frequency: 0.31663838 },
      { prefix: 'K905', frequency: 0.0950764 },
      { prefix: 'K902', frequency: 0.092529714 },
      { prefix: 'K906', frequency: 0.084040746 },
      { prefix: 'K903', frequency: 0.08319185 },
      { prefix: 'K904', frequency: 0.08234295 },
      { prefix: 'k', frequency: 0.078947365 },
      { prefix: 'K608', frequency: 0.065365024 },
      { prefix: 'K607', frequency: 0.06196944 },
      { prefix: 'K609', frequency: 0.06196944 },
      { prefix: 'K10', frequency: 0.05942275 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.60865873 },
      { suffix: '02', frequency: 0.17826825 },
      { suffix: '03', frequency: 0.1196944 },
    ],
    formats: [
      { format: 'adddddddddd', frequency: 0.9244482 },
      { format: 'aaadddddadd', frequency: 0.051782683 },
      { format: 'adddddddd', frequency: 0.014431239 },
      { format: 'addddddddd', frequency: 0.005942275 },
    ],
  },
  {
    front_end_carrier_id: 5,
    prefixes: [
      { prefix: 'N', frequency: 0.10371561 },
      { prefix: 'V', frequency: 0.07668121 },
      { prefix: 'J', frequency: 0.0671194 },
      { prefix: 'R', frequency: 0.062496413 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.124461606 }],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.36426806 },
      { format: 'aaaddddddddd', frequency: 0.16121575 },
      { format: 'adadddaddddd', frequency: 0.11721128 },
      { format: 'adddddddd', frequency: 0.05425544 },
      { format: 'aaaaaddddddd', frequency: 0.053250447 },
      { format: 'aaadddddddaa', frequency: 0.04572733 },
      { format: 'ddddddddd', frequency: 0.038017575 },
      { format: 'aaadddddddddddd', frequency: 0.028671108 },
      { format: 'aaadddddddda', frequency: 0.019697927 },
      { format: 'aaaadddddddd', frequency: 0.013725378 },
      { format: 'aaadaaadddddddd', frequency: 0.010294033 },
      { format: 'adaddddddddd', frequency: 0.009662321 },
      { format: 'adadddddddddddd', frequency: 0.009332108 },
      { format: 'adadddddddaa', frequency: 0.0071211164 },
      { format: 'adaddddadddd', frequency: 0.006733475 },
      { format: 'aaaddddddddddd', frequency: 0.00656119 },
      { format: 'aaaaaadddddd', frequency: 0.005986906 },
      { format: 'adadddddddddd', frequency: 0.005986906 },
      { format: 'aaadddddddddd', frequency: 0.005757193 },
      { format: 'adaaaddddddd', frequency: 0.0055849077 },
      { format: 'dddaddddd', frequency: 0.0050536953 },
      { format: 'aaasddddddddd', frequency: 0.004235341 },
      { format: 'aaasadddddddd', frequency: 0.002239706 },
      { format: 'aaasdddddddaa', frequency: 0.0018951359 },
      { format: 'aaaddadddddd', frequency: 0.0015792799 },
      { format: 'dddddddaa', frequency: 0.0015362086 },
      { format: 'adadaaadddddddd', frequency: 0.0010049962 },
    ],
  },
  {
    front_end_carrier_id: 6,
    prefixes: [
      { prefix: 'H', frequency: 0.84045583 },
      { prefix: 'HP', frequency: 0.71581197 },
      { prefix: 'HP62', frequency: 0.12001424 },
      { prefix: 'HP61', frequency: 0.10220797 },
      { prefix: 'HP60', frequency: 0.10149573 },
      { prefix: 'HH', frequency: 0.088675216 },
      { prefix: 'HP59', frequency: 0.056267805 },
      { prefix: 'HPP7', frequency: 0.052350428 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.41096866 },
      { suffix: '-00', frequency: 0.23504274 },
      { suffix: '01', frequency: 0.12464388 },
      { suffix: '02', frequency: 0.1011396 },
    ],
    formats: [
      { format: 'aadddddddhdd', frequency: 0.34366098 },
      { format: 'aaddddddddd', frequency: 0.2037037 },
      { format: 'aaddddddd', frequency: 0.07905983 },
      { format: 'ddddddddd', frequency: 0.054131053 },
      { format: 'aaaaddddd', frequency: 0.053418804 },
      { format: 'aaaddddddhdd', frequency: 0.0505698 },
      { format: 'aaadddddddd', frequency: 0.03311966 },
      { format: 'aaadddddd', frequency: 0.027777778 },
      { format: 'aaaddddddddd', frequency: 0.014957265 },
      { format: 'ddddddddddd', frequency: 0.014245015 },
      { format: 'aaaadddddhdd', frequency: 0.014245015 },
      { format: 'dddddddd', frequency: 0.013176639 },
      { format: 'aaaaddddddd', frequency: 0.009615385 },
      { format: 'aaaddaddd', frequency: 0.008547009 },
      { format: 'dddddddddd', frequency: 0.0053418805 },
      { format: 'dddddddhdd', frequency: 0.004985755 },
      { format: 'ddddddd', frequency: 0.0046296297 },
      { format: 'adddddddddd', frequency: 0.0042735045 },
      { format: 'dddddddddddd', frequency: 0.003917379 },
      { format: 'addddddddd', frequency: 0.003917379 },
      { format: 'aasdddddddhdd', frequency: 0.0035612537 },
      { format: 'ddddadddddd', frequency: 0.0035612537 },
      { format: 'aadddddddd', frequency: 0.0032051282 },
      { format: 'aaaaadddd', frequency: 0.0028490028 },
      { format: 'aahdddddddhdd', frequency: 0.0024928774 },
      { format: 'aaddddddddhdd', frequency: 0.0021367522 },
      { format: 'aaaddddddd', frequency: 0.0021367522 },
      { format: 'aadddddd', frequency: 0.0017806268 },
      { format: 'aaddddddhdd', frequency: 0.0017806268 },
      { format: 'aaaaaaddd', frequency: 0.0014245014 },
      { format: 'dddddddddhdd', frequency: 0.0014245014 },
    ],
  },
  {
    front_end_carrier_id: 7,
    prefixes: [
      { prefix: 'VYT20', frequency: 0.13793103 },
      { prefix: 'YTD7', frequency: 0.13300492 },
      { prefix: 'JLJ7', frequency: 0.06403941 },
      { prefix: 'VYT204', frequency: 0.054187194 },
      { prefix: 'X', frequency: 0.054187194 },
    ],
    suffixes: [{ suffix: '8', frequency: 0.13300492 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5566502 },
      { format: 'ddddddddd', frequency: 0.19704433 },
      { format: 'dddddddddddd', frequency: 0.054187194 },
      { format: 'dddddddddd', frequency: 0.03448276 },
      { format: 'aaasddddddddd', frequency: 0.02955665 },
      { format: 'aaadddddddd', frequency: 0.02955665 },
      { format: 'ddddddddda', frequency: 0.014778325 },
      { format: 'aaaadddddddd', frequency: 0.014778325 },
      { format: 'adddddddddd', frequency: 0.009852217 },
      { format: 'aaadddddddda', frequency: 0.009852217 },
      { format: 'dddsdddsdddd', frequency: 0.0049261083 },
      { format: 'aaddddda', frequency: 0.0049261083 },
      { format: 'dddddddddhdd', frequency: 0.0049261083 },
      { format: 'adddddd', frequency: 0.0049261083 },
      { format: 'dddd', frequency: 0.0049261083 },
      { format: 'aaaddddddddddd', frequency: 0.0049261083 },
      { format: 'adddddddd', frequency: 0.0049261083 },
      { format: 'addddddddd', frequency: 0.0049261083 },
      { format: 'aasaddddddddd', frequency: 0.0049261083 },
      { format: 'dddddddddda', frequency: 0.0049261083 },
    ],
  },
  {
    front_end_carrier_id: 9,
    prefixes: [
      { prefix: '8', frequency: 0.93809175 },
      { prefix: '821', frequency: 0.4333576 },
      { prefix: '800', frequency: 0.29206118 },
      { prefix: '8007', frequency: 0.17625637 },
      { prefix: '8215', frequency: 0.17188638 },
      { prefix: '8214', frequency: 0.09905317 },
      { prefix: '80075', frequency: 0.054624908 },
      { prefix: '8208', frequency: 0.053896576 },
      { prefix: '8213', frequency: 0.05098325 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.6729789 },
      { suffix: '01', frequency: 0.13109978 },
    ],
    formats: [
      { format: 'ddddddddddd', frequency: 0.8077203 },
      { format: 'dddddddddsdd', frequency: 0.09905317 },
      { format: 'ddddddddd', frequency: 0.03787327 },
      { format: 'adddddddddd', frequency: 0.013109978 },
      { format: 'adddddddd', frequency: 0.008011653 },
      { format: 'dddddddddd', frequency: 0.007283321 },
      { format: 'dddddddddhdd', frequency: 0.006554989 },
      { format: 'dddddddd', frequency: 0.0050983247 },
      { format: 'dddddddddddd', frequency: 0.0021849964 },
      { format: 'addddddddsdd', frequency: 0.0021849964 },
      { format: 'ddddddddddddd', frequency: 0.0014566643 },
      { format: 'addddddddhdd', frequency: 0.0014566643 },
      { format: 'aaddddddd', frequency: 0.0014566643 },
      { format: 'aaddddda', frequency: 0.0014566643 },
    ],
  },
  {
    front_end_carrier_id: 10,
    prefixes: [
      { prefix: '9', frequency: 0.22990072 },
      { prefix: '8', frequency: 0.1102137 },
      { prefix: '0', frequency: 0.1078075 },
      { prefix: '1', frequency: 0.10646138 },
      { prefix: '7', frequency: 0.07693084 },
      { prefix: '3', frequency: 0.07324584 },
      { prefix: '2', frequency: 0.067558475 },
      { prefix: '4', frequency: 0.061702844 },
      { prefix: '6', frequency: 0.058505803 },
      { prefix: '89', frequency: 0.05766448 },
      { prefix: '98', frequency: 0.056183744 },
      { prefix: '5', frequency: 0.055880867 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.30405518 },
      { suffix: '1', frequency: 0.16779405 },
    ],
    formats: [
      { format: 'ddddddddddd', frequency: 0.4111055 },
      { format: 'ddddddddd', frequency: 0.35726064 },
      { format: 'dddddddhdd', frequency: 0.03335016 },
      { format: 'dddddddddddd', frequency: 0.03319872 },
      { format: 'ddddddd', frequency: 0.028571429 },
      { format: 'dddddddd', frequency: 0.028403163 },
      { format: 'dddddddddd', frequency: 0.025054686 },
      { format: 'dddddddddhdd', frequency: 0.011812216 },
      { format: 'adddddddd', frequency: 0.010028605 },
      { format: 'adddddddddd', frequency: 0.0047450783 },
      { format: 'aaaddddddddd', frequency: 0.004576813 },
      { format: 'addddddddd', frequency: 0.003920579 },
      { format: 'ddddddddaaaa', frequency: 0.0030119468 },
      { format: 'aadddddddhdd', frequency: 0.0023220596 },
      { format: 'aaaddddddd', frequency: 0.0022884065 },
      { format: 'ddaddddddd', frequency: 0.0022211005 },
      { format: 'aaadddaddddd', frequency: 0.0015816927 },
      { format: 'aaddddddd', frequency: 0.0014639071 },
      { format: 'aaddddddddd', frequency: 0.0014134275 },
      { format: 'dddddd', frequency: 0.0011778563 },
      { format: 'aaadddddddd', frequency: 0.0011105502 },
    ],
  },
  {
    front_end_carrier_id: 11,
    prefixes: [
      { prefix: '98', frequency: 0.16800572 },
      { prefix: '99', frequency: 0.11872295 },
      { prefix: '1', frequency: 0.07853078 },
      { prefix: '00', frequency: 0.069065385 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.19384307 },
      { suffix: '00', frequency: 0.1190705 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.65766805 },
      { format: 'ddddddddddd', frequency: 0.16889843 },
      { format: 'dddddddd', frequency: 0.041919656 },
      { format: 'dddddddddddd', frequency: 0.02008927 },
      { format: 'dddddddddd', frequency: 0.017864322 },
      { format: 'dddddddddhdd', frequency: 0.012259361 },
      { format: 'adddddddd', frequency: 0.010944155 },
      { format: 'ddddddd', frequency: 0.010930526 },
      { format: 'dddddddhdd', frequency: 0.008306927 },
      { format: 'addddddddd', frequency: 0.0067872843 },
      { format: 'ddddddddaaaa', frequency: 0.003918362 },
      { format: 'aaadddddddda', frequency: 0.0038297728 },
      { format: 'adddddddddd', frequency: 0.0034345293 },
      { format: 'aaadddd', frequency: 0.0027224096 },
      { format: 'aaaddddddddd', frequency: 0.0021840609 },
      { format: 'aaaddddddd', frequency: 0.0020852499 },
      { format: 'ddddddddda', frequency: 0.0016695629 },
      { format: 'aaaadddddddd', frequency: 0.0013560939 },
      { format: 'aaadddaddddd', frequency: 0.0013390576 },
      { format: 'aadddddddhdd', frequency: 0.0010119595 },
    ],
  },
  {
    front_end_carrier_id: 18,
    prefixes: [
      { prefix: '1100', frequency: 0.99056107 },
      { prefix: '11001', frequency: 0.7635029 },
      { prefix: '11000', frequency: 0.22653383 },
      { prefix: '110013', frequency: 0.12165705 },
      { prefix: '110018', frequency: 0.11851075 },
      { prefix: '110015', frequency: 0.106449924 },
      { prefix: '110016', frequency: 0.0907184 },
      { prefix: '110014', frequency: 0.08180388 },
      { prefix: '110017', frequency: 0.069218665 },
      { prefix: '110012', frequency: 0.06397483 },
    ],
    suffixes: [],
    formats: [
      { format: 'dddddddddddd', frequency: 0.9968537 },
      { format: 'dddddddd', frequency: 0.0010487677 },
      { format: 'ddsdddddddddd', frequency: 0.0010487677 },
      { format: 'ddhdddddddddd', frequency: 0.0010487677 },
    ],
  },
  {
    front_end_carrier_id: 22,
    prefixes: [
      { prefix: 'H', frequency: 0.5451505 },
      { prefix: '1', frequency: 0.36622074 },
      { prefix: 'H7', frequency: 0.20568562 },
      { prefix: '12', frequency: 0.19899665 },
      { prefix: 'H6', frequency: 0.19397993 },
      { prefix: '11', frequency: 0.115384616 },
      { prefix: 'H4', frequency: 0.058528427 },
      { prefix: '123', frequency: 0.055183947 },
      { prefix: 'H5', frequency: 0.051839463 },
      { prefix: '10', frequency: 0.051839463 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.17558528 }],
    formats: [
      { format: 'adddddddd', frequency: 0.5451505 },
      { format: 'ddddddddd', frequency: 0.26755852 },
      { format: 'ddddddddddd', frequency: 0.08361204 },
      { format: 'addddddddhdd', frequency: 0.02341137 },
      { format: 'dddddddddhdd', frequency: 0.02341137 },
      { format: 'dddddddddsdd', frequency: 0.02173913 },
      { format: 'dddddddddddd', frequency: 0.016722407 },
      { format: 'dddddddddd', frequency: 0.0100334445 },
      { format: 'adddddddddd', frequency: 0.0050167223 },
      { format: 'ddddddddddddd', frequency: 0.0016722408 },
      { format: 'daddaadaadd', frequency: 0.0016722408 },
    ],
  },
  {
    front_end_carrier_id: 23,
    prefixes: [
      { prefix: 'OSC7', frequency: 0.6723711 },
      { prefix: 'OSC78', frequency: 0.26785082 },
      { prefix: 'OSC79', frequency: 0.2597073 },
      { prefix: 'OSC77', frequency: 0.14398678 },
      { prefix: 'OSC0', frequency: 0.07936976 },
      { prefix: 'osc7', frequency: 0.062138557 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.6117668 },
      { suffix: '-01', frequency: 0.42965892 },
    ],
    formats: [
      { format: 'aaaddddddddhdd', frequency: 0.5237224 },
      { format: 'aaadddddddddd', frequency: 0.21226248 },
      { format: 'aaadddddddd', frequency: 0.1419214 },
      { format: 'ddddddddd', frequency: 0.026200874 },
      { format: 'ddddddddhdd', frequency: 0.013218459 },
      { format: 'dddddddddd', frequency: 0.012333294 },
      { format: 'dddddddd', frequency: 0.009500767 },
      { format: 'aaaddddddddd', frequency: 0.007317361 },
      { format: 'ddddddddddd', frequency: 0.00542901 },
      { format: 'aaadddddddhdd', frequency: 0.004484834 },
      { format: 'daaddddddddhdd', frequency: 0.0033636256 },
      { format: 'aaadddddddddhdd', frequency: 0.0031275817 },
      { format: 'aaaadddddddhdd', frequency: 0.0028915377 },
      { format: 'aaaddddddd', frequency: 0.002360439 },
      { format: 'aaaddddddddhd', frequency: 0.0018883513 },
      { format: 'aaaddddddddddd', frequency: 0.0017703293 },
      { format: 'daadddddddddd', frequency: 0.0014752744 },
      { format: 'adddddddd', frequency: 0.0012982414 },
      { format: 'adddddddddd', frequency: 0.0012982414 },
      { format: 'aaaddddddddsdd', frequency: 0.0011802195 },
    ],
  },
  {
    front_end_carrier_id: 24,
    prefixes: [
      { prefix: 'JQ', frequency: 0.12585078 },
      { prefix: 'XD', frequency: 0.081690915 },
      { prefix: 'C', frequency: 0.06725589 },
      { prefix: 'N', frequency: 0.06569143 },
      { prefix: 'JQU', frequency: 0.065417245 },
      { prefix: 'S', frequency: 0.0584336 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.62735075 },
      { format: 'aaaddddddddd', frequency: 0.078416824 },
      { format: 'aaadddddddda', frequency: 0.05796587 },
      { format: 'adadddaddddd', frequency: 0.0544176 },
      { format: 'aaaaaddddddd', frequency: 0.048837133 },
      { format: 'aaadddddddaa', frequency: 0.020773523 },
      { format: 'adddddddd', frequency: 0.016434954 },
      { format: 'aaaadddddddd', frequency: 0.012499597 },
      { format: 'adadddddddaa', frequency: 0.011306087 },
      { format: 'dddaddddd', frequency: 0.011144802 },
      { format: 'adaaaddddddd', frequency: 0.008612625 },
      { format: 'aaadddddddddddd', frequency: 0.0074513727 },
      { format: 'aaddddddd', frequency: 0.00530628 },
      { format: 'aaaddddddddddd', frequency: 0.004725654 },
      { format: 'adaddddddddd', frequency: 0.0041772844 },
      { format: 'aaasaaddddddd', frequency: 0.0039514853 },
      { format: 'adasaaddddddd', frequency: 0.0023386343 },
      { format: 'aaaddddddadd', frequency: 0.0016934938 },
      { format: 'dddddddda', frequency: 0.0016128512 },
      { format: 'adaadddddddd', frequency: 0.0015322086 },
      { format: 'aaadddddddddd', frequency: 0.001483823 },
      { format: 'aaasdddaddddd', frequency: 0.001354795 },
      { format: 'adadddddddddd', frequency: 0.0013064095 },
      { format: 'adadddddddddddd', frequency: 0.001225767 },
      { format: 'aaaaaadddddd', frequency: 0.0011612528 },
      { format: 'aaadaaadddddddd', frequency: 0.0010483533 },
      { format: 'aaadaddddddd', frequency: 0.0010160962 },
    ],
  },
  {
    front_end_carrier_id: 25,
    prefixes: [
      { prefix: 'R', frequency: 0.09165381 },
      { prefix: 'C', frequency: 0.08660035 },
      { prefix: 'VA', frequency: 0.08037662 },
      { prefix: 'P', frequency: 0.06574818 },
      { prefix: 'U', frequency: 0.05915208 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.1226661 }],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.44794935 },
      { format: 'adadddaddddd', frequency: 0.18681845 },
      { format: 'aaaddddddddd', frequency: 0.12404915 },
      { format: 'adddddddd', frequency: 0.069152616 },
      { format: 'aaadddddddddddd', frequency: 0.03351242 },
      { format: 'aaadddddddaa', frequency: 0.026171604 },
      { format: 'aaaaaddddddd', frequency: 0.017288154 },
      { format: 'adaddddddddd', frequency: 0.017075377 },
      { format: 'adadddddddaa', frequency: 0.013032608 },
      { format: 'aaaadddddddd', frequency: 0.009947337 },
      { format: 'dddaddddd', frequency: 0.00824512 },
      { format: 'aaaddddddddddd', frequency: 0.0063833185 },
      { format: 'aaadddddddda', frequency: 0.0051066545 },
      { format: 'adaaaddddddd', frequency: 0.0049470714 },
      { format: 'adadddddddddddd', frequency: 0.0045747114 },
      { format: 'aaadaaadddddddd', frequency: 0.0028724931 },
      { format: 'aaadddddddddd', frequency: 0.0026065216 },
      { format: 'aaaddadddddd', frequency: 0.0017022182 },
      { format: 'aaadddddd', frequency: 0.0014362466 },
      { format: 'aaasddddddddd', frequency: 0.001329858 },
      { format: 'aaaaaadddddd', frequency: 0.0011170807 },
      { format: 'adadddddddddd', frequency: 0.0011170807 },
    ],
  },
  {
    front_end_carrier_id: 26,
    prefixes: [
      { prefix: 'AZL097', frequency: 0.083550915 },
      { prefix: 'U', frequency: 0.06331593 },
      { prefix: 'SHP097', frequency: 0.06037859 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.34301567 },
      { format: 'aaadddddddddd', frequency: 0.3038512 },
      { format: 'aaaaaddddddd', frequency: 0.07898172 },
      { format: 'adadddaddddd', frequency: 0.06853786 },
      { format: 'aaaddddddddd', frequency: 0.04830287 },
      { format: 'aaadddddddaa', frequency: 0.035900783 },
      { format: 'adddddddd', frequency: 0.024804177 },
      { format: 'adadddddddddd', frequency: 0.021866841 },
      { format: 'aaadddddddda', frequency: 0.013707572 },
      { format: 'adaaaddddddd', frequency: 0.012728459 },
      { format: 'adadddddddaa', frequency: 0.009791123 },
      { format: 'aaadddddddddddd', frequency: 0.009138381 },
      { format: 'adaddddddddd', frequency: 0.0045691906 },
      { format: 'aaaadddddddd', frequency: 0.0042428197 },
      { format: 'aaaaaadddddd', frequency: 0.0022845953 },
      { format: 'adadddddddddddd', frequency: 0.0022845953 },
      { format: 'dddaddddd', frequency: 0.0022845953 },
      { format: 'aaadaaadddddddd', frequency: 0.0022845953 },
      { format: 'aaaddddddddddd', frequency: 0.0016318538 },
      { format: 'ddddddddd', frequency: 0.0016318538 },
      { format: 'dddddddddd', frequency: 0.0016318538 },
    ],
  },
  {
    front_end_carrier_id: 27,
    prefixes: [
      { prefix: 'A', frequency: 0.06965174 },
      { prefix: 'E', frequency: 0.06119403 },
      { prefix: 'I', frequency: 0.05920398 },
      { prefix: 'YZD', frequency: 0.05771144 },
      { prefix: 'X', frequency: 0.054228857 },
      { prefix: 'W', frequency: 0.051243782 },
    ],
    suffixes: [
      { suffix: '9', frequency: 0.12736319 },
      { suffix: '1', frequency: 0.12537314 },
    ],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.45373136 },
      { format: 'aaaaaddddddd', frequency: 0.15870647 },
      { format: 'adadddaddddd', frequency: 0.12288557 },
      { format: 'aaaddddddddd', frequency: 0.08059701 },
      { format: 'aaadddddddddddd', frequency: 0.03084577 },
      { format: 'dddddddddddd', frequency: 0.025870647 },
      { format: 'adaaaddddddd', frequency: 0.023880597 },
      { format: 'adddddddd', frequency: 0.023383085 },
      { format: 'aaadddddddaa', frequency: 0.021890547 },
      { format: 'adadddddddaa', frequency: 0.011442786 },
      { format: 'adaddddddddd', frequency: 0.008457712 },
      { format: 'aaadddddddda', frequency: 0.005472637 },
      { format: 'aaaadddddddd', frequency: 0.005472637 },
      { format: 'aaadddddddddd', frequency: 0.0039800997 },
      { format: 'adadddddddddddd', frequency: 0.0039800997 },
      { format: 'dddaddddd', frequency: 0.0029850747 },
      { format: 'adaadddddddd', frequency: 0.0019900498 },
      { format: 'aaadaaadddddddd', frequency: 0.0019900498 },
      { format: 'aaddddddd', frequency: 0.0019900498 },
      { format: 'aaaddddddddddd', frequency: 0.0014925373 },
    ],
  },
  {
    front_end_carrier_id: 28,
    prefixes: [
      { prefix: 'TOAAN', frequency: 0.06846473 },
      { prefix: 'KYHAN', frequency: 0.06327801 },
      { prefix: 'AJW', frequency: 0.056016598 },
      { prefix: 'Y', frequency: 0.056016598 },
      { prefix: 'U', frequency: 0.05394191 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.47302905 },
      { format: 'aaaaaddddddd', frequency: 0.23962656 },
      { format: 'aaaddddddddd', frequency: 0.06846473 },
      { format: 'aaadddddddaa', frequency: 0.05912863 },
      { format: 'adadddaddddd', frequency: 0.045643155 },
      { format: 'adddddddd', frequency: 0.030082988 },
      { format: 'adadddddddaa', frequency: 0.029045643 },
      { format: 'adaddddddddd', frequency: 0.008298756 },
      { format: 'aaadddddddddddd', frequency: 0.007261411 },
      { format: 'aaadddddddda', frequency: 0.0062240665 },
      { format: 'aaaadddddddd', frequency: 0.004149378 },
      { format: 'aaasddddddddd', frequency: 0.004149378 },
      { format: 'aaadddddddddd', frequency: 0.0031120332 },
      { format: 'adaaaddddddd', frequency: 0.0031120332 },
      { format: 'dddaddddd', frequency: 0.0031120332 },
      { format: 'aaddddddd', frequency: 0.002074689 },
      { format: 'adaddddddddddd', frequency: 0.0010373445 },
      { format: 'aaaadddadddd', frequency: 0.0010373445 },
      { format: 'aaadddddddddhdd', frequency: 0.0010373445 },
      { format: 'aaasaaddddddd', frequency: 0.0010373445 },
      { format: 'aaassdddddddaa', frequency: 0.0010373445 },
      { format: 'aaahadddddddd', frequency: 0.0010373445 },
      { format: 'aaaddddddddda', frequency: 0.0010373445 },
      { format: 'ddddddddd', frequency: 0.0010373445 },
      { format: 'xaaadddaddddd', frequency: 0.0010373445 },
      { format: 'aaasdddddddddsdd', frequency: 0.0010373445 },
      { format: 'aaasadddddddd', frequency: 0.0010373445 },
      { format: 'adasddddddddd', frequency: 0.0010373445 },
      { format: 'dddddddddd', frequency: 0.0010373445 },
    ],
  },
  {
    front_end_carrier_id: 29,
    prefixes: [
      { prefix: 'R', frequency: 0.08067941 },
      { prefix: 'BEY09719', frequency: 0.07855626 },
      { prefix: 'S6R097', frequency: 0.06581741 },
      { prefix: 'V', frequency: 0.061571125 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddddddddd', frequency: 0.3481953 },
      { format: 'aaadddaddddd', frequency: 0.1762208 },
      { format: 'adadddddddddd', frequency: 0.08704883 },
      { format: 'adddddddd', frequency: 0.08280255 },
      { format: 'adadddaddddd', frequency: 0.08067941 },
      { format: 'aaaddddddddd', frequency: 0.046709128 },
      { format: 'aaaaaddddddd', frequency: 0.044585988 },
      { format: 'aaadddddddda', frequency: 0.021231422 },
      { format: 'aaadddddddaa', frequency: 0.01910828 },
      { format: 'adaddddddddd', frequency: 0.014861995 },
      { format: 'adadddddddaa', frequency: 0.010615711 },
      { format: 'aaadddddddddddd', frequency: 0.010615711 },
      { format: 'aaaddddddddddd', frequency: 0.010615711 },
      { format: 'aaaaaadddddd', frequency: 0.008492569 },
      { format: 'dddddddddd', frequency: 0.006369427 },
      { format: 'aaaddddaddddd', frequency: 0.0042462847 },
      { format: 'aaadddddddddsdd', frequency: 0.0042462847 },
      { format: 'aaaadddddddd', frequency: 0.0042462847 },
      { format: 'adadddddddddddd', frequency: 0.0042462847 },
      { format: 'aaassdddddddaa', frequency: 0.0021231424 },
      { format: 'aaadaaadddddddd', frequency: 0.0021231424 },
      { format: 'dddaddddd', frequency: 0.0021231424 },
      { format: 'aaaadddadddd', frequency: 0.0021231424 },
      { format: 'aaasddddddddd', frequency: 0.0021231424 },
      { format: 'aaasdddddddddd', frequency: 0.0021231424 },
      { format: 'adaaaddddddd', frequency: 0.0021231424 },
    ],
  },
  {
    front_end_carrier_id: 30,
    prefixes: [
      { prefix: 'A', frequency: 0.08988095 },
      { prefix: 'UCR', frequency: 0.08392857 },
      { prefix: 'M', frequency: 0.06488095 },
      { prefix: 'J', frequency: 0.060714286 },
      { prefix: 'E', frequency: 0.060119048 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.58988094 },
      { format: 'adadddaddddd', frequency: 0.12142857 },
      { format: 'aaaddddddddd', frequency: 0.0625 },
      { format: 'aaaaaddddddd', frequency: 0.060119048 },
      { format: 'adddddddd', frequency: 0.033333335 },
      { format: 'aaadddddddddddd', frequency: 0.023214286 },
      { format: 'aaaddadddddd', frequency: 0.019642858 },
      { format: 'adadddddddaa', frequency: 0.017261906 },
      { format: 'aaadddddddaa', frequency: 0.01607143 },
      { format: 'aaadddddddda', frequency: 0.00952381 },
      { format: 'aaaadddddddd', frequency: 0.008928572 },
      { format: 'adaaaddddddd', frequency: 0.006547619 },
      { format: 'aaaddddddaaa', frequency: 0.004761905 },
      { format: 'adaddddddddd', frequency: 0.004761905 },
      { format: 'dddaddddd', frequency: 0.004166667 },
      { format: 'aaaddddddddddd', frequency: 0.0023809525 },
      { format: 'aaadaaadddddddd', frequency: 0.0023809525 },
      { format: 'adadddddddddddd', frequency: 0.0023809525 },
      { format: 'aaadaddddddd', frequency: 0.0011904762 },
      { format: 'adddddddddaa', frequency: 0.0011904762 },
      { format: 'aaaadddddddddd', frequency: 0.0011904762 },
    ],
  },
  {
    front_end_carrier_id: 31,
    prefixes: [
      { prefix: 'J', frequency: 0.0900474 },
      { prefix: 'YFW', frequency: 0.08688784 },
      { prefix: 'R', frequency: 0.061611373 },
      { prefix: 'Z', frequency: 0.056872036 },
      { prefix: 'V', frequency: 0.05529226 },
      { prefix: 'G', frequency: 0.05371248 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.49605057 },
      { format: 'aaaddddddddd', frequency: 0.12164297 },
      { format: 'adadddaddddd', frequency: 0.07740916 },
      { format: 'adddddddd', frequency: 0.06477093 },
      { format: 'aaaaaddddddd', frequency: 0.025276462 },
      { format: 'aaadddddddaa', frequency: 0.023696683 },
      { format: 'aaaaaadddddd', frequency: 0.020537125 },
      { format: 'adaddddddddd', frequency: 0.018957347 },
      { format: 'aaadddddddda', frequency: 0.015797788 },
      { format: 'ddddddddddd', frequency: 0.015797788 },
      { format: 'adadddddddddd', frequency: 0.014218009 },
      { format: 'aaadddddddddddd', frequency: 0.012638231 },
      { format: 'aaaddddddddddd', frequency: 0.011058452 },
      { format: 'adaaaddddddd', frequency: 0.009478673 },
      { format: 'dddaddddd', frequency: 0.007898894 },
      { format: 'aaadaddddddd', frequency: 0.007898894 },
      { format: 'adadddddddaa', frequency: 0.007898894 },
      { format: 'aaaddddddadd', frequency: 0.0063191154 },
      { format: 'aaaadddddddd', frequency: 0.0063191154 },
      { format: 'aaaadddddddddddd', frequency: 0.0047393367 },
      { format: 'adaadddddddd', frequency: 0.0047393367 },
      { format: 'aaasdddaddddd', frequency: 0.0031595577 },
      { format: 'daddddddd', frequency: 0.0031595577 },
      { format: 'adadddddddddddd', frequency: 0.0031595577 },
      { format: 'aaasddddddddd', frequency: 0.0031595577 },
      { format: 'aaassdddddddaa', frequency: 0.0015797789 },
      { format: 'daadddaddddd', frequency: 0.0015797789 },
      { format: 'adadaddddddd', frequency: 0.0015797789 },
      { format: 'adadddddddda', frequency: 0.0015797789 },
      { format: 'aaaddddddddda', frequency: 0.0015797789 },
      { format: 'aaadddddd', frequency: 0.0015797789 },
      { format: 'aaaaddddd', frequency: 0.0015797789 },
      { format: 'aaaadddadddd', frequency: 0.0015797789 },
      { format: 'adddddaddddd', frequency: 0.0015797789 },
    ],
  },
  {
    front_end_carrier_id: 32,
    prefixes: [
      { prefix: 'BPP097', frequency: 0.08430233 },
      { prefix: 'BQE097', frequency: 0.068313956 },
      { prefix: 'X3A097', frequency: 0.05087209 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddddddddd', frequency: 0.30523255 },
      { format: 'aaadddaddddd', frequency: 0.24273255 },
      { format: 'adadddddddddd', frequency: 0.09156977 },
      { format: 'adadddaddddd', frequency: 0.075581394 },
      { format: 'aaaaaddddddd', frequency: 0.056686047 },
      { format: 'aaaddddddddd', frequency: 0.05523256 },
      { format: 'aaadddddddaa', frequency: 0.05087209 },
      { format: 'adddddddd', frequency: 0.039244186 },
      { format: 'adadddddddaa', frequency: 0.030523255 },
      { format: 'aaadddddddda', frequency: 0.011627907 },
      { format: 'aaadddddddddddd', frequency: 0.011627907 },
      { format: 'adaaaddddddd', frequency: 0.0058139535 },
      { format: 'adadddddddddddd', frequency: 0.0043604653 },
      { format: 'adaddddddddd', frequency: 0.0029069767 },
      { format: 'aaaadddddddd', frequency: 0.0029069767 },
      { format: 'aaaaaadddddd', frequency: 0.0014534884 },
      { format: 'aaassdddddddaa', frequency: 0.0014534884 },
      { format: 'aaasadddddddd', frequency: 0.0014534884 },
      { format: 'dddaddddd', frequency: 0.0014534884 },
      { format: 'aaaadddadddd', frequency: 0.0014534884 },
      { format: 'aaasddddddddd', frequency: 0.0014534884 },
      { format: 'dddddddddd', frequency: 0.0014534884 },
      { format: 'adadddddddda', frequency: 0.0014534884 },
      { format: 'aaadaaadddddddd', frequency: 0.0014534884 },
    ],
  },
  {
    front_end_carrier_id: 33,
    prefixes: [
      { prefix: 'AKH', frequency: 0.06853707 },
      { prefix: 'R', frequency: 0.06412826 },
      { prefix: 'E', frequency: 0.06242485 },
      { prefix: 'C', frequency: 0.056813627 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.123647295 }],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.50841683 },
      { format: 'aaaaaddddddd', frequency: 0.11272545 },
      { format: 'aaaddddddddd', frequency: 0.08016032 },
      { format: 'adadddaddddd', frequency: 0.060220443 },
      { format: 'adddddddd', frequency: 0.0496994 },
      { format: 'aaadddddddaa', frequency: 0.047895793 },
      { format: 'aaadddddddddddd', frequency: 0.030961923 },
      { format: 'adadddddddaa', frequency: 0.020841684 },
      { format: 'dddddddddddd', frequency: 0.017635271 },
      { format: 'adaddddddddd', frequency: 0.014529058 },
      { format: 'aaaadddddddd', frequency: 0.008216433 },
      { format: 'adaaaddddddd', frequency: 0.0071142283 },
      { format: 'aaadddddddddd', frequency: 0.0056112222 },
      { format: 'adadddddddddddd', frequency: 0.0054108216 },
      { format: 'aaadddddddda', frequency: 0.0041082166 },
      { format: 'aaaddddddddddd', frequency: 0.0035070141 },
      { format: 'dddaddddd', frequency: 0.0032064128 },
      { format: 'aaadaaadddddddd', frequency: 0.00250501 },
      { format: 'ddddddddd', frequency: 0.0022044089 },
      { format: 'aaasddddddddd', frequency: 0.0021042083 },
      { format: 'aaaadddddddddd', frequency: 0.0014028056 },
      { format: 'aaadaddddddd', frequency: 0.0013026053 },
    ],
  },
  {
    front_end_carrier_id: 34,
    prefixes: [
      { prefix: 'FVI', frequency: 0.1095865 },
      { prefix: 'M', frequency: 0.05687929 },
      { prefix: 'X', frequency: 0.054515112 },
      { prefix: 'Z4U', frequency: 0.05321713 },
      { prefix: 'VQX', frequency: 0.052707214 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.40733358 },
      { format: 'adddddddd', frequency: 0.12831448 },
      { format: 'aaadddddddaa', frequency: 0.12789728 },
      { format: 'adadddaddddd', frequency: 0.12557946 },
      { format: 'aaaddddddddd', frequency: 0.096514 },
      { format: 'aaaaaddddddd', frequency: 0.029992582 },
      { format: 'aaadddddddddddd', frequency: 0.020767663 },
      { format: 'adaddddddddd', frequency: 0.010244762 },
      { format: 'adadddddddaa', frequency: 0.0066753197 },
      { format: 'aaaddddddddddd', frequency: 0.006119043 },
      { format: 'aaadddddddda', frequency: 0.006072687 },
      { format: 'dddaddddd', frequency: 0.004033006 },
      { format: 'aaasddddddddd', frequency: 0.0036621545 },
      { format: 'adadddddddddddd', frequency: 0.003105878 },
      { format: 'adaaaddddddd', frequency: 0.0026423142 },
      { format: 'aaadaaadddddddd', frequency: 0.002271463 },
      { format: 'aaaadddddddd', frequency: 0.0021787502 },
      { format: 'aaadddddddddd', frequency: 0.002132394 },
      { format: 'aaadddddd', frequency: 0.0017151864 },
    ],
  },
  {
    front_end_carrier_id: 35,
    prefixes: [
      { prefix: 'R', frequency: 0.08456973 },
      { prefix: 'F', frequency: 0.0727003 },
      { prefix: 'A', frequency: 0.06231454 },
      { prefix: 'E', frequency: 0.05637982 },
      { prefix: 'W', frequency: 0.053412464 },
      { prefix: 'X', frequency: 0.050445102 },
      { prefix: 'V', frequency: 0.050445102 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.4198813 },
      { format: 'adadddaddddd', frequency: 0.18397626 },
      { format: 'aaaddddddddd', frequency: 0.100890204 },
      { format: 'aaaaaddddddd', frequency: 0.09792285 },
      { format: 'aaadddddddaa', frequency: 0.0578635 },
      { format: 'adddddddd', frequency: 0.047477745 },
      { format: 'adaddddddddd', frequency: 0.013353116 },
      { format: 'aaadddddddda', frequency: 0.010385756 },
      { format: 'aaaadddddddd', frequency: 0.007418398 },
      { format: 'adadddddddaa', frequency: 0.007418398 },
      { format: 'aaadddddddddddd', frequency: 0.007418398 },
      { format: 'dddaddddd', frequency: 0.005934718 },
      { format: 'adaaaddddddd', frequency: 0.004451039 },
      { format: 'dddddddddd', frequency: 0.004451039 },
      { format: 'adadddddddddddd', frequency: 0.002967359 },
      { format: 'aaaddadddddd', frequency: 0.002967359 },
      { format: 'ddddddddd', frequency: 0.002967359 },
      { format: 'aaadddddddddd', frequency: 0.002967359 },
      { format: 'xaaadddaddddd', frequency: 0.0014836795 },
      { format: 'daadhaadhaadd', frequency: 0.0014836795 },
      { format: 'aaadddddd', frequency: 0.0014836795 },
      { format: 'aaaadddddddddd', frequency: 0.0014836795 },
      { format: 'aaaddddddddddd', frequency: 0.0014836795 },
      { format: 'aaaaaadaadad', frequency: 0.0014836795 },
      { format: 'daaadddddddd', frequency: 0.0014836795 },
      { format: 'adaaaadddddd', frequency: 0.0014836795 },
      { format: 'aaaadddadddd', frequency: 0.0014836795 },
      { format: 'adaadddddddd', frequency: 0.0014836795 },
      { format: 'aaasddddddddd', frequency: 0.0014836795 },
      { format: 'aaadaaadddddddd', frequency: 0.0014836795 },
      { format: 'asdsdsdsdsdsdsdsd', frequency: 0.0014836795 },
    ],
  },
  {
    front_end_carrier_id: 36,
    prefixes: [
      { prefix: 'W', frequency: 0.38621444 },
      { prefix: 'WMW', frequency: 0.16083151 },
      { prefix: 'WDD', frequency: 0.07111597 },
      { prefix: 'X', frequency: 0.07002188 },
      { prefix: 'WMW1', frequency: 0.06345733 },
      { prefix: 'WMW0', frequency: 0.060175054 },
      { prefix: 'M', frequency: 0.060175054 },
    ],
    suffixes: [
      { suffix: 'W', frequency: 0.3238512 },
      { suffix: '1', frequency: 0.12363239 },
    ],
    formats: [
      { format: 'aaadddddddda', frequency: 0.37417942 },
      { format: 'aaadddaddddd', frequency: 0.18490154 },
      { format: 'aaaadddddddddd', frequency: 0.09409191 },
      { format: 'aaaddddddddd', frequency: 0.072210066 },
      { format: 'aaaddddddddddd', frequency: 0.07002188 },
      { format: 'adadddaddddd', frequency: 0.037199125 },
      { format: 'aaaadddddddd', frequency: 0.020787746 },
      { format: 'aaaddddddddadd', frequency: 0.019693654 },
      { format: 'aaaaaddddddd', frequency: 0.01750547 },
      { format: 'adddddddd', frequency: 0.014223195 },
      { format: 'aaadddddddddddd', frequency: 0.014223195 },
      { format: 'adadddddddda', frequency: 0.008752735 },
      { format: 'aaadddddddaa', frequency: 0.007658643 },
      { format: 'adaaaddddddd', frequency: 0.0065645515 },
      { format: 'dddddddda', frequency: 0.0065645515 },
      { format: 'adaddddddddd', frequency: 0.0054704593 },
      { format: 'adddddddddd', frequency: 0.0043763677 },
      { format: 'aaaddddddda', frequency: 0.0043763677 },
      { format: 'adaadddddddddd', frequency: 0.0043763677 },
      { format: 'adadddddddaa', frequency: 0.0032822757 },
      { format: 'dddddd', frequency: 0.0021881838 },
      { format: 'adaddddddddddd', frequency: 0.0021881838 },
      { format: 'aaaddddddadd', frequency: 0.0021881838 },
      { format: 'aaaadddadddd', frequency: 0.0021881838 },
      { format: 'aaadddddddddd', frequency: 0.0021881838 },
      { format: 'aaaddddddddda', frequency: 0.0021881838 },
      { format: 'addaddddd', frequency: 0.0010940919 },
      { format: 'ddddddddd', frequency: 0.0010940919 },
      { format: 'adaddddddddadd', frequency: 0.0010940919 },
      { format: 'aaasadddddddd', frequency: 0.0010940919 },
      { format: 'aaadddddddad', frequency: 0.0010940919 },
      { format: 'ddddddddddd', frequency: 0.0010940919 },
      { format: 'dddddddd', frequency: 0.0010940919 },
      { format: 'adadddddddddd', frequency: 0.0010940919 },
      { format: 'adddddddda', frequency: 0.0010940919 },
      { format: 'ddddddddadd', frequency: 0.0010940919 },
      { format: 'aaaaddaddddddd', frequency: 0.0010940919 },
      { format: 'aaasddddddddd', frequency: 0.0010940919 },
      { format: 'adadddddddddddd', frequency: 0.0010940919 },
      { format: 'aaadddadddddx', frequency: 0.0010940919 },
      { format: 'adadddddd', frequency: 0.0010940919 },
    ],
  },
  {
    front_end_carrier_id: 37,
    prefixes: [
      { prefix: 'YAQ86', frequency: 0.23372781 },
      { prefix: 'YAQ869', frequency: 0.17751479 },
      { prefix: 'YAQ8693', frequency: 0.10355029 },
      { prefix: 'YAX86', frequency: 0.0739645 },
      { prefix: 'H', frequency: 0.06213018 },
      { prefix: 'SFQ86', frequency: 0.05029586 },
    ],
    suffixes: [{ suffix: 'M', frequency: 0.5088757 }],
    formats: [
      { format: 'aaaddddddddda', frequency: 0.4408284 },
      { format: 'aaaddddddddd', frequency: 0.15088758 },
      { format: 'aaasddddddddda', frequency: 0.07988165 },
      { format: 'adddddddd', frequency: 0.06213018 },
      { format: 'aaadddaddddd', frequency: 0.056213018 },
      { format: 'aaadddddddddddd', frequency: 0.053254437 },
      { format: 'adaddddddddd', frequency: 0.02366864 },
      { format: 'aaadaaadddddddd', frequency: 0.020710059 },
      { format: 'aaaadddddddd', frequency: 0.0147929 },
      { format: 'adadddaddddd', frequency: 0.0147929 },
      { format: 'ddddddddda', frequency: 0.01183432 },
      { format: 'aaaaaddddddd', frequency: 0.00887574 },
      { format: 'aaadddddddda', frequency: 0.00887574 },
      { format: 'aaaddddddddddd', frequency: 0.00887574 },
      { format: 'aaaaaadddddd', frequency: 0.00591716 },
      { format: 'aaadddddddddd', frequency: 0.00591716 },
      { format: 'adadddddddddd', frequency: 0.00591716 },
      { format: 'aaasddddddddd', frequency: 0.00591716 },
      { format: 'adadddddddaa', frequency: 0.00295858 },
      { format: 'aaadddddddaa', frequency: 0.00295858 },
      { format: 'aaadaddaadaa', frequency: 0.00295858 },
      { format: 'aaadddadddddd', frequency: 0.00295858 },
      { format: 'ddddddddd', frequency: 0.00295858 },
      { format: 'aaadddddddddsa', frequency: 0.00295858 },
      { format: 'adaaaddddddd', frequency: 0.00295858 },
    ],
  },
  {
    front_end_carrier_id: 38,
    prefixes: [
      { prefix: 'R', frequency: 0.074010745 },
      { prefix: 'S3Z85', frequency: 0.061309233 },
      { prefix: 'XBM852', frequency: 0.060576454 },
      { prefix: 'P', frequency: 0.060332194 },
      { prefix: 'XBP852', frequency: 0.058622375 },
      { prefix: 'T', frequency: 0.054958478 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.12139717 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5522716 },
      { format: 'adaddddddddd', frequency: 0.15168539 },
      { format: 'aaadddaddddd', frequency: 0.085735224 },
      { format: 'adddddddd', frequency: 0.06985833 },
      { format: 'aaadddddddddddd', frequency: 0.031998046 },
      { format: 'aaaadddddddd', frequency: 0.014899854 },
      { format: 'adadddaddddd', frequency: 0.014167074 },
      { format: 'aaadddddddda', frequency: 0.0122129945 },
      { format: 'aaaaaddddddd', frequency: 0.010258916 },
      { format: 'aaaddddddddddd', frequency: 0.009037616 },
      { format: 'aaadaaadddddddd', frequency: 0.005129458 },
      { format: 'aaadddddddddd', frequency: 0.004640938 },
      { format: 'aaasddddddddd', frequency: 0.004152418 },
      { format: 'aaadddddddaa', frequency: 0.0034196386 },
      { format: 'adadddddddddddd', frequency: 0.0031753785 },
      { format: 'adadddddddddd', frequency: 0.0026868589 },
      { format: 'adaaaddddddd', frequency: 0.002442599 },
      { format: 'ddddddddd', frequency: 0.0017098193 },
      { format: 'ddddddddddd', frequency: 0.0014655594 },
      { format: 'dddddddddd', frequency: 0.0014655594 },
      { format: 'adadddddddaa', frequency: 0.0012212995 },
    ],
  },
  {
    front_end_carrier_id: 39,
    prefixes: [
      { prefix: 'STH', frequency: 0.09050191 },
      { prefix: 'SJN', frequency: 0.07609889 },
      { prefix: 'U', frequency: 0.05800729 },
      { prefix: 'XK', frequency: 0.055416502 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.38475388 },
      { format: 'aaaadddadddd', frequency: 0.18644887 },
      { format: 'aaaddddddddd', frequency: 0.090414084 },
      { format: 'adadddaddddd', frequency: 0.06959996 },
      { format: 'adddddddd', frequency: 0.066657886 },
      { format: 'aaadddddddda', frequency: 0.034997586 },
      { format: 'aaadddddddddddd', frequency: 0.03214333 },
      { format: 'aaadddddddaa', frequency: 0.028806042 },
      { format: 'aaaaaddddddd', frequency: 0.026215255 },
      { format: 'adadddddddaa', frequency: 0.013349142 },
      { format: 'adaddddddddd', frequency: 0.011812233 },
      { format: 'adaaaddddddd', frequency: 0.010363149 },
      { format: 'aaaadddddddd', frequency: 0.009353181 },
      { format: 'aaaddddddddddd', frequency: 0.0054889563 },
      { format: 'dddaddddd', frequency: 0.0054889563 },
      { format: 'adadddddddddddd', frequency: 0.0029859922 },
      { format: 'aaadaaadddddddd', frequency: 0.0022394941 },
      { format: 'aaasddddddddd', frequency: 0.001888201 },
      { format: 'aaadddddddddd', frequency: 0.001888201 },
      { format: 'aaaddadddddd', frequency: 0.001624731 },
      { format: 'aaadddddddd', frequency: 0.0011856145 },
    ],
  },
  {
    front_end_carrier_id: 40,
    prefixes: [
      { prefix: 'XY', frequency: 0.25981107 },
      { prefix: 'XYQ', frequency: 0.16243061 },
      { prefix: 'M', frequency: 0.10439186 },
      { prefix: 'A', frequency: 0.06923751 },
      { prefix: 'XYQ9', frequency: 0.068653226 },
      { prefix: 'G', frequency: 0.053461876 },
      { prefix: 'XYQM', frequency: 0.05278021 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.623235 },
      { format: 'aaaadddddddd', frequency: 0.24958614 },
      { format: 'aaadddaddddd', frequency: 0.026877008 },
      { format: 'adddddddd', frequency: 0.024734639 },
      { format: 'aaadddddddddddd', frequency: 0.011880416 },
      { format: 'adaadddddddd', frequency: 0.011393514 },
      { format: 'adaddddddddd', frequency: 0.008959003 },
      { format: 'ddddddddd', frequency: 0.006719252 },
      { format: 'aaaaaddddddd', frequency: 0.0053559258 },
      { format: 'adadddaddddd', frequency: 0.005161165 },
      { format: 'aaadddddddddd', frequency: 0.0044795014 },
      { format: 'aaasddddddddd', frequency: 0.0025318922 },
      { format: 'aaadddddddda', frequency: 0.0023371312 },
      { format: 'aaadddddddaa', frequency: 0.0022397507 },
      { format: 'adadddddddddddd', frequency: 0.0022397507 },
      { format: 'aaaddddddddddd', frequency: 0.0019476094 },
      { format: 'adadddddddaa', frequency: 0.0016554679 },
      { format: 'aaadaaadddddddd', frequency: 0.0016554679 },
      { format: 'dddddddddd', frequency: 0.0015580874 },
      { format: 'aaahddddddddd', frequency: 0.0013633266 },
      { format: 'aaahadddddddd', frequency: 0.0011685656 },
    ],
  },
  {
    front_end_carrier_id: 43,
    prefixes: [
      { prefix: 'P', frequency: 0.32717296 },
      { prefix: 'PPA8', frequency: 0.26537684 },
      { prefix: 'E', frequency: 0.11435172 },
      { prefix: 'PPA80', frequency: 0.076812014 },
      { prefix: 'L', frequency: 0.070747904 },
      { prefix: 'R', frequency: 0.06786024 },
      { prefix: 'B', frequency: 0.057464626 },
      { prefix: 'EDU8', frequency: 0.05717586 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.847531 },
      { format: 'adddddddd', frequency: 0.04562518 },
      { format: 'adaddddddddd', frequency: 0.03782847 },
      { format: 'aaadddaddddd', frequency: 0.015882183 },
      { format: 'aaadddddddddddd', frequency: 0.013860814 },
      { format: 'adadddaddddd', frequency: 0.0057753394 },
      { format: 'aaaadddddddd', frequency: 0.005486572 },
      { format: 'aaadddddddda', frequency: 0.0043315045 },
      { format: 'aaaaaddddddd', frequency: 0.0040427377 },
      { format: 'aaasddddddddd', frequency: 0.0037539706 },
      { format: 'aaadaaadddddddd', frequency: 0.0020213688 },
      { format: 'adadddddddaa', frequency: 0.0017326018 },
      { format: 'adadddddddddddd', frequency: 0.0017326018 },
      { format: 'aaaddddddddddd', frequency: 0.0017326018 },
      { format: 'aaaadddadddd', frequency: 0.0014438349 },
      { format: 'aaadddddddddd', frequency: 0.0011550678 },
    ],
  },
  {
    front_end_carrier_id: 45,
    prefixes: [
      { prefix: 'XO', frequency: 0.23199877 },
      { prefix: 'XOF8', frequency: 0.14226109 },
      { prefix: 'A', frequency: 0.05335174 },
      { prefix: 'B', frequency: 0.052922226 },
      { prefix: 'C', frequency: 0.052799508 },
      { prefix: 'P', frequency: 0.05141893 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.81782484 },
      { format: 'adaddddddddd', frequency: 0.08019635 },
      { format: 'aaadddaddddd', frequency: 0.024114128 },
      { format: 'adddddddd', frequency: 0.016935112 },
      { format: 'aaadddddddddddd', frequency: 0.011965025 },
      { format: 'adadddddddaa', frequency: 0.0095106615 },
      { format: 'aaaadddddddd', frequency: 0.008068722 },
      { format: 'adadddaddddd', frequency: 0.006227949 },
      { format: 'aaaaaddddddd', frequency: 0.005399601 },
      { format: 'aaadddddddda', frequency: 0.003344071 },
      { format: 'aaadddddddaa', frequency: 0.003282712 },
      { format: 'aaaddddddddddd', frequency: 0.0021782483 },
      { format: 'aaasddddddddd', frequency: 0.0019634913 },
      { format: 'aaadaaadddddddd', frequency: 0.0014419389 },
      { format: 'aaadddddddddd', frequency: 0.0013499003 },
      { format: 'adadddddddddddd', frequency: 0.0010124252 },
    ],
  },
  {
    front_end_carrier_id: 46,
    prefixes: [
      { prefix: 'XSB8', frequency: 0.29473683 },
      { prefix: 'KSE8', frequency: 0.07105263 },
      { prefix: 'YBC', frequency: 0.05263158 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5105263 },
      { format: 'aaadddddddddddd', frequency: 0.21052632 },
      { format: 'aaaddadddddd', frequency: 0.14473684 },
      { format: 'aaadddaddddd', frequency: 0.028947368 },
      { format: 'adddddddd', frequency: 0.023684211 },
      { format: 'adaddddddddd', frequency: 0.010526316 },
      { format: 'aaadddddddda', frequency: 0.010526316 },
      { format: 'aaaaaddddddd', frequency: 0.007894737 },
      { format: 'adadddaddddd', frequency: 0.007894737 },
      { format: 'aaaadddddddd', frequency: 0.007894737 },
      { format: 'aaasddddddddd', frequency: 0.005263158 },
      { format: 'aaadaaadddddddd', frequency: 0.005263158 },
      { format: 'adadddddddddddd', frequency: 0.005263158 },
      { format: 'aaaddddddddddd', frequency: 0.005263158 },
      { format: 'aaaadddddddddd', frequency: 0.002631579 },
      { format: 'aaadddddddd', frequency: 0.002631579 },
      { format: 'aaadddddddaa', frequency: 0.002631579 },
      { format: 'adaadddddddd', frequency: 0.002631579 },
      { format: 'aaaddaaddddd', frequency: 0.002631579 },
      { format: 'aaadddddddddd', frequency: 0.002631579 },
    ],
  },
  {
    front_end_carrier_id: 47,
    prefixes: [
      { prefix: 'YB', frequency: 0.36253777 },
      { prefix: 'K', frequency: 0.1389728 },
      { prefix: 'YBP', frequency: 0.0795569 },
      { prefix: 'R', frequency: 0.057401814 },
      { prefix: 'KPM100010', frequency: 0.053373616 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddadddddd', frequency: 0.5599194 },
      { format: 'aaadddddddddddd', frequency: 0.21450152 },
      { format: 'aaaddddddddd', frequency: 0.06747231 },
      { format: 'adddddddd', frequency: 0.04431017 },
      { format: 'aaadddaddddd', frequency: 0.026183283 },
      { format: 'adadddaddddd', frequency: 0.01510574 },
      { format: 'aaaddaaddddd', frequency: 0.0130916415 },
      { format: 'aaaadddddddd', frequency: 0.011077543 },
      { format: 'adaddddddddd', frequency: 0.0070493454 },
      { format: 'aaasddadddddd', frequency: 0.006042296 },
      { format: 'aaaaaaaddddd', frequency: 0.0050352467 },
      { format: 'aaaaaddddddd', frequency: 0.0040281974 },
      { format: 'aaadaaadddddddd', frequency: 0.003021148 },
      { format: 'aaaddddddddddd', frequency: 0.003021148 },
      { format: 'aaasddddddddd', frequency: 0.003021148 },
      { format: 'adadaaadddddddd', frequency: 0.0020140987 },
      { format: 'adadddddddddddd', frequency: 0.0020140987 },
      { format: 'aaaddddddddhdd', frequency: 0.0010070493 },
      { format: 'aaasddsadddddd', frequency: 0.0010070493 },
      { format: 'adasddddddddd', frequency: 0.0010070493 },
      { format: 'aaaddadddddddd', frequency: 0.0010070493 },
      { format: 'aaadddddd', frequency: 0.0010070493 },
      { format: 'aaaddddddaaa', frequency: 0.0010070493 },
      { format: 'adadddddddaa', frequency: 0.0010070493 },
      { format: 'aaadddddddda', frequency: 0.0010070493 },
      { format: 'aaadaddddddd', frequency: 0.0010070493 },
      { format: 'aaaaddddddd', frequency: 0.0010070493 },
      { format: 'adaddddddddddd', frequency: 0.0010070493 },
      { format: 'aaaddsaaddddd', frequency: 0.0010070493 },
      { format: 'aaadddddddaa', frequency: 0.0010070493 },
    ],
  },
  {
    front_end_carrier_id: 48,
    prefixes: [
      { prefix: 'XUP20', frequency: 0.33333334 },
      { prefix: 'XUP203', frequency: 0.23529412 },
      { prefix: 'OGS20', frequency: 0.08144797 },
      { prefix: 'R', frequency: 0.0693816 },
      { prefix: 'XUP2033', frequency: 0.06787331 },
      { prefix: 'XUP202', frequency: 0.06334842 },
      { prefix: 'XUP2032', frequency: 0.051282052 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.74811465 },
      { format: 'aaadddddddddddd', frequency: 0.108597286 },
      { format: 'adddddddd', frequency: 0.049773756 },
      { format: 'aaadddaddddd', frequency: 0.028657617 },
      { format: 'ddddddddd', frequency: 0.02413273 },
      { format: 'aaadddddddddd', frequency: 0.01055807 },
      { format: 'aaadddddddda', frequency: 0.004524887 },
      { format: 'adaddddddddd', frequency: 0.004524887 },
      { format: 'adadddaddddd', frequency: 0.0030165913 },
      { format: 'aaadaaadddddddd', frequency: 0.0030165913 },
      { format: 'aaaaaddddddd', frequency: 0.0030165913 },
      { format: 'adadddddddaa', frequency: 0.0015082957 },
      { format: 'aadddddddddd', frequency: 0.0015082957 },
      { format: 'daaddddddddd', frequency: 0.0015082957 },
      { format: 'asdsdsdsdsdsdsdsd', frequency: 0.0015082957 },
      { format: 'aaadddddddaa', frequency: 0.0015082957 },
      { format: 'adaadddddddddd', frequency: 0.0015082957 },
      { format: 'adadddddddddddd', frequency: 0.0015082957 },
      { format: 'aaasddddddddd', frequency: 0.0015082957 },
    ],
  },
  {
    front_end_carrier_id: 49,
    prefixes: [
      { prefix: 'MTN9', frequency: 0.17890243 },
      { prefix: 'XXP96', frequency: 0.14547813 },
      { prefix: 'MTN96', frequency: 0.12310519 },
      { prefix: 'MTN98', frequency: 0.054230053 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.12283499 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.78251237 },
      { format: 'aaadddaddddd', frequency: 0.030235888 },
      { format: 'adddddddd', frequency: 0.029236132 },
      { format: 'adaddddddddd', frequency: 0.024777757 },
      { format: 'aaadddddddddddd', frequency: 0.019968117 },
      { format: 'ddddddddddddd', frequency: 0.01310492 },
      { format: 'aaaadddddddd', frequency: 0.010267773 },
      { format: 'ddddddddd', frequency: 0.009349077 },
      { format: 'aaadddddddddd', frequency: 0.008214219 },
      { format: 'aaaaaddddddd', frequency: 0.007646789 },
      { format: 'adadddaddddd', frequency: 0.0075657275 },
      { format: 'aaaddddddddddd', frequency: 0.006079602 },
      { format: 'aaadaaadddddddd', frequency: 0.0058904593 },
      { format: 'aaasddddddddd', frequency: 0.005160907 },
      { format: 'aaadddddddd', frequency: 0.004296252 },
      { format: 'aaadddddddda', frequency: 0.0037558433 },
      { format: 'aaadddddddaa', frequency: 0.0030533113 },
      { format: 'ddddddddddddddd', frequency: 0.0028911887 },
      { format: 'aaaddddddddddddd', frequency: 0.0025939636 },
      { format: 'adadddddddddddd', frequency: 0.0024318409 },
      { format: 'addddddddd', frequency: 0.0014591046 },
      { format: 'adadddddddaa', frequency: 0.0014320841 },
    ],
  },
  {
    front_end_carrier_id: 50,
    prefixes: [
      { prefix: 'JZH13', frequency: 0.09611554 },
      { prefix: 'TGT80', frequency: 0.05677291 },
      { prefix: 'P', frequency: 0.05179283 },
      { prefix: 'RB', frequency: 0.050298806 },
    ],
    suffixes: [{ suffix: '001', frequency: 0.689741 }],
    formats: [
      { format: 'aaadddddddddddd', frequency: 0.59561753 },
      { format: 'aaaddddddddd', frequency: 0.17181274 },
      { format: 'adadddddddddddd', frequency: 0.050298806 },
      { format: 'dddddddddddd', frequency: 0.026892431 },
      { format: 'aaaaaddddddd', frequency: 0.023406375 },
      { format: 'aaadddaddddd', frequency: 0.01992032 },
      { format: 'aaadddddddddd', frequency: 0.0154382475 },
      { format: 'adaddddddddd', frequency: 0.0154382475 },
      { format: 'aaaddddddddddd', frequency: 0.0139442235 },
      { format: 'adddddddd', frequency: 0.011952192 },
      { format: 'adadddaddddd', frequency: 0.00996016 },
      { format: 'adadddddddddd', frequency: 0.005478088 },
      { format: 'aaaddddddddddddd', frequency: 0.00498008 },
      { format: 'aaaadddddddd', frequency: 0.003984064 },
      { format: 'aaasddddddddd', frequency: 0.0034860559 },
      { format: 'aaadaaadddddddd', frequency: 0.002988048 },
      { format: 'aaa', frequency: 0.001992032 },
      { format: 'aaasdddddddddddd', frequency: 0.001992032 },
      { format: 'adadddddddaa', frequency: 0.001494024 },
      { format: 'aaadddddddd', frequency: 0.001494024 },
      { format: 'aaadddddddddddda', frequency: 0.001494024 },
      { format: 'adaddddddddddd', frequency: 0.001494024 },
    ],
  },
  {
    front_end_carrier_id: 52,
    prefixes: [
      { prefix: 'YNN100', frequency: 0.25 },
      { prefix: 'YNN1003', frequency: 0.09859155 },
      { prefix: 'YNN1004', frequency: 0.07394366 },
      { prefix: 'TRV100', frequency: 0.06690141 },
      { prefix: 'U', frequency: 0.06338028 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.16901408 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.8028169 },
      { format: 'adadddddddddddd', frequency: 0.04225352 },
      { format: 'adddddddd', frequency: 0.03169014 },
      { format: 'aaadddddddddddd', frequency: 0.03169014 },
      { format: 'aaadddaddddd', frequency: 0.02112676 },
      { format: 'aaadddddddddd', frequency: 0.017605634 },
      { format: 'aaaadddddddd', frequency: 0.01056338 },
      { format: 'aaaddddddddddd', frequency: 0.0070422534 },
      { format: 'ddddddddd', frequency: 0.0070422534 },
      { format: 'adadddaddddd', frequency: 0.0035211267 },
      { format: 'aadddddddd', frequency: 0.0035211267 },
      { format: 'aaadaaadddddddd', frequency: 0.0035211267 },
      { format: 'aaddddddddd', frequency: 0.0035211267 },
      { format: 'aaaaaddddddd', frequency: 0.0035211267 },
      { format: 'adaddddddddd', frequency: 0.0035211267 },
      { format: 'aaasddddddddd', frequency: 0.0035211267 },
      { format: 'ddddddddddd', frequency: 0.0035211267 },
    ],
  },
  {
    front_end_carrier_id: 53,
    prefixes: [
      { prefix: 'YIE8', frequency: 0.21585903 },
      { prefix: 'LAJ8', frequency: 0.07929515 },
      { prefix: 'R', frequency: 0.07268722 },
      { prefix: 'NMJ8', frequency: 0.066079296 },
      { prefix: 'YIE80', frequency: 0.059471365 },
      { prefix: 'VCB8', frequency: 0.059471365 },
      { prefix: 'YIE82', frequency: 0.05506608 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.13215859 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.85022026 },
      { format: 'adddddddd', frequency: 0.07488987 },
      { format: 'aaadddaddddd', frequency: 0.0154185025 },
      { format: 'adaddddddddd', frequency: 0.0066079297 },
      { format: 'aaadddddddddddd', frequency: 0.0066079297 },
      { format: 'aaasddddddddd', frequency: 0.0066079297 },
      { format: 'aaadddddddda', frequency: 0.0066079297 },
      { format: 'adadddddddaa', frequency: 0.0066079297 },
      { format: 'aaadaaadddddddd', frequency: 0.004405286 },
      { format: 'aaaaaddddddd', frequency: 0.004405286 },
      { format: 'aaadddddddddd', frequency: 0.004405286 },
      { format: 'adadddddddddd', frequency: 0.002202643 },
      { format: 'aaaadddddddd', frequency: 0.002202643 },
      { format: 'adadddaddddd', frequency: 0.002202643 },
      { format: 'adadddddddddddd', frequency: 0.002202643 },
      { format: 'adadaaadddddddd', frequency: 0.002202643 },
      { format: 'adasdddddddddsdd', frequency: 0.002202643 },
    ],
  },
  {
    front_end_carrier_id: 54,
    prefixes: [
      { prefix: 'Y', frequency: 0.51264125 },
      { prefix: 'YP', frequency: 0.4192045 },
      { prefix: 'YPY104', frequency: 0.14470407 },
      { prefix: 'R', frequency: 0.11801698 },
      { prefix: 'YPS10', frequency: 0.107386835 },
      { prefix: 'Y2', frequency: 0.06049288 },
      { prefix: 'YPI1', frequency: 0.054363787 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.18904424 },
      { suffix: '1', frequency: 0.12162421 },
      { suffix: '00', frequency: 0.106556855 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5979697 },
      { format: 'aaaddddddddddd', frequency: 0.11338824 },
      { format: 'adaddddddddd', frequency: 0.06330205 },
      { format: 'aaadddaddddd', frequency: 0.055544917 },
      { format: 'adddddddd', frequency: 0.034635767 },
      { format: 'aaadddddddddddd', frequency: 0.025601737 },
      { format: 'adaddddddddddd', frequency: 0.015131201 },
      { format: 'adadddaddddd', frequency: 0.010406691 },
      { format: 'aaadddddddda', frequency: 0.008938262 },
      { format: 'aaaaaddddddd', frequency: 0.008746728 },
      { format: 'ddddddddd', frequency: 0.0083317375 },
      { format: 'aaaadddddddd', frequency: 0.007852902 },
      { format: 'aaadddddddd', frequency: 0.005873715 },
      { format: 'aaadddddddddd', frequency: 0.00472451 },
      { format: 'aaadaaadddddddd', frequency: 0.004564898 },
      { format: 'aaaadddddddddd', frequency: 0.0035433825 },
      { format: 'ddddddddddd', frequency: 0.0033518483 },
      { format: 'aaasddddddddd', frequency: 0.0026814786 },
      { format: 'aaadddddddaa', frequency: 0.002521867 },
      { format: 'adadddddddddddd', frequency: 0.0020430314 },
      { format: 'adadddddddaa', frequency: 0.002011109 },
      { format: 'adaaaddddddd', frequency: 0.0017238077 },
      { format: 'aaadddddddddsdd', frequency: 0.0012768946 },
      { format: 'aaadddddddddhdd', frequency: 0.0012130499 },
    ],
  },
  {
    front_end_carrier_id: 55,
    prefixes: [
      { prefix: 'Y', frequency: 0.45079365 },
      { prefix: 'YU', frequency: 0.3968254 },
      { prefix: 'R', frequency: 0.16507937 },
      { prefix: 'R6', frequency: 0.08888889 },
      { prefix: 'YUP83', frequency: 0.06984127 },
      { prefix: 'YUP80', frequency: 0.06666667 },
      { prefix: 'YUP82', frequency: 0.05079365 },
      { prefix: 'YUQ94', frequency: 0.05079365 },
      { prefix: 'B', frequency: 0.05079365 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.14285715 },
      { suffix: '0', frequency: 0.123809524 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.64444447 },
      { format: 'adddddddd', frequency: 0.14285715 },
      { format: 'adaddddddddd', frequency: 0.057142857 },
      { format: 'aaadddaddddd', frequency: 0.04761905 },
      { format: 'aaaaaddddddd', frequency: 0.022222223 },
      { format: 'adadddaddddd', frequency: 0.015873017 },
      { format: 'aaadddddddddddd', frequency: 0.015873017 },
      { format: 'aaaddddddddddd', frequency: 0.012698413 },
      { format: 'aaaadddddddd', frequency: 0.012698413 },
      { format: 'aaadddddddda', frequency: 0.0063492064 },
      { format: 'adaddddddddddd', frequency: 0.0063492064 },
      { format: 'd', frequency: 0.0031746032 },
      { format: 'adadddddddddddd', frequency: 0.0031746032 },
      { format: 'aaaddddddddda', frequency: 0.0031746032 },
      { format: 'aaadddddddaa', frequency: 0.0031746032 },
      { format: 'aaasddddddddd', frequency: 0.0031746032 },
    ],
  },
  {
    front_end_carrier_id: 56,
    prefixes: [
      { prefix: 'ZBF2010', frequency: 0.07488987 },
      { prefix: 'AFI200', frequency: 0.051395006 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.1277533 },
      { suffix: '1', frequency: 0.12041116 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.77826726 },
      { format: 'adaddddddddd', frequency: 0.080763586 },
      { format: 'aaadddddddddddd', frequency: 0.06901615 },
      { format: 'adddddddd', frequency: 0.019089574 },
      { format: 'aaadddaddddd', frequency: 0.013215859 },
      { format: 'ddddddddd', frequency: 0.007342144 },
      { format: 'aaadddddddddd', frequency: 0.005873715 },
      { format: 'aaaddddddddddd', frequency: 0.004405286 },
      { format: 'aaadddddddddhddd', frequency: 0.004405286 },
      { format: 'aaadddddddda', frequency: 0.0029368575 },
      { format: 'aaadddddddaa', frequency: 0.0029368575 },
      { format: 'aaaaaddddddd', frequency: 0.0029368575 },
      { format: 'aaadadaddddd', frequency: 0.0014684288 },
      { format: 'aaaddadddddddd', frequency: 0.0014684288 },
      { format: 'aaadaaadddddddd', frequency: 0.0014684288 },
      { format: 'aaasddddddddd', frequency: 0.0014684288 },
      { format: 'aaadddddddd', frequency: 0.0014684288 },
      { format: 'adadddddddddhddd', frequency: 0.0014684288 },
    ],
  },
  {
    front_end_carrier_id: 57,
    prefixes: [
      { prefix: 'ZC', frequency: 0.3469751 },
      { prefix: 'S', frequency: 0.12752075 },
      { prefix: 'ZCS', frequency: 0.12692764 },
      { prefix: 'ZCF', frequency: 0.053973902 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddddddddddd', frequency: 0.6020166 },
      { format: 'aaadddddddd', frequency: 0.2241993 },
      { format: 'aaaddddddddd', frequency: 0.049822062 },
      { format: 'aaadddaddddd', frequency: 0.03084223 },
      { format: 'adddddddd', frequency: 0.03024911 },
      { format: 'aaaddddddddddd', frequency: 0.008896797 },
      { format: 'aaddddddddddddd', frequency: 0.0077105574 },
      { format: 'aaaaaddddddd', frequency: 0.0071174377 },
      { format: 'adadddaddddd', frequency: 0.0047449586 },
      { format: 'adaddddddddd', frequency: 0.0047449586 },
      { format: 'aaadaaadddddddd', frequency: 0.002965599 },
      { format: 'dddddddddddd', frequency: 0.002965599 },
      { format: 'aaadddddddda', frequency: 0.0023724793 },
      { format: 'aaadddddddaa', frequency: 0.0023724793 },
      { format: 'aaadddddddddd', frequency: 0.0023724793 },
      { format: 'adadddddddddddd', frequency: 0.0023724793 },
      { format: 'aaaadddadddd', frequency: 0.0017793594 },
      { format: 'adaaaddddddd', frequency: 0.0017793594 },
      { format: 'aaaadddddddd', frequency: 0.0017793594 },
      { format: 'adadddddddaa', frequency: 0.0011862396 },
      { format: 'aaasdddddddddddd', frequency: 0.0011862396 },
      { format: 'aaasddddddddd', frequency: 0.0011862396 },
      { format: 'aaasdddddddd', frequency: 0.0011862396 },
    ],
  },
  {
    front_end_carrier_id: 58,
    prefixes: [
      { prefix: 'ZGP8', frequency: 0.19075331 },
      { prefix: 'ZGP82', frequency: 0.058720026 },
      { prefix: 'U', frequency: 0.058033947 },
      { prefix: 'R', frequency: 0.05574165 },
      { prefix: 'JEA0', frequency: 0.050309543 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.14545617 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.59810483 },
      { format: 'adaddddddddd', frequency: 0.13555245 },
      { format: 'aaadddaddddd', frequency: 0.056040294 },
      { format: 'adddddddd', frequency: 0.047662094 },
      { format: 'aaadddddddddddd', frequency: 0.025949812 },
      { format: 'aaadaadaadaa', frequency: 0.023512224 },
      { format: 'adadddddddaa', frequency: 0.018249618 },
      { format: 'adadddaddddd', frequency: 0.013091942 },
      { format: 'aaaaaddddddd', frequency: 0.012583439 },
      { format: 'aaadddddddda', frequency: 0.009508205 },
      { format: 'aaaadddddddd', frequency: 0.007724407 },
      { format: 'aaaddddddddddd', frequency: 0.0065217568 },
      { format: 'aaadaadaadda', frequency: 0.005302963 },
      { format: 'aaadaddaadaa', frequency: 0.004899389 },
      { format: 'aaadddddddaa', frequency: 0.003825882 },
      { format: 'adadddddddddddd', frequency: 0.0031317347 },
      { format: 'aaadaddaadda', frequency: 0.002970305 },
      { format: 'aaadaaadddddddd', frequency: 0.0025425164 },
      { format: 'aaadaadaadad', frequency: 0.0023730155 },
      { format: 'aaasddddddddd', frequency: 0.002147014 },
      { format: 'adaaaddddddd', frequency: 0.0021066566 },
      { format: 'aaadddddddddd', frequency: 0.001912941 },
      { format: 'aaadaddaadad', frequency: 0.0017918688 },
      { format: 'aaadddddddd', frequency: 0.001259151 },
      { format: 'aaadaddaaddd', frequency: 0.0012349366 },
      { format: 'aaadaadaaddd', frequency: 0.0012268651 },
      { format: 'adadaaadddddddd', frequency: 0.0011945792 },
    ],
  },
  {
    front_end_carrier_id: 59,
    prefixes: [
      { prefix: 'VEIV8', frequency: 0.14553991 },
      { prefix: 'ZIIV8', frequency: 0.089201875 },
      { prefix: 'EVTV8', frequency: 0.08450704 },
      { prefix: 'R', frequency: 0.051643193 },
      { prefix: 'U', frequency: 0.051643193 },
    ],
    suffixes: [{ suffix: '000', frequency: 0.54460096 }],
    formats: [
      { format: 'aaaadddddddddddd', frequency: 0.4976526 },
      { format: 'aaaddddddddd', frequency: 0.17370892 },
      { format: 'aaadddddddddddd', frequency: 0.07042254 },
      { format: 'adaddddddddd', frequency: 0.065727696 },
      { format: 'adddddddd', frequency: 0.04694836 },
      { format: 'aaadddaddddd', frequency: 0.032863848 },
      { format: 'aaaadddddddddddddd', frequency: 0.032863848 },
      { format: 'aaaaddddddddddddd', frequency: 0.014084507 },
      { format: 'aaasddddddddd', frequency: 0.0093896715 },
      { format: 'aaaaddddddddddd', frequency: 0.0093896715 },
      { format: 'adddddddddddd', frequency: 0.0093896715 },
      { format: 'aaaaddddddddd', frequency: 0.0046948357 },
      { format: 'aaadddddddaa', frequency: 0.0046948357 },
      { format: 'aaasadddddddddddd', frequency: 0.0046948357 },
      { format: 'aaasadddddddddddddd', frequency: 0.0046948357 },
      { format: 'aaaadddddddd', frequency: 0.0046948357 },
      { format: 'adadddddddddddd', frequency: 0.0046948357 },
      { format: 'adaadddddddd', frequency: 0.0046948357 },
      { format: 'adddddddddddddd', frequency: 0.0046948357 },
    ],
  },
  {
    front_end_carrier_id: 60,
    prefixes: [
      { prefix: 'MIP', frequency: 0.07198443 },
      { prefix: 'XMP1000', frequency: 0.060311284 },
      { prefix: 'PWX', frequency: 0.060311284 },
      { prefix: 'B', frequency: 0.060311284 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.14007781 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.8774319 },
      { format: 'aaadddddddddddd', frequency: 0.03307393 },
      { format: 'adaddddddddd', frequency: 0.015564202 },
      { format: 'adddddddd', frequency: 0.013618677 },
      { format: 'aaaadddddddd', frequency: 0.009727626 },
      { format: 'aaadddaddddd', frequency: 0.009727626 },
      { format: 'aaasddddddddd', frequency: 0.009727626 },
      { format: 'aaaddddddddddd', frequency: 0.007782101 },
      { format: 'aaadddddddaa', frequency: 0.0038910506 },
      { format: 'aadddddddddd', frequency: 0.0038910506 },
      { format: 'adadddaddddd', frequency: 0.0038910506 },
      { format: 'ddddddddd', frequency: 0.0019455253 },
      { format: 'adadddddddaa', frequency: 0.0019455253 },
      { format: 'aaadddddddsaa', frequency: 0.0019455253 },
      { format: 'adadddddddddd', frequency: 0.0019455253 },
      { format: 'aaaaaddddddd', frequency: 0.0019455253 },
      { format: 'aaadddddddda', frequency: 0.0019455253 },
    ],
  },
  {
    front_end_carrier_id: 61,
    prefixes: [
      { prefix: 'XE', frequency: 0.5053483 },
      { prefix: 'XEA91', frequency: 0.12755434 },
      { prefix: 'XED91', frequency: 0.08101062 },
      { prefix: 'T', frequency: 0.06602001 },
      { prefix: 'R', frequency: 0.05409654 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.7417092 },
      { format: 'aaadddddddda', frequency: 0.0531764 },
      { format: 'adddddddd', frequency: 0.04290151 },
      { format: 'aaaadddddddd', frequency: 0.037227314 },
      { format: 'aaadddaddddd', frequency: 0.026377333 },
      { format: 'ddddddddd', frequency: 0.017827705 },
      { format: 'aaaddddddddddd', frequency: 0.013610397 },
      { format: 'aaadddddddddddd', frequency: 0.011655101 },
      { format: 'aaasadddddddd', frequency: 0.0073994556 },
      { format: 'aaadddddddaa', frequency: 0.0052908026 },
      { format: 'adaddddddddd', frequency: 0.005175785 },
      { format: 'aaaddddadddd', frequency: 0.003412184 },
      { format: 'aaaaaddddddd', frequency: 0.0029521144 },
      { format: 'adadddaddddd', frequency: 0.0027987577 },
      { format: 'aaadddddddad', frequency: 0.002607062 },
      { format: 'aaasddddddddd', frequency: 0.0022620098 },
      { format: 'ddddddddddd', frequency: 0.0018019399 },
      { format: 'adadddddddddddd', frequency: 0.0017636009 },
      { format: 'aaadaaadddddddd', frequency: 0.0013418702 },
      { format: 'aaadddddddddd', frequency: 0.0013418702 },
      { format: 'aaadddddddddhdd', frequency: 0.0011501744 },
      { format: 'dddddddda', frequency: 0.0010734962 },
    ],
  },
  {
    front_end_carrier_id: 63,
    prefixes: [
      { prefix: 'Z', frequency: 0.28071463 },
      { prefix: 'ZE', frequency: 0.22613803 },
      { prefix: 'C', frequency: 0.06314244 },
      { prefix: 'ZES909', frequency: 0.058981888 },
      { prefix: 'ZEB9', frequency: 0.05408713 },
      { prefix: 'P', frequency: 0.051395006 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.12383749 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.7503671 },
      { format: 'aaaadddddddd', frequency: 0.06265296 },
      { format: 'adddddddd', frequency: 0.041605484 },
      { format: 'aaadddaddddd', frequency: 0.031081742 },
      { format: 'aaadddddddddddd', frequency: 0.020558003 },
      { format: 'adaddddddddd', frequency: 0.018600099 },
      { format: 'ddddddddd', frequency: 0.012481645 },
      { format: 'adadddaddddd', frequency: 0.011992169 },
      { format: 'aaadddddddddd', frequency: 0.0066079297 },
      { format: 'aaaaaddddddd', frequency: 0.005873715 },
      { format: 'aaadddddddd', frequency: 0.0048947628 },
      { format: 'adaaaddddddd', frequency: 0.0031815956 },
      { format: 'aaadddddddda', frequency: 0.0029368575 },
      { format: 'aaaddddddddddd', frequency: 0.0029368575 },
      { format: 'aaadddddddaa', frequency: 0.0026921195 },
      { format: 'aaasddddddddd', frequency: 0.0024473814 },
      { format: 'adadddddddaa', frequency: 0.0024473814 },
      { format: 'adadddddddddddd', frequency: 0.001957905 },
      { format: 'aaadaaadddddddd', frequency: 0.001957905 },
      { format: 'dddddddddhdd', frequency: 0.0014684288 },
      { format: 'aaaaddddddddd', frequency: 0.0014684288 },
    ],
  },
  {
    front_end_carrier_id: 64,
    prefixes: [
      { prefix: 'R6', frequency: 0.056561086 },
      { prefix: 'VY', frequency: 0.055429865 },
      { prefix: '89', frequency: 0.055429865 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.35180995 },
      { suffix: '001', frequency: 0.280543 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.2183258 },
      { format: 'aaadddddddddddd', frequency: 0.14592761 },
      { format: 'adadddddddddddd', frequency: 0.14253394 },
      { format: 'adddddddd', frequency: 0.10972851 },
      { format: 'adadddaddddd', frequency: 0.09728507 },
      { format: 'aaadddaddddd', frequency: 0.07239819 },
      { format: 'ddddddddd', frequency: 0.056561086 },
      { format: 'aaasddddddddd', frequency: 0.015837105 },
      { format: 'aaadddddddaa', frequency: 0.014705882 },
      { format: 'aaadaaadddddddd', frequency: 0.014705882 },
      { format: 'aaaaaddddddd', frequency: 0.011312217 },
      { format: 'aaaadddddddd', frequency: 0.010180996 },
      { format: 'adadddddddaa', frequency: 0.009049774 },
      { format: 'aaaddddddddddd', frequency: 0.009049774 },
      { format: 'adaddddddddd', frequency: 0.009049774 },
      { format: 'adaaaddddddd', frequency: 0.0079185525 },
      { format: 'aaadddddddda', frequency: 0.0079185525 },
      { format: 'adadddddddddd', frequency: 0.0056561087 },
      { format: 'aaasadddddddd', frequency: 0.0056561087 },
      { format: 'dddaddddd', frequency: 0.0056561087 },
      { format: 'aaadddddddddd', frequency: 0.0056561087 },
      { format: 'aaddddda', frequency: 0.004524887 },
      { format: 'dddddddddddd', frequency: 0.0033936652 },
      { format: 'aaaadddddddddddd', frequency: 0.0022624435 },
      { format: 'adadaaadddddddd', frequency: 0.0022624435 },
      { format: 'adaddddadddd', frequency: 0.0022624435 },
      { format: 'adaddddddddddddd', frequency: 0.0011312218 },
      { format: 'aaasdddddddddddd', frequency: 0.0011312218 },
      { format: 'adadddsaddddd', frequency: 0.0011312218 },
      { format: 'aaddddaddddd', frequency: 0.0011312218 },
      { format: 'aaaaaadddddd', frequency: 0.0011312218 },
      { format: 'adasaaddddddd', frequency: 0.0011312218 },
      { format: 'adaaaadddddd', frequency: 0.0011312218 },
      { format: 'adadddddddda', frequency: 0.0011312218 },
      { format: 'aaaddddddddda', frequency: 0.0011312218 },
    ],
  },
  {
    front_end_carrier_id: 65,
    prefixes: [
      { prefix: 'V', frequency: 0.072044864 },
      { prefix: 'N', frequency: 0.06686799 },
      { prefix: 'P4U1', frequency: 0.053062987 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.3498706 },
      { suffix: '001', frequency: 0.27351165 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.17817083 },
      { format: 'aaadddaddddd', frequency: 0.1755824 },
      { format: 'aaadddddddddddd', frequency: 0.1419327 },
      { format: 'adadddddddddddd', frequency: 0.121656604 },
      { format: 'adddddddd', frequency: 0.09663503 },
      { format: 'adadddaddddd', frequency: 0.050474547 },
      { format: 'aaaaaddddddd', frequency: 0.03710095 },
      { format: 'aaadddddddaa', frequency: 0.028041415 },
      { format: 'adaaaddddddd', frequency: 0.015962036 },
      { format: 'aaadaaadddddddd', frequency: 0.01553063 },
      { format: 'ddddddddd', frequency: 0.015099224 },
      { format: 'adaddddddddd', frequency: 0.015099224 },
      { format: 'aaadddddddda', frequency: 0.014236411 },
      { format: 'aaaadddddddd', frequency: 0.010353753 },
      { format: 'aaasddddddddd', frequency: 0.010353753 },
      { format: 'aaaddddddddddd', frequency: 0.009922347 },
      { format: 'dddddddddddd', frequency: 0.009490941 },
      { format: 'aaadddddddddd', frequency: 0.008628128 },
      { format: 'adadddddddddd', frequency: 0.0069025024 },
      { format: 'adadddddddaa', frequency: 0.0038826575 },
      { format: 'adaddddddddddd', frequency: 0.0034512512 },
      { format: 'adaddddadddd', frequency: 0.0034512512 },
      { format: 'aaaaaadddddd', frequency: 0.0025884383 },
      { format: 'adadaaadddddddd', frequency: 0.002157032 },
      { format: 'aaaddadddddd', frequency: 0.002157032 },
      { format: 'adaddddddddddddd', frequency: 0.0012942192 },
      { format: 'adaadddddddd', frequency: 0.0012942192 },
      { format: 'aaadddddddd', frequency: 0.0012942192 },
    ],
  },
  {
    front_end_carrier_id: 66,
    prefixes: [
      { prefix: 'YWP802', frequency: 0.13185379 },
      { prefix: 'A', frequency: 0.06266318 },
      { prefix: 'GFD80', frequency: 0.057441253 },
      { prefix: 'S', frequency: 0.05613577 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.41775456 },
      { suffix: '01', frequency: 0.22193211 },
      { suffix: '02', frequency: 0.11357702 },
    ],
    formats: [
      { format: 'aaaddddddddddd', frequency: 0.6018277 },
      { format: 'aaadddddddddddd', frequency: 0.12010444 },
      { format: 'adaddddddddddd', frequency: 0.11227154 },
      { format: 'adddddddd', frequency: 0.028720627 },
      { format: 'aaaddddddddd', frequency: 0.022193212 },
      { format: 'adadddddddddddd', frequency: 0.014360313 },
      { format: 'aaaadddddddd', frequency: 0.01305483 },
      { format: 'aaadddddddddd', frequency: 0.011749348 },
      { format: 'aaaaaddddddd', frequency: 0.009138381 },
      { format: 'aaadddddddd', frequency: 0.009138381 },
      { format: 'aaadddaddddd', frequency: 0.007832898 },
      { format: 'aaadaaadddddddd', frequency: 0.006527415 },
      { format: 'adadddddddd', frequency: 0.003916449 },
      { format: 'aaadddddd', frequency: 0.003916449 },
      { format: 'ddddddddddd', frequency: 0.003916449 },
      { format: 'adaddddddddd', frequency: 0.003916449 },
      { format: 'aaadddddddda', frequency: 0.003916449 },
      { format: 'adaaaddddddd', frequency: 0.002610966 },
      { format: 'ddddddddd', frequency: 0.002610966 },
      { format: 'adadddaddddd', frequency: 0.002610966 },
      { format: 'adadddddddddd', frequency: 0.002610966 },
      { format: 'aaasddddddddddd', frequency: 0.001305483 },
      { format: 'aaasdddddddd', frequency: 0.001305483 },
      { format: 'aaahddddddhdddhdd', frequency: 0.001305483 },
      { format: 'adaddddddddddddd', frequency: 0.001305483 },
      { format: 'aaasdddddddddddd', frequency: 0.001305483 },
      { format: 'aaasdddhdddhddddd', frequency: 0.001305483 },
      { format: 'adadddddddaa', frequency: 0.001305483 },
      { format: 'aaaadddddddddd', frequency: 0.001305483 },
      { format: 'adadddddddddddddd', frequency: 0.001305483 },
      { format: 'adaadddddddd', frequency: 0.001305483 },
    ],
  },
  {
    front_end_carrier_id: 67,
    prefixes: [
      { prefix: 'XI', frequency: 0.21423075 },
      { prefix: 'JH', frequency: 0.09130344 },
      { prefix: 'M', frequency: 0.085916184 },
    ],
    suffixes: [
      { suffix: '2', frequency: 0.25222138 },
      { suffix: '1', frequency: 0.15441126 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.52459246 },
      { format: 'adddddddd', frequency: 0.13636045 },
      { format: 'aaasddddddddd', frequency: 0.09655076 },
      { format: 'adaddddddddd', frequency: 0.06513678 },
      { format: 'ddddddddd', frequency: 0.053032953 },
      { format: 'aaadddddd', frequency: 0.037850697 },
      { format: 'aaadddaddddd', frequency: 0.017700972 },
      { format: 'aaadddddddddddd', frequency: 0.0149024 },
      { format: 'adasddddddddd', frequency: 0.012103827 },
      { format: 'adadddddd', frequency: 0.006996432 },
      { format: 'aaaddddddddddd', frequency: 0.0049674665 },
      { format: 'adadddaddddd', frequency: 0.004127895 },
      { format: 'aaaaaddddddd', frequency: 0.0035681801 },
      { format: 'aaaadddddddd', frequency: 0.0025886798 },
      { format: 'aaadaaadddddddd', frequency: 0.0023088225 },
      { format: 'aaadddddddaa', frequency: 0.001749108 },
      { format: 'adadddddddddddd', frequency: 0.001749108 },
      { format: 'adadddddddaa', frequency: 0.0014692507 },
      { format: 'aaadddddddda', frequency: 0.0014692507 },
      { format: 'aaadddddddddd', frequency: 0.001329322 },
      { format: 'adaaaddddddd', frequency: 0.0010494648 },
    ],
  },
  {
    front_end_carrier_id: 68,
    prefixes: [
      { prefix: 'XI', frequency: 0.21839896 },
      { prefix: 'JH', frequency: 0.09211304 },
      { prefix: 'MYY81', frequency: 0.07071065 },
      { prefix: 'XIK', frequency: 0.061724015 },
      { prefix: 'XIB', frequency: 0.057940166 },
    ],
    suffixes: [
      { suffix: '2', frequency: 0.22064562 },
      { suffix: '1', frequency: 0.14922549 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.55634385 },
      { format: 'aaasddddddddd', frequency: 0.120964885 },
      { format: 'adddddddd', frequency: 0.114343144 },
      { format: 'aaadddddd', frequency: 0.04020338 },
      { format: 'ddddddddd', frequency: 0.039848644 },
      { format: 'adaddddddddd', frequency: 0.028615348 },
      { format: 'aaadddaddddd', frequency: 0.021993615 },
      { format: 'aaadddddddddddd', frequency: 0.018800993 },
      { format: 'aaaddddddddddd', frequency: 0.008750148 },
      { format: 'adasddddddddd', frequency: 0.006385243 },
      { format: 'adadddaddddd', frequency: 0.0060305074 },
      { format: 'adadddddd', frequency: 0.0052027907 },
      { format: 'aaaaaddddddd', frequency: 0.0046115643 },
      { format: 'aaaadddddddd', frequency: 0.002956131 },
      { format: 'adadddddddddddd', frequency: 0.0028378859 },
      { format: 'adaaaddddddd', frequency: 0.0020101692 },
      { format: 'adadddddddaa', frequency: 0.0020101692 },
      { format: 'aaadaaadddddddd', frequency: 0.0018919238 },
      { format: 'aaadddsddsdddd', frequency: 0.0017736786 },
      { format: 'aaadddddddda', frequency: 0.0017736786 },
      { format: 'aaadddddddddd', frequency: 0.0016554333 },
      { format: 'aaasddddddddddd', frequency: 0.0016554333 },
    ],
  },
  {
    front_end_carrier_id: 69,
    prefixes: [
      { prefix: 'VY', frequency: 0.54385704 },
      { prefix: 'YN', frequency: 0.17971057 },
      { prefix: 'VYA20', frequency: 0.16952156 },
      { prefix: 'YND20', frequency: 0.07900177 },
      { prefix: 'VYT', frequency: 0.06113408 },
      { prefix: 'VYK20', frequency: 0.06069108 },
      { prefix: 'VYA203', frequency: 0.060100414 },
      { prefix: 'VYA204', frequency: 0.058328412 },
      { prefix: 'VYW20', frequency: 0.05685174 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.7248966 },
      { format: 'aaasddddddddd', frequency: 0.16184288 },
      { format: 'aaadddaddddd', frequency: 0.023922032 },
      { format: 'adddddddd', frequency: 0.022740697 },
      { format: 'aaadddddddddddd', frequency: 0.018163024 },
      { format: 'adadddaddddd', frequency: 0.0073833433 },
      { format: 'aaaadddddddd', frequency: 0.0067926757 },
      { format: 'aaaaaddddddd', frequency: 0.004134672 },
      { format: 'adadddddddddddd', frequency: 0.0033963379 },
      { format: 'aaadaaadddddddd', frequency: 0.0033963379 },
      { format: 'adaddddddddd', frequency: 0.0026580035 },
      { format: 'aaaddddddddddd', frequency: 0.00236267 },
      { format: 'aaasadddddddd', frequency: 0.002215003 },
      { format: 'ddddddddd', frequency: 0.002067336 },
      { format: 'aaadddddddaa', frequency: 0.0019196692 },
      { format: 'aaadddddddda', frequency: 0.0016243355 },
      { format: 'aaadddddddddd', frequency: 0.001033668 },
      { format: 'aaasssssddddddddd', frequency: 0.001033668 },
    ],
  },
  {
    front_end_carrier_id: 70,
    prefixes: [
      { prefix: 'XJ', frequency: 0.3139515 },
      { prefix: 'VM', frequency: 0.21004644 },
      { prefix: 'XJBH', frequency: 0.18991914 },
      { prefix: 'VMYH', frequency: 0.09547566 },
      { prefix: 'XJBH4', frequency: 0.094787546 },
      { prefix: 'VMAH', frequency: 0.08412179 },
      { prefix: 'F', frequency: 0.05418889 },
      { prefix: 'XJGH', frequency: 0.05298469 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaadddddddd', frequency: 0.6862205 },
      { format: 'aaadddddddddddd', frequency: 0.12214003 },
      { format: 'adddddddd', frequency: 0.05866162 },
      { format: 'aaaddddddddd', frequency: 0.038706347 },
      { format: 'aaadddaddddd', frequency: 0.017890934 },
      { format: 'adadddaddddd', frequency: 0.011181834 },
      { format: 'aaadddddddd', frequency: 0.010321693 },
      { format: 'adaddddddddd', frequency: 0.0053328746 },
      { format: 'aaaaddddddddd', frequency: 0.0044727335 },
      { format: 'aaaaddddddd', frequency: 0.004300705 },
      { format: 'adaadddddddd', frequency: 0.0041286773 },
      { format: 'aaadddddddda', frequency: 0.0041286773 },
      { format: 'aaadddddd', frequency: 0.0036125924 },
      { format: 'aaaadddddddddd', frequency: 0.0034405643 },
      { format: 'aaaaaddddddd', frequency: 0.0027524515 },
      { format: 'aaadddddddaa', frequency: 0.0020643387 },
      { format: 'adadddddddddddd', frequency: 0.0020643387 },
      { format: 'adadddddddaa', frequency: 0.0020643387 },
      { format: 'aaaddddddddddd', frequency: 0.0018923103 },
      { format: 'aaadaaadddddddd', frequency: 0.0012041975 },
      { format: 'aaadddddddddd', frequency: 0.0012041975 },
      { format: 'aaaddddddddda', frequency: 0.0010321693 },
      { format: 'adddddddddd', frequency: 0.0010321693 },
    ],
  },
  {
    front_end_carrier_id: 71,
    prefixes: [
      { prefix: 'XHP1', frequency: 0.12542373 },
      { prefix: 'SOD1', frequency: 0.10169491 },
      { prefix: 'H6J1', frequency: 0.06949153 },
      { prefix: 'W', frequency: 0.06610169 },
      { prefix: 'XHP13', frequency: 0.062711865 },
      { prefix: 'C9I1', frequency: 0.055932205 },
    ],
    suffixes: [
      { suffix: '001', frequency: 0.9033898 },
      { suffix: '8001', frequency: 0.120338984 },
    ],
    formats: [
      { format: 'aaadddddddddddd', frequency: 0.6118644 },
      { format: 'adadddddddddddd', frequency: 0.2898305 },
      { format: 'adaaaddddddd', frequency: 0.025423728 },
      { format: 'adddddddd', frequency: 0.022033898 },
      { format: 'aaaddddddddd', frequency: 0.020338982 },
      { format: 'aaaddddddddddddd', frequency: 0.008474576 },
      { format: 'aaadddddddda', frequency: 0.0050847456 },
      { format: 'aaasddddddddd', frequency: 0.0033898305 },
      { format: 'adadddddddaa', frequency: 0.0033898305 },
      { format: 'aaaaaddddddd', frequency: 0.0016949152 },
      { format: 'adadddaddddd', frequency: 0.0016949152 },
      { format: 'adadddddddd', frequency: 0.0016949152 },
      { format: 'aaadddaddddd', frequency: 0.0016949152 },
      { format: 'aaadddddddaa', frequency: 0.0016949152 },
      { format: 'aaasdddddddddddd', frequency: 0.0016949152 },
    ],
  },
  {
    front_end_carrier_id: 72,
    prefixes: [
      { prefix: 'S', frequency: 0.060723513 },
      { prefix: 'YYM13', frequency: 0.056847546 },
      { prefix: 'Z', frequency: 0.055555556 },
      { prefix: 'CQM1', frequency: 0.054263566 },
    ],
    suffixes: [
      { suffix: '001', frequency: 0.8656331 },
      { suffix: '6001', frequency: 0.10723514 },
    ],
    formats: [
      { format: 'aaadddddddddddd', frequency: 0.66020674 },
      { format: 'adadddddddddddd', frequency: 0.19638243 },
      { format: 'adaaaddddddd', frequency: 0.04263566 },
      { format: 'ddddddddddddd', frequency: 0.015503876 },
      { format: 'aaaddddddddd', frequency: 0.015503876 },
      { format: 'adddddddd', frequency: 0.015503876 },
      { format: 'aaaddddddddddddd', frequency: 0.014211887 },
      { format: 'dddddddddddd', frequency: 0.010335918 },
      { format: 'adadddddddd', frequency: 0.006459948 },
      { format: 'aaaaaddddddd', frequency: 0.003875969 },
      { format: 'aaadddddddddd', frequency: 0.003875969 },
      { format: 'adaddddddddddddd', frequency: 0.003875969 },
      { format: 'aaasddddddddd', frequency: 0.0012919897 },
      { format: 'aaaadddddddd', frequency: 0.0012919897 },
      { format: 'adadddddddaa', frequency: 0.0012919897 },
      { format: 'adasddddddddd', frequency: 0.0012919897 },
      { format: 'aaadaaadddddddd', frequency: 0.0012919897 },
      { format: 'adaddddddddddd', frequency: 0.0012919897 },
      { format: 'adaddddddddd', frequency: 0.0012919897 },
      { format: 'aaadddddddd', frequency: 0.0012919897 },
      { format: 'adddddddddddddd', frequency: 0.0012919897 },
    ],
  },
  {
    front_end_carrier_id: 75,
    prefixes: [
      { prefix: 'NJX3HZN', frequency: 0.19400588 },
      { prefix: 'R', frequency: 0.05496494 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.8108573 }],
    formats: [
      { format: 'aaadaaadddddddd', frequency: 0.70171905 },
      { format: 'adadaaadddddddd', frequency: 0.055055417 },
      { format: 'aaaddddddddd', frequency: 0.03634924 },
      { format: 'aaadddddddd', frequency: 0.03279801 },
      { format: 'adddddddd', frequency: 0.030513458 },
      { format: 'daaadddddddd', frequency: 0.029020583 },
      { format: 'aaadddaddddd', frequency: 0.02252884 },
      { format: 'aaadddddddddddd', frequency: 0.02216693 },
      { format: 'adaddddddddd', frequency: 0.014204931 },
      { format: 'adadddaddddd', frequency: 0.011513232 },
      { format: 'aaaaaddddddd', frequency: 0.009228681 },
      { format: 'aaadddddddddd', frequency: 0.004863153 },
      { format: 'adadddddddddddd', frequency: 0.0040940964 },
      { format: 'adaaaddddddd', frequency: 0.003913142 },
      { format: 'aaadddddddda', frequency: 0.0030083691 },
      { format: 'aaaddddddddddd', frequency: 0.002850034 },
      { format: 'aaaadddddddd', frequency: 0.002782176 },
      { format: 'aaadddddddaa', frequency: 0.0021940737 },
      { format: 'adadddddddddd', frequency: 0.0016512101 },
      { format: 'adadddddddaa', frequency: 0.0014476363 },
      { format: 'aaadddddd', frequency: 0.0012440624 },
    ],
  },
  {
    front_end_carrier_id: 76,
    prefixes: [
      { prefix: 'YXF13', frequency: 0.09714572 },
      { prefix: 'CDQ13', frequency: 0.07811718 },
      { prefix: 'U', frequency: 0.07761642 },
      { prefix: 'D', frequency: 0.072108164 },
      { prefix: 'QC', frequency: 0.06159239 },
    ],
    suffixes: [
      { suffix: '001', frequency: 0.6995493 },
      { suffix: '00', frequency: 0.14571857 },
    ],
    formats: [
      { format: 'aaadddddddddddd', frequency: 0.63194793 },
      { format: 'aaadddddddddd', frequency: 0.2163245 },
      { format: 'adadddddddddddd', frequency: 0.052078117 },
      { format: 'adadddddddddd', frequency: 0.032548822 },
      { format: 'aaaddddddddddddd', frequency: 0.013520281 },
      { format: 'ddddddddddddd', frequency: 0.010515774 },
      { format: 'dddddddddddd', frequency: 0.009013521 },
      { format: 'aaaddddddddd', frequency: 0.008012018 },
      { format: 'aaadddddddd', frequency: 0.004006009 },
      { format: 'dddddddddd', frequency: 0.003505258 },
      { format: 'adddddddd', frequency: 0.0030045067 },
      { format: 'aaasdddddddddd', frequency: 0.0020030045 },
      { format: 'aaasdddddddddddd', frequency: 0.0020030045 },
      { format: 'ddddddddd', frequency: 0.0015022533 },
      { format: 'aaddddddddddd', frequency: 0.0015022533 },
      { format: 'aaadddaddddd', frequency: 0.0010015023 },
      { format: 'aaadaaadddddddd', frequency: 0.0010015023 },
      { format: 'aaasddddddddddddd', frequency: 0.0010015023 },
      { format: 'aaaddddddddddd', frequency: 0.0010015023 },
      { format: 'dddddddd', frequency: 0.0010015023 },
    ],
  },
  {
    front_end_carrier_id: 77,
    prefixes: [
      { prefix: 'AQT60', frequency: 0.14684723 },
      { prefix: 'MSJ60', frequency: 0.05014812 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.25201017 },
      { suffix: '01', frequency: 0.18853153 },
      { suffix: '2', frequency: 0.12452391 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.3424672 },
      { format: 'ddddddddd', frequency: 0.21656792 },
      { format: 'aaaddddddddddd', frequency: 0.1801735 },
      { format: 'adaddddddddd', frequency: 0.035019044 },
      { format: 'aaasddddddddd', frequency: 0.030681338 },
      { format: 'adaddddddddddd', frequency: 0.025708845 },
      { format: 'aaasdddddddddsdd', frequency: 0.023063902 },
      { format: 'aaadddaddddd', frequency: 0.021794328 },
      { format: 'adddddddd', frequency: 0.015552264 },
      { format: 'ddddddddddd', frequency: 0.012907322 },
      { format: 'aaadddddddddddd', frequency: 0.008252222 },
      { format: 'aaasddddddddddd', frequency: 0.007194245 },
      { format: 'dddddddddhdd', frequency: 0.005713077 },
      { format: 'aaadddddddddhdd', frequency: 0.005607279 },
      { format: 'aaaaddddddddd', frequency: 0.005184088 },
      { format: 'aaaadddddddd', frequency: 0.00507829 },
      { format: 'adasdddddddddsdd', frequency: 0.0045493017 },
      { format: 'aaadddddddddd', frequency: 0.004443504 },
      { format: 'adadddaddddd', frequency: 0.004126111 },
      { format: 'adasddddddddd', frequency: 0.0039145155 },
      { format: 'aaaaaddddddd', frequency: 0.0031739315 },
      { format: 'aaadddddddddsdd', frequency: 0.0031739315 },
      { format: 'adadddddddaa', frequency: 0.0028565384 },
      { format: 'dddddddddd', frequency: 0.0023275497 },
      { format: 'dddddddddsdd', frequency: 0.0019043589 },
      { format: 'aaadddddddd', frequency: 0.0016927634 },
      { format: 'aaadddddddda', frequency: 0.0013753703 },
      { format: 'adadddddddddddd', frequency: 0.0012695725 },
      { format: 'aaahdddddddddhdd', frequency: 0.0011637749 },
      { format: 'adadddddddddd', frequency: 0.0011637749 },
      { format: 'dddddddd', frequency: 0.0011637749 },
      { format: 'aaasdddddddddhdd', frequency: 0.0010579771 },
      { format: 'adaaaddddddd', frequency: 0.0010579771 },
    ],
  },
  {
    front_end_carrier_id: 78,
    prefixes: [
      { prefix: 'AQT60', frequency: 0.12562814 },
      { prefix: 'Z', frequency: 0.10050251 },
      { prefix: 'AQT604', frequency: 0.050251257 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.17336683 },
      { suffix: '2', frequency: 0.1356784 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.31909546 },
      { format: 'ddddddddd', frequency: 0.31407034 },
      { format: 'aaaddddddddddd', frequency: 0.15075377 },
      { format: 'adaddddddddd', frequency: 0.030150754 },
      { format: 'aaasdddddddddsdd', frequency: 0.022613065 },
      { format: 'aaasddddddddd', frequency: 0.020100502 },
      { format: 'adddddddd', frequency: 0.01758794 },
      { format: 'ddddddddddd', frequency: 0.015075377 },
      { format: 'dddddddddhdd', frequency: 0.012562814 },
      { format: 'adaddddddddddd', frequency: 0.012562814 },
      { format: 'dddddddddsdd', frequency: 0.012562814 },
      { format: 'aaadddddddddd', frequency: 0.010050251 },
      { format: 'adadddaddddd', frequency: 0.0050251256 },
      { format: 'aaadddddddddsdd', frequency: 0.0050251256 },
      { format: 'aaadddddddd', frequency: 0.0050251256 },
      { format: 'aaasddddddddddd', frequency: 0.0050251256 },
      { format: 'aaadddddddddddd', frequency: 0.0050251256 },
      { format: 'addddddddd', frequency: 0.0025125628 },
      { format: 'dddddddddddddddd', frequency: 0.0025125628 },
      { format: 'aaadddddddaa', frequency: 0.0025125628 },
      { format: 'dddddddddd', frequency: 0.0025125628 },
      { format: 'aaaadddddddd', frequency: 0.0025125628 },
      { format: 'ddddddddhdd', frequency: 0.0025125628 },
      { format: 'aaahdddddddddhdd', frequency: 0.0025125628 },
      { format: 'aaaddddddddsdd', frequency: 0.0025125628 },
      { format: 'adadddddddda', frequency: 0.0025125628 },
      { format: 'aaahddddddddd', frequency: 0.0025125628 },
      { format: 'aaasdddddddddsssaaaaaasdd', frequency: 0.0025125628 },
      { format: 'aaadddaddddd', frequency: 0.0025125628 },
      { format: 'adadddddddaa', frequency: 0.0025125628 },
      { format: 'aaadddddddddhdd', frequency: 0.0025125628 },
      { format: 'dddd', frequency: 0.0025125628 },
    ],
  },
  {
    front_end_carrier_id: 79,
    prefixes: [
      { prefix: 'YVA', frequency: 0.22264561 },
      { prefix: 'YVA2', frequency: 0.15732172 },
      { prefix: 'O2F', frequency: 0.062057704 },
      { prefix: 'R', frequency: 0.051714752 },
      { prefix: 'L', frequency: 0.05062602 },
      { prefix: 'F', frequency: 0.05062602 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5427327 },
      { format: 'adaddddddddd', frequency: 0.18562874 },
      { format: 'aaasddddddddd', frequency: 0.060968973 },
      { format: 'adddddddd', frequency: 0.035928145 },
      { format: 'ddddddddd', frequency: 0.03538378 },
      { format: 'adasddddddddd', frequency: 0.03375068 },
      { format: 'aaadddaddddd', frequency: 0.029395754 },
      { format: 'aaaaddddddddd', frequency: 0.009254219 },
      { format: 'aaadddddddddddd', frequency: 0.008165487 },
      { format: 'aaaadddddddd', frequency: 0.0076211216 },
      { format: 'aaaddddddddddd', frequency: 0.005988024 },
      { format: 'adadddaddddd', frequency: 0.0043549263 },
      { format: 'aaasaddddddddd', frequency: 0.0038105608 },
      { format: 'aaaaaddddddd', frequency: 0.0038105608 },
      { format: 'aaadddddddda', frequency: 0.0032661948 },
      { format: 'adadddddddaa', frequency: 0.0032661948 },
      { format: 'ddaddddddddd', frequency: 0.0021774631 },
      { format: 'aaadddddddddd', frequency: 0.0021774631 },
      { format: 'adassddddddddd', frequency: 0.0016330974 },
      { format: 'dddddddd', frequency: 0.0010887316 },
      { format: 'addddddddd', frequency: 0.0010887316 },
      { format: 'aaadaaadddddddd', frequency: 0.0010887316 },
      { format: 'addddddddddd', frequency: 0.0010887316 },
      { format: 'aaadddddddaa', frequency: 0.0010887316 },
      { format: 'adaddddddddddd', frequency: 0.0010887316 },
      { format: 'adadddddddddddd', frequency: 0.0010887316 },
    ],
  },
  {
    front_end_carrier_id: 80,
    prefixes: [
      { prefix: 'Z', frequency: 0.2300995 },
      { prefix: 'U', frequency: 0.21268657 },
      { prefix: 'ZHL2', frequency: 0.07587065 },
      { prefix: 'P', frequency: 0.07462686 },
      { prefix: 'UUG', frequency: 0.05597015 },
      { prefix: 'R', frequency: 0.05597015 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.12810946 },
      { suffix: '0', frequency: 0.12064677 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.51616913 },
      { format: 'adaddddddddd', frequency: 0.1716418 },
      { format: 'aaasddddddddd', frequency: 0.05970149 },
      { format: 'adddddddd', frequency: 0.048507463 },
      { format: 'aaadddaddddd', frequency: 0.04477612 },
      { format: 'ddddddddd', frequency: 0.038557213 },
      { format: 'adasddddddddd', frequency: 0.024875622 },
      { format: 'aaadddddddddddd', frequency: 0.012437811 },
      { format: 'aaaadddddddd', frequency: 0.01119403 },
      { format: 'aaaaaddddddd', frequency: 0.008706467 },
      { format: 'aaadddddddda', frequency: 0.0074626864 },
      { format: 'adadddaddddd', frequency: 0.0062189056 },
      { format: 'aaaddddddddddd', frequency: 0.0049751243 },
      { format: 'adadddddddddddd', frequency: 0.0037313432 },
      { format: 'adaddddddddddd', frequency: 0.0037313432 },
      { format: 'adadddddddaa', frequency: 0.0037313432 },
      { format: 'addddddddddd', frequency: 0.0037313432 },
      { format: 'aaadddddddaa', frequency: 0.0024875621 },
      { format: 'aaadaadaadaa', frequency: 0.0024875621 },
      { format: 'ddddddddddd', frequency: 0.0024875621 },
      { format: 'adahddddddddd', frequency: 0.0024875621 },
      { format: 'aaaaddddddddd', frequency: 0.0024875621 },
      { format: 'dddddddd', frequency: 0.0012437811 },
      { format: 'aaadaadaadda', frequency: 0.0012437811 },
      { format: 'aaadddddddd', frequency: 0.0012437811 },
      { format: 'aaadddddddddsdd', frequency: 0.0012437811 },
      { format: 'dddddddddd', frequency: 0.0012437811 },
      { format: 'aaadaaadddddddd', frequency: 0.0012437811 },
      { format: 'aaasaddddddddd', frequency: 0.0012437811 },
      { format: 'adaddddddddda', frequency: 0.0012437811 },
      { format: 'aadddddddddd', frequency: 0.0012437811 },
      { format: 'daaddddddddd', frequency: 0.0012437811 },
      { format: 'aaaddddddddda', frequency: 0.0012437811 },
      { format: 'addsddddddddd', frequency: 0.0012437811 },
      { format: 'aaadddddddddhdd', frequency: 0.0012437811 },
      { format: 'aaa', frequency: 0.0012437811 },
    ],
  },
  {
    front_end_carrier_id: 81,
    prefixes: [
      { prefix: 'UDW', frequency: 0.22373976 },
      { prefix: 'W', frequency: 0.09163149 },
      { prefix: 'UDW W7', frequency: 0.08902409 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.42873105 },
      { format: 'aaaaddddddddd', frequency: 0.16923268 },
      { format: 'aaasaddddddddd', frequency: 0.11919543 },
      { format: 'adaddddddddd', frequency: 0.075738765 },
      { format: 'aaasddddddddd', frequency: 0.042587534 },
      { format: 'ddddddddd', frequency: 0.029053885 },
      { format: 'adddddddd', frequency: 0.026818972 },
      { format: 'addddddddd', frequency: 0.0234666 },
      { format: 'aaadddaddddd', frequency: 0.019741744 },
      { format: 'aaadddddddddddd', frequency: 0.009312143 },
      { format: 'adasddddddddd', frequency: 0.008691333 },
      { format: 'aaaddddddddddd', frequency: 0.008691333 },
      { format: 'adadddaddddd', frequency: 0.0047181523 },
      { format: 'aaaadddddddd', frequency: 0.0033523715 },
      { format: 'aaaaaddddddd', frequency: 0.0033523715 },
      { format: 'aaadddddddddhdd', frequency: 0.0024832382 },
      { format: 'aaadddddddda', frequency: 0.0023590762 },
      { format: 'adadddddddddddd', frequency: 0.0019865904 },
      { format: 'aaadddddddd', frequency: 0.0017382667 },
      { format: 'adadddddddaa', frequency: 0.0016141048 },
    ],
  },
  {
    front_end_carrier_id: 82,
    prefixes: [
      { prefix: 'D4Q2', frequency: 0.05882353 },
      { prefix: 'WZO17025', frequency: 0.050980393 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5294118 },
      { format: 'adaddddddddd', frequency: 0.18039216 },
      { format: 'aaasddddddddd', frequency: 0.08627451 },
      { format: 'ddddddddd', frequency: 0.047058824 },
      { format: 'adddddddd', frequency: 0.023529412 },
      { format: 'aaaaddddddddd', frequency: 0.023529412 },
      { format: 'aaadddddddddddd', frequency: 0.023529412 },
      { format: 'aaadddaddddd', frequency: 0.015686275 },
      { format: 'aaasaddddddddd', frequency: 0.011764706 },
      { format: 'adasddddddddd', frequency: 0.011764706 },
      { format: 'aaadddddddd', frequency: 0.007843138 },
      { format: 'aaaadddddddd', frequency: 0.007843138 },
      { format: 'aaaddddddddddd', frequency: 0.003921569 },
      { format: 'adadddddddddd', frequency: 0.003921569 },
      { format: 'adadddaddddd', frequency: 0.003921569 },
      { format: 'aadddddddddd', frequency: 0.003921569 },
      { format: 'ddddddd', frequency: 0.003921569 },
      { format: 'aaa', frequency: 0.003921569 },
      { format: 'adahdddhdddhddd', frequency: 0.003921569 },
      { format: 'daddddddddd', frequency: 0.003921569 },
    ],
  },
  {
    front_end_carrier_id: 83,
    prefixes: [
      { prefix: 'X', frequency: 0.21533442 },
      { prefix: 'XQ', frequency: 0.1517129 },
      { prefix: 'R', frequency: 0.08482871 },
      { prefix: 'H', frequency: 0.07177814 },
      { prefix: 'XQWW0', frequency: 0.052202284 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.12724307 }],
    formats: [
      { format: 'aaaadddddddd', frequency: 0.67536706 },
      { format: 'aaaddddddddd', frequency: 0.07830343 },
      { format: 'adddddddd', frequency: 0.053833604 },
      { format: 'adaadddddddd', frequency: 0.045677 },
      { format: 'aaadddaddddd', frequency: 0.03752039 },
      { format: 'aaadddddddddddd', frequency: 0.027732464 },
      { format: 'adadddddddddddd', frequency: 0.01141925 },
      { format: 'aaaaaddddddd', frequency: 0.009787928 },
      { format: 'adaddddddddd', frequency: 0.009787928 },
      { format: 'adadddaddddd', frequency: 0.008156607 },
      { format: 'aaadaaadddddddd', frequency: 0.008156607 },
      { format: 'aaasddddddddd', frequency: 0.004893964 },
      { format: 'aaadddddddaa', frequency: 0.004893964 },
      { format: 'aaaddddddddddd', frequency: 0.0032626428 },
      { format: 'aaadddddddda', frequency: 0.0032626428 },
      { format: 'adadaaadddddddd', frequency: 0.0016313214 },
      { format: 'adaaaddddddd', frequency: 0.0016313214 },
      { format: 'aaadddddddd', frequency: 0.0016313214 },
      { format: 'adadddddddaa', frequency: 0.0016313214 },
      { format: 'aaasaddddddddd', frequency: 0.0016313214 },
      { format: 'aaaddadddddd', frequency: 0.0016313214 },
      { format: 'aaaddddddddda', frequency: 0.0016313214 },
      { format: 'aaadddaadddd', frequency: 0.0016313214 },
      { format: 'aaasadddddddd', frequency: 0.0016313214 },
      { format: 'adaddddddddddd', frequency: 0.0016313214 },
      { format: 'aaaadddadddd', frequency: 0.0016313214 },
    ],
  },
  {
    front_end_carrier_id: 84,
    prefixes: [
      { prefix: '3', frequency: 0.6224299 },
      { prefix: '30', frequency: 0.4728972 },
      { prefix: '2', frequency: 0.34392524 },
      { prefix: '23', frequency: 0.15327103 },
      { prefix: '31', frequency: 0.1495327 },
      { prefix: '24', frequency: 0.10280374 },
      { prefix: '309', frequency: 0.093457945 },
      { prefix: '305', frequency: 0.0635514 },
      { prefix: '311', frequency: 0.057943925 },
      { prefix: '310', frequency: 0.057943925 },
      { prefix: '304', frequency: 0.052336447 },
      { prefix: '307', frequency: 0.05046729 },
    ],
    suffixes: [{ suffix: 'GEHA', frequency: 0.7084112 }],
    formats: [
      { format: 'ddddddddaaaa', frequency: 0.7439252 },
      { format: 'dddddddd', frequency: 0.21682243 },
      { format: 'ddddddddd', frequency: 0.018691588 },
      { format: 'ddddddddddd', frequency: 0.011214953 },
      { format: 'dddddddddd', frequency: 0.0037383179 },
      { format: 'dddddddddaaaa', frequency: 0.0018691589 },
      { format: 'addddddddd', frequency: 0.0018691589 },
      { format: 'ddddddddsaaaa', frequency: 0.0018691589 },
    ],
  },
  {
    front_end_carrier_id: 85,
    prefixes: [
      { prefix: 'N10', frequency: 0.20870535 },
      { prefix: 'E110', frequency: 0.12276786 },
      { prefix: 'N101', frequency: 0.108258925 },
      { prefix: 'E111', frequency: 0.08258928 },
      { prefix: 'W2', frequency: 0.071428575 },
      { prefix: 'E109', frequency: 0.06026786 },
      { prefix: 'N100', frequency: 0.05245536 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.12946428 }],
    formats: [
      { format: 'adddddddd', frequency: 0.69084823 },
      { format: 'addddddddd', frequency: 0.06696428 },
      { format: 'dddddddda', frequency: 0.04017857 },
      { format: 'ddddddddd', frequency: 0.03013393 },
      { format: 'addddddddhdd', frequency: 0.021205356 },
      { format: 'dddddddddddd', frequency: 0.020089285 },
      { format: 'aaadddddddda', frequency: 0.017857144 },
      { format: 'aaddddddd', frequency: 0.014508928 },
      { format: 'aaddddddddd', frequency: 0.011160715 },
      { format: 'ddddddddddd', frequency: 0.008928572 },
      { format: 'adddddddddd', frequency: 0.0078125 },
      { format: 'dd', frequency: 0.0078125 },
      { format: 'addddsddddd', frequency: 0.0055803573 },
      { format: 'dddddddddd', frequency: 0.0055803573 },
      { format: 'ddd', frequency: 0.004464286 },
      { format: 'dddddddd', frequency: 0.0033482143 },
      { format: 'aa', frequency: 0.002232143 },
      { format: 'adaddddddddd', frequency: 0.002232143 },
      { format: 'dddddddddhdd', frequency: 0.002232143 },
      { format: 'adaaaddddddd', frequency: 0.002232143 },
      { format: 'aaddddddddddd', frequency: 0.002232143 },
      { format: 'aaaddddddddd', frequency: 0.002232143 },
      { format: 'ddddsdddddhdd', frequency: 0.002232143 },
      { format: 'dddddd', frequency: 0.002232143 },
      { format: 'ddddd', frequency: 0.002232143 },
      { format: 'ddddddd', frequency: 0.0011160715 },
      { format: 'aadddd', frequency: 0.0011160715 },
      { format: 'dddd', frequency: 0.0011160715 },
      { format: 'addddddddddd', frequency: 0.0011160715 },
      { format: 'aaadddddd', frequency: 0.0011160715 },
      { format: 'addddsdddddhdd', frequency: 0.0011160715 },
      { format: 'aaa', frequency: 0.0011160715 },
      { format: 'aaaaaddddddd', frequency: 0.0011160715 },
      { format: 'addddddddddhdd', frequency: 0.0011160715 },
      { format: 'aaaddddddd', frequency: 0.0011160715 },
      { format: 'asdddddddddd', frequency: 0.0011160715 },
      { format: 'aadddddddhdd', frequency: 0.0011160715 },
      { format: 'ddddddddddddddd', frequency: 0.0011160715 },
      { format: 'adddddddddhdd', frequency: 0.0011160715 },
      { format: 'adadddddddda', frequency: 0.0011160715 },
      { format: 'adddd', frequency: 0.0011160715 },
      { format: 'dddddddaa', frequency: 0.0011160715 },
      { format: 'addddddd', frequency: 0.0011160715 },
      { format: 'addddsdddd', frequency: 0.0011160715 },
      { format: 'aaadddaddddd', frequency: 0.0011160715 },
      { format: 'aaddddd', frequency: 0.0011160715 },
    ],
  },
  {
    front_end_carrier_id: 86,
    prefixes: [
      { prefix: 'W2', frequency: 0.31239396 },
      { prefix: 'W28', frequency: 0.116963916 },
      { prefix: 'W27', frequency: 0.07111346 },
      { prefix: '0', frequency: 0.06391162 },
      { prefix: '9', frequency: 0.061686967 },
      { prefix: '2', frequency: 0.057954073 },
      { prefix: '10', frequency: 0.05780325 },
      { prefix: '3', frequency: 0.053429358 },
      { prefix: '7', frequency: 0.053353947 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.15338787 }],
    formats: [
      { format: 'dddddddddd', frequency: 0.47275743 },
      { format: 'addddddddd', frequency: 0.31152672 },
      { format: 'dddddddddddd', frequency: 0.06161155 },
      { format: 'addddsddddd', frequency: 0.039817505 },
      { format: 'ddddddddd', frequency: 0.026356472 },
      { format: 'ddddddddddd', frequency: 0.0138003845 },
      { format: 'adddddddd', frequency: 0.010557671 },
      { format: 'addddddddddd', frequency: 0.008898608 },
      { format: 'aaddddddd', frequency: 0.0057313074 },
      { format: 'adddddddddhdd', frequency: 0.0055050715 },
      { format: 'adddddddddd', frequency: 0.004939482 },
      { format: 'aaadddaddddd', frequency: 0.003468949 },
      { format: 'dddddddddhdd', frequency: 0.002413182 },
      { format: 'dddddddd', frequency: 0.002186946 },
      { format: 'aaaddddddddd', frequency: 0.0017721805 },
      { format: 'addddsdddddhdd', frequency: 0.0017721805 },
      { format: 'aaaddddddd', frequency: 0.0016590626 },
      { format: 'addddd', frequency: 0.0015082387 },
      { format: 'dddddddda', frequency: 0.0013951209 },
      { format: 'aaddddddddd', frequency: 0.0013951209 },
      { format: 'ddddddddddddd', frequency: 0.001244297 },
      { format: 'addddhddddd', frequency: 0.0010180612 },
    ],
  },
  {
    front_end_carrier_id: 87,
    prefixes: [
      { prefix: 'ZZ', frequency: 0.47054324 },
      { prefix: 'AH', frequency: 0.20811017 },
      { prefix: 'ZZ05', frequency: 0.13465953 },
      { prefix: 'W2', frequency: 0.067329764 },
      { prefix: 'AH05', frequency: 0.060443763 },
      { prefix: 'zz', frequency: 0.054322876 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaddddddd', frequency: 0.76587605 },
      { format: 'addddddddd', frequency: 0.059678655 },
      { format: 'ddddddddd', frequency: 0.03136955 },
      { format: 'aaaddddddd', frequency: 0.026013771 },
      { format: 'dddddddddddd', frequency: 0.020657996 },
      { format: 'adddddddd', frequency: 0.013771997 },
      { format: 'ddddddd', frequency: 0.009181331 },
      { format: 'dddddddddd', frequency: 0.009181331 },
      { format: 'aadddddddd', frequency: 0.0061208876 },
      { format: 'addddsddddd', frequency: 0.0053557768 },
      { format: 'ddddddddddd', frequency: 0.0045906655 },
      { format: 'addddd', frequency: 0.0045906655 },
      { format: 'adddddd', frequency: 0.0038255546 },
      { format: 'aadddddd', frequency: 0.0038255546 },
      { format: 'adddddddddd', frequency: 0.0030604438 },
      { format: 'addddddddddd', frequency: 0.0030604438 },
      { format: 'ddddddddddddddd', frequency: 0.0022953327 },
      { format: 'dddddddd', frequency: 0.0022953327 },
      { format: 'aaadddaddddd', frequency: 0.0022953327 },
      { format: 'aaddddddddd', frequency: 0.0022953327 },
      { format: 'aaadddddd', frequency: 0.0022953327 },
      { format: 'ddddd', frequency: 0.0015302219 },
      { format: 'ddddsdddddhdd', frequency: 0.0015302219 },
    ],
  },
  {
    front_end_carrier_id: 88,
    prefixes: [
      { prefix: 'E11', frequency: 0.17660044 },
      { prefix: 'E10', frequency: 0.14569536 },
      { prefix: 'E110', frequency: 0.12582782 },
      { prefix: '2', frequency: 0.057395145 },
      { prefix: 'E108', frequency: 0.05518764 },
      { prefix: 'E111', frequency: 0.050772626 },
      { prefix: 'N22', frequency: 0.050772626 },
      { prefix: '10', frequency: 0.050772626 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.14790288 },
      { suffix: '1', frequency: 0.14348786 },
    ],
    formats: [
      { format: 'adddddddd', frequency: 0.410596 },
      { format: 'addddddddd', frequency: 0.119205296 },
      { format: 'dddddddd', frequency: 0.09492274 },
      { format: 'ddddhdddd', frequency: 0.068432674 },
      { format: 'dddddddddddd', frequency: 0.048565123 },
      { format: 'adddddddddd', frequency: 0.028697573 },
      { format: 'ddddddddd', frequency: 0.026490066 },
      { format: 'aaddddddddd', frequency: 0.022075055 },
      { format: 'aaddddddd', frequency: 0.01986755 },
      { format: 'aaadddddddda', frequency: 0.017660044 },
      { format: 'dddddddda', frequency: 0.017660044 },
      { format: 'addddddddhdd', frequency: 0.015452539 },
      { format: 'aaddddd', frequency: 0.011037528 },
      { format: 'addddsddddd', frequency: 0.011037528 },
      { format: 'dddddddddd', frequency: 0.011037528 },
      { format: 'ddddddddddd', frequency: 0.008830022 },
      { format: 'adaaaddddddd', frequency: 0.0066225166 },
      { format: 'ddddsdddddhdd', frequency: 0.0066225166 },
      { format: 'ddddddd', frequency: 0.004415011 },
      { format: 'aaaadddddddd', frequency: 0.004415011 },
      { format: 'dddddddddhdd', frequency: 0.004415011 },
      { format: 'adaddddddddd', frequency: 0.004415011 },
      { format: 'addddsdddddhdd', frequency: 0.004415011 },
      { format: 'ddddddddddddddd', frequency: 0.0022075055 },
      { format: 'adddddddddhdd', frequency: 0.0022075055 },
      { format: 'dddd', frequency: 0.0022075055 },
      { format: 'addddddddddd', frequency: 0.0022075055 },
      { format: 'aaadddddd', frequency: 0.0022075055 },
      { format: 'addddddd', frequency: 0.0022075055 },
      { format: 'dddddd', frequency: 0.0022075055 },
      { format: 'adddd', frequency: 0.0022075055 },
      { format: 'ddddd', frequency: 0.0022075055 },
      { format: 'dddddaddddd', frequency: 0.0022075055 },
      { format: 'aaaaaddddddd', frequency: 0.0022075055 },
      { format: 'aaadddaddddd', frequency: 0.0022075055 },
      { format: 'addddddddddhdd', frequency: 0.0022075055 },
      { format: 'aaaddddddd', frequency: 0.0022075055 },
      { format: 'aadddddddhdd', frequency: 0.0022075055 },
    ],
  },
  {
    front_end_carrier_id: 89,
    prefixes: [
      { prefix: '8', frequency: 0.53697217 },
      { prefix: '9', frequency: 0.30710608 },
      { prefix: '91', frequency: 0.091452114 },
      { prefix: '88', frequency: 0.085066944 },
      { prefix: '85', frequency: 0.08259526 },
      { prefix: '90', frequency: 0.07621009 },
      { prefix: '89', frequency: 0.07415036 },
      { prefix: '86', frequency: 0.06673533 },
      { prefix: '87', frequency: 0.065705456 },
      { prefix: '7', frequency: 0.06343975 },
      { prefix: '92', frequency: 0.057054583 },
    ],
    suffixes: [],
    formats: [
      { format: 'ddddddd', frequency: 0.90566427 },
      { format: 'ddddddddd', frequency: 0.04799176 },
      { format: 'dddddddd', frequency: 0.0096807415 },
      { format: 'ddddddddddd', frequency: 0.0059732236 },
      { format: 'dddddddddddd', frequency: 0.0035015447 },
      { format: 'dddddddddd', frequency: 0.0030895984 },
      { format: 'dddddd', frequency: 0.0030895984 },
      { format: 'ddddhdddhd', frequency: 0.0028836252 },
      { format: 'ddddhddddddhd', frequency: 0.0020597323 },
      { format: 'dddddddhdd', frequency: 0.0014418126 },
      { format: 'dddddddddddddd', frequency: 0.0012358393 },
      { format: 'ddddddddddddd', frequency: 0.0010298662 },
      { format: 'dddddddddhdd', frequency: 0.0010298662 },
    ],
  },
  {
    front_end_carrier_id: 90,
    prefixes: [
      { prefix: '1', frequency: 0.08857037 },
      { prefix: 'Y', frequency: 0.06212908 },
      { prefix: '001', frequency: 0.053227004 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.13957185 }],
    formats: [
      { format: 'dddddddd', frequency: 0.6782535 },
      { format: 'dddddddddddd', frequency: 0.07802565 },
      { format: 'adddddddd', frequency: 0.06721598 },
      { format: 'dddddddddd', frequency: 0.05818143 },
      { format: 'ddddddddd', frequency: 0.04379504 },
      { format: 'ddddddddaaaa', frequency: 0.034098133 },
      { format: 'aaaddddddddd', frequency: 0.00861064 },
      { format: 'aaaaddddd', frequency: 0.006120178 },
      { format: 'dddddddda', frequency: 0.003232302 },
      { format: 'aaaddddddd', frequency: 0.003073336 },
      { format: 'addddddddaaa', frequency: 0.0029143705 },
      { format: 'aaadddddd', frequency: 0.002755405 },
      { format: 'aaddddddd', frequency: 0.0020665536 },
      { format: 'dddsddddddddd', frequency: 0.0018810937 },
      { format: 'addddddddd', frequency: 0.0015896567 },
      { format: 'ddddddd', frequency: 0.0012187368 },
      { format: 'ddddddddddd', frequency: 0.001059771 },
    ],
  },
  {
    front_end_carrier_id: 91,
    prefixes: [
      { prefix: 'C0', frequency: 0.43944716 },
      { prefix: 'C06', frequency: 0.27992505 },
      { prefix: '09', frequency: 0.24502225 },
      { prefix: 'C05', frequency: 0.15905364 },
      { prefix: '096', frequency: 0.11852893 },
      { prefix: '097', frequency: 0.11056454 },
      { prefix: 'C063', frequency: 0.084797375 },
      { prefix: 'C062', frequency: 0.07378777 },
      { prefix: '44', frequency: 0.07191379 },
      { prefix: '9', frequency: 0.059030216 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.12789881 }],
    formats: [
      { format: 'adddddddd', frequency: 0.46216914 },
      { format: 'ddddddddd', frequency: 0.40337315 },
      { format: 'ddddddddddd', frequency: 0.06020145 },
      { format: 'dddsdddsddd', frequency: 0.011243851 },
      { format: 'aaddddddd', frequency: 0.010072617 },
      { format: 'dddddddd', frequency: 0.007964394 },
      { format: 'dddddddddd', frequency: 0.007730148 },
      { format: 'dddddddddddd', frequency: 0.004450691 },
      { format: 'ddddddd', frequency: 0.0028109627 },
      { format: 'addddddd', frequency: 0.0028109627 },
      { format: 'adddddddddd', frequency: 0.0025767158 },
      { format: 'addddddddd', frequency: 0.002108222 },
      { format: 'dddddddhdd', frequency: 0.0014054814 },
      { format: 'dddddddddhdd', frequency: 0.0014054814 },
      { format: 'ddaddddddd', frequency: 0.0011712345 },
      { format: 'aaaddddddddd', frequency: 0.0011712345 },
    ],
  },
  {
    front_end_carrier_id: 92,
    prefixes: [
      { prefix: '992', frequency: 0.15586796 },
      { prefix: '7', frequency: 0.067848414 },
      { prefix: '8', frequency: 0.056234717 },
      { prefix: 'N01', frequency: 0.05348411 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.5155868 },
      { suffix: '02', frequency: 0.11522005 },
    ],
    formats: [
      { format: 'adddddddddd', frequency: 0.32121027 },
      { format: 'ddddddddddd', frequency: 0.14975551 },
      { format: 'ddddadddddd', frequency: 0.14119804 },
      { format: 'ddddddddd', frequency: 0.12652811 },
      { format: 'addddddddsdd', frequency: 0.0846577 },
      { format: 'dddddddddsdd', frequency: 0.029951101 },
      { format: 'adddddddd', frequency: 0.029339854 },
      { format: 'aaaaddddd', frequency: 0.0137530565 },
      { format: 'dddddddddddd', frequency: 0.011308068 },
      { format: 'addddddddhdd', frequency: 0.009779952 },
      { format: 'dddddddd', frequency: 0.008251834 },
      { format: 'dddddddddd', frequency: 0.0076405867 },
      { format: 'aaddddddddd', frequency: 0.006418093 },
      { format: 'dddddddddhdd', frequency: 0.005195599 },
      { format: 'addddddddddd', frequency: 0.0042787287 },
      { format: 'addddddddd', frequency: 0.003973105 },
      { format: 'aadddddddddd', frequency: 0.0036674817 },
      { format: 'aaaddddddddd', frequency: 0.0036674817 },
      { format: 'aadddddddhdd', frequency: 0.0030562347 },
      { format: 'aaadddddd', frequency: 0.0027506112 },
      { format: 'aaddddddd', frequency: 0.002444988 },
      { format: 'ddddddd', frequency: 0.002444988 },
      { format: 'ddddaddddd', frequency: 0.0021393644 },
      { format: 'ddddddddddddd', frequency: 0.0018337409 },
      { format: 'daadddddddd', frequency: 0.0015281173 },
      { format: 'aaaddaddd', frequency: 0.001222494 },
      { format: 'ddddddddsdd', frequency: 0.001222494 },
      { format: 'dddadddddd', frequency: 0.001222494 },
    ],
  },
  {
    front_end_carrier_id: 93,
    prefixes: [
      { prefix: '0025', frequency: 0.13362701 },
      { prefix: '0023', frequency: 0.09985316 },
      { prefix: '0024', frequency: 0.08957416 },
      { prefix: '8030', frequency: 0.08223201 },
      { prefix: 'U', frequency: 0.055800293 },
    ],
    suffixes: [{ suffix: '00', frequency: 0.26872247 }],
    formats: [
      { format: 'ddddddddd', frequency: 0.5756241 },
      { format: 'ddddddddddd', frequency: 0.21292217 },
      { format: 'dddddddddsdd', frequency: 0.04405286 },
      { format: 'adddddddd', frequency: 0.038179148 },
      { format: 'adddddddddd', frequency: 0.03524229 },
      { format: 'addddddddsdd', frequency: 0.010279002 },
      { format: 'dddddddddd', frequency: 0.010279002 },
      { format: 'dddddddd', frequency: 0.008810572 },
      { format: 'aaadddddddd', frequency: 0.008810572 },
      { format: 'aaddddddaaa', frequency: 0.005873715 },
      { format: 'dddddddddddd', frequency: 0.005873715 },
      { format: 'aaddddddaaadd', frequency: 0.004405286 },
      { format: 'dddddddddhdd', frequency: 0.004405286 },
      { format: 'ddddddd', frequency: 0.004405286 },
      { format: 'addddddddhdd', frequency: 0.0029368575 },
      { format: 'aaddddddaaasdd', frequency: 0.0029368575 },
      { format: 'aadddddddddd', frequency: 0.0029368575 },
      { format: 'addddddddd', frequency: 0.0014684288 },
      { format: 'aaaddddddd', frequency: 0.0014684288 },
      { format: 'addddddd', frequency: 0.0014684288 },
      { format: 'aaaaaaaa', frequency: 0.0014684288 },
      { format: 'dddddd', frequency: 0.0014684288 },
      { format: 'aaadddaddddd', frequency: 0.0014684288 },
      { format: 'adddddddsdd', frequency: 0.0014684288 },
      { format: 'dddddddddsddsa', frequency: 0.0014684288 },
      { format: 'daaddddddddsdd', frequency: 0.0014684288 },
      { format: 'dddddddddsddsaaddddaa', frequency: 0.0014684288 },
      { format: 'aaadddddddhdd', frequency: 0.0014684288 },
      { format: 'ddddddddda', frequency: 0.0014684288 },
      { format: 'ddddddddsdd', frequency: 0.0014684288 },
      { format: 'dddddddddsddd', frequency: 0.0014684288 },
      { format: 'dddddddddddaaddddaa', frequency: 0.0014684288 },
    ],
  },
  {
    front_end_carrier_id: 104,
    prefixes: [
      { prefix: 'A', frequency: 0.80196804 },
      { prefix: 'A60', frequency: 0.30381304 },
      { prefix: 'A10', frequency: 0.21094711 },
      { prefix: 'A601', frequency: 0.15313654 },
      { prefix: 'A600', frequency: 0.1506765 },
      { prefix: 'A530', frequency: 0.06888069 },
      { prefix: '9', frequency: 0.06334563 },
      { prefix: 'A521', frequency: 0.06088561 },
      { prefix: 'A107', frequency: 0.06088561 },
      { prefix: 'A6012', frequency: 0.052890528 },
      { prefix: 'A520', frequency: 0.05104551 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.4901599 },
      { suffix: '01', frequency: 0.14821649 },
    ],
    formats: [
      { format: 'adddddddddd', frequency: 0.77429277 },
      { format: 'ddddddddd', frequency: 0.08856089 },
      { format: 'adddddddd', frequency: 0.040590405 },
      { format: 'dddddddddd', frequency: 0.016605167 },
      { format: 'addddddddhdd', frequency: 0.012915129 },
      { format: 'dddddddd', frequency: 0.011070111 },
      { format: 'ddddddddddd', frequency: 0.0104551045 },
      { format: 'addddddddd', frequency: 0.00799508 },
      { format: 'addddddddddd', frequency: 0.0043050433 },
      { format: 'ddddddd', frequency: 0.003690037 },
      { format: 'dddddddddddd', frequency: 0.003690037 },
      { format: 'asdddddddddd', frequency: 0.003690037 },
      { format: 'dddddddhdd', frequency: 0.0030750309 },
      { format: 'aaaddddddddhdd', frequency: 0.0018450185 },
      { format: 'dddd', frequency: 0.0012300123 },
      { format: 'dddddddddhdd', frequency: 0.0012300123 },
    ],
  },
  {
    front_end_carrier_id: 105,
    prefixes: [{ prefix: 'PSC9', frequency: 0.07114624 }],
    suffixes: [
      { suffix: '0', frequency: 0.18972331 },
      { suffix: '1', frequency: 0.13833992 },
      { suffix: '3', frequency: 0.12252964 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.45849803 },
      { format: 'addddddddd', frequency: 0.19367589 },
      { format: 'aaaddddddddd', frequency: 0.08300395 },
      { format: 'dddddddddddd', frequency: 0.06324111 },
      { format: 'adddddddd', frequency: 0.027667984 },
      { format: 'addddsddddd', frequency: 0.019762846 },
      { format: 'ddddddddddd', frequency: 0.019762846 },
      { format: 'dddddddddd', frequency: 0.019762846 },
      { format: 'ddddsdddddhdd', frequency: 0.011857707 },
      { format: 'ddddddd', frequency: 0.007905139 },
      { format: 'ddddddddddddddd', frequency: 0.007905139 },
      { format: 'aaddddddddd', frequency: 0.007905139 },
      { format: 'addddddddddd', frequency: 0.007905139 },
      { format: 'dddddddddhdd', frequency: 0.007905139 },
      { format: 'dddddddd', frequency: 0.007905139 },
      { format: 'd', frequency: 0.0039525693 },
      { format: 'adddddddddhdd', frequency: 0.0039525693 },
      { format: 'dddddd', frequency: 0.0039525693 },
      { format: 'adddddddddd', frequency: 0.0039525693 },
      { format: 'addddsdddddhdd', frequency: 0.0039525693 },
      { format: 'aaddddddd', frequency: 0.0039525693 },
      { format: 'a', frequency: 0.0039525693 },
      { format: 'adddd', frequency: 0.0039525693 },
      { format: 'ddddd', frequency: 0.0039525693 },
      { format: 'dddsdddsddd', frequency: 0.0039525693 },
      { format: 'aaaaaddddddd', frequency: 0.0039525693 },
      { format: 'aaadddaddddd', frequency: 0.0039525693 },
      { format: 'addddddddddhdd', frequency: 0.0039525693 },
      { format: 'aaaddddddd', frequency: 0.0039525693 },
    ],
  },
  {
    front_end_carrier_id: 106,
    prefixes: [
      { prefix: '771900', frequency: 0.12343052 },
      { prefix: '1', frequency: 0.09372207 },
      { prefix: '0', frequency: 0.09333901 },
      { prefix: '890', frequency: 0.059204087 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.18012343 },
      { suffix: '0', frequency: 0.13228346 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4869121 },
      { format: 'dddddddddddd', frequency: 0.17986806 },
      { format: 'dddddddhdd', frequency: 0.058778465 },
      { format: 'ddddddddddd', frequency: 0.052819747 },
      { format: 'dddddddd', frequency: 0.04124282 },
      { format: 'dddddddddd', frequency: 0.026771653 },
      { format: 'dddddddddhdd', frequency: 0.019025324 },
      { format: 'ddddddd', frequency: 0.019025324 },
      { format: 'adddddddd', frequency: 0.014088104 },
      { format: 'aaaddddddddd', frequency: 0.0071078953 },
      { format: 'ddddddddaaaa', frequency: 0.0063843373 },
      { format: 'addddddddd', frequency: 0.0060438393 },
      { format: 'adddddddddd', frequency: 0.0054054055 },
      { format: 'ddaddddddd', frequency: 0.004681847 },
      { format: 'aadddddddhdd', frequency: 0.0045541604 },
      { format: 'aaaddddddd', frequency: 0.0035752289 },
      { format: 'aaddddddd', frequency: 0.0028516706 },
      { format: 'aaddddddddd', frequency: 0.0022983614 },
      { format: 'aaadddaddddd', frequency: 0.0021281124 },
      { format: 'dddddd', frequency: 0.0018301767 },
      { format: 'ddddddddddddd', frequency: 0.0017024899 },
      { format: 'aaaaaahdd', frequency: 0.0016599277 },
      { format: 'aaadddddddd', frequency: 0.0016173654 },
      { format: 'dddsdddsddd', frequency: 0.0015748031 },
      { format: 'aaaddddddhdd', frequency: 0.0013619919 },
      { format: 'aaaaddddd', frequency: 0.0012343052 },
      { format: 'aaadaahdd', frequency: 0.0010640562 },
      { format: 'aaadddddd', frequency: 0.0010214939 },
      { format: 'aaaadddddddd', frequency: 0.0010214939 },
    ],
  },
  {
    front_end_carrier_id: 107,
    prefixes: [
      { prefix: '1', frequency: 0.660826 },
      { prefix: '12', frequency: 0.25531915 },
      { prefix: '13', frequency: 0.19899875 },
      { prefix: '10', frequency: 0.11514393 },
      { prefix: '11', frequency: 0.08510638 },
      { prefix: 'U', frequency: 0.07634543 },
      { prefix: '128', frequency: 0.07008761 },
      { prefix: '5', frequency: 0.06382979 },
      { prefix: '132', frequency: 0.061326656 },
      { prefix: '3', frequency: 0.060075093 },
      { prefix: '133', frequency: 0.05882353 },
      { prefix: '129', frequency: 0.055068836 },
      { prefix: '131', frequency: 0.055068836 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.155194 }],
    formats: [
      { format: 'dddddddd', frequency: 0.6858573 },
      { format: 'ddddddddd', frequency: 0.096370466 },
      { format: 'adddddddddd', frequency: 0.048811015 },
      { format: 'adddddddd', frequency: 0.03629537 },
      { format: 'ddddddddddd', frequency: 0.027534418 },
      { format: 'ddddddd', frequency: 0.021276595 },
      { format: 'dddddddddd', frequency: 0.015018773 },
      { format: 'aadddddddddd', frequency: 0.01126408 },
      { format: 'addddddddsdd', frequency: 0.01126408 },
      { format: 'dddddddddddd', frequency: 0.008760951 },
      { format: 'addddddddhdd', frequency: 0.0037546933 },
      { format: 'ddddddddddddd', frequency: 0.002503129 },
      { format: 'aaadddddd', frequency: 0.002503129 },
      { format: 'ddddddddda', frequency: 0.002503129 },
      { format: 'aaaddddddd', frequency: 0.0012515645 },
      { format: 'd', frequency: 0.0012515645 },
      { format: 'addaaadddddd', frequency: 0.0012515645 },
      { format: 'addddddddd', frequency: 0.0012515645 },
      { format: 'aaddddddddd', frequency: 0.0012515645 },
      { format: 'aaddddddd', frequency: 0.0012515645 },
      { format: 'ddddddddad', frequency: 0.0012515645 },
      { format: 'aaadddddaddddd', frequency: 0.0012515645 },
      { format: 'dddddd', frequency: 0.0012515645 },
      { format: 'ddaddddddd', frequency: 0.0012515645 },
      { format: 'ddddddddsdd', frequency: 0.0012515645 },
      { format: 'addddddddaaa', frequency: 0.0012515645 },
      { format: 'addddddddddd', frequency: 0.0012515645 },
      { format: 'aaaadddddddd', frequency: 0.0012515645 },
      { format: 'dd', frequency: 0.0012515645 },
      { format: 'dddddddddhdd', frequency: 0.0012515645 },
      { format: 'dddddddddsdd', frequency: 0.0012515645 },
      { format: 'ddd', frequency: 0.0012515645 },
      { format: 'aaadddddddhdd', frequency: 0.0012515645 },
      { format: 'aaaddddddddsdd', frequency: 0.0012515645 },
      { format: 'ddddd', frequency: 0.0012515645 },
    ],
  },
  {
    front_end_carrier_id: 108,
    prefixes: [
      { prefix: '09', frequency: 0.34349826 },
      { prefix: '096', frequency: 0.17779057 },
      { prefix: '097', frequency: 0.13176064 },
      { prefix: 'C0', frequency: 0.1306099 },
      { prefix: '44', frequency: 0.078826234 },
      { prefix: 'C06', frequency: 0.07825086 },
      { prefix: '0971', frequency: 0.05581128 },
      { prefix: 'C05', frequency: 0.052359033 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.13751438 },
      { suffix: '1', frequency: 0.12658228 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.63348675 },
      { format: 'adddddddd', frequency: 0.14096662 },
      { format: 'ddddddddddd', frequency: 0.10586882 },
      { format: 'dddsdddsddd', frequency: 0.022439586 },
      { format: 'dddddddddd', frequency: 0.016110472 },
      { format: 'dddddddd', frequency: 0.013233602 },
      { format: 'dddddddddddd', frequency: 0.012082854 },
      { format: 'ddddddd', frequency: 0.0057537397 },
      { format: 'dddddddddhdd', frequency: 0.004602992 },
      { format: 'ddaddddddd', frequency: 0.003452244 },
      { format: 'adddddddddd', frequency: 0.003452244 },
      { format: 'dddddddhdd', frequency: 0.0028768699 },
      { format: 'aaddddddd', frequency: 0.0028768699 },
      { format: 'aaaddddddddd', frequency: 0.002301496 },
      { format: 'aaadddaddddd', frequency: 0.001726122 },
      { format: 'ddddd', frequency: 0.001726122 },
      { format: 'ddddddddaaaa', frequency: 0.001150748 },
      { format: 'aaadaaadddddddd', frequency: 0.001150748 },
      { format: 'dddd', frequency: 0.001150748 },
      { format: 'dddhdddhddd', frequency: 0.001150748 },
      { format: 'aaddddddddd', frequency: 0.001150748 },
      { format: 'addddsddddd', frequency: 0.001150748 },
      { format: 'dddddd', frequency: 0.001150748 },
      { format: 'aaadddd', frequency: 0.001150748 },
      { format: 'dddhdddddhdd', frequency: 0.001150748 },
      { format: 'addddddddd', frequency: 0.001150748 },
    ],
  },
  {
    front_end_carrier_id: 109,
    prefixes: [
      { prefix: '10', frequency: 0.1831357 },
      { prefix: 'W2', frequency: 0.10013175 },
      { prefix: '101', frequency: 0.09354414 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.13306983 }],
    formats: [
      { format: 'ddddddddd', frequency: 0.55599475 },
      { format: 'ddddddd', frequency: 0.10408432 },
      { format: 'addddddddd', frequency: 0.08959157 },
      { format: 'dddddd', frequency: 0.076416336 },
      { format: 'dddddddddddd', frequency: 0.03557312 },
      { format: 'adddddddd', frequency: 0.018445322 },
      { format: 'dddddddddd', frequency: 0.014492754 },
      { format: 'ddddddddddd', frequency: 0.011857707 },
      { format: 'addddsddddd', frequency: 0.010540185 },
      { format: 'aaaddddddddd', frequency: 0.009222661 },
      { format: 'aaddddddd', frequency: 0.007905139 },
      { format: 'adddddddddd', frequency: 0.007905139 },
      { format: 'dddd', frequency: 0.0065876152 },
      { format: 'ddddsdddddhdd', frequency: 0.0052700923 },
      { format: 'aaddddddddd', frequency: 0.0052700923 },
      { format: 'aaadddaddddd', frequency: 0.0039525693 },
      { format: 'dddddddddhdd', frequency: 0.0039525693 },
      { format: 'addddsdddddhdd', frequency: 0.0026350461 },
      { format: 'aaaa', frequency: 0.0026350461 },
      { format: 'ddddsddddd', frequency: 0.0026350461 },
      { format: 'dddddddd', frequency: 0.0026350461 },
      { format: 'addddddddddhdd', frequency: 0.0026350461 },
      { format: 'ddddddddddddddd', frequency: 0.0026350461 },
      { format: 'ddd', frequency: 0.0026350461 },
      { format: 'addddddddddd', frequency: 0.0013175231 },
      { format: 'aaadddddd', frequency: 0.0013175231 },
      { format: 'a', frequency: 0.0013175231 },
      { format: 'aaaaaddddddd', frequency: 0.0013175231 },
      { format: 'aaaddddddd', frequency: 0.0013175231 },
      { format: 'asdddddddddd', frequency: 0.0013175231 },
      { format: 'dddaddddd', frequency: 0.0013175231 },
      { format: 'd', frequency: 0.0013175231 },
      { format: 'adddddddddhdd', frequency: 0.0013175231 },
      { format: 'adddd', frequency: 0.0013175231 },
      { format: 'ddddd', frequency: 0.0013175231 },
    ],
  },
  {
    front_end_carrier_id: 110,
    prefixes: [
      { prefix: 'W2', frequency: 0.10617551 },
      { prefix: '0', frequency: 0.06608884 },
      { prefix: '101', frequency: 0.058504876 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.14084508 }],
    formats: [
      { format: 'dddddddddddd', frequency: 0.45720476 },
      { format: 'ddddddddd', frequency: 0.13434453 },
      { format: 'addddddddd', frequency: 0.094257854 },
      { format: 'dddddddd', frequency: 0.07367281 },
      { format: 'adddddddd', frequency: 0.04442037 },
      { format: 'ddddddddddd', frequency: 0.032502707 },
      { format: 'dddddddddd', frequency: 0.027085591 },
      { format: 'aaddddddd', frequency: 0.014084507 },
      { format: 'adddddddddd', frequency: 0.013001083 },
      { format: 'addddsddddd', frequency: 0.01191766 },
      { format: 'aaadddddddd', frequency: 0.0065005417 },
      { format: 'ddddddd', frequency: 0.0054171183 },
      { format: 'aaadddaddddd', frequency: 0.0054171183 },
      { format: 'ddddsdddddhdd', frequency: 0.0054171183 },
      { format: 'ddddddddddddd', frequency: 0.0054171183 },
      { format: 'aaddddddddd', frequency: 0.0054171183 },
      { format: 'dddddddddhdd', frequency: 0.0054171183 },
      { format: 'addddddddddd', frequency: 0.0043336945 },
      { format: 'ddddddddddddddd', frequency: 0.0043336945 },
      { format: 'aaadddddd', frequency: 0.0032502708 },
      { format: 'dddhddhaaaa', frequency: 0.0021668472 },
      { format: 'aaaddddddddd', frequency: 0.0021668472 },
      { format: 'aaaaaddddddd', frequency: 0.0021668472 },
      { format: 'addddddddddhdd', frequency: 0.0021668472 },
      { format: 'aaaddddddd', frequency: 0.0021668472 },
      { format: 'dddddd', frequency: 0.0021668472 },
      { format: 'aaaaddddddd', frequency: 0.0021668472 },
      { format: 'addddddd', frequency: 0.0021668472 },
      { format: 'adddddd', frequency: 0.0010834236 },
      { format: 'aaaddddaaaa', frequency: 0.0010834236 },
      { format: 'dddddddddddddd', frequency: 0.0010834236 },
      { format: 'dddddaaaa', frequency: 0.0010834236 },
      { format: 'addddsdddddhdd', frequency: 0.0010834236 },
      { format: 'a', frequency: 0.0010834236 },
      { format: 'aaaadddddddddd', frequency: 0.0010834236 },
      { format: 'addddddddhdd', frequency: 0.0010834236 },
      { format: 'aaadaaadddddddd', frequency: 0.0010834236 },
      { format: 'dddaddddd', frequency: 0.0010834236 },
      { format: 'ddddddddddsdd', frequency: 0.0010834236 },
      { format: 'd', frequency: 0.0010834236 },
      { format: 'addddhdddd', frequency: 0.0010834236 },
      { format: 'dddddaddddd', frequency: 0.0010834236 },
      { format: 'aaasdddddddddsdd', frequency: 0.0010834236 },
      { format: 'ddadddddd', frequency: 0.0010834236 },
      { format: 'aaaaddddddddd', frequency: 0.0010834236 },
      { format: 'dddadaadadda', frequency: 0.0010834236 },
      { format: 'aasddddddd', frequency: 0.0010834236 },
      { format: 'ddhdddddddd', frequency: 0.0010834236 },
      { format: 'dddddsdddd', frequency: 0.0010834236 },
      { format: 'aa', frequency: 0.0010834236 },
      { format: 'asdddddddddd', frequency: 0.0010834236 },
      { format: 'adddddddddhdd', frequency: 0.0010834236 },
      { format: 'adddd', frequency: 0.0010834236 },
      { format: 'ddd', frequency: 0.0010834236 },
      { format: 'ddddd', frequency: 0.0010834236 },
    ],
  },
  {
    front_end_carrier_id: 112,
    prefixes: [
      { prefix: 'AQT60', frequency: 0.08389513 },
      { prefix: 'R', frequency: 0.074157305 },
      { prefix: 'M', frequency: 0.050187267 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.22022472 },
      { suffix: '01', frequency: 0.16029963 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29363295 },
      { format: 'aaaddddddddd', frequency: 0.19325842 },
      { format: 'aaaddddddddddd', frequency: 0.13033707 },
      { format: 'adddddddd', frequency: 0.07565543 },
      { format: 'ddddddddddd', frequency: 0.033707865 },
      { format: 'aaasddddddddd', frequency: 0.0329588 },
      { format: 'adaddddddddd', frequency: 0.02846442 },
      { format: 'aaadddaddddd', frequency: 0.025468165 },
      { format: 'adaddddddddddd', frequency: 0.017977528 },
      { format: 'aaadddddddddddd', frequency: 0.017228464 },
      { format: 'aaasdddddddddsdd', frequency: 0.017228464 },
      { format: 'aaadddddddda', frequency: 0.015730336 },
      { format: 'dddddddddhdd', frequency: 0.009737828 },
      { format: 'aaasddddddddddd', frequency: 0.009737828 },
      { format: 'adadddaddddd', frequency: 0.008988764 },
      { format: 'aaaadddddddd', frequency: 0.007490637 },
      { format: 'adaaaddddddd', frequency: 0.004494382 },
      { format: 'aaaaddddddddd', frequency: 0.004494382 },
      { format: 'aaasaddddddddd', frequency: 0.004494382 },
      { format: 'aaadddddddddhdd', frequency: 0.0037453184 },
      { format: 'dddddddddd', frequency: 0.0037453184 },
      { format: 'adasdddddddddsdd', frequency: 0.0037453184 },
      { format: 'aaahdddddddddhdd', frequency: 0.0037453184 },
      { format: 'adadddddddaa', frequency: 0.0037453184 },
      { format: 'dddddddd', frequency: 0.0037453184 },
      { format: 'aaadddddd', frequency: 0.0029962547 },
      { format: 'aaaaaddddddd', frequency: 0.002247191 },
      { format: 'aaadaaadddddddd', frequency: 0.002247191 },
      { format: 'aaadddddddddsdd', frequency: 0.002247191 },
      { format: 'adadddddddddddd', frequency: 0.002247191 },
      { format: 'aaadddddddddd', frequency: 0.002247191 },
      { format: 'adadddddddddd', frequency: 0.002247191 },
      { format: 'adadddddddddhdd', frequency: 0.0014981274 },
      { format: 'addddddddd', frequency: 0.0014981274 },
      { format: 'aaaddddddddadd', frequency: 0.0014981274 },
      { format: 'adasddddddddd', frequency: 0.0014981274 },
      { format: 'aaasdddddddddsaaaaaasdd', frequency: 0.0014981274 },
      { format: 'adaadddddddd', frequency: 0.0014981274 },
      { format: 'aaadddddddd', frequency: 0.0014981274 },
      { format: 'adadaaadddddddd', frequency: 0.0014981274 },
      { format: 'aaasdddddddddhdd', frequency: 0.0014981274 },
      { format: 'aaasadddddddd', frequency: 0.0014981274 },
      { format: 'dddddddddddd', frequency: 0.0014981274 },
    ],
  },
  {
    front_end_carrier_id: 113,
    prefixes: [
      { prefix: 'W28', frequency: 0.14329761 },
      { prefix: 'W27', frequency: 0.098370284 },
      { prefix: '101', frequency: 0.08177947 },
      { prefix: '9', frequency: 0.06768463 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.1852885 },
      { suffix: '1', frequency: 0.13199237 },
      { suffix: '00', frequency: 0.106445454 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.42240494 },
      { format: 'dddddddddd', frequency: 0.12523858 },
      { format: 'dddddddddddd', frequency: 0.12230216 },
      { format: 'ddddddddd', frequency: 0.0668037 },
      { format: 'addddsddddd', frequency: 0.04463368 },
      { format: 'adddddddd', frequency: 0.035971224 },
      { format: 'ddddddddddd', frequency: 0.032447513 },
      { format: 'aaddddddd', frequency: 0.021435913 },
      { format: 'addddddddddd', frequency: 0.012920276 },
      { format: 'aaadddaddddd', frequency: 0.012920276 },
      { format: 'adddddddddd', frequency: 0.008662458 },
      { format: 'adddddddddhdd', frequency: 0.008515636 },
      { format: 'aaaddddddddd', frequency: 0.006460138 },
      { format: 'aaaddddddd', frequency: 0.005138746 },
      { format: 'aaddddddddd', frequency: 0.004991925 },
      { format: 'dddddddd', frequency: 0.0048451037 },
      { format: 'dddddddddhdd', frequency: 0.003670533 },
      { format: 'ddddddddddddd', frequency: 0.0035237116 },
      { format: 'aaadddddddd', frequency: 0.0033768904 },
      { format: 'adadddaddddd', frequency: 0.0033768904 },
      { format: 'dddddddda', frequency: 0.0029364263 },
      { format: 'addddddd', frequency: 0.0022023197 },
      { format: 'ddddddddaaaa', frequency: 0.0019086772 },
      { format: 'addddsdddddhdd', frequency: 0.0019086772 },
      { format: 'adddddd', frequency: 0.0016150345 },
      { format: 'ddddsdddddhdd', frequency: 0.0013213919 },
      { format: 'dddddaddddd', frequency: 0.0013213919 },
      { format: 'ddddddddddsdd', frequency: 0.0013213919 },
      { format: 'addddddddsdd', frequency: 0.0011745705 },
      { format: 'addddddddhdd', frequency: 0.0011745705 },
      { format: 'aaaaaddddddd', frequency: 0.0011745705 },
      { format: 'ddddddd', frequency: 0.0011745705 },
      { format: 'aadddddddd', frequency: 0.0010277493 },
    ],
  },
  {
    front_end_carrier_id: 114,
    prefixes: [
      { prefix: 'XEA91', frequency: 0.17059529 },
      { prefix: 'XEH91', frequency: 0.10214409 },
      { prefix: 'XEA912', frequency: 0.07297909 },
      { prefix: 'XED91', frequency: 0.071913704 },
      { prefix: 'T2G943', frequency: 0.07124784 },
      { prefix: 'ZGN9', frequency: 0.056199227 },
      { prefix: 'XEA911', frequency: 0.05140498 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.8134239 },
      { format: 'adaddddddddd', frequency: 0.102543615 },
      { format: 'aaadddddddda', frequency: 0.024637101 },
      { format: 'aaaadddddddd', frequency: 0.01584765 },
      { format: 'ddddddddd', frequency: 0.012784658 },
      { format: 'aaaddddddddddd', frequency: 0.006658676 },
      { format: 'ddddddddddd', frequency: 0.003329338 },
      { format: 'adddddddd', frequency: 0.002796644 },
      { format: 'aaasadddddddd', frequency: 0.0021307764 },
      { format: 'dddddddddaa', frequency: 0.0021307764 },
      { format: 'aaadddddddddd', frequency: 0.0014649088 },
      { format: 'aaaddddadddd', frequency: 0.0011985617 },
    ],
  },
  {
    front_end_carrier_id: 116,
    prefixes: [
      { prefix: '00', frequency: 0.5238821 },
      { prefix: 'A0', frequency: 0.1453252 },
      { prefix: '0070', frequency: 0.094512194 },
      { prefix: 'W2', frequency: 0.076219514 },
      { prefix: '009', frequency: 0.07113821 },
      { prefix: 'A007', frequency: 0.07012195 },
      { prefix: '008', frequency: 0.0574187 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.31859756 },
      { suffix: '-01', frequency: 0.19461381 },
      { suffix: '3', frequency: 0.12195122 },
      { suffix: '02', frequency: 0.10670732 },
    ],
    formats: [
      { format: 'dddddddddhdd', frequency: 0.22459349 },
      { format: 'ddddddddd', frequency: 0.22052845 },
      { format: 'ddddddddddd', frequency: 0.11839431 },
      { format: 'addddddddd', frequency: 0.08079268 },
      { format: 'addddddddhdd', frequency: 0.07977642 },
      { format: 'adddddddd', frequency: 0.051321138 },
      { format: 'dddddddddddd', frequency: 0.046747968 },
      { format: 'adddddddddd', frequency: 0.039634146 },
      { format: 'dddddddddhddd', frequency: 0.037601627 },
      { format: 'ddddddddddhdd', frequency: 0.018292682 },
      { format: 'dddddddddd', frequency: 0.017276423 },
      { format: 'addddddddddhdd', frequency: 0.0076219514 },
      { format: 'aaddddddd', frequency: 0.0071138213 },
      { format: 'addddsddddd', frequency: 0.0060975607 },
      { format: 'addddddddddd', frequency: 0.0035569107 },
      { format: 'aaddddddddd', frequency: 0.0030487804 },
      { format: 'aaadddaddddd', frequency: 0.0025406503 },
      { format: 'adddddddddddd', frequency: 0.0025406503 },
      { format: 'ddddsdddddhdd', frequency: 0.0025406503 },
      { format: 'dddddddddsdd', frequency: 0.0020325202 },
      { format: 'ddddddd', frequency: 0.0015243902 },
      { format: 'aaaddddddd', frequency: 0.0015243902 },
      { format: 'dddddd', frequency: 0.0015243902 },
      { format: 'aaaaaddddddd', frequency: 0.0010162601 },
      { format: 'dddddddd', frequency: 0.0010162601 },
      { format: 'ddddd', frequency: 0.0010162601 },
      { format: 'ddddddddddddd', frequency: 0.0010162601 },
    ],
  },
  {
    front_end_carrier_id: 117,
    prefixes: [
      { prefix: 'H', frequency: 0.5602901 },
      { prefix: 'HH', frequency: 0.28286493 },
      { prefix: 'HP', frequency: 0.26563916 },
      { prefix: '9', frequency: 0.16772439 },
      { prefix: 'HP6', frequency: 0.12420671 },
      { prefix: 'HHB', frequency: 0.12420671 },
      { prefix: 'HP5', frequency: 0.06980961 },
      { prefix: '98', frequency: 0.052583862 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.20942883 },
      { suffix: '00', frequency: 0.1586582 },
      { suffix: '1', frequency: 0.15684497 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.21214868 },
      { format: 'aaaaddddd', frequency: 0.17679057 },
      { format: 'aadddddddhdd', frequency: 0.10426111 },
      { format: 'aaadddddd', frequency: 0.08068903 },
      { format: 'aaddddddddd', frequency: 0.07162285 },
      { format: 'ddddddddddd', frequency: 0.05530372 },
      { format: 'aaddddddd', frequency: 0.04986401 },
      { format: 'dddddddd', frequency: 0.026291931 },
      { format: 'aaaddaddd', frequency: 0.026291931 },
      { format: 'aaaddddddhdd', frequency: 0.022665458 },
      { format: 'ddddadddddd', frequency: 0.019038985 },
      { format: 'adddddddddd', frequency: 0.014505893 },
      { format: 'aaaddddddddd', frequency: 0.012692656 },
      { format: 'aaadddddddd', frequency: 0.012692656 },
      { format: 'dddddddddddd', frequency: 0.009972801 },
      { format: 'aaaddddddd', frequency: 0.009066183 },
      { format: 'dddddddddhdd', frequency: 0.0072529465 },
      { format: 'aaaaadddd', frequency: 0.006346328 },
      { format: 'addddddddd', frequency: 0.00543971 },
      { format: 'adddddddd', frequency: 0.00543971 },
      { format: 'dddddddhdd', frequency: 0.00543971 },
      { format: 'aaaadddddhdd', frequency: 0.00543971 },
      { format: 'dddddddddd', frequency: 0.0045330916 },
      { format: 'aaaaaaddd', frequency: 0.0036264732 },
      { format: 'aaaadaddd', frequency: 0.0036264732 },
      { format: 'aaaaaddddd', frequency: 0.002719855 },
      { format: 'dddaddddd', frequency: 0.002719855 },
      { format: 'aaddddddhdd', frequency: 0.002719855 },
      { format: 'aaaadddd', frequency: 0.002719855 },
      { format: 'ddddddd', frequency: 0.002719855 },
      { format: 'ddddddddaaaa', frequency: 0.002719855 },
      { format: 'aaddddddddhdd', frequency: 0.0018132366 },
      { format: 'dddddddddsdd', frequency: 0.0018132366 },
    ],
  },
  {
    front_end_carrier_id: 118,
    prefixes: [
      { prefix: 'P04', frequency: 0.12287334 },
      { prefix: '10', frequency: 0.07183365 },
      { prefix: '00', frequency: 0.06427221 },
      { prefix: '9', frequency: 0.058601134 },
      { prefix: '2', frequency: 0.051039696 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.17202269 }],
    formats: [
      { format: 'addddddddd', frequency: 0.21550095 },
      { format: 'addddddd', frequency: 0.1663516 },
      { format: 'ddddddddd', frequency: 0.12098299 },
      { format: 'dddddddddddd', frequency: 0.08506616 },
      { format: 'adddddddd', frequency: 0.077504724 },
      { format: 'dddddddddd', frequency: 0.062381852 },
      { format: 'ddddddddddd', frequency: 0.060491495 },
      { format: 'aaddddddd', frequency: 0.02268431 },
      { format: 'addddsddddd', frequency: 0.02079395 },
      { format: 'adddddddddd', frequency: 0.02079395 },
      { format: 'ddddsdddddhdd', frequency: 0.011342155 },
      { format: 'dddddddddhdd', frequency: 0.011342155 },
      { format: 'aaadddaddddd', frequency: 0.009451796 },
      { format: 'aadddddd', frequency: 0.007561437 },
      { format: 'ddddddd', frequency: 0.007561437 },
      { format: 'aaddddddddd', frequency: 0.007561437 },
      { format: 'dddddddd', frequency: 0.007561437 },
      { format: 'addddddddddd', frequency: 0.0056710774 },
      { format: 'aaaddddddddd', frequency: 0.0056710774 },
      { format: 'aaaaaddddddd', frequency: 0.0037807184 },
      { format: 'dddddsdddd', frequency: 0.0037807184 },
      { format: 'aaadddddd', frequency: 0.0037807184 },
      { format: 'aa', frequency: 0.0037807184 },
      { format: 'ddddddddddddd', frequency: 0.0037807184 },
      { format: 'dddddd', frequency: 0.0037807184 },
      { format: 'aaaddddddd', frequency: 0.0037807184 },
      { format: 'dddddaddddd', frequency: 0.0037807184 },
      { format: 'ddddd', frequency: 0.0037807184 },
      { format: 'adddddd', frequency: 0.0018903592 },
      { format: 'ddhdddddddd', frequency: 0.0018903592 },
      { format: 'addddsdddddhdd', frequency: 0.0018903592 },
      { format: 'ddddddaaa', frequency: 0.0018903592 },
      { format: 'aaadddddddda', frequency: 0.0018903592 },
      { format: 'adddsddddd', frequency: 0.0018903592 },
      { format: 'aaadaaadddddddd', frequency: 0.0018903592 },
      { format: 'ddddddddddddddd', frequency: 0.0018903592 },
      { format: 'dddaddddd', frequency: 0.0018903592 },
      { format: 'ddddddddddsdd', frequency: 0.0018903592 },
      { format: 'adddddddddhdd', frequency: 0.0018903592 },
      { format: 'addddhdddd', frequency: 0.0018903592 },
      { format: 'addddddddddhdd', frequency: 0.0018903592 },
      { format: 'adddd', frequency: 0.0018903592 },
      { format: 'ddd', frequency: 0.0018903592 },
      { format: 'aaasdddddddddsdd', frequency: 0.0018903592 },
      { format: 'hdddddd', frequency: 0.0018903592 },
      { format: 'aaaaddddddddd', frequency: 0.0018903592 },
      { format: 'dddadaadadda', frequency: 0.0018903592 },
      { format: 'aasdddddddddddddd', frequency: 0.0018903592 },
      { format: 'aasddddddd', frequency: 0.0018903592 },
    ],
  },
  {
    front_end_carrier_id: 119,
    prefixes: [
      { prefix: '10', frequency: 0.16668375 },
      { prefix: '00', frequency: 0.08517037 },
      { prefix: '9', frequency: 0.076352574 },
      { prefix: '7', frequency: 0.05485492 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.2643973 },
      { suffix: '01', frequency: 0.2140538 },
      { suffix: '2', frequency: 0.14901398 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.26508084 },
      { format: 'adddddddddd', frequency: 0.20236509 },
      { format: 'adddddddd', frequency: 0.105711065 },
      { format: 'dddddddddddd', frequency: 0.085307084 },
      { format: 'ddddddddddd', frequency: 0.07638675 },
      { format: 'addddddddsdd', frequency: 0.058409378 },
      { format: 'dddddddd', frequency: 0.02700024 },
      { format: 'aaddddddd', frequency: 0.023514133 },
      { format: 'dddddddddd', frequency: 0.019515363 },
      { format: 'dddddddddsdd', frequency: 0.011927954 },
      { format: 'aaaddddddddhdd', frequency: 0.010082368 },
      { format: 'dddddaddddd', frequency: 0.00868109 },
      { format: 'dddddddddhdd', frequency: 0.008236782 },
      { format: 'aaadddddd', frequency: 0.008168427 },
      { format: 'ddddddddda', frequency: 0.006903859 },
      { format: 'ddddddd', frequency: 0.0068355035 },
      { format: 'aaadddddddd', frequency: 0.0068013263 },
      { format: 'aaaaddddd', frequency: 0.005673468 },
      { format: 'aaaddddddd', frequency: 0.0050924504 },
      { format: 'aaaddddddddd', frequency: 0.0046481425 },
      { format: 'aaadddddddddd', frequency: 0.00427219 },
      { format: 'addddddddhdd', frequency: 0.0039304146 },
      { format: 'dddddd', frequency: 0.0026658464 },
      { format: 'addddddddd', frequency: 0.0024607813 },
      { format: 'addddddddaad', frequency: 0.0021531836 },
      { format: 'ddddddddddddd', frequency: 0.0021190061 },
      { format: 'ddaddddddd', frequency: 0.001913941 },
      { format: 'aaaaadddddd', frequency: 0.001845586 },
      { format: 'addddddd', frequency: 0.0016746984 },
      { format: 'aadddddddddd', frequency: 0.0015379883 },
      { format: 'aaaddddddddsdd', frequency: 0.0014354557 },
      { format: 'dddddddddddddd', frequency: 0.0013329232 },
      { format: 'addddddddddd', frequency: 0.0012645682 },
      { format: 'aaddddddddd', frequency: 0.0010936806 },
      { format: 'daddddddddd', frequency: 0.0010253255 },
    ],
  },
  {
    front_end_carrier_id: 120,
    prefixes: [
      { prefix: 'W2', frequency: 0.25181818 },
      { prefix: '10', frequency: 0.086363636 },
      { prefix: '00', frequency: 0.07727273 },
      { prefix: 'W28', frequency: 0.07545455 },
      { prefix: '9', frequency: 0.06636363 },
      { prefix: '2', frequency: 0.05818182 },
      { prefix: 'W27', frequency: 0.056363635 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16636364 },
      { suffix: '1', frequency: 0.122727275 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.22727273 },
      { format: 'ddddddddd', frequency: 0.13181818 },
      { format: 'dddddddddd', frequency: 0.09 },
      { format: 'dddddddddddd', frequency: 0.088181816 },
      { format: 'adddddddd', frequency: 0.07727273 },
      { format: 'ddddddddddd', frequency: 0.060909092 },
      { format: 'aaddddddd', frequency: 0.044545453 },
      { format: 'addddsddddd', frequency: 0.030909091 },
      { format: 'adddddddddd', frequency: 0.021818181 },
      { format: 'dddddddd', frequency: 0.015454546 },
      { format: 'addddddddddd', frequency: 0.012727273 },
      { format: 'dddddddddhdd', frequency: 0.012727273 },
      { format: 'aaaddddddd', frequency: 0.012727273 },
      { format: 'aaadddaddddd', frequency: 0.011818182 },
      { format: 'ddddddd', frequency: 0.010909091 },
      { format: 'aaddddddddd', frequency: 0.010909091 },
      { format: 'aaaddddddddd', frequency: 0.0072727273 },
      { format: 'ddddsdddddhdd', frequency: 0.0063636363 },
      { format: 'adddddd', frequency: 0.0054545454 },
      { format: 'ddddddddddddd', frequency: 0.0054545454 },
      { format: 'dddddd', frequency: 0.0054545454 },
      { format: 'ddddd', frequency: 0.0045454544 },
      { format: 'dddddddddddddd', frequency: 0.0036363637 },
      { format: 'addddddd', frequency: 0.0036363637 },
      { format: 'adddddddddhdd', frequency: 0.0036363637 },
      { format: 'addddddddhdd', frequency: 0.0036363637 },
      { format: 'adddd', frequency: 0.0036363637 },
      { format: 'dddddddda', frequency: 0.0027272727 },
      { format: 'dddddaddddd', frequency: 0.0027272727 },
      { format: 'ddddddddddddddd', frequency: 0.0027272727 },
      { format: 'dddddsdddd', frequency: 0.0027272727 },
      { format: 'aa', frequency: 0.0027272727 },
      { format: 'aaaaaaaa', frequency: 0.0027272727 },
      { format: 'aaadddddd', frequency: 0.0027272727 },
      { format: 'adadddaddddd', frequency: 0.0027272727 },
      { format: 'aaadddddddd', frequency: 0.0027272727 },
      { format: 'aaddddda', frequency: 0.0018181818 },
      { format: 'dddd', frequency: 0.0018181818 },
      { format: 'addddsdddddhdd', frequency: 0.0018181818 },
      { format: 'ddddhdddd', frequency: 0.0018181818 },
      { format: 'aaaaaddddddd', frequency: 0.0018181818 },
      { format: 'aaaaddddddd', frequency: 0.0018181818 },
      { format: 'adaddddddddd', frequency: 0.0018181818 },
      { format: 'dddhddhdddd', frequency: 0.0018181818 },
      { format: 'aaadddddddaa', frequency: 0.0018181818 },
    ],
  },
  {
    front_end_carrier_id: 121,
    prefixes: [
      { prefix: 'N10', frequency: 0.37020317 },
      { prefix: 'N101', frequency: 0.18510158 },
      { prefix: 'E1', frequency: 0.1738149 },
      { prefix: 'W2', frequency: 0.12866817 },
      { prefix: 'N107', frequency: 0.11060948 },
      { prefix: 'N100', frequency: 0.0744921 },
      { prefix: '1', frequency: 0.058690745 },
      { prefix: 'N1076', frequency: 0.05643341 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.1489842 },
      { suffix: '1', frequency: 0.13995485 },
    ],
    formats: [
      { format: 'adddddddd', frequency: 0.60045147 },
      { format: 'addddddddd', frequency: 0.11512415 },
      { format: 'ddddddddd', frequency: 0.047404062 },
      { format: 'dddddddddddd', frequency: 0.04288939 },
      { format: 'addddddddhdd', frequency: 0.03160271 },
      { format: 'aaadddddddda', frequency: 0.013544018 },
      { format: 'addddsddddd', frequency: 0.013544018 },
      { format: 'ddddddddddd', frequency: 0.013544018 },
      { format: 'dddddddddd', frequency: 0.013544018 },
      { format: 'aaddddddd', frequency: 0.0112866815 },
      { format: 'ddd', frequency: 0.009029346 },
      { format: 'dddddddda', frequency: 0.009029346 },
      { format: 'dddddddd', frequency: 0.006772009 },
      { format: 'aaddddddddd', frequency: 0.006772009 },
      { format: 'adddddddddd', frequency: 0.006772009 },
      { format: 'ddddsdddddhdd', frequency: 0.006772009 },
      { format: 'dddddddddhdd', frequency: 0.004514673 },
      { format: 'dddddd', frequency: 0.004514673 },
      { format: 'ddddd', frequency: 0.004514673 },
      { format: 'aaaddddddddd', frequency: 0.004514673 },
      { format: 'ddddddd', frequency: 0.0022573364 },
      { format: 'ddddddddddddddd', frequency: 0.0022573364 },
      { format: 'dd', frequency: 0.0022573364 },
      { format: 'dddd', frequency: 0.0022573364 },
      { format: 'addddddddddd', frequency: 0.0022573364 },
      { format: 'adadddddddda', frequency: 0.0022573364 },
      { format: 'aaadddddd', frequency: 0.0022573364 },
      { format: 'addddsdddddhdd', frequency: 0.0022573364 },
      { format: 'adddd', frequency: 0.0022573364 },
      { format: 'aaaaaddddddd', frequency: 0.0022573364 },
      { format: 'aaadddaddddd', frequency: 0.0022573364 },
      { format: 'addddddddddhdd', frequency: 0.0022573364 },
      { format: 'aaaddddddd', frequency: 0.0022573364 },
      { format: 'aa', frequency: 0.0022573364 },
      { format: 'ahdddddddd', frequency: 0.0022573364 },
    ],
  },
  {
    front_end_carrier_id: 122,
    prefixes: [
      { prefix: 'W28', frequency: 0.090625 },
      { prefix: '101', frequency: 0.084375 },
      { prefix: '0', frequency: 0.084375 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.184375 }],
    formats: [
      { format: 'addddddddd', frequency: 0.259375 },
      { format: 'dddddddddddd', frequency: 0.096875 },
      { format: 'ddddddddd', frequency: 0.084375 },
      { format: 'dddddddddd', frequency: 0.059375 },
      { format: 'aaadddddddaa', frequency: 0.053125 },
      { format: 'adddddddd', frequency: 0.05 },
      { format: 'ddddddaaa', frequency: 0.040625 },
      { format: 'addddsddddd', frequency: 0.0375 },
      { format: 'ddddddddddd', frequency: 0.0375 },
      { format: 'aaaddddddddd', frequency: 0.03125 },
      { format: 'adadddddddaa', frequency: 0.028125 },
      { format: 'aaddddddd', frequency: 0.01875 },
      { format: 'adddddddddd', frequency: 0.015625 },
      { format: 'addddddddddd', frequency: 0.0125 },
      { format: 'ddddddd', frequency: 0.009375 },
      { format: 'aaadddaddddd', frequency: 0.009375 },
      { format: 'ddddddaaaa', frequency: 0.009375 },
      { format: 'aaddddddddd', frequency: 0.009375 },
      { format: 'adaddddddddd', frequency: 0.009375 },
      { format: 'dddddddddhdd', frequency: 0.009375 },
      { format: 'ddddsdddddhdd', frequency: 0.009375 },
      { format: 'ddddddddddddddd', frequency: 0.00625 },
      { format: 'ddddddddaaaa', frequency: 0.00625 },
      { format: 'dddddddddaaa', frequency: 0.00625 },
      { format: 'addddddddddhdd', frequency: 0.00625 },
      { format: 'ddddaddddd', frequency: 0.00625 },
      { format: 'dddddddd', frequency: 0.00625 },
      { format: 'adadddd', frequency: 0.003125 },
      { format: 'dddaddddd', frequency: 0.003125 },
      { format: 'addddhddddd', frequency: 0.003125 },
      { format: 'd', frequency: 0.003125 },
      { format: 'adddddddddhdd', frequency: 0.003125 },
      { format: 'aaaaaaa', frequency: 0.003125 },
      { format: 'aaadddddd', frequency: 0.003125 },
      { format: 'dddddddddddddd', frequency: 0.003125 },
      { format: 'addddddddaa', frequency: 0.003125 },
      { format: 'dddddd', frequency: 0.003125 },
      { format: 'addddsdddddhdd', frequency: 0.003125 },
      { format: 'dddddddhdddhddddd', frequency: 0.003125 },
      { format: 'a', frequency: 0.003125 },
      { format: 'ddd', frequency: 0.003125 },
      { format: 'adddd', frequency: 0.003125 },
      { format: 'add', frequency: 0.003125 },
      { format: 'aaadddddddsaa', frequency: 0.003125 },
      { format: 'dddddaaaa', frequency: 0.003125 },
      { format: 'dddddaddddd', frequency: 0.003125 },
      { format: 'adadddaddddd', frequency: 0.003125 },
      { format: 'aaaaaddddddd', frequency: 0.003125 },
      { format: 'aaaddddddd', frequency: 0.003125 },
      { format: 'asdddddddddd', frequency: 0.003125 },
    ],
  },
  {
    front_end_carrier_id: 123,
    prefixes: [
      { prefix: '0', frequency: 0.4515571 },
      { prefix: '01', frequency: 0.3529412 },
      { prefix: '017', frequency: 0.19204152 },
      { prefix: '1', frequency: 0.14359862 },
      { prefix: 'U', frequency: 0.121107265 },
      { prefix: '00', frequency: 0.08650519 },
      { prefix: '10', frequency: 0.08650519 },
      { prefix: '016', frequency: 0.053633217 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.3217993 },
      { suffix: '02', frequency: 0.105536334 },
    ],
    formats: [
      { format: 'ddddddddddd', frequency: 0.43079585 },
      { format: 'ddddddddd', frequency: 0.15916955 },
      { format: 'adddddddddd', frequency: 0.08477509 },
      { format: 'adddddddd', frequency: 0.08131488 },
      { format: 'dddddddddd', frequency: 0.07266436 },
      { format: 'dddddddd', frequency: 0.025951557 },
      { format: 'addddddddsdd', frequency: 0.020761246 },
      { format: 'aadddddddddd', frequency: 0.019031141 },
      { format: 'ddddddd', frequency: 0.012110727 },
      { format: 'aaadddddd', frequency: 0.010380623 },
      { format: 'dddddddddddd', frequency: 0.008650519 },
      { format: 'ddddddddddddd', frequency: 0.0069204154 },
      { format: 'addddddddd', frequency: 0.0051903115 },
      { format: 'addddddddhdd', frequency: 0.0051903115 },
      { format: 'dddddddddhdd', frequency: 0.0051903115 },
      { format: 'aaaddddddd', frequency: 0.0034602077 },
      { format: 'ddaddddddd', frequency: 0.0034602077 },
      { format: 'aaddddddd', frequency: 0.0034602077 },
      { format: 'aaaddddddddd', frequency: 0.0034602077 },
      { format: 'addddddddddd', frequency: 0.0034602077 },
      { format: 'd', frequency: 0.0017301039 },
      { format: 'addaaadddddd', frequency: 0.0017301039 },
      { format: 'aaadddaddddd', frequency: 0.0017301039 },
      { format: 'aaaddddddsdd', frequency: 0.0017301039 },
      { format: 'ddddd', frequency: 0.0017301039 },
      { format: 'dddddddddsdd', frequency: 0.0017301039 },
      { format: 'aaadddddaddddd', frequency: 0.0017301039 },
      { format: 'ddddddddsdd', frequency: 0.0017301039 },
      { format: 'ddddddddddddsxax', frequency: 0.0017301039 },
      { format: 'aaxsadddddddddd', frequency: 0.0017301039 },
      { format: 'addddddddaaa', frequency: 0.0017301039 },
      { format: 'aaadddddddaa', frequency: 0.0017301039 },
      { format: 'aaadddddddddddd', frequency: 0.0017301039 },
      { format: 'dddhdddhdddd', frequency: 0.0017301039 },
      { format: 'ddd', frequency: 0.0017301039 },
      { format: 'aaadddddddhdd', frequency: 0.0017301039 },
      { format: 'ddddddddda', frequency: 0.0017301039 },
      { format: 'ddddddddhdd', frequency: 0.0017301039 },
      { format: 'aaaddddddddsdd', frequency: 0.0017301039 },
      { format: 'dddddddddddx', frequency: 0.0017301039 },
    ],
  },
  {
    front_end_carrier_id: 124,
    prefixes: [
      { prefix: '9', frequency: 0.06303237 },
      { prefix: 'I3300', frequency: 0.05451448 },
      { prefix: 'E', frequency: 0.052810904 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16865417 },
      { suffix: '2', frequency: 0.12265758 },
    ],
    formats: [
      { format: 'adddddddd', frequency: 0.29471892 },
      { format: 'addddddddd', frequency: 0.18398637 },
      { format: 'ddddddddd', frequency: 0.11413969 },
      { format: 'dddddddddddd', frequency: 0.07495741 },
      { format: 'dddddddddd', frequency: 0.066439524 },
      { format: 'ddddddddddd', frequency: 0.057921637 },
      { format: 'aaddddddd', frequency: 0.023850085 },
      { format: 'addddsddddd', frequency: 0.017035775 },
      { format: 'adddddddddd', frequency: 0.017035775 },
      { format: 'dddddddddhdd', frequency: 0.011925043 },
      { format: 'ddddsdddddhdd', frequency: 0.0102214655 },
      { format: 'aaadddaddddd', frequency: 0.0102214655 },
      { format: 'dddddddd', frequency: 0.0102214655 },
      { format: 'ddddddd', frequency: 0.008517887 },
      { format: 'addddddddhdd', frequency: 0.008517887 },
      { format: 'addddddddddd', frequency: 0.0051107327 },
      { format: 'aaaddddddddd', frequency: 0.0051107327 },
      { format: 'aaddddddddd', frequency: 0.0051107327 },
      { format: 'aaaddddddd', frequency: 0.0051107327 },
      { format: 'dddddsdddd', frequency: 0.003407155 },
      { format: 'aaadddddd', frequency: 0.003407155 },
      { format: 'addddsdddddhdd', frequency: 0.003407155 },
      { format: 'ddddddddddddd', frequency: 0.003407155 },
      { format: 'dddddaddddd', frequency: 0.003407155 },
      { format: 'dddddd', frequency: 0.003407155 },
      { format: 'addddddd', frequency: 0.003407155 },
      { format: 'ddddd', frequency: 0.003407155 },
      { format: 'adddddd', frequency: 0.0017035775 },
      { format: 'aaaaaddddddd', frequency: 0.0017035775 },
      { format: 'ddhdddddddd', frequency: 0.0017035775 },
      { format: 'addddsdddd', frequency: 0.0017035775 },
      { format: 'a', frequency: 0.0017035775 },
      { format: 'ddddddaaa', frequency: 0.0017035775 },
      { format: 'aa', frequency: 0.0017035775 },
      { format: 'aaadaaadddddddd', frequency: 0.0017035775 },
      { format: 'ddddsddddd', frequency: 0.0017035775 },
      { format: 'ddddddddddddddd', frequency: 0.0017035775 },
      { format: 'dddaddddd', frequency: 0.0017035775 },
      { format: 'ddddddddddsdd', frequency: 0.0017035775 },
      { format: 'd', frequency: 0.0017035775 },
      { format: 'dddd', frequency: 0.0017035775 },
      { format: 'adddddddddhdd', frequency: 0.0017035775 },
      { format: 'addddhdddd', frequency: 0.0017035775 },
      { format: 'dddddsddd', frequency: 0.0017035775 },
      { format: 'addddddddddhdd', frequency: 0.0017035775 },
      { format: 'adddd', frequency: 0.0017035775 },
      { format: 'ddd', frequency: 0.0017035775 },
      { format: 'aaasdddddddddsdd', frequency: 0.0017035775 },
      { format: 'adadadddd', frequency: 0.0017035775 },
      { format: 'aaaaddddddddd', frequency: 0.0017035775 },
      { format: 'dddadaadadda', frequency: 0.0017035775 },
      { format: 'aasddddddd', frequency: 0.0017035775 },
    ],
  },
  {
    front_end_carrier_id: 126,
    prefixes: [{ prefix: '9191910', frequency: 0.19106926 }],
    suffixes: [{ suffix: '322', frequency: 0.68529767 }],
    formats: [
      { format: 'dddddddddddd', frequency: 0.92132443 },
      { format: 'ddddddddd', frequency: 0.01944107 },
      { format: 'adddddddddd', frequency: 0.011846902 },
      { format: 'dddddddddddddd', frequency: 0.010935602 },
      { format: 'adddddddd', frequency: 0.008505468 },
      { format: 'ddddddddddd', frequency: 0.0048602675 },
      { format: 'addddddddsdd', frequency: 0.0033414338 },
      { format: 'dddddddddd', frequency: 0.0030376671 },
      { format: 'aadddddddddd', frequency: 0.0027339004 },
      { format: 'dddddddd', frequency: 0.002126367 },
      { format: 'ddddddd', frequency: 0.002126367 },
      { format: 'ddddddddddddsdd', frequency: 0.0015188336 },
      { format: 'ddddddddddddhdd', frequency: 0.0015188336 },
    ],
  },
  {
    front_end_carrier_id: 127,
    prefixes: [
      { prefix: '10', frequency: 0.06866417 },
      { prefix: 'W27', frequency: 0.06616729 },
      { prefix: 'W28', frequency: 0.06491885 },
      { prefix: '00', frequency: 0.058676653 },
      { prefix: '982', frequency: 0.056179777 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.18102372 }],
    formats: [
      { format: 'ddddddddd', frequency: 0.24219726 },
      { format: 'addddddddd', frequency: 0.21348314 },
      { format: 'dddddddddddd', frequency: 0.08239701 },
      { format: 'adddddddd', frequency: 0.07990012 },
      { format: 'dddddddddd', frequency: 0.06866417 },
      { format: 'ddddddddddd', frequency: 0.06616729 },
      { format: 'aaddddddd', frequency: 0.027465668 },
      { format: 'addddsddddd', frequency: 0.02247191 },
      { format: 'adddddddddd', frequency: 0.01997503 },
      { format: 'dddhddhdddd', frequency: 0.018726591 },
      { format: 'aaadddaddddd', frequency: 0.012484395 },
      { format: 'dddddddddhdd', frequency: 0.011235955 },
      { format: 'ddddddd', frequency: 0.009987515 },
      { format: 'dddddddd', frequency: 0.009987515 },
      { format: 'aaddddddddd', frequency: 0.009987515 },
      { format: 'addddddddddd', frequency: 0.008739077 },
      { format: 'addddddd', frequency: 0.008739077 },
      { format: 'ddddsdddddhdd', frequency: 0.007490637 },
      { format: 'aaaddddddddd', frequency: 0.0037453184 },
      { format: 'ddddddddddddd', frequency: 0.0037453184 },
      { format: 'addddddddhdd', frequency: 0.0037453184 },
      { format: 'adddd', frequency: 0.0037453184 },
      { format: 'aaaddddddd', frequency: 0.0037453184 },
      { format: 'aaaaaddddddd', frequency: 0.0024968788 },
      { format: 'dddddsdddd', frequency: 0.0024968788 },
      { format: 'addddsdddddhdd', frequency: 0.0024968788 },
      { format: 'aa', frequency: 0.0024968788 },
      { format: 'aaadddddddd', frequency: 0.0024968788 },
      { format: 'dddddd', frequency: 0.0024968788 },
      { format: 'addddddddddhdd', frequency: 0.0024968788 },
      { format: 'ddddddddddddddd', frequency: 0.0024968788 },
      { format: 'adddddddddhdd', frequency: 0.0024968788 },
      { format: 'adddddd', frequency: 0.0012484394 },
      { format: 'aaaddddaaaa', frequency: 0.0012484394 },
      { format: 'adddsddddd', frequency: 0.0012484394 },
      { format: 'ddhdddddddd', frequency: 0.0012484394 },
      { format: 'dddddhddhddd', frequency: 0.0012484394 },
      { format: 'dddddddddddddd', frequency: 0.0012484394 },
      { format: 'ddaaddddddd', frequency: 0.0012484394 },
      { format: 'aaadddddd', frequency: 0.0012484394 },
      { format: 'dddddaaddddddd', frequency: 0.0012484394 },
      { format: 'a', frequency: 0.0012484394 },
      { format: 'ddddddaaa', frequency: 0.0012484394 },
      { format: 'adadddaddddd', frequency: 0.0012484394 },
      { format: 'aaadaaadddddddd', frequency: 0.0012484394 },
      { format: 'addddsdddd', frequency: 0.0012484394 },
      { format: 'dddaddddd', frequency: 0.0012484394 },
      { format: 'dddddddda', frequency: 0.0012484394 },
      { format: 'ddddddddddsdd', frequency: 0.0012484394 },
      { format: 'd', frequency: 0.0012484394 },
      { format: 'addddhdddd', frequency: 0.0012484394 },
      { format: 'dddddaddddd', frequency: 0.0012484394 },
      { format: 'aaadddddddddd', frequency: 0.0012484394 },
      { format: 'dddadaaaadad', frequency: 0.0012484394 },
      { format: 'ddd', frequency: 0.0012484394 },
      { format: 'aaasdddddddddsdd', frequency: 0.0012484394 },
      { format: 'ddddd', frequency: 0.0012484394 },
      { format: 'aaaaddddddddd', frequency: 0.0012484394 },
      { format: 'dddadaadadda', frequency: 0.0012484394 },
      { format: 'ddddsddddd', frequency: 0.0012484394 },
      { format: 'aaaaaaaasaddsaaasaaa', frequency: 0.0012484394 },
      { format: 'ddddhddddddhdd', frequency: 0.0012484394 },
      { format: 'aasddddddd', frequency: 0.0012484394 },
    ],
  },
  {
    front_end_carrier_id: 128,
    prefixes: [
      { prefix: '99', frequency: 0.84189725 },
      { prefix: '990', frequency: 0.7878788 },
      { prefix: '9906', frequency: 0.2911726 },
      { prefix: '9905', frequency: 0.25823453 },
      { prefix: '9902', frequency: 0.08959157 },
      { prefix: '9904', frequency: 0.07773386 },
      { prefix: 'U', frequency: 0.07114624 },
      { prefix: '99061', frequency: 0.06982872 },
      { prefix: '99060', frequency: 0.060606062 },
      { prefix: '9900', frequency: 0.060606062 },
      { prefix: '99054', frequency: 0.0513834 },
      { prefix: '999', frequency: 0.0513834 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.13833992 }],
    formats: [
      { format: 'ddddddddd', frequency: 0.85902506 },
      { format: 'adddddddddd', frequency: 0.040843215 },
      { format: 'adddddddd', frequency: 0.026350461 },
      { format: 'addddddddsdd', frequency: 0.017127799 },
      { format: 'dddddddd', frequency: 0.009222661 },
      { format: 'dddddddddd', frequency: 0.009222661 },
      { format: 'ddddddddddd', frequency: 0.007905139 },
      { format: 'dddddddddhad', frequency: 0.0039525693 },
      { format: 'addddddddhdd', frequency: 0.0026350461 },
      { format: 'aaaddddddd', frequency: 0.0013175231 },
      { format: 'd', frequency: 0.0013175231 },
      { format: 'ddddddddddddd', frequency: 0.0013175231 },
      { format: 'addddddd', frequency: 0.0013175231 },
      { format: 'ddddd', frequency: 0.0013175231 },
      { format: 'aaddddddd', frequency: 0.0013175231 },
      { format: 'ddddddd', frequency: 0.0013175231 },
      { format: 'aaadddddaddddd', frequency: 0.0013175231 },
      { format: 'aaaadddd', frequency: 0.0013175231 },
      { format: 'adddddddddsdd', frequency: 0.0013175231 },
      { format: 'dddadd', frequency: 0.0013175231 },
      { format: 'dddddddddhdd', frequency: 0.0013175231 },
      { format: 'dddddddddddd', frequency: 0.0013175231 },
      { format: 'addddddddd', frequency: 0.0013175231 },
      { format: 'aaadddddddhdd', frequency: 0.0013175231 },
      { format: 'ddddddddda', frequency: 0.0013175231 },
      { format: 'aadddddddddd', frequency: 0.0013175231 },
      { format: 'ddaaaaaaa', frequency: 0.0013175231 },
    ],
  },
  {
    front_end_carrier_id: 129,
    prefixes: [
      { prefix: '9', frequency: 0.09689214 },
      { prefix: 'W28', frequency: 0.073126145 },
      { prefix: 'W27', frequency: 0.055758685 },
      { prefix: '2', frequency: 0.055758685 },
      { prefix: '000', frequency: 0.0511883 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16087751 },
      { suffix: '1', frequency: 0.12705667 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.21297988 },
      { format: 'ddddddddd', frequency: 0.16544789 },
      { format: 'dddddddddd', frequency: 0.08500914 },
      { format: 'dddddddddddd', frequency: 0.08500914 },
      { format: 'adddddddd', frequency: 0.073126145 },
      { format: 'ddddddddddd', frequency: 0.061243143 },
      { format: 'aaddddddd', frequency: 0.041133456 },
      { format: 'addddsddddd', frequency: 0.026508227 },
      { format: 'adddddddddd', frequency: 0.021023765 },
      { format: 'dddddddd', frequency: 0.018281536 },
      { format: 'addddddddddd', frequency: 0.012797075 },
      { format: 'aaddddddddd', frequency: 0.011882998 },
      { format: 'aaaddddddd', frequency: 0.011882998 },
      { format: 'ddddddd', frequency: 0.010968922 },
      { format: 'dddddddddhdd', frequency: 0.010968922 },
      { format: 'aaadddaddddd', frequency: 0.010054844 },
      { format: 'aaaddddddddd', frequency: 0.009140768 },
      { format: 'ddddsdddddhdd', frequency: 0.0063985377 },
      { format: 'adddddd', frequency: 0.004570384 },
      { format: 'dddddd', frequency: 0.004570384 },
      { format: 'ddddd', frequency: 0.0036563072 },
      { format: 'dddddddddddddd', frequency: 0.0036563072 },
      { format: 'ddddddddddddd', frequency: 0.0036563072 },
      { format: 'adddddddddhdd', frequency: 0.0036563072 },
      { format: 'addddddddhdd', frequency: 0.0036563072 },
      { format: 'adddd', frequency: 0.0036563072 },
      { format: 'dddddddda', frequency: 0.0027422304 },
      { format: 'addddddd', frequency: 0.0027422304 },
      { format: 'dddddaddddd', frequency: 0.0027422304 },
      { format: 'ddddddddddddddd', frequency: 0.0027422304 },
      { format: 'dddddsdddd', frequency: 0.0027422304 },
      { format: 'aa', frequency: 0.0027422304 },
      { format: 'aaaaaaaa', frequency: 0.0027422304 },
      { format: 'aaadddddd', frequency: 0.0027422304 },
      { format: 'adadddaddddd', frequency: 0.0027422304 },
      { format: 'aaadddddddd', frequency: 0.0027422304 },
      { format: 'aaddddda', frequency: 0.0018281536 },
      { format: 'dddd', frequency: 0.0018281536 },
      { format: 'ddddhdddd', frequency: 0.0018281536 },
      { format: 'addddddddddhdd', frequency: 0.0018281536 },
      { format: 'aaaaddddddd', frequency: 0.0018281536 },
      { format: 'adaddddddddd', frequency: 0.0018281536 },
      { format: 'dddhddhdddd', frequency: 0.0018281536 },
      { format: 'aaaaddddddddd', frequency: 0.0018281536 },
      { format: 'ddddddhddhddd', frequency: 0.0018281536 },
      { format: 'aaadddddddaa', frequency: 0.0018281536 },
    ],
  },
  {
    front_end_carrier_id: 130,
    prefixes: [
      { prefix: 'W28', frequency: 0.081953645 },
      { prefix: '00', frequency: 0.0794702 },
      { prefix: '9', frequency: 0.069536425 },
      { prefix: 'W27', frequency: 0.05794702 },
      { prefix: '2', frequency: 0.053807948 },
      { prefix: '101', frequency: 0.052980132 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.17549668 },
      { suffix: '1', frequency: 0.1307947 },
      { suffix: '00', frequency: 0.10678808 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.22350994 },
      { format: 'ddddddddd', frequency: 0.12831126 },
      { format: 'dddddddddddd', frequency: 0.08857616 },
      { format: 'dddddddddd', frequency: 0.08609272 },
      { format: 'adddddddd', frequency: 0.0794702 },
      { format: 'ddddddddddd', frequency: 0.07864238 },
      { format: 'aaddddddd', frequency: 0.04387417 },
      { format: 'addddsddddd', frequency: 0.028145695 },
      { format: 'adddddddddd', frequency: 0.022350993 },
      { format: 'dddddddd', frequency: 0.014072848 },
      { format: 'addddddddddd', frequency: 0.013245033 },
      { format: 'aaddddddddd', frequency: 0.012417219 },
      { format: 'dddddddddhdd', frequency: 0.011589404 },
      { format: 'aaaddddddd', frequency: 0.01076159 },
      { format: 'aaadddaddddd', frequency: 0.009933775 },
      { format: 'ddddddd', frequency: 0.00910596 },
      { format: 'ddddddddddddd', frequency: 0.007450331 },
      { format: 'aaaddddddddd', frequency: 0.0066225166 },
      { format: 'adddddddddhdd', frequency: 0.005794702 },
      { format: 'ddddsdddddhdd', frequency: 0.0049668876 },
      { format: 'adddddd', frequency: 0.0049668876 },
      { format: 'dddddd', frequency: 0.0049668876 },
      { format: 'ddddd', frequency: 0.0041390727 },
      { format: 'dddddaddddd', frequency: 0.0041390727 },
      { format: 'dddddddda', frequency: 0.0033112583 },
      { format: 'dddddddddddddd', frequency: 0.0033112583 },
      { format: 'aaadddddddd', frequency: 0.0033112583 },
      { format: 'addddddddhdd', frequency: 0.0033112583 },
      { format: 'adadddaddddd', frequency: 0.0033112583 },
      { format: 'adddd', frequency: 0.0033112583 },
      { format: 'addddddd', frequency: 0.0024834438 },
      { format: 'ddddddddddddddd', frequency: 0.0024834438 },
      { format: 'dddddsdddd', frequency: 0.0024834438 },
      { format: 'aa', frequency: 0.0024834438 },
      { format: 'aaaaaaaa', frequency: 0.0024834438 },
      { format: 'aaadddddd', frequency: 0.0024834438 },
      { format: 'aaddddda', frequency: 0.0016556291 },
      { format: 'dddd', frequency: 0.0016556291 },
      { format: 'ddddhdddd', frequency: 0.0016556291 },
      { format: 'aaadddddddda', frequency: 0.0016556291 },
      { format: 'aaaaaddddddd', frequency: 0.0016556291 },
      { format: 'aaaaddddddd', frequency: 0.0016556291 },
      { format: 'adaddddddddd', frequency: 0.0016556291 },
      { format: 'dddhddhdddd', frequency: 0.0016556291 },
      { format: 'aaadddddddaa', frequency: 0.0016556291 },
    ],
  },
  {
    front_end_carrier_id: 131,
    prefixes: [
      { prefix: '0', frequency: 0.1027668 },
      { prefix: 'W28', frequency: 0.06719368 },
      { prefix: '2', frequency: 0.06719368 },
      { prefix: 'LTF0736A', frequency: 0.06324111 },
      { prefix: '9', frequency: 0.06324111 },
      { prefix: '101', frequency: 0.0513834 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.15810277 },
      { suffix: '5', frequency: 0.12648222 },
      { suffix: '1', frequency: 0.12252964 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.37549406 },
      { format: 'addddddddd', frequency: 0.18972331 },
      { format: 'aaaddddaada', frequency: 0.06719368 },
      { format: 'dddddddddddd', frequency: 0.06719368 },
      { format: 'adddddddd', frequency: 0.03557312 },
      { format: 'addddsddddd', frequency: 0.023715414 },
      { format: 'aaaaddddddd', frequency: 0.023715414 },
      { format: 'dddddddddd', frequency: 0.023715414 },
      { format: 'aaaddddaadd', frequency: 0.019762846 },
      { format: 'ddddddddddd', frequency: 0.019762846 },
      { format: 'aaaddddadda', frequency: 0.015810277 },
      { format: 'dddddddd', frequency: 0.011857707 },
      { format: 'ddddsdddddhdd', frequency: 0.011857707 },
      { format: 'ddddddddddddddd', frequency: 0.007905139 },
      { format: 'aaadddddddd', frequency: 0.007905139 },
      { format: 'ddddddd', frequency: 0.0039525693 },
      { format: 'aaaddddaddd', frequency: 0.0039525693 },
      { format: 'adddddddddhdd', frequency: 0.0039525693 },
      { format: 'aaaadddaaaa', frequency: 0.0039525693 },
      { format: 'addddddddddd', frequency: 0.0039525693 },
      { format: 'aaaddddaaaa', frequency: 0.0039525693 },
      { format: 'aaadddddd', frequency: 0.0039525693 },
      { format: 'aaaadddadda', frequency: 0.0039525693 },
      { format: 'dddddd', frequency: 0.0039525693 },
      { format: 'adddddddddd', frequency: 0.0039525693 },
      { format: 'addddsdddddhdd', frequency: 0.0039525693 },
      { format: 'ddddaadd', frequency: 0.0039525693 },
      { format: 'aaddddddd', frequency: 0.0039525693 },
      { format: 'aaaadddaada', frequency: 0.0039525693 },
      { format: 'aaaddddaaa', frequency: 0.0039525693 },
      { format: 'adddd', frequency: 0.0039525693 },
      { format: 'ddddd', frequency: 0.0039525693 },
      { format: 'ddddddhddhddd', frequency: 0.0039525693 },
      { format: 'aaaadddd', frequency: 0.0039525693 },
      { format: 'aaaaaddddddd', frequency: 0.0039525693 },
      { format: 'aaadddaddddd', frequency: 0.0039525693 },
      { format: 'addddddddddhdd', frequency: 0.0039525693 },
      { format: 'aaddddddddd', frequency: 0.0039525693 },
      { format: 'aaaddddddd', frequency: 0.0039525693 },
      { format: 'asdddddddddd', frequency: 0.0039525693 },
    ],
  },
  {
    front_end_carrier_id: 132,
    prefixes: [
      { prefix: 'W2', frequency: 0.25460124 },
      { prefix: '1', frequency: 0.08895706 },
      { prefix: 'W28', frequency: 0.073619634 },
      { prefix: '00', frequency: 0.07055215 },
      { prefix: '4', frequency: 0.064417176 },
      { prefix: 'W27', frequency: 0.061349694 },
      { prefix: '2', frequency: 0.061349694 },
      { prefix: '9', frequency: 0.055214725 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16871166 },
      { suffix: '1', frequency: 0.12883435 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.2208589 },
      { format: 'ddddddddd', frequency: 0.116564415 },
      { format: 'adddddddd', frequency: 0.09815951 },
      { format: 'dddddddddd', frequency: 0.08588957 },
      { format: 'dddddddddddd', frequency: 0.07668712 },
      { format: 'dddddddd', frequency: 0.07668712 },
      { format: 'ddddddddaaaa', frequency: 0.042944785 },
      { format: 'ddddddddddd', frequency: 0.0398773 },
      { format: 'aaddddddd', frequency: 0.027607363 },
      { format: 'addddsddddd', frequency: 0.021472393 },
      { format: 'adddddddddd', frequency: 0.018404908 },
      { format: 'ddddddd', frequency: 0.012269938 },
      { format: 'aaadddaddddd', frequency: 0.012269938 },
      { format: 'addddddddddd', frequency: 0.009202454 },
      { format: 'aaddddddddd', frequency: 0.009202454 },
      { format: 'dddddd', frequency: 0.009202454 },
      { format: 'ddddsdddddhdd', frequency: 0.006134969 },
      { format: 'adddddddddhdd', frequency: 0.006134969 },
      { format: 'aaadddddd', frequency: 0.006134969 },
      { format: 'addddddd', frequency: 0.006134969 },
      { format: 'ddddd', frequency: 0.006134969 },
      { format: 'aaaddddddd', frequency: 0.006134969 },
      { format: 'aaaddddaddd', frequency: 0.0030674846 },
      { format: 'ddddddddddddddd', frequency: 0.0030674846 },
      { format: 'dddaddddd', frequency: 0.0030674846 },
      { format: 'dddaadddddd', frequency: 0.0030674846 },
      { format: 'aaddddddddddd', frequency: 0.0030674846 },
      { format: 'ddddddddddsdd', frequency: 0.0030674846 },
      { format: 'd', frequency: 0.0030674846 },
      { format: 'adadddaddddd', frequency: 0.0030674846 },
      { format: 'dddddddddddddd', frequency: 0.0030674846 },
      { format: 'aaaaddddd', frequency: 0.0030674846 },
      { format: 'aaaddaddd', frequency: 0.0030674846 },
      { format: 'ddddddhddhddd', frequency: 0.0030674846 },
      { format: 'dddhddhdddd', frequency: 0.0030674846 },
      { format: 'aadddddddd', frequency: 0.0030674846 },
      { format: 'addddsdddddhdd', frequency: 0.0030674846 },
      { format: 'a', frequency: 0.0030674846 },
      { format: 'aa', frequency: 0.0030674846 },
      { format: 'adddddd', frequency: 0.0030674846 },
      { format: 'adddd', frequency: 0.0030674846 },
      { format: 'dddddddddhdd', frequency: 0.0030674846 },
      { format: 'aaaadddddddd', frequency: 0.0030674846 },
      { format: 'aaaaaddddddd', frequency: 0.0030674846 },
      { format: 'aaaahaaahaaahddddhddddddd', frequency: 0.0030674846 },
      { format: 'ddddsddddd', frequency: 0.0030674846 },
      { format: 'addddddddddhdd', frequency: 0.0030674846 },
      { format: 'addaa', frequency: 0.0030674846 },
      { format: 'aaadddddddd', frequency: 0.0030674846 },
      { format: 'adadddddddddddd', frequency: 0.0030674846 },
    ],
  },
  {
    front_end_carrier_id: 133,
    prefixes: [
      { prefix: 'W28', frequency: 0.08585858 },
      { prefix: 'W27', frequency: 0.078282826 },
      { prefix: '2', frequency: 0.06691919 },
      { prefix: '9', frequency: 0.059343435 },
      { prefix: '802', frequency: 0.056818184 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.24494949 },
      { suffix: '00', frequency: 0.16666667 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.2588384 },
      { format: 'ddddddddddd', frequency: 0.1729798 },
      { format: 'ddddddddd', frequency: 0.116161615 },
      { format: 'dddddddddddd', frequency: 0.07575758 },
      { format: 'adddddddd', frequency: 0.07323232 },
      { format: 'dddddddddd', frequency: 0.061868686 },
      { format: 'addddsddddd', frequency: 0.03409091 },
      { format: 'adddddddddd', frequency: 0.022727273 },
      { format: 'aaddddddd', frequency: 0.021464646 },
      { format: 'dddddddddhdd', frequency: 0.012626262 },
      { format: 'addddddddddd', frequency: 0.011363637 },
      { format: 'dddddddd', frequency: 0.011363637 },
      { format: 'aaadddaddddd', frequency: 0.0088383835 },
      { format: 'ddddddd', frequency: 0.007575758 },
      { format: 'ddddsdddddhdd', frequency: 0.007575758 },
      { format: 'addddddd', frequency: 0.006313131 },
      { format: 'aaaddddddd', frequency: 0.006313131 },
      { format: 'aaaddddddddd', frequency: 0.003787879 },
      { format: 'aaddddddddd', frequency: 0.003787879 },
      { format: 'ddddddddddddd', frequency: 0.003787879 },
      { format: 'adddddddddhdd', frequency: 0.003787879 },
      { format: 'dddddd', frequency: 0.003787879 },
      { format: 'aaaaaddddddd', frequency: 0.0025252525 },
      { format: 'dddddsdddd', frequency: 0.0025252525 },
      { format: 'aaadddddd', frequency: 0.0025252525 },
      { format: 'ddddd', frequency: 0.0025252525 },
      { format: 'dddddaddddd', frequency: 0.0025252525 },
      { format: 'ddddddddaaaa', frequency: 0.0025252525 },
      { format: 'adddd', frequency: 0.0025252525 },
      { format: 'adddddd', frequency: 0.0012626263 },
      { format: 'ddhdddddddd', frequency: 0.0012626263 },
      { format: 'dddaddddddd', frequency: 0.0012626263 },
      { format: 'addddsdddddhdd', frequency: 0.0012626263 },
      { format: 'ddddddaaa', frequency: 0.0012626263 },
      { format: 'aaadddddddda', frequency: 0.0012626263 },
      { format: 'ddddddhdd', frequency: 0.0012626263 },
      { format: 'aa', frequency: 0.0012626263 },
      { format: 'dddddddddddddddddddddd', frequency: 0.0012626263 },
      { format: 'aaaaaaa', frequency: 0.0012626263 },
      { format: 'add', frequency: 0.0012626263 },
      { format: 'aaaaddddd', frequency: 0.0012626263 },
      { format: 'dddadaadddad', frequency: 0.0012626263 },
      { format: 'aaaaaaaaaaaa', frequency: 0.0012626263 },
      { format: 'aaadaaadddddddd', frequency: 0.0012626263 },
      { format: 'ddddsddddd', frequency: 0.0012626263 },
      { format: 'aaaaaaaaaa', frequency: 0.0012626263 },
      { format: 'dddaddddd', frequency: 0.0012626263 },
      { format: 'dddddaaddddddd', frequency: 0.0012626263 },
      { format: 'aaaddddd', frequency: 0.0012626263 },
      { format: 'ddddddddddsdd', frequency: 0.0012626263 },
      { format: 'adadddaddddd', frequency: 0.0012626263 },
      { format: 'ddddddddddddddd', frequency: 0.0012626263 },
      { format: 'aaadddddddd', frequency: 0.0012626263 },
      { format: 'addddhdddd', frequency: 0.0012626263 },
      { format: 'addaaddd', frequency: 0.0012626263 },
      { format: 'addddddddddhdd', frequency: 0.0012626263 },
      { format: 'addddsdddddd', frequency: 0.0012626263 },
      { format: 'aaadhaadhaadd', frequency: 0.0012626263 },
      { format: 'ddd', frequency: 0.0012626263 },
      { format: 'aaaaaaaa', frequency: 0.0012626263 },
      { format: 'aaasdddddddddsdd', frequency: 0.0012626263 },
      { format: 'hdddddd', frequency: 0.0012626263 },
      { format: 'aaaaddddddddd', frequency: 0.0012626263 },
      { format: 'dddadaadadda', frequency: 0.0012626263 },
      { format: 'aasdddddddddddddd', frequency: 0.0012626263 },
      { format: 'addaa', frequency: 0.0012626263 },
      { format: 'adddddddddddddd', frequency: 0.0012626263 },
      { format: 'asdddd', frequency: 0.0012626263 },
      { format: 'adadddddddddddd', frequency: 0.0012626263 },
      { format: 'aasddddddd', frequency: 0.0012626263 },
      { format: 'addddddddhdd', frequency: 0.0012626263 },
      { format: 'dddddddda', frequency: 0.0012626263 },
    ],
  },
  {
    front_end_carrier_id: 134,
    prefixes: [
      { prefix: '2477', frequency: 0.16029143 },
      { prefix: '24771', frequency: 0.0992714 },
      { prefix: '00', frequency: 0.068306014 },
      { prefix: 'W27', frequency: 0.06466302 },
      { prefix: 'W28', frequency: 0.06375228 },
      { prefix: '101', frequency: 0.056466304 },
      { prefix: '9', frequency: 0.052823316 },
      { prefix: '24772', frequency: 0.05100182 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.17668489 },
      { suffix: '1', frequency: 0.15027322 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.23315118 },
      { format: 'ddddddddddd', frequency: 0.2140255 },
      { format: 'ddddddddd', frequency: 0.12112933 },
      { format: 'dddddddddddd', frequency: 0.11839709 },
      { format: 'dddddddddd', frequency: 0.06557377 },
      { format: 'adddddddd', frequency: 0.044626594 },
      { format: 'addddsddddd', frequency: 0.016393442 },
      { format: 'aaddddddd', frequency: 0.016393442 },
      { format: 'adddddddddd', frequency: 0.015482696 },
      { format: 'dddddddd', frequency: 0.010928961 },
      { format: 'aaadddaddddd', frequency: 0.010018215 },
      { format: 'addddddddddd', frequency: 0.010018215 },
      { format: 'ddddddddddddd', frequency: 0.010018215 },
      { format: 'dddddddddhdd', frequency: 0.010018215 },
      { format: 'aaddddddddd', frequency: 0.008196721 },
      { format: 'ddddsdddddhdd', frequency: 0.0072859745 },
      { format: 'ddddddd', frequency: 0.0063752276 },
      { format: 'adddddd', frequency: 0.0054644807 },
      { format: 'addddddd', frequency: 0.004553734 },
      { format: 'adddddddddhdd', frequency: 0.004553734 },
      { format: 'aaaddddddddd', frequency: 0.0036429872 },
      { format: 'dddddaddddd', frequency: 0.0036429872 },
      { format: 'dddddd', frequency: 0.0036429872 },
      { format: 'aadddddddd', frequency: 0.0036429872 },
      { format: 'addddsdddddhdd', frequency: 0.0027322404 },
      { format: 'aaaddddddd', frequency: 0.0027322404 },
      { format: 'aaaaaddddddd', frequency: 0.0018214936 },
      { format: 'adddd', frequency: 0.0018214936 },
      { format: 'addddddddddhdd', frequency: 0.0018214936 },
      { format: 'ddddddddddddddd', frequency: 0.0018214936 },
      { format: 'dddddsdddd', frequency: 0.0018214936 },
      { format: 'ddddddddaaaa', frequency: 0.0018214936 },
      { format: 'addddddddhdd', frequency: 0.0018214936 },
      { format: 'dddddddddddddd', frequency: 0.0018214936 },
      { format: 'ddddd', frequency: 0.0018214936 },
      { format: 'aa', frequency: 0.0018214936 },
      { format: 'dddddddda', frequency: 0.0018214936 },
    ],
  },
  {
    front_end_carrier_id: 136,
    prefixes: [
      { prefix: '00', frequency: 0.5551402 },
      { prefix: '007', frequency: 0.33271027 },
      { prefix: '1', frequency: 0.13271028 },
      { prefix: '00708', frequency: 0.108411215 },
      { prefix: 'U', frequency: 0.08971962 },
      { prefix: '00710', frequency: 0.06542056 },
      { prefix: '008', frequency: 0.054205608 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.3140187 },
      { suffix: '-01', frequency: 0.13084112 },
      { suffix: '02', frequency: 0.11588785 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.25794393 },
      { format: 'ddddddddddd', frequency: 0.22056074 },
      { format: 'dddddddddhdd', frequency: 0.20747663 },
      { format: 'adddddddddd', frequency: 0.059813086 },
      { format: 'adddddddd', frequency: 0.05046729 },
      { format: 'dddddddddd', frequency: 0.026168223 },
      { format: 'dddddddddsdd', frequency: 0.022429906 },
      { format: 'aadddddddddd', frequency: 0.018691588 },
      { format: 'dddddddd', frequency: 0.0149532715 },
      { format: 'ddddddd', frequency: 0.0149532715 },
      { format: 'dddddddddddd', frequency: 0.013084112 },
      { format: 'addddddddsdd', frequency: 0.013084112 },
      { format: 'addddddddhdd', frequency: 0.009345794 },
      { format: 'aaaaadddddddddhad', frequency: 0.0074766357 },
      { format: 'ddddddddddddd', frequency: 0.0037383179 },
      { format: 'addddddddddhdd', frequency: 0.0037383179 },
      { format: 'aaadddddd', frequency: 0.0037383179 },
      { format: 'aaaaaaaddddhdd', frequency: 0.0037383179 },
      { format: 'aaddddddd', frequency: 0.0037383179 },
      { format: 'addddddddddd', frequency: 0.0037383179 },
      { format: 'aaaddddddd', frequency: 0.0018691589 },
      { format: 'd', frequency: 0.0018691589 },
      { format: 'dddddddddddddd', frequency: 0.0018691589 },
      { format: 'addaaadddddd', frequency: 0.0018691589 },
      { format: 'addddddddd', frequency: 0.0018691589 },
      { format: 'ddddddddddhdd', frequency: 0.0018691589 },
      { format: 'aaaddddddddhdd', frequency: 0.0018691589 },
      { format: 'ddhddddddddd', frequency: 0.0018691589 },
      { format: 'aaadddddaddddd', frequency: 0.0018691589 },
      { format: 'aaaaadddddddddhd', frequency: 0.0018691589 },
      { format: 'ddddddddddddhdd', frequency: 0.0018691589 },
      { format: 'ddddddddsdd', frequency: 0.0018691589 },
      { format: 'ddddddddddddsxax', frequency: 0.0018691589 },
      { format: 'ddaddddddd', frequency: 0.0018691589 },
      { format: 'addddddddaaa', frequency: 0.0018691589 },
      { format: 'ddd', frequency: 0.0018691589 },
      { format: 'aaadddddddhdd', frequency: 0.0018691589 },
      { format: 'ddddddddda', frequency: 0.0018691589 },
      { format: 'ddddddddhdd', frequency: 0.0018691589 },
      { format: 'aaaddddddddsdd', frequency: 0.0018691589 },
      { format: 'addddddddddsdd', frequency: 0.0018691589 },
      { format: 'aaaaaaadddd', frequency: 0.0018691589 },
    ],
  },
  {
    front_end_carrier_id: 137,
    prefixes: [
      { prefix: '3', frequency: 0.64356434 },
      { prefix: '30', frequency: 0.4009901 },
      { prefix: '2', frequency: 0.26856434 },
      { prefix: '31', frequency: 0.24133663 },
      { prefix: '23', frequency: 0.11076733 },
      { prefix: '311', frequency: 0.11014851 },
      { prefix: '309', frequency: 0.08106436 },
      { prefix: '312', frequency: 0.079826735 },
      { prefix: '24', frequency: 0.06930693 },
      { prefix: '305', frequency: 0.056930695 },
      { prefix: '307', frequency: 0.051980197 },
      { prefix: '310', frequency: 0.051361386 },
    ],
    suffixes: [{ suffix: 'GEHA', frequency: 0.6181931 }],
    formats: [
      { format: 'ddddddddaaaa', frequency: 0.6763614 },
      { format: 'dddddddd', frequency: 0.22648515 },
      { format: 'ddddddddd', frequency: 0.050742574 },
      { format: 'ddddddddddd', frequency: 0.013613861 },
      { format: 'dddddddddd', frequency: 0.0043316833 },
      { format: 'dddddddddddd', frequency: 0.0043316833 },
      { format: 'dddddddhdd', frequency: 0.0043316833 },
      { format: 'dddddddddhdd', frequency: 0.0018564357 },
      { format: 'dddddddaaaa', frequency: 0.0018564357 },
      { format: 'ddddddd', frequency: 0.0012376237 },
    ],
  },
  {
    front_end_carrier_id: 138,
    prefixes: [
      { prefix: '10', frequency: 0.17645258 },
      { prefix: '00', frequency: 0.088188015 },
      { prefix: '9', frequency: 0.061547883 },
      { prefix: '109', frequency: 0.060246497 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.25208604 },
      { suffix: '01', frequency: 0.20048994 },
      { suffix: '2', frequency: 0.12929648 },
      { suffix: '0', frequency: 0.1263875 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.26586542 },
      { format: 'adddddddddd', frequency: 0.20202097 },
      { format: 'adddddddd', frequency: 0.11145985 },
      { format: 'ddddddddddd', frequency: 0.07165276 },
      { format: 'dddddddddddd', frequency: 0.0567251 },
      { format: 'aaddddddd', frequency: 0.053586464 },
      { format: 'addddddddsdd', frequency: 0.053433362 },
      { format: 'dddddddd', frequency: 0.027482202 },
      { format: 'dddddddddd', frequency: 0.021128377 },
      { format: 'ddddddd', frequency: 0.010104876 },
      { format: 'dddddaddddd', frequency: 0.010028324 },
      { format: 'aaadddddd', frequency: 0.010028324 },
      { format: 'dddddddddsdd', frequency: 0.009415908 },
      { format: 'ddddddddda', frequency: 0.008344178 },
      { format: 'dddddddddhdd', frequency: 0.007272449 },
      { format: 'aaaddddddddhdd', frequency: 0.0069662407 },
      { format: 'aaadddddddd', frequency: 0.0068896883 },
      { format: 'aaaaddddd', frequency: 0.0059710634 },
      { format: 'aaaddddddddd', frequency: 0.0052055423 },
      { format: 'aaadddddddddd', frequency: 0.0045165736 },
      { format: 'ddaddddddd', frequency: 0.0036745004 },
      { format: 'addddddddhdd', frequency: 0.00329174 },
      { format: 'dddddd', frequency: 0.0026793233 },
      { format: 'addddddddd', frequency: 0.002449667 },
      { format: 'aaaddddddd', frequency: 0.002373115 },
      { format: 'ddddddddddddd', frequency: 0.0022200106 },
      { format: 'aadddddddddd', frequency: 0.0018372502 },
      { format: 'addddddd', frequency: 0.0017606982 },
      { format: 'dddddddaa', frequency: 0.0013013856 },
      { format: 'daddddddddd', frequency: 0.0012248335 },
      { format: 'aadddddddd', frequency: 0.0012248335 },
      { format: 'aaaaadddddd', frequency: 0.0011482814 },
      { format: 'aaddddddddddd', frequency: 0.0010717293 },
      { format: 'addddddddaad', frequency: 0.0010717293 },
    ],
  },
  {
    front_end_carrier_id: 139,
    prefixes: [
      { prefix: 'JHU0', frequency: 0.122474745 },
      { prefix: 'MTX0', frequency: 0.08207071 },
      { prefix: 'R', frequency: 0.065656565 },
      { prefix: 'JHU01', frequency: 0.061868686 },
      { prefix: 'JHU00', frequency: 0.060606062 },
      { prefix: 'A', frequency: 0.060606062 },
    ],
    suffixes: [
      { suffix: '2', frequency: 0.16287878 },
      { suffix: '1', frequency: 0.12373737 },
    ],
    formats: [
      { format: 'aaadddddd', frequency: 0.5151515 },
      { format: 'aaaddddddddd', frequency: 0.22348484 },
      { format: 'adadddddd', frequency: 0.065656565 },
      { format: 'aaasddddddddd', frequency: 0.056818184 },
      { format: 'adddddddd', frequency: 0.053030305 },
      { format: 'ddddddddd', frequency: 0.027777778 },
      { format: 'adaddddddddd', frequency: 0.0239899 },
      { format: 'aaadddddddddddd', frequency: 0.007575758 },
      { format: 'aaaaddddd', frequency: 0.005050505 },
      { format: 'aaadddaddddd', frequency: 0.003787879 },
      { format: 'aaaadddddddd', frequency: 0.003787879 },
      { format: 'aaaaaddddddd', frequency: 0.0025252525 },
      { format: 'adasddddddddd', frequency: 0.0025252525 },
      { format: 'adadaaadddddddd', frequency: 0.0012626263 },
      { format: 'adadddaddddd', frequency: 0.0012626263 },
      { format: 'aaadddddddda', frequency: 0.0012626263 },
      { format: 'aaaddddddddddd', frequency: 0.0012626263 },
      { format: 'adaaddddd', frequency: 0.0012626263 },
      { format: 'aaasdddddd', frequency: 0.0012626263 },
      { format: 'aaaddddd', frequency: 0.0012626263 },
    ],
  },
  {
    front_end_carrier_id: 141,
    prefixes: [
      { prefix: 'XXP96', frequency: 0.111821085 },
      { prefix: 'MTN96', frequency: 0.07428115 },
      { prefix: 'R', frequency: 0.060702875 },
      { prefix: 'V', frequency: 0.053514376 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.12539937 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.6309904 },
      { format: 'adaddddddddd', frequency: 0.14057508 },
      { format: 'adddddddd', frequency: 0.048722044 },
      { format: 'aaaadddddddd', frequency: 0.032747604 },
      { format: 'aaadddddddddddd', frequency: 0.026357828 },
      { format: 'aaadddaddddd', frequency: 0.022364218 },
      { format: 'aaadddddddddd', frequency: 0.011182109 },
      { format: 'aaadddddddda', frequency: 0.010383386 },
      { format: 'aaadddddd', frequency: 0.0071884985 },
      { format: 'aaasddddddddd', frequency: 0.0063897762 },
      { format: 'adadddddddddddd', frequency: 0.0063897762 },
      { format: 'adadddddddddd', frequency: 0.0055910544 },
      { format: 'aaadaaadddddddd', frequency: 0.0055910544 },
      { format: 'aaaadddddddddd', frequency: 0.0055910544 },
      { format: 'adadddaddddd', frequency: 0.0055910544 },
      { format: 'aaaaaddddddd', frequency: 0.004792332 },
      { format: 'aaaddddddddddd', frequency: 0.0039936104 },
      { format: 'aaadddddddd', frequency: 0.0031948881 },
      { format: 'adaddddddddddd', frequency: 0.0015974441 },
      { format: 'aaaaddddddddd', frequency: 0.0015974441 },
      { format: 'adadddddddaa', frequency: 0.0015974441 },
      { format: 'adadddddd', frequency: 0.0015974441 },
      { format: 'aaasdddddddddsdd', frequency: 0.0015974441 },
      { format: 'aaaddddddddda', frequency: 0.0015974441 },
      { format: 'aasaddddddddd', frequency: 0.0015974441 },
    ],
  },
  {
    front_end_carrier_id: 142,
    prefixes: [
      { prefix: '50', frequency: 0.23182711 },
      { prefix: 'W2', frequency: 0.19646366 },
      { prefix: '500', frequency: 0.17878193 },
      { prefix: '10', frequency: 0.07465619 },
      { prefix: 'W28', frequency: 0.06876228 },
      { prefix: '00', frequency: 0.06876228 },
      { prefix: '5002', frequency: 0.06090373 },
      { prefix: '9', frequency: 0.058939096 },
      { prefix: 'W27', frequency: 0.055009823 },
      { prefix: '501', frequency: 0.05108055 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.3811395 },
      { suffix: '00', frequency: 0.32416502 },
    ],
    formats: [
      { format: 'dddddddddddd', frequency: 0.3104126 },
      { format: 'addddddddd', frequency: 0.18074656 },
      { format: 'ddddddddd', frequency: 0.115913555 },
      { format: 'dddddddddd', frequency: 0.066797644 },
      { format: 'adddddddd', frequency: 0.05697446 },
      { format: 'ddddddddddd', frequency: 0.055009823 },
      { format: 'aaddddddd', frequency: 0.02357564 },
      { format: 'addddsddddd', frequency: 0.019646365 },
      { format: 'adddddddddd', frequency: 0.019646365 },
      { format: 'ddddsdddddhdd', frequency: 0.013752456 },
      { format: 'dddddddddhdd', frequency: 0.013752456 },
      { format: 'ddddddd', frequency: 0.009823183 },
      { format: 'aaadddaddddd', frequency: 0.009823183 },
      { format: 'addddddddddd', frequency: 0.009823183 },
      { format: 'dddddddd', frequency: 0.007858546 },
      { format: 'ddddddddddddd', frequency: 0.00589391 },
      { format: 'aaddddddddd', frequency: 0.00589391 },
      { format: 'dddddsdddd', frequency: 0.003929273 },
      { format: 'aaaddddddddd', frequency: 0.003929273 },
      { format: 'addddsdddddhdd', frequency: 0.003929273 },
      { format: 'dddddaddddd', frequency: 0.003929273 },
      { format: 'dddddd', frequency: 0.003929273 },
      { format: 'addddddddddhdd', frequency: 0.003929273 },
      { format: 'aaaddddddd', frequency: 0.003929273 },
      { format: 'adddddddddhdd', frequency: 0.003929273 },
      { format: 'adddddd', frequency: 0.0019646366 },
      { format: 'aaaddddaaaa', frequency: 0.0019646366 },
      { format: 'aaaaaddddddd', frequency: 0.0019646366 },
      { format: 'ddhdddddddd', frequency: 0.0019646366 },
      { format: 'ddddddddddddhd', frequency: 0.0019646366 },
      { format: 'aaadddddd', frequency: 0.0019646366 },
      { format: 'a', frequency: 0.0019646366 },
      { format: 'ddddddaaa', frequency: 0.0019646366 },
      { format: 'aa', frequency: 0.0019646366 },
      { format: 'aaadaaadddddddd', frequency: 0.0019646366 },
      { format: 'ddddddddddddddd', frequency: 0.0019646366 },
      { format: 'dddaddddd', frequency: 0.0019646366 },
      { format: 'ddddddddddsdd', frequency: 0.0019646366 },
      { format: 'd', frequency: 0.0019646366 },
      { format: 'addddhdddd', frequency: 0.0019646366 },
      { format: 'addddddd', frequency: 0.0019646366 },
      { format: 'adddd', frequency: 0.0019646366 },
      { format: 'ddd', frequency: 0.0019646366 },
      { format: 'aaasdddddddddsdd', frequency: 0.0019646366 },
      { format: 'aaaaddddddddd', frequency: 0.0019646366 },
      { format: 'dddadaadadda', frequency: 0.0019646366 },
      { format: 'aasddddddd', frequency: 0.0019646366 },
    ],
  },
  {
    front_end_carrier_id: 143,
    prefixes: [
      { prefix: '1', frequency: 0.44686648 },
      { prefix: 'U', frequency: 0.14850137 },
      { prefix: '110', frequency: 0.14577657 },
      { prefix: '0', frequency: 0.061307903 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.20708446 },
      { suffix: '0', frequency: 0.15531336 },
      { suffix: '01', frequency: 0.13623978 },
      { suffix: '2', frequency: 0.12125341 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.40326977 },
      { format: 'adddddddddd', frequency: 0.089918256 },
      { format: 'adddddddd', frequency: 0.08583106 },
      { format: 'ddddddddddd', frequency: 0.07901908 },
      { format: 'dddddddddd', frequency: 0.061307903 },
      { format: 'dddddddddddd', frequency: 0.05722071 },
      { format: 'dddddddd', frequency: 0.03814714 },
      { format: 'addddddddsdd', frequency: 0.028610354 },
      { format: 'aadddddddddd', frequency: 0.027247956 },
      { format: 'ddddddd', frequency: 0.01907357 },
      { format: 'dddddddddsdd', frequency: 0.009536785 },
      { format: 'addddddddd', frequency: 0.008174387 },
      { format: 'dddddddddhdd', frequency: 0.005449591 },
      { format: 'addddddd', frequency: 0.005449591 },
      { format: 'aaadddddd', frequency: 0.005449591 },
      { format: 'ddddd', frequency: 0.005449591 },
      { format: 'addddddddddd', frequency: 0.005449591 },
      { format: 'aaddddddd', frequency: 0.005449591 },
      { format: 'aaaddddddddd', frequency: 0.005449591 },
      { format: 'addddddddhdd', frequency: 0.0040871934 },
      { format: 'ddaddddddd', frequency: 0.0040871934 },
      { format: 'dddddd', frequency: 0.0027247956 },
      { format: 'ddddddddda', frequency: 0.0027247956 },
      { format: 'aaadddddddddddd', frequency: 0.0013623978 },
      { format: 'd', frequency: 0.0013623978 },
      { format: 'ddddddddddddd', frequency: 0.0013623978 },
      { format: 'addaaadddddd', frequency: 0.0013623978 },
      { format: 'adaddddddddd', frequency: 0.0013623978 },
      { format: 'dddddddddddddd', frequency: 0.0013623978 },
      { format: 'aadaaaadd', frequency: 0.0013623978 },
      { format: 'addddsddddd', frequency: 0.0013623978 },
      { format: 'dddddaddddd', frequency: 0.0013623978 },
      { format: 'dddddddddxddd', frequency: 0.0013623978 },
      { format: 'xdddxsdddhdddd', frequency: 0.0013623978 },
      { format: 'aaaaaaaa', frequency: 0.0013623978 },
      { format: 'aaadddddaddddd', frequency: 0.0013623978 },
      { format: 'aaddddddddd', frequency: 0.0013623978 },
      { format: 'ddddddddsdd', frequency: 0.0013623978 },
      { format: 'aaaddddd', frequency: 0.0013623978 },
      { format: 'ddddddddddddsxax', frequency: 0.0013623978 },
      { format: 'aaa', frequency: 0.0013623978 },
      { format: 'addddddddaaa', frequency: 0.0013623978 },
      { format: 'dddddddddddda', frequency: 0.0013623978 },
      { format: 'aa', frequency: 0.0013623978 },
      { format: 'adddd', frequency: 0.0013623978 },
      { format: 'dddddaddd', frequency: 0.0013623978 },
      { format: 'dddddddddddddddd', frequency: 0.0013623978 },
      { format: 'ddd', frequency: 0.0013623978 },
      { format: 'adddddddddda', frequency: 0.0013623978 },
      { format: 'aaadddddddhdd', frequency: 0.0013623978 },
      { format: 'ddddddddhdd', frequency: 0.0013623978 },
      { format: 'aaaddddddddsdd', frequency: 0.0013623978 },
      { format: 'aaaddddddd', frequency: 0.0013623978 },
    ],
  },
  {
    front_end_carrier_id: 146,
    prefixes: [
      { prefix: '9', frequency: 0.3415892 },
      { prefix: '8', frequency: 0.14420383 },
      { prefix: '1', frequency: 0.11521188 },
      { prefix: '0', frequency: 0.097558804 },
      { prefix: '98', frequency: 0.08648673 },
      { prefix: '890', frequency: 0.072035216 },
      { prefix: '99', frequency: 0.06749967 },
      { prefix: '3', frequency: 0.055671662 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.1957846 },
      { suffix: '0', frequency: 0.13224243 },
      { suffix: '01', frequency: 0.114500426 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.57970566 },
      { format: 'dddddddhdd', frequency: 0.067766465 },
      { format: 'ddddddddddd', frequency: 0.062163725 },
      { format: 'dddddddd', frequency: 0.046867356 },
      { format: 'dddddddddd', frequency: 0.038063053 },
      { format: 'dddddddddddd', frequency: 0.030325938 },
      { format: 'dddddddddhdd', frequency: 0.02249989 },
      { format: 'ddddddd', frequency: 0.021610565 },
      { format: 'adddddddd', frequency: 0.015785495 },
      { format: 'aaaddddddddd', frequency: 0.008715372 },
      { format: 'addddddddd', frequency: 0.00733692 },
      { format: 'adddddddddd', frequency: 0.0072035217 },
      { format: 'ddddddddaaaa', frequency: 0.0062252656 },
      { format: 'ddaddddddd', frequency: 0.0049802125 },
      { format: 'aadddddddhdd', frequency: 0.0039574904 },
      { format: 'aaaddddddd', frequency: 0.003868558 },
      { format: 'aaddddddd', frequency: 0.002934768 },
      { format: 'aaadddaddddd', frequency: 0.002934768 },
      { format: 'aaddddddddd', frequency: 0.002178843 },
      { format: 'dddddd', frequency: 0.0020899107 },
      { format: 'aaaaaahdd', frequency: 0.0019120459 },
      { format: 'aaadddddddd', frequency: 0.0017786473 },
      { format: 'aaaadddddddd', frequency: 0.0012450531 },
      { format: 'dddaddddd', frequency: 0.0012005869 },
      { format: 'dddsdddsddd', frequency: 0.0011116546 },
      { format: 'aaadaahdd', frequency: 0.0010671885 },
      { format: 'aaddddda', frequency: 0.0010227222 },
      { format: 'aaaddddddhdd', frequency: 0.0010227222 },
      { format: 'ddddd', frequency: 0.0010227222 },
    ],
  },
  {
    front_end_carrier_id: 148,
    prefixes: [
      { prefix: '1', frequency: 0.48377126 },
      { prefix: '110', frequency: 0.1576507 },
      { prefix: 'U', frequency: 0.1561051 },
      { prefix: '0', frequency: 0.064914994 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.19165379 },
      { suffix: '0', frequency: 0.13137558 },
      { suffix: '01', frequency: 0.12364761 },
      { suffix: '2', frequency: 0.120556414 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4404946 },
      { format: 'adddddddd', frequency: 0.0927357 },
      { format: 'adddddddddd', frequency: 0.0927357 },
      { format: 'ddddddddddd', frequency: 0.077279754 },
      { format: 'dddddddd', frequency: 0.04018547 },
      { format: 'addddddddsdd', frequency: 0.032457497 },
      { format: 'dddddddddd', frequency: 0.02472952 },
      { format: 'aadddddddddd', frequency: 0.023183925 },
      { format: 'ddddddd', frequency: 0.017001545 },
      { format: 'dddddddddddd', frequency: 0.015455951 },
      { format: 'ddddddaaa', frequency: 0.010819165 },
      { format: 'dddddddddsdd', frequency: 0.010819165 },
      { format: 'addddddddd', frequency: 0.00927357 },
      { format: 'dddddddddhdd', frequency: 0.0077279755 },
      { format: 'addddddd', frequency: 0.0077279755 },
      { format: 'aaddddddd', frequency: 0.00618238 },
      { format: 'aaaddddddddd', frequency: 0.00618238 },
      { format: 'aaadddddd', frequency: 0.004636785 },
      { format: 'ddaddddddd', frequency: 0.004636785 },
      { format: 'dddddaddddd', frequency: 0.00309119 },
      { format: 'dddddddddaaa', frequency: 0.00309119 },
      { format: 'adaddddddddd', frequency: 0.00309119 },
      { format: 'addddddddddd', frequency: 0.00309119 },
      { format: 'dddddaaaa', frequency: 0.00309119 },
      { format: 'adadddddddaa', frequency: 0.00309119 },
      { format: 'aaadddddddaa', frequency: 0.00309119 },
      { format: 'ddddddddda', frequency: 0.00309119 },
      { format: 'aaadddddddddddd', frequency: 0.001545595 },
      { format: 'd', frequency: 0.001545595 },
      { format: 'ddddddddddddd', frequency: 0.001545595 },
      { format: 'dddddd', frequency: 0.001545595 },
      { format: 'dddddddddddddd', frequency: 0.001545595 },
      { format: 'aadaaaadd', frequency: 0.001545595 },
      { format: 'addddddddhdd', frequency: 0.001545595 },
      { format: 'addddsddddd', frequency: 0.001545595 },
      { format: 'aaaddddddddhdd', frequency: 0.001545595 },
      { format: 'dddddddddxddd', frequency: 0.001545595 },
      { format: 'adadddaddddd', frequency: 0.001545595 },
      { format: 'xdddxsdddhdddd', frequency: 0.001545595 },
      { format: 'aaaaaaaa', frequency: 0.001545595 },
      { format: 'aaadddddaddddd', frequency: 0.001545595 },
      { format: 'addddddaaa', frequency: 0.001545595 },
      { format: 'aaddddddddd', frequency: 0.001545595 },
      { format: 'dadddddddddd', frequency: 0.001545595 },
      { format: 'aaaddddd', frequency: 0.001545595 },
      { format: 'ddddddddddddsxax', frequency: 0.001545595 },
      { format: 'aaa', frequency: 0.001545595 },
      { format: 'addddddddaaa', frequency: 0.001545595 },
      { format: 'daaddddddddsdd', frequency: 0.001545595 },
      { format: 'dddddddddddda', frequency: 0.001545595 },
      { format: 'aa', frequency: 0.001545595 },
      { format: 'adddd', frequency: 0.001545595 },
      { format: 'aaaaddddd', frequency: 0.001545595 },
      { format: 'dddddaddd', frequency: 0.001545595 },
      { format: 'ddddd', frequency: 0.001545595 },
      { format: 'dddddddddddddddd', frequency: 0.001545595 },
      { format: 'adddddddddda', frequency: 0.001545595 },
      { format: 'aaadddddddhdd', frequency: 0.001545595 },
      { format: 'aaaddddddddsdd', frequency: 0.001545595 },
      { format: 'aaaddddddd', frequency: 0.001545595 },
    ],
  },
  {
    front_end_carrier_id: 149,
    prefixes: [
      { prefix: '110', frequency: 0.120300755 },
      { prefix: '109', frequency: 0.10311493 },
      { prefix: 'N32', frequency: 0.051557466 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.2244898 },
      { suffix: '01', frequency: 0.15789473 },
      { suffix: '2', frequency: 0.120300755 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4017186 },
      { format: 'adddddddd', frequency: 0.1084855 },
      { format: 'adddddddddd', frequency: 0.10311493 },
      { format: 'ddddddddddd', frequency: 0.07411385 },
      { format: 'dddddddd', frequency: 0.042964555 },
      { format: 'dddddddddd', frequency: 0.03651987 },
      { format: 'addddddddsdd', frequency: 0.03651987 },
      { format: 'ddddddd', frequency: 0.023630505 },
      { format: 'aadddddddddd', frequency: 0.023630505 },
      { format: 'dddddddddddd', frequency: 0.018259935 },
      { format: 'addddddd', frequency: 0.008592911 },
      { format: 'addddddddd', frequency: 0.008592911 },
      { format: 'aaddddddd', frequency: 0.008592911 },
      { format: 'dddddddddsdd', frequency: 0.008592911 },
      { format: 'addddddddddd', frequency: 0.007518797 },
      { format: 'dddddddddhdd', frequency: 0.0053705694 },
      { format: 'addddddddhdd', frequency: 0.0042964555 },
      { format: 'aaaddddddddd', frequency: 0.0042964555 },
      { format: 'aaadddddd', frequency: 0.0042964555 },
      { format: 'ddaddddddd', frequency: 0.0042964555 },
      { format: 'ddddd', frequency: 0.0042964555 },
      { format: 'aaaddddddd', frequency: 0.0032223416 },
      { format: 'aaadddaddddd', frequency: 0.0032223416 },
      { format: 'ddddddddddddd', frequency: 0.0021482278 },
      { format: 'aa', frequency: 0.0021482278 },
      { format: 'dddhddhdddd', frequency: 0.0021482278 },
      { format: 'adadddaddddd', frequency: 0.0021482278 },
      { format: 'ddddddddhdd', frequency: 0.0021482278 },
      { format: 'ddddddddda', frequency: 0.0021482278 },
      { format: 'aaddddddddsdd', frequency: 0.0021482278 },
      { format: 'aaadddddadd', frequency: 0.0010741139 },
      { format: 'aaadddddddddddd', frequency: 0.0010741139 },
      { format: 'aaxddddd', frequency: 0.0010741139 },
      { format: 'aaddddddddddsd', frequency: 0.0010741139 },
      { format: 'addaaadddddd', frequency: 0.0010741139 },
      { format: 'adaddddddddd', frequency: 0.0010741139 },
      { format: 'dddddddddddddd', frequency: 0.0010741139 },
      { format: 'addddsddddd', frequency: 0.0010741139 },
      { format: 'aaasaaaaaa', frequency: 0.0010741139 },
      { format: 'addddddddaad', frequency: 0.0010741139 },
      { format: 'dddddaddddd', frequency: 0.0010741139 },
      { format: 'dddddddddxddd', frequency: 0.0010741139 },
      { format: 'aaaaaaaa', frequency: 0.0010741139 },
      { format: 'aaddddddddd', frequency: 0.0010741139 },
      { format: 'dddddddddxa', frequency: 0.0010741139 },
      { format: 'ddddddddsdd', frequency: 0.0010741139 },
      { format: 'aaaddddd', frequency: 0.0010741139 },
      { format: 'aaa', frequency: 0.0010741139 },
      { format: 'daaddddddddsdd', frequency: 0.0010741139 },
      { format: 'dddddaddd', frequency: 0.0010741139 },
      { format: 'd', frequency: 0.0010741139 },
      { format: 'dddddd', frequency: 0.0010741139 },
      { format: 'aadaaaadd', frequency: 0.0010741139 },
      { format: 'aaaddddddddhdd', frequency: 0.0010741139 },
      { format: 'adddddddddsdd', frequency: 0.0010741139 },
      { format: 'xdddxsdddhdddd', frequency: 0.0010741139 },
      { format: 'ddddddddddddsxax', frequency: 0.0010741139 },
      { format: 'aaxsadddddddddd', frequency: 0.0010741139 },
      { format: 'adddddddsdd', frequency: 0.0010741139 },
      { format: 'addddddddaaa', frequency: 0.0010741139 },
      { format: 'dddddddddddda', frequency: 0.0010741139 },
      { format: 'adddd', frequency: 0.0010741139 },
      { format: 'dddddddddddddddd', frequency: 0.0010741139 },
      { format: 'aadddddd', frequency: 0.0010741139 },
      { format: 'ddd', frequency: 0.0010741139 },
      { format: 'adddddddddda', frequency: 0.0010741139 },
      { format: 'aaadddddddhdd', frequency: 0.0010741139 },
      { format: 'aaaddddddddsdd', frequency: 0.0010741139 },
    ],
  },
  {
    front_end_carrier_id: 150,
    prefixes: [
      { prefix: '9HP000', frequency: 0.074235804 },
      { prefix: 'GFN00064', frequency: 0.06550218 },
      { prefix: 'H', frequency: 0.063318774 },
      { prefix: 'N', frequency: 0.058951966 },
      { prefix: 'P', frequency: 0.05458515 },
    ],
    suffixes: [{ suffix: '7', frequency: 0.12663755 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.5524017 },
      { format: 'adaddddddddd', frequency: 0.35152838 },
      { format: 'daaddddddddd', frequency: 0.082969435 },
      { format: 'aaasddddddddd', frequency: 0.008733625 },
      { format: 'ddddddddd', frequency: 0.0021834061 },
      { format: 'ddaddddddddd', frequency: 0.0021834061 },
    ],
  },
  {
    front_end_carrier_id: 151,
    prefixes: [
      { prefix: 'ZNU60', frequency: 0.1086262 },
      { prefix: 'ZNU604', frequency: 0.0543131 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.31309903 },
      { suffix: '01', frequency: 0.25239617 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.46325877 },
      { format: 'aaaddddddddd', frequency: 0.1373802 },
      { format: 'aaaddddddddddd', frequency: 0.1341853 },
      { format: 'ddddddddddd', frequency: 0.1086262 },
      { format: 'dddddddddhdd', frequency: 0.015974442 },
      { format: 'aaadddaddddd', frequency: 0.015974442 },
      { format: 'dddddddddsdd', frequency: 0.0127795525 },
      { format: 'aaasdddddddddsdd', frequency: 0.0127795525 },
      { format: 'adaddddddddddd', frequency: 0.0127795525 },
      { format: 'adaddddddddd', frequency: 0.009584664 },
      { format: 'aaahdddddddddhdd', frequency: 0.009584664 },
      { format: 'dddddddd', frequency: 0.0063897762 },
      { format: 'aaaadddddddd', frequency: 0.0063897762 },
      { format: 'aaadddddddddhdd', frequency: 0.0063897762 },
      { format: 'adddddddd', frequency: 0.0063897762 },
      { format: 'aaasddddddddd', frequency: 0.0063897762 },
      { format: 'dddddddddd', frequency: 0.0031948881 },
      { format: 'aaadddddddddddd', frequency: 0.0031948881 },
      { format: 'aaadddddddda', frequency: 0.0031948881 },
      { format: 'adadddddddaa', frequency: 0.0031948881 },
      { format: 'aaadddddddddsdd', frequency: 0.0031948881 },
      { format: 'dddaddddd', frequency: 0.0031948881 },
      { format: 'aaaaddddddddd', frequency: 0.0031948881 },
      { format: 'dddddddddaa', frequency: 0.0031948881 },
      { format: 'adadddaddddd', frequency: 0.0031948881 },
      { format: 'ddddddddsdd', frequency: 0.0031948881 },
      { format: 'ddddddd', frequency: 0.0031948881 },
    ],
  },
  {
    front_end_carrier_id: 152,
    prefixes: [
      { prefix: '9', frequency: 0.5884615 },
      { prefix: '9HP000', frequency: 0.50769234 },
      { prefix: '9HP0006', frequency: 0.21153846 },
      { prefix: '9HP0005', frequency: 0.12692308 },
      { prefix: '9HP0004', frequency: 0.08461539 },
      { prefix: '9hp00', frequency: 0.053846154 },
      { prefix: 'G', frequency: 0.053846154 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.1423077 },
      { suffix: '1', frequency: 0.13076924 },
    ],
    formats: [
      { format: 'daaddddddddd', frequency: 0.5538462 },
      { format: 'aaaddddddddd', frequency: 0.20769231 },
      { format: 'adaddddddddd', frequency: 0.10769231 },
      { format: 'ddddddddd', frequency: 0.03846154 },
      { format: 'ddddddddddd', frequency: 0.01923077 },
      { format: 'daadddddddd', frequency: 0.015384615 },
      { format: 'daddddddddd', frequency: 0.011538462 },
      { format: 'aaadddddddd', frequency: 0.0076923077 },
      { format: 'adddddddd', frequency: 0.0076923077 },
      { format: 'adadddddddd', frequency: 0.0038461538 },
      { format: 'aaaaaaddddd', frequency: 0.0038461538 },
      { format: 'addddddddd', frequency: 0.0038461538 },
      { format: 'aaddddddddd', frequency: 0.0038461538 },
      { format: 'aaaaaadddddd', frequency: 0.0038461538 },
      { format: 'dddddddddhdd', frequency: 0.0038461538 },
      { format: 'daaaaadddddd', frequency: 0.0038461538 },
      { format: 'aaasddddddddd', frequency: 0.0038461538 },
    ],
  },
  {
    front_end_carrier_id: 153,
    prefixes: [
      { prefix: 'U8', frequency: 0.15902965 },
      { prefix: 'U7', frequency: 0.106469005 },
      { prefix: 'HH', frequency: 0.08355795 },
      { prefix: 'U3', frequency: 0.074123986 },
      { prefix: 'U6', frequency: 0.07008086 },
      { prefix: 'U5', frequency: 0.0606469 },
      { prefix: '00', frequency: 0.056603774 },
      { prefix: '1', frequency: 0.052560646 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.39757413 },
      { suffix: '3', frequency: 0.1212938 },
      { suffix: '02', frequency: 0.11859838 },
    ],
    formats: [
      { format: 'adddddddddd', frequency: 0.42857143 },
      { format: 'addddddddsdd', frequency: 0.14420485 },
      { format: 'ddddddddd', frequency: 0.09973046 },
      { format: 'adddddddd', frequency: 0.08625337 },
      { format: 'ddddddddddd', frequency: 0.053908356 },
      { format: 'aaaaddddd', frequency: 0.053908356 },
      { format: 'aaaddddddddd', frequency: 0.018867925 },
      { format: 'dddddddddd', frequency: 0.017520215 },
      { format: 'addddddddhdd', frequency: 0.01212938 },
      { format: 'dddddddd', frequency: 0.010781671 },
      { format: 'aaadddddd', frequency: 0.008086253 },
      { format: 'aaaadaddd', frequency: 0.008086253 },
      { format: 'aadddddddddd', frequency: 0.0067385444 },
      { format: 'dddddddddsdd', frequency: 0.0053908355 },
      { format: 'dddddddddddd', frequency: 0.0040431265 },
      { format: 'addddddddd', frequency: 0.0040431265 },
      { format: 'adddddddsdd', frequency: 0.0040431265 },
      { format: 'ddddd', frequency: 0.0026954177 },
      { format: 'aadddddddhdd', frequency: 0.0026954177 },
      { format: 'ddddddd', frequency: 0.0026954177 },
      { format: 'aaaddddddd', frequency: 0.0013477089 },
      { format: 'aaaddaddd', frequency: 0.0013477089 },
      { format: 'aaaaadddd', frequency: 0.0013477089 },
      { format: 'aaadddddddd', frequency: 0.0013477089 },
      { format: 'ddddadddddd', frequency: 0.0013477089 },
      { format: 'aaaddddddddhdd', frequency: 0.0013477089 },
      { format: 'aaddddddd', frequency: 0.0013477089 },
      { format: 'asdddddddddd', frequency: 0.0013477089 },
      { format: 'aaaaddddddd', frequency: 0.0013477089 },
      { format: 'addddddddddsdd', frequency: 0.0013477089 },
      { format: 'aaadddddaddddd', frequency: 0.0013477089 },
      { format: 'addddddd', frequency: 0.0013477089 },
      { format: 'dddddddddsssssssssdd', frequency: 0.0013477089 },
      { format: 'aaddddd', frequency: 0.0013477089 },
      { format: 'addddddddddd', frequency: 0.0013477089 },
      { format: 'dddddddddhdd', frequency: 0.0013477089 },
      { format: 'aaadddddddhdd', frequency: 0.0013477089 },
      { format: 'ddddddddda', frequency: 0.0013477089 },
      { format: 'aaaaaaddd', frequency: 0.0013477089 },
    ],
  },
  {
    front_end_carrier_id: 155,
    prefixes: [
      { prefix: '7', frequency: 0.14155251 },
      { prefix: '1', frequency: 0.1369863 },
      { prefix: '5', frequency: 0.12328767 },
      { prefix: '9', frequency: 0.11415525 },
      { prefix: '3', frequency: 0.11415525 },
      { prefix: '2', frequency: 0.10958904 },
      { prefix: '4', frequency: 0.08675799 },
      { prefix: '8', frequency: 0.07762557 },
      { prefix: '6', frequency: 0.07762557 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.7671233 },
      { suffix: '01', frequency: 0.1324201 },
      { suffix: '600', frequency: 0.12328767 },
      { suffix: '700', frequency: 0.10502283 },
      { suffix: '100', frequency: 0.10502283 },
    ],
    formats: [
      { format: 'ddddddddddd', frequency: 0.96347034 },
      { format: 'addddddddd', frequency: 0.01369863 },
      { format: 'dddddddddddd', frequency: 0.01369863 },
      { format: 'adddddddddhdd', frequency: 0.00456621 },
      { format: 'dddddddddddddddddddddd', frequency: 0.00456621 },
    ],
  },
  {
    front_end_carrier_id: 157,
    prefixes: [
      { prefix: '9800', frequency: 0.4606866 },
      { prefix: '90', frequency: 0.27906978 },
      { prefix: '98008', frequency: 0.13621262 },
      { prefix: 'W2', frequency: 0.08637874 },
      { prefix: '98007', frequency: 0.06866002 },
      { prefix: '98006', frequency: 0.058693245 },
      { prefix: '98005', frequency: 0.050941307 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.1295681 },
      { suffix: '1', frequency: 0.12292359 },
    ],
    formats: [
      { format: 'dddddddddd', frequency: 0.72425246 },
      { format: 'addddddddd', frequency: 0.07751938 },
      { format: 'dddddddddddd', frequency: 0.04761905 },
      { format: 'ddddddddd', frequency: 0.03433001 },
      { format: 'ddddddddddd', frequency: 0.018826135 },
      { format: 'adddddddd', frequency: 0.015503876 },
      { format: 'adddddddddd', frequency: 0.014396456 },
      { format: 'addddsddddd', frequency: 0.007751938 },
      { format: 'aaddddddd', frequency: 0.006644518 },
      { format: 'ddddddd', frequency: 0.004429679 },
      { format: 'aaadddaddddd', frequency: 0.004429679 },
      { format: 'dddddddddhdd', frequency: 0.004429679 },
      { format: 'ddddsdddddhdd', frequency: 0.004429679 },
      { format: 'aaddddddddd', frequency: 0.004429679 },
      { format: 'd', frequency: 0.0022148394 },
      { format: 'addddsdddddhdd', frequency: 0.0022148394 },
      { format: 'dddddddd', frequency: 0.0022148394 },
      { format: 'addddddddddhdd', frequency: 0.0022148394 },
      { format: 'ddddddddddddddd', frequency: 0.0022148394 },
      { format: 'dddddd', frequency: 0.0022148394 },
      { format: 'ddddd', frequency: 0.0022148394 },
      { format: 'aaaddddddddd', frequency: 0.0011074197 },
      { format: 'aaasaaaaaaaaa', frequency: 0.0011074197 },
      { format: 'addddddddddd', frequency: 0.0011074197 },
      { format: 'aaadddddd', frequency: 0.0011074197 },
      { format: 'a', frequency: 0.0011074197 },
      { format: 'aaaaaddddddd', frequency: 0.0011074197 },
      { format: 'aaaddddddd', frequency: 0.0011074197 },
      { format: 'aaaaaaddddddaaa', frequency: 0.0011074197 },
      { format: 'ddddsdddsddd', frequency: 0.0011074197 },
      { format: 'dddaddddd', frequency: 0.0011074197 },
      { format: 'adddddddddhdd', frequency: 0.0011074197 },
      { format: 'adddd', frequency: 0.0011074197 },
      { format: 'ddd', frequency: 0.0011074197 },
      { format: 'aaaaaa', frequency: 0.0011074197 },
    ],
  },
  {
    front_end_carrier_id: 158,
    prefixes: [
      { prefix: '1', frequency: 0.4180432 },
      { prefix: 'U', frequency: 0.13977128 },
      { prefix: '110', frequency: 0.13723 },
      { prefix: 'N', frequency: 0.06480305 },
      { prefix: '0', frequency: 0.059720457 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.1931385 },
      { suffix: '0', frequency: 0.13087675 },
      { suffix: '01', frequency: 0.12833545 },
      { suffix: '2', frequency: 0.12198221 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.38500634 },
      { format: 'adddddddd', frequency: 0.18551461 },
      { format: 'adddddddddd', frequency: 0.08259212 },
      { format: 'ddddddddddd', frequency: 0.07496823 },
      { format: 'dddddddd', frequency: 0.041931383 },
      { format: 'dddddddddd', frequency: 0.031766202 },
      { format: 'addddddddsdd', frequency: 0.031766202 },
      { format: 'aadddddddddd', frequency: 0.024142312 },
      { format: 'ddddddd', frequency: 0.019059721 },
      { format: 'dddddddddddd', frequency: 0.010165184 },
      { format: 'aaadddddd', frequency: 0.008894537 },
      { format: 'dddddddddsdd', frequency: 0.008894537 },
      { format: 'addddddddhdd', frequency: 0.007623888 },
      { format: 'dddddddddhdd', frequency: 0.007623888 },
      { format: 'addddddddd', frequency: 0.00635324 },
      { format: 'addddddd', frequency: 0.005082592 },
      { format: 'addddddddddd', frequency: 0.005082592 },
      { format: 'aaaddddddddd', frequency: 0.005082592 },
      { format: 'aaddddddd', frequency: 0.003811944 },
      { format: 'ddddd', frequency: 0.003811944 },
      { format: 'ddaddddddd', frequency: 0.003811944 },
      { format: 'aaaddddddd', frequency: 0.002541296 },
      { format: 'aaa', frequency: 0.002541296 },
      { format: 'aaddddddddd', frequency: 0.002541296 },
      { format: 'ddddddddda', frequency: 0.002541296 },
      { format: 'aaadddddddddddd', frequency: 0.001270648 },
      { format: 'd', frequency: 0.001270648 },
      { format: 'dddddd', frequency: 0.001270648 },
      { format: 'addaaadddddd', frequency: 0.001270648 },
      { format: 'aaaddddddsdd', frequency: 0.001270648 },
      { format: 'adaddddddddd', frequency: 0.001270648 },
      { format: 'dddddddddddddd', frequency: 0.001270648 },
      { format: 'aadaaaadd', frequency: 0.001270648 },
      { format: 'addddsddddd', frequency: 0.001270648 },
      { format: 'ddddddddddddd', frequency: 0.001270648 },
      { format: 'dddddaddddd', frequency: 0.001270648 },
      { format: 'dddddddddxddd', frequency: 0.001270648 },
      { format: 'xdddxsdddhdddd', frequency: 0.001270648 },
      { format: 'aaaaaaaa', frequency: 0.001270648 },
      { format: 'ddddddddsdd', frequency: 0.001270648 },
      { format: 'aaaddddd', frequency: 0.001270648 },
      { format: 'ddddddddddddsxax', frequency: 0.001270648 },
      { format: 'addddddddaaa', frequency: 0.001270648 },
      { format: 'daaddddddddsdd', frequency: 0.001270648 },
      { format: 'dddddddddddda', frequency: 0.001270648 },
      { format: 'aa', frequency: 0.001270648 },
      { format: 'adddd', frequency: 0.001270648 },
      { format: 'dddddaddd', frequency: 0.001270648 },
      { format: 'dddddddddddddddd', frequency: 0.001270648 },
      { format: 'ddd', frequency: 0.001270648 },
      { format: 'adddddddddda', frequency: 0.001270648 },
      { format: 'aaadddddddhdd', frequency: 0.001270648 },
      { format: 'ddddddddhdd', frequency: 0.001270648 },
      { format: 'aaaddddddddsdd', frequency: 0.001270648 },
    ],
  },
  {
    front_end_carrier_id: 159,
    prefixes: [
      { prefix: 'W28', frequency: 0.14945365 },
      { prefix: 'W27', frequency: 0.103630595 },
      { prefix: '101', frequency: 0.09728587 },
      { prefix: '0', frequency: 0.05816003 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.20162143 },
      { suffix: '1', frequency: 0.12548466 },
      { suffix: '00', frequency: 0.12336975 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.40606275 },
      { format: 'dddddddddddd', frequency: 0.115615085 },
      { format: 'ddddddddd', frequency: 0.08600634 },
      { format: 'dddddddddd', frequency: 0.069087066 },
      { format: 'adddddddd', frequency: 0.052167784 },
      { format: 'addddsddddd', frequency: 0.048642933 },
      { format: 'ddddddddddd', frequency: 0.038068384 },
      { format: 'aaddddddd', frequency: 0.020091645 },
      { format: 'adddddddddd', frequency: 0.018681707 },
      { format: 'addddddddddd', frequency: 0.0109270355 },
      { format: 'dddddddd', frequency: 0.01057455 },
      { format: 'adddddddddhdd', frequency: 0.009164611 },
      { format: 'ddddddddddddd', frequency: 0.008107156 },
      { format: 'ddddddd', frequency: 0.0070497002 },
      { format: 'aaadddaddddd', frequency: 0.006697215 },
      { format: 'aaaddddddd', frequency: 0.005287275 },
      { format: 'dddddddddhdd', frequency: 0.005287275 },
      { format: 'aaddddddddd', frequency: 0.00493479 },
      { format: 'aaaddddddddd', frequency: 0.0035248501 },
      { format: 'addddddd', frequency: 0.0035248501 },
      { format: 'addddsdddddhdd', frequency: 0.0031723653 },
      { format: 'ddddd', frequency: 0.0028198801 },
      { format: 'addddddddhdd', frequency: 0.0028198801 },
      { format: 'dddddd', frequency: 0.002467395 },
      { format: 'adadddaddddd', frequency: 0.0021149102 },
      { format: 'dddddddddddddd', frequency: 0.0021149102 },
      { format: 'adddddd', frequency: 0.0021149102 },
      { format: 'a', frequency: 0.0017624251 },
      { format: 'adddsddddd', frequency: 0.0017624251 },
      { format: 'ddddsdddddhdd', frequency: 0.0017624251 },
      { format: 'aaadddddddd', frequency: 0.0017624251 },
      { format: 'dddddddda', frequency: 0.0017624251 },
      { format: 'ddddddddaaaa', frequency: 0.0017624251 },
      { format: 'dddd', frequency: 0.0014099401 },
      { format: 'addddsdddd', frequency: 0.0014099401 },
      { format: 'dddddddhdddhddddd', frequency: 0.0014099401 },
      { format: 'ddddsddddd', frequency: 0.0014099401 },
      { format: 'adddd', frequency: 0.0014099401 },
      { format: 'ddddddddddddddd', frequency: 0.0014099401 },
      { format: 'dddddaddddd', frequency: 0.0010574551 },
      { format: 'd', frequency: 0.0010574551 },
      { format: 'addddhddddd', frequency: 0.0010574551 },
      { format: 'aa', frequency: 0.0010574551 },
    ],
  },
  {
    front_end_carrier_id: 160,
    prefixes: [
      { prefix: '10', frequency: 0.20876856 },
      { prefix: '109', frequency: 0.09991754 },
      { prefix: 'U8', frequency: 0.08452447 },
      { prefix: '9', frequency: 0.075041234 },
      { prefix: '00', frequency: 0.07064321 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22828478 },
      { suffix: '2', frequency: 0.1375756 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.28930733 },
      { format: 'adddddddddd', frequency: 0.23144585 },
      { format: 'adddddddd', frequency: 0.110500276 },
      { format: 'addddddddsdd', frequency: 0.0724299 },
      { format: 'ddddddddddd', frequency: 0.067344695 },
      { format: 'dddddddddddd', frequency: 0.05071468 },
      { format: 'dddddddd', frequency: 0.028174821 },
      { format: 'dddddddddd', frequency: 0.017592084 },
      { format: 'aaddddddd', frequency: 0.0125068715 },
      { format: 'dddddddddsdd', frequency: 0.012094557 },
      { format: 'aaadddddd', frequency: 0.010170423 },
      { format: 'dddddaddddd', frequency: 0.0076965364 },
      { format: 'aaaddddddddhdd', frequency: 0.0076965364 },
      { format: 'dddddddddhdd', frequency: 0.0068719075 },
      { format: 'aaadddddddd', frequency: 0.0057724025 },
      { format: 'ddddddddda', frequency: 0.0056349644 },
      { format: 'ddddddd', frequency: 0.005497526 },
      { format: 'aaaaddddd', frequency: 0.0050852117 },
      { format: 'addddddddhdd', frequency: 0.0039857063 },
      { format: 'aaaddddddd', frequency: 0.003573392 },
      { format: 'aaaddddddddd', frequency: 0.0034359538 },
      { format: 'addddddddd', frequency: 0.0031610776 },
      { format: 'aaddddddddd', frequency: 0.0030236393 },
      { format: 'aaadddddddddd', frequency: 0.002748763 },
      { format: 'dddddd', frequency: 0.0021990105 },
      { format: 'aadddddddddd', frequency: 0.0019241341 },
      { format: 'addddddddaad', frequency: 0.001786696 },
      { format: 'ddddddddddddd', frequency: 0.0016492578 },
      { format: 'addddddd', frequency: 0.0016492578 },
      { format: 'dddddddddddddd', frequency: 0.0015118197 },
      { format: 'aaddddddddddd', frequency: 0.0015118197 },
      { format: 'addddddddddd', frequency: 0.0012369434 },
    ],
  },
  {
    front_end_carrier_id: 162,
    prefixes: [
      { prefix: 'JH', frequency: 0.09750492 },
      { prefix: 'R61', frequency: 0.06762968 },
    ],
    suffixes: [
      { suffix: '2', frequency: 0.29908076 },
      { suffix: '1', frequency: 0.16152331 },
    ],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.45896256 },
      { format: 'adddddddd', frequency: 0.17925148 },
      { format: 'aaasddddddddd', frequency: 0.1126067 },
      { format: 'adaddddddddd', frequency: 0.06336179 },
      { format: 'ddddddddd', frequency: 0.053512804 },
      { format: 'aaadddddd', frequency: 0.030860145 },
      { format: 'aaadddaddddd', frequency: 0.025607353 },
      { format: 'adasddddddddd', frequency: 0.018384768 },
      { format: 'aaadddddddddddd', frequency: 0.013460276 },
      { format: 'adadddddd', frequency: 0.0068942877 },
      { format: 'aaaddddddddddd', frequency: 0.0052527906 },
      { format: 'adadddaddddd', frequency: 0.0052527906 },
      { format: 'aaaadddddddd', frequency: 0.0036112934 },
      { format: 'aaadddddddaa', frequency: 0.0029546947 },
      { format: 'asdsdsdsdsdsdsdsd', frequency: 0.0026263953 },
      { format: 'adadddddddddddd', frequency: 0.002298096 },
      { format: 'aaadddddddda', frequency: 0.0019697964 },
      { format: 'aaaaaddddddd', frequency: 0.0019697964 },
      { format: 'aaaaddddd', frequency: 0.001641497 },
      { format: 'adadddddddaa', frequency: 0.0013131977 },
    ],
  },
  {
    front_end_carrier_id: 163,
    prefixes: [
      { prefix: 'W28', frequency: 0.17159465 },
      { prefix: 'W27', frequency: 0.13242355 },
      { prefix: '101', frequency: 0.09596917 },
      { prefix: 'w', frequency: 0.051617388 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.19345464 },
      { suffix: '1', frequency: 0.12503159 },
      { suffix: '00', frequency: 0.11536518 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.5442886 },
      { format: 'dddddddddddd', frequency: 0.11789234 },
      { format: 'addddsddddd', frequency: 0.06058883 },
      { format: 'dddddddddd', frequency: 0.0567349 },
      { format: 'ddddddddd', frequency: 0.053196866 },
      { format: 'adddddddd', frequency: 0.022491787 },
      { format: 'ddddddddddd', frequency: 0.021165024 },
      { format: 'addddddddddd', frequency: 0.018511498 },
      { format: 'aaddddddd', frequency: 0.014341673 },
      { format: 'adddddddddhdd', frequency: 0.009540056 },
      { format: 'aaadddaddddd', frequency: 0.008592368 },
      { format: 'adddddddddd', frequency: 0.006444276 },
      { format: 'dddddddddhdd', frequency: 0.006002022 },
      { format: 'aaaddddddddd', frequency: 0.004422542 },
      { format: 'aaaddddddd', frequency: 0.0039171088 },
      { format: 'aaddddddddd', frequency: 0.0030957796 },
      { format: 'addddsdddddhdd', frequency: 0.0030326005 },
      { format: 'dddddddd', frequency: 0.0029062422 },
      { format: 'ddddddddddddd', frequency: 0.0024008087 },
      { format: 'dddddddda', frequency: 0.0023376294 },
      { format: 'ddddddddaaaa', frequency: 0.0020849127 },
      { format: 'adadddaddddd', frequency: 0.0020849127 },
      { format: 'aaadddddddd', frequency: 0.0018321961 },
      { format: 'adddddd', frequency: 0.0013899419 },
      { format: 'addddhddddd', frequency: 0.0013899419 },
      { format: 'addddddddhdd', frequency: 0.0012635835 },
      { format: 'addddddd', frequency: 0.0011372252 },
      { format: 'aaaaaddddddd', frequency: 0.0010108668 },
    ],
  },
  {
    front_end_carrier_id: 164,
    prefixes: [
      { prefix: '10', frequency: 0.09691161 },
      { prefix: '0', frequency: 0.076677315 },
      { prefix: 'U', frequency: 0.05111821 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.13844515 }],
    formats: [
      { format: 'dddddddddddd', frequency: 0.6794462 },
      { format: 'ddddddddd', frequency: 0.071352504 },
      { format: 'dddddddd', frequency: 0.06815761 },
      { format: 'ddddddddddd', frequency: 0.037273694 },
      { format: 'adddddddddd', frequency: 0.037273694 },
      { format: 'adddddddd', frequency: 0.034078807 },
      { format: 'aadddddddddd', frequency: 0.013844515 },
      { format: 'dddddddddd', frequency: 0.009584664 },
      { format: 'ddddddd', frequency: 0.008519702 },
      { format: 'addddddddsdd', frequency: 0.007454739 },
      { format: 'aaadddddd', frequency: 0.004259851 },
      { format: 'ddadddddd', frequency: 0.0031948881 },
      { format: 'ddddddddddddd', frequency: 0.0031948881 },
      { format: 'addddddddhdd', frequency: 0.0021299254 },
      { format: 'addaaadddddd', frequency: 0.0010649627 },
      { format: 'ddsdddhdddhdddd', frequency: 0.0010649627 },
      { format: 'aaadddd', frequency: 0.0010649627 },
      { format: 'dddhddhaaaa', frequency: 0.0010649627 },
      { format: 'dddddhaaaa', frequency: 0.0010649627 },
      { format: 'aaddddddd', frequency: 0.0010649627 },
      { format: 'aaadddddaddddd', frequency: 0.0010649627 },
      { format: 'ddddddddsdd', frequency: 0.0010649627 },
      { format: 'addddddddddd', frequency: 0.0010649627 },
      { format: 'd', frequency: 0.0010649627 },
      { format: 'ddddd', frequency: 0.0010649627 },
      { format: 'addddddddd', frequency: 0.0010649627 },
      { format: 'ddaddddddd', frequency: 0.0010649627 },
      { format: 'addddddddaaa', frequency: 0.0010649627 },
      { format: 'dddddaaaa', frequency: 0.0010649627 },
      { format: 'ddd', frequency: 0.0010649627 },
      { format: 'aaadddddddhdd', frequency: 0.0010649627 },
      { format: 'ddddddddda', frequency: 0.0010649627 },
      { format: 'aaaddddddddsdd', frequency: 0.0010649627 },
    ],
  },
  {
    front_end_carrier_id: 165,
    prefixes: [
      { prefix: '1', frequency: 0.54228854 },
      { prefix: '11', frequency: 0.28557214 },
      { prefix: '10', frequency: 0.23383084 },
      { prefix: '113', frequency: 0.16318408 },
      { prefix: 'U', frequency: 0.12736319 },
      { prefix: '110', frequency: 0.10945274 },
      { prefix: '109', frequency: 0.08855721 },
      { prefix: '1136', frequency: 0.08059701 },
      { prefix: '0', frequency: 0.05472637 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.2039801 },
      { suffix: '00', frequency: 0.19800995 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.3243781 },
      { format: 'ddddddddddd', frequency: 0.24676616 },
      { format: 'adddddddddd', frequency: 0.07462686 },
      { format: 'adddddddd', frequency: 0.07462686 },
      { format: 'dddddddd', frequency: 0.03482587 },
      { format: 'dddddddddd', frequency: 0.02885572 },
      { format: 'addddddddsdd', frequency: 0.025870647 },
      { format: 'aaaddddddddddd', frequency: 0.025870647 },
      { format: 'aadddddddddd', frequency: 0.021890547 },
      { format: 'ddddddd', frequency: 0.015920399 },
      { format: 'aaaddddddddd', frequency: 0.012935324 },
      { format: 'dddddddddddd', frequency: 0.010945274 },
      { format: 'dddddddddsdd', frequency: 0.007960199 },
      { format: 'dddddddddhdd', frequency: 0.0069651743 },
      { format: 'aaasddddddddddd', frequency: 0.0059701493 },
      { format: 'addddddddd', frequency: 0.0059701493 },
      { format: 'addddddd', frequency: 0.0059701493 },
      { format: 'aaddddddd', frequency: 0.0059701493 },
      { format: 'addddddddddd', frequency: 0.0049751243 },
      { format: 'aaadddddd', frequency: 0.0039800997 },
      { format: 'addddddddhdd', frequency: 0.0029850747 },
      { format: 'ddaddddddd', frequency: 0.0029850747 },
      { format: 'aaaddddddd', frequency: 0.0019900498 },
      { format: 'ddddddddddddd', frequency: 0.0019900498 },
      { format: 'aaaaaaaa', frequency: 0.0019900498 },
      { format: 'addddddddaaa', frequency: 0.0019900498 },
      { format: 'ddddd', frequency: 0.0019900498 },
      { format: 'ddddddddda', frequency: 0.0019900498 },
    ],
  },
  {
    front_end_carrier_id: 166,
    prefixes: [
      { prefix: '10', frequency: 0.21801358 },
      { prefix: '109', frequency: 0.10582623 },
      { prefix: 'U8', frequency: 0.08529709 },
      { prefix: '00', frequency: 0.07329767 },
      { prefix: '9', frequency: 0.06288853 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.2295793 },
      { suffix: '2', frequency: 0.13661993 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29246783 },
      { format: 'adddddddddd', frequency: 0.2305913 },
      { format: 'adddddddd', frequency: 0.10886222 },
      { format: 'addddddddsdd', frequency: 0.07503253 },
      { format: 'ddddddddddd', frequency: 0.064912535 },
      { format: 'dddddddddddd', frequency: 0.052045684 },
      { format: 'dddddddd', frequency: 0.026311984 },
      { format: 'dddddddddd', frequency: 0.01705942 },
      { format: 'dddddddddsdd', frequency: 0.012722278 },
      { format: 'aaddddddd', frequency: 0.012288565 },
      { format: 'aaadddddd', frequency: 0.010409137 },
      { format: 'dddddaddddd', frequency: 0.008240567 },
      { format: 'aaaddddddddhdd', frequency: 0.007951424 },
      { format: 'dddddddddhdd', frequency: 0.0070839957 },
      { format: 'ddddddddda', frequency: 0.0059274253 },
      { format: 'aaadddddddd', frequency: 0.0059274253 },
      { format: 'ddddddd', frequency: 0.0056382823 },
      { format: 'aaaaddddd', frequency: 0.0053491397 },
      { format: 'addddddddhdd', frequency: 0.0043371404 },
      { format: 'aaaddddddd', frequency: 0.0034697123 },
      { format: 'aaaddddddddd', frequency: 0.0031805695 },
      { format: 'addddddddd', frequency: 0.002891427 },
      { format: 'aaadddddddddd', frequency: 0.0027468556 },
      { format: 'dddddd', frequency: 0.0023131415 },
      { format: 'addddddddaad', frequency: 0.0020239989 },
      { format: 'aadddddddddd', frequency: 0.0018794275 },
      { format: 'ddddddddddddd', frequency: 0.0015902848 },
      { format: 'aaddddddddddd', frequency: 0.0015902848 },
      { format: 'addddddd', frequency: 0.0015902848 },
      { format: 'dddddddddddddd', frequency: 0.0014457135 },
      { format: 'aaaaadddddd', frequency: 0.0010119994 },
      { format: 'addddddddddd', frequency: 0.0010119994 },
    ],
  },
  {
    front_end_carrier_id: 167,
    prefixes: [
      { prefix: 'E1', frequency: 0.476 },
      { prefix: 'E11', frequency: 0.292 },
      { prefix: 'E111', frequency: 0.188 },
      { prefix: 'E10', frequency: 0.184 },
      { prefix: 'E110', frequency: 0.104 },
      { prefix: '10', frequency: 0.076 },
      { prefix: 'E109', frequency: 0.072 },
      { prefix: '0', frequency: 0.06 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.148 }],
    formats: [
      { format: 'adddddddd', frequency: 0.54 },
      { format: 'ddddddddd', frequency: 0.108 },
      { format: 'adddddddddd', frequency: 0.068 },
      { format: 'aaadddddddda', frequency: 0.04 },
      { format: 'addddddddhdd', frequency: 0.028 },
      { format: 'dddddddd', frequency: 0.024 },
      { format: 'addddddddsdd', frequency: 0.024 },
      { format: 'aaddddd', frequency: 0.02 },
      { format: 'dd', frequency: 0.016 },
      { format: 'dddddddddd', frequency: 0.016 },
      { format: 'ddddddddddd', frequency: 0.016 },
      { format: 'aaddddddd', frequency: 0.012 },
      { format: 'dddddddddddd', frequency: 0.008 },
      { format: 'aaaddddddd', frequency: 0.004 },
      { format: 'd', frequency: 0.004 },
      { format: 'aadddddddd', frequency: 0.004 },
      { format: 'ddddddddddddd', frequency: 0.004 },
      { format: 'dddd', frequency: 0.004 },
      { format: 'dddddddda', frequency: 0.004 },
      { format: 'adddddddsdd', frequency: 0.004 },
      { format: 'aaaddddddddd', frequency: 0.004 },
      { format: 'aaaddddddddhdd', frequency: 0.004 },
      { format: 'aadddd', frequency: 0.004 },
      { format: 'dddddddddhdd', frequency: 0.004 },
      { format: 'ddddd', frequency: 0.004 },
      { format: 'ddddddd', frequency: 0.004 },
      { format: 'aaadddddddhdd', frequency: 0.004 },
      { format: 'ddddddddda', frequency: 0.004 },
      { format: 'aaaddddddddsdd', frequency: 0.004 },
      { format: 'aadddddddddd', frequency: 0.004 },
      { format: 'addddddddddd', frequency: 0.004 },
      { format: 'aaadddddaddddd', frequency: 0.004 },
      { format: 'aaaadddddddd', frequency: 0.004 },
    ],
  },
  {
    front_end_carrier_id: 178,
    prefixes: [
      { prefix: 'AH', frequency: 0.22554348 },
      { prefix: 'ZZ', frequency: 0.21195652 },
      { prefix: 'U', frequency: 0.125 },
      { prefix: '10', frequency: 0.092391305 },
      { prefix: 'AH0', frequency: 0.08152174 },
      { prefix: 'ZZ05', frequency: 0.05706522 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.19565217 },
      { suffix: '01', frequency: 0.10869565 },
    ],
    formats: [
      { format: 'aaddddddd', frequency: 0.46195653 },
      { format: 'ddddddddd', frequency: 0.14402173 },
      { format: 'adddddddddd', frequency: 0.092391305 },
      { format: 'adddddddd', frequency: 0.07336956 },
      { format: 'dddddddddd', frequency: 0.035326086 },
      { format: 'ddddddddddd', frequency: 0.027173912 },
      { format: 'dddddddd', frequency: 0.024456521 },
      { format: 'addddddddsdd', frequency: 0.019021738 },
      { format: 'ddddddd', frequency: 0.013586956 },
      { format: 'addddddd', frequency: 0.010869565 },
      { format: 'aaaddddddddhdd', frequency: 0.008152174 },
      { format: 'aaddddddddd', frequency: 0.008152174 },
      { format: 'dddddddddddd', frequency: 0.008152174 },
      { format: 'dddddaddddd', frequency: 0.0054347827 },
      { format: 'addddddddhdd', frequency: 0.0054347827 },
      { format: 'dddddddddsdd', frequency: 0.0054347827 },
      { format: 'addddddddd', frequency: 0.0027173914 },
      { format: 'aaaddddddd', frequency: 0.0027173914 },
      { format: 'ddddddddddddd', frequency: 0.0027173914 },
      { format: 'adddddddsdd', frequency: 0.0027173914 },
      { format: 'daddddddddd', frequency: 0.0027173914 },
      { format: 'axa', frequency: 0.0027173914 },
      { format: 'addddd', frequency: 0.0027173914 },
      { format: 'aaaddddddddsdd', frequency: 0.0027173914 },
      { format: 'aadddddd', frequency: 0.0027173914 },
      { format: 'aaaaaaaa', frequency: 0.0027173914 },
      { format: 'aaaaddddd', frequency: 0.0027173914 },
      { format: 'addddddddaaa', frequency: 0.0027173914 },
      { format: 'dddddd', frequency: 0.0027173914 },
      { format: 'dddddddddhdd', frequency: 0.0027173914 },
      { format: 'aadddddddddd', frequency: 0.0027173914 },
      { format: 'aaadddddd', frequency: 0.0027173914 },
      { format: 'aaadddddddhdd', frequency: 0.0027173914 },
      { format: 'ddddddddda', frequency: 0.0027173914 },
      { format: 'ddddddddsdd', frequency: 0.0027173914 },
      { format: 'aadddddddsa', frequency: 0.0027173914 },
      { format: 'aaadddddaddddd', frequency: 0.0027173914 },
    ],
  },
  {
    front_end_carrier_id: 179,
    prefixes: [
      { prefix: 'ZZ', frequency: 0.44186047 },
      { prefix: 'AH', frequency: 0.179402 },
      { prefix: 'ZZ0', frequency: 0.13953489 },
      { prefix: 'ZZ05', frequency: 0.06312292 },
      { prefix: 'zz', frequency: 0.06312292 },
      { prefix: 'ZZ3', frequency: 0.059800666 },
      { prefix: 'AH0', frequency: 0.059800666 },
    ],
    suffixes: [{ suffix: '8', frequency: 0.1295681 }],
    formats: [
      { format: 'aaddddddd', frequency: 0.72425246 },
      { format: 'ddddddddd', frequency: 0.073089704 },
      { format: 'addddddddd', frequency: 0.059800666 },
      { format: 'adddddddd', frequency: 0.016611295 },
      { format: 'aaaddddddd', frequency: 0.013289036 },
      { format: 'dddddddddd', frequency: 0.009966778 },
      { format: 'ddddddd', frequency: 0.009966778 },
      { format: 'addddddddddd', frequency: 0.009966778 },
      { format: 'adddddddddd', frequency: 0.006644518 },
      { format: 'dddddddddddd', frequency: 0.006644518 },
      { format: 'adddddd', frequency: 0.006644518 },
      { format: 'dddddddd', frequency: 0.006644518 },
      { format: 'aaadddaddddd', frequency: 0.006644518 },
      { format: 'ddddddddddd', frequency: 0.006644518 },
      { format: 'aadddddddd', frequency: 0.003322259 },
      { format: 'ddddddddddddddd', frequency: 0.003322259 },
      { format: 'aadddddd', frequency: 0.003322259 },
      { format: 'addddddd', frequency: 0.003322259 },
      { format: 'ddd', frequency: 0.003322259 },
      { format: 'aaadddddd', frequency: 0.003322259 },
      { format: 'addddsddddd', frequency: 0.003322259 },
      { format: 'dddddaddddd', frequency: 0.003322259 },
      { format: 'dddsdddsddd', frequency: 0.003322259 },
      { format: 'ddddhdddd', frequency: 0.003322259 },
      { format: 'addddhddddd', frequency: 0.003322259 },
      { format: 'aaaddddddddd', frequency: 0.003322259 },
      { format: 'aadddddda', frequency: 0.003322259 },
    ],
  },
  {
    front_end_carrier_id: 180,
    prefixes: [
      { prefix: '1', frequency: 0.19158879 },
      { prefix: 'U', frequency: 0.14018692 },
      { prefix: '9', frequency: 0.11682243 },
      { prefix: '10', frequency: 0.098130845 },
      { prefix: '0', frequency: 0.098130845 },
      { prefix: '2', frequency: 0.088785045 },
      { prefix: '8', frequency: 0.060747664 },
      { prefix: '5', frequency: 0.05140187 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.18224299 },
      { suffix: '0', frequency: 0.12149533 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.60280377 },
      { format: 'adddddddddd', frequency: 0.093457945 },
      { format: 'adddddddd', frequency: 0.056074765 },
      { format: 'dddddddddd', frequency: 0.042056076 },
      { format: 'ddddddddddd', frequency: 0.042056076 },
      { format: 'dddddddd', frequency: 0.028037382 },
      { format: 'addddddddsdd', frequency: 0.023364486 },
      { format: 'dddddddddddd', frequency: 0.014018691 },
      { format: 'ddddddd', frequency: 0.014018691 },
      { format: 'addddddddhdd', frequency: 0.009345794 },
      { format: 'aaaaddddddd', frequency: 0.009345794 },
      { format: 'aaaddddddddhdd', frequency: 0.004672897 },
      { format: 'd', frequency: 0.004672897 },
      { format: 'dddddddddhdd', frequency: 0.004672897 },
      { format: 'ddddddddddddd', frequency: 0.004672897 },
      { format: 'aaddddddd', frequency: 0.004672897 },
      { format: 'aaaddddaadd', frequency: 0.004672897 },
      { format: 'aaadddddddhdd', frequency: 0.004672897 },
      { format: 'ddddddddda', frequency: 0.004672897 },
      { format: 'aaaddddddddsdd', frequency: 0.004672897 },
      { format: 'aadddddddddd', frequency: 0.004672897 },
      { format: 'addddddddddd', frequency: 0.004672897 },
      { format: 'aaadddddaddddd', frequency: 0.004672897 },
      { format: 'aaaaddddd', frequency: 0.004672897 },
      { format: 'aaaadddddd', frequency: 0.004672897 },
    ],
  },
  {
    front_end_carrier_id: 181,
    prefixes: [
      { prefix: '10', frequency: 0.29641694 },
      { prefix: 'W2', frequency: 0.2019544 },
      { prefix: '0', frequency: 0.078175895 },
      { prefix: '1000035', frequency: 0.06840391 },
      { prefix: '105f5ee', frequency: 0.06514658 },
      { prefix: 'W28', frequency: 0.058631923 },
      { prefix: 'G', frequency: 0.058631923 },
    ],
    suffixes: [{ suffix: '0', frequency: 0.15960912 }],
    formats: [
      { format: 'addddddddd', frequency: 0.17589577 },
      { format: 'dddddddddddd', frequency: 0.15960912 },
      { format: 'ddddddddd', frequency: 0.09771987 },
      { format: 'dddddddddd', frequency: 0.06514658 },
      { format: 'adddddd', frequency: 0.06514658 },
      { format: 'adddddddd', frequency: 0.055374593 },
      { format: 'ddddddddddd', frequency: 0.03257329 },
      { format: 'dddadaadddad', frequency: 0.022801302 },
      { format: 'adddddddddd', frequency: 0.022801302 },
      { format: 'addddsddddd', frequency: 0.019543974 },
      { format: 'ddddddd', frequency: 0.016286645 },
      { format: 'aaddddddd', frequency: 0.016286645 },
      { format: 'dddadaadadad', frequency: 0.016286645 },
      { format: 'dddadaaddddd', frequency: 0.0130293155 },
      { format: 'dddadaaaaddd', frequency: 0.0130293155 },
      { format: 'aaadddaddddd', frequency: 0.009771987 },
      { format: 'ddddsdddddhdd', frequency: 0.009771987 },
      { format: 'dddddddd', frequency: 0.009771987 },
      { format: 'ddddadaadddd', frequency: 0.0065146578 },
      { format: 'aaaddddddddd', frequency: 0.0065146578 },
      { format: 'dddadaadadaa', frequency: 0.0065146578 },
      { format: 'dddadaadaddd', frequency: 0.0065146578 },
      { format: 'ddddd', frequency: 0.0065146578 },
      { format: 'aaddddddddd', frequency: 0.0065146578 },
      { format: 'dddadaaaadaa', frequency: 0.0065146578 },
      { format: 'aaadddddd', frequency: 0.0065146578 },
      { format: 'addddsdddddhdd', frequency: 0.0065146578 },
      { format: 'addddddddddhdd', frequency: 0.0065146578 },
      { format: 'ddddadaadadd', frequency: 0.0065146578 },
      { format: 'dddadaaaddad', frequency: 0.0065146578 },
      { format: 'dddddddddhdd', frequency: 0.0065146578 },
      { format: 'dddadaaaddda', frequency: 0.0065146578 },
      { format: 'dddadaaadddd', frequency: 0.0065146578 },
      { format: 'dddadaadadda', frequency: 0.0065146578 },
      { format: 'aaaddddddd', frequency: 0.0065146578 },
      { format: 'ddddadaaddaa', frequency: 0.0032573289 },
      { format: 'addddddddddd', frequency: 0.0032573289 },
      { format: 'ddddddddddddddd', frequency: 0.0032573289 },
      { format: 'dddaddddd', frequency: 0.0032573289 },
      { format: 'adddddddddhdd', frequency: 0.0032573289 },
      { format: 'addddddd', frequency: 0.0032573289 },
      { format: 'dddddd', frequency: 0.0032573289 },
      { format: 'dddadaaaadda', frequency: 0.0032573289 },
      { format: 'ddddadaadada', frequency: 0.0032573289 },
      { format: 'ddddadaaaddd', frequency: 0.0032573289 },
      { format: 'dddadaaaddaa', frequency: 0.0032573289 },
      { format: 'adddd', frequency: 0.0032573289 },
      { format: 'ddd', frequency: 0.0032573289 },
      { format: 'aaadddddddda', frequency: 0.0032573289 },
      { format: 'aaaaaadddddd', frequency: 0.0032573289 },
      { format: 'ddddadaaddda', frequency: 0.0032573289 },
      { format: 'dddddadaadad', frequency: 0.0032573289 },
      { format: 'addaa', frequency: 0.0032573289 },
      { format: 'dddadaaaadad', frequency: 0.0032573289 },
      { format: 'adadddddddddddd', frequency: 0.0032573289 },
      { format: 'asdddddddddd', frequency: 0.0032573289 },
    ],
  },
  {
    front_end_carrier_id: 182,
    prefixes: [
      { prefix: '1', frequency: 0.52572346 },
      { prefix: '110', frequency: 0.16237941 },
      { prefix: 'U', frequency: 0.14630225 },
      { prefix: '0', frequency: 0.059485532 },
      { prefix: '1103', frequency: 0.051446944 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.19614148 },
      { suffix: '0', frequency: 0.13022508 },
      { suffix: '01', frequency: 0.12540193 },
      { suffix: '2', frequency: 0.1221865 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4421222 },
      { format: 'adddddddddd', frequency: 0.08842444 },
      { format: 'adddddddd', frequency: 0.085209005 },
      { format: 'ddddddddddd', frequency: 0.073954985 },
      { format: 'dddddddddddd', frequency: 0.048231512 },
      { format: 'dddddddd', frequency: 0.040192924 },
      { format: 'dddddddddd', frequency: 0.033762056 },
      { format: 'addddddddsdd', frequency: 0.030546624 },
      { format: 'aadddddddddd', frequency: 0.020900322 },
      { format: 'ddddddd', frequency: 0.017684888 },
      { format: 'dddddddddsdd', frequency: 0.011254019 },
      { format: 'addddddddd', frequency: 0.008038585 },
      { format: 'dddddddddhdd', frequency: 0.008038585 },
      { format: 'addddddd', frequency: 0.006430868 },
      { format: 'aaddddddd', frequency: 0.006430868 },
      { format: 'aaaddddddddd', frequency: 0.006430868 },
      { format: 'aaadddddd', frequency: 0.004823151 },
      { format: 'ddaddddddd', frequency: 0.004823151 },
      { format: 'addddddddhdd', frequency: 0.003215434 },
      { format: 'addddddddddd', frequency: 0.003215434 },
      { format: 'ddddddddda', frequency: 0.003215434 },
      { format: 'adddddd', frequency: 0.001607717 },
      { format: 'aaaddddddd', frequency: 0.001607717 },
      { format: 'aaadddddddddddd', frequency: 0.001607717 },
      { format: 'ddddddddddddd', frequency: 0.001607717 },
      { format: 'dddddd', frequency: 0.001607717 },
      { format: 'ddddadaaaaad', frequency: 0.001607717 },
      { format: 'adaddddddddd', frequency: 0.001607717 },
      { format: 'dddddddddddddd', frequency: 0.001607717 },
      { format: 'aadaaaadd', frequency: 0.001607717 },
      { format: 'addddsddddd', frequency: 0.001607717 },
      { format: 'aaaddddddddhdd', frequency: 0.001607717 },
      { format: 'dddddaddddd', frequency: 0.001607717 },
      { format: 'dddddddddxddd', frequency: 0.001607717 },
      { format: 'ddddadaadada', frequency: 0.001607717 },
      { format: 'xdddxsdddhdddd', frequency: 0.001607717 },
      { format: 'aaaaaaaa', frequency: 0.001607717 },
      { format: 'aaddddddddd', frequency: 0.001607717 },
      { format: 'dddadaaddddd', frequency: 0.001607717 },
      { format: 'aaaddddd', frequency: 0.001607717 },
      { format: 'ddddddddddddsxax', frequency: 0.001607717 },
      { format: 'adddddddsdd', frequency: 0.001607717 },
      { format: 'aaa', frequency: 0.001607717 },
      { format: 'addddddddaaa', frequency: 0.001607717 },
      { format: 'daaddddddddsdd', frequency: 0.001607717 },
      { format: 'dddddddddddda', frequency: 0.001607717 },
      { format: 'aa', frequency: 0.001607717 },
      { format: 'adddd', frequency: 0.001607717 },
      { format: 'dddddaddd', frequency: 0.001607717 },
      { format: 'ddddd', frequency: 0.001607717 },
      { format: 'dddddddddddddddd', frequency: 0.001607717 },
      { format: 'adddddddddda', frequency: 0.001607717 },
      { format: 'aaadddddddhdd', frequency: 0.001607717 },
      { format: 'aaaddddddddsdd', frequency: 0.001607717 },
    ],
  },
  {
    front_end_carrier_id: 183,
    prefixes: [
      { prefix: '109', frequency: 0.08901252 },
      { prefix: '110', frequency: 0.08066759 },
      { prefix: 'N32', frequency: 0.053546593 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.20027816 },
      { suffix: '01', frequency: 0.13699582 },
      { suffix: '0', frequency: 0.12656467 },
      { suffix: '2', frequency: 0.12517385 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.3518776 },
      { format: 'ddddddddddd', frequency: 0.12308762 },
      { format: 'adddddddd', frequency: 0.11891516 },
      { format: 'adddddddddd', frequency: 0.08553547 },
      { format: 'dddddddddddd', frequency: 0.04450626 },
      { format: 'dddddddd', frequency: 0.041029207 },
      { format: 'dddddddddd', frequency: 0.034770515 },
      { format: 'addddddddsdd', frequency: 0.025730181 },
      { format: 'aadddddddddd', frequency: 0.018776078 },
      { format: 'ddddddd', frequency: 0.018080669 },
      { format: 'aaddddddd', frequency: 0.012517385 },
      { format: 'dddddddddsdd', frequency: 0.009735744 },
      { format: 'aaadddddd', frequency: 0.008344923 },
      { format: 'dddddddddhdd', frequency: 0.0076495134 },
      { format: 'aaaddddddddd', frequency: 0.006954103 },
      { format: 'ddddddddda', frequency: 0.006954103 },
      { format: 'addddddddd', frequency: 0.004867872 },
      { format: 'dddddd', frequency: 0.004867872 },
      { format: 'aaadddddddd', frequency: 0.0041724616 },
      { format: 'dddddaddddd', frequency: 0.0041724616 },
      { format: 'aaaddddddddhdd', frequency: 0.0041724616 },
      { format: 'ddaddddddd', frequency: 0.0027816412 },
      { format: 'addddddddhdd', frequency: 0.0027816412 },
      { format: 'addddddddddd', frequency: 0.0027816412 },
      { format: 'aaddddddddd', frequency: 0.0027816412 },
      { format: 'aaadddddddddd', frequency: 0.0027816412 },
      { format: 'addddddddaad', frequency: 0.0027816412 },
      { format: 'addddddd', frequency: 0.0020862308 },
      { format: 'aaaaddddd', frequency: 0.0020862308 },
      { format: 'aaaddddddd', frequency: 0.0020862308 },
      { format: 'ddddd', frequency: 0.0020862308 },
      { format: 'aaadddddddddddd', frequency: 0.0013908206 },
      { format: 'aaaaadddddd', frequency: 0.0013908206 },
      { format: 'dddddddddddddd', frequency: 0.0013908206 },
      { format: 'ddddddddsdd', frequency: 0.0013908206 },
      { format: 'aadddddddd', frequency: 0.0013908206 },
      { format: 'aaddddddddddd', frequency: 0.0013908206 },
      { format: 'aaaaddddddd', frequency: 0.0013908206 },
      { format: 'aaaddddddddsdd', frequency: 0.0013908206 },
    ],
  },
  {
    front_end_carrier_id: 184,
    prefixes: [
      { prefix: '10', frequency: 0.21628207 },
      { prefix: '109', frequency: 0.10319622 },
      { prefix: 'U8', frequency: 0.08485022 },
      { prefix: '00', frequency: 0.07295399 },
      { prefix: '9', frequency: 0.06191773 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22674502 },
      { suffix: '2', frequency: 0.13931489 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29138598 },
      { format: 'adddddddddd', frequency: 0.23391142 },
      { format: 'adddddddd', frequency: 0.111652575 },
      { format: 'addddddddsdd', frequency: 0.075103916 },
      { format: 'ddddddddddd', frequency: 0.062347714 },
      { format: 'dddddddddddd', frequency: 0.051884763 },
      { format: 'dddddddd', frequency: 0.02680235 },
      { format: 'dddddddddd', frequency: 0.016912714 },
      { format: 'aaddddddd', frequency: 0.013042855 },
      { format: 'dddddddddsdd', frequency: 0.011609575 },
      { format: 'aaadddddd', frequency: 0.01046295 },
      { format: 'dddddaddddd', frequency: 0.008026373 },
      { format: 'aaaddddddddhdd', frequency: 0.007883044 },
      { format: 'dddddddddhdd', frequency: 0.0067364196 },
      { format: 'aaadddddddd', frequency: 0.0058764513 },
      { format: 'ddddddd', frequency: 0.005589795 },
      { format: 'ddddddddda', frequency: 0.005589795 },
      { format: 'aaaaddddd', frequency: 0.0051598107 },
      { format: 'addddddddhdd', frequency: 0.0037265301 },
      { format: 'aaaddddddd', frequency: 0.003439874 },
      { format: 'aaaddddddddd', frequency: 0.0032965457 },
      { format: 'addddddddd', frequency: 0.0030098897 },
      { format: 'aaadddddddddd', frequency: 0.0028665615 },
      { format: 'dddddd', frequency: 0.0022932494 },
      { format: 'addddddddaad', frequency: 0.0020065932 },
      { format: 'aadddddddddd', frequency: 0.0018632651 },
      { format: 'addddddd', frequency: 0.001719937 },
      { format: 'aaddddddddddd', frequency: 0.0015766089 },
      { format: 'dddddddddddddd', frequency: 0.0014332808 },
      { format: 'ddddddddddddd', frequency: 0.0014332808 },
      { format: 'aaaaadddddd', frequency: 0.0010032966 },
      { format: 'addddddddddd', frequency: 0.0010032966 },
    ],
  },
  {
    front_end_carrier_id: 185,
    prefixes: [
      { prefix: '10', frequency: 0.21133429 },
      { prefix: '109', frequency: 0.10057389 },
      { prefix: 'U8', frequency: 0.08608321 },
      { prefix: '00', frequency: 0.07245337 },
      { prefix: '9', frequency: 0.06384505 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.23271163 },
      { suffix: '2', frequency: 0.1368723 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.28593975 },
      { format: 'adddddddddd', frequency: 0.23314203 },
      { format: 'adddddddd', frequency: 0.11248206 },
      { format: 'addddddddsdd', frequency: 0.07546628 },
      { format: 'ddddddddddd', frequency: 0.063271165 },
      { format: 'dddddddddddd', frequency: 0.05236729 },
      { format: 'dddddddd', frequency: 0.026542325 },
      { format: 'dddddddddd', frequency: 0.0169297 },
      { format: 'aaddddddd', frequency: 0.013342898 },
      { format: 'dddddddddsdd', frequency: 0.013199426 },
      { format: 'aaadddddd', frequency: 0.010329986 },
      { format: 'aaaddddddddhdd', frequency: 0.008034433 },
      { format: 'dddddaddddd', frequency: 0.007890961 },
      { format: 'dddddddddhdd', frequency: 0.0068866573 },
      { format: 'aaadddddddd', frequency: 0.005882353 },
      { format: 'ddddddddda', frequency: 0.005738881 },
      { format: 'ddddddd', frequency: 0.005595409 },
      { format: 'aaaaddddd', frequency: 0.0053084646 },
      { format: 'addddddddhdd', frequency: 0.004304161 },
      { format: 'aaaddddddd', frequency: 0.0034433287 },
      { format: 'aaaddddddddd', frequency: 0.0032998566 },
      { format: 'addddddddd', frequency: 0.0031563845 },
      { format: 'aaadddddddddd', frequency: 0.0027259684 },
      { format: 'dddddd', frequency: 0.0021520804 },
      { format: 'addddddddaad', frequency: 0.0020086083 },
      { format: 'aadddddddddd', frequency: 0.0018651363 },
      { format: 'addddddd', frequency: 0.0017216643 },
      { format: 'ddddddddddddd', frequency: 0.0015781922 },
      { format: 'aaddddddddddd', frequency: 0.0015781922 },
      { format: 'dddddddddddddd', frequency: 0.0012912482 },
      { format: 'aaaaadddddd', frequency: 0.0010043042 },
      { format: 'addddddddddd', frequency: 0.0010043042 },
    ],
  },
  {
    front_end_carrier_id: 186,
    prefixes: [
      { prefix: 'JQ', frequency: 0.14150943 },
      { prefix: 'X', frequency: 0.13836478 },
      { prefix: 'C', frequency: 0.059355345 },
      { prefix: 'JQO', frequency: 0.055031445 },
      { prefix: 'N', frequency: 0.055031445 },
      { prefix: 'XDP', frequency: 0.054245282 },
      { prefix: 'JQU', frequency: 0.052279875 },
    ],
    suffixes: [],
    formats: [
      { format: 'aaadddaddddd', frequency: 0.5235849 },
      { format: 'aaaddddddddd', frequency: 0.09512579 },
      { format: 'aaadddddddda', frequency: 0.07822327 },
      { format: 'aaaaaddddddd', frequency: 0.060927674 },
      { format: 'adadddaddddd', frequency: 0.040880505 },
      { format: 'adddddddd', frequency: 0.036556605 },
      { format: 'aaddddddd', frequency: 0.02476415 },
      { format: 'aaadddddddaa', frequency: 0.023584906 },
      { format: 'dddaddddd', frequency: 0.019261006 },
      { format: 'daddddddd', frequency: 0.013757861 },
      { format: 'aaaadddddddd', frequency: 0.011006289 },
      { format: 'aaadddddddddddd', frequency: 0.008254717 },
      { format: 'adadddddddaa', frequency: 0.008254717 },
      { format: 'adaddddddddd', frequency: 0.0058962265 },
      { format: 'dddddddda', frequency: 0.0055031446 },
      { format: 'adadddddddddddd', frequency: 0.0043238993 },
      { format: 'aaadddddddddd', frequency: 0.003930818 },
      { format: 'adaaaddddddd', frequency: 0.003930818 },
      { format: 'aaadddddddad', frequency: 0.003537736 },
      { format: 'aaasaaddddddd', frequency: 0.003144654 },
      { format: 'aaaddddddddddd', frequency: 0.0027515723 },
      { format: 'dddddddaa', frequency: 0.0023584906 },
      { format: 'ddddddddd', frequency: 0.001965409 },
      { format: 'adaadddddddd', frequency: 0.001965409 },
      { format: 'adasaaddddddd', frequency: 0.001572327 },
      { format: 'aaasdddaddddd', frequency: 0.0011792453 },
      { format: 'aaadddaddddddd', frequency: 0.0011792453 },
      { format: 'adadddddddda', frequency: 0.0011792453 },
      { format: 'adaaaadddddd', frequency: 0.0011792453 },
    ],
  },
  {
    front_end_carrier_id: 187,
    prefixes: [
      { prefix: 'LWE8', frequency: 0.17224081 },
      { prefix: 'LWE80', frequency: 0.09698997 },
      { prefix: 'SCY8', frequency: 0.09531773 },
      { prefix: 'P', frequency: 0.09030101 },
      { prefix: 'RGN8', frequency: 0.068561874 },
      { prefix: 'U', frequency: 0.053511705 },
    ],
    suffixes: [{ suffix: '5', frequency: 0.122073576 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.87458193 },
      { format: 'adaddddddddd', frequency: 0.06521739 },
      { format: 'adddddddd', frequency: 0.015050167 },
      { format: 'aaadddddddddddd', frequency: 0.011705685 },
      { format: 'aaadddaddddd', frequency: 0.008361204 },
      { format: 'aaaadddddddd', frequency: 0.0050167223 },
      { format: 'adadddaddddd', frequency: 0.0050167223 },
      { format: 'aaasddddddddd', frequency: 0.0033444816 },
      { format: 'adadddddddddddd', frequency: 0.0016722408 },
      { format: 'adaddddddddddd', frequency: 0.0016722408 },
      { format: 'aaaadddadddd', frequency: 0.0016722408 },
      { format: 'aaadddddddddd', frequency: 0.0016722408 },
      { format: 'aaassaaddddddd', frequency: 0.0016722408 },
      { format: 'aaaaaddddddd', frequency: 0.0016722408 },
      { format: 'aaadddddddda', frequency: 0.0016722408 },
    ],
  },
  {
    front_end_carrier_id: 191,
    prefixes: [
      { prefix: '10', frequency: 0.21561822 },
      { prefix: '109', frequency: 0.10310918 },
      { prefix: 'U8', frequency: 0.08445408 },
      { prefix: '00', frequency: 0.072595805 },
      { prefix: '9', frequency: 0.063051336 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22704266 },
      { suffix: '2', frequency: 0.13911787 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29067245 },
      { format: 'adddddddddd', frequency: 0.23036876 },
      { format: 'adddddddd', frequency: 0.11265365 },
      { format: 'addddddddsdd', frequency: 0.07317426 },
      { format: 'ddddddddddd', frequency: 0.06319595 },
      { format: 'dddddddddddd', frequency: 0.052494578 },
      { format: 'dddddddd', frequency: 0.028344179 },
      { format: 'dddddddddd', frequency: 0.017064353 },
      { format: 'aaddddddd', frequency: 0.013015185 },
      { format: 'dddddddddsdd', frequency: 0.011858279 },
      { format: 'aaadddddd', frequency: 0.010267534 },
      { format: 'dddddaddddd', frequency: 0.008098337 },
      { format: 'aaaddddddddhdd', frequency: 0.0078091105 },
      { format: 'dddddddddhdd', frequency: 0.0070860446 },
      { format: 'ddddddddda', frequency: 0.0059291394 },
      { format: 'aaadddddddd', frequency: 0.0057845265 },
      { format: 'ddddddd', frequency: 0.005639913 },
      { format: 'aaaaddddd', frequency: 0.0052060736 },
      { format: 'addddddddhdd', frequency: 0.0040491684 },
      { format: 'aaaddddddd', frequency: 0.0034707158 },
      { format: 'aaaddddddddd', frequency: 0.0033261026 },
      { format: 'addddddddd', frequency: 0.0031814894 },
      { format: 'aaadddddddddd', frequency: 0.0028922632 },
      { format: 'dddddd', frequency: 0.0023138106 },
      { format: 'aadddddddddd', frequency: 0.0018799711 },
      { format: 'addddddddaad', frequency: 0.0017353579 },
      { format: 'addddddd', frequency: 0.0017353579 },
      { format: 'ddddddddddddd', frequency: 0.0015907447 },
      { format: 'aaddddddddddd', frequency: 0.0015907447 },
      { format: 'dddddddddddddd', frequency: 0.0014461316 },
      { format: 'aaaaadddddd', frequency: 0.0010122921 },
      { format: 'addddddddddd', frequency: 0.0010122921 },
    ],
  },
  {
    front_end_carrier_id: 192,
    prefixes: [
      { prefix: '10', frequency: 0.21612671 },
      { prefix: '109', frequency: 0.10611951 },
      { prefix: 'U8', frequency: 0.08668107 },
      { prefix: '00', frequency: 0.0725702 },
      { prefix: '9', frequency: 0.06306695 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22879769 },
      { suffix: '2', frequency: 0.13808495 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29013678 },
      { format: 'adddddddddd', frequency: 0.23110151 },
      { format: 'adddddddd', frequency: 0.11231101 },
      { format: 'addddddddsdd', frequency: 0.0763139 },
      { format: 'ddddddddddd', frequency: 0.06263499 },
      { format: 'dddddddddddd', frequency: 0.05226782 },
      { format: 'dddddddd', frequency: 0.026061915 },
      { format: 'dddddddddd', frequency: 0.016702663 },
      { format: 'dddddddddsdd', frequency: 0.012958963 },
      { format: 'aaddddddd', frequency: 0.012526998 },
      { format: 'aaadddddd', frequency: 0.010367171 },
      { format: 'dddddaddddd', frequency: 0.0080633545 },
      { format: 'aaaddddddddhdd', frequency: 0.0079193665 },
      { format: 'dddddddddhdd', frequency: 0.007199424 },
      { format: 'ddddddddda', frequency: 0.0059035276 },
      { format: 'aaadddddddd', frequency: 0.0059035276 },
      { format: 'ddddddd', frequency: 0.0056155506 },
      { format: 'aaaaddddd', frequency: 0.005327574 },
      { format: 'addddddddhdd', frequency: 0.0040316773 },
      { format: 'aaaddddddd', frequency: 0.0034557236 },
      { format: 'aaaddddddddd', frequency: 0.0031677466 },
      { format: 'addddddddd', frequency: 0.003023758 },
      { format: 'aaadddddddddd', frequency: 0.0027357813 },
      { format: 'dddddd', frequency: 0.0021598272 },
      { format: 'addddddddaad', frequency: 0.0020158386 },
      { format: 'aadddddddddd', frequency: 0.0018718502 },
      { format: 'addddddd', frequency: 0.0017278618 },
      { format: 'ddddddddddddd', frequency: 0.0015838733 },
      { format: 'aaddddddddddd', frequency: 0.0015838733 },
      { format: 'dddddddddddddd', frequency: 0.0012958964 },
      { format: 'addddddddddd', frequency: 0.0011519078 },
      { format: 'aaaaadddddd', frequency: 0.0010079193 },
    ],
  },
  {
    front_end_carrier_id: 193,
    prefixes: [
      { prefix: '10', frequency: 0.2175893 },
      { prefix: '109', frequency: 0.106161945 },
      { prefix: 'U8', frequency: 0.08353493 },
      { prefix: '00', frequency: 0.07357336 },
      { prefix: '9', frequency: 0.061192542 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22313932 },
      { suffix: '2', frequency: 0.1381813 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.28973958 },
      { format: 'adddddddddd', frequency: 0.22997011 },
      { format: 'adddddddd', frequency: 0.11171197 },
      { format: 'addddddddsdd', frequency: 0.07343105 },
      { format: 'ddddddddddd', frequency: 0.06403871 },
      { format: 'dddddddddddd', frequency: 0.05251174 },
      { format: 'dddddddd', frequency: 0.028034722 },
      { format: 'dddddddddd', frequency: 0.018642379 },
      { format: 'dddddddddsdd', frequency: 0.013234667 },
      { format: 'aaddddddd', frequency: 0.012807742 },
      { format: 'aaadddddd', frequency: 0.01053081 },
      { format: 'dddddaddddd', frequency: 0.00811157 },
      { format: 'aaaddddddddhdd', frequency: 0.007684645 },
      { format: 'dddddddddhdd', frequency: 0.0068307957 },
      { format: 'aaadddddddd', frequency: 0.0058346377 },
      { format: 'ddddddddda', frequency: 0.0056923297 },
      { format: 'ddddddd', frequency: 0.0055500213 },
      { format: 'aaaaddddd', frequency: 0.005265405 },
      { format: 'addddddddhdd', frequency: 0.0038423224 },
      { format: 'aaaddddddd', frequency: 0.0034153978 },
      { format: 'aaaddddddddd', frequency: 0.0032730894 },
      { format: 'addddddddd', frequency: 0.0028461649 },
      { format: 'aaadddddddddd', frequency: 0.0028461649 },
      { format: 'dddddd', frequency: 0.0021346237 },
      { format: 'addddddddaad', frequency: 0.0019923153 },
      { format: 'aadddddddddd', frequency: 0.0018500071 },
      { format: 'ddddddddddddd', frequency: 0.0015653906 },
      { format: 'aaddddddddddd', frequency: 0.0015653906 },
      { format: 'addddddd', frequency: 0.0015653906 },
      { format: 'dddddddddddddd', frequency: 0.0012807741 },
    ],
  },
  {
    front_end_carrier_id: 194,
    prefixes: [
      { prefix: 'W2', frequency: 0.2553018 },
      { prefix: '10', frequency: 0.080750406 },
      { prefix: 'W28', frequency: 0.07667211 },
      { prefix: '00', frequency: 0.07014682 },
      { prefix: '9', frequency: 0.064437196 },
      { prefix: 'W27', frequency: 0.05791191 },
      { prefix: '2', frequency: 0.052202284 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16313213 },
      { suffix: '1', frequency: 0.12071778 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.23327896 },
      { format: 'ddddddddd', frequency: 0.13050571 },
      { format: 'dddddddddd', frequency: 0.10766721 },
      { format: 'dddddddddddd', frequency: 0.079934746 },
      { format: 'adddddddd', frequency: 0.07748777 },
      { format: 'ddddddddddd', frequency: 0.06035889 },
      { format: 'aaddddddd', frequency: 0.039967373 },
      { format: 'addddsddddd', frequency: 0.030179445 },
      { format: 'adddddddddd', frequency: 0.0228385 },
      { format: 'addddddddddd', frequency: 0.0146818925 },
      { format: 'dddddddd', frequency: 0.0146818925 },
      { format: 'aaaddddddd', frequency: 0.01223491 },
      { format: 'aaddddddddd', frequency: 0.01141925 },
      { format: 'dddddddddhdd', frequency: 0.010603589 },
      { format: 'ddddddd', frequency: 0.009787928 },
      { format: 'aaadddaddddd', frequency: 0.009787928 },
      { format: 'aaaddddddddd', frequency: 0.0065252855 },
      { format: 'ddddsdddddhdd', frequency: 0.005709625 },
      { format: 'ddddddddddddd', frequency: 0.005709625 },
      { format: 'adddddd', frequency: 0.004893964 },
      { format: 'ddddd', frequency: 0.004893964 },
      { format: 'addddddddhdd', frequency: 0.0040783035 },
      { format: 'dddddd', frequency: 0.0040783035 },
      { format: 'dddddddddddddd', frequency: 0.0032626428 },
      { format: 'addddddd', frequency: 0.0032626428 },
      { format: 'dddddaddddd', frequency: 0.0032626428 },
      { format: 'adddddddddhdd', frequency: 0.0032626428 },
      { format: 'aaadddddddd', frequency: 0.0032626428 },
      { format: 'adddd', frequency: 0.0032626428 },
      { format: 'dddddddda', frequency: 0.002446982 },
      { format: 'ddddddddddddddd', frequency: 0.002446982 },
      { format: 'dddddsdddd', frequency: 0.002446982 },
      { format: 'aa', frequency: 0.002446982 },
      { format: 'aaaaaaaa', frequency: 0.002446982 },
      { format: 'aaadddddd', frequency: 0.002446982 },
      { format: 'adadddaddddd', frequency: 0.002446982 },
      { format: 'aaaddddaaaa', frequency: 0.0016313214 },
      { format: 'aaddddda', frequency: 0.0016313214 },
      { format: 'dddd', frequency: 0.0016313214 },
      { format: 'ddddhdddd', frequency: 0.0016313214 },
      { format: 'aaaaddddddd', frequency: 0.0016313214 },
      { format: 'adaddddddddd', frequency: 0.0016313214 },
      { format: 'dddhddhdddd', frequency: 0.0016313214 },
      { format: 'aaadddddddaa', frequency: 0.0016313214 },
    ],
  },
  {
    front_end_carrier_id: 198,
    prefixes: [
      { prefix: '1', frequency: 0.45931035 },
      { prefix: '110', frequency: 0.15172414 },
      { prefix: 'U', frequency: 0.14758621 },
      { prefix: '0', frequency: 0.066206895 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.19448276 },
      { suffix: '0', frequency: 0.13241379 },
      { suffix: '01', frequency: 0.12827586 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4289655 },
      { format: 'adddddddd', frequency: 0.12137931 },
      { format: 'adddddddddd', frequency: 0.08551724 },
      { format: 'ddddddddddd', frequency: 0.08275862 },
      { format: 'dddddddd', frequency: 0.04 },
      { format: 'dddddddddd', frequency: 0.03448276 },
      { format: 'aadddddddddd', frequency: 0.028965518 },
      { format: 'addddddddsdd', frequency: 0.028965518 },
      { format: 'ddddddd', frequency: 0.020689655 },
      { format: 'dddddddddddd', frequency: 0.012413793 },
      { format: 'dddddddddsdd', frequency: 0.011034483 },
      { format: 'addddddddd', frequency: 0.0068965517 },
      { format: 'addddddddddd', frequency: 0.0068965517 },
      { format: 'dddddddddhdd', frequency: 0.0068965517 },
      { format: 'addddddd', frequency: 0.0068965517 },
      { format: 'aaddddddd', frequency: 0.0068965517 },
      { format: 'aaadddddd', frequency: 0.0055172415 },
      { format: 'ddaddddddd', frequency: 0.0055172415 },
      { format: 'aaaddddddddd', frequency: 0.0055172415 },
      { format: 'addddddddhdd', frequency: 0.004137931 },
      { format: 'dddddd', frequency: 0.0027586208 },
      { format: 'ddddd', frequency: 0.0027586208 },
      { format: 'ddddddddda', frequency: 0.0027586208 },
      { format: 'aaaddddddd', frequency: 0.0013793104 },
      { format: 'aaadddddddddddd', frequency: 0.0013793104 },
      { format: 'd', frequency: 0.0013793104 },
      { format: 'ddddddddddddd', frequency: 0.0013793104 },
      { format: 'addaaadddddd', frequency: 0.0013793104 },
      { format: 'adaddddddddd', frequency: 0.0013793104 },
      { format: 'dddddddddddddd', frequency: 0.0013793104 },
      { format: 'aadaaaadd', frequency: 0.0013793104 },
      { format: 'addddsddddd', frequency: 0.0013793104 },
      { format: 'dddddaddddd', frequency: 0.0013793104 },
      { format: 'dddddddddxddd', frequency: 0.0013793104 },
      { format: 'xdddxsdddhdddd', frequency: 0.0013793104 },
      { format: 'aaaaaaaa', frequency: 0.0013793104 },
      { format: 'aaddddddddd', frequency: 0.0013793104 },
      { format: 'ddddddddsdd', frequency: 0.0013793104 },
      { format: 'aaaddddd', frequency: 0.0013793104 },
      { format: 'ddddddddddddsxax', frequency: 0.0013793104 },
      { format: 'aaa', frequency: 0.0013793104 },
      { format: 'addddddddaaa', frequency: 0.0013793104 },
      { format: 'aaasaaaaaaa', frequency: 0.0013793104 },
      { format: 'dddddddddddda', frequency: 0.0013793104 },
      { format: 'aa', frequency: 0.0013793104 },
      { format: 'adddd', frequency: 0.0013793104 },
      { format: 'dddddaddd', frequency: 0.0013793104 },
      { format: 'dddddddddddddddd', frequency: 0.0013793104 },
      { format: 'ddd', frequency: 0.0013793104 },
      { format: 'adddddddddda', frequency: 0.0013793104 },
      { format: 'aaadddddddhdd', frequency: 0.0013793104 },
      { format: 'ddddddddhdd', frequency: 0.0013793104 },
      { format: 'aaaddddddddsdd', frequency: 0.0013793104 },
    ],
  },
  {
    front_end_carrier_id: 199,
    prefixes: [
      { prefix: '110', frequency: 0.13348946 },
      { prefix: '00', frequency: 0.11124122 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.20843092 },
      { suffix: '01', frequency: 0.14519906 },
      { suffix: '0', frequency: 0.14051522 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4449649 },
      { format: 'adddddddddd', frequency: 0.09250586 },
      { format: 'ddddddddddd', frequency: 0.08430913 },
      { format: 'adddddddd', frequency: 0.077283375 },
      { format: 'dddddddd', frequency: 0.044496488 },
      { format: 'dddddddddd', frequency: 0.037470724 },
      { format: 'addddddddsdd', frequency: 0.026932085 },
      { format: 'aadddddddddd', frequency: 0.022248244 },
      { format: 'ddddddd', frequency: 0.019906323 },
      { format: 'dddddddddddd', frequency: 0.018735362 },
      { format: 'dddddddddsdd', frequency: 0.010538641 },
      { format: 'aaaddddddddd', frequency: 0.010538641 },
      { format: 'addddddddd', frequency: 0.009367681 },
      { format: 'dddddddddhdd', frequency: 0.007025761 },
      { format: 'aaadddddd', frequency: 0.007025761 },
      { format: 'addddddddddd', frequency: 0.007025761 },
      { format: 'aaddddddd', frequency: 0.007025761 },
      { format: 'addddddddhdd', frequency: 0.0046838406 },
      { format: 'dddddaddddd', frequency: 0.0035128805 },
      { format: 'dddddd', frequency: 0.0035128805 },
      { format: 'addddddd', frequency: 0.0035128805 },
      { format: 'ddaddddddd', frequency: 0.0035128805 },
      { format: 'ddddddddddddd', frequency: 0.0023419203 },
      { format: 'ddddd', frequency: 0.0023419203 },
      { format: 'aaaddddddddhdd', frequency: 0.0023419203 },
      { format: 'ddddddddsdd', frequency: 0.0023419203 },
      { format: 'aaaddddddd', frequency: 0.0023419203 },
      { format: 'adddddaddddd', frequency: 0.0011709601 },
      { format: 'aaadddddddddddd', frequency: 0.0011709601 },
      { format: 'd', frequency: 0.0011709601 },
      { format: 'addaaadddddd', frequency: 0.0011709601 },
      { format: 'adaddddddddd', frequency: 0.0011709601 },
      { format: 'dddddddddddddd', frequency: 0.0011709601 },
      { format: 'aadaaaadd', frequency: 0.0011709601 },
      { format: 'addddsddddd', frequency: 0.0011709601 },
      { format: 'dddddddaddddd', frequency: 0.0011709601 },
      { format: 'dddddddddxddd', frequency: 0.0011709601 },
      { format: 'xdddxsdddhdddd', frequency: 0.0011709601 },
      { format: 'aaaaaaaa', frequency: 0.0011709601 },
      { format: 'aaadddddaddddd', frequency: 0.0011709601 },
      { format: 'aaddddddddd', frequency: 0.0011709601 },
      { format: 'aaaddddd', frequency: 0.0011709601 },
      { format: 'ddddddddddddsxax', frequency: 0.0011709601 },
      { format: 'ddddddddda', frequency: 0.0011709601 },
      { format: 'aaaadddd', frequency: 0.0011709601 },
      { format: 'adddddddsdd', frequency: 0.0011709601 },
      { format: 'aaa', frequency: 0.0011709601 },
      { format: 'addddddddaaa', frequency: 0.0011709601 },
      { format: 'aaaaasaasddddd', frequency: 0.0011709601 },
      { format: 'dddddaadddd', frequency: 0.0011709601 },
      { format: 'daaddddddddsdd', frequency: 0.0011709601 },
      { format: 'dhdddddddd', frequency: 0.0011709601 },
      { format: 'dddddddddddda', frequency: 0.0011709601 },
      { format: 'aa', frequency: 0.0011709601 },
      { format: 'adddd', frequency: 0.0011709601 },
      { format: 'aaaaddddd', frequency: 0.0011709601 },
      { format: 'dddddaddd', frequency: 0.0011709601 },
      { format: 'dddddddddddddddd', frequency: 0.0011709601 },
      { format: 'ddd', frequency: 0.0011709601 },
      { format: 'adddddddddda', frequency: 0.0011709601 },
      { format: 'ddddddddhdd', frequency: 0.0011709601 },
      { format: 'ddaadddddddd', frequency: 0.0011709601 },
      { format: 'aaaddddddddsdd', frequency: 0.0011709601 },
    ],
  },
  {
    front_end_carrier_id: 202,
    prefixes: [
      { prefix: '1', frequency: 0.46666667 },
      { prefix: 'U', frequency: 0.15507247 },
      { prefix: '110', frequency: 0.1536232 },
      { prefix: '0', frequency: 0.06521739 },
      { prefix: '9', frequency: 0.05652174 },
      { prefix: '1103', frequency: 0.052173913 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.21159421 },
      { suffix: '01', frequency: 0.14782609 },
      { suffix: '0', frequency: 0.12898551 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.44347826 },
      { format: 'adddddddddd', frequency: 0.09275363 },
      { format: 'adddddddd', frequency: 0.089855075 },
      { format: 'ddddddddddd', frequency: 0.085507244 },
      { format: 'dddddddd', frequency: 0.044927537 },
      { format: 'dddddddddd', frequency: 0.036231883 },
      { format: 'addddddddsdd', frequency: 0.03188406 },
      { format: 'aadddddddddd', frequency: 0.028985508 },
      { format: 'ddddddd', frequency: 0.02173913 },
      { format: 'dddddddddddd', frequency: 0.011594203 },
      { format: 'dddddddddsdd', frequency: 0.010144928 },
      { format: 'addddddddd', frequency: 0.007246377 },
      { format: 'dddddddddhdd', frequency: 0.0057971017 },
      { format: 'addddddd', frequency: 0.0057971017 },
      { format: 'aaadddddd', frequency: 0.0057971017 },
      { format: 'addddddddddd', frequency: 0.0057971017 },
      { format: 'aaaddddddddd', frequency: 0.0057971017 },
      { format: 'aaddddddd', frequency: 0.004347826 },
      { format: 'ddddd', frequency: 0.004347826 },
      { format: 'ddaddddddd', frequency: 0.004347826 },
      { format: 'aaaddddddd', frequency: 0.0028985508 },
      { format: 'addddddddhdd', frequency: 0.0028985508 },
      { format: 'ddddddddda', frequency: 0.0028985508 },
      { format: 'aaadddddddddddd', frequency: 0.0014492754 },
      { format: 'd', frequency: 0.0014492754 },
      { format: 'ddddddddddddd', frequency: 0.0014492754 },
      { format: 'dddddd', frequency: 0.0014492754 },
      { format: 'addaaadddddd', frequency: 0.0014492754 },
      { format: 'adaddddddddd', frequency: 0.0014492754 },
      { format: 'dddddddddddddd', frequency: 0.0014492754 },
      { format: 'aadaaaadd', frequency: 0.0014492754 },
      { format: 'addddsddddd', frequency: 0.0014492754 },
      { format: 'aaaddddddddhdd', frequency: 0.0014492754 },
      { format: 'dddddaddddd', frequency: 0.0014492754 },
      { format: 'dddddddddxddd', frequency: 0.0014492754 },
      { format: 'xdddxsdddhdddd', frequency: 0.0014492754 },
      { format: 'aaaaaaaa', frequency: 0.0014492754 },
      { format: 'aaddddddddd', frequency: 0.0014492754 },
      { format: 'ddddddddsdd', frequency: 0.0014492754 },
      { format: 'aaaddddd', frequency: 0.0014492754 },
      { format: 'ddddddddddddsxax', frequency: 0.0014492754 },
      { format: 'aaa', frequency: 0.0014492754 },
      { format: 'addddddddaaa', frequency: 0.0014492754 },
      { format: 'daaddddddddsdd', frequency: 0.0014492754 },
      { format: 'dddddddddddda', frequency: 0.0014492754 },
      { format: 'aa', frequency: 0.0014492754 },
      { format: 'adddd', frequency: 0.0014492754 },
      { format: 'dddddaddd', frequency: 0.0014492754 },
      { format: 'dddddddddddddddd', frequency: 0.0014492754 },
      { format: 'ddd', frequency: 0.0014492754 },
      { format: 'adddddddddda', frequency: 0.0014492754 },
      { format: 'aaadddddddhdd', frequency: 0.0014492754 },
      { format: 'ddddddddhdd', frequency: 0.0014492754 },
      { format: 'aaaddddddddsdd', frequency: 0.0014492754 },
    ],
  },
  {
    front_end_carrier_id: 204,
    prefixes: [
      { prefix: '10', frequency: 0.21285428 },
      { prefix: '109', frequency: 0.10220441 },
      { prefix: 'U8', frequency: 0.0860292 },
      { prefix: '00', frequency: 0.07400516 },
      { prefix: '9', frequency: 0.0632694 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22802748 },
      { suffix: '2', frequency: 0.13641569 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.28914973 },
      { format: 'adddddddddd', frequency: 0.23189236 },
      { format: 'adddddddd', frequency: 0.111795016 },
      { format: 'addddddddsdd', frequency: 0.07429144 },
      { format: 'ddddddddddd', frequency: 0.06212425 },
      { format: 'dddddddddddd', frequency: 0.052247353 },
      { format: 'dddddddd', frequency: 0.026767822 },
      { format: 'dddddddddd', frequency: 0.016890924 },
      { format: 'aaddddddd', frequency: 0.012882909 },
      { format: 'dddddddddsdd', frequency: 0.012453479 },
      { format: 'aaadddddd', frequency: 0.010592613 },
      { format: 'dddddaddddd', frequency: 0.008016032 },
      { format: 'aaaddddddddhdd', frequency: 0.007872889 },
      { format: 'dddddddddhdd', frequency: 0.0071571716 },
      { format: 'ddddddddda', frequency: 0.0058688805 },
      { format: 'aaadddddddd', frequency: 0.0058688805 },
      { format: 'ddddddd', frequency: 0.005725737 },
      { format: 'aaaaddddd', frequency: 0.0052963067 },
      { format: 'addddddddhdd', frequency: 0.0041511594 },
      { format: 'aaaddddddd', frequency: 0.0035785858 },
      { format: 'aaaddddddddd', frequency: 0.0032922989 },
      { format: 'addddddddd', frequency: 0.0031491555 },
      { format: 'aaadddddddddd', frequency: 0.0027197252 },
      { format: 'dddddd', frequency: 0.0024334383 },
      { format: 'aadddddddddd', frequency: 0.0018608646 },
      { format: 'ddddddddddddd', frequency: 0.0017177211 },
      { format: 'addddddddaad', frequency: 0.0017177211 },
      { format: 'addddddd', frequency: 0.0017177211 },
      { format: 'aaddddddddddd', frequency: 0.0015745778 },
      { format: 'dddddddddddddd', frequency: 0.0012882908 },
      { format: 'aaaaadddddd', frequency: 0.001002004 },
    ],
  },
  {
    front_end_carrier_id: 208,
    prefixes: [
      { prefix: '1', frequency: 0.45125347 },
      { prefix: '110', frequency: 0.15041783 },
      { prefix: 'U', frequency: 0.14902507 },
      { prefix: '0', frequency: 0.0626741 },
      { prefix: '1103', frequency: 0.050139274 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.19498608 },
      { suffix: '0', frequency: 0.13370474 },
      { suffix: '01', frequency: 0.13091922 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.43593314 },
      { format: 'adddddddd', frequency: 0.09052925 },
      { format: 'adddddddddd', frequency: 0.08774373 },
      { format: 'ddddddddddd', frequency: 0.07938719 },
      { format: 'dddddddd', frequency: 0.038997214 },
      { format: 'dddddddddd', frequency: 0.03481894 },
      { format: 'addddddddsdd', frequency: 0.03064067 },
      { format: 'aadddddddddd', frequency: 0.027855154 },
      { format: 'aaaddddddddd', frequency: 0.022284122 },
      { format: 'ddddddd', frequency: 0.020891365 },
      { format: 'dddddddddddd', frequency: 0.013927577 },
      { format: 'aaaadddddddd', frequency: 0.012534819 },
      { format: 'dddddddddsdd', frequency: 0.009749304 },
      { format: 'addddddddd', frequency: 0.0069637885 },
      { format: 'dddddddddhdd', frequency: 0.0069637885 },
      { format: 'addddddd', frequency: 0.0055710305 },
      { format: 'aaadddddd', frequency: 0.0055710305 },
      { format: 'addddddddddd', frequency: 0.0055710305 },
      { format: 'addddddddhdd', frequency: 0.004178273 },
      { format: 'aaddddddd', frequency: 0.004178273 },
      { format: 'ddaddddddd', frequency: 0.004178273 },
      { format: 'aaaddddddd', frequency: 0.0027855153 },
      { format: 'ddddd', frequency: 0.0027855153 },
      { format: 'ddddddddda', frequency: 0.0027855153 },
      { format: 'aaadddddddddddd', frequency: 0.0013927576 },
      { format: 'd', frequency: 0.0013927576 },
      { format: 'ddddddddddddd', frequency: 0.0013927576 },
      { format: 'dddddd', frequency: 0.0013927576 },
      { format: 'addaaadddddd', frequency: 0.0013927576 },
      { format: 'adaddddddddd', frequency: 0.0013927576 },
      { format: 'dddddddddddddd', frequency: 0.0013927576 },
      { format: 'aadaaaadd', frequency: 0.0013927576 },
      { format: 'addddsddddd', frequency: 0.0013927576 },
      { format: 'aaasddddddddd', frequency: 0.0013927576 },
      { format: 'dddddaddddd', frequency: 0.0013927576 },
      { format: 'dddddddddxddd', frequency: 0.0013927576 },
      { format: 'xdddxsdddhdddd', frequency: 0.0013927576 },
      { format: 'aaaaaaaa', frequency: 0.0013927576 },
      { format: 'aaddddddddd', frequency: 0.0013927576 },
      { format: 'ddddddddsdd', frequency: 0.0013927576 },
      { format: 'aaaddddd', frequency: 0.0013927576 },
      { format: 'ddddddddddddsxax', frequency: 0.0013927576 },
      { format: 'aaa', frequency: 0.0013927576 },
      { format: 'addddddddaaa', frequency: 0.0013927576 },
      { format: 'daaddddddddsdd', frequency: 0.0013927576 },
      { format: 'dddddddddddda', frequency: 0.0013927576 },
      { format: 'aa', frequency: 0.0013927576 },
      { format: 'adddd', frequency: 0.0013927576 },
      { format: 'aaaaddddd', frequency: 0.0013927576 },
      { format: 'dddddaddd', frequency: 0.0013927576 },
      { format: 'dddddddddddddddd', frequency: 0.0013927576 },
      { format: 'ddd', frequency: 0.0013927576 },
      { format: 'adddddddddda', frequency: 0.0013927576 },
      { format: 'ddddddddhdd', frequency: 0.0013927576 },
      { format: 'aaaddddddddsdd', frequency: 0.0013927576 },
    ],
  },
  {
    front_end_carrier_id: 209,
    prefixes: [
      { prefix: '10', frequency: 0.21201618 },
      { prefix: '109', frequency: 0.096707106 },
      { prefix: 'U8', frequency: 0.0909301 },
      { prefix: '00', frequency: 0.06597342 },
      { prefix: '9', frequency: 0.06227614 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.23316002 },
      { suffix: '2', frequency: 0.13622184 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.284922 },
      { format: 'adddddddddd', frequency: 0.23778163 },
      { format: 'adddddddd', frequency: 0.12582323 },
      { format: 'addddddddsdd', frequency: 0.07128827 },
      { format: 'ddddddddddd', frequency: 0.067706525 },
      { format: 'dddddddddddd', frequency: 0.044714037 },
      { format: 'dddddddd', frequency: 0.027729636 },
      { format: 'dddddddddd', frequency: 0.0151357595 },
      { format: 'dddddddddsdd', frequency: 0.014211439 },
      { format: 'aaddddddd', frequency: 0.011322935 },
      { format: 'aaadddddd', frequency: 0.009589832 },
      { format: 'dddddddddhdd', frequency: 0.00774119 },
      { format: 'dddddaddddd', frequency: 0.00751011 },
      { format: 'aaaddddddddhdd', frequency: 0.0071634892 },
      { format: 'aaadddddddd', frequency: 0.005314847 },
      { format: 'ddddddd', frequency: 0.0050837668 },
      { format: 'ddddddddda', frequency: 0.004852686 },
      { format: 'addddddddhdd', frequency: 0.004621606 },
      { format: 'aaaaddddd', frequency: 0.0041594454 },
      { format: 'aaaddddddd', frequency: 0.003119584 },
      { format: 'aaadddddddddd', frequency: 0.0026574235 },
      { format: 'aaaddddddddd', frequency: 0.0025418834 },
      { format: 'aadddddddddd', frequency: 0.0025418834 },
      { format: 'addddddddd', frequency: 0.0021952628 },
      { format: 'dddddd', frequency: 0.0017331023 },
      { format: 'addddddddaad', frequency: 0.0017331023 },
      { format: 'addddddddddd', frequency: 0.0015020219 },
      { format: 'ddddddddddddd', frequency: 0.0013864818 },
      { format: 'aaddddddddddd', frequency: 0.0013864818 },
      { format: 'addddddd', frequency: 0.0013864818 },
      { format: 'addddddddaaa', frequency: 0.0012709417 },
      { format: 'dddddddddddddd', frequency: 0.0010398614 },
    ],
  },
  {
    front_end_carrier_id: 211,
    prefixes: [
      { prefix: '1', frequency: 0.39634147 },
      { prefix: 'U', frequency: 0.13414635 },
      { prefix: '110', frequency: 0.13292684 },
      { prefix: '0', frequency: 0.053658538 },
      { prefix: 'N', frequency: 0.052439023 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.1902439 },
      { suffix: '0', frequency: 0.120731704 },
      { suffix: '01', frequency: 0.11829268 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.36097562 },
      { format: 'aaaaadddddd', frequency: 0.12560976 },
      { format: 'adddddddddd', frequency: 0.07804878 },
      { format: 'adddddddd', frequency: 0.07439025 },
      { format: 'ddddddddddd', frequency: 0.068292685 },
      { format: 'dddddddd', frequency: 0.035365853 },
      { format: 'dddddddddd', frequency: 0.031707317 },
      { format: 'addddddddsdd', frequency: 0.02804878 },
      { format: 'aadddddddddd', frequency: 0.025609756 },
      { format: 'aaadddddddd', frequency: 0.020731708 },
      { format: 'aaaaddddddd', frequency: 0.020731708 },
      { format: 'ddddddd', frequency: 0.017073171 },
      { format: 'dddddddddddd', frequency: 0.0109756095 },
      { format: 'dddddddddsdd', frequency: 0.008536586 },
      { format: 'aadaadddddd', frequency: 0.007317073 },
      { format: 'addddddddd', frequency: 0.0060975607 },
      { format: 'dddddddddhdd', frequency: 0.0060975607 },
      { format: 'addddddd', frequency: 0.004878049 },
      { format: 'aaadddddd', frequency: 0.004878049 },
      { format: 'addddddddddd', frequency: 0.004878049 },
      { format: 'aaddddddd', frequency: 0.004878049 },
      { format: 'aaaddddddddd', frequency: 0.004878049 },
      { format: 'ddaddddddd', frequency: 0.0036585366 },
      { format: 'aaaddddddd', frequency: 0.0024390244 },
      { format: 'addddddddhdd', frequency: 0.0024390244 },
      { format: 'ddddd', frequency: 0.0024390244 },
      { format: 'ddddddddda', frequency: 0.0024390244 },
      { format: 'aaadddddddddddd', frequency: 0.0012195122 },
      { format: 'd', frequency: 0.0012195122 },
      { format: 'ddddddddddddd', frequency: 0.0012195122 },
      { format: 'dddddd', frequency: 0.0012195122 },
      { format: 'addaaadddddd', frequency: 0.0012195122 },
      { format: 'adaddddddddd', frequency: 0.0012195122 },
      { format: 'dddddddddddddd', frequency: 0.0012195122 },
      { format: 'aadaaaadd', frequency: 0.0012195122 },
      { format: 'addddsddddd', frequency: 0.0012195122 },
      { format: 'dddddaddddd', frequency: 0.0012195122 },
      { format: 'dddddddddxddd', frequency: 0.0012195122 },
      { format: 'xdddxsdddhdddd', frequency: 0.0012195122 },
      { format: 'aaaaaaaa', frequency: 0.0012195122 },
      { format: 'aaddddddddd', frequency: 0.0012195122 },
      { format: 'ddddddddsdd', frequency: 0.0012195122 },
      { format: 'aaaddddd', frequency: 0.0012195122 },
      { format: 'ddddddddddddsxax', frequency: 0.0012195122 },
      { format: 'aaa', frequency: 0.0012195122 },
      { format: 'addddddddaaa', frequency: 0.0012195122 },
      { format: 'aaaaadddddddd', frequency: 0.0012195122 },
      { format: 'dddddddddddda', frequency: 0.0012195122 },
      { format: 'aa', frequency: 0.0012195122 },
      { format: 'adddd', frequency: 0.0012195122 },
      { format: 'dddddaddd', frequency: 0.0012195122 },
      { format: 'dddddddddddddddd', frequency: 0.0012195122 },
      { format: 'ddd', frequency: 0.0012195122 },
      { format: 'adddddddddda', frequency: 0.0012195122 },
      { format: 'aaadddddddhdd', frequency: 0.0012195122 },
      { format: 'ddddddddhdd', frequency: 0.0012195122 },
      { format: 'aaaddddddddsdd', frequency: 0.0012195122 },
    ],
  },
  {
    front_end_carrier_id: 213,
    prefixes: [
      { prefix: '1', frequency: 0.39043826 },
      { prefix: 'U', frequency: 0.1942231 },
      { prefix: '110', frequency: 0.1185259 },
      { prefix: '0', frequency: 0.065737054 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.22609562 },
      { suffix: '01', frequency: 0.16035856 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.36454183 },
      { format: 'adddddddddd', frequency: 0.119521916 },
      { format: 'adddddddd', frequency: 0.112549804 },
      { format: 'ddddddddddd', frequency: 0.06474104 },
      { format: 'dddddddd', frequency: 0.052788846 },
      { format: 'addddddddsdd', frequency: 0.033864543 },
      { format: 'dddddddddd', frequency: 0.030876495 },
      { format: 'aadddddddddd', frequency: 0.029880479 },
      { format: 'ddddddd', frequency: 0.023904383 },
      { format: 'dddddddddddd', frequency: 0.0139442235 },
      { format: 'addddddddd', frequency: 0.011952192 },
      { format: 'dddddddddsdd', frequency: 0.00996016 },
      { format: 'aaadddddd', frequency: 0.007968128 },
      { format: 'dddddddddhdd', frequency: 0.0069721118 },
      { format: 'ddddd', frequency: 0.0069721118 },
      { format: 'aaaddddddddd', frequency: 0.005976096 },
      { format: 'addddddd', frequency: 0.00498008 },
      { format: 'addddddddddd', frequency: 0.00498008 },
      { format: 'dddddd', frequency: 0.00498008 },
      { format: 'ddaddddddd', frequency: 0.00498008 },
      { format: 'aaddddddd', frequency: 0.00498008 },
      { format: 'addddddddhdd', frequency: 0.003984064 },
      { format: 'dddddaddddd', frequency: 0.003984064 },
      { format: 'aaddddddddd', frequency: 0.002988048 },
      { format: 'ddddddddsdd', frequency: 0.002988048 },
      { format: 'ddddddddda', frequency: 0.002988048 },
      { format: 'aaaddddddd', frequency: 0.001992032 },
      { format: 'ddddddddddddd', frequency: 0.001992032 },
      { format: 'adddddddsdd', frequency: 0.001992032 },
      { format: 'aaaaaadddddd', frequency: 0.001992032 },
      { format: 'aaaaaaaa', frequency: 0.001992032 },
      { format: 'aaa', frequency: 0.001992032 },
      { format: 'ddddddddhdd', frequency: 0.001992032 },
      { format: 'aaaddddddddhdd', frequency: 0.001992032 },
    ],
  },
  {
    front_end_carrier_id: 214,
    prefixes: [
      { prefix: '1', frequency: 0.4639321 },
      { prefix: 'U', frequency: 0.15983027 },
      { prefix: '110', frequency: 0.14992929 },
      { prefix: '0', frequency: 0.06647807 },
      { prefix: '1103', frequency: 0.050919376 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.20933522 },
      { suffix: '01', frequency: 0.145686 },
      { suffix: '0', frequency: 0.12871288 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.41867045 },
      { format: 'adddddddd', frequency: 0.09335219 },
      { format: 'adddddddddd', frequency: 0.09052334 },
      { format: 'ddddddddddd', frequency: 0.083451204 },
      { format: 'dddddddd', frequency: 0.04526167 },
      { format: 'dddddddddd', frequency: 0.038189534 },
      { format: 'addddddddsdd', frequency: 0.033946253 },
      { format: 'aadddddddddd', frequency: 0.028288543 },
      { format: 'ddddddd', frequency: 0.022630835 },
      { format: 'dddddddddddd', frequency: 0.012729844 },
      { format: 'dddddddddsdd', frequency: 0.0113154175 },
      { format: 'addddddddd', frequency: 0.00990099 },
      { format: 'addddddddddd', frequency: 0.008486563 },
      { format: 'dddddddddhdd', frequency: 0.007072136 },
      { format: 'addddddd', frequency: 0.007072136 },
      { format: 'aaddddddd', frequency: 0.007072136 },
      { format: 'aaaddddddddd', frequency: 0.007072136 },
      { format: 'aaadddddd', frequency: 0.0056577087 },
      { format: 'ddddd', frequency: 0.0056577087 },
      { format: 'ddaddddddd', frequency: 0.0056577087 },
      { format: 'addddddddhdd', frequency: 0.0042432817 },
      { format: 'd', frequency: 0.0028288544 },
      { format: 'dddddaddddd', frequency: 0.0028288544 },
      { format: 'ddddddddda', frequency: 0.0028288544 },
      { format: 'dddddddxdd', frequency: 0.0014144272 },
      { format: 'aaaddddddd', frequency: 0.0014144272 },
      { format: 'aaadddddddddddd', frequency: 0.0014144272 },
      { format: 'ddddddddddddd', frequency: 0.0014144272 },
      { format: 'dddddd', frequency: 0.0014144272 },
      { format: 'addaaadddddd', frequency: 0.0014144272 },
      { format: 'aaaddddddsdd', frequency: 0.0014144272 },
      { format: 'adaddddddddd', frequency: 0.0014144272 },
      { format: 'dddddddddddddd', frequency: 0.0014144272 },
      { format: 'aadaaaadd', frequency: 0.0014144272 },
      { format: 'adddddddsdd', frequency: 0.0014144272 },
      { format: 'addddsddddd', frequency: 0.0014144272 },
      { format: 'dddddddddxddd', frequency: 0.0014144272 },
      { format: 'xdddxsdddhdddd', frequency: 0.0014144272 },
      { format: 'aaaaaaaa', frequency: 0.0014144272 },
      { format: 'aaddddddddd', frequency: 0.0014144272 },
      { format: 'ddddddddsdd', frequency: 0.0014144272 },
      { format: 'aaaddddd', frequency: 0.0014144272 },
      { format: 'ddddddddddddsxax', frequency: 0.0014144272 },
      { format: 'aaa', frequency: 0.0014144272 },
      { format: 'addddddddaaa', frequency: 0.0014144272 },
      { format: 'dddddddddddda', frequency: 0.0014144272 },
      { format: 'aa', frequency: 0.0014144272 },
      { format: 'dddhddhdddd', frequency: 0.0014144272 },
      { format: 'adddd', frequency: 0.0014144272 },
      { format: 'dddddaddd', frequency: 0.0014144272 },
      { format: 'dddddddddddddddd', frequency: 0.0014144272 },
      { format: 'ddd', frequency: 0.0014144272 },
      { format: 'adddddddddda', frequency: 0.0014144272 },
      { format: 'aaadddddddhdd', frequency: 0.0014144272 },
      { format: 'ddddddddhdd', frequency: 0.0014144272 },
      { format: 'aaaddddddddsdd', frequency: 0.0014144272 },
    ],
  },
  {
    front_end_carrier_id: 216,
    prefixes: [
      { prefix: 'N32', frequency: 0.06170531 },
      { prefix: '110', frequency: 0.054973822 },
      { prefix: 'OP', frequency: 0.050860133 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.20942408 },
      { suffix: '0', frequency: 0.13313389 },
      { suffix: '2', frequency: 0.1264024 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.18735977 },
      { format: 'adddddddddd', frequency: 0.14622289 },
      { format: 'adddddddd', frequency: 0.14136125 },
      { format: 'ddddddddddd', frequency: 0.06694091 },
      { format: 'dddddddddd', frequency: 0.053851906 },
      { format: 'aadddddddddd', frequency: 0.04973822 },
      { format: 'addddddddsdd', frequency: 0.04936425 },
      { format: 'dddddddd', frequency: 0.039640985 },
      { format: 'addddddddd', frequency: 0.033283472 },
      { format: 'dddddddddddd', frequency: 0.02356021 },
      { format: 'addddddddddd', frequency: 0.01421092 },
      { format: 'ddddddd', frequency: 0.012341063 },
      { format: 'dddddddddsdd', frequency: 0.010845176 },
      { format: 'dddddddddhdd', frequency: 0.009723261 },
      { format: 'aaddddddd', frequency: 0.008227374 },
      { format: 'aaddddddddd', frequency: 0.0074794316 },
      { format: 'addddddd', frequency: 0.00710546 },
      { format: 'ddddddddsdd', frequency: 0.0067314883 },
      { format: 'aaaddddddddd', frequency: 0.0063575166 },
      { format: 'aadddddddsdd', frequency: 0.0059835454 },
      { format: 'ddaddddddd', frequency: 0.005609574 },
      { format: 'aaadddddd', frequency: 0.005235602 },
      { format: 'aaadddaddddd', frequency: 0.004487659 },
      { format: 'adddddddddsdd', frequency: 0.004487659 },
      { format: 'aaaddddddd', frequency: 0.004113687 },
      { format: 'addddsddddd', frequency: 0.0033657441 },
      { format: 'addddddddhdd', frequency: 0.0033657441 },
      { format: 'aaaddddddddhdd', frequency: 0.0029917727 },
      { format: 'ddddddddda', frequency: 0.002617801 },
      { format: 'adddddddddddd', frequency: 0.0022438294 },
      { format: 'adddddddsdd', frequency: 0.0022438294 },
      { format: 'aaadddddddddddd', frequency: 0.0022438294 },
      { format: 'aaadddddddd', frequency: 0.0022438294 },
      { format: 'aaddddddddddd', frequency: 0.0018698579 },
      { format: 'addddddddaaa', frequency: 0.0018698579 },
      { format: 'ddddddddddddd', frequency: 0.0018698579 },
      { format: 'dddddddddxa', frequency: 0.0018698579 },
      { format: 'dddddddddddddd', frequency: 0.0014958864 },
      { format: 'adddddd', frequency: 0.0014958864 },
      { format: 'ddddd', frequency: 0.0014958864 },
      { format: 'aaddddddddsdd', frequency: 0.0014958864 },
      { format: 'dddddaddddd', frequency: 0.0014958864 },
      { format: 'aadddddd', frequency: 0.0011219147 },
      { format: 'ddddddddhdd', frequency: 0.0011219147 },
      { format: 'aaadddddddda', frequency: 0.0011219147 },
      { format: 'aaaaaa', frequency: 0.0011219147 },
      { format: 'aadddddddd', frequency: 0.0011219147 },
      { format: 'aaadddddddaa', frequency: 0.0011219147 },
      { format: 'aaaaaadddddd', frequency: 0.0011219147 },
    ],
  },
  {
    front_end_carrier_id: 220,
    prefixes: [
      { prefix: '10', frequency: 0.21988472 },
      { prefix: '109', frequency: 0.10547551 },
      { prefix: 'U8', frequency: 0.084005766 },
      { prefix: '00', frequency: 0.0723343 },
      { prefix: '9', frequency: 0.0629683 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.23025936 },
      { suffix: '2', frequency: 0.13760807 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.2939481 },
      { format: 'adddddddddd', frequency: 0.22881845 },
      { format: 'adddddddd', frequency: 0.11051873 },
      { format: 'addddddddsdd', frequency: 0.07377522 },
      { format: 'ddddddddddd', frequency: 0.06498559 },
      { format: 'dddddddddddd', frequency: 0.05259366 },
      { format: 'dddddddd', frequency: 0.026368877 },
      { format: 'dddddddddd', frequency: 0.01685879 },
      { format: 'aaddddddd', frequency: 0.012824208 },
      { format: 'dddddddddsdd', frequency: 0.011959654 },
      { format: 'aaadddddd', frequency: 0.01037464 },
      { format: 'dddddaddddd', frequency: 0.008069164 },
      { format: 'aaaddddddddhdd', frequency: 0.0076368875 },
      { format: 'dddddddddhdd', frequency: 0.007060519 },
      { format: 'ddddddd', frequency: 0.0057636886 },
      { format: 'ddddddddda', frequency: 0.0057636886 },
      { format: 'aaadddddddd', frequency: 0.0057636886 },
      { format: 'aaaaddddd', frequency: 0.005331412 },
      { format: 'addddddddhdd', frequency: 0.0036023054 },
      { format: 'aaaddddddd', frequency: 0.0034582133 },
      { format: 'addddddddd', frequency: 0.0031700288 },
      { format: 'aaaddddddddd', frequency: 0.0030259367 },
      { format: 'aaadddddddddd', frequency: 0.0028818443 },
      { format: 'dddddd', frequency: 0.0021613834 },
      { format: 'addddddddaad', frequency: 0.002017291 },
      { format: 'aadddddddddd', frequency: 0.0018731989 },
      { format: 'ddddddddddddd', frequency: 0.0015850144 },
      { format: 'aaddddddddddd', frequency: 0.0015850144 },
      { format: 'addddddd', frequency: 0.0015850144 },
      { format: 'dddddddddddddd', frequency: 0.00129683 },
      { format: 'aaaaadddddd', frequency: 0.0010086455 },
    ],
  },
  {
    front_end_carrier_id: 222,
    prefixes: [
      { prefix: '1', frequency: 0.4136646 },
      { prefix: 'U', frequency: 0.16024844 },
      { prefix: '110', frequency: 0.13291925 },
      { prefix: '0', frequency: 0.06583851 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.20372671 },
      { suffix: '01', frequency: 0.1378882 },
      { suffix: '0', frequency: 0.13167702 },
      { suffix: '2', frequency: 0.12546584 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.39503106 },
      { format: 'adddddddd', frequency: 0.14782609 },
      { format: 'adddddddddd', frequency: 0.101863354 },
      { format: 'ddddddddddd', frequency: 0.07204969 },
      { format: 'dddddddd', frequency: 0.040993787 },
      { format: 'dddddddddd', frequency: 0.032298137 },
      { format: 'aadddddddddd', frequency: 0.031055901 },
      { format: 'addddddddsdd', frequency: 0.024844721 },
      { format: 'ddddddd', frequency: 0.022360248 },
      { format: 'dddddddddddd', frequency: 0.013664597 },
      { format: 'dddddddddsdd', frequency: 0.009937888 },
      { format: 'dddddddddhdd', frequency: 0.008695652 },
      { format: 'addddddddd', frequency: 0.007453416 },
      { format: 'addddddddddd', frequency: 0.007453416 },
      { format: 'aaadddddd', frequency: 0.0062111802 },
      { format: 'aaaddddddddd', frequency: 0.004968944 },
      { format: 'addddddd', frequency: 0.003726708 },
      { format: 'addddddddhdd', frequency: 0.003726708 },
      { format: 'aaddddddd', frequency: 0.003726708 },
      { format: 'ddaddddddd', frequency: 0.003726708 },
      { format: 'aaaddddddd', frequency: 0.002484472 },
      { format: 'dddddd', frequency: 0.002484472 },
      { format: 'aaaddddddddhdd', frequency: 0.002484472 },
      { format: 'aaa', frequency: 0.002484472 },
      { format: 'ddddd', frequency: 0.002484472 },
      { format: 'addddddddaaa', frequency: 0.002484472 },
      { format: 'ddddddddda', frequency: 0.002484472 },
      { format: 'aaadddddddddddd', frequency: 0.001242236 },
      { format: 'd', frequency: 0.001242236 },
      { format: 'addaaadddddd', frequency: 0.001242236 },
      { format: 'adaddddddddd', frequency: 0.001242236 },
      { format: 'dddddddddddddd', frequency: 0.001242236 },
      { format: 'aadaaaadd', frequency: 0.001242236 },
      { format: 'addddddddaad', frequency: 0.001242236 },
      { format: 'addddsddddd', frequency: 0.001242236 },
      { format: 'ddddddddddddd', frequency: 0.001242236 },
      { format: 'dddhddhdddd', frequency: 0.001242236 },
      { format: 'dddddaddddd', frequency: 0.001242236 },
      { format: 'dddddddddxddd', frequency: 0.001242236 },
      { format: 'adddddddddsdd', frequency: 0.001242236 },
      { format: 'xdddxsdddhdddd', frequency: 0.001242236 },
      { format: 'aaaaaaaa', frequency: 0.001242236 },
      { format: 'aaadddddaddddd', frequency: 0.001242236 },
      { format: 'aaddddddddd', frequency: 0.001242236 },
      { format: 'ddddddddsdd', frequency: 0.001242236 },
      { format: 'aaaddddd', frequency: 0.001242236 },
      { format: 'ddddddddddddsxax', frequency: 0.001242236 },
      { format: 'aaadddaddddd', frequency: 0.001242236 },
      { format: 'dddaddddd', frequency: 0.001242236 },
      { format: 'dddddddddddda', frequency: 0.001242236 },
      { format: 'aa', frequency: 0.001242236 },
      { format: 'adddd', frequency: 0.001242236 },
      { format: 'dddddaddd', frequency: 0.001242236 },
      { format: 'dddddddddddddddd', frequency: 0.001242236 },
      { format: 'ddd', frequency: 0.001242236 },
      { format: 'adddddddddda', frequency: 0.001242236 },
      { format: 'aaadddddddhdd', frequency: 0.001242236 },
      { format: 'ddddddddhdd', frequency: 0.001242236 },
      { format: 'ddaadddddddd', frequency: 0.001242236 },
      { format: 'aaaddddddddsdd', frequency: 0.001242236 },
    ],
  },
  {
    front_end_carrier_id: 223,
    prefixes: [
      { prefix: '10', frequency: 0.21785152 },
      { prefix: '109', frequency: 0.1062795 },
      { prefix: 'U8', frequency: 0.087827206 },
      { prefix: '00', frequency: 0.07323702 },
      { prefix: '9', frequency: 0.062079817 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22757831 },
      { suffix: '2', frequency: 0.13746245 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29151767 },
      { format: 'adddddddddd', frequency: 0.23301387 },
      { format: 'adddddddd', frequency: 0.109569445 },
      { format: 'addddddddsdd', frequency: 0.075668715 },
      { format: 'ddddddddddd', frequency: 0.063510224 },
      { format: 'dddddddddddd', frequency: 0.05178086 },
      { format: 'dddddddd', frequency: 0.026462594 },
      { format: 'dddddddddd', frequency: 0.018738378 },
      { format: 'aaddddddd', frequency: 0.012730653 },
      { format: 'dddddddddsdd', frequency: 0.012301531 },
      { format: 'aaadddddd', frequency: 0.010155914 },
      { format: 'dddddaddddd', frequency: 0.007867258 },
      { format: 'aaaddddddddhdd', frequency: 0.007438135 },
      { format: 'dddddddddhdd', frequency: 0.0068659703 },
      { format: 'ddddddddda', frequency: 0.005864683 },
      { format: 'aaadddddddd', frequency: 0.005721642 },
      { format: 'ddddddd', frequency: 0.0055786013 },
      { format: 'aaaaddddd', frequency: 0.005292519 },
      { format: 'addddddddhdd', frequency: 0.0040051495 },
      { format: 'aaaddddddd', frequency: 0.0034329852 },
      { format: 'aaaddddddddd', frequency: 0.0030038622 },
      { format: 'aaadddddddddd', frequency: 0.002860821 },
      { format: 'addddddddd', frequency: 0.002860821 },
      { format: 'addddddddaad', frequency: 0.0022886568 },
      { format: 'dddddd', frequency: 0.0021456159 },
      { format: 'aadddddddddd', frequency: 0.0017164926 },
      { format: 'aaddddddddddd', frequency: 0.0015734516 },
      { format: 'addddddd', frequency: 0.0015734516 },
      { format: 'ddddddddddddd', frequency: 0.0014304105 },
      { format: 'dddddddddddddd', frequency: 0.0012873695 },
      { format: 'aaaaadddddd', frequency: 0.0010012874 },
      { format: 'addddddddddd', frequency: 0.0010012874 },
    ],
  },
  {
    front_end_carrier_id: 224,
    prefixes: [
      { prefix: '10', frequency: 0.21752353 },
      { prefix: '109', frequency: 0.10615496 },
      { prefix: 'U8', frequency: 0.08312817 },
      { prefix: '00', frequency: 0.07356988 },
      { prefix: '9', frequency: 0.062997825 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.2237509 },
      { suffix: '2', frequency: 0.13816075 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.2923968 },
      { format: 'adddddddddd', frequency: 0.22722664 },
      { format: 'adddddddd', frequency: 0.11383056 },
      { format: 'addddddddsdd', frequency: 0.0750181 },
      { format: 'ddddddddddd', frequency: 0.063866764 },
      { format: 'dddddddddddd', frequency: 0.05199131 },
      { format: 'dddddddd', frequency: 0.026068067 },
      { format: 'dddddddddd', frequency: 0.017089065 },
      { format: 'dddddddddsdd', frequency: 0.013178856 },
      { format: 'aaddddddd', frequency: 0.012599565 },
      { format: 'aaadddddd', frequency: 0.010716872 },
      { format: 'dddddaddddd', frequency: 0.008110065 },
      { format: 'aaaddddddddhdd', frequency: 0.00782042 },
      { format: 'dddddddddhdd', frequency: 0.007096307 },
      { format: 'ddddddd', frequency: 0.0057929037 },
      { format: 'ddddddddda', frequency: 0.0057929037 },
      { format: 'aaadddddddd', frequency: 0.0057929037 },
      { format: 'aaaaddddd', frequency: 0.0050687906 },
      { format: 'addddddddhdd', frequency: 0.00391021 },
      { format: 'aaaddddddd', frequency: 0.0034757422 },
      { format: 'aaaddddddddd', frequency: 0.0031860971 },
      { format: 'addddddddd', frequency: 0.0030412744 },
      { format: 'aaadddddddddd', frequency: 0.0028964519 },
      { format: 'dddddd', frequency: 0.0023171615 },
      { format: 'aadddddddddd', frequency: 0.0018826937 },
      { format: 'addddddddaad', frequency: 0.0018826937 },
      { format: 'ddddddddddddd', frequency: 0.0015930486 },
      { format: 'aaddddddddddd', frequency: 0.0015930486 },
      { format: 'addddddd', frequency: 0.0015930486 },
      { format: 'dddddddddddddd', frequency: 0.0014482259 },
      { format: 'addddddddddd', frequency: 0.0011585808 },
      { format: 'aaaaadddddd', frequency: 0.0010137581 },
    ],
  },
  {
    front_end_carrier_id: 225,
    prefixes: [
      { prefix: '110', frequency: 0.14002478 },
      { prefix: '00', frequency: 0.071871124 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.22800496 },
      { suffix: '01', frequency: 0.16604708 },
      { suffix: '0', frequency: 0.120198265 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.40892193 },
      { format: 'adddddddddd', frequency: 0.102850065 },
      { format: 'ddddddddddd', frequency: 0.10161091 },
      { format: 'adddddddd', frequency: 0.08550186 },
      { format: 'dddddddd', frequency: 0.040892195 },
      { format: 'dddddddddd', frequency: 0.03717472 },
      { format: 'addddddddsdd', frequency: 0.030978935 },
      { format: 'aadddddddddd', frequency: 0.026022306 },
      { format: 'ddddddd', frequency: 0.02354399 },
      { format: 'dddddddddddd', frequency: 0.022304833 },
      { format: 'dddddddddsdd', frequency: 0.0111524165 },
      { format: 'addddddddddd', frequency: 0.007434944 },
      { format: 'addddddddd', frequency: 0.007434944 },
      { format: 'aaadddddd', frequency: 0.006195787 },
      { format: 'dddddddddhdd', frequency: 0.006195787 },
      { format: 'addddddd', frequency: 0.006195787 },
      { format: 'aaaddddddddd', frequency: 0.006195787 },
      { format: 'aaaddddddd', frequency: 0.003717472 },
      { format: 'aaddddddd', frequency: 0.003717472 },
      { format: 'ddddd', frequency: 0.003717472 },
      { format: 'ddaddddddd', frequency: 0.003717472 },
      { format: 'ddddddddda', frequency: 0.003717472 },
      { format: 'addddddddhdd', frequency: 0.0024783148 },
      { format: 'aaddddddddd', frequency: 0.0024783148 },
      { format: 'aaadddddddddddd', frequency: 0.0012391574 },
      { format: 'aaxddddd', frequency: 0.0012391574 },
      { format: 'd', frequency: 0.0012391574 },
      { format: 'ddddddddddddd', frequency: 0.0012391574 },
      { format: 'dddddd', frequency: 0.0012391574 },
      { format: 'addaaadddddd', frequency: 0.0012391574 },
      { format: 'aaaddddddsdd', frequency: 0.0012391574 },
      { format: 'adaddddddddd', frequency: 0.0012391574 },
      { format: 'dddddddddddddd', frequency: 0.0012391574 },
      { format: 'aadaaaadd', frequency: 0.0012391574 },
      { format: 'addddddddaad', frequency: 0.0012391574 },
      { format: 'aaaddddddddhdd', frequency: 0.0012391574 },
      { format: 'addddsddddd', frequency: 0.0012391574 },
      { format: 'dddddaddddd', frequency: 0.0012391574 },
      { format: 'dddddddddxddd', frequency: 0.0012391574 },
      { format: 'xdddxsdddhdddd', frequency: 0.0012391574 },
      { format: 'aaaaaaaa', frequency: 0.0012391574 },
      { format: 'addddddddaaddd', frequency: 0.0012391574 },
      { format: 'ddddddddsdd', frequency: 0.0012391574 },
      { format: 'aaaddddd', frequency: 0.0012391574 },
      { format: 'ddddddddddddsxax', frequency: 0.0012391574 },
      { format: 'adddddddsdd', frequency: 0.0012391574 },
      { format: 'aaa', frequency: 0.0012391574 },
      { format: 'addddddddaaa', frequency: 0.0012391574 },
      { format: 'dddaddddd', frequency: 0.0012391574 },
      { format: 'daaddddddddsdd', frequency: 0.0012391574 },
      { format: 'dddddddddddda', frequency: 0.0012391574 },
      { format: 'aa', frequency: 0.0012391574 },
      { format: 'adddd', frequency: 0.0012391574 },
      { format: 'dddddaddd', frequency: 0.0012391574 },
      { format: 'dddddddddddddddd', frequency: 0.0012391574 },
      { format: 'ddd', frequency: 0.0012391574 },
      { format: 'adddddddddda', frequency: 0.0012391574 },
      { format: 'aaadddddddhdd', frequency: 0.0012391574 },
      { format: 'ddddddddhdd', frequency: 0.0012391574 },
      { format: 'aaaddddddddsdd', frequency: 0.0012391574 },
      { format: 'aaaaddddd', frequency: 0.0012391574 },
    ],
  },
  {
    front_end_carrier_id: 226,
    prefixes: [
      { prefix: 'W28', frequency: 0.100148365 },
      { prefix: 'W27', frequency: 0.068249255 },
      { prefix: '00', frequency: 0.06676558 },
      { prefix: '101', frequency: 0.06305638 },
      { prefix: '9', frequency: 0.06231454 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.17359051 },
      { suffix: '1', frequency: 0.12759644 },
      { suffix: '00', frequency: 0.10163205 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.27893174 },
      { format: 'ddddddddd', frequency: 0.1231454 },
      { format: 'dddddddddddd', frequency: 0.09792285 },
      { format: 'dddddddddd', frequency: 0.08308605 },
      { format: 'adddddddd', frequency: 0.068991095 },
      { format: 'ddddddddddd', frequency: 0.058605343 },
      { format: 'aaddddddd', frequency: 0.03560831 },
      { format: 'addddsddddd', frequency: 0.034124628 },
      { format: 'adddddddddd', frequency: 0.017804155 },
      { format: 'addddddddddd', frequency: 0.014836796 },
      { format: 'dddddddd', frequency: 0.014094955 },
      { format: 'aaaddddddd', frequency: 0.010385756 },
      { format: 'ddddddd', frequency: 0.009643917 },
      { format: 'aaddddddddd', frequency: 0.009643917 },
      { format: 'aaadddaddddd', frequency: 0.008902078 },
      { format: 'dddddddddhdd', frequency: 0.008160237 },
      { format: 'aaaddddddddd', frequency: 0.007418398 },
      { format: 'ddddddddddddd', frequency: 0.007418398 },
      { format: 'ddddsdddddhdd', frequency: 0.005192878 },
      { format: 'adddddddddhdd', frequency: 0.005192878 },
      { format: 'adddddd', frequency: 0.003709199 },
      { format: 'dddddd', frequency: 0.003709199 },
      { format: 'dddddddda', frequency: 0.002967359 },
      { format: 'ddddd', frequency: 0.002967359 },
      { format: 'dddddddddddddd', frequency: 0.002967359 },
      { format: 'addddddd', frequency: 0.002967359 },
      { format: 'ddddddddddddddd', frequency: 0.002967359 },
      { format: 'addddddddhdd', frequency: 0.002967359 },
      { format: 'adddd', frequency: 0.002967359 },
      { format: 'dddd', frequency: 0.0022255194 },
      { format: 'dddddaddddd', frequency: 0.0022255194 },
      { format: 'dddddsdddd', frequency: 0.0022255194 },
      { format: 'aa', frequency: 0.0022255194 },
      { format: 'aaaaaaaa', frequency: 0.0022255194 },
      { format: 'aaadddddd', frequency: 0.0022255194 },
      { format: 'adadddaddddd', frequency: 0.0022255194 },
      { format: 'aaadddddddd', frequency: 0.0022255194 },
      { format: 'aaddddda', frequency: 0.0014836795 },
      { format: 'ddddhdddd', frequency: 0.0014836795 },
      { format: 'aaaaddddddd', frequency: 0.0014836795 },
      { format: 'adaddddddddd', frequency: 0.0014836795 },
      { format: 'dddhddhdddd', frequency: 0.0014836795 },
      { format: 'ddddddhddhddd', frequency: 0.0014836795 },
      { format: 'aaadddddddaa', frequency: 0.0014836795 },
    ],
  },
  {
    front_end_carrier_id: 230,
    prefixes: [
      { prefix: 'W28', frequency: 0.07435508 },
      { prefix: '00', frequency: 0.06525038 },
      { prefix: 'W27', frequency: 0.06069803 },
      { prefix: '101', frequency: 0.057663128 },
      { prefix: '2', frequency: 0.056145675 },
      { prefix: '9', frequency: 0.053110775 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.17147192 },
      { suffix: '1', frequency: 0.13505311 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.23520486 },
      { format: 'ddddddddd', frequency: 0.1168437 },
      { format: 'dddddddddd', frequency: 0.09256449 },
      { format: 'dddddddddddd', frequency: 0.08801214 },
      { format: 'adddddddd', frequency: 0.059180576 },
      { format: 'ddddddddddd', frequency: 0.054628223 },
      { format: 'dddddaddddd', frequency: 0.050075874 },
      { format: 'aaddddddd', frequency: 0.04400607 },
      { format: 'addddsddddd', frequency: 0.033383913 },
      { format: 'aaddddddddd', frequency: 0.015174507 },
      { format: 'dddddddd', frequency: 0.013657056 },
      { format: 'adddddddddd', frequency: 0.013657056 },
      { format: 'ddddddd', frequency: 0.012139605 },
      { format: 'aaadddaddddd', frequency: 0.012139605 },
      { format: 'addddddddddd', frequency: 0.0091047045 },
      { format: 'aaaddddddd', frequency: 0.0091047045 },
      { format: 'ddddsdddddhdd', frequency: 0.0060698027 },
      { format: 'aaaddddddddd', frequency: 0.0060698027 },
      { format: 'dddddddddhdd', frequency: 0.0060698027 },
      { format: 'adddddddddhdd', frequency: 0.0060698027 },
      { format: 'dddddddddddddd', frequency: 0.0045523522 },
      { format: 'addddddd', frequency: 0.0045523522 },
      { format: 'aaadddddd', frequency: 0.0045523522 },
      { format: 'ddddd', frequency: 0.0045523522 },
      { format: 'dddddd', frequency: 0.0045523522 },
      { format: 'ddddddddddddd', frequency: 0.0030349013 },
      { format: 'dddddsdddd', frequency: 0.0030349013 },
      { format: 'addddsdddddhdd', frequency: 0.0030349013 },
      { format: 'aaadddddddd', frequency: 0.0030349013 },
      { format: 'aaaadddddddd', frequency: 0.0030349013 },
      { format: 'ddddddddddddddd', frequency: 0.0030349013 },
      { format: 'dddaddddd', frequency: 0.0030349013 },
      { format: 'adadddaddddd', frequency: 0.0030349013 },
      { format: 'adaddddddddd', frequency: 0.0030349013 },
      { format: 'adddd', frequency: 0.0030349013 },
      { format: 'ddddaddddd', frequency: 0.0030349013 },
      { format: 'adadddddddddddd', frequency: 0.0030349013 },
      { format: 'aaaddddaaaa', frequency: 0.0015174507 },
      { format: 'aaadddddddddddd', frequency: 0.0015174507 },
      { format: 'aaaddddaddd', frequency: 0.0015174507 },
      { format: 'dddd', frequency: 0.0015174507 },
      { format: 'aaaaddddddd', frequency: 0.0015174507 },
      { format: 'ddddddhddhddd', frequency: 0.0015174507 },
      { format: 'dddhddhdddd', frequency: 0.0015174507 },
      { format: 'aadddddddd', frequency: 0.0015174507 },
      { format: 'ddddhdddd', frequency: 0.0015174507 },
      { format: 'a', frequency: 0.0015174507 },
      { format: 'dddddddddddsdd', frequency: 0.0015174507 },
      { format: 'aaaadddadddd', frequency: 0.0015174507 },
      { format: 'aaadddddddda', frequency: 0.0015174507 },
      { format: 'xdddddxsdddddddddd', frequency: 0.0015174507 },
      { format: 'aaaaaddddddd', frequency: 0.0015174507 },
      { format: 'aaddddda', frequency: 0.0015174507 },
      { format: 'aaaahaaahaaahddddhddddddd', frequency: 0.0015174507 },
      { format: 'aaaaaadddddd', frequency: 0.0015174507 },
      { format: 'asdddddddddd', frequency: 0.0015174507 },
      { format: 'dddaadddddd', frequency: 0.0015174507 },
      { format: 'aaddddddddddd', frequency: 0.0015174507 },
      { format: 'ddddddddddsdd', frequency: 0.0015174507 },
      { format: 'd', frequency: 0.0015174507 },
      { format: 'aaaaaaaa', frequency: 0.0015174507 },
      { format: 'dddadd', frequency: 0.0015174507 },
      { format: 'dddddddda', frequency: 0.0015174507 },
      { format: 'aaaaaaasaa', frequency: 0.0015174507 },
      { format: 'aa', frequency: 0.0015174507 },
      { format: 'addddddddddhdd', frequency: 0.0015174507 },
      { format: 'adddddd', frequency: 0.0015174507 },
      { format: 'dddhddddddd', frequency: 0.0015174507 },
      { format: 'ddd', frequency: 0.0015174507 },
      { format: 'dddddddddsdd', frequency: 0.0015174507 },
      { format: 'adaaaddddddd', frequency: 0.0015174507 },
      { format: 'dddadaaaadda', frequency: 0.0015174507 },
      { format: 'ddddsddddd', frequency: 0.0015174507 },
      { format: 'addaa', frequency: 0.0015174507 },
      { format: 'aaadddddddaa', frequency: 0.0015174507 },
    ],
  },
  {
    front_end_carrier_id: 231,
    prefixes: [
      { prefix: '0', frequency: 0.15355805 },
      { prefix: 'SH02', frequency: 0.09363296 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.20973782 },
      { suffix: '2', frequency: 0.1423221 },
      { suffix: '0', frequency: 0.12734082 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.23970038 },
      { format: 'dddddaddddd', frequency: 0.14981273 },
      { format: 'adddddddddd', frequency: 0.11610487 },
      { format: 'aaddddddddd', frequency: 0.10861423 },
      { format: 'adddddddd', frequency: 0.052434456 },
      { format: 'ddddddddddd', frequency: 0.04494382 },
      { format: 'dddddddd', frequency: 0.037453182 },
      { format: 'addddddddsdd', frequency: 0.029962547 },
      { format: 'dddddddddd', frequency: 0.026217228 },
      { format: 'ddddddd', frequency: 0.026217228 },
      { format: 'aaddddddddddd', frequency: 0.02247191 },
      { format: 'aadddddddddd', frequency: 0.02247191 },
      { format: 'dddddddddddd', frequency: 0.014981274 },
      { format: 'aaddddddd', frequency: 0.011235955 },
      { format: 'addddddddaaa', frequency: 0.007490637 },
      { format: 'aaadddddd', frequency: 0.007490637 },
      { format: 'aaadddddddd', frequency: 0.007490637 },
      { format: 'addddddddddd', frequency: 0.007490637 },
      { format: 'aaaddddddd', frequency: 0.0037453184 },
      { format: 'd', frequency: 0.0037453184 },
      { format: 'ddddddddddddd', frequency: 0.0037453184 },
      { format: 'addddddddd', frequency: 0.0037453184 },
      { format: 'dddddddddsdd', frequency: 0.0037453184 },
      { format: 'dddddaadddd', frequency: 0.0037453184 },
      { format: 'aadddddddd', frequency: 0.0037453184 },
      { format: 'aa', frequency: 0.0037453184 },
      { format: 'dddddddddhdd', frequency: 0.0037453184 },
      { format: 'aadddddddddsdd', frequency: 0.0037453184 },
      { format: 'dddddddddddddd', frequency: 0.0037453184 },
      { format: 'ddddddddda', frequency: 0.0037453184 },
      { format: 'aaaddddddddsdd', frequency: 0.0037453184 },
      { format: 'dddddadddddd', frequency: 0.0037453184 },
      { format: 'aaasaaddddddd', frequency: 0.0037453184 },
      { format: 'aaadddddaddddd', frequency: 0.0037453184 },
      { format: 'addddddd', frequency: 0.0037453184 },
      { format: 'aaaddddddddd', frequency: 0.0037453184 },
    ],
  },
  {
    front_end_carrier_id: 233,
    prefixes: [
      { prefix: 'W28', frequency: 0.14637198 },
      { prefix: 'W27', frequency: 0.09090909 },
      { prefix: '101', frequency: 0.06588824 },
      { prefix: '9', frequency: 0.06005004 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16826522 },
      { suffix: '1', frequency: 0.13573812 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.41951627 },
      { format: 'dddddddddddd', frequency: 0.103628024 },
      { format: 'ddddddddd', frequency: 0.07443703 },
      { format: 'adddddddd', frequency: 0.07276897 },
      { format: 'dddddddddd', frequency: 0.06651376 },
      { format: 'addddsddddd', frequency: 0.04566305 },
      { format: 'ddddddddddd', frequency: 0.034612175 },
      { format: 'aaddddddd', frequency: 0.028356964 },
      { format: 'aaadddaddddd', frequency: 0.016263554 },
      { format: 'addddddddddd', frequency: 0.015429525 },
      { format: 'adddddddddd', frequency: 0.009591326 },
      { format: 'aaaddddddddd', frequency: 0.00792327 },
      { format: 'aaddddddddd', frequency: 0.0077147624 },
      { format: 'adddddddddhdd', frequency: 0.007089241 },
      { format: 'aaaddddddd', frequency: 0.0062552127 },
      { format: 'dddddddd', frequency: 0.0054211845 },
      { format: 'aaadddddddd', frequency: 0.0045871558 },
      { format: 'dddddddddhdd', frequency: 0.0045871558 },
      { format: 'adadddaddddd', frequency: 0.004378649 },
      { format: 'dddddddda', frequency: 0.003961635 },
      { format: 'ddddddddddddd', frequency: 0.0035446205 },
      { format: 'addddddd', frequency: 0.0029190993 },
      { format: 'addddddddhdd', frequency: 0.0029190993 },
      { format: 'ddddddddaaaa', frequency: 0.0025020852 },
      { format: 'addddsdddddhdd', frequency: 0.0022935779 },
      { format: 'adddddd', frequency: 0.0018765638 },
      { format: 'ddddddddddsdd', frequency: 0.0018765638 },
      { format: 'addddddddsdd', frequency: 0.0014595496 },
      { format: 'dddddaddddd', frequency: 0.0014595496 },
      { format: 'aaaaaddddddd', frequency: 0.0014595496 },
      { format: 'ddddddd', frequency: 0.0014595496 },
      { format: 'aadddddddd', frequency: 0.0012510426 },
      { format: 'aaadddddddaa', frequency: 0.0012510426 },
      { format: 'aaadddddddda', frequency: 0.0012510426 },
      { format: 'ddddsdddddhdd', frequency: 0.0010425354 },
      { format: 'adaddddddddd', frequency: 0.0010425354 },
    ],
  },
  {
    front_end_carrier_id: 234,
    prefixes: [
      { prefix: '10', frequency: 0.21663827 },
      { prefix: '109', frequency: 0.10491198 },
      { prefix: 'U8', frequency: 0.0834753 },
      { prefix: '00', frequency: 0.07211812 },
      { prefix: '9', frequency: 0.0653038 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22629188 },
      { suffix: '2', frequency: 0.13898353 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29358318 },
      { format: 'adddddddddd', frequency: 0.23055083 },
      { format: 'adddddddd', frequency: 0.111442365 },
      { format: 'addddddddsdd', frequency: 0.074247584 },
      { format: 'ddddddddddd', frequency: 0.06246451 },
      { format: 'dddddddddddd', frequency: 0.05124929 },
      { format: 'dddddddd', frequency: 0.028109029 },
      { format: 'dddddddddd', frequency: 0.01689381 },
      { format: 'aaddddddd', frequency: 0.012776831 },
      { format: 'dddddddddsdd', frequency: 0.012634867 },
      { format: 'aaadddddd', frequency: 0.010505395 },
      { format: 'dddddaddddd', frequency: 0.007950028 },
      { format: 'aaaddddddddhdd', frequency: 0.007950028 },
      { format: 'dddddddddhdd', frequency: 0.00709824 },
      { format: 'aaadddddddd', frequency: 0.0058205565 },
      { format: 'ddddddd', frequency: 0.005678592 },
      { format: 'ddddddddda', frequency: 0.005678592 },
      { format: 'aaaaddddd', frequency: 0.0048268028 },
      { format: 'addddddddhdd', frequency: 0.0038330494 },
      { format: 'aaaddddddd', frequency: 0.003407155 },
      { format: 'aaaddddddddd', frequency: 0.0031232254 },
      { format: 'aaadddddddddd', frequency: 0.0026973311 },
      { format: 'addddddddd', frequency: 0.0026973311 },
      { format: 'dddddd', frequency: 0.0022714366 },
      { format: 'aadddddddddd', frequency: 0.0018455423 },
      { format: 'addddddddaad', frequency: 0.0018455423 },
      { format: 'ddddddddddddd', frequency: 0.0015616127 },
      { format: 'aaddddddddddd', frequency: 0.0015616127 },
      { format: 'addddddd', frequency: 0.0015616127 },
      { format: 'dddddddddddddd', frequency: 0.0012776832 },
      { format: 'addddddddddd', frequency: 0.0011357183 },
    ],
  },
  {
    front_end_carrier_id: 235,
    prefixes: [
      { prefix: 'A00', frequency: 0.35142118 },
      { prefix: '1', frequency: 0.2015504 },
      { prefix: 'A000', frequency: 0.18087855 },
      { prefix: 'U', frequency: 0.14470284 },
      { prefix: 'A001', frequency: 0.090439275 },
      { prefix: 'A002', frequency: 0.08010336 },
      { prefix: '0', frequency: 0.059431523 },
    ],
    suffixes: [{ suffix: 'APU', frequency: 0.14470284 }],
    formats: [
      { format: 'adddddddd', frequency: 0.24806201 },
      { format: 'ddddddddd', frequency: 0.21447028 },
      { format: 'addddddddaaa', frequency: 0.14470284 },
      { format: 'adddddddddd', frequency: 0.11369509 },
      { format: 'ddddddddddd', frequency: 0.054263566 },
      { format: 'aadddddddddd', frequency: 0.031007752 },
      { format: 'dddddddd', frequency: 0.031007752 },
      { format: 'dddddddddd', frequency: 0.031007752 },
      { format: 'addddddddsdd', frequency: 0.025839793 },
      { format: 'ddddddd', frequency: 0.015503876 },
      { format: 'dddddddddddd', frequency: 0.012919896 },
      { format: 'addddddddddd', frequency: 0.007751938 },
      { format: 'aaadddddd', frequency: 0.005167959 },
      { format: 'addddddddd', frequency: 0.005167959 },
      { format: 'addddddddhdd', frequency: 0.005167959 },
      { format: 'adddddddaaa', frequency: 0.005167959 },
      { format: 'ddddddddsdd', frequency: 0.0025839794 },
      { format: 'd', frequency: 0.0025839794 },
      { format: 'ddddddddddddsxax', frequency: 0.0025839794 },
      { format: 'ddddddddddddd', frequency: 0.0025839794 },
      { format: 'ddaddddddd', frequency: 0.0025839794 },
      { format: 'adddddddsdd', frequency: 0.0025839794 },
      { format: 'addaaadddddd', frequency: 0.0025839794 },
      { format: 'daaddddddddsdd', frequency: 0.0025839794 },
      { format: 'aaaddddddddhdd', frequency: 0.0025839794 },
      { format: 'dddddddddhdd', frequency: 0.0025839794 },
      { format: 'ddddd', frequency: 0.0025839794 },
      { format: 'aaddddddd', frequency: 0.0025839794 },
      { format: 'ddd', frequency: 0.0025839794 },
      { format: 'aaadddddddhdd', frequency: 0.0025839794 },
      { format: 'ddddddddda', frequency: 0.0025839794 },
      { format: 'ddddddddhdd', frequency: 0.0025839794 },
      { format: 'aaaddddddddsdd', frequency: 0.0025839794 },
      { format: 'aaadddddaddddd', frequency: 0.0025839794 },
      { format: 'aaaddddddddd', frequency: 0.0025839794 },
    ],
  },
  {
    front_end_carrier_id: 236,
    prefixes: [
      { prefix: 'W2', frequency: 0.24012639 },
      { prefix: '10', frequency: 0.08135861 },
      { prefix: 'W28', frequency: 0.07582939 },
      { prefix: '00', frequency: 0.070300154 },
      { prefix: '9', frequency: 0.070300154 },
      { prefix: '2', frequency: 0.061611373 },
      { prefix: 'W27', frequency: 0.05292259 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.17377567 },
      { suffix: '1', frequency: 0.12085308 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.21327014 },
      { format: 'ddddddddd', frequency: 0.19273302 },
      { format: 'dddddddddd', frequency: 0.083728276 },
      { format: 'dddddddddddd', frequency: 0.08135861 },
      { format: 'adddddddd', frequency: 0.07187994 },
      { format: 'ddddddddddd', frequency: 0.061611373 },
      { format: 'aaddddddd', frequency: 0.037914693 },
      { format: 'addddsddddd', frequency: 0.030015798 },
      { format: 'adddddddddd', frequency: 0.019747235 },
      { format: 'dddddddd', frequency: 0.015797788 },
      { format: 'addddddddddd', frequency: 0.01342812 },
      { format: 'aaaddddddd', frequency: 0.011058452 },
      { format: 'ddddddd', frequency: 0.0102685625 },
      { format: 'aaddddddddd', frequency: 0.0102685625 },
      { format: 'aaadddaddddd', frequency: 0.009478673 },
      { format: 'dddddddddhdd', frequency: 0.009478673 },
      { format: 'aaaddddddddd', frequency: 0.0063191154 },
      { format: 'ddddddddddddd', frequency: 0.0063191154 },
      { format: 'dddddd', frequency: 0.005529226 },
      { format: 'ddddsdddddhdd', frequency: 0.0047393367 },
      { format: 'adddddd', frequency: 0.0047393367 },
      { format: 'ddddd', frequency: 0.003949447 },
      { format: 'dddddddddddddd', frequency: 0.0031595577 },
      { format: 'dddddaddddd', frequency: 0.0031595577 },
      { format: 'adddddddddhdd', frequency: 0.0031595577 },
      { format: 'ddddddddddddddd', frequency: 0.0031595577 },
      { format: 'addddddddhdd', frequency: 0.0031595577 },
      { format: 'adddd', frequency: 0.0031595577 },
      { format: 'dddddddda', frequency: 0.0023696683 },
      { format: 'addddddd', frequency: 0.0023696683 },
      { format: 'dddddsdddd', frequency: 0.0023696683 },
      { format: 'aa', frequency: 0.0023696683 },
      { format: 'aaaaaaaa', frequency: 0.0023696683 },
      { format: 'aaadddddd', frequency: 0.0023696683 },
      { format: 'adadddaddddd', frequency: 0.0023696683 },
      { format: 'aaadddddddd', frequency: 0.0023696683 },
      { format: 'aaddddda', frequency: 0.0015797789 },
      { format: 'dddd', frequency: 0.0015797789 },
      { format: 'addddhdddd', frequency: 0.0015797789 },
      { format: 'addddsdddddhdd', frequency: 0.0015797789 },
      { format: 'ddddhdddd', frequency: 0.0015797789 },
      { format: 'aaaaaaa', frequency: 0.0015797789 },
      { format: 'aaaaddddddd', frequency: 0.0015797789 },
      { format: 'adaddddddddd', frequency: 0.0015797789 },
      { format: 'dddhddhdddd', frequency: 0.0015797789 },
      { format: 'ddddsddddd', frequency: 0.0015797789 },
      { format: 'aaadddddddaa', frequency: 0.0015797789 },
    ],
  },
  {
    front_end_carrier_id: 237,
    prefixes: [
      { prefix: '1', frequency: 0.43277848 },
      { prefix: 'U', frequency: 0.14980794 },
      { prefix: '110', frequency: 0.13828425 },
      { prefix: '0', frequency: 0.064020485 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.2099872 },
      { suffix: '01', frequency: 0.13956466 },
      { suffix: '0', frequency: 0.12419974 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.46734956 },
      { format: 'adddddddddd', frequency: 0.09475032 },
      { format: 'adddddddd', frequency: 0.081946224 },
      { format: 'ddddddddddd', frequency: 0.07682458 },
      { format: 'dddddddd', frequency: 0.0396927 },
      { format: 'dddddddddd', frequency: 0.03841229 },
      { format: 'addddddddsdd', frequency: 0.028169014 },
      { format: 'aadddddddddd', frequency: 0.026888605 },
      { format: 'ddddddd', frequency: 0.021766966 },
      { format: 'dddddddddddd', frequency: 0.010243278 },
      { format: 'dddddddddsdd', frequency: 0.010243278 },
      { format: 'addddddddd', frequency: 0.0076824585 },
      { format: 'dddddddddhdd', frequency: 0.0076824585 },
      { format: 'addddddd', frequency: 0.0064020487 },
      { format: 'aaaddddddd', frequency: 0.005121639 },
      { format: 'aaadddddd', frequency: 0.005121639 },
      { format: 'addddddddddd', frequency: 0.005121639 },
      { format: 'aaaddddddddd', frequency: 0.005121639 },
      { format: 'addddddddhdd', frequency: 0.0038412292 },
      { format: 'aaddddddd', frequency: 0.0038412292 },
      { format: 'aaaddddddddhdd', frequency: 0.0038412292 },
      { format: 'ddddd', frequency: 0.0038412292 },
      { format: 'ddaddddddd', frequency: 0.0038412292 },
      { format: 'ddddddddda', frequency: 0.0025608195 },
      { format: 'aaadddddddddddd', frequency: 0.0012804097 },
      { format: 'd', frequency: 0.0012804097 },
      { format: 'ddddddddddddd', frequency: 0.0012804097 },
      { format: 'dddddd', frequency: 0.0012804097 },
      { format: 'addaaadddddd', frequency: 0.0012804097 },
      { format: 'adaddddddddd', frequency: 0.0012804097 },
      { format: 'dddddddddddddd', frequency: 0.0012804097 },
      { format: 'aadaaaadd', frequency: 0.0012804097 },
      { format: 'addddsddddd', frequency: 0.0012804097 },
      { format: 'dddddaddddd', frequency: 0.0012804097 },
      { format: 'dddddddddxddd', frequency: 0.0012804097 },
      { format: 'xdddxsdddhdddd', frequency: 0.0012804097 },
      { format: 'aaaaaaaa', frequency: 0.0012804097 },
      { format: 'aaddddddddd', frequency: 0.0012804097 },
      { format: 'ddddddddsdd', frequency: 0.0012804097 },
      { format: 'aaaddddd', frequency: 0.0012804097 },
      { format: 'ddddddddddddsxax', frequency: 0.0012804097 },
      { format: 'aaa', frequency: 0.0012804097 },
      { format: 'addddddddaaa', frequency: 0.0012804097 },
      { format: 'daaddddddddsdd', frequency: 0.0012804097 },
      { format: 'dddddddddddda', frequency: 0.0012804097 },
      { format: 'aa', frequency: 0.0012804097 },
      { format: 'adddd', frequency: 0.0012804097 },
      { format: 'aaaaddddd', frequency: 0.0012804097 },
      { format: 'dddddaddd', frequency: 0.0012804097 },
      { format: 'dddddddddddddddd', frequency: 0.0012804097 },
      { format: 'ddd', frequency: 0.0012804097 },
      { format: 'adddddddddda', frequency: 0.0012804097 },
      { format: 'aaadddddddhdd', frequency: 0.0012804097 },
      { format: 'ddddddddhdd', frequency: 0.0012804097 },
      { format: 'aaaddddddddsdd', frequency: 0.0012804097 },
    ],
  },
  {
    front_end_carrier_id: 238,
    prefixes: [
      { prefix: '10', frequency: 0.21576467 },
      { prefix: '109', frequency: 0.10381265 },
      { prefix: 'U8', frequency: 0.08410681 },
      { prefix: '00', frequency: 0.07325432 },
      { prefix: '9', frequency: 0.06268742 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22518921 },
      { suffix: '2', frequency: 0.13794088 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29158932 },
      { format: 'adddddddddd', frequency: 0.23061545 },
      { format: 'adddddddd', frequency: 0.112808794 },
      { format: 'addddddddsdd', frequency: 0.07296873 },
      { format: 'ddddddddddd', frequency: 0.063972585 },
      { format: 'dddddddddddd', frequency: 0.05140654 },
      { format: 'dddddddd', frequency: 0.028273597 },
      { format: 'dddddddddd', frequency: 0.016992718 },
      { format: 'dddddddddsdd', frequency: 0.012708839 },
      { format: 'aaddddddd', frequency: 0.012708839 },
      { format: 'aaadddddd', frequency: 0.010424104 },
      { format: 'dddddaddddd', frequency: 0.008139369 },
      { format: 'aaaddddddddhdd', frequency: 0.007710981 },
      { format: 'dddddddddhdd', frequency: 0.0071397973 },
      { format: 'ddddddd', frequency: 0.0058546336 },
      { format: 'aaadddddddd', frequency: 0.0058546336 },
      { format: 'ddddddddda', frequency: 0.0057118377 },
      { format: 'aaaaddddd', frequency: 0.00528345 },
      { format: 'addddddddhdd', frequency: 0.0038554906 },
      { format: 'aaaddddddd', frequency: 0.0034271027 },
      { format: 'aaaddddddddd', frequency: 0.0032843067 },
      { format: 'addddddddd', frequency: 0.0029987148 },
      { format: 'aaadddddddddd', frequency: 0.0027131229 },
      { format: 'dddddd', frequency: 0.0022847352 },
      { format: 'addddddddaad', frequency: 0.0019991433 },
      { format: 'aadddddddddd', frequency: 0.0018563473 },
      { format: 'addddddd', frequency: 0.0017135513 },
      { format: 'aaddddddddddd', frequency: 0.0015707554 },
      { format: 'dddddddddddddd', frequency: 0.0014279594 },
      { format: 'ddddddddddddd', frequency: 0.0014279594 },
    ],
  },
  {
    front_end_carrier_id: 240,
    prefixes: [
      { prefix: '10', frequency: 0.48677248 },
      { prefix: '100', frequency: 0.3809524 },
      { prefix: '1004', frequency: 0.27513227 },
      { prefix: '10046', frequency: 0.1005291 },
      { prefix: '1005', frequency: 0.0952381 },
      { prefix: '10045', frequency: 0.08994709 },
      { prefix: '109', frequency: 0.06878307 },
      { prefix: '00', frequency: 0.06613757 },
      { prefix: '110', frequency: 0.052910052 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.2037037 },
      { suffix: '01', frequency: 0.18518518 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.27513227 },
      { format: 'ddddddddddd', frequency: 0.26455027 },
      { format: 'adddddddddd', frequency: 0.08994709 },
      { format: 'dddddddddxdd', frequency: 0.071428575 },
      { format: 'adddddddd', frequency: 0.06613757 },
      { format: 'dddddddddd', frequency: 0.02910053 },
      { format: 'aadddddddddd', frequency: 0.02910053 },
      { format: 'dddddddddsdd', frequency: 0.023809524 },
      { format: 'dddddddd', frequency: 0.023809524 },
      { format: 'addddddddsdd', frequency: 0.02116402 },
      { format: 'ddddddd', frequency: 0.015873017 },
      { format: 'addddddddhdd', frequency: 0.007936508 },
      { format: 'dddddddddddd', frequency: 0.007936508 },
      { format: 'addddddddddd', frequency: 0.007936508 },
      { format: 'dddddddddhdd', frequency: 0.007936508 },
      { format: 'aaadddddd', frequency: 0.005291005 },
      { format: 'aaaddddddd', frequency: 0.0026455026 },
      { format: 'ddddddddsdd', frequency: 0.0026455026 },
      { format: 'd', frequency: 0.0026455026 },
      { format: 'ddddddddddddsxax', frequency: 0.0026455026 },
      { format: 'ddddddddddddd', frequency: 0.0026455026 },
      { format: 'ddaddddddd', frequency: 0.0026455026 },
      { format: 'adddddddsdd', frequency: 0.0026455026 },
      { format: 'addaaadddddd', frequency: 0.0026455026 },
      { format: 'addddddddaaa', frequency: 0.0026455026 },
      { format: 'addddddddd', frequency: 0.0026455026 },
      { format: 'daaddddddddsdd', frequency: 0.0026455026 },
      { format: 'ddddd', frequency: 0.0026455026 },
      { format: 'aaddddddd', frequency: 0.0026455026 },
      { format: 'ddddsddddsddd', frequency: 0.0026455026 },
      { format: 'ddd', frequency: 0.0026455026 },
      { format: 'ddddddddhdd', frequency: 0.0026455026 },
      { format: 'aaaddddddddsdd', frequency: 0.0026455026 },
      { format: 'aaadddddaddddd', frequency: 0.0026455026 },
      { format: 'aaddddddddddd', frequency: 0.0026455026 },
      { format: 'aaaddddddddd', frequency: 0.0026455026 },
    ],
  },
  {
    front_end_carrier_id: 243,
    prefixes: [
      { prefix: '110', frequency: 0.14697802 },
      { prefix: '109', frequency: 0.11401099 },
      { prefix: '0', frequency: 0.06593407 },
      { prefix: '300', frequency: 0.054945055 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.21016483 },
      { suffix: '0', frequency: 0.1717033 },
      { suffix: '01', frequency: 0.14835165 },
      { suffix: '00', frequency: 0.11263736 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.4052198 },
      { format: 'ddddddddddd', frequency: 0.13598901 },
      { format: 'adddddddd', frequency: 0.09203297 },
      { format: 'adddddddddd', frequency: 0.08379121 },
      { format: 'dddddddd', frequency: 0.03846154 },
      { format: 'dddddddddd', frequency: 0.03159341 },
      { format: 'aadddddddddd', frequency: 0.028846154 },
      { format: 'addddddddsdd', frequency: 0.027472528 },
      { format: 'ddddddd', frequency: 0.020604396 },
      { format: 'dddddddddddd', frequency: 0.017857144 },
      { format: 'dddddddddhdd', frequency: 0.009615385 },
      { format: 'dddddddddsdd', frequency: 0.009615385 },
      { format: 'addddddddd', frequency: 0.008241759 },
      { format: 'addddddddddd', frequency: 0.008241759 },
      { format: 'aaaddddddddd', frequency: 0.006868132 },
      { format: 'aaadddddd', frequency: 0.0054945056 },
      { format: 'aaddddddd', frequency: 0.0054945056 },
      { format: 'addddddd', frequency: 0.0041208793 },
      { format: 'addddddddhdd', frequency: 0.0041208793 },
      { format: 'ddaddddddd', frequency: 0.0041208793 },
      { format: 'dddddd', frequency: 0.0027472528 },
      { format: 'ddddd', frequency: 0.0027472528 },
      { format: 'ddddddddda', frequency: 0.0027472528 },
      { format: 'aaadddddddddddd', frequency: 0.0013736264 },
      { format: 'd', frequency: 0.0013736264 },
      { format: 'ddddddddddddd', frequency: 0.0013736264 },
      { format: 'addaaadddddd', frequency: 0.0013736264 },
      { format: 'adaddddddddd', frequency: 0.0013736264 },
      { format: 'dddddddddddddd', frequency: 0.0013736264 },
      { format: 'aadaaaadd', frequency: 0.0013736264 },
      { format: 'addddsddddd', frequency: 0.0013736264 },
      { format: 'aaaddddddddhdd', frequency: 0.0013736264 },
      { format: 'dddddaddddd', frequency: 0.0013736264 },
      { format: 'dddddddddxddd', frequency: 0.0013736264 },
      { format: 'xdddxsdddhdddd', frequency: 0.0013736264 },
      { format: 'aaaaaaaa', frequency: 0.0013736264 },
      { format: 'aaadddddaddddd', frequency: 0.0013736264 },
      { format: 'aaddddddddd', frequency: 0.0013736264 },
      { format: 'ddddddddsdd', frequency: 0.0013736264 },
      { format: 'aaaddddd', frequency: 0.0013736264 },
      { format: 'ddddddddddddsxax', frequency: 0.0013736264 },
      { format: 'aaa', frequency: 0.0013736264 },
      { format: 'addddddddaaa', frequency: 0.0013736264 },
      { format: 'daaddddddddsdd', frequency: 0.0013736264 },
      { format: 'dddddddddddda', frequency: 0.0013736264 },
      { format: 'aa', frequency: 0.0013736264 },
      { format: 'adddd', frequency: 0.0013736264 },
      { format: 'dddddaddd', frequency: 0.0013736264 },
      { format: 'dddddddddddddddd', frequency: 0.0013736264 },
      { format: 'ddd', frequency: 0.0013736264 },
      { format: 'adddddddddda', frequency: 0.0013736264 },
      { format: 'aaadddddddhdd', frequency: 0.0013736264 },
      { format: 'ddddddddhdd', frequency: 0.0013736264 },
      { format: 'aaaddddddddsdd', frequency: 0.0013736264 },
      { format: 'aaaddddddd', frequency: 0.0013736264 },
    ],
  },
  {
    front_end_carrier_id: 244,
    prefixes: [
      { prefix: '10', frequency: 0.22235948 },
      { prefix: '109', frequency: 0.10994441 },
      { prefix: 'U8', frequency: 0.085546635 },
      { prefix: '00', frequency: 0.07566399 },
      { prefix: '9', frequency: 0.05898703 },
    ],
    suffixes: [
      { suffix: '01', frequency: 0.22869055 },
      { suffix: '2', frequency: 0.14067325 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.29786906 },
      { format: 'adddddddddd', frequency: 0.23409513 },
      { format: 'adddddddd', frequency: 0.09496603 },
      { format: 'addddddddsdd', frequency: 0.07890673 },
      { format: 'ddddddddddd', frequency: 0.06315627 },
      { format: 'dddddddddddd', frequency: 0.054972205 },
      { format: 'dddddddd', frequency: 0.026559604 },
      { format: 'dddddddddd', frequency: 0.017757876 },
      { format: 'aaddddddd', frequency: 0.013125386 },
      { format: 'dddddddddsdd', frequency: 0.0115812225 },
      { format: 'aaadddddd', frequency: 0.010500309 },
      { format: 'aaaddddddddhdd', frequency: 0.00833848 },
      { format: 'dddddaddddd', frequency: 0.008184064 },
      { format: 'ddddddddda', frequency: 0.006022236 },
      { format: 'aaadddddddd', frequency: 0.006022236 },
      { format: 'ddddddd', frequency: 0.00586782 },
      { format: 'aaaaddddd', frequency: 0.005558987 },
      { format: 'dddddddddhdd', frequency: 0.0052501545 },
      { format: 'addddddddhdd', frequency: 0.0038604077 },
      { format: 'aaaddddddd', frequency: 0.0037059914 },
      { format: 'aaaddddddddd', frequency: 0.0032427425 },
      { format: 'addddddddd', frequency: 0.0030883262 },
      { format: 'aaadddddddddd', frequency: 0.0026250773 },
      { format: 'dddddd', frequency: 0.002470661 },
      { format: 'addddddddaad', frequency: 0.0021618283 },
      { format: 'aadddddddddd', frequency: 0.0018529957 },
      { format: 'ddddddddddddd', frequency: 0.0016985794 },
      { format: 'aaddddddddddd', frequency: 0.0016985794 },
      { format: 'dddddddddddddd', frequency: 0.0013897468 },
      { format: 'addddddd', frequency: 0.0013897468 },
      { format: 'aaaaadddddd', frequency: 0.0010809142 },
    ],
  },
  {
    front_end_carrier_id: 246,
    prefixes: [
      { prefix: '992', frequency: 0.18107833 },
      { prefix: 'N01', frequency: 0.14649034 },
      { prefix: '7', frequency: 0.088504575 },
      { prefix: '8', frequency: 0.078331634 },
      { prefix: '4', frequency: 0.051881995 },
      { prefix: '1', frequency: 0.0508647 },
    ],
    suffixes: [{ suffix: '01', frequency: 0.6480163 }],
    formats: [
      { format: 'ddddadddddd', frequency: 0.40895218 },
      { format: 'adddddddddd', frequency: 0.19023398 },
      { format: 'ddddddddddd', frequency: 0.13428281 },
      { format: 'ddddddddd', frequency: 0.124109864 },
      { format: 'dddddddddsdd', frequency: 0.03763988 },
      { format: 'aadddddddhdd', frequency: 0.01525941 },
      { format: 'adddddddd', frequency: 0.014242116 },
      { format: 'dddddddddddd', frequency: 0.012207528 },
      { format: 'dddddddddhdd', frequency: 0.009155646 },
      { format: 'aaddddddddd', frequency: 0.009155646 },
      { format: 'dddddddddd', frequency: 0.00508647 },
      { format: 'addddddddhdd', frequency: 0.004069176 },
      { format: 'addddddddd', frequency: 0.003051882 },
      { format: 'aaaaddddd', frequency: 0.002034588 },
      { format: 'dddaadddddd', frequency: 0.002034588 },
      { format: 'dddddddddssdd', frequency: 0.002034588 },
      { format: 'dddddddd', frequency: 0.002034588 },
      { format: 'ddddaddddd', frequency: 0.002034588 },
      { format: 'addddddddddd', frequency: 0.002034588 },
      { format: 'aaadddddddd', frequency: 0.002034588 },
      { format: 'aaaddddddhdd', frequency: 0.001017294 },
      { format: 'ddddaadddad', frequency: 0.001017294 },
      { format: 'dddsdddsdddsddd', frequency: 0.001017294 },
      { format: 'aaddddddd', frequency: 0.001017294 },
      { format: 'aaadddddd', frequency: 0.001017294 },
      { format: 'ddddaddddddd', frequency: 0.001017294 },
      { format: 'aaasddddddhdd', frequency: 0.001017294 },
      { format: 'ddddaddddhdd', frequency: 0.001017294 },
      { format: 'ddddaddd', frequency: 0.001017294 },
      { format: 'aaaddddddddd', frequency: 0.001017294 },
      { format: 'ddddaadddddd', frequency: 0.001017294 },
      { format: 'ddddddddddddd', frequency: 0.001017294 },
      { format: 'dddadddddd', frequency: 0.001017294 },
      { format: 'ddddaaddddd', frequency: 0.001017294 },
      { format: 'ddddadddd', frequency: 0.001017294 },
      { format: 'aaaaddddddd', frequency: 0.001017294 },
      { format: 'dddhdddhddddd', frequency: 0.001017294 },
      { format: 'dddaaadaaad', frequency: 0.001017294 },
    ],
  },
  {
    front_end_carrier_id: 247,
    prefixes: [
      { prefix: 'H', frequency: 0.93871385 },
      { prefix: 'HP', frequency: 0.8541217 },
      { prefix: 'HP6', frequency: 0.536038 },
      { prefix: 'HP62', frequency: 0.18774277 },
      { prefix: 'HP5', frequency: 0.17781614 },
      { prefix: 'HP63', frequency: 0.12861459 },
      { prefix: 'HP61', frequency: 0.11264566 },
      { prefix: 'HP60', frequency: 0.090202846 },
      { prefix: 'HH', frequency: 0.05179111 },
      { prefix: 'HPP', frequency: 0.050927926 },
      { prefix: 'HP59', frequency: 0.050496332 },
    ],
    suffixes: [
      { suffix: '00', frequency: 0.5394907 },
      { suffix: '-00', frequency: 0.34527406 },
      { suffix: '01', frequency: 0.14242555 },
    ],
    formats: [
      { format: 'aadddddddhdd', frequency: 0.49719465 },
      { format: 'aaddddddddd', frequency: 0.24169184 },
      { format: 'aaddddddd', frequency: 0.0694864 },
      { format: 'aaaddddddhdd', frequency: 0.034527406 },
      { format: 'aaaaddddd', frequency: 0.031074665 },
      { format: 'aaadddddd', frequency: 0.018126888 },
      { format: 'aaaadddddhdd', frequency: 0.017695297 },
      { format: 'aaadddddddd', frequency: 0.017263703 },
      { format: 'aaaaddddddd', frequency: 0.011653 },
      { format: 'ddddddddd', frequency: 0.0086318515 },
      { format: 'aaaddaddd', frequency: 0.006042296 },
      { format: 'aaaddddddddd', frequency: 0.005179111 },
      { format: 'dddddddhdd', frequency: 0.0047475183 },
      { format: 'aadddddddd', frequency: 0.0038843332 },
      { format: 'ddddddd', frequency: 0.0021579629 },
      { format: 'aahdddddddhdd', frequency: 0.0021579629 },
      { format: 'aasdddddddhdd', frequency: 0.0021579629 },
      { format: 'adddddddddd', frequency: 0.0021579629 },
      { format: 'ddddadddddd', frequency: 0.0021579629 },
      { format: 'aaddddddhdd', frequency: 0.0017263703 },
      { format: 'aaddddddddhdd', frequency: 0.0017263703 },
      { format: 'aadddddddhd', frequency: 0.0017263703 },
      { format: 'aaaaadddd', frequency: 0.0017263703 },
      { format: 'aadddddddddd', frequency: 0.0012947777 },
      { format: 'aadddddd', frequency: 0.0012947777 },
      { format: 'dddddddd', frequency: 0.0012947777 },
      { format: 'aahddddddd', frequency: 0.0012947777 },
    ],
  },
  {
    front_end_carrier_id: 248,
    prefixes: [
      { prefix: 'XY', frequency: 0.32395115 },
      { prefix: 'R6', frequency: 0.08284652 },
      { prefix: 'XYS9', frequency: 0.0685077 },
      { prefix: 'XYH91', frequency: 0.05841742 },
    ],
    suffixes: [{ suffix: '1', frequency: 0.12108338 }],
    formats: [
      { format: 'aaaddddddddd', frequency: 0.48751992 },
      { format: 'aaaadddddddd', frequency: 0.16728625 },
      { format: 'adddddddd', frequency: 0.1306426 },
      { format: 'aaadddddddddddd', frequency: 0.057355285 },
      { format: 'aaadddaddddd', frequency: 0.03505045 },
      { format: 'adaddddddddd', frequency: 0.022304833 },
      { format: 'adadddaddddd', frequency: 0.014338821 },
      { format: 'aaaaaddddddd', frequency: 0.007966012 },
      { format: 'ddddddddd', frequency: 0.007434944 },
      { format: 'aaadaaadddddddd', frequency: 0.006903877 },
      { format: 'aaadddddddda', frequency: 0.0063728094 },
      { format: 'adadddddddddddd', frequency: 0.0053106747 },
      { format: 'aaadddddddaa', frequency: 0.0042485395 },
      { format: 'aaasddddddddd', frequency: 0.003717472 },
      { format: 'aaaddddddddddd', frequency: 0.003717472 },
      { format: 'adaadddddddd', frequency: 0.003717472 },
      { format: 'aaadddddddddd', frequency: 0.003717472 },
      { format: 'dddddddddd', frequency: 0.0031864047 },
      { format: 'adadaaadddddddd', frequency: 0.0031864047 },
      { format: 'adaaaddddddd', frequency: 0.0026553373 },
      { format: 'adadddddddddd', frequency: 0.0021242697 },
      { format: 'aaahddddddddd', frequency: 0.0021242697 },
      { format: 'aaadddddddd', frequency: 0.0015932024 },
      { format: 'aaadddddd', frequency: 0.0015932024 },
      { format: 'adadddddddaa', frequency: 0.0015932024 },
      { format: 'aaaadddadddd', frequency: 0.0015932024 },
      { format: 'adaddddddddddd', frequency: 0.0010621349 },
      { format: 'asdsdsdsdsdsdsdsd', frequency: 0.0010621349 },
      { format: 'aaaaddddddd', frequency: 0.0010621349 },
      { format: 'aaaddddddddda', frequency: 0.0010621349 },
    ],
  },
  {
    front_end_carrier_id: 250,
    prefixes: [
      { prefix: '1', frequency: 0.50980395 },
      { prefix: '110', frequency: 0.1633987 },
      { prefix: 'U', frequency: 0.14869282 },
      { prefix: '0', frequency: 0.05882353 },
      { prefix: '1103', frequency: 0.052287582 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.19934641 },
      { suffix: '01', frequency: 0.13398694 },
      { suffix: '0', frequency: 0.12908497 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.45261437 },
      { format: 'adddddddddd', frequency: 0.08986928 },
      { format: 'adddddddd', frequency: 0.08496732 },
      { format: 'ddddddddddd', frequency: 0.07679739 },
      { format: 'dddddddd', frequency: 0.04248366 },
      { format: 'addddddddsdd', frequency: 0.03267974 },
      { format: 'dddddddddd', frequency: 0.026143791 },
      { format: 'aadddddddddd', frequency: 0.024509804 },
      { format: 'dddddd', frequency: 0.02124183 },
      { format: 'ddddddd', frequency: 0.019607844 },
      { format: 'dddddddddddd', frequency: 0.013071896 },
      { format: 'dddddddddsdd', frequency: 0.013071896 },
      { format: 'addddddddd', frequency: 0.008169935 },
      { format: 'dddddddddhdd', frequency: 0.008169935 },
      { format: 'addddddd', frequency: 0.006535948 },
      { format: 'aaaddddddddd', frequency: 0.006535948 },
      { format: 'aaadddddd', frequency: 0.004901961 },
      { format: 'aaddddddd', frequency: 0.004901961 },
      { format: 'ddaddddddd', frequency: 0.004901961 },
      { format: 'addddddddhdd', frequency: 0.003267974 },
      { format: 'addddddddddd', frequency: 0.003267974 },
      { format: 'aaaddddddddhdd', frequency: 0.003267974 },
      { format: 'ddddddddda', frequency: 0.003267974 },
      { format: 'ddddd', frequency: 0.003267974 },
      { format: 'aaaddddddd', frequency: 0.001633987 },
      { format: 'aaadddddddddddd', frequency: 0.001633987 },
      { format: 'd', frequency: 0.001633987 },
      { format: 'adaddddddddd', frequency: 0.001633987 },
      { format: 'dddddddddddddd', frequency: 0.001633987 },
      { format: 'aadaaaadd', frequency: 0.001633987 },
      { format: 'ddddx', frequency: 0.001633987 },
      { format: 'addddsddddd', frequency: 0.001633987 },
      { format: 'dddddaddddd', frequency: 0.001633987 },
      { format: 'dddddddddxddd', frequency: 0.001633987 },
      { format: 'xdddxsdddhdddd', frequency: 0.001633987 },
      { format: 'aaaaaaaa', frequency: 0.001633987 },
      { format: 'aaddddddddd', frequency: 0.001633987 },
      { format: 'aaaddddd', frequency: 0.001633987 },
      { format: 'ddddddddddddsxax', frequency: 0.001633987 },
      { format: 'aaa', frequency: 0.001633987 },
      { format: 'addddddddaaa', frequency: 0.001633987 },
      { format: 'daaddddddddsdd', frequency: 0.001633987 },
      { format: 'dddddddddddda', frequency: 0.001633987 },
      { format: 'aa', frequency: 0.001633987 },
      { format: 'adddd', frequency: 0.001633987 },
      { format: 'dddddaddd', frequency: 0.001633987 },
      { format: 'dddddddddddddddd', frequency: 0.001633987 },
      { format: 'adddddddddda', frequency: 0.001633987 },
      { format: 'aaadddddddhdd', frequency: 0.001633987 },
      { format: 'aaaddddddddsdd', frequency: 0.001633987 },
    ],
  },
  {
    front_end_carrier_id: 255,
    prefixes: [
      { prefix: '1', frequency: 0.42209303 },
      { prefix: 'U', frequency: 0.16976744 },
      { prefix: '110', frequency: 0.13604651 },
      { prefix: '0', frequency: 0.0627907 },
      { prefix: '1103', frequency: 0.05116279 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.21627907 },
      { suffix: '01', frequency: 0.14767441 },
      { suffix: '0', frequency: 0.1244186 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.39186046 },
      { format: 'adddddddddd', frequency: 0.1 },
      { format: 'adddddddd', frequency: 0.09767442 },
      { format: 'ddddddddddd', frequency: 0.070930235 },
      { format: 'dddddddd', frequency: 0.05116279 },
      { format: 'aadddddddddd', frequency: 0.030232558 },
      { format: 'addddddddsdd', frequency: 0.030232558 },
      { format: 'dddddddddd', frequency: 0.029069768 },
      { format: 'ddddddd', frequency: 0.025581395 },
      { format: 'dddddddddddd', frequency: 0.013953488 },
      { format: 'addddddddd', frequency: 0.011627907 },
      { format: 'dddddddddsdd', frequency: 0.011627907 },
      { format: 'aaadddddd', frequency: 0.009302326 },
      { format: 'ddddd', frequency: 0.008139535 },
      { format: 'dddddddddhdd', frequency: 0.006976744 },
      { format: 'aaaddddddddd', frequency: 0.006976744 },
      { format: 'addddddddddd', frequency: 0.0058139535 },
      { format: 'aaddddddd', frequency: 0.0058139535 },
      { format: 'ddaddddddd', frequency: 0.0058139535 },
      { format: 'dddddd', frequency: 0.004651163 },
      { format: 'addddddd', frequency: 0.004651163 },
      { format: 'dddddaddddd', frequency: 0.004651163 },
      { format: 'aaddddddddd', frequency: 0.003488372 },
      { format: 'adddddddsdd', frequency: 0.0023255814 },
      { format: 'ddddddddhdd', frequency: 0.0023255814 },
      { format: 'addddddddhdd', frequency: 0.0023255814 },
      { format: 'aaaaaadddddd', frequency: 0.0023255814 },
      { format: 'ddddddddsdd', frequency: 0.0023255814 },
      { format: 'aaaddddddddhdd', frequency: 0.0023255814 },
      { format: 'aaa', frequency: 0.0023255814 },
      { format: 'dddddddxdd', frequency: 0.0011627907 },
      { format: 'adddddddddsdd', frequency: 0.0011627907 },
      { format: 'aaadddddddddddd', frequency: 0.0011627907 },
      { format: 'd', frequency: 0.0011627907 },
      { format: 'addddd', frequency: 0.0011627907 },
      { format: 'adadddddddaa', frequency: 0.0011627907 },
      { format: 'addaaadddddd', frequency: 0.0011627907 },
      { format: 'aaaddddddsdd', frequency: 0.0011627907 },
      { format: 'adaddddddddd', frequency: 0.0011627907 },
      { format: 'dddddddddddddd', frequency: 0.0011627907 },
      { format: 'aadaaaadd', frequency: 0.0011627907 },
      { format: 'aaadddaddddd', frequency: 0.0011627907 },
      { format: 'adddddd', frequency: 0.0011627907 },
      { format: 'addddsddddd', frequency: 0.0011627907 },
      { format: 'ddddddddddddd', frequency: 0.0011627907 },
      { format: 'addddddddddddd', frequency: 0.0011627907 },
      { format: 'dddddddddxddd', frequency: 0.0011627907 },
      { format: 'xdddxsdddhdddd', frequency: 0.0011627907 },
      { format: 'aaaaaaaa', frequency: 0.0011627907 },
      { format: 'asddddddd', frequency: 0.0011627907 },
      { format: 'aaaddddd', frequency: 0.0011627907 },
      { format: 'ddddddddddddsxax', frequency: 0.0011627907 },
      { format: 'aadddddddd', frequency: 0.0011627907 },
      { format: 'ddddddddda', frequency: 0.0011627907 },
      { format: 'dsdsdsdsdsdsdsdsdssdd', frequency: 0.0011627907 },
      { format: 'adaaaddddddd', frequency: 0.0011627907 },
      { format: 'addddddddaaa', frequency: 0.0011627907 },
      { format: 'aaasddddddddd', frequency: 0.0011627907 },
      { format: 'dddddddddddda', frequency: 0.0011627907 },
      { format: 'aa', frequency: 0.0011627907 },
      { format: 'aaaaasaaaaaaaa', frequency: 0.0011627907 },
      { format: 'dddhddhdddd', frequency: 0.0011627907 },
      { format: 'adddd', frequency: 0.0011627907 },
      { format: 'aaadddddddddd', frequency: 0.0011627907 },
      { format: 'dddddaddd', frequency: 0.0011627907 },
      { format: 'dddddhdddd', frequency: 0.0011627907 },
      { format: 'addsdsdsdsdsdsdsdssdd', frequency: 0.0011627907 },
      { format: 'dddddddddddddddd', frequency: 0.0011627907 },
      { format: 'ddd', frequency: 0.0011627907 },
      { format: 'aaaaddddd', frequency: 0.0011627907 },
      { format: 'adddddddddda', frequency: 0.0011627907 },
      { format: 'aaadddddddddhdd', frequency: 0.0011627907 },
      { format: 'aaaddddddddsdd', frequency: 0.0011627907 },
      { format: 'aaddddaddddd', frequency: 0.0011627907 },
      { format: 'ddddddddddddddd', frequency: 0.0011627907 },
      { format: 'aaddddddddddsd', frequency: 0.0011627907 },
    ],
  },
  {
    front_end_carrier_id: 256,
    prefixes: [
      { prefix: '1', frequency: 0.67168677 },
      { prefix: '110', frequency: 0.26204818 },
      { prefix: '1103', frequency: 0.084337346 },
      { prefix: 'U', frequency: 0.078313254 },
      { prefix: '1104', frequency: 0.057228915 },
      { prefix: '1102', frequency: 0.057228915 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.15060242 },
      { suffix: '1', frequency: 0.13855422 },
      { suffix: '2', frequency: 0.13554217 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.5993976 },
      { format: 'ddddddddddd', frequency: 0.09337349 },
      { format: 'adddddddd', frequency: 0.07530121 },
      { format: 'dddddddd', frequency: 0.05421687 },
      { format: 'adddddddddd', frequency: 0.042168673 },
      { format: 'addddddddsdd', frequency: 0.024096385 },
      { format: 'dddddddddsdd', frequency: 0.018072288 },
      { format: 'dddddddddd', frequency: 0.015060241 },
      { format: 'ddddddd', frequency: 0.012048192 },
      { format: 'aaadddddd', frequency: 0.006024096 },
      { format: 'dddddddddhdd', frequency: 0.006024096 },
      { format: 'dddddddddddd', frequency: 0.006024096 },
      { format: 'aadddddddddd', frequency: 0.006024096 },
      { format: 'd', frequency: 0.003012048 },
      { format: 'ddddddddddddsxax', frequency: 0.003012048 },
      { format: 'aaaddddddddd', frequency: 0.003012048 },
      { format: 'addddddddd', frequency: 0.003012048 },
      { format: 'addddddddaaa', frequency: 0.003012048 },
      { format: 'aaaddddddddhdd', frequency: 0.003012048 },
      { format: 'dddddddddddda', frequency: 0.003012048 },
      { format: 'dddddaddddd', frequency: 0.003012048 },
      { format: 'dddddddddxddd', frequency: 0.003012048 },
      { format: 'aaaaaaaa', frequency: 0.003012048 },
      { format: 'aaaddddddddsdd', frequency: 0.003012048 },
      { format: 'addddddddddd', frequency: 0.003012048 },
      { format: 'addddddd', frequency: 0.003012048 },
      { format: 'aaddddddddd', frequency: 0.003012048 },
    ],
  },
  {
    front_end_carrier_id: 257,
    prefixes: [
      { prefix: '10', frequency: 0.15609756 },
      { prefix: '101', frequency: 0.068292685 },
      { prefix: '100', frequency: 0.058536585 },
    ],
    suffixes: [
      { suffix: '5', frequency: 0.14146341 },
      { suffix: '9', frequency: 0.12195122 },
    ],
    formats: [
      { format: 'dddddddddddd', frequency: 0.7902439 },
      { format: 'dddddddd', frequency: 0.15609756 },
      { format: 'aaddddddd', frequency: 0.009756098 },
      { format: 'ddddddddd', frequency: 0.009756098 },
      { format: 'adddddddd', frequency: 0.009756098 },
      { format: 'aadddddddd', frequency: 0.009756098 },
      { format: 'aaadddddd', frequency: 0.004878049 },
      { format: 'ddddddd', frequency: 0.004878049 },
      { format: 'ddadddddd', frequency: 0.004878049 },
    ],
  },
  {
    front_end_carrier_id: 258,
    prefixes: [
      { prefix: '1', frequency: 0.11426409 },
      { prefix: '890', frequency: 0.09496784 },
      { prefix: '0', frequency: 0.09383277 },
      { prefix: '3', frequency: 0.06167234 },
      { prefix: '2', frequency: 0.05448354 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.21263716 },
      { suffix: '0', frequency: 0.12902005 },
      { suffix: '01', frequency: 0.12220961 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.5641317 },
      { format: 'dddddddhdd', frequency: 0.07869845 },
      { format: 'dddddddd', frequency: 0.05297011 },
      { format: 'ddddddddddd', frequency: 0.046916384 },
      { format: 'dddddddddddd', frequency: 0.036700718 },
      { format: 'dddddddddd', frequency: 0.033673856 },
      { format: 'dddddddddhdd', frequency: 0.033295497 },
      { format: 'ddddddd', frequency: 0.014755959 },
      { format: 'adddddddd', frequency: 0.01286417 },
      { format: 'ddaddddddd', frequency: 0.009837306 },
      { format: 'ddddddddaaaa', frequency: 0.009458948 },
      { format: 'adddddddddd', frequency: 0.009080591 },
      { format: 'aaaddddddddd', frequency: 0.007945516 },
      { format: 'addddddddd', frequency: 0.0075671584 },
      { format: 'aaaddddddd', frequency: 0.0034052213 },
      { format: 'aaaaaahdd', frequency: 0.0030268633 },
      { format: 'aaddddddddd', frequency: 0.0026485054 },
      { format: 'aadaaahdd', frequency: 0.0026485054 },
      { format: 'aaaadddddddd', frequency: 0.0022701477 },
      { format: 'dddaddddd', frequency: 0.0022701477 },
      { format: 'aadddddddhdd', frequency: 0.0018917896 },
      { format: 'aaadddaddddd', frequency: 0.0018917896 },
      { format: 'aaaadahdd', frequency: 0.0018917896 },
      { format: 'aaddddddddddd', frequency: 0.0018917896 },
      { format: 'aaadddddddddddd', frequency: 0.0015134317 },
      { format: 'aaaaa', frequency: 0.0015134317 },
      { format: 'dddaddaaa', frequency: 0.0015134317 },
      { format: 'dddd', frequency: 0.0015134317 },
      { format: 'ddddddddaaa', frequency: 0.0011350738 },
      { format: 'dddadddaa', frequency: 0.0011350738 },
      { format: 'ddddddddda', frequency: 0.0011350738 },
      { format: 'dadaaahdd', frequency: 0.0011350738 },
      { format: 'daadaahdd', frequency: 0.0011350738 },
      { format: 'aaaaaa', frequency: 0.0011350738 },
      { format: 'dddsddddddddd', frequency: 0.0011350738 },
      { format: 'dddddddddddddd', frequency: 0.0011350738 },
      { format: 'aaadaahdd', frequency: 0.0011350738 },
      { format: 'dddddd', frequency: 0.0011350738 },
      { format: 'adaadahdd', frequency: 0.0011350738 },
      { format: 'aaa', frequency: 0.0011350738 },
    ],
  },
  {
    front_end_carrier_id: 259,
    prefixes: [
      { prefix: 'W28', frequency: 0.12580994 },
      { prefix: 'W27', frequency: 0.08396328 },
      { prefix: '9', frequency: 0.06776458 },
      { prefix: '101', frequency: 0.064794816 },
      { prefix: '0', frequency: 0.056965444 },
    ],
    suffixes: [
      { suffix: '0', frequency: 0.16792656 },
      { suffix: '1', frequency: 0.13984881 },
    ],
    formats: [
      { format: 'addddddddd', frequency: 0.37419006 },
      { format: 'dddddddddddd', frequency: 0.093952484 },
      { format: 'ddddddddd', frequency: 0.08585313 },
      { format: 'dddddddddd', frequency: 0.0737041 },
      { format: 'adddddddd', frequency: 0.0575054 },
      { format: 'ddddddddddd', frequency: 0.04211663 },
      { format: 'aaddddddd', frequency: 0.039686825 },
      { format: 'addddsddddd', frequency: 0.038336933 },
      { format: 'aaadddaddddd', frequency: 0.017818574 },
      { format: 'addddddddddd', frequency: 0.015658747 },
      { format: 'adddddddddd', frequency: 0.013228942 },
      { format: 'aaddddddddd', frequency: 0.009989201 },
      { format: 'aaaddddddd', frequency: 0.009719223 },
      { format: 'aaaddddddddd', frequency: 0.009719223 },
      { format: 'dddddddd', frequency: 0.00836933 },
      { format: 'adddddddddhdd', frequency: 0.008099352 },
      { format: 'dddddddddhdd', frequency: 0.0072894166 },
      { format: 'aaadddddddd', frequency: 0.0062095034 },
      { format: 'adadddaddddd', frequency: 0.0051295897 },
      { format: 'addddddd', frequency: 0.0037796977 },
      { format: 'ddddddddddddd', frequency: 0.0037796977 },
      { format: 'dddddddda', frequency: 0.0032397409 },
      { format: 'ddddddd', frequency: 0.0029697623 },
      { format: 'adddddd', frequency: 0.002699784 },
      { format: 'aaddddddddddd', frequency: 0.002699784 },
      { format: 'ddddddddddsdd', frequency: 0.0024298057 },
      { format: 'addddddddhdd', frequency: 0.0024298057 },
      { format: 'ddddsdddddhdd', frequency: 0.0018898488 },
      { format: 'aaadddddddaa', frequency: 0.0018898488 },
      { format: 'aadddddddd', frequency: 0.0016198704 },
      { format: 'addddddddsdd', frequency: 0.0016198704 },
      { format: 'dddddaddddd', frequency: 0.0016198704 },
      { format: 'adaddddddddd', frequency: 0.0016198704 },
      { format: 'aaaaaddddddd', frequency: 0.0016198704 },
      { format: 'dddddddddddddd', frequency: 0.001349892 },
      { format: 'ddddddddaaaa', frequency: 0.001349892 },
      { format: 'addddsdddddhdd', frequency: 0.0010799136 },
      { format: 'aaaaaaaa', frequency: 0.0010799136 },
      { format: 'ddddd', frequency: 0.0010799136 },
      { format: 'dddddd', frequency: 0.0010799136 },
      { format: 'dddaadddddd', frequency: 0.0010799136 },
    ],
  },
  {
    front_end_carrier_id: 273,
    prefixes: [
      { prefix: 'N32', frequency: 0.14685315 },
      { prefix: '10', frequency: 0.13986014 },
      { prefix: 'N326', frequency: 0.08741259 },
      { prefix: 'U8', frequency: 0.073426574 },
      { prefix: '00', frequency: 0.06993007 },
    ],
    suffixes: [
      { suffix: '1', frequency: 0.27272728 },
      { suffix: '01', frequency: 0.21328671 },
      { suffix: '2', frequency: 0.13811189 },
      { suffix: '0', frequency: 0.13811189 },
    ],
    formats: [
      { format: 'ddddddddd', frequency: 0.27622378 },
      { format: 'adddddddd', frequency: 0.23251748 },
      { format: 'adddddddddd', frequency: 0.17657343 },
      { format: 'ddddddddddd', frequency: 0.12062937 },
      { format: 'addddddddsdd', frequency: 0.06118881 },
      { format: 'dddddddddhdd', frequency: 0.031468533 },
      { format: 'dddddddddsdd', frequency: 0.02097902 },
      { format: 'aaadddddd', frequency: 0.013986014 },
      { format: 'dddddddddddd', frequency: 0.008741259 },
      { format: 'addddddddhdd', frequency: 0.008741259 },
      { format: 'aaddddddd', frequency: 0.006993007 },
      { format: 'aaaddddddddhdd', frequency: 0.005244755 },
      { format: 'addddddddd', frequency: 0.0034965035 },
      { format: 'aaadaddaa', frequency: 0.0034965035 },
      { format: 'ddaddddddd', frequency: 0.0034965035 },
      { format: 'dddddddddd', frequency: 0.0034965035 },
      { format: 'd', frequency: 0.0017482517 },
      { format: 'aaaddddddd', frequency: 0.0017482517 },
      { format: 'dddaddddd', frequency: 0.0017482517 },
      { format: 'aaaaddddd', frequency: 0.0017482517 },
      { format: 'aaaaaaaad', frequency: 0.0017482517 },
      { format: 'aaadddddddd', frequency: 0.0017482517 },
      { format: 'dddddddd', frequency: 0.0017482517 },
      { format: 'addddddsdddd', frequency: 0.0017482517 },
      { format: 'dddddddddddddd', frequency: 0.0017482517 },
      { format: 'aaaddddddsdd', frequency: 0.0017482517 },
      { format: 'dddddddddad', frequency: 0.0017482517 },
      { format: 'addddddddsda', frequency: 0.0017482517 },
      { format: 'aaaaaaaaddd', frequency: 0.0017482517 },
    ],
  },
];

export function getCarrierMemberIdData(
  front_end_carrier_id: number
): CarrierMemberIdData | undefined {
  return data.find((d) => d.front_end_carrier_id === front_end_carrier_id);
}
