import { FocusableProps } from '@react-types/shared';
import { FocusRingProps, HoverProps, useFocusRing, useHover } from 'react-aria';

import { DATA } from './consts';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type InteractionProps = PickAndConfigure<
  HoverProps & FocusRingProps & FocusableProps,
  | 'autoFocus'
  | 'isTextInput'
  | 'onFocus'
  | 'onBlur'
  | 'onFocusChange'
  | {
      /** Whether the interaction events should be disabled. */
      disabled?: 'isDisabled';
    }
>;
export const useInteraction = <T extends InteractionProps>({
  disabled = false,
  ...props
}: T) => {
  const ariaProps = { isDisabled: disabled, ...props };
  const { isFocusVisible, isFocused, focusProps } = useFocusRing(ariaProps);
  const { hoverProps, isHovered } = useHover(ariaProps);
  return {
    ariaProps,
    isHovered,
    rootProps: {
      [DATA.DISABLED]: disabled,
    },
    hoverProps: {
      ...hoverProps,
      [DATA.HOVERED]: isHovered,
    },
    isFocused,
    isFocusVisible,
    focusProps: {
      ...focusProps,
      [DATA.FOCUSED]: isFocused,
    },
  } as const;
};
