import { Formik } from 'formik';
import React from 'react';
import { isHttpsUri } from 'valid-url';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderUpdate } from '@headway/api/models/ProviderUpdate';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormStickyFooter } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextArea } from '@headway/helix/TextArea';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { getErrorMessage } from '@headway/shared/utils/error';
import { theme as legacyTheme } from '@headway/ui/theme';
import {
  notifyError,
  notifySuccess, // notifyWarning,
} from '@headway/ui/utils/notify';

const TELEHEALTH_HELP_CENTER_LINK =
  'https://findheadway.zendesk.com/hc/articles/4421041745684';

interface TelehealthSettingsProps {
  telehealthInstructions: ProviderRead['telehealthInstructions'];
  videoUrlDefault: ProviderRead['videoUrlDefault'];
  isAtlas: boolean;
  // updateProvider prop handles both updating the provider record in the db and refreshing provider state in the app
  updateProvider: (provider: ProviderUpdate) => Promise<ProviderRead>;
}

type FormValues = Omit<TelehealthSettingsProps, 'isAtlas' | 'updateProvider'>;

const validationSchema = Yup.object().shape({
  telehealthInstructions: Yup.string().max(
    150,
    ({ max, value }) =>
      `Telehealth instructions are too long (${value.length}/${max} characters).`
  ),
  videoUrlDefault: Yup.string().test(
    'is-https-url',
    'Video link needs to be a URL which begins with https://',
    (value) => (value?.length ? !!isHttpsUri(value) : true)
  ),
});

export const TelehealthSettings: React.FC<
  React.PropsWithChildren<TelehealthSettingsProps>
> = ({ updateProvider, telehealthInstructions, videoUrlDefault, isAtlas }) => {
  const initialValues: FormValues = {
    telehealthInstructions: telehealthInstructions ?? '',
    videoUrlDefault: videoUrlDefault ?? '',
  };
  const {
    telehealthDefaultLinkPrimary,
    telehealthDefaultLinkSecondary,
    telehealthTipTitle,
    telehealthInstructions: telehealthInstructionsCopy,
  } = getTelehealthSettingsCopy(isAtlas);

  // const clipboard = useClipboard({
  //   copiedTimeout: 1000,
  //   onError() {
  //     notifyWarning('Unable to copy to clipboard');
  //   },
  // });

  const handleOnSubmit = async (
    values: FormValues,
    resetForm: (values: { values: FormValues }) => void
  ) => {
    const submitValuesCastToNull = {
      telehealthInstructions: values.telehealthInstructions || null,
      videoUrlDefault: values.videoUrlDefault || null,
    };
    try {
      const res = await updateProvider(submitValuesCastToNull);

      resetForm({
        values: {
          telehealthInstructions: res.telehealthInstructions,
          videoUrlDefault: res.videoUrlDefault,
        },
      });

      notifySuccess('Telehealth Settings have updated successfully.');
    } catch (err: unknown) {
      const message = getErrorMessage(
        err,
        'Error updating your telehealth settings.'
      );
      notifyError(message);
    }
  };

  return (
    <Formik
      onSubmit={async (values, { resetForm }) => {
        handleOnSubmit(values, resetForm);
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values, dirty, errors }) => {
        return (
          <Form>
            <PageSection layout="grid.two-column">
              <div>
                <h2>
                  <SectionHeader>Virtual (telehealth)</SectionHeader>
                </h2>
                <PageSectionSubText>
                  <BodyText>
                    {telehealthDefaultLinkPrimary}
                    <br />
                    <br />
                    {telehealthDefaultLinkSecondary}
                  </BodyText>
                </PageSectionSubText>
              </div>
              <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
                <FormControl
                  name="videoUrlDefault"
                  component={TextField}
                  label="Telehealth URL"
                  placeholder="E.g. https://doxy.me/myname"
                  //   inputRef={clipboard.target}
                  // endAdornment={
                  //   clipboard.isSupported() &&
                  //   !!values.videoUrlDefault?.length && (
                  //     <InputAdornment
                  //       position="end"
                  //       css={{
                  //         marginBottom: legacyTheme.space.xs,
                  //       }}
                  //     >
                  //       <IconButton
                  //         aria-label="Copy video default url"
                  //         onClick={() => {
                  //           clipboard.copy();
                  //         }}
                  //         size="small"
                  //       >
                  //         {clipboard.copied ? (
                  //           <Check htmlColor={legacyTheme.color.success} />
                  //         ) : (
                  //           <FileCopyOutlined />
                  //         )}
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }
                />

                <GuidanceCard variant="info">
                  <div>
                    {telehealthTipTitle + ' '}
                    <span css={{ fontWeight: legacyTheme.fontWeight.bold }}>
                      MUST
                    </span>{' '}
                    be:
                    <ul css={{ paddingLeft: 30 }}>
                      <li>HIPAA compliant</li>
                      <li>Secured via HTTPS</li>
                      <li>
                        Set up with a default waiting room to ensure clients
                        never overlap
                      </li>
                    </ul>
                    <p>
                      Learn how to set up a compliant video link{' '}
                      <Link
                        href={TELEHEALTH_HELP_CENTER_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </Link>
                      .
                    </p>
                  </div>
                </GuidanceCard>
              </div>
            </PageSection>
            <PageSection layout="grid.two-column">
              <div>
                <h2>
                  <SectionHeader>Telehealth instructions</SectionHeader>
                </h2>

                <PageSectionSubText>
                  <BodyText>{telehealthInstructionsCopy}</BodyText>
                </PageSectionSubText>
              </div>
              <div>
                <FormControl
                  name="telehealthInstructions"
                  component={TextArea}
                  sizing={3}
                  label="Instructions"
                  optionalityText="Optional"
                  helpText={
                    'For example, "The telehealth link for our session will be available on your Headway appointments page and on your reminder email ahead of our session."'
                  }
                />
              </div>
            </PageSection>

            <FormStickyFooter>
              <Button
                type="submit"
                disabled={!dirty || isSubmitting}
                variant="primary"
                size="large"
              >
                Save
              </Button>
            </FormStickyFooter>
          </Form>
        );
      }}
    </Formik>
  );
};

const getTelehealthSettingsCopy = (isAtlas: boolean) => {
  let telehealthTitle = 'You can always see clients virtually with Headway.';
  let telehealthDefaultLinkPrimary =
    "If you use the same telehealth link for all of your clients, add it here and we'll share it with them ahead of their session.";
  let telehealthDefaultLinkSecondary =
    'If you use individual links, please share those directly with each client.';
  let telehealthTipTitle = 'For Headway sessions, your telehealth platform';
  let telehealthInstructions =
    "Share details and expectations so your clients know how to join your virtual sessions. We'll include these in the booking process and on appointment reminders.";

  if (isAtlas) {
    telehealthTitle =
      'Providers can always see patients virtually with Headway.';
    telehealthDefaultLinkPrimary =
      "If the provider uses the same telehealth link for all of their clients, add it here and we'll share it with them ahead of their session.";
    telehealthDefaultLinkSecondary =
      'If the provider uses individual links, please share those directly with each client.';
    telehealthTipTitle =
      "For Headway sessions, the provider's telehealth platform";
    telehealthInstructions =
      "Share details and expectations so the provider's clients know how to join their virtual sessions. We'll include these in the booking process and on appointment reminders.";
  }

  return {
    telehealthTitle,
    telehealthDefaultLinkPrimary,
    telehealthDefaultLinkSecondary,
    telehealthTipTitle,
    telehealthInstructions,
  };
};
