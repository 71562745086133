import React from 'react';

export function IconChevronRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.20752 5.2925L10.4059 9.5L6.20752 13.7075L7.50002 15L13 9.5L7.50002 4L6.20752 5.2925Z"
        fill="currentColor"
      />
    </svg>
  );
}
