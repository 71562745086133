import keyBy from 'lodash/keyBy';
import moment from 'moment';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAddressRead } from '@headway/api/models/ProviderAddressRead';
import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderAddressApi } from '@headway/api/resources/ProviderAddressApi';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { useMutation, useQuery } from '@headway/shared/react-query';

import { useProvider } from 'hooks/useProvider';
import { getRecurrenceString } from 'views/Calendar/events/util/events';
import { RecurrenceType } from 'views/Calendar/utils/constants';
import { getWorkingHoursQueryKeyArgs } from 'views/Calendar/utils/queries';

import {
  useFindConcreteProviderEvents,
  useFindProviderEventsCache,
} from './useFindProviderEvents';

export interface ProviderWorkingHour {
  id: number;
  startDate: string;
  endDate: string;
  telehealth: boolean;
  location?: ProviderAddressRead;
  providerEvent: ConcreteProviderEventRead;
}

export interface ProviderWorkingHourCreate {
  weekday: Weekday;
  startDate: Date;
  endDate: Date;
  telehealth: boolean;
  locationId?: number;
}

export enum Weekday {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export type WorkingHourMap = Record<Weekday, ProviderWorkingHour[]>;

export interface UseWorkingHours {
  workingHours: WorkingHourMap;
  isLoading: boolean;
  updateWorkingHour: (
    data: ProviderWorkingHour
  ) => Promise<ConcreteProviderEventRead | undefined>;
  deleteWorkingHours: (ids: number[]) => Promise<{}>;
  createWorkingHour: (
    data: ProviderWorkingHourCreate
  ) => Promise<ConcreteProviderEventRead>;
  locations: ProviderAddressRead[];
}

export const useWorkingHours = (): UseWorkingHours => {
  const providerId = useProvider().id;

  const workingHoursQueryKeyArgs = getWorkingHoursQueryKeyArgs(providerId);
  const workingHoursQuery = useFindConcreteProviderEvents(
    workingHoursQueryKeyArgs,
    {
      refetchOnWindowFocus: false,
    }
  );
  const findProviderEventsCache = useFindProviderEventsCache();

  // Maps out the working hours to be keyed by the Weekday
  const workingHoursMapped: WorkingHourMap = (
    workingHoursQuery.data?.data ?? []
  )
    .filter(
      (event) => !event.recurrenceEndDate || event.recurrenceEndDate === null
    )
    .reduce((workingHourMap: WorkingHourMap, e: ConcreteProviderEventRead) => {
      const weekday = moment(e.startDate).weekday() as Weekday;
      workingHourMap[weekday] = [
        ...(workingHourMap[weekday] ?? []),
        {
          id: e.id,
          startDate: e.startDate!,
          endDate: e.endDate!,
          telehealth: !!e.telehealth,
          providerEvent: e,
        },
      ];
      return workingHourMap;
    }, {} as WorkingHourMap);

  // Sort our dates in the array
  Object.keys(workingHoursMapped).forEach((day) => {
    workingHoursMapped[parseInt(day) as Weekday].sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
  });

  const providerLocationsQuery = useQuery(
    ['provider-addresses', providerId],
    () => {
      return ProviderAddressApi.getAllProviderAddresses({
        provider_ids: [providerId],
      });
    }
  );

  const providerLocationsById = keyBy(providerLocationsQuery.data ?? [], 'id');

  // Add information of the Provider's locations to our working hours map
  Object.values(workingHoursMapped).forEach((whs) => {
    whs.forEach((wh) => {
      if (wh.providerEvent.providerAddressId) {
        wh.location = providerLocationsById[wh.providerEvent.providerAddressId];
      }
    });
  });

  const timeZoneGuess = moment.tz.guess();
  const updateWorkingHour = useMutation(
    async (variables: ProviderWorkingHour) => {
      if (!variables.id) {
        return;
      }

      const startDate = moment(variables.startDate).toISOString();
      const endDate = moment(variables.endDate).toISOString();

      // Otherwise we update existing events
      let update: ProviderEventUpdate = {
        type: ProviderEventType.AVAILABILITY,
        startDate,
        endDate,
        recurrence: getRecurrenceString(
          RecurrenceType.WEEKLY,
          startDate,
          timeZoneGuess
        ),
        timeZone: timeZoneGuess,
        // We pass null to unset the location. Unsupported by our generated types.
        // @ts-expect-error
        providerAddressId: variables.location?.id ?? null,
        telehealth: variables.telehealth,
        // @ts-expect-error
        recurrenceEndDate: null,
      };

      return ProviderEventApi.updateEvent(variables.id, update);
    },
    {
      onSuccess(response, variables) {
        if (!response || !variables.id) {
          return;
        }
        findProviderEventsCache.setAndInvalidate(
          workingHoursQueryKeyArgs,
          (current) => {
            if (!current) {
              return undefined;
            }
            return {
              ...current,
              data: current.data.map((e) =>
                e.id === variables.id ? response : e
              ),
            };
          }
        );
      },
    }
  );

  const createWorkingHour = useMutation(
    async (variables: ProviderWorkingHourCreate) => {
      const startDate = moment(variables.startDate)
        .set('day', variables.weekday)
        .toISOString();
      const endDate = moment(variables.endDate)
        .set('day', variables.weekday)
        .toISOString();

      let creation: ProviderEventCreate = {
        type: ProviderEventType.AVAILABILITY,
        providerId,
        startDate,
        endDate,
        recurrence: getRecurrenceString(
          RecurrenceType.WEEKLY,
          startDate,
          timeZoneGuess
        ),
        timeZone: timeZoneGuess,
        // We pass null to unset the location. Unsupported by our generated types.
        // @ts-expect-error
        providerAddressId: variables.location?.id ?? null,
        telehealth: variables.telehealth,
        // @ts-expect-error
        recurrenceEndDate: null,
      };

      return ProviderEventApi.createEvent(creation);
    },
    {
      onSuccess(response) {
        if (!response) {
          return;
        }
        findProviderEventsCache.setAndInvalidate(
          workingHoursQueryKeyArgs,
          (current) => {
            if (!current) {
              return {
                totalCount: 1,
                data: [response],
              };
            }
            return {
              totalCount: current.totalCount + 1,
              data: [...current.data, response],
            };
          }
        );
      },
    }
  );

  const deleteWorkingHours = useMutation(
    async (providerEventIds: number[]) => {
      return Promise.all(
        providerEventIds.map((id) => ProviderEventApi.deleteEvent(id))
      );
    },
    {
      onSuccess(response, variables) {
        findProviderEventsCache.setAndInvalidate(
          workingHoursQueryKeyArgs,
          (current) => {
            if (!current) {
              return undefined;
            }
            const providerEventIdsSet = new Set(variables);
            return {
              totalCount: current.totalCount - variables.length,
              data: current.data.filter(
                (e) => !(e.id && providerEventIdsSet.has(e.id))
              ),
            };
          }
        );
      },
    }
  );

  return {
    workingHours: workingHoursMapped,
    locations: providerLocationsQuery.data ?? [],
    isLoading: workingHoursQuery.isLoading || providerLocationsQuery.isLoading,
    updateWorkingHour: (data: ProviderWorkingHour) =>
      updateWorkingHour.mutateAsync(data),
    deleteWorkingHours: (ids: number[]) => deleteWorkingHours.mutateAsync(ids),
    createWorkingHour: (data: ProviderWorkingHourCreate) =>
      createWorkingHour.mutateAsync(data),
  };
};
