import React from 'react';

import { NumberField } from './NumberField';

const usd = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
};

type CurrencyFieldProps = React.ComponentProps<typeof NumberField>;

function CurrencyField(props: CurrencyFieldProps) {
  return <NumberField formatOptions={usd} {...props} />;
}

export { CurrencyField };
