/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { PayerQuestionnaireResponseRead } from '../models/PayerQuestionnaireResponseRead';
import { PayerQuestionnaireResponseUpdate } from '../models/PayerQuestionnaireResponseUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class PayerQuestionnaireResponseApiResource {

    /**
     * Submit Provider Payer Questionnaire Response
     * 
     * @param payerQuestionnaireResponseId 
     
     */
    public submitProviderPayerQuestionnaireResponse(payerQuestionnaireResponseId: number, axiosConfig?: AxiosRequestConfig): Promise<PayerQuestionnaireResponseRead> {
        const apiRoute = '/payer-questionnaire-response/{payer_questionnaire_response_id}/submit';
        const reqPath = apiRoute
                    .replace('{' + 'payer_questionnaire_response_id}', String(payerQuestionnaireResponseId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Payer Questionnaire Response
     * 
     * @param payerQuestionnaireResponseId 
     * @param payerQuestionnaireResponseUpdate 
     
     */
    public updateProviderPayerQuestionnaireResponse(payerQuestionnaireResponseId: number, body: PayerQuestionnaireResponseUpdate, axiosConfig?: AxiosRequestConfig): Promise<PayerQuestionnaireResponseRead> {
        const apiRoute = '/payer-questionnaire-response/{payer_questionnaire_response_id}';
        const reqPath = apiRoute
                    .replace('{' + 'payer_questionnaire_response_id}', String(payerQuestionnaireResponseId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const PayerQuestionnaireResponseApi = new PayerQuestionnaireResponseApiResource();
