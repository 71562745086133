import { css } from '@emotion/react';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';

import { FormFile, FormState } from '../../schema/schema.types';
import { BuilderState } from '../BuilderEmbedded';
import { FormStateEdit } from '../edit/FormState';
import { RendererContext } from '../RendererContext';

export const EditPanel = ({
  form,
  builderState,
  setBuilderState,
  currentEditingBlock,
}: {
  form: FormFile;
  builderState: BuilderState;
  setBuilderState: (state: BuilderState) => void;
  currentEditingBlock: React.ReactNode;
}) => {
  const [refreshLayoutTree, setRefreshLayoutTree] = useState(1);
  const [formState, setFormState] = useState<FormState | undefined>(
    form?.state ?? {}
  );

  const updateFormState = (state: FormState) => {
    setFormState(state);
    RendererContext.state = state;
  };

  return (
    <div
      css={[
        editorCss.blockEditPopup,
        {
          alignItems:
            currentEditingBlock ||
            (builderState.showEditPanel &&
              builderState.editPanelView === 'layoutTree')
              ? 'flex-start'
              : 'center',
          justifyContent:
            currentEditingBlock ||
            (builderState.showEditPanel &&
              builderState.editPanelView === 'layoutTree')
              ? 'flex-start'
              : 'center',
          top: 0,
        },
      ]}
    >
      {builderState.showEditPanel &&
      builderState.editPanelView === 'layoutTree' &&
      refreshLayoutTree ? (
        <div
          css={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            overflow: 'scroll',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing.x2,
            }}
          >
            <Button onPress={() => setRefreshLayoutTree(refreshLayoutTree + 1)}>
              Refresh
            </Button>
            <Button
              onPress={() =>
                setBuilderState({
                  showEditPanel: false,
                })
              }
            >
              Close
            </Button>
          </div>
          <pre>{JSON.stringify(RendererContext.layoutTree, null, 2)}</pre>
        </div>
      ) : builderState.showEditPanel &&
        builderState.editPanelView === 'formState' ? (
        <FormStateEdit
          state={formState}
          setFormState={(state) => updateFormState(state)}
          onClose={() => setBuilderState({ showEditPanel: false })}
        />
      ) : currentEditingBlock ? (
        <div css={{ display: 'flex', flex: 1 }}>
          {window.screen.width >= theme.breakpoints.desktop ? (
            <div css={{ flex: 1 }}>{currentEditingBlock}</div>
          ) : (
            <div css={{ flex: 1 }}>
              {createPortal(currentEditingBlock, document.body)}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

const editorCss = {
  blockEditPopup: css({
    position: 'sticky',
    overflowY: 'auto',
    padding: '16px',
    paddingTop: '25px',
    backgroundColor: 'white',
    borderLeft: '1px solid #e8e8eb',
    height: '100%',
    minHeight: '500px',
    [`@media (max-width: 1095px)`]: {
      position: 'absolute',
      right: 0,
      top: 50,
    },
  }),
};
