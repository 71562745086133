import React from 'react';

import { BodyText } from '@headway/helix/BodyText';

import { SchemaComponent } from '../../schema/schema.types';

export const InfoTitle = ({ element }: SchemaComponent) => {
  const { title } = element;

  return (
    <div>
      <BodyText>
        <strong css={{ fontSize: `18px !important` }}>{title}</strong>
      </BodyText>
    </div>
  );
};
