import { parseDate } from '@internationalized/date';
import { FormikProps, useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback, useMemo } from 'react';

import { DateField } from '@headway/helix/DateField';
import { validity } from '@headway/helix/FormControl';

import { SchemaComponent } from '../../schema/schema.types';
import { ViewComponent } from './ViewComponent';

const MemoizedDateField = React.memo(DateField);

export const FormDate = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title } = element;

  const { values, setFieldValue, touched, errors, submitCount } =
    useFormikContext<any>();
  const isoDateString: string | undefined = get(values, id);

  const onChange = useCallback(
    (v: any) => {
      setFieldValue(id, v?.toString());
    },
    [id, setFieldValue]
  );

  const value = useMemo(
    () => (isoDateString ? parseDate(isoDateString) : undefined),
    [isoDateString]
  );

  const validation = useMemo(
    () => validity(id, { touched, errors, submitCount } as FormikProps<any>),
    [id, touched, errors, submitCount]
  );

  if (readOnly) {
    return <ViewComponent title={title} value={isoDateString} />;
  }

  return (
    <MemoizedDateField
      label={title}
      name={id}
      key={id}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      value={value}
      onChange={onChange}
      validation={validation}
    />
  );
};
