import React from 'react';
import Linkify from 'react-linkify';

import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { SchemaComponent } from '../../schema/schema.types';

export const InfoParagraph = ({ element }: SchemaComponent) => {
  const { textContent, htmlContent, style } = element;

  return (
    <div>
      <BodyText>
        <div
          css={{
            fontSize:
              style?.fontSize === 'small'
                ? `13px !important`
                : style?.fontSize === 'large'
                ? '25px !important'
                : '16px !important',
            fontWeight: style?.fontWeight === 'bold' ? `700` : `400`,
            color:
              style?.fontColor === 'gray'
                ? theme.color.system.gray
                : theme.color.system.black,
            '& > a': linkCss,
          }}
        >
          {htmlContent ? (
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(htmlContent) }}
              css={{
                ul: {
                  margin: 0,
                },
                '& > a': linkCss,
              }}
            ></div>
          ) : (
            <Linkify>{textContent}</Linkify>
          )}
        </div>
      </BodyText>
    </div>
  );
};

const linkCss = {
  color: theme.color.system.black,
  textDecoration: 'underline',
};
