import { Global } from '@emotion/react';
import { CollectionElement } from '@react-types/shared';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';

import { Item } from '@headway/helix/collections';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import { theme } from '@headway/helix/theme';

import { Header } from 'components/Header/Header';
import { useAuthStore } from 'stores/AuthStore';
import { hasContractAccess, hasRateAccess } from 'utils/access';
import { Contract } from 'views/Legal/Agreement';

import { RatesContainer } from './Rates/RatesContainer';

export const LegalTabs = ({
  selectedTab,
  selectedState,
  onTabChange,
  onStateChange,
  hasContractAccess,
}: {
  selectedTab: string;
  selectedState?: string;
  onTabChange: (tab: string) => void;
  onStateChange: (state: string) => void;
  hasContractAccess: boolean;
}) => {
  const { user, provider } = useAuthStore();

  const itemLists = [
    <Item key="rates">Rates</Item>,
    hasContractAccess && <Item key="agreement">Agreements</Item>,
  ];

  const itemPanels = [
    <Item key="rates">
      <RatesContainer
        selectedState={selectedState}
        onStateChange={onStateChange}
      />
    </Item>,
    hasContractAccess && (
      <Item key="agreement">
        <Contract
          user={user}
          provider={provider}
          setSelectedPart={onTabChange}
        />
      </Item>
    ),
  ];

  return (
    <Tabs
      aria-label="Task"
      selectedKey={selectedTab}
      onSelectionChange={(select) => onTabChange(select as string)}
    >
      <TabList>
        {itemLists.filter((item): item is CollectionElement<object> => !!item)}
      </TabList>
      <TabPanels>
        {itemPanels.filter((item): item is CollectionElement<object> => !!item)}
      </TabPanels>
    </Tabs>
  );
};

export const Legal = () => {
  const authStore = useAuthStore();
  const provider = authStore.provider;
  const user = authStore.user;

  if (!hasContractAccess(provider, user) && !hasRateAccess(provider, user)) {
    return <Navigate replace to="/" />;
  }

  const doesUserHaveContractAccess = hasContractAccess(provider, user);

  return (
    <div>
      <Header />
      <Global
        styles={{
          body: {
            background: theme.color.system.white,
          },
        }}
      />
      <Routes>
        <Route
          path={`:selectedPart?/:selectedState?/*`}
          element={
            <LegalWrapper
              doesUserHaveContractAccess={doesUserHaveContractAccess}
            />
          }
        />
      </Routes>
    </div>
  );
};

const LegalWrapper = ({
  doesUserHaveContractAccess,
}: {
  doesUserHaveContractAccess: boolean;
}) => {
  const match = useMatch('/legal/:selectedPart/:selectedState?/*');
  const navigate = useNavigate();
  const selectedPart = match?.params.selectedPart;
  const selectedState = match?.params.selectedState;

  const allowedRoutes = doesUserHaveContractAccess
    ? ['rates', 'agreement']
    : ['rates'];

  if (!selectedPart || !allowedRoutes.includes(selectedPart)) {
    return <Navigate replace to="/legal/rates" />;
  }
  return (
    <div css={{ marginTop: 50, marginBottom: theme.spacing.x12 }}>
      <LegalTabs
        selectedTab={selectedPart}
        selectedState={selectedState}
        hasContractAccess={doesUserHaveContractAccess}
        onTabChange={(selectedTab: string) => {
          navigate('/legal/' + selectedTab);
        }}
        onStateChange={(selectedState: string) => {
          navigate('/legal/rates/' + selectedState);
        }}
      />
    </div>
  );
};
