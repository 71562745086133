import { ProviderIncentiveProgramApi } from '@headway/api/resources/ProviderIncentiveProgramApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderIncentiveEnrollmentQueryKeyArgs {
  providerId: number;
}

export const getUseProviderIncentiveProgramQueryKey = ({
  providerId,
}: UseProviderIncentiveEnrollmentQueryKeyArgs): [string, number] => [
  'get-provider-incentive-program-enrollment',
  providerId,
];

const {
  useSingleQuery: useProviderIncentiveProgramEnrollment,
  useListQuery: useProviderIncentiveProgramEnrollmentList,
  useCachedQuery: useProviderIncentiveProgramEnrollmentCache,
} = createBasicApiHooks(
  getUseProviderIncentiveProgramQueryKey,
  ({ providerId }) => {
    return ProviderIncentiveProgramApi.getProviderIncentiveProgramEnrollmentSummary(
      providerId
    );
  },
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider enrollment summary'
);

export {
  useProviderIncentiveProgramEnrollment,
  useProviderIncentiveProgramEnrollmentList,
  useProviderIncentiveProgramEnrollmentCache,
};
