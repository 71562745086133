import { CheckboxProps, FormControlLabel } from '@mui/material';
import React, { FC } from 'react';

import { Checkbox } from '../Checkbox';
import { theme } from '../theme';

export type FormCheckboxProps = CheckboxProps & {
  label: string | React.ReactElement;
  labelColor?: string;
  labelCss?: { [key: string]: string };
};

export const FormCheckbox: FC<React.PropsWithChildren<FormCheckboxProps>> = ({
  label,
  labelColor,
  labelCss = {},
  ...rest
}) => (
  <FormControlLabel
    label={label}
    css={{
      cursor: 'pointer',
      paddingLeft: '0',
      color: labelColor || theme.color.black,
      fontFamily: theme.fontFamily.postGrotesk,
      fontWeight: theme.fontWeight.light,
      lineHeight: '1.5',
      display: 'inline-flex',
      transition: '0.3s ease all',
      '.MuiFormControlLabel-label': {
        fontFamily: theme.fontFamily.postGrotesk,
      },
      ...labelCss,
    }}
    control={<Checkbox {...rest} css={{ margin: 0 }} />}
  />
);
