/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum Language {
    ALBANIAN = 'albanian',
    AMERICAN_SIGN_LANGUAGE = 'american_sign_language',
    ARABIC = 'arabic',
    ARMENIAN = 'armenian',
    ASSAMESE = 'assamese',
    BENGALI = 'bengali',
    BOSNIAN = 'bosnian',
    BULGARIAN = 'bulgarian',
    CANTONESE = 'cantonese',
    CROATIAN = 'croatian',
    DANISH = 'danish',
    DUTCH = 'dutch',
    ENGLISH = 'english',
    FARSI = 'farsi',
    FLEMISH = 'flemish',
    FRENCH = 'french',
    FUZHOUNESE = 'fuzhounese',
    GERMAN = 'german',
    GREEK = 'greek',
    GUJARATI = 'gujarati',
    HAITIAN_CREOLE = 'haitian_creole',
    HEBREW = 'hebrew',
    HINDI = 'hindi',
    HUNGARIAN = 'hungarian',
    ICELANDIC = 'icelandic',
    IGBO = 'igbo',
    ITALIAN = 'italian',
    JAPANESE = 'japanese',
    KASHMIRI = 'kashmiri',
    KOREAN = 'korean',
    KURDISH = 'kurdish',
    MALAYALAM = 'malayalam',
    MANDARIN = 'mandarin',
    MARATHI = 'marathi',
    NORWEGIAN = 'norwegian',
    POLISH = 'polish',
    PORTUGUESE = 'portuguese',
    PUNJABI = 'punjabi',
    ROMANIAN = 'romanian',
    RUSSIAN = 'russian',
    SERBIAN = 'serbian',
    SHONA = 'shona',
    SOMALI = 'somali',
    SPANISH = 'spanish',
    SWAHILI = 'swahili',
    TAGALOG = 'tagalog',
    THAI = 'thai',
    TURKISH = 'turkish',
    TWI = 'twi',
    UKRAINIAN = 'ukrainian',
    URDU = 'urdu',
    VIETNAMESE = 'vietnamese',
    YIDDISH = 'yiddish',
    YORUBA = 'yoruba'
}
