import React from 'react';

import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SubBodyText } from '@headway/helix/SubBodyText';

/**
 * use exactly one of either OptionsSchema or OptionsByQuestionSchema.
 * use OptionsSchema when all the questions have the same set of possible answers.
 * use OptionsByQuestionSchema if the possible answers or their values vary depending on the question.
 */

// List of response options for each question, displayed in the order given.
export type OptionsSchema = Array<{
  // Text displayed to the user for this option.
  displayText: string;
  // Numerical value tied to this number.
  value: number;
}>;

// List of response options for each question *keyed by that question*, displayed in the order given.
export type OptionsByQuestionSchema = {
  [questionName: string]: { displayText: string; value: string }[];
};

// List of questions, which are displayed in the order given.
export type QuestionsSchema = Array<{
  // Text displayed to the user for this question.
  questionText: string;
  // Key in the scorableResponseJson object used for this input
  key: string;
}>;

type ScorableQuestionListFormProps = {
  questions: QuestionsSchema;
  // Label of the first question in this list
  offset?: number;
} & (
  | {
      options: OptionsSchema;
      optionsByQuestion?: never;
    }
  | {
      options?: never;
      optionsByQuestion: OptionsByQuestionSchema;
    }
);

/**
 * Renders FormControls for a list of questions which all have the same response options, and whose
 * values map to a numerical score. This component assumes it is the child of a Formik form.
 *
 * NOTE: Due to limitations of input components, the values set are string-typed. Parent components
 * are expected to convert to numbers as necessary.
 */
export const ScorableQuestionListForm = ({
  questions,
  offset,
  options,
  optionsByQuestion,
}: ScorableQuestionListFormProps) => {
  return (
    <>
      {questions.map(({ questionText, key }, idx) => {
        const theOptions = optionsByQuestion ? optionsByQuestion[key] : options;
        return (
          <FormControl
            key={key}
            component={RadioGroup}
            name={key}
            label={`${idx + (offset || 0) + 1}. ${questionText}`}
            size="large"
          >
            {theOptions.map(({ displayText, value }) => (
              <Radio key={value} value={String(value)}>
                {displayText}
              </Radio>
            ))}
          </FormControl>
        );
      })}
    </>
  );
};

type ReadonlyScorableQuestionListProps = {
  questions: QuestionsSchema;
} & {
  // Response data from a PatientAssessmentRead.
  response?: PatientAssessmentRead['scorableResponseJson'];
  // Label of the first question in this list
  offset?: number;
} & (
    | {
        options: OptionsSchema;
        optionsByQuestion?: never;
      }
    | {
        options?: never;
        optionsByQuestion: OptionsByQuestionSchema;
      }
  );

/**
 * Renders a readonly version of a list of questions which all have the same response options, and
 * whose values map to a numerical score.
 */
export const ReadonlyScorableQuestionList = ({
  questions,
  offset,
  options,
  optionsByQuestion,
  response,
}: ReadonlyScorableQuestionListProps) => {
  return (
    <>
      {questions.map(({ questionText, key }, idx) => {
        const theOptions = optionsByQuestion ? optionsByQuestion[key] : options;
        return (
          <RadioGroup
            key={key}
            readonly
            label={`${idx + (offset || 0) + 1}. ${questionText}`}
            name={key}
            defaultValue={String(
              response
                ? (response as { [name: string]: number })[key]
                : undefined
            )}
          >
            {theOptions.map(({ displayText, value }) => (
              <Radio key={value} value={String(value)}>
                <SubBodyText>{displayText}</SubBodyText>
              </Radio>
            ))}
          </RadioGroup>
        );
      })}
    </>
  );
};
