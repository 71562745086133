import { useActionGroup, useActionGroupItem } from '@react-aria/actiongroup';
import { PressResponder } from '@react-aria/interactions';
import { ListState, useListState } from '@react-stately/list';
import {
  AriaLabelingProps,
  CollectionChildren,
  ItemElement,
  Node,
  Selection,
} from '@react-types/shared';
import React from 'react';
import { FocusScope, mergeProps, VisuallyHidden } from 'react-aria';
import {
  Button,
  ToggleButtonProps as RaToggleButtonProps,
  ToggleButton,
  ToggleButtonContext,
} from 'react-aria-components';

type DisallowedReactAriaProps = Pick<RaToggleButtonProps, 'style' | 'slot'>;

type ToggleButtonProps = Omit<
  RaToggleButtonProps,
  keyof DisallowedReactAriaProps
> & {
  size?: 'medium' | 'large';
};

function HelixToggleButton({
  size = 'large',
  isDisabled = false,
  ...props
}: ToggleButtonProps) {
  return (
    <ToggleButton
      data-hlx-size={size}
      isDisabled={isDisabled}
      className="hlx-toggle-button"
      {...props}
    />
  );
}

interface ToggleButtonGroupProps extends AriaLabelingProps {
  children: React.ReactNode;
  size?: ToggleButtonProps['size'];
  onAction?: (key: React.Key) => void;
  selectionMode?: 'single' | 'multiple';
  selectedKeys?: React.Key[];
  defaultSelectedKeys?: React.Key[];
  onSelectionChange?: (selectedKeys: Selection) => void;
  isDisabled?: boolean;
}

function ToggleButtonGroup<T>(props: ToggleButtonGroupProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  let state = useListState({
    ...props,
    suppressTextValueWarning: true,
    children: props.children as CollectionChildren<object>,
  });
  let { actionGroupProps } = useActionGroup(
    { ...props, children: props.children as ItemElement<object> },
    state,
    ref
  );

  const children = [...state.collection];

  return (
    <FocusScope>
      <div ref={ref} className="hlx-toggle-button-group" {...actionGroupProps}>
        {children.map((item) => {
          return (
            <ToggleButtonGroupItem
              item={item}
              state={state}
              key={item.key}
              onAction={props.onAction}
              size={props.size ?? 'large'}
              isDisabled={props.isDisabled}
            >
              {item.rendered}
            </ToggleButtonGroupItem>
          );
        })}
      </div>
    </FocusScope>
  );
}

interface ToggleButtonGroupItemProps {
  item: Node<object>;
  state: ListState<object>;
  onAction?: (key: React.Key) => void;
  size: 'medium' | 'large';
  isDisabled?: boolean;
  children: React.ReactNode;
}

export function ToggleButtonGroupItem({
  item,
  state,
  onAction,
  size,
  isDisabled,
  children,
}: ToggleButtonGroupItemProps) {
  let { buttonProps } = useActionGroupItem({ key: item.key }, state);
  isDisabled = isDisabled || state.disabledKeys.has(item.key);

  if (onAction && !isDisabled) {
    buttonProps = mergeProps(buttonProps, {
      onPress: () => onAction?.(item.key),
    });
  }

  return (
    <PressResponder {...buttonProps}>
      <Button
        {...(buttonProps as any)}
        data-hlx-size={size}
        isDisabled={isDisabled}
        className="hlx-toggle-button"
        data-selected={
          state.selectionManager.isSelected(item.key) ? 'true' : null
        }
      >
        {children}
      </Button>
    </PressResponder>
  );
}

export { HelixToggleButton as ToggleButton, ToggleButtonGroup };
