import React from 'react';

import { DiagnosisCodeComboBox } from '@headway/ui/DiagnosisCodeComboBox';

import { SchemaComponent } from '.';

export const FormDiagnosisSelect = ({
  element,
  disabled = false,
}: SchemaComponent) => {
  const { id, placeholder, subTitle } = element;
  return (
    <DiagnosisCodeComboBox
      name={id}
      disabled={disabled}
      instructionalText={subTitle}
      placeholder={placeholder}
      selectionMode="single"
    />
  );
};
