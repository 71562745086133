import { PatientFormSchemaNestedRead } from '@headway/api/models/PatientFormSchemaNestedRead';
import { PatientFormSchemaApi } from '@headway/api/resources/PatientFormSchemaApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';
import { useQuery, UseQueryOptions } from '@headway/shared/react-query';

export interface UsePatientFormSchemaQueryKeyArgs {
  patientFormSchemaId: number;
}

export interface UsePatientFormSchemasQueryKeyArgs {
  providerId: number | undefined;
}

export const getUsePatientFormSchemaQueryKey = ({
  patientFormSchemaId,
}: UsePatientFormSchemaQueryKeyArgs) => [
  'patient-form-schema',
  patientFormSchemaId,
];

export const getUsePatientFormSchemasQueryKey = ({
  providerId,
}: UsePatientFormSchemasQueryKeyArgs) => ['patient-form-schemas', providerId];

const {
  useSingleQuery: usePatientFormSchema,
  useListQuery: usePatientFormSchemaList,
} = createBasicApiHooks(
  getUsePatientFormSchemaQueryKey,
  ({ patientFormSchemaId }) => {
    return PatientFormSchemaApi.getPatientFormSchema(patientFormSchemaId);
  },
  ({ patientFormSchemaId }) => {
    return !!patientFormSchemaId;
  },
  () => 'Failed to fetch intake form'
);

export function usePatientFormSchemas<T = PatientFormSchemaNestedRead[]>(
  queryParams?: {
    providerId?: number;
    deletionStatus?: Array<'ACTIVE' | 'ARCHIVED'>;
  },
  options?: UseQueryOptions<
    PatientFormSchemaNestedRead[],
    unknown,
    T,
    ReturnType<typeof getUsePatientFormSchemasQueryKey>
  >
) {
  return useQuery(
    getUsePatientFormSchemasQueryKey({ providerId: queryParams?.providerId }),
    () =>
      PatientFormSchemaApi.findPatientFormSchemas(
        queryParams?.providerId
          ? {
              provider_id: queryParams.providerId,
              deletion_status: queryParams.deletionStatus,
            }
          : undefined
      ),
    options
  );
}

export { usePatientFormSchema, usePatientFormSchemaList };
