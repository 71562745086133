// adapted from https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
import React from 'react';
import { NumericFormat } from 'react-number-format';

import { FieldInput } from './FieldInput';

export const NumberFormatCustom = React.forwardRef(
  function NumberFormatCustom(props, ref) {
    const { onChange, name, id, ...other } = props;

    return (
      <NumericFormat
        {...other}
        name={name}
        id={id}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.floatValue,
              // Formik's onChange depends on name and id being present in the event target
              name: props.name,
              id: props.id,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

export const FieldNumberFormatInput = (props) => (
  <FieldInput inputComponent={NumberFormatCustom} {...props} />
);
