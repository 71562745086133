import * as React from 'react';

export const Italic = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.787 15.963v-1.8h2.57l3.356-8.326H7.806v-1.8h7.425v1.8h-2.569l-3.375 8.325h2.907v1.8H4.787z"
      fill="currentColor"
    />
  </svg>
);
