import { useLocation, useNavigate } from 'react-router-dom';

import { ProviderRead } from '@headway/api/models/ProviderRead';

import { PROVIDER_VIEW_STORAGE_KEY } from 'utils/contstants';

import { useAuthStore } from '../stores/AuthStore';

/**
 * Updates the selected provider in AuthStore and local storage.
 */
export const useSetSelectedProvider = (): ((
  provider: ProviderRead
) => void) => {
  const AuthStore = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  return (provider: ProviderRead) => {
    AuthStore.setProvider(provider);

    if (
      location.pathname.match(/\/clients\/[0-9]+/) ||
      location.pathname.match(/\/credentials/)
    ) {
      navigate('/');
    }

    window.localStorage.setItem(
      PROVIDER_VIEW_STORAGE_KEY,
      provider.id.toString()
    );
  };
};
