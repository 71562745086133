import moment from 'moment-timezone';

export const toFloatingDateTime = (localizedMaybeDate, tzid) => {
  // make sure that we're working with a date instead of a string
  const localizedDate = new Date(localizedMaybeDate);

  // convert the incoming date to the timezone provided
  const localizedToTimezoneDate = moment.tz(localizedDate, tzid);

  // return a floating date where the values are not independent of any timezone
  return new Date(
    Date.UTC(
      localizedToTimezoneDate.year(),
      localizedToTimezoneDate.month(),
      localizedToTimezoneDate.date(),
      localizedToTimezoneDate.hour(),
      localizedToTimezoneDate.minute()
    )
  );
};

export const toLocalizedDateTime = (floatingMaybeDate, tzid) => {
  // make sure that we're working with a date instead of a string
  const floatingDate = new Date(floatingMaybeDate);

  // return a localized date with values equal to the floating date values at the timezone provided
  return moment
    .tz(
      {
        year: floatingDate.getUTCFullYear(),
        month: floatingDate.getUTCMonth(),
        date: floatingDate.getUTCDate(),
        hours: floatingDate.getUTCHours(),
        minutes: floatingDate.getUTCMinutes(),
      },
      tzid
    )
    .toDate();
};

export const formatTimezoneDescription = (timezone) => {
  return `(GMT${moment().tz(timezone).format('Z) zz')} - ${timezone
    .replace('America/', '')
    .replace('_', ' ')}`;
};
