import React from 'react';

import { Checkbox as HelixCheckbox } from '@headway/helix/Checkbox';
import { FormControl } from '@headway/helix/FormControl';

import { SchemaComponent } from '.';

const MemoizedCheckbox = React.memo(HelixCheckbox);

export const Checkbox = ({
  element,
  template,
  disabled = false,
}: SchemaComponent) => {
  const { id, title } = element;

  return (
    <FormControl component={MemoizedCheckbox} name={id} disabled={disabled}>
      {title}
    </FormControl>
  );
};
