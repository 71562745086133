import { Skeleton } from '@mui/material';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import { PatientAddressCreate } from '@headway/api/models/PatientAddressCreate';
import { PatientAddressType } from '@headway/api/models/PatientAddressType';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Banner } from '@headway/helix/Banner';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { addressTypeDisplayNames } from '@headway/shared/constants/addressTypeDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { TELEHEALTH_LOCATIONS_SIGMUND } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useMutation, useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { logException } from '@headway/shared/utils/sentry';
import useScript from '@headway/shared/utils/useScript';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import {
  HandleLocationSelectProps,
  HelixLocationFilter,
} from '@headway/ui/HelixLocationFilter';

import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';

const STATES_ARRAY = Object.entries(statesToDisplayNames);

export interface PatientAddressFormIntakeFlowProps {
  providerId: number;
  apiKey: string;
  client: UserRead;
  onSuccess?: (user: UserRead) => void;
}

export type PatientAddressFormIntakeFlowValues = {
  streetLine1: string;
  streetLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  addressLocationDescription?: PatientAddressType;
  patientJoiningFrom?: string;
};

const patientAddressFormInitialValues: PatientAddressFormIntakeFlowValues = {
  city: '',
  state: '',
  streetLine1: '',
  streetLine2: '',
  zipCode: '',
};

const telehealthFormIntialValues = {
  ...patientAddressFormInitialValues,
  addressLocationDescription: 'HOME',
  patientJoiningFrom: '',
} as PatientAddressFormIntakeFlowValues;

const patientAddressFormValidationSchema = Yup.object().shape({
  city: Yup.string().required('City is required.'),
  state: Yup.string().required('State is required.'),
  streetLine1: Yup.string().required('Street line 1 is required.'),
  streetLine2: Yup.string().nullable().default(null),
  zipCode: Yup.string()
    .required('Zip code is required.')
    .matches(/^[0-9]+$/, 'Must be only digits'),
});

const telehealthFormValidationSchema = Yup.object().shape({
  ...patientAddressFormValidationSchema.fields,
  addressLocationDescription: Yup.string().required(
    'A description is required.'
  ),
  patientJoiningFrom: Yup.string().required('A selection is required.'),
});

export const PatientAddressFormIntakeFlow = ({
  providerId,
  apiKey,
  client,
  onSuccess,
}: PatientAddressFormIntakeFlowProps) => {
  const { setCurrentStep } = useContext(AddPatientModalContext);

  const queryClient = useQueryClient();
  const telehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );

  const gmapsScriptStatus = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3&libraries=places`
  );

  const createPatientAddressMutation = useMutation(
    (variables: PatientAddressCreate) => {
      return PatientAddressApi.createPatientAddress(variables);
    }
  );

  const handlePatientAddressFormSubmit = async (
    values: PatientAddressFormIntakeFlowValues
  ) => {
    try {
      const address = await createPatientAddressMutation.mutateAsync({
        patientUserId: client.id,
        city: values.city,
        state: values.state as UnitedStates,
        streetLine1: values.streetLine1,
        streetLine2: values.streetLine2,
        zipCode: values.zipCode,
        addressType: telehealthLocationsEnabled
          ? values.addressLocationDescription
          : undefined,
      });

      const user = await UserApi.updateUser(client.id, {
        activePatientAddressId: address.id,
      });
      onSuccess && onSuccess(user);
      await queryClient.invalidateQueries(['claim-readiness']);

      onAddressFormComplete(values);
    } catch (e) {
      logException(e);
    }
  };

  const onSkip = () => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Add Telehealth Address',
        stepName: 'PATIENT_ADDRESS',
      },
    });

    if (telehealthLocationsEnabled) {
      setCurrentStep(AddPatientModalPage.TELEHEALTH_LOCATIONS);
    } else {
      setCurrentStep(AddPatientModalPage.SEND_ACCOUNT_INVITE);
    }
  };

  const onAddressFormComplete = (
    values: PatientAddressFormIntakeFlowValues
  ) => {
    trackEvent({
      name: 'Add Patient Step Viewed',
      properties: {
        screenName: 'Add Telehealth Address',
        stepName: 'PATIENT_ADDRESS',
        telehealthSession: values.patientJoiningFrom
          ? values.patientJoiningFrom === 'joiningFromAnotherAddress'
          : undefined,
      },
    });

    if (telehealthLocationsEnabled) {
      if (values.patientJoiningFrom === 'joiningFromAnotherAddress') {
        setCurrentStep(AddPatientModalPage.TELEHEALTH_LOCATIONS);
      } else {
        setCurrentStep(AddPatientModalPage.SEND_ACCOUNT_INVITE);
      }
    } else {
      setCurrentStep(AddPatientModalPage.SEND_ACCOUNT_INVITE);
    }
  };

  const firstName = formatFirstName(client);

  const initialValues = telehealthLocationsEnabled
    ? telehealthFormIntialValues
    : patientAddressFormInitialValues;

  const validationSchema = telehealthLocationsEnabled
    ? telehealthFormValidationSchema
    : patientAddressFormValidationSchema;

  return (
    <Formik
      validateOnMount={true}
      onSubmit={handlePatientAddressFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <>
            <ModalContent>
              <SafeFormikForm
                id="patient-address-form"
                style={{
                  gap: 0,
                  height: '100%',
                }}
              >
                <PageSection>
                  <div>
                    {createPatientAddressMutation.status === 'error' && (
                      <div
                        css={{
                          marginTop: theme.spacing.x4,
                          marginBottom: theme.spacing.x4,
                        }}
                      >
                        <Banner variant="error">
                          We couldn't save this address. Please try again.
                        </Banner>
                      </div>
                    )}

                    <SectionHeader>
                      Add address information for {firstName}{' '}
                    </SectionHeader>
                    <div
                      css={{
                        marginTop: theme.spacing.x4,
                      }}
                    >
                      <BodyText>
                        <strong>
                          We ask for a home address because the insurance
                          company requires residence verification for
                          eligibility and claims. We will not use this
                          information otherwise, nor will we share it with
                          anyone besides the insurance and provider.
                        </strong>
                      </BodyText>

                      {gmapsScriptStatus === 'ready' ? (
                        <div css={{ marginTop: theme.spacing.x4 }}>
                          <FormControl
                            component={HelixLocationFilter}
                            placeholder="Search for an address"
                            selectionMode="single"
                            GOOGLE_MAPS_API_KEY={apiKey}
                            name="address"
                            label="Address"
                            handleLocationSelect={({
                              streetLine1,
                              city,
                              state,
                              zipCode,
                            }: HandleLocationSelectProps) => {
                              setFieldValue('streetLine1', streetLine1);
                              setFieldValue('state', state);
                              setFieldValue('city', city);
                              setFieldValue('zipCode', zipCode);
                            }}
                          />
                        </div>
                      ) : gmapsScriptStatus === 'loading' ? (
                        <Skeleton variant="rectangular" height={40} />
                      ) : null}
                      <div
                        css={{
                          marginTop: theme.spacing.x4,
                          display: 'grid',
                          gap: theme.spacing.x4,
                        }}
                      >
                        <FormControl
                          component={TextField}
                          label="Street line 1"
                          name="streetLine1"
                        />
                        <FormControl
                          component={TextField}
                          label="Street line 2"
                          name="streetLine2"
                        />
                        <FormControl
                          component={TextField}
                          label="City"
                          name="city"
                        />
                        <FormControl
                          component={Select}
                          selectionMode="single"
                          label="State"
                          name="state"
                          selectedKeys={values.state ? [values.state] : []}
                        >
                          {STATES_ARRAY.map(([key, value]) => (
                            <Item key={key}>{value}</Item>
                          ))}
                        </FormControl>
                        <FormControl
                          component={TextField}
                          label="Zip code"
                          name="zipCode"
                        />
                        {telehealthLocationsEnabled && (
                          <>
                            <FormControl
                              component={Select}
                              label="Describe the client's location"
                              name="addressLocationDescription"
                              selectionMode="single"
                            >
                              {(
                                Object.keys(
                                  addressTypeDisplayNames
                                ) as PatientAddressType[]
                              ).map((addressType) => (
                                <Item key={addressType}>
                                  {addressTypeDisplayNames[addressType]}
                                </Item>
                              ))}
                            </FormControl>
                            <FormControl
                              component={RadioGroup}
                              label="Will your client be joining virtual telehealth sessions from this address?"
                              name="patientJoiningFrom"
                            >
                              <Radio value="joiningFromThisAddress">
                                Yes, my client will be joining virtual
                                telehealth sessions from this address.
                              </Radio>
                              <Radio value="joiningFromAnotherAddress">
                                No, my client will be joining virtual telehealth
                                sessions from another address.
                              </Radio>
                            </FormControl>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </PageSection>
              </SafeFormikForm>
            </ModalContent>
            <ModalFooter>
              <Button variant="secondary" onPress={onSkip}>
                Skip
              </Button>
              <Button
                disabled={isSubmitting}
                variant="primary"
                type="submit"
                form="patient-address-form"
              >
                {isSubmitting ? 'Saving' : 'Save and continue'}
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};
