import React from 'react';

export const MaxWidthContainer: React.FC<React.PropsWithChildren<unknown>> = (
  props
) => (
  <div
    css={{
      maxWidth: 1200,
      padding: '0 15px',
      margin: '0 auto',
      width: '100%',
    }}
    {...props}
  />
);
