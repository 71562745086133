/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { BulkCreateProviderFrontEndCarrierRosterAuditStatusResult } from '../models/BulkCreateProviderFrontEndCarrierRosterAuditStatusResult';
import { BulkUpdateProviderFrontEndCarrierResult } from '../models/BulkUpdateProviderFrontEndCarrierResult';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderFrontEndCarrierBulkUpdate } from '../models/ProviderFrontEndCarrierBulkUpdate';
import { ProviderFrontEndCarrierCreate } from '../models/ProviderFrontEndCarrierCreate';
import { ProviderFrontEndCarrierRead } from '../models/ProviderFrontEndCarrierRead';
import { ProviderFrontEndCarrierRosterAuditStatusCreate } from '../models/ProviderFrontEndCarrierRosterAuditStatusCreate';
import { ProviderFrontEndCarrierRosterAuditStatusRead } from '../models/ProviderFrontEndCarrierRosterAuditStatusRead';
import { ProviderFrontEndCarrierUpdate } from '../models/ProviderFrontEndCarrierUpdate';
import { ProviderFrontEndCarrierVisibilityUpdate } from '../models/ProviderFrontEndCarrierVisibilityUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderFrontEndCarrierApiResource {

    /**
     * Add Provider Front End Carrier To Provider
     * 
     * @param providerFrontEndCarrierCreate 
     
     */
    public addProviderFrontEndCarrierToProvider(body: ProviderFrontEndCarrierCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderFrontEndCarrierRead> {
        const apiRoute = '/add-provider-front-end-carrier-to-provider';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Create Provider Front End Carrier Roster Audit Statuses
     * 
     * @param providerFrontEndCarrierRosterAuditStatusCreate 
     
     */
    public bulkCreateProviderFrontEndCarrierRosterAuditStatuses(body: Array<ProviderFrontEndCarrierRosterAuditStatusCreate>, axiosConfig?: AxiosRequestConfig): Promise<Array<BulkCreateProviderFrontEndCarrierRosterAuditStatusResult>> {
        const apiRoute = '/bulk-create-provider-front-end-carrier-roster-audit-statuses';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Update Provider Front End Carrier
     * 
     * @param providerFrontEndCarrierBulkUpdate 
     
     */
    public bulkUpdateProviderFrontEndCarrier(body: Array<ProviderFrontEndCarrierBulkUpdate>, axiosConfig?: AxiosRequestConfig): Promise<Array<BulkUpdateProviderFrontEndCarrierResult>> {
        const apiRoute = '/bulk-update-provider-front-end-carrier';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Update Provider Front End Carrier Visibility
     * 
     * @param providerFrontEndCarrierVisibilityUpdate 
     
     */
    public bulkUpdateProviderFrontEndCarrierVisibility(body: Array<ProviderFrontEndCarrierVisibilityUpdate>, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderFrontEndCarrierRead>> {
        const apiRoute = '/bulk-update-provider-front-end-carrier-visibility';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Provider Front End Carrier
     * 
     * @param providerFrontEndCarrierId 
     
     */
    public deleteProviderFrontEndCarrier(providerFrontEndCarrierId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderFrontEndCarrierRead> {
        const apiRoute = '/provider-front-end-carrier/{provider_front_end_carrier_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_front_end_carrier_id}', String(providerFrontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Front End Carrier
     * 
     * @param providerFrontEndCarrierId 
     
     */
    public getProviderFrontEndCarrier(providerFrontEndCarrierId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderFrontEndCarrierRead> {
        const apiRoute = '/provider-front-end-carrier/{provider_front_end_carrier_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_front_end_carrier_id}', String(providerFrontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Front End Carriers
     * 
     * @param providerId 
     
     */
    public getProviderFrontEndCarriers(query?: { provider_id: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderFrontEndCarrierRead>> {
        const apiRoute = '/provider-front-end-carrier';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Roster Audit Statuses By Provider Front End Carrier Id
     * 
     * @param providerFrontEndCarrierId 
     
     */
    public getRosterAuditStatusesByProviderFrontEndCarrierId(providerFrontEndCarrierId: any, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderFrontEndCarrierRosterAuditStatusRead>> {
        const apiRoute = '/provider-front-end-carrier/{provider_front_end_carrier_id}/roster-audit-statuses';
        const reqPath = apiRoute
                    .replace('{' + 'provider_front_end_carrier_id}', String(providerFrontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Credentialed Email By Provider Front End Carrier
     * 
     * @param providerFrontEndCarrierId 
     
     */
    public sendCredentialedEmailByProviderFrontEndCarrier(providerFrontEndCarrierId: any, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-front-end-carrier/{provider_front_end_carrier_id}/send-credentialed-email';
        const reqPath = apiRoute
                    .replace('{' + 'provider_front_end_carrier_id}', String(providerFrontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Front End Carrier
     * 
     * @param providerFrontEndCarrierId 
     * @param providerFrontEndCarrierUpdate 
     
     */
    public updateProviderFrontEndCarrier(providerFrontEndCarrierId: number, body: ProviderFrontEndCarrierUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderFrontEndCarrierRead> {
        const apiRoute = '/provider-front-end-carrier/{provider_front_end_carrier_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_front_end_carrier_id}', String(providerFrontEndCarrierId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderFrontEndCarrierApi = new ProviderFrontEndCarrierApiResource();
