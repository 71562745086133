import { reject } from 'lodash';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import {
  AppointmentStatusData,
  filterByAppointmentStatus,
} from './appointmentStatus';
import { CalendarViewData, filterByCalendarView } from './calendarView';
import {
  filterByExcludingProviderCalendars,
  FilterByExcludingProviderCalendarsData,
} from './excludeProviderCalendar';

/**
 * Specifies filters to run against events.
 *
 * If you want to add another filter:
 *
 * 1. Create a new file [your filter].ts
 *    - Should export a function that is of type Filter
 * 2. Add your filter to following enum, map, and type
 * 3. Update your filterEvents call with the the new filter
 */

export enum EventFilters {
  CALENDAR_VIEW = 'CALENDAR_VIEW',
  APPOINTMENT_STATUS = 'APPOINTMENT_STATUS',
  EXCLUDE_PROVIDER_CALENDARS = 'EXCLUDE_PROVIDER_CALENDARS',
}

export type EventFilterData = {
  [key in keyof typeof EventFilters]: CalendarViewData &
    FilterByExcludingProviderCalendarsData &
    AppointmentStatusData;
};

const EventFiltersMap = {
  [EventFilters.CALENDAR_VIEW]: filterByCalendarView,
  [EventFilters.APPOINTMENT_STATUS]: filterByAppointmentStatus,
  [EventFilters.EXCLUDE_PROVIDER_CALENDARS]: filterByExcludingProviderCalendars,
};

/**
 * Generic EventFilterType. A created filter
 * should implement this
 */
export type EventFilterFunction<FilterData> = (
  event: ProviderEventRead,
  filterData: FilterData
) => boolean;

function isNotUndefined(
  filter: Partial<EventFilterData>,
  prop: keyof typeof EventFilters
): filter is EventFilterData {
  return (filter as EventFilterData)[prop] !== undefined;
}

export const filterEvents = (
  events: ProviderEventRead[],
  filterData: Partial<EventFilterData>
): ProviderEventRead[] =>
  reject(
    events,
    (event) =>
      !!Object.keys(filterData).find((filter) => {
        if (isNotUndefined(filterData, filter as keyof typeof EventFilters)) {
          return EventFiltersMap[filter as EventFilters](
            event,
            filterData[filter as keyof typeof EventFilters]
          );
        }

        return false;
      })
  );
