import React from 'react';

import { Banner } from '@headway/helix/Banner';
import { Link } from '@headway/helix/Link';

interface GroupPracticeBillingBannerProps {
  bannerOffset: number;
  isGroupAdmin: boolean;
}

export const GroupPracticeBillingBanner = ({
  bannerOffset,
  isGroupAdmin,
}: GroupPracticeBillingBannerProps) => {
  const bannerText = isGroupAdmin
    ? 'You won’t be able to confirm sessions until you update your practice’s billing information'
    : 'You won’t be able to confirm sessions until your admin updates your practice’s billing information.';
  return (
    <div
      css={{
        position: 'fixed',
        height: 50,
        top: 0 + bannerOffset,
        width: '100%',
        '& .hlx-banner': {
          height: '100%',
        },
      }}
    >
      <Banner
        adornment={
          isGroupAdmin ? (
            <Link href="/settings/billing">Update billing info</Link>
          ) : null
        }
        variant="warning"
      >
        {bannerText}
      </Banner>
    </div>
  );
};
