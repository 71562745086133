import * as React from 'react';

export const HighlightCalendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M34.247 41.621H7.491C6.671 41.621 6 40.95 6 40.13V12.26c0-.82 1.21-1.772 2.165-.817l27.35 27.35c.897.897.323 2.83-1.268 2.83v-.002z"
      fill="#FFEFDC"
    />
    <path
      d="M36.941 8.644h-5.36V6.81a.81.81 0 00-1.62 0v1.834H19.506V6.81a.81.81 0 00-1.62 0v1.834h-5.36a1.996 1.996 0 00-1.994 1.993V35.05c0 1.099.895 1.993 1.993 1.993H36.94a1.996 1.996 0 001.993-1.993V10.635a1.996 1.996 0 00-1.993-1.993l.002.002zm.373 26.406a.372.372 0 01-.373.373H12.53a.372.372 0 01-.373-.373V10.635c0-.206.166-.373.373-.373h5.36v1.834a.81.81 0 001.62 0v-1.834h10.454v1.834a.81.81 0 001.62 0v-1.834h5.36c.207 0 .374.167.374.373V35.05h-.003zm-5.251-16.134l-7.401 9.103a.946.946 0 01-.681.346h-.048a.939.939 0 01-.663-.276l-4.48-4.481a.811.811 0 011.145-1.146l3.946 3.947 6.924-8.517a.807.807 0 011.139-.117c.347.28.4.793.116 1.138l.003.003z"
      fill="#128656"
    />
  </svg>
);
