/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderAgeGroupCreate } from '../models/ProviderAgeGroupCreate';
import { ProviderAgeGroupRead } from '../models/ProviderAgeGroupRead';
import { ProviderAgeGroupUpdate } from '../models/ProviderAgeGroupUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderAgeGroupApiResource {

    /**
     * Create Provider Age Group
     * 
     * @param providerAgeGroupCreate 
     
     */
    public createProviderAgeGroup(body: ProviderAgeGroupCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderAgeGroupRead> {
        const apiRoute = '/provider-age-group';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Provider Age Group
     * 
     * @param providerAgeGroupId 
     
     */
    public deleteProviderAgeGroup(providerAgeGroupId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderAgeGroupRead> {
        const apiRoute = '/provider-age-group/{provider_age_group_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_age_group_id}', String(providerAgeGroupId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Age Groups
     * 
     * @param providerId 
     
     */
    public getProviderAgeGroups(query?: { provider_id: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderAgeGroupRead>> {
        const apiRoute = '/provider-age-group';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Age Group
     * 
     * @param providerAgeGroupId 
     * @param providerAgeGroupUpdate 
     
     */
    public updateProviderAgeGroup(providerAgeGroupId: number, body: ProviderAgeGroupUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderAgeGroupRead> {
        const apiRoute = '/provider-age-group/{provider_age_group_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_age_group_id}', String(providerAgeGroupId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderAgeGroupApi = new ProviderAgeGroupApiResource();
