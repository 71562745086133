import PDFObject from 'pdfobject';
import React, { useEffect, useState } from 'react';

import { Malpractice } from '@headway/api/models/Malpractice';
import { UserUploadApi } from '@headway/api/resources/UserUploadApi';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';

import { ConfirmationModalPage } from '../../Malpractice/Malpractice';

export interface ConfirmDocumentsProps {
  values: any;
  setConfirmationModalPage: any;
  closeModal: () => void;
  next: () => void;
  shouldValidateCoiUploads: boolean;
}

export const ConfirmDocuments: React.FC<
  React.PropsWithChildren<ConfirmDocumentsProps>
> = ({
  values,
  setConfirmationModalPage,
  closeModal,
  next,
  shouldValidateCoiUploads,
}) => {
  const [malpracticeUrls, setMalpracticeUrls] = useState(['']);
  const [malpracticeDocumentIndex, setMalpracticeDocumentIndex] = useState(0);

  useEffect(() => {
    async function getMalpracticeUrls() {
      const malpracticeUrls = (await Promise.all(
        values.malpracticeInsurances.map(async (malpractice: Malpractice) => {
          if (!malpractice?.liabilityInsuranceUrl?.[0]?.link) return;
          const res = await UserUploadApi.generatePresignedGetUrl({
            url: malpractice?.liabilityInsuranceUrl?.[0]?.link,
          });
          return res.signedUrl;
        })
      )) as string[];
      setMalpracticeUrls(malpracticeUrls);
    }

    getMalpracticeUrls();
  }, [values.malpracticeInsurances]);

  useEffect(() => {
    PDFObject.embed(
      `${malpracticeUrls[malpracticeDocumentIndex]}`,
      '#pdfContainer',
      {
        fallbackLink: `<p>This browser does not support inline PDFs. Please download the PDF to view it: <a href='${malpracticeUrls[malpracticeDocumentIndex]}' target='_blank' rel='noopener noreferrer'>Download PDF</a></p>`,
      }
    );
  }, [malpracticeUrls, malpracticeDocumentIndex]);

  return (
    <>
      <div css={{ marginBottom: theme.spacing.x4 }}>
        <span>Please confirm that the documents uploaded are correct.</span>
      </div>
      <div css={{ marginBottom: theme.spacing.x2 }}>
        <span>Document {malpracticeDocumentIndex + 1}:</span>
        <div
          css={{
            height: PDFObject.supportsPDFs ? '400px' : '200px',
            width: '100%',
          }}
          id="pdfContainer"
        />
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: theme.spacing.x4,
          gap: theme.spacing.x2,
        }}
      >
        <Button variant="secondary" onPress={() => closeModal()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          data-testid="ConfirmDocumentsModalContinueButton"
          onPress={() => {
            if (malpracticeDocumentIndex === malpracticeUrls.length - 1) {
              if (shouldValidateCoiUploads) {
                next();
              } else {
                setConfirmationModalPage(ConfirmationModalPage.CONFIRM_NAME);
              }
            } else {
              setMalpracticeDocumentIndex(malpracticeDocumentIndex + 1);
            }
          }}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};
