import { Malpractice } from '@headway/api/models/Malpractice';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { VerificationType } from '@headway/api/models/VerificationType';

export const getInsuranceCoiS3Key = (insurance?: Malpractice) =>
  insurance?.liabilityInsuranceUrl?.[0]?.s3ObjectKey || null;

export const getCurrentCoiPqvs = (
  pqvs: ProviderQuestionnaireVerificationRead[],
  malpracticeInsurances: Malpractice[]
) => {
  const malpracticeCoiS3Keys =
    malpracticeInsurances
      ?.filter((insurance) => getInsuranceCoiS3Key(insurance))
      .map((insurance) => getInsuranceCoiS3Key(insurance)) || [];
  return pqvs.filter(
    (pqv) =>
      pqv.type === VerificationType.CERTIFICATE_OF_INSURANCE &&
      malpracticeCoiS3Keys.includes(pqv.requestJson.coiS3Key)
  );
};
