import * as React from 'react';

export const CEUOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M9.5 10.2202C4.4 6.09617 1 6.2221 1 6.2221V15.3989C5.98981 15.5249 9.5 19.098 9.5 19.098C9.5 19.098 13.0102 15.5249 18 15.3989V6.2221C18 6.2221 14.6157 6.09617 9.5 10.2202Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-miterlimit="10"
    />
    <path
      d="M9.50062 6.91531C11.1611 6.91531 12.5071 5.56926 12.5071 3.90883C12.5071 2.24839 11.1611 0.902344 9.50062 0.902344C7.84019 0.902344 6.49414 2.24839 6.49414 3.90883C6.49414 5.56926 7.84019 6.91531 9.50062 6.91531Z"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-miterlimit="10"
    />
  </svg>
);
