import { useProvider } from 'hooks';
import React from 'react';

import { PriceChangeDetailsResponse } from '@headway/api/models/PriceChangeDetailsResponse';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { trackEvent } from '@headway/shared/utils/analytics';

import clinicalLeadHeadshotImage from 'assets/img/michael_heckendorn.png';
import { useEligibilityLookup } from 'hooks/useEligibilityLookup';
import {
  getPatientPlanDescription,
  getPatientPriceChangeDescription,
} from 'utils/patient';

const PatientPriceChangeModal = ({
  isOpen = false,
  onClose,
  patient,
  priceChangeDetails,
}: {
  isOpen: boolean;
  onClose: () => void;
  patient: UserRead;
  priceChangeDetails: PriceChangeDetailsResponse;
}) => {
  const provider = useProvider();
  return (
    <Modal
      title={`An update about ${
        patient.displayFirstName || 'your patient'
      }’s session cost`}
      onDismiss={onClose}
      isOpen={isOpen}
      isDismissable
    >
      <ModalContent>
        <div className="flex flex-col gap-4 text-system-black">
          <div>
            <p>Hi {provider.displayFirstName},</p>
            <p>
              We know that the final cost from{' '}
              {patient.activeUserInsurance?.billingFrontEndCarrierName ||
                `${patient.displayFirstName}’s insurer`}{' '}
              is different than our estimate so we wanted to give you a bit more
              context on what we learned.
            </p>
          </div>
          <section>
            <h3 className="hlx-typography-body mt-0 font-bold">
              Why did the cost change?
            </h3>
            <p>
              {getPatientPriceChangeDescription({
                patient,
                priceChangeDetails,
              })}
            </p>
          </section>
          <section>
            <h3 className="hlx-typography-body mt-0 font-bold">
              What if my client has questions?
            </h3>
            <p>
              They can review their Explanation of Benefits (EOB) from{' '}
              {patient.activeUserInsurance?.billingFrontEndCarrierName ||
                'their insurer'}{' '}
              to make sure this info is correct. If they still have questions,
              they can always reach out to us.
            </p>
          </section>
          <p>Thanks for your understanding!</p>
          <div className="flex gap-2">
            <img
              src={clinicalLeadHeadshotImage}
              alt="Michael"
              height="40"
              width="40"
              className="rounded-full"
            />
            <div className="flex flex-col">
              <span className="font-bold">Michael</span>
              <SubBodyText>Clinical Lead at Headway</SubBodyText>
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button variant="primary" onPress={onClose}>
          Got it
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const PricingInfoInsightCard = ({
  patient,
  providerId,
  priceChangeDetails,
}: {
  patient: UserRead;
  providerId: number;
  priceChangeDetails?: PriceChangeDetailsResponse;
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const insurance = patient.activeUserInsurance;
  const { data: latestEligibilityLookup } = useEligibilityLookup({
    eligibilityLookupId: insurance?.latestEligibilityLookup?.id,
  });
  const frontEndCarrierName =
    insurance?.billingFrontEndCarrierName ||
    `${patient.displayFirstName}’s insurer`;
  const hasPriceChanged =
    priceChangeDetails?.priceChanges &&
    priceChangeDetails.priceChanges.length > 0;

  if (!latestEligibilityLookup) {
    return null;
  }

  if (hasPriceChanged) {
    const priceChangeDirection =
      priceChangeDetails.currentPrice! > priceChangeDetails.previousPrice!
        ? 'higher'
        : 'lower';
    return (
      <>
        <GuidanceCard
          variant="insight"
          title={`${frontEndCarrierName} updated ${patient.displayFirstName}’s cost after reviewing their claim`}
        >
          After reviewing {patient.displayFirstName}’s claims,{' '}
          {frontEndCarrierName} let us know that their session cost is{' '}
          {priceChangeDirection} than our initial estimate. We’ve already
          notified them and will use this cost for their future sessions.
          <Button
            variant="link"
            onPress={() => {
              trackEvent({
                name: 'Learn More About Client Price Change in Autopay Clicked',
                properties: {
                  providerId: providerId,
                  patientUserId: patient.id,
                },
              });
              setIsModalOpen(true);
            }}
          >
            Learn more about this change
          </Button>
        </GuidanceCard>
        <PatientPriceChangeModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          patient={patient}
          priceChangeDetails={priceChangeDetails}
        />
      </>
    );
  }
  return (
    <GuidanceCard
      variant="insight"
      title={`How is ${patient.displayFirstName}’s cost determined?`}
    >
      {getPatientPlanDescription(patient, latestEligibilityLookup!)}{' '}
      <p>
        This is our best estimate based on everything we know about{' '}
        {patient.displayFirstName}’s plan. After {frontEndCarrierName} reviews,
        they may adjust the final cost. If this happens, we’ll email{' '}
        {patient.displayFirstName} an update. If this doesn’t look right, let us
        know.
      </p>
    </GuidanceCard>
  );
};
