import { Card, CardContent } from '@mui/material';
import { useProvider } from 'hooks';
import moment from 'moment';

import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';

import { ErrorDataField } from './CoiDataFields';
import { ErrorObject, formatNumber } from './CoiUtils';

const populateErrorValues = (pqv?: ProviderQuestionnaireVerificationRead) => {
  const incorrectValueMap = {
    nameInsured: '',
    expirationDate: '',
    occurrenceLimit: '',
    aggregateLimit: '',
  };
  const errorMessageMap = {
    nameInsured: '',
    expirationDate: '',
    occurrenceLimit: '',
    aggregateLimit: '',
  };
  if (pqv) {
    const errors = pqv.responseJson?.errorsFound as ErrorObject[];
    errors.forEach((error) => {
      switch (error.errorType) {
        case 'bad_name':
          incorrectValueMap.nameInsured = error.incorrectValuesFound.length
            ? error.incorrectValuesFound[0]
            : '';
          errorMessageMap.nameInsured = 'You must be the insured individual.';
          break;
        case 'bad_expiration_date':
          incorrectValueMap.expirationDate = error.incorrectValuesFound.length
            ? error.incorrectValuesFound[0]
            : '';
          errorMessageMap.expirationDate = error.errorMessage;
          break;
        case 'bad_per_claim_limit':
          incorrectValueMap.occurrenceLimit = error.incorrectValuesFound.length
            ? error.incorrectValuesFound[0]
            : '';
          errorMessageMap.occurrenceLimit = error.errorMessage;
          break;
        case 'bad_aggregate_limit':
          incorrectValueMap.aggregateLimit = error.incorrectValuesFound.length
            ? error.incorrectValuesFound[0]
            : '';
          errorMessageMap.aggregateLimit = error.errorMessage;
          break;
      }
    });
  }
  return { incorrectValueMap, errorMessageMap };
};

const TextContent = ({
  pqv,
}: {
  pqv: ProviderQuestionnaireVerificationRead | undefined;
}) => {
  const { incorrectValueMap, errorMessageMap } = populateErrorValues(pqv);
  const provider = useProvider();

  return (
    <Card variant="outlined" sx={{ marginBottom: 1 }}>
      <CardContent>
        <BodyText>
          <div className="grid gap-y-4">
            <div className="text-base">
              <strong>Your coverage details:</strong>
            </div>
            <ErrorDataField
              fieldName="Name insured"
              fieldContent={
                pqv?.responseJson?.nameInsuredFound
                  ? provider.name
                  : incorrectValueMap.nameInsured
              }
              errorMsg={errorMessageMap.nameInsured ?? ''}
            />
            <ErrorDataField
              fieldName="Expiration date"
              fieldContent={
                moment(pqv?.responseJson?.coverageEndDateFound).format(
                  'MM/DD/YYYY'
                ) ?? incorrectValueMap.expirationDate
              }
              errorMsg={errorMessageMap.expirationDate ?? ''}
            />
            <ErrorDataField
              fieldName="Occurrence limit"
              fieldContent={
                formatNumber(pqv?.responseJson?.perClaimCoverageLimitFound) ??
                incorrectValueMap.occurrenceLimit
              }
              errorMsg={errorMessageMap.occurrenceLimit ?? ''}
            />
            <ErrorDataField
              fieldName="Aggregate limit"
              fieldContent={
                formatNumber(pqv?.responseJson?.aggregateCoverageLimitFound) ??
                incorrectValueMap.aggregateLimit
              }
              errorMsg={errorMessageMap.aggregateLimit ?? ''}
            />
          </div>
        </BodyText>
      </CardContent>
    </Card>
  );
};

export const CoiIneligible = ({
  coiVerification,
}: {
  coiVerification?: ProviderQuestionnaireVerificationRead;
}) => {
  return (
    <div className="grid gap-y-4">
      <GuidanceCard variant="error" layout="vertical">
        <div>
          <b>The file you uploaded does not meet requirements.</b> Contact your
          malpractice insurance provider to either request an updated document
          or get more coverage.
        </div>
      </GuidanceCard>
      <div>{<TextContent pqv={coiVerification} />}</div>
    </div>
  );
};
