import { MessageApi } from '@headway/api/resources/MessageApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderUnreadMessagesQueryKeyArgs {
  providerId: number | undefined;
}
export const useProviderUnreadMessagesQueryKey = ({
  providerId,
}: UseProviderUnreadMessagesQueryKeyArgs) => [
  'provider-unread-messages',
  providerId,
];

const {
  useSingleQuery: useUnreadProviderMessages,
  useListQuery: useUnreadProviderMessagesList,
  useCachedQuery: useUnreadProviderMessagesCache,
} = createBasicApiHooks(
  useProviderUnreadMessagesQueryKey,
  ({ providerId }) => MessageApi.getUnreadProviderMessageThreads(providerId!),
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch unread messages'
);

export {
  useUnreadProviderMessages,
  useUnreadProviderMessagesList,
  useUnreadProviderMessagesCache,
};
