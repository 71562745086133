import * as React from 'react';

export const ReferralOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.675 18.25c1.25 0 2.312-.438 3.187-1.312.875-.875 1.313-1.938 1.313-3.188h-1.126c0 .937-.328 1.734-.984 2.39-.656.657-1.453.985-2.39.985v1.125zm-12-12H2.8c0-.937.328-1.734.984-2.39.656-.657 1.453-.985 2.39-.985V1.75c-1.25 0-2.312.438-3.187 1.312C2.112 3.937 1.675 5 1.675 6.25zm1.893 10.106c-1.224-1.225-1.837-2.697-1.837-4.416 0-1.718.613-3.19 1.837-4.415L7.543 3.55l1.594 1.594 3.244-3.263 1.594 1.594 1.068-1.088 2.42 2.438-.807.806 1.65 1.65-1.556 1.557 1.593 1.593-5.943 5.925c-1.238 1.238-2.713 1.853-4.425 1.847-1.713-.006-3.182-.622-4.407-1.847zm.881-.88A4.63 4.63 0 006.1 16.58a5.145 5.145 0 001.876.357c.637 0 1.262-.12 1.875-.357a4.63 4.63 0 001.65-1.106l5.043-5.025-.693-.712-3.02 3.018-.9-.9 4.613-4.593-.712-.694-4.031 4.03-.9-.88 4.856-4.857-.713-.693-4.856 4.856-.881-.881 3.788-3.788-.694-.712-5.232 5.231c.263.662.338 1.337.225 2.025a3.476 3.476 0 01-.918 1.837l-.882-.88a2.28 2.28 0 00.62-1.595 2.28 2.28 0 00-.62-1.593l2.644-2.644-.694-.694L4.45 8.425a4.854 4.854 0 00-1.096 1.65 5.025 5.025 0 00-.366 1.875c0 .637.119 1.262.356 1.875a4.63 4.63 0 001.106 1.65z"
      fill="currentColor"
    />
  </svg>
);
