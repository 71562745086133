import React from 'react';

import { theme } from './theme';

interface PropertyListProps {
  maxColumnCount?: number;
}

export const PropertyList: React.FC<
  React.PropsWithChildren<PropertyListProps>
> = ({ maxColumnCount, ...rest }) => (
  <div
    css={{
      columnCount: 1,
      [theme.media.small]: {
        columnCount: maxColumnCount || 2,
      },
    }}
    {...rest}
  />
);

interface PropertyListItemProps {
  label: string;
  value: React.ReactNode;
  flexBasis?: string;
  vertical?: boolean;
}

export const PropertyListItem: React.FC<
  React.PropsWithChildren<PropertyListItemProps>
> = ({ label, value, flexBasis, vertical, ...props }) => (
  <div
    css={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: vertical ? `${theme.space.xs2} 0` : `${theme.space.xs2} 0`,

      [theme.media.small]: {
        flexDirection: vertical ? 'column' : 'row',
      },
    }}
    {...props}
  >
    <div
      css={{
        color: theme.color.gray,
        fontFamily: theme.fontFamily.postGrotesk,
        fontWeight: theme.fontWeight.light,
        flexBasis: flexBasis || '50%',
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      {label}
    </div>
    <div
      css={{
        overflowWrap: 'anywhere',
        color: theme.color.black,
        fontFamily: theme.fontFamily.postGrotesk,
      }}
    >
      {value}
    </div>
  </div>
);
