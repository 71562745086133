import { css } from '@emotion/react';
import React from 'react';

import { LogoLoader } from './LogoLoader';

export const logoLoaderWrapperStyle = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const logoLoaderWrapperStyleFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface LoaderProps {
  flex?: boolean;
}

export const Loader = ({ flex = false }: LoaderProps) => {
  return (
    <div
      css={flex ? logoLoaderWrapperStyleFlex : logoLoaderWrapperStyle}
      data-testid="loader"
    >
      <LogoLoader />
    </div>
  );
};
