import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/ui/theme';

type LicenseExpiredAlertProps = {
  licenseNumber?: string;
};

const LicenseExpiredAlert = ({ licenseNumber }: LicenseExpiredAlertProps) => {
  return (
    <div css={{ marginTop: theme.space.base }}>
      <GuidanceCard layout="vertical" variant="error">
        <BodyText>
          <strong>Your license # {licenseNumber} is expired:</strong> A renewed
          license is required to confirm sessions.
        </BodyText>
        <BodyText>
          <strong>
            <Link
              href="https://findheadway.zendesk.com/hc/en-us/articles/15695975816340"
              target="_blank"
            >
              View renewal instructions
            </Link>
          </strong>
        </BodyText>
      </GuidanceCard>
    </div>
  );
};

export { LicenseExpiredAlert };
