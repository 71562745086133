import { css } from '@emotion/react';
import { Alert, AlertTitle } from '@mui/material';
import { useProvider } from 'hooks';

import { CustomerType } from '@headway/api/models/CustomerType';
import { PaymentsBonusesSecondaryIssueType } from '@headway/api/models/PaymentsBonusesSecondaryIssueType';
import { ProviderIssueType } from '@headway/api/models/ProviderIssueType';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { BodyText } from '@headway/helix/BodyText';
import { IconError } from '@headway/helix/icons/Error';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListHeader } from '@headway/helix/ListHeader';
import { PageSection } from '@headway/helix/Page';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { trackEvent } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { formatPrice } from '@headway/shared/utils/payments';

import { useProviderPayments } from 'hooks/useProviderPayments';

import {
  FormattedAppointmentInfo,
  getAdditionalTransactionAppointmentInfoFromAppointment,
} from './FormattedAppointmentInfo';
import { PaymentFeesTooltip } from './PaymentInfoTooltip';

const DeniedPaymentsTab = () => {
  const provider = useProvider();

  const isPausedPaymentsFlagEnabled = useFlag('pausedPayments', false);

  const {
    isLoading,
    data: deniedPayments,
    error,
  } = useProviderPayments(
    {
      providerId: provider.id,
      providerPaymentStatusEnums: [ProviderPaymentStatus.DENIED],
      isPausedPaymentsFlagEnabled,
    },
    {
      refetchOnWindowFocus: false, //Aggregating/paging can be expensive client side, don't refetch automatically
    }
  );

  const hasDeniedPayments =
    isPausedPaymentsFlagEnabled &&
    deniedPayments &&
    deniedPayments.data.length > 0;

  return (
    <div>
      <div css={deniedCss.warning.container}>
        <div css={deniedCss.warning.title}>
          <IconError fontSize={20} color={theme.color.primary.red} />
          <ListHeader>Notes did not meet insurance standards</ListHeader>
        </div>
        <BodyText>
          We could not verify that your notes met insurers' standards. If you
          have any questions, please contact Headway support.
        </BodyText>
        <div css={deniedCss.button}>
          <LinkButton
            variant="secondary"
            href={`${process.env.REACT_APP_MAIN_URL}/contact?submitterType=${CustomerType.PROVIDER_ON_BEHALF_OF_SELF}&primaryIssueType=${ProviderIssueType.PAYMENTS_OR_BONUSES}&secondaryIssueType=${PaymentsBonusesSecondaryIssueType.SESSION_PAYOUTS}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              trackEvent({
                name: 'Contact Headway Support Button Clicked',
                properties: {
                  providerId: provider.id,
                  auditBy: 'Headway',
                },
              });
            }}
          >
            Contact Headway Support
          </LinkButton>
        </div>
      </div>
      <PageSection css={deniedCss.table}>
        {hasDeniedPayments && (
          <Table aria-labelledby="denied-payments-title">
            <TableHeader>
              <Column>Description</Column>
              <Column>Amount</Column>
            </TableHeader>
            <TableBody>
              {deniedPayments.data.map((e) => {
                const additionalTransactionInfo =
                  getAdditionalTransactionAppointmentInfoFromAppointment(e);
                return (
                  <Row key={e.id}>
                    <Cell>
                      {e &&
                      e.providerAppointment &&
                      e.providerAppointment.status &&
                      e.providerAppointment.billingType &&
                      e.providerAppointment.patient &&
                      e.startDate ? (
                        <FormattedAppointmentInfo
                          status={e.providerAppointment.status}
                          billingType={e.providerAppointment.billingType}
                          appointmentStartAt={e.startDate}
                          patientName={formatPatientName(
                            e.providerAppointment.patient
                          )}
                          patientUserId={e.providerAppointment.patient.id}
                          frontEndCarrier={
                            additionalTransactionInfo.frontEndCarrier
                          }
                          appointmentStateLocation={
                            additionalTransactionInfo.appointmentStateLocation
                          }
                          isTelehealth={
                            !!additionalTransactionInfo.isTelehealth
                          }
                          isProviderIncentiveBonus={false}
                        />
                      ) : (
                        'Unable to format appointment details'
                      )}
                    </Cell>
                    <Cell>
                      {e && e.providerAppointment && (
                        <div css={{ marginLeft: 'auto' }}>
                          {formatPrice(
                            e.providerAppointment.providerPaymentAmount,
                            false
                          )}
                          <PaymentFeesTooltip
                            fees={
                              e.providerAppointment.providerPaymentFeesAmount
                            }
                            billingType={e.providerAppointment.billingType}
                          />
                        </div>
                      )}
                    </Cell>
                  </Row>
                );
              })}
            </TableBody>
          </Table>
        )}
      </PageSection>
      {!isLoading && !error && !hasDeniedPayments ? (
        <div css={deniedCss.empty}>No denied payments to display.</div>
      ) : null}
      {error ? (
        <div css={deniedCss.error}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            We can't load your denied payments. Refresh the page to see if this
            is an ongoing issue.
          </Alert>
        </div>
      ) : null}
    </div>
  );
};

const deniedCss = {
  warning: {
    container: css({
      ...theme.stack.vertical,
      padding: theme.spacing.x6,
      marginBottom: theme.spacing.x5,
      gap: theme.spacing.x4,
      border: `1px solid ${theme.color.system.borderGray}`,
      borderRadius: theme.spacing.x1,
    }),
    title: css({
      ...theme.stack.horizontal,
      gap: theme.spacing.x2,
      alignItems: 'center',
    }),
  },
  table: css({
    margin: 0,
    padding: 0,
  }),
  button: css({
    display: 'flex',
  }),
  empty: css({
    margin: `${theme.spacing.x10} auto`,
    textAlign: 'center',
    maxWidth: '30rem',
  }),
  error: css({
    margin: `${theme.spacing.x12} auto`,
    maxWidth: '50rem',
  }),
};

export default DeniedPaymentsTab;
