import { EligibilityLookupApi } from '@headway/api/resources/EligibilityLookupApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseEligibilityLookupQueryKeyArgs {
  eligibilityLookupId: number | undefined;
}

export const getUseEligibilityLookupQueryKey = ({
  eligibilityLookupId,
}: UseEligibilityLookupQueryKeyArgs) => [
  'eligibility-lookup',
  eligibilityLookupId,
];

const {
  useSingleQuery: useEligibilityLookup,
  useListQuery: useEligibilityLookupList,
} = createBasicApiHooks(
  getUseEligibilityLookupQueryKey,
  ({ eligibilityLookupId }) =>
    EligibilityLookupApi.getEligibilityLookup(eligibilityLookupId!),
  ({ eligibilityLookupId }) => !!eligibilityLookupId,
  () => 'Failed to fetch eligibility lookup'
);

export { useEligibilityLookup, useEligibilityLookupList };
