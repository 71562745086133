import { InfoTwoTone } from '@mui/icons-material';
import { Chip, FormHelperText } from '@mui/material';
import { uniqWith } from 'lodash';
import React, { FunctionComponent } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import {
  CIGNA_EAP_CODE,
  CPTCodeInfo,
  NONPRESCRIBER_COMMON_CODES,
  NONPRESCRIBER_LESS_COMMON_CODES,
  PRESCRIBER_COMMON_CODES,
  PRESCRIBER_LESS_COMMON_CODES,
} from '@headway/shared/constants/cptCodes';

import { formatPatientName } from '../shared/utils/patient';
import { FieldAutocompleteFuzzy, FieldErrorText } from './form';
import { theme } from './theme';
import { Tooltip } from './Tooltip';

export interface CptCodeOption extends CPTCodeInfo {
  description: string;
}

export const prescriberCptOptions: CptCodeOption[] = [
  ...PRESCRIBER_COMMON_CODES.map((code) => ({
    ...code,
    description: 'Common codes',
  })),
  ...PRESCRIBER_LESS_COMMON_CODES.map((code) => ({
    ...code,
    description: 'Less common codes',
  })),
];

export const nonPrescriberCptOptions: CptCodeOption[] = [
  ...NONPRESCRIBER_COMMON_CODES.map((code) => ({
    ...code,
    description: 'Common codes',
  })),
  ...NONPRESCRIBER_LESS_COMMON_CODES.map((code) => ({
    ...code,
    description: 'Less common codes',
  })),
];

export const allCptOptions = uniqWith(
  prescriberCptOptions.concat(nonPrescriberCptOptions),
  (a, b) => a.value == b.value
);

export const getCptOptionsForProvider = (provider: ProviderRead) =>
  provider.isPrescriber ? prescriberCptOptions : nonPrescriberCptOptions;

interface CPTInputProps {
  provider: ProviderRead;
  patient: UserRead;
  disabled?: boolean;
  patientHasCignaEapAuthorization: boolean;
  displayOptionalHelpText?: boolean;
}

const CPTCodeInput: FunctionComponent<
  React.PropsWithChildren<CPTInputProps>
> = (props) => {
  const {
    disabled,
    provider,
    patient,
    patientHasCignaEapAuthorization,
    displayOptionalHelpText = false,
    ...rest
  } = props;

  return (
    <>
      <FieldAutocompleteFuzzy
        fuzzySearchOptions={{
          keys: ['value', 'display'],
          useExtendedSearch: true,
          ignoreLocation: true,
        }}
        label={
          <div css={{ display: 'flex' }}>
            <span css={{ paddingRight: theme.space.xs }}>CPT codes</span>
            {patientHasCignaEapAuthorization && (
              <Tooltip
                title={`${formatPatientName(patient, {
                  firstNameOnly: true,
                })} is using Employee Assistance Program benefits through their Cigna plan. Cigna requires these sessions to be billed with the ${CIGNA_EAP_CODE} CPT code.`}
              >
                <InfoTwoTone
                  fontSize="inherit"
                  css={{ color: theme.color.info }}
                />
              </Tooltip>
            )}
          </div>
        }
        isOptionEqualToValue={(option, value) => {
          if (value.allowMultiple) {
            return false;
          } else {
            return value.value === option.value;
          }
        }}
        multiple={true}
        options={getCptOptionsForProvider(provider)}
        groupBy={(option) => option.description}
        getOptionLabel={(option) => `${option.value} — ${option.display}`}
        disabled={disabled || patientHasCignaEapAuthorization}
        renderTags={(value, getTagProps) =>
          value.map((option, idx) => (
            <Chip
              {...getTagProps({ index: idx })}
              label={`${option.value} — ${option.display}`}
              onDelete={(...args) => {
                // @ts-ignore
                !disabled && getTagProps({ index: idx }).onDelete(...args);
              }}
            />
          ))
        }
        {...rest}
      />
      <FieldErrorText />
      {!!provider.isPrescriber && (
        <FormHelperText>Select multiple if applicable.</FormHelperText>
      )}
      {displayOptionalHelpText && (
        <FormHelperText>
          *Optional for private pay clients. Please note, clients cannot file
          for OON reimbursement without CPT/diagnosis codes.
        </FormHelperText>
      )}
    </>
  );
};

export default CPTCodeInput;
