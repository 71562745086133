// gemini.link(mamba/app/shared/services/provider_incentive_program_service.py)
export const INCENTIVE_BONUS_AMOUNT_DOLLARS = 5;
// gemini.endlink

/**
 * Values for the `copyVariant` property of the `Rate Boost Banner Viewed` analytics event
 */
export enum RateBoostBannerCopyVariant {
  // Small guidance cards, usually with a Learn More link
  SMALL_GUIDANCE_CARD = 'SMALL_GUIDANCE_CARD',
  // Larger guidance cards which give more detailed information
  LARGE_GUIDANCE_CARD = 'LARGE_GUIDANCE_CARD',
  // Full page takeovers
  FULL_PAGE = 'FULL_PAGE',
}
