import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';

const InsuranceNeedsAuthzGuidanceCard = ({
  carrier,
  firstName,
  showInsuranceAuthorizationInstructionsModal,
}: {
  carrier: FrontEndCarrierRead | FrontEndCarrierNested;
  firstName: string;
  showInsuranceAuthorizationInstructionsModal: (
    carrier: FrontEndCarrierRead | FrontEndCarrierNested
  ) => void;
}) => {
  return (
    <GuidanceCard variant="error">
      <BodyText>
        {carrier.name} members need to receive authorization before booking
        sessions, so you won’t be able to add their insurance directly. If you’d
        like, you can send {firstName + ' '}
        <Link
          onClick={() => showInsuranceAuthorizationInstructionsModal(carrier)}
        >
          instructions for requesting a referral.
        </Link>
      </BodyText>
    </GuidanceCard>
  );
};

export { InsuranceNeedsAuthzGuidanceCard };
