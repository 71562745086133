import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { StyleTag } from '@headway/api/models/StyleTag';
import { ProviderStyleTagApi } from '@headway/api/resources/ProviderStyleTagApi';
import { useQuery, useQueryClient } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { WizardStep } from 'components/Wizard/WizardStep';
import { useProvider } from 'hooks/useProvider';
import { PROVIDER_STYLE_TAGS_CACHE_KEY } from 'utils/cacheKeys';

import { PrimaryContentHeader } from './ProfileWizard';
import { StyleTagsInput } from './StyleTagsInput';

type StyleTagFormValues = {
  styleTags: StyleTag[];
};

export const StyleTagStep = () => {
  const queryClient = useQueryClient();
  const provider = useProvider();
  const styleTagsQuery = useQuery(
    [PROVIDER_STYLE_TAGS_CACHE_KEY, provider.id],
    () => ProviderStyleTagApi.getProviderStyleTags({ provider_id: provider.id })
  );
  const providerStyleTags = styleTagsQuery.data || [];

  const initialValues: StyleTagFormValues = {
    styleTags: providerStyleTags.map(({ styleTag }) => styleTag as StyleTag),
  };

  const validationSchema = Yup.object().shape({
    styleTags: Yup.array(Yup.string())
      .test(
        'styleTagsLength',
        'Please select 3 attributes',
        (val) => val?.length === 3
      )
      .required('Attributes are required'),
  });

  let initialValuesAreValid = false;
  try {
    validationSchema.validateSync(initialValues);
    initialValuesAreValid = true;
  } catch (e) {
    // Let value remain false
  }

  const onSubmit = async (values: StyleTagFormValues) => {
    // Calculate the diff between the new set of tags and the old set of tags.
    const valuesSet = new Set(values.styleTags);
    const tagIdsToDelete = [];
    for (const { styleTag, id } of providerStyleTags) {
      if (valuesSet.has(styleTag as StyleTag)) {
        // We can ignore this tag because it is not changing.
        valuesSet.delete(styleTag as StyleTag);
      } else {
        tagIdsToDelete.push(id);
      }
    }
    const tagsToAdd = [...valuesSet];

    try {
      if (tagIdsToDelete.length) {
        await ProviderStyleTagApi.bulkDeleteProviderStyleTags({
          provider_id: provider.id,
          provider_style_tag_ids: tagIdsToDelete,
        });
      }
      if (tagsToAdd.length) {
        await ProviderStyleTagApi.bulkCreateProviderStyleTags({
          providerId: provider.id,
          styleTags: tagsToAdd,
        });
      }
      if (tagIdsToDelete.length || tagsToAdd.length) {
        queryClient.invalidateQueries([
          PROVIDER_STYLE_TAGS_CACHE_KEY,
          provider.id,
        ]);
      }
    } catch (err) {
      notifyError('An error occurred while saving your progress.');
      logException(err);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isValid, values, dirty }) => {
        return (
          <WizardStep
            onSaveAndContinue={async () => await submitForm()}
            onSaveAndExit={async () => await onSubmit(values)}
            primaryContent={
              <>
                <div css={{ width: '65%' }}>
                  <PrimaryContentHeader
                    title="How would you describe your personality style or traits with clients?"
                    description="Select up to three attributes that best describe your approach with clients in sessions"
                  />
                  <StyleTagsInput
                    data-testid="styleTagInput"
                    fieldName="styleTags"
                    noLabel={true}
                  />
                </div>
              </>
            }
            areRequirementsMet={dirty ? isValid : initialValuesAreValid}
          />
        );
      }}
    </Formik>
  );
};
