import { useFormikContext } from 'formik';
import { groupBy, keyBy, merge } from 'lodash';
import React from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ComboBox } from '@headway/helix/ComboBox';
import { validity } from '@headway/helix/FormControl';
import { IconChevronDown } from '@headway/helix/icons/ChevronDown';
import { IconChevronUp } from '@headway/helix/icons/ChevronUp';
import { Item, Section } from '@headway/helix/Select';
import { theme } from '@headway/helix/theme';
import {
  CIGNA_EAP_CODE,
  CPTCodeInfo,
  NONPRESCRIBER_COMMON_CODES,
  NONPRESCRIBER_LESS_COMMON_CODES,
  PRESCRIBER_COMMON_CODES,
  PRESCRIBER_LESS_COMMON_CODES,
} from '@headway/shared/constants/cptCodes';
import { formatPatientName } from '@headway/shared/utils/patient';

export interface CptCodeOption extends CPTCodeInfo {
  description: string;
}

export const prescriberCptOptions = {
  'Common codes': PRESCRIBER_COMMON_CODES,
  'Less common codes': PRESCRIBER_LESS_COMMON_CODES,
};

export const nonPrescriberCptOptions = {
  'Common codes': NONPRESCRIBER_COMMON_CODES,
  'Less common codes': NONPRESCRIBER_LESS_COMMON_CODES,
};

export const prescriberCptCodes = [
  ...PRESCRIBER_COMMON_CODES,
  ...PRESCRIBER_LESS_COMMON_CODES,
];
export const nonPrescriberCptCodes = [
  ...NONPRESCRIBER_COMMON_CODES,
  ...NONPRESCRIBER_LESS_COMMON_CODES,
];

export const getCptOptionsForProvider = (provider?: ProviderRead | null) =>
  !provider
    ? merge(prescriberCptOptions, nonPrescriberCptOptions)
    : provider.isPrescriber
    ? prescriberCptOptions
    : nonPrescriberCptOptions;

type ComboBoxProps = React.ComponentProps<typeof ComboBox>;

interface CPTCodeComboBoxProps
  extends Omit<ComboBoxProps, 'items' | 'children'> {
  // optional provider argument to filter code types
  provider?: ProviderRead | null;
  patient: UserRead;
  patientHasCignaEapAuthorization: boolean;
  disabled?: boolean;
  helpText?: React.ReactNode;
  instructionalText?: React.ReactNode;
  optionalityText?: React.ReactNode;
  // field name for Formik
  name: string;
  searchable?: boolean;
}

export const CPT_COMBOBOX_DEFAULT_NONPRESCRIBER_HELP_TEXT =
  'Include CPT codes that need additional verification.';
export const CPT_COMBOBOX_DEFAULT_PRESCRIBER_HELP_TEXT =
  'Include CPT codes that need additional verification. Select multiple if applicable.';
export const CPT_COMBOBOX_DEFAULT_EAP_INSTRUCTIONAL_TEXT = (
  patient?: UserRead
) =>
  `${
    patient?.firstName
      ? formatPatientName(patient, {
          firstNameOnly: true,
        })
      : 'This patient'
  } is using Employee Assistance Program benefits through their Cigna plan. Cigna requires these sessions to be billed with the ${CIGNA_EAP_CODE} CPT code.`;
export const CPT_COMBOBOX_DEFAULT_OPTIONALITY_TEXT =
  'Optional for private pay clients. Please note, clients cannot file for OON reimbursement without CPT/diagnosis codes.';

const CPTCodeComboBox = ({
  disabled,
  helpText,
  instructionalText,
  name,
  optionalityText,
  patient,
  patientHasCignaEapAuthorization,
  provider,
  ...rest
}: CPTCodeComboBoxProps) => {
  const formik = useFormikContext<{
    [name: string]: CPTCodeInfo[];
  }>();

  const values = formik.values[name] ?? [];

  const { options, optionsByKey } = React.useMemo(() => {
    const countByValue = groupBy(values, 'value');
    let sections = getCptOptionsForProvider(provider);

    let items: CPTCodeInfo[] = [];

    for (const codes of Object.values(sections)) {
      items = [...items, ...codes];
    }

    let options: [
      section: string,
      codes: (CPTCodeInfo & { count: number })[],
    ][] = Object.entries(sections).map(([desc, codes]) => {
      const withCount = codes.map((code) => {
        return {
          ...code,
          count: countByValue[code.value]?.length ?? 0,
        };
      });

      return [desc, withCount];
    });

    const optionsByKey = keyBy(
      Object.values(options).flatMap(([, codes]) => codes),
      (code) => `${code.value} — ${code.display}`
    );

    return {
      optionsByKey,
      options,
    };
  }, [provider, values]);

  return (
    <div
      css={{
        '& .hlx-chip-root .code-combobox-desc': {
          display: 'none',
        },
      }}
    >
      <ComboBox
        disabled={disabled || patientHasCignaEapAuthorization}
        helpText={helpText}
        instructionalText={
          patientHasCignaEapAuthorization && !instructionalText
            ? CPT_COMBOBOX_DEFAULT_EAP_INSTRUCTIONAL_TEXT(patient)
            : instructionalText
        }
        items={options}
        label="CPT codes"
        menuWidth="stretch"
        name={name}
        onSelectionChange={(keys) => {
          const codes = Array.from(keys).flatMap((key) => {
            const { count, ...code } = optionsByKey[key];

            if (count === 0) {
              return code;
            }

            return new Array(count).fill(code);
          });

          formik.setFieldValue(name, codes);
        }}
        optionalityText={optionalityText}
        selectedKeys={
          new Set(values.map((code) => `${code.value} — ${code.display}`) ?? [])
        }
        validation={validity(name, formik)}
        {...rest}
      >
        {(section) => {
          return (
            <Section key={section[0]} title={section[0]} items={section[1]}>
              {(code) => {
                if (code.allowMultiple) {
                  return (
                    <Item
                      key={`${code.value} — ${code.display}`}
                      textValue={`${code.value} — ${code.display}`}
                    >
                      <IncrementableItem
                        code={code}
                        onIncrement={() => {
                          const { count, ...selected } =
                            optionsByKey[`${code.value} — ${code.display}`];
                          formik.setFieldValue(name, [...values, selected]);
                        }}
                        onDecrement={() => {
                          let updated = [];
                          let decremented = false;

                          // iterate over the values in reverse order
                          // so that we prioritize pruning from the end of the array.
                          // This way we don't change the order of the chips in the ComboBox.

                          for (const value of [...values].reverse()) {
                            // if the value matches the one we are decrementing
                            // and we've not yet decremented, skip it
                            if (value.value === code.value && !decremented) {
                              decremented = true;
                              continue;
                            }

                            updated.push(value);
                          }

                          formik.setFieldValue(name, updated.reverse());
                        }}
                      >
                        <span className="code-combobox-value">
                          {code.value}
                        </span>
                        <span className="code-combobox-desc">
                          {' '}
                          — {code.display}
                        </span>
                      </IncrementableItem>
                    </Item>
                  );
                }

                return (
                  <Item
                    key={`${code.value} — ${code.display}`}
                    textValue={`${code.value} — ${code.display}`}
                  >
                    <span className="code-combobox-value">{code.value}</span>
                    <span className="code-combobox-desc">
                      {' '}
                      — {code.display}
                    </span>
                  </Item>
                );
              }}
            </Section>
          );
        }}
      </ComboBox>
    </div>
  );
};

interface IncrementableItemProps {
  onIncrement: () => void;
  onDecrement: () => void;
  code: CPTCodeInfo & { count: number };
  children: React.ReactNode;
}

export function IncrementableItem({
  code,
  onDecrement,
  onIncrement,
  children,
}: IncrementableItemProps) {
  return (
    <div
      css={{
        '.hlx-listbox & > :not(:is(.code-combobox-value, .code-combobox-desc))':
          {
            display: 'none',
          },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {code.allowMultiple && code.count > 0 && (
        <div css={{ color: theme.color.system.white }}>
          <span
            css={{
              marginRight: theme.spacing.x1,
              background: theme.color.system.textBlack,
              borderRadius: 12,
              padding: '0 8px',
              ...theme.typography.caption.medium,
              fontSize: 12,
              lineHeight: '16px',
            }}
          >
            {code.count}
          </span>
        </div>
      )}
      {children}
      {code.allowMultiple && (
        <span
          css={{
            marginLeft: 4,
            display: 'flex',
            alignItems: 'center',
            '& button': {
              fontSize: 0,
              padding: 0,
              border: 'none',
              background: 'none',
              '&:hover': {
                background: 'var(--hlx-color-system-disabledGray)',
              },
            },
          }}
        >
          <button
            className="hlx-chip-action"
            type="button"
            onClick={onDecrement}
          >
            <IconChevronDown />
          </button>

          <button
            className="hlx-chip-action"
            type="button"
            onClick={onIncrement}
          >
            <IconChevronUp />
          </button>
        </span>
      )}
    </div>
  );
}

export { CPTCodeComboBox };
