import { Formik } from 'formik';
import { useProvider } from 'hooks';
import { useEffect, useState } from 'react';

import { MalpracticeMinimums } from '@headway/api/models/MalpracticeMinimums';
import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderTaskStatus } from '@headway/api/models/ProviderTaskStatus';
import { ProviderTaskType } from '@headway/api/models/ProviderTaskType';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { ENABLE_COI_DROPZONE_INSURANCE_STATUS_PAGE } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { getLatestQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { UploadedFile } from '@headway/shared/utils/upload';
import { Dropzone } from '@headway/ui/Dropzone';

import { useUiStore } from 'stores/UiStore';
import { onDropFiles } from 'utils/providerQuestionnaire';
import { createCoiPqv } from 'views/IntakeQuestionnaireV2/steps/Malpractice/Malpractice';

import { TaskStatusMap } from '../utils';
import { CoiPathway } from './CoiPathway';
import {
  CoiPathwayType,
  coiValidationSchema,
  markCoiTaskWithStatus,
  updateMalpracticeField,
} from './CoiUtils';

function resetValues(values: any) {
  values.nameInsured = '';
  values.malpracticeProvider = '';
  values.effectiveDate = '';
  values.expirationDate = '';
  values.occurrenceLimit = 0;
  values.aggregateLimit = 0;
}

export const CoiProviderTaskModal = ({
  activeCoiModal,
  setActiveCoiModal,
  fileDropped,
  setFileDropped,
  tasks,
}: {
  activeCoiModal: boolean;
  setActiveCoiModal: (arg: boolean) => void;
  fileDropped: boolean;
  setFileDropped: (arg: boolean) => void;

  tasks: { taskStatusMap: TaskStatusMap } | undefined;
}) => {
  const enableCoiDropzone = useFlag(ENABLE_COI_DROPZONE_INSURANCE_STATUS_PAGE);
  const uiStore = useUiStore();
  const actionNeededTasks = tasks?.taskStatusMap?.ACTION_NEEDED ?? [];
  const actionNeededHighPriorityTasks =
    tasks?.taskStatusMap?.ACTION_NEEDED_HIGH_PRIORITY ?? [];
  const totalTaskList = actionNeededHighPriorityTasks.concat(actionNeededTasks);
  const listCoiProblems = totalTaskList
    .filter(
      (task) => task.taskType === ProviderTaskType.CERTIFICATE_OF_INSURANCE
    )
    .map((task) => (
      <li>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(task.statusDescription || ''),
          }}
        ></div>
      </li>
    ));

  const [userDroppedFile, setUserDroppedFile] = useState<File>();
  const [verificationId, setVerificationId] = useState<number>(0);
  const [questionnaireId, setQuestionnaireId] = useState<number>();
  const [uploadedFileObject, setUploadedFileObject] =
    useState<UploadedFile[]>();
  const [coiPathway, setCoiPathway] = useState<CoiPathwayType>(
    CoiPathwayType.INITIAL
  );
  const [malpracticeMinimums, setMalpracticeMinimums] =
    useState<MalpracticeMinimums>();
  const provider = useProvider();
  const initialCoiValues = {
    nameInsured: '',
    malpracticeProvider: '',
    effectiveDate: '',
    expirationDate: '',
    occurrenceLimit: 0,
    aggregateLimit: 0,
  };

  useEffect(() => {
    if (malpracticeMinimums) {
      return;
    }

    const getMalpracticeMinimums = async () => {
      const response =
        await ProviderQuestionnaireApi.getProviderMalpracticeMinimums({
          provider_id: provider.id,
        });
      setMalpracticeMinimums(response);
    };

    getMalpracticeMinimums();
  }, [malpracticeMinimums]);

  const coiDropzoneLogic = async (files: File[]) => {
    let processedFiles = await onDropFiles(provider.id, files);
    const questionnaires = await getLatestQuestionnaire(provider.id);
    setQuestionnaireId(questionnaires.selectedQuestionnaire?.id);

    if (!processedFiles || !processedFiles.length) {
      setUserDroppedFile(undefined);
      return processedFiles;
    }
    const coiS3Key = processedFiles[0].s3ObjectKey;

    if (!coiS3Key) {
      setFileDropped(false);
      setUserDroppedFile(undefined);
      return processedFiles;
    }

    if (!questionnaires.selectedQuestionnaire?.id) {
      return processedFiles;
    }

    const createdPqv = await createCoiPqv(
      coiS3Key,
      questionnaires.selectedQuestionnaire?.id
    );
    setVerificationId(createdPqv.id);

    const processedFile = [
      {
        name: processedFiles[0].name,
        link: processedFiles[0].link,
        s3ObjectKey: coiS3Key,
      },
    ] as UploadedFile[];

    setFileDropped(true);
    setUserDroppedFile(files[0]);
    setUploadedFileObject(processedFile);

    return processedFiles;
  };

  return (
    <div>
      {malpracticeMinimums && (
        <Formik
          initialValues={initialCoiValues}
          validationSchema={coiValidationSchema(malpracticeMinimums)}
          onSubmit={async (values, { resetForm }) => {
            if (uploadedFileObject && questionnaireId) {
              await updateMalpracticeField(
                questionnaireId,
                uploadedFileObject[0],
                values
              );
            }
            if (coiPathway === CoiPathwayType.SUCCESS) {
              markCoiTaskWithStatus(provider.id, ProviderTaskStatus.COMPLETE);
            }
            if (coiPathway === CoiPathwayType.OCR_FAILED) {
              markCoiTaskWithStatus(
                provider.id,
                ProviderTaskStatus.IN_PROGRESS
              );
            }

            setActiveCoiModal(false);
            resetForm();

            if (coiPathway === CoiPathwayType.SUCCESS) {
              uiStore.showSuccessSnackbar(
                `Your Certificate of Insurance has been successfully submitted!`
              );
            }
            if (coiPathway === CoiPathwayType.OCR_FAILED) {
              uiStore.showSuccessSnackbar(
                `Your document has been submitted for manual review. Our
                  credentialing team will review your document to verify your malpractice
                  coverage.`
              );
            }

            setCoiPathway(CoiPathwayType.INITIAL);
          }}
        >
          {({ values, isSubmitting }) => {
            return (
              <div>
                <Modal
                  isOpen={activeCoiModal}
                  onDismiss={() => {
                    setActiveCoiModal(false);
                    setFileDropped(false);
                    setUserDroppedFile(undefined);
                    setCoiPathway(CoiPathwayType.INITIAL);
                    resetValues(values);
                  }}
                  title={`Action required: Malpractice insurance`}
                >
                  <div className="overflow-y-auto">
                    <Form>
                      <ModalContent>
                        <BodyText>
                          <div className="grid gap-y-4">
                            <div>
                              {
                                'There was an issue with the COI (certificate of insurance) you submitted. You may need to contact your malpractice provider to either get more coverage or request an updated COI:'
                              }
                              <ul>{listCoiProblems}</ul>
                            </div>
                          </div>
                        </BodyText>
                        <div>
                          <LinkButton
                            variant="link"
                            href={
                              'https://headway-redacted-malpractice.s3.amazonaws.com/allied-world-insurance.jpg'
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            See COI Example
                          </LinkButton>
                          <div>
                            {!fileDropped && enableCoiDropzone && (
                              <Dropzone
                                height={75}
                                accept="application/pdf,image/*"
                                onDrop={async (files: any) => {
                                  coiDropzoneLogic(files);
                                }}
                              />
                            )}
                          </div>
                          &nbsp;
                          <div className="grid gap-y-4">
                            {enableCoiDropzone &&
                              fileDropped &&
                              questionnaireId &&
                              !!userDroppedFile &&
                              !!uploadedFileObject && (
                                <CoiPathway
                                  file={userDroppedFile}
                                  uploadedFileObject={uploadedFileObject}
                                  verificationId={verificationId}
                                  secondFileDropped={false}
                                  coiPathway={coiPathway}
                                  setCoiPathway={setCoiPathway}
                                />
                              )}
                          </div>
                        </div>
                      </ModalContent>
                      {enableCoiDropzone ? (
                        <ModalFooter>
                          <Button
                            type="submit"
                            variant="primary"
                            size="large"
                            disabled={
                              isSubmitting ||
                              coiPathway === CoiPathwayType.LOADING ||
                              coiPathway === CoiPathwayType.INITIAL ||
                              coiPathway === CoiPathwayType.INELIGIBLE ||
                              coiPathway === CoiPathwayType.BAD_DOCUMENT ||
                              coiPathway === CoiPathwayType.EXPIRING_SOON ||
                              coiPathway === CoiPathwayType.SUBMITTED
                            }
                          >
                            Submit
                          </Button>
                          <LinkButton
                            variant="secondary"
                            href={'https://headway.co/contact'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Contact Support
                          </LinkButton>
                        </ModalFooter>
                      ) : (
                        <ModalFooter>
                          <Button
                            variant="primary"
                            size="large"
                            onPress={() => {
                              setActiveCoiModal(false);
                            }}
                          >
                            Got it
                          </Button>
                        </ModalFooter>
                      )}
                    </Form>
                  </div>
                </Modal>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export const GenericProviderTaskModal = ({
  activeProviderTask,
  setActiveProviderTask,
}: {
  activeProviderTask: ProviderTaskRead | undefined;
  setActiveProviderTask: (arg: ProviderTaskRead | undefined) => void;
}) => {
  return (
    <Modal
      isOpen={!!activeProviderTask}
      onDismiss={() => {
        setActiveProviderTask(undefined);
      }}
      title={`Action required: ${activeProviderTask?.title}`}
    >
      <ModalContent>
        <BodyText>
          <div className="grid gap-y-4">
            <div>{activeProviderTask?.description}</div>
            <div>{<b>Here's what to do: </b>}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(activeProviderTask?.statusDescription || ''),
              }}
            ></div>
          </div>
        </BodyText>
      </ModalContent>
      <ModalFooter>
        <LinkButton
          variant="primary"
          href={
            'https://headway.co/contact?primaryIssueType=pit_credentialing___payer_networks&secondaryIssueType=credentialing_documentation&tags=cred_docs_url'
          }
          target="_blank"
          rel="noreferrer"
        >
          Submit information to Headway
        </LinkButton>
      </ModalFooter>
    </Modal>
  );
};
