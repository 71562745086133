/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface InsuranceReadinessIssueHasNoTelehealthBenefits {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: InsuranceReadinessIssueHasNoTelehealthBenefitsType;

}


    export enum InsuranceReadinessIssueHasNoTelehealthBenefitsType {
            HAS_NO_TELEHEALTH_BENEFITS = 'HAS_NO_TELEHEALTH_BENEFITS'
    }

