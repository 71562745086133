/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { PatientFormCreate } from '../models/PatientFormCreate';
import { PatientFormNestedRead } from '../models/PatientFormNestedRead';
import { PatientFormRead } from '../models/PatientFormRead';
import { PatientFormUpdate } from '../models/PatientFormUpdate';
import { PatientFormsCreateRequestBody } from '../models/PatientFormsCreateRequestBody';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class PatientFormApiResource {

    /**
     * Bulk Reset Patient Forms
     * Endpoint that takes in:     - a csv of 1 column: patient form id Aggregates the csv info, and reset the submitted_on date so that patients can resubmit them.
     * @param file 
     
     */
    public bulkResetPatientForms(file: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/patient-forms/bulk-reset-patient-forms';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Patient Form
     * 
     * @param patientFormCreate 
     
     */
    public createPatientForm(body: PatientFormCreate, axiosConfig?: AxiosRequestConfig): Promise<PatientFormRead> {
        const apiRoute = '/patient-forms';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Patient Forms Bulk
     * 
     * @param patientFormsCreateRequestBody 
     
     */
    public createPatientFormsBulk(body: PatientFormsCreateRequestBody, axiosConfig?: AxiosRequestConfig): Promise<Array<PatientFormRead>> {
        const apiRoute = '/patient-forms/bulk';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Patient Forms
     * 
     * @param providerPatientIds 
     * @param deletionStatus 
     
     */
    public findPatientForms(query?: { provider_patient_ids?: Array<number>, deletion_status?: Array<'ACTIVE' | 'ARCHIVED'> }, axiosConfig?: AxiosRequestConfig): Promise<Array<PatientFormNestedRead>> {
        const apiRoute = '/patient-forms';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Form
     * 
     * @param providerPatientId 
     * @param patientFormId 
     
     */
    public getPatientForm(providerPatientId: number, patientFormId: number, axiosConfig?: AxiosRequestConfig): Promise<PatientFormRead> {
        const apiRoute = '/provider-patients/{provider_patient_id}/patient-forms/{patient_form_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId))
                    .replace('{' + 'patient_form_id}', String(patientFormId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Reset Patient Form To Incomplete
     * 
     * @param patientFormId 
     
     */
    public resetPatientFormToIncomplete(patientFormId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/patient-forms/{patient_form_id}/reset-to-incomplete';
        const reqPath = apiRoute
                    .replace('{' + 'patient_form_id}', String(patientFormId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Intake Form Email To All Active Patients
     * 
     * @param providerId 
     
     */
    public sendIntakeFormEmailToAllActivePatients(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-patients/{provider_id}/send-intake-form-email-to-all-active-patients';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Intake Form Email To Patient
     * 
     * @param providerPatientId 
     
     */
    public sendIntakeFormEmailToPatient(providerPatientId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider-patients/{provider_patient_id}/send-intake-form-email-to-patient';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Patient Form
     * 
     * @param providerPatientId 
     * @param patientFormId 
     * @param patientFormUpdate 
     * @param sendEmail 
     
     */
    public updatePatientForm(providerPatientId: number, patientFormId: number, body: PatientFormUpdate, query?: { send_email?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<PatientFormRead> {
        const apiRoute = '/provider-patients/{provider_patient_id}/patient-forms/{patient_form_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId))
                    .replace('{' + 'patient_form_id}', String(patientFormId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            params: query,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const PatientFormApi = new PatientFormApiResource();
