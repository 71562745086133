import * as React from 'react';

export const Referral = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.67 18.25v-1.125c.937 0 1.734-.328 2.39-.984.657-.657.985-1.453.985-2.391h1.125c0 1.25-.438 2.313-1.313 3.188S14.92 18.25 13.67 18.25zm-12-12c0-1.25.437-2.313 1.312-3.188C3.857 2.188 4.92 1.75 6.17 1.75v1.125c-.938 0-1.734.328-2.39.984-.657.657-.985 1.454-.985 2.391H1.67zm1.8 10.2c-.6-.6-1.05-1.281-1.35-2.044-.3-.762-.45-1.54-.45-2.334s.15-1.572.45-2.334c.3-.763.75-1.444 1.35-2.044l4.125-4.125L8.907 4.88 5.214 8.575c.5.4.806.94.918 1.622.113.681-.106 1.303-.656 1.866l.525.524c.513-.474.803-1.106.872-1.893.069-.788-.14-1.475-.628-2.063l6.394-6.394 1.33 1.332L9.19 8.35l.506.506 5.831-5.831 1.331 1.331-5.83 5.832.505.506 5.025-5.025 1.35 1.35-5.568 5.569.543.543 3.975-3.975 1.332 1.332-5.963 5.962c-.6.6-1.281 1.05-2.044 1.35-.762.3-1.54.45-2.334.45s-1.572-.15-2.334-.45a5.947 5.947 0 01-2.044-1.35z"
      fill="currentColor"
    />
  </svg>
);
