import moment from 'moment';

import { EstimatedPrice } from '@headway/api/models/EstimatedPrice';

export const formatPrice = (
  dollars?: number | null,
  truncate = true
): string => {
  if (dollars === undefined || dollars === null) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  })
    .format(dollars)
    .replace(truncate ? '.00' : '', '');
};

export const formatPriceFromCents = (
  cents?: number | null,
  truncate = true
): string => {
  if (cents === undefined || cents === null) {
    return '';
  }

  return formatPrice(cents / 100, truncate);
};

export const getLastBusinessDayOnOrBefore = (
  date: moment.Moment
): moment.Moment => {
  if (date.day() === 0) {
    // Date is a Sunday, so return Friday
    return date.clone().subtract(2, 'days');
  } else if (date.day() === 6) {
    // Date is a Saturday, so return Friday
    return date.clone().subtract(1, 'days');
  } else {
    return date;
  }
};

export const formatPatientPriceEstimate = (estimatedPrice: EstimatedPrice) =>
  `${formatPrice(estimatedPrice.min)}` +
  (estimatedPrice.min !== estimatedPrice.max
    ? ` - ${formatPrice(estimatedPrice.max)}`
    : '');
