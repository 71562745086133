import moment from 'moment';
import React from 'react';

import { CDSCertificate } from '@headway/api/models/CDSCertificate';
import { DEACertificate } from '@headway/api/models/DEACertificate';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { BodyText } from '@headway/helix/BodyText';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { getDisplayYesNo, YesNo } from 'utils/providerQuestionnaire';
import {
  CaqhKeyValuePair,
  CaqhSection,
  CaqhSubsection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';
import { useQuestionnaireContext } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Context';

import {
  getCertsInState,
  IDCertificationType,
  idCertificationTypeToDisplayNames,
} from '../../Identification';

const IDCertificationSummary = ({
  idCertType,
  certsInState,
}: {
  idCertType: IDCertificationType;
  certsInState: (DEACertificate | CDSCertificate)[];
}) => {
  if (certsInState.length === 0) {
    return (
      <CaqhKeyValuePair
        label={`Do you have a ${idCertType} (${idCertificationTypeToDisplayNames[idCertType]}) Registration Certificate?`}
        value="No"
      />
    );
  }

  return (
    <>
      {certsInState.map((cert, index) => {
        return (
          <div
            key={`${idCertType.toLowerCase()}Certificate-${index}`}
            css={{
              ...theme.stack.vertical,
              gap: theme.spacing.x1,
            }}
          >
            <CaqhKeyValuePair
              label={`${idCertType} Number`}
              value={cert.number}
            />
            <CaqhKeyValuePair
              label={`${idCertType} State`}
              value={statesToDisplayNames[cert.state]}
            />
            {!!cert.issueDate && (
              <CaqhKeyValuePair
                label={`${idCertType} Issue Date`}
                value={moment(cert.issueDate).format('MM/DD/YYYY')}
              />
            )}
            <CaqhKeyValuePair
              label={`${idCertType} Expiration Date`}
              value={moment(cert.expirationDate).format('MM/DD/YYYY')}
            />
          </div>
        );
      })}
    </>
  );
};

export const IdentificationHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  const { provider } = useQuestionnaireContext();
  const providerStates = getSupportedStates(provider);

  return (
    <CaqhSection>
      <BodyText>
        <b>General</b>
      </BodyText>
      <CaqhSubsection>
        <CaqhKeyValuePair
          label="Are you a participating Medicaid provider?"
          value={getDisplayYesNo(initialValues?.medicaidProvider)}
        />
        {initialValues?.medicaidProvider === YesNo.YES && (
          <CaqhKeyValuePair
            label="Medicaid Number"
            value={initialValues?.medicaidNumber}
          />
        )}
        <CaqhKeyValuePair
          label="Are you a participating Medicare provider?"
          value={getDisplayYesNo(initialValues?.medicareProvider)}
        />
        {initialValues?.medicareProvider === YesNo.YES && (
          <CaqhKeyValuePair
            label="Medicare Number"
            value={initialValues?.medicareNumber}
          />
        )}
      </CaqhSubsection>
      {providerStates.map((state) => {
        return (
          <div key={state} css={{ marginTop: theme.spacing.x6 }}>
            <BodyText>
              <b>{statesToDisplayNames[state]}</b>
            </BodyText>
            <CaqhSubsection>
              <IDCertificationSummary
                idCertType={IDCertificationType.DEA}
                certsInState={getCertsInState(
                  state,
                  initialValues.deaCertificates
                )}
              />
              <IDCertificationSummary
                idCertType={IDCertificationType.CDS}
                certsInState={getCertsInState(
                  state,
                  initialValues.cdsCertificates
                )}
              />
            </CaqhSubsection>
          </div>
        );
      })}
    </CaqhSection>
  );
};
