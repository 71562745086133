import { Skeleton } from '@mui/material';
import { useFormikContext } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormBuilderApi } from '@headway/api/resources/FormBuilderApi';
import { BodyText } from '@headway/helix/BodyText';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';
import { useQuery } from '@headway/shared/react-query';

import { Component, FormResponse, SchemaComponent } from '../../schema/schema.types';
import { getComponentData } from '../../schema/utils';
import { FormError } from './FormError';
import { FormTitle } from './FormTitle';
import { HelloSignData, HelloSignDataInterface } from './HelloSign.types';

export const upgradeComponentDataAndCheckStateForHelloSignClientId = (
  element: Component,
  injectedData: object
) => {
  const elementCopied = { ...element };
  if (
    !elementCopied.data?.find(
      (data) => data.propName === HelloSignData.helloSignSdkClientId
    )
  ) {
    // If we don't have this data element defined for this block that means that this block definiton
    // is outdated. Lets optimistically migrate it to the current version
    elementCopied?.data?.push({
      propDisplayName: 'clientId',
      propName: HelloSignData.helloSignSdkClientId,
      isNumber: false,
      isMappedToInjectedData: true,
      isRequired: true,
      value: HelloSignData.helloSignSdkClientId,
    });
  }

  const { helloSignSdkClientId } = getComponentData<HelloSignDataInterface>(
    elementCopied,
    injectedData
  );

  return helloSignSdkClientId;
};

export const HelloSign = ({
  element,
  injectedData,
  readOnly,
}: SchemaComponent) => {
  const { id, title } = element;
  const {
    name,
    email,
    role,
    templateIds,
    userId,
    onSuccessMessage,
    helloSignEmbedded,
  } = getComponentData<HelloSignDataInterface>(element, injectedData);

  const releaseContainerRef = useRef<HTMLDivElement>(null);
  const [creatingReleaseForm, setCreatingReleaseForm] = useState(false);
  const [isReleaseFormSigned, setIsReleaseFormSigned] = useState(false);
  const { setFieldValue, values } = useFormikContext<FormResponse>();
  const [cacheKey] = useState(Math.random());

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  const onComplete = useCallback(
    (signatureRequestId: String) => {
      setIsReleaseFormSigned(true);
      setFieldValue(id, signatureRequestId);
    },
    [setFieldValue, setIsReleaseFormSigned, id, values]
  );

  const { data: electronicSignatureRequest } = useQuery(
    ['electornic-signature-request', cacheKey],
    async () => {
      const { url, signatureRequestId } =
        await FormBuilderApi.createElectronicSignatureUrl({
          user_id: userId,
          email,
          name,
          role,
          template_ids: templateIds,
        });

      return {
        url,
        signatureRequestId,
      };
    },
    {
      enabled: !!helloSignEmbedded,

      // Makes sure we only call this once
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (
      !releaseContainerRef.current ||
      !helloSignEmbedded ||
      !electronicSignatureRequest
    ) {
      return;
    }

    let clientId: string =
      upgradeComponentDataAndCheckStateForHelloSignClientId(
        element,
        injectedData ?? {}
      ) ?? '';
    let environment: string = getComponentData<HelloSignDataInterface>(
      element,
      injectedData
    ).environment;

    if (!clientId) {
      try {
        clientId = process.env.REACT_APP_HELLO_SIGN_CLIENT_ID || '';
      } catch (e) {
        console.error('Unable to get HelloSign client ID');
      }
    }

    if (!environment) {
      try {
        environment = process.env.REACT_APP_ENVIRONMENT || '';
      } catch (e) {
        console.error('Unable to environment from default injected state');
      }
    }

    const helloSignClient = new helloSignEmbedded({
      clientId: clientId,
    });

    helloSignClient.open(electronicSignatureRequest.url, {
      skipDomainVerification: environment !== 'production',
      allowCancel: false,
      hideHeader: true,
      container: releaseContainerRef.current,
    });
    helloSignClient.on('ready', () => {
      setCreatingReleaseForm(true);
    });
    helloSignClient.on('sign', () => {
      if (electronicSignatureRequest.signatureRequestId) {
        onComplete(electronicSignatureRequest.signatureRequestId);
      }
    });
  }, [
    releaseContainerRef.current,
    helloSignEmbedded,
    electronicSignatureRequest?.url,
    electronicSignatureRequest?.signatureRequestId,
  ]);

  useEffect(() => {
    if (readOnly) {
      const signatureRequestId = values[id];
      const getDownloadUrl = async () => {
        const url =
          await FormBuilderApi.getElectronicSignatureDownloadUrl(
            signatureRequestId
          );
        setDownloadUrl(url);
      };

      getDownloadUrl();
      return;
    }
  }, []);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.x2,
      }}
    >
      <FormTitle title={title} />
      {readOnly && (
        <div
          css={{
            width: '200px',
            height: '50px',
          }}
        >
          <LinkButton
            disabled={!downloadUrl}
            href={downloadUrl}
            variant="link"
            size="large"
            rel="noreferrer"
            target="_blank"
          >
            Download document
          </LinkButton>
        </div>
      )}
      {!readOnly && (
        <div
          css={{
            position: 'relative',
            height: isReleaseFormSigned ? 225 : 700,
          }}
        >
          {!creatingReleaseForm && (
            <div
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: 700,
                width: '100%',
              }}
            >
              <Skeleton variant="rectangular" height={700} />
            </div>
          )}
          <div
            ref={releaseContainerRef}
            css={{
              iframe: {
                display: 'block',
                minHeight: isReleaseFormSigned ? 225 : 700,
              },
            }}
          />
        </div>
      )}
      {isReleaseFormSigned && onSuccessMessage && (
        <BodyText>{onSuccessMessage}</BodyText>
      )}
      <FormError id={id} />
    </div>
  );
};
