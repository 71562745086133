import { Global } from '@emotion/react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useMediaQuery } from '@headway/helix/utils';
import { theme } from '@headway/ui/theme';

import { Header } from 'components/Header/Header';

import { AvailabilityWizard } from './AvailabilityWizard';
import { ProfileWizard } from './ProfileWizard';

export const Onboarding = () => {
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);
  return (
    <div>
      <Global
        styles={{
          body: {
            background: theme.color.background,
          },
        }}
      />
      <Header />
      <div
        css={{
          height: 'auto',
          minHeight: '100vh',
          position: 'relative',
          top: '0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: isSmall ? 'stretch' : 'center',
          paddingTop: isSmall ? 50 : 0,
        }}
      >
        <Routes>
          <Route path="profile/*" element={<ProfileWizard />} />
          <Route path="availability/*" element={<AvailabilityWizard />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </div>
    </div>
  );
};
