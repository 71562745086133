import * as React from 'react';

export const HighlightTherapyChair = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M15.413 46.431c7.408 0 13.413-6.005 13.413-13.412 0-7.408-6.005-13.413-13.413-13.413S2 25.61 2 33.019c0 7.407 6.005 13.413 13.413 13.413z"
      fill="#FFE6F6"
    />
    <path
      d="M44.344 14.73a.81.81 0 01-.81.81H40.99v8.624c0 1.6-1.302 2.905-2.905 2.905h-5.301l4.983 12.193a.81.81 0 01-1.499.614l-5.234-12.807H17.51l-5.234 12.807a.81.81 0 01-1.498-.614l4.983-12.193H10.46a2.909 2.909 0 01-2.905-2.905V15.54H5.012a.81.81 0 010-1.62h3.092c.591 0 1.07.48 1.07 1.07v8.212c0 1.946.411 2.24 1.818 2.24h26.527c1.414 0 1.824-.503 1.824-2.24v-2.853a.83.83 0 01.03-.209v-5.15c0-.59.48-1.07 1.071-1.07h3.092a.81.81 0 01.81.81h-.002zm-32.125 6.43a.81.81 0 00.81-.811V7.119c0-.248.13-.499.418-.499h21.448a.5.5 0 01.5.5v13.229a.81.81 0 001.62 0V7.119c0-1.167-.95-2.119-2.12-2.119h-21.45c-1.144 0-2.036.932-2.036 2.12v13.229c0 .447.363.81.81.81z"
      fill="#128656"
    />
  </svg>
);
