export function formatPhoneNumber(number: string): string {
  const cleaned = number.replace(/\D/g, '');

  if (cleaned.length === 0) {
    return '';
  } else if (cleaned.length < 3) {
    return `(${cleaned.slice(0, 3)}`;
  } else if (cleaned.length < 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  }
}

export function formatSSN(ssn: string): string {
  const cleaned = ssn.replace(/\D/g, '');

  if (cleaned.length === 0) {
    return '';
  } else if (cleaned.length < 4) {
    return `${cleaned.slice(0, 3)}`;
  } else if (cleaned.length < 6) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
  } else {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 5)}-${cleaned.slice(5)}`;
  }
}

export function formatEIN(ein: string): string {
  const cleaned = ein.replace(/\D/g, '');
  if (cleaned.length === 0) {
    return '';
  } else if (cleaned.length < 3) {
    return `${cleaned.slice(0, 2)}`;
  } else {
    return `${cleaned.slice(0, 2)}-${cleaned.slice(2)}`;
  }
}
