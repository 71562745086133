import moment from 'moment';

import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';

export const compareCarriersAlphabetically = (
  carrierA: FrontEndCarrierRead,
  carrierB: FrontEndCarrierRead
) => {
  return carrierA.id === -1
    ? 1
    : carrierB.id === -1
    ? -1
    : (carrierA.name || '')
        .toLowerCase()
        .localeCompare(carrierB.name?.toLowerCase() || '');
};

// determines if a given carrier has a live contract, optionally filtered for a given US state.
// carriersById is a mapping of *every* carrier by their ids,
// & is a required argument here bc of subsidiary carriers, which don't have state contracts.
// instead, we use their parents' contracts.
// thus we need carriersById in order to convert subsidiary carriers into their parents.
// If no state is included, checks to see if carrier is Headway network for ANY state.
export const isCarrierInHeadwayNetworkForState = (
  carrier: FrontEndCarrierRead,
  state?: UnitedStates
): boolean => {
  if (carrier.fullyOutOfNetwork) {
    return false;
  }
  const effectiveCarrier = eligibilityEffectiveCarrierFor(carrier);
  const carriersWithEffectiveContracts = effectiveCarrier.carrierStates?.filter(
    (carrierState) =>
      carrierState.groupContractEffectiveDate &&
      moment().isAfter(moment(carrierState.groupContractEffectiveDate))
  );

  if (state) {
    return carriersWithEffectiveContracts
      ?.map((carrierState) => carrierState.state)
      .includes(state);
  }

  return (
    !!carriersWithEffectiveContracts &&
    carriersWithEffectiveContracts.length > 0
  );
};

export const groupAndSortFrontEndCarriers = (
  frontEndCarriers: FrontEndCarrierRead[],
  state?: UnitedStates
) => {
  return frontEndCarriers
    .sort(compareCarriersAlphabetically)
    .sort((carrierA, carrierB) => {
      const isCarrierAInStateNetwork = isCarrierInHeadwayNetworkForState(
        carrierA,
        state
      );
      const isCarrierBInStateNetwork = isCarrierInHeadwayNetworkForState(
        carrierB,
        state
      );

      if (isCarrierAInStateNetwork === isCarrierBInStateNetwork) {
        return compareCarriersAlphabetically(carrierA, carrierB);
      }

      return isCarrierAInStateNetwork ? -1 : 1;
    });
};

// if the carrier has an eligibility effective carrier id, return the carrier with that id.
// eligibility effective id for subsidiaries is the parent carrier's id.
// e.g. if the carrier is meritain (a subsidiary of aetna),
// we want to treat it as aetna for provider matching and in-network checks.
// thus meritain's eligibility-effective carrier is aetna.
export const eligibilityEffectiveCarrierFor = (
  carrier: FrontEndCarrierRead
) => {
  return carrier.eligibilityEffectiveCarrier || carrier;
};

// given an array of carriers + a dict of subsidiaries indexed by their parent ids,
// returns an array containing the original carriers plus their subsidiaries.
// (see comment above eligibilityEffectiveCarrierFor for what this means.)
export const carriersWithSubsidiaries = (
  carriers: FrontEndCarrierRead[] | FrontEndCarrierNested[],
  carriersByEligibilityEffectiveId: {
    [index: string]: FrontEndCarrierRead[] | FrontEndCarrierNested[];
  }
) => {
  return carriers.flatMap((carrier) => {
    const parents = carriersByEligibilityEffectiveId[carrier.id] || [];
    return [carrier, ...parents];
  });
};

/**
 * Returns true if selected state has multiple BCBS plans.
 * https://www.bcbs.com/bcbs-companies-and-licensees
 */
const MULTIPLE_BCBS_STATE = [
  UnitedStates.CALIFORNIA,
  UnitedStates.FLORIDA,
  UnitedStates.IDAHO,
  UnitedStates.MISSOURI,
  UnitedStates.NEW_YORK,
  UnitedStates.PENNSYLVANIA,
  UnitedStates.VIRGINIA,
  UnitedStates.WASHINGTON,
];

export function stateHasMultipleBCBSPlan(state: UnitedStates): boolean {
  return MULTIPLE_BCBS_STATE.includes(state);
}

// gemini.link(mamba/app/shared/services/provider_front_end_carrier_service.py)
export const availableOnboardingCarriers = (
  carriers: FrontEndCarrierRead[],
  state: UnitedStates,
  licenseType: string
): FrontEndCarrierRead[] => {
  /*
  given a set of carriers and some provider information,
  returns the subset available to assign to a given provider during onboarding.

  requires the chosen carriers to be available in the given state.
  additional wrap network restrictions apply for BCBSMA if the provider is not in Massachusetts -
  namely, some license types are excluded.
  */
  const availableCarriers: FrontEndCarrierRead[] = [];
  carriers.forEach((carrier) => {
    const hasMatchingState =
      carrier.carrierStates.findIndex(
        (carrierState) => carrierState.state === state
      ) !== -1;

    const carrierIsBCBSMA =
      carrier.id ===
      FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS;
    const bcbsMAWrapNetworkApplies =
      carrierIsBCBSMA && state !== UnitedStates.MASSACHUSETTS;
    const wrapNetworkExcludedLicenseTypes: string[] = [
      'LP',
      'LCADC',
      'LCAT',
      'LCAS',
    ];
    const wrapNetworkExcludesLicenseType =
      wrapNetworkExcludedLicenseTypes.includes(licenseType);
    const available =
      hasMatchingState &&
      !(bcbsMAWrapNetworkApplies && wrapNetworkExcludesLicenseType);
    if (available) {
      availableCarriers.push(carrier);
    }
  });
  return availableCarriers;
};
