import { UserRead } from '@headway/api/models/UserRead';
import { Avatar, AvatarProps } from '@headway/helix/Avatar';
import { formatPatientName } from '@headway/shared/utils/patient';

import { stringToHslColor } from 'utils/stringToHslColor';

export interface ClientAvatarProps extends Pick<AvatarProps, 'size'> {
  client: UserRead;
}

/**
 * Avatar which shows a client's initials and has a background color determined by the client's
 * name.
 */
export const ClientAvatar = ({ client, ...avatarProps }: ClientAvatarProps) => {
  return (
    <Avatar
      {...avatarProps}
      aria-hidden
      backgroundColor={stringToHslColor(formatPatientName(client), 70, 96)}
    >
      {formatPatientName(client, {
        firstInitial: true,
        lastInitial: true,
        initialsWithoutPeriodOrSpace: true,
      })}
    </Avatar>
  );
};
