import { css } from '@emotion/react';
import { InputAdornment } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';

import { Button } from '@headway/helix/Button';
import {
  FieldControl,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
} from '@headway/ui/form';
import { theme } from '@headway/ui/theme';

import {
  MAX_SELF_PAY_RATE,
  MAX_SELF_PAY_RATE_MESSAGE,
  MIN_NONZERO_SELF_PAY_RATE,
  MIN_NONZERO_SELF_PAY_RATE_MESSAGE,
} from 'views/Patients/utils/billingType';

export interface SelfPayFormProps {
  selfPayRateDefault?: number;
  onCancel: () => void;
  onSubmit: (values: SelfPayFormValues) => void;
}

export const selfPayFormValidationSchema = Yup.object().shape({
  selfPayRateDefault: Yup.number()
    .min(0, 'Must be $0 or more.')
    .max(MAX_SELF_PAY_RATE, MAX_SELF_PAY_RATE_MESSAGE)
    .test(
      'nonzero-minimum',
      MIN_NONZERO_SELF_PAY_RATE_MESSAGE,
      (value) => !value || value === 0 || value >= MIN_NONZERO_SELF_PAY_RATE
    )
    .required('Private Pay rate is required'),
});

export interface SelfPayFormValues {
  selfPayRateDefault: number;
}

export const selfPayFormInitialValues = (selfPayRateDefault?: number) => ({
  selfPayRateDefault: selfPayRateDefault ?? 100,
});

export interface SelfPayFormFieldsProps {
  isSubmitting?: boolean;
  cancelBtnText?: string;
  onCancel: () => void;
  children?: React.ReactNode;
}

export const SelfPayRateFormFields = ({
  isSubmitting,
  cancelBtnText = 'Cancel',
  onCancel,
  children,
}: SelfPayFormFieldsProps) => (
  <>
    <FieldControl name="selfPayRateDefault">
      <FieldInputLabel>Session rate</FieldInputLabel>
      <FieldInput
        data-testid="patientSelfPayRateInputField"
        inputProps={{
          step: 'any',
        }}
        type="number"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
      />
      <FieldErrorText />
    </FieldControl>
    {children}
    <div css={selfPayFormActionsContainer}>
      <Button
        variant="secondary"
        disabled={isSubmitting}
        onPress={() => {
          onCancel();
        }}
      >
        {cancelBtnText}
      </Button>
      <Button variant="primary" disabled={isSubmitting} type="submit">
        Save
      </Button>
    </div>
  </>
);

const selfPayFormActionsContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-top: ${theme.space.xl};
  gap: ${theme.space.xs};
`;
