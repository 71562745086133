/**
 * localStorage key for the flag that determines whether the onboarding card has been dismissed on
 * the home page.
 */
export const PROVIDER_CLOSED_ONBOARDING_CARD_LOCAL_STORAGE_KEY =
  'providerClosedOnboardingCard';

/**
 * localStorage key for the flag that is flipped when clicking the "Port Patients" link in the
 * home page onboarding card.
 */
export const CLICKED_PORT_PATIENTS_LOCAL_STORAGE_KEY = 'clicked_port_patients';

export const getLocalStorageKeyForProvider = (
  key: string,
  providerId: number
) => {
  return `${key}_${providerId}`;
};
