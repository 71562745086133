import React, { ReactNode } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconUpload } from '@headway/helix/icons/Upload';
import { Link } from '@headway/helix/Link';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { LogoLoader } from '@headway/ui';

import { useQuestionnaireContext } from './QuestionnaireV2Context';
import { CAQH_WEBSITE_URL } from './utils/intakeQuestionnaireConstants';

export const CaqhSection = ({
  children,
  maxHeight,
  wrapColumns,
  additionalSubBody,
}: {
  children: ReactNode;
  maxHeight?: string;
  wrapColumns?: boolean;
  additionalSubBody?: ReactNode;
}) => {
  const context = useQuestionnaireContext();

  return (
    <div>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x1,
          width: '50%',
          paddingRight: theme.spacing.x6,
        }}
      >
        <SectionHeader>
          <IconUpload
            width="16"
            height="16"
            css={{ marginRight: theme.spacing.x1, rotate: '90deg' }}
          />
          First, review CAQH details we found
        </SectionHeader>
        <SubBodyText>Here’s what we found on the CAQH website.</SubBodyText>
      </div>

      {context.isRefreshing ? (
        <div
          css={{
            ...theme.stack.horizontal,
            justifyContent: 'center',
            padding: theme.spacing.x9,
          }}
        >
          <LogoLoader />
        </div>
      ) : (
        <div
          css={{
            ...theme.stack.vertical,
            width: '100%',
            backgroundColor: theme.color.system.backgroundGray,
            padding: theme.spacing.x6,
            margin: `${theme.spacing.x4} 0`,
            whiteSpace: wrapColumns ? 'nowrap' : 'normal',
            gap: theme.spacing.x2,
            flexFlow: wrapColumns ? 'column wrap' : '',
            maxHeight: maxHeight || wrapColumns ? '200px' : '',
          }}
        >
          {children}
        </div>
      )}

      {additionalSubBody}
      <SubBodyText>
        <b> Need to correct something?</b>
        <ol css={{ padding: `0 ${theme.spacing.x5}`, margin: 0 }}>
          <li css={{ marginBottom: theme.spacing.x1 }}>
            Go to the{' '}
            <Link href={CAQH_WEBSITE_URL} target="_blank">
              CAQH website
            </Link>{' '}
            to update these details, then click re-attest.
          </li>
          <li>
            {' '}
            All fixed?{' '}
            <Button
              size="medium"
              variant="link"
              onPress={async () => {
                if (
                  !!context.providerQuestionnaire.rawData?.caqhNumberFromNpi
                ) {
                  await context.refreshCaqhToIntakeMapping(
                    Number(
                      context.providerQuestionnaire.rawData?.caqhNumberFromNpi
                    )
                  );
                }
                // TODO [sc-217256]: Set up tracking event
                // trackEvent({ name: '' });
              }}
            >
              Recheck CAQH info
            </Button>
          </li>
        </ol>
      </SubBodyText>
    </div>
  );
};

export const CaqhKeyValuePair: React.FC<
  React.PropsWithChildren<{
    label: string;
    value?: ReactNode;
  }>
> = ({ label, value = '' }) => (
  <SubBodyText>
    <span
      css={{
        ...theme.stack.horizontal,
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <span css={{ width: '50%' }}>
        <SubBodyText>{label}</SubBodyText>
      </span>
      <div css={{ marginLeft: theme.spacing.x4, width: '50%' }}>
        <span css={[theme.typography.subbody.medium]}>{value}</span>
      </div>
    </span>
  </SubBodyText>
);

export const CaqhSubsection: React.FC<
  React.PropsWithChildren<{
    children: ReactNode;
    label?: string;
  }>
> = ({ children, label }) => (
  <div
    css={{
      ...theme.stack.vertical,
      gap: theme.spacing.x2,
    }}
  >
    {label && (
      <BodyText>
        <b>{label}</b>
      </BodyText>
    )}

    <div
      css={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(1, 1fr)',
        gridRowGap: theme.spacing.x2,
      }}
    >
      {children}
    </div>
  </div>
);
