import { css, cx } from '@emotion/css';
import { AriaRadioProps } from '@react-types/radio';
import React, { useContext, useRef } from 'react';
import { useFocusRing, useHover, useRadio, VisuallyHidden } from 'react-aria';

import { ValidationState } from '@headway/helix/forms';
import { RadioGroupContext } from '@headway/helix/RadioGroup';
import { theme } from '@headway/helix/theme';

export type BigRadioProps = {
  validation?: ValidationState;
  disabled?: boolean;
} & Pick<AriaRadioProps, 'autoFocus' | 'children' | 'value'>;

export const BigRadio = ({ disabled, value, ...props }: BigRadioProps) => {
  const radioGroupState = useContext(RadioGroupContext);
  if (!radioGroupState) {
    throw new Error('Radio components must be a descendant of RadioGroup');
  }
  const ref = useRef<HTMLInputElement>(null);

  const ariaProps = {
    value,
    isDisabled: disabled || radioGroupState.isDisabled,
    isSelected: radioGroupState.selectedValue === value,
    isReadOnly: radioGroupState.isReadOnly,
    ...props,
  };

  const { inputProps } = useRadio(ariaProps, radioGroupState, ref);
  const { hoverProps, isHovered } = useHover({
    isDisabled: ariaProps.isDisabled,
  });
  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus: ariaProps.autoFocus,
  });

  const Icon = ariaProps.isSelected ? SelectedIcon : UnselectedIcon;

  return (
    <label
      className={cx(labelCss, {
        [hoverCss]: !ariaProps.isReadOnly && isHovered,
        [selectedCss]: ariaProps.isSelected,
        [disabledCss]: ariaProps.isDisabled,
      })}
      {...hoverProps}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <Icon
        className={cx({
          [focusRingCss]: isFocusVisible,
          [errorCss]: props.validation?.validity === 'invalid',
        })}
        aria-hidden="true"
      />
      {props.children}
    </label>
  );
};

const SelectedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 19a9 9 0 100-18 9 9 0 000 18zm0-6a3 3 0 100-6 3 3 0 000 6z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const UnselectedIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      {...props}
    >
      <circle cx="10" cy="10" r="8.5" stroke="currentColor" />
    </svg>
  );
};

const labelCss = css({
  ...theme.typography.body.regular,
  color: theme.color.system.textBlack,
  display: 'grid',
  gridTemplateColumns: '1em auto',
  gap: theme.spacing.x2,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.color.system.borderGray,
  borderRadius: '4px',
  padding: '24px 18px',
  flexGrow: 1,
  flexBasis: 0,
});

const hoverCss = css({
  cursor: 'pointer',
  color: theme.color.system.black,
});

const focusRingCss = css({
  outline: `2px solid ${theme.color.system.green}`,
  borderRadius: '50%',
});

const disabledCss = css({
  color: theme.color.system.disabledGray,
  borderColor: theme.color.system.borderGray,
});

const errorCss = css({
  color: theme.color.primary.red,
});

const selectedCss = css({
  backgroundColor: theme.color.system.backgroundGray,
  borderColor: theme.color.system.black,
});
