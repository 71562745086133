import { css } from '@emotion/react';
import {
  AccessAlarmTwoTone,
  ArrowForward,
  AssignmentTwoTone,
  AttachmentTwoTone,
  CancelTwoTone,
  CreditCardTwoTone,
  ErrorTwoTone,
  ImportContactsTwoTone,
  LocalHospitalTwoTone,
  MessageTwoTone,
  NotesTwoTone,
  PaymentTwoTone,
  PhoneTwoTone,
  SupervisorAccountTwoTone,
} from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Alert, Chip, Link as ExternalLink } from '@mui/material';
import { useProvider, useProviderPatient } from 'hooks';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import {
  InsuranceReadinessIssueInsufficientOrIncorrectInfo,
  InsuranceReadinessIssueInsufficientOrIncorrectInfoType,
} from '@headway/api/models/InsuranceReadinessIssueInsufficientOrIncorrectInfo';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderAppointmentRead } from '@headway/api/models/ProviderAppointmentRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderAppointmentStatusNotes } from '@headway/api/models/ProviderAppointmentStatusNotes';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPaymentStatus } from '@headway/api/models/ProviderPaymentStatus';
import { ProviderProgressNoteRead } from '@headway/api/models/ProviderProgressNoteRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { SessionUnconfirmableReason } from '@headway/api/models/SessionUnconfirmableReason';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderAppointmentApi } from '@headway/api/resources/ProviderAppointmentApi';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { ProviderProgressNotesApi } from '@headway/api/resources/ProviderProgressNotesApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { UserPaymentMethodApi } from '@headway/api/resources/UserPaymentMethodApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { IconError } from '@headway/helix/icons/Error';
import { Link as HelixLink } from '@headway/helix/Link';
import { SelfPay } from '@headway/icons/dist/SelfPay';
import { abbreviationToStateEnum } from '@headway/shared/constants/unitedStatesDisplayNames';
import { PATIENT_WELCOME_EMAIL } from '@headway/shared/constants/zendesk';
import { SelectedEventContext } from '@headway/shared/events/SelectedEventContext';
import {
  ALWAYS_USE_GP_BILLING_ACCOUNT,
  FAILED_PAYMENTS_M1,
  MM_TREATMENT_PLAN,
  MULTI_STATE_CREDENTIALING_BETA,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent, trackPageView } from '@headway/shared/utils/analytics';
import { downloadFile } from '@headway/shared/utils/download';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { isUnready } from '@headway/shared/utils/insuranceUtils';
import {
  formatPatientName,
  formatPatientPronouns,
} from '@headway/shared/utils/patient';
import { formatPrice } from '@headway/shared/utils/payments';
import { Button, MessageBubble, Modal, Tooltip } from '@headway/ui';
import { usePrevious } from '@headway/ui/hooks/usePrevious';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';
import { theme } from '@headway/ui/theme';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { SelfPayOptInForm } from 'components/SelfPayOptInForm/SelfPayOptInForm';
import { SkeletonBlock } from 'components/SkeletonBlock';
import { useBillingAccountForPayment } from 'hooks/useBillingAccount';
import {
  ReasonCannotConfirmSessionDetails,
  useCanConfirmSessionDetails,
} from 'hooks/useCanConfirmSessionDetails';
import { useClaimReadiness } from 'hooks/useClaimReadiness';
import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { usePatientAddresses } from 'hooks/usePatientAddresses';
import { useProviderPrice } from 'hooks/useProviderPrice';
import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import { useSessionDetailsConfirmability } from 'hooks/useSessionDetailsConfirmability';
import { PatientsContext } from 'providers/PatientsProvider';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdmin } from 'utils/access';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';
import { shouldShowMSCGuidanceCards } from 'utils/msc';
import { isPatientMissingRequiredInfo } from 'utils/patient';
import { MissingAddressBannerWithAddLocation } from 'views/Clients/AddressManagement';
import {
  TreatmentPlanRequirementClientDetailAlert,
  useTreatmentPlanRequirements,
} from 'views/Clients/TreatmentPlan/Requirements/TreatmentPlanRequirements';
import {
  getActiveTreatmentPlan,
  getReviewDate,
  isTreatmentPlanExiringSoon,
} from 'views/Clients/TreatmentPlan/TreatmentPlanUtils';
import {
  getAuthorizationInstructionsModalTitle,
  InsuranceAuthorizationInstructionsModalContent,
} from 'views/Patients/InsuranceAuthorizationInstructionsModalContent';
import { PatientBillingForm } from 'views/Patients/PatientBillingForm';
import {
  isProviderNotCredentialedInAnyPatientState,
  isProviderNotCredentialedInSpecificAddress,
} from 'views/Patients/utils/patientInsuranceStatus';

import { CalendarContext } from '../../CalendarContext';
import {
  getLastDateToUpdateCancellationDetails,
  getLastDateToUpdateSessionDetails,
  getPaymentAmount,
  getPaymentSummary,
  getTaskSummary,
  hasTask,
  isAppointment,
  isAppointmentDateValidForPFEC,
  isAppointmentDetailsConfirmed,
  isAutobooked,
  isCanceled,
  isDetailsConfirmed,
  isHealthcareReferral,
  isInsuranceAppointment,
  isIntakeCall,
  isPaid,
  isPast,
  isPatientBooked,
  isPaymentExpected,
  isSelfPayAppt,
  paymentAttemptFailed,
  useWarningMessage,
} from '../../events/util/events';
import { getProgressNoteSubmissionState } from '../AppointmentConfirmation/Forms/ProgressNotes/templateView/utils';
import { CanceledChip } from '../CanceledChip';
import { DetailPopover } from '../DetailPopover';
import { EventContent } from '../EventContent';
import { EventDetail, LocationEventDetail } from '../EventDetail';
import { EventFooter } from '../EventFooter';
import { EventHeader } from '../EventHeader';
import { HumanErrorGuidanceCard } from '../HumanErrorGuidanceCard';
import { IneligibleGuidanceCard } from '../IneligibleGuidanceCard';
import { LicenseExpiredAlert } from '../LicenseExpiredAlert';
import { PaymentFailedAlert } from '../PaymentFailedAlert';
import { NotCredentialedInAnyPatientStateWarning } from './AppointmentDetailGuidanceCards/NotCredentialedInAnyPatientStateWarning';
import { NotCredentialedInStateError } from './AppointmentDetailGuidanceCards/NotCredentialedInStateError';
import EAPInfo from './Components/EAPInfo';
import { MissingInfoNextSteps } from './Components/MissingInfoNextSteps';

export type AppointmentDetailProps = Pick<
  AppointmentDetailImplProps,
  | 'open'
  | 'onClose'
  | 'handleConfirmDetailsClick'
  | 'handleAddProgressNoteClick'
  | 'handleIntakeCallClick'
  | 'handleRescheduleClick'
  | 'handleCancelClick'
  | 'handleUndoConfirmedSessionClick'
  | 'eventIdsToMatchingProviderFrontEndCarriers'
> & {
  handleScheduleEventClick: (
    eventType: ProviderEventType,
    patientUserId: number,
    date: Date
  ) => void;
};

export const AppointmentDetail = ({
  open,
  onClose,
  eventIdsToMatchingProviderFrontEndCarriers,
  ...handleProps
}: AppointmentDetailProps) => {
  const [loadingInfoCollectionEmail, setLoadingInfoCollectionEmail] =
    useState(false);
  const [sentInfoCollectionEmail, setSentInfoCollectionEmail] = useState(false);

  const [isSelfPayProviderOptInModalOpen, setIsSelfPayProviderOptInModalOpen] =
    useState(false);
  const [isBillingMethodModalOpen, setIsBillingMethodModalOpen] =
    useState(false);

  const { event } = useSelectedEvent();
  const provider = useProvider();

  const action = useRef<any>();
  const prevOpen = usePrevious(open);
  const prevEvent = usePrevious(event);

  useEffect(() => {
    if (open && (open !== prevOpen || event !== prevEvent) && action.current) {
      action.current.updatePosition();
    }
  });

  const { data: providerPatient } = useProviderPatient(
    {
      providerId: provider.id,
      patientId: event?.patientUserId,
    },
    { enabled: open }
  );

  const { selectedEventVirtualId } = React.useContext(SelectedEventContext);

  if (!open || !selectedEventVirtualId) {
    return null;
  }

  const anchorEl = document.querySelector(
    `.rbc-event-${selectedEventVirtualId.toString().replace(/[^0-9]/g, '')}`
  );
  return (
    <DetailPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      action={action}
      PaperProps={{
        style: {
          width: 448,
        },
      }}
    >
      {event ? (
        <PatientsContext.Consumer>
          {({ patients }) => {
            const handleScheduleEventClick = (eventType: ProviderEventType) => {
              const proposedStartDateOffset =
                eventType === ProviderEventType.APPOINTMENT ? 'week' : 'day';

              onClose();
              handleProps.handleScheduleEventClick(
                eventType,
                event.patientUserId!,
                moment(event.startDate).add(1, proposedStartDateOffset).toDate()
              );
            };

            const handleSendInfoCollectionEmail = async (patient: UserRead) => {
              try {
                setLoadingInfoCollectionEmail(true);
                if (providerPatient) {
                  await ProviderPatientApi.sendProviderPatientAccountInvite(
                    providerPatient.id
                  );
                }
                await UserApi.updateUser(patient.id, {
                  isInvited: true,
                });
                setSentInfoCollectionEmail(true);
                notifySuccess(`Email sent to ${formatPatientName(patient)}`);
              } catch (error: any) {
                notifyError(error.message);
              }
              setLoadingInfoCollectionEmail(false);
            };

            const checkIfSelfPayTermsAccepted = () => {
              const { handleConfirmDetailsClick, handleAddProgressNoteClick } =
                handleProps;

              const isSelfPayAndHasAttested =
                !!providerPatient?.providerSelfPayAttestationDate;
              const isSelfPayAndHasAcceptedTerms =
                !!provider.selfPayTermsAcceptanceDate;

              if (!isSelfPayAndHasAcceptedTerms) {
                setIsSelfPayProviderOptInModalOpen(true);
              } else if (!isSelfPayAndHasAttested) {
                setIsBillingMethodModalOpen(true);
              } else if (isPast(event)) {
                handleConfirmDetailsClick();
              } else handleAddProgressNoteClick();
            };

            const handleConfirmDetailsOrAddProgressNoteClick = async () => {
              const { handleConfirmDetailsClick, handleAddProgressNoteClick } =
                handleProps;

              if (isSelfPayAppt(event)) {
                checkIfSelfPayTermsAccepted();
              } else if (isPast(event)) {
                handleConfirmDetailsClick();
              } else {
                handleAddProgressNoteClick();
              }
            };

            return (
              <AppointmentDetailImpl
                loadingInfoCollectionEmail={loadingInfoCollectionEmail}
                sentInfoCollectionEmail={sentInfoCollectionEmail}
                patients={patients ?? undefined}
                provider={provider}
                providerPatient={providerPatient}
                event={event}
                open={open}
                onClose={onClose}
                action={action}
                isBillingMethodModalOpen={isBillingMethodModalOpen}
                setIsBillingMethodModalOpen={setIsBillingMethodModalOpen}
                isSelfPayProviderOptInModalOpen={
                  isSelfPayProviderOptInModalOpen
                }
                setIsSelfPayProviderOptInModalOpen={
                  setIsSelfPayProviderOptInModalOpen
                }
                handleScheduleEventClick={handleScheduleEventClick}
                handleConfirmDetailsClick={
                  handleProps.handleConfirmDetailsClick
                }
                handleSendInfoCollectionEmail={handleSendInfoCollectionEmail}
                handleConfirmDetailsOrAddProgressNoteClick={
                  handleConfirmDetailsOrAddProgressNoteClick
                }
                handleAddProgressNoteClick={
                  handleProps.handleAddProgressNoteClick
                }
                handleIntakeCallClick={handleProps.handleIntakeCallClick}
                handleRescheduleClick={handleProps.handleRescheduleClick}
                handleCancelClick={handleProps.handleCancelClick}
                handleUndoConfirmedSessionClick={
                  handleProps.handleUndoConfirmedSessionClick
                }
                eventIdsToMatchingProviderFrontEndCarriers={
                  eventIdsToMatchingProviderFrontEndCarriers
                }
              />
            );
          }}
        </PatientsContext.Consumer>
      ) : (
        <AppointmentDetailSkeleton />
      )}
    </DetailPopover>
  );
};

function AppointmentDetailSkeleton() {
  return (
    <div role="status">
      <span className="sr-only">Loading...</span>
      <div className="flex flex-col gap-5 p-5">
        <div className="flex flex-col gap-1">
          <SkeletonBlock className="hlx-typography-section-heading w-[15ch]" />
          <SkeletonBlock className="hlx-typography-body w-[12ch]" />
          <SkeletonBlock className="hlx-typography-body w-[10ch]" />
        </div>
        <div className="flex flex-col gap-1">
          <SkeletonBlock className="hlx-typography-body w-[25ch]" />
          <SkeletonBlock className="hlx-typography-body w-[25ch]" />
          <SkeletonBlock className="hlx-typography-body w-[28ch]" />
        </div>
        <div className="flex flex-col gap-1">
          <SkeletonBlock className="hlx-typography-body w-[16ch]" />
          <SkeletonBlock className="hlx-typography-body w-full" />
          <SkeletonBlock className="hlx-typography-body w-full" />
          <SkeletonBlock className="hlx-typography-body w-[20ch]" />
        </div>
        <div className="flex flex-col gap-1">
          <SkeletonBlock className="h-[36px] w-[60%] self-end" />
          <SkeletonBlock className="hlx-typography-subbody w-[10ch] self-end" />
        </div>
        <div className="border-1 -mx-5 flex items-center gap-3 border-0 border-y border-solid border-y-system-borderGray p-5">
          <SkeletonBlock className="aspect-square h-[24px] w-[24px] rounded-full" />
          <SkeletonBlock className="hlx-typography-body w-[13ch]" />
        </div>
        <div className="flex flex-col gap-1">
          <SkeletonBlock className="hlx-typography-body w-[30%] self-end" />
          <SkeletonBlock className="hlx-typography-body w-[30%] self-end" />
          <SkeletonBlock className="hlx-typography-body w-[30%] self-end" />
        </div>
      </div>
    </div>
  );
}

const PaymentDetail = ({ event }: { event: ProviderEventRead }) => {
  return (
    <div className={'flex items-center'}>
      <div className={'flex flex-row'}>
        <div>{getPaymentSummary(event)}</div>
        {event.providerAppointment?.providerPaymentStatusEnum ===
          ProviderPaymentStatus.UNDER_REVIEW && (
          <div className={'ml-4'}>
            <Badge variant="negative" icon={IconError}>
              Payment on hold
            </Badge>
          </div>
        )}
      </div>
      {event.providerAppointment?.billingType === BillingType.SELF_PAY && (
        <Tooltip
          title={`This amount reflects the application of a ${formatPrice(
            event.providerAppointment?.providerPaymentFeesAmount
          )} payment processing fee. Headway covers this fee for sessions billed to insurance.`}
          placement="right"
        >
          <InfoIcon fontSize="inherit" className={'ml-2'} />
        </Tooltip>
      )}
    </div>
  );
};

interface AppointmentDetailImplProps {
  event: ProviderEventRead;
  patients?: UserRead[];
  open: boolean;
  onClose: () => void;
  provider: ProviderRead;
  providerPatient?: ProviderPatientRead;
  action: React.MutableRefObject<any>;
  isBillingMethodModalOpen: boolean;
  setIsBillingMethodModalOpen: (value: boolean) => void;
  loadingInfoCollectionEmail: boolean;
  sentInfoCollectionEmail: boolean;
  isSelfPayProviderOptInModalOpen: boolean;
  setIsSelfPayProviderOptInModalOpen: (value: boolean) => void;
  handleSendInfoCollectionEmail: (patient: UserRead) => Promise<void>;
  handleConfirmDetailsOrAddProgressNoteClick: () => Promise<void>;
  handleScheduleEventClick: (eventType: ProviderEventType) => void;
  handleConfirmDetailsClick: () => void;
  handleAddProgressNoteClick: () => void;
  handleIntakeCallClick: () => void;
  handleRescheduleClick: () => void;
  handleCancelClick: () => void;
  handleUndoConfirmedSessionClick: () => void;
  eventIdsToMatchingProviderFrontEndCarriers: Map<
    number,
    ProviderFrontEndCarrierRead | null
  >;
}

function BillingAccountNotVerifiedWarningCard() {
  const { user, provider } = useAuthStore();
  const shouldAlwaysUseGPBillingAccount = useFlag(
    ALWAYS_USE_GP_BILLING_ACCOUNT
  );
  const isInGP = !!provider?.groupPracticeId;
  if (
    // conditions for temp GP copy for 2023 tax season
    isInGP &&
    !shouldAlwaysUseGPBillingAccount
  ) {
    if (isGroupAdmin(user)) {
      return (
        <GuidanceCard variant="warning">
          <div>
            <p>
              <b>Update your billing info to complete this session:</b> We’ll
              use this information to prepare your 1099 for the 2023 tax year.
            </p>
            <HelixLink href="/settings/billing">
              <b>Update your billing info</b>
            </HelixLink>
          </div>
        </GuidanceCard>
      );
    }
    return (
      <GuidanceCard variant="warning">
        <div>
          <p>
            <b>
              Admin needs to re-verify your practice’s billing account
              information to confirm this session:
            </b>{' '}
            We’ll use this information to prepare your 1099 for the 2023 tax
            year.
          </p>
        </div>
      </GuidanceCard>
    );
  }
  return (
    <GuidanceCard variant="warning">
      <div>
        <p>
          <b>Update your billing info to complete this session:</b> Please link
          your bank account in Settings for your direct deposit. Then, return
          here to confirm your session and process payment.
        </p>
        {(!isInGP || isGroupAdmin(user)) && (
          <HelixLink href="/settings/billing">
            <b>Update your billing info</b>
          </HelixLink>
        )}
      </div>
    </GuidanceCard>
  );
}

const AppointmentDetailImpl = ({
  event,
  patients,
  open,
  onClose,
  provider,
  providerPatient,
  action,
  isBillingMethodModalOpen,
  setIsBillingMethodModalOpen,
  isSelfPayProviderOptInModalOpen,
  setIsSelfPayProviderOptInModalOpen,
  handleScheduleEventClick,
  handleConfirmDetailsClick,
  handleSendInfoCollectionEmail,
  handleConfirmDetailsOrAddProgressNoteClick,
  handleAddProgressNoteClick,
  handleIntakeCallClick,
  handleRescheduleClick,
  handleCancelClick,
  handleUndoConfirmedSessionClick,
  sentInfoCollectionEmail,
  loadingInfoCollectionEmail,
  eventIdsToMatchingProviderFrontEndCarriers,
}: AppointmentDetailImplProps) => {
  const isStandardPatientFormsEnabled = useFlag('standardPatientForms', true);
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const {
    isMSCGuardrailWarning,
    isMSCGuardrailRestriction,
    restrictionDate: mscGuardrailRestrictionDate,
  } = useMSCGuardrail();
  const showMSCGuidanceCards = shouldShowMSCGuidanceCards(
    isMSCEnabled,
    isMSCGuardrailWarning,
    isMSCGuardrailRestriction
  );
  const sessionLabel = isIntakeCall(event) ? 'phone consultation' : 'session';
  const [cancellationReason, setCancellationReason] = useState<
    React.ReactElement | string
  >('');
  const [currentProgressNoteState, setCurrentProgressNoteState] = useState<
    string | undefined
  >();
  const [currentProgressNote, setCurrentProgressNote] = useState<
    ProviderProgressNoteRead | undefined
  >();
  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const isMMTreatmentPlanRequirementEnabled = useFlag(MM_TREATMENT_PLAN, false);
  const isFailedPaymentsM1Enabled = useFlag(FAILED_PAYMENTS_M1, false);
  const [
    carrierToShowAuthzInstructionsFor,
    setCarrierToShowAuthzInstructionsFor,
  ] = useState<FrontEndCarrierRead | FrontEndCarrierNested>();
  const { ironcladAgreementStatus } = useIroncladAgreementStatus();
  const { billingAccountInfo } = useBillingAccountForPayment();

  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient?.id,
  });

  const appointment = event.providerAppointment;
  const patient = patients?.find(
    (patient) => patient.id === event?.patientUserId
  );
  const patientFullName = formatPatientName(patient);
  const patientFirstName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const { providerAddresses } = useContext(ProviderAddressContext);
  const { data: patientAddresses } = usePatientAddresses({
    patientId: patient?.id,
  });

  const inPersonApptAddressState = providerAddresses.find(
    (address) => address.id === event.providerAddressId
  )?.state;
  const virtualApptAddressState = patientAddresses?.filter(
    (address) => address.id === appointment?.appointmentLocationPatientAddressId
  )[0]?.state;

  const appointmentState = inPersonApptAddressState
    ? (abbreviationToStateEnum[inPersonApptAddressState] as UnitedStates)
    : virtualApptAddressState;

  const { insuranceStatus, isLoading: isUserInsuranceStatusLoading } =
    useInsuranceStatus(
      patient,
      patient?.activeUserInsurance,
      event.telehealth,
      appointmentState
    );

  const insuranceStatusWithCheckAppointmentStateResult = useInsuranceStatus(
    patient,
    patient?.activeUserInsurance,
    event.telehealth,
    appointmentState,
    true
  );
  const insuranceStatusWithCheckAppointmentState =
    isMSCGuardrailWarning &&
    !isUserInsuranceStatusLoading &&
    !insuranceStatusWithCheckAppointmentStateResult.isLoading &&
    insuranceStatus === PatientInsuranceOrEAPStatus.IN_NETWORK
      ? insuranceStatusWithCheckAppointmentStateResult.insuranceStatus
      : undefined;

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { patientMessages } = useContext(CalendarContext);
  const message = event?.id ? patientMessages[event.id] : undefined;

  const { isTreatmentPlanRequired, treatmentPlanRequirementType } =
    // This is a false positive from the react-hooks/rules-of-hooks rule
    // seemingly due to the code complexity of this component? Likely a good
    // sign that we should refactor this component.
    // https://github.com/facebook/react/issues/21328
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTreatmentPlanRequirements(patient?.id);

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userPaymentQuery = useQuery(
    ['user-payment', patient?.defaultUserPaymentMethodId],
    () => {
      if (!patient?.defaultUserPaymentMethodId) {
        throw new Error('No default payment method found');
      }

      return UserPaymentMethodApi.getUserPaymentMethod(
        patient.defaultUserPaymentMethodId
      );
    },
    {
      enabled: !!(open && patient?.defaultUserPaymentMethodId),
    }
  );

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const latestClaimQuery = useQuery(
    ['new-claims-for-appointment', appointment?.id],
    async () => {
      if (!appointment?.id) {
        throw new Error('No appointment id found');
      }
      const claims =
        await ProviderAppointmentApi.getClaimsForProviderAppointment(
          appointment.id
        );

      if (claims?.length > 0) {
        const latestClaim = claims.reduce((prev, curr) =>
          curr.createdOn && prev.createdOn && curr.createdOn < prev.createdOn
            ? curr
            : prev
        );
        return latestClaim;
      }
    },
    {
      enabled: !!(open && appointment?.id),
    }
  );

  const addAnyReasonDescriptionToCancellationCopy = (
    cancelReason: string | undefined,
    copy: string
  ) => {
    if (cancelReason) {
      const shortenedReason = cancelReason.substring(0, 210) + '...';
      const titleValue =
        cancelReason.length > 200 ? sanitize(cancelReason) : '';
      return (
        <div title={titleValue}>{`${copy}. Reason: "${shortenedReason}"`}</div>
      );
    }
    return copy;
  };

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let copy;

    switch (appointment?.statusNotes) {
      case ProviderAppointmentStatusNotes.NOT_AWARE:
        copy = `Wasn't aware of the ${
          isIntakeCall(event) ? 'call' : 'session'
        }`;
        break;
      case ProviderAppointmentStatusNotes.NOT_AVAILABLE:
        copy = 'Not available at this time';
        break;
      case ProviderAppointmentStatusNotes.CLIENT_NOT_AVAILABLE:
        copy = 'Client not available';
        break;
      case ProviderAppointmentStatusNotes.SEEING_OUTSIDE_HEADWAY:
        copy = 'Seeing client outside of Headway';
        break;
      case ProviderAppointmentStatusNotes.STOPPING_CARE:
        copy = 'Stopping care with client';
        break;
      case ProviderAppointmentStatusNotes.NOT_RIGHT_FIT:
        copy = 'Client not the right fit';
        break;
      case ProviderAppointmentStatusNotes.PATIENT_CANCELLATION:
        copy = `Client canceled ${isIntakeCall(event) ? 'call' : 'session'}`;
        break;
      case ProviderAppointmentStatusNotes.EXTERNAL_PLATFORM_CANCELLATION:
        copy = `Client canceled ${isIntakeCall(event) ? 'call' : 'session'}`;
        break;
      case ProviderAppointmentStatusNotes.NO_SHOW:
        copy = `Client did not ${
          isIntakeCall(event) ? 'pick up call' : 'show'
        }`;
        break;
      case ProviderAppointmentStatusNotes.NOT_TAKING_NEW_CLIENTS:
        copy = 'Not currently taking new clients';
        break;
      case ProviderAppointmentStatusNotes.OTHER:
        copy = 'Other';
        break;
      case ProviderAppointmentStatusNotes.RESCHEDULING:
        copy = `Rebooking ${
          isIntakeCall(event) ? 'call' : 'session'
        } at another time`;
        break;
      default:
        if (
          appointment?.statusNotes !== undefined &&
          [
            ProviderAppointmentStatusNotes.PATIENT_NOT_AVAILABLE,
            ProviderAppointmentStatusNotes.PROVIDER_NOT_RIGHT_FIT,
            ProviderAppointmentStatusNotes.PROVIDER_NOT_RESPONDING,
            ProviderAppointmentStatusNotes.PROVIDER_NOT_AVAILABLE,
            ProviderAppointmentStatusNotes.FOUND_OTHER_PROVIDER,
            ProviderAppointmentStatusNotes.TOO_EXPENSIVE,
            ProviderAppointmentStatusNotes.SOMETHING_ELSE,
            ProviderAppointmentStatusNotes.NOT_INTERESTED_IN_THERAPY,
          ].includes(appointment?.statusNotes)
        ) {
          copy = addAnyReasonDescriptionToCancellationCopy(
            appointment?.cancellationReasonDescription,
            `Client canceled ${isIntakeCall(event) ? 'call' : 'session'}`
          );
        } else {
          copy = '';
        }
    }

    setCancellationReason(copy);
  }, [
    event,
    appointment?.cancellationReasonDescription,
    appointment?.statusNotes,
  ]);

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!appointment) {
      return;
    }

    const fetchAndSetProgressNoteState = async () => {
      try {
        const currentNotes =
          await ProviderProgressNotesApi.findProviderProgressNotes({
            appointment_id: appointment.id,
          });

        if (currentNotes && currentNotes.length > 0) {
          setCurrentProgressNote(currentNotes[0]);
        }

        setCurrentProgressNoteState(
          getProgressNoteSubmissionState(event, currentNotes)
        );
      } catch (e) {
        throw new Error(`Error fetching progress note state information: ${e}`);
      }
    };

    fetchAndSetProgressNoteState();
  }, [event, appointment]);

  const {
    isFetchedAfterMount: sessionDetailsEditabilityFetched,
    data: sessionDetailsEditabilityStatus,
    // This is a false positive from the react-hooks/rules-of-hooks rule
    // seemingly due to the code complexity of this component? Likely a good
    // sign that we should refactor this component.
    // https://github.com/facebook/react/issues/21328
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(['session-details-editability', event?.id], async () => {
    if (!event.id) {
      return null;
    }

    return ProviderEventApi.getSessionDetailsEditability(event.id!);
  });

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: claimReadiness } = useClaimReadiness(
    { patientUser: patient },
    {
      enabled: !!(
        open &&
        patient?.id &&
        patient.activeUserInsuranceId &&
        appointment &&
        appointment.billingType === BillingType.INSURANCE
      ),
    }
  );

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userAppointmentReadinessQuery = useQuery(
    ['UserAppointmentReadiness', patient?.id],
    () => {
      if (!patient?.id) {
        throw new Error('user_id required for getUserAppointmentReadiness');
      }

      return UserApi.getUserAppointmentReadiness(patient.id);
    },
    {
      enabled: !!(open && patient?.id),
    }
  );

  const isInNoDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.NO_DATA_PRELIM_PRICING;

  const isInOldDataPrelimPricing =
    insuranceStatus === PatientInsuranceOrEAPStatus.OLD_DATA_PRELIM_PRICING;

  const { data: providerPriceData, isLoading: isProviderPriceLoading } =
    // This is a false positive from the react-hooks/rules-of-hooks rule
    // seemingly due to the code complexity of this component? Likely a good
    // sign that we should refactor this component.
    // https://github.com/facebook/react/issues/21328
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useProviderPrice(
      { provider, client: patient },
      { enabled: isInOldDataPrelimPricing }
    );

  const insufficientOrIncorrectInfoIssue =
    userAppointmentReadinessQuery.data?.insurance.find(
      ({ type }) =>
        type ===
        InsuranceReadinessIssueInsufficientOrIncorrectInfoType.INSUFFICIENT_OR_INCORRECT_INFORMATION
    ) as InsuranceReadinessIssueInsufficientOrIncorrectInfo;

  const isHumanErrorBannerEnabled = insufficientOrIncorrectInfoIssue;

  const mismatchedFields =
    insufficientOrIncorrectInfoIssue?.patientMismatchInputFields || [];

  const matchingPFEC = event.id
    ? eventIdsToMatchingProviderFrontEndCarriers.get(event.id)
    : undefined;
  const acceptsPatientInsurance =
    !!matchingPFEC &&
    isAppointmentDateValidForPFEC(matchingPFEC, event.startDate);
  const isAnthemEAPAppointment =
    matchingPFEC?.frontEndCarrierId === FrontEndCarrierIdentifier.ANTHEM_EAP;

  const missingRequiredPatientInfo =
    patient && appointment?.billingType
      ? isPatientMissingRequiredInfo(
          patient,
          appointment.billingType,
          claimReadiness
        )
      : false;

  const resendWelcomeEmailButton =
    patient &&
    missingRequiredPatientInfo &&
    isStandardPatientFormsEnabled &&
    !isIntakeCall(event);

  const showInfoCollectionEmail =
    (!isPast(event) &&
      patient?.inviteChannel === UserInviteChannel.AUTOBOOK_HC_REFERRAL &&
      !isIntakeCall(event) &&
      (!patient?.defaultUserPaymentMethodId || !patient?.activeUserInsurance) &&
      !sentInfoCollectionEmail) ||
    resendWelcomeEmailButton;

  const canUpdateSessionDetails =
    sessionDetailsEditabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  const {
    canConfirmSessionDetails: didClientSideConfirmabilityCheckPass,
    reasonCannotConfirm: confirmDetailsDisabledReason,
    isLoading: isSessionDetailsConfirmabilityLoading,
  } =
    // This is a false positive from the react-hooks/rules-of-hooks rule
    // seemingly due to the code complexity of this component? Likely a good
    // sign that we should refactor this component.
    // https://github.com/facebook/react/issues/21328
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCanConfirmSessionDetails(event);

  const {
    data: sessionDetailsConfirmabilityApiResponse,
    isLoading: isSessionDetailsConfirmabilityApiLoading,
    // TODO: SC-109203
    // This is a false positive from the react-hooks/rules-of-hooks rule
    // seemingly due to the code complexity of this component? Likely a good
    // sign that we should refactor this component.
    // https://github.com/facebook/react/issues/21328
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useSessionDetailsConfirmability({
    providerEventId: event.originalRecurringEventId
      ? event.originalRecurringEventId
      : event?.id,
    appointmentDate: event.startDate
      ? moment(event.startDate).toDate()
      : undefined,
  });

  const disableConfirmDetails = !didClientSideConfirmabilityCheckPass;

  let detailCTA = 'View session details';
  if (currentProgressNote && currentProgressNote.attestedOn === null) {
    detailCTA = 'Complete progress note';
  }

  const shouldBlockProviderFromConfirming =
    !!event?.startDate &&
    shouldBlockProviderWithIroncladAgreement(
      isIroncladBlockAppointmentConfirmationEnabled,
      ironcladAgreementStatus,
      new Date(event.startDate)
    ) &&
    isAppointment(event);

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const appointmentDetailWarning = useWarningMessage({
    event,
    patient,
    acceptsPatientInsurance,
    claimReadiness,
  });

  const isApptScheduledOrConfirmed =
    appointment?.status === ProviderAppointmentStatus.SCHEDULED ||
    appointment?.status === ProviderAppointmentStatus.DETAILS_CONFIRMED;

  const shouldShowPrelimPricingBanner =
    isApptScheduledOrConfirmed &&
    (isInNoDataPrelimPricing ||
      (isInOldDataPrelimPricing && !isProviderPriceLoading));
  const isStateLoading =
    isUserInsuranceStatusLoading || isSessionDetailsConfirmabilityLoading;

  const shouldDisplayAppointmentDetails =
    event.startDate && patient && appointment;

  // This is a false positive from the react-hooks/rules-of-hooks rule
  // seemingly due to the code complexity of this component? Likely a good
  // sign that we should refactor this component.
  // https://github.com/facebook/react/issues/21328
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      shouldDisplayAppointmentDetails &&
      !!patient.activeUserInsurance &&
      shouldShowPrelimPricingBanner &&
      !isStateLoading &&
      open
    ) {
      trackPageView({
        name: 'Verification In Progress Banner Viewed',
        properties: {
          providerId: provider.id,
          patientUserId: patient.id,
          userInsuranceId: `${patient.activeUserInsurance.id}`,
          prelimPricingType: `${patient.activeUserInsurance.prelimPricingType}`,
          copyVariant: '',
          presumptiveAccumulatorsApplied:
            providerPriceData?.priceCalculationInfo?.presumptiveAccumulators ??
            [],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldShowPrelimPricingBanner,
    isStateLoading,
    shouldDisplayAppointmentDetails,
    provider.id,
    patient?.id,
    patient?.activeUserInsurance,
    open,
  ]);

  if (isStateLoading) {
    return <AppointmentDetailSkeleton />;
  }

  const presumptiveAccumulatorApplied =
    !isProviderPriceLoading &&
    (providerPriceData?.priceCalculationInfo?.presumptiveAccumulators?.length ??
      0) > 0;

  const isNotCredentialedInAnyPatientState =
    isProviderNotCredentialedInAnyPatientState(
      insuranceStatus,
      patientAddresses,
      event.telehealth,
      appointmentState,
      showMSCGuidanceCards
    ) ||
    isProviderNotCredentialedInAnyPatientState(
      insuranceStatusWithCheckAppointmentState,
      patientAddresses,
      event.telehealth,
      appointmentState,
      showMSCGuidanceCards
    );
  const isNotCredentialedInSelectedAddressWithoutCheckApptState =
    isProviderNotCredentialedInSpecificAddress(
      insuranceStatus,
      event.telehealth,
      appointmentState
    );
  const isNotCredentialedInSelectedAddressWithCheckApptState =
    isProviderNotCredentialedInSpecificAddress(
      insuranceStatusWithCheckAppointmentState,
      event.telehealth,
      appointmentState
    );

  const activeTreatmentPlan = getActiveTreatmentPlan(
    treatmentPlans,
    isMMTreatmentPlanRequirementEnabled
  );
  const shouldShowTreatmentPlanRequirementWarning =
    isTreatmentPlanRequired && !activeTreatmentPlan && hasTask(event);
  const shouldShowTreatmentPlanExpirationWarning =
    isTreatmentPlanRequired &&
    activeTreatmentPlan &&
    isTreatmentPlanExiringSoon(activeTreatmentPlan) &&
    hasTask(event);

  const isInsuredBilling = (appointment: ProviderAppointmentRead) =>
    appointment.billingType === BillingType.INSURANCE;

  const shouldShowNotCredentialedInAnyPatientStateWarning = (
    appointment: ProviderAppointmentRead
  ) =>
    isNotCredentialedInAnyPatientState &&
    isInsuredBilling(appointment) &&
    !isAppointmentDetailsConfirmed(appointment);

  const shouldShowNotCredentialedInStateGuidanceCard = (
    appointment: ProviderAppointmentRead
  ) => {
    return (
      (isNotCredentialedInSelectedAddressWithoutCheckApptState ||
        isNotCredentialedInSelectedAddressWithCheckApptState) &&
      isInsuredBilling(appointment) &&
      !isAppointmentDetailsConfirmed(appointment)
    );
  };

  const shouldShowHumanErrorGuidanceCard = (
    appointment: ProviderAppointmentRead
  ) =>
    !userAppointmentReadinessQuery.isLoading &&
    isHumanErrorBannerEnabled &&
    isInsuredBilling(appointment);

  const shouldShowMissingAddressBannerWithAddLocation =
    showMSCGuidanceCards &&
    patient!.activeUserInsurance &&
    claimReadiness &&
    claimReadiness.requirements?.includes(
      UserClaimReadinessCheck.PATIENT_ADDRESS
    ) &&
    !isInNoDataPrelimPricing;

  const sessionUnconfirmableReasons =
    sessionDetailsConfirmabilityApiResponse?.unconfirmableReasons;
  const sessionDetailsConfirmabilityMetadata =
    sessionDetailsConfirmabilityApiResponse?.metadata;

  const shouldShowLicenseExpiredAlert =
    sessionUnconfirmableReasons?.includes(
      SessionUnconfirmableReason.EXPIRED_LICENSE
    ) && !isSessionDetailsConfirmabilityApiLoading;

  const shouldShowNoLicenseFoundForStateAlert =
    sessionUnconfirmableReasons?.includes(
      SessionUnconfirmableReason.NO_LICENSE_FOUND_FOR_STATE
    ) && !isSessionDetailsConfirmabilityApiLoading;

  const shouldShowNoVerifiedPaymentMethodsAlert =
    sessionUnconfirmableReasons?.includes(
      SessionUnconfirmableReason.ALL_PAYMENT_METHODS_HAVE_INVALID_PRE_AUTHS
    ) && isFailedPaymentsM1Enabled;

  const shouldShowIneligibleGuidanceCard =
    isInsuranceAppointment(event) &&
    isUnready(userAppointmentReadinessQuery?.data);

  const sessionConfirmationDateOfAppointmentInTerminalFailure =
    sessionDetailsConfirmabilityMetadata?.sessionConfirmationDateOfAppointmentInTerminalFailure;

  const shouldShowHasPastAppointmentInTerminalFailureAlert =
    sessionUnconfirmableReasons?.includes(
      SessionUnconfirmableReason.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
    ) &&
    sessionConfirmationDateOfAppointmentInTerminalFailure &&
    isFailedPaymentsM1Enabled;

  if (!open) {
    return null;
  }

  if (!shouldDisplayAppointmentDetails) {
    return <AppointmentDetailSkeleton />;
  }

  return (
    <React.Fragment>
      <EventHeader
        title={
          <div className={'flex items-center'}>
            <Link
              css={{
                marginRight: theme.space.xs,
                color: theme.color.primary,
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
              to={`/clients/${patient.id}`}
            >
              {patientFullName}
              {patient.pronouns && (
                <span css={pronounCss}>{formatPatientPronouns(patient)}</span>
              )}
            </Link>
            {isCanceled(event) ? <CanceledChip /> : null}
          </div>
        }
        startDate={event.startDate!}
        recurrence={event.recurrence}
        channel={event.channel}
        onClose={onClose}
      >
        {isSelfPayAppt(event) && (
          <span className={'mt-1 flex items-center'}>
            <SelfPay className={'mr-2 w-4'} />
            <span css={{ fontSize: theme.fontSize.sm }}>Private Pay</span>
          </span>
        )}

        {shouldBlockProviderFromConfirming &&
          !isCanceled(event) &&
          !isDetailsConfirmed(event) && (
            <div className={'mt-2'}>
              <GuidanceCard variant="error">
                To ensure you are a paid in a timely manner, we only allow
                confirming session details for 30 days
              </GuidanceCard>
            </div>
          )}

        {shouldShowNotCredentialedInAnyPatientStateWarning(appointment) ? (
          <div className={'mt-2'}>
            <NotCredentialedInAnyPatientStateWarning
              patient={patient}
              noPatientAddressOnFile={patientAddresses?.length === 0}
              onClickToReschedule={handleRescheduleClick}
              isMSCGuardrailWarning={isMSCGuardrailWarning}
              mscGuardrailRestrictionDate={mscGuardrailRestrictionDate}
            />
          </div>
        ) : shouldShowNotCredentialedInStateGuidanceCard(appointment) &&
          appointmentState ? (
          <NotCredentialedInStateError
            patient={patient}
            state={appointmentState}
            isMSCGuardrailWarning={isMSCGuardrailWarning}
            mscGuardrailRestrictionDate={mscGuardrailRestrictionDate}
            onClickToReschedule={handleRescheduleClick}
          />
        ) : shouldShowPrelimPricingBanner ? (
          <div className={'mt-4'}>
            <GuidanceCard variant="warning">
              <BodyText>
                <div className={'mb-2'}>
                  <>
                    Reminder:{' '}
                    {patient?.activeUserInsurance?.billingFrontEndCarrierName ||
                      `${patientFirstName}’s insurer`}{' '}
                    is working to get us the information we need to verify{' '}
                    {patientFirstName}’s benefits. If you book a session,
                    they’ll pay{' '}
                    {isInOldDataPrelimPricing && !presumptiveAccumulatorApplied
                      ? 'their most recent'
                      : 'full'}{' '}
                    cost{' '}
                    {isInOldDataPrelimPricing && presumptiveAccumulatorApplied
                      ? '(due to their deductible or out-of-pocket max likely resetting)'
                      : isInOldDataPrelimPricing
                      ? '(adjusted by CPT code)'
                      : '(depending on the CPT code)'}
                    ,{' '}
                    {isInOldDataPrelimPricing && !presumptiveAccumulatorApplied
                      ? 'with a refund or second bill later if'
                      : 'with a refund later if'}{' '}
                    {patient?.activeUserInsurance?.billingFrontEndCarrierName ||
                      'their insurer'}{' '}
                    confirms a{' '}
                    {isInOldDataPrelimPricing && !presumptiveAccumulatorApplied
                      ? 'different'
                      : 'lower'}{' '}
                    final cost.
                  </>
                </div>
                <HelixLink
                  href={
                    'https://help.headway.co/hc/en-us/articles/21767328647828'
                  }
                  target="_blank"
                >
                  Learn more
                </HelixLink>
              </BodyText>
            </GuidanceCard>
          </div>
        ) : shouldShowHumanErrorGuidanceCard(appointment) ? (
          <HumanErrorGuidanceCard
            patient={patient}
            mismatchedFields={mismatchedFields}
          />
        ) : !userAppointmentReadinessQuery.isLoading &&
          !!appointmentDetailWarning ? (
          <>
            <Alert color="warning" icon={<ErrorTwoTone />} className={'mt-4'}>
              {appointmentDetailWarning.warningMessage &&
                appointmentDetailWarning.warningHeader && (
                  <div className={'font-bold'}>
                    {appointmentDetailWarning.warningHeader}
                  </div>
                )}
              {appointmentDetailWarning.warningMessage}
            </Alert>
            {missingRequiredPatientInfo && (
              <MissingInfoNextSteps
                clientId={patient.id}
                // using virtualId instead of id || virtualId solves a mysterious calendar rendering bug
                eventId={event.virtualId}
              />
            )}
          </>
        ) : shouldShowMissingAddressBannerWithAddLocation ? (
          <div className={'mt-2'}>
            <MissingAddressBannerWithAddLocation
              client={patient}
              updateActiveAddress
              css={{ marginTop: theme.space.xs }}
            >
              <BodyText>
                We need {patientFirstName}’s home address to submit claims.
              </BodyText>
            </MissingAddressBannerWithAddLocation>
          </div>
        ) : shouldShowLicenseExpiredAlert ? (
          <LicenseExpiredAlert
            licenseNumber={
              sessionDetailsConfirmabilityApiResponse?.metadata?.licenseNumber
            }
          />
        ) : shouldShowNoLicenseFoundForStateAlert ? (
          <div className={'mt-4'}>
            <GuidanceCard layout="vertical" variant="error">
              <BodyText>
                <strong>No license found:</strong> we're not able to find a
                verified license for this state. Please{' '}
                <a
                  href="https://headway.co/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact us
                </a>{' '}
                to submit a license for this state in order to confirm this
                session.
              </BodyText>
            </GuidanceCard>
          </div>
        ) : shouldShowNoVerifiedPaymentMethodsAlert ? (
          <div className={'mt-4'}>
            <GuidanceCard layout="vertical" variant="error">
              <div className={'hlx-typography-body'}>
                {patientFirstName} does not have a valid payment method on file.
                Please make sure one is added in order to confirm this session.
              </div>
            </GuidanceCard>
          </div>
        ) : shouldShowHasPastAppointmentInTerminalFailureAlert ? (
          <div className={'mt-4'}>
            <GuidanceCard layout="vertical" variant="error">
              <div className={'hlx-typography-body'}>
                {patientFirstName} has previously unpaid sessions that need to
                be resolved before you can confirm any of their sessions held
                after{' '}
                {moment(
                  sessionConfirmationDateOfAppointmentInTerminalFailure
                ).format('MMM D, YYYY')}
                . Please ask them to log in to their Billing page to pay the
                balance or add a new payment method.
              </div>
            </GuidanceCard>
          </div>
        ) : confirmDetailsDisabledReason ===
          ReasonCannotConfirmSessionDetails.INDIVIDUAL_W9 ? (
          <div className={'mt-4'}>
            <GuidanceCard variant="warning">
              <div>
                <p>
                  <b>Complete your W-9 form to confirm this session:</b> We’ll
                  use this information to prepare your 1099 for the 2023 tax
                  year.
                </p>
                <HelixLink href={billingAccountInfo?.stripeOnboardingLink}>
                  Complete W-9 form on Stripe ↗
                </HelixLink>
              </div>
            </GuidanceCard>
          </div>
        ) : confirmDetailsDisabledReason ===
          ReasonCannotConfirmSessionDetails.BILLING_ACCOUNT_NOT_VERIFIED ? (
          <div className={'mt-4'}>
            <BillingAccountNotVerifiedWarningCard />
          </div>
        ) : shouldShowIneligibleGuidanceCard ? (
          <IneligibleGuidanceCard
            patient={patient}
            userAppointmentReadiness={userAppointmentReadinessQuery?.data}
          />
        ) : shouldShowTreatmentPlanRequirementWarning ? (
          <TreatmentPlanRequirementClientDetailAlert
            clientId={patient?.id}
            requirementType={treatmentPlanRequirementType}
          />
        ) : shouldShowTreatmentPlanExpirationWarning ? (
          <TreatmentPlanRequirementClientDetailAlert
            clientId={patient?.id}
            requirementType={treatmentPlanRequirementType}
            expirationDate={
              activeTreatmentPlan
                ? getReviewDate(activeTreatmentPlan)
                    ?.toDate()
                    .toLocaleDateString() ?? 'soon'
                : 'soon'
            }
          />
        ) : hasTask(event) ? (
          <Alert
            color="info"
            variant="outlined"
            icon={<AssignmentTwoTone />}
            className={'mt-4'}
          >
            {getTaskSummary(event)}
          </Alert>
        ) : isSelfPayAppt(event) && paymentAttemptFailed(event) ? (
          <PaymentFailedAlert
            patientFirstName={patientFirstName}
            paymentMethod={userPaymentQuery?.data}
            variant={undefined}
          />
        ) : null}
        {!isPast(event) && isAnthemEAPAppointment && <EAPInfo />}
        {showInfoCollectionEmail && (
          <div className={'flex flex-col items-end'}>
            <Button
              variant="contained"
              color="primary"
              disabled={loadingInfoCollectionEmail}
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                handleSendInfoCollectionEmail(patient);
              }}
              className={'mt-5'}
            >
              Re-send Headway setup invitation{' '}
            </Button>
            <a
              css={{
                marginTop: '10px',
                color: theme.color.darkGray,
                textDecoration: 'underline',
                fontSize: theme.fontSize.sm,
              }}
              href={PATIENT_WELCOME_EMAIL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Email
            </a>
          </div>
        )}
      </EventHeader>
      <EventContent>
        {!isIntakeCall(event) && <LocationEventDetail event={event} />}
        {isIntakeCall(event) && (
          <EventDetail
            title="Client phone number"
            icon={PhoneTwoTone}
            label={patient?.phone || 'Unknown'}
          />
        )}
        {message?.content && (
          <EventDetail
            title={`Message from ${patientFirstName}`}
            label={
              <MessageBubble position="left">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(message.content),
                  }}
                />
              </MessageBubble>
            }
            icon={MessageTwoTone}
          />
        )}
        {(isAutobooked(event) || isHealthcareReferral(event)) && (
          <EventDetail
            title="Referral source"
            icon={SupervisorAccountTwoTone}
            label={`${patientFirstName} was referred by their healthcare provider. Please check the client's profile for physician notes.`}
          />
        )}
        {isCanceled(event) ? (
          <React.Fragment>
            {!!cancellationReason && (
              <EventDetail
                title="Cancellation reason"
                icon={CancelTwoTone}
                label={cancellationReason}
              />
            )}
            <EventDetail
              title="Cancellation fee"
              icon={CreditCardTwoTone}
              label={
                (isPaid(event) || isPaymentExpected(event)) &&
                (getPaymentAmount(event) ?? 0) > 0 ? (
                  <PaymentDetail event={event} />
                ) : (
                  'No cancellation fee'
                )
              }
            />
            {isAppointment(event) &&
              isCanceled(event) &&
              canUpdateSessionDetails &&
              getLastDateToUpdateCancellationDetails(event) && (
                <EventDetail
                  title="Modification deadline"
                  icon={AccessAlarmTwoTone}
                  label={`${getLastDateToUpdateCancellationDetails(
                    event
                  )!.fromNow(true)} left to modify cancellation details`}
                />
              )}
          </React.Fragment>
        ) : isDetailsConfirmed(event) ? (
          <React.Fragment>
            {isPaid(event) || isPaymentExpected(event) ? (
              <EventDetail
                title="Payment"
                icon={PaymentTwoTone}
                label={<PaymentDetail event={event} />}
              />
            ) : null}
            <EventDetail
              title="CPT codes"
              icon={ImportContactsTwoTone}
              label={appointment.cptCodes?.join(', ') ?? ''}
            />
            <EventDetail
              title="Diagnosis codes"
              icon={LocalHospitalTwoTone}
              label={appointment.diagnosisCodes?.join(', ') ?? ''}
            />
            {appointment.attachments && appointment.attachments.length > 0 ? (
              <EventDetail
                title="Progress note attachments"
                icon={AttachmentTwoTone}
                label={
                  <div>
                    {appointment.attachments.map((attachment, idx) => (
                      <Chip
                        className={'mr-1'}
                        key={idx}
                        component={'button'}
                        size="small"
                        variant="outlined"
                        label={attachment.name}
                        onClick={async () =>
                          attachment.link &&
                          (await downloadFile({
                            link: attachment.link,
                            name: attachment.name,
                          }))
                        }
                      />
                    ))}
                  </div>
                }
              />
            ) : null}
            {isAppointment(event) && (
              <EventDetail
                title="Progress note"
                icon={NotesTwoTone}
                label={
                  <div className={'flex flex-row'}>
                    <Button
                      css={{
                        fontSize: theme.fontSize.base,
                        textDecoration: 'underline',
                        ':hover': {
                          textDecoration: 'underline',
                        },
                        padding: 0,
                        lineHeight: 'inherit',
                      }}
                      variant="link"
                      onClick={
                        canUpdateSessionDetails
                          ? handleConfirmDetailsClick
                          : handleAddProgressNoteClick
                      }
                    >
                      Progress Note
                    </Button>
                    {currentProgressNoteState !== undefined && (
                      <div className={'ml-4'}>
                        <Badge
                          variant={
                            currentProgressNoteState === 'Submitted'
                              ? 'positive'
                              : 'neutral'
                          }
                        >
                          {currentProgressNoteState}
                        </Badge>
                      </div>
                    )}
                  </div>
                }
              />
            )}
            {isAppointment(event) &&
              isDetailsConfirmed(event) &&
              canUpdateSessionDetails && (
                <EventDetail
                  title="Modification deadline"
                  icon={AccessAlarmTwoTone}
                  label={`${getLastDateToUpdateSessionDetails(
                    event,
                    latestClaimQuery?.data
                  )?.fromNow(true)} left to modify session details`}
                />
              )}
          </React.Fragment>
        ) : null}
        {sessionDetailsEditabilityStatus ===
          SessionDetailsEditabilityStatus.DISALLOWED_DEADLINE_EXCEEDED && (
          <EventDetail
            title="Modification deadline exceeded"
            icon={AccessAlarmTwoTone}
            label="The session details above can no longer be edited"
          />
        )}
        {sessionDetailsEditabilityStatus ===
          SessionDetailsEditabilityStatus.DISALLOWED_TRANSACTION_PENDING && (
          <EventDetail
            title="Transactions pending"
            icon={AccessAlarmTwoTone}
            label="The session details above can no longer be edited because a transaction is pending"
          />
        )}
      </EventContent>
      <EventFooter>
        {!isPast(event) &&
          !isCanceled(event) &&
          !isDetailsConfirmed(event) &&
          isAppointment(event) &&
          event.telehealth &&
          provider.videoUrlDefault && (
            <ExternalLink
              rel="nooopener noreferrer"
              target="_blank"
              href={provider.videoUrlDefault}
              className={'text-decoration-none'}
            >
              <Button
                css={{ color: theme.color.primary }}
                endIcon={<ArrowForward />}
              >
                Join Session
              </Button>
            </ExternalLink>
          )}
        {!isCanceled(event) &&
          !isIntakeCall(event) &&
          (!isDetailsConfirmed(event) ? (
            <Button
              color="gray"
              onClick={() => {
                handleConfirmDetailsOrAddProgressNoteClick();
                if (isPast(event)) {
                  trackEvent({
                    name: `Calendar - Confirm Session Details Clicked`,
                    properties: {
                      providerId: appointment.providerId!,
                      patientUserId: event.patientUserId!,
                      providerAppointmentId: appointment.id!,
                      progressNoteId: currentProgressNote?.id ?? null,
                    },
                  });
                } else {
                  trackEvent({
                    name: 'Calendar - Start Progress Note',
                    properties: {
                      providerId: appointment.providerId!,
                      patientUserId: event.patientUserId!,
                      providerAppointmentId: appointment.id!,
                      progressNoteId: currentProgressNote?.id ?? null,
                    },
                  });
                }
              }}
              disabled={disableConfirmDetails && isPast(event)}
              data-testid="appointmentDetailConfirmDetailsButton"
              endIcon={<ArrowForward />}
            >
              {isPast(event)
                ? 'Confirm session details'
                : 'Start Progress Note'}
            </Button>
          ) : sessionDetailsEditabilityFetched && !canUpdateSessionDetails ? (
            <Button
              color="gray"
              onClick={() => {
                handleAddProgressNoteClick();
                trackEvent({
                  name: `Calendar - Add Progress Note Clicked`,
                  properties: {
                    providerId: appointment.providerId!,
                    patientUserId: event.patientUserId!,
                    providerAppointmentId: appointment.id!,
                    // Avo needs updating to include this property
                    // progressNoteId: currentProgressNote?.id ?? null,
                  },
                });
              }}
              endIcon={<ArrowForward />}
            >
              {detailCTA}
            </Button>
          ) : null)}
        {isAppointment(event) &&
          !isPast(event) &&
          isPatientBooked(event) &&
          !isCanceled(event) && (
            <Button
              color="gray"
              onClick={handleIntakeCallClick}
              endIcon={<ArrowForward />}
            >
              Change to phone consultation
            </Button>
          )}
        {!isCanceled(event) && !isDetailsConfirmed(event) ? (
          <Button
            color="gray"
            onClick={handleRescheduleClick}
            data-testid="appointmentDetailRescheduleButton"
            endIcon={<ArrowForward />}
          >
            Reschedule {sessionLabel}
          </Button>
        ) : null}
        {!isCanceled(event) && !isDetailsConfirmed(event) ? (
          <Button
            color="gray"
            onClick={handleCancelClick}
            data-testid="appointmentDetailCancelButton"
            endIcon={<ArrowForward />}
          >
            Cancel {sessionLabel}
          </Button>
        ) : null}
        {isDetailsConfirmed(event) ||
        (isAppointment(event) && isCanceled(event)) ||
        (isIntakeCall(event) && isPast(event)) ? (
          <Button
            color="gray"
            onClick={() =>
              handleScheduleEventClick(ProviderEventType.APPOINTMENT)
            }
            endIcon={<ArrowForward />}
          >
            Schedule new session
          </Button>
        ) : isIntakeCall(event) && isCanceled(event) ? (
          <Button
            color="gray"
            onClick={() =>
              handleScheduleEventClick(ProviderEventType.INTAKE_CALL)
            }
            endIcon={<ArrowForward />}
          >
            Schedule new phone consultation
          </Button>
        ) : null}
        {isAppointment(event) &&
        isCanceled(event) &&
        canUpdateSessionDetails ? (
          <Button
            color="gray"
            onClick={handleCancelClick}
            endIcon={<ArrowForward />}
          >
            Modify cancellation details
          </Button>
        ) : null}
        {isAppointment(event) &&
        isDetailsConfirmed(event) &&
        canUpdateSessionDetails ? (
          <React.Fragment>
            <Button
              color="gray"
              onClick={() => {
                handleConfirmDetailsClick();
                trackEvent({
                  name: `Calendar - Modify Session Details Clicked`,
                  properties: {
                    providerId: appointment.providerId!,
                    patientUserId: event.patientUserId!,
                    providerAppointmentId: appointment.id!,
                    progressNoteId: currentProgressNote?.id ?? null,
                  },
                });
              }}
              endIcon={<ArrowForward />}
            >
              Modify session details
            </Button>
            <Button
              color="gray"
              onClick={handleUndoConfirmedSessionClick}
              endIcon={<ArrowForward />}
            >
              Undo session confirmation
            </Button>
          </React.Fragment>
        ) : null}
      </EventFooter>

      <Modal
        open={isBillingMethodModalOpen}
        onClose={() => {
          setIsBillingMethodModalOpen(false);
          setIsSelfPayProviderOptInModalOpen(false);
        }}
        title="Confirm Private Pay"
      >
        {provider && patient && providerPatient && (
          <PatientBillingForm
            patient={patient}
            provider={provider}
            providerPatient={providerPatient}
            showPatientAddedAlert={false}
            startFormOnSelfPay={true}
            openSelfPayProviderOptInModal={() => {
              setIsSelfPayProviderOptInModalOpen(true);
            }}
            showAuthorizationInstructionsModal={(carrier) => {
              setIsBillingMethodModalOpen(false);
              setCarrierToShowAuthzInstructionsFor(carrier);
            }}
            onUpdateSuccess={() => {
              setIsSelfPayProviderOptInModalOpen(false);
              setIsBillingMethodModalOpen(false);

              if (isPast(event)) {
                handleConfirmDetailsClick();
              } else handleAddProgressNoteClick();
            }}
            onCancel={() => {}}
          />
        )}
      </Modal>
      {carrierToShowAuthzInstructionsFor && (
        <Modal
          open={!!carrierToShowAuthzInstructionsFor}
          onClose={() => {
            setCarrierToShowAuthzInstructionsFor(undefined);
          }}
          title={getAuthorizationInstructionsModalTitle(patientFirstName)}
        >
          <div className="hlx-modal hlx-modal-root">
            <InsuranceAuthorizationInstructionsModalContent
              carrier={carrierToShowAuthzInstructionsFor}
              clientDisplayName={patientFirstName}
              closeModal={() => {
                setCarrierToShowAuthzInstructionsFor(undefined);
              }}
            />
          </div>
        </Modal>
      )}

      <Modal
        title="Welcome to Private Pay"
        open={isSelfPayProviderOptInModalOpen}
        onClose={() => {
          setIsSelfPayProviderOptInModalOpen(false);
        }}
      >
        {providerPatient && (
          <SelfPayOptInForm
            provider={provider}
            onSubmit={() => {
              setIsSelfPayProviderOptInModalOpen(false);
              if (!providerPatient.providerSelfPayAttestationDate) {
                setIsBillingMethodModalOpen(true);
              } else if (isPast(event)) {
                handleConfirmDetailsClick();
              } else handleAddProgressNoteClick();
            }}
          />
        )}
      </Modal>
    </React.Fragment>
  );
};

/* Styles */
const pronounCss = css`
  font-size: ${theme.fontSize.sm};
  margin-left: ${theme.space.xs2};
`;
