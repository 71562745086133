import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { UserApi } from '@headway/api/resources/UserApi';
import {
  getExternalPlatformChannelForEventChannel,
  getExternalPlatformDisplayName,
} from '@headway/shared/utils/externalPlatforms';

export enum MessagePrefillTemplateId {
  EXTERNAL_PLATFORM_PATIENT_UNREADINESS_REMINDER = 'EXTERNAL_PLATFORM_PATIENT_UNREADINESS_REMINDER',
  PATIENT_UNREADINESS_REMINDER = 'PATIENT_UNREADINESS_REMINDER',
}

interface IMessagePrefillTemplate<TId extends MessagePrefillTemplateId, TData> {
  id: TId;
  data: TData;
}

type PatientUnreadinessReminderTemplate = IMessagePrefillTemplate<
  MessagePrefillTemplateId.PATIENT_UNREADINESS_REMINDER,
  { providerEventId: any }
>;

type ExternalPlatformPatientUnreadinessReminderTemplate =
  IMessagePrefillTemplate<
    MessagePrefillTemplateId.EXTERNAL_PLATFORM_PATIENT_UNREADINESS_REMINDER,
    { providerEventId: any }
  >;

type MessagePrefillTemplate =
  | PatientUnreadinessReminderTemplate
  | ExternalPlatformPatientUnreadinessReminderTemplate; // | next template type goes here;

export const isPatientUnreadinessReminderTemplate = (
  candidate?: unknown
): candidate is PatientUnreadinessReminderTemplate => {
  const result =
    typeof candidate === 'object' &&
    candidate !== null &&
    'id' in candidate &&
    (candidate as IMessagePrefillTemplate<any, any>).id ===
      MessagePrefillTemplateId.PATIENT_UNREADINESS_REMINDER &&
    'data' in candidate &&
    typeof (candidate as PatientUnreadinessReminderTemplate).data ===
      'object' &&
    'providerEventId' in (candidate as PatientUnreadinessReminderTemplate).data;
  return result;
};

export const isExternalPlatformPatientUnreadinessReminderTemplate = (
  candidate?: unknown
): candidate is ExternalPlatformPatientUnreadinessReminderTemplate => {
  const result =
    typeof candidate === 'object' &&
    candidate !== null &&
    'id' in candidate &&
    (candidate as IMessagePrefillTemplate<any, any>).id ===
      MessagePrefillTemplateId.EXTERNAL_PLATFORM_PATIENT_UNREADINESS_REMINDER &&
    'data' in candidate &&
    typeof (candidate as ExternalPlatformPatientUnreadinessReminderTemplate)
      .data === 'object' &&
    'providerEventId' in
      (candidate as ExternalPlatformPatientUnreadinessReminderTemplate).data;
  return result;
};

const isKnownTemplateId = (id: unknown): id is MessagePrefillTemplateId => {
  if (typeof id !== 'string') {
    return false;
  }

  return Object.values(MessagePrefillTemplateId).includes(
    id as MessagePrefillTemplateId
  );
};

const formatDateInLocalTimezone = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

const useGetPatientUnreadinessReminderMessage = async (
  messagePrefillParams: MessagePrefillTemplate
): Promise<string> => {
  const providerEventId = messagePrefillParams.data.providerEventId;
  const providerEvent = await ProviderEventApi.getEventById(providerEventId);
  if (!providerEvent.startDate) {
    throw new Error('ProviderEvent has no startDate property');
  }

  const patientId = providerEvent.patientUserId;

  if (!patientId) {
    throw new Error('ProviderEvent has no patientUserId');
  }

  const user = await UserApi.getUser(patientId);
  const formattedDate = formatDateInLocalTimezone(providerEvent.startDate);

  return `Hi ${
    user.displayFirstName ?? 'there'
  }. We're scheduled to meet on ${formattedDate} – I'm looking forward to it! Before our first session, please be sure to (1) complete your Headway account setup and (2) let me know what you'd like to cover in our first session. If you are unable to complete these items by the time of our appointment, we'll need to reschedule. Feel free to reach out with any questions. Thanks!`;
};

const useGetExternalPlatformPatientUnreadinessReminderMessage = async (
  messagePrefillParams: MessagePrefillTemplate
): Promise<string> => {
  const providerEventId = messagePrefillParams.data.providerEventId;
  const providerEvent = await ProviderEventApi.getEventById(providerEventId);
  if (!providerEvent.startDate) {
    throw new Error('ProviderEvent has no startDate property');
  }
  const eventChannel: ProviderEventChannel = providerEvent.channel;
  const externalPlatformChannel =
    getExternalPlatformChannelForEventChannel(eventChannel)!;

  const patientId = providerEvent.patientUserId;

  if (!patientId) {
    throw new Error('ProviderEvent has no patientUserId');
  }

  const user = await UserApi.getUser(patientId);
  const formattedDate = formatDateInLocalTimezone(providerEvent.startDate);

  const externalPlatformName = getExternalPlatformDisplayName(
    externalPlatformChannel
  );

  return `Hi ${
    user.displayFirstName ?? 'there'
  }. I see you recently booked a time with me via ${externalPlatformName} on ${formattedDate} – I'm looking forward to it! Before our first session, please be sure to (1) complete your Headway account setup and (2) let me know what you'd like to cover in our first session. If you are unable to complete these items by the time of our appointment, we'll need to reschedule. Feel free to reach out with any questions. Thanks!`;
};

const getMessageBuilder = (
  messagePrefillParams: MessagePrefillTemplate
):
  | ((messagePrefillParams: MessagePrefillTemplate) => Promise<string>)
  | undefined => {
  if (
    messagePrefillParams.id ===
      MessagePrefillTemplateId.PATIENT_UNREADINESS_REMINDER &&
    isPatientUnreadinessReminderTemplate(messagePrefillParams)
  ) {
    return useGetPatientUnreadinessReminderMessage;
  }
  if (
    messagePrefillParams.id ===
      MessagePrefillTemplateId.EXTERNAL_PLATFORM_PATIENT_UNREADINESS_REMINDER &&
    isExternalPlatformPatientUnreadinessReminderTemplate(messagePrefillParams)
  ) {
    return useGetExternalPlatformPatientUnreadinessReminderMessage;
  }
  return;
};

class UnknownTemplateIdError extends Error {
  constructor(
    public message: string,
    public templateId: unknown
  ) {
    super(message);
    this.name = 'UnknownTemplateIdError';
    this.templateId = templateId;
  }
}

class NoMessageBuilderConfiguredError extends Error {
  constructor(
    public message: string,
    public templateId: MessagePrefillTemplateId
  ) {
    super(message);
    this.name = 'NoMessageComposerConfiguredError';
    this.templateId = templateId;
  }
}

export const getMessagePrefill = async (
  messagePrefillParams: MessagePrefillTemplate
): Promise<string> => {
  if (!isKnownTemplateId(messagePrefillParams.id)) {
    throw new UnknownTemplateIdError(
      'Unknown message prefill template ID',
      messagePrefillParams.id
    );
  }

  const messageBuilder = getMessageBuilder(messagePrefillParams);

  if (!messageBuilder) {
    throw new NoMessageBuilderConfiguredError(
      'Message composer for prefill template ID not implemented',
      messagePrefillParams.id
    );
  }

  return await messageBuilder(messagePrefillParams);
};

export const generatePatientUnreadinessReminderMessagePrefillParameter = (
  providerEventId: any,
  messagePrefillTemplateId: MessagePrefillTemplateId
): string => {
  const parameter = {
    id: messagePrefillTemplateId,
    data: {
      providerEventId: providerEventId,
    },
  };
  // TODO(michael) url encode this
  const asJsonString = JSON.stringify(parameter);
  const encoded = encodeURIComponent(asJsonString);
  return `messagePrefill=${encoded}`;
};
