import { ProviderRead } from '@headway/api/models/ProviderRead';

export const HIGHLIGHT_COUNT_BOUNDS = {
  min: 1,
  max: 2,
};

// remove any empty elements from the incoming array of highlights
// and then add `n` empty string elements to the array, where
// `n` is equal to `requiredNumberOfHighlights - currentNumberOfHighlights`
export const formatHighlightsFromRead = (
  providerHighlights: ProviderRead['highlights'] = []
): { highlight: string }[] => {
  const formattedHighlights =
    providerHighlights
      ?.map((h) => h.trim())
      .filter(Boolean)
      .map((h) => ({ highlight: h })) || [];

  while (formattedHighlights.length < HIGHLIGHT_COUNT_BOUNDS.min) {
    formattedHighlights.push({ highlight: '' });
  }

  return formattedHighlights;
};

// to deal with some formik fieldarray error weirdness, the `highlights`
// are stored in the form state as `{ highlights: { highlight: string }[] }`.
// however, the API expects to receive highlights as `{ highlights: string[] }`.
// this function handles that formatting and removing of blank values
export const formatHighlightsForSubmission = (
  providerHighlights: { highlight: string }[]
): string[] =>
  providerHighlights.map((h) => h.highlight.trim()).filter(Boolean);
