import React, { useEffect, useState } from 'react';

import { LicenseAndVerification } from '@headway/api/models/LicenseAndVerification';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { theme } from '@headway/helix/theme';
import {
  DEA_INTAKE_FORM_UPDATES,
  LICENSE_UPLOAD_DISABLED,
  MULTI_STATE_CREDENTIALING_BETA,
  MULTI_STATE_CREDENTIALING_ONBOARDING,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { notifyWarning } from '@headway/ui/utils/notify';

import {
  getLicenseStepState,
  getMalpracticeStepState,
} from 'utils/questionnaireVerificationHelpers';

import { IntakeNavigationItem } from './IntakeNavigationItem';
import { QuestionnaireV2ContextType } from './QuestionnaireV2Context';
import { useQuestionnaireContext } from './QuestionnaireV2Context';
import { QuestionnaireV2Step } from './QuestionnaireV2Step';
import {
  FinalCaqhErrorsStepConfig,
  InitialCaqhErrorsStepConfig,
} from './steps/CaqhErrorsStep/CaqhErrorsStep';
import LicenseConfig from './steps/LicenseStep/LicenseStep';
import MalpracticeConfig from './steps/Malpractice/Malpractice';
import { getLatestProviderQuestionnaireCaqhProfile } from './utils/getLatestProviderQuestionnaireCaqhProfile';

export const getStepIndexByConfig = (
  steps: QuestionnaireV2Step[],
  targetConfig: QuestionnaireV2Step
) => {
  return steps.indexOf(targetConfig);
};

export enum StepState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
}

const UnableToVerifyMessage = ({
  step,
  setCurrentStepNum,
  index,
}: {
  step: QuestionnaireV2Step;
  setCurrentStepNum: (stepNum: number) => void;
  index: number;
}) => (
  <div css={{ ...theme.stack.vertical }}>
    <div css={{ maxWidth: '250px', marginBottom: theme.spacing.x4 }}>
      We were not able to verify information provided in the {step.title} step.
      Please try again in the{' '}
      <span
        css={{
          textDecoration: 'underline',
          color: theme.color.system.green,
          cursor: 'pointer',
        }}
        onClick={() => {
          setCurrentStepNum(index);
        }}
      >
        {step.title} section.
      </span>
    </div>
  </div>
);

const checkForNewInvalidVerificationsAndNotify = (
  stepState: StepState,
  selectedLicensesAndVerifications: LicenseAndVerification[],
  step: QuestionnaireV2Step,
  index: number,
  setCurrentStepNum: (stepNum: number) => void
) => {
  if (stepState !== StepState.INVALID || !selectedLicensesAndVerifications) {
    return;
  }

  const localStorageKey = `invalidPqvsLICENSE`;
  const localStoragePqvs = localStorage.getItem(localStorageKey);
  if (localStoragePqvs !== JSON.stringify(selectedLicensesAndVerifications)) {
    notifyWarning(
      <UnableToVerifyMessage
        step={step}
        index={index}
        setCurrentStepNum={setCurrentStepNum}
      />
    );
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(selectedLicensesAndVerifications)
    );
  }
};

export const validateSteps = async (
  context: QuestionnaireV2ContextType,
  pqvs: ProviderQuestionnaireVerificationRead[],
  selectedLicensesAndVerifications: LicenseAndVerification[],
  setCurrentStepNum: (stepNum: number) => void,
  steps: QuestionnaireV2Step[],
  isMSCBetaEnabled: boolean = false,
  isLicenseUploadDisabled: boolean = false,
  isDEAIntakeUpdatesEnabled: boolean = false
) => {
  const {
    shouldUseVerifiable,
    shouldValidateCoiUploads,
    providerQuestionnaire,
  } = context;

  const validatedSteps = await Promise.all(
    steps.map(async (step, index) => {
      const { validationSchema } = step.getFormMeta(context, {
        [MULTI_STATE_CREDENTIALING_BETA]: isMSCBetaEnabled,
        [LICENSE_UPLOAD_DISABLED]: isLicenseUploadDisabled,
        [DEA_INTAKE_FORM_UPDATES]: isDEAIntakeUpdatesEnabled,
      });

      if (!validationSchema) {
        return {
          step,
          stepState: StepState.VALID,
        };
      }

      if (
        [InitialCaqhErrorsStepConfig, FinalCaqhErrorsStepConfig].includes(step)
      ) {
        const latestProviderQuestionnaireCaqhProfile =
          getLatestProviderQuestionnaireCaqhProfile(providerQuestionnaire);
        if (latestProviderQuestionnaireCaqhProfile?.errors?.length) {
          return {
            step,
            stepState: StepState.INVALID,
          };
        } else {
          return {
            step,
            stepState: StepState.VALID,
          };
        }
      }

      try {
        await validationSchema.validate(providerQuestionnaire.rawData);
      } catch (err) {
        return {
          step,
          stepState: StepState.INVALID,
        };
      }

      if (step === LicenseConfig && shouldUseVerifiable) {
        const stepState = getLicenseStepState(selectedLicensesAndVerifications);
        checkForNewInvalidVerificationsAndNotify(
          stepState,
          selectedLicensesAndVerifications,
          step,
          index,
          setCurrentStepNum
        );
        return {
          step,
          stepState: stepState,
        };
      }

      if (step === MalpracticeConfig && shouldValidateCoiUploads) {
        return {
          step,
          stepState: getMalpracticeStepState(
            pqvs,
            providerQuestionnaire?.rawData || {}
          ),
        };
      }

      return {
        step,
        stepState: StepState.VALID,
      };
    })
  );
  return validatedSteps;
};

type IntakeNavigationProps = {
  steps: QuestionnaireV2Step[];
  currentStepNum: number;
  setCurrentStepNum: (newStepNum: number) => void;
  validatedSteps: {
    step: QuestionnaireV2Step;
    stepState: StepState;
  }[];
  doResetQuestionnaire: boolean;
  setDoResetQuestionnaire: (value: boolean) => void;
};

export const IntakeNavigation = ({
  steps,
  currentStepNum,
  setCurrentStepNum,
  validatedSteps,
  doResetQuestionnaire,
  setDoResetQuestionnaire,
}: IntakeNavigationProps) => {
  const [latestStepNum, setLatestStepNum] = useState(currentStepNum);
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const isMSCOnboardingEnabled = useFlag(MULTI_STATE_CREDENTIALING_ONBOARDING);
  const isDEAIntakeUpdatesEnabled = useFlag(DEA_INTAKE_FORM_UPDATES, false);
  const isOnWelcomeStep = isDEAIntakeUpdatesEnabled
    ? currentStepNum === 0
    : currentStepNum === -1;
  const { provider } = useQuestionnaireContext();

  useEffect(() => {
    if (currentStepNum > latestStepNum) {
      setLatestStepNum(currentStepNum);
    }
    if (doResetQuestionnaire) {
      setDoResetQuestionnaire(false);
      setCurrentStepNum(Math.min(currentStepNum, 0));
    }
  }, [doResetQuestionnaire, currentStepNum, latestStepNum]);

  const welcomeStepTitle =
    isDEAIntakeUpdatesEnabled || isMSCBetaEnabled || isMSCOnboardingEnabled
      ? 'Welcome ' + provider.displayFirstName
      : 'Introduction';

  if (isDEAIntakeUpdatesEnabled && isOnWelcomeStep) return null;

  return (
    <nav
      css={{
        borderRight: `1px solid ${theme.color.system.borderGray}`,
        maxWidth: '350px',
        flex: '0 1 auto',
        '@media (max-width: 820px)': {
          display: 'none',
        },
      }}
    >
      <div
        css={{
          position: 'sticky',
          top: '50px',
        }}
      >
        {!isDEAIntakeUpdatesEnabled && (
          <IntakeNavigationItem
            text={
              isMSCBetaEnabled || isMSCOnboardingEnabled
                ? 'Welcome ' + provider.displayFirstName
                : 'Introduction'
            }
            active={currentStepNum === -1}
            onPress={() => {
              setCurrentStepNum(-1);
            }}
          />
        )}
        {!!validatedSteps &&
          steps.map((s, idx) => (
            <IntakeNavigationItem
              key={idx}
              text={s.title ?? welcomeStepTitle}
              stepState={validatedSteps[idx]?.stepState}
              showState={idx !== latestStepNum}
              active={currentStepNum === idx}
              disabled={latestStepNum < idx}
              onPress={() => {
                setCurrentStepNum(idx);
              }}
            />
          ))}
      </div>
    </nav>
  );
};
