import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { getProviderLiveProviderLicenseStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

export const getProviderLicenseStatesWhereCanUpdateShownInSearch = (
  provider: ProviderRead
): ProviderLicenseStateRead[] =>
  getProviderLiveProviderLicenseStates(provider).filter(
    (pls) => pls.canUpdateShownInSearch
  );

export const getShownInSearch = (
  provider: ProviderRead,
  primaryProviderLicenseState: ProviderLicenseStateRead,
  isMscSearchEnabled: boolean
): boolean =>
  isMscSearchEnabled
    ? getProviderLicenseStatesWhereCanUpdateShownInSearch(provider).some(
        (pls) => pls.shownInSearch
      )
    : primaryProviderLicenseState.shownInSearch || false;

export const getCanUpdateShownInSearch = (
  provider: ProviderRead,
  primaryProviderLicenseState: ProviderLicenseStateRead,
  isMscSearchEnabled: boolean
): boolean =>
  isMscSearchEnabled
    ? getProviderLicenseStatesWhereCanUpdateShownInSearch(provider).length > 0
    : primaryProviderLicenseState.canUpdateShownInSearch || false;
