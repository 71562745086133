import { keyframes } from '@emotion/react';

import { theme } from '@headway/helix/theme';
import { Loading } from '@headway/icons/dist/helix/Loading';

export const CoiLoading = () => {
  return (
    <div className="inline-flex">
      <div
        css={{
          display: 'grid',
          placeItems: 'left',
          color: theme.color.system.textBlack,
          '& > *': {
            animation: `${rotate} 2s infinite linear`,
          },
        }}
      >
        <Loading />
      </div>
      &nbsp;
      {'Verifying your document'}
    </div>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
