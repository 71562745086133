import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseUpcomingPaymentsForProviderQueryKeyArgs {
  providerId: number;
}

export const getUpcomingPaymentsForProviderQueryKey = ({
  providerId,
}: UseUpcomingPaymentsForProviderQueryKeyArgs): [string, number] => [
  'get-upcoming-payments-for-provider',
  providerId,
];

const {
  useSingleQuery: useGetUpcomingPaymentsForProvider,
  useListQuery: useGetUpcomingPaymentsForProviderList,
  useCachedQuery: useGetUpcomingPaymentsForProviderCache,
} = createBasicApiHooks(
  getUpcomingPaymentsForProviderQueryKey,
  ({ providerId }) => {
    return ProviderApi.getUpcomingPaymentsForProvider(providerId);
  },
  ({ providerId }) => !!providerId,
  () => 'Failed to fetch provider upcoming payments'
);

export {
  useGetUpcomingPaymentsForProvider,
  useGetUpcomingPaymentsForProviderList,
  useGetUpcomingPaymentsForProviderCache,
};
