import { useField } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';

import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { SchemaComponent } from '.';

const MemoizedCheckbox = React.memo(Checkbox);
const MemoizedCheckboxGroup = React.memo(CheckboxGroup);

interface OtherWithTextFieldProps {
  disabled: boolean;
  option: string;
  id: string;
}

const OtherWithTextField = ({
  disabled,
  option,
  id,
}: OtherWithTextFieldProps) => {
  const [field, _, { setValue }] = useField(id);
  const [splitValue, setSplitValue] = useState('');

  const isOtherSelected =
    option === 'Other' &&
    field.value !== undefined &&
    Array.isArray(field.value) &&
    field.value.includes('Other');

  const otherPrefix = 'Other-';

  const createOtherValue = (value: string) => {
    return `${otherPrefix}${value}`;
  };

  // Takes out the ["Other-[Othervalue]"] from field.values
  const removeOtherTextFromValues = (value: string[] = []) => {
    if (!Array.isArray(value)) return [value];

    const otherTextIndex = value.findIndex((value: string) =>
      value.startsWith(otherPrefix)
    );
    if (otherTextIndex !== -1) {
      const newValues = [...value];
      newValues.splice(otherTextIndex, 1);
      return newValues;
    } else {
      return [...value];
    }
  };

  // When our input updates - remove existing other value and set our new one
  const onOtherTextChange = (newOtherText: string) => {
    if (!field.value) {
      return;
    }

    const trimmedValues = removeOtherTextFromValues(field.value);

    trimmedValues.push(newOtherText);
    setValue(trimmedValues);
    const splitValues = newOtherText.split(/-(.*)/);
    if (splitValues && splitValues.length > 1) {
      setSplitValue(splitValues[1]);
    } else {
      setSplitValue('');
    }
  };

  useEffect(() => {
    // Set the value of the text field if it is selected
    if (isOtherSelected) {
      const splitValues = field.value
        .find((value: string) => value.includes(otherPrefix))
        ?.split(/-(.*)/);
      if (splitValues && splitValues.length > 1) {
        setSplitValue(splitValues[1]);
      } else {
        // If other value is not in selected values, add an empty other value
        onOtherTextChange(createOtherValue(''));
      }
    }

    // Remove the other value if it is not selected
    if (!isOtherSelected && !!field.value) {
      setValue(removeOtherTextFromValues(field.value));
      setSplitValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOtherSelected]);

  return (
    <div css={{ display: 'flex', gap: theme.spacing.x2, alignItems: 'center' }}>
      <MemoizedCheckbox value={option} disabled={disabled}>
        {option}
      </MemoizedCheckbox>
      {isOtherSelected && (
        <TextField
          disabled={disabled}
          name="Other"
          value={splitValue}
          onChange={(value) => {
            onOtherTextChange(createOtherValue(value));
          }}
        />
      )}
    </div>
  );
};

/**
 * This component is heavily memoized in order to prevent unnecessary rerenders of the child
 * checkbox components on large forms. If making changes to this component, you should validate that
 * there are no performance regressions.
 */
export const Checklist = ({
  element,
  isOptional = false,
  disabled = false,
}: SchemaComponent) => {
  const { id, title, options } = element;

  let newOptions: string[] = [...options];

  const optionsColumn1 = newOptions.slice(0, Math.ceil(newOptions.length / 2));
  const optionsColumn2 = newOptions.slice(
    Math.ceil(newOptions.length / 2),
    newOptions.length
  );

  const column1 = useMemo(
    () =>
      optionsColumn1.map((option: string, i: number) =>
        option === 'Other' ? (
          <OtherWithTextField
            key={i}
            option={option}
            disabled={disabled}
            id={id}
          />
        ) : (
          <MemoizedCheckbox key={i} value={option} disabled={disabled}>
            {option}
          </MemoizedCheckbox>
        )
      ),
    [optionsColumn1, disabled, id]
  );

  const column2 = useMemo(
    () =>
      optionsColumn2.map((option: string, i: number) =>
        option === 'Other' ? (
          <OtherWithTextField
            key={i}
            option={option}
            disabled={disabled}
            id={id}
          />
        ) : (
          <MemoizedCheckbox key={i} value={option} disabled={disabled}>
            {option}
          </MemoizedCheckbox>
        )
      ),
    [optionsColumn2, disabled, id]
  );

  return (
    <FormControl
      component={MemoizedCheckboxGroup}
      name={id}
      label={title}
      optionalityText={isOptional ? 'Optional' : null}
      disabled={disabled}
    >
      <div
        css={{
          all: 'inherit',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <div css={{ all: 'inherit', display: 'flex', flexDirection: 'column' }}>
          {column1}
        </div>
        <div css={{ all: 'inherit', display: 'flex', flexDirection: 'column' }}>
          {column2}
        </div>
      </div>
    </FormControl>
  );
};
