/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum DegreeTypes {
    MD = 'MD (Medical Degree)',
    DO = 'DO (Doctor of Osteopathy)',
    PHD = 'PhD (Doctor of Philosophy)',
    PSYD = 'PsyD (Doctor of Psychology)',
    DNP = 'DNP (Doctor of Nursing Practice)',
    DNS = 'DNS (Doctor of Nursing Science)',
    EDD = 'EdD (Doctor of Education)',
    OTHER_DOCTORATE = 'Other - Doctorate',
    PMC = 'PMC (Post-Master’s Certificate)',
    EDS = 'EdS (Educational Specialist)',
    MSN = 'MSN (Master of Science in Nursing)',
    MC = 'MC (Masters in Counseling)',
    MSED = 'MSEd (Master of Science in Education)',
    MED = 'MEd (Master of Education)',
    MPS = 'MPS (Masters in Professional Studies)',
    MSW = 'MSW (Master of Social Work)',
    MSSW = 'MSSW (Master of Science in Social Work)',
    MS = 'MS (Master of Science)',
    MA = 'MA (Master of Arts)',
    OTHER_MASTERS = 'Other - Masters',
    BN = 'BA in Nursing (Bachelor of Arts in Nursing)',
    BA = 'BA (Bachelor of Arts)',
    OTHER = 'Other'
}
