import { FormControl } from '@headway/helix/FormControl';
import { TextField } from '@headway/helix/TextField';

import { SchemaComponent } from '.';
import { getInstructionalText } from '../../utils';

export const FormTextField = ({
  element,
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { id, title, placeholder, subTitle, optionalityText } = element;

  return (
    <FormControl
      component={TextField}
      label={title}
      name={id}
      key={id}
      optionalityText={
        isOptional ? (optionalityText ? optionalityText : 'Optional') : ''
      }
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={getInstructionalText(subTitle, { requiresEdit })}
    />
  );
};
