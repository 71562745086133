import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { LinkButton } from '@headway/helix/LinkButton';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';

import { CHICAGO_MANUAL_DATE_FORMAT } from 'constants/date';
import {
  getIndividualW9Deadline,
  getRemainingDaysToIndividualW9Deadline,
} from 'utils/billing';

interface W9ModalProps {
  providerState: UnitedStates;
  stripeOnboardingLink: string;
  closeW9Modal: () => {};
}

const getModalContent = (
  isInitialRender: boolean,
  deadline: string,
  numberOfDaysToDeadline: number
) => {
  const deadlineHasPassed = numberOfDaysToDeadline < 0;
  const daysToDeadlineReadable = `${numberOfDaysToDeadline} day${
    numberOfDaysToDeadline > 1 ? 's' : ''
  }`;

  if (!deadlineHasPassed && isInitialRender) {
    return {
      title: 'Complete your W-9 form',
      content: () => (
        <p>
          We need you to complete your W-9 form to prepare your 1099 for the
          2023 tax year. This will only take 5 minutes or less of your time.
        </p>
      ),
    };
  } else if (!deadlineHasPassed && !isInitialRender) {
    return {
      title: `Complete your W-9 form: ${numberOfDaysToDeadline} days left`,
      content: () => (
        <div>
          <div css={{ marginBottom: theme.spacing.x3 }}>
            <GuidanceCard variant="warning">
              You have {daysToDeadlineReadable} to complete your W-9 form. After{' '}
              {daysToDeadlineReadable} on {deadline}, you will not be able to
              confirm sessions.
            </GuidanceCard>
          </div>
          <p>
            We need you to complete your W-9 form to prepare your 1099 for the
            2023 tax year. This will only take 5 minutes or less of your time.
          </p>
          <p>
            <b>
              If your practice’s billing info is not updated by {deadline}, you
              will not be able to confirm sessions.
            </b>
          </p>
        </div>
      ),
    };
  } else {
    return {
      title: 'Complete your W-9 form to confirm sessions.',
      content: () => (
        <div>
          <div css={{ marginBottom: theme.spacing.x3 }}>
            <GuidanceCard variant="warning">
              You did not complete your W-9 form by {deadline}.
            </GuidanceCard>
          </div>
          <p>
            You will not be able to confirm sessions until you complete your W-9
            form.
          </p>
        </div>
      ),
    };
  }
};

export const W9Modal = ({
  providerState,
  stripeOnboardingLink,
  closeW9Modal,
}: W9ModalProps) => {
  const deadline = getIndividualW9Deadline(providerState).format(
    CHICAGO_MANUAL_DATE_FORMAT
  );
  const numberOfDaysToDeadline =
    getRemainingDaysToIndividualW9Deadline(providerState);

  const [open, setOpen] = useState(false);

  const [modalContent, setModalContent] = useState({
    title: '',
    content: () => <></>,
  });

  const [individualW9ModalLastRenderedOn, setIndividualW9ModalLastRenderedOn] =
    useLocalStorage('individualW9ModalLastRenderedOn');

  useEffect(() => {
    if (!open) {
      const isInitialRender = individualW9ModalLastRenderedOn === null;
      setModalContent(
        getModalContent(isInitialRender, deadline, numberOfDaysToDeadline)
      );
      const isTwentyFourHoursSinceLastRender =
        !!individualW9ModalLastRenderedOn &&
        moment().isAfter(
          moment(individualW9ModalLastRenderedOn).add(24, 'hours')
        );
      const isCorrectTimeToRender =
        isInitialRender || isTwentyFourHoursSinceLastRender;

      if (isCorrectTimeToRender) {
        setIndividualW9ModalLastRenderedOn(moment().toISOString());
        setOpen(true);
      } else {
        closeW9Modal();
        setOpen(false);
      }
    }
  }, [
    deadline,
    numberOfDaysToDeadline,
    open,
    individualW9ModalLastRenderedOn,
    closeW9Modal,
    setIndividualW9ModalLastRenderedOn,
  ]);

  return (
    <Modal isOpen={open} onDismiss={closeW9Modal} title={modalContent?.title}>
      <ModalContent>{modalContent?.content()}</ModalContent>
      <ModalFooter>
        {numberOfDaysToDeadline > 0 && (
          <Button variant="secondary" onPress={closeW9Modal}>
            Remind me later
          </Button>
        )}
        <LinkButton href={stripeOnboardingLink} variant="primary" size="medium">
          Complete on Stripe↗
        </LinkButton>
      </ModalFooter>
    </Modal>
  );
};
