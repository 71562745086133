import React from 'react';
import { useDateSegment } from 'react-aria';
import { DateFieldState, DateSegment } from 'react-stately';

interface LiteralDateTimeSegmentProps {
  segment: DateSegment;
}

export function LiteralDateTimeSegment({
  segment,
}: LiteralDateTimeSegmentProps) {
  return (
    <span
      aria-hidden="true"
      className="hlx-date-segment hlx-date-segment-literal"
    >
      {segment.text}
    </span>
  );
}

interface EditableDateTimeSegmentProps {
  segment: DateSegment;
  state: DateFieldState;
}
export function EditableDateTimeSegment({
  segment,
  state,
}: EditableDateTimeSegmentProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className="hlx-date-segment hlx-date-segment-editable"
      style={
        {
          ...segmentProps.style,
          '--chars': String(segment.text).length + 'ch',
          '--max-chars': String(segment.maxValue).length + 'ch',
        } as React.CSSProperties
      }
      data-placeholder={segment.isPlaceholder || undefined}
      data-readonly={!segment.isEditable}
    >
      <span aria-hidden="true" className="hlx-date-segment-placeholder">
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
}
