import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { BillingType } from '@headway/api/models/BillingType';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { ProviderFrontEndCarrierNested } from '@headway/api/models/ProviderFrontEndCarrierNested';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { Link } from '@headway/helix/Link';
import { ListRow } from '@headway/helix/List';
import { ListHeader } from '@headway/helix/ListHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { formatPatientName } from '@headway/shared/utils/patient';

import { ClientAvatar } from './ClientAvatar';
import { ClientVerificationChip } from './ClientVerificationChip';
import {
  clientNeedsInviteEmail,
  ClientWelcomeEmail,
} from './ClientWelcomeEmail';

interface ClientListRowProps {
  client: UserRead;
  claimReadiness?: UserClaimReadinessResponse;
  billingType: BillingType;
  isArchived?: boolean;
  providerFrontEndCarriers: Array<ProviderFrontEndCarrierNested>;
  handleToggleArchiveClick: () => void;
  carriersById: { [index: string]: FrontEndCarrierRead };
  freezeReasonsByUser: { [index: string]: UserFreezeReason[] };
  page: number;
  provider: ProviderRead;
  search?: string;
}

export const ClientListRow = ({
  client,
  claimReadiness,
  billingType,
  isArchived,
  providerFrontEndCarriers,
  handleToggleArchiveClick,
  carriersById,
  freezeReasonsByUser,
  page,
  provider,
  search,
}: ClientListRowProps) => {
  const fullName = formatPatientName(client, {
    appendLegalName: true,
  });

  return (
    <ListRow>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            display: 'flex',
          }}
        >
          <ClientAvatar client={client} size="medium" />
          <div css={{ marginLeft: theme.spacing.x4, ...theme.stack.vertical }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
              }}
            >
              <div css={{ marginRight: theme.spacing.x2 }}>
                <Link
                  to={`/clients/${client.id}`}
                  component={RouterLink}
                  elementType="a"
                  data-testid={`client-${client.id}-link`}
                >
                  <ListHeader>{fullName}</ListHeader>
                </Link>
              </div>
              <ClientVerificationChip
                claimReadiness={claimReadiness}
                client={client}
                billingType={billingType}
                freezeReasonsByUser={freezeReasonsByUser}
                provider={provider}
              />
            </div>

            <SubBodyText>
              {`${client.email}${client.phone ? ` · ${client.phone}` : ''}`}
            </SubBodyText>
          </div>
        </div>
        <Button variant="secondary" onPress={handleToggleArchiveClick}>
          {isArchived ? 'Unarchive' : 'Archive'}
        </Button>
      </div>
      {clientNeedsInviteEmail(client, billingType, claimReadiness) ? (
        <div css={{ marginTop: theme.spacing.x3 }}>
          <ClientWelcomeEmail
            client={client}
            isArchivedPatient={isArchived}
            page={page}
            search={search}
          />
        </div>
      ) : null}
    </ListRow>
  );
};
