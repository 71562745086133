/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { LicenseAndVerification } from '../models/LicenseAndVerification';
import { ProviderCredentialRead } from '../models/ProviderCredentialRead';
import { ProviderCredentialUpdate } from '../models/ProviderCredentialUpdate';
import { VerifyLicenseRequest } from '../models/VerifyLicenseRequest';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderCredentialApiResource {

    /**
     * Add License And Trigger Verification
     * 
     * @param providerId 
     * @param verifyLicenseRequest 
     
     */
    public addLicenseAndTriggerVerification(providerId: number, body: VerifyLicenseRequest, axiosConfig?: AxiosRequestConfig): Promise<LicenseAndVerification> {
        const apiRoute = '/providers/{provider_id}/provider-credentials';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Licenses
     * 
     * @param providerId 
     
     */
    public getProviderLicenses(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<LicenseAndVerification>> {
        const apiRoute = '/providers/{provider_id}/provider-credentials';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Credential
     * 
     * @param providerCredentialId 
     * @param providerCredentialUpdate 
     
     */
    public updateProviderCredential(providerCredentialId: number, body: ProviderCredentialUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderCredentialRead> {
        const apiRoute = '/admin/provider-credentials/{provider_credential_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_credential_id}', String(providerCredentialId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderCredentialApi = new ProviderCredentialApiResource();
