import { Alert, Skeleton } from '@mui/material';
import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { notifyError } from '@headway/ui/utils/notify';

import { PatientInsurancePropertyList } from './PatientInsurancePropertyList';

interface PatientInsuranceDetailProps {
  patient: UserRead;
  onUpdateInsurance: () => void;
  onComplete: () => void;
}

interface PatientInsuranceDetailState {
  userInsurance?: any;
  loaded: boolean;
}

export class PatientInsuranceReview extends React.Component<
  PatientInsuranceDetailProps,
  PatientInsuranceDetailState
> {
  state = {
    userInsurance: undefined,
    loaded: false,
  } as PatientInsuranceDetailState;

  async componentDidMount() {
    try {
      if (this.props.patient.activeUserInsuranceId) {
        const userInsurance = await UserInsuranceApi.getUserInsurance(
          this.props.patient.activeUserInsuranceId
        );
        this.setState({ userInsurance, loaded: true });
      }
    } catch (err) {
      this.setState({ loaded: true });
      notifyError('Failed to load patient insurance details.');
      logException(err);
    }
  }

  render() {
    const { userInsurance } = this.state;
    return (
      <div>
        <Alert color="success">
          {formatPatientName(this.props.patient, {
            firstNameOnly: true,
          })}{' '}
          has been added to your practice! We already have their insurance on
          file, but feel free to review/make any changes necessary.
        </Alert>
        {this.state.loaded ? (
          <div
            css={{
              borderRadius: 6,
              border: `1px solid ${theme.color.system.borderGray}`,
              padding: theme.spacing.x4,
              marginTop: theme.spacing.x4,
              marginBottom: theme.spacing.x4,
            }}
          >
            <PatientInsurancePropertyList userInsurance={userInsurance} />
          </div>
        ) : (
          <Skeleton
            css={{
              marginTop: theme.spacing.x4,
              marginBottom: theme.spacing.x4,
            }}
            variant="rectangular"
            height={70}
          />
        )}
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: theme.spacing.x2,
          }}
        >
          <Button
            variant="secondary"
            onPress={() => {
              this.props.onUpdateInsurance();
            }}
          >
            Update
          </Button>
          <Button
            variant="primary"
            onPress={() => {
              this.props.onComplete();
            }}
          >
            Complete
          </Button>
        </div>
      </div>
    );
  }
}
