import React from 'react';

import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { SchemaComponent } from '../../schema/schema.types';

export const InfoGuidanceCard = ({
  element,
  disabled = false,
}: SchemaComponent) => {
  const { htmlContent } = element;

  if (disabled) {
    /* 
        Don't show the info guidance card component if it's disabled 
        (which means the form has been signed or is in review)
        */
    return null;
  }

  return (
    <GuidanceCard variant="neutral">
      {htmlContent && (
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(htmlContent) }}
          css={{
            ul: {
              margin: 0,
            },
          }}
        ></div>
      )}
    </GuidanceCard>
  );
};
