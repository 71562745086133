import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';

import {
  BlockDefinitions,
  ComponentMap,
  ComponentMapDeclaration,
  FormBuilderEnvironment,
  FormFile,
  OnSaveData,
} from '../schema/schema.types';
import { blockDefinitions } from './blocks/BlockDefinitions';
import { BuilderCore } from './components/BuilderCore';
import { EditPanel } from './components/BuilderEditPanel';
import { BuilderMenu } from './components/BuilderMenu';
import { BuilderMenuInjectedData } from './components/BuilderMenuInjectedData';
import { BuilderMenuPreview } from './components/BuilderMenuPreview';
import { BuilderMenuResetModal } from './components/BuilderMenuResetModal';
import { RendererContext } from './RendererContext';

type EditPanelView = 'layoutTree' | 'formState';

export interface BuilderState {
  showEditPanel: boolean;
  editPanelView?: EditPanelView;
  showDeleteModal?: boolean;
  showPreview?: boolean;
  showInjectedData?: boolean;
}

export type ComponentEditPanelOptions =
  | 'Content'
  | 'Validation'
  | 'Conditions'
  | 'Configuration';

export interface BuilderEmbeddedProps {
  form: FormFile;
  data: object;

  onSave: (onSaveData: OnSaveData) => void;
  onSaveCTAString?: string;

  preview?: (form: FormFile, injectedData: object) => React.ReactNode;
  componentDefinitions?: BlockDefinitions<keyof ComponentMapDeclaration>;
  componentMap?: ComponentMapDeclaration;

  environment: FormBuilderEnvironment;
  componentEditPanelOptions?: ComponentEditPanelOptions[];
}

export const BuilderEmbedded = ({
  form,
  onSave,
  onSaveCTAString = 'Save',
  data,
  environment = 'production',
  preview,
  componentDefinitions,
  componentMap,
  componentEditPanelOptions,
}: BuilderEmbeddedProps) => {
  // Gets set after editorjs mounts the editor to a div element
  const [editor, setEditor] = useState<EditorJS.default | undefined>(undefined);
  const [injectedData, setInjectedData] = useState(data);

  // Block that is currently being edited -> has the blue border around it
  const [currentEditingBlock, setCurrentEditingBlock] = useState<
    React.ReactNode | undefined
  >(undefined);

  const [builderState, setBuilderState] = useState<BuilderState>({
    showEditPanel: true,
    showDeleteModal: false,
  });

  useEffect(() => {
    if (componentEditPanelOptions) {
      RendererContext.componentEditPanelOptions = componentEditPanelOptions;
    }
  }, [componentEditPanelOptions]);

  useEffect(() => {
    RendererContext.injectedData = {
      ...injectedData,
      environment,
    };
  }, [JSON.stringify(injectedData)]);

  const resetForm = useCallback(() => {
    onSave({
      form: { form: [], state: form.state },
      isValid: true,
      errors: { allErrors: [], unknownSystemErrors: [] },
    });
  }, [JSON.stringify(form.state)]);

  const blurEditor =
    builderState.showDeleteModal ||
    builderState.showPreview ||
    builderState.showInjectedData;

  return (
    <div css={editorCss.container}>
      {builderState.showDeleteModal && (
        <BuilderMenuResetModal
          builderState={builderState}
          setBuilderState={setBuilderState}
          resetForm={resetForm}
        />
      )}
      {builderState.showPreview && preview && (
        <BuilderMenuPreview preview={preview} editor={editor} />
      )}
      {builderState.showInjectedData && (
        <BuilderMenuInjectedData
          injectedData={injectedData}
          setInjectedData={setInjectedData}
          onClose={() =>
            setBuilderState({ ...builderState, showInjectedData: false })
          }
        />
      )}
      <BuilderMenu
        editor={editor}
        builderState={builderState}
        setBuilderState={setBuilderState}
        onSave={onSave}
        onSaveCTAString={onSaveCTAString}
        hasPreview={!!preview}
      />
      <div css={{ ...{ filter: blurEditor ? 'blur(2px)' : '' } }}>
        <div css={editorCss.editorContainer}>
          <div css={{ flex: 0.7, minWidth: 380 }}>
            <BuilderCore
              form={form}
              setEditor={setEditor}
              currentEditingBlock={currentEditingBlock}
              setCurrentEditingBlock={setCurrentEditingBlock}
              activeComponentDefinitions={
                componentDefinitions ?? blockDefinitions
              }
              activeComponentMap={componentMap ?? ComponentMap}
            />
          </div>
          {builderState.showEditPanel && (
            <div css={{ flex: 0.3, position: 'sticky', top: 0, right: 0 }}>
              <EditPanel
                form={form}
                currentEditingBlock={currentEditingBlock}
                builderState={builderState}
                setBuilderState={setBuilderState}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const editorCss = {
  container: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    overflowX: 'hidden',
    borderBottom: '1px solid #e8e8eb',
    borderLeft: '1px solid #e8e8eb',
    boxShadow: '0 30px 40px rgb(0 0 0 / 5%)',
  }),
  editorContainer: css({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    overflow: 'scroll',
    overflowX: 'hidden',
    height: '700px',
  }),
};
