/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { FilterType } from '../models/FilterType';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderFilterCreate } from '../models/ProviderFilterCreate';
import { ProviderFilterRead } from '../models/ProviderFilterRead';
import { ProviderFilterUpdate } from '../models/ProviderFilterUpdate';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderFilterApiResource {

    /**
     * Create Provider Filter
     * 
     * @param providerFilterCreate 
     
     */
    public createProviderFilter(body: ProviderFilterCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderFilterRead> {
        const apiRoute = '/provider-filter';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Delete Provider Filter
     * 
     * @param providerFilterId 
     
     */
    public deleteProviderFilter(providerFilterId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderFilterRead> {
        const apiRoute = '/provider-filter/{provider_filter_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_filter_id}', String(providerFilterId));
        const reqConfig = {
            ...axiosConfig,
            method: 'DELETE' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Filter
     * 
     * @param providerFilterId 
     
     */
    public getProviderFilter(providerFilterId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderFilterRead> {
        const apiRoute = '/provider-filter/{provider_filter_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_filter_id}', String(providerFilterId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Filters
     * 
     * @param providerId 
     * @param filterTypes 
     
     */
    public getProviderFilters(query?: { provider_id: number, filter_types?: Array<FilterType> }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderFilterRead>> {
        const apiRoute = '/provider-filter';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Filter
     * 
     * @param providerFilterId 
     * @param providerFilterUpdate 
     
     */
    public updateProviderFilter(providerFilterId: number, body: ProviderFilterUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderFilterRead> {
        const apiRoute = '/provider-filter/{provider_filter_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_filter_id}', String(providerFilterId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderFilterApi = new ProviderFilterApiResource();
