import { Skeleton } from '@mui/material';
import React from 'react';

import { ProviderBillingAccountRead } from '@headway/api/models/ProviderBillingAccountRead';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';

import {
  hasUnmetStripeAccountRequirements,
  hasUnmetW9StripeRequirement,
} from '../../../../utils/billing';

const LEARN_MORE_TAXES_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/11428114166932-Filing-Taxes-';

interface StripeOnboardingLinkProps {
  stripeOnboardingLink: string | undefined;
  isLoading: boolean;
}

const StripeOnboardingButtonLink: React.FC<
  React.PropsWithChildren<StripeOnboardingLinkProps>
> = ({ stripeOnboardingLink, isLoading, children }) => {
  return (
    <LinkButton
      disabled={isLoading}
      variant="secondary"
      href={stripeOnboardingLink}
    >
      {isLoading ? 'Loading' : children}
    </LinkButton>
  );
};

interface StripeOnboardingButtonsSectionProps {
  billingAccountInfo: ProviderBillingAccountRead & {
    stripeOnboardingLink: string | undefined;
    isLoadingStripeOnboardingLink: boolean;
    isGroupPracticeBillingAccount: boolean;
  };
}

export const StripeOnboardingButtonsSection: React.FC<
  React.PropsWithChildren<StripeOnboardingButtonsSectionProps>
> = ({ billingAccountInfo }) => {
  if (hasUnmetW9StripeRequirement(billingAccountInfo.stripeAccount)) {
    return (
      <W9CollectionSection
        stripeOnboardingLink={billingAccountInfo?.stripeOnboardingLink}
      />
    );
  } else if (billingAccountInfo.stripeAccount.status !== 'verified') {
    return (
      <StripeOnboardingButtonLink
        stripeOnboardingLink={billingAccountInfo?.stripeOnboardingLink}
        isLoading={billingAccountInfo?.isLoadingStripeOnboardingLink}
      >
        {billingAccountInfo?.isGroupPracticeBillingAccount
          ? 'Verify as group practice'
          : 'Verify your identity'}
      </StripeOnboardingButtonLink>
    );
  } else if (
    hasUnmetStripeAccountRequirements(billingAccountInfo.stripeAccount)
  ) {
    return (
      <StripeOnboardingButtonLink
        stripeOnboardingLink={billingAccountInfo?.stripeOnboardingLink}
        isLoading={billingAccountInfo?.isLoadingStripeOnboardingLink}
      >
        Complete verification
      </StripeOnboardingButtonLink>
    );
  }
  return null;
};

interface W9CollectionSectionProps {
  stripeOnboardingLink: string | undefined;
}

const W9CollectionSection: React.FC<
  React.PropsWithChildren<W9CollectionSectionProps>
> = ({ stripeOnboardingLink }) => {
  return (
    <div
      css={{
        backgroundColor: '#F8F8F8',
        padding: theme.spacing.x5,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.x2,
      }}
    >
      <div>
        <b>Complete your practice’s W-9 form:</b>
      </div>
      <div>
        We’ll use this information to prepare your 1099 for the 2023 tax year.
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: theme.spacing.x2,
        }}
      >
        <div>
          <LinkButton
            variant="secondary"
            size="medium"
            href={LEARN_MORE_TAXES_LINK}
          >
            Learn more
          </LinkButton>
        </div>
        <div css={{ marginLeft: theme.spacing.x2 }}>
          {!stripeOnboardingLink ? (
            <Skeleton variant="rectangular" height={40} />
          ) : (
            <LinkButton
              variant="primary"
              size="medium"
              href={stripeOnboardingLink}
            >
              Complete W-9 tax form on Stripe ↗
            </LinkButton>
          )}
        </div>
      </div>
    </div>
  );
};
