import { IroncladApi } from '@headway/api/resources/IroncladApi';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import { getIroncladSignerId } from 'components/IroncladAmendmentsModal/helpers/utils';
import { useAuthStore } from 'stores/AuthStore';

/**
 *
 * We expect the contract title to like this -
 * Headway [Therapist/Group/Physician/Nurse Practitioner] Agreement
 * Headway [stateName] State Specific Addendum
 *
 * This function will remove the Headway part
 *
 * @param title
 */
const getContractTitle = (title: string) => {
  const headwayIndex = title.toLowerCase().indexOf('headway');

  //Doesn't exist or not at the beginning of the string
  if (headwayIndex === -1 || headwayIndex !== 0) {
    return title;
  }

  return title.substring(0, 8);
};

const createIroncladDownloadUrl = (downloadEndpoint: any) =>
  `https://vault.pactsafe.io/${downloadEndpoint}`;

export type IroncladVersionData = {
  title: string;
  downloadEndpoint: string;
};

export const useIroncladVersionData = () => {
  const shouldUseIroncladAmendments = useFlag('ironcladProviderAmendmnts');
  const { user, provider } = useAuthStore();
  const signerId = getIroncladSignerId(user);

  const {
    isLoading,
    isError,
    data: ironcladVersionData,
  } = useQuery(
    ['ironcladVersionData', signerId],
    async () => {
      const res = await IroncladApi.getUserLastSignedVersionData({
        signer_id: signerId,
        provider_id: provider.id,
      });

      if (res.status === 404) {
        throw new Error(`Latest signed version data wasn't found in Ironclad`);
      }

      return res;
    },
    {
      enabled: shouldUseIroncladAmendments,
      refetchOnWindowFocus: false,
      onError: (e) => {
        logException(e);
      },
    }
  );

  return {
    isLoading,
    isError,
    ironcladVersionData: ironcladVersionData
      ? ironcladVersionData.map((data: any) => ({
          title: getContractTitle(data.title),
          downloadEndpoint: createIroncladDownloadUrl(data.download_endpoint),
        }))
      : [],
  };
};
