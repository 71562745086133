import { FormikProps } from 'formik';

import { CredentialVerificationStatus } from '@headway/api/models/CredentialVerificationStatus';
import { License } from '@headway/api/models/License';
import { LicenseAndVerification } from '@headway/api/models/LicenseAndVerification';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { StepState } from '../../IntakeNavigation';

export const getLicenseKey = (license: License) =>
  `${license.licenseState}${license.licenseNumber}`;

export const getLicenseKeys = (licenses: License[]) => {
  const licenseKeys = new Set();
  licenses.forEach((license) => {
    licenseKeys.add(getLicenseKey(license));
  });
  return licenseKeys;
};

export const getSelectedLicense = (
  state: UnitedStates,
  formikHelpers: FormikProps<ProviderQuestionnaireRawData>
) => {
  const caqhLicenses = formikHelpers.values.caqhLicenses;
  const selectedLicenses = formikHelpers.values.selectedLicenses;
  if (!caqhLicenses || !selectedLicenses) {
    return undefined;
  }

  const selectedLicense = selectedLicenses[state];
  if (!selectedLicense) {
    return undefined;
  }
  const licenseKeys = getLicenseKeys(caqhLicenses);
  if (licenseKeys.has(getLicenseKey(selectedLicense))) {
    return selectedLicense;
  } else {
    return undefined;
  }
};

export const getMatchingLicenseAndVerification = (
  licenseInput?: License,
  licensesAndVerifications?: LicenseAndVerification[]
) => {
  if (!licenseInput || !licensesAndVerifications) {
    return undefined;
  }

  for (const licenseAndVerification of licensesAndVerifications) {
    const license = licenseAndVerification.license;
    if (
      licenseInput.licenseNumber === license.credentialNumberInput &&
      licenseInput.licenseState === license.state &&
      licenseInput.licenseType === license.licenseTypeInput
    ) {
      return licenseAndVerification;
    }
  }

  return undefined;
};

export const getMatchingLicensesAndVerifications = (
  provider: ProviderRead,
  selectedLicenses: { [key: string]: License } | undefined,
  licensesAndVerifications: LicenseAndVerification[] | undefined
) => {
  if (!selectedLicenses || !licensesAndVerifications) {
    return [];
  }
  const supportedStates = getSupportedStates(provider);
  let matchingLicensesAndVerifications: LicenseAndVerification[] = [];

  Object.entries(selectedLicenses).forEach(([state, license]) => {
    if (!supportedStates.includes(state as UnitedStates)) {
      return;
    }

    const matchingLicenseAndVerification = getMatchingLicenseAndVerification(
      license,
      licensesAndVerifications
    );

    if (matchingLicenseAndVerification) {
      matchingLicensesAndVerifications.push(matchingLicenseAndVerification);
    }
  });

  return matchingLicensesAndVerifications;
};

export const getUniqueLicenses = (licenses?: License[]) => {
  if (!licenses) {
    return [];
  }
  const licenseKeys = new Set();
  return licenses?.filter((license) => {
    const licenseKey = getLicenseKey(license);
    if (licenseKeys.has(licenseKey)) {
      return false;
    }
    licenseKeys.add(licenseKey);
    return true;
  });
};

export const isLicenseVerified = (
  licenseAndVerification: LicenseAndVerification | undefined
) => {
  if (!licenseAndVerification) {
    return false;
  }
  return licenseAndVerification.license.isVerified;
};

export const getLicenseVerificationStatus = (
  licenseInput?: License,
  licensesAndVerifications?: LicenseAndVerification[]
) => {
  const matchingLicenseAndVerification = getMatchingLicenseAndVerification(
    licenseInput,
    licensesAndVerifications
  );
  if (isLicenseVerified(matchingLicenseAndVerification)) {
    return StepState.VALID;
  }

  if (
    matchingLicenseAndVerification?.verification &&
    [
      CredentialVerificationStatus.IN_PROGRESS,
      CredentialVerificationStatus.NEEDS_REVIEW,
    ].includes(matchingLicenseAndVerification.verification.status)
  ) {
    return StepState.PENDING;
  }
  if (matchingLicenseAndVerification) {
    return StepState.INVALID;
  }

  return undefined;
};
