import { FormHelperText, FormHelperTextProps } from '@mui/material';
import React, { FC } from 'react';

import { theme } from '../theme';

export const ErrorText: FC<React.PropsWithChildren<FormHelperTextProps>> = ({
  children,
  sx,
  ...props
}) => (
  <FormHelperText
    variant="standard"
    sx={{
      position: 'absolute',
      bottom: `-${theme.space.lg}`,
      ...(sx || {}),
    }}
    {...props}
  >
    {children}
  </FormHelperText>
);
