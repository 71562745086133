import moment from 'moment';
import React from 'react';

import { Malpractice } from '@headway/api/models/Malpractice';
import { MalpracticeMinimums } from '@headway/api/models/MalpracticeMinimums';
import { BodyText } from '@headway/helix/BodyText';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';

import {
  CaqhKeyValuePair,
  CaqhSection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';

export const MalpracticeHeader = ({
  nonExpiredMalpracticeInsurances,
  malpracticeMinimums,
}: {
  nonExpiredMalpracticeInsurances: Malpractice[];
  malpracticeMinimums?: MalpracticeMinimums;
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const additionalSubBody = (
    <SubBodyText>
      <b>Heads up</b>
      <br />
      Make sure the policy you select to use on Headway meets insurers’
      requirements. The policy you select must:
      <ul css={{ padding: `0 ${theme.spacing.x5}`, margin: 0 }}>
        {!!malpracticeMinimums && (
          <li css={{ marginBottom: theme.spacing.x1 }}>
            have an occurrence limit of at least
            {` ${formatter.format(malpracticeMinimums.occurrenceLimit)}`}
          </li>
        )}
        {!!malpracticeMinimums && (
          <li css={{ marginBottom: theme.spacing.x1 }}>
            have an aggregate limit of at least
            {` ${formatter.format(malpracticeMinimums.aggregateLimit)}`}
          </li>
        )}
        <li>be effective for at least the next 21 days</li>
      </ul>
    </SubBodyText>
  );

  return (
    <CaqhSection additionalSubBody={additionalSubBody}>
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x4,
          width: '100%',
        }}
      >
        {nonExpiredMalpracticeInsurances.map((malpractice: Malpractice) => (
          <div
            css={{
              ...theme.stack.vertical,
              gap: theme.spacing.x2,
            }}
          >
            <BodyText>
              <b>
                {malpractice.liabilityInsuranceCarrier} (Policy number:{' '}
                {malpractice.liabilityInsurancePolicyNumber})
              </b>
            </BodyText>
            <div
              css={{
                ...theme.stack.horizontal,
                width: '100%',
              }}
            >
              <div
                css={{
                  ...theme.stack.vertical,
                  gap: theme.spacing.x2,
                  width: '50%',
                }}
              >
                <CaqhKeyValuePair
                  label={`Effective Date`}
                  value={moment(
                    malpractice.liabilityInsuranceEffectiveDate
                  ).format('MM/DD/YYYY')}
                />
                <CaqhKeyValuePair
                  label={`Expiration Date`}
                  value={moment(
                    malpractice.liabilityInsuranceExpirationDate
                  ).format('MM/DD/YYYY')}
                />
              </div>
              <div
                css={{
                  ...theme.stack.vertical,
                  gap: theme.spacing.x2,
                  width: '50%',
                }}
              >
                <CaqhKeyValuePair
                  label={`Occurrence Limit`}
                  value={formatter.format(
                    Number(malpractice.liabilityInsuranceOccurrenceLimit)
                  )}
                />
                <CaqhKeyValuePair
                  label={`Aggregate Limit`}
                  value={formatter.format(
                    Number(malpractice.liabilityInsuranceAggregateLimit)
                  )}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </CaqhSection>
  );
};
