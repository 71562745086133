import { ProviderTreatmentPlanAttachmentCreate } from '@headway/api/models/ProviderTreatmentPlanAttachmentCreate';
import { ProviderTreatmentPlanAttachmentRead } from '@headway/api/models/ProviderTreatmentPlanAttachmentRead';
import { ProviderTreatmentPlanCreate } from '@headway/api/models/ProviderTreatmentPlanCreate';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { ProviderTreatmentPlanUpdateRequest } from '@headway/api/models/ProviderTreatmentPlanUpdateRequest';
import { ProviderTreatmentPlanApi } from '@headway/api/resources/ProviderTreatmentPlanApi';

import { useProviderTreatmentPlanAttachmentsCache } from 'hooks/useProviderTreatmentPlanAttachments';
import { useProviderTreatmentPlansCache } from 'hooks/useProviderTreatmentPlans';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export interface DeleteTreatmentPlanMutationArgs {
  treatmentPlanId: number;
  providerPatientId: number;
}

export const useDeleteTreatmentPlanMutation = (
  options: UseMutationWithSideEffectsOptions<
    {},
    unknown,
    DeleteTreatmentPlanMutationArgs
  > = {}
) => {
  const treatmentPlansCache = useProviderTreatmentPlansCache();

  return useMutationWithSideEffects(
    ({ treatmentPlanId }: DeleteTreatmentPlanMutationArgs) =>
      ProviderTreatmentPlanApi.deleteProviderTreatmentPlan(treatmentPlanId),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        {},
        unknown,
        DeleteTreatmentPlanMutationArgs
      >()
        .addOptimisticUpdate(
          treatmentPlansCache,
          ({ providerPatientId }) => ({ providerPatientId }),
          ({ treatmentPlanId }, old) => {
            if (!old) {
              return undefined;
            }
            return old.filter((plan) => plan.id !== treatmentPlanId);
          }
        )
        .addErrorLogging(
          () => 'There was a problem deleting the treatment plan'
        )
        .merge(options.sideEffects),
    }
  );
};

export interface UpdateTreatmentPlanMutationArgs {
  treatmentPlanId: number;
  providerPatientId: number;
  update: ProviderTreatmentPlanUpdateRequest;
}

export const useUpdateTreatmentPlanMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderTreatmentPlanRead,
    unknown,
    UpdateTreatmentPlanMutationArgs
  > = {}
) => {
  const treatmentPlansCache = useProviderTreatmentPlansCache();

  return useMutationWithSideEffects(
    ({ treatmentPlanId, update }: UpdateTreatmentPlanMutationArgs) =>
      ProviderTreatmentPlanApi.updateProviderTreatmentPlan(
        treatmentPlanId,
        update
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderTreatmentPlanRead,
        unknown,
        UpdateTreatmentPlanMutationArgs
      >()
        .addOptimisticUpdate(
          treatmentPlansCache,
          ({ providerPatientId }) => ({ providerPatientId }),
          ({ treatmentPlanId, update }, old) => {
            if (!old) {
              return undefined;
            }
            return old.map((plan) =>
              plan.id === treatmentPlanId ? { ...plan, ...update } : plan
            );
          }
        )
        .addErrorLogging(
          () => 'There was a problem updating the treatment plan'
        )
        .merge(options.sideEffects),
    }
  );
};

interface CreateTreatmentPlanMutationArgs {
  treatmentPlan: ProviderTreatmentPlanCreate;
}

export const useCreateTreatmentPlanMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderTreatmentPlanRead,
    unknown,
    CreateTreatmentPlanMutationArgs
  > = {}
) => {
  const treatmentPlansCache = useProviderTreatmentPlansCache();

  return useMutationWithSideEffects(
    ({ treatmentPlan }: CreateTreatmentPlanMutationArgs) =>
      ProviderTreatmentPlanApi.createProviderTreatmentPlan(treatmentPlan),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderTreatmentPlanRead,
        unknown,
        CreateTreatmentPlanMutationArgs
      >()
        .add({
          onSuccess: (newPlan) => {
            treatmentPlansCache.setAndInvalidate(
              { providerPatientId: newPlan.providerPatientId },
              (old) => {
                if (!old) {
                  return undefined;
                }
                return [
                  newPlan,
                  ...old.filter((plan) => plan.id !== newPlan.id),
                ];
              }
            );
          },
        })
        .addErrorLogging(
          () => 'There was a problem creating a new treatment plan'
        )
        .merge(options.sideEffects),
    }
  );
};

export interface UpdateTreatmentPlanAttachmentsMutationArgs {
  treatmentPlanId: number;
  attachmentsToDelete?: number[];
  attachmentsToCreate?: ProviderTreatmentPlanAttachmentCreate[];
}

export const useUpdateTreatmentPlanAttachmentsMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderTreatmentPlanAttachmentRead[],
    unknown,
    UpdateTreatmentPlanAttachmentsMutationArgs
  > = {}
) => {
  const attachmentsCache = useProviderTreatmentPlanAttachmentsCache();
  return useMutationWithSideEffects(
    async ({
      treatmentPlanId,
      attachmentsToCreate,
      attachmentsToDelete,
    }: UpdateTreatmentPlanAttachmentsMutationArgs) => {
      const added = await Promise.all(
        (attachmentsToCreate || []).map((attachment) =>
          ProviderTreatmentPlanApi.createTreatmentPlanAttachment(
            treatmentPlanId,
            attachment
          )
        )
      );

      await Promise.all(
        (attachmentsToDelete || []).map((attachmentId) =>
          ProviderTreatmentPlanApi.deleteTreatmentPlanAttachment(
            treatmentPlanId,
            attachmentId
          )
        )
      );

      return added;
    },
    {
      sideEffects: new SideEffectsBuilder<
        ProviderTreatmentPlanAttachmentRead[],
        unknown,
        UpdateTreatmentPlanAttachmentsMutationArgs
      >()
        .addErrorLogging(
          () => 'There was a problem adding treatment plan attachments'
        )
        .add({
          onSuccess: (
            addedAttachments,
            { treatmentPlanId, attachmentsToDelete }
          ) => {
            const deletedIds = new Set(attachmentsToDelete || []);
            attachmentsCache.setAndInvalidate({ treatmentPlanId }, (old) => {
              if (!old) {
                return undefined;
              }
              return old
                .filter((attachment) => !deletedIds.has(attachment.id))
                .concat(addedAttachments);
            });
          },
        })
        .merge(options.sideEffects),
    }
  );
};
