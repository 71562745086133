import React from 'react';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderStyleTagApi } from '@headway/api/resources/ProviderStyleTagApi';
import { theme } from '@headway/ui/theme';

import { Wizard } from 'components/Wizard/Wizard';

import { BioStep } from './BioStep';
import { Completed } from './Completed';
import { PhotoStep } from './PhotoStep';
import { StyleTagStep } from './StyleTagStep';

interface PrimaryContentHeaderProps {
  title: string;
  description: string | JSX.Element;
}

export const PrimaryContentHeader = ({
  title,
  description,
}: PrimaryContentHeaderProps) => {
  return (
    <>
      <div
        css={{
          fontSize: theme.fontSize.lg,
          color: theme.color.black,
          marginTop: theme.space.xs,
          marginBottom: theme.space.xs,
        }}
      >
        {title}
      </div>
      <p css={{ fontSize: theme.fontSize.sm, color: theme.color.textGray }}>
        {description}
      </p>
    </>
  );
};
interface SecondaryContentProps {
  title: string;
  doContent: String[];
  avoidContent: String[];
  goodPhotoIcon?: string;
  badPhotoIcon?: string;
}

export const SecondaryContent = ({
  title,
  doContent,
  avoidContent,
  goodPhotoIcon,
  badPhotoIcon,
}: SecondaryContentProps) => {
  return (
    <div>
      <span css={{ color: theme.color.primary, fontSize: theme.fontSize.xs }}>
        TIPS
      </span>
      <p css={{ color: theme.color.black, marginBottom: theme.space.base }}>
        {title}
      </p>
      <div>
        <div css={{ display: 'flex' }}>
          {goodPhotoIcon && (
            <div>
              <img
                data-testid="goodPhotoIcon"
                src={goodPhotoIcon}
                alt="example of a good headshot"
                css={{
                  marginRight: theme.space.base,
                }}
              />
            </div>
          )}
          <div>
            <span
              css={{
                color: theme.color.primary,
                fontSize: theme.fontSize.sm,
                fontWeight: theme.fontWeight.bold,
              }}
            >
              Do
            </span>
            <ul css={{ marginTop: 0, paddingInlineStart: '25px' }}>
              {doContent?.map((content, idx) => {
                return (
                  <li
                    key={idx}
                    css={{
                      fontSize: theme.fontSize.sm,
                      color: theme.color.black,
                    }}
                  >
                    {content}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div css={{ display: 'flex' }}>
          {badPhotoIcon && (
            <div>
              <img
                data-testid="badPhotoIcon"
                src={badPhotoIcon}
                alt="example of a bad headshot"
                css={{
                  marginRight: theme.space.base,
                }}
              />
            </div>
          )}
          <div>
            <span
              css={{
                color: theme.color.error,
                fontSize: theme.fontSize.sm,
                fontWeight: theme.fontWeight.bold,
              }}
            >
              Avoid
            </span>
            <ul css={{ marginTop: 0, paddingInlineStart: '25px' }}>
              {avoidContent?.map((content, idx) => {
                return (
                  <li
                    key={idx}
                    css={{
                      fontSize: theme.fontSize.sm,
                      color: theme.color.black,
                    }}
                  >
                    {content}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ProfileWizard = () => {
  const getDefaultStep = async (provider: ProviderRead) => {
    let step = 0;
    if (provider.photoUrl) {
      step = 1;

      const fetchStyleTags = await ProviderStyleTagApi.getProviderStyleTags({
        provider_id: provider.id,
      });
      // if provider has fewer than 3 style tags, forward to style tag step
      if (fetchStyleTags.length >= 3) {
        step = 2;
      }
    }
    return step;
  };

  return (
    <Wizard
      steps={[<PhotoStep />, <StyleTagStep />, <BioStep />]}
      title="Set up your profile"
      endContent={<Completed />}
      module={ProviderModule.PROFILE}
      getDefaultStep={getDefaultStep}
    />
  );
};
