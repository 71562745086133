import invert from 'lodash/invert';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { IroncladApi } from '@headway/api/resources/IroncladApi';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useIsMutating, useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import {
  getIroncladSignerId,
  isNonAdminGroupPracticeProvider,
  StateAddendumContractIds,
} from 'components/IroncladAmendmentsModal/helpers/utils';
import { IroncladAgreementStatus } from 'components/IroncladAmendmentsModal/types/IroncladAgreementStatus';
import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { useAuthStore } from 'stores/AuthStore';

const getStateContractIdsByAcceptanceStatus = (
  isAccepted: boolean,
  latestAgreementStatuses?: { [key: number]: boolean },
  stateAddendumContractIds?: StateAddendumContractIds
): StateAddendumContractIds | null => {
  if (!latestAgreementStatuses || !stateAddendumContractIds) return null;

  const states: string[] = [];
  const contractIdToStateName = invert(stateAddendumContractIds);

  for (let contractId in latestAgreementStatuses) {
    const acceptanceCheck = isAccepted
      ? latestAgreementStatuses[contractId]
      : !latestAgreementStatuses[contractId];
    if (acceptanceCheck && contractIdToStateName[contractId]) {
      states.push(contractIdToStateName[contractId]);
    }
  }

  const addendumContractIds = Object.keys(stateAddendumContractIds)
    .filter((state) => states.includes(state))
    .reduce((obj, state) => {
      return {
        ...obj,
        [state]: stateAddendumContractIds[state as UnitedStates],
      };
    }, {} as StateAddendumContractIds);
  return addendumContractIds;
};

interface UseIroncladAgreementStatusProps {
  includeAllActiveProviderLicenseStates?: boolean;
}

export const useIroncladAgreementStatus = (
  options: UseIroncladAgreementStatusProps = {}
) => {
  const { includeAllActiveProviderLicenseStates = false } = options;
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const { user, provider } = useAuthStore();
  const { ironcladAgreementInfo } = useIroncladAgreementInfo(
    includeAllActiveProviderLicenseStates
  );
  const { groupKey, mainContractId, stateAddendumContractIds } =
    ironcladAgreementInfo || {};

  let ironcladAgreementStatus;

  const signerId = isNonAdminGroupPracticeProvider(provider, user)
    ? `group-${provider?.groupPracticeId}`
    : getIroncladSignerId(user);

  const isMutating = useIsMutating({
    mutationKey: ['updateIroncladAgreementStatus'],
  });

  const ironcladKey = `updateIroncladAgreementStatus-${isMutating}`;

  const contractIds =
    isMSCBetaEnabled && mainContractId && stateAddendumContractIds
      ? [mainContractId, ...Object.values(stateAddendumContractIds)]
      : [];

  const queryParams =
    isMSCBetaEnabled && contractIds.length
      ? `&cid=${encodeURIComponent(contractIds.join(','))}`
      : groupKey
      ? `&gkey=${groupKey}`
      : null;

  const {
    isLoading: isLoadingAcceptedAgreements,
    isError: isErrorAcceptedAgreements,
    data: latestAgreementStatuses,
  } = useQuery(
    ['latestAgreementStatuses', signerId, queryParams, ironcladKey],
    async () => {
      const res = await fetch(
        `https://pactsafe.io/latest?sid=${process.env.REACT_APP_IRONCLAD_SITE_ID}&sig=${signerId}${queryParams}`,
        { method: 'GET' }
      );

      return await res.json();
    },
    {
      enabled: !!(provider?.id && signerId && (groupKey || contractIds.length)),
      onError: (error) => logException(error),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    }
  );

  const {
    isLoading: isLoadingDeclinedAgreements,
    isError: isErrorDeclinedAgreements,
    data: declinedLatestVersions,
  } = useQuery(
    ['declinedLatestVersions', signerId, provider?.id, ironcladAgreementInfo],
    async () => {
      return await IroncladApi.getUserExplicitlyDeclinedAgreements({
        signer_id: signerId,
        provider_id: provider.id,
      });
    },
    {
      enabled: !!(provider?.id && signerId && (groupKey || contractIds.length)),
      onError: (error) => logException(error),
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    }
  );

  const isAccepted =
    latestAgreementStatuses &&
    !Object.values(latestAgreementStatuses).includes(false);

  const isDeclined = !!declinedLatestVersions?.hasExplicitlyDeclinedAgreements;

  if (!latestAgreementStatuses) {
    ironcladAgreementStatus = IroncladAgreementStatus.NOT_FOUND;
  } else if (isAccepted) {
    ironcladAgreementStatus = IroncladAgreementStatus.AGREED;
  } else if (isDeclined) {
    ironcladAgreementStatus = IroncladAgreementStatus.DISAGREED;
  } else ironcladAgreementStatus = IroncladAgreementStatus.NO_REPLY;

  // Fetch active license states that a user OR group practice has ALREADY signed their addendum for
  const stateswithAcceptedAgreements = getStateContractIdsByAcceptanceStatus(
    true,
    latestAgreementStatuses,
    stateAddendumContractIds as StateAddendumContractIds
  );

  // Fetch active license states that a user OR group practice has NOT YET signed their addendum for
  const statesPendingAgreement = getStateContractIdsByAcceptanceStatus(
    false,
    latestAgreementStatuses,
    stateAddendumContractIds as StateAddendumContractIds
  );

  const isLoading = isLoadingAcceptedAgreements || isLoadingDeclinedAgreements;
  const isError = isErrorDeclinedAgreements || isErrorAcceptedAgreements;

  return {
    isLoading,
    isError,
    ironcladAgreementStatus,
    stateswithAcceptedAgreements,
    statesPendingAgreement,
  };
};
