import { BillingType } from '@headway/api/models/BillingType';
import { UserRead } from '@headway/api/models/UserRead';

export const isUserSelfPayWithRespectToProvider = (
  user: UserRead,
  providerId: number
): boolean => {
  const providerPatients = user.providerPatients || [];
  const relevantProviderPatient = providerPatients.find((providerPatient) => {
    return providerPatient.providerId === providerId;
  });
  return (
    !!relevantProviderPatient &&
    relevantProviderPatient.billingTypeDefault === BillingType.SELF_PAY
  );
};

export const getSelfPaynesses = (
  users: UserRead[],
  providerId: number
): { [userId: string]: boolean } => {
  const result = {} as { [userId: string]: boolean };
  users.forEach((user) => {
    result[user.id] = isUserSelfPayWithRespectToProvider(user, providerId);
  });
  return result;
};
