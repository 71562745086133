/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';
import { stringify } from 'qs';

import { AppointmentAuditResponse } from '../models/AppointmentAuditResponse';
import { AppointmentProgressNoteResponse } from '../models/AppointmentProgressNoteResponse';
import { HTTPValidationError } from '../models/HTTPValidationError';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class AppointmentAuditApiResource {

    /**
     * Bulk Send Audit Remediation Emails
     * Endpoint that takes in a csv of 2 columns: appointment id and semi-colon deliminted remediation descriptions. Aggregates the csv info, to send emails to providers notifying them of remediations (or changes) needed to their documents.
     * @param file 
     * @param token 
     
     */
    public bulkSendAuditRemediationEmails(file: any, query?: { token?: string }, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/appointment-audit/bulk-send-remediation-emails';
        const reqPath = apiRoute;
        let reqFormParams = {
            file: file
        };
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            data: stringify(reqFormParams),

        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Export Progress Notes To Patient
     * 
     * @param toPatientId 
     
     */
    public exportProgressNotesToPatient(query?: { to_patient_id: number }, axiosConfig?: AxiosRequestConfig): Promise<string> {
        const apiRoute = '/appointment-audit/export';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Appointment Audit Download
     * 
     * @param patientId 
     * @param tradingPartnerId 
     * @param frontEndCarrierId 
     * @param appointmentIds 
     * @param providerId 
     * @param claimCreationDateRangeStart 
     * @param claimCreationDateRangeEnd 
     * @param appointmentStartDateRangeStart 
     * @param appointmentStartDateRangeEnd 
     * @param token 
     
     */
    public getAppointmentAuditDownload(query?: { patient_id?: number, trading_partner_id?: number, front_end_carrier_id?: number, appointment_ids?: Array<number>, provider_id?: number, claim_creation_date_range_start?: string, claim_creation_date_range_end?: string, appointment_start_date_range_start?: string, appointment_start_date_range_end?: string, token?: string }, axiosConfig?: AxiosRequestConfig): Promise<AppointmentAuditResponse> {
        const apiRoute = '/appointment-audit/download';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Appointment Audit Progress Notes Data
     * 
     * @param providerAppointmentId 
     * @param token 
     
     */
    public getAppointmentAuditProgressNotesData(providerAppointmentId: number, query?: { token?: string }, axiosConfig?: AxiosRequestConfig): Promise<AppointmentProgressNoteResponse> {
        const apiRoute = '/appointment-audit/{provider_appointment_id}/progress-notes';
        const reqPath = apiRoute
                    .replace('{' + 'provider_appointment_id}', String(providerAppointmentId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const AppointmentAuditApi = new AppointmentAuditApiResource();
