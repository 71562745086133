import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';

import { EventFilterFunction } from '.';

export interface FilterByExcludingProviderCalendarsData {
  providerCalendarIds?: number[];
}

export const filterByExcludingProviderCalendars: EventFilterFunction<
  FilterByExcludingProviderCalendarsData
> = (
  event: ProviderEventRead,
  filterData: FilterByExcludingProviderCalendarsData
) => {
  if (!event.providerCalendarId) {
    return false;
  }

  return !!filterData.providerCalendarIds?.includes(event.providerCalendarId);
};
