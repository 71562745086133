import { FormControl, FormControlProps } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';

import { theme } from '../theme';

export type FieldControlProps = FormControlProps & {
  name: string;
  noMargin?: boolean;
  id?: string;
};

export const FieldControlContext = React.createContext({ name: '', id: '' });

export const FieldControl: FC<React.PropsWithChildren<FieldControlProps>> = ({
  name,
  noMargin,
  id,
  ...props
}) => {
  const [, meta] = useField(name);
  const form = useFormikContext();

  return (
    <FieldControlContext.Provider value={{ name, id: id || name }}>
      <FormControl
        sx={{
          marginBottom: noMargin ? 0 : theme.space.lg,
          display: 'flex',
          flexGrow: 1,
          scrollMarginTop: `calc(76px + ${theme.space.xl5})`,
        }}
        error={
          meta.error !== undefined && (meta.touched || form.submitCount !== 0)
        }
        {...props}
      />
    </FieldControlContext.Provider>
  );
};
