import {
  CaqhAuthorizationLoginError,
  CaqhAuthorizationMismatch,
  CaqhAuthorizationRequiresAttestation,
  CaqhAuthorizationRequiresAuthorization,
  CaqhAuthorizationServerError,
  CaqhEmptyCredentialsError,
} from './steps/CaqhAuthorization';
import { CaqhErrorsNeedToBeResolved } from './steps/CaqhErrorsStep/CaqhErrorsStep';
import { EducationRequireMastersOrHigher } from './steps/Education/Education';
import { LicenseTriggerValidation } from './steps/LicenseStep/LicenseStep';
import {
  MalpracticeRequireActivePolicy,
  MalpracticeRequireFuturePolicy,
} from './steps/Malpractice/Malpractice';
import { SsnInvalidError } from './steps/PersonalInformation/PersonalInformation';
import {
  DeaCdsErrorGuidanceCard,
  LicenseErrorGuidanceCard,
} from './steps/PracticeStatesStep';

export enum OnBeforeSubmitError {
  CaqhEmptyCredentialsError,
  CaqhAuthorizationLoginError,
  CaqhAuthorizationMismatch,
  CaqhAuthorizationRequiresAttestation,
  CaqhAuthorizationRequiresAuthorization,
  CaqhAuthorizationServerError,
  CaqhErrorsNeedToBeResolved,
  SsnInvalidError,
  LicenseTriggerValidation,
  MalpracticeRequireActivePolicy,
  MalpracticeRequireFuturePolicy,
  EducationRequireMastersOrHigher,
  LicenseErrorGuidanceCard,
  DeaCdsErrorGuidanceCard,
}

export interface OnBeforeSubmitErrorInterface {
  errorType: OnBeforeSubmitError;
}

export const OnBeforeSubmitErrorCard: React.FC<
  React.PropsWithChildren<OnBeforeSubmitErrorInterface>
> = (type: OnBeforeSubmitErrorInterface) => {
  switch (type.errorType) {
    case OnBeforeSubmitError.CaqhEmptyCredentialsError:
      return <CaqhEmptyCredentialsError />;
    case OnBeforeSubmitError.CaqhAuthorizationLoginError:
      return <CaqhAuthorizationLoginError />;
    case OnBeforeSubmitError.CaqhAuthorizationMismatch:
      return <CaqhAuthorizationMismatch />;
    case OnBeforeSubmitError.CaqhAuthorizationRequiresAttestation:
      return <CaqhAuthorizationRequiresAttestation />;
    case OnBeforeSubmitError.CaqhAuthorizationRequiresAuthorization:
      return <CaqhAuthorizationRequiresAuthorization />;
    case OnBeforeSubmitError.CaqhAuthorizationServerError:
      return <CaqhAuthorizationServerError />;
    case OnBeforeSubmitError.CaqhErrorsNeedToBeResolved:
      return <CaqhErrorsNeedToBeResolved />;
    case OnBeforeSubmitError.SsnInvalidError:
      return <SsnInvalidError />;
    case OnBeforeSubmitError.LicenseTriggerValidation:
      return <LicenseTriggerValidation />;
    case OnBeforeSubmitError.MalpracticeRequireActivePolicy:
      return <MalpracticeRequireActivePolicy />;
    case OnBeforeSubmitError.MalpracticeRequireFuturePolicy:
      return <MalpracticeRequireFuturePolicy />;
    case OnBeforeSubmitError.EducationRequireMastersOrHigher:
      return <EducationRequireMastersOrHigher />;
    case OnBeforeSubmitError.LicenseErrorGuidanceCard:
      return <LicenseErrorGuidanceCard />;
    case OnBeforeSubmitError.DeaCdsErrorGuidanceCard:
      return <DeaCdsErrorGuidanceCard />;
    default:
      return null;
  }
};
