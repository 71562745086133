import React from 'react';

export function IconChevronUp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 6.91654L15 11.9165L14.1042 12.8124L10 8.68738L5.89583 12.7915L5 11.8957L10 6.91654Z"
        fill="currentColor"
      />
    </svg>
  );
}
