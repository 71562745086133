/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { AvailabilityEventResponse } from '../models/AvailabilityEventResponse';
import { ExternalPlatformRead } from '../models/ExternalPlatformRead';
import { GetMatchingProviderFrontEndCarrierRequest } from '../models/GetMatchingProviderFrontEndCarrierRequest';
import { GetPaginatedUserReadResponse } from '../models/GetPaginatedUserReadResponse';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { PaginatedPaymentSummary } from '../models/PaginatedPaymentSummary';
import { PaginatedProviderAtlasSearchRead } from '../models/PaginatedProviderAtlasSearchRead';
import { PaginatedProviderRead } from '../models/PaginatedProviderRead';
import { PaginatedProviderSearchRead } from '../models/PaginatedProviderSearchRead';
import { PaginatedTransactionRead } from '../models/PaginatedTransactionRead';
import { PayerQuestionnaire } from '../models/PayerQuestionnaire';
import { PaymentRead } from '../models/PaymentRead';
import { PriceTableResponse } from '../models/PriceTableResponse';
import { ProviderAttributes } from '../models/ProviderAttributes';
import { ProviderBookingPreferenceResponse } from '../models/ProviderBookingPreferenceResponse';
import { ProviderCreate } from '../models/ProviderCreate';
import { ProviderFrontEndCarrierRead } from '../models/ProviderFrontEndCarrierRead';
import { ProviderGoLiveRequest } from '../models/ProviderGoLiveRequest';
import { ProviderGoLiveResult } from '../models/ProviderGoLiveResult';
import { ProviderHighlight } from '../models/ProviderHighlight';
import { ProviderHighlightKey } from '../models/ProviderHighlightKey';
import { ProviderLicenseStateRead } from '../models/ProviderLicenseStateRead';
import { ProviderMetrics } from '../models/ProviderMetrics';
import { ProviderMetricsTimePeriod } from '../models/ProviderMetricsTimePeriod';
import { ProviderModuleStatus } from '../models/ProviderModuleStatus';
import { ProviderPayerSystemReadyError } from '../models/ProviderPayerSystemReadyError';
import { ProviderPayerSystemReadyRequest } from '../models/ProviderPayerSystemReadyRequest';
import { ProviderPaymentsDownload } from '../models/ProviderPaymentsDownload';
import { ProviderPaymentsDownloadOptions } from '../models/ProviderPaymentsDownloadOptions';
import { ProviderProfileRead } from '../models/ProviderProfileRead';
import { ProviderRead } from '../models/ProviderRead';
import { ProviderSitemapRead } from '../models/ProviderSitemapRead';
import { ProviderTerminationRequest } from '../models/ProviderTerminationRequest';
import { ProviderUpdate } from '../models/ProviderUpdate';
import { ProviderUpdateShownInSearchRequest } from '../models/ProviderUpdateShownInSearchRequest';
import { RosterType } from '../models/RosterType';
import { UnitedStates } from '../models/UnitedStates';
import { UserClaimReadinessResponse } from '../models/UserClaimReadinessResponse';
import { UserPriceEstimateResponse } from '../models/UserPriceEstimateResponse';
import { UserRead } from '../models/UserRead';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class ProviderApiResource {

    /**
     * Atlas Search Providers
     * 
     * @param offset 
     * @param limit 
     * @param isActive 
     * @param liveOnMax 
     * @param state 
     * @param orderBy 
     * @param searchTerm 
     * @param npi 
     
     */
    public atlasSearchProviders(query?: { offset?: number, limit?: number, is_active?: boolean, live_on_max?: string, state?: UnitedStates, order_by?: string, search_term?: string, npi?: string }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedProviderAtlasSearchRead> {
        const apiRoute = '/provider/atlas-search';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Bulk Payer System Ready
     * Sets payer system ready date for multiple providers at a time.
     * @param providerPayerSystemReadyRequest 
     
     */
    public bulkPayerSystemReady(body: Array<ProviderPayerSystemReadyRequest>, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderPayerSystemReadyError>> {
        const apiRoute = '/provider/bulk-payer-system-ready';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Profile View
     * 
     * @param providerId 
     
     */
    public createProfileView(providerId: any, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider/{provider_id}/view';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Create Provider
     * 
     * @param providerCreate 
     
     */
    public createProvider(body: ProviderCreate, axiosConfig?: AxiosRequestConfig): Promise<ProviderRead> {
        const apiRoute = '/provider';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Download Payments For Provider
     * 
     * @param providerId 
     * @param providerName 
     * @param startDate 
     * @param endDate 
     
     */
    public downloadPaymentsForProvider(providerId: number, query?: { provider_name: string, start_date?: string, end_date?: string }, axiosConfig?: AxiosRequestConfig): Promise<ProviderPaymentsDownload> {
        const apiRoute = '/provider/{provider_id}/download-payments';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Find Providers
     * 
     * @param offset 
     * @param limit 
     * @param providerIds 
     * @param patientUserIds 
     * @param age 
     * @param gender 
     * @param ethnicity 
     * @param prescribers 
     * @param frontEndCarrierId 
     * @param patientAge 
     * @param shownInSearch 
     * @param isActive 
     * @param liveOnMax 
     * @param lowerLat 
     * @param upperLat 
     * @param lowerLon 
     * @param upperLon 
     * @param groupPracticeId 
     * @param state 
     * @param orderBy 
     * @param searchTerm 
     * @param availabilityCountMin 
     * @param npi 
     
     */
    public findProviders(query?: { offset?: number, limit?: number, provider_ids?: Array<number>, patient_user_ids?: Array<number>, age?: Array<string>, gender?: Array<string>, ethnicity?: Array<string>, prescribers?: boolean, front_end_carrier_id?: number, patient_age?: number, shown_in_search?: boolean, is_active?: boolean, live_on_max?: string, lower_lat?: number, upper_lat?: number, lower_lon?: number, upper_lon?: number, group_practice_id?: number, state?: UnitedStates, order_by?: string, search_term?: string, availability_count_min?: number, npi?: string }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedProviderRead> {
        const apiRoute = '/provider';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Generate Calendar Address Token
     * 
     * @param providerId 
     
     */
    public generateCalendarAddressToken(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderRead> {
        const apiRoute = '/provider/{provider_id}/generate-calendar-address-token';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Generate Csv For Roster Type
     * Generates a csv file with for each provider id specified using their latest completed provider questionaire data for a RosterType enum.  :param providers_ids (str) &#x3D; A string that represents the provider id\&#39;s that we want to download. ie: \&quot;7,13,2\&quot; we then use this string and separate it to a proper list :param roster_type (RosterType) &#x3D; RosterType enum  :returns a csv file with the RosterType provider questionaire data
     * @param rosterType 
     * @param providersIds 
     
     */
    public generateCsvForRosterType(rosterType: RosterType, query?: { providers_ids: string }, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider-task/generate/csv/roster/{roster_type}';
        const reqPath = apiRoute
                    .replace('{' + 'roster_type}', String(rosterType));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get External Platforms For Provider
     * 
     * @param providerId 
     
     */
    public getExternalPlatformsForProvider(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ExternalPlatformRead>> {
        const apiRoute = '/provider/{provider_id}/external-platforms';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Matching Provider Front End Carrier
     * 
     * @param providerId 
     * @param patientUserId 
     * @param patientFrontEndCarrierId 
     * @param patientInsuranceMemberId 
     * @param appointmentId 
     * @param isTelehealthAppointment 
     * @param appointmentState 
     * @param includeHiddenCarriers 
     * @param useAllProviderStatesForScheduling 
     
     */
    public getMatchingProviderFrontEndCarrier(providerId: number, query?: { patient_user_id?: number, patient_front_end_carrier_id?: number, patient_insurance_member_id?: string, appointment_id?: number, is_telehealth_appointment?: boolean, appointment_state?: UnitedStates, include_hidden_carriers?: boolean, use_all_provider_states_for_scheduling?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<ProviderFrontEndCarrierRead> {
        const apiRoute = '/provider/{provider_id}/provider-front-end-carrier-match';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Matching Provider Front End Carrier Batch
     * 
     * @param providerId 
     * @param getMatchingProviderFrontEndCarrierRequest 
     
     */
    public getMatchingProviderFrontEndCarrierBatch(providerId: number, body: Array<GetMatchingProviderFrontEndCarrierRequest>, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderFrontEndCarrierRead>> {
        const apiRoute = '/provider/{provider_id}/provider-front-end-carrier-match/batch';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Newly Bookable Patients
     * 
     * @param providerId 
     
     */
    public getNewlyBookablePatients(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/provider/{provider_id}/newly-bookable-patients';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Next Availabilities By Providers
     * 
     * @param dateRangeEnd 
     * @param providerIds 
     * @param telehealth 
     * @param inPerson 
     * @param limit 
     
     */
    public getNextAvailabilitiesByProviders(query?: { date_range_end: string, provider_ids?: Array<number>, telehealth?: boolean, in_person?: boolean, limit?: number }, axiosConfig?: AxiosRequestConfig): Promise<{ [key: string]: Array<AvailabilityEventResponse>; }> {
        const apiRoute = '/provider/next_availabilities';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Onboarding Module Statuses
     * 
     * @param providerId 
     
     */
    public getOnboardingModuleStatuses(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderModuleStatus>> {
        const apiRoute = '/provider/{provider_id}/onboarding-modules';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patients For Provider
     * 
     * @param providerId 
     * @param hidden 
     
     */
    public getPatientsForProvider(providerId: number, query?: { hidden?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/provider/{provider_id}/patients';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patients Missing Scheduling Info For Provider
     * 
     * @param providerId 
     * @param limit 
     * @param offset 
     
     */
    public getPatientsMissingSchedulingInfoForProvider(providerId: number, query?: { limit?: number, offset?: number }, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/provider/{provider_id}/patients-missing-scheduling-info';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patients Paginated For Provider
     * 
     * @param providerId 
     * @param limit 
     * @param offset 
     * @param hidden 
     * @param search 
     * @param checkPaymentMethod 
     
     */
    public getPatientsPaginatedForProvider(providerId: number, query?: { limit?: number, offset?: number, hidden?: boolean, search?: string, check_payment_method?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<GetPaginatedUserReadResponse> {
        const apiRoute = '/provider/{provider_id}/patients-paginated';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Payment Summaries For Provider
     * 
     * @param providerId 
     * @param dateStart 
     * @param dateEnd 
     * @param offset 
     * @param limit 
     
     */
    public getPaymentSummariesForProvider(providerId: number, query?: { date_start?: string, date_end?: string, offset?: number, limit?: number }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedPaymentSummary> {
        const apiRoute = '/provider/{provider_id}/payments/history';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Payments Download Options
     * 
     * @param providerId 
     
     */
    public getPaymentsDownloadOptions(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderPaymentsDownloadOptions> {
        const apiRoute = '/provider/{provider_id}/payments-download-options';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider
     * 
     * @param providerId 
     
     */
    public getProvider(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderRead> {
        const apiRoute = '/provider/{provider_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Attribute Set
     * 
     
     */
    public getProviderAttributeSet(axiosConfig?: AxiosRequestConfig): Promise<ProviderAttributes> {
        const apiRoute = '/provider/attribute-set';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Availability
     * get given provider\&#39;s availabilities if the provider is live
     * @param providerId 
     * @param dateRangeStart The inclusive start date for the availability lookup
     * @param dateRangeEnd The inclusive end date for the availability lookup
     
     */
    public getProviderAvailability(providerId: number, query?: { date_range_start: string, date_range_end: string }, axiosConfig?: AxiosRequestConfig): Promise<Array<AvailabilityEventResponse>> {
        const apiRoute = '/provider/{provider_id}/availability';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Booking Preference
     * Given a provider id and a patient id, returns additional info on whether the patient is eligible to book with the provider, taking into account information like insurance, age, issues
     * @param providerId 
     * @param userId 
     
     */
    public getProviderBookingPreference(providerId: number, userId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderBookingPreferenceResponse> {
        const apiRoute = '/provider/{provider_id}/booking-preference/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId))
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider By Slug
     * 
     * @param providerSlug 
     
     */
    public getProviderBySlug(providerSlug: string, axiosConfig?: AxiosRequestConfig): Promise<ProviderRead> {
        const apiRoute = '/provider/slug/{provider_slug}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_slug}', String(providerSlug));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Highlights
     * Return the given provider\&#39;s currently valid highlights in ranked order  :param user_tzinfo: Used to get availabilities based on the viewer\&#39;s timezone (America/New_York, America/Los_Angeles). Defaults to provider\&#39;s timezone if not specified
     * @param providerId 
     * @param userId 
     * @param userTzinfo 
     * @param requestedProviderHighlights 
     
     */
    public getProviderHighlights(providerId: number, query?: { user_id: number, user_tzinfo?: string, requested_provider_highlights?: Array<ProviderHighlightKey> }, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderHighlight>> {
        const apiRoute = '/provider/{provider_id}/highlights';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider License States For Provider
     * 
     * @param providerId 
     
     */
    public getProviderLicenseStatesForProvider(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderLicenseStateRead>> {
        const apiRoute = '/provider/{provider_id}/provider-license-states';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Metrics
     * 
     * @param providerId 
     * @param timePeriod 
     
     */
    public getProviderMetrics(providerId: number, query?: { time_period: ProviderMetricsTimePeriod }, axiosConfig?: AxiosRequestConfig): Promise<ProviderMetrics> {
        const apiRoute = '/provider/{provider_id}/metrics';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Patient Claim Readiness
     * 
     * @param providerId 
     * @param requestBody 
     
     */
    public getProviderPatientClaimReadiness(providerId: number, body: Array<number>, axiosConfig?: AxiosRequestConfig): Promise<Array<UserClaimReadinessResponse>> {
        const apiRoute = '/provider/{provider_id}/claim-readiness';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Payer Questionnaires
     * 
     * @param providerId 
     * @param incompleteOnly 
     * @param liveOnly 
     
     */
    public getProviderPayerQuestionnaires(providerId: number, query?: { incomplete_only?: boolean, live_only?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<PayerQuestionnaire>> {
        const apiRoute = '/provider/{provider_id}/payer-questionnaires';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Price
     * 
     * @param providerId 
     * @param userId 
     
     */
    public getProviderPrice(providerId: number, userId: number, axiosConfig?: AxiosRequestConfig): Promise<UserPriceEstimateResponse> {
        const apiRoute = '/provider/{provider_id}/price/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId))
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Price Table
     * Calculates the exact price of a sample of common CPT codes for a patient-provider pair.
     * @param providerId 
     * @param userId 
     
     */
    public getProviderPriceTable(providerId: number, userId: number, axiosConfig?: AxiosRequestConfig): Promise<PriceTableResponse> {
        const apiRoute = '/provider/{provider_id}/price-table/{user_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId))
                    .replace('{' + 'user_id}', String(userId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Profile
     * 
     * @param providerId 
     
     */
    public getProviderProfile(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderProfileRead> {
        const apiRoute = '/provider/{provider_id}/profile';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Video Url
     * 
     * @param providerId 
     * @param token 
     
     */
    public getProviderVideoUrl(providerId: number, query?: { token?: string }, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider/{provider_id}/video-link';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Providers For Sitemap
     * 
     
     */
    public getProvidersForSitemap(axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderSitemapRead>> {
        const apiRoute = '/provider/sitemap';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Suggested Diagnosis Codes
     * 
     * @param providerId 
     * @param patientUserId 
     
     */
    public getSuggestedDiagnosisCodes(providerId: number, patientUserId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<string>> {
        const apiRoute = '/provider/{provider_id}/patient/{patient_user_id}/diagnosis-codes';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId))
                    .replace('{' + 'patient_user_id}', String(patientUserId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Transactions For Provider By Payment Date
     * 
     * @param providerId 
     * @param paymentDate 
     * @param offset 
     * @param limit 
     
     */
    public getTransactionsForProviderByPaymentDate(providerId: number, query?: { payment_date: string, offset?: number, limit?: number }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedTransactionRead> {
        const apiRoute = '/provider/{provider_id}/transactions';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Upcoming Payments For Provider
     * 
     * @param providerId 
     
     */
    public getUpcomingPaymentsForProvider(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<PaymentRead>> {
        const apiRoute = '/provider/{provider_id}/payments/upcoming';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Users By Provider Id
     * 
     * @param providerId 
     
     */
    public getUsersByProviderId(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<Array<UserRead>> {
        const apiRoute = '/provider/{provider_id}/users';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Go Live With Providers
     * Brings multiple providers live. Only providers who failed to go live are returned with their failure reason.
     * @param providerGoLiveRequest 
     
     */
    public goLiveWithProviders(body: Array<ProviderGoLiveRequest>, axiosConfig?: AxiosRequestConfig): Promise<Array<ProviderGoLiveResult>> {
        const apiRoute = '/provider/bulk-go-live';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Reactivate Provider
     * 
     * @param providerId 
     
     */
    public reactivateProvider(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider/{provider_id}/reactivate';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Search Providers
     * 
     * @param offset 
     * @param limit 
     * @param providerIds 
     * @param age 
     * @param gender 
     * @param ethnicity 
     * @param languages 
     * @param prescribers 
     * @param frontEndCarrierId 
     * @param patientAge 
     * @param issues 
     * @param liveOnMax 
     * @param lowerLat 
     * @param upperLat 
     * @param lowerLon 
     * @param upperLon 
     * @param availabilityCountMin 
     * @param telehealthAvailabilityCountMin 
     * @param inPersonAvailabilityCountMin 
     * @param includeTelehealth 
     * @param searchTerm 
     * @param state 
     
     */
    public searchProviders(query?: { offset?: number, limit?: number, provider_ids?: Array<number>, age?: Array<string>, gender?: Array<string>, ethnicity?: Array<string>, languages?: Array<string>, prescribers?: boolean, front_end_carrier_id?: number, patient_age?: number, issues?: Array<string>, live_on_max?: string, lower_lat?: number, upper_lat?: number, lower_lon?: number, upper_lon?: number, availability_count_min?: number, telehealth_availability_count_min?: number, in_person_availability_count_min?: number, include_telehealth?: boolean, search_term?: string, state?: UnitedStates }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedProviderSearchRead> {
        const apiRoute = '/provider/search';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Agreement Acceptance Confirmation Email
     * 
     * @param providerId 
     
     */
    public sendAgreementAcceptanceConfirmationEmail(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider/{provider_id}/send-agreement-acceptance-confirmation-email';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Send Termination Confirmation Email
     * 
     * @param providerId 
     
     */
    public sendTerminationConfirmationEmail(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider/{provider_id}/send-termination-confirmation-email';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Terminate Provider
     * 
     * @param providerId 
     * @param providerTerminationRequest 
     
     */
    public terminateProvider(providerId: number, body: ProviderTerminationRequest, axiosConfig?: AxiosRequestConfig): Promise<{}> {
        const apiRoute = '/provider/{provider_id}/terminate';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider
     * 
     * @param providerId 
     * @param providerUpdate 
     
     */
    public updateProvider(providerId: number, body: ProviderUpdate, axiosConfig?: AxiosRequestConfig): Promise<ProviderRead> {
        const apiRoute = '/provider/{provider_id}';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PUT' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Region Via Provider Address
     * 
     * @param providerId 
     
     */
    public updateProviderRegionViaProviderAddress(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<ProviderLicenseStateRead> {
        const apiRoute = '/provider/{provider_id}/update-provider-region-via-provider-address';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Provider Shown In Search
     * 
     * @param providerId 
     * @param providerUpdateShownInSearchRequest 
     
     */
    public updateProviderShownInSearch(providerId: number, body: ProviderUpdateShownInSearchRequest, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/provider/{provider_id}/update_shown_in_search';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const ProviderApi = new ProviderApiResource();
