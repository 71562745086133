import { useProvider } from 'hooks';
import React from 'react';

import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserRead } from '@headway/api/models/UserRead';
import { getUseUserQueryKey, useUser } from '@headway/shared/hooks/useUser';
import {
  getUseUserPaymentMethodQueryKey,
  useUserPaymentMethods,
} from '@headway/shared/hooks/useUserPaymentMethod';
import { useQueryClient } from '@headway/shared/react-query';
import { Modal } from '@headway/ui/Modal';

import { PatientPaymentForm } from 'views/Patients/PatientPaymentForm';

export interface PaymentMethodModalProps {
  clientId: number;
  open: boolean;
  onClose: () => void;
}

export const PaymentMethodModal = ({
  clientId,
  open,
  onClose,
}: PaymentMethodModalProps) => {
  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: paymentMethods } = useUserPaymentMethods(clientId);
  const queryClient = useQueryClient();

  const onPaymentUpdated = (
    updatedUser: UserRead,
    newPaymentMethod: UserPaymentMethodRead
  ) => {
    const userQueryKey = getUseUserQueryKey({ userId: clientId });
    queryClient.setQueryData(userQueryKey, updatedUser);
    queryClient.invalidateQueries(userQueryKey);

    const userPaymentMethodKey = getUseUserPaymentMethodQueryKey(
      newPaymentMethod.id
    );
    queryClient.setQueryData(userPaymentMethodKey, newPaymentMethod);
    queryClient.invalidateQueries(userPaymentMethodKey);

    onClose();
  };

  if (!client) {
    return null;
  }

  return (
    <Modal
      title={
        !!paymentMethods && paymentMethods.length > 0
          ? 'Update Payment Method'
          : 'Add Payment Method'
      }
      open={open}
      onClose={onClose}
    >
      <PatientPaymentForm
        onComplete={onPaymentUpdated}
        patient={client}
        providerId={provider.id}
        paymentMethods={paymentMethods}
      />
    </Modal>
  );
};
