import React from 'react';

import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserRead } from '@headway/api/models/UserRead';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import { trackPageView } from '@headway/shared/utils/analytics';
import {
  getIneligibleLookupTypeFromReadinessIssues,
  hasManualVerificationRequiredUnreadiness,
  hasNoMentalHealthBenefitsUnreadiness,
  hasOONUnreadiness,
  hasTerminatedUnreadiness,
} from '@headway/shared/utils/insuranceUtils';

const IneligibleGuidanceCard = ({
  patient,
  userAppointmentReadiness,
}: {
  patient: UserRead;
  userAppointmentReadiness?: UserAppointmentReadiness;
}) => {
  let body = null;
  const patientFirstName = patient.displayFirstName;
  const clientPageLink = `/clients/${patient.id}/billing?showIneligibilityModal`;

  React.useEffect(() => {
    if (patient.activeUserInsurance && userAppointmentReadiness?.insurance) {
      trackPageView({
        name: 'Ineligible Insurance Banner Viewed',
        properties: {
          patientUserId: patient.id,
          userInsuranceId: String(patient.activeUserInsurance.id),
          eligibilityLookupId:
            patient.activeUserInsurance?.latestEligibilityLookup?.id,
          ineligibleLookupType: getIneligibleLookupTypeFromReadinessIssues(
            userAppointmentReadiness?.insurance
          ),
        },
      });
    }
  }, [
    patient.activeUserInsurance,
    patient.id,
    userAppointmentReadiness?.insurance,
  ]);

  if (hasOONUnreadiness(userAppointmentReadiness)) {
    body = (
      <>
        {patientFirstName}'s plan is not eligible for in-network care on
        Headway.{' '}
        <a href={clientPageLink}>See more details and what you can do.</a>
      </>
    );
  } else if (hasNoMentalHealthBenefitsUnreadiness(userAppointmentReadiness)) {
    body = (
      <>
        {patientFirstName}'s plan does not include mental health benefits.{' '}
        <a href={clientPageLink}>See more details and what you can do.</a>
      </>
    );
  } else if (hasTerminatedUnreadiness(userAppointmentReadiness)) {
    body = (
      <>
        {patientFirstName}'s plan is no longer active.{' '}
        <a href={clientPageLink}>See more details and what you can do.</a>
      </>
    );
  } else if (
    hasManualVerificationRequiredUnreadiness(userAppointmentReadiness)
  ) {
    body = (
      <>
        We can't verify {patientFirstName}'s plan.{' '}
        <a href={clientPageLink}>See more details and what you can do.</a>
      </>
    );
  }

  if (body) {
    return (
      <div css={{ marginTop: theme.spacing.x2 }}>
        <GuidanceCard variant="error" layout="vertical">
          <>
            <SubBodyText>{body}</SubBodyText>
          </>
        </GuidanceCard>
      </div>
    );
  }

  return null;
};

export { IneligibleGuidanceCard };
