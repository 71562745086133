import React from 'react';

import { GetPaginatedUserReadResponse } from '@headway/api/models/GetPaginatedUserReadResponse';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { useQueryClient } from '@headway/shared/react-query';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

import { getUsePatientsQueryKey } from 'hooks/usePatients';
import { useProvider } from 'hooks/useProvider';

interface BulkClientEmailConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  showArchived: boolean;
  page: number;
  search?: string;
}

export const BulkClientEmailConfirmationModal = ({
  open,
  onClose,
  showArchived,
  page,
  search,
}: BulkClientEmailConfirmationModalProps) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const provider = useProvider();

  const patientsQueryKey = getUsePatientsQueryKey(provider.id, {
    hidden: showArchived,
    page,
    search,
  });

  const handleOnSubmit = async () => {
    const errors = [];
    setIsSubmitting(true);
    try {
      const clientsWithMissingInfo =
        await ProviderApi.getPatientsMissingSchedulingInfoForProvider(
          provider.id
        );

      await Promise.all(
        clientsWithMissingInfo.map(async (client) => {
          const providerPatient =
            client.providerPatients &&
            client.providerPatients.find((el) => el.providerId === provider.id);

          if (providerPatient) {
            await ProviderPatientApi.sendProviderPatientAccountInvite(
              providerPatient.id
            );

            const updatedUser = await UserApi.updateUser(client.id, {
              isInvited: true,
            });

            queryClient.setQueryData<GetPaginatedUserReadResponse>(
              patientsQueryKey,
              (patientsResult) => {
                if (!patientsResult?.data) {
                  return { data: [updatedUser], totalCount: 1 };
                }
                return {
                  ...patientsResult,
                  data: patientsResult.data.map((patient) =>
                    patient.id === client.id ? updatedUser : patient
                  ),
                };
              }
            );
          }
        })
      );
      queryClient.invalidateQueries(patientsQueryKey);
    } catch (e) {
      errors.push(e);
    }

    if (errors.length > 0) {
      notifyError(`Error sending these emails— please try again!`);
    } else {
      notifySuccess('Emails sent!');
    }

    setIsSubmitting(false);
    onClose();
  };

  return (
    <Modal isOpen={open} onDismiss={onClose} title={`Are you sure?`}>
      <ModalContent>
        Headway will automatically email all clients added within the last 90
        days who are missing insurance or payment details, or who need to
        acknowledge our standard forms.
      </ModalContent>
      <ModalFooter>
        <Button variant="secondary" disabled={isSubmitting} onPress={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={isSubmitting}
          onPress={handleOnSubmit}
        >
          Send emails
        </Button>
      </ModalFooter>
    </Modal>
  );
};
