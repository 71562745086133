import * as Yup from 'yup';

import { ComponentNames } from '../renderer/components';
import { ComponentMapDeclaration } from '../schema/schema.types';

export type TemplateErrorConfig = {
  id?: string;
  type?: string;
  validationType: keyof typeof Yup;
  validations: Array<{ type: string; params: Array<string | number> }>;
};

export const getValidationType = (type: keyof ComponentMapDeclaration) => {
  switch (type) {
    case ComponentNames.checklist:
    case ComponentNames.dropdownMulti:
      return 'array';
    case ComponentNames.dropdown:
    case ComponentNames.radio:
    case ComponentNames.shortFreeText:
    case ComponentNames.longFreeText:
    case ComponentNames.richFreeText:
      return 'string';
    default:
      return 'string';
  }
};

export function createYupSchema(schema: any, config: TemplateErrorConfig) {
  const { id, validationType, validations = [] } = config;

  if (!Yup[validationType] || !id) {
    return schema;
  }

  const validatorSchema = Yup[validationType] as NewableFunction;
  let validator = validatorSchema();

  validations.forEach(
    (validation: { type: string; params: Array<string | number> }) => {
      const { params, type } = validation;
      if (!validator[type]) {
        return;
      }
      validator = validator[type](...params);
    }
  );
  schema[id] = validator;
  return schema;
}
