import { ModalProps } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { GroupPracticeApi } from '@headway/api/resources/GroupPracticeApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { theme } from '@headway/helix/theme';
import {
  ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT,
  ALWAYS_USE_GP_BILLING_ACCOUNT,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import { CHICAGO_MANUAL_DATE_FORMAT } from 'constants/date';
import { getDaysLeftCopy, getDaysUntilDate } from 'utils/date';
import { getLocalStorageKeyForProvider } from 'utils/localStorage';
import {
  is1DayFromLastAcknowledgedDate,
  isFirstModalRender,
} from 'utils/modals';

import { DEADLINE_TO_UPDATE } from './constants';

const GROUP_PRACTICE_BILLING_MODAL_ACKNOWLEDGED_DATE =
  'group_practice_billing_modal_acknowledged_date';

const formattedDeadline = moment(DEADLINE_TO_UPDATE).format(
  CHICAGO_MANUAL_DATE_FORMAT
);

type Props = ModalProps & {
  provider: ProviderRead;
};

const ModalBodyText = ({
  daysUntilDeadline,
  isFirstTimeShowingModal,
}: {
  daysUntilDeadline: number;
  isFirstTimeShowingModal: boolean;
}) => {
  if (daysUntilDeadline > 0) {
    return (
      <BodyText>
        {!isFirstTimeShowingModal && (
          <div css={{ marginBottom: theme.spacing.x3 }}>
            <GuidanceCard variant="warning">
              You have {getDaysLeftCopy(daysUntilDeadline)} to update your
              billing info. After {getDaysLeftCopy(daysUntilDeadline)} on{' '}
              {formattedDeadline}, providers in your group practice will not be
              able to confirm sessions.
            </GuidanceCard>
          </div>
        )}
        <p>
          <b>Group admins only:</b> We need you to re-verify your group practice
          and update your practice's bank account by {formattedDeadline} to
          prepare your 1099 for the 2023 tax year. This information only needs
          to be updated once by a group admin on behalf of your group practice.
        </p>
        <p>
          Headway requires that all payments for your group practice are
          deposited into one bank account. After you update your practice's
          billing information, we will deposit all payments moving forward for
          your group practice into the saved bank account.
        </p>
        {!isFirstTimeShowingModal && (
          <div css={{ marginTop: theme.spacing.x5 }}>
            <b>
              If your practice's billing info is not updated by{' '}
              {formattedDeadline}:
            </b>
            <ul>
              <li>
                Providers in your group practice will not be able to confirm
                sessions.
              </li>
              <li>Payments will be paused.</li>
            </ul>
          </div>
        )}
      </BodyText>
    );
  } else {
    return (
      <BodyText>
        <b>Until you update your practice's billing info:</b>
        <ol>
          <li>
            Providers in your group practice will not be able to confirm
            sessions.
          </li>
          <li>Payments will be paused.</li>
        </ol>
      </BodyText>
    );
  }
};

export const GroupPracticeBillingModal: React.FC<Props> = ({ provider }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const shouldAlwaysUseGpBillingAccount = useFlag(
    ALWAYS_USE_GP_BILLING_ACCOUNT
  );
  const shouldAllowGPToConfigureAccount = useFlag(
    ALLOW_GP_TO_CONFIGURE_OWN_BILLING_ACCOUNT
  );
  const shouldFetchGroupPracticeBillingAccount =
    isOpen &&
    !shouldAlwaysUseGpBillingAccount &&
    shouldAllowGPToConfigureAccount &&
    !!provider.groupPracticeId &&
    is1DayFromLastAcknowledgedDate(
      GROUP_PRACTICE_BILLING_MODAL_ACKNOWLEDGED_DATE,
      provider.id
    );
  const { data: billingAccount } = useQuery(
    ['billingAccount', provider.groupPracticeId],
    async () => {
      if (!provider.groupPracticeId) {
        return null;
      }

      return await GroupPracticeApi.getGroupPracticeBillingAccount(
        provider.groupPracticeId
      );
    },
    {
      enabled: shouldFetchGroupPracticeBillingAccount,
      onError: (e) => {
        logException(e);
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  if (!billingAccount || billingAccount.isVerified) {
    return null;
  }

  const isFirstTimeShowingModal = isFirstModalRender(
    GROUP_PRACTICE_BILLING_MODAL_ACKNOWLEDGED_DATE,
    provider.id
  );
  const daysUntilDeadline = getDaysUntilDate(moment(DEADLINE_TO_UPDATE));

  const getTitle = () => {
    let title = 'Update your billing information';

    if (daysUntilDeadline <= 0) {
      return title + ' to confirm sessions';
    }
    if (isFirstTimeShowingModal) {
      return title;
    }
    if (daysUntilDeadline > 1) {
      return title + `: ${daysUntilDeadline} days left`;
    }

    return title + ': Last day';
  };

  const setModalAcknowledgedDateAndCloseModal = () => {
    const key = getLocalStorageKeyForProvider(
      GROUP_PRACTICE_BILLING_MODAL_ACKNOWLEDGED_DATE,
      provider.id
    );
    window.localStorage.setItem(key, moment().format('YYYY-MM-DD'));
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={setModalAcknowledgedDateAndCloseModal}
      title={getTitle()}
    >
      <ModalContent>
        {daysUntilDeadline <= 0 && (
          <div css={{ marginBottom: theme.spacing.x3 }}>
            <GuidanceCard variant="warning">
              You did not update your billing information by {formattedDeadline}
            </GuidanceCard>
          </div>
        )}
        <ModalBodyText
          daysUntilDeadline={daysUntilDeadline}
          isFirstTimeShowingModal={isFirstTimeShowingModal}
        />
      </ModalContent>
      <ModalFooter>
        {daysUntilDeadline > 0 && (
          <Button
            variant="secondary"
            onPress={setModalAcknowledgedDateAndCloseModal}
          >
            Remind me later
          </Button>
        )}
        <Button
          variant="primary"
          onPress={() => {
            setModalAcknowledgedDateAndCloseModal();
            navigate('/settings/billing');
          }}
        >
          Update billing info
        </Button>
      </ModalFooter>
    </Modal>
  );
};
