import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderEventApi } from '@headway/api/resources/ProviderEventApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';
import { UseQueryOptions, UseQueryResult } from '@headway/shared/react-query';

export interface UseProviderEventQueryKeyArgs {
  eventIdOrVirtualId: number | string | undefined;
}
export type UseProviderEventQueryKey = [string, number | string | undefined];

export const getUseProviderEventQueryKey = ({
  eventIdOrVirtualId,
}: UseProviderEventQueryKeyArgs): UseProviderEventQueryKey => [
  'provider-event',
  eventIdOrVirtualId,
];

const {
  useSingleQuery: useProviderEvent,
  useListQuery: useProviderEventList,
  useCachedQuery: useProviderEventCache,
} = createBasicApiHooks(
  getUseProviderEventQueryKey,
  ({ eventIdOrVirtualId }) =>
    ProviderEventApi.getEventById(eventIdOrVirtualId!),
  ({ eventIdOrVirtualId }) => !!eventIdOrVirtualId,
  () => 'Failed to fetch event'
);

/**
 * Narrower typing which returns events where `id` is non-nullable. For use in cases where an event
 * can be presupposed to have an id.
 */
const useConcreteProviderEvent = useProviderEvent as <
  T = ConcreteProviderEventRead,
>(
  queryKeyArgs: UseProviderEventQueryKeyArgs,
  options?: UseQueryOptions<
    ConcreteProviderEventRead,
    unknown,
    T,
    ReturnType<typeof getUseProviderEventQueryKey>
  >
) => UseQueryResult<ConcreteProviderEventRead>;

const useConcreteProviderEventList = useProviderEventList as <
  T = ConcreteProviderEventRead,
>(
  queryArgs: {
    queryKeyArgs: UseProviderEventQueryKeyArgs;
    options?: UseQueryOptions<
      ConcreteProviderEventRead,
      unknown,
      T,
      ReturnType<typeof getUseProviderEventQueryKey>
    >;
  }[]
) => UseQueryResult<ConcreteProviderEventRead>[];

export {
  useProviderEvent,
  useProviderEventList,
  useProviderEventCache,
  useConcreteProviderEvent,
  useConcreteProviderEventList,
};
