import { Formik, useFormikContext } from 'formik';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { AuthApi } from '@headway/api/resources/AuthApi';
import { ProviderAccountInviteApi } from '@headway/api/resources/ProviderAccountInviteApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { TextField } from '@headway/helix/TextField';
import {
  IdentifyFlagsUser,
  withFlags,
} from '@headway/shared/FeatureFlags/react';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { logException } from '@headway/shared/utils/sentry';
import {
  notifyError,
  notifyInfo,
  notifyWarning,
} from '@headway/ui/utils/notify';

import DeprecatedAuthApi from 'api/AuthApi';
import { IroncladAgreementCheckbox } from 'components/IroncladAgreementCheckbox';
import {
  getContractIds,
  sendIroncladResponse,
} from 'components/IroncladAmendmentsModal/helpers/utils';
import { IroncladAgreementStatus } from 'components/IroncladAmendmentsModal/types/IroncladAgreementStatus';
import { useIroncladAgreementInfo } from 'hooks/useIroncladAgreementInfo';
import { hasContractAccess } from 'utils/access';
import { redirectToAuth0Logout, useAuth0Client } from 'utils/auth';
import { withReactRouterV5Props } from 'utils/migration/withReactRouterV5Props';

import { getLDAttributes } from '../../utils/analytics';
import { useTailwindGlobals } from '../../utils/css';

const EXPIRED_INVITE_DETAIL = 'INVITE_EXPIRED';
const EXPIRED_INVITE_MESSAGE =
  'The invite link you used has expired, so we sent you a new one. Please check your email inbox for a new invitation.';
const INVALID_TOKEN_MESSAGE =
  'The invited link you used is not valid. Please check your email inbox for the invitation.';

const RegistrationPage = inject('AuthStore')(
  observer(
    class RegistrationPage extends React.Component {
      state = {
        accountInvite: null,
        loaded: false,
      };

      verifyInvitation = async (token) => {
        // if there is a scoped token for PROVIDER_INVITE,
        // verify and set the cookie with the appropriate data.
        try {
          let user;
          if (!token) {
            // setTimeout accounts for the fact that navigate() should not be called during the
            // initial rendering of a component.
            setTimeout(() => {
              this.props.history.push('/auth/login');
            });
            notifyWarning(INVALID_TOKEN_MESSAGE);
            return;
          }

          try {
            // get basic info from this invite needed for registration
            user = await AuthApi.verifyProviderInvite({ token });
          } catch (e) {
            if (e.response?.data?.detail === EXPIRED_INVITE_DETAIL) {
              this.props.history.push('/auth/login');
              notifyInfo(EXPIRED_INVITE_MESSAGE);
              return;
            }
          }

          const invites =
            await ProviderAccountInviteApi.findProviderAccountInvites({
              user_id: user.id,
            });

          if (invites.length !== 1) {
            // TODO: make a decision and clean up.
            // this is out of legacy laziness as we do not know which invite to resolve.
            // one option - accept only the latest invite and make sure it's a valid invite
            throw new Error('Multiple provider account invites for user');
          } else {
            const accountInvite = invites[0];
            const provider = await ProviderApi.getProvider(
              accountInvite.providerId
            );

            await this.props.AuthStore.setUser(user);
            this.props.AuthStore.setProvider(provider);

            this.setState({
              accountInvite,
              loaded: true,
            });
          }
        } catch (err) {
          this.props.onLogout();
        }
      };

      componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        const token = params.get('token');
        this.verifyInvitation(token);
      }

      handleSubmit = async (values) => {
        try {
          // TODO: maybe accepting invite and completing account should be one endpoint..
          await ProviderAccountInviteApi.acceptProviderAccountInvite(
            this.state.accountInvite.id
          );

          // complete the account with password and name.
          // this will revoke the given token and reissue a full-access provider token
          // and log provider in.
          await AuthApi.completeAccount({
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
          });

          // get the user obj with the fields needed in sigmund
          const { data: user } = await DeprecatedAuthApi.me();
          const currentTime = moment().toISOString();
          let updatedProviderFields = {
            tosAcceptanceDate: currentTime,
            smsAgreementAcceptanceDate: currentTime,
          };

          const provider = await ProviderApi.updateProvider(
            user.provider_id,
            updatedProviderFields
          );
          this.props.AuthStore.setProvider(provider);
          await this.props.AuthStore.setUser(user);

          //Only submit agreement acceptance to Ironclad if an admin
          const contractSignRequired = hasContractAccess(provider, user);
          const contractIds = Object.values(
            getContractIds(
              provider,
              null,
              false,
              this.props.ironcladAgreementInfo
            )
          );

          if (contractSignRequired) {
            await sendIroncladResponse(
              this.props.ironcladAgreementInfo,
              IroncladAgreementStatus.AGREED,
              provider,
              user,
              this.props.flags.multiStateCredentialingBeta,
              contractIds
            );
          }

          this.props.history.push('/');
        } catch (err) {
          notifyError(
            err.response?.data?.message || 'Oops, something went wrong.'
          );
          logException(err);
        }
      };

      render() {
        const {
          flags: { multiStateCredentialingBeta },
          AuthStore: { provider, user },
          ironcladAgreementInfo,
          isLoadingIroncladAgreementInfo,
          isErrorIroncladAgreementInfo,
        } = this.props;
        const contractSignRequired = hasContractAccess(provider, user);
        const { groupKey, mainContractId, stateAddendumContractIds } =
          ironcladAgreementInfo || {};

        return (
          <React.Fragment>
            {this.state.loaded && user && provider && (
              <>
                <IdentifyFlagsUser
                  includeEmail
                  user={user}
                  userAttributes={getLDAttributes(user, provider)}
                />
                <div className="mx-auto w-full px-4 tablet:w-[970px] desktop:w-[1020px]">
                  <div className="rounded-sm mx-auto w-[512px] self-center justify-self-center bg-system-white px-6 py-5 shadow-medium">
                    <Formik
                      initialValues={{
                        firstName: user?.first_name || '',
                        lastName: user?.last_name || '',
                        email: user?.email || '',
                        password: '',
                        agreements: false,
                      }}
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().required(
                          'Please provide your first name'
                        ),
                        lastName: Yup.string().required(
                          'Please provide your last name'
                        ),
                        email: Yup.string()
                          .email('Please provide a valid email')
                          .required('Please provide an email address'),
                        password: Yup.string()
                          .min(8, 'Password must be at least 8 characters')
                          .max(
                            64,
                            'Passwords cannot be more than 64 characters'
                          )
                          .required('Please provide a password'),
                        ...(contractSignRequired
                          ? {
                              agreements: Yup.bool()
                                .required()
                                .oneOf(
                                  [true],
                                  'You must agree before continuing'
                                ),
                            }
                          : {}),
                      })}
                      onSubmit={this.handleSubmit}
                      enableReinitialize={true}
                    >
                      {(formik) => {
                        const { isSubmitting } = formik;
                        const noAgreementsFound = multiStateCredentialingBeta
                          ? !mainContractId ||
                            (stateAddendumContractIds &&
                              !Object.keys(stateAddendumContractIds))
                          : !groupKey;

                        if (
                          !this.props.AuthStore.user ||
                          !provider ||
                          !provider.providerLicenseState ||
                          isLoadingIroncladAgreementInfo
                        ) {
                          return null;
                        }
                        // TODO: figure out when to show guidance card
                        // groupKey seems to be loading slightly delayed
                        if (noAgreementsFound || isErrorIroncladAgreementInfo) {
                          return (
                            <GuidanceCard variant="error">
                              Could not find agreements for your account. Please
                              contact your PGA for assistance.
                            </GuidanceCard>
                          );
                        }

                        return (
                          <Form>
                            <div>
                              <h1 className="m-0">
                                <PageHeader>Welcome to Headway!</PageHeader>
                              </h1>
                              <BodyText>
                                We’re excited to partner with you to increase
                                access to quality and affordable mental
                                healthcare.
                              </BodyText>
                            </div>
                            <FormControl
                              component={TextField}
                              label="First name"
                              name="firstName"
                            />
                            <FormControl
                              component={TextField}
                              label="Last name"
                              name="lastName"
                            />
                            <FormControl
                              component={TextField}
                              label="Email"
                              name="email"
                              readonly
                              disabled
                            />
                            <FormControl
                              component={TextField}
                              label="Password"
                              name="password"
                              type="password"
                              autoComplete="new-password"
                            />

                            <TermsAndConditions
                              acceptanceRequired={contractSignRequired}
                            />
                            <Button
                              variant="brand"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Sign up with Headway
                            </Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        );
      }
    }
  )
);

const RegistrationPageWithQuery = (props) => {
  const {
    ironcladAgreementInfo,
    isLoading: isLoadingIroncladAgreementInfo,
    isError: isErrorIroncladAgreementInfo,
  } = useIroncladAgreementInfo(true);
  useTailwindGlobals();

  const auth0Client = useAuth0Client();

  return (
    <RegistrationPage
      {...props}
      ironcladAgreementInfo={ironcladAgreementInfo}
      isLoadingIroncladAgreementInfo={isLoadingIroncladAgreementInfo}
      isErrorIroncladAgreementInfo={isErrorIroncladAgreementInfo}
      onLogout={async () => {
        await redirectToAuth0Logout(auth0Client);
      }}
    />
  );
};

function TermsAndConditions({ acceptanceRequired }) {
  const formik = useFormikContext();

  const autoEnrollProviderReferralProgram = useFlag(
    'referralProgramAutoenroll'
  );

  let terms = [
    <span>
      Stripe's{' '}
      <Link
        href="https://stripe.com/us/connect-account/legal"
        target="_blank"
        rel="noopener noreferrer"
      >
        Connected Account Agreement
      </Link>
    </span>,
  ];

  if (autoEnrollProviderReferralProgram) {
    terms.push(
      <span>
        join{' '}
        <Link
          href="https://help.headway.co/hc/en-us/articles/11559127552404-Provider-referral-program#h_01H98M10WPKFVQDB2G5P9D38QD"
          target="_blank"
          rel="noopener noreferrer"
        >
          Headway's provider referral program
        </Link>
      </span>
    );
  }

  const commaSeparatedTerms = terms.reduce((acc, term, i) => {
    if (i === 0) {
      acc.push(term);
      return acc;
    }
    if (i === terms.length - 1) {
      acc.push(' and to ');
      acc.push(term);
      acc.push('.');
      return acc;
    }

    acc.push(', to ');
    acc.push(term);
    return acc;
  }, []);

  return (
    <div>
      {acceptanceRequired && <IroncladAgreementCheckbox formik={formik} />}
      <div className="mt-2">
        <BodyText>
          By clicking Sign up with Headway you agree to {commaSeparatedTerms}
        </BodyText>
      </div>
    </div>
  );
}

export default withReactRouterV5Props(
  withFlags(RegistrationPageWithQuery, {
    multiStateCredentialingBeta: undefined,
    ironcladProviderAgreements: undefined,
  })
);
