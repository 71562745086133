import { css, cx } from '@emotion/css';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import * as Yup from 'yup';

import { CalendarEventType } from '@headway/api/models/CalendarEventType';
import { ProgressNoteType } from '@headway/api/models/ProgressNoteType';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { UserRead } from '@headway/api/models/UserRead';
import { Checkbox } from '@headway/helix/Checkbox';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';

import { useProviderEvent } from 'hooks/useProviderEvent';
import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';

import {
  ProgressNoteContext,
  ProgressNoteState,
} from './stores/ProgressNotesContext';

export const AppointmentAttestation = ({
  patient,
  innerRef,
  eventVirtualId,
}: {
  patient: UserRead;
  innerRef: any;
  progressNoteOnly: boolean;
  eventVirtualId: string;
}) => {
  const { progressNoteType, progressNoteState } =
    useContext(ProgressNoteContext);

  const patientPayer =
    patient?.activeUserInsurance?.billingFrontEndCarrierName || 'insurance';

  const { data: event } = useProviderEvent({
    eventIdOrVirtualId: eventVirtualId,
  });
  const { data: editabilityStatus } = useSessionDetailsEditability(
    event?.calendarEventType === CalendarEventType.INSTANCE
      ? event?.originalRecurringEventId
      : event?.id
  );
  const canUpdateSessionDetails =
    editabilityStatus === SessionDetailsEditabilityStatus.ALLOWED;

  // We don't need the attestation if we are editing a template,
  // writing an addendum, or if the time to edit session details has passed
  if (
    (progressNoteType === ProgressNoteType.TEMPLATE &&
      (progressNoteState === ProgressNoteState.EDITING ||
        progressNoteState === ProgressNoteState.ADDENDUM_EDITING)) ||
    progressNoteState === ProgressNoteState.ADDENDUM_EDITING_FREE_TEXT ||
    !canUpdateSessionDetails
  ) {
    return null;
  }

  return (
    <Formik
      initialValues={{ attestation: false }}
      validationSchema={Yup.object().shape({
        attestation: Yup.boolean()
          .oneOf([true], 'Box must be checked to continue')
          .required(),
      })}
      onSubmit={(_values: { attestation: boolean }) => {}}
      innerRef={innerRef}
    >
      {() => {
        return (
          <Form>
            {progressNoteType === ProgressNoteType.TEMPLATE &&
            progressNoteState === ProgressNoteState.ADDENDUM_EDITING ? (
              <div className={cx(attestationContainerCss)}>
                <SectionHeader>Declaration</SectionHeader>
                <div
                  id="attestation"
                  data-testid="attestationCheckbox"
                  className={cx(checkboxContainerFlagCss)}
                >
                  <FormControl component={Checkbox} name="attestation">
                    {`I understand that compliant notes are required for payment. My notes are
                    true, accurate and meet ${patientPayer}'s requirements, and I'll provide supplemental information
                    on request within 3 business days. Further, I attest that I have not made modifications to the original note content and have included only new information in the addendum.`}
                  </FormControl>
                </div>
              </div>
            ) : (
              <div className={cx(attestationContainerCss)}>
                <SectionHeader>Declaration</SectionHeader>
                <div
                  id="attestation"
                  data-testid="attestationCheckbox"
                  className={cx(checkboxContainerFlagCss)}
                >
                  <FormControl component={Checkbox} name="attestation">
                    {`I understand that compliant notes are required for payment. My notes are
                  true, accurate and meet ${patientPayer}'s requirements, and I'll provide ${
                    progressNoteType === ProgressNoteType.NONE
                      ? 'my notes'
                      : 'supplemental information'
                  }
                  on request within 3 business days.`}
                  </FormControl>
                </div>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const attestationContainerCss = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingBottom: theme.spacing.x5,
});

const checkboxContainerFlagCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.spacing.x5,
});
