import { Formik } from 'formik';
import React, { useMemo, useState } from 'react';

import { Button } from '@headway/helix/Button';
import { Form } from '@headway/helix/Form';
import { IconLock } from '@headway/helix/icons/Lock';
import { LinkButton } from '@headway/helix/LinkButton';
import { PageHeader } from '@headway/helix/PageHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import {
  DEA_INTAKE_FORM_UPDATES,
  LICENSE_UPLOAD_DISABLED,
  MULTI_STATE_CREDENTIALING_BETA,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { isExistingProvider } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import { getProviderLicenseStatesPendingAgreement } from 'components/IroncladAmendmentsModal/helpers/utils';
import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useAuthStore } from 'stores/AuthStore';
import { isAdminImpersonatingProviderUser, isGroupAdmin } from 'utils/access';

import { OnBeforeSubmitErrorCard } from './OnBeforeSubmitErrorCard';
import { useQuestionnaireContext } from './QuestionnaireV2Context';
import { QuestionnaireV2Step } from './QuestionnaireV2Step';
import {
  FinalCaqhErrorsStepConfig,
  InitialCaqhErrorsStepConfig,
} from './steps/CaqhErrorsStep/CaqhErrorsStep';
import { getLatestProviderQuestionnaireCaqhProfile } from './utils/getLatestProviderQuestionnaireCaqhProfile';
import { CAQH_ZENDESK_ARTICLE } from './utils/intakeQuestionnaireConstants';

export type QuestionnaireStepProps = {
  stepInfo: QuestionnaireV2Step;
  currentStep: number;
  isFinalStep?: boolean;
  setHaveFormValuesChanged: (dirty: boolean) => void;
  onStepComplete: (stepData: object) => void;
  onStepGoBack: () => void;
};

export function QuestionnaireStep({
  onStepComplete,
  onStepGoBack,
  setHaveFormValuesChanged,
  stepInfo,
  currentStep,
  isFinalStep,
}: QuestionnaireStepProps) {
  const {
    title,
    description,
    recredDescription,
    Component,
    getFormMeta,
    onBeforeSubmit,
    css,
  } = stepInfo;
  const context = useQuestionnaireContext();
  const {
    provider,
    providerQuestionnaire,
    isRefreshing,
    refreshCaqhToIntakeMapping,
    disableSubmit,
    onBeforeSubmitError,
    setOnBeforeSubmitError,
  } = context;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const isMSCBetaEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA);
  const isLicenseUploadDisabled = useFlag(LICENSE_UPLOAD_DISABLED);
  const isDEAIntakeUpdatesEnabled = useFlag(DEA_INTAKE_FORM_UPDATES, false);
  const authStore = useAuthStore();
  const isOnWelcomeStep = isDEAIntakeUpdatesEnabled
    ? currentStep === 0
    : currentStep === -1;

  const { statesPendingAgreement } = useIroncladAgreementStatus({
    includeAllActiveProviderLicenseStates: true,
  });

  const providerLicenseStatesPendingAgreement =
    getProviderLicenseStatesPendingAgreement(provider, statesPendingAgreement);

  const isGPNonAdminAddingNewState =
    provider.groupPracticeId &&
    !isGroupAdmin(authStore.user) &&
    !!providerLicenseStatesPendingAgreement?.length;

  const { validationSchema, initialValue } = useMemo(() => {
    return getFormMeta(context, {
      [MULTI_STATE_CREDENTIALING_BETA]: isMSCBetaEnabled,
      [LICENSE_UPLOAD_DISABLED]: isLicenseUploadDisabled,
      [DEA_INTAKE_FORM_UPDATES]: isDEAIntakeUpdatesEnabled,
    });
  }, [
    getFormMeta,
    context,
    isMSCBetaEnabled,
    isLicenseUploadDisabled,
    isDEAIntakeUpdatesEnabled,
  ]);

  return (
    <>
      {title && (description || recredDescription) && (
        <div
          css={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <h1>
            <PageHeader>{title}</PageHeader>
          </h1>
          <LinkButton
            variant="secondary"
            size="medium"
            href={CAQH_ZENDESK_ARTICLE}
            target="_blank"
            rel="noreferrer"
          >
            Need help? See our Help Center guide
          </LinkButton>
        </div>
      )}
      <div css={css?.formikContainer ?? { padding: `${theme.spacing.x4} 0` }}>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValue}
          enableReinitialize={true}
          onSubmit={async (formVal, { setFieldError }) => {
            if (
              !isAdminImpersonatingProviderUser(
                authStore.user,
                authStore.impersonatingUser
              ) &&
              providerQuestionnaire.completedOn
            ) {
              console.error(
                'Provider attempting to submit a intake step for a completed questionnaire'
              );
              return;
            }

            setOnBeforeSubmitError(null);
            if (onBeforeSubmit) {
              const stepData = await onBeforeSubmit(
                formVal,
                setOnBeforeSubmitError,
                setShowConfirmationModal,
                context,
                setFieldError,
                authStore.user
              );
              if (!stepData) {
                return;
              } else if (typeof stepData === 'object') {
                return onStepComplete(stepData);
              }
            }

            return onStepComplete(formVal);
          }}
        >
          {(formikHelpers) => {
            // For TSE troubleshooting efforts - please don't remove
            console.log(formikHelpers.errors);
            setHaveFormValuesChanged(formikHelpers.dirty);
            return (
              <Form name={`intake-form-page`}>
                <Component
                  initialValues={initialValue}
                  formikHelpers={formikHelpers}
                  showConfirmationModal={showConfirmationModal}
                  setShowConfirmationModal={setShowConfirmationModal}
                  onStepComplete={onStepComplete}
                />
                {onBeforeSubmitError !== null && (
                  <OnBeforeSubmitErrorCard errorType={onBeforeSubmitError} />
                )}
                <div
                  css={
                    css?.submissionContainer ?? {
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingTop: theme.spacing.x6,
                      gap: theme.spacing.x2,
                    }
                  }
                >
                  <div
                    css={
                      css?.submissionButtonContainer ?? {
                        display: 'flex',
                        gap: theme.spacing.x2,
                      }
                    }
                  >
                    {!isOnWelcomeStep && (
                      <Button
                        variant="secondary"
                        type="button"
                        onPress={() => {
                          onStepGoBack();
                        }}
                      >
                        Back
                      </Button>
                    )}
                    {[
                      InitialCaqhErrorsStepConfig,
                      FinalCaqhErrorsStepConfig,
                    ].includes(stepInfo) &&
                    !!getLatestProviderQuestionnaireCaqhProfile(
                      context?.providerQuestionnaire || {}
                    )?.errors?.length ? (
                      <Button
                        disabled={isRefreshing}
                        onPress={async () => {
                          await refreshCaqhToIntakeMapping(
                            Number(
                              providerQuestionnaire?.rawData?.caqhNumberFromNpi
                            )
                          );
                        }}
                      >
                        Refresh
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        disabled={
                          disableSubmit ||
                          formikHelpers.isSubmitting ||
                          (isFinalStep && isGPNonAdminAddingNewState) ||
                          (isFinalStep && !!providerQuestionnaire.completedOn)
                        }
                      >
                        {isOnWelcomeStep
                          ? 'Get started'
                          : isFinalStep
                          ? 'Submit'
                          : 'Save and continue'}
                      </Button>
                    )}
                    {isDEAIntakeUpdatesEnabled &&
                      isOnWelcomeStep &&
                      isExistingProvider(provider) && (
                        <LinkButton variant="secondary" href={'/'}>
                          Return to provider portal
                        </LinkButton>
                      )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {isDEAIntakeUpdatesEnabled && isOnWelcomeStep && (
          <div className="flex flex-row gap-2">
            <div className="min-width-5">
              <IconLock fill={theme.color.system.black} />
            </div>
            <div className="flex flex-col items-start gap-2">
              <SubBodyText>
                <strong className="text-system-gray">
                  Headway is HIPAA-compliant. All information you share with us
                  will only be used to get you credentialed and onboarded.
                </strong>{' '}
              </SubBodyText>
              <LinkButton
                size="medium"
                variant="link"
                href="https://help.headway.co/hc/en-us/articles/360058922872-HIPAA-compliance-and-PHI"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-system-gray">
                  Learn more about how we comply with HIPAA requirements
                </span>
              </LinkButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
