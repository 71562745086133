import { axios } from './axios.config';

let API_URL = `/messages`;

export class MessagesApi {
  static findMessages(params) {
    return axios.get(API_URL, { params });
  }

  static create(createMessage) {
    return axios.post(API_URL, createMessage);
  }
}

export class Message {
  static Type = {
    GENERAL: 'GENERAL',
    PROVIDER_CANCELLATION: 'PROVIDER_CANCELLATION',
    APPOINTMENT_TO_INTAKE_CALL: 'APPOINTMENT_TO_INTAKE_CALL',
    PATIENT_BOOKING: 'PATIENT_BOOKING',
    PROVIDER_BOOKING: 'PROVIDER_BOOKING',
    PROVIDER_RESCHEDULE: 'PROVIDER_RESCHEDULE',
  };
}
