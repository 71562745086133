import { apiObject, apiOptions } from 'rudder-sdk-js';

import { AvoEvent } from '@headway/avo';

import {
  aliasUser as aliasUserInRudderStack,
  identifyLoggedOutUser as identifyLoggedOutUserInRudderStack,
  identifyUser as identifyUserInRudderStack,
  trackEvent as trackEventInRudderStack,
  trackPage as trackPageInRudderStack,
} from './rudderStack';
import { identifyUser as identifyUserInSentry } from './sentry';

/**
 * Send event to RudderStack, type signature will be inferred by name.
 * @example <caption>To use the Log In Started event:</caption>
 * trackEvent({ name: 'Log In Started' });
 */
export function trackEvent({
  name,
  properties = {},
  options = {},
  onComplete = () => {},
}: AvoEvent): void {
  trackEventInRudderStack(name, properties as apiObject, options, onComplete);
}

export const trackErrorEvent = (
  errorReason: string,
  errorContext: { [context: string]: string },
  errorType?: string
) => {
  trackEvent({
    name: 'Error Encountered',
    properties: {
      errorMessage: errorReason,
      errorContext,
      errorType,
    },
  });
};

export const identifyUser = ({
  id,
  isAuthenticated,
  traits = {},
  options = {},
  onComplete = () => {},
}: {
  id: string;
  isAuthenticated: boolean;
  traits?: { [key: string]: any };
  options?: apiOptions;
  onComplete?: () => void;
}): void => {
  identifyUserInSentry({ id });
  /**
   * Even though we have user ids for unauthenticated/anonymous users, RudderStack
   * only expects us to send user ids for logged-in users. Don't send user ids for
   * unauthenticated users so RudderStack can assign anonymous ids correctly
   * https://www.rudderstack.com/docs/rudderstack-api/api-specification/rudderstack-spec/identify/
   */
  const rudderStackUserId = isAuthenticated ? id : undefined;
  identifyUserInRudderStack(rudderStackUserId, traits, options, onComplete);
};

/**
 * Identify a user after they log out to track actions even after logout
 */
export const identifyLoggedOutUser = () => {
  identifyLoggedOutUserInRudderStack();
};

/**
 * Merge two user identities
 */
export const aliasUser = (
  newId: string,
  oldId?: string,
  options?: apiOptions,
  onComplete?: () => void
) => {
  aliasUserInRudderStack(newId, oldId, options, onComplete);
};

/**
 * Send page view to RudderStack, type signature will be inferred by name.
 * @example <caption>To use the Topics page view:</caption>
 * trackPageView({ name: 'Topics' });
 */
export function trackPageView({
  name,
  properties = {},
  options = {},
  onComplete = () => {},
}: AvoEvent): void {
  trackPageInRudderStack(name, properties as apiObject, options, onComplete);
}

type ExperimentGroup = 'control' | 'treatment';

export const trackFlagView = ({
  flag,
  properties = {},
  options = {},
  onComplete = () => {},
}: {
  flag: { name: string; group: ExperimentGroup };
  properties?: { [key: string]: any };
  options?: apiOptions;
  onComplete?: () => void;
}): void => {
  trackEventInRudderStack(
    'Experiment Viewed',
    { experimentName: flag.name, experimentGroup: flag.group, ...properties },
    options,
    onComplete
  );
};

/**
 * Takes a boolean value from a feature flag and converts it to the expected
 * value for analytics. Expects the feature flag to return a boolean where
 * true = the new feature is on and false = the feature is off.
 */
export const booleanToExperimentGroup = (bool: boolean): ExperimentGroup =>
  bool ? 'treatment' : 'control';
