export const getListFormatter = (locale: string) => {
  try {
    return new Intl.ListFormat(locale);
  } catch (e) {
    return {
      format: (arr: string[]) => {
        if (!arr?.length) {
          return '';
        }
        const last = arr[arr.length - 1];
        const arrMinusLast = arr.slice(0, arr.length - 1);
        return arrMinusLast.join(', ') + ' and ' + last;
      },
    };
  }
};
