import { EventTwoTone } from '@mui/icons-material';
import { PopoverActions } from '@mui/material';
import React, { FC, useLayoutEffect, useRef } from 'react';

import { useSelectedEvent } from 'hooks/useSelectedEvent';
import { useAuthStore } from 'stores/AuthStore';

import { getEventIdentifierClass } from '../events/util/events';
import { useProviderCalendarsQuery } from '../utils/queries';
import { DetailPopover } from './DetailPopover';
import { EventContent } from './EventContent';
import { EventDetail } from './EventDetail';
import { EventHeader } from './EventHeader';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ExternalUnavailabilityDetail: FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
}) => {
  const { event } = useSelectedEvent();
  const calendarId = event?.providerCalendarId;
  const action = useRef<PopoverActions | null>(null);
  const auth = useAuthStore();
  const calendarsQuery = useProviderCalendarsQuery(auth.provider.id, {
    enabled: true,
  });

  useLayoutEffect(() => {
    if (isOpen && event && action.current) {
      action.current.updatePosition();
    }
  }, [event, isOpen]);

  if (!event) return null;

  const anchorEl = document.querySelector(`.${getEventIdentifierClass(event)}`);

  const calendarName = calendarsQuery.data?.find(
    (calendar) => calendar.id === calendarId
  )?.calendarName;

  return (
    <DetailPopover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      action={action}
      PaperProps={{
        style: {
          width: 448,
        },
      }}
    >
      <EventHeader
        title={event.title ? `Unavailable (${event.title})` : 'Unavailable'}
        onClose={onClose}
        startDate={event.startDate!}
        recurrence={event.recurrence}
        eventHasContents={true}
      />
      {calendarName ? (
        <EventContent>
          <EventDetail
            title="External Calendar"
            icon={EventTwoTone}
            label={calendarName}
          />
        </EventContent>
      ) : null}
    </DetailPopover>
  );
};

export { ExternalUnavailabilityDetail };
