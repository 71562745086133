import { css } from '@emotion/react';
import React from 'react';

import { theme } from '@headway/ui/theme';

const cardRadioButtonGroupContainerStyle = css`
  display: flex;
  flex-direction: row;
  ${theme.media.smallDown} {
    flex-direction: column;
  }
`;

interface CardRadioButtonGroupProps {
  onSelect(value: string): void;
  selected?: string;
}
export const CardRadioButtonGroup: React.FC<
  React.PropsWithChildren<CardRadioButtonGroupProps>
> = ({ children, onSelect, selected }) => {
  const [selectedValue, setSelectedValue] = React.useState(selected);

  return (
    <div css={cardRadioButtonGroupContainerStyle}>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          onClick: () => {
            setSelectedValue(child.props.value);
            onSelect(child.props.value);
          },
          selected: selectedValue ? selectedValue === child?.props.value : null,
        });
      })}
    </div>
  );
};
