import { ListState, useListState } from '@react-stately/list';
import type {
  CollectionBase,
  MultipleSelection,
  Node,
} from '@react-types/shared';
import { Key } from 'react';

interface MultiSelectListProps<T>
  extends CollectionBase<T>,
    MultipleSelection {}

export interface MultiSelectListState<T> extends ListState<T> {
  /** The keys for the currently selected items. */
  selectedKeys: Set<Key>;

  /** Sets the selected keys. */
  setSelectedKeys(keys: Iterable<Key>): void;

  /** The value of the currently selected items. */
  selectedItems: Node<T>[] | null;

  /** The type of selection. */
  selectionMode: MultipleSelection['selectionMode'];
}

export function useMultiSelectListState<T extends {}>(
  props: MultiSelectListProps<T>
): MultiSelectListState<T> {
  const {
    collection,
    disabledKeys,
    selectionManager,
    selectionManager: { setSelectedKeys, selectedKeys, selectionMode },
  } = useListState(props);

  const missingKeys: Key[] = [];

  const selectedItems = [];

  for (const key of Array.from(selectedKeys)) {
    const item = collection.getItem(key);

    if (item) {
      selectedItems.push(item);
    } else {
      missingKeys.push(key);
    }
  }

  if (missingKeys.length) {
    // eslint-disable-next-line no-console
    console.warn(
      `Select: Keys "${missingKeys.join(
        ', '
      )}" passed to "selectedKeys" are not present in the collection.`
    );
  }

  return {
    collection,
    disabledKeys,
    selectionManager,
    selectionMode,
    selectedKeys,
    setSelectedKeys: setSelectedKeys.bind(selectionManager),
    selectedItems: selectedItems.length > 0 ? selectedItems : null,
  };
}
