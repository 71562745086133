import React from 'react';

import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';

import { Component, Data, SchemaComponent } from '../../schema/schema.types';
import { getComponentData } from '../../schema/utils';
import { EmbedVideoDataInterface } from './EmbedVideo.types';
import { FormTitle } from './FormTitle';

const getYoutubeVideoId = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return match && match[2].length === 11 ? match[2] : null;
};

const EmbedVideo = ({ element, injectedData }: SchemaComponent) => {
  const { title, subTitle } = element;
  const { videoLink } = getComponentData<EmbedVideoDataInterface>(
    element,
    injectedData
  );

  let embedLink: string | undefined;
  if (videoLink) {
    const videoId = getYoutubeVideoId(videoLink);
    if (videoId) {
      embedLink = `//www.youtube.com/embed/${videoId}`;
    }
  }

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
      <FormTitle title={title} subTitle={subTitle} />
      {embedLink ? (
        <iframe
          css={{ flexGrow: 1, marginTop: theme.spacing.x2 }}
          src={embedLink}
          frameBorder="0"
        />
      ) : (
        <div
          css={{
            flexGrow: 1,
            marginTop: theme.spacing.x2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SubBodyText> Add a video link</SubBodyText>
        </div>
      )}
    </div>
  );
};

EmbedVideo.validate = (componentData: Component): string[] => {
  const data = componentData.data;
  if (
    data?.length &&
    data[0].value &&
    !data[0].value.includes('https://www.youtube.com/')
  ) {
    return ['Youtube link must start with https://www.youtube.com'];
  }

  return [];
};

export { EmbedVideo };
