import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Divider } from '@headway/helix/Divider';
import { FormControl } from '@headway/helix/FormControl';
import { Switch } from '@headway/helix/Switch';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';

import { ComponentNames } from '../../renderer/components';
import { FormValues } from '../../schema/schema.types';
import { Component } from '../../schema/schema.types';

const validationWithDefaults = [
  {
    name: 'Is Required',
    type: 'required',
    params: ['This field is required.'],
  },
  {
    name: 'Minimum Length',
    type: 'min',
    params: [400, 'This requires a minimum of 400 characters'],
  },
];

export const FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE = 'This is required.';
const MIN_LENGTH_DEFAULT_ERROR_MESSAGE =
  'This requires a minimum of 400 characters.';
const MIN_LENGTH_DEFAULT_MIN_NUMBER = 400;

export const getValidationInitialValues = (component: Component) => {
  const isRequiredValidationObject = component.validations?.find(
    (validation) => validation.type === 'required'
  );

  const isRequired = isRequiredValidationObject !== undefined;
  const isRequiredErrorMessage = isRequired
    ? isRequiredValidationObject.params[0].toString()
    : FIELD_REQUIRED_DEFAULT_ERROR_MESSAGE;

  const minLengthValidationObject = component.validations?.find(
    (validation) => validation.type === 'min'
  );
  const minLength = minLengthValidationObject !== undefined;
  const minLengthErrorMessage = minLength
    ? minLengthValidationObject.params[1].toString()
    : MIN_LENGTH_DEFAULT_ERROR_MESSAGE;
  const minLengthNumber = minLength
    ? minLengthValidationObject.params[0]
    : MIN_LENGTH_DEFAULT_MIN_NUMBER;

  return {
    isRequired: isRequired,
    minLength: minLength,
    isRequiredErrorMessage: isRequiredErrorMessage,
    minLengthErrorMessage: minLengthErrorMessage,
    minLengthNumber: minLengthNumber.toString(),
  };
};

export const getSchemaValidations = (formValues: FormValues) => {
  const newValidations: Array<{
    type: string;
    params: Array<string | number>;
  }> = [];
  if (formValues.isRequired) {
    newValidations.push({
      type: 'required',
      params: [formValues.isRequiredErrorMessage],
    });
  }
  if (formValues.minLength) {
    newValidations.push({
      type: 'min',
      params: [
        parseInt(formValues.minLengthNumber),
        formValues.minLengthErrorMessage,
      ],
    });
  }

  return newValidations;
};

export const Validations = ({
  setFieldValue,
  component,
  values,
}: {
  setFieldValue: any;
  component: Component;
  values: any;
}) => {
  return (
    <div css={{ marginBottom: theme.spacing.x11 }}>
      <BodyText>
        <strong>Validations</strong>
      </BodyText>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme.spacing.x3,
          gap: theme.spacing.x2,
        }}
      >
        <Switch
          name="isRequired"
          onChange={(value) => {
            setFieldValue('isRequired', value);
            if (!value) {
              setFieldValue('minLength', false);
            }
          }}
          selected={values.isRequired}
        >
          Is this field required?
        </Switch>
        {values.isRequired && (
          <FormControl
            component={TextField}
            label="What message should we display if the field is incomplete?"
            name="isRequiredErrorMessage"
            defaultValue={values.isRequiredErrorMessage}
          />
        )}
        {values.isRequired &&
          (component.type === ComponentNames.shortFreeText ||
            component.type === ComponentNames.longFreeText ||
            component.type === ComponentNames.richFreeText) && (
            <>
              <Divider autoSpacing={false} />
              <Switch
                name="minLength"
                onChange={(value) => setFieldValue('minLength', value)}
                selected={values.minLength}
              >
                Minimum length
              </Switch>
            </>
          )}
        {values.minLength && (
          <>
            <TextField
              label="Length"
              name="minLengthNumber"
              defaultValue={values.minLengthNumber.toString()}
            />
            <TextField
              label="Error message"
              name="minLengthErrorMessage"
              defaultValue={values.minLengthErrorMessage}
            />
          </>
        )}
      </div>
    </div>
  );
};
