import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { UserRead } from '@headway/api/models/UserRead';

export interface FunctionState {
  patient: UserRead;
  event?: ProviderEventRead;
}

export interface FunctionMap {
  functionName: TemplateFunctionNames;
  functionArguments?: any[];
  areArgumentsComposed?: boolean;
}

/**
 * Calls the functions defined in the FunctionMap array in sequential
 * order, injecting the FunctionState when calling the specified function.
 *
 * Multiple functions can be declared, allowing function composition.
 * As an example, say the FunctionMap array was = [ f(x), g(y)].
 * If g's arguments were composed, the evaluation would be g(f(x)).
 * You can still pass regular function arguments to a composed function, the composed value
 * would be the first parameter - g(f(x), "hello")
 */
export const callFunctions = <T extends TemplateFunctionNames>(
  state: FunctionState,
  functionsToCall: FunctionMap[]
): (typeof templateFunctions)[T] => {
  let functionEvaluationValues: unknown[] = [];

  functionsToCall.forEach((info) => {
    if (!templateFunctions[info.functionName]) {
      console.error(
        `Error no template function named ${info.functionName} exists`
      );
      throw new Error(
        `Error no matching template function named ${info.functionName} exists`
      );
    }

    if (info.areArgumentsComposed) {
      // Calls the function adding in all the returned values from the previous functions
      // in the order they were called, as well as any functionArguments defined for this function
      const returnValue = (
        templateFunctions[info.functionName] as Function
      ).call(
        undefined,
        state,
        ...Object.values(functionEvaluationValues),
        ...(info.functionArguments ? info.functionArguments : [])
      );

      // Once a composed function is called we clear the map since we
      // don't need it anymore, and set the new composed value in
      functionEvaluationValues = [];
      functionEvaluationValues.push(returnValue);
    } else {
      // Calls the function passing in any functionArguments defined for it
      functionEvaluationValues.push(
        (templateFunctions[info.functionName] as Function).call(
          undefined,
          state,
          ...(info.functionArguments ? info.functionArguments : [])
        )
      );
    }
  });

  // We always return the last evaluated functions value
  return functionEvaluationValues.pop() as (typeof templateFunctions)[T];
};

export type TemplateFunctionNames = keyof typeof templateFunctions;

export const templateFunctions = {
  andOperator: (_: FunctionState, ...composedArgs: boolean[]) => {
    return composedArgs.every((value) => value);
  },
  isPatientAgeInInclusiveRange: (
    state: FunctionState,
    minPatientAge: number,
    maxPatientAge: number
  ): boolean => {
    if (!state.patient.dob) {
      return false;
    }

    // maxPatientsAge years ago from event start date (or today if no event)
    const maxPatientAgeDate = state.event?.startDate
      ? new Date(state.event.startDate)
      : new Date();
    maxPatientAgeDate.setFullYear(
      maxPatientAgeDate.getFullYear() - maxPatientAge
    );
    maxPatientAgeDate.setHours(0);
    maxPatientAgeDate.setMinutes(0);
    maxPatientAgeDate.setSeconds(0);

    // minPatientsAge years ago from event start date (or today if no event)
    const minPatientAgeDate = state.event?.startDate
      ? new Date(state.event.startDate)
      : new Date();
    minPatientAgeDate.setFullYear(
      minPatientAgeDate.getFullYear() - minPatientAge
    );
    minPatientAgeDate.setHours(23);
    minPatientAgeDate.setMinutes(59);
    minPatientAgeDate.setSeconds(59);

    // assert that the patient dob is between the range
    const patientDOB = new Date(state.patient.dob);
    return patientDOB <= minPatientAgeDate && patientDOB >= maxPatientAgeDate;
  },
};
