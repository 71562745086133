import { ReactNode } from 'react';

import { theme } from './theme';

export interface BodyTextProps {
  children: ReactNode;
  color?: 'textBlack' | 'gray' | 'white';
}

export const BodyText = ({ children, color = 'textBlack' }: BodyTextProps) => {
  return (
    <span
      className="hlx-typography-body"
      style={{ color: COLOR_STYLE_MAP[color] }}
    >
      {children}
    </span>
  );
};

const COLOR_STYLE_MAP: Record<NonNullable<BodyTextProps['color']>, string> = {
  textBlack: theme.color.system.textBlack,
  gray: theme.color.system.gray,
  white: theme.color.system.white,
};
