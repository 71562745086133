import { FormControlLabel, FormControlLabelProps, Switch } from '@mui/material';
import { SwitchProps } from '@mui/material/Switch';
import { useFormikContext } from 'formik';
import React, { FC, RefObject } from 'react';

import { FieldControlContext } from '@headway/ui/form/FieldControl';

import { theme } from '../theme';
import { RegisteredRef } from './Form';
import { useField } from './useField';

type FieldSwitchProps = SwitchProps & {
  negatedField?: boolean;
  label?: string;
  // Props to apply to the FormControlLabel
  labelProps?: FormControlLabelProps;
  // Restricting type of ref so that is can be used with useField
  ref?: RefObject<RegisteredRef>;
};

export const FieldSwitch: FC<React.PropsWithChildren<FieldSwitchProps>> = ({
  negatedField,
  label,
  labelProps,
  ...switchProps
}) => {
  const { name, id } = React.useContext(FieldControlContext);
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name, ...switchProps });

  return (
    <FormControlLabel
      htmlFor={id}
      label={label}
      css={{
        color: theme.color.black,
        '.MuiFormControlLabel-label': {
          fontFamily: theme.fontFamily.postGrotesk,
        },
      }}
      control={
        <Switch
          id={id}
          checked={!!field.value !== !!negatedField}
          color="primary"
          onChange={() => {
            setFieldValue(name, !field.value);
          }}
          {...switchProps}
        />
      }
      {...labelProps}
    />
  );
};
