import { ThemeOptions } from '@mui/material/styles';

export const themeOverrides: ThemeOptions = {
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: 'rgba(0, 0, 0, 0.87) !important',
          },
        },
        underline: {
          '&:hover:not($disabled):before,&:before': {
            borderColor: '#D2D2D2 !important',
            borderWidth: '1px !important',
          },
          '&$disabled:before': {
            borderColor: '#D2D2D2 !important',
            borderWidth: '1px !important',
            borderBottomStyle: 'solid !important',
          },
          '&:after': {
            borderColor: '#13aa65',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          ' .MuiMenuItem-root': {},
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // Emulate Material UI 4 styles, which applied the MuiListItem class to menu items.
          '&.MuiButtonBase-root': {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '16px',
            paddingRight: '16px',
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
    },
  },
};
