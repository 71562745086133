export enum RecurringUpdateApplyTo {
  THIS_EVENT = 'THIS_EVENT',
  FOLLOWING_EVENTS = 'FOLLOWING_EVENTS',
}

export enum RecurringUpdateType {
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum longTimeZoneAbbrs {
  EST = 'Eastern Standard Time',
  EDT = 'Eastern Daylight Time',
  CST = 'Central Standard Time',
  CDT = 'Central Daylight Time',
  MST = 'Mountain Standard Time',
  MDT = 'Mountain Daylight Time',
  PST = 'Pacific Standard Time',
  PDT = 'Pacific Daylight Time',
}
