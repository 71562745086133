import { useSlotId } from '@react-aria/utils';
import clsx from 'clsx';
import React from 'react';
import { mergeProps } from 'react-aria';
import {
  Button,
  Checkbox,
  Collection,
  TreeItemProps,
  TreeProps,
  UNSTABLE_Tree,
  UNSTABLE_TreeItem,
  UNSTABLE_TreeItemContent,
  UNSTABLE_TreeStateContext,
} from 'react-aria-components';
import { TreeItemContentRenderProps } from 'react-aria-components';

import { CheckedIcon, IndeterminateIcon, UncheckedIcon } from './Checkbox';
import { DATA } from './consts';
import { IconChevronDown } from './icons/ChevronDown';
import { IconChevronUp } from './icons/ChevronUp';

interface DynamicTreeItemProps extends TreeItemProps<object> {
  children: React.ReactNode;
  description?: React.ReactNode;
  item: any;
}

const CheckboxTreeItem = (props: DynamicTreeItemProps) => {
  let { item, childItems, description, ...rest } = props;

  return (
    <UNSTABLE_TreeItem
      {...rest}
      className={({
        isFocused,
        isSelected,
        isHovered,
        isFocusVisible,
        isDisabled,
      }) =>
        clsx('hlx-tree-item', {
          focused: isFocused,
          'focus-visible': isFocusVisible,
          selected: isSelected,
          hovered: isHovered,
          disabled: isDisabled,
        })
      }
    >
      <UNSTABLE_TreeItemContent>
        {(context) => {
          return (
            <TreeItemContent
              description={description}
              childItems={item.childItems}
              item={item}
              context={context}
            >
              {props.children}
            </TreeItemContent>
          );
        }}
      </UNSTABLE_TreeItemContent>
      <Collection items={childItems}>
        {(item: any) => (
          <CheckboxTreeItem
            childItems={item.childItems}
            textValue={item.name}
            item={item}
          >
            {item.name}
          </CheckboxTreeItem>
        )}
      </Collection>
    </UNSTABLE_TreeItem>
  );
};

interface TreeItemContentProps {
  context: TreeItemContentRenderProps;
  description?: React.ReactNode;
  item: any;
  childItems: any[];
  children: React.ReactNode;
}

function TreeItemContent({
  childItems,
  item,
  description,
  context,
  children,
}: TreeItemContentProps) {
  const descriptionId = useSlotId([Boolean(description)]);

  // this is not available via React Context yet but I suspect it will be
  // once the Tree component is stable
  const { isDisabled, level, isExpanded, hasChildRows } = context;

  return (
    <div
      className="hlx-tree-item-content"
      data-hlx-disabled={isDisabled}
      style={
        {
          ['--level']: level,
        } as React.CSSProperties
      }
    >
      <div className="hlx-tree-item-group">
        <TreeCheckbox item={item} context={context} />

        {hasChildRows ? (
          <>
            <span className="hlx-tree-item-label">
              {children} ({Array.from(childItems).length})
            </span>
            <Button
              className={({
                isHovered,
                isFocused,
                isPressed,
                isFocusVisible,
              }) =>
                clsx('hlx-tree-expand-toggle', {
                  hovered: isHovered,
                  focused: isFocused,
                  pressed: isPressed,
                  'focus-visible': isFocusVisible,
                })
              }
              slot="chevron"
            >
              {isExpanded ? <IconChevronUp /> : <IconChevronDown />}
            </Button>
          </>
        ) : (
          <span className="hlx-tree-item-label">{children}</span>
        )}
      </div>
      {description && (
        <div id={descriptionId} className="hlx-tree-item-description">
          {description}
        </div>
      )}
    </div>
  );
}

interface TreeCheckboxProps {
  item: any;
  context: TreeItemContentRenderProps;
}

function TreeCheckbox({ context, item, ...props }: TreeCheckboxProps) {
  const state = React.useContext(UNSTABLE_TreeStateContext);

  const key = item.key ?? item.id;

  // includes this item and all its children

  const childItems: any[] = Array.from(item.childItems ?? []);
  const hasChildItems = childItems.length > 0;

  const childItemKeys = new Set(
    childItems.map((child) => child.key ?? child.id)
  );
  const allChildrenSelected = Array.from(childItemKeys).every((key) =>
    state.selectionManager.isSelected(key)
  );

  const noChildrenSelected = Array.from(childItemKeys).every(
    (key) => !state.selectionManager.isSelected(key)
  );

  const isIndeterminate =
    hasChildItems && !allChildrenSelected && !noChildrenSelected;

  let isSelected = state.selectionManager.isSelected(key);

  return (
    <Checkbox
      className="hlx-checkbox hlx-checkbox-root"
      slot="selection"
      {...mergeProps({
        [DATA.DISABLED]: context.isDisabled,
        [DATA.HOVERED]: context.isHovered,
      })}
      {...props}
      isIndeterminate={isIndeterminate}
      isSelected={isSelected}
    >
      {({ isSelected, isIndeterminate, isFocusVisible }) => {
        const Icon = isIndeterminate
          ? IndeterminateIcon
          : isSelected
          ? CheckedIcon
          : UncheckedIcon;

        return (
          <Icon
            className={clsx('hlx-checkbox-control', {
              'focus-ring': context.isFocusVisible || isFocusVisible,
            })}
          />
        );
      }}
    </Checkbox>
  );
}

function CheckboxTree<T extends object>(props: TreeProps<T>) {
  return (
    <UNSTABLE_Tree
      {...props}
      className="hlx-tree"
      selectionBehavior="toggle"
      selectionMode="multiple"
      disabledBehavior="all"
    />
  );
}

export { CheckboxTree, CheckboxTreeItem };
