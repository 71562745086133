import clsx from 'clsx';
import React from 'react';
import {
  AriaLinkOptions,
  mergeProps,
  useFocusRing,
  useHover,
  useLink,
} from 'react-aria';
import { Simplify } from 'type-fest';

import { DATA } from './consts';
import { PickAndConfigure } from './utils/PickAndConfigure';

export type LinkProps<T extends 'a' | React.JSXElementConstructor<any>> =
  Simplify<
    {
      component?: T;
      children: React.ReactNode;
    } & React.ComponentProps<T> &
      PickAndConfigure<
        AriaLinkOptions,
        'autoFocus' | { disabled?: 'isDisabled' }
      > &
      // Add elementType if component isn't 'a''
      (T extends 'a' ? {} : { elementType: AriaLinkOptions['elementType'] })
  >;

function Link<T extends 'a' | React.JSXElementConstructor<any> = 'a'>({
  component: Component = 'a',
  elementType,
  disabled,
  ...props
}: LinkProps<T>) {
  const ref = React.useRef<HTMLAnchorElement>(null);
  const { linkProps } = useLink(
    { elementType, isDisabled: disabled, ...props },
    ref
  );
  const { hoverProps, isHovered } = useHover({ isDisabled: disabled });
  const { focusProps, isFocusVisible } = useFocusRing({
    autoFocus: props.autoFocus,
  });

  return (
    <Component
      {...props}
      className={clsx(
        'hlx-link',
        {
          'focus-ring': isFocusVisible,
        },
        props.className
      )}
      {...mergeProps(linkProps, hoverProps, focusProps, {
        [DATA.DISABLED]: disabled,
        [DATA.HOVERED]: isHovered,
      })}
      ref={ref}
    >
      {props.children}
    </Component>
  );
}

export { Link };
