/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { PaginatedPatientAssessmentRead } from '../models/PaginatedPatientAssessmentRead';
import { PaginatedUnreadAssessmentSummary } from '../models/PaginatedUnreadAssessmentSummary';
import { PatientAssessmentCreateRequest } from '../models/PatientAssessmentCreateRequest';
import { PatientAssessmentRead } from '../models/PatientAssessmentRead';
import { PatientAssessmentScore } from '../models/PatientAssessmentScore';
import { PatientAssessmentType } from '../models/PatientAssessmentType';
import { PatientAssessmentUpdateRequest } from '../models/PatientAssessmentUpdateRequest';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class PatientAssessmentApiResource {

    /**
     * Create Patient Assessments
     * 
     * @param patientAssessmentCreateRequest 
     
     */
    public createPatientAssessments(body: PatientAssessmentCreateRequest, axiosConfig?: AxiosRequestConfig): Promise<Array<PatientAssessmentRead>> {
        const apiRoute = '/patient-assessments/send';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Paginated Patient Assessments
     * 
     * @param providerPatientId 
     * @param patientId 
     * @param assessmentType 
     * @param completedOnDateRangeStart 
     * @param completedOnDateRangeEnd 
     * @param includeCompleted 
     * @param includeExpired 
     * @param orderBy 
     * @param order 
     * @param limit 
     * @param offset 
     
     */
    public getPaginatedPatientAssessments(query?: { provider_patient_id?: number, patient_id?: number, assessment_type?: PatientAssessmentType, completed_on_date_range_start?: string, completed_on_date_range_end?: string, include_completed?: boolean, include_expired?: boolean, order_by?: string, order?: string, limit?: number, offset?: number }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedPatientAssessmentRead> {
        const apiRoute = '/patient-assessments';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Paginated Unread Per Patient
     * 
     * @param providerId 
     * @param completedOnDateRangeStart 
     * @param includeHiddenProviderPatients 
     * @param limit 
     * @param offset 
     
     */
    public getPaginatedUnreadPerPatient(providerId: number, query?: { completed_on_date_range_start?: string, include_hidden_provider_patients?: boolean, limit?: number, offset?: number }, axiosConfig?: AxiosRequestConfig): Promise<PaginatedUnreadAssessmentSummary> {
        const apiRoute = '/providers/{provider_id}/patient-assessments/unread-per-patient';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Assessment
     * 
     * @param id 
     
     */
    public getPatientAssessment(id: number, axiosConfig?: AxiosRequestConfig): Promise<PatientAssessmentRead> {
        const apiRoute = '/patient-assessments/{id}';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Assessment Count By Type
     * 
     * @param providerPatientId 
     * @param completedOnDateRangeStart 
     * @param completedOnDateRangeEnd 
     
     */
    public getPatientAssessmentCountByType(providerPatientId: number, query?: { completed_on_date_range_start?: string, completed_on_date_range_end?: string }, axiosConfig?: AxiosRequestConfig): Promise<{ [key: string]: number; }> {
        const apiRoute = '/provider-patients/{provider_patient_id}/patient-assessments/count-by-type';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Assessment Scores
     * Get the scores for all completed patient assessments of a certain type for a provider patient, sorted by ascending completion date. The response model is deliberately simplified to allow efficiently fetching the entire history of scores for an assessment type.
     * @param providerPatientId 
     * @param assessmentType 
     
     */
    public getPatientAssessmentScores(providerPatientId: number, query?: { assessment_type: PatientAssessmentType }, axiosConfig?: AxiosRequestConfig): Promise<Array<PatientAssessmentScore>> {
        const apiRoute = '/provider-patients/{provider_patient_id}/patient-assessments/scores';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Patient Assessment Unread Count
     * 
     * @param providerPatientId 
     
     */
    public getPatientAssessmentUnreadCount(providerPatientId: number, axiosConfig?: AxiosRequestConfig): Promise<number> {
        const apiRoute = '/provider-patients/{provider_patient_id}/patient-assessments/unread-count';
        const reqPath = apiRoute
                    .replace('{' + 'provider_patient_id}', String(providerPatientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Provider Has Sent Patient Assessments
     * 
     * @param providerId 
     
     */
    public getProviderHasSentPatientAssessments(providerId: number, axiosConfig?: AxiosRequestConfig): Promise<boolean> {
        const apiRoute = '/providers/{provider_id}/patient-assessments/has-sent';
        const reqPath = apiRoute
                    .replace('{' + 'provider_id}', String(providerId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Patient Assessment
     * 
     * @param id 
     * @param patientAssessmentUpdateRequest 
     
     */
    public updatePatientAssessment(id: number, body: PatientAssessmentUpdateRequest, axiosConfig?: AxiosRequestConfig): Promise<PatientAssessmentRead> {
        const apiRoute = '/patient-assessments/{id}/complete';
        const reqPath = apiRoute
                    .replace('{' + 'id}', String(id));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Update Patient Assessments For Type
     * Updates multiple assessments if a patient has incomplete assessments of the same type from multiple providers
     * @param patientId 
     * @param patientAssessmentUpdateRequest 
     
     */
    public updatePatientAssessmentsForType(patientId: number, body: PatientAssessmentUpdateRequest, axiosConfig?: AxiosRequestConfig): Promise<any> {
        const apiRoute = '/patients/{patient_id}/patient-assessments/complete';
        const reqPath = apiRoute
                    .replace('{' + 'patient_id}', String(patientId));
        const reqConfig = {
            ...axiosConfig,
            method: 'PATCH' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const PatientAssessmentApi = new PatientAssessmentApiResource();
