import {
  AriaToastRegionProps,
  useToast,
  useToastRegion,
} from '@react-aria/toast';
import {
  QueuedToast,
  ToastQueue,
  ToastState,
  useToastQueue,
} from '@react-stately/toast';
import React from 'react';
import { DismissButton, FocusRing } from 'react-aria';
import ReactDOM from 'react-dom';

import { Button } from './Button';
import { IconCircleCheck } from './icons/CircleCheck';
import { IconError } from './icons/Error';
import { theme } from './theme';

interface ToastValue {
  variant: 'negative' | 'neutral' | 'positive';
  children: React.ReactNode;
  actionLabel?: React.ReactNode;
  onAction?: () => void;
}

interface ToastProps {
  toast: QueuedToast<ToastValue>;
  state: ToastState<ToastValue>;
}

function Toast(props: ToastProps) {
  let {
    toast: {
      key,
      animation,
      content: {
        children,
        variant,

        actionLabel,
        onAction,
      },
    },
    state,
    ...otherProps
  } = props;
  let domRef = React.useRef<HTMLDivElement>(null);
  let { closeButtonProps, titleProps, toastProps } = useToast(
    props,
    state,
    domRef
  );

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
  };

  return (
    <div
      {...toastProps}
      ref={domRef}
      className={'hlx-toast'}
      style={{
        zIndex: props.toast.priority,
      }}
      data-animation={animation}
      onAnimationEnd={() => {
        if (animation === 'exiting') {
          state.remove(key);
        }
      }}
    >
      {variant === 'negative' && (
        <IconError
          aria-hidden
          className="hlx-toast-type-icon"
          style={{
            color: theme.color.system.gray,
          }}
        />
      )}
      {variant === 'positive' && (
        <IconCircleCheck
          aria-hidden
          className="hlx-toast-type-icon"
          style={{
            color: theme.color.system.gray,
          }}
        />
      )}
      <div className="hlx-toast-body">
        <div className="hlx-toast-content" {...titleProps}>
          {children}
        </div>
        {actionLabel && (
          <Button onPress={handleAction} variant="link" size="large">
            {actionLabel}
          </Button>
        )}
      </div>

      <DismissButton
        {...closeButtonProps}
        onDismiss={() => {
          state.close(key);
        }}
      />
    </div>
  );
}

const queue = new ToastQueue<ToastValue>({
  maxVisibleToasts: Infinity,
  hasExitAnimation: true,
});

interface ToastRegionProps extends AriaToastRegionProps {
  children: React.ReactNode;
  state: ToastState<ToastValue>;
}

export function ToastRegion(props: ToastRegionProps): React.ReactElement {
  let { children, state } = props;

  let ref = React.useRef<HTMLDivElement>(null);
  let { regionProps } = useToastRegion({}, state, ref);

  let contents = (
    <FocusRing focusRingClass="focus-ring">
      <div
        {...regionProps}
        ref={ref}
        data-position="bottom"
        data-placement="center"
        className="hlx-toast-region"
      >
        {children}
      </div>
    </FocusRing>
  );

  return ReactDOM.createPortal(contents, document.body);
}

interface ToastContainerProps extends AriaToastRegionProps {}

function ToastContainer(props: ToastContainerProps): React.ReactElement | null {
  let state = useToastQueue<ToastValue>(queue);

  if (state.visibleToasts.length > 0) {
    return (
      <ToastRegion state={state} {...props}>
        {state.visibleToasts.map((toast) => (
          <Toast key={toast.key} toast={toast} state={state} />
        ))}
      </ToastRegion>
    );
  }

  return null;
}

interface ToastOptions {
  variant?: ToastValue['variant'];
  actionLabel?: string;
  onAction?: () => void;
  /**
   * Automatically hide the toast after an amount of time. For accessibility, toasts have a
   * minimum timeout of 5 seconds, and actionable toasts will not auto dismiss. In addition,
   * timers will pause when the user focuses or hovers over a toast.

   * Be sure only to automatically dismiss toasts when the information is not important,
   * or may be found elsewhere. Some users may require additional time to read a
   * toast message, and screen zoom users may miss toasts entirely.
  **/
  timeout?: number;
  onClose?: () => void;
}

const HELIX_TOAST_DEFAULT_TIMEOUT_MS = 5000;

const helixQueue = {
  add(content: string, options: ToastOptions = {}) {
    let value = {
      children: content,
      actionLabel: options.actionLabel,
      onAction: options.onAction,
      variant: options.variant ?? 'neutral',
    };

    // Toasts with an action will not auto dismiss
    // but all other toasts will auto dismiss after 5 seconds
    // or the timeout specified in the options if it is greater than 5 seconds.
    const timeout = options.onAction
      ? undefined
      : options.timeout === Infinity
      ? undefined
      : Math.max(options.timeout ?? 0, HELIX_TOAST_DEFAULT_TIMEOUT_MS);

    let key = queue.add(value, {
      priority: 1,
      timeout,
      onClose: options.onClose,
    });
    return () => queue.close(key);
  },
};

export { helixQueue as toasts, ToastContainer, HELIX_TOAST_DEFAULT_TIMEOUT_MS };
