import { css } from '@emotion/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CloudUploadIcon from '@mui/icons-material/CloudUploadTwoTone';
import React from 'react';
import ReactDropzone, {
  DropzoneProps as ReactDropzoneProps,
} from 'react-dropzone';

import { BodyText } from '@headway/helix/BodyText';
import { IconUpload } from '@headway/helix/icons/Upload';
import { theme as helixTheme } from '@headway/helix/theme';

import { theme } from './theme';

type DropzoneProps = Pick<
  ReactDropzoneProps,
  'onDrop' | 'accept' | 'disabled' | 'maxSize'
> & {
  hasUploaded?: boolean;
  height?: number;
  maxHeight?: number;
  uploadedFileName?: string | string[];
  inputTestId?: string;
  variant?: 'helix' | 'mui';
  descriptionId?: string;
};

const getUploadText = (uploadedFileName?: string | string[]) => {
  if (!uploadedFileName) {
    return 'File successfully uploaded.';
  }

  if (Array.isArray(uploadedFileName)) {
    if (uploadedFileName.length > 5) {
      return `Uploaded ${uploadedFileName.length} files.`;
    } else {
      return `Uploaded: ${uploadedFileName.join(', ')}`;
    }
  } else {
    return `Uploaded: ${uploadedFileName}`;
  }
};

export const Dropzone: React.FC<React.PropsWithChildren<DropzoneProps>> = ({
  onDrop,
  accept,
  hasUploaded,
  height,
  maxHeight,
  uploadedFileName,
  inputTestId,
  disabled,
  maxSize,
  variant = 'mui',
  descriptionId,
  ...rest
}) => {
  const dropzoneCss =
    variant === 'helix' ? helixDropzoneCss : legacyDropzoneCss;
  return (
    <ReactDropzone
      onDrop={onDrop}
      accept={accept}
      disabled={disabled}
      {...(!!maxSize ? { maxSize } : {})}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          role="button"
          {...rest}
          {...getRootProps()}
          css={dropzoneCss.button}
          style={{
            minHeight: height || 120,
            maxHeight: maxHeight || height || 120,
            border:
              variant === 'helix'
                ? 'none'
                : hasUploaded
                ? `1px solid ${theme.color.primary}`
                : `1px dashed ${theme.color.gray}`,
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          <React.Fragment>
            {/* @ts-ignore */}
            <input
              {...(inputTestId ? { 'data-testid': inputTestId } : {})}
              {...getInputProps()}
            />
            {hasUploaded ? (
              <React.Fragment>
                <CheckCircleOutlineIcon
                  css={{
                    color: theme.color.primary,
                    marginBottom: theme.space.xs,
                    marginLeft: theme.space.xs,
                    marginRight: theme.space.xs,
                  }}
                />
                <p
                  css={{
                    textAlign: 'center',
                    maxWidth: '90%',
                  }}
                >
                  {getUploadText(uploadedFileName)}
                </p>

                <p
                  css={{
                    color: theme.color.gray,
                    textAlign: 'center',
                  }}
                  id={descriptionId}
                >
                  {isDragActive
                    ? 'To replace drop file here'
                    : 'Drag and drop file here or click to select files to replace'}
                </p>
              </React.Fragment>
            ) : variant === 'helix' ? (
              <>
                <IconUpload />
                <div
                  css={
                    disabled
                      ? {
                          filter: 'opacity(50%)',
                        }
                      : {}
                  }
                >
                  {isDragActive ? (
                    <label id={descriptionId}>
                      <BodyText>Drop file here</BodyText>
                    </label>
                  ) : (
                    <label id={descriptionId}>
                      <BodyText>
                        Drag and drop file here or click to select files
                      </BodyText>
                    </label>
                  )}
                </div>
              </>
            ) : (
              <React.Fragment>
                <CloudUploadIcon
                  css={{
                    marginBottom: theme.space.xs,
                  }}
                />
                {isDragActive ? (
                  <p id={descriptionId}>Drop file here</p>
                ) : (
                  <p id={descriptionId}>
                    Drag and drop file here or click to select files
                  </p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </div>
      )}
    </ReactDropzone>
  );
};

const legacyDropzoneCss = {
  button: css({
    borderRadius: 3,
    width: '100%',
    color: theme.color.primary,
    background: 'white',
    textTransform: 'none',
    margin: `${theme.space.base} 0`,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
};

const helixDropzoneCss = {
  button: css({
    borderRadius: helixTheme.spacing.x1,
    backgroundColor: helixTheme.color.system.backgroundGray,
    padding: '0',
    border: 'none',
    width: '100%',
    backgroundClip: 'content-box',
    textTransform: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: helixTheme.spacing.x2,
  }),
};
