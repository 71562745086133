import { Chip, FormHelperText } from '@mui/material';
import React, { FunctionComponent } from 'react';

import {
  COMMON_DIAGNOSIS_CODES,
  DXCodeInfo,
  LESS_COMMON_DIAGNOSIS_CODES,
  PHYSIOLOGICAL_CODES,
} from '../shared/constants/diagnosisCodes';
import { FieldAutocompleteFuzzy, FieldErrorText } from './form';

export interface DiagnosisCodeOption extends DXCodeInfo {
  description: string;
}

export const diagnosisOptions = (
  includePhysiologicalCodes?: boolean
): DiagnosisCodeOption[] => {
  const commonCodes = [
    ...COMMON_DIAGNOSIS_CODES.map((code) => ({
      ...code,
      description: 'Common codes',
    })),
  ];
  const lessCommonCodes = [
    ...LESS_COMMON_DIAGNOSIS_CODES.map((code) => ({
      ...code,
      description: 'Less common codes',
    })),
  ];
  const physiologicalCodes = [
    ...PHYSIOLOGICAL_CODES.map((code) => ({
      ...code,
      description: 'Physiological codes',
    })),
  ];
  if (includePhysiologicalCodes) {
    return [...commonCodes, ...lessCommonCodes, ...physiologicalCodes];
  }
  return [...commonCodes, ...lessCommonCodes];
};

const shouldIncludePhysiologicalCodes = (
  chosenCodes: DiagnosisCodeOption[]
): boolean => {
  // https://docs.google.com/document/d/13-u6iW0sVv7c9F_v09LOqO60SQhJnXGK-_CiayPISy4/edit
  const result = chosenCodes.some((code) => {
    const value = code.value.toUpperCase();
    return (
      value.startsWith('F01') ||
      value.startsWith('F02') ||
      value === 'F04' ||
      value === 'F05' ||
      value.startsWith('F06') ||
      value.startsWith('F07') ||
      value === 'F09' ||
      value === 'F48.2' ||
      value === 'F54'
    );
  });
  return result;
};

const orderedDiagnosisCodes = (
  codes: DiagnosisCodeOption[]
): DiagnosisCodeOption[] => {
  // physiological codes go first
  // everything else should stay in order it was entered.
  const physiologicalCodeValues = PHYSIOLOGICAL_CODES.map((c) => c.value);
  return codes.sort((a, b) => {
    if (physiologicalCodeValues.includes(a.value)) {
      return -1;
    }
    if (physiologicalCodeValues.includes(b.value)) {
      return 1;
    }
    return 0;
  });
};

interface DiagnosisCodeInputProps {
  disabled?: boolean;
  showHelperText?: boolean;
  displayOptionalHelpText?: boolean;
  chosenCodes?: DiagnosisCodeOption[];
  setFieldValue: (name: string, value: any) => void;
  fieldName: string;
}

const DiagnosisCodeInput: FunctionComponent<
  React.PropsWithChildren<DiagnosisCodeInputProps>
> = (props) => {
  const {
    disabled,
    setFieldValue,
    showHelperText = true,
    displayOptionalHelpText = false,
    chosenCodes = [],
    fieldName,
    ...rest
  } = props;

  return (
    <>
      <FieldAutocompleteFuzzy
        label="Diagnosis codes"
        fuzzySearchOptions={{
          keys: ['value', 'display'],
          useExtendedSearch: true,
          ignoreLocation: true,
        }}
        multiple={true}
        options={diagnosisOptions(shouldIncludePhysiologicalCodes(chosenCodes))}
        groupBy={(option) => option.description}
        getOptionLabel={(option) => `${option.value} — ${option.display}`}
        disabled={disabled}
        renderTags={(value, getTagProps) =>
          value.map((option, idx) => (
            <Chip
              {...getTagProps({ index: idx })}
              label={`${option.value} — ${option.display}`}
              onDelete={(...args) => {
                //@ts-ignore
                !disabled && getTagProps({ index: idx }).onDelete(...args);
              }}
            />
          ))
        }
        onChange={(_, val) => {
          let toSet = val;
          if (Array.isArray(toSet)) {
            toSet = orderedDiagnosisCodes(toSet);
          }
          setFieldValue(fieldName, toSet);
        }}
        {...rest}
      />
      <FieldErrorText />
      {showHelperText && (
        <FormHelperText>Select multiple if applicable.</FormHelperText>
      )}
      {displayOptionalHelpText && (
        <FormHelperText>
          *Optional for private pay clients. Please note, clients cannot file
          for OON reimbursement without CPT/diagnosis codes.
        </FormHelperText>
      )}
    </>
  );
};

export default DiagnosisCodeInput;
