import { PersonAddTwoTone } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { useProvider } from 'hooks';
import moment from 'moment';
import React, { useContext, useState } from 'react';

import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { Tooltip, VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useFindProviderEvents } from 'hooks/useFindProviderEvents';
import { PatientsContext } from 'providers/PatientsProvider';
import { useAuthStore } from 'stores/AuthStore';

import { CalendarContext } from '../CalendarContext';
import {
  eventHasPatientUserId,
  isUpcomingPatientBookedEvent,
} from '../events/util/events';
import { getExpandedPatientBookedEventsQueryKeyArgs } from '../utils/queries';
import {
  BookedEvent,
  PatientBookedEventPopover,
  PatientBookedEventPopoverProps,
} from './PatientBookedEventPopover';

interface PatientBookedEventProps {
  handleIntakeCallClick: PatientBookedEventPopoverProps['handleIntakeCallClick'];
  handleCancelClick: PatientBookedEventPopoverProps['handleCancelClick'];
}

export const PatientBookedEvent = ({
  handleIntakeCallClick,
  handleCancelClick,
}: PatientBookedEventProps) => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const provider = useProvider();
  const AuthStore = useAuthStore();

  const updateProviderLastSeen = async (providerId: number, seenDate: Date) => {
    try {
      const updatedProvider = await ProviderApi.updateProvider(providerId, {
        newPatientBookingsLastSeen: seenDate.toISOString(),
      });
      AuthStore.setProvider(updatedProvider);
    } catch {
      // don't do anything -- non-blocking problem
    }
  };

  return (
    <PatientBookedEventsCalculator
      provider={provider}
      renderProp={({ patientBookedEvents, numUnseen }) => {
        return (
          <React.Fragment>
            <PatientBookedEventPopover
              provider={provider}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
                updateProviderLastSeen(provider.id, new Date());
              }}
              bookedEvents={patientBookedEvents}
              lastSeenDate={
                provider?.newPatientBookingsLastSeen
                  ? moment(provider.newPatientBookingsLastSeen).toDate()
                  : undefined
              }
              handleIntakeCallClick={handleIntakeCallClick}
              handleCancelClick={handleCancelClick}
            />
            <Badge badgeContent={numUnseen} color="error">
              <Tooltip title="Referrals">
                <IconButton
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                  size="large"
                >
                  <PersonAddTwoTone css={{ fontSize: theme.fontSize.xl }} />
                  <VisuallyHidden>Referrals</VisuallyHidden>
                </IconButton>
              </Tooltip>
            </Badge>
          </React.Fragment>
        );
      }}
    />
  );
};

interface PatientBookedEventsCalculatorProps {
  provider: ProviderRead;
  renderProp: (props: {
    patientBookedEvents: BookedEvent[];
    numUnseen: number;
  }) => JSX.Element;
}

const PatientBookedEventsCalculator: React.FC<
  React.PropsWithChildren<PatientBookedEventsCalculatorProps>
> = ({ provider, renderProp }) => {
  const { patientMessages: messages } = useContext(CalendarContext);
  const { patientsById } = React.useContext(PatientsContext);

  const { data: upcomingPatientBookedEvents } = useFindProviderEvents(
    getExpandedPatientBookedEventsQueryKeyArgs(provider.id),
    { refetchOnWindowFocus: false }
  );

  const patientBookedEvents = React.useMemo(() => {
    if (!patientsById || !upcomingPatientBookedEvents?.data) {
      return [];
    }

    return upcomingPatientBookedEvents.data
      .filter(isUpcomingPatientBookedEvent)
      .filter(eventHasPatientUserId)
      .sort((eventA: ProviderEventRead, eventB: ProviderEventRead) => {
        return (
          new Date(eventB.createdOn!).getTime() -
          new Date(eventA.createdOn!).getTime()
        );
      })
      .map(
        (event: ProviderEventRead) =>
          ({
            event,
            patient: patientsById[event.patientUserId!],
            message: event?.id ? messages[event.id] : undefined,
          }) as BookedEvent
      );
  }, [upcomingPatientBookedEvents, patientsById, messages]);

  const unseenPatientBookedEvents = provider?.newPatientBookingsLastSeen
    ? patientBookedEvents.filter((bookedEvent: any) =>
        moment(bookedEvent.event.created_on).isAfter(
          moment(provider.newPatientBookingsLastSeen)
        )
      )
    : patientBookedEvents;

  return (
    <React.Fragment>
      {renderProp({
        patientBookedEvents: patientBookedEvents,
        numUnseen: unseenPatientBookedEvents.length,
      })}
    </React.Fragment>
  );
};
