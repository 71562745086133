import { RefObject, useEffect, useState } from 'react';

interface UseInViewportOptions {
  /** The margin around the root (viewport) that should trigger the callback (see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#rootmargin) */
  rootMargin?: string;
  /** A number between 0 and 1 indicating the percentage of the target element which must be visible to trigger the callback (see https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#thresholds)  */
  threshold?: number;
}
interface UseInViewportArgs {
  /** A React ref object pointing to the element to observe */
  ref: RefObject<Element>;
  options?: UseInViewportOptions;
  /** callback function invoked whenever the target meets a threshold specified for the IntersectionObserver
   *    Note: make sure to pass in a memoized function (e.g. with useCallback) to prevent unnecessary re-renders
   */
  onIntersection?: (
    entry: IntersectionObserverEntry,
    observer: IntersectionObserver
  ) => void;
}
/**
 * Hook for determining when an element is visible in the viewport (e.g. scrolled into view)
 *
 * @returns an tuple of a boolean if the element is intersection as the first element, and IntersectionObserverEntry
 *   object or undefined as the second element, see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry
 */
export const useInViewport = ({
  ref,
  options: { rootMargin, threshold } = {},
  onIntersection,
}: UseInViewportArgs): [boolean, IntersectionObserverEntry | undefined] => {
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState<IntersectionObserverEntry>();

  useEffect(() => {
    if (ref.current == null) return;
    const observerOptions = {
      ...(rootMargin !== undefined && { rootMargin }),
      ...(threshold !== undefined && { threshold }),
    };
    const observer = new IntersectionObserver(([entry], observer) => {
      setIntersectionObserverEntry(entry);
      if (onIntersection) {
        onIntersection(entry, observer);
      }
    }, observerOptions);
    observer.observe(ref.current);
    return () => {
      if (ref.current == null) return;
      observer.unobserve(ref.current);
    };
  }, [ref, rootMargin, threshold, onIntersection]);

  return [
    !!intersectionObserverEntry?.isIntersecting,
    intersectionObserverEntry,
  ];
};
