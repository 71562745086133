import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UsePatientAddressesQueryKeyArgs {
  patientId?: number;
}

export const getUsePatientAddressesQueryKey = ({
  patientId,
}: UsePatientAddressesQueryKeyArgs): [string, number | undefined] => [
  'patient-addresses',
  patientId,
];

const {
  useSingleQuery: usePatientAddresses,
  useListQuery: usePatientAddressesList,
} = createBasicApiHooks(
  getUsePatientAddressesQueryKey,
  ({ patientId }) => {
    return PatientAddressApi.getPatientAddresses({
      patient_user_id: patientId!,
    });
  },
  ({ patientId }) => !!patientId,
  () => 'Failed to fetch patient addresses'
);

export { usePatientAddresses, usePatientAddressesList };
