import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import moment from 'moment';
import React, { useContext, useState } from 'react';

import { ProviderTreatmentPlanAttachmentRead } from '@headway/api/models/ProviderTreatmentPlanAttachmentRead';
import { ProviderTreatmentPlanRead } from '@headway/api/models/ProviderTreatmentPlanRead';
import { TreatmentPlanType } from '@headway/api/models/TreatmentPlanType';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconButton } from '@headway/helix/IconButton';
import { IconDelete } from '@headway/helix/icons/Delete';
import { IconDocument } from '@headway/helix/icons/Document';
import { ListHeader } from '@headway/helix/ListHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';

import { useProviderTreatmentPlan } from 'hooks/useProviderTreatmentPlan';
import { useProviderTreatmentPlanAttachmentsList } from 'hooks/useProviderTreatmentPlanAttachments';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';

import { DeleteTreatmentPlanModal } from './Modals/DeleteTreatmentPlanModal';
import { PlanJson } from './Template/types';
import { trackCompleteDraftButtonClickedEvent } from './TreatmentPlanAnalyticsUtils';
import {
  TREATMENT_PLAN_PAGES,
  TreatmentPlanContext,
} from './TreatmentPlanContext';
import { TreatmentPlanStatusBadges } from './TreatmentPlanStatusBadges';
import {
  getTreatmentPlanName,
  isSignedTreatmentPlan,
} from './TreatmentPlanUtils';

export interface TreatmentPlanTableProps {
  setIsEditing: (value: boolean) => void;
}

export const TreatmentPlanTable = ({
  setIsEditing,
}: TreatmentPlanTableProps) => {
  const {
    editTreatmentPlan,
    setTreatmentPlan,
    setPage,
    providerPatient,
    initializeTemplateForm,
    treatmentPlans: allTreatmentPlans,
  } = useContext(TreatmentPlanContext);

  const provider = useProvider();
  const { user } = useAuthStore();

  const { deleteTreatmentPlan } = useProviderTreatmentPlan(
    providerPatient!.userId
  );

  const [isDeleteTreatmentPlanModalOpen, setIsDeleteTreatmentPlanModalOpen] =
    useState<boolean>(false);
  const [selectedTreatmentPlanId, setSelectedTreatmentPlanId] =
    useState<number>();

  const treatmentPlans = allTreatmentPlans
    .sort((planA, planB) =>
      moment(planA.updatedOn!) < moment(planB.updatedOn!) ? 1 : -1
    )
    .filter((plan) => !isSignedTreatmentPlan(plan))
    .concat(allTreatmentPlans.filter((plan) => isSignedTreatmentPlan(plan)));

  const attachmentsByTreatmentPlanId: {
    [key: number]: ProviderTreatmentPlanAttachmentRead[];
  } = {};
  const treatmentPlanAttachmentsQuery = useProviderTreatmentPlanAttachmentsList(
    treatmentPlans.map((plan) => {
      return { queryKeyArgs: { treatmentPlanId: plan.id } };
    })
  );
  treatmentPlanAttachmentsQuery.forEach((query) => {
    if (query && query.data && query.data.length > 0) {
      attachmentsByTreatmentPlanId[query.data[0].providerTreatmentPlanId] =
        query.data;
    }
  });

  return (
    <>
      {treatmentPlans && treatmentPlans.length > 0 && (
        <Table aria-label="Treatment plans" data-testid="treatmentPlansTable">
          <TableHeader>
            <Column>Plan</Column>
            <Column>Status</Column>
            <Column>Uploaded files</Column>
            <Column> </Column>
          </TableHeader>
          <TableBody>
            {treatmentPlans.map(
              (treatmentPlan: ProviderTreatmentPlanRead, idx: number) => (
                <Row key={idx}>
                  <Cell>
                    <div
                      css={{
                        ...theme.stack.vertical,
                      }}
                    >
                      <div css={treatmentPlanNameCss}>
                        <Button
                          variant="link"
                          onPress={() => {
                            setTreatmentPlan(treatmentPlan);
                            if (
                              treatmentPlan.planType ===
                              TreatmentPlanType.TEMPLATE
                            ) {
                              initializeTemplateForm(
                                (treatmentPlan.planJson as PlanJson).template
                              );
                            }
                            setIsEditing(true);
                            setPage(TREATMENT_PLAN_PAGES.PREVIEW);
                          }}
                        >
                          <ListHeader>
                            {getTreatmentPlanName(
                              treatmentPlan.id,
                              treatmentPlans
                            )}
                          </ListHeader>
                        </Button>
                      </div>
                      <SubBodyText>
                        {treatmentPlan.attestedOn
                          ? 'Signed on ' +
                            moment(treatmentPlan.attestedOn).format('MM/DD/YY')
                          : 'Updated on ' +
                            moment(treatmentPlan.updatedOn).format('MM/DD/YY')}
                      </SubBodyText>
                    </div>
                  </Cell>
                  <Cell>
                    <div css={rowCss}>
                      <TreatmentPlanStatusBadges
                        treatmentPlan={treatmentPlan}
                        isLatestAttestedPlan={
                          idx ===
                          treatmentPlans.findIndex((plan) =>
                            isSignedTreatmentPlan(plan)
                          )
                        }
                      />
                    </div>
                  </Cell>
                  <Cell>
                    {attachmentsByTreatmentPlanId[treatmentPlan.id] ? (
                      attachmentsByTreatmentPlanId[treatmentPlan.id].map(
                        (attachment, attachment_id) => (
                          <div key={attachment_id} css={rowCss}>
                            <IconDocument
                              css={{ marginRight: theme.spacing.x2 }}
                            />
                            <BodyText>{attachment.name}</BodyText>
                          </div>
                        )
                      )
                    ) : (
                      <BodyText>-</BodyText>
                    )}
                  </Cell>
                  <Cell>
                    {!isSignedTreatmentPlan(treatmentPlan) && (
                      <div css={editActionCss}>
                        <div css={{ marginRight: theme.spacing.x2 }}>
                          <IconButton
                            variant="default"
                            size="medium"
                            onPress={() => {
                              setSelectedTreatmentPlanId(treatmentPlan.id);
                              setIsDeleteTreatmentPlanModalOpen(true);
                            }}
                          >
                            <IconDelete />
                          </IconButton>
                        </div>
                        <Button
                          variant="secondary"
                          size="medium"
                          onPress={() => {
                            trackCompleteDraftButtonClickedEvent(
                              providerPatient!
                            );
                            editTreatmentPlan(treatmentPlan);
                            setIsEditing(true);
                          }}
                          disabled={
                            treatmentPlan.planType !==
                              TreatmentPlanType.UPLOAD &&
                            isGroupAdminImpersonatingProvider(provider, user)
                          }
                        >
                          Complete draft
                        </Button>
                      </div>
                    )}
                  </Cell>
                </Row>
              )
            )}
          </TableBody>
        </Table>
      )}
      <DeleteTreatmentPlanModal
        open={isDeleteTreatmentPlanModalOpen}
        onClose={() => {
          setSelectedTreatmentPlanId(undefined);
          setIsDeleteTreatmentPlanModalOpen(false);
        }}
        onDelete={() => {
          deleteTreatmentPlan(selectedTreatmentPlanId!);
        }}
        treatmentPlanId={selectedTreatmentPlanId!}
      />
    </>
  );
};

const editActionCss = css({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
});

const rowCss = css({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
});

const treatmentPlanNameCss = css({
  marginLeft: 0,
  marginBottom: theme.spacing.x1,
});
