import React from 'react';
import * as Yup from 'yup';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import {
  getBioTherapyApproachHeader,
  PROVIDER_BIO_ABOUT_YOU_PROMPT,
  PROVIDER_BIO_TAKE_AWAYS_PROMPT,
  ProviderBioFields,
} from '@headway/shared/utils/providers';
import { getContentOfHtml } from '@headway/shared/utils/strings';
import { theme } from '@headway/ui/theme';

export const BIO_SECTION_TITLE = 'Build your bio';
export const BIO_SECTION_DESCRIPTION =
  'Help prospective clients learn about you and your practice by describing your approach, style, and strengths. They’ll review your bio before booking to determine if you’re a good fit.';

interface NewBioFeatureAlertProps {
  oldBio: string;
}

export const NewBioFeatureAlert: React.FC<
  React.PropsWithChildren<NewBioFeatureAlertProps>
> = ({ oldBio }) => {
  const [view, setView] = React.useState<'EXPANDED' | 'COLLAPSED'>('COLLAPSED');

  return (
    <div css={{ marginBottom: theme.space.base }}>
      <GuidanceCard variant="info" layout="vertical">
        <BodyText>
          <strong>Note: </strong>We've recently updated our bio authoring
          experience to help improve referrals. You can reference your old bio
          to ease the transition.
        </BodyText>
        <Button
          variant="link"
          size="large"
          onPress={() => {
            setView(view === 'COLLAPSED' ? 'EXPANDED' : 'COLLAPSED');
          }}
        >
          {view === 'COLLAPSED' ? 'View' : 'Close'}
        </Button>
      </GuidanceCard>
      {view === 'EXPANDED' && (
        <div
          css={{
            padding: theme.space.lg,
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            border: `1px solid ${theme.color.lightGray}`,
            borderTop: 'none',
            margin: `0 ${theme.space.base}`,
          }}
          dangerouslySetInnerHTML={{ __html: sanitize(oldBio) }}
        />
      )}
    </div>
  );
};

export const bioQandAValidator = Yup.string()
  .transform((value) => {
    const content = getContentOfHtml(value);

    return content;
  })
  .min(50, ({ min, value }) => {
    return `Must be at least ${min} characters. (${value.length}/${min}).`;
  })
  .max(500, ({ max, value }) => {
    return `Cannot be more than ${max} characters. (${value.length}/${max}).`;
  });

/**
 * Concatenates any completed Q&A fields into a single HTML string with
 * bolded questions, allowing backwards compatibility between the new bio
 * Q&A fields and the old statementHtml field.
 */
export function bioQandAToStatementHtml(
  fields: ProviderBioFields,
  isProviderPrescriber?: boolean
) {
  let questions: string[] = [];

  if (fields.bioAboutYou) {
    questions = [
      `<strong>${PROVIDER_BIO_ABOUT_YOU_PROMPT}</strong>`,
      fields.bioAboutYou,
    ];
  }

  if (fields.bioTherapyApproach) {
    questions = [
      ...questions,
      `<strong>${getBioTherapyApproachHeader(isProviderPrescriber)}</strong>`,
      fields.bioTherapyApproach,
    ];
  }

  if (fields.bioTakeAways) {
    questions = [
      ...questions,
      `<strong>${PROVIDER_BIO_TAKE_AWAYS_PROMPT}</strong>`,
      fields.bioTakeAways,
    ];
  }
  return questions.join('');
}
