import * as React from 'react';

export const IndentDecrease = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.325 16.675v-1.088h13.369v1.088H3.325zM9.531 13.6v-1.069h7.163V13.6H9.53zm-3.544-1.106L3.42 9.925 5.987 7.45v5.044zm3.544-1.969V9.456h7.163v1.069H9.53zm0-3.075V6.381h7.163V7.45H9.53zM3.325 4.394V3.306h13.369v1.088H3.325z"
      fill="currentColor"
    />
  </svg>
);
