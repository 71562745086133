/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ProviderAppointmentStatusNotes {
    PATIENT_CANCELLATION = 'Patient Cancellation',
    RESCHEDULED_BY_PATIENT = 'Rescheduled by patient',
    NO_SHOW = 'No Show',
    ADMIN_CANCELLATION = 'Admin cancellation',
    NOT_AWARE = 'Provider not aware of appointment',
    NOT_AVAILABLE = 'Provider not available',
    CLIENT_NOT_AVAILABLE = 'Client not available',
    SEEING_OUTSIDE_HEADWAY = 'Provider seeing client outside of Headway',
    STOPPING_CARE = 'Stopping care with client',
    NOT_RIGHT_FIT = 'Client not the right fit',
    NOT_TAKING_NEW_CLIENTS = 'Provider not currently taking new clients',
    AUTO_CANCEL_DUE_TO_PAYMENT = 'Canceled automatically due to lack of payment information',
    OTHER = 'Other',
    PROVIDER_CANCELLATION = 'Provider Cancellation',
    RESCHEDULING = 'Provider rescheduling',
    FOUND_OTHER_PROVIDER = 'FOUND_OTHER_PROVIDER',
    NOT_INTERESTED_IN_THERAPY = 'NOT_INTERESTED_IN_THERAPY',
    PATIENT_NOT_AVAILABLE = 'PATIENT_NOT_AVAILABLE',
    PROVIDER_NOT_AVAILABLE = 'PROVIDER_NOT_AVAILABLE',
    PROVIDER_NOT_RESPONDING = 'PROVIDER_NOT_RESPONDING',
    PROVIDER_NOT_RIGHT_FIT = 'PROVIDER_NOT_RIGHT_FIT',
    SOMETHING_ELSE = 'SOMETHING_ELSE',
    TOO_EXPENSIVE = 'TOO_EXPENSIVE',
    EXTERNAL_PLATFORM_CANCELLATION = 'EXTERNAL_PLATFORM_CANCELLATION'
}
