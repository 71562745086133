import styled from '@emotion/styled';
import React from 'react';

import { HeadwayMark } from '@headway/icons/dist/HeadwayMark';

import { theme } from '../theme';

export const PrimaryBackground = styled.div({
  overflow: 'hidden',
  position: 'relative',
  background: theme.color.primaryBackground,
  padding: theme.space.xl2,
  borderRadius: 6,
});

export const VectorBackground: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
  ...rest
}) => {
  return (
    <PrimaryBackground css={{ zIndex: 0 }} {...rest}>
      <HeadwayMark
        css={{
          position: 'absolute',
          width: 1000,
          height: 1000,
          bottom: -800,
          transform: 'rotate(0deg)',
          right: -500,
          zIndex: -1,
          [theme.media.small]: {
            bottom: -750,
            right: -400,
          },
        }}
        role="none"
        aria-hidden="true"
        focusable="false"
      />
      <div>{children}</div>
    </PrimaryBackground>
  );
};
