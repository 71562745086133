import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';

import { LinkButton } from '@headway/helix/LinkButton';

import { Task } from './Task';

export const BankAccountTask = () => {
  return (
    <>
      <Task
        icon={<ErrorOutlineIcon />}
        listHeaderText={<>Connect your bank account</>}
        subBodyText="Connect your bank information to receive automatic payments for sessions."
        secondaryAction={<></>}
        primaryAction={
          <LinkButton
            href={`/settings/billing`}
            variant="primary"
            size="medium"
          >
            Complete in Settings
          </LinkButton>
        }
      />
    </>
  );
};
