import { useProvider } from 'hooks';
import moment from 'moment';
import React, { useEffect } from 'react';

import { ASRSResponse } from '@headway/api/models/ASRSResponse';
import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { Badge } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { CaptionText } from '@headway/helix/CaptionText';
import { Divider } from '@headway/helix/Divider';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { SubBodyText } from '@headway/helix/SubBodyText';
import {
  AGE_ENUM_TO_TEXT,
  ASRS_PART_B_KEY_TO_QUESTION_TEXT,
  ASRS_VALUE_TO_OPTION_TEXT,
  SHORT_ASSESSMENT_NAMES,
} from '@headway/shared/constants/patientAssessments';
import { useUser } from '@headway/shared/hooks/useUser';
import { trackPageView } from '@headway/shared/utils/analytics';
import { formatPatientName } from '@headway/shared/utils/patient';
import { ReadonlyAssessmentRenderer } from '@headway/ui/assessments/renderer';
import { ReadonlyAssessmentProps } from '@headway/ui/assessments/types';
import { LogoLoader } from '@headway/ui/LogoLoader';
import { getOrdinal } from '@headway/ui/utils/numbers';

import { usePatientAssessment } from 'hooks/usePatientAssessment';

import { ASSESSMENT_SCORE_RANGES } from '../helpers/constants';
import {
  getAssessmentScoreDescriptor,
  getScoreRepresentationForAssessmentType,
  getVariantForAssessmentResult,
  indicatesSiRisk,
  isASRSInvalid,
} from '../helpers/utils';
import { ScoreDiffBadge } from './InsightBadges';

interface ASRSResultsInsightsProps {
  // ASRS assessment to display.
  assessment: PatientAssessmentRead;
}

export const ASRSResultsInsights = ({
  assessment,
}: ASRSResultsInsightsProps) => {
  const { assessmentType, score, scorableResponseJson, subscores } = assessment;
  if (!scorableResponseJson || !score || !subscores) {
    return null;
  }

  const {
    ageCategory,
    partA_score,
    partB_criticalQuestionKeys,
    inattentiveSubscaleRawScore,
    inattentiveSubscalePercentageScore,
    motorHyperactiveImpulsiveSubscaleRawScore,
    motorHyperactiveImpulsiveSubscalePercentageScore,
    verbalHyperactiveImpulsiveSubscaleRawScore,
    verbalHyperactiveImpulsiveSubscalePercentageScore,
  } = subscores;

  return (
    <div>
      <section className="flex flex-col gap-2 pb-4">
        <div className="flex flex-col gap-2">
          <SubBodyText>
            <strong>ASRSv1.1 Part A scoring guide:</strong>
          </SubBodyText>

          {ASSESSMENT_SCORE_RANGES[assessmentType].map((range) => (
            <div key={range.startScore} className="flex flex-row gap-4">
              <Badge variant={range.variant}>
                {range.endScore != null
                  ? `${range.startScore} - ${range.endScore}`
                  : `${range.startScore}+`}{' '}
                on Part A
              </Badge>
              <CaptionText>{range.descriptor}</CaptionText>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2 pt-2">
          <SubBodyText>
            <strong>Client results for Part A:</strong>
          </SubBodyText>
          <Badge
            variant={getVariantForAssessmentResult(assessmentType, partA_score)}
          >
            {`Total score: ${partA_score}`}
          </Badge>
          <Badge
            variant={getVariantForAssessmentResult(assessmentType, partA_score)}
          >
            {`${getAssessmentScoreDescriptor(
              assessmentType,
              partA_score
            )} (Part A)`}
          </Badge>
        </div>
      </section>
      <Divider autoSpacing={false} />
      <section className="flex flex-col gap-2 py-4">
        <SubBodyText>
          <strong>ASRSv1.1 Part B scoring guide:</strong>
        </SubBodyText>

        <SubBodyText color="gray">
          Follow up on questions marked '<b>Often</b>' and '<b>Very Often</b>'
          by the client
        </SubBodyText>
        <div className="flex flex-col gap-2 pt-2">
          <SubBodyText>
            <strong>Client results for Part B:</strong>
          </SubBodyText>
          <ol className="hlx-typography-subbody list-decimal space-y-4 pl-6 text-system-gray">
            {scorableResponseJson &&
              partB_criticalQuestionKeys.map((questionKey) => {
                const value = (
                  scorableResponseJson as { [key: string]: ASRSResponse }
                )[questionKey];
                return (
                  <li key={questionKey} value={questionKey.split('_')[1]}>
                    <div className="flex flex-col">
                      <SubBodyText color="gray">
                        {ASRS_PART_B_KEY_TO_QUESTION_TEXT[questionKey]}
                      </SubBodyText>

                      <SubBodyText color="gray">
                        {`Answer: ${ASRS_VALUE_TO_OPTION_TEXT[value]}`}
                      </SubBodyText>
                    </div>
                  </li>
                );
              })}
          </ol>
        </div>
      </section>
      <Divider autoSpacing={false} />
      <section className="flex flex-col gap-2 py-4">
        <SubBodyText>
          <strong>Total score:</strong>
        </SubBodyText>
        <Badge variant="neutral">
          {score}
          {getOrdinal(score)} percentile
        </Badge>
        <SubBodyText color="gray">
          This client had a higher ASRSv1.1 total score than {score} percent of
          people {AGE_ENUM_TO_TEXT[ageCategory]}.
        </SubBodyText>
      </section>
      <Divider autoSpacing={false} />
      <section className="flex flex-col gap-4 pt-4">
        <SubBodyText>
          <strong>Subscales:</strong>
        </SubBodyText>
        <div className="flex flex-col gap-2">
          <CaptionText>
            <strong>Inattentive subscale:</strong>
          </CaptionText>
          <Badge variant="neutral">
            {inattentiveSubscaleRawScore} of 9,{' '}
            {parseFloat((inattentiveSubscalePercentageScore * 100).toFixed(2))}%
          </Badge>
          <CaptionText color="gray">
            Measuring an adult's difficulty in focusing on details, being
            organized, remembering appointments, making careless mistakes, and
            concentrating.
          </CaptionText>
        </div>

        <div className="flex flex-col gap-2">
          <CaptionText>
            <strong>Hyperactive / impulsive (Motor subscale):</strong>
          </CaptionText>
          <Badge variant="neutral">
            {motorHyperactiveImpulsiveSubscaleRawScore} of 5,{' '}
            {parseFloat(
              (motorHyperactiveImpulsiveSubscalePercentageScore * 100).toFixed(
                2
              )
            )}
            %
          </Badge>
          <CaptionText color="gray">
            Measuring an adult's difficulty in sitting still, staying seated,
            and ability to relax.
          </CaptionText>
        </div>

        <div className="flex flex-col gap-2">
          <CaptionText>
            <strong>Hyperactive / impulsive (Verbal subscale):</strong>
          </CaptionText>
          <Badge variant="neutral">
            {verbalHyperactiveImpulsiveSubscaleRawScore} of 4,{' '}
            {parseFloat(
              (verbalHyperactiveImpulsiveSubscalePercentageScore * 100).toFixed(
                2
              )
            )}
            %
          </Badge>
          <CaptionText color="gray">
            Measuring an adult’s difficulty in controlling how much they are
            talking, interrupting others, and waiting their turn.
          </CaptionText>
        </div>
      </section>
    </div>
  );
};

interface AssessmentResultsModalProps {
  // Assessment to display.
  assessmentId: number;
  // Score from the previous assessment (if there is one) for displaying the score diff.
  previousScore?: number;
  // Client associated with the assessment.
  clientId: number;
  isOpen: boolean;
  onDismiss: () => void;
}

/**
 * Displays the results of a patient assessment. Assumes that the assessment has been completed.
 */
export const AssessmentResultsModal = ({
  assessmentId,
  previousScore,
  clientId,
  isOpen,
  onDismiss,
}: AssessmentResultsModalProps) => {
  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();

  const { data: assessment } = usePatientAssessment(
    { assessmentId },
    { enabled: isOpen, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    trackPageView({
      name: 'Client Assessment Results Modal Viewed',
      properties: {
        patientUserId: clientId,
        providerId: provider.id,
        assessmentId: assessmentId,
      },
    });
  }, [isOpen, assessmentId, clientId, provider.id]);

  if (!client || !assessment) {
    return (
      <Modal title="Loading..." isOpen={isOpen} onDismiss={onDismiss}>
        <ModalContent>
          <div className="flex h-[85vh] items-center justify-center">
            <LogoLoader />
          </div>
        </ModalContent>
      </Modal>
    );
  }

  const {
    assessmentType,
    score,
    completedOn,
    scorableResponseJson,
    safetyRisk,
  } = assessment;

  const TITLE = `${formatPatientName(client)}'s ${moment(completedOn!).format(
    'M/D/YY'
  )} ${SHORT_ASSESSMENT_NAMES[assessmentType]} results`;

  // Under 18 ASRS submissions responses are invalid and will not be visible to providers
  if (
    scorableResponseJson &&
    isASRSInvalid(assessmentType, scorableResponseJson)
  ) {
    return (
      <Modal title={TITLE} isOpen={isOpen} onDismiss={onDismiss}>
        <ModalContent>
          {client.displayFirstName} indicated they are under 18 years old. The
          ASRS is designed for adults 18+.
        </ModalContent>
      </Modal>
    );
  }
  if (
    !completedOn ||
    !scorableResponseJson ||
    (score == null && assessmentType !== PatientAssessmentType.ANCHOR)
  ) {
    throw new Error('Expected assessment to be completed');
  }
  return (
    <Modal title={TITLE} isOpen={isOpen} onDismiss={onDismiss}>
      <ModalContent>
        <div className="flex flex-col gap-6">
          {assessmentType === PatientAssessmentType.ASRS ? (
            <ASRSResultsInsights assessment={assessment} />
          ) : (
            score != null &&
            assessmentType !== PatientAssessmentType.ANCHOR && (
              <section className="grid grid-cols-2 gap-2">
                <div className="flex flex-col gap-2">
                  <SubBodyText>
                    <b>
                      Client's {SHORT_ASSESSMENT_NAMES[assessmentType]} results
                    </b>
                  </SubBodyText>
                  <Badge
                    variant={getVariantForAssessmentResult(
                      assessmentType,
                      score
                    )}
                  >
                    {getAssessmentScoreDescriptor(assessmentType, score)}
                  </Badge>
                  <Badge
                    variant={getVariantForAssessmentResult(
                      assessmentType,
                      score
                    )}
                  >
                    Score:{' '}
                    {getScoreRepresentationForAssessmentType(
                      score,
                      assessmentType
                    )}
                  </Badge>
                  {previousScore != null && (
                    <ScoreDiffBadge
                      assessmentType={assessmentType}
                      prevScore={previousScore}
                      currentScore={score}
                      prefix="Since last"
                    />
                  )}
                  {indicatesSiRisk(assessmentType, safetyRisk) && (
                    <Badge variant="negative">Safety risk</Badge>
                  )}
                </div>

                <div className="grid grid-cols-[max-content_1fr] items-center gap-x-4 gap-y-2">
                  <div className="col-span-2">
                    <SubBodyText>
                      <b>
                        {SHORT_ASSESSMENT_NAMES[assessmentType]} scoring guide
                      </b>
                    </SubBodyText>
                  </div>
                  {ASSESSMENT_SCORE_RANGES[assessmentType].map((range) => (
                    <React.Fragment key={range.startScore}>
                      <Badge variant={range.variant}>
                        {range.endScore != null
                          ? `${range.startScore} - ${range.endScore}`
                          : `${range.startScore}+`}
                      </Badge>
                      <CaptionText>{range.descriptor}</CaptionText>
                    </React.Fragment>
                  ))}
                </div>
              </section>
            )
          )}
          {assessmentType !== PatientAssessmentType.ANCHOR && (
            <Divider autoSpacing={false} />
          )}
          <section className="flex flex-col gap-2">
            <SubBodyText>
              <b>Client's responses:</b>
            </SubBodyText>
            <ReadonlyAssessmentRenderer
              assessmentType={assessmentType}
              response={assessment as ReadonlyAssessmentProps['response']}
            />
          </section>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button onPress={onDismiss}>Done</Button>
      </ModalFooter>
    </Modal>
  );
};
