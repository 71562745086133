import { PatientAssessmentCreateRequest } from '@headway/api/models/PatientAssessmentCreateRequest';
import { PatientAssessmentRead } from '@headway/api/models/PatientAssessmentRead';
import { PatientAssessmentRecurrenceScheduleRead } from '@headway/api/models/PatientAssessmentRecurrenceScheduleRead';
import { PatientAssessmentRecurrenceSchedulesReplaceRequest } from '@headway/api/models/PatientAssessmentRecurrenceSchedulesReplaceRequest';
import { PatientAssessmentApi } from '@headway/api/resources/PatientAssessmentApi';
import { PatientAssessmentRecurrenceScheduleApi } from '@headway/api/resources/PatientAssessmentRecurrenceScheduleApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';

import {
  usePaginatedUnreadAssessmentsPerPatientCache,
  UsePaginatedUnreadAssessmentsPerPatientQueryKeyArgs,
} from 'hooks/usePaginatedUnreadAssessmentsPerPatient';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export const useCreatePatientAssessmentsMutation = (
  options: UseMutationWithSideEffectsOptions<
    PatientAssessmentRead[],
    unknown,
    PatientAssessmentCreateRequest
  > = {}
) => {
  return useMutationWithSideEffects(
    (request: PatientAssessmentCreateRequest) =>
      PatientAssessmentApi.createPatientAssessments(request),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        PatientAssessmentRead[],
        unknown,
        PatientAssessmentCreateRequest
      >()
        .addErrorLogging(() => 'There was a problem sending assessments')
        .merge(options.sideEffects),
    }
  );
};

export interface ReplacePatientAssessmentRecurrenceSchedulesMutationArgs {
  providerPatientId: number;
  body: PatientAssessmentRecurrenceSchedulesReplaceRequest;
}

export const useReplacePatientAssessmentRecurrenceSchedulesMutation = (
  options: UseMutationWithSideEffectsOptions<
    PatientAssessmentRecurrenceScheduleRead[],
    unknown,
    ReplacePatientAssessmentRecurrenceSchedulesMutationArgs
  > = {}
) => {
  return useMutationWithSideEffects(
    ({
      providerPatientId,
      body,
    }: ReplacePatientAssessmentRecurrenceSchedulesMutationArgs) =>
      PatientAssessmentRecurrenceScheduleApi.replacePatientAssessmentRecurrenceSchedules(
        providerPatientId,
        body
      ),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        PatientAssessmentRecurrenceScheduleRead[],
        unknown,
        ReplacePatientAssessmentRecurrenceSchedulesMutationArgs
      >()
        .addErrorLogging(
          () => 'There was a problem creating assessment schedules'
        )
        .merge(options.sideEffects),
    }
  );
};

export interface DismissUnreadAssessmentSummaryArgs {
  providerId: number;
  providerPatientId: number;
}

export const useDismissUnreadAssessmentSummaryMutation = (
  queryKeysToUpdate: UsePaginatedUnreadAssessmentsPerPatientQueryKeyArgs[] = [],
  options: UseMutationWithSideEffectsOptions<
    {},
    unknown,
    DismissUnreadAssessmentSummaryArgs
  > = {}
) => {
  const unreadPatientAssessmentSummaryCache =
    usePaginatedUnreadAssessmentsPerPatientCache();

  let sideEffectsBuilder = new SideEffectsBuilder<
    {},
    unknown,
    DismissUnreadAssessmentSummaryArgs
  >().addErrorLogging(
    () => 'There was a problem dismissing the unread assessment summary'
  );
  for (const queryKeyArgs of queryKeysToUpdate) {
    sideEffectsBuilder = sideEffectsBuilder.addOptimisticUpdate(
      unreadPatientAssessmentSummaryCache,
      () => queryKeyArgs,
      ({ providerPatientId }, old) => {
        if (!old) {
          return undefined;
        }
        return {
          totalCount: old.totalCount - 1,
          data: old.data.filter(
            (unreadAssessmentSummary) =>
              unreadAssessmentSummary.providerPatientId !== providerPatientId
          ),
        };
      }
    );
  }

  return useMutationWithSideEffects(
    ({ providerPatientId }: DismissUnreadAssessmentSummaryArgs) =>
      ProviderPatientApi.updateProviderPatient(providerPatientId, {
        providerLastViewedAssessmentsOn: new Date().toISOString(),
      }),
    {
      ...options,
      sideEffects: sideEffectsBuilder.merge(options.sideEffects),
    }
  );
};
