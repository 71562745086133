import { css } from '@emotion/react';
import React, { useEffect } from 'react';

import { usePrevious } from '@headway/ui/hooks/usePrevious';
import { theme } from '@headway/ui/theme';

/** Props passed to the function provided for renderControls. */
export interface RenderControlsProps {
  /** Function which advances to the next page. */
  next: () => void;
  /** Function which moves to the previous page. */
  prev: () => void;
  /** True if there is a next page to advance to. */
  hasNext: boolean;
  /** True if there is a previous page to return to. */
  hasPrev: boolean;
}

export interface CarouselProps {
  /** The items to cycle through in the carousel. Items need not have a key prop. */
  items: React.ReactNode[];
  /** The number of items to display at once. */
  itemsPerPage: number;
  /**
   * A function which returns the rendered controls to display alongside the carousel. The function
   * receives props useful for rendering "Next" and "Previous" buttons.
   */
  renderControls: (props: RenderControlsProps) => React.ReactNode;
  /** The gap between items, defined as a CSS value, e.g. "1rem" */
  gap?: string;
}

/**
 * Component which can cycle through series of items with a horizontal scroll.
 * Some CSS classes are exposed for customization:
 * .carousel-inner — The inner container which holds each item in the carousel.
 * .carousel-item-wrapper — The wrapper div around each item.
 */
export const Carousel = ({
  items,
  renderControls,
  itemsPerPage,
  gap = '0px',
  ...rest
}: CarouselProps) => {
  const [page, setPage] = React.useState<number>(0);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const previousItemsPerPage = usePrevious(itemsPerPage);

  const handleNext = () => {
    setPage((page) => (page < totalPages - 1 ? page + 1 : page));
  };
  const handlePrev = () => {
    setPage((page) => (page > 0 ? page - 1 : page));
  };

  const leftmostItem = Math.min(
    Math.floor(page * itemsPerPage),
    items.length - itemsPerPage
  );
  const previousLeftmostItem = usePrevious(leftmostItem);

  useEffect(() => {
    if (
      previousItemsPerPage !== undefined &&
      itemsPerPage !== previousItemsPerPage
    ) {
      // When the itemsPerPage changes, adjust the page to try to keep the leftmost visible item
      // onscreen.
      setPage(Math.floor(previousLeftmostItem! / itemsPerPage));
    }
  }, [itemsPerPage, previousItemsPerPage, previousLeftmostItem, page]);

  return (
    <>
      {renderControls({
        next: handleNext,
        prev: handlePrev,
        hasNext: page < totalPages - 1,
        hasPrev: page > 0,
      })}
      <div css={outerContainerCss} {...rest}>
        <div>
          <div>
            <div
              className="carousel-inner"
              css={{
                '--gap': gap,
                '--space-without-gaps': `calc(100% - ${
                  Math.ceil(itemsPerPage) - 1
                } * ${gap})`,
                '--width': `calc(var(--space-without-gaps) / ${itemsPerPage})`,
                '--scroll': `calc(${leftmostItem} * var(--width) + ${Math.floor(
                  leftmostItem
                )} * var(--gap))`,
              }}
            >
              {items.map((item, idx) => (
                <div className="carousel-item-wrapper" key={idx}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const outerContainerCss = css`
  position: relative;
  width: 100%;

  & > div {
    ${theme.media.small} {
      overflow: hidden;
    }
    height: 100%;
  }

  & > div > div {
    height: 100%;
  }

  & .carousel-inner {
    gap: var(--gap);
    display: flex;
    flex-direction: row;

    ${theme.media.smallDown} {
      scroll-snap-type: x mandatory;
      overflow: auto;
    }
    ${theme.media.small} {
      position: relative;
      overflow: visible;
      white-space: nowrap;
      height: 100%;

      transform: translateX(calc(-1 * var(--scroll)));
      transition: transform cubic-bezier(0.5, 1, 0.89, 1) 600ms;
    }
  }

  & .carousel-item-wrapper {
    width: var(--width);
    flex-shrink: 0;

    ${theme.media.smallDown} {
      scroll-snap-align: end;
    }

    ${theme.media.small} {
      white-space: normal;
    }
  }
`;
