import { Card, CardContent } from '@mui/material';
import { useProvider } from 'hooks';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { getLatestQuestionnaire } from '@headway/shared/utils/providerQuestionnaire';
import { UploadedFile } from '@headway/shared/utils/upload';
import { Dropzone } from '@headway/ui/Dropzone';

import { onDropFiles } from 'utils/providerQuestionnaire';
import { createCoiPqv } from 'views/IntakeQuestionnaireV2/steps/Malpractice/Malpractice';

import { DataField, ExpirationDataField } from './CoiDataFields';
import { CoiPathway } from './CoiPathway';
import {
  CoiPathwayType,
  formatNumber,
  updateExpiringSoonMalpractice,
} from './CoiUtils';

const TextContent = ({
  pqv,
}: {
  pqv?: ProviderQuestionnaireVerificationRead;
}) => {
  const provider = useProvider();
  return (
    <CardContent>
      <BodyText>
        <div className="grid gap-y-4">
          <div className="text-base">
            <strong>Your coverage details:</strong>
          </div>
          <DataField
            fieldName="Name insured"
            fieldContent={
              pqv?.responseJson?.nameInsuredFound ? provider.name : ''
            }
          />
          <ExpirationDataField
            fieldName="Expiration date"
            fieldContent={moment(
              pqv?.responseJson?.coverageEndDateFound
            ).format('MM/DD/YYYY')}
            errorMsg="This policy is expiring within two weeks."
          />
          <DataField
            fieldName="Occurrence limit"
            fieldContent={
              formatNumber(pqv?.responseJson?.perClaimCoverageLimitFound) ?? ''
            }
          />
          <DataField
            fieldName="Aggregate limit"
            fieldContent={
              formatNumber(pqv?.responseJson?.aggregateCoverageLimitFound) ?? ''
            }
          />
        </div>
      </BodyText>
    </CardContent>
  );
};

export const CoiExpiringSoon = ({
  coiVerification,
  firstFileDropped,
  coiPathway,
  setCoiPathway,
}: {
  coiVerification?: ProviderQuestionnaireVerificationRead;
  firstFileDropped: UploadedFile;
  coiPathway: CoiPathwayType;
  setCoiPathway: (arg: CoiPathwayType) => void;
}) => {
  const [verificationId, setVerificationId] = useState<number>(0);
  const [uploadedFirstMalpractice, setUploadedFirstMalpractice] =
    useState<boolean>(false);
  const [secondFileDropped, setSecondFileDropped] = useState<boolean>(false);
  const [userDroppedFile, setUserDroppedFile] = useState<File>();
  const [questionnaireId, setQuestionnaireId] = useState<number>();
  const [uploadedFileObject, setUploadedFileObject] =
    useState<UploadedFile[]>();
  const provider = useProvider();

  if (!uploadedFirstMalpractice && coiVerification) {
    updateExpiringSoonMalpractice(coiVerification, provider, firstFileDropped);
    setUploadedFirstMalpractice(true);
  }

  useEffect(() => {
    const fetchLatestQuestionnaireId = async () => {
      const questionnaires = await getLatestQuestionnaire(provider.id);
      if (questionnaires.selectedQuestionnaire) {
        setQuestionnaireId(questionnaires.selectedQuestionnaire?.id);
      }
    };

    fetchLatestQuestionnaireId();
  }, [provider.id]);

  const expiringSoonDropzoneLogic = async (files: File[]) => {
    let processedFiles = await onDropFiles(provider.id, files);

    if (!processedFiles || !processedFiles.length) {
      setSecondFileDropped(false);
      return processedFiles;
    }
    const coiS3Key = processedFiles[0].s3ObjectKey;
    if (!coiS3Key) {
      setSecondFileDropped(false);
      return processedFiles;
    }
    if (!questionnaireId) {
      return processedFiles;
    }
    const createdPqv = await createCoiPqv(coiS3Key, questionnaireId);
    setVerificationId(createdPqv.id);

    const processedFile = [
      {
        name: processedFiles[0].name,
        link: processedFiles[0].link,
        s3ObjectKey: coiS3Key,
      },
    ] as UploadedFile[];

    setSecondFileDropped(true);
    setUserDroppedFile(files[0]);
    setUploadedFileObject(processedFile);
    return processedFiles;
  };

  return (
    <div className="grid gap-y-4">
      <div>
        <Card variant="outlined" sx={{ marginBottom: 1 }}>
          <TextContent pqv={coiVerification} />
        </Card>
      </div>
      <div className="grid gap-y-4">
        <span className="hlx-modal-heading">
          {'Second malpractice insurance'}
        </span>
        {!secondFileDropped && (
          <GuidanceCard variant="warning" layout="vertical">
            <div>
              <b>Your policy is expiring within 14 days.</b> Add another policy
              that overlaps with this policy to ensure a smooth credentialing
              experience.
            </div>
          </GuidanceCard>
        )}
        <div>
          {secondFileDropped &&
            !!userDroppedFile &&
            !!uploadedFileObject &&
            questionnaireId && (
              <CoiPathway
                file={userDroppedFile}
                verificationId={verificationId}
                secondFileDropped={secondFileDropped}
                uploadedFileObject={uploadedFileObject}
                coiPathway={coiPathway}
                setCoiPathway={setCoiPathway}
              />
            )}
        </div>
        <div>
          {coiPathway !== CoiPathwayType.SUBMITTED &&
            coiPathway !== CoiPathwayType.SUCCESS && (
              <Dropzone
                height={75}
                accept="application/pdf,image/*"
                onDrop={async (files: any) => {
                  expiringSoonDropzoneLogic(files);
                }}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export const CoiExpiringSoonSecond = ({
  coiVerification,
}: {
  coiVerification?: ProviderQuestionnaireVerificationRead;
}) => {
  return (
    <div className="grid gap-y-4">
      <div>
        <Card variant="outlined" sx={{ marginBottom: 1 }}>
          <TextContent pqv={coiVerification} />
        </Card>
      </div>
      <div className="grid gap-y-4">
        <GuidanceCard variant="error" layout="vertical">
          <div>
            <b>Your policy is expiring within 14 days.</b> Please try again with
            another policy to ensure a smooth credentialing experience.
          </div>
        </GuidanceCard>
      </div>
    </div>
  );
};
