import { RadioGroup, RadioGroupProps } from '@headway/helix/RadioGroup';
import { theme } from '@headway/helix/theme';

export const BigRadioGroup = (props: RadioGroupProps) => {
  const { children } = props;

  return (
    <RadioGroup {...props}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing.x2,
          marginLeft: '-4px',
          [theme.__futureMedia.phone]: {
            flexDirection: 'column',
          },
        }}
      >
        {children}
      </div>
    </RadioGroup>
  );
};
