import clsx from 'clsx';
import React from 'react';

interface PageSectionProps {
  children: React.ReactNode;
  className?: string;
  layout?: 'grid.two-column' | 'grid.one-column';
}

const PageSection = (props: PageSectionProps) => {
  const { layout = 'grid.one-column' } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <section
      ref={ref}
      className={clsx(`hlx-page-section`, props.className)}
      data-hlx-layout={layout}
    >
      {props.children}
    </section>
  );
};

interface PageSectionSubTextProps {
  children: React.ReactNode;
  className?: string;
}
const PageSectionSubText = (props: PageSectionSubTextProps) => {
  return (
    <div className={clsx('hlx-page-section-subtext', props.className)}>
      {props.children}
    </div>
  );
};

export { PageSection, PageSectionSubText };
