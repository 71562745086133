import { css } from '@emotion/react';
import { SaveAltTwoTone } from '@mui/icons-material';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';

import { UserUploadTypes } from '@headway/api/models/UserUploadTypes';
import { BodyText } from '@headway/helix/BodyText';
import { IconButton } from '@headway/helix/IconButton';
import { IconDelete } from '@headway/helix/icons/Delete';
import { IconDocument } from '@headway/helix/icons/Document';
import { theme } from '@headway/helix/theme';
import { downloadFile } from '@headway/shared/utils/download';
import { uploadFilesToS3 } from '@headway/shared/utils/upload';
import { Dropzone } from '@headway/ui/Dropzone';

import { FormValues, SchemaComponent } from '../../schema/schema.types';
import { getComponentData } from '../../schema/utils';

interface Attachment {
  link: string;
  name: string;
  s3ObjectKey: string;
}

interface AttachmentsListProps {
  attachments: Attachment[];
  onDeleteAttachment?: (attachment: string) => void;
  onDownloadAttachment?: (attachment: Attachment) => Promise<void>;
  loading?: boolean;
}

export const AttachmentsList = <T extends number | string>({
  attachments,
  onDownloadAttachment,
  onDeleteAttachment,
  loading,
}: AttachmentsListProps) => (
  <ul css={attachmentsCss.list}>
    {attachments.map((attachment, idx) => {
      return (
        <li css={attachmentsCss.listItem} key={idx}>
          <div css={attachmentsCss.listLabel}>
            <IconDocument width={30} height={30} />
            <BodyText>{attachment.name}</BodyText>
          </div>
          {onDownloadAttachment && (
            <IconButton
              aria-label={`Download ${attachment.name}`}
              onPress={() => onDownloadAttachment(attachment)}
            >
              <SaveAltTwoTone />
            </IconButton>
          )}
          {onDeleteAttachment && (
            <IconButton
              aria-label={`Delete ${attachment.name}`}
              onPress={() => onDeleteAttachment(attachment.name)}
            >
              <IconDelete />
            </IconButton>
          )}
        </li>
      );
    })}
  </ul>
);

interface UploadComponentData {
  uploadOwnerId: number;
  uploadType: UserUploadTypes;
  acceptedFileTypes: string[];
}

export const Upload = ({
  element,
  readOnly,
  injectedData,
  disabled,
}: SchemaComponent) => {
  const { id, title, subTitle } = element;
  const { uploadOwnerId, uploadType, acceptedFileTypes } =
    getComponentData<UploadComponentData>(element, injectedData);
  const { values, setFieldValue, errors, setFieldError, submitCount } =
    useFormikContext<FormValues>();

  const [uploadingAttachment, setUploadingAttachment] = React.useState(false);
  const uploadedFiles: Attachment[] | undefined = _.get(values, id);
  const uploadFormError = submitCount > 0 ? _.get(errors, id) : undefined;

  const onDropFiles = async (files: any[]) => {
    setUploadingAttachment(true);
    setFieldError(id, undefined);

    try {
      const processedFiles = await uploadFilesToS3(
        files,
        uploadType,
        uploadOwnerId
      );
      const successfullyProcessedFiles = processedFiles.filter(
        (file) => !!file
      );
      setFieldValue(id, [
        ...successfullyProcessedFiles,
        ...(uploadedFiles ?? []),
      ]);
    } catch (e) {
      setUploadingAttachment(false);
    }

    setUploadingAttachment(false);
  };

  const onDeleteAttachment = (name: string) => {
    if (!uploadedFiles) {
      return;
    }

    const uploadedFilesCopy = uploadedFiles.map((file) => ({ ...file }));
    const fileIndex = uploadedFilesCopy.findIndex((file) => file.name === name);
    uploadedFilesCopy.splice(fileIndex, 1);
    setFieldValue(id, uploadedFilesCopy);
  };

  const onDownloadAttachment = async (attachment: {
    link: string;
    name: string;
  }) => {
    try {
      await downloadFile(attachment);
    } catch (err) {}
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.x1,
      }}
    >
      <label
        css={{
          ...theme.typography.caption.medium,
          color: theme.color.system.textBlack,
        }}
      >
        {title}
      </label>
      <div
        css={{
          ...theme.typography.caption.regular,
          color: theme.color.system.textBlack,
        }}
      >
        {subTitle}
      </div>
      {!readOnly && (
        <Dropzone
          variant="helix"
          accept={
            acceptedFileTypes ? acceptedFileTypes.toString() : 'application/pdf'
          }
          onDrop={onDropFiles}
          disabled={uploadingAttachment || disabled}
        />
      )}
      {uploadFormError && (
        <div
          css={{
            color: theme.color.primary.red,
            ...theme.typography.caption.medium,
          }}
        >
          {uploadFormError as string}
        </div>
      )}
      {uploadedFiles && (
        <AttachmentsList
          attachments={uploadedFiles ?? []}
          onDeleteAttachment={
            readOnly || disabled ? undefined : onDeleteAttachment
          }
          onDownloadAttachment={disabled ? undefined : onDownloadAttachment}
        />
      )}
    </div>
  );
};

const attachmentsCss = {
  container: css({
    borderRadius: theme.spacing.x1,
    border: `1px solid ${theme.color.system.borderGray}`,
    padding: theme.spacing.x2,
    width: '100%',
  }),
  list: css({
    padding: 0,
    margin: 0,
  }),
  listItem: css({
    display: 'flex',
    gap: theme.spacing.x2,
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 'auto',
    },
    padding: theme.spacing.x2,
  }),
  listLabel: css({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.x2,
  }),
  spinner: css({
    textAlign: 'center',
  }),
};
