import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { RichTextArea } from '@headway/helix/RichTextArea';
import {
  getBioTherapyApproachHeader,
  PROVIDER_BIO_ABOUT_YOU_PROMPT,
  PROVIDER_BIO_TAKE_AWAYS_PROMPT,
} from '@headway/shared/utils/providers';
import { logException } from '@headway/shared/utils/sentry';
import { getContentOfHtml } from '@headway/shared/utils/strings';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { WizardStep } from 'components/Wizard/WizardStep';
import { useProvider } from 'hooks/useProvider';
import { useAuthStore } from 'stores/AuthStore';

import { PrimaryContentHeader, SecondaryContent } from './ProfileWizard';

export type BioFormValues = {
  bioAboutYou: string;
  bioTherapyApproach: string;
  bioTakeAways: string;
};

const validationSchema = Yup.object().shape({
  bioAboutYou: Yup.string()
    .transform((value) => getContentOfHtml(value))
    .min(50, ({ min, value }) => {
      return `Must be at least ${min} characters. (${value.length}/${min}).`;
    })
    .max(500, ({ max, value }) => {
      return `Cannot be more than ${max} characters. (${value.length}/${max}).`;
    })
    .required('Field is required'),
  bioTherapyApproach: Yup.string()
    .transform((value) => getContentOfHtml(value))
    .min(50, ({ min, value }) => {
      return `Must be at least ${min} characters. (${value.length}/${min}).`;
    })
    .max(500, ({ max, value }) => {
      return `Cannot be more than ${max} characters. (${value.length}/${max}).`;
    })
    .required('Field is required'),
  bioTakeAways: Yup.string()
    .transform((value) => getContentOfHtml(value))
    .min(50, ({ min, value }) => {
      return `Must be at least ${min} characters. (${value.length}/${min}).`;
    })
    .max(500, ({ max, value }) => {
      return `Cannot be more than ${max} characters. (${value.length}/${max}).`;
    })
    .required('Field is required'),
});

export const BioStep = () => {
  const provider = useProvider();
  const authStore = useAuthStore();

  const initialValues: BioFormValues = {
    bioAboutYou: provider.bioAboutYou || '',
    bioTherapyApproach: provider.bioTherapyApproach || '',
    bioTakeAways: provider.bioTakeAways || '',
  };

  let initialValuesAreValid = false;
  try {
    validationSchema.validateSync(initialValues);
    initialValuesAreValid = true;
  } catch (e) {
    // Let value remain false
  }

  const onSubmit = async (values: BioFormValues) => {
    const bioFormValues = {
      bioAboutYou: values.bioAboutYou,
      bioTherapyApproach: values.bioTherapyApproach,
      bioTakeAways: values.bioTakeAways,
    };

    try {
      const updatedProvider = await ProviderApi.updateProvider(
        provider.id,
        bioFormValues
      );
      authStore.setProvider(updatedProvider);
    } catch (err) {
      notifyError('An error occurred while saving your progress.');
      logException(err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isValid, dirty, values }) => {
        return (
          <WizardStep
            onSaveAndContinue={async () => await submitForm()}
            onSaveAndExit={async () => await onSubmit(values)}
            primaryContent={
              <>
                <PrimaryContentHeader
                  title={PROVIDER_BIO_ABOUT_YOU_PROMPT}
                  description="Introduce yourself to help prospective clients learn about you and your practice. We recommend including your professional background, education and any specific treatment areas you specialize in."
                />
                <FormControl
                  component={RichTextArea}
                  name="bioAboutYou"
                  aria-label={PROVIDER_BIO_ABOUT_YOU_PROMPT}
                />
                <PrimaryContentHeader
                  title={getBioTherapyApproachHeader(provider.isPrescriber)}
                  description="Describe how you work with clients, from your style to any specializations or focuses"
                />
                <FormControl
                  component={RichTextArea}
                  name="bioTherapyApproach"
                  aria-label={getBioTherapyApproachHeader(
                    provider.isPrescriber
                  )}
                />

                <PrimaryContentHeader
                  title={PROVIDER_BIO_TAKE_AWAYS_PROMPT}
                  description="Include goals or outcomes you typically set or the type of environment you create."
                />
                <FormControl
                  component={RichTextArea}
                  name="bioTakeAways"
                  aria-label={PROVIDER_BIO_TAKE_AWAYS_PROMPT}
                />
              </>
            }
            secondaryContentWrapperCss={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
            secondaryContent={
              <div css={{ display: 'flex', flexDirection: 'column' }}>
                <SecondaryContent
                  title={'What makes a great intro?'}
                  doContent={[
                    'Be welcoming and friendly - show your personality!',
                    'Keep it concise (3-5 sentences)',
                    'Use simplified, easily digestible language',
                  ]}
                  avoidContent={[
                    `Share details that aren't relevant to your practice or approach to care`,
                    'Use long sentences or overly clinical terms',
                  ]}
                />
                <a
                  href="https://headway.co/providers/jane-sussman"
                  target="_blank"
                  rel="noreferrer"
                  css={{
                    marginTop: theme.space.base,
                    paddingLeft: theme.space.xs,
                  }}
                >
                  <u>See Example</u>
                </a>
              </div>
            }
            areRequirementsMet={dirty ? isValid : initialValuesAreValid}
          />
        );
      }}
    </Formik>
  );
};
