/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum PatientAssessmentType {
    ADNM8 = 'ADNM8',
    ANCHOR = 'ANCHOR',
    ASRS = 'ASRS',
    GAD7 = 'GAD7',
    IESR = 'IESR',
    ISI = 'ISI',
    PCL5 = 'PCL5',
    PHQ9 = 'PHQ9',
    PROMIS = 'PROMIS',
    WHO5 = 'WHO5',
    WHODAS_2 = 'WHODAS2'
}
