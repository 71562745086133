import { ProviderModuleStatus } from '@headway/api/models/ProviderModuleStatus';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@headway/shared/react-query';

import { ONBOARDING_MODULE_STATUSES_CACHE_KEY } from 'utils/cacheKeys';

import { useProvider } from './useProvider';

/** Hook for fetching the status of the current provider's relevant onboarding modules. */
export const useOnboardingModuleStatuses = (
  options: UseQueryOptions<ProviderModuleStatus[]> = {}
): UseQueryResult<ProviderModuleStatus[]> => {
  const provider = useProvider();
  const providerId = provider?.id;
  return useQuery(
    [ONBOARDING_MODULE_STATUSES_CACHE_KEY, providerId] as QueryKey,
    () => ProviderApi.getOnboardingModuleStatuses(providerId!),
    { enabled: !!provider, ...options }
  );
};
