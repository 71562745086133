import { useFormikContext } from 'formik';
import get from 'lodash/get';
import React, { useCallback, useMemo, useState } from 'react';

import { ComboBox, Item } from '@headway/helix/ComboBox';
import { validity } from '@headway/helix/FormControl';

import { SchemaComponent } from '../../schema/schema.types';
import { ViewComponent } from './ViewComponent';

const noneOptions = ['None', 'Within normal limits'];

const MemoizedComboBox = React.memo(ComboBox);

export const hasValue = (set: Set<string>, value: string | string[]) => {
  if (Array.isArray(value)) {
    const arr = Array.from(set);
    return (
      arr.filter((item) =>
        value.find((v) => {
          return v === item.trim();
        })
      ).length > 0
    );
  } else {
    const arr = Array.from(set);
    return arr.filter((item) => item.trim() === value).length > 0;
  }
};

/**
 * This component is heavily memoized in order to prevent unnecessary rerenders of the child
 * combobox component on large forms. If making changes to this component, you should validate that
 * there are no performance regressions.
 */
export const FormMultipleComboBox = ({
  element,
  isOptional = false,
  disabled = false,
  readOnly,
}: SchemaComponent) => {
  const { id, title, options, placeholder, subTitle } = element;
  const formik = useFormikContext();

  const formikSelectedKeys = get(formik.values as any, id);

  const selectedKeys = useMemo(
    () => formikSelectedKeys || [],
    [formikSelectedKeys]
  );

  const componentsNoneOption = options.find((option: string) =>
    noneOptions.find((noneOption) => option === noneOption)
  );

  const [previouslyHadNone, setPreviouslyHadNone] = useState(
    hasValue(selectedKeys, noneOptions)
  );
  const [isCloseOptionSelected, setIsCloseOptionSelected] = useState(false);

  const selectOptions = useMemo(
    () =>
      Array.from(options as string[]).map((option) => {
        return { key: option };
      }),
    [options]
  );

  const child = useCallback(
    (item: { key?: string }) => <Item>{item?.key}</Item>,
    []
  );

  const setFieldValue = formik.setFieldValue;
  const onSelectionChange = useCallback(
    (value: Set<string>) => {
      // closes the drowDown of the comboBox if noneOptions or 'Other' is selected
      if (hasValue(value, [...noneOptions, 'Other'])) {
        setIsCloseOptionSelected(true);
      } else {
        setIsCloseOptionSelected(false);
      }

      /**
       * Functionality for deselecting other options if None
       * is selected, or deselectign None if another option
       * is selected.
       */
      if (previouslyHadNone && hasValue(value, noneOptions) && value.size > 0) {
        noneOptions.forEach((option) => {
          if (value.has(option)) {
            value.delete(option);
          }
        });
      } else if (
        !previouslyHadNone &&
        hasValue(value, noneOptions) &&
        value.size > 0
      ) {
        setFieldValue(id, [componentsNoneOption]);
        setPreviouslyHadNone(true);
        return;
      }

      if (!hasValue(value, noneOptions)) {
        setPreviouslyHadNone(false);
      }

      if (!hasValue(value, 'Other')) {
        setFieldValue(
          id,
          Array.from(value).filter((item) => !item.startsWith('Other-'))
        );
      } else {
        setFieldValue(id, Array.from(value));
      }
    },
    [
      setFieldValue,
      id,
      previouslyHadNone,
      setPreviouslyHadNone,
      componentsNoneOption,
      setIsCloseOptionSelected,
    ]
  );

  if (readOnly) {
    return <ViewComponent title={title} value={selectedKeys.join(', ')} />;
  }

  return (
    <MemoizedComboBox
      name={id}
      label={title}
      items={selectOptions}
      selectedKeys={selectedKeys}
      selectionMode="multiple"
      menuWidth="stretch"
      validation={validity(id, formik)}
      onSelectionChange={onSelectionChange}
      optionalityText={isOptional ? 'Optional' : ''}
      disabled={disabled}
      placeholder={placeholder}
      instructionalText={subTitle}
      isOpen={isCloseOptionSelected === true ? false : undefined}
    >
      {child}
    </MemoizedComboBox>
  );
};
