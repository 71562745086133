import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import React, { FC } from 'react';

import { theme } from './theme';

export const Tooltip: FC<React.PropsWithChildren<MuiTooltipProps>> = ({
  title,
  ...rest
}: MuiTooltipProps) => (
  <MuiTooltip
    title={
      title ? <span css={{ fontSize: theme.fontSize.xs }}>{title}</span> : ''
    }
    arrow={true}
    {...rest}
  />
);
