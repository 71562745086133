import { Skeleton } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useContext, useState } from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { LinkButton } from '@headway/helix/LinkButton';
import {
  SelectedEventContext,
  SelectedEventContextProvider,
} from '@headway/shared/events/SelectedEventContext';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';

import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';
import { usePaginatedProviderDocumentRequestsAndRemediations } from 'hooks/usePaginatedProviderDocumentRequestsAndRemediations';
import { useProvider } from 'hooks/useProvider';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import { PanelLayout } from 'layouts/PanelLayout';
import { OnboardingCompletionCardContext } from 'providers/OnboardingCompletionCardProvider';
import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';
import { useTailwindGlobals } from 'utils/css';
import { AppointmentConfirmation } from 'views/Calendar/components/AppointmentConfirmation/AppointmentConfirmation';

import { AssessmentsCard } from './AssessmentsCard';
import { ChartReviewCombinedCard } from './ChartReviewCombinedCard';
import { EnrollmentSummaryCard } from './EnrollmentSummaryCard';
import { LearningHub } from './LearningHub';
import { NewClientReferrals } from './NewClientReferrals';
import { OnboardingCard } from './OnboardingCard';
import { OnboardingCompletionCard } from './OnboardingCompletionCard';
import { PracticeAtAGlance } from './PracticeAtAGlance';
import { TasksToDo } from './TasksToDo';
import { UpcomingAppointments } from './UpcomingAppointments';
import {
  providerCompletedClientReferralOnboardingModules,
  providerCompletedRequiredOnboardingModules,
} from './utils';

const HomeImpl = () => {
  useTailwindGlobals();
  const provider = useProvider();
  const auth = useAuthStore();

  const practiceAtAGlanceFlagEnabled = useFlag('practiceAtAGlance');

  const onboardingStatusesQuery = useOnboardingModuleStatuses();
  const onboardingStatuses = onboardingStatusesQuery.data || [];
  const isProviderGroupPracticeMember =
    !!provider.groupPracticeId &&
    !auth?.user?.group_practice?.roles?.includes('Owner');

  const onboardingComplete = onboardingStatuses.every(
    ({ isComplete }) => isComplete
  );
  const [shouldShowOnboardingCompletionCard] = useContext(
    OnboardingCompletionCardContext
  );

  const { data: chartReviewResult } =
    usePaginatedProviderDocumentRequestsAndRemediations({
      providerId: provider.id,
    });

  const isChartReviewCardShowing =
    chartReviewResult && chartReviewResult?.chartReviewItems?.length > 0;

  const providerLiveForThirtyDays =
    provider.earliestActiveLiveOn &&
    moment(provider.earliestActiveLiveOn) < moment().subtract(30, 'days');
  const [providerClosedOnboardingCard] = useLocalStorage(
    'providerClosedOnboardingCard'
  );

  const clientReferralOnboardingComplete =
    providerCompletedClientReferralOnboardingModules(onboardingStatuses);
  const onboardingSkipped =
    (providerCompletedRequiredOnboardingModules(
      onboardingStatuses,
      isProviderGroupPracticeMember
    ) &&
      providerLiveForThirtyDays) ||
    providerClosedOnboardingCard === 'true';

  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const { setSelectedEventVirtualId } = useContext(SelectedEventContext);
  const { event: selectedEvent } = useSelectedEvent();

  return (
    <PanelLayout>
      <div className="mt-8 px-4">
        <h3>
          <BrandText>Hi, {provider.displayFirstName}.</BrandText>
        </h3>
      </div>
      <div className="flex flex-col">
        <ChartReviewCombinedCard
          onEventSelected={(selectedEvent: ConcreteProviderEventRead) => {
            setSelectedEventVirtualId(selectedEvent.virtualId);
            setIsAppointmentModalOpen(true);
          }}
        />

        {!isChartReviewCardShowing && <EnrollmentSummaryCard />}
        {onboardingStatusesQuery.isFetched ? (
          <>
            {!onboardingComplete && !onboardingSkipped && <OnboardingCard />}
            {(onboardingComplete || onboardingSkipped) && <TasksToDo />}
            {practiceAtAGlanceFlagEnabled &&
              (onboardingComplete || onboardingSkipped) &&
              hasRateAccess(auth.provider, auth.user) && <PracticeAtAGlance />}
            {clientReferralOnboardingComplete && <NewClientReferrals />}
            {onboardingComplete &&
              !onboardingSkipped &&
              shouldShowOnboardingCompletionCard && (
                <OnboardingCompletionCard />
              )}
            <AssessmentsCard />
            {(onboardingComplete || onboardingSkipped) &&
              !shouldShowOnboardingCompletionCard && <UpcomingAppointments />}
          </>
        ) : (
          <Skeleton variant="rectangular" width={860} height={323} />
        )}
        <LearningHub
          isOnboardingComplete={onboardingComplete || onboardingSkipped}
        />
      </div>
      {selectedEvent?.providerAppointment?.patient && (
        <AppointmentConfirmation
          progressNoteOnly={true}
          open={isAppointmentModalOpen}
          onClose={() => {
            setIsAppointmentModalOpen(false);
            setSelectedEventVirtualId(undefined);
          }}
          onOpenContactFormInsuranceIssues={() => {}}
          eventVirtualId={selectedEvent.virtualId}
          patientId={selectedEvent.providerAppointment.patient.id}
          setSelectedEvent={(selectedEvent) =>
            setSelectedEventVirtualId(selectedEvent?.virtualId)
          }
        />
      )}
    </PanelLayout>
  );
};

export const Home = () => (
  <SelectedEventContextProvider>
    <HomeImpl />
  </SelectedEventContextProvider>
);
