import { PrelimPricingType } from '@headway/api/models/PrelimPricingType';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { isUserSelfPayWithRespectToProvider } from '@headway/shared/utils/selfPay';

const isPatientInPrelimPricing = (
  prelimPricingType: PrelimPricingType,
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  const isSelfPay =
    patient &&
    !!providerId &&
    isUserSelfPayWithRespectToProvider(patient, providerId);
  let insurance;
  if (patientInsurance) {
    insurance = patientInsurance;
  } else if (patient && patient.activeUserInsurance) {
    insurance = patient.activeUserInsurance;
  } else {
    return false;
  }

  return (
    isSelfPay !== undefined &&
    !isSelfPay &&
    !!insurance.prelimPriceActivatedOn &&
    !insurance.prelimPriceDeactivatedOn &&
    insurance.prelimPricingType === prelimPricingType
  );
};

export const isPatientInNoDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return isPatientInPrelimPricing(
    PrelimPricingType.NO_DATA_USE_FULL_COST,
    patientInsurance,
    patient,
    providerId
  );
};

export const isPatientInOldDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return isPatientInPrelimPricing(
    PrelimPricingType.OLD_DATA_PRESUME_ACCUMULATOR_RESET,
    patientInsurance,
    patient,
    providerId
  );
};

export const wasPatientInPrelimPricing = (
  prelimPricingType: PrelimPricingType,
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  const isSelfPay =
    patient &&
    !!providerId &&
    isUserSelfPayWithRespectToProvider(patient, providerId);
  let insurance;
  if (patientInsurance) {
    insurance = patientInsurance;
  } else if (patient && patient.activeUserInsurance) {
    insurance = patient.activeUserInsurance;
  } else {
    return false;
  }

  return (
    isSelfPay !== undefined &&
    !isSelfPay &&
    !!insurance.prelimPriceActivatedOn &&
    !!insurance.prelimPriceDeactivatedOn &&
    insurance.prelimPricingType === prelimPricingType
  );
};

export const wasPatientInNoDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return wasPatientInPrelimPricing(
    PrelimPricingType.NO_DATA_USE_FULL_COST,
    patientInsurance,
    patient,
    providerId
  );
};

export const wasPatientInOldDataPrelimPricing = (
  patientInsurance?: UserInsuranceRead,
  patient?: UserRead,
  providerId?: number
): boolean => {
  return wasPatientInPrelimPricing(
    PrelimPricingType.OLD_DATA_PRESUME_ACCUMULATOR_RESET,
    patientInsurance,
    patient,
    providerId
  );
};
