import { AddCircleOutlineTwoTone } from '@mui/icons-material';
import { Divider, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { useMediaQuery } from '@headway/helix/utils';
import { Button } from '@headway/ui/Button';
import { theme } from '@headway/ui/theme';

export const ScheduleEventButton = ({
  handleCreateEventClick,
  component: Component = Button,
  provider,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const matches = useMediaQuery(`(max-width: ${theme.breakpoints.small}px)`);

  return (
    <React.Fragment>
      {matches ? (
        <Component
          aria-controls="create-menu"
          aria-haspopup="true"
          color="success"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          css={{
            minWidth: 0,
            background: `${theme.color.primary} !important`,
            borderColor: `${theme.color.primary} !important`,
            color: `${theme.color.white} !important`,
            '.MuiButton-startIcon': {
              margin: 0,
            },
          }}
          variant="contained"
          startIcon={<AddCircleOutlineTwoTone />}
          {...rest}
        />
      ) : (
        <Component
          aria-controls="create-menu"
          aria-haspopup="true"
          color="success"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          css={{
            background: `${theme.color.primary} !important`,
            borderColor: `${theme.color.primary} !important`,
            color: `${theme.color.white} !important`,
          }}
          startIcon={<AddCircleOutlineTwoTone />}
          {...rest}
        >
          Add
        </Component>
      )}
      <Menu
        {...rest}
        id="create-menu"
        keepMounted={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          key={ProviderEventType.APPOINTMENT}
          onClick={() => {
            handleCreateEventClick(ProviderEventType.APPOINTMENT);
            handleClose();
          }}
        >
          Session
        </MenuItem>
        <MenuItem
          key={ProviderEventType.INTAKE_CALL}
          onClick={() => {
            handleCreateEventClick(ProviderEventType.INTAKE_CALL);
            handleClose();
          }}
        >
          Phone consultation
        </MenuItem>
        <Divider key="divider" css={{ margin: `${theme.space.xs} 0` }} />

        <MenuItem
          key={ProviderEventType.BUSY}
          onClick={() => {
            handleCreateEventClick(ProviderEventType.BUSY);
            handleClose();
          }}
        >
          Unavailability
        </MenuItem>
        <MenuItem component={Link} to="/settings/calendar">
          Update working hours
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
