import React from 'react';

import { theme } from '@headway/helix/theme';

interface DividerProps {
  autoSpacing?: boolean;
}

const Divider = ({ autoSpacing = true }: DividerProps) => {
  return (
    <hr
      className="hlx-divider"
      style={{
        margin: autoSpacing ? `${theme.spacing.x8} 0` : 0,
      }}
    />
  );
};

export { Divider };
