import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useContext } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { BodyText } from '@headway/helix/BodyText';
import { CaptionText } from '@headway/helix/CaptionText';
import { IconCircleCheck } from '@headway/helix/icons/CircleCheck';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import HeadwayLogo from '@headway/ui/icons/HeadwayLogo';

import { stepHasErrors } from '../Template/errors';
import { TreatmentPlanContext } from '../TreatmentPlanContext';
import { stepHasAllValues } from '../TreatmentPlanUtils';
import { StepIndicator } from './StepPreview/StepIndicator';
import { StepPreview } from './StepPreview/StepPreview';

interface PreviewCardProps {
  providerPatient: ProviderPatientRead;
}
export const PreviewCard = ({ providerPatient }: PreviewCardProps) => {
  const { data: client, isLoading: clientIsLoading } = useUser({
    userId: providerPatient.userId,
  });
  // Prefer pulling info from insurance if it exists, otherwise use client.
  const insurance = client?.activeUserInsurance;
  const name = insurance
    ? `${insurance.firstName} ${insurance.lastName}`
    : `${client?.firstName} ${client?.lastName}`;
  const dob = insurance?.dob || client?.dob;

  return (
    <div css={previewCardCss.container}>
      <HeadwayLogo css={previewCardCss.headwayLogo} />
      <div css={previewCardCss.clientDetails}>
        <dl css={previewCardCss.definitionList}>
          <dt>
            <CaptionText>
              <strong>Client</strong>
            </CaptionText>
          </dt>
          <dd>{!clientIsLoading && <CaptionText>{name}</CaptionText>}</dd>
          <dt>
            <CaptionText>
              <strong>Client DOB</strong>
            </CaptionText>
          </dt>
          <dd>
            {!clientIsLoading && (
              <CaptionText>
                {dob ? moment(dob).format('M/D/YYYY') : '—'}
              </CaptionText>
            )}
          </dd>
        </dl>
        <CaptionText>
          This information is automatically included in your plan and can be
          edited separately in the client tab.
        </CaptionText>
      </div>
      <TemplatePreview />
    </div>
  );
};

const previewCardCss = {
  container: css({
    boxShadow: theme.elevation.light,
    padding: theme.spacing.x6,
    backgroundColor: theme.color.system.white,
    border: `1px solid ${theme.color.system.borderGray}`,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.x5,
  }),
  headwayLogo: css({ width: 126 }),
  clientDetails: css({
    padding: theme.spacing.x4,
    backgroundColor: theme.color.system.backgroundGray,
  }),
  definitionList: css({
    display: 'grid',
    gridTemplateColumns: '136px auto',
    gap: theme.spacing.x3,
    marginTop: 0,
    marginBottom: theme.spacing.x5,
    overflowX: 'auto',
    dd: {
      margin: 0,
    },
  }),
};

const TemplatePreview = () => {
  const { steps, furthestStep } = useContext(TreatmentPlanContext);
  const { values } = useFormikContext<any>();
  return steps ? (
    <div>
      {steps.map((step, index) => (
        <div key={index} css={templatePreviewCss.step}>
          <div css={templatePreviewCss.stepTitle}>
            <div css={templatePreviewCss.stepTitle}>
              <StepIndicator>{index + 1}</StepIndicator>
              <BodyText>
                <strong>{step.title}</strong>
              </BodyText>
            </div>
            {!stepHasErrors(values, step) && stepHasAllValues(values, step) && (
              <IconCircleCheck color={theme.color.system.green} />
            )}
          </div>
          <StepPreview
            step={step}
            variant="small"
            alert={index < furthestStep}
          />
        </div>
      ))}
    </div>
  ) : null;
};

const templatePreviewCss = {
  step: css({
    borderTop: `solid 1px ${theme.color.system.borderGray}`,
    padding: `${theme.spacing.x5} 0 ${theme.spacing.x5} 0`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.x5,
  }),
  stepTitle: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing.x3,
  }),
};
