import { ProviderAppointmentAddendumRead } from '@headway/api/models/ProviderAppointmentAddendumRead';
import { ProviderAppointmentApi } from '@headway/api/resources/ProviderAppointmentApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderAppointmentAddendumsQueryKeyArgs {
  providerAppointmentId?: number;
}

export const getUseProviderAppointmentAddendumQueryKey = ({
  providerAppointmentId,
}: UseProviderAppointmentAddendumsQueryKeyArgs) => [
  'provider-appointment-addendums',
  providerAppointmentId,
];

const {
  useSingleQuery: useProviderAppointmentAddendums,
  useListQuery: useProviderAppointmentAddendumsList,
  useCachedQuery: useProviderAppointmentAddendumsCache,
} = createBasicApiHooks<
  UseProviderAppointmentAddendumsQueryKeyArgs,
  (string | number | undefined)[],
  ProviderAppointmentAddendumRead[]
>(
  getUseProviderAppointmentAddendumQueryKey,
  ({ providerAppointmentId }) => {
    if (!providerAppointmentId) {
      throw new Error('MIssing provider appointment id.');
    }

    return ProviderAppointmentApi.getProviderAppointmentAddendums(
      providerAppointmentId
    );
  },
  ({ providerAppointmentId }) => !!providerAppointmentId,
  () => 'Failed to fetch provider document remediations'
);

export {
  useProviderAppointmentAddendums,
  useProviderAppointmentAddendumsList,
  useProviderAppointmentAddendumsCache,
};
