import React from 'react';

import { SELF_PAY_FEES } from '@headway/shared/constants/selfPayFees';

import { LegalSection } from './LegalSection';

interface PrivatePayProviderAddendumProps {
  selfPayFlatRate?: string;
}

export const PrivatePayProviderAddendum: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<PrivatePayProviderAddendumProps>
  >
> = () => {
  const selfPayFlatRate = SELF_PAY_FEES.flat;

  return (
    <LegalSection title="Attachment B: Private Pay">
      <p>
        A. Headway’s platform has a feature that allows providers to see private
        pay patients who do not have insurance accepted by Headway. By accepting
        the following terms and conditions, you will amend your agreement with
        the Practice and be provided access to this feature:
      </p>
      <ul>
        1. PROVIDER is able to negotiate and set private pay rates for patients
        without insurance (the “Private Pay Rate”). PROVIDER understands that
        the Practice will pass along to PROVIDER certain of the costs associated
        with seeing private pay patients, including (i) a Stripe percentage fee
        that is variable to whether ACH (currently 0.8% of the Private Pay Rate)
        or credit card (currently 2.3% of the Private Pay Rate) payments are
        made, and (ii) a flat rate of ${selfPayFlatRate?.toFixed(2)}, which is
        dependent on geographic location.
        <ul>
          a. By way of example, if PROVIDER negotiates a Private Pay Rate of
          $100, Practice will charge the patient $100. If the patient pays $100
          by credit card and the flat rate for PROVIDER’s geographic location is
          set at $2.00, the Practice would pay the PROVIDER $95.70. ($100 -
          (($100 * 0.023) + $2.00) = $95.70).
        </ul>
        <p>
          2. The following requirements apply to your use of Private Pay Rates.
          Failure to observe any of these requirements will result in
          non-payment: (a) Private Pay Rates are only available for patients who
          do not have insurance that can be used to see the PROVIDER through the
          Practice; (b) Private pay patients must pay the Private-Pay Rate in
          order for the PROVIDER to receive compensation for seeing the private
          pay patient; (c) to charge a Private Pay Rate, PROVIDER must either be
          credentialed with the Practice or in process to be credentialed with
          the Practice; and (d) Private Pay Rates are only available to patients
          the PROVIDER has brought to the Practice; except that, a PROVIDER may
          charge a Private Pay Rate to a Practice-sourced patient if that
          patient is no longer covered by an insurance that the Practice
          accepts.
        </p>
        <p>
          3. In addition, PROVIDER and Practice agree that: (a) the Practice is
          not responsible for payment to the PROVIDER if a private pay patient
          does not complete payment; and (b) Private Pay patients will be able
          to download superbills from the Practice to submit to insurance
          companies that will contain PROVIDER’s EIN (if provided) and NPI (and
          not the Practice’s EIN or NPI).
        </p>
      </ul>
    </LegalSection>
  );
};
