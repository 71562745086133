import * as React from 'react';

export const Fire = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.363 7.204c-.915-2.38-4.176-2.508-3.389-5.967a.294.294 0 00-.437-.321c-2.118 1.248-3.64 3.75-2.363 7.029.105.268-.21.52-.437.344C3.68 7.49 3.57 6.341 3.663 5.52c.035-.304-.361-.45-.53-.199-.397.607-.8 1.587-.8 3.063.222 3.266 2.981 4.27 3.973 4.398 1.417.18 2.952-.082 4.054-1.091 1.213-1.126 1.657-2.922 1.003-4.486zM5.95 10.138c.84-.204 1.272-.81 1.388-1.347.193-.834-.56-1.651-.052-2.97.192 1.092 1.907 1.774 1.907 2.964.047 1.476-1.551 2.742-3.243 1.353z"
      fill="#13AA65"
    />
  </svg>
);
