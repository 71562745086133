import React from 'react';

import { ProviderQuestionnaireRecredSummary } from '@headway/api/models/ProviderQuestionnaireRecredSummary';
import { Banner } from '@headway/helix/Banner';
import { LinkButton } from '@headway/helix/LinkButton';
import { theme } from '@headway/helix/theme';

import { getDaysUntilTerminationCopy } from './recred_utils';

interface Props {
  recredSummary?: ProviderQuestionnaireRecredSummary;
  bannerOffset: number;
}

export const RecredPastDeadlineBanner = ({
  recredSummary,
  bannerOffset,
}: Props) => {
  return (
    <div
      css={{
        position: 'fixed',
        background: theme.color.hue.lightSystemRed,
        padding: `0 ${theme.spacing.x1}`,
        height: 50,
        top: 0 + bannerOffset,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 17,
        color: theme.color.system.black,
      }}
    >
      <Banner
        adornment={
          <LinkButton
            href="https://headway.co/for-providers#get-started"
            target="_blank"
            rel="noreferrer"
            variant="link"
          >
            Contact us to reopen your account
          </LinkButton>
        }
        variant="warning"
      >
        Your account will be terminated in{' '}
        {getDaysUntilTerminationCopy(recredSummary)} because you did not renew
        your insurance credentials.
      </Banner>
    </div>
  );
};
