import * as Yup from 'yup';

import { FileUpload } from '@headway/api/models/FileUpload';
import { Malpractice } from '@headway/api/models/Malpractice';
import { MalpracticeMinimums } from '@headway/api/models/MalpracticeMinimums';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { ProviderQuestionnaireVerificationRead } from '@headway/api/models/ProviderQuestionnaireVerificationRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderTaskStatus } from '@headway/api/models/ProviderTaskStatus';
import { ProviderTaskType } from '@headway/api/models/ProviderTaskType';
import { ProviderQuestionnaireApi } from '@headway/api/resources/ProviderQuestionnaireApi';
import { ProviderTaskApi } from '@headway/api/resources/ProviderTaskApi';
import { UploadedFile } from '@headway/shared/utils/upload';

import { CoiErrorType } from 'utils/questionnaireVerificationHelpers';

export const COI_EXPIRING_SOON_THRESHOLD = 14;

export enum CoiPathwayType {
  BAD_DOCUMENT = 'BAD_DOCUMENT',
  EXPIRING_SOON = 'EXPIRING_SOON',
  INELIGIBLE = 'INELIGIBLE',
  LOADING = 'LOADING',
  OCR_FAILED = 'OCR_FAILED',
  SUCCESS = 'SUCCESS',
  INITIAL = 'INITIAL',
  SUBMITTED = 'SUBMITTED',
}

export type MalpracticeValuesType = {
  nameInsured: string;
  malpracticeProvider: string;
  effectiveDate: string;
  expirationDate: string;
  occurrenceLimit: number;
  aggregateLimit: number;
};

export type ErrorObject = {
  errorType: CoiErrorType;
  errorMessage: string;
  incorrectValuesFound: string[];
};

export async function updateMalpracticeField(
  questionnaireId: number,
  uploadedFile: UploadedFile,
  malpracticeValues?: MalpracticeValuesType
) {
  const providerQuestionnaire =
    await ProviderQuestionnaireApi.getProviderQuestionnaire(questionnaireId);
  const providerQuestionnaireRawData =
    providerQuestionnaire.rawData as ProviderQuestionnaireRawData;

  const malpracticeFileObject: FileUpload[] = [
    {
      name: uploadedFile.name,
      link: uploadedFile.link,
      s3ObjectKey: uploadedFile.s3ObjectKey,
    },
  ];

  const malpracticeInsurance: Malpractice = {
    allowSkipCoiVerification: false,
    confirmSkipCoiVerification: false,
    liabilityInsuranceAggregateLimit:
      malpracticeValues?.aggregateLimit.toString(),
    liabilityInsuranceEffectiveDate: malpracticeValues?.effectiveDate,
    liabilityInsuranceExpirationDate: malpracticeValues?.expirationDate,
    liabilityInsuranceOccurrenceLimit:
      malpracticeValues?.occurrenceLimit.toString(),
    liabilityInsuranceCarrier: malpracticeValues?.malpracticeProvider,
    liabilityInsuranceNameInsured: malpracticeValues?.nameInsured,
    liabilityInsuranceUrl: malpracticeFileObject,
  };
  if (!!providerQuestionnaireRawData.malpracticeInsurances) {
    providerQuestionnaireRawData.malpracticeInsurances?.push(
      malpracticeInsurance
    );
  } else {
    providerQuestionnaireRawData.malpracticeInsurances = [malpracticeInsurance];
  }

  await ProviderQuestionnaireApi.updateProviderQuestionnaire(questionnaireId, {
    rawData: providerQuestionnaireRawData,
  });
}

export function formatNumber(value?: number) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? null;
}

export async function markCoiTaskWithStatus(
  providerId: number,
  coiStatus: ProviderTaskStatus
) {
  const markTaskWithStatus = async (taskId: number) => {
    ProviderTaskApi.updateProviderTask(taskId, {
      status: coiStatus,
    });
  };
  (await ProviderTaskApi.getProviderTasks({ provider_id: providerId })).map(
    (task) =>
      task.taskType === ProviderTaskType.CERTIFICATE_OF_INSURANCE &&
      markTaskWithStatus(task.id)
  );
}

export function updateExpiringSoonMalpractice(
  coiVerification: ProviderQuestionnaireVerificationRead,
  provider: ProviderRead,
  uploadedFile: UploadedFile
) {
  const textractResponse = coiVerification?.responseJson;
  const values = {
    nameInsured: '',
    malpracticeProvider: `Malpractice Expiring Soon - ${provider.name}`,
    effectiveDate: '',
    expirationDate: '',
    occurrenceLimit: 0,
    aggregateLimit: 0,
  } as MalpracticeValuesType;

  values.nameInsured = textractResponse?.nameInsuredFound ? provider.name : '';
  values.effectiveDate = textractResponse?.coverageStartDateFound;
  values.expirationDate = textractResponse?.coverageEndDateFound;
  values.occurrenceLimit = textractResponse?.perClaimCoverageLimitFound;
  values.aggregateLimit = textractResponse?.aggregateCoverageLimitFound;

  if (coiVerification.providerQuestionnaireId) {
    updateMalpracticeField(
      coiVerification.providerQuestionnaireId,
      uploadedFile,
      values
    );
  }
}

export const coiValidationSchema = (
  malpracticeMinimums: MalpracticeMinimums
) => {
  const expirationMin = new Date();
  const today = new Date();
  expirationMin.setDate(expirationMin.getDate() + COI_EXPIRING_SOON_THRESHOLD);

  return Yup.object().shape({
    nameInsured: Yup.string().required('Name Insured is required'),
    malpracticeProvider: Yup.string().required(
      'Malpractice insurance provider is required'
    ),
    effectiveDate: Yup.date()
      .required('Effective Date is required')
      .max(Date(), 'Effective Date cannot be in the future'),
    expirationDate: Yup.date()
      .required('Expiration Date is required')
      .test(
        'is-close-to-expiry',
        'We do not accept policies that expire within two weeks',
        (value) => {
          return value === null || value === undefined || value > expirationMin;
        }
      )
      .test('is-expired', 'Expiration date cannot be in the past', (value) => {
        return value === null || value === undefined || value > today;
      }),
    occurrenceLimit: Yup.number()
      .required('Occurrence Limit is required')
      .integer('Occurrence Limit must be a number')
      .min(
        malpracticeMinimums.occurrenceLimit,
        `Occurrence Limit must be at least ${formatNumber(
          malpracticeMinimums.occurrenceLimit
        )}`
      ),
    aggregateLimit: Yup.number()
      .required('Aggregate Limit is required')
      .integer('Aggregate Limit must be a number')
      .min(
        malpracticeMinimums.aggregateLimit,
        `Aggregate Limit must be at least ${formatNumber(
          malpracticeMinimums.aggregateLimit
        )}`
      ),
  });
};
