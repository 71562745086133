import { ProviderEventChannel } from '@headway/api/models/ProviderEventChannel';

export enum ExternalPlatformChannel {
  ZOCDOC = 'ZOCDOC',
  DOCASAP = 'DOCASAP',
  ZELIS_HCSC = 'ZELIS_HCSC',
}

export const EXTERNAL_PLATFORM_CHANNELS_BY_EVENT_CHANNEL: Record<
  ProviderEventChannel,
  ExternalPlatformChannel | undefined
> = {
  [ProviderEventChannel.ZOCDOC]: ExternalPlatformChannel.ZOCDOC,
  [ProviderEventChannel.DOCASAP]: ExternalPlatformChannel.DOCASAP,
  [ProviderEventChannel.ZELIS_HCSC]: ExternalPlatformChannel.ZELIS_HCSC,

  [ProviderEventChannel.PROVIDER_PORTAL]: undefined,
  [ProviderEventChannel.PATIENT_PORTAL]: undefined,
  [ProviderEventChannel.ADMIN_PORTAL]: undefined,
  [ProviderEventChannel.AUTOBOOK_HC_REFERRAL]: undefined,
  [ProviderEventChannel.HEALTHCARE_REFERRAL]: undefined,
  [ProviderEventChannel.EXTERNAL_CALENDAR]: undefined,
};

const ZENDESK_HELPCENTER_LINKS_BY_EXTERNAL_PLATFORM_CHANNEL: Record<
  ExternalPlatformChannel,
  string
> = {
  [ExternalPlatformChannel.ZOCDOC]:
    'https://help.headway.co/hc/en-us/articles/23985536184724-Reaching-new-clients-with-the-Headway-Zocdoc-referral-program',
  [ExternalPlatformChannel.DOCASAP]:
    'https://help.headway.co/hc/en-us/articles/23766504505108-Reaching-new-clients-with-the-United-Healthcare-referral-program',
  // TODO: [PAT-140] Add Zelis Specific Zendesk Doc
  [ExternalPlatformChannel.ZELIS_HCSC]:
    'https://help.headway.co/hc/en-us/categories/4418062709396-Providers',
};

const DISPLAY_NAMES_BY_EXTERNAL_PLATFORM_CHANNEL = {
  [ExternalPlatformChannel.ZOCDOC]: 'Zocdoc',
  [ExternalPlatformChannel.DOCASAP]: 'UHC',
  [ExternalPlatformChannel.ZELIS_HCSC]: 'HCSC',
};

export const getExternalPlatformChannelForEventChannel = (
  providerEventChannel: ProviderEventChannel
): ExternalPlatformChannel | undefined => {
  return EXTERNAL_PLATFORM_CHANNELS_BY_EVENT_CHANNEL[providerEventChannel];
};

export const getExternalPlatformDisplayName = (
  channel: ExternalPlatformChannel
): string => {
  return DISPLAY_NAMES_BY_EXTERNAL_PLATFORM_CHANNEL[channel];
};

export const getExternalPlatformZendeskHelpcenterLink = (
  channel: ExternalPlatformChannel
): string => {
  return ZENDESK_HELPCENTER_LINKS_BY_EXTERNAL_PLATFORM_CHANNEL[channel];
};
