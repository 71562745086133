import moment from 'moment';

import { ProviderQuestionnaireCaqhProfileRead } from '@headway/api/models/ProviderQuestionnaireCaqhProfileRead';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';

export const getLatestProviderQuestionnaireCaqhProfile = (
  providerQuestionnaire: ProviderQuestionnaireReadV2
) => {
  return providerQuestionnaire?.providerQuestionnaireCaqhProfiles?.sort(
    (
      a: ProviderQuestionnaireCaqhProfileRead,
      b: ProviderQuestionnaireCaqhProfileRead
    ) => moment(b?.createdOn).valueOf() - moment(a?.createdOn).valueOf()
  )?.[0];
};
