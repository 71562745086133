import { getFocusableTreeWalker } from '@react-aria/focus';
import { useSlotId } from '@react-aria/utils';
import { AriaLabelingProps } from '@react-types/shared';
import { AriaTextFieldProps } from '@react-types/textfield';
import Heading from '@tiptap/extension-heading';
import UnderlineExtension from '@tiptap/extension-underline';
import {
  Editor,
  EditorContent,
  Extension,
  Extensions,
  useEditor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import {
  FocusRing,
  FocusScope,
  useFocusManager,
  useId,
  useTextField,
} from 'react-aria';
import { flushSync } from 'react-dom';
import { Simplify } from 'type-fest';

import {
  Bold,
  IndentDecrease,
  IndentIncrease,
  Italic,
  ListBulleted,
  ListNumbered,
  Underline,
} from '@headway/icons/dist/helix/editor';
import { sanitize } from '@headway/shared/utils/htmlSanitize';

import { useAssertFormParentEffect } from './useAssertFormParentEffect';
import { FormInputProps, useFormInput } from './useFormInput';
import { PickAndConfigure } from './utils/PickAndConfigure';

type SupportedExtension =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'heading'
  | 'bulletList'
  | 'orderedList';

const defaultExtensions: SupportedExtension[] = [
  'bold',
  'italic',
  'underline',
  'bulletList',
  'orderedList',
];

type RichTextAreaProps = Simplify<
  {
    height?: number | 'fill';
    optionalityText?: React.ReactNode;
    instructionalText?: React.ReactNode;
    extensions?: SupportedExtension[];
  } & FormInputProps<string> &
    PickAndConfigure<
      AriaTextFieldProps,
      'autoComplete' | 'type' | 'placeholder'
    >
>;

function RichTextArea({
  extensions = defaultExtensions,
  ...props
}: RichTextAreaProps) {
  const { ariaProps, rootProps, hoverProps, isHovered } = useFormInput({
    ...props,
    inputElementType: 'textarea',
    isTextInput: true,
  });

  const ref = React.useRef<HTMLTextAreaElement>(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(ariaProps, ref);

  const editorId = useId(inputProps.id);

  const enabledExtensions: Extensions = [];

  enabledExtensions.push(
    StarterKit.configure({
      // Optionally enabled extensions
      bold: extensions.includes('bold') === false ? false : undefined,
      italic: extensions.includes('italic') === false ? false : undefined,
      bulletList:
        extensions.includes('bulletList') === false ? false : undefined,
      orderedList:
        extensions.includes('orderedList') === false ? false : undefined,
      heading: false,

      // Disable all other extensions
      blockquote: false,
      code: false,
      codeBlock: false,
      dropcursor: false,
      gapcursor: false,
      hardBreak: false,
      horizontalRule: false,
      strike: false,
    })
  );

  if (extensions.includes('underline')) {
    enabledExtensions.push(UnderlineExtension);
  }

  if (extensions.includes('heading')) {
    enabledExtensions.push(
      Heading.configure({
        levels: [2],
      })
    );
  }

  const editor = useRichTextEditor({
    id: editorId,
    onChange: props.onChange,
    disabled: props.disabled,
    value: props.value,
    onBlur: props.onBlur,
    name: props.name,
    'aria-labelledby': props['aria-labelledby'],
    'aria-label': props['aria-label'],
    'aria-describedby': props['aria-describedby'],
    'aria-details': props['aria-details'],
    extensions: enabledExtensions,
  });

  const optionalityId = useSlotId([Boolean(props.optionalityText)]);
  const instructionalTextId = useSlotId([Boolean(props.instructionalText)]);

  useAssertFormParentEffect(ref, 'RichTextArea');

  return (
    <div
      className="hlx-rich-text-area-root"
      data-hlx-height={props.height}
      style={
        {
          '--text-area-height':
            typeof props.height === 'number' ? `${props.height}lh` : undefined,
        } as React.CSSProperties
      }
      data-hlx-hovered={isHovered}
      {...rootProps}
    >
      <label className="hlx-rich-text-area-label" {...labelProps}>
        {props.label}
      </label>
      {props.instructionalText && (
        <div
          id={instructionalTextId}
          className="hlx-rich-text-area-instructional-text"
        >
          {props.instructionalText}
        </div>
      )}
      <FocusRing
        focusClass="focused"
        focusRingClass="focus-ring"
        isTextInput={true}
        within
        autoFocus={props.autoFocus}
      >
        <div className="hlx-rich-text-area-editor" {...hoverProps}>
          <EditorContent editor={editor} />
          <Toolbar
            editor={editor}
            aria-controls={editorId}
            disabled={props.disabled}
            extensions={extensions}
          />
        </div>
      </FocusRing>
      {props.optionalityText && (
        <div id={optionalityId} className="hlx-rich-text-area-optionality-text">
          {props.optionalityText}
        </div>
      )}
      {props.helpText && (
        <div className="hlx-rich-text-area-help-text" {...descriptionProps}>
          {props.helpText}
        </div>
      )}
      {props.validation?.validity === 'invalid' && (
        <div className="hlx-rich-text-area-error" {...errorMessageProps}>
          {props.validation.message}
        </div>
      )}
    </div>
  );
}

interface RichTextAreaToolbarProps {
  editor: Editor | null;
  'aria-controls': string;
  disabled?: boolean;
  extensions: SupportedExtension[];
}

const Toolbar = ({
  editor,
  'aria-controls': ariaControls,
  disabled,
  extensions,
}: RichTextAreaToolbarProps) => {
  if (!editor) {
    return null;
  }

  return (
    <div
      className="hlx-rich-text-area-toolbar"
      role="toolbar"
      aria-label="Text formatting"
      aria-controls={ariaControls}
    >
      <FocusScope autoFocus={false} restoreFocus={false}>
        {extensions.includes('bold') && (
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive('bold') ? 'is-active' : ''}
            disabled={disabled}
          >
            <Bold />
          </ToolbarButton>
        )}
        {extensions.includes('italic') && (
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive('italic') ? 'is-active' : ''}
            disabled={disabled}
          >
            <Italic />
          </ToolbarButton>
        )}
        {extensions.includes('underline') && (
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive('underline') ? 'is-active' : ''}
            disabled={disabled}
          >
            <Underline />
          </ToolbarButton>
        )}
        {extensions.includes('heading') && (
          <ToolbarButton
            onClick={() =>
              editor
                .chain()
                .focus()
                .toggleHeading({
                  level: 2,
                })
                .run()
            }
            className={editor.isActive('heading') ? 'is-active' : ''}
            disabled={disabled}
          >
            {/* TODO(nf): this should go through design and come from
            our icon workflow */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M5 4v3h5.5v12h3V7H19V4H5z"></path>
            </svg>
          </ToolbarButton>
        )}
        {extensions.includes('bulletList') && (
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive('bulletList') ? 'is-active' : ''}
            disabled={disabled}
          >
            <ListBulleted />
          </ToolbarButton>
        )}
        {extensions.includes('orderedList') && (
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive('orderedList') ? 'is-active' : ''}
            disabled={disabled}
          >
            <ListNumbered />
          </ToolbarButton>
        )}
        {(extensions.includes('bulletList') ||
          extensions.includes('orderedList')) && (
          <>
            <ToolbarButton
              onClick={() =>
                editor.chain().focus().sinkListItem('listItem').run()
              }
              disabled={disabled || !editor.can().sinkListItem('listItem')}
            >
              <IndentIncrease />
            </ToolbarButton>

            <ToolbarButton
              onClick={() =>
                editor.chain().focus().liftListItem('listItem').run()
              }
              disabled={disabled || !editor.can().liftListItem('listItem')}
            >
              <IndentDecrease />
            </ToolbarButton>
          </>
        )}
      </FocusScope>
    </div>
  );
};

interface ToolbarButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const ToolbarButton = (props: ToolbarButtonProps) => {
  const ref = React.useRef<HTMLButtonElement>(null);

  const focusManager = useFocusManager();
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (!focusManager) {
      throw new Error('FocusManager is not available');
    }
    const button = ref.current;

    switch (e.key) {
      case 'ArrowRight':
        focusManager.focusNext({ wrap: true });

        break;
      case 'ArrowLeft':
        focusManager.focusPrevious({ wrap: true });
        break;
      case 'Tab':
        const body = document.querySelector('body');
        if (!body) {
          return;
        }

        const toolbar = button?.parentElement;

        if (!toolbar) {
          return;
        }
        const walker = getFocusableTreeWalker(body, {
          from: toolbar,
          tabbable: true,
        });

        const target = e.shiftKey
          ? walker.previousSibling()
          : walker.nextSibling();

        e.preventDefault();

        try {
          // @ts-expect-error
          target.focus();
        } catch (e) {
          button?.blur();
        }

        break;
    }
  };
  return (
    <FocusRing
      focusClass="focused"
      focusRingClass="focus-ring"
      isTextInput={false}
    >
      <button type="button" ref={ref} {...props} onKeyDown={onKeyDown} />
    </FocusRing>
  );
};

type RichTextEditorProps = {
  id: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  extensions: Extensions;
  value?: string;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  name: string;
} & AriaLabelingProps;

export function useRichTextEditor(props: RichTextEditorProps) {
  const debouncedOnChange = React.useCallback(
    debounce(
      ({ editor }) => {
        const isEmpty = editor.isEmpty;

        const onChange = props.onChange;
        if (onChange) {
          flushSync(() => {
            const value = isEmpty ? '' : editor.getHTML();
            onChange(value);
          });
        }
      },
      120,
      {
        // call it at least every 2000ms
        maxWait: 2000,
        // always call the first invocation
        leading: false,
        // always call the last invocation
        trailing: true,
      }
    ),
    [props.onChange]
  );

  const editor = useEditor({
    editable: !props.disabled,
    editorProps: {
      attributes: {
        id: props.id,
        class: 'hlx-rich-text-area-control',
        name: props.name,
        role: 'textbox',
        'aria-labelledby': props['aria-labelledby'] ?? '',
        'aria-label': props['aria-label'] ?? '',
        'aria-describedby': props['aria-describedby'] ?? '',
        'aria-details': props['aria-details'] ?? '',
      },
    },
    extensions: props.extensions,
    content: props.value,
    onUpdate: debouncedOnChange,
    onBlur({ event }) {
      if (props.onBlur) {
        // Mismatch of FocusEvent types
        // TODO: Are they compat?
        // @ts-expect-error
        props.onBlur(event);
      }
    },
  });

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (props.value === editor.getHTML()) {
      return;
    }

    if (!props.disabled) {
      editor?.commands.setContent(props.value ?? '');
    }
  }, [props.value]);

  useEffect(() => {
    const isEditable = !props.disabled;

    if (isEditable !== editor?.isEditable) {
      editor?.setEditable(isEditable);
    }
  }, [props.disabled]);

  return editor;
}

interface RichTextHTMLContentProps {
  html: string;
}

function RichTextHTMLContent(props: RichTextHTMLContentProps) {
  return (
    <div
      className="hlx-rich-text-area-html-content"
      dangerouslySetInnerHTML={{ __html: sanitize(props.html) }}
    />
  );
}

export { RichTextArea, RichTextHTMLContent };
