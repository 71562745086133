import React from 'react';

import { Dropzone } from '@headway/ui/Dropzone';
import { theme } from '@headway/ui/theme';

const ZENDESK_LINK =
  'https://findheadway.zendesk.com/hc/articles/360038730132#h_01G3VR4S8EEFW06YE05FDRYAPJ';
const DATA_URL_SCHEME = 'data:text/plain;charset=utf-8,';
const CSV_CONTENTS =
  'Legal first name: This is the name your client uses for insurance purposes (Required), Legal last name: This is the name your client uses for insurance purposes (Required), Email (Required), Mobile Phone (Optional), Preferred first name - add this field if your client uses a different first name from their legal name (Optional), Preferred last name - add this field if your client uses a different last name from their legal name (Optional)\n\n';

export const ImportStep = ({
  userFiles = [],
  onFileDrop,
}: {
  userFiles: any[];
  onFileDrop: (data: any) => void;
}) => (
  <div>
    <>
      <p>
        Add multiple clients to Headway via spreadsheet (.xlsx), CSV files, or
        vCard files.{' '}
        <a
          href={DATA_URL_SCHEME + encodeURIComponent(CSV_CONTENTS)}
          download={'client_list.csv'}
        >
          Download template
        </a>
      </p>
      <p css={{ marginTop: theme.space.base }}>
        Be sure to include column headers for our required fields: Legal First
        Name, Legal Last Name, and Email. Mobile Phone, Preferred First Name,
        and Preferred Last Name are optional.
      </p>
    </>

    <p css={{ marginTop: theme.space.base }}>
      Want to import your client list from another platform?{' '}
      <a href={ZENDESK_LINK} target="_blank" rel="noopener noreferrer">
        Here's how
      </a>
      .
    </p>
    <Dropzone
      accept={[
        'text/vcard',
        'text/x-vcard',
        'text/directory',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ]}
      onDrop={onFileDrop}
      hasUploaded={userFiles.length > 0}
      uploadedFileName={userFiles.map((file) => file.name)}
      height={200}
      maxHeight={400}
      css={{ marginTop: theme.space.xl3 }}
    />
  </div>
);
