import { ProviderDocumentRemediationApi } from '@headway/api/resources/ProviderDocumentRemediationApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseProviderDocumentRemediationQueryKeyArgs {
  providerId?: number;
  providerAppointmentId?: number;
}

export const getUseProviderDocumentRemediationQueryKey = ({
  providerId,
  providerAppointmentId,
}: UseProviderDocumentRemediationQueryKeyArgs) => [
  'provider-document-remediation',
  providerId,
  providerAppointmentId,
];

const {
  useSingleQuery: useProviderDocumentRemediations,
  useListQuery: useProviderDocumentRemediationsList,
  useCachedQuery: useProviderDocumentRemediationsCache,
} = createBasicApiHooks(
  getUseProviderDocumentRemediationQueryKey,
  ({ providerId, providerAppointmentId }) =>
    ProviderDocumentRemediationApi.findProviderDocumentRemediations({
      provider_id: providerId,
      provider_appointment_id: providerAppointmentId,
    }),
  ({ providerId, providerAppointmentId }) =>
    !!(providerId || providerAppointmentId),
  () => 'Failed to fetch provider document remediations'
);

export {
  useProviderDocumentRemediations,
  useProviderDocumentRemediationsList,
  useProviderDocumentRemediationsCache,
};
