import { CloseTwoTone } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import { CalendarProps } from 'react-big-calendar';

import { ProviderCalendarRead } from '@headway/api/models/ProviderCalendarRead';
import { VisuallyHidden } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { DetailPopover, DetailPopoverProps } from './DetailPopover';

type FilterPopoverProps = DetailPopoverProps & {
  handleToggleCancelledAppointments: () => void;
  hideCancelledAppointments: boolean;
  calendars: ProviderCalendarRead[];
  selectedCalendarIds: number[];
  onFilterByCalendar: (exclusions: number[]) => void;
  view: CalendarProps['view'];
};

export const FilterPopover: React.FC<
  React.PropsWithChildren<FilterPopoverProps>
> = ({
  open,
  onClose,
  anchorEl,
  handleToggleCancelledAppointments,
  hideCancelledAppointments,
  calendars = [],
  selectedCalendarIds,
  onFilterByCalendar,
  view,
}) => {
  return (
    <DetailPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{
        style: {
          minWidth: 382,
          maxWidth: '25ch',
        },
      }}
    >
      <div
        css={{
          padding: theme.space.base,
          borderBottom: `1px solid ${theme.color.border}`,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: theme.fontFamily.postGrotesk,
          }}
        >
          <h4 css={{ margin: 0 }}>Filter</h4>
          <IconButton
            onClick={onClose as any}
            css={{
              margin: `-${theme.space.xs}`,
            }}
            data-testid="calendarFilterCloseButton"
            size="large"
          >
            <CloseTwoTone
              css={{
                width: 24,
                height: 24,
              }}
            />
            <VisuallyHidden>Close</VisuallyHidden>
          </IconButton>
        </div>
      </div>
      <div
        css={{
          marginTop: -1,
          [theme.media.medium]: { maxHeight: 420, overflowY: 'scroll' },
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: theme.space.xl,
          }}
        >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => handleToggleCancelledAppointments()}
                    checked={hideCancelledAppointments}
                    data-testid="filterPopoverHideCancelledAppointments"
                  />
                }
                label="Hide Cancelled Appointments"
              />
            </FormGroup>
          </FormControl>
          {view !== 'month' && calendars.length > 0 && (
            <FormControl
              name="filters"
              component="fieldset"
              css={{ marginTop: theme.space.base }}
            >
              <FormLabel css={{ margin: 0 }} component="legend">
                Calendars
              </FormLabel>
              <RadioGroup>
                {calendars.map((calendar) => {
                  return (
                    <FormControlLabel
                      key={calendar.id}
                      css={{
                        '& .MuiFormControlLabel-label': {
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          maxWidth: '30ch',
                        },
                      }}
                      control={
                        <Checkbox
                          name="calendars"
                          color="primary"
                          onClick={(e) => {
                            const nodes: NodeListOf<HTMLInputElement> =
                              document.querySelectorAll(
                                'input[name="calendars"]:not(:checked)'
                              );

                            const unchecked = Array.from(nodes, (node) =>
                              parseInt(node.value, 10)
                            );

                            onFilterByCalendar(unchecked);
                          }}
                          key={calendar.id}
                          value={calendar.id}
                          checked={selectedCalendarIds.includes(calendar.id)}
                        />
                      }
                      label={calendar.calendarName}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          )}
        </div>
      </div>
    </DetailPopover>
  );
};
