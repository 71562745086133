import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderUpdate } from '@headway/api/models/ProviderUpdate';
import { ProviderApi } from '@headway/api/resources/ProviderApi';

import { useAuthStore } from 'stores/AuthStore';

import {
  SideEffectsBuilder,
  useMutationWithSideEffects,
  UseMutationWithSideEffectsOptions,
} from './utils';

export interface UpdateProviderMutationArgs {
  providerId: number;
  update: ProviderUpdate;
}

export const useUpdateProviderMutation = (
  options: UseMutationWithSideEffectsOptions<
    ProviderRead,
    unknown,
    UpdateProviderMutationArgs
  > = {}
) => {
  const authStore = useAuthStore();
  return useMutationWithSideEffects(
    ({ providerId, update }: UpdateProviderMutationArgs) =>
      ProviderApi.updateProvider(providerId, update),
    {
      ...options,
      sideEffects: new SideEffectsBuilder<
        ProviderRead,
        unknown,
        UpdateProviderMutationArgs
      >()
        .add({
          onSuccess: (result) => {
            if (authStore.provider.id === result.id) {
              authStore.setProvider(result);
            }
          },
        })
        .addErrorLogging(() => 'There was a problem saving provider data')
        .merge(options.sideEffects),
    }
  );
};
