/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An auxiliary class used to specify what kind of authorizations are desired from the below query */    /**
    * An auxiliary class used to specify what kind of authorizations are desired from the below query
    */
export enum AuthorizationType {
    EAP = 'EAP',
    NOT_EAP = 'NOT_EAP'
}
