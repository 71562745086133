import moment from 'moment';

import { PayerQuestionnaire } from '@headway/api/models/PayerQuestionnaire';
import { ProviderTaskRead } from '@headway/api/models/ProviderTaskRead';
import { ProviderTaskStatus } from '@headway/api/models/ProviderTaskStatus';

export type TaskStatusMap = {
  [key in ProviderTaskStatus]?: ProviderTaskRead[];
};
export type ProgressMap = { [key: number]: number };

export function getTaskStatusMap(tasks: ProviderTaskRead[]) {
  return tasks.reduce(
    (acc, task) => ({
      ...acc,
      [task.status]: [...(acc[task.status] || []), task],
    }),
    {} as TaskStatusMap
  );
}

export function getProgressMap(tasks: ProviderTaskRead[]) {
  const completedTaskMap: ProgressMap = {};
  const totalTaskMap: ProgressMap = {};

  tasks.forEach((task) => {
    if (!task.frontEndCarrierIds) {
      return;
    }

    if (task.status === ProviderTaskStatus.COMPLETE) {
      task.frontEndCarrierIds.forEach((id) => {
        const current = completedTaskMap[id];
        completedTaskMap[id] = current ? current + 1 : 1;
      });
    }

    task.frontEndCarrierIds.forEach((id) => {
      const current = totalTaskMap[id];
      totalTaskMap[id] = current ? current + 1 : 1;
    });
  });

  return Object.keys(completedTaskMap).reduce(
    (acc, id) => ({
      ...acc,
      [id]: completedTaskMap[Number(id)] / (totalTaskMap[Number(id)] + 1), // add 1 since even if every task is done we still need to update their credentialed_on date
    }),
    {} as ProgressMap
  );
}

export function parseDate(maybeDate?: string | null) {
  if (!maybeDate) {
    return;
  }

  return new Date(maybeDate);
}

export const isDateInPast = (date: Date | undefined) => {
  return date && date < new Date();
};

export function formatDate(toFormat: Date) {
  return moment(toFormat).utc().format('MM/DD/YY');
}

export const hasIncompletePayerQuestionnaire = (
  payerQuestionnaire: PayerQuestionnaire | undefined
) => payerQuestionnaire && !payerQuestionnaire.response.completedOn;
