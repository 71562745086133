import { ModalProps } from '@mui/material';
import React from 'react';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderAppointmentStatus } from '@headway/api/models/ProviderAppointmentStatus';
import { ProviderAppointmentStatusNotes } from '@headway/api/models/ProviderAppointmentStatusNotes';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventRecurrenceUpdateResponse } from '@headway/api/models/ProviderEventRecurrenceUpdateResponse';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import { trackEvent } from '@headway/shared/utils/analytics';
import { Modal } from '@headway/ui';

import { Message } from 'api/MessagesApi';
import { useProviderEventCache } from 'hooks/useProviderEvent';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import {
  UpdateProviderEventMutationArgs,
  UpdateRecurringInstanceMutationArgs,
  useUpdateProviderEventMutation,
  useUpdateRecurringInstanceAndEndRecurrenceMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';

import { isPatientBooked } from '../events/util/events';
import {
  AppointmentCancelForm,
  AppointmentCancelFormValues,
} from '../form/AppointmentCancelForm';
import {
  useUpdateProviderEventSideEffectsForCalendar,
  useUpdateRecurringInstanceAndEndRecurrenceMutationSideEffectsForCalendar,
} from '../utils/queries';

// Extend MaterialUI's ModalProps, we don't need the children though
type AppointmentCancelModalProps = Omit<ModalProps, 'children'> & {
  open: boolean;
  onClose: () => void;
  notifyForEvent: (
    event: ProviderEventRead | undefined,
    content: string,
    type: keyof typeof Message.Type,
    additionalFields: { is_recurring_appointment_cancellation: boolean }
  ) => Promise<void>;
  hideCancelledAppointments: any;
  handleDetailClose: any;
};

export const AppointmentCancelModal = ({
  open,
  onClose,
  notifyForEvent,
  hideCancelledAppointments,
  handleDetailClose,
}: AppointmentCancelModalProps) => {
  const providerEventCache = useProviderEventCache();
  const updateProviderEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (updated) => {
          providerEventCache.set(
            { eventIdOrVirtualId: updated.virtualId },
            updated
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar()),
  });
  const updateRecurringInstanceAndEndRecurrenceMutation =
    useUpdateRecurringInstanceAndEndRecurrenceMutation({
      sideEffects: new SideEffectsBuilder<
        ProviderEventRecurrenceUpdateResponse,
        unknown,
        UpdateRecurringInstanceMutationArgs
      >()
        .add({
          onSuccess: ({ updatedInstance }) => {
            providerEventCache.set(
              { eventIdOrVirtualId: updatedInstance.virtualId },
              updatedInstance
            );
          },
        })
        .merge(
          useUpdateRecurringInstanceAndEndRecurrenceMutationSideEffectsForCalendar()
        ),
    });

  const { event } = useSelectedEvent();
  if (!event) return null;

  const handleCancellationSubmit = async ({
    recurringUpdateApplyTo,
    content,
    sendMessage,
    ...updateValues
  }: AppointmentCancelFormValues) => {
    // Cancellation reason description is only passed if provider selected "Other"
    if (updateValues.statusNotes !== ProviderAppointmentStatusNotes.OTHER) {
      delete updateValues.cancellationReasonDescription;
    }

    const cancelledValues = {
      providerAppointment: {
        statusNotes: updateValues.statusNotes as ProviderAppointmentStatusNotes,
        patientResponsibilityAmount: updateValues.patientResponsibilityAmount,
        cancellationReasonDescription:
          updateValues.cancellationReasonDescription,
        status: ProviderAppointmentStatus.CANCELED,
        providerRebookingPreference: updateValues.providerRebookingPreference,
      },
    };

    let updatedEvent: ProviderEventRead;
    if (recurringUpdateApplyTo === RecurringUpdateApplyTo.FOLLOWING_EVENTS) {
      const result =
        await updateRecurringInstanceAndEndRecurrenceMutation.mutateAsync({
          virtualId: event.virtualId,
          update: cancelledValues,
        });
      updatedEvent = result.updatedInstance;
    } else {
      if (hideCancelledAppointments) {
        handleDetailClose();
      }
      updatedEvent = await updateProviderEventMutation.mutateAsync({
        eventIdOrVirtualId: event.virtualId,
        update: cancelledValues,
      });
    }

    //  track all cancelled appointments
    trackEvent({
      name: 'Cancel Session Button Clicked',
      properties: {
        providerId: event.providerId,
        patientUserId: event.patientUserId,
        providerEventId: event.id,
        providerAppointmentId:
          event.recurrence === undefined
            ? event.providerAppointment?.id
            : undefined,
        patientBookedAppointment: isPatientBooked(event),
      },
    });

    onClose();

    // don't ask to notify the patient if the status is already Canceled
    if (
      event.providerAppointment?.status !==
        ProviderAppointmentStatus.CANCELED &&
      (sendMessage || isPatientBooked(event))
    ) {
      notifyForEvent(
        updatedEvent,
        content,
        // the type of the `type` property is a string literal type, so we need to cast it to a string
        // that is a valid member
        Message.Type.PROVIDER_CANCELLATION as 'PROVIDER_CANCELLATION',
        {
          is_recurring_appointment_cancellation: !!(
            event.recurrence &&
            recurringUpdateApplyTo === RecurringUpdateApplyTo.FOLLOWING_EVENTS
          ),
        }
      );
    }
  };

  return (
    <Modal open={open} onClose={onClose} title="Cancel session">
      <AppointmentCancelForm
        onSubmit={handleCancellationSubmit}
        onClose={onClose}
      />
    </Modal>
  );
};
