import * as React from 'react';

export const CalendarOutlined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.166 11.666V10h1.668v1.666H9.166zm-3.333 0V10H7.5v1.666H5.833zm6.667 0V10h1.666v1.666H12.5zM9.166 15v-1.666h1.668V15H9.166zm-3.333 0v-1.666H7.5V15H5.833zm6.667 0v-1.666h1.666V15H12.5zm-10 3.334V3.063h2.563V1.665H6.52v1.397h6.958V1.665h1.459v1.397H17.5v15.27h-15zm1.396-1.396h12.208v-8.75H3.896v8.75zm0-10.125h12.208V4.438H3.896v2.375zm0 0V4.438v2.375z"
      fill="currentColor"
    />
  </svg>
);
