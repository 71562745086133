import { css } from '@emotion/react';
import { useProvider } from 'hooks';
import React, { useContext } from 'react';

import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';
import { MM_TREATMENT_PLAN } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';

import { useProviderTreatmentPlans } from 'hooks/useProviderTreatmentPlans';
import { useAuthStore } from 'stores/AuthStore';
import { isGroupAdminImpersonatingProvider } from 'utils/access';

import { TreatmentPlanModal } from './Modal';
import { PreviewPage } from './Pages/PreviewPage';
import { StartPage } from './Pages/StartPage';
import { SummaryPage } from './Pages/SummaryPage';
import { TemplatePage } from './Pages/TemplatePage';
import { TextInputPage } from './Pages/TextInputPage';
import { UploadPage } from './Pages/UploadPage';
import {
  TreatmentPlanContext,
  TreatmentPlanContextProvider,
} from './TreatmentPlanContext';
import { TreatmentPlanForm } from './TreatmentPlanForm';
import { TreatmentPlanTable } from './TreatmentPlanTable';
import { getActiveTreatmentPlan } from './TreatmentPlanUtils';

export interface TreatmentPlanProps {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  providerPatient: ProviderPatientRead;
  onCloseTreatmentPlanModal?: () => void;
}
export const TreatmentPlanImpl = ({
  isEditing,
  setIsEditing,
  providerPatient,
  onCloseTreatmentPlanModal = () => {},
}: TreatmentPlanProps) => {
  const isTreatmentPlanRequirementEnabled = useFlag(MM_TREATMENT_PLAN, false);
  const { page, resetTreatmentPlanAndPage } = useContext(TreatmentPlanContext);

  const { data: treatmentPlans } = useProviderTreatmentPlans({
    providerPatientId: providerPatient!.id,
  });

  const provider = useProvider();
  const { user } = useAuthStore();

  if (!isEditing) {
    const activeTreatmentPlan = getActiveTreatmentPlan(
      treatmentPlans,
      isTreatmentPlanRequirementEnabled
    );

    return treatmentPlans && treatmentPlans?.length > 0 ? (
      <>
        {isGroupAdminImpersonatingProvider(provider, user) && (
          <BodyText>
            As a group admin, you can only upload treatment plans.
          </BodyText>
        )}
        {!activeTreatmentPlan && (
          <GuidanceCard variant="warning">
            <BodyText>
              Reminder: Update or add a new treatment plan. Insurers typically
              look for updates every 3-6 months.
            </BodyText>
          </GuidanceCard>
        )}
        <div css={tableCss}>
          <TreatmentPlanTable setIsEditing={setIsEditing} />
        </div>
      </>
    ) : (
      <div css={emptyStateCss}>
        <GuidanceCard
          variant="compliance"
          title="We're here to help you write a treatment plan"
        >
          <BodyText>
            Our templates offer structure and support, making it easier to write
            treatment plans that meet insurers' standards.
          </BodyText>
          <Link
            href="https://findheadway.zendesk.com/hc/en-us/articles/14936779297172"
            target="_blank"
            rel="noreferrer"
          >
            <strong>Learn more about treatment plans</strong>
          </Link>
        </GuidanceCard>
        <BodyText>You haven't added a treatment plan yet.</BodyText>
      </div>
    );
  }

  const closeModalAndResetPage = () => {
    setIsEditing(false);
    resetTreatmentPlanAndPage();
    onCloseTreatmentPlanModal();
  };

  const pages = {
    START: <StartPage />,
    TEMPLATE: <TemplatePage closeModal={closeModalAndResetPage} />,
    SUMMARY: <SummaryPage closeModal={closeModalAndResetPage} />,
    TEXT: <TextInputPage closeModal={closeModalAndResetPage} />,
    UPLOAD: <UploadPage closeModal={closeModalAndResetPage} />,
    PREVIEW: <PreviewPage closeModal={closeModalAndResetPage} />,
  };

  return (
    <TreatmentPlanModal open={isEditing} onClose={closeModalAndResetPage}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: 0,
          height: '100%',
        }}
      >
        {pages[page]}
      </div>
    </TreatmentPlanModal>
  );
};

export const TreatmentPlanWithContext = (props: TreatmentPlanProps) => (
  <TreatmentPlanContextProvider providerPatient={props.providerPatient}>
    <TreatmentPlanForm>
      <TreatmentPlanImpl {...props} />
    </TreatmentPlanForm>
  </TreatmentPlanContextProvider>
);

export const TreatmentPlan = (props: TreatmentPlanProps) => (
  <TreatmentPlanForm>
    <TreatmentPlanImpl {...props} />
  </TreatmentPlanForm>
);

const emptyStateCss = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.x5,
});

const tableCss = css({
  margin: `0 -${theme.spacing.x4}`,
  display: 'grid',
});
