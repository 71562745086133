import React from 'react';
import { DismissButton, FocusScope, useOverlay } from 'react-aria';

export const POPOVER_MAX_HEIGHT = 400;

interface PopoverProps {
  popoverRef?: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  __style?: React.CSSProperties;
}
export function Popover(props: PopoverProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  let { popoverRef = ref, isOpen, onClose, children, __style } = props;

  let { overlayProps } = useOverlay(
    {
      isOpen,
      onClose,
      shouldCloseOnBlur: true,
      isDismissable: true,
    },
    popoverRef
  );

  return (
    <FocusScope restoreFocus contain={isOpen}>
      <div
        {...overlayProps}
        style={__style}
        className="hlx-popover"
        ref={popoverRef}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </div>
    </FocusScope>
  );
}
