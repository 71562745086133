import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react';

import { ProviderModule } from '@headway/api/models/ProviderModule';
import { Button } from '@headway/ui';
import { theme } from '@headway/ui/theme';

import { useOnboardingModuleStatuses } from 'hooks/useOnboardingModuleStatuses';
import { captureAnalyticsEvent } from 'views/Home/OnboardingCard';

export const Completed = () => {
  const statusesQuery = useOnboardingModuleStatuses();

  const availabilityModuleStatus = (statusesQuery.data || []).find(
    (status) => status.module === ProviderModule.AVAILABILITY
  );
  const isInProgress =
    !availabilityModuleStatus?.isComplete &&
    availabilityModuleStatus?.wizard?.currentStep != null &&
    availabilityModuleStatus?.wizard?.completedOn == null;

  return (
    <div
      css={{
        textAlign: 'center',
        padding: theme.space.xl5,
        borderTop: `4px solid ${theme.color.primaryLight}`,
      }}
    >
      <CheckCircleOutlineIcon
        css={{
          width: 50,
          height: 50,
          color: theme.color.success,
          marginBottom: theme.space.base,
        }}
      />
      <h4>Your profile is complete!</h4>
      <p>You can make changes anytime.</p>
      <p css={{ marginBottom: theme.space.xl3 }}>
        Next, we'll help you set up your calendar and availability.
      </p>
      <div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          href="/onboarding/availability"
          style={{ marginBottom: theme.space.xs }}
          onClick={() => {
            if (availabilityModuleStatus) {
              captureAnalyticsEvent(
                availabilityModuleStatus.module,
                isInProgress,
                availabilityModuleStatus.isComplete,
                'Availability'
              );
            }
          }}
        >
          Next
        </Button>
        <Button color="primary" size="large" fullWidth href="/home">
          Save And Exit
        </Button>
      </div>
    </div>
  );
};
