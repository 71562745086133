import { FormControl } from '@headway/helix/FormControl';
import { RichTextArea } from '@headway/helix/RichTextArea';

import { SchemaComponent } from '.';
import { getInstructionalText } from '../../utils';

export const FormRichTextArea = ({
  element,
  isOptional = false,
  disabled = false,
  requiresEdit = false,
}: SchemaComponent) => {
  const { id, title, subTitle, placeholder } = element;

  return (
    <FormControl
      component={RichTextArea}
      label={title}
      name={id}
      key={id}
      optionalityText={isOptional ? 'Optional' : ''}
      instructionalText={getInstructionalText(subTitle, { requiresEdit })}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
