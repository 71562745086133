/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { HTTPValidationError } from '../models/HTTPValidationError';
import { ProviderType } from '../models/ProviderType';
import { RegionalProviderFeeScheduleCreateBulk } from '../models/RegionalProviderFeeScheduleCreateBulk';
import { RegionalProviderFeeScheduleRead } from '../models/RegionalProviderFeeScheduleRead';
import { UnitedStates } from '../models/UnitedStates';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class RegionalProviderFeeScheduleApiResource {

    /**
     * Get Regional Provider Fee Schedules
     * 
     * @param state 
     * @param providerStateOfResidence 
     * @param frontEndCarrierId 
     * @param zipCode 
     * @param isTelehealth 
     * @param providerType 
     * @param maxEffectiveDate 
     * @param maxGroupContractEffectiveDate 
     * @param onlyMostRecent 
     * @param expectsSingleProviderRegion 
     * @param providerRegionId 
     * @param isLiveProvider 
     
     */
    public getRegionalProviderFeeSchedules(query?: { state?: UnitedStates, provider_state_of_residence?: UnitedStates, front_end_carrier_id?: number, zip_code?: string, is_telehealth?: boolean, provider_type?: ProviderType, max_effective_date?: string, max_group_contract_effective_date?: string, only_most_recent?: boolean, expects_single_provider_region?: boolean, provider_region_id?: number, is_live_provider?: boolean }, axiosConfig?: AxiosRequestConfig): Promise<Array<RegionalProviderFeeScheduleRead>> {
        const apiRoute = '/regional-provider-fee-schedules';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Post Regional Provider Fee Schedules
     * 
     * @param regionalProviderFeeScheduleCreateBulk 
     
     */
    public postRegionalProviderFeeSchedules(body: RegionalProviderFeeScheduleCreateBulk, axiosConfig?: AxiosRequestConfig): Promise<Array<RegionalProviderFeeScheduleRead>> {
        const apiRoute = '/regional-provider-fee-schedules';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const RegionalProviderFeeScheduleApi = new RegionalProviderFeeScheduleApiResource();
