import { useProvider } from 'hooks';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { Header } from 'components/Header/Header';
import { ProviderSelect } from 'components/Header/ProviderSelect';
import { useProviderIncentiveProgramEnrollment } from 'hooks/useGetIsEnrolledProviderIncentiveProgram';

import { RateBoost } from './RateBoost';

export const Incentives = () => {
  const provider = useProvider();
  const {
    data: providerEnrollmentSummary,
    isLoading: isEnrollmentSummaryLoading,
  } = useProviderIncentiveProgramEnrollment({
    providerId: provider.id,
  });

  if (
    (!providerEnrollmentSummary?.isProviderEnrolled &&
      !isEnrollmentSummaryLoading) ||
    !provider
  ) {
    return <Navigate replace to="/home" />;
  }

  return (
    <>
      <Header startComponent={<ProviderSelect />} />
      <RateBoost />
    </>
  );
};
