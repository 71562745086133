/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ASRSAgeCategory {
    UNDER_18 = 0,
    _18_TO_29 = 1,
    _30_TO_39 = 2,
    _40_TO_49 = 3,
    _50_TO_64 = 4,
    _65_PLUS = 5
}
