import * as React from 'react';

export const CredentialForm = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 17.5v-15h15v10l-5 5h-10zm3.604-6.104h3.75V10h-3.75v1.396zm0-3.334h7.792V6.668H6.104v1.396zm5.833 8.042l4.167-4.166h-4.166v4.166z"
      fill="currentColor"
    />
  </svg>
);
