import { InputAdornment, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useToggle } from 'react-use';
import * as Yup from 'yup';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Modal } from '@headway/ui';
import {
  FieldControl,
  FieldErrorText,
  FieldInputLabel,
  FieldNumberFormatInput,
  FieldSelect,
} from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

interface CancellationPolicyProps {
  onUpdateSuccess: (provider: ProviderRead) => void;
  provider: ProviderRead;
}

export const CancellationPolicy: React.FC<
  React.PropsWithChildren<CancellationPolicyProps>
> = ({ provider, onUpdateSuccess }) => {
  const [isModalOpen, toggleModalOpen] = useToggle(false);

  return (
    <PageSection layout="grid.two-column">
      <div>
        <h2>
          <SectionHeader>Cancellation policy</SectionHeader>
        </h2>

        <PageSectionSubText>
          <BodyText>
            <strong>${provider.cancellationDefaultFee}</strong> per session
            cancelled within{' '}
            <strong>{provider.cancellationCutoffHours} hours</strong>
          </BodyText>
        </PageSectionSubText>
      </div>
      <div css={{ justifySelf: 'end' }}>
        <Button
          variant="secondary"
          onPress={() => {
            toggleModalOpen();
          }}
        >
          Update cancellation policy
        </Button>
      </div>
      <Modal
        title="Update cancellation policy"
        open={isModalOpen}
        onClose={toggleModalOpen}
      >
        <Formik
          initialValues={{
            cancellationDefaultFee: provider.cancellationDefaultFee || 75,
            cancellationCutoffHours: provider.cancellationCutoffHours || 24,
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            cancellationDefaultFee: Yup.number()
              .min(0, 'Cancellation fee cannot be less than $0.')
              .max(200, 'Cancellation fee cannot be greater than $200.')
              .required('Cancellation fee is required.'),
            cancellationCutoffHours: Yup.string().required(
              'Cancellation cutoff hours is required.'
            ),
          })}
          onSubmit={async ({
            cancellationDefaultFee,
            cancellationCutoffHours,
          }) => {
            try {
              const updatedProvider = await ProviderApi.updateProvider(
                provider.id,
                {
                  cancellationDefaultFee,
                  cancellationCutoffHours,
                }
              );
              onUpdateSuccess(updatedProvider);
              toggleModalOpen();
              notifySuccess('Cancellation policy updated.');
            } catch (err: AnyTS4TryCatchUnknownError) {
              notifyError(err.toString());
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <SafeFormikForm>
                <FieldControl name="cancellationDefaultFee">
                  <FieldInputLabel>Cancellation fee</FieldInputLabel>
                  <FieldNumberFormatInput
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                  />
                  <FieldErrorText />
                  <FieldErrorText />
                </FieldControl>
                <FieldControl name="cancellationCutoffHours" fullWidth={true}>
                  <FieldInputLabel>Cutoff hours</FieldInputLabel>
                  <FieldSelect>
                    <MenuItem value={24}>24 hours</MenuItem>
                    <MenuItem value={48}>48 hours</MenuItem>
                  </FieldSelect>
                  <FieldErrorText />
                </FieldControl>
                <div
                  css={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Update
                  </Button>
                </div>
              </SafeFormikForm>
            );
          }}
        </Formik>
      </Modal>
    </PageSection>
  );
};
