import * as React from 'react';

export const Payments = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.396 11.104a2.41 2.41 0 001.77-.729 2.41 2.41 0 00.73-1.771 2.41 2.41 0 00-.73-1.77 2.41 2.41 0 00-1.77-.73 2.41 2.41 0 00-1.771.73 2.41 2.41 0 00-.729 1.77c0 .695.243 1.285.729 1.771a2.411 2.411 0 001.771.729zm-7.792 2.792V3.333h15.563v10.563H3.603zm-2.77 2.77V5.834h1.395v9.437h14.438v1.395H.832zM5 6.666c.542 0 1-.187 1.375-.562a1.87 1.87 0 00.563-1.375H5v1.938zm12.771 0V4.73h-1.938c0 .542.188 1 .563 1.375a1.87 1.87 0 001.375.563zM15.833 12.5h1.938v-1.938c-.542 0-1 .188-1.375.563a1.87 1.87 0 00-.563 1.375zM5 12.5h1.938c0-.542-.188-1-.563-1.375A1.87 1.87 0 005 10.562V12.5z"
      fill="currentColor"
    />
  </svg>
);
