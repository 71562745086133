import React from 'react';

import { UserRead } from '@headway/api/models/UserRead';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

interface ResendEmailReminderModalProps {
  open: boolean;
  onClose: () => void;
  client: UserRead;
  isSendIntakeFormEmailToPatient: boolean;
  handleSendIntakeFormEmailToPatient: () => void;
}

export const ResendEmailReminderModal = ({
  open,
  onClose,
  client,
  isSendIntakeFormEmailToPatient,
  handleSendIntakeFormEmailToPatient,
}: ResendEmailReminderModalProps) => {
  return (
    <Modal title="Resend reminder?" isOpen={open} onDismiss={onClose}>
      <ModalContent>
        You sent a reminder to this client to complete their forms less than 24
        hours ago. Are you sure you want to resend a reminder again?
      </ModalContent>
      <ModalFooter>
        <Button
          variant="secondary"
          disabled={isSendIntakeFormEmailToPatient}
          onPress={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={isSendIntakeFormEmailToPatient}
          onPress={async () => {
            handleSendIntakeFormEmailToPatient();
            onClose();
          }}
        >
          Yes resend reminder
        </Button>
      </ModalFooter>
    </Modal>
  );
};
