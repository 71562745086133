import { css } from '@emotion/react';
import { ErrorOutline } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip } from '@mui/material';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import React from 'react';

import { CalendarEventType } from '@headway/api/models/CalendarEventType';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserRead } from '@headway/api/models/UserRead';
import { Badge } from '@headway/helix/Badge';
import { Button as HelixButton } from '@headway/helix/Button';
import { IconError } from '@headway/helix/icons/Error';
import { theme as helixTheme } from '@headway/helix/theme';
import { SelfPay } from '@headway/icons/dist/SelfPay';
import {
  COMMON_DIAGNOSIS_CODES,
  LESS_COMMON_DIAGNOSIS_CODES,
} from '@headway/shared/constants/diagnosisCodes';
import { SelectedEventContextProvider } from '@headway/shared/events/SelectedEventContext';
import { formatPatientName } from '@headway/shared/utils/patient';
import { Button, PropertyList, PropertyListItem } from '@headway/ui';
import {
  Accordion,
  AccordionDetails,
  AccordionDetailsHeader,
  AccordionSummary,
} from '@headway/ui/Accordion';
import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';
import { theme } from '@headway/ui/theme';

import { verifyInsuranceBenefitsProviderRedirectUrl } from 'constants/contactForm';
import { useProviderProgressNotesCache } from 'hooks/useProviderProgressNotes';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import { useSessionDetailsEditability } from 'hooks/useSessionDetailsEditability';
import { useAuthStore } from 'stores/AuthStore';
import {
  isAdminImpersonatingProviderUser,
  isMedicalRecordAuditorImpersonatingProvider,
} from 'utils/access';
import { AppointmentConfirmation } from 'views/Calendar/components/AppointmentConfirmation/AppointmentConfirmation';

import { PaymentFailedAlert } from '../Calendar/components/PaymentFailedAlert';
import {
  isSelfPayAppt,
  paymentAttemptFailed,
} from '../Calendar/events/util/events';
import { PastSessionsProgressNote } from './PastSessionsProgressNote';

const ALL_DIAGNOSIS_CODES = COMMON_DIAGNOSIS_CODES.concat(
  LESS_COMMON_DIAGNOSIS_CODES
);

const DEFAULT_NUM_EVENTS_IN_VIEW = 5;

type PastSessionsListWithEventsHelpersProps = Pick<
  PastSessionsListProps,
  'variant' | 'pastSessions' | 'onCloseConfirmSessionModal'
> & {
  patientUser: UserRead;
  paymentMethod?: UserPaymentMethodRead;
};

const getSessionDescription = (event: ProviderEventRead) => {
  const sessionMinutes = moment(event.endDate).diff(
    moment(event.startDate),
    'minutes'
  );
  const locationDescription = event.telehealth ? 'virtual' : 'in person';
  const diagnosisDescription = event.providerAppointment?.diagnosisCodes
    ?.map(
      (d: string) =>
        `${ALL_DIAGNOSIS_CODES.find(
          (code) => code.value === d
        )?.display?.toLowerCase()} (${d})`
    )
    .join(', ');

  return `${sessionMinutes} minute ${locationDescription} session for ${diagnosisDescription}.`;
};

const PastSessionsListWithEventsHelpers: React.FC<
  React.PropsWithChildren<PastSessionsListWithEventsHelpersProps>
> = ({
  onCloseConfirmSessionModal,
  patientUser,
  pastSessions,
  paymentMethod,
  variant = 'mui',
}) => {
  const { user, impersonatingUser, impersonatingUserRoles } = useAuthStore();
  const isSpoofing = isAdminImpersonatingProviderUser(user, impersonatingUser);
  const isSpoofingAsMedicalRecordAuditor = isSpoofing
    ? isMedicalRecordAuditorImpersonatingProvider(impersonatingUserRoles)
    : false;

  const [isSessionDetailsEditable, setIsSessionDetailsEditable] =
    React.useState(false);
  const [isConfirmDetailsOpen, setIsConfirmDetailsOpen] = React.useState(false);

  const [showAll, setShowAll] = React.useState(false);
  const addressContext = React.useContext(ProviderAddressContext);

  const providerProgressNotesCache = useProviderProgressNotesCache();
  const { event: selectedEvent } = useSelectedEvent();
  const numEvents = (pastSessions || []).length;

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_ID) {
    throw new Error('REACT_APP_GOOGLE_MAPS_API_ID expected');
  }

  const handleCloseConfirmSessionModal = () => {
    onCloseConfirmSessionModal();
    setIsConfirmDetailsOpen(false);
  };

  const onOpenContactFormInsuranceIssues = () => {
    setIsConfirmDetailsOpen(false);
    window.open(
      verifyInsuranceBenefitsProviderRedirectUrl +
        `&clientId=${patientUser.id}`,
      '_blank'
    );
  };

  const { data: sessionDetailsEditabilityStatus } =
    useSessionDetailsEditability(
      selectedEvent?.calendarEventType === CalendarEventType.INSTANCE
        ? selectedEvent?.originalRecurringEventId
        : selectedEvent?.id
    );

  React.useEffect(() => {
    const canUpdateSessionDetails =
      sessionDetailsEditabilityStatus ===
      SessionDetailsEditabilityStatus.ALLOWED;

    setIsSessionDetailsEditable(canUpdateSessionDetails);
  }, [sessionDetailsEditabilityStatus]);

  return numEvents ? (
    <div
      css={{
        width: '100%',
        '> *': {
          ':not(:last-child)': {
            marginBottom: theme.space.xs2,
          },
        },
      }}
    >
      {sortBy(pastSessions || [], (event) => moment(event.startDate).toDate())
        .reverse()
        .slice(
          0,
          showAll ? numEvents : Math.min(DEFAULT_NUM_EVENTS_IN_VIEW, numEvents)
        )
        .map((event: ProviderEventRead, idx: number) => (
          <Accordion
            key={idx}
            css={variant === 'helix' ? helixCss : muiCss}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="hw-accordion-summary-container">
                <div className="hw-accordion-summary-date">
                  <div css={{ fontWeight: 'bold' }}>
                    {moment(event.startDate).format(
                      variant === 'helix' ? 'MM/DD/YYYY' : 'MMMM Do YYYY'
                    )}
                  </div>
                  {variant === 'mui' &&
                    isSelfPayAppt(event) &&
                    paymentAttemptFailed(event) && (
                      <Chip
                        variant="outlined"
                        size="small"
                        color={'error'}
                        icon={<ErrorOutline />}
                        label={'Client Payment Failed'}
                      />
                    )}
                </div>

                {variant === 'mui' && isSelfPayAppt(event) && (
                  <span
                    css={{
                      display: 'flex',
                      marginTop: theme.space.xs,
                    }}
                  >
                    <SelfPay
                      css={{
                        width: 20,
                        marginRight: theme.space.xs,
                      }}
                    />
                    <span>Private Pay</span>
                  </span>
                )}

                <div className="hw-accordion-summary-desc">
                  {getSessionDescription(event)}
                </div>
                {variant === 'helix' && isSelfPayAppt(event) && (
                  <div className="hw-accordion-summary-badge">
                    <Badge variant="positive">Private Pay</Badge>
                  </div>
                )}
                {variant === 'helix' &&
                  isSelfPayAppt(event) &&
                  paymentAttemptFailed(event) && (
                    <div className="hw-accordion-summary-badge">
                      <Badge variant="negative" icon={IconError}>
                        Client payment failed
                      </Badge>
                    </div>
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails
              css={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {isSelfPayAppt(event) && paymentAttemptFailed(event) && (
                <PaymentFailedAlert
                  patientFirstName={formatPatientName(patientUser, {
                    firstNameOnly: true,
                  })}
                  paymentMethod={paymentMethod}
                  variant={variant}
                />
              )}
              <AccordionDetailsHeader className="hw-accordion-details-header">
                Session Details
              </AccordionDetailsHeader>
              <PropertyList css={{ width: '100%' }}>
                <PropertyListItem
                  label="Appointment date"
                  value={moment(event.startDate).format('MM/DD/YYYY h:mma')}
                  className="hw-property-list-item"
                />
                <PropertyListItem
                  label="CPT codes"
                  value={event.providerAppointment?.cptCodes?.join(', ')}
                  className="hw-property-list-item"
                />
                {!!event.providerAppointment?.exactStartTime && (
                  <PropertyListItem
                    label="Exact start time"
                    value={moment(
                      event.providerAppointment.exactStartTime
                    ).format('h:mma')}
                    className="hw-property-list-item"
                  />
                )}
                <PropertyListItem
                  label="Diagnosis codes"
                  value={event.providerAppointment?.diagnosisCodes?.join(', ')}
                  className="hw-property-list-item"
                />
                <PropertyListItem
                  label="Location"
                  value={
                    event.telehealth
                      ? 'Virtual (Telehealth)'
                      : addressContext.providerAddressesById[
                          event.providerAddressId!
                        ]?.streetLine1
                  }
                  className="hw-property-list-item"
                />
                {!!event.providerAppointment?.exactEndTime && (
                  <PropertyListItem
                    label="Exact end time"
                    value={moment(
                      event.providerAppointment.exactEndTime
                    ).format('h:mma')}
                    className="hw-property-list-item"
                  />
                )}
              </PropertyList>

              <PastSessionsProgressNote
                eventVirtualId={event.virtualId}
                setIsConfirmDetailsOpen={setIsConfirmDetailsOpen}
                isSpoofing={isSpoofing && !isSpoofingAsMedicalRecordAuditor}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      {selectedEvent ? (
        <AppointmentConfirmation
          open={isConfirmDetailsOpen}
          onClose={handleCloseConfirmSessionModal}
          eventVirtualId={selectedEvent.virtualId}
          patientId={patientUser.id}
          progressNoteOnly={!isSessionDetailsEditable}
          onOpenContactFormInsuranceIssues={onOpenContactFormInsuranceIssues}
          onProgressNoteUpdate={() => {
            providerProgressNotesCache.forceRefresh({
              providerAppointmentId: selectedEvent.providerAppointment!.id,
            });
          }}
        />
      ) : null}
      {numEvents > DEFAULT_NUM_EVENTS_IN_VIEW &&
        (variant === 'helix' ? (
          <div
            css={{
              marginTop: theme.space.xs,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <HelixButton
              variant="link"
              onPress={() => setShowAll((prevShowAll) => !prevShowAll)}
            >
              {showAll ? 'Show fewer sessions' : 'Show all sessions'}
            </HelixButton>
          </div>
        ) : (
          <Button
            css={{ marginTop: theme.space.xs }}
            onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
            variant="outlined"
            color="primary"
            fullWidth={true}
          >
            {showAll ? 'Show Fewer Sessions' : 'Show All Sessions'}
          </Button>
        ))}
    </div>
  ) : (
    <div>No sessions have been confirmed for this client.</div>
  );
};

const muiCss = css({
  '.hw-accordion-summary-container': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.hw-accordion-summary-date': {
    display: 'flex',
    gap: '10px',
  },
  '.hw-accordion-summary-desc': {
    fontStyle: 'italic',
    marginTop: theme.space.xs2,
  },
  '.hw-accordion-details-header': {
    marginTop: theme.space.base,
  },
});

const helixCss = css({
  '.MuiAccordionSummary-root': {
    flexDirection: 'row-reverse',
    gap: helixTheme.spacing.x4,
  },
  '&.MuiAccordion-root': {
    borderRadius: 0,
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderColor: helixTheme.color.system.borderGray,
    margin: 0,
    '&:last-child': {
      borderBottom: `1px solid ${helixTheme.color.system.borderGray}`,
    },
  },
  '.MuiAccordionSummary-content': {
    margin: `${helixTheme.spacing.x4} 0`,
  },
  '.MuiAccordionDetails-root': {
    border: 'none',
  },
  '.hw-property-list-item > :first-child': {
    ...helixTheme.typography.body.medium,
    color: helixTheme.color.system.textBlack,
  },
  '.hw-accordion-summary-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: helixTheme.spacing.x4,
  },
  '.hw-accordion-summary-date': {
    display: 'flex',
    gap: '10px',
    minWidth: 169,
    paddingRight: helixTheme.spacing.x4,
    '& > *': {
      ...helixTheme.typography.body.regular,
    },
  },
  '.hw-accordion-summary-badge': {
    minWidth: 'max-content',
  },
  '.hw-accordion-summary-desc': {
    ...helixTheme.typography.body.regular,
  },
  '.hw-accordion-details-header': {
    ...helixTheme.typography.list,
    marginTop: helixTheme.spacing.x4,
    color: helixTheme.color.system.textBlack,
    marginBottom: helixTheme.spacing.x3,
  },
});

type PastSessionsListProps = {
  patientUser: UserRead;
  paymentMethod?: UserPaymentMethodRead;
  onCloseConfirmSessionModal: () => void;
  isLoading: boolean;
  variant?: 'mui' | 'helix';
  pastSessions: ProviderEventRead[];
};

export const PastSessionsList: React.FC<
  React.PropsWithChildren<PastSessionsListProps>
> = (props) => {
  return props.isLoading ? null : (
    <SelectedEventContextProvider>
      <PastSessionsListWithEventsHelpers
        pastSessions={props.pastSessions}
        onCloseConfirmSessionModal={props.onCloseConfirmSessionModal}
        patientUser={props.patientUser}
        paymentMethod={props.paymentMethod}
        variant={props.variant}
      />
    </SelectedEventContextProvider>
  );
};
