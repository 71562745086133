/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum PatientAssessmentRecurrenceCadence {
    ONE_TIME = 'ONE_TIME',
    EVERY_7_DAYS = 'EVERY_7_DAYS',
    EVERY_14_DAYS = 'EVERY_14_DAYS',
    EVERY_30_DAYS = 'EVERY_30_DAYS',
    EVERY_60_DAYS = 'EVERY_60_DAYS',
    EVERY_90_DAYS = 'EVERY_90_DAYS'
}
