import { BaseTextFieldProps, TextFieldProps } from '@mui/material';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { AutocompleteValue } from '@mui/material/useAutocomplete';
import { useFormikContext } from 'formik';
import React, { useRef } from 'react';

import { Optional } from '@headway/shared/utils/types';

import { FieldControlContext } from './FieldControl';
import { useField } from './useField';

type OptionsType = {} | string;
export type FieldAutocompleteProps<
  T extends OptionsType,
  Multiple extends boolean | undefined = false, // TODO: Extend option to type generic Multiple type
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = Optional<
  AutocompleteProps<T, true | false, DisableClearable, FreeSolo>,
  'renderInput'
> & {
  freeSoloCreateOption?: boolean; // For free solo with dropdown option to "Add X"
  label?: BaseTextFieldProps['label'];
  onChangeOptions?(
    _: React.ChangeEvent<{}>,
    value: AutocompleteValue<T, true | false, DisableClearable, FreeSolo>
  ): void;
  textFieldProps?: TextFieldProps;
};

export const FieldAutocomplete = <
  T extends OptionsType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
>({
  freeSoloCreateOption,
  label,
  placeholder,
  onChangeOptions,
  textFieldProps,
  ref,
  ...props
}: FieldAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const inputRef = useRef(null);
  const { name, id } = React.useContext(FieldControlContext);
  const [field] = useField({ name, ref: inputRef, ...props });
  const { errors, touched, setFieldValue } = useFormikContext<{
    [name: string]: T;
  }>();

  return (
    <Autocomplete
      id={id}
      data-testid={name}
      renderInput={(params: object) => (
        <TextField
          inputRef={inputRef}
          {...params}
          label={label}
          placeholder={placeholder}
          error={!!touched[name] && !!errors[name]}
          variant="standard"
          fullWidth={true}
          {...(freeSoloCreateOption
            ? { onChange: () => setFieldValue(name, field.value) }
            : props.freeSolo
            ? {
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue(name, e.target.value),
              }
            : {})}
          css={{
            marginTop: 0,
            marginBottom: 0,
          }}
          {...textFieldProps}
        />
      )}
      ref={ref}
      {...field}
      onChange={(
        _: React.ChangeEvent<{}>,
        value: AutocompleteValue<T, true | false, DisableClearable, FreeSolo>
      ) => {
        if (onChangeOptions) onChangeOptions(_, value);
        setFieldValue(name, value);
      }}
      {...props}
    />
  );
};
