import queryString from 'query-string';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface QueryUpdaterOptions {
  // If set to true, the param update will replace the current history value instead of pushing.
  replace?: boolean;
}

export type QueryUpdater<T> = (
  newValue: T | undefined,
  options?: QueryUpdaterOptions
) => void;

export type QueryValueAndUpdater<T> = [T | undefined, QueryUpdater<T>];

/**
 * Hook for reading and writing a URL query parameter in a way similar to useState. Only supports
 * parameters that are expected to have a single value; if multiple values are supplied for the
 * same query param (e.g. ?foo=1&foo=2), the first parsed value will be returned.
 * @param name The name of the param as written in the URL
 * @return A tuple where the first value is the current value of the query param, and the second
 * value is a function which can be called to update the value of the query param in the URL.
 */
export function useQueryParam(name: string): QueryValueAndUpdater<string> {
  const { search } = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    const parsedParams = queryString.parse(search);
    const rawValue = parsedParams[name];
    let value: string | undefined;
    if (Array.isArray(rawValue)) {
      value = rawValue[0] || undefined;
    } else {
      value = rawValue || undefined;
    }

    return [
      value,
      (newValue: string | undefined, options: QueryUpdaterOptions = {}) => {
        const newParams = { ...parsedParams, [name]: newValue };
        const newQueryString = `?${queryString.stringify(newParams)}`;
        navigate(newQueryString, { replace: !!options.replace });
      },
    ];
  }, [search, name]);
}

export function useQueryParams<T>(): T {
  const { search } = useLocation();

  return useMemo(() => {
    const parsedParams = queryString.parse(search) as unknown;
    return parsedParams as T;
  }, [search]);
}

/**
 * Convenience wrapper for useQueryParam that automatically parses numerical query params to
 * numbers. Note: if the param is present but not parseable to a number, NaN is returned.
 */
export function useNumberQueryParam(
  name: string
): QueryValueAndUpdater<number> {
  const [stringVal, setStringVal] = useQueryParam(name);
  return useMemo(
    () => [
      stringVal === undefined ? stringVal : Number(stringVal),
      (newValue: number | undefined, options: QueryUpdaterOptions = {}) =>
        setStringVal(
          newValue === undefined ? newValue : String(newValue),
          options
        ),
    ],
    [stringVal, setStringVal]
  );
}
