import { inject, observer } from 'mobx-react';
import React from 'react';

import { ProviderAddressContext } from '@headway/ui/providers/ProviderAddressProvider';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { Address } from './components/Address';

const AddressesWithContext = inject('AuthStore')(
  observer(
    class AddressesWithContext extends React.Component {
      state = {
        inactiveOpen: false,
        isModalOpen: false,
        modalAction: 'Add',
        selectedAddress: {},
      };

      clearSelectedAddress = () => {
        this.setState({
          selectedAddress: {},
        });
      };

      handleLocationSelect = (values) => {
        this.setState({
          selectedAddress: {
            ...this.state.selectedAddress,
            ...values,
          },
        });
      };

      toggleAddressArchived = (address) => {
        return this.props.updateProviderAddress(address.id, {
          isActive: !address.isActive,
        });
      };

      toggleIsModalOpen = (modalAction, address) => {
        this.setState({
          isModalOpen: !this.state.isModalOpen,
          modalAction,
          selectedAddress:
            modalAction === 'Add' ? {} : address || this.state.selectedAddress,
        });
      };

      toggleInactiveCollapse = () => {
        this.setState({
          inactiveOpen: !this.state.inactiveOpen,
        });
      };

      saveAddress = async (address) => {
        const addressId = this.state.selectedAddress.id;
        if (addressId) {
          try {
            await this.props.updateProviderAddress(addressId, address);
            this.toggleIsModalOpen();
          } catch (error) {
            notifyError(error.message);
          }
        } else {
          try {
            await this.props.createProviderAddress({
              ...address,
              providerId: this.props.AuthStore.provider.id,
            });
            this.toggleIsModalOpen();
          } catch (error) {
            notifyError(error.message);
          }
        }
      };

      render() {
        const { selectedAddress, isModalOpen, modalAction } = this.state;
        return (
          <Address
            provider={this.props.AuthStore.provider}
            isLoading={this.state.isLoading}
            titleColor={theme.color.primary}
            selectedAddress={selectedAddress}
            isModalOpen={isModalOpen}
            modalAction={modalAction}
            saveAddress={this.saveAddress}
            addresses={this.props.providerAddresses}
            inactiveOpen={this.state.inactiveOpen}
            toggleIsModalOpen={this.toggleIsModalOpen}
            toggleInactiveCollapse={this.toggleInactiveCollapse}
            toggleAddressArchived={this.toggleAddressArchived}
            handleLocationSelect={this.handleLocationSelect}
            clearSelectedAddress={this.clearSelectedAddress}
          />
        );
      }
    }
  )
);

export const Locations = (props) => {
  return (
    <ProviderAddressContext.Consumer>
      {({
        isLoading,
        providerAddresses,
        createProviderAddress,
        updateProviderAddress,
      }) => (
        <AddressesWithContext
          {...props}
          isLoading={isLoading}
          providerAddresses={providerAddresses}
          createProviderAddress={createProviderAddress}
          updateProviderAddress={updateProviderAddress}
        />
      )}
    </ProviderAddressContext.Consumer>
  );
};

export { AddressesWithContext };
