import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { FieldControlContext } from '@headway/ui/form/FieldControl';

import { FormCheckbox, FormCheckboxProps } from './FormCheckbox';
import { useField } from './useField';

export type FieldCheckboxProps = FormCheckboxProps & {
  fieldType?: string;
  labelCss?: { [key: string]: string };
};

export const FieldCheckbox: FC<React.PropsWithChildren<FieldCheckboxProps>> = ({
  fieldType,
  ref,
  labelCss,
  ...props
}) => {
  const inputRef = React.useRef(null);
  const { name, id } = React.useContext(FieldControlContext);
  const { setFieldValue } = useFormikContext();
  const [field] = useField({ name, ref: inputRef, ...props });

  if (fieldType === 'boolean') {
    return (
      <FormCheckbox
        id={id}
        data-testid={name}
        checked={field.value == true}
        onChange={() => {
          if (field.value == props.value) {
            setFieldValue(name, !props.value);
          } else {
            setFieldValue(name, props.value);
          }
        }}
        ref={ref}
        inputRef={inputRef}
        labelCss={labelCss}
        {...props}
      />
    );
  } else if (fieldType === 'string') {
    return (
      <FormCheckbox
        id={name}
        ref={ref}
        inputRef={inputRef}
        {...props}
        {...field}
        labelCss={labelCss}
      />
    );
  }

  return (
    <FormCheckbox
      id={name}
      data-testid={name}
      checked={field.value?.includes(props.value)}
      onChange={() => {
        if (field.value?.includes(props.value)) {
          const nextValue = field.value
            .filter((value: boolean) => value !== props.value)
            .sort();
          setFieldValue(name, nextValue);
        } else {
          const nextValue = field.value?.concat(props.value).sort();
          setFieldValue(name, nextValue);
        }
      }}
      inputRef={inputRef}
      ref={ref}
      labelCss={labelCss}
      {...props}
    />
  );
};
