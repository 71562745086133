const fontFamily = {
  brand: '"CanelaText", serif',
  regular: '"PostGrotesk", "Roboto", "Helvetica", "Arial", sans-serif',
} as const;

const breakpoints = {
  wide: 1280,
  desktop: 1044,
  tablet: 768,
  phone: 480,
} as const;

const DESKTOP_BREAKPOINT = 1024;
// @deprecated: use __futureMedia instead
const legacyMedia = {
  mobile: `@media screen and (max-width: ${DESKTOP_BREAKPOINT}px)`,
  desktop: `@media screen and (min-width: ${DESKTOP_BREAKPOINT + 1}px)`,
};
// @deprecated: use __futureMedia instead
const legacyMediaQuery = {
  mobile: `(max-width: ${DESKTOP_BREAKPOINT}px)`,
  desktop: `(min-width: ${DESKTOP_BREAKPOINT + 1}px)`,
};

const media = {
  below(breakpoint: keyof typeof breakpoints): string {
    return `@media (max-width: ${breakpoints[breakpoint] - 1}px)`;
  },

  above(breakpoint: keyof typeof breakpoints): string {
    return `@media (min-width: ${breakpoints[breakpoint]}px)`;
  },
  wide: `@media screen and (min-width: ${breakpoints.wide}px)`,
  desktop: `@media screen and (min-width: ${
    breakpoints.desktop
  }px) and (max-width: ${breakpoints.wide - 1}px)`,
  tablet: `@media screen and (min-width: ${
    breakpoints.tablet
  }px) and (max-width: ${breakpoints.desktop - 1}px)`,
  phone: `@media screen and (max-width: ${breakpoints.phone - 1}px)`,
};

// Design tokens for things like fonts, tap size/spacing, etc.
const responsive = {
  mobile: `@media screen and (max-width: ${breakpoints.tablet - 1}px)`,
};

//TODO(stephen): Font sizes and spacing etc are declared in px, should translate to rem at some point
const fontSize = {
  caption: '13px',
  subbody: '14px',
  body: '16px',
  list: '18px',
  sectionHeader: '21px',
  header: '26px',
  brandContent: '18px',
  brandHeader: '48px',
} as const;

const lineHeight = {
  caption: '16px',
  subbody: '18px',
  body: '22px',
  list: '24px',
  sectionHeader: '28px',
  header: '36px',
  brandContent: '28px',
  brandHeader: '60px',
} as const;

const fontWeight = {
  // book: 300,
  regular: 400,
  // medium: 500,
  bold: 700,
} as const;

const typography = {
  brand: {
    header: {
      fontFamily: fontFamily.brand,
      fontSize: fontSize.brandHeader,
      lineHeight: lineHeight.brandHeader,
      fontWeight: fontWeight.regular,
      letterSpacing: '-.02em',
      [responsive.mobile]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    section: {
      fontFamily: fontFamily.brand,
      fontSize: fontSize.sectionHeader,
      lineHeight: lineHeight.sectionHeader,
      fontWeight: fontWeight.regular,
      letterSpacing: '-.02em',
    },
    content: {
      fontFamily: fontFamily.brand,
      fontSize: fontSize.brandContent,
      lineHeight: lineHeight.brandContent,
      fontWeight: fontWeight.regular,
      letterSpacing: '-.02em',
    },
  },
  header: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.header,
    lineHeight: lineHeight.header,
    fontWeight: fontWeight.bold,
    letterSpacing: '-.02em',
  },
  sectionHeader: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.sectionHeader,
    lineHeight: lineHeight.sectionHeader,
    fontWeight: fontWeight.bold,
  },

  list: {
    fontFamily: fontFamily.regular,
    fontSize: fontSize.list,
    lineHeight: lineHeight.list,
    fontWeight: fontWeight.bold,
  },

  body: {
    regular: {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.body,
      lineHeight: lineHeight.body,
      fontWeight: fontWeight.regular,
      letterSpacing: 'normal',
      [responsive.mobile]: {
        fontSize: '17px',
      },
    },
    medium: {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.body,
      lineHeight: lineHeight.body,
      fontWeight: fontWeight.bold,
      letterSpacing: 'normal',
      [responsive.mobile]: {
        fontSize: '17px',
      },
    },
  },
  subbody: {
    regular: {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.subbody,
      lineHeight: lineHeight.subbody,
      fontWeight: fontWeight.regular,
      letterSpacing: 'normal',
      [responsive.mobile]: {
        fontSize: '15px',
      },
    },
    medium: {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.subbody,
      lineHeight: lineHeight.subbody,
      fontWeight: fontWeight.bold,
      letterSpacing: 'normal',
      [responsive.mobile]: {
        fontSize: '15px',
      },
    },
  },
  caption: {
    regular: {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.caption,
      lineHeight: lineHeight.caption,
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontFamily: fontFamily.regular,
      fontSize: fontSize.caption,
      lineHeight: lineHeight.caption,
      fontWeight: fontWeight.bold,
    },
  },
  hyperlink: {
    textDecoration: 'underline !important',
    textUnderlineOffset: '2px',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    fontWeight: 'inherit',
  },
} as const;

const color = {
  primary: {
    brandGreen: '#13AA65',
    blue: '#2C98EF',
    red: '#E32C02',
    yellow: '#FFC41D',
    brown: '#7E5C00',
  },
  hue: {
    lightGreen: '#E7FBF2',
    lightBlue: '#E8F4FD',
    lightSystemRed: '#FFF4F1',
    lightBrandRed: '#FFF0F9',
    lightYellow: '#FFF8EE',
  },
  system: {
    green: '#128756',

    black: '#212326',
    textBlack: '#353535',
    gray: '#717171',
    disabledGray: '#BEBEBE',
    borderGray: '#DADCE0',
    lightGray: '#EBEBEB',
    backgroundGray: '#F8F8F8',
    white: '#FFFFFF',
  },
} as const;

const spacing = {
  x1: '4px',
  x2: '8px',
  x3: '12px',
  x4: '16px',
  x5: '20px',
  x6: '24px',
  x7: '28px',
  x8: '32px',
  x9: '36px',
  x10: '40px',
  x11: '44px',
  x12: '48px',
} as const;

const elevation = {
  light: '0px 2px 16px rgba(0, 0, 0, 0.12)',
  medium: '0px 2px 8px rgba(0, 0, 0, 0.2)',
} as const;

const layers = {
  backdrop: 0,
  base: 10,
  modal: 20,
  popover: 30,
} as const;

const stack = {
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
  },
} as const;

export const theme = {
  color,
  elevation,
  breakpoints,
  responsive,
  media: legacyMedia,
  mediaQuery: legacyMediaQuery,
  __futureMedia: media,
  spacing,
  stack,
  typography,
  layers,
  reset: {
    '& :where(h1, h2, h3, h4, h5, h6)': {
      fontSize: 'unset',
      margin: 0,
    },
  },
};
