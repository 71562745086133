import React from 'react';

import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { Link } from '@headway/helix/Link';
import { theme } from '@headway/helix/theme';

// TO DO: Remove section in sc-142500
export const ProviderClientRelationships = () => {
  return (
    <div
      css={{
        marginTop: theme.spacing.x2,
        marginBottom: theme.spacing.x2,
      }}
    >
      <CollapsibleDisclosure label="New: Provider-client relationships">
        <p
          css={{
            marginTop: theme.spacing.x2,
            marginBottom: theme.spacing.x2,
          }}
        >
          Here is the updated language:
        </p>
        <ul
          css={{
            margin: '0 0 10px',
          }}
        >
          <li>
            “For clarity, this section does not prohibit PROVIDER from
            communicating with or soliciting any client or patient that PROVIDER
            brings to the Practice. For example, if PROVIDER brings a client or
            patient onto Practice’s platform, PROVIDER has the right to reach
            out to the client or patient about continuing their treatment
            relationship with PROVIDER after PROVIDER leaves the Practice.”
          </li>
          <li>
            “You are not prohibited from soliciting patients that you brought to
            Headway when you end your relationship with Headway.”
          </li>
          <li>
            “For clarity, nothing in this Agreement shall require PROVIDER to
            delete any clinical documentation or other health records generated
            as a result of a patient or client encounter, whether before or
            after termination of the Agreement.”
          </li>
        </ul>

        <p
          css={{
            marginTop: theme.spacing.x5,
            marginBottom: theme.spacing.x2,
          }}
        >
          Have more questions about these changes?{' '}
          <Link
            target="_blank"
            rel="noreferrer"
            href="https://calendly.com/office-hours-h2k?utm_source=sendgrid&utm_medium=email&utm_campaign=email_name"
          >
            Sign up for office hours
          </Link>{' '}
          to speak directly with a member of our clinical team.
        </p>
      </CollapsibleDisclosure>
    </div>
  );
};
