import { Banner } from '@headway/helix/Banner';
import { Button } from '@headway/helix/Button';
import { theme } from '@headway/helix/theme';

export const PausedPaymentsBanner = ({
  bannerOffset,
  onClick,
}: {
  bannerOffset: number;
  onClick: () => void;
}) => {
  return (
    <div
      css={{
        position: 'fixed',
        background: theme.color.hue.lightSystemRed,
        padding: `0 ${theme.spacing.x1}`,
        height: 50,
        top: 0 + bannerOffset,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 17,
        color: theme.color.system.black,
      }}
    >
      <Banner
        variant="error"
        adornment={
          <Button size="large" variant="link" onPress={onClick}>
            Learn More
          </Button>
        }
      >
        Payments are on hold for sessions covered by insurance until we can
        verify your notes meet insurance standards.
      </Banner>
    </div>
  );
};
