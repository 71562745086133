import { ASRSScoreSummary } from '@headway/api/models/ASRSScoreSummary';
import { PatientAssessmentRecurrenceCadence } from '@headway/api/models/PatientAssessmentRecurrenceCadence';
import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

export interface ScheduleConfig {
  cadence: PatientAssessmentRecurrenceCadence;
  startDate: string;
}

export type ManageAssessmentsModalFormValues = {
  [assessmentType in PatientAssessmentType]: ScheduleConfig;
} & { enabledAssessmentTypes: PatientAssessmentType[] };

// Information used in the assessment history view for populating the assessment results modal when
// selecting an assessment.
export interface SelectedAssessmentInfo {
  // The assessment id of the selected assessment
  id: number;
  // The score of the assessment prior to the selected assessment, if it exists.
  previousScore?: number;
}

// Information used to determine result and score badges for an assessment.
export interface AssessmentBadgeInfo {
  assessmentType: PatientAssessmentType;
  score?: number | null;
  scorableResponseJson?: object | null;
  subscores?: ASRSScoreSummary | null;
}

// Time scales used in the assessment line chart
export enum TimeScale {
  ALL = 'ALL',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTHS = 'THREE_MONTHS',
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
}
