import { css, Global } from '@emotion/react';
import clsx from 'clsx';
import { useProvider } from 'hooks';
import { groupBy } from 'lodash';
import { observable } from 'mobx';
import { makeObservable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment-timezone';
import qs from 'qs';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { MessageType } from '@headway/api/models/MessageType';
import { MessageApi } from '@headway/api/resources/MessageApi';
import { Item } from '@headway/helix/collections';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { SearchField } from '@headway/helix/SearchField';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TabList, TabPanels, Tabs } from '@headway/helix/Tabs';
import { theme } from '@headway/helix/theme';
import { HeadwayMark } from '@headway/icons/dist/HeadwayMark';
import {
  OPTIMIZED_MESSAGES_VIEW,
  SHOW_MESSAGING_UI_FILTERING_BAR,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import {
  formatPatientName,
  formatPatientPronouns,
} from '@headway/shared/utils/patient';
import { useQueryParam } from '@headway/shared/utils/queryParams';
import { logException } from '@headway/shared/utils/sentry';
import {
  ComposeMessageTextarea,
  getMessageGroup,
  InlineContent,
  MessageThread,
  MessageThreadHeader,
  MessageThreadRecipientItem,
  RecipientBubble,
  SenderBubble,
} from '@headway/ui/messages';
import { notifyError } from '@headway/ui/utils/notify';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { useMessages, useMessagesCache } from 'hooks/useMessages';
import { useUnreadProviderMessagesCache } from 'hooks/useProviderUnreadMessages';
import { useCreateMessageMutation } from 'mutations/message';
import { SideEffectsBuilder } from 'mutations/utils';
import { withUiStore } from 'stores/UiStore';
import { withReactRouterV5Props } from 'utils/migration/withReactRouterV5Props';

import { FullHeightPanelLayout } from '../../layouts/FullHeightPanelLayout';
import { useTailwindGlobals } from '../../utils/css';
import { getMessagePrefill } from './utils';

const TIME_BEFORE_MESSAGE_MARKED_AS_READ = 600;

const getMessageTypeDescription = (message, patient) => {
  switch (message.type) {
    case MessageType.PROVIDER_CANCELLATION:
      return `You canceled an appointment ${
        message.content ? 'with the following message' : 'without a message.'
      }`;
    case MessageType.APPOINTMENT_TO_INTAKE_CALL:
      return `You changed an appointment to a phone consultation ${
        message.content ? 'with the following message' : 'without a message.'
      }`;
    case MessageType.PATIENT_BOOKING:
      return `${formatPatientName(patient, {
        firstNameOnly: true,
      })} booked an appointment with the following message`;
    case MessageType.PROVIDER_BOOKING:
      return `You booked an appointment ${
        message.content ? 'with the following message' : 'without a message.'
      }`;
    case MessageType.PROVIDER_RESCHEDULE:
      return `You rescheduled an appointment ${
        message.content ? 'with the following message' : 'without a message.'
      }`;
    default:
      return null;
  }
};

const MessagesImpl = inject('AuthStore')(
  observer(
    class MessagesImpl extends React.Component {
      query_params = qs.parse(this.props.location.search.slice(1));

      messageFiltersModalOpen = false;

      patientArchivedFilter =
        this.query_params.archived_filter === undefined
          ? true
          : this.query_params.archived_filter === 'true';

      isValid = false;

      selected_patient_id = undefined;

      constructor(props) {
        super(props);

        makeObservable(this, {
          messageFiltersModalOpen: observable,
          patientArchivedFilter: observable,
          isValid: observable,
          selected_patient_id: observable,
        });

        this.state = {
          threads: [],
          read_thread_timeout_id: -1,
          patient_filter: '',
          new_message_content: '',
        };
        this.sendNewMessage = this.sendNewMessage.bind(this);
        this.selectFilter = this.selectFilter.bind(this);
        this.selectPatient = this.selectPatient.bind(this);
        this.markMessageThreadAsRead = this.markMessageThreadAsRead.bind(this);
        this.handlePatientFilter = this.handlePatientFilter.bind(this);
      }

      sendNewMessage() {
        this.props.createMessageMutation.mutate({
          providerId: this.props.AuthStore.provider.id,
          patientId: this.selected_patient_id,
          message: {
            content: this.state.new_message_content,
            toProvider: false,
          },
        });
        this.setState({ new_message_content: '' });
      }

      async fetchMessageThreads() {
        this.props.UiStore.enableLoading();
        try {
          const messageThreads = (
            await MessageApi.getActiveProviderMessageThreads(
              this.props.AuthStore.provider.id,
              {
                include_only_archived: this.patientArchivedFilter === false,
              }
            )
          ).threads;

          this.setState({ threads: messageThreads });

          // if we're on tablet or above, select the first patient by default
          const isTabletOrAbove = window.matchMedia(
            theme.__futureMedia.above('tablet').replace('@media ', '')
          ).matches;
          if (
            isTabletOrAbove &&
            this.selected_patient_id === undefined &&
            messageThreads[0]
          ) {
            this.selectPatient(messageThreads[0].patient.id);
          }
        } catch (e) {
          if (e instanceof Error) {
            notifyError(e.message);
          }
        }

        this.props.UiStore.disableLoading();
      }

      async prefillMessageContent() {
        const messagePrefillParams = this.props.messagePrefill;
        if (!messagePrefillParams) {
          return;
        }
        this.props.UiStore.enableLoading();
        try {
          const prefilledMessage =
            await getMessagePrefill(messagePrefillParams);
          this.setState({ new_message_content: prefilledMessage });
        } catch (e) {
          logException(e.message);
        } finally {
          this.props.UiStore.disableLoading();
        }
      }

      selectFilter(option) {
        this.props.history.push(
          `?${qs.stringify({ archived_filter: option })}`
        );
      }

      selectPatient(id) {
        this.props.history.push(
          `?${qs.stringify({
            patient: id,
            archived_filter: this.patientArchivedFilter,
          })}`
        );
        this.selected_patient_id = id;
      }

      markMessageThreadAsRead(thread) {
        MessageApi.markMessageThreadAsRead({
          providerId: this.props.AuthStore.provider.id,
          patientId: thread.patient.id,
          providerReadAtTime: moment.utc(moment(Date.now())).format(),
        })
          .then(() => {
            this.props.unreadProviderMessagesCache.invalidate({
              providerId: this.props.AuthStore.provider.id,
            });
          })
          .catch((err) => {
            logException(err);
          });
        // Mark this thread as read in state, so we don't do a reload of all message threads on click
        this.setState({
          threads: this.state.threads.map(
            ({ providerHasUnreadMessages, ...inputThread }) => ({
              ...inputThread,
              providerHasUnreadMessages:
                inputThread.patient.id === thread.patient.id
                  ? false
                  : providerHasUnreadMessages,
            })
          ),
        });
      }
      handlePatientFilter(patient_filter) {
        if (patient_filter !== this.state.patient_filter) {
          this.setState({ patient_filter: patient_filter.toLowerCase() });
        }
      }

      componentDidUpdate() {
        let query_params = qs.parse(this.props.location.search.slice(1));

        if (query_params.patient !== undefined) {
          this.selected_patient_id = Number(query_params.patient);
        }

        const patientArchivedFilter =
          query_params.archived_filter === undefined
            ? true
            : query_params.archived_filter === 'true';

        if (patientArchivedFilter !== this.patientArchivedFilter) {
          this.patientArchivedFilter = patientArchivedFilter;
          this.selected_patient_id = undefined;
          this.fetchMessageThreads();
        }
      }

      async componentDidMount() {
        await this.fetchMessageThreads();
        await this.prefillMessageContent();
      }

      render() {
        const { messages: allMessages } = this.props;
        const { threads, patient_filter } = this.state;

        const messagesByPatient = groupBy(allMessages, (message) => {
          return message.userId;
        });
        const messages = messagesByPatient[this.selected_patient_id] || [];

        const selected_patient = this.selected_patient_id
          ? threads.find((x) => x.patient.id === this.selected_patient_id)
              ?.patient
          : undefined;

        const filteredThreads =
          patient_filter !== '' && this.props.showMessageThreadFilteringBar
            ? threads.filter((thread) => {
                let matches = false;

                const candidates = [
                  thread.patient.firstName,
                  thread.patient.lastName,
                  `${thread.patient.firstName} ${thread.patient.lastName}`,
                  thread.patient.displayFirstName,
                  thread.patient.displayLastName,
                  `${thread.patient.displayFirstName} ${thread.patient.displayLastName}`,
                  thread.patient.email,
                ];

                for (const candidate of candidates) {
                  if (candidate?.toLowerCase().includes(patient_filter)) {
                    matches = true;
                    break;
                  }
                }

                return matches;
              })
            : threads;

        const handleThreadClick = (show_unread_notification, thread) => {
          if (this.state.read_thread_timeout_id !== -1) {
            // Clear the old read thread timeout_Id
            clearTimeout(this.state.read_thread_timeout_id);
          }
          if (show_unread_notification || thread.lastProviderRead === null) {
            const timer_id = setTimeout(() => {
              this.markMessageThreadAsRead(thread);
            }, TIME_BEFORE_MESSAGE_MARKED_AS_READ);
            this.setState({ read_thread_timeout_id: timer_id });
          }
          this.selectPatient(thread.patient.id);
        };

        return (
          <FullHeightPanelLayout>
            <div className="grid h-full grid-cols-1 bg-system-white tablet:grid-cols-[4fr,8fr]">
              <div
                className={clsx(
                  'h-full overflow-scroll border-r border-system-borderGray tablet:block',
                  {
                    hidden: this.selected_patient_id,
                  }
                )}
              >
                <h1 className="m-0 px-4 pb-2 pt-4">
                  <PageHeader>Messages</PageHeader>
                </h1>
                <Tabs
                  aria-label="Clients"
                  selectedKey={
                    this.patientArchivedFilter === true ? 'active' : 'archived'
                  }
                  onSelectionChange={(key) => {
                    if (key === 'archived') {
                      this.selectFilter(false);
                    } else {
                      this.selectFilter(true);
                    }
                  }}
                >
                  <TabList>
                    <Item key="active">Active clients</Item>
                    <Item key="archived">Archived clients</Item>
                  </TabList>
                  <div className="px-4 py-3 pb-1">
                    <SearchField
                      onChange={(value) => {
                        this.handlePatientFilter(value);
                      }}
                      placeholder="Filter by name or email"
                      aria-label="Filter by name or email"
                      defaultValue={patient_filter}
                    />
                  </div>
                  {/* Pretty hacky for now but we don't actually render anything within each tab as the tab
              state is synced into this.patientArchivedFilter */}
                  <TabPanels>
                    <Item key="active">
                      <VisuallyHidden>
                        <h2>Active clients</h2>
                      </VisuallyHidden>
                    </Item>
                    <Item key="archived">
                      <VisuallyHidden>
                        <h2>Active clients</h2>
                      </VisuallyHidden>
                    </Item>
                  </TabPanels>
                </Tabs>
                <ul className="m-0 px-0 py-1">
                  {filteredThreads.map((thread) => {
                    const isUnread =
                      thread.providerHasUnreadMessages &&
                      thread.patient.id !== this.selected_patient_id;

                    return (
                      <li key={thread.patient.id}>
                        <MessageThreadRecipientItem
                          isUnread={isUnread}
                          onSelectRecipient={() => {
                            handleThreadClick(isUnread, thread);
                          }}
                          name={
                            formatPatientName(thread.patient) ||
                            `(Name Unknown)`
                          }
                          isSelected={
                            thread.patient.id === this.selected_patient_id
                          }
                          messages={messagesByPatient[thread.patient.id]}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>

              <div
                className={clsx(
                  'grid h-full min-h-0 grid-rows-[auto,1fr,auto]',
                  {
                    hidden: !this.selected_patient_id,
                  }
                )}
              >
                {selected_patient ? (
                  <>
                    <MessageThreadHeader
                      recipientName={formatPatientName(selected_patient)}
                      onBack={() => {
                        this.selectPatient(undefined);
                      }}
                      heading={
                        <span>
                          <Link
                            component={RouterLink}
                            elementType="a"
                            to={`/clients/${selected_patient.id}`}
                          >
                            {formatPatientName(selected_patient)}
                          </Link>
                          {selected_patient.pronouns && (
                            <SubBodyText color="gray">
                              {formatPatientPronouns(selected_patient)}
                            </SubBodyText>
                          )}
                        </span>
                      }
                    />

                    <MessageThread>
                      {(messages || [])
                        .sort(
                          (x, y) =>
                            new Date(y.createdOn).getTime() -
                            new Date(x.createdOn).getTime()
                        )
                        .map((message, index, all) => {
                          let items = [];

                          // the list is displayed in reverse order,
                          // so the previous message chronologically is the next one in the list
                          const previousMessage = all[index + 1];

                          if (!!message.content && message.toProvider) {
                            items.push(
                              <RecipientBubble
                                key={message.id}
                                message={message}
                                sender={selected_patient}
                                group={getMessageGroup(message)}
                              />
                            );
                          } else if (!!message.content && !message.toProvider) {
                            items.push(
                              <SenderBubble
                                key={message.id}
                                message={message}
                                group={getMessageGroup(message)}
                              />
                            );
                          }

                          if (message.type !== MessageType.GENERAL) {
                            items.push(
                              <InlineContent
                                key={`${message.id}-${message.type}`}
                              >
                                {getMessageTypeDescription(
                                  message,
                                  selected_patient
                                )}
                              </InlineContent>
                            );
                          }

                          if (
                            !previousMessage ||
                            !moment(message.createdOn).isSame(
                              previousMessage.createdOn,
                              'day'
                            )
                          ) {
                            items.push(
                              <InlineContent key={message.createdOn}>
                                {moment(message.createdOn).format('M/DD/YYYY')}
                              </InlineContent>
                            );
                          }

                          return items;
                        })}
                      <InlineContent key="intro">
                        <HeadwayMark width={'1em'} height="1em" />
                        <span>Share messages securely on Headway</span>
                      </InlineContent>
                    </MessageThread>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();

                        this.sendNewMessage();
                      }}
                    >
                      <ComposeMessageTextarea
                        placeholder="Send a message"
                        value={this.state.new_message_content}
                        onChange={(value) =>
                          this.setState({
                            new_message_content: value,
                          })
                        }
                        submittable={this.state.new_message_content.length > 0}
                        disabled={this.props.createMessageMutation.isLoading}
                      />
                    </form>
                  </>
                ) : null}
              </div>
            </div>
          </FullHeightPanelLayout>
        );
      }
    }
  )
);

const MessagesImplWithFlagsAndMutations = (props) => {
  useTailwindGlobals();
  const showMessageThreadFilteringBar = useFlag(
    SHOW_MESSAGING_UI_FILTERING_BAR,
    true
  );
  const useOptimizedMessagesView = useFlag(OPTIMIZED_MESSAGES_VIEW, false);
  const messagesCache = useMessagesCache();
  const createMessageMutation = useCreateMessageMutation({
    sideEffects: new SideEffectsBuilder().addOptimisticUpdate(
      messagesCache,
      ({ providerId, patientId }) => ({ providerId, patientId }),
      ({ message }, current) => {
        const optimisticMessage = {
          createdOn: new Date().toISOString(),
          ...message,
        };
        if (!current) {
          return [optimisticMessage];
        }
        return [...current, optimisticMessage];
      }
    ),
  });
  const unreadProviderMessagesCache = useUnreadProviderMessagesCache();
  const provider = useProvider();

  const patientId = usePatientIdQueryParam();
  const messagePrefill = useMessagePrefillParam();
  const { data: messages } = useMessages(
    {
      providerId: provider.id,
      patientId,
    },
    {
      select(data) {
        return data?.sort(
          (x, y) => new Date(y.createdOn) - new Date(x.createdOn)
        );
      },
      enabled: !!patientId,
    }
  );

  return (
    <>
      <Global styles={fullscreenMobileCss} />
      <MessagesImpl
        key="messages-helix"
        {...props}
        messages={messages}
        showMessageThreadFilteringBar={showMessageThreadFilteringBar}
        useOptimizedMessagesView={useOptimizedMessagesView}
        createMessageMutation={createMessageMutation}
        unreadProviderMessagesCache={unreadProviderMessagesCache}
        messagePrefill={messagePrefill}
      />
    </>
  );
};

function usePatientIdQueryParam() {
  const [qp] = useQueryParam('patient');
  return qp ? parseInt(qp, 10) : undefined;
}

function useMessagePrefillParam() {
  const [qp] = useQueryParam('messagePrefill');
  if (qp) {
    try {
      return JSON.parse(qp);
    } catch (e) {
      return undefined;
    }
  }
}

const fullscreenMobileCss = css`
  html,
  body {
    height: 100vh;
    height: var(--real100vh);
    height: 100dvh;
  }
`;

export default withReactRouterV5Props(
  withUiStore(MessagesImplWithFlagsAndMutations)
);
