import * as React from 'react';

export const SelfPay = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    id="self-pay_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    width={24}
    x={0}
    y={0}
    viewBox="0 0 441.5 364.2"
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {'.self-pay_svg__st0{fill:#a3d0bc}.self-pay_svg__st1{fill:#046339}'}
    </style>
    <path
      className="self-pay_svg__st0"
      d="M48.3 250.3L290 310.2V113.9l-241.7-60z"
    />
    <path
      className="self-pay_svg__st1"
      d="M141.4 199.2L96.1 188c-12.9-3.2-26 4.7-29.2 17.6-2.8 11.3 2.9 22.7 13 27.6 1.5.7 3 1.3 4.7 1.7l45.3 11.2c2 .5 3.9.7 5.8.7 8.5 0 16.3-4.5 20.7-11.7 1.2-2 2.1-4.2 2.7-6.6 3.1-13-4.8-26.1-17.7-29.3z"
    />
    <path
      className="self-pay_svg__st1"
      d="M433.1 8.3C427.8 2.9 420.6 0 413 0H28.5c-1 0-1.9.1-2.9.1-.2 0-.3.1-.5.1-.8.1-1.6.2-2.3.4-.1 0-.1 0-.2.1-5.4 1.1-10.3 3.7-14.3 7.6-4 4-6.6 8.9-7.7 14.3-.2.8-.3 1.6-.4 2.4 0 .1 0 .3-.1.4-.1 1-.1 1.9-.1 2.9v237.5c0 .9 0 1.8.1 2.7 0 .4.1.8.2 1.2.1.5.1 1 .2 1.4.1.5.2 1 .4 1.5.1.3.2.7.3 1 .2.6.4 1.1.6 1.7.1.3.2.5.3.8.2.6.5 1.1.7 1.7.1.2.2.4.3.7.3.6.6 1.1.9 1.6.1.2.2.4.4.6.3.5.7 1 1 1.5.1.2.3.4.5.6.4.5.7 1 1.1 1.4.2.2.4.4.5.6.3.3.6.7.9 1l.3.3c.1.1.3.2.4.3 2.3 2.1 4.8 3.8 7.5 5.1.2.1.3.2.5.2.6.2 1.1.5 1.7.7.3.1.5.2.8.3.5.2 1 .3 1.6.5.4.1.7.2 1.1.3.5.1.9.2 1.4.3.2 0 .4.1.6.1L307 364h2.9c15.7 0 28.4-12.8 28.4-28.4v-41.4H413c7.6 0 14.7-3 20.1-8.3s8.3-12.5 8.3-20.1V28.4c.1-7.6-2.9-14.7-8.3-20.1zM290 310.2l-241.7-60V53.9l241.7 60v196.3zm103.2-64.1h-54.9v-62.3h54.9v62.3zm0-110.6h-54.9V98.2c0-14.3-10.5-26.1-24.2-28.1l-88-21.8h167.2v87.2z"
    />
    <g>
      <path
        className="self-pay_svg__st0"
        d="M338.3 183.8h54.9v62.3h-54.9zM338.3 98.2v37.3h54.9V48.3H226l88 21.8c13.8 2 24.3 13.9 24.3 28.1z"
      />
    </g>
  </svg>
);
