import moment from 'moment';
import React from 'react';

import { Education as EducationModel } from '@headway/api/models/Education';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { isProviderInAnyStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';

import {
  CaqhKeyValuePair,
  CaqhSection,
  CaqhSubsection,
} from 'views/IntakeQuestionnaireV2/CaqhSection';
import { useQuestionnaireContext } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Context';
import { statesThatRequireCertificateAwarded } from 'views/IntakeQuestionnaireV2/utils/intakeQuestionnaireConstants';

export const EducationHeader = ({
  initialValues,
}: {
  initialValues: ProviderQuestionnaireRawData;
}) => {
  const { provider } = useQuestionnaireContext();

  return (
    <CaqhSection>
      <>
        {initialValues?.education?.map((education: EducationModel, i) => (
          <CaqhSubsection label={education.institutionName}>
            {education.completedDegree === 'YES' &&
              education.startDate &&
              education.completionDate && (
                <CaqhKeyValuePair
                  label="Duration"
                  value={`${moment(education.startDate).format('MMMM YYYY')} - 
                                ${moment(education.completionDate).format(
                                  'MMMM YYYY'
                                )}`}
                />
              )}
            {isProviderInAnyStates(
              provider,
              statesThatRequireCertificateAwarded
            ) && (
              <CaqhKeyValuePair
                label="Certificate Awarded"
                value={education.certificateAwarded}
              />
            )}
            <div>
              <CaqhKeyValuePair
                label="Degree type"
                value={education.degreeType}
              />

              {education.degreeTypeOtherLevel && (
                <div>
                  <CaqhKeyValuePair
                    label="Degree level"
                    value={education.degreeTypeOtherLevel}
                  />
                </div>
              )}
            </div>
            <CaqhKeyValuePair
              label="Address"
              value={
                <>
                  <div>{education.street1}</div>
                  {education.street2 && <div>{education.street2}</div>}
                  <div>
                    {education.city}, {education.state} {education.zip}{' '}
                  </div>
                  <div>{education.country}</div>
                </>
              }
            />
          </CaqhSubsection>
        ))}
      </>
    </CaqhSection>
  );
};
