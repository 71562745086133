import { BillingType } from '@headway/api/models/BillingType';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useUser } from '@headway/shared/hooks/useUser';

// Implemented to match Mamba's user.should_show_anthem_experiences
export const useShowAnthemEAPExperiences = (
  userId: number,
  providerPatient: ProviderPatientRead | undefined,
  appointmentBillingType: BillingType | undefined
) => {
  const anthemEAPFlag = useFlag(ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP, false);
  const { data: patient, isLoading: patientIsLoading } = useUser({
    userId: userId,
  });
  if (!anthemEAPFlag) {
    return {
      showAnthemEAPExperiences: false,
      isLoading: false,
    };
  }

  if (appointmentBillingType) {
    return {
      showAnthemEAPExperiences: appointmentBillingType === BillingType.EAP,
      isLoading: false,
    };
  }

  if (providerPatient) {
    return {
      showAnthemEAPExperiences:
        providerPatient.billingTypeDefault === BillingType.EAP,
      isLoading: false,
    };
  }

  return {
    showAnthemEAPExperiences:
      patient?.inviteChannel &&
      patient?.inviteChannel ===
        UserInviteChannel.ANTHEM_BANK_OF_AMERICA_EAP_LANDING_PAGE,
    isLoading: patientIsLoading,
  };
};
