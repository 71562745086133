import React, { FunctionComponent } from 'react';

import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { BodyText } from '@headway/helix/BodyText';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { hasPhysicianLicenseType } from '@headway/shared/utils/licenseHelper';

import { YesNoQuestion } from '../utils/YesNoQuestion';
import { getConfigForStep } from './config';
import { SectionStepForBscaSpecialty } from './constants';
import { getAllSpecialties } from './helpers';

type BlueCrossCaliforniaFollowupQuestionsProps = {
  values: ProviderQuestionnaireRawData;
  stepForBscaSpecialty: SectionStepForBscaSpecialty;
  specialtyKeys: string[];
};

export const BlueCrossCaliforniaFollowupQuestions: FunctionComponent<
  React.PropsWithChildren<BlueCrossCaliforniaFollowupQuestionsProps>
> = ({ values, stepForBscaSpecialty, specialtyKeys }) => {
  const config = getConfigForStep(stepForBscaSpecialty);
  const allSpecialties = getAllSpecialties(values);
  const isPhysician = hasPhysicianLicenseType(values?.selectedLicenses, [
    UnitedStates.CALIFORNIA,
  ]);

  if (
    stepForBscaSpecialty === SectionStepForBscaSpecialty.Modalities &&
    !isPhysician
  ) {
    return null;
  }

  return allSpecialties.some((specialty) => {
    return specialtyKeys.includes(specialty);
  }) ? (
    <>
      <section
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: theme.spacing.x8,
          marginTop: theme.spacing.x8,
        }}
      >
        <h2>
          <SectionHeader>Follow-up Questions</SectionHeader>
        </h2>
        <p>
          <BodyText>
            We are requesting additional information regarding your training,
            experience, and external agency approval for the areas of expertise
            you selected above, as some of the health plans we partner with have
            specific requirements that providers must meet in order to consider
            themselves a specialist in a treatment area.
          </BodyText>
        </p>
        <p>
          <BodyText>
            Please note that you may be required to provide documentation to
            verify that you meet the criteria you designate below.
          </BodyText>
        </p>
      </section>
      {config.map(
        (section) =>
          allSpecialties.includes(section.specialtyKey) && (
            <section
              css={{
                ...theme.stack.vertical,
                gap: theme.spacing.x6,
                paddingTop: theme.spacing.x6,
              }}
            >
              <h3>
                <SectionHeader>{section.title}</SectionHeader>
              </h3>
              {section.questions.map(({ key, text }) => (
                <React.Fragment key={key}>
                  <YesNoQuestion
                    name={key}
                    label={isPhysician ? text.physician : text.other}
                  />
                </React.Fragment>
              ))}
            </section>
          )
      )}
    </>
  ) : null;
};
