/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import axios, { AxiosRequestConfig, Method } from 'axios';

import { axios as axiosClient, MambaApiError } from '../axios.config';

import { CreateElectronicSignatureRequest } from '../models/CreateElectronicSignatureRequest';
import { FormValidationResponse } from '../models/FormValidationResponse';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { HelloSignResponse } from '../models/HelloSignResponse';

/* tslint:disable:no-unused-variable member-ordering max-line-length */
/* eslint-disable no-useless-concat */

export class FormBuilderApiResource {

    /**
     * Create Electronic Signature Url
     * 
     * @param createElectronicSignatureRequest 
     
     */
    public createElectronicSignatureUrl(body: CreateElectronicSignatureRequest, axiosConfig?: AxiosRequestConfig): Promise<HelloSignResponse> {
        const apiRoute = '/form-builder/electronic-signature';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            data: body
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Get Electronic Signature Download Url
     * 
     * @param signatureRequestId 
     
     */
    public getElectronicSignatureDownloadUrl(signatureRequestId: any, axiosConfig?: AxiosRequestConfig): Promise<string> {
        const apiRoute = '/form-builder/electronic-signature/{signature_request_id}';
        const reqPath = apiRoute
                    .replace('{' + 'signature_request_id}', String(signatureRequestId));
        const reqConfig = {
            ...axiosConfig,
            method: 'GET' as Method,
            url: reqPath
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

    /**
     * Validate Form
     * 
     * @param noteJson 
     
     */
    public validateForm(query?: { note_json: string }, axiosConfig?: AxiosRequestConfig): Promise<FormValidationResponse> {
        const apiRoute = '/form-builder/validate';
        const reqPath = apiRoute;
        const reqConfig = {
            ...axiosConfig,
            method: 'POST' as Method,
            url: reqPath,
            params: query
        };

        let originalStackTrace: string | null = new Error().stack ?? null;

        return axiosClient.request(reqConfig)
            .then(res => {
                originalStackTrace = null
                return res.data;
            })
            .catch(error => {
                if (axios.isAxiosError(error)) {
                    const formattedError = new MambaApiError({ apiRoute, error });

                    // this will not include Axios' library code which is the source of the error.
                    // if we ever want to do that, we can use `${formattedError.stack}\n{originalStackTrace}
                    formattedError.stack = originalStackTrace || formattedError.stack;

                    originalStackTrace = null;

                    throw formattedError;
                }

                throw error;
            });
    }

}

export const FormBuilderApi = new FormBuilderApiResource();
