import moment from 'moment';

import { ConcreteProviderEventRead } from '@headway/api/models/ConcreteProviderEventRead';
import { ProviderEventType } from '@headway/api/models/ProviderEventType';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { Modal } from '@headway/ui';

import { Message } from 'api/MessagesApi';
import { useProviderEventCache } from 'hooks/useProviderEvent';
import { useSelectedEvent } from 'hooks/useSelectedEvent';
import {
  UpdateProviderEventMutationArgs,
  useUpdateProviderEventMutation,
} from 'mutations/providerEvent';
import { SideEffectsBuilder } from 'mutations/utils';
import { NullableKeys } from 'utils/types';

import { AppointmentToIntakeCallForm } from '../form/AppointmentToIntakeCallForm';
import { useUpdateProviderEventSideEffectsForCalendar } from '../utils/queries';

interface AppointmentToIntakeCallModalProps {
  open: boolean;
  onClose: () => void;
  notifyForSelectedEvent: (
    content: string,
    type: keyof typeof Message.Type,
    additionalFields?: any
  ) => Promise<void>;
}

export const AppointmentToIntakeCallModal = ({
  open,
  onClose,
  notifyForSelectedEvent,
}: AppointmentToIntakeCallModalProps) => {
  const { event, isLoading } = useSelectedEvent();
  const providerEventCache = useProviderEventCache();

  const updateEventMutation = useUpdateProviderEventMutation({
    sideEffects: new SideEffectsBuilder<
      ConcreteProviderEventRead,
      unknown,
      UpdateProviderEventMutationArgs
    >()
      .add({
        onSuccess: (result) => {
          providerEventCache.set(
            { eventIdOrVirtualId: result.virtualId },
            result
          );
        },
      })
      .merge(useUpdateProviderEventSideEffectsForCalendar()),
  });

  const handleSubmit = async ({ content }: { content: string }) => {
    if (event) {
      const updateValues = {
        type: ProviderEventType.INTAKE_CALL,
        providerAddressId: null,
        endDate: moment(event.startDate).add(15, 'minutes').toISOString(),
      } as NullableKeys<ProviderEventUpdate> as ProviderEventUpdate;

      await updateEventMutation.mutateAsync({
        eventIdOrVirtualId: event?.virtualId,
        update: updateValues,
      });

      onClose();
      notifyForSelectedEvent(
        content,
        Message.Type.APPOINTMENT_TO_INTAKE_CALL as keyof typeof Message.Type
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Change session to phone consultation"
    >
      {!isLoading ? (
        <AppointmentToIntakeCallForm onSubmit={handleSubmit} />
      ) : null}
    </Modal>
  );
};
