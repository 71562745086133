import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { FrontEndCarrierApi } from '@headway/api/resources/FrontEndCarrierApi';
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@headway/shared/react-query';

import { FRONT_END_CARRIER_CACHE_KEY } from '../utils/cacheKeys';

export interface UseFrontEndCarriers {
  frontEndCarriers: FrontEndCarrierRead[];
  carriersById: { [index: number]: FrontEndCarrierRead };
  isCarriersLoading: boolean;
  query: UseQueryResult<Array<FrontEndCarrierRead>>;
}

type GetFrontEndCarriersQueryParams = Parameters<
  typeof FrontEndCarrierApi.getFrontEndCarriers
>[0];

function useFrontEndCarriers(
  queryParams?: GetFrontEndCarriersQueryParams,
  options?: UseQueryOptions<
    Array<FrontEndCarrierRead>,
    unknown,
    Array<FrontEndCarrierRead>,
    [typeof FRONT_END_CARRIER_CACHE_KEY, GetFrontEndCarriersQueryParams]
  >
): UseFrontEndCarriers {
  const query = useQuery(
    [FRONT_END_CARRIER_CACHE_KEY, queryParams],
    () => FrontEndCarrierApi.getFrontEndCarriers(queryParams),
    { staleTime: Infinity, ...options }
  );

  const carriersById = useMemo(() => keyBy(query.data, 'id'), [query.data]);
  return {
    frontEndCarriers: query.data ?? [],
    carriersById: carriersById,
    isCarriersLoading: query.isLoading,
    query,
  };
}

export { useFrontEndCarriers };
