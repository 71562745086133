import { DateField } from '@headway/helix/DateField';
import { Form } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { TextField } from '@headway/helix/TextField';
import { UploadedFile } from '@headway/shared/utils/upload';

import { CoiPathwayType } from './CoiUtils';

const OcrGuidanceCard = () => {
  return (
    <GuidanceCard variant="warning" layout="vertical">
      <b>We were unable to verify your certificate of insurance.</b> Our
      credentialing team will need to manually review your document to verify
      your malpractice coverage.
    </GuidanceCard>
  );
};

const OcrFailedSubmission = () => {
  return (
    <GuidanceCard variant="warning" layout="vertical">
      <b>Your document has been submitted for manual review.</b> Our
      credentialing team will review your document to verify your malpractice
      coverage.
    </GuidanceCard>
  );
};

const TextFields = ({}: {}) => {
  return (
    <div>
      <Form>
        <FormControl
          name="nameInsured"
          component={TextField}
          label="Name Insured"
        />
        <FormControl
          name="malpracticeProvider"
          component={TextField}
          label="Malpractice insurance provider"
        />

        <div className="grid grid-cols-2 gap-x-6">
          <div>
            <FormControl
              component={DateField}
              label="Effective date"
              name="effectiveDate"
            />
          </div>
          <div>
            <FormControl
              component={DateField}
              label="Expiration date"
              name="expirationDate"
            />
          </div>
        </div>
        <FormControl
          name="occurrenceLimit"
          component={TextField}
          label="Occurrence limit"
        />
        <FormControl
          name="aggregateLimit"
          component={TextField}
          label="Aggregate limit"
        />
      </Form>
    </div>
  );
};

export const CoiOcrFailed = ({
  coiPathway,
}: {
  coiPathway: CoiPathwayType;
}) => {
  return (
    <div className="grid gap-y-4">
      {coiPathway === CoiPathwayType.OCR_FAILED && (
        <div className="grid gap-y-4">
          <OcrGuidanceCard />
          <TextFields />
        </div>
      )}
      {coiPathway === CoiPathwayType.SUBMITTED && <OcrFailedSubmission />}
    </div>
  );
};
