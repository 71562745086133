import {
  Create,
  ErrorTwoTone,
  EventTwoTone,
  ReplayTwoTone,
} from '@mui/icons-material';
import { FormLabel, MenuItem } from '@mui/material';
import { Alert } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';

import { ProviderEventCreate } from '@headway/api/models/ProviderEventCreate';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderEventUpdate } from '@headway/api/models/ProviderEventUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { RecurringUpdateApplyTo } from '@headway/shared/events/constants';
import { Button } from '@headway/ui';
import {
  FieldControl,
  FieldDatePicker,
  FieldErrorText,
  FieldInput,
  FieldInputLabel,
  FieldRadioGroup,
  FieldSelect,
  FieldSwitch,
  FormIconRow,
} from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { Radio } from '@headway/ui/Radio';
import { theme } from '@headway/ui/theme';

import { getRecurrenceString } from '../events/util/events';
import { EstimatedLiveDateWithCarrier } from '../LiveDateCalculator';
import { CalendarSlot } from '../utils/Calendar';
import { RecurrenceType } from '../utils/constants';
import { FieldFiveMinuteIntervalSelect } from './FieldFiveMinuteIntervalSelect';

interface UnavailabilityFormValues {
  startDate: Date | string;
  startTime: Date | string;
  endDate: Date | string;
  endTime: Date | string;
  recurrence?: string;
  recurringUpdateApplyTo?: RecurringUpdateApplyTo;
  title: string;
  allDay: boolean;
}

interface ScheduleUnavailabilityFormProps {
  event: CalendarSlot | ProviderEventRead;
  provider: ProviderRead;
  minEstimatedLiveDate?: EstimatedLiveDateWithCarrier;
  timeZone: string;
  isRescheduling: boolean;
  handleSubmit: (eventCreate: ProviderEventCreate) => Promise<void>;
  handleRescheduleSubmit: (
    eventUpdate: ProviderEventUpdate,
    recurringUpdateApplyTo?: RecurringUpdateApplyTo
  ) => Promise<void>;
}

const getEventBounds = ({
  startDate,
  startTime,
  endDate,
}: {
  startDate: Date;
  startTime: Date;
  endDate: Date;
}): { startDate: Date; endDate: Date } => {
  const combinedStartDate = new Date(startDate);
  combinedStartDate.setHours(startTime.getHours());
  combinedStartDate.setMinutes(startTime.getMinutes());
  const duration = moment
    .duration(moment(endDate).diff(moment(startTime)))
    .asMinutes();
  const summedEndDate = moment(combinedStartDate)
    .add(duration, 'minutes')
    .toDate();

  return { startDate: combinedStartDate, endDate: summedEndDate };
};

const getStartAndEndDateForProviderEvent = (rawValues: {
  startDate: Date | string;
  startTime: Date | string;
  endDate: Date | string;
  endTime: Date | string;
  allDay: boolean;
}): [Date, Date] => {
  let startDate;
  let endDate;

  if (rawValues.allDay) {
    // For all day we want to set the startDate to midnight and the
    // end date to the next days midnight
    startDate = new Date(rawValues.startDate);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate = new Date(rawValues.endDate);

    // We add one day here since in the DB we store endDate
    // as the next days midnight.
    // Ex: The provider wants to be busy Jan 3rd and Jan 4th
    // we would save it as startDate = Jan 3rd 00:00:00 and
    // endDate = Jan 5th 00:00:00
    endDate.setDate(endDate.getDate() + 1);

    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(0);
  } else {
    const eventBounds = getEventBounds({
      startDate: new Date(rawValues.startDate),
      startTime: new Date(rawValues.startTime),
      endDate: new Date(rawValues.endTime),
    });
    startDate = eventBounds.startDate;
    endDate = eventBounds.endDate;
  }

  return [startDate, endDate];
};

const ScheduleUnavailabilityForm = ({
  event: initialEvent,
  provider,
  timeZone,
  isRescheduling,
  handleSubmit,
  handleRescheduleSubmit,
}: ScheduleUnavailabilityFormProps) => {
  const isEventNew = ((initialEvent): initialEvent is CalendarSlot =>
    !isRescheduling)(initialEvent);
  const validationSchema = Yup.object()
    .shape({
      title: Yup.string(),
      startDate: Yup.date().required(),
      startTime: Yup.string().required(),
      endDate: Yup.lazy((value) =>
        typeof value === 'string'
          ? Yup.string().required()
          : Yup.date().required()
      ),
      endTime: Yup.string(),
      allDay: Yup.boolean().required(),
    })
    .test('dates-are-valid', '', function (values) {
      if (!values) return true;

      const [startDate, endDate] = getStartAndEndDateForProviderEvent(values);
      if (endDate > startDate) return true;

      return this.createError({
        path: 'startDate',
        message: 'End date must be after start date.',
      });
    });

  const startTime = moment(initialEvent.startDate!).toISOString();

  const isAllDay =
    moment(initialEvent.startDate).day() !== moment(initialEvent.endDate).day();

  const endTime = isAllDay
    ? startTime
    : moment(initialEvent.endDate!).toISOString();

  const initialValues: UnavailabilityFormValues = {
    recurrence: isEventNew
      ? RecurrenceType.DOES_NOT_REPEAT
      : initialEvent.recurrence || RecurrenceType.DOES_NOT_REPEAT,
    recurringUpdateApplyTo: RecurringUpdateApplyTo.THIS_EVENT,
    startDate: moment(initialEvent.startDate!).toISOString(),
    startTime,
    endDate: isAllDay
      ? // We subtract one day since in the DB the endDate is midnight of the following day,
        // but for the user they would want to see only the inclusive days that are busy
        // Ex: ProviderEvent row startDate = Jan 3rd 00:00:00 and endDate = Jan 6th 00:00:00
        // the provider wants to see the startDate as Jan 3rd and the end date as Jan 5th
        moment(initialEvent.endDate!).subtract(1, 'day').toISOString()
      : initialEvent.endDate!,
    endTime,
    title: initialEvent.title || '',
    allDay: isAllDay,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async ({
        recurrence,
        recurringUpdateApplyTo,
        title,
        ...rawValues
      }) => {
        const [startDate, endDate] =
          getStartAndEndDateForProviderEvent(rawValues);

        const formattedTitle = title.trim() || undefined;

        const eventValues = {
          title: formattedTitle,
          timeZone: timeZone,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          type: initialEvent.type!,
        };

        if (isEventNew) {
          const event: ProviderEventCreate = {
            ...(eventValues as any),
            providerId: provider.id,
            recurrence: getRecurrenceString(
              recurrence as RecurrenceType,
              startDate.toISOString(),
              timeZone
            ),
          };
          return await handleSubmit(event);
        } else {
          return await handleRescheduleSubmit(
            eventValues as ProviderEventUpdate,
            recurringUpdateApplyTo
          );
        }
      }}
    >
      {({
        values,
        isSubmitting,
        errors,
      }: FormikProps<UnavailabilityFormValues>) => {
        return (
          <SafeFormikForm>
            <FormIconRow icon={Create}>
              <FieldControl name="title" disabled={isSubmitting}>
                <FieldInputLabel>Title</FieldInputLabel>
                <FieldInput data-testid="addUnavailabilityTitle" />
                <FieldErrorText />
              </FieldControl>
            </FormIconRow>
            <FormIconRow icon={EventTwoTone}>
              <FieldControl name="startDate" fullWidth={true}>
                <FieldDatePicker
                  disabled={isSubmitting}
                  label="Start date"
                  inputFormat="MMM Do, YYYY"
                  disableMaskedInput
                  data-testid="unavailabilityStartField"
                />
              </FieldControl>
              {values.allDay ? (
                <FieldControl name="endDate" fullWidth={true}>
                  <FieldDatePicker
                    disableToolbar={true}
                    disabled={isSubmitting}
                    label="End date"
                    margin="normal"
                    autoOk={true}
                    inputFormat="MMM Do, YYYY"
                    disableMaskedInput
                    data-testid="unavailabilityEndDateField"
                  />
                </FieldControl>
              ) : (
                <>
                  <FieldControl name="startTime" fullWidth={true}>
                    <FieldInputLabel>Start time</FieldInputLabel>
                    <FieldFiveMinuteIntervalSelect
                      data-testid="unavailabilityStartTimeField"
                      disabled={isSubmitting}
                    />
                  </FieldControl>
                  <FieldControl name="endTime" fullWidth={true}>
                    <FieldInputLabel>End time</FieldInputLabel>
                    <FieldFiveMinuteIntervalSelect
                      data-testid="unavailabilityEndTimeField"
                      disabled={isSubmitting}
                    />
                  </FieldControl>
                </>
              )}
              <FieldControl name="allDay" css={{ alignItems: 'flex-end' }}>
                <FieldSwitch label="All day" />
              </FieldControl>
            </FormIconRow>
            {errors.startDate ? (
              <Alert
                color="error"
                variant="outlined"
                icon={<ErrorTwoTone />}
                css={{ marginBottom: theme.space.base }}
              >
                {errors.startDate}
              </Alert>
            ) : null}
            {!isRescheduling ? (
              <FormIconRow icon={ReplayTwoTone}>
                <FieldControl name="recurrence" fullWidth={true}>
                  <FieldInputLabel>Recurrence</FieldInputLabel>
                  <FieldSelect
                    data-testid="unavailabilityRecurrenceField"
                    disabled={isSubmitting}
                  >
                    <MenuItem value={RecurrenceType.DOES_NOT_REPEAT}>
                      Does not repeat
                    </MenuItem>
                    <MenuItem value={RecurrenceType.DAILY}>
                      Every day from {moment(values.startTime).format('h:mma')}{' '}
                      until {moment(values.endTime).format('h:mma')}
                    </MenuItem>
                    <MenuItem
                      value={RecurrenceType.WEEKLY}
                      data-testid="unavailabilityRecurrenceWeeklyOption"
                    >
                      Weekly on {moment(values.startDate).format('dddd')}
                    </MenuItem>
                    <MenuItem value={RecurrenceType.BIWEEKLY}>
                      Every other week on{' '}
                      {moment(values.startDate).format('dddd')}
                    </MenuItem>
                  </FieldSelect>
                  <FieldErrorText />
                </FieldControl>
              </FormIconRow>
            ) : null}
            {isRescheduling &&
            values.recurrence !== RecurrenceType.DOES_NOT_REPEAT ? (
              <FormIconRow icon={ReplayTwoTone}>
                <FieldControl name="recurringUpdateApplyTo" fullWidth={true}>
                  <FormLabel css={{ marginTop: theme.space.xs }}>
                    Which unvailabilities should this apply to?
                  </FormLabel>
                  <FieldRadioGroup>
                    <Radio
                      value={RecurringUpdateApplyTo.THIS_EVENT}
                      label="Only this unavailability"
                      data-testid="unavailabilityThisBlockOption"
                      disabled={isSubmitting}
                    />
                    <Radio
                      value={RecurringUpdateApplyTo.FOLLOWING_EVENTS}
                      label="This and all following unvailabilities"
                      data-testid="unavailabilityAllBlocksOption"
                      disabled={isSubmitting}
                    />
                  </FieldRadioGroup>
                </FieldControl>
              </FormIconRow>
            ) : null}
            <Alert severity="info">
              Hint: Drag the top or bottom of the unavailability block to expand
              or reduce it directly on the calendar.
            </Alert>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                marginTop: theme.space.xl2,
              }}
            >
              <Button
                color="primary"
                type="submit"
                size="large"
                variant="contained"
                disabled={Object.keys(errors).length || isSubmitting}
              >
                Save
              </Button>
            </div>
          </SafeFormikForm>
        );
      }}
    </Formik>
  );
};

export { ScheduleUnavailabilityForm };
