/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/* An enumeration. */    /**
    * An enumeration.
    */
export enum ProviderTerminatedReason {
    ACCOUNTS_MERGED = 'ACCOUNTS_MERGED',
    API = 'API',
    CREDENTIALING_COMMITTEE_DENIAL = 'CREDENTIALING_COMMITTEE_DENIAL',
    DECEASED = 'DECEASED',
    HIGH_PRIORITY_TASK_INACTION = 'HIGH_PRIORITY_TASK_INACTION',
    IRONCLAD_OPT_OUT = 'IRONCLAD_OPT_OUT',
    LICENSE_EXPIRATION = 'LICENSE_EXPIRATION',
    MISSED_RECREDENTIALING_DEADLINE = 'MISSED_RECREDENTIALING_DEADLINE',
    PROVIDER_INELIGIBLE = 'PROVIDER_INELIGIBLE',
    PROVIDER_REQUEST = 'PROVIDER_REQUEST',
    PROVIDER_CANCELED_NEW_CREDENTIALING_REQUEST_IN_INTAKE = 'PROVIDER_CANCELED_NEW_CREDENTIALING_REQUEST_IN_INTAKE',
    SANCTION = 'SANCTION'
}
