import { Skeleton } from '@mui/material';
import React from 'react';

import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { AddressLists } from '@headway/ui/Addresses/AddressLists';
import { AddressModal } from '@headway/ui/Addresses/AddressModal';

import { useAuthStore } from 'stores/AuthStore';

import { TelehealthSettings } from './TelehealthSettings';

export const Address = ({
  provider,
  selectedAddress,
  isModalOpen,
  modalAction,
  saveAddress,
  addresses,
  inactiveOpen,
  toggleIsModalOpen,
  toggleInactiveCollapse,
  toggleAddressArchived,
  titleColor,
  isLoading,
  handleLocationSelect,
  clearSelectedAddress,
  REACT_APP_GOOGLE_MAPS_API_ID,
  ...props
}) => {
  const { setProvider } = useAuthStore();
  const updateProvider = async (providerUpdate) => {
    const res = await ProviderApi.updateProvider(provider.id, providerUpdate);
    setProvider({
      ...provider,
      ...res,
    });
    return res;
  };

  return (
    <>
      <AddressModal
        providerStates={provider.activeProviderLicenseStates}
        selectedAddress={selectedAddress}
        open={isModalOpen}
        onClose={() => toggleIsModalOpen(modalAction)}
        modalAction={modalAction}
        saveAddress={saveAddress}
        handleLocationSelect={handleLocationSelect}
        clearSelectedAddress={clearSelectedAddress}
        REACT_APP_GOOGLE_MAPS_API_ID={process.env.REACT_APP_GOOGLE_MAPS_API_ID}
      />
      <div>
        <div
          css={{
            ...theme.stack.vertical,
          }}
        >
          <PageSection layout="grid.two-column">
            <div>
              <h2>
                <SectionHeader>Practice locations</SectionHeader>
              </h2>
              <PageSectionSubText>
                <p>
                  <BodyText>
                    Update or archive your practice locations.
                  </BodyText>
                </p>
                <p>
                  <BodyText>
                    <strong>Note:</strong> Archiving a location will remove it
                    from your profile, but it will remain on any existing
                    sessions scheduled or held.
                  </BodyText>
                </p>
              </PageSectionSubText>
            </div>

            {isLoading ? (
              <Skeleton variant="rectangular" height={68} />
            ) : (
              <AddressLists
                addresses={addresses}
                inactiveOpen={inactiveOpen}
                toggleIsModalOpen={toggleIsModalOpen}
                toggleInactiveCollapse={toggleInactiveCollapse}
                toggleAddressArchived={toggleAddressArchived}
              />
            )}
          </PageSection>

          <TelehealthSettings
            updateProvider={updateProvider}
            telehealthInstructions={provider.telehealthInstructions}
            videoUrlDefault={provider.videoUrlDefault}
            isAtlas={false}
          />
        </div>
      </div>
    </>
  );
};
