import { css } from '@emotion/react';
import { Formik } from 'formik';
import React from 'react';

import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { RichTextArea } from '@headway/helix/RichTextArea';
import { theme } from '@headway/helix/theme';
import { sanitize } from '@headway/shared/utils/htmlSanitize';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';

interface ClinicalTextEditorProps {
  clinicalText?: string;
  onSave: (textHtml: string) => Promise<void>;
  onCancel: () => void;
  'aria-label'?: string;
  'aria-labelledby'?: string;
}

const ClinicalTextEditor: React.FC<
  React.PropsWithChildren<ClinicalTextEditorProps>
> = ({ clinicalText, onSave, onCancel, ...rest }) => {
  return (
    <Formik
      initialValues={{ textHtml: clinicalText || '' }}
      onSubmit={async (values) => await onSave(values.textHtml)}
      enableReinitialize={true}
    >
      {() => (
        <SafeFormikForm className="ph-no-capture">
          <FormControl component={RichTextArea} name="textHtml" {...rest} />
          <div css={buttonGroupCss}>
            <Button
              variant="primary"
              type="submit"
              data-testid="clinicalTextEditorSaveButton"
            >
              Save
            </Button>
            <Button variant="secondary" type="button" onPress={onCancel}>
              Cancel
            </Button>
          </div>
        </SafeFormikForm>
      )}
    </Formik>
  );
};

const buttonGroupCss = css({
  display: 'flex',
  flexDirection: 'row-reverse',
  gap: theme.spacing.x2,
  marginTop: theme.spacing.x3,
});

interface ClinicalTextStaticDisplayProps {
  fieldId: string;
  clinicalText?: string;
}

const ClinicalTextStaticDisplay: React.FC<
  React.PropsWithChildren<ClinicalTextStaticDisplayProps>
> = ({ fieldId, clinicalText }) => {
  return (
    <div
      data-testid={`clinicalTextEditorStaticView${fieldId}`}
      className="ph-no-capture"
      css={staticDisplayCss}
      dangerouslySetInnerHTML={{
        __html: sanitize(clinicalText || ''),
      }}
    ></div>
  );
};

const staticDisplayCss = css({
  maxHeight: 200,
  overflowY: 'auto',
  ' p': {
    margin: 0,
    ...theme.typography.body.regular,
  },
  ...theme.typography.body.regular,
});

type ClinicalTextProps = Omit<ClinicalTextEditorProps, 'onCancel'> &
  ClinicalTextStaticDisplayProps & {
    editing: boolean;
    setEditing: (editing: boolean) => void;
    emptyStateExplanation: string;
  };

export const ClinicalText: React.FC<
  React.PropsWithChildren<ClinicalTextProps>
> = ({
  fieldId,
  clinicalText,
  onSave,
  editing,
  setEditing,
  emptyStateExplanation,
  ...rest
}) => {
  if (editing) {
    return (
      <ClinicalTextEditor
        onSave={async (values) => {
          await onSave(values);
          setEditing(false);
        }}
        onCancel={() => setEditing(false)}
        clinicalText={clinicalText}
        {...rest}
      />
    );
  }

  if (!clinicalText) {
    return (
      <div>
        <BodyText>{emptyStateExplanation}</BodyText>
      </div>
    );
  }

  return (
    <ClinicalTextStaticDisplay fieldId={fieldId} clinicalText={clinicalText} />
  );
};
