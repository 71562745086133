import React from 'react';

import useFuzzy, { Options } from '@headway/ui/form/useFuzzy';

import { FieldAutocomplete, FieldAutocompleteProps } from './FieldAutocomplete';

type Props<T extends {}> = FieldAutocompleteProps<T> & {
  fuzzySearchOptions?: Options<T>;
};

export const FieldAutocompleteFuzzy = <T extends {}>({
  fuzzySearchOptions,
  ...props
}: Props<T>) => {
  const options: T[] = [...props.options];
  const { search } = useFuzzy<T>(options, {
    threshold: 0.2,
    ...fuzzySearchOptions,
  });

  return (
    <FieldAutocomplete
      {...props}
      filterOptions={(
        _options: T[],
        { inputValue }: { inputValue: string }
      ) => {
        const filteredResults = search(inputValue);

        // NOTE: This is needed to bridge the gap between fuse and MUI, if the
        // groupBy prop is used. Fuse reorders the search list
        if (props.groupBy) {
          return filteredResults.sort((a: T, b: T) =>
            // @ts-ignore - TS can't follow that this won't be undefined because of the .sort call
            props.groupBy(a).localeCompare(props.groupBy(b))
          );
        }

        return filteredResults;
      }}
    />
  );
};
