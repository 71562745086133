import { css } from '@emotion/react';
import { useProvider, useProviderPatient } from 'hooks';
import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { BodyText } from '@headway/helix/BodyText';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';

interface CommunicationDetailProps {
  clientId: number;
}

export const CommunicationDetail = ({ clientId }: CommunicationDetailProps) => {
  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });
  const patientName = formatPatientName(client, {
    firstNameOnly: true,
  });
  const isSelfPayPatient =
    providerPatient?.billingTypeDefault === BillingType.SELF_PAY;

  return (
    <div css={communicationDetailCss.container}>
      <PageSection css={communicationDetailCss.text}>
        <h2>
          <SectionHeader>Appointment Reminders</SectionHeader>
        </h2>
        <BodyText>
          The day before your session, {patientName} will receive an email
          appointment reminder with the date, time, and cost of the session.
        </BodyText>
      </PageSection>

      <PageSection css={communicationDetailCss.text}>
        <h2>
          <SectionHeader>Invoices</SectionHeader>
        </h2>
        <BodyText>
          We'll automatically create invoices for {patientName} after a
          confirmed session. {patientName} will receive invoices via email.
        </BodyText>
      </PageSection>

      {isSelfPayPatient && (
        <PageSection css={communicationDetailCss.text}>
          <h2>
            <SectionHeader>Superbills</SectionHeader>
          </h2>
          <BodyText>
            We'll automatically create a monthly superbill for {patientName} at
            the end of the month. This is important for clients who are seeing
            you through out-of-network (OON) benefits. {patientName} will
            receive superbills via email.
          </BodyText>
        </PageSection>
      )}
    </div>
  );
};

const communicationDetailCss = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  text: css({
    h2: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gap: theme.spacing.x3,
  }),
};
