import { css } from '@emotion/react';
import HelloSignSDK from 'hellosign-embedded';
import React, { useMemo } from 'react';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';

import { PayerQuestionnaireResponseApi } from '@headway/api/resources/PayerQuestionnaireResponseApi';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { usePayerQuestionnaire } from '@headway/shared/hooks/usePayerQuestionnaire';
import {
  getIsProviderCredentialedWithFrontEndCarrier,
  isMedicareAdvantage,
  MEDICARE_ADVANTAGE_PARAM,
} from '@headway/shared/utils/postIntakeInfoCollection';
import { logException } from '@headway/shared/utils/sentry';
import { LoadingWrapper } from '@headway/ui/LoadingWrapper';
import { PostIntakeInfoCollectionContent } from '@headway/ui/postIntakeInfoCollection/PostIntakeInfoCollectionContent';
import { notifyError } from '@headway/ui/utils/notify';

import { Header } from 'components/Header/Header';
import { useIntakeCompletionStatus } from 'hooks/useIntakeCompletionStatus';
import { useAuthStore } from 'stores/AuthStore';

export const PostIntakeInfoCollection = () => {
  const payerQuestionnaireResponseIdMatch = useMatch(
    '/payer-questionnaire/:id'
  );
  const payerQuestionnaireResponseId =
    payerQuestionnaireResponseIdMatch?.params.id;

  const {
    data: payerQuestionnaireData,
    isLoading: isLoadingPayerQuestionnaireData,
    isError: isErrorPayerQuestionnaireData,
  } = usePayerQuestionnaire({
    payerQuestionnaireResponseId,
    incompleteOnly: false,
  });
  const { payerQuestionnaire, frontEndCarrier } = payerQuestionnaireData || {};
  const { provider, user } = useAuthStore();
  const navigate = useNavigate();
  const isProviderCredentialed = getIsProviderCredentialedWithFrontEndCarrier(
    provider,
    payerQuestionnaire?.template.frontEndCarrierId
  );

  const {
    isIntakeIncomplete,
    isIntakeIncompleteLoading,
    isIntakeIncompleteError,
  } = useIntakeCompletionStatus();

  const frontEndCarrierName = frontEndCarrier?.name;

  const isError = useMemo(
    () => isErrorPayerQuestionnaireData || isIntakeIncompleteError,
    [isErrorPayerQuestionnaireData, isIntakeIncompleteError]
  );
  const isPageLoading = useMemo(
    () => isLoadingPayerQuestionnaireData || isIntakeIncompleteLoading,
    [isLoadingPayerQuestionnaireData, isIntakeIncompleteLoading]
  );

  if (isIntakeIncomplete) {
    return <Navigate replace to={`/credentials`} />;
  }

  if (isError && !isPageLoading) {
    return <Navigate replace to={`/`} />;
  }

  const formState = {
    userId: user.id,
    providerId: provider.id,
    name: `${provider.displayFirstName} ${provider.displayLastName}`,
    email: provider.email,
    helloSignEmbedded: HelloSignSDK,
    helloSignSdkClientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
  };

  if (!payerQuestionnaire || !frontEndCarrierName) return null; // TEMP

  const onSaveForLater = async (values: any) => {
    try {
      await PayerQuestionnaireResponseApi.updateProviderPayerQuestionnaireResponse(
        payerQuestionnaire.response.id,
        { response: values }
      );
      toasts.add('Your credential form has been saved');
      navigate(-1);
    } catch (error) {
      notifyError('There was a problem saving this form.');
      logException(error);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      await PayerQuestionnaireResponseApi.updateProviderPayerQuestionnaireResponse(
        payerQuestionnaire.response.id,
        { response: values }
      );
      await PayerQuestionnaireResponseApi.submitProviderPayerQuestionnaireResponse(
        payerQuestionnaire.response.id
      );

      toasts.add('Your form has been submitted');

      if (isProviderCredentialed) {
        navigate(-1);
      } else {
        navigate(
          `/payer-questionnaire/${
            payerQuestionnaire.response.id
          }/submit-success${
            isMedicareAdvantage(frontEndCarrierName)
              ? `?${MEDICARE_ADVANTAGE_PARAM}=true`
              : ''
          }`
        );
      }
    } catch (error) {
      notifyError('There was a problem submitting this form.');
      logException(error);
    }
  };

  return (
    <LoadingWrapper loading={isPageLoading}>
      {payerQuestionnaire && frontEndCarrierName && !isIntakeIncomplete && (
        <div css={layoutWrapperStyle}>
          <Header />
          <div
            css={{
              paddingTop: '50px',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              ...theme.reset,
            }}
          >
            <PostIntakeInfoCollectionContent
              isProviderCredentialed={isProviderCredentialed}
              frontEndCarrierName={frontEndCarrierName}
              payerQuestionnaire={payerQuestionnaire}
              formState={formState}
              onSaveForLater={onSaveForLater}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      )}
    </LoadingWrapper>
  );
};

const layoutWrapperStyle = css({
  height: '100vh',
  backgroundColor: theme.color.system.white,
});
