import { IroncladApi } from '@headway/api/resources/IroncladApi';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useQuery } from '@headway/shared/react-query';
import { logException } from '@headway/shared/utils/sentry';

import { useAuthStore } from 'stores/AuthStore';
import { hasRateAccess } from 'utils/access';

import { getSignerId } from '../utils/ironcladAgreement';

export const getQueryKeyAndSigner = (
  provider: any,
  user: any,
  shouldUseIroncladAmendments: boolean,
  includeAllActiveProviderLicenseStates?: boolean
) => {
  let signerId: string;
  const isGroupPractice = !!user?.group_practice;

  if (shouldUseIroncladAmendments && provider) {
    signerId = hasRateAccess(provider, user)
      ? getSignerId(user, isGroupPractice)
      : `group-${provider.groupPracticeId}`;
  } else signerId = user?.id as string;

  return {
    signerId,
    queryKey: [
      'ironcladAgreementInfo',
      signerId,
      provider?.id,
      provider?.activeProviderLicenseStates,
      includeAllActiveProviderLicenseStates,
    ],
  };
};

export const useIroncladAgreementInfo = (
  includeAllActiveProviderLicenseStates?: boolean
) => {
  const { user, provider } = useAuthStore();
  const shouldUseIroncladAgreements = useFlag('ironcladProviderAgreements');
  const shouldUseIroncladAmendments = useFlag('ironcladProviderAmendments');

  const { signerId, queryKey } = getQueryKeyAndSigner(
    provider,
    user,
    shouldUseIroncladAmendments,
    includeAllActiveProviderLicenseStates
  );

  const {
    isLoading,
    isError,
    data: ironcladAgreementInfo,
  } = useQuery(
    queryKey,
    async () => {
      if (!(signerId && provider?.id)) {
        return null;
      }
      // TEMP - hack to resolve race condition
      // will be resolved once we update mamba to pull amendment_date_of_notice from published_time
      await new Promise((resolve) => setTimeout(resolve, 500));
      return IroncladApi.getIroncladAgreementInfo({
        signer_id: signerId,
        provider_id: provider.id,
        include_all_active_provider_license_states:
          includeAllActiveProviderLicenseStates,
      });
    },
    {
      enabled: shouldUseIroncladAgreements,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (e) => {
        logException(e);
      },
    }
  );

  return {
    ironcladAgreementInfo,
    isLoading,
    isError,
  };
};
