import { useEffect, useState } from 'react';

import { isServer } from '../utils/isServer';

export type UseLocalStorageResult = [
  /**
   * The current value of the key in localStorage. If there is no item with this key, null will
   * be returned.
   */
  string | null,
  /**
   * Sets the value of the key in localStorage. Type is limited to string because any value passed
   * to localStorage.setItem is passed through String() first.
   */
  (newValue: string) => void,
  /** Removes the value from localStorage entirely.  */
  () => void,
];

/**
 * Hook for reading and writing a value in window.localStorage with semantics similar to useState.
 * The hook detects changes to the localStorage value triggered from other components or tabs
 * and triggers a rerender.
 * Caveats:
 * - The hook can only globally detect changes to a key if the key is entirely managed using
 *   useLocalStorage.
 * - The hook cannot detect changes from localStorage.clear()
 * @param key The key of the item in localStorage.
 */
export function useLocalStorage(key: string): UseLocalStorageResult {
  const [value, setValueInState] = useState(
    isServer ? null : window.localStorage.getItem(key)
  );

  const setValueInStorage = (newValue: string) => {
    if (!isServer) {
      window.localStorage.setItem(key, newValue);
    }
  };

  const setValue = (newValue: string) => {
    setValueInState(newValue);
    setValueInStorage(newValue);
    if (!isServer) {
      window.dispatchEvent(new CustomEvent('useLocalStorageEvent'));
    }
  };

  const removeValue = () => {
    if (!isServer) {
      window.localStorage.removeItem(key);
    }
    setValueInState(null);
    if (!isServer) {
      window.dispatchEvent(new CustomEvent('useLocalStorageEvent'));
    }
  };

  useEffect(() => {
    if (isServer) {
      return;
    }

    const listener = () => {
      const storageValue = window.localStorage.getItem(key);
      if (storageValue !== value) {
        setValueInState(storageValue);
      }
    };
    // The 'storage' event only fires upon updates initiated by other tabs, not this tab. So we
    // use a custom event to track same-tab updates across instances of the hook.
    window.addEventListener('storage', listener);
    window.addEventListener(USE_LOCAL_STORAGE_CUSTOM_EVENT_KEY, listener);

    return () => {
      window.removeEventListener('storage', listener);
      window.removeEventListener(USE_LOCAL_STORAGE_CUSTOM_EVENT_KEY, listener);
    };
  }, []);

  return [value, setValue, removeValue];
}

const USE_LOCAL_STORAGE_CUSTOM_EVENT_KEY = 'useLocalStorageEvent';
