import { UserRead } from '@headway/api/models/UserRead';
import { PatientAddressApi } from '@headway/api/resources/PatientAddressApi';
import { createBasicApiHooks } from '@headway/shared/hooks/utils';

export interface UseActivePatientAddressQueryKeyArgs {
  patientUser: UserRead | undefined;
}

export const getUseActivePatientAddressQueryKey = ({
  patientUser,
}: UseActivePatientAddressQueryKeyArgs): [string, number | undefined] => [
  'patient-address',
  patientUser?.activePatientAddressId,
];

const {
  useSingleQuery: useActivePatientAddress,
  useListQuery: useActivePatientAddressList,
} = createBasicApiHooks(
  getUseActivePatientAddressQueryKey,
  async ({ patientUser }: UseActivePatientAddressQueryKeyArgs) => {
    return PatientAddressApi.getPatientAddress(
      patientUser?.activePatientAddressId!
    );
  },
  ({ patientUser }) => !!patientUser?.activePatientAddressId,
  () => 'Failed to fetch patient address'
);
export { useActivePatientAddress, useActivePatientAddressList };
