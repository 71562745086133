export const id_1_name_FreeText_v1 = {
  templateInfo: { id: 1, name: 'Free Text', version: 1 },
  template: [
    {
      id: 'a4980795-336d-4dfe-9549-76b05a7418dc',
      type: 'richFreeText',
      title: 'Note Content',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: false,
      },
    },
  ],
};
export const id_6_name_IntakePrescriber_v1 = {
  templateInfo: { id: 6, name: 'Intake - Prescriber', version: 1 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '5a86120d-3835-4ab3-9fd1-a7cdf29c9483',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f5864b91-56da-4186-9a09-84dedd052cf1',
          type: 'longFreeText',
          title: 'History of Present Illness',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '720d7861-e165-4b1a-8319-d3e91ee206e3',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7f0d1f4-5651-4b2a-9012-8312cde12714',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f66d8036-e3c7-4a7f-aa0c-98f59858bcb4',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b398bc48-98ec-4269-895f-604a5672dd77',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fd5982c5-0733-4c3f-b729-3b964bd7ed69',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e14ec5-5050-4f95-9cc5-5feee162c298',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e8852baa-018e-4c82-b4a3-c3cdf3260466',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: '6d3dd53a-97ec-47fe-98d4-8e70c8a8292c',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bda78f35-0f09-429d-8e00-4dad9d6f8cec',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bb662889-3409-422f-a4b5-9bb4f851cfd9',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b502f76b-0a51-475c-8b47-5d7ee7b91f43',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '22b56bb1-ae39-4ccf-aab4-e7e1f14c7fbb',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c0fd9bc1-40fe-4f0e-80b7-0cf03af1fd2e',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fdd6ffe9-cfa9-4844-b332-1db520acc5da',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '66089e8c-fb93-42d2-9c94-047438cfc42e',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b0e07927-f12e-402f-8d9a-97c94947640b',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '04b1d4cb-f2ae-48c4-b9f1-9b3b004c11d4',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4c5f4085-d9b4-4a6b-8c93-13a0cb778023',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3c03f765-33b0-4ad8-8e88-12c1966890f8',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e791231-0fc3-4e9e-aee0-994f955a0655',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eba460d8-0a04-4f9b-9bf2-e4a1e5030c2e',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '52dc7af8-bbc0-4c50-a956-6e2d985ae659',
          type: 'longFreeText',
          title: 'Past Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5300dacb-d713-4eca-851a-ebff9dca5a0b',
          type: 'longFreeText',
          title: 'Past Medical History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'aad8f80f-0c0c-4fb9-92f1-15dc3bad0fc1',
          type: 'longFreeText',
          title: 'Allergies',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1b5f3f7a-4bb5-4ca4-8a8b-66e3c45fb694',
          type: 'longFreeText',
          title: 'Current Medications',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c625a52c-ed5f-4f99-b32f-406e205bdcf5',
          type: 'longFreeText',
          title: 'Medication Trial History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2bf84e01-00a4-49ae-8cc5-7a2774a875a5',
          type: 'longFreeText',
          title: 'Family Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a5db48df-17b9-4bc2-9447-4c131c4cd9eb',
          type: 'longFreeText',
          title: 'Substance Use History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '14036bca-9d53-42a1-95c2-f568cd5cda44',
          type: 'longFreeText',
          title: 'Social History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '0ee11bd7-33c9-409c-91b2-dfaa20dbb451',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c2c7516c-0ca8-4c44-a00c-7f963eaf6f88',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f25295bb-a239-4c69-bc8b-17eaa2573b61',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '70ab8ecb-3401-42d1-b96a-f5443c8de8b1',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7fa7f646-7dbe-4d47-8394-6ca4b7bcf3dd',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fe153640-9090-43f6-a3d1-83f59c3228b7',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8a4d0361-648d-421e-b84b-b6b8d5c85c71',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd5d015a9-b345-40ed-aa65-81dbfaf7b35a',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '88cba2fc-41c6-48d0-8dd6-5f43ab968923',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '95940939-b3ec-4d07-9702-99de798239de',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a2db4706-6afe-4f9e-8605-070f0df9a84a',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '55f4fef6-1ec9-47c3-a887-a95158e5b84d',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5a199c96-b811-43e2-bc47-0ff9a04d5952',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8608c5a4-fdd7-498b-bbee-bdb10eca2377',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '9f96e980-9daf-47cc-9355-2ea02fbf9361',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: 'ea74886c-94c4-48c4-bd21-38a00269e19c',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7f5f94d2-9864-49f8-a784-6c7b46ccb2d0',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e8431534-92ec-401e-ab50-bf9ec9944ef4',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: 'c3b0ab02-3fdf-4fd4-88ab-ae3c5ffd166b',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8d16bc4b-5b8e-45ba-8b79-2c0a08d46639',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '41ad40c1-0162-4a62-a324-35b2616e3d2b',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ceb8528c-4fbc-4d5f-8ba1-dd76663656c3',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b0b18c5b-1255-4008-a5c7-d278db21425f',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fb03ebfc-6864-4957-bca7-78e9dfa2bc33',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e0a0e05e-94d6-42e1-ab47-75c543fd45af',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1e335f8c-e57a-41c2-8f20-56b7cd8084db',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '73fcda39-b063-4236-b762-33b4d67f1cce',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5c419a58-420f-43d7-95fa-a911549f3ece',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '055a0dfe-36c4-47e6-a148-3ab112c63e1f',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ffa4440d-6b80-4b86-97d6-46ecdb8c3f08',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '277aa03d-5e58-4ac6-8070-c86e7567dc3d',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '031398cb-6bd6-4823-93d8-6265ca3dab99',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cc56c644-c446-4129-a7d1-28691bdac8c0',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '20090fa6-89dd-4f8a-8be0-a931d4a85221',
          type: 'checklist',
          title: 'Release of Information',
          options: [
            'Client asked to sign release for communication with other providers',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_7_name_FollowUpPrescriber_v1 = {
  templateInfo: { id: 7, name: 'Follow up - Prescriber', version: 1 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '8c5aefd3-3a3a-4492-8c6d-250230451b16',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e20da193-7c39-4d7e-9c69-fe65dd083ff2',
          type: 'longFreeText',
          title: 'Interval History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: 'fe6d0f61-96f5-4701-aae5-f88604eedf42',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e643b84-56ba-4e33-adda-e0708986e33b',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b1d3f1b-a999-443b-83a7-7d2c6fe12279',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '73108dcc-c5de-4486-8765-862220e4d8b1',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '55aeef37-5216-4295-9459-d0013229bbe8',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0cf3d777-a0bd-44be-b9a7-79c0b46a84f7',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0fa15564-0757-4994-a405-ca81f63382cf',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: 'c089fc44-4b61-4aba-86aa-1e69113e0d58',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '73cb6ab4-d065-43ea-bbe0-cc779ac7719c',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '42f0e4af-808d-484f-8677-87ea48e036e9',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '84ffef13-fcb7-49af-8c7d-2bf4c565e8e3',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c057ba25-00d6-4759-919e-de9d98025d9e',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '51ec0c6a-806d-4d46-b3dd-7c026fe5efe9',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15a0725c-30ab-4ca0-b046-c277eb855cd8',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e5c4aef7-e74a-40a8-b5d5-5a47353532cb',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b95178a4-8a4c-48ab-b769-a7a11eab55cb',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b49627a-09c6-4293-8bd7-0445bb5f29be',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ceacf5d7-8745-40c3-8d60-4f7ed881300f',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '898beb31-573e-4b49-a179-ea7b6b283e54',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c9bbe7b4-ad29-42dc-ba52-896f6339a004',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4bba3259-b061-428a-aa27-cf58c87989c9',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '14c757fe-1290-4580-872e-59d3c45e06b6',
          type: 'longFreeText',
          title: 'Pertinent History (Family, Social, Medical)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '12c4195b-5e58-4ff1-813d-966c6d74bab3',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1d920cdc-4272-4934-99b2-0e35ae2a4a7e',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4a3c2dc8-e3da-4d8e-ba08-0a433f283541',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '270978c0-d868-4461-ab2b-c6af5662c5ed',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '63a3347a-1573-454c-a002-82c64f5b42d0',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '56177d1c-2e16-4314-a9cc-43c3d117422c',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e57d1e45-cba3-421c-8228-7e8f609e795e',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fe4d0d04-0495-40b6-862c-d2a1b344fbf6',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '31743e27-72fc-42b4-b43f-0e4a93b5ac3c',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c7df01c7-afda-48ba-a7df-3212214cb510',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '76906670-fce4-4176-9d33-d19cbf9735ee',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7ac24bf4-00ea-46a4-a5fc-743203a2a767',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b64a02f-aaf8-4e70-8bda-d18a989199cc',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bad8d7b2-0e5f-474e-908c-d72e0c984828',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '9e2df398-cf85-4000-8cf0-bf00d732124c',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '27cb4e1e-7412-4f48-a964-c3f79d3b8813',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '21aea9bc-6e36-423c-8e67-0baab68beed6',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6453c2a3-010b-4593-a1ed-dc56ea05c619',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: 'e85d4ab9-6837-41b1-a2a0-d5a1f4fa10a2',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1b7235bb-19cb-4efd-9b53-ae3f28ceb4f9',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8360f94b-589f-4b7b-a124-ff527a6a3bc1',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '23969908-bbff-4c18-af9a-7495d87de5c9',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8d4cdcef-a9de-4b47-b224-fd3961332c52',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0750d93b-c9ff-4001-b6e4-8adbfd016e7c',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9d8bb944-388b-4437-aca8-7e0ca244c325',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c8a6cfac-449c-42c6-8e6e-074ef7b4b8c0',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '59152778-81d5-4cce-9d40-3257a4d6224e',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e3908ff7-c9aa-4091-808d-bf17d1867aca',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '73d722d0-dded-4cd0-9ffd-d632b1b2443f',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f17f188c-41cc-49f9-9397-b780efd7b7c9',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'a80e27e3-27f9-4938-8edb-04c7750890a6',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '499ee24c-78e6-4359-b68d-f479b442163e',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '850c11d2-2d8f-4e43-b6b1-cdc2887ffda8',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '90606e16-5516-4045-9eb9-97a47d74c6ac',
          type: 'checklist',
          title: 'Release of Information',
          options: [
            'Client asked to sign release for communication with other providers',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_8_name_IntakeTherapyOnly_v1 = {
  templateInfo: { id: 8, name: 'Intake - Therapy Only', version: 1 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '4e742a6e-961a-4044-90c1-eceb7e6d747e',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bd624870-dd08-4299-8d22-044b00d5e8ec',
          type: 'longFreeText',
          title: 'History of Present Illness',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: 'eb84a978-d150-4cbe-9fc1-4b7798c06863',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d5127f-05d5-4a74-8bf8-fdca9ea00f53',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '423d2b20-ace5-43ef-a8f5-c10253a32265',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '92cd4de3-e07d-46a7-b18e-01decce6ddc0',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8110f1c-c69c-4ecd-832d-d336559f2890',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '06c16de2-a5da-4d13-bcd8-364ed27fb045',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1f264b97-52db-41eb-a3e4-909b6c8911a2',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '135bc63b-118c-4221-bb18-a9b49a06d092',
          type: 'longFreeText',
          title: 'Past Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e0a63332-473c-47d2-a7ae-588e74c6753e',
          type: 'longFreeText',
          title: 'Past Medical History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da85a4e3-6149-4453-bcd6-4614d977ff19',
          type: 'longFreeText',
          title: 'Allergies',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9a71a9c3-674c-464d-8084-66f03d8bc5ea',
          type: 'longFreeText',
          title: 'Current Medications',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c599ddc9-ed6a-48fa-8566-0e4aa9a0bee7',
          type: 'longFreeText',
          title: 'Family Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fe27a1da-3fe0-4975-8b13-030607be9f9c',
          type: 'longFreeText',
          title: 'Substance Use History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd7f789bf-7ef1-43b3-a88e-4661e3ab59b8',
          type: 'longFreeText',
          title: 'Social History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '5217b901-8df3-425a-aba5-b8fb9a93cfee',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '76171c57-84d1-4477-8959-ef132fd81eb7',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ab522b36-4d06-4d52-b421-15193c331396',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f3cc44e6-0953-43b2-b3dc-da1a43f29c15',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ea79ebb1-381b-442a-8e04-a5ea3efb2e76',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd24aef20-bbe1-448c-ab34-c2d392613b49',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '76161d06-c464-486b-b629-7b5ee3309041',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7f3c3439-a9a1-4b25-88bc-6ed04e85017c',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '60b10d93-987a-486e-b8ac-e9c5413208f2',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bd3ee421-abc0-4c7b-b637-79be779b6ca0',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5fbe745f-598f-4b4c-924f-b015c6683a08',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7a00eb1-5ca6-4fac-b233-25e548a5eb0f',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '88b6e5a8-67b4-4231-a291-043adb57a7c3',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5d12f246-aabf-4ee0-878f-24f013e4d5da',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '73c25576-15d7-4ef7-9ae1-cc5392dc09bb',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '872f9cbf-bba7-45ae-a55d-4a37750d6759',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '561ca58d-d755-4215-a2d3-01e3d68ffa09',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '603f73b0-9496-4175-abdd-ee9bfc5741cb',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8a563226-3770-461d-9a1e-24ed29d08bb8',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd37345b1-dad0-4ac7-a0a3-e00c5b9770bb',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b6ad0f3-45ce-4434-884a-d8903a89cf36',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da75a0e4-bac9-488c-854c-ade3fc97b71c',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '51e42aab-990d-4b12-8fe3-47bfcdac78eb',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '20a4e580-54e9-42a2-af16-f13d7479a714',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '66418b76-14ee-4b24-a311-2274a577dec1',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7ae0e112-5eb4-41c4-818f-0b9ff96fcab0',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b91e64cf-4b21-4cb4-9cba-0c21f8ed3032',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '8b39aff9-68a5-4cf6-891c-8727eeaa7dc5',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2bb902b4-ffbc-4809-abf6-c46237e8f141',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7145e1cc-10a7-4c75-8dfb-a01d65639e3e',
          type: 'checklist',
          title: 'Modality(s) of Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b9e92c3d-974c-424a-82af-1a7348edb9a7',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3dad99b0-b579-4b93-a048-8cefc80b1ec1',
          type: 'checklist',
          title: 'Release of Information',
          options: [
            'Client asked to sign release for communication with other providers',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_9_name_FollowUpTherapySOAP_v1 = {
  templateInfo: { id: 9, name: 'Follow up - Therapy SOAP', version: 1 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '8772c890-7e25-49fd-a5f6-0eeb68901d91',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Subjective',
      components: [
        {
          id: '2febdfbd-2897-4fcb-ae41-856b4dc00add',
          type: 'longFreeText',
          title: 'Subjective',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective: Mental Status Exam',
      components: [
        {
          id: 'e29098bd-3371-4193-a9fb-d2f9fac01097',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1d474f70-636b-4474-b552-19f38fefce80',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9c392eda-02e4-4e75-ab33-7a527646ffbb',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a04a24af-5412-41ed-b741-21498d89c0f0',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a91577af-83e1-40f3-8489-d77c269d7e38',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b36db901-b9f0-4203-afdd-304267cb95e6',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1acaf946-e4b9-46d0-8460-dcb1441cd09b',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '959d6570-4692-42af-b068-398f6cbc54fb',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e4297fa9-24d4-404e-bccb-725a1e06cd62',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '94482348-92e4-4cf8-b34a-4625ba73b9b3',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2b2b7ba4-c773-458f-b43e-680823e0cc0b',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f4e85b21-531f-4888-b028-e570822e1551',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '13831b49-950f-4d56-94c5-e587f30db423',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd3a53226-e6f2-4eeb-91f7-2c271b248be0',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective: Test Results',
      components: [
        {
          id: '909f2390-68b3-4585-adac-c7efdd31eebb',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment: Risk Assessment',
      components: [
        {
          id: '205f43aa-a5a7-4a32-b6fe-03eb8af1c805',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '805ea21e-a861-49bf-93d0-f4fdd8eff85c',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '802c3fc6-8413-407f-a8f6-2be888aa2333',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bc71c8a2-6a56-4d16-8953-1187416e6609',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7798694-443f-4c27-a272-6423e16414ee',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '02d5b638-f6a2-4b44-8a3e-8f862bebfaf0',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'acd1f125-dca8-4c34-aab0-cff06d2b14af',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a8b59cda-bc49-41ca-b0cd-05301a9e831e',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '84c8c803-0118-4452-b8ce-12b45d7f0f37',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '58dac15a-6c14-4cdb-9beb-adf21bcc1029',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e52b38b3-8554-491e-900d-3c75072f95b0',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ae642307-0de7-4ed8-8068-32b9b9e15bea',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'cf9cf4d3-ced6-4cdc-9852-5126c00fd18e',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8a4779a0-4e09-4186-815c-5f6f9f71104a',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ed7ae759-0311-4523-a72c-944c0b6add77',
          type: 'checklist',
          title: 'Modality(s) of Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a5e1c43c-17ab-4403-825f-d63ac196b1bc',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '212a40c7-7a0b-4e0e-a844-7de3618a30a1',
          type: 'checklist',
          title: 'Release of Information',
          options: [
            'Client asked to sign release for communication with other providers',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_10_name_FollowUpEMTherapyAddOn_v1 = {
  templateInfo: {
    id: 10,
    name: 'Follow up - EM with Therapy Add-On',
    version: 1,
  },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '6b4e40de-fc80-4ad4-98e1-fa2ee4e80146',
          type: 'shortFreeText',
          title: 'E&M (in minutes)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e2ec59eb-4c35-4144-b39d-24fda83ca3db',
          type: 'shortFreeText',
          title: 'Psychotherapy (in minutes) ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '073f8670-f0a8-4253-8a3b-b56a91e50dda',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4a7aa994-7f17-483f-a31f-104ceb03d9d2',
          type: 'longFreeText',
          title: 'Interval History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: 'cbac1c41-3d61-4d72-ad9a-32c6f5f6e70f',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a80a6019-770b-4bea-9267-a26693fcd695',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '77ae2c4f-8737-4327-95c5-d813dc3ceff9',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '000c23d0-9384-48f1-a80a-b06df7dc934f',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd17be067-77bd-4f86-a38c-e98383a417ce',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b815d5ea-cb63-4997-9a2b-12693efb27c1',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3385072e-9277-411a-a6a3-abb6fb12a483',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Modality',
      components: [
        {
          id: 'f4a19cae-aaa5-4c59-914f-8270712da987',
          type: 'checklist',
          title: 'Modality(s) of Therapy Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5c616320-67ef-42fd-88c8-d6279751eea4',
          type: 'longFreeText',
          title: 'Discussion/Focus of Therapy',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: 'a70eb611-e398-4b95-a17b-96b7c08c24c6',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '04b0645e-8a14-4aae-8a19-51ad3237d0c2',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'dd861822-3f7a-406a-9a95-6cda150fd3c8',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f2052f38-8a9f-4711-910f-98647ca24b92',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b63854ca-ab46-494b-b226-a4ae4b0f00d2',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7c2268f-1a0e-4596-91ad-1fb481e076ad',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b9c154e1-cd29-4088-b409-9ce10dadc21f',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8a684c12-c9f5-4028-adb0-fca2885311d5',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '11d0a512-2e5b-42de-846f-3f7cd0ba9998',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0560288c-c444-4bcd-89e4-723854a45f2b',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3c6ff9c8-a089-4c02-91fa-1bcd5ac3e0cb',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bbd864dc-1592-47fd-9047-c7055b4fa458',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4d2abc07-e01d-4b81-b545-6fc81a161968',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e5046d32-22c4-4f81-a96d-a9b6cf7d8f4b',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: 'bb8d929a-fa04-4e94-8469-30bd987d6a06',
          type: 'longFreeText',
          title: 'Pertinent History (Family, Social, Medical)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '64d1eebd-ce6e-4988-9065-0a4c7e813ba8',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a01c189c-5649-4f91-805c-aa787e87f021',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e3b6bf4c-10e5-4421-9eca-b82ffff81f7b',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6e49c723-27ef-40f9-ace9-5c4cce8a4cfa',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '629714ae-e8d8-4b4f-bc7c-ba38fd9677ea',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '78fa6afc-e7a4-43dc-b3cd-e4c498ca9bcb',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5edb7245-bc17-499a-bee3-4213a149e7e7',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '03fcce53-a1a9-46e8-b149-11c27a0bc28e',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd3998c86-e104-4180-a795-af9157a6bdbc',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '75ce2263-acf4-48a6-b109-619e257b132a',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3f6e7130-e1bb-459c-a696-5c245553a5e2',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ed7bfae8-233e-4440-8910-ce9b6848a006',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5f937b2a-512d-43be-8bd9-f3b242e7b5ba',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f481299a-5b26-47a9-a083-d4f9cd0486c1',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: 'a58850b0-606c-4032-83ca-24bd12323773',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '9c7d7e61-4903-4857-a0f1-97a3af971424',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2847d7b7-6142-4759-a27a-19a31ffdf5c5',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2247dc20-a7be-4edb-b411-1857e31a7346',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '942c581a-ec69-469e-8a92-cfc8b003b1b1',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5083f992-07cc-4516-bee8-e80c9e7be9f6',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b8fde8df-4a8b-492e-8720-2a6386e67d7b',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c555f8b8-aeb6-4cd5-84d5-c0443e5aa481',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2d418f21-0305-4b92-b425-7a30684fcdaf',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '93665143-ff23-41d1-bc38-e4366cb0ae1e',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a804e0f6-2639-4885-868a-b59f5ba07e20',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7acaba33-b242-4810-976a-343f9086b415',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '52e9f144-1045-4e80-a986-c61d33acd4b9',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ca9aa5dd-cb0d-4862-904e-1e222493462f',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '940ed99b-540b-4bee-bb05-1b59cbac253c',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7d79fc74-b6bb-4888-87f4-4b2ce6da2502',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'f29e182a-e7c2-4261-87a8-c00841d0448d',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8470639b-c2ef-4fb1-95b6-872ac8861c87',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cfe1f17f-8af3-4032-8da0-de6e5e052747',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fd2c5742-4f96-4571-b2fe-56ae27e91a60',
          type: 'checklist',
          title: 'Release of Information',
          options: [
            'Client asked to sign release for communication with other providers',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_11_name_IntakeProgressFreeTextSOAP_v1 = {
  templateInfo: {
    id: 11,
    name: 'Intake / Progress - Free Text SOAP',
    version: 1,
  },
  template: [
    {
      header: 'Subjective',
      components: [
        {
          id: 'e7ac26bc-0409-482b-8afd-7de04d170c62',
          type: 'richFreeText',
          title:
            'For therapy: discussion of problems, how session adressed treatment goals / objectives.\nFor prescribers: history of present illness / interval history, psychiatric review of systems, medical review of systems ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective',
      components: [
        {
          id: '37ab3f36-20f6-45dc-a03d-be89f09b9301',
          type: 'richFreeText',
          title:
            'Mental Status Exam, Vital Signs, Physical Exam, Relevant Labs / Studies, Patient Questionnaires',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '54ea7d1e-7fbd-4626-b11c-a9ba81303ff1',
          type: 'richFreeText',
          title:
            'Past psychiatric history, medical history, allergies, medications, family and social history',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '88e27680-c067-4241-9498-fb477ee21ad4',
          type: 'richFreeText',
          title: 'Formulation, Risk Assessment, Statement of medical necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Plan',
      components: [
        {
          id: '117e997f-e6dd-4613-ba76-abdbc4d8d8d5',
          type: 'richFreeText',
          title: 'For therapy: include modality of treatment',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b425bbf5-ebb5-421b-ac3a-e6f438befa57',
          type: 'checklist',
          title: 'Release of Information',
          options: [
            'Client asked to sign release for communication with other providers',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_13_name_FreeText_v2 = {
  templateInfo: { id: 13, name: 'Free Text', version: 2 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '0c00b298-5c45-4ec3-b3b3-b287d5e43611',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e9064737-398a-47f0-bcd1-0eb155aaf195',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      id: 'c29b6f5b-952a-44c8-b548-48ebf82198fd',
      type: 'richFreeText',
      title: 'Note Content',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: false,
      },
    },
  ],
};
export const id_14_name_IntakePrescriber_v2 = {
  templateInfo: { id: 14, name: 'Intake - Prescriber', version: 2 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '6a973298-9b6b-4cff-b06d-8c5a0125495a',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1bdae7ac-bba9-43f2-8d5a-6278386e0bda',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'cb1060f6-2ed9-4dc1-af19-b5803152b74d',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ca633a04-c5aa-414d-9992-08e563706ee7',
          type: 'longFreeText',
          title: 'History of Present Illness',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '96979057-9984-4761-b41a-d06e69a20303',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b550646-f061-4f8d-881c-e20d08d6199d',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '86469c0c-c581-4a50-8d97-da240dd01830',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4c69c3b3-6ccf-4516-b426-4539d7282531',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '498ed35c-e1c1-4eb9-8982-12c6ff057a84',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cc955684-9b13-4f59-a298-12debcf9f181',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '887af2ef-8ac9-411c-8a2c-69d5d471b016',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: '9fa00129-8b53-433c-b153-136f0b405a2b',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1aa20507-7bdb-41e8-80d5-d1c1bce825a1',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '16cadb8a-7adf-42eb-ae79-f31bef75ade4',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1d2e0148-7da9-46e0-b7c2-8659772f79be',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fb9e7a28-ed45-4cf5-90c3-fefd4e821c84',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8af6cb2c-a6cf-4d80-baea-dbe118f0ba8d',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cd964757-f5da-4a48-a9f4-7e534ae15e3f',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ec9a196f-74b4-41fc-a6dd-231a9ac1648b',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '02f4034b-da06-41ae-bdcb-2f2e41f3f22e',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0113cb4d-c305-44f0-bd31-b883a2e32d12',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1cbf4242-6f7f-4454-82c6-476d0f51c1e0',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '369053e5-4f45-44f2-8149-c3e9c12ec516',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '565fa525-866d-4102-a46a-0704542d7303',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f7072426-c7e3-482c-b773-86bf60e3613c',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '4d112d1b-1c51-42e9-ab9e-c34f8a4782f3',
          type: 'longFreeText',
          title: 'Past Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '370ae581-1976-4fe8-9330-698055470262',
          type: 'longFreeText',
          title: 'Past Medical History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8351766-c21e-4812-9363-8129b9018965',
          type: 'longFreeText',
          title: 'Allergies',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '028d54a6-b047-4f1c-8919-9b7622429b01',
          type: 'longFreeText',
          title: 'Current Medications',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd871f6d2-b69a-4f2d-9ec2-2b24f2351d24',
          type: 'longFreeText',
          title: 'Medication Trial History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '42114910-2dc3-4741-a727-1edf8fe039ac',
          type: 'longFreeText',
          title: 'Family Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '46f5689c-35c3-450b-af75-2d9e38ce07dd',
          type: 'longFreeText',
          title: 'Substance Use History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb476429-27c6-46a6-b357-873595f40b6a',
          type: 'longFreeText',
          title: 'Social History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: 'f4b7390d-3207-4164-9b23-89190406850a',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '74ed0b6e-7cb1-44dd-9213-512f1fd54f51',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '85972cfe-f5fd-43be-9649-701a5ca34780',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '39822b2b-4e3c-4861-a2b8-2792a3704686',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8689a535-21df-46a1-99e8-484c55a7112d',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '23526d55-7e3e-4d2c-8480-e06fb117d278',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8ba0d2b5-5c09-4ded-b2f6-f84668e2e26d',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3ebe810f-6528-458e-8c60-1b73545c5beb',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f3214af2-33c7-4124-a4bb-71bf36e5afdf',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f1f1d864-0e3d-4b21-9a7e-715546d4365f',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6e8b34e7-9ac4-4d83-901a-688a6383073b',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '568c6db8-9bf6-430d-baca-15a94f8abfb1',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '47f7df3e-675f-4be8-98b9-e25135e2ff85',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'abff5ca9-5d8a-405e-a3dd-21022326776f',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: 'c03c261c-5853-47fc-8220-bce6d3839b9c',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '3ad7ae26-87c6-4101-8518-27669d3c32e7',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6ccdf4b3-f26a-4c93-a873-ece9215e2f8e',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '13ee4063-fe97-4261-bedb-72e22c6d0bb8',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '53ef41cb-d5fb-4212-819b-739c607eb9a6',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4dd5e18f-2d79-447d-8ca5-40170b48609a',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bb331028-7cb9-4606-b859-f9cf1c0df420',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e26e96ac-1ff3-4604-8bf1-6a33a4b6e145',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '843ed9a6-09dd-47e4-8fdd-06f26f1c046a',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c96ce87a-568d-492f-9206-db9ea19b8767',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bb956af6-e290-47c4-b50b-d0f3c7658f1a',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7fb921a9-7735-4467-82bd-0bbfbd2e8c0a',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '62991952-603a-420a-95bd-fd06b60adf2e',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '07d7f842-ab1b-419b-ba90-48971d89223f',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '74174015-467a-4056-bb82-728fcdbed5d5',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e2a9e38b-512f-4746-b82a-4208ef306a38',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '55c3b45c-fc72-42d2-b93d-fd9e98945dcb',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6d62edce-e58c-4129-9f6e-2adaf8c576f2',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bf4a1375-50e6-408c-a1ed-b351c625da59',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_15_name_FollowUpPrescriber_v2 = {
  templateInfo: { id: 15, name: 'Follow up - Prescriber', version: 2 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '8d325fba-b04d-4c67-95ea-6b6efed3f405',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd9a089e5-38a9-4726-b66e-fc9ff9983cbe',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'ca5f9a00-28a8-4fe1-9e54-c93baae685b7',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f79b9934-319b-4a59-8008-651028919ddb',
          type: 'longFreeText',
          title: 'Interval History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: 'eb5a87b1-ef77-4a76-a088-0abd0d707a89',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '546110f8-9a2d-4899-bae1-85f6e945ab59',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '43e0a524-907d-412b-8841-a448fe41a36d',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f04236c8-fd20-405f-b16b-3e3ac9149c29',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3cdebe06-3047-424a-b606-06b8410aef15',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5a31efdc-140e-443a-8a19-c3ed6dd84b96',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '59c680b8-519e-42c9-8e63-aa77a468eba7',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: 'a92dff59-b793-429a-9e72-f827a9a7c9f9',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67abe4d6-84af-4027-9991-01a72fcf4d58',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '33725e84-e759-41c3-b007-5a374134c99a',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '97938722-1d9b-4ded-b76b-ebed6d63b220',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '16c20d2e-c620-4eef-9f4a-61374e5a6d7e',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '58f3a15b-f31b-4b2d-8f11-475585b44346',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '141cedc9-e374-4674-bc24-aa9a737a9583',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '45dcedc4-af3e-43fd-bc92-b3afbe7161df',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f421348d-346f-409d-bfd5-946e6c174e64',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '24b12790-dcf0-47cb-af52-61ed6a21ef24',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e41f742f-5558-4268-a5cd-3fa268dbcc42',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4a2d38a2-310c-4739-9cb7-bbbc07a0ea7d',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a9b4b32d-4155-4717-ba3c-201f90c9d7b3',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a9329e06-42b3-46c4-9680-6b6b2001b751',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '74e4a3af-545d-41f7-a6dc-647bd6b0697d',
          type: 'longFreeText',
          title: 'Pertinent History (Family, Social, Medical)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: 'e52587ba-95da-4b4e-a3e1-6fee1da72965',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b86a82d4-15fe-4bbc-9aa7-01e3c26ea377',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '88c9bfc7-fd1c-4107-b263-d77687ba0810',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '690caa54-bce7-406b-895f-adbad43b6e59',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5430e01e-f296-466f-841d-ad603edc8ecc',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '43ffb117-d116-42b4-9280-8e4f3907ec70',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a3fc8c3a-fd2c-450b-a598-265d6ee8638f',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c152033e-0132-499b-b352-a4622aee49f6',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd04aa545-7f81-47d5-ad43-16d63718c241',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6cca29d6-d233-4adb-b127-0f9160c268d0',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fcf980e2-55d9-4090-aac0-62e2af18814f',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ecbffaa5-dc9d-4628-bb5e-a59a6dae9a5b',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '96b55402-f19b-4d2b-888c-cd47b42aec14',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '680d4c74-c460-41de-bea7-8ef2333758c0',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '607e2f12-982c-44eb-ab09-d01a5ece9627',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '9a03a529-df67-4875-aa87-29ecddd779fb',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '33184e05-6e75-472b-aeef-68a2d26ef0d8',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '32caf1ba-69d3-4276-91ae-74298abd08c5',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '210fbcce-42a4-4611-9bef-b8918a252600',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c801ddc2-3c17-4e4a-9343-6cf4335097ee',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b58a112c-7a09-4602-a7ba-4106815387b4',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9482ae30-ad12-4563-b779-e689c7227d78',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'baca6298-6414-4fd4-aa98-b2230629b8a2',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2ab3ea35-af37-4951-9aff-8c1e0ea1a60b',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e205904a-752b-45a6-a5fd-aa144920de4a',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ab098b05-ef5b-43cc-85f5-d9b9016e9ee9',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0acfb2a7-bfa3-4e16-bb56-76b2bdaa465f',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15346044-f8af-4197-a8a3-513e2d503e56',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd59d0d0c-2cf8-40e8-acd0-759ed7814afc',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f03c4830-7591-4a78-8fa9-1542162c1c74',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'e55602f6-1110-4add-966a-e5304819e7a6',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7762f7da-4a74-4f91-b03a-5b284366c704',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e8d989eb-7fdc-45df-8174-2f357df58870',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_16_name_IntakeTherapyOnly_v2 = {
  templateInfo: { id: 16, name: 'Intake - Therapy Only', version: 2 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '6b378e09-edd3-488d-8882-eb9753ad4cc5',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd71e7d61-fa9e-470b-b125-13e4b506f406',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'a6e2b0d8-0385-4c8a-a1fa-23a83c9ac1c0',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'dc4e0e65-1b20-4ea6-bca1-f7abbc6c897b',
          type: 'longFreeText',
          title: 'History of Present Illness',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '6e3e8a34-e6a3-4fa1-a7cc-e5adf97c4670',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a0d7c453-643a-466a-89c2-b28907066653',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e984c808-9fc7-4bfb-aae9-91b817d7d65a',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1626892b-6fdb-4b84-a5ec-4923e961d95a',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a1ecd40f-8c26-4012-a3ff-3df0f1e18902',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'dab9c271-5d67-408b-b0b4-e1fe8b6de5fa',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6b2d1887-df4f-413f-8aef-038816d7e519',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: 'f22639dd-ca70-4d8d-bf20-30f23c93c1b9',
          type: 'longFreeText',
          title: 'Past Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e86afaa9-1635-4aff-bdca-fe65141f44ea',
          type: 'longFreeText',
          title: 'Past Medical History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b80ecce9-af4b-4bd9-b98f-85ec28fa1004',
          type: 'longFreeText',
          title: 'Allergies',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9aa1897a-17e7-4333-90ef-12afbd77dab1',
          type: 'longFreeText',
          title: 'Current Medications',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '147c2811-05b3-4fc6-88a4-2bd042b057c5',
          type: 'longFreeText',
          title: 'Family Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7e0306c1-2d42-4e96-a674-4f8e60599ef6',
          type: 'longFreeText',
          title: 'Substance Use History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fea14d8-bcd9-45b3-b814-7dcb48fafcd3',
          type: 'longFreeText',
          title: 'Social History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '924d8a47-c6ea-4fe3-9be9-842a7c6bd9c7',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '325c1fb6-752e-4825-b174-7c799c8bdc47',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '51a8e4d5-7a5e-45db-baec-788eb72bbc29',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c38bb952-b27f-4add-9bdc-5fd909664479',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c5fa04a6-8ece-429f-967e-414058ac151b',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b6031f3e-436d-41c5-b16d-ea7b42b3a5f3',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1ae33aa6-280f-4f17-bca7-faa0466ba373',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c22ec427-9a07-4d97-a616-6d5192f2da9b',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '84b2e20c-141c-47ab-ba03-497ccecebf6a',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '95b6b09b-b3ad-4cce-bd21-cadceac0f50b',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f32a96d8-1e86-4183-81cd-c96594092c98',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3a954304-a9ba-41a9-a7a5-fc9694643167',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da741825-7531-4ffe-9790-fa202796e0c2',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'adab2332-73f3-4b25-aacb-b2753d79b3d2',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '1f5a718c-c252-4fa3-a0b0-10b08840c11b',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: 'fe17514d-db39-42d2-b04f-8527bbe4a7fa',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '42a4aa62-922a-431b-b1e1-3ca34d0542b1',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f1a9c4dc-534f-4cd1-8cc4-0b8e47d1f789',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fc593e9e-27f5-403d-a807-932bd16d8034',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '18e265c6-bc5d-49d4-b32e-bfcbebd2af30',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '83a4fef4-6e7b-43ed-a453-df53b4d7007e',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e591ea4b-bfdb-428d-8bb3-2e49fcfd51ef',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6b245439-aa95-4955-ae47-a3998febf25e',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6925a5ed-3ccf-4a2a-8af4-7ddaa5010df4',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7337347a-16a2-4f98-9307-179885d98360',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '20dc2348-3a0a-44ed-b404-25c93be42c31',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b8f192c2-0b29-4312-995c-1f5b55e458d7',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '4e32d5b8-16f0-4b32-8ef1-04c44d819912',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2eba1e70-f088-4a01-9b3c-a1f569a840db',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1b0c9047-2b80-4f74-ab8f-0e3968d70aff',
          type: 'checklist',
          title: 'Modality(s) of Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '23659137-e5ef-4926-a20d-b978e7ebf151',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_17_name_FollowUpTherapySOAP_v2 = {
  templateInfo: {
    id: 17,
    name: 'Follow up - Therapy SOAP',
    version: 2,
  },
  template: [
    {
      header: 'General',
      components: [
        {
          id: 'eb925644-ba65-4f39-b93a-010802484d7c',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'a247c9b1-3561-427a-8c91-baadca4d8dea',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '136a69ce-d88a-41cf-ac4a-e6174c0e9006',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Subjective',
      components: [
        {
          id: '6b2f4d16-73f0-4948-adb2-4f21cc21fd9f',
          type: 'longFreeText',
          title: 'Subjective',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective: Mental Status Exam',
      components: [
        {
          id: '1b280ae1-beb0-4a88-8491-6d262a24930e',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '892fc062-53a7-46a0-9d40-fe4ba2a29470',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a14ea51e-3180-45e1-91c7-4ceaf6563d88',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3b8ba311-cddd-4bbf-adfb-be881acdaad8',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '670c3829-1727-4282-947a-4db49bf3bad9',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '75fd45a1-3d00-4b4f-a47a-5d0d075caebd',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2ced3936-b2ca-494c-b1a2-c4f0a6ea9670',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4705e952-1c44-415a-a6bd-6c63abd6d109',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b1aa84a-cc69-4a0b-be46-9d40ffcfd9e1',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '919d8bef-226f-40d7-97a9-ed4e2b21cc0d',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '79f1d5c3-3707-40fe-a2db-b6effce9e7ad',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fee2e0ef-8980-4b73-92c4-fde1fa871235',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5f009948-da51-4f7e-abf0-6a20b7f8e7c1',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1793ad81-7ed8-469a-97af-7d2f2e4cfc91',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective: Test Results',
      components: [
        {
          id: '5a7ef0a4-a9b1-40dc-b48a-f5de663b2542',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment: Risk Assessment',
      components: [
        {
          id: 'f15a0b81-09ac-473a-87d0-3aa268e2537a',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9c1dc50c-21b7-4bbd-bd0f-1c8552ae700f',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb99fed9-49c9-42d7-b161-fde1647f32a0',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '90104e1a-2a92-47d4-abc9-4c60689a498d',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7ae44747-d95e-4b3e-9cad-e8a37ec27faf',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '34910d77-7b65-4bf5-979f-ffb83fb4b6f3',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd1a8fd81-1d7b-4eb5-adaf-87a15cb43134',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b276210f-bccb-487a-80d3-d02e2e9e6067',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'adc32d51-a6a1-40c3-8374-4d125af61e5e',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1b849140-7b17-4e4c-850b-9a83e633bf2e',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '901dd4ed-f8e9-46ce-8766-f3df8c0bbac3',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3a79a421-2f11-4e95-b2aa-ecce3c2de662',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'feac7123-1bec-44e1-8127-476c0e29c8aa',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ac3e68a5-2e0d-4cb8-807b-d82e61901674',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a04e08bf-9300-4f8b-9a21-03b737c0220c',
          type: 'checklist',
          title: 'Modality(s) of Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f47443bf-71c2-4e45-aef0-4e360263403f',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_18_name_FollowUpEMTherapyAddOn_v2 = {
  templateInfo: {
    id: 18,
    name: 'Follow up - EM with Therapy Add-On',
    version: 2,
  },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '55fbec54-df1b-4f5a-b4de-d6647d57fa62',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'ed8f0978-777f-472c-a3bf-5f76ce1593ba',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4d01b1db-7c5a-44bb-ab69-e50e0106ad68',
          type: 'shortFreeText',
          title: 'E&M (in minutes)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ccfe6e60-729b-442f-b9e2-b62260d4bb27',
          type: 'shortFreeText',
          title: 'Psychotherapy (in minutes) ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd8667c1d-2223-4d08-b5ad-517e37745e35',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e06625e9-dc4f-4d7d-867d-faf6e4b22c2a',
          type: 'longFreeText',
          title: 'Interval History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '08d617a9-9f70-4fa0-81d8-d9109f7a56b7',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '843d36e2-f132-48f1-8f34-f2cfca32bf84',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf5f3a44-1ffd-4005-a275-ab8bd4ae2ef2',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9f8300bc-f59d-45d7-b1ed-100fc05a75d6',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b63da87a-cd7b-43d9-892c-897eea9c0795',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1f46ed44-9b89-4d50-add3-c0de278f0cc5',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'de06a7ac-3a09-4e3b-a5e2-601973d3e00d',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Modality',
      components: [
        {
          id: '028b5809-288b-49c6-a8c1-e66b9bd68d85',
          type: 'checklist',
          title: 'Modality(s) of Therapy Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '59027be1-a1f3-4667-a2c0-fc40663f8b90',
          type: 'longFreeText',
          title: 'Discussion/Focus of Therapy',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: '3ca046c5-0a31-4d2d-aeea-838bb8b97239',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '98abe120-10da-4aa3-ba2f-2beba267401b',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '87babb3f-ce1f-4bf9-bf78-26de36d0415e',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f411af07-206b-4a0a-9446-431da7a63bfd',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '83645392-15c5-44ea-abf7-e2c2b6b6bbbf',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8fab2e15-d387-44e4-b634-45d57c9b0ca7',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c85853a8-bab7-4b59-9528-ec9d604d821a',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '49b210d3-0a07-4709-b0b3-8b357564f6c0',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cdc55394-6be2-4fe7-9954-e1fb8f7eb9d5',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e9ce6d3e-8e1c-4364-9954-c7b965ccecc7',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd7703339-4e1d-479e-9296-a9a39bb5c8dd',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3e54e48c-5611-49c6-8442-36b23b45a9fe',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '906c61f5-2074-46d0-9560-3870bd5d6fae',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '44ced7c7-bf8c-49fc-b151-304fc2ae58cb',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '9852b4d6-76ee-4fe7-a4a2-5b333970ef9e',
          type: 'longFreeText',
          title: 'Pertinent History (Family, Social, Medical)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: 'f8ab0097-fff3-4804-84fb-cddd14f07ceb',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'af5c99ed-72c0-4e85-a4ab-266e624607e7',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '03922b04-6fa0-420a-8a56-cee142439dc9',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '27c2ffe7-cc36-4e18-9bbf-8a573c51c172',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '462227fc-588e-40b7-8331-13cb522d7a92',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '501f0c48-47ec-40ca-bfad-43f47c9b5938',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2f092db2-c5dd-425d-9126-36b5166cded9',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f6dc0458-e54d-4703-b857-930898fe6f35',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '641f3497-257b-415a-8c1a-1c865880adac',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '01258e8e-dd77-459c-8602-075ad04a90bd',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ec835432-c12f-4536-aaa7-7a50fac1ba4e',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'dd003192-da79-4a3e-a15a-8dec86c319f0',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a8e7d406-7176-4830-8f7f-1b71a4d4431d',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2a27b59b-609b-4c1d-a8fb-ab111ae8952f',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '7a805baf-6e5e-40a1-9972-3df35e3b1438',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '313c35e2-327d-4e05-b96e-cfbfa46c9615',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '54bce363-d603-4d03-91ff-1a4bdd76439c',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd7001e2f-62a6-42d9-b113-e8046fa81b7a',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '3988f5a7-1f6b-484d-8a0b-e3049542a87a',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5cd6e1d5-eaae-4135-af8e-231ad2ae05ab',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '12613ef5-7788-4a1f-a214-4a2c74e61c0b',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7b1790e5-0b79-4c3c-ab9a-79f52a0311e9',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a6223487-245e-4ccf-9d22-028a77103fc5',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '75925940-ce43-44ad-bf54-2b8e8b154af3',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e4693742-9d51-435d-8396-a75684ca9c3a',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '50c5a471-a91e-47dd-b199-cd892e036e52',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e828e861-1fb0-4f51-8303-ed5a23250012',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2b155f2e-7beb-4ca7-a162-28f061d2ac43',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9010ce9f-9774-4997-86ef-270117d9f8ff',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9d835de1-aae4-4007-9d26-297dacda4a24',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '746e0069-9b10-40af-b774-2bf385ce425e',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd08ba204-5298-4f8d-a706-ef5da83e2450',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '962d87b9-b5dd-4f8e-88ae-b0f3024651fc',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_19_name_IntakeProgressFreeTextSOAP_v2 = {
  templateInfo: {
    id: 19,
    name: 'Intake / Progress - Free Text SOAP',
    version: 2,
  },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '26745d53-4fe6-4d65-a08a-55f751ca5462',
          type: 'shortFreeText',
          title: 'Exact session start time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'ebde54aa-be96-4772-8434-7500fd4e00f5',
          type: 'shortFreeText',
          title: 'Exact session end time (HH:MM)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Subjective',
      components: [
        {
          id: 'e83d0de0-6b92-4e09-a78d-dc6180337fca',
          type: 'richFreeText',
          title:
            'For therapy: discussion of problems, how session adressed treatment goals / objectives.\nFor prescribers: history of present illness / interval history, psychiatric review of systems, medical review of systems ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective',
      components: [
        {
          id: 'b1509824-27db-40c5-a895-9323b391a83a',
          type: 'richFreeText',
          title:
            'Mental Status Exam, Vital Signs, Physical Exam, Relevant Labs / Studies, Patient Questionnaires',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '09a2b127-9cde-495a-9ba4-4709f1308f28',
          type: 'richFreeText',
          title:
            'Past psychiatric history, medical history, allergies, medications, family and social history',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'fba51270-5583-4f65-9f80-a847832f1781',
          type: 'richFreeText',
          title: 'Formulation, Risk Assessment, Statement of medical necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Plan',
      components: [
        {
          id: 'f217f58c-2702-420a-a734-d08b094d75c6',
          type: 'richFreeText',
          title: 'For therapy: include modality of treatment',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_20_name_FreeText_v3 = {
  templateInfo: { id: 20, name: 'Free Text', version: 3 },
  template: [
    {
      id: 'f5f9f378-24aa-4479-adda-fdb77480d727',
      type: 'richFreeText',
      title: 'Note Content',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: false,
      },
    },
  ],
};
export const id_21_name_IntakePrescriber_v3 = {
  templateInfo: { id: 21, name: 'Intake - Prescriber', version: 3 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '4ef3765c-ae76-46bb-b17b-2c0cfe3f95b3',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '77903076-506a-4ed2-85b7-94209a961050',
          type: 'longFreeText',
          title: 'History of Present Illness',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: 'faf1ddbb-7282-4476-9aa6-5bb41bc5d365',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1a15c098-651c-4029-a893-4952b5d6155f',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '014a8708-596e-4148-82f4-4a29baa15b7b',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6c63352c-5541-4959-a5d8-f44b6bc23fa2',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '538ba7db-836d-4a5f-a41d-db008c1463bd',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1ccd8cfb-0028-4d0a-af33-90cfd296a09a',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb42b296-2513-4c3b-bc69-e5be3259d77e',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: 'e44bd0fe-c538-49f0-adad-29ea05fb6d19',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '248383de-cf7c-443c-8bf4-f03579d732dd',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6a7e2bc1-47ce-453f-86c6-6ae5bb3f1347',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1e882252-57ad-4183-b785-55616a0ac44e',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cb3e16b-6079-4fa9-8e0b-99d005252bf1',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '411cbe20-3fc7-4f97-9658-f64eb99d641a',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b736fe8e-d3ee-4086-abaa-5d3dced1595a',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1b685cb2-f97c-406d-9b9d-d96cbd30ea01',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c63e6ab3-225e-40e1-8eba-97a25e4c421b',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b5fdefc2-3150-4458-a574-c82bdebacd07',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a7f77b49-f86f-4cbd-8b3a-34e9bd46f212',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3e7a534a-0789-4e38-a799-0004a2313373',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '22ec0fca-a662-4f75-b247-d21ad4ddb539',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '78dca578-408d-4c8e-a755-6451728fd6a3',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '3de53c4e-7e77-48eb-b70f-64ca716e8009',
          type: 'longFreeText',
          title: 'Past Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '53d6837f-b64a-4fe4-9b9e-6317170e77e2',
          type: 'longFreeText',
          title: 'Past Medical History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7b0b3231-8dbd-4b8b-847e-d11791800755',
          type: 'longFreeText',
          title: 'Allergies',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '203c588b-0735-4afe-a192-936e2a02383c',
          type: 'longFreeText',
          title: 'Current Medications',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '483cd8de-37e1-499d-89ec-9a093a9e616d',
          type: 'longFreeText',
          title: 'Medication Trial History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f158af47-dd35-4ad3-9902-4e3847571fca',
          type: 'longFreeText',
          title: 'Family Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8ecbfb5f-1a78-437b-85d4-1a369adaa606',
          type: 'longFreeText',
          title: 'Substance Use History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '62c28828-12f9-4b13-8567-9b93f2dd9a8a',
          type: 'longFreeText',
          title: 'Social History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '058fbca0-b81d-4445-9eda-bbdf0841f4bb',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7863a3b4-28ea-47c7-b260-59c8803f3ef0',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '53f27aca-fee8-4aff-8da7-c2fb7ab323e9',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3d60d345-2af6-4d51-a77f-879596be83ca',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2db8afc2-1677-4db9-92d6-a916edba4b03',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4c808a21-def1-4427-8cab-5ddaa2256e29',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '14a42e65-e545-41b2-bf50-b4196c0bdc94',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '586bb783-486b-436e-9269-1e3b4a63fd95',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e73c1c56-5d85-42fd-aa7b-7ab9494aed65',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0fe2ee19-a5f0-4d52-b712-1124abf9f62a',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd9de596a-0256-42d1-a1de-d6680b9974c9',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '08794a9e-7f2f-4a5a-95ac-e134660a9bd4',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4f61facf-f631-479d-b222-33c92f06355c',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '197b395f-7fcc-4aae-84dd-70e878fab3a3',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: 'eec27d52-fafe-4ace-b0ab-1b56836021b1',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '16c7bfc2-9534-44ad-ae75-49c5cd092f66',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6ca76ee6-354a-463b-8047-772288db73d7',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e6145595-4d1e-49b8-ab6c-2f7dae30dd89',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: 'fd16355f-c54b-4812-a388-89c56181e1bf',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf169a95-ef6e-49b1-a5fa-5019a039f152',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f6681969-c678-4818-93f9-273897eeaf19',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '72fcc640-b902-483c-9465-22473e680ef6',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '576909c4-b076-49d2-960a-ba2c8b38eaa5',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9270fddc-ef67-4532-9918-74ae21c55f27',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8a721013-362f-4768-8118-5464fcc08f50',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b9d549a-1c18-4cc4-a1ad-0cdd67a454ee',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '660dc5d2-f983-4586-9dd9-ba56ebeb5285',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '22466edc-be99-45f2-8d34-8b530974674c',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0ca2c30e-7801-49a6-9b72-685842bfde25',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9c2eac5a-b931-45a6-8d84-c97be55f74db',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '77421c10-a469-4d2e-aa95-a9649737ced6',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5788b6ac-a6a2-49d4-ba8f-3f304da383c5',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67682c11-b12b-4213-a83e-b76b86ba9099',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_22_name_FollowUpPrescriber_v3 = {
  templateInfo: { id: 22, name: 'Follow up - Prescriber', version: 3 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: 'ec43ce65-d547-40e7-b620-a13723c1fbd8',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8e00c04d-bcb1-406b-9220-33049d0a9f0c',
          type: 'longFreeText',
          title: 'Interval History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '697c7c61-62e2-4dac-a1ec-8891c11f9361',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '05c0fa09-311a-423c-ae6e-5c3249a211f7',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '707f1c81-64f7-4c8d-9205-97795acca3c7',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '43a28506-93b8-45b0-8efe-8e20611eb835',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e22e709a-96c0-4574-b606-3c902b5be946',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd13df3eb-f455-4e2c-98df-7919594ef68c',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ff368e0-cde4-4afb-966f-cf7ef327b5ae',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: '7c1f0135-007d-464a-a95a-145aae04f67c',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '31dfb71e-8763-475c-a220-11a8fbafbdd8',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd49e19ec-2d4b-4cab-8cf6-55736fbbc838',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '75dbfcf7-e9c2-47c4-b029-c3366d5611c4',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7a285755-da64-4aa2-84ae-143d4f315094',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '84e875a2-04ac-4afd-afd8-21a61dfdf631',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '78bc17db-a087-4f3b-9fd0-1856c1c17147',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '56825ea7-9415-4d1a-bb0c-0eeebcfd1cfe',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '40cd1449-980f-4546-b177-990f1063225f',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '47285583-278a-4da6-8d3d-e19287827592',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e3ccf74-e81c-4661-a46f-a23f07b5ec77',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a51803e7-8067-4821-9117-9d7b2d7abcd6',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f31618e9-762b-42a6-8072-eed4736645e1',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2800c92c-93e9-4b93-9a31-4fd691c97e51',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '1b294d15-2752-4c0c-93dd-c215a7567c22',
          type: 'longFreeText',
          title: 'Pertinent History (Family, Social, Medical)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '1b5c8b12-f58c-47d3-ae0b-ed06e742d4a1',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f39cfb2b-c4cd-4714-9cf6-ba5504c0e669',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4ad908e0-9dd0-4089-8195-d87aeef4e302',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f0567fe5-168b-4dc0-8f3c-89707e1abdbd',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '19ee46d4-3326-43fa-b40c-65e4e4e4ac74',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5aa2ada3-bd10-408b-acc7-81a47ec937d5',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8362927c-03c6-4a82-ad53-709230af21c9',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9383704b-8b64-4545-9bc0-e5176a3f2817',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7f7ee83-8222-4409-a062-adf82d4feeaa',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c0849a1c-0362-4c9c-8cce-55afa6cc6ddc',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8c6ae927-86dc-4913-b514-b97509ddcc21',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '80b2468b-c8a7-434a-88b1-0e2d5da66b85',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e5d9a06a-b020-46ea-8980-ea7535c4cdca',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb890faf-e54d-413c-acdc-657b116f6dab',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: 'ff28bac0-2b51-421d-9a63-d774cabf7536',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '84653660-edd0-453b-865c-45542f7eb3e9',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b90b61f7-6e05-412d-a172-fae42fe475ed',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a8e6c9a0-6026-4346-9b44-712546593482',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '5b0e1d3b-dafd-4471-87b6-1e08f9a6e7d5',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cbee7008-a6a6-46f5-8086-6da812390edf',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '311cbd49-a16a-495e-9e7c-046facb85e2c',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4bf4ce3c-225d-4af6-ba22-8cc92ee79667',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '877e48b3-26aa-4fd7-bc22-8f958cad72db',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15ab8451-3fac-446e-96c6-9150b386b3c7',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e72525a1-04fe-4adc-8948-a427b5d30e3e',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '046e528a-6df3-45e3-95c2-507eb59d4262',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ad5d6a37-c598-44e9-a858-ab5478aa6a6d',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9b2fa647-d837-406b-83b3-b76e6ac7c4c1',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '34c76e40-cea7-4324-aed1-f812c3467053',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0c3b9dfd-2692-4d8c-9b03-05089cf13266',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'cb6882d9-11e5-4412-b827-c9435385bdc6',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e4141b66-a91b-498f-be37-e91ea8e3f27f',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '873f9ab0-5e0e-4255-b8bb-301d29f57995',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_23_name_IntakeTherapyOnly_v3 = {
  templateInfo: { id: 23, name: 'Intake - Therapy Only', version: 3 },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '8b7e0242-d83e-425e-ba35-9c0856e75786',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e22ad57d-a41f-4799-8da1-6e1a9d27837a',
          type: 'longFreeText',
          title: 'History of Present Illness',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '9a528f7c-f653-426e-92b8-9771814d3869',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd7af49d1-d733-402a-ab27-f4ac2ceaf25a',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd131d2a5-f4a1-482f-98fc-00c20ab0eb20',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6d78f29b-064f-46ba-a816-1e5e1d4bb877',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '648af360-6476-44fb-a82f-ae8999ed704c',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd5a75285-5eb6-450d-a692-e99edea1efa3',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c4264dd5-a3da-428a-9ad6-c23b48cf3200',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: 'aaf69264-1a46-452d-b0c7-b8964957ba5e',
          type: 'longFreeText',
          title: 'Past Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'dfa0b32a-782b-489f-8ae5-8360f644bbef',
          type: 'longFreeText',
          title: 'Past Medical History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f97c693b-98ca-4e1a-b666-f99109e6b80d',
          type: 'longFreeText',
          title: 'Allergies',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '821aea5c-7814-49a2-a90f-0f3195f0efd1',
          type: 'longFreeText',
          title: 'Current Medications',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c814f7c3-4219-40bc-9a87-78316464e2b3',
          type: 'longFreeText',
          title: 'Family Psychiatric History',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b5f42131-241f-4bd1-b585-46fc89208105',
          type: 'longFreeText',
          title: 'Substance Use History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '95c438f6-ba40-4bcd-80ff-e7ac5c23c580',
          type: 'longFreeText',
          title: 'Social History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '6131a3f1-9c61-408e-9fa9-e1b1e7a114b8',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a6be1a76-5eba-4c9a-8481-df29ccc5082e',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8568366f-19a9-40bf-8d27-29952801063e',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd05690fd-2d60-4117-a814-840fbd552a25',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b3d161d7-67e8-4855-b6f5-8bb598bc934c',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bdecea4-8eff-482b-8008-0cd10ffa3972',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6147694e-aad4-4b54-bba8-9b7090b60067',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '34e98307-9567-4d2f-9130-8032fa5bb03b',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0396ac2f-6585-4cde-a89a-a73e4ad4d63c',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e350cb-6584-4511-afea-b0d6116b2fee',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2168834a-2181-49b9-a5da-0f8be0e8d1b1',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0f68a385-1b4b-4641-a5f4-fec296c9f3e4',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ba6c3f8-b734-4e17-bb19-511a1d9d1409',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ab6aeeb4-9414-47be-b974-98b78b174cb9',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '0c29b32b-89d9-48bb-b760-93b957f18f4a',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: 'cf33af82-aea7-49c8-80af-15b742505488',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4df6ab82-4985-45a5-a2d0-b5a3b6b6e50f',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '69ecfdf0-763a-477e-bac2-20ba9204cf41',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '431d0815-098c-4d91-8e69-6dc6dc4c6b81',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ef67a5ea-a9cd-4401-a978-fa44f6e789cc',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b96f3ab0-c1fa-40eb-a90f-67b6b8f9da44',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3ea76b53-a1a8-42c2-88de-adbfb169fb79',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '05a1415a-996e-4423-90d9-2178c2b2fbd7',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '056121c5-1f14-4283-9b07-a08ba1a1eae9',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '956ed57f-d540-4e0b-a376-d1c188e541f4',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '27b2cb5e-f9ac-449a-9418-bb7a2ea9aa86',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bb9f6558-dec4-4e4e-85c6-263fdc3c7afc',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: '9fb4471d-c606-49ae-b843-159103ec700d',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '70cf7e67-f27b-41e1-96b4-da2107b5f7a1',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '23aea8b5-f1c7-4df7-aa26-050e14e9a4a2',
          type: 'checklist',
          title: 'Modality(s) of Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '70d3c4f8-e599-4f6a-8e26-16f2955d8d6c',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_24_name_FollowUpTherapySOAP_v3 = {
  templateInfo: {
    id: 24,
    name: 'Follow up - Therapy SOAP',
    version: 3,
  },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '9029537d-c24f-45c3-914f-ab982501c71c',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Subjective',
      components: [
        {
          id: '1fb6b44a-27b7-4379-9b7e-3ad998b3b1ce',
          type: 'longFreeText',
          title: 'Subjective',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective: Mental Status Exam',
      components: [
        {
          id: '61490160-4a96-4e12-a145-1a9be298de1d',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb61a537-6633-4e99-ba21-ddec4ee3684a',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f1f5a59b-bf41-4ae7-b2db-7b49c036e024',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '674f7770-6ae3-44f4-b0a7-f1e5f70123a8',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a788a500-a6d4-43ed-b7c8-2c510d7fe1f8',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8b6ba35f-6ebe-4be1-a5fe-49bad04aeb55',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '17204b4a-f6b5-4592-9d5d-b52faa363a18',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ceb1949-5503-497c-adb7-c7b95ac579b1',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '687d103d-220c-457c-91a1-d011fc76614d',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5a321cf1-b1e5-49ea-aba4-f5c03f5a546b',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'acfa0c38-f163-4490-84ed-d0f549850969',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd68f34cb-710c-44b5-8fcd-0d31f2aa3f4c',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9c971cf0-a3af-4a71-a979-6f13c4fac771',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1b508761-d611-47d0-8d4f-32c4914c3eab',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective: Test Results',
      components: [
        {
          id: '053c3937-a628-4055-b93b-175b55c901b2',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment: Risk Assessment',
      components: [
        {
          id: '2361f62f-a2cb-46e2-8132-c2fd4d93fd1b',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7e85ecc-a5c8-4077-80f8-140ac629b79f',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d16da5b-dea9-405f-b25f-b02b9154ce14',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b92371aa-67ad-413a-ae92-1b579f285ad2',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8a826d7d-b969-4c8d-bf0a-e1ab5678bf1f',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5f1b22f3-4d0c-48c4-91a1-18bd3fe54fc3',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a8672fae-f5d5-4589-bc4e-d717a223c82c',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3b2243c7-d473-4625-8817-868e447e76cb',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cde3f44e-6b72-4bc0-8911-173e07706110',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ffac2256-8b54-49d3-b8e5-ead310c8cd72',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2a4dea1a-1c70-4012-bb1a-4b0b17b7bd90',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1dee7f96-5e26-49bd-b068-5806dabe9a70',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'b010703d-f190-44ee-b5b9-1ca75e8a4a9b',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9f3db9dd-e25e-4dae-9a3e-8e4545235e5f',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b25aac7c-08d8-456d-920b-6cbf60fbc5fc',
          type: 'checklist',
          title: 'Modality(s) of Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd7cd80b3-1ea7-4892-a7b4-b8d43033cbfa',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_25_name_FollowUpEMTherapyAddOn_v3 = {
  templateInfo: {
    id: 25,
    name: 'Follow up - EM with Therapy Add-On',
    version: 3,
  },
  template: [
    {
      header: 'General',
      components: [
        {
          id: '34897134-3260-4971-8df2-0a60ec1af466',
          type: 'shortFreeText',
          title: 'E&M (in minutes)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd7817a9b-1e97-45c0-aea8-a2db78d140a5',
          type: 'shortFreeText',
          title: 'Psychotherapy (in minutes) ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'abe9c000-6bbb-46c2-9eb2-c2b4899e15c6',
          type: 'longFreeText',
          title: 'Chief-complaint (patient words)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '049fec75-4b2f-4ab5-925f-2eb4ef5c5c85',
          type: 'longFreeText',
          title: 'Interval History ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych Review of Systems',
      components: [
        {
          id: '517a43a0-57b8-420e-b28e-49de011c1ca7',
          type: 'longFreeText',
          title: 'Mood Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cceecb5-5d4b-49a0-a828-36f9bc1e3bfd',
          type: 'longFreeText',
          title: 'Anxiety Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'df5a7eb3-e22b-4b57-aee0-2e1ecba5d519',
          type: 'longFreeText',
          title: 'Psychotic Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '90416e99-af5a-48bc-9fa4-ad250827b7ff',
          type: 'longFreeText',
          title: 'Personality Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '40785320-3b19-48b4-a6f4-9bdfd25e8fe8',
          type: 'longFreeText',
          title: 'Eating disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '941fe85f-7395-4bdf-b883-7df9be96f747',
          type: 'longFreeText',
          title: 'Substance Use Disorders',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd348d504-ae63-49e0-b9c1-484c2d587bd1',
          type: 'longFreeText',
          title: 'Other',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Modality',
      components: [
        {
          id: '57c3a530-9251-4eb5-9793-a817644729ab',
          type: 'checklist',
          title: 'Modality(s) of Therapy Treatment ',
          options: [
            'Behavioral treatment',
            ' Supportive',
            ' Insight-oriented',
            ' Motivational',
            ' Exploratory',
            ' Medication management',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c419a6ef-5edf-4192-ad4b-1f1a47d1a8de',
          type: 'longFreeText',
          title: 'Discussion/Focus of Therapy',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical Review of Systems',
      components: [
        {
          id: 'b355faad-8970-4fea-9b65-22c7ead8f9dc',
          type: 'longFreeText',
          title: 'Constitutional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0084d989-813e-4ce9-b2b3-d7e76ef32346',
          type: 'longFreeText',
          title: 'Eyes',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd6f68cf4-a969-4b05-b254-152064c5059b',
          type: 'longFreeText',
          title: 'Ear, Nose, and Throat',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5beb2af8-c7d6-4048-9e72-64e2be1583d2',
          type: 'longFreeText',
          title: 'Cardiovascular',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f2a9389f-d86a-4bbf-b7a7-3fc694068be7',
          type: 'longFreeText',
          title: 'Endocrine / Reproductive',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e2e1c4de-56d5-429c-bf1a-199a8135a91b',
          type: 'longFreeText',
          title: 'Respiratory',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b3730488-5547-4947-9070-4785c29a1cee',
          type: 'longFreeText',
          title: 'Gastrointestinal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6847c009-057c-423d-b487-a36878759c13',
          type: 'longFreeText',
          title: 'Genitourinary',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a8ddd54f-a641-4593-ab30-422c439ac1cc',
          type: 'longFreeText',
          title: 'Allergic / Immunologic',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3dfdfff0-8980-4416-87c6-0032b72429fa',
          type: 'longFreeText',
          title: 'Psychiatric',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6014aa01-abf2-4d9f-8c6d-783e79ffe703',
          type: 'longFreeText',
          title: 'Hematology / Lymph',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '59558042-fe10-47d6-9ddd-19df2a31cba1',
          type: 'longFreeText',
          title: 'Musculoskeletal',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c61c7576-6136-44fb-b769-7c4b3fd43109',
          type: 'longFreeText',
          title: 'Skin',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '52ea21d8-10e0-4991-bbe3-af142403930e',
          type: 'longFreeText',
          title: 'Neurological',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '7e231f45-9b48-4372-8bdc-b146620e2a81',
          type: 'longFreeText',
          title: 'Pertinent History (Family, Social, Medical)',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental Status Exam',
      components: [
        {
          id: '9a292178-aefd-4559-ba25-a5a190c00300',
          type: 'checklist',
          title: 'Appearance',
          options: [
            'Nothing of note',
            ' Well-Groomed',
            ' Unkempt',
            ' Casually dressed',
            ' Disheveled',
            ' Appears stated age',
            ' Appears older than stated age',
            ' Appears younger than stated age',
            ' Poor hygiene',
            ' Adequate hygiene',
            ' Dressed inappropriate for occasion',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '99583faf-5978-4511-9d38-f785baa6fa69',
          type: 'checklist',
          title: 'Attitude',
          options: [
            'Nothing of note',
            ' Cooperative',
            ' Pleasant',
            ' Relaxed',
            ' Guarded',
            ' Disinhibited',
            ' Playful',
            ' Provocative',
            ' Overly familiar',
            ' Hostile',
            ' Difficult to engage',
            ' Impulsive',
            ' Demanding',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ac234085-c12a-4dfb-8bf7-11e0e65b1c63',
          type: 'checklist',
          title: 'Behavior',
          options: [
            'Nothing of note',
            ' Indirect eye contact',
            ' Intense stare',
            ' Appropriate eye contact',
            ' Fidgeting',
            ' Pacing',
            ' Physically threatening',
            ' Posturing',
            ' Decreased spontaneous movement',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '27a91d04-c67c-467e-913b-2dbaedf1efee',
          type: 'checklist',
          title: 'Speech',
          options: [
            'Nothing of note',
            ' Normal rate',
            ' Rapid rate',
            ' Slow rate',
            ' Normal volume',
            ' Low volume',
            ' High volume',
            ' Talkative',
            ' Flattened prosody',
            ' Normal prosody',
            ' Prolonged latency',
            ' Under-articulated',
            ' Mute',
            ' Limited output',
            ' Pressured',
            ' Rapid but interruptible',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b2fbd7e-20e5-497f-a91a-ec5a07a91d3f',
          type: 'checklist',
          title: 'Affect',
          options: [
            'Nothing of note',
            ' Congruent with mood',
            ' Incongruent with mood',
            ' Full range',
            ' Constricted',
            ' Sad',
            ' Irritable',
            ' Anxious',
            ' Giddy',
            ' Expansive',
            ' Angry',
            ' Flat',
            ' Blunted',
            ' Labile',
            ' Apathetic',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cce6e644-6e34-4d70-b1c4-c64f36194f51',
          type: 'checklist',
          title: 'Mood',
          options: [
            'Nothing of note',
            ' Even',
            ' Stable',
            ' Good',
            ' Happy',
            ' Depressed',
            ' Irritable',
            ' Angry',
            ' Apprehensive',
            ' Anxious',
            ' Ruminative',
            ' Fearful',
            ' Hopeless',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b81b4e9b-5caa-4851-8e93-5c0ca7a325d8',
          type: 'checklist',
          title: 'Thought Process',
          options: [
            'Nothing of note',
            ' Logical',
            ' Linear',
            ' Circumstantial',
            ' Tangential',
            ' Racing',
            ' Flight of ideas',
            ' Ruminative',
            ' Perseverative',
            ' Thought blocking',
            ' Concrete',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c677ed9d-dff4-476d-aa65-e09630639103',
          type: 'checklist',
          title: 'Thought Content',
          options: [
            'Nothing of note',
            ' Appropriate to Situation',
            ' Ideas of reference',
            ' No delusions elicited',
            ' Delusions present',
            ' Obsessions',
            ' Phobias',
            ' Guilt',
            ' Compulsions',
            ' Thought broadcasting',
            ' Thought withdrawal',
            ' Thought insertion',
            ' Overvalued ideas',
            ' Low self image',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '581d10ea-387e-48ce-8a90-6006384c0c0c',
          type: 'checklist',
          title: 'Perceptions',
          options: [
            'Nothing of note',
            ' No hallucinations elicited',
            ' Hallucinations',
            ' Flashbacks',
            ' Nightmares',
            ' Hypervigilance',
            ' Depersonalization',
            ' Derealization',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'effd124c-b69e-4e76-9bcc-15123880dbbf',
          type: 'checklist',
          title: 'Orientation',
          options: [
            'Nothing of note',
            ' Oriented to self',
            ' Oriented to place',
            ' Oriented to date',
            ' Not oriented to self',
            ' Not oriented to place',
            ' Not oriented to date',
            ' Perplexed',
            ' Confused',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a14be83f-6bbd-42e8-9819-d673d84e1c47',
          type: 'checklist',
          title: 'Memory',
          options: [
            'Nothing of note',
            ' No deficits noted',
            ' Impaired receall of recent events',
            ' Impaired recall of remote events',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '98ff08da-5d02-46f6-8d64-b1ced8e67dee',
          type: 'checklist',
          title: 'Concentration',
          options: ['Nothing of note', ' Impaired', ' Intact', ' Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'dcbf0cb5-0896-4ab6-b6a6-805fc7f0940b',
          type: 'checklist',
          title: 'Insight',
          options: [
            'Nothing of note',
            ' Intact',
            ' Limited',
            ' Adherent to treatment',
            ' Not adherent to treatment',
            ' Does not recognize having a mental illness',
            ' Recognizes presence of mental illness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8ed40827-1397-4cbf-93f1-095e1334b81a',
          type: 'checklist',
          title: 'Judgement',
          options: [
            'Nothing of note',
            ' Impaired',
            ' Intact',
            ' Fair',
            ' Limited',
            ' Poor',
            ' Improving',
            ' Worsening',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test Results',
      components: [
        {
          id: '7d3b8701-f3d6-4138-ad43-6654ac434524',
          type: 'dropdownMulti',
          title: 'Diagnostic Data',
          options: ['GAD-7', ' PHQ-9', ' Audit C', ' Other (free text)'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical Exam',
      components: [
        {
          id: '75b30a64-e644-4e28-872a-02747bcb1b59',
          type: 'dropdownMulti',
          title: 'Constitutional',
          options: [
            'No apparent distress',
            ' Well-appearing',
            ' Fatigued',
            ' Ill-appearing',
            ' Non-toxic',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '975bcfa2-d90c-4665-938a-328f173f6d28',
          type: 'dropdownMulti',
          title: 'Neurological',
          options: [
            'Alert & Oriented x3',
            ' No gross Neurological deficits',
            ' Extra-occular movement intact',
            ' Symmetric facies',
            ' Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fcd4822-8019-4323-b5f4-0232ee83a8ea',
          type: 'longFreeText',
          title: 'Additional',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [
              99202, 99203, 99204, 99205, 99212, 99213, 99214, 99215, 90792,
            ],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk Assessment',
      components: [
        {
          id: '5034c294-9b41-44cf-8a95-3e32b8486cf4',
          type: 'dropdown',
          title:
            'Did the patient endorse recent thoughts of harm to self or others since last encounter?',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '82d4a186-6f0f-438c-8569-fe5545591ddf',
          type: 'dropdownMulti',
          title: 'Suicidal Thoughts',
          options: [
            'None',
            ' Suicidal Ideation without intent or plan',
            ' Wish to be dead without thoughts of suicide',
            ' Intent',
            ' Plan',
            ' Acts of furtherance',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '633397bb-c5c5-45c1-94e7-f20827019a93',
          type: 'dropdownMulti',
          title: 'Self Harm Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent self-harm behaviors',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd50afe96-8498-4ac2-8a2f-25273545dc6d',
          type: 'dropdownMulti',
          title: 'Homicidal Thoughts',
          options: [
            'None',
            ' Homicidal Ideation without plan or intent',
            ' Plan',
            ' Intent',
            ' Acts of furtherance',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4cdac9af-9b35-49b9-9f10-efc96bcf229c',
          type: 'dropdownMulti',
          title: 'Violent/Destructive Thoughts',
          options: [
            'None',
            ' Urges without plan or intent',
            ' Plan',
            ' Intent',
            ' Recent violent or destructive acts',
            ' Identified Victim',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ce737a68-1db2-4785-b624-ce8512300738',
          type: 'longFreeText',
          title: 'Additional details',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0a57c9a3-6f41-482b-a4a3-1812335b1eaa',
          type: 'dropdown',
          title: 'Prior Suicide behaviors or attempts',
          options: ['Yes', ' No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ab544308-ba84-422e-abe6-6daec665506e',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          options: [
            'None',
            ' History of prior suicide attempts',
            ' History of violent behaviors',
            ' chronic/severe mental illness',
            ' Chronic medical illness',
            ' Family history of suicide',
            ' History of trauma',
            ' Recent losses/significant negative events',
            ' Pending incarceration/homelessness',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'efc36713-48a3-4b85-9dc2-2e74f9535529',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          options: [
            'None',
            ' Anxiety and/or panic',
            ' Depressed mood',
            ' Insomnia',
            ' Hopelessness',
            ' Psychosis',
            ' Substance use/intoxication',
            ' Impulsivity',
            ' Chronic pain',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '158e7c8f-9fa5-4a73-9ac4-6ffe50c8efa0',
          type: 'dropdownMulti',
          title: 'Protective factors',
          options: [
            'None',
            ' Help-seeking behavior',
            ' Lack of prior suicide attempts',
            ' Engagement in treatment',
            ' Social supports',
            ' Engaged in school or work',
            ' Fear of death',
            ' Spiritual',
            ' Moral/cultural attitude against suicide',
            ' Resilience',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '26e927a8-983b-49fa-b54f-728424bbdc2b',
          type: 'dropdown',
          title:
            "What is patient's current overall, acute risk of harm to self and/or others?",
          options: ['Minimal', ' Low', ' Moderate', ' High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3af3400d-8a44-4fb1-9240-2ce3fa2e5e5c',
          type: 'dropdownMulti',
          title: 'Actions take to minimize risk of harm to self and others',
          options: [
            'None',
            ' Admitted to an inpatient unit',
            ' Referred for a higher level of care',
            ' Plan to increase frequency of appointments',
            ' Referred to another modality of treatment',
            ' Addressed firearm safety',
            ' Family involvement',
            ' Made medication changes',
            ' Continue to monitor',
            ' Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'faf5cc3b-4904-4963-ac3f-65b9163a82db',
          type: 'richFreeText',
          title:
            "Assessment of client's ability and capacity to respond to treatment, session, situation \n\n*If coding based on Medical Decision Making for Evaluation and Management Service, justify your level of Medical Decision Making in this section",
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f665df18-4c4f-49ba-a2cb-aae16f2d3e43',
          type: 'longFreeText',
          title: 'Statement of Medical Necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '20314b4c-4a9b-4307-9f28-7c8a1b8c3c00',
          type: 'longFreeText',
          title: 'Plan',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_26_name_IntakeProgressFreeTextSOAP_v3 = {
  templateInfo: {
    id: 26,
    name: 'Intake / Progress - Free Text SOAP',
    version: 3,
  },
  template: [
    {
      header: 'Subjective',
      components: [
        {
          id: '50a31c78-70ec-4dca-babd-5908050ffba7',
          type: 'richFreeText',
          title:
            'For therapy: discussion of problems, how session adressed treatment goals / objectives.\nFor prescribers: history of present illness / interval history, psychiatric review of systems, medical review of systems ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Objective',
      components: [
        {
          id: 'fcd19fe1-1d1c-45d1-aeac-437c4376f69e',
          type: 'richFreeText',
          title:
            'Mental Status Exam, Vital Signs, Physical Exam, Relevant Labs / Studies, Patient Questionnaires',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      components: [
        {
          id: '977c3c81-5a87-4c25-ad74-098eab464bac',
          type: 'richFreeText',
          title:
            'Past psychiatric history, medical history, allergies, medications, family and social history',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment & Plan',
      components: [
        {
          id: 'faf0be85-bc75-467b-922e-eaecf81d1ec8',
          type: 'richFreeText',
          title: 'Formulation, Risk Assessment, Statement of medical necessity',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Plan',
      components: [
        {
          id: '379749f5-555b-4e53-8a9a-7efb682be8d2',
          type: 'richFreeText',
          title: 'For therapy: include modality of treatment',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_28_name_DiagnosticEvalMedicalServices_v1 = {
  templateInfo: {
    id: 28,
    name: 'Diagnostic eval: Medical Services',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '13fe256b-e635-4270-95f6-4a027ea78ade',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fb8c800c-5491-4b7e-9d15-a2f855fc0b1f',
          type: 'dropdown',
          title: 'Was there anyone else at the sesssion?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd0becdb9-36a7-4e2b-b4c1-0f9f18c86770',
          type: 'longFreeText',
          title: 'History of present illness (HPI)',
          subTitle:
            'Include the presenting illness, history, current symptoms and behaviors',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '6915c3a2-1b3c-44bb-b25a-b146fa2c292a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aeb58d9f-5b1c-4252-8866-70cc41ba3319',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '51ba10d5-a743-4deb-9c8e-5f3e0d0a2802',
          type: 'longFreeText',
          title: 'Allergies',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e256ebb4-769c-41f2-a3a7-dc57329ceaba',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cce62d8c-fb38-4129-bdbe-afcad05eed41',
          type: 'longFreeText',
          title: 'Family psychiatric',
          subTitle:
            'Include family history of psychiatric illness, diagnosed or undiagnosed ',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8f33dfd9-7957-4b17-93b4-d0f7877d7b1a',
          type: 'longFreeText',
          title: 'Psychiatric',
          subTitle:
            'Include past diagnoses, hospitalizations, treatment dates, providers, facilities and types of treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3167cc94-a16d-4b65-ae6b-3c0dd05f23d0',
          type: 'longFreeText',
          title: 'Medical',
          subTitle: 'Include past diagnoses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2a2a07ad-a3c1-49df-aab7-0eb72ceafd8f',
          type: 'longFreeText',
          title: 'Medication trials',
          subTitle: 'Include dosages, dates, and responses to medications',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '83f65054-5e18-49b5-b431-25f91b2d819b',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '579e9de3-94c4-4448-87d3-2aa5571ee174',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'dropdownMulti',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'dropdownMulti',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Disheveled',
            'Well-groomed',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'dropdownMulti',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'dropdownMulti',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropiate eye contact',
            'Decreased spontaneous movement',
            'Inappropiate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'dropdownMulti',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'dropdownMulti',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'dropdownMulti',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'dropdownMulti',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'dropdownMulti',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'dropdownMulti',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'dropdownMulti',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'c6621c63-6820-4666-aab1-879794eef4b6',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a2ff7414-5635-4963-bd4f-e1b50c2ba633',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7cabe8df-b1c0-477f-bbb4-5d99d737ba0f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '09f19e3e-678d-447b-9261-32209acab5e6',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '675588ef-fffd-4aad-8302-d25f9d7142bb',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ed689e79-3b07-4e76-a641-1a8293a7f744',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '72770eac-114d-4ee5-9d5b-f025db63921c',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '93f08773-353c-4fcf-856b-7636759bd6fd',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da46591b-a757-4173-8f69-5df13b377512',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      subHeader: 'If you identify risk, review a safety plan with the client',
      infoComponent: null,
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'dropdownMulti',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'dropdownMulti',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'dropdownMulti',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'dropdownMulti',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'dropdownMulti',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'dropdownMulti',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'dropdownMulti',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ada0ac0-8822-46c7-afe6-68bc4f3eff16',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9537bdb2-0c46-4330-9a5a-23933a03a489',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Include medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care ',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '68c0f1ae-ff6e-4076-a71c-1167d846d2aa',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'd541afac-cac2-40be-ae46-aa5127a40125',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2974ff90-adc1-44fd-905f-d748ed3c4086',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_28_name_DiagnosticEvalMedicalServices_v2 = {
  templateInfo: {
    id: 28,
    name: 'Diagnostic eval: Medical Services',
    version: 2,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '13fe256b-e635-4270-95f6-4a027ea78ade',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fb8c800c-5491-4b7e-9d15-a2f855fc0b1f',
          type: 'dropdown',
          title: 'Was there anyone else at the session?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd0becdb9-36a7-4e2b-b4c1-0f9f18c86770',
          type: 'longFreeText',
          title: 'History of present illness (HPI)',
          subTitle:
            'Note must include the presenting illness, history, current symptoms and behaviors',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '6915c3a2-1b3c-44bb-b25a-b146fa2c292a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aeb58d9f-5b1c-4252-8866-70cc41ba3319',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Constitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '51ba10d5-a743-4deb-9c8e-5f3e0d0a2802',
          type: 'longFreeText',
          title: 'Allergies',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e256ebb4-769c-41f2-a3a7-dc57329ceaba',
          type: 'longFreeText',
          title: 'Developmental',
          subTitle:
            'Note must include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cce62d8c-fb38-4129-bdbe-afcad05eed41',
          type: 'longFreeText',
          title: 'Family psychiatric',
          subTitle:
            'Note must include family history of psychiatric illness, diagnosed or undiagnosed ',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8f33dfd9-7957-4b17-93b4-d0f7877d7b1a',
          type: 'longFreeText',
          title: 'Psychiatric',
          subTitle:
            'Note must include past diagnoses, hospitalizations, treatment dates, providers, facilities and types of treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3167cc94-a16d-4b65-ae6b-3c0dd05f23d0',
          type: 'longFreeText',
          title: 'Medical',
          subTitle: 'Note must include past diagnoses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2a2a07ad-a3c1-49df-aab7-0eb72ceafd8f',
          type: 'longFreeText',
          title: 'Medication trials',
          subTitle:
            'Note must include dosages, dates, and responses to medications',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '83f65054-5e18-49b5-b431-25f91b2d819b',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Note must include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '579e9de3-94c4-4448-87d3-2aa5571ee174',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Note must include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Disheveled',
            'Well-groomed',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropriate eye contact',
            'Decreased spontaneous movement',
            'Inappropriate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'c6621c63-6820-4666-aab1-879794eef4b6',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a2ff7414-5635-4963-bd4f-e1b50c2ba633',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '7cabe8df-b1c0-477f-bbb4-5d99d737ba0f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '09f19e3e-678d-447b-9261-32209acab5e6',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '675588ef-fffd-4aad-8302-d25f9d7142bb',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ed689e79-3b07-4e76-a641-1a8293a7f744',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '72770eac-114d-4ee5-9d5b-f025db63921c',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '93f08773-353c-4fcf-856b-7636759bd6fd',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da46591b-a757-4173-8f69-5df13b377512',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ada0ac0-8822-46c7-afe6-68bc4f3eff16',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9537bdb2-0c46-4330-9a5a-23933a03a489',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Note must include medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care ',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: '68c0f1ae-ff6e-4076-a71c-1167d846d2aa',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'd541afac-cac2-40be-ae46-aa5127a40125',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2974ff90-adc1-44fd-905f-d748ed3c4086',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_28_name_DiagnosticEvalMedicalServices_v3 = {
  templateInfo: {
    id: 28,
    name: 'Diagnostic eval: Medical Services',
    version: 3,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '13fe256b-e635-4270-95f6-4a027ea78ade',
          type: 'longFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fb8c800c-5491-4b7e-9d15-a2f855fc0b1f',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd0becdb9-36a7-4e2b-b4c1-0f9f18c86770',
          type: 'longFreeText',
          title: 'History of present illness (HPI)',
          subTitle:
            'Note must include the presenting illness, history, current symptoms and behaviors',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '6915c3a2-1b3c-44bb-b25a-b146fa2c292a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aeb58d9f-5b1c-4252-8866-70cc41ba3319',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Constitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '51ba10d5-a743-4deb-9c8e-5f3e0d0a2802',
          type: 'longFreeText',
          title: 'Allergies',
          subTitle:
            'Note must include known allergies, or if unknown, notate NKA (i.e. no known allergies)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e256ebb4-769c-41f2-a3a7-dc57329ceaba',
          type: 'longFreeText',
          title: 'Developmental',
          subTitle:
            'Note must include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cce62d8c-fb38-4129-bdbe-afcad05eed41',
          type: 'longFreeText',
          title: 'Family psychiatric',
          subTitle:
            'Note must include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8f33dfd9-7957-4b17-93b4-d0f7877d7b1a',
          type: 'longFreeText',
          title: 'Psychiatric',
          subTitle:
            'Note must include past diagnoses, hospitalizations, treatment dates, providers, facilities and types of treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3167cc94-a16d-4b65-ae6b-3c0dd05f23d0',
          type: 'longFreeText',
          title: 'Medical',
          subTitle: 'Note must include past diagnoses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2a2a07ad-a3c1-49df-aab7-0eb72ceafd8f',
          type: 'longFreeText',
          title: 'Medication history',
          subTitle:
            'Note must include past and present dosage/route/frequency, rationale for prescription, and medication adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '83f65054-5e18-49b5-b431-25f91b2d819b',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Note must include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '579e9de3-94c4-4448-87d3-2aa5571ee174',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Note must include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Disheveled',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropriate eye contact',
            'Decreased spontaneous movement',
            'Inappropriate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'c6621c63-6820-4666-aab1-879794eef4b6',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a2ff7414-5635-4963-bd4f-e1b50c2ba633',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '7cabe8df-b1c0-477f-bbb4-5d99d737ba0f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '09f19e3e-678d-447b-9261-32209acab5e6',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '675588ef-fffd-4aad-8302-d25f9d7142bb',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ed689e79-3b07-4e76-a641-1a8293a7f744',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '72770eac-114d-4ee5-9d5b-f025db63921c',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '93f08773-353c-4fcf-856b-7636759bd6fd',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['N/A', 'Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da46591b-a757-4173-8f69-5df13b377512',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ada0ac0-8822-46c7-afe6-68bc4f3eff16',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            "Assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9537bdb2-0c46-4330-9a5a-23933a03a489',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Course of treatment (medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '68c0f1ae-ff6e-4076-a71c-1167d846d2aa',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'd541afac-cac2-40be-ae46-aa5127a40125',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2974ff90-adc1-44fd-905f-d748ed3c4086',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_28_name_DiagnosticEvalMedicalServices_v4 = {
  templateInfo: {
    id: 28,
    name: 'Diagnostic eval: Medical Services',
    version: 4,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'fb8c800c-5491-4b7e-9d15-a2f855fc0b1f',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '13fe256b-e635-4270-95f6-4a027ea78ade',
          type: 'longFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd0becdb9-36a7-4e2b-b4c1-0f9f18c86770',
          type: 'longFreeText',
          title: 'History of present illness (HPI)',
          subTitle:
            'Presenting illness, current symptoms and behaviors, and time course of current episode',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8f33dfd9-7957-4b17-93b4-d0f7877d7b1a',
          type: 'longFreeText',
          title: 'Psychiatric History',
          subTitle:
            'Note must include past diagnoses, hospitalizations, treatment dates, providers, facilities and types of treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6915c3a2-1b3c-44bb-b25a-b146fa2c292a',
          type: 'dropdownMulti',
          title: 'Psych review of systems',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '579e9de3-94c4-4448-87d3-2aa5571ee174',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Note must include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aeb58d9f-5b1c-4252-8866-70cc41ba3319',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Constitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '2a2a07ad-a3c1-49df-aab7-0eb72ceafd8f',
          type: 'longFreeText',
          title: 'Medication history',
          subTitle:
            'Note must include past and present dosage/route/frequency, rationale for prescription, and medication adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '3167cc94-a16d-4b65-ae6b-3c0dd05f23d0',
          type: 'longFreeText',
          title: 'Medical',
          subTitle: 'Note must include past diagnoses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '51ba10d5-a743-4deb-9c8e-5f3e0d0a2802',
          type: 'longFreeText',
          title: 'Allergies',
          subTitle:
            'Note must include known allergies, or if unknown, notate NKA (i.e. no known allergies)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e256ebb4-769c-41f2-a3a7-dc57329ceaba',
          type: 'longFreeText',
          title: 'Developmental',
          subTitle:
            'Note must include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cce62d8c-fb38-4129-bdbe-afcad05eed41',
          type: 'longFreeText',
          title: 'Family psychiatric',
          subTitle:
            'Note must include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '83f65054-5e18-49b5-b431-25f91b2d819b',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Note must include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Disheveled',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropriate eye contact',
            'Decreased spontaneous movement',
            'Inappropriate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'c6621c63-6820-4666-aab1-879794eef4b6',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a2ff7414-5635-4963-bd4f-e1b50c2ba633',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '7cabe8df-b1c0-477f-bbb4-5d99d737ba0f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '09f19e3e-678d-447b-9261-32209acab5e6',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '675588ef-fffd-4aad-8302-d25f9d7142bb',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ed689e79-3b07-4e76-a641-1a8293a7f744',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '72770eac-114d-4ee5-9d5b-f025db63921c',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '93f08773-353c-4fcf-856b-7636759bd6fd',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['N/A', 'Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'da46591b-a757-4173-8f69-5df13b377512',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ada0ac0-8822-46c7-afe6-68bc4f3eff16',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            "Assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd2e17a73-8c69-4375-9241-3b19f8d6a45b',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requirePrefillUniqueness: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9537bdb2-0c46-4330-9a5a-23933a03a489',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Course of treatment (medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '68c0f1ae-ff6e-4076-a71c-1167d846d2aa',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'd541afac-cac2-40be-ae46-aa5127a40125',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2974ff90-adc1-44fd-905f-d748ed3c4086',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_29_name_IntakeTherapy_v1 = {
  templateInfo: {
    id: 29,
    name: 'Intake: Therapy',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aedd25dd-d8c3-4445-a257-f840bbad1856',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6de31ea0-6141-4c74-b1e6-698a9ee89360',
          type: 'dropdown',
          title: 'Was there anyone else at the sesssion?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '62292cf6-7132-4df7-b07c-6d339aa55fd2',
          type: 'longFreeText',
          title: 'History of present illness (HPI)',
          subTitle:
            'Include the presenting illness, history, current symptoms and behaviors',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '61451e20-93b5-48a9-8bba-926c6f34bc21',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'af455077-2718-4fb1-9dcd-10252a39df06',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'ea2126a5-49f0-4444-a339-45f9bf6849ef',
          type: 'longFreeText',
          title: 'Current medications',
          subTitle:
            'Include prenatal and perinatal events, physical, psychological, social, intellectual, academic and educational history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f12df1b0-327f-4128-bc48-992e38739ccf',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include things like prenatal and perinatal events, physical, psychological, social, intellectual, academic, and educational history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6a95d728-723b-4824-92ed-484cceac0f5a',
          type: 'longFreeText',
          title: 'Family psychiatric',
          subTitle:
            'Include family history of psychiatric illness, diagnosed or undiagnosed',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bae7356-9c26-4f53-9b87-57a9d71e3d9b',
          type: 'longFreeText',
          title: 'Psychiatric',
          subTitle:
            'Include past diagnoses, hospitalizations, treatment dates, providers, facilities and types of treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a95131b8-3248-4cb0-9fce-d2502fe9fa8d',
          type: 'longFreeText',
          title: 'Medical',
          subTitle: 'Include past diagnoses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4c124c50-744a-40de-ad6c-13b9de88514f',
          type: 'longFreeText',
          title: 'Medication trials',
          subTitle: 'Include dosages, dates, and responses to medications',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '29ac19c6-4e6c-4d13-8992-f554956fff35',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '69ae3246-0540-49d4-ad95-35a42b625f82',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '4e33f601-0167-4bd2-9780-f0d21830890f',
          type: 'dropdownMulti',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8bc6d510-9ed4-41a5-be43-8eb631943b61',
          type: 'dropdownMulti',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Disheveled',
            'Well-groomed',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c47a5d74-a1ef-4c1e-8a66-8153a0108660',
          type: 'dropdownMulti',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1944062f-02fd-4ea6-b35c-18df56836bee',
          type: 'dropdownMulti',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropiate eye contact',
            'Decreased spontaneous movement',
            'Inappropiate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fd450a2a-16a3-4e03-b53a-64be3f8d9c9a',
          type: 'dropdownMulti',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ab820e44-a4ef-4223-aee2-5a799ed4f8b4',
          type: 'dropdownMulti',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b7026c12-e4c5-4e87-8883-115aa21f53e3',
          type: 'dropdownMulti',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67ed0e90-095f-41d8-8b1f-5ef8a64f7ffd',
          type: 'dropdownMulti',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bfa816ab-6ac0-4610-9403-fe4aa96a6a1b',
          type: 'dropdownMulti',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c92ae4c8-12d6-4c41-a1cb-fa2a1272884a',
          type: 'dropdownMulti',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '374dcd3c-820e-43fc-a5e6-be097e5ca0c9',
          type: 'dropdownMulti',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cd0ca951-d84f-40d8-85d3-b35b46cc836f',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8fb6752b-5af8-449b-b3c5-7300e4b92ea3',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      subHeader: 'If you identify risk, review a safety plan with the client',
      infoComponent: null,
      components: [
        {
          id: '4265f7f0-13b1-4112-a9e1-21d0b2ea37d3',
          type: 'dropdownMulti',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ba825619-18f3-4089-ac4d-8ac357a4e073',
          type: 'dropdownMulti',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Plan',
            'Recent self-harm behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6399261e-eb90-43cf-bbca-fc37105ed15a',
          type: 'dropdownMulti',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'cea68a7e-3347-4bcb-abd4-551cac6b6040',
          type: 'dropdownMulti',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '5ece7f47-627f-485c-a754-e67133a3148a',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '201c9a34-73a6-4cd9-b153-16ae8d51afdc',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b974ef18-8f72-4d6e-aafd-379e50dadae1',
          type: 'radio',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '9557af6e-27ba-48cd-b70b-ac52fdbe96f1',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40f8d040-0865-42a7-a98a-9b94f43a8735',
          type: 'dropdownMulti',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0f6f0c97-1262-4c05-93f3-39a2d768a490',
          type: 'radio',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e40c1584-62a2-4b1c-9218-e76fd3002261',
          type: 'dropdownMulti',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '576c2d4e-be6d-4128-b89c-b78468883bf9',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '89c9a9d5-388c-49de-8801-70d0f8ab91f5',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '934f909f-8e4a-443a-bdd0-e2c2ae637f92',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Include medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care ',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '74cc5bd2-9bb2-41ad-97c0-721e21bf97a2',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8c107bb0-f8df-4d2d-ac65-0c2bdd1d996e',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9dce67b5-a74a-4385-b3fc-c065dd759346',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_29_name_IntakeTherapy_v2 = {
  templateInfo: {
    id: 29,
    name: 'Intake: Therapy',
    version: 2,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aedd25dd-d8c3-4445-a257-f840bbad1856',
          type: 'shortFreeText',
          title: 'Presenting problem',
          subTitle:
            'From the client’s perspective, what are the primary concerns, stressors, current symptoms, behaviors, and impairments in life',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6de31ea0-6141-4c74-b1e6-698a9ee89360',
          type: 'dropdown',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '76e0d726-a02d-475e-a207-306517c9aa0e',
          type: 'shortFreeText',
          title: 'Allergies',
          subTitle:
            'Note must include known allergies, or if unknown, notate NKA (i.e. no known allergies)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f12df1b0-327f-4128-bc48-992e38739ccf',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include prenatal and perinatal events, as well as developmental milestones, if applicable',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6a95d728-723b-4824-92ed-484cceac0f5a',
          type: 'longFreeText',
          title: 'Family',
          subTitle:
            'Note must include family relationships and history of family’s mental health and medical issues',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bae7356-9c26-4f53-9b87-57a9d71e3d9b',
          type: 'longFreeText',
          title: 'Mental Health',
          subTitle:
            'Note must include current and past diagnoses, hospitalizations and treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a95131b8-3248-4cb0-9fce-d2502fe9fa8d',
          type: 'longFreeText',
          title: 'Physical Health',
          subTitle:
            'Note must include current and past diagnoses and treatments',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4c124c50-744a-40de-ad6c-13b9de88514f',
          type: 'longFreeText',
          title: 'Medications',
          subTitle:
            'Note must include current and past medications, relevant dosages, dates, and responses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '29ac19c6-4e6c-4d13-8992-f554956fff35',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Note must include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '69ae3246-0540-49d4-ad95-35a42b625f82',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Note must include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Disheveled',
            'Well-groomed',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropriate eye contact',
            'Decreased spontaneous movement',
            'Inappropriate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '576c2d4e-be6d-4128-b89c-b78468883bf9',
          type: 'longFreeText',
          title: 'Clinical formulation',
          subTitle:
            "Note must include assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '89c9a9d5-388c-49de-8801-70d0f8ab91f5',
          type: 'shortFreeText',
          title: 'Modality',
          subTitle:
            'Note must include type of therapy (e.g. individual, couple, group) and planned frequency of sessions (e.g. weekly)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '934f909f-8e4a-443a-bdd0-e2c2ae637f92',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Note must indicate whether client has made progress towards one or more specific objectives from the treatment plan and provide recommendations for follow-up care',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: '74cc5bd2-9bb2-41ad-97c0-721e21bf97a2',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8c107bb0-f8df-4d2d-ac65-0c2bdd1d996e',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9dce67b5-a74a-4385-b3fc-c065dd759346',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8fb6752b-5af8-449b-b3c5-7300e4b92ea3',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_29_name_IntakeTherapy_v3 = {
  templateInfo: {
    id: 29,
    name: 'Intake: Therapy',
    version: 3,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aedd25dd-d8c3-4445-a257-f840bbad1856',
          type: 'longFreeText',
          title: 'Presenting problem',
          subTitle:
            'From the client’s perspective, what are the primary concerns, stressors, current symptoms, behaviors, and impairments in life',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
        {
          id: '6de31ea0-6141-4c74-b1e6-698a9ee89360',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '76e0d726-a02d-475e-a207-306517c9aa0e',
          type: 'shortFreeText',
          title: 'Allergies',
          subTitle:
            'Note must include known allergies, or if unknown, notate NKA (i.e. no known allergies)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f12df1b0-327f-4128-bc48-992e38739ccf',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include prenatal and perinatal events, as well as developmental milestones, if applicable',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6a95d728-723b-4824-92ed-484cceac0f5a',
          type: 'longFreeText',
          title: 'Family',
          subTitle:
            'Note must include family relationships and history of family’s mental health and medical issues',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bae7356-9c26-4f53-9b87-57a9d71e3d9b',
          type: 'longFreeText',
          title: 'Mental Health',
          subTitle:
            'Note must include current and past diagnoses, hospitalizations and treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a95131b8-3248-4cb0-9fce-d2502fe9fa8d',
          type: 'longFreeText',
          title: 'Physical Health',
          subTitle:
            'Note must include current and past diagnoses and treatments',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4c124c50-744a-40de-ad6c-13b9de88514f',
          type: 'longFreeText',
          title: 'Medications',
          subTitle:
            'Note must include current and past medications, relevant dosages, dates, and responses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '29ac19c6-4e6c-4d13-8992-f554956fff35',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Note must include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '69ae3246-0540-49d4-ad95-35a42b625f82',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Note must include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Disheveled',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropriate eye contact',
            'Decreased spontaneous movement',
            'Inappropriate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '576c2d4e-be6d-4128-b89c-b78468883bf9',
          type: 'longFreeText',
          title: 'Clinical formulation',
          subTitle:
            "Assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '89c9a9d5-388c-49de-8801-70d0f8ab91f5',
          type: 'shortFreeText',
          title: 'Modality',
          subTitle:
            'Include type of therapy (e.g. individual, couple, group) and planned frequency of sessions (e.g. weekly)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '934f909f-8e4a-443a-bdd0-e2c2ae637f92',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8c107bb0-f8df-4d2d-ac65-0c2bdd1d996e',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9dce67b5-a74a-4385-b3fc-c065dd759346',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8fb6752b-5af8-449b-b3c5-7300e4b92ea3',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_29_name_IntakeTherapy_v4 = {
  templateInfo: {
    id: 29,
    name: 'Intake: Therapy',
    version: 4,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aedd25dd-d8c3-4445-a257-f840bbad1856',
          type: 'longFreeText',
          title: 'Presenting problem',
          subTitle:
            'From the client’s perspective, what are the primary concerns, stressors, current symptoms, behaviors, and impairments in life',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: 'fa7705b7-3e12-4935-a632-434c302067bc',
        },
        {
          id: '6de31ea0-6141-4c74-b1e6-698a9ee89360',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'fa7705b7-3e12-4935-a632-434c302067bc',
        },
      ],
      id: 'fa7705b7-3e12-4935-a632-434c302067bc',
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '76e0d726-a02d-475e-a207-306517c9aa0e',
          type: 'shortFreeText',
          title: 'Allergies',
          subTitle:
            'Note must include known allergies, or if unknown, notate NKA (i.e. no known allergies)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f12df1b0-327f-4128-bc48-992e38739ccf',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include prenatal and perinatal events, as well as developmental milestones, if applicable',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '6a95d728-723b-4824-92ed-484cceac0f5a',
          type: 'longFreeText',
          title: 'Family',
          subTitle:
            'Note must include family relationships and history of family’s mental health and medical issues',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '0bae7356-9c26-4f53-9b87-57a9d71e3d9b',
          type: 'longFreeText',
          title: 'Psychiatric History',
          subTitle:
            'Note must include current and past diagnoses, hospitalizations and treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: 'a95131b8-3248-4cb0-9fce-d2502fe9fa8d',
          type: 'longFreeText',
          title: 'Physical Health',
          subTitle:
            'Note must include current and past diagnoses and treatments',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '4c124c50-744a-40de-ad6c-13b9de88514f',
          type: 'longFreeText',
          title: 'Medications',
          subTitle:
            'Note must include current and past medications, relevant dosages, dates, and responses',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '29ac19c6-4e6c-4d13-8992-f554956fff35',
          type: 'longFreeText',
          title: 'Social',
          subTitle:
            'Note must include work history, education, social supports, sexual history and living arrangements',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '69ae3246-0540-49d4-ad95-35a42b625f82',
          type: 'longFreeText',
          title: 'Substance use',
          subTitle:
            'Note must include substances used, amount, frequency and prior treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
      ],
      id: '34948467-a78c-47ed-a4e5-a117d3233c19',
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'bbe62931-ca0f-4582-a854-e31d66cccf43',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '5b791102-b2aa-4b43-9659-f03e3ccb9c3d',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Disheveled',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '523a5df5-9063-421d-ae56-ee4a2b7b14fc',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['Within normal limits', 'Impaired', 'Intact', 'Other'],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '322f7604-8e8b-47fd-a585-d7179c70a71f',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Appropriate eye contact',
            'Decreased spontaneous movement',
            'Inappropriate eye contact',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '2e8d5365-ce1b-4ffc-8521-4610f7c0489d',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Impaired',
            'Improving',
            'Intact',
            'Poor',
            'Worsening',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'eb426789-6098-4425-b4a4-4e48e3dda44e',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '4af76bff-8bbd-401b-9790-d30ae91f01d7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '8143c6d8-c9c6-423b-a84d-afeed53069d7',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Latency',
            'Pressured',
            'Talkative',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'facddb46-dc47-43b8-b440-58630d864d66',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'f8775d6a-1faa-4cdc-baf2-404136e0980e',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
      ],
      id: '633977c8-26a4-41a2-8080-0e516a5a7d67',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
      ],
      id: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
    },
    {
      id: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
            'History of unresponsiveness to treatment',
            'Impulsivity',
            'Lack of insight',
            'Other antisocial behaviors',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Chronic and/or acute physical pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
            'Employment problems and/or unemployment',
            'Lack of engagement in appropriate psychiatric treatment',
            'Relation instability / poor social support',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '21a5b609-0d08-450d-8bed-8ac5a00d8edf',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Support justification for level of treatment service, frequency, duration and modality',
          placeholder: '',
          description:
            'Example: “Joe is appropriate for outpatient services and weekly individual sessions are warranted to address Major Depression. ”',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '576c2d4e-be6d-4128-b89c-b78468883bf9',
          type: 'longFreeText',
          title: 'Clinical formulation and differential diagnostic support',
          subTitle:
            "Assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '89c9a9d5-388c-49de-8801-70d0f8ab91f5',
          type: 'shortFreeText',
          title: 'Modality',
          subTitle: 'Include type of therapy (e.g. individual, couple, group)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '934f909f-8e4a-443a-bdd0-e2c2ae637f92',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: 'b651ffc1-3761-4975-8a5a-3977cb4b54a2',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '453d64b6-6707-4a98-9b46-8863caebf05a',
          type: 'checkbox',
          title:
            'A separate treatment plan will be created before the end of the first psychotherapy session, or within 14 days of the initial appointment, whichever is sooner.',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
      ],
      id: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8c107bb0-f8df-4d2d-ac65-0c2bdd1d996e',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
        },
        {
          id: '9dce67b5-a74a-4385-b3fc-c065dd759346',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
        },
      ],
      id: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8fb6752b-5af8-449b-b3c5-7300e4b92ea3',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '8bb97d90-55bf-4986-b2e7-7a6dc87008d0',
        },
      ],
      id: '8bb97d90-55bf-4986-b2e7-7a6dc87008d0',
    },
  ],
  state: {},
};
export const id_29_name_IntakeTherapy_v5 = {
  templateInfo: {
    id: 29,
    name: 'Intake: Therapy',
    version: 5,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aedd25dd-d8c3-4445-a257-f840bbad1856',
          type: 'longFreeText',
          title: 'Presenting problem',
          subTitle:
            'Reason for seeking treatment: focus on issues/concerns/problems which you will be helping with, specific psychiatric symptoms, functional status. May include psychiatric diagnoses, life transitions, recent stressors, other relevant events.',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: 'fa7705b7-3e12-4935-a632-434c302067bc',
        },
        {
          id: '6de31ea0-6141-4c74-b1e6-698a9ee89360',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          optionalityText: 'If applicable',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'fa7705b7-3e12-4935-a632-434c302067bc',
        },
      ],
      id: 'fa7705b7-3e12-4935-a632-434c302067bc',
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '0bae7356-9c26-4f53-9b87-57a9d71e3d9b',
          type: 'longFreeText',
          title: 'Psychiatric history',
          subTitle:
            'Note must include current and past diagnoses, hospitalizations and treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '69ae3246-0540-49d4-ad95-35a42b625f82',
          type: 'longFreeText',
          title: 'Alcohol and substance use',
          subTitle:
            'Note must include current and past substances used, amount, and frequency. If relevant, include treatment history, any extended periods of abstinence or previous recovery experience and effective of behavioral health symptoms, past tolerance or withdrawal, negative consequences to use, DUIs, etc.',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'a95131b8-3248-4cb0-9fce-d2502fe9fa8d',
          type: 'longFreeText',
          title: 'Medical history / Physical health',
          subTitle:
            'Note must include current and past diagnoses and treatments',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f51a8bb3-7f82-4d38-94c1-8adbc7eb7e1f',
          type: 'radio',
          title:
            'Are there other relevant providers with whom the patient is collaborating (PCP or any other medical / mental health providers)?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '3e46f001-cca5-49ea-a4db-93ad19cd91fc',
          type: 'shortFreeText',
          title: 'Name of provider',
          subTitle: 'If yes to above',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'fd98a871-4b32-4d5b-b4c2-0d99f7dbe094',
          type: 'shortFreeText',
          title:
            'Provider type (e.g. primary care physician, psychiatrist, etc.)',
          subTitle: 'If yes to above',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'd0556f68-41ea-4c1c-aeac-bcba458d299d',
          type: 'shortFreeText',
          title: 'Provider contact information',
          subTitle: 'If yes to above',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f163c941-e99e-4d7e-b1c1-b0c702e6ea30',
          type: 'longFreeTextWithCheckbox',
          title: 'Medications',
          subTitle:
            'Note must include current and past medications, relevant dosages, dates, and responses',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known medications',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '59c88c1a-417e-4d10-837b-893d22595729',
          type: 'longFreeTextWithCheckbox',
          title: 'Allergies',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known allergies',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '29ac19c6-4e6c-4d13-8992-f554956fff35',
          type: 'longFreeText',
          title: 'Social / relationships (current and past)',
          subTitle: 'General support, family/friends, romantic relationships',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '6a95d728-723b-4824-92ed-484cceac0f5a',
          type: 'longFreeText',
          title: 'Family history',
          subTitle:
            'Family dynamics and involvement; family psychiatric history including family history of suicide attempts',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f12df1b0-327f-4128-bc48-992e38739ccf',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include prenatal and perinatal events, as well as developmental milestones, if applicable',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '3dc42284-d8c2-4cac-883f-8a84c6ad74b3',
          type: 'longFreeTextWithCheckbox',
          title: 'Trauma history',
          subTitle: 'Abuse, neglect, violence, assault, exploitation',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known trauma',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
      ],
      id: '34948467-a78c-47ed-a4e5-a117d3233c19',
    },
    {
      header: 'History of harm',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'No history of harm to self or others',
      },
      components: [
        {
          id: '2e62b575-a144-430e-a9fd-11768edfaa2e',
          type: 'longFreeTextWithCheckbox',
          title: 'History of non-lethal self-injurious behaviors',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse:
            'No known history of non-lethal self-injurious behavior',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
        {
          id: '6780fdf3-5c82-449c-813f-e12891758a94',
          type: 'longFreeTextWithCheckbox',
          title: 'History of suicidal ideation',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known history of suicidal ideation',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
        {
          id: 'cb52d66b-30e2-4277-a995-e3676d22df49',
          type: 'longFreeTextWithCheckbox',
          title: 'History of suicide attempts',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known history of suicide attempts',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
        {
          id: '39396c75-79bc-4972-b1db-6e0ecd0dbcb5',
          type: 'longFreeTextWithCheckbox',
          title: 'History of harm to others and violent/destructive behavior',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known history of harm to others',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
      ],
      id: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: '9bd0316c-d597-44cf-8039-874afde5818c',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: '',
          options: [
            'Alert (within normal limits)',
            'Somnolent (sleepy)',
            'Lethargic (very sleepy)',
            'Obtunded (difficult to arouse)',
            'Stupor (very difficult to arouse)',
            'Comatose (unarousable)',
            'Selective attention',
            'Confused',
            'Delirium (fluctuating level of consciousness)',
            'Other',
          ],
          defaultResponse: 'Alert (within normal limits)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'f134275e-0ecf-4cd1-a4d3-6ebeb3c0c85a',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: '',
          options: [
            'Oriented to person, place, time, and situation (within normal limits)',
            'Oriented to person',
            'Oriented to place',
            'Oriented to time',
            'Oriented to situation',
            'Other',
          ],
          defaultResponse:
            'Oriented to person, place, time, and situation (within normal limits)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'fdedf1c1-c721-4ff4-a6c8-39555519d46e',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: '',
          options: [
            'Within normal limits; adequate hygiene, well groomed',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Abnormal motor activity',
            'Disheveled',
            'Unkempt',
            'Other',
          ],
          defaultResponse:
            'Within normal limits; adequate hygiene, well groomed',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'aa015238-6731-4317-a93f-c3221cc496bc',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: '',
          options: [
            'Within normal limits; appropriate eye contact, normal motor activity',
            'Inappropriate eye contact',
            'Decreased spontaneous movement',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse:
            'Within normal limits; appropriate eye contact, normal motor activity',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'ea9b38fc-6b51-488c-ac1d-cbbe7aff84b4',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Delayed',
            'Pressured',
            'Hyperverbal',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'd324876b-3b6f-4aec-8bf9-77d06124dc78',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits (full); congruent with mood',
            'Incongruent with mood',
            'Restricted',
            'Blunted',
            'Flat',
            'Labile',
            'Other',
          ],
          defaultResponse: 'Within normal limits (full); congruent with mood',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '8a90f517-fb84-4bf3-b3d1-186062beab5b',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Euthymic',
            'Sad/Dysthymic',
            'Depressed',
            'Irritable',
            'Anxious',
            'Elevated',
            'Expansive',
            'Euphoric',
            'Hopelessness',
            'Angry',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'f2d6a82c-40d7-4ff9-893f-dc4c8109ea8d',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits (linear, goal directed)',
            'Circumferential',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Disorganization',
            'Loose association',
            'Word salad',
            'Internal preoccupation',
            'Blocking',
            'Flight of ideas',
            'Other',
          ],
          defaultResponse: 'Within normal limits (linear, goal directed)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'df387d5c-89cc-4fca-966c-72cff3baf458',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Visual hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Poverty of thought content',
            'Obsessions',
            'Paranoid ideation',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'c41c53b6-dc31-49b3-9d0c-ddea3a8abfc4',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Poor',
            'Impaired',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
      ],
      id: '633977c8-26a4-41a2-8080-0e516a5a7d67',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: 'fa7a9cdc-bdf9-43a4-b55a-f3d7283638e1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '42fc40e5-61d7-475b-9619-2f78a5d03fbb',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
      ],
      id: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
    },
    {
      id: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'Impulsivity',
            'History of violent behaviors',
            'History of unresponsiveness to treatment',
            'Lack of insight',
            'Other antisocial behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic and/or acute physical pain',
            'Employment problems and/or unemployment',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Lack of engagement in appropriate psychiatric treatment',
            'Mood/anxiety disorder',
            'Psychosis',
            'Relation instability / poor social support',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'Cultural/moral/religious beliefs',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '352298da-b803-4f1a-a7e8-f292c2eb724a',
          type: 'longFreeText',
          title: 'Clinical summary',
          subTitle:
            'Clinical presentation of the client, and how the factors above contribute to their symptoms',
          placeholder: '',
          description: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '21a5b609-0d08-450d-8bed-8ac5a00d8edf',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Support justification for level of treatment service, frequency, duration and modality',
          placeholder: '',
          description:
            'Example: “Joe is appropriate for outpatient services and weekly individual sessions are warranted to address Major Depression. ”',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '576c2d4e-be6d-4128-b89c-b78468883bf9',
          type: 'longFreeText',
          title: 'Clinical formulation and differential diagnostic support',
          subTitle:
            "Assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: 'b7e2d788-ecd0-49e2-bcee-7fa7bc1a86db',
          type: 'radio',
          title: 'Expected length of care',
          subTitle: '',
          placeholder: '',
          options: ['1-3 months', '3-6 months', '6-9 months', 'Chronic care'],
          defaultResponse: '1-3 months',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: 'fcff1d40-2063-492e-afc0-cfdb7d8cf4f3',
          type: 'radio',
          title: 'Prognosis',
          subTitle: '',
          placeholder: '',
          options: ['Poor', 'Fair', 'Good'],
          defaultResponse: 'Poor',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: '934f909f-8e4a-443a-bdd0-e2c2ae637f92',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: 'b651ffc1-3761-4975-8a5a-3977cb4b54a2',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'e383d882-2ae7-463b-b2f6-fa4b3954548c',
          type: 'radio',
          title:
            'Release of Information (ROI) requested and signed from client for coordination of care with another provider?',
          subTitle: '',
          placeholder: '',
          options: [
            'Yes',
            'No (therapist requested, but client declined to sign)',
          ],
          defaultResponse: 'Yes',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: '05160725-3072-4bbc-9fff-70ed46909540',
          type: 'checklist',
          title:
            'Interactive complexity element (only required for CPT code 90785)',
          subTitle:
            'Symptom evidence that support the client diagnosis. This statement must be supported and consistent with the information in the presenting issues and psychiatric history.',
          placeholder: 'Choose relevant options',
          options: [
            'Maladaptive communication among multiple participants',
            'Caregiver emotions or behavior that interferes with implementation of treatment plan',
            'Evidence or disclosure of a sentinel event and a mandated report to a third party',
            'Use of play equipment, physical devices, or translator to overcome significant communication barrier',
          ],
          defaultResponse: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [90785],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '89c9a9d5-388c-49de-8801-70d0f8ab91f5',
          type: 'shortFreeText',
          title: 'Modality',
          subTitle:
            'Include type of therapy planned and/or recommended (e.g. individual, family, group)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '453d64b6-6707-4a98-9b46-8863caebf05a',
          type: 'checkbox',
          title:
            'A separate treatment plan will be created before the end of the first psychotherapy session, or within 14 days of the initial appointment, whichever is sooner.',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
      ],
      id: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '16964fcf-c46c-402c-9cfd-2a2e08d38c8d',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Mental health assessments', 'Labs', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '8bb97d90-55bf-4986-b2e7-7a6dc87008d0',
        },
      ],
      id: '8bb97d90-55bf-4986-b2e7-7a6dc87008d0',
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8c107bb0-f8df-4d2d-ac65-0c2bdd1d996e',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
        },
        {
          id: '9dce67b5-a74a-4385-b3fc-c065dd759346',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
        },
      ],
      id: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
    },
  ],
  state: {},
};
export const id_29_name_IntakeTherapy_v6 = {
  templateInfo: {
    id: 29,
    name: 'Intake: Therapy',
    version: 6,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'aedd25dd-d8c3-4445-a257-f840bbad1856',
          type: 'longFreeText',
          title: 'Presenting problem',
          subTitle:
            'Reason for seeking treatment: focus on issues/concerns/problems which you will be helping with, specific psychiatric symptoms, functional status. May include psychiatric diagnoses, life transitions, recent stressors, other relevant events.',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: 'fa7705b7-3e12-4935-a632-434c302067bc',
        },
        {
          id: '6de31ea0-6141-4c74-b1e6-698a9ee89360',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          optionalityText: 'If applicable',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'fa7705b7-3e12-4935-a632-434c302067bc',
        },
      ],
      id: 'fa7705b7-3e12-4935-a632-434c302067bc',
    },
    {
      header: 'History',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '0bae7356-9c26-4f53-9b87-57a9d71e3d9b',
          type: 'longFreeText',
          title: 'Psychiatric history',
          subTitle:
            'Note must include current and past diagnoses, hospitalizations and treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '69ae3246-0540-49d4-ad95-35a42b625f82',
          type: 'longFreeText',
          title: 'Alcohol and substance use',
          subTitle:
            'Note must include current and past substances used, amount, and frequency. If relevant, include treatment history, any extended periods of abstinence or previous recovery experience and effective of behavioral health symptoms, past tolerance or withdrawal, negative consequences to use, DUIs, etc.',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'a95131b8-3248-4cb0-9fce-d2502fe9fa8d',
          type: 'longFreeText',
          title: 'Medical history / Physical health',
          subTitle:
            'Note must include current and past diagnoses and treatments',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f51a8bb3-7f82-4d38-94c1-8adbc7eb7e1f',
          type: 'radio',
          title:
            'Are there other relevant providers with whom the patient is collaborating (PCP or any other medical / mental health providers)?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '3e46f001-cca5-49ea-a4db-93ad19cd91fc',
          type: 'shortFreeText',
          title: 'Name of provider',
          subTitle: 'If yes to above',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'fd98a871-4b32-4d5b-b4c2-0d99f7dbe094',
          type: 'shortFreeText',
          title:
            'Provider type (e.g. primary care physician, psychiatrist, etc.)',
          subTitle: 'If yes to above',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'd0556f68-41ea-4c1c-aeac-bcba458d299d',
          type: 'shortFreeText',
          title: 'Provider contact information',
          subTitle: 'If yes to above',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f163c941-e99e-4d7e-b1c1-b0c702e6ea30',
          type: 'longFreeTextWithCheckbox',
          title: 'Medications',
          subTitle:
            'Note must include current and past medications, relevant dosages, dates, and responses',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known medications',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '59c88c1a-417e-4d10-837b-893d22595729',
          type: 'longFreeTextWithCheckbox',
          title: 'Allergies',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known allergies',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '29ac19c6-4e6c-4d13-8992-f554956fff35',
          type: 'longFreeText',
          title: 'Social / relationships (current and past)',
          subTitle: 'General support, family/friends, romantic relationships',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '6a95d728-723b-4824-92ed-484cceac0f5a',
          type: 'longFreeText',
          title: 'Family history',
          subTitle:
            'Family dynamics and involvement; family psychiatric history including family history of suicide attempts',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: 'f12df1b0-327f-4128-bc48-992e38739ccf',
          type: 'longFreeText',
          title: 'Developmental (if the client is under 18)',
          subTitle:
            'Include prenatal and perinatal events, as well as developmental milestones, if applicable',
          placeholder: '',
          options: '',
          shouldDisplay: [
            {
              functionName: 'isPatientAgeInInclusiveRange',
              functionArguments: [0, 18],
            },
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
        {
          id: '3dc42284-d8c2-4cac-883f-8a84c6ad74b3',
          type: 'longFreeTextWithCheckbox',
          title: 'Trauma history',
          subTitle: 'Abuse, neglect, violence, assault, exploitation',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known trauma',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '34948467-a78c-47ed-a4e5-a117d3233c19',
        },
      ],
      id: '34948467-a78c-47ed-a4e5-a117d3233c19',
    },
    {
      header: 'History of harm',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'No history of harm to self or others',
      },
      components: [
        {
          id: '2e62b575-a144-430e-a9fd-11768edfaa2e',
          type: 'longFreeTextWithCheckbox',
          title: 'History of non-lethal self-injurious behaviors',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse:
            'No known history of non-lethal self-injurious behavior',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
        {
          id: '6780fdf3-5c82-449c-813f-e12891758a94',
          type: 'longFreeTextWithCheckbox',
          title: 'History of suicidal ideation',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known history of suicidal ideation',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
        {
          id: 'cb52d66b-30e2-4277-a995-e3676d22df49',
          type: 'longFreeTextWithCheckbox',
          title: 'History of suicide attempts',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known history of suicide attempts',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
        {
          id: '39396c75-79bc-4972-b1db-6e0ecd0dbcb5',
          type: 'longFreeTextWithCheckbox',
          title: 'History of harm to others and violent/destructive behavior',
          subTitle: '',
          placeholder: 'Please provide details',
          options: '',
          defaultResponse: 'No known history of harm to others',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
        },
      ],
      id: 'fd1623bd-25f8-498d-b375-df231a03ac5e',
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: '9bd0316c-d597-44cf-8039-874afde5818c',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: '',
          options: [
            'Alert (within normal limits)',
            'Somnolent (sleepy)',
            'Lethargic (very sleepy)',
            'Obtunded (difficult to arouse)',
            'Stupor (very difficult to arouse)',
            'Comatose (unarousable)',
            'Selective attention',
            'Confused',
            'Delirium (fluctuating level of consciousness)',
            'Other',
          ],
          defaultResponse: 'Alert (within normal limits)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'f134275e-0ecf-4cd1-a4d3-6ebeb3c0c85a',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: '',
          options: [
            'Oriented to person, place, time, and situation (within normal limits)',
            'Oriented to person',
            'Oriented to place',
            'Oriented to time',
            'Oriented to situation',
            'Other',
          ],
          defaultResponse:
            'Oriented to person, place, time, and situation (within normal limits)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'fdedf1c1-c721-4ff4-a6c8-39555519d46e',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: '',
          options: [
            'Within normal limits; adequate hygiene, well groomed',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Abnormal motor activity',
            'Disheveled',
            'Unkempt',
            'Other',
          ],
          defaultResponse:
            'Within normal limits; adequate hygiene, well groomed',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'aa015238-6731-4317-a93f-c3221cc496bc',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: '',
          options: [
            'Within normal limits; appropriate eye contact, normal motor activity',
            'Inappropriate eye contact',
            'Decreased spontaneous movement',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse:
            'Within normal limits; appropriate eye contact, normal motor activity',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'ea9b38fc-6b51-488c-ac1d-cbbe7aff84b4',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Delayed',
            'Pressured',
            'Hyperverbal',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'd324876b-3b6f-4aec-8bf9-77d06124dc78',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits (full); congruent with mood',
            'Incongruent with mood',
            'Restricted',
            'Blunted',
            'Flat',
            'Labile',
            'Other',
          ],
          defaultResponse: 'Within normal limits (full); congruent with mood',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '8a90f517-fb84-4bf3-b3d1-186062beab5b',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Euthymic',
            'Sad/Dysthymic',
            'Depressed',
            'Irritable',
            'Anxious',
            'Elevated',
            'Expansive',
            'Euphoric',
            'Hopelessness',
            'Angry',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'f2d6a82c-40d7-4ff9-893f-dc4c8109ea8d',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits (linear, goal directed)',
            'Circumferential',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Disorganization',
            'Loose association',
            'Word salad',
            'Internal preoccupation',
            'Blocking',
            'Flight of ideas',
            'Other',
          ],
          defaultResponse: 'Within normal limits (linear, goal directed)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'df387d5c-89cc-4fca-966c-72cff3baf458',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Visual hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Poverty of thought content',
            'Obsessions',
            'Paranoid ideation',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: 'c41c53b6-dc31-49b3-9d0c-ddea3a8abfc4',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Poor',
            'Impaired',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '10d64634-b64c-4b24-9705-63bacf108631',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
        {
          id: '67536248-bcfb-49a7-92d4-555dbcaddfb9',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '633977c8-26a4-41a2-8080-0e516a5a7d67',
        },
      ],
      id: '633977c8-26a4-41a2-8080-0e516a5a7d67',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: 'fa7a9cdc-bdf9-43a4-b55a-f3d7283638e1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '42fc40e5-61d7-475b-9619-2f78a5d03fbb',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
        },
      ],
      id: 'aa3bc22b-0666-43f1-8c49-c20dde77c803',
    },
    {
      id: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'Impulsivity',
            'History of violent behaviors',
            'History of unresponsiveness to treatment',
            'Lack of insight',
            'Other antisocial behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic and/or acute physical pain',
            'Employment problems and/or unemployment',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Lack of engagement in appropriate psychiatric treatment',
            'Mood/anxiety disorder',
            'Psychosis',
            'Relation instability / poor social support',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'Cultural/moral/religious beliefs',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '1c33212d-d6fd-4996-b1b4-283a60ef7600',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '352298da-b803-4f1a-a7e8-f292c2eb724a',
          type: 'longFreeText',
          title: 'Clinical summary',
          subTitle:
            'Clinical presentation of the client, and how the factors above contribute to their symptoms',
          placeholder: '',
          description: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '21a5b609-0d08-450d-8bed-8ac5a00d8edf',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Support justification for level of treatment service, frequency, duration and modality',
          placeholder: '',
          description:
            'Example: “Joe is appropriate for outpatient services and weekly individual sessions are warranted to address Major Depression. ”',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '576c2d4e-be6d-4128-b89c-b78468883bf9',
          type: 'longFreeText',
          title: 'Clinical formulation and differential diagnostic support',
          subTitle:
            "Assessment of client's presentation and justification for your diagnosis",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: 'b7e2d788-ecd0-49e2-bcee-7fa7bc1a86db',
          type: 'radio',
          title: 'Expected length of care',
          subTitle: '',
          placeholder: '',
          options: ['1-3 months', '3-6 months', '6-9 months', 'Chronic care'],
          defaultResponse: '1-3 months',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: 'fcff1d40-2063-492e-afc0-cfdb7d8cf4f3',
          type: 'radio',
          title: 'Prognosis',
          subTitle: '',
          placeholder: '',
          options: ['Poor', 'Fair', 'Good'],
          defaultResponse: 'Poor',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: '934f909f-8e4a-443a-bdd0-e2c2ae637f92',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: 'b651ffc1-3761-4975-8a5a-3977cb4b54a2',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'e383d882-2ae7-463b-b2f6-fa4b3954548c',
          type: 'radio',
          title:
            'Release of Information (ROI) requested and signed from client for coordination of care with another provider?',
          subTitle: '',
          placeholder: '',
          options: [
            'Yes',
            'No (therapist requested, but client declined to sign)',
          ],
          defaultResponse: 'Yes',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
        {
          id: '05160725-3072-4bbc-9fff-70ed46909540',
          type: 'checklist',
          title:
            'Interactive complexity element (only required for CPT code 90785)',
          subTitle:
            'Symptom evidence that support the client diagnosis. This statement must be supported and consistent with the information in the presenting issues and psychiatric history.',
          placeholder: 'Choose relevant options',
          options: [
            'Maladaptive communication among multiple participants',
            'Caregiver emotions or behavior that interferes with implementation of treatment plan',
            'Evidence or disclosure of a sentinel event and a mandated report to a third party',
            'Use of play equipment, physical devices, or translator to overcome significant communication barrier',
          ],
          defaultResponse: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [90785],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '89c9a9d5-388c-49de-8801-70d0f8ab91f5',
          type: 'shortFreeText',
          title: 'Modality',
          subTitle:
            'Include type of therapy planned and/or recommended (e.g. individual, family, group)',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '453d64b6-6707-4a98-9b46-8863caebf05a',
          type: 'checkbox',
          title:
            'A separate treatment plan will be created before the end of the first session after the intake session, or within 14 days of the intake session, whichever is sooner.',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
          sectionParentUUID: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
        },
      ],
      id: '5478a413-d747-4b3f-95bb-97f3d58e80e0',
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '16964fcf-c46c-402c-9cfd-2a2e08d38c8d',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Mental health assessments', 'Labs', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '8bb97d90-55bf-4986-b2e7-7a6dc87008d0',
        },
      ],
      id: '8bb97d90-55bf-4986-b2e7-7a6dc87008d0',
    },
    {
      header: 'Language (California-only)',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '8c107bb0-f8df-4d2d-ac65-0c2bdd1d996e',
          type: 'dropdown',
          title: "What's the client's preferred language?",
          subTitle: '',
          placeholder: 'Choose a language',
          options: [
            'English',
            'Cantonese',
            'French',
            'Mandarin',
            'Russian',
            'Spanish',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
        },
        {
          id: '9dce67b5-a74a-4385-b3fc-c065dd759346',
          type: 'dropdown',
          title: 'Did you offer a qualified interpreter for the evaluation?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: [
            'Yes - interpreter was offered and used',
            'Yes - but interpreter was declined',
            "No - interpreter wasn't offered (please provide explanation)",
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
        },
      ],
      id: 'ab706ba1-3472-42c3-baed-3c6888b3ec27',
    },
  ],
  state: {},
};
export const id_30_name_ProgNoteTherapyAndMedical_v1 = {
  templateInfo: {
    id: 30,
    name: 'Progress note: Therapy and medical services',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '579e79ee-6e85-4e98-937c-863803120ba5',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15ab8624-4dc8-4784-aee7-dd3bd809f86f',
          type: 'dropdown',
          title: 'Was there anyone else at the sesssion?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '976af80b-5058-44ab-99de-88ef3d9c2f05',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            "Include a summary of changes since the last session, treatment adherence and the client's feedback and response",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '9f32060a-4bdd-466d-87a3-2d52ffff427d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '254fb158-23bf-48c1-b9fc-6fa6b301572a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '00183f99-5fb2-4621-8237-63a024eda127',
          type: 'dropdownMulti',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7dc08a70-2f53-4863-ba81-f9772f149b25',
          type: 'dropdownMulti',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fdba91d6-6dea-4622-85b0-bb78bf78ce8f',
          type: 'dropdownMulti',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ab661a1-7405-4a99-80ee-9630bfacbdf0',
          type: 'dropdownMulti',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ea2c5c03-7062-4f56-addc-d869e25083d1',
          type: 'dropdownMulti',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c3418419-21c0-4187-b0e1-d4cfb7b2405d',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'e244ba4d-aa2a-4c4f-968a-424260676c41',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fc5c2c19-2db6-4b9c-a9fb-5027f7c90e94',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'dd51acc8-5235-4187-8bc5-0d553c7978e5',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '573c2d3a-781d-43d2-9b2a-52def9a9d3fe',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b0cbd9e-2fe4-4a37-99cd-fda08694949a',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e7ab2c90-abbd-43c0-8d12-9df58cd1e24f',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7e8f62bf-f113-4905-8428-32955c19cbea',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'edc896e8-4b66-468e-99e4-bf0fe1f8f75f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ddf7c6c1-9403-46cb-bf21-068931b98014',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '121908c6-da68-42c5-b567-5094dd5a687c',
          type: 'dropdownMulti',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e6cbcc64-d8ff-4217-9d24-0dab89b64784',
          type: 'dropdownMulti',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'dbed9172-6d81-4983-a87e-b65329a2364a',
          type: 'dropdownMulti',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '7ad8db90-6188-4270-a70d-b8bb1e33cdc5',
          type: 'dropdownMulti',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '8944e408-db82-46fd-ac83-12f00dc21003',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e1af5cec-3a90-4f64-a6cf-6b51e8b69bdc',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1c981a5d-c180-49a9-af6d-ec66cef68631',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'ea9061d6-858f-46b9-9f16-9492dfef91d4',
          type: 'dropdownMulti',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '8b55238b-9275-438b-9245-ebe3d85d1bb2',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2b2f7042-11bd-41ec-ac2e-48f0bc393b8f',
          type: 'dropdownMulti',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'f6eb7ae4-b011-47dc-aebf-ab9e44a248e1',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf951761-f42a-4e81-8f93-db9f09d27c5f',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a7392e55-802b-4ee9-a1e2-3cf64519058d',
          type: 'longFreeText',
          title: 'Plan',
          subTitle: 'Include medication changes and treatment progress',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5bdf3524-2d4e-489d-907e-ce5160deceaa',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_30_name_ProgNoteTherapyAndMedical_v2 = {
  templateInfo: {
    id: 30,
    name: 'Progress note: Therapy and medical services',
    version: 2,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '579e79ee-6e85-4e98-937c-863803120ba5',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15ab8624-4dc8-4784-aee7-dd3bd809f86f',
          type: 'dropdown',
          title: 'Was there anyone else at the sesssion?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '976af80b-5058-44ab-99de-88ef3d9c2f05',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            "Note must include a summary of changes since the last session, treatment adherence and the client's feedback and response",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '9f32060a-4bdd-466d-87a3-2d52ffff427d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '254fb158-23bf-48c1-b9fc-6fa6b301572a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: '00183f99-5fb2-4621-8237-63a024eda127',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7dc08a70-2f53-4863-ba81-f9772f149b25',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fdba91d6-6dea-4622-85b0-bb78bf78ce8f',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ab661a1-7405-4a99-80ee-9630bfacbdf0',
          type: 'checklist',
          title: 'Thought content',
          defaultResponse: 'Within normal limits',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ea2c5c03-7062-4f56-addc-d869e25083d1',
          type: 'checklist',
          title: 'Thought process',
          defaultResponse: 'Within normal limits',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c3418419-21c0-4187-b0e1-d4cfb7b2405d',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'e244ba4d-aa2a-4c4f-968a-424260676c41',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fc5c2c19-2db6-4b9c-a9fb-5027f7c90e94',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'dd51acc8-5235-4187-8bc5-0d553c7978e5',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '573c2d3a-781d-43d2-9b2a-52def9a9d3fe',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b0cbd9e-2fe4-4a37-99cd-fda08694949a',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e7ab2c90-abbd-43c0-8d12-9df58cd1e24f',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7e8f62bf-f113-4905-8428-32955c19cbea',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'edc896e8-4b66-468e-99e4-bf0fe1f8f75f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ddf7c6c1-9403-46cb-bf21-068931b98014',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'f6eb7ae4-b011-47dc-aebf-ab9e44a248e1',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf951761-f42a-4e81-8f93-db9f09d27c5f',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a7392e55-802b-4ee9-a1e2-3cf64519058d',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Note must include medication changes and treatment progress',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: '5bdf3524-2d4e-489d-907e-ce5160deceaa',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_30_name_ProgNoteTherapyAndMedical_v3 = {
  templateInfo: {
    id: 30,
    name: 'Progress note: Therapy and medical services',
    version: 3,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '579e79ee-6e85-4e98-937c-863803120ba5',
          type: 'longFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15ab8624-4dc8-4784-aee7-dd3bd809f86f',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '976af80b-5058-44ab-99de-88ef3d9c2f05',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            "Note must include a summary of changes since the last session, treatment adherence and the client's feedback and response",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '9f32060a-4bdd-466d-87a3-2d52ffff427d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '254fb158-23bf-48c1-b9fc-6fa6b301572a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: '00183f99-5fb2-4621-8237-63a024eda127',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7dc08a70-2f53-4863-ba81-f9772f149b25',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fdba91d6-6dea-4622-85b0-bb78bf78ce8f',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ab661a1-7405-4a99-80ee-9630bfacbdf0',
          type: 'checklist',
          title: 'Thought content',
          defaultResponse: 'Within normal limits',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ea2c5c03-7062-4f56-addc-d869e25083d1',
          type: 'checklist',
          title: 'Thought process',
          defaultResponse: 'Within normal limits',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c3418419-21c0-4187-b0e1-d4cfb7b2405d',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'e244ba4d-aa2a-4c4f-968a-424260676c41',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fc5c2c19-2db6-4b9c-a9fb-5027f7c90e94',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'dd51acc8-5235-4187-8bc5-0d553c7978e5',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '573c2d3a-781d-43d2-9b2a-52def9a9d3fe',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b0cbd9e-2fe4-4a37-99cd-fda08694949a',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e7ab2c90-abbd-43c0-8d12-9df58cd1e24f',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7e8f62bf-f113-4905-8428-32955c19cbea',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
          },
        },
        {
          id: 'edc896e8-4b66-468e-99e4-bf0fe1f8f75f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['N/A', 'Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ddf7c6c1-9403-46cb-bf21-068931b98014',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'f6eb7ae4-b011-47dc-aebf-ab9e44a248e1',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress, issues addressed, and therapeutic interventions',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requirePrefillUniqueness: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf951761-f42a-4e81-8f93-db9f09d27c5f',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a7392e55-802b-4ee9-a1e2-3cf64519058d',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Course of treatment (medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: '5bdf3524-2d4e-489d-907e-ce5160deceaa',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_30_name_ProgNoteTherapyAndMedical_v4 = {
  templateInfo: {
    id: 30,
    name: 'Progress note: Therapy and medical services',
    version: 4,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '579e79ee-6e85-4e98-937c-863803120ba5',
          type: 'longFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '15ab8624-4dc8-4784-aee7-dd3bd809f86f',
          type: 'shortFreeText',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '976af80b-5058-44ab-99de-88ef3d9c2f05',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            'Please include relevant information on psychosocial history (e.g., family, work, education), trauma history, medical history, and substance use history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd8756e3d-bd5d-4894-84e9-ffb2b8f54f41',
          type: 'longFreeText',
          title: 'Allergies',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '9f32060a-4bdd-466d-87a3-2d52ffff427d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '254fb158-23bf-48c1-b9fc-6fa6b301572a',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: '00183f99-5fb2-4621-8237-63a024eda127',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '7dc08a70-2f53-4863-ba81-f9772f149b25',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fdba91d6-6dea-4622-85b0-bb78bf78ce8f',
          type: 'checklist',
          defaultResponse: 'Within normal limits',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '5ab661a1-7405-4a99-80ee-9630bfacbdf0',
          type: 'checklist',
          title: 'Thought content',
          defaultResponse: 'Within normal limits',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ea2c5c03-7062-4f56-addc-d869e25083d1',
          type: 'checklist',
          title: 'Thought process',
          defaultResponse: 'Within normal limits',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c3418419-21c0-4187-b0e1-d4cfb7b2405d',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'e244ba4d-aa2a-4c4f-968a-424260676c41',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'fc5c2c19-2db6-4b9c-a9fb-5027f7c90e94',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'dd51acc8-5235-4187-8bc5-0d553c7978e5',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '573c2d3a-781d-43d2-9b2a-52def9a9d3fe',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0b0cbd9e-2fe4-4a37-99cd-fda08694949a',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e7ab2c90-abbd-43c0-8d12-9df58cd1e24f',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7e8f62bf-f113-4905-8428-32955c19cbea',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
          },
        },
        {
          id: 'edc896e8-4b66-468e-99e4-bf0fe1f8f75f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['N/A', 'Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ddf7c6c1-9403-46cb-bf21-068931b98014',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'f6eb7ae4-b011-47dc-aebf-ab9e44a248e1',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress, issues addressed, and therapeutic interventions',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requirePrefillUniqueness: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd2e17a73-8c69-4375-9241-3b19f8d6a45b',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requirePrefillUniqueness: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf951761-f42a-4e81-8f93-db9f09d27c5f',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a7392e55-802b-4ee9-a1e2-3cf64519058d',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Course of treatment (medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: '5bdf3524-2d4e-489d-907e-ce5160deceaa',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psychotherapy Documentation',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'd6500c07-c856-469f-8429-743e3b55f77e',
          type: 'shortFreeText',
          title: 'Add-on psychotherapy duration (minutes)',
          subTitle: 'Please include the exact number of minutes',
          placeholder: 'Number of minutes',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            numberValueRequired: true,
          },
        },
        {
          id: '795c0b08-10f5-47d2-bec4-c38957cf5d0a',
          type: 'longFreeText',
          title: 'Add-on psychotherapy documentation',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_31_name_ProgNoteMedical_v1 = {
  templateInfo: {
    id: 31,
    name: 'Progress note: Medical services',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ccd7537-c5f3-4ee0-a570-816984ff484f',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ffd7731e-b7d9-4d38-adca-b927a7533ad7',
          type: 'dropdown',
          title: 'Was there anyone else at the sesssion?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb869c6d-a097-4cb9-96e1-dce6f9dc07c6',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            "Include a summary of changes since the last session, treatment adherence and the client's feedback and response",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'c295190c-7eba-41a8-86c3-c12931dbea81',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '2e0a7a7a-8f0d-41c9-92dc-b2ca588e662d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'dropdownMulti',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'dropdownMulti',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'dropdownMulti',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'dropdownMulti',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'dropdownMulti',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '3431a322-0ac9-4c5c-8f8e-0f76d4740d08',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ef503c99-0878-4138-8e13-f8487f568e02',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7c157329-49d4-4704-b08b-26f4736f1d3f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e3de59-c486-4ce3-8502-64b6d1c7a64d',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cd1c42c-4a77-49f8-85cf-106c4652e3fa',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '723a7e1e-c286-4dd4-be9e-eff2003558a2',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8aaf0ebc-77b3-4be3-9e5c-991f6c2726b7',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '16e00db9-5f8e-4d36-a6cf-10942b97bc9f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '64692b27-53b2-4c3a-9710-c5d0c50ab3e7',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'd5fcea5e-a308-4e7d-965c-4921b6e7d269',
          type: 'dropdownMulti',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e6ac1a24-b33d-4fa3-a6ff-f390e0cf569c',
          type: 'dropdownMulti',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1ced67a5-a8c0-4f50-a829-e527a2c441a1',
          type: 'dropdownMulti',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'f4f7218f-1a65-479c-b208-01a78982f919',
          type: 'dropdownMulti',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '8491fc52-e7f1-4100-86b1-a35aa205b55c',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e2c11c68-2653-4965-af43-57ea094e574e',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'c6fbec56-46c0-4710-a5cc-fb4724559bd8',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'f16a2080-1262-4c5e-839d-ec7e556a70dd',
          type: 'dropdownMulti',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '00791087-3450-4191-aa03-746d76bd2cdc',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '21f32742-633c-4e4a-becf-1623c8eff8d8',
          type: 'dropdownMulti',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '59b8448f-d698-4c60-98fb-ace090565f28',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9971ed20-6897-43a3-9865-364c786e6668',
          type: 'longFreeText',
          title: 'Plan',
          subTitle: 'Include progress towards treatment goals and objectives',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'c3afe072-8724-4485-984b-1b90243c3e05',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_31_name_ProgNoteMedical_v2 = {
  templateInfo: {
    id: 31,
    name: 'Progress note: Medical services',
    version: 2,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ccd7537-c5f3-4ee0-a570-816984ff484f',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ffd7731e-b7d9-4d38-adca-b927a7533ad7',
          type: 'dropdown',
          title: 'Was there anyone else at the session?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb869c6d-a097-4cb9-96e1-dce6f9dc07c6',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            "Note must include a summary of changes since the last session, treatment adherence and the client's feedback and response",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'c295190c-7eba-41a8-86c3-c12931dbea81',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '2e0a7a7a-8f0d-41c9-92dc-b2ca588e662d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '3431a322-0ac9-4c5c-8f8e-0f76d4740d08',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ef503c99-0878-4138-8e13-f8487f568e02',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '7c157329-49d4-4704-b08b-26f4736f1d3f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e3de59-c486-4ce3-8502-64b6d1c7a64d',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cd1c42c-4a77-49f8-85cf-106c4652e3fa',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '723a7e1e-c286-4dd4-be9e-eff2003558a2',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8aaf0ebc-77b3-4be3-9e5c-991f6c2726b7',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '16e00db9-5f8e-4d36-a6cf-10942b97bc9f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '64692b27-53b2-4c3a-9710-c5d0c50ab3e7',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '59b8448f-d698-4c60-98fb-ace090565f28',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9971ed20-6897-43a3-9865-364c786e6668',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Note must include progress towards treatment goals and objectives',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: 'c3afe072-8724-4485-984b-1b90243c3e05',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_31_name_ProgNoteMedical_v3 = {
  templateInfo: {
    id: 31,
    name: 'Progress note: Medical services',
    version: 3,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ccd7537-c5f3-4ee0-a570-816984ff484f',
          type: 'longFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ffd7731e-b7d9-4d38-adca-b927a7533ad7',
          type: 'dropdown',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb869c6d-a097-4cb9-96e1-dce6f9dc07c6',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            "Note must include a summary of changes since the last session, treatment adherence and the client's feedback and response",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'c295190c-7eba-41a8-86c3-c12931dbea81',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '2e0a7a7a-8f0d-41c9-92dc-b2ca588e662d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '3431a322-0ac9-4c5c-8f8e-0f76d4740d08',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ef503c99-0878-4138-8e13-f8487f568e02',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '7c157329-49d4-4704-b08b-26f4736f1d3f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e3de59-c486-4ce3-8502-64b6d1c7a64d',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cd1c42c-4a77-49f8-85cf-106c4652e3fa',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '723a7e1e-c286-4dd4-be9e-eff2003558a2',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8aaf0ebc-77b3-4be3-9e5c-991f6c2726b7',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
          },
        },
        {
          id: '16e00db9-5f8e-4d36-a6cf-10942b97bc9f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['N/A', 'Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '64692b27-53b2-4c3a-9710-c5d0c50ab3e7',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '59b8448f-d698-4c60-98fb-ace090565f28',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress and issues addressed',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf951761-f42a-4e81-8f93-db9f09d27c5f',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9971ed20-6897-43a3-9865-364c786e6668',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Course of treatment (medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: 'c3afe072-8724-4485-984b-1b90243c3e05',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_31_name_ProgNoteMedical_v4 = {
  templateInfo: {
    id: 31,
    name: 'Progress note: Medical services',
    version: 4,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '7ccd7537-c5f3-4ee0-a570-816984ff484f',
          type: 'longFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ffd7731e-b7d9-4d38-adca-b927a7533ad7',
          type: 'dropdown',
          title: 'Other people present at the session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cb869c6d-a097-4cb9-96e1-dce6f9dc07c6',
          type: 'longFreeText',
          title: 'Interval history',
          subTitle:
            'Please include relevant information on psychosocial history (e.g., family, work, education), trauma history, medical history, and substance use history',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd8756e3d-bd5d-4894-84e9-ffb2b8f54f41',
          type: 'longFreeText',
          title: 'Allergies',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Psych review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: 'c295190c-7eba-41a8-86c3-c12931dbea81',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Active substance use',
            'Anxiety/panic',
            'Depressive',
            'Disordered eating',
            'Manic',
            'Psychotic',
            'Trauma-related',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Medical review of systems',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '2e0a7a7a-8f0d-41c9-92dc-b2ca588e662d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Physical exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '3431a322-0ac9-4c5c-8f8e-0f76d4740d08',
          type: 'dropdownMulti',
          title: 'Observations',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Ill-appearing',
            'Fatigued',
            'No gross neurological deficits',
            'Non-toxic',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'ef503c99-0878-4138-8e13-f8487f568e02',
          type: 'shortFreeText',
          title: 'Pertinent vital signs',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: false,
      },
      components: [
        {
          id: '7c157329-49d4-4704-b08b-26f4736f1d3f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e3de59-c486-4ce3-8502-64b6d1c7a64d',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cd1c42c-4a77-49f8-85cf-106c4652e3fa',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '723a7e1e-c286-4dd4-be9e-eff2003558a2',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Current medications',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '8aaf0ebc-77b3-4be3-9e5c-991f6c2726b7',
          type: 'longFreeText',
          title: 'List of current medications',
          subTitle:
            'Note must include dosage, date started, date of most recent dose change, response, side effects and adherence',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
          },
        },
        {
          id: '16e00db9-5f8e-4d36-a6cf-10942b97bc9f',
          type: 'radio',
          title:
            'If the client is prescribed a controlled substance, was the prescription monitoring program reviewed?',
          subTitle: '',
          placeholder: '',
          options: ['N/A', 'Yes', 'No'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '64692b27-53b2-4c3a-9710-c5d0c50ab3e7',
          type: 'radio',
          title:
            'If the client is under 18, were they and their guardian made aware of black box warnings including risk of increased suicide and suicidal thoughts for antidepressants?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '59b8448f-d698-4c60-98fb-ace090565f28',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress and issues addressed',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
          },
        },
        {
          id: 'd2e17a73-8c69-4375-9241-3b19f8d6a45b',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requirePrefillUniqueness: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cf951761-f42a-4e81-8f93-db9f09d27c5f',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9971ed20-6897-43a3-9865-364c786e6668',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Course of treatment (medication initiation, adjustment, non-medication interventions, or transfer to alternative level of care)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: 'c3afe072-8724-4485-984b-1b90243c3e05',
          type: 'longFreeText',
          title: 'Follow-up care recommendations',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_32_name_ProgNoteTherapy_v1 = {
  templateInfo: {
    id: 32,
    name: 'Progress note: Therapy with risk assessment, mental status exam',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      header: 'General',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '9bf55781-ee11-412f-80c7-865a447e36a9',
          type: 'shortFreeText',
          title: 'Chief complaint',
          subTitle:
            "Note must include the reason for presentation in the client's own words",
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e42b5226-8ab0-4ddd-ae53-e9cacf1ed489',
          type: 'dropdown',
          title: 'Was there anyone else at the session?',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['No', 'Family member', 'Guardian', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Subjective',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'fdd2569b-41ab-4b9d-89bd-30be2f42c582',
          type: 'longFreeText',
          title:
            'Note must include information that the client reports (in their own words) and progress since the last session',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '027361ee-96e5-4270-af84-f045771b615c',
          type: 'dropdownMulti',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'e36b37ef-1fdd-4935-8ed6-a680bf849b9f',
          type: 'dropdownMulti',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '08a77d7b-20cb-40da-9323-01a53f20a97e',
          type: 'dropdownMulti',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'bc820041-8b30-428e-a57c-cb6a32fff7d1',
          type: 'dropdownMulti',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '529538ef-9c2e-455b-831d-c21a21fff1a5',
          type: 'dropdownMulti',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'db95d580-4800-43c4-a33a-8280c55ac0f1',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Test results',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '620fc4e7-b444-4228-b85e-69dc11a489a5',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      subHeader: 'If you identify risk, review a safety plan with the client',
      infoComponent: null,
      components: [
        {
          id: 'd501cd3f-855a-491e-b372-82dfbc061a76',
          type: 'dropdownMulti',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'ff8c5b44-32fb-43e3-a1c0-308170f34b7d',
          type: 'dropdownMulti',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '8300cf4f-0c5e-452a-a631-0997f028954d',
          type: 'dropdownMulti',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'e2751591-cccd-44cf-b43d-2846289a0dcf',
          type: 'dropdownMulti',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '6d9a534a-83b0-43de-8e86-e996102dacb8',
          type: 'infoGuidanceCard',
          htmlContent:
            '<strong>Commonly missed:</strong> If you identified risks and reviewed a safety plan with the client, mention this below',
        },
        {
          id: '8afdc41a-8934-44a4-9652-a23bbfc92ba7',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'efe0d43a-4584-4810-8047-d85909eb3efa',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0aa89db8-2bf2-4587-a813-e1eeae4eeab7',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'c281f21d-db4d-4ae8-8d50-06aa97a03b46',
          type: 'dropdownMulti',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'fb9af499-f94c-4f95-a0bf-41a2f305cf05',
          type: 'dropdownMulti',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '3c5ffd07-f274-4c16-82d5-e8f2e8c4022a',
          type: 'dropdownMulti',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan',
      subHeader: "Note must include progress towards the client's goals.",
      infoComponent: null,
      components: [
        {
          id: '05f61bdf-ec4c-48f2-8dcc-e2e8817a8607',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Note must include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'b343863f-5d24-4f9d-81ef-dbe0af9b913b',
          type: 'dropdownMulti',
          title: 'Modality',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cognitive behavioral therapy',
            'Dialectical behavioral therapy',
            'Interpersonal',
            'Insight-oriented',
            'Medication management',
            'Motivational interviewing',
            'Supportive',
            'Trauma focused therapy',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '6d9a534a-83b0-433e-8e86-e996102dacb8',
          type: 'infoGuidanceCard',
          htmlContent:
            'For the Plan, be sure to reference the <strong>treatment plan</strong>, with specific progress toward goals, and any necessary adjustments',
        },
        {
          id: '6d9a534a-83b0-433e-8e86-e996102d4cb8',
          type: 'longFreeText',
          title: 'Plan',
          subTitle:
            'Note must include interventions and next steps for treatment',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'cc658950-fc40-47e8-a0dc-63ca279d7b00',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_33_name_ProgNoteSoap_v1 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      header: 'Subjective',
      subHeader:
        'Include information that the client reports (in their own words) and progress since the last session',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
        {
          id: 'f002175-0477-4882-b22a-12513d8390d5',
          type: 'infoGuidanceCard',
          htmlContent:
            'For Objective, make sure to include a mental status exam, with notes on at least 3 categories (e.g. affect, mood, orientation, thought content, thought process)',
        },
      ],
    },
    {
      header: 'Objective',
      subHeader: 'Include observation, test results, and mental status exam',
      infoComponent: null,
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
      ],
    },
    {
      header: 'Assessment',
      subHeader:
        "Include a summary of the client's progress and treatment response",
      infoComponent: null,
      components: [
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bdbe1',
          type: 'infoGuidanceCard',
          htmlContent:
            'For the Plan, be sure to reference<ul><li><strong>the treatment plan</strong>, with specific progress toward goals, and any necessary adjustments</li><li><strong>a safety plan</strong>, if you’ve identified any risks</li></ul>',
        },
      ],
    },
    {
      header: 'Plan',
      subHeader:
        'Include formulation, risk assessment and progress towards treatment goals. If you identify risk, document a safety plan with the client.',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_33_name_ProgNoteSoap_v2 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 2,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'Subjective',
      subHeader:
        'Note must include what the client reported in the session (thoughts, concerns, stressors)',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
      ],
    },
    {
      header: 'Objective',
      subHeader:
        'Note must include which interventions you used, and how the client responded',
      infoComponent: null,
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment',
      subHeader:
        'Note must include your clinical interpretation of the client’s current state and a comment on progression or regression of symptoms',
      infoComponent: null,
      components: [
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Plan',
      subHeader:
        'Note must indicate whether client has made progress towards one or more specific objectives from the treatment plan and provide recommendations for follow-up care',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
      ],
    },
  ],
};
export const id_33_name_ProgNoteSoap_v3 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 3,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'Subjective',
      subHeader:
        'Note must include what the client reported in the session (thoughts, concerns, stressors)',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
      ],
    },
    {
      header: 'Objective',
      subHeader:
        'Note must include which interventions you used, and how the client responded',
      infoComponent: null,
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title: 'Assessment of symptoms',
          subTitle: '',
          placeholder: '',
          options: ['Escalated', 'Maintained', 'Improved'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Explain why the diagnosis is still appropriate or if changes should be made',
          description:
            'Example: “The client’s ongoing sleep struggles, tendency to focus on the worst-case scenario, and fear of social interaction continue to support a Generalized Anxiety Disorder diagnosis”',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe2',
          type: 'longFreeText',
          title: 'Treatment goal',
          subTitle: 'Describe the client’s treatment goal',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            requirePrefillUniqueness: false,
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: 'bcefa16b-543e-4b59-acfa-b38bef8453d7',
          type: 'radio',
          title: 'Progression toward treatment goal',
          subTitle:
            'Indicate whether client has made progress towards the treatment goal.',
          placeholder: '',
          options: ['Progressed', 'Regressed', 'No change'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2515ecff-7e08-4c98-be96-0a77e581ebbd',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
    {
      header: 'Plan',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: 'Provide recommendations for follow-up care',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_33_name_ProgNoteSoap_v4 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 4,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'Subjective',
      subHeader:
        'Note must include what the client reported in the session (thoughts, concerns, stressors)',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '4319f525-f8e6-401d-ad82-a8301602cdf9',
        },
      ],
      id: '4319f525-f8e6-401d-ad82-a8301602cdf9',
    },
    {
      id: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
      header: 'Objective',
      subHeader:
        'Must include measurable, observable data (information) obtained during the session',
      conditions: [],
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Other',
            'Sad',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Not oriented to self/place/time',
            'Oriented to self/place/time',
            'Other',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Low self image',
            'Obsessions',
            'Other',
            'Paranoid ideation',
            'Visual hallucinations',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Circumstantial',
            'Concrete',
            'Other',
            'Perseverative',
            'Ruminative',
            'Tangential',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
      ],
      id: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
    },
    {
      header: 'Assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '23bd94e4-84ba-4780-83e3-7205655e558d',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          description:
            'If a change in diagnosis, please describe the symptom evidence that support changes to the client diagnosis',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Support justification for level of care, length of session, frequency, and modality',
          description:
            'Example: “Client needed additional time to access emotional content to avoid the possible need for a higher level of care due to a recent loss. Given no changes to the client’s clinical presentation, weekly treatment is warranted in order to address and reduce symptoms.”',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe2',
          type: 'longFreeText',
          title: 'Treatment goal',
          subTitle: 'Describe the client’s treatment goal',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
      ],
      id: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'None',
            'Other',
            'Plan',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Intent',
            'None',
            'Other',
            'Recent self-harm behaviors',
            'Urges without plan or intent',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Ideation without intent or plan',
            'Intent',
            'None',
            'Other',
            'Plan',
            'Wish to be dead without thoughts of suicide',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Identified victim',
            'Intent',
            'None',
            'Other',
            'Plan',
            'Recent violent or destructive acts',
            'Urges without plan or intent',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'N/A',
            'None',
            'Other',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
      ],
      id: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
    },
    {
      id: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of unresponsiveness to treatment',
            'History of violent behaviors',
            'Impulsivity',
            'Lack of insight',
            'None',
            'Other',
            'Other antisocial behaviors',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'None',
            'Other',
            'Chronic and/or acute physical pain',
            'Psychosis',
            'Substance use/intoxication',
            'Employment problems and/or unemployment',
            'Lack of engagement in appropriate psychiatric treatment',
            'Relationship instability / poor social support',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Other',
            'Social supports',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
    },
    {
      header: 'Plan',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: 'Provide recommendations for follow-up care',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
        },
        {
          id: 'b897f5a4-abe2-4ebb-938b-a2c2f31c66b6',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
      id: '058d7401-41e4-4c81-af83-51f330c61ac0',
    },
  ],
  state: {},
};
export const id_33_name_ProgNoteSoap_v5 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 5,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'Subjective',
      subHeader:
        'Note must include what the client reported in the session (thoughts, concerns, stressors)',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '4319f525-f8e6-401d-ad82-a8301602cdf9',
        },
      ],
      id: '4319f525-f8e6-401d-ad82-a8301602cdf9',
    },
    {
      id: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
      header: 'Objective',
      subHeader:
        'Must include measurable, observable data (information) obtained during the session',
      conditions: [],
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Other',
            'Sad',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Not oriented to self/place/time',
            'Oriented to self/place/time',
            'Other',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Low self image',
            'Obsessions',
            'Other',
            'Paranoid ideation',
            'Visual hallucinations',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Circumstantial',
            'Concrete',
            'Other',
            'Perseverative',
            'Ruminative',
            'Tangential',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
      ],
      id: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
    },
    {
      header: 'Assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '23bd94e4-84ba-4780-83e3-7205655e558d',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          description:
            'If a change in diagnosis, please describe the symptom evidence that support changes to the client diagnosis',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title: 'Assessment of symptoms',
          subTitle: '',
          placeholder: '',
          options: ['Escalated', 'Maintained', 'Improved'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Support justification for level of care, length of session, frequency, and modality',
          description:
            'Example: “Client needed additional time to access emotional content to avoid the possible need for a higher level of care due to a recent loss. Given no changes to the client’s clinical presentation, weekly treatment is warranted in order to address and reduce symptoms.”',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe2',
          type: 'longFreeText',
          title: 'Treatment goal',
          subTitle: 'Describe the client’s treatment goal',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
        },
        {
          id: 'bcefa16b-543e-4b59-acfa-b38bef8453d7',
          type: 'radio',
          title: 'Progression toward treatment goal',
          subTitle:
            'Indicate whether client has made progress towards the treatment goal.',
          placeholder: '',
          options: ['Progressed', 'Regressed', 'No change'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
      id: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'None',
            'Other',
            'Plan',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Intent',
            'None',
            'Other',
            'Recent self-harm behaviors',
            'Urges without plan or intent',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Ideation without intent or plan',
            'Intent',
            'None',
            'Other',
            'Plan',
            'Wish to be dead without thoughts of suicide',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Identified victim',
            'Intent',
            'None',
            'Other',
            'Plan',
            'Recent violent or destructive acts',
            'Urges without plan or intent',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'N/A',
            'None',
            'Other',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
      ],
      id: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
    },
    {
      id: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of unresponsiveness to treatment',
            'History of violent behaviors',
            'Impulsivity',
            'Lack of insight',
            'None',
            'Other',
            'Other antisocial behaviors',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'None',
            'Other',
            'Chronic and/or acute physical pain',
            'Psychosis',
            'Substance use/intoxication',
            'Employment problems and/or unemployment',
            'Lack of engagement in appropriate psychiatric treatment',
            'Relationship instability / poor social support',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Other',
            'Social supports',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
    },
    {
      header: 'Plan',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: 'Provide recommendations for follow-up care',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
        },
        {
          id: 'b897f5a4-abe2-4ebb-938b-a2c2f31c66b6',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
      id: '058d7401-41e4-4c81-af83-51f330c61ac0',
    },
  ],
  state: {},
};
export const id_33_name_ProgNoteSoap_v6 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 6,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'Subjective',
      subHeader:
        'Note must include what the client reported in the session (thoughts, concerns, stressors)',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '4319f525-f8e6-401d-ad82-a8301602cdf9',
        },
      ],
      id: '4319f525-f8e6-401d-ad82-a8301602cdf9',
    },
    {
      id: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
      header: 'Objective',
      subHeader:
        'Must include measurable, observable data (information) obtained during the session',
      conditions: [],
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Other',
            'Sad',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Not oriented to self/place/time',
            'Oriented to self/place/time',
            'Other',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Low self image',
            'Obsessions',
            'Other',
            'Paranoid ideation',
            'Visual hallucinations',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Circumstantial',
            'Concrete',
            'Other',
            'Perseverative',
            'Ruminative',
            'Tangential',
            'Within normal limits',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
      ],
      id: '4d422c43-7a71-483a-a7f0-22e1b7fae286',
    },
    {
      header: 'Assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '23bd94e4-84ba-4780-83e3-7205655e558d',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          description:
            'If a change in diagnosis, please describe the symptom evidence that support changes to the client diagnosis',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title: 'Assessment of symptoms',
          subTitle: '',
          placeholder: '',
          options: ['Escalated', 'Maintained', 'Improved'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: 'Justification for medical necessity',
          subTitle:
            'Support justification for level of care, length of session, frequency, and modality',
          description:
            'Example: “Client needed additional time to access emotional content to avoid the possible need for a higher level of care due to a recent loss. Given no changes to the client’s clinical presentation, weekly treatment is warranted in order to address and reduce symptoms.”',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe2',
          type: 'longFreeText',
          title: 'Treatment goal',
          subTitle: 'Describe the client’s treatment goal',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
        },
        {
          id: 'bcefa16b-543e-4b59-acfa-b38bef8453d7',
          type: 'radio',
          title: 'Progression toward treatment goal',
          subTitle:
            'Indicate whether client has made progress towards the treatment goal.',
          placeholder: '',
          options: ['Progressed', 'Regressed', 'No change'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
      id: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'None',
            'Other',
            'Plan',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Intent',
            'None',
            'Other',
            'Recent self-harm behaviors',
            'Urges without plan or intent',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Ideation without intent or plan',
            'Intent',
            'None',
            'Other',
            'Plan',
            'Wish to be dead without thoughts of suicide',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Identified victim',
            'Intent',
            'None',
            'Other',
            'Plan',
            'Recent violent or destructive acts',
            'Urges without plan or intent',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '2515ecff-7e08-4c98-be96-0a77e581ebbd',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'N/A',
            'None',
            'Other',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
      ],
      id: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
    },
    {
      id: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of unresponsiveness to treatment',
            'History of violent behaviors',
            'Impulsivity',
            'Lack of insight',
            'None',
            'Other',
            'Other antisocial behaviors',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'None',
            'Other',
            'Chronic and/or acute physical pain',
            'Psychosis',
            'Substance use/intoxication',
            'Employment problems and/or unemployment',
            'Lack of engagement in appropriate psychiatric treatment',
            'Relationship instability / poor social support',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Other',
            'Social supports',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
    },
    {
      header: 'Plan',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: 'Provide recommendations for follow-up care',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
        },
        {
          id: 'b897f5a4-abe2-4ebb-938b-a2c2f31c66b6',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
      id: '058d7401-41e4-4c81-af83-51f330c61ac0',
    },
  ],
  state: {},
};
export const id_33_name_ProgNoteSoap_v7 = {
  templateInfo: {
    id: 33,
    name: 'Progress note: SOAP',
    version: 7,
    schemaVersion: 3,
  },
  template: [
    {
      id: '596c4275-0282-4d5d-9e55-b2d378e72e4b',
      type: 'shortFreeText',
      title: 'Other people present at the session',
      subTitle: '',
      placeholder: '',
      optionalityText: 'If applicable',
      options: '',
      metadata: {
        requiredAlways: false,
        requiredForCptCodes: [],
        prefillFromLastSession: false,
      },
    },
    {
      header: 'Subjective',
      subHeader:
        'Client’s subjective concerns, symptoms, and any information that the client provided about their condition, personal experiences, and feelings',
      infoComponent: null,
      components: [
        {
          id: 'ab4b06e7-8509-41b7-9b27-a06030f25a61',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '4319f525-f8e6-401d-ad82-a8301602cdf9',
        },
      ],
      id: '4319f525-f8e6-401d-ad82-a8301602cdf9',
    },
    {
      id: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
      header: 'Objective',
      subHeader:
        'Must include measurable, observable data (information) obtained during the session',
      conditions: [],
      components: [
        {
          id: 'e68365fc-f0f0-4a93-a3a7-e892d703dd42',
          type: 'longFreeText',
          title: '',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          sectionParentUUID: '7d74b035-f7cc-4234-bb50-bd288f8a5a49',
        },
      ],
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'd31c2d6c-ad07-40d7-b23a-7070c1e58b54',
          type: 'checklist',
          title: 'Attention',
          subTitle: '',
          placeholder: '',
          options: [
            'Alert (within normal limits)',
            'Somnolent (sleepy)',
            'Lethargic (very sleepy)',
            'Obtunded (difficult to arouse)',
            'Stupor (very difficult to arouse)',
            'Comatose (unarousable)',
            'Selective attention',
            'Confused',
            'Delirium (fluctuating level of consciousness)',
            'Other',
          ],
          defaultResponse: 'Alert (within normal limits)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: '5ea98a43-b8cb-429f-8dd3-f10639b8bf16',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: '',
          options: [
            'Oriented to person, place, time, and situation (within normal limits)',
            'Oriented to person',
            'Oriented to place',
            'Oriented to time',
            'Oriented to situation',
            'Other',
          ],
          defaultResponse:
            'Oriented to person, place, time, and situation (within normal limits)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: 'f3583a08-0303-4974-b22c-c7b4c8b50c17',
          type: 'checklist',
          title: 'Appearance',
          subTitle: '',
          placeholder: '',
          options: [
            'Within normal limits; adequate hygiene, well groomed',
            'Adequate hygiene',
            'Casually dressed',
            'Well-groomed',
            'Abnormal motor activity',
            'Disheveled',
            'Unkempt',
            'Other',
          ],
          defaultResponse:
            'Within normal limits; adequate hygiene, well groomed',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: 'e255ba6f-ff3e-42b6-8fee-4560d36fc0da',
          type: 'checklist',
          title: 'Behavior',
          subTitle: '',
          placeholder: '',
          options: [
            'Within normal limits; appropriate eye contact, normal motor activity',
            'Inappropriate eye contact',
            'Decreased spontaneous movement',
            'Physically threatening',
            'Restless',
            'Other',
          ],
          defaultResponse:
            'Within normal limits; appropriate eye contact, normal motor activity',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: 'd5226a1a-2263-44dc-9595-9adf5080646c',
          type: 'checklist',
          title: 'Speech',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Abnormal rate',
            'Abnormal volume',
            'Flattened prosody',
            'Delayed',
            'Pressured',
            'Hyperverbal',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: 'f13ff900-4e99-446a-8dc6-37eda885264d',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits (full); congruent with mood',
            'Incongruent with mood',
            'Restricted',
            'Blunted',
            'Flat',
            'Labile',
            'Other',
          ],
          defaultResponse: 'Within normal limits (full); congruent with mood',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: '183c4cd8-56ce-4acd-b5df-a3b501e3e37b',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Euthymic',
            'Sad/Dysthymic',
            'Depressed',
            'Irritable',
            'Anxious',
            'Elevated',
            'Expansive',
            'Euphoric',
            'Hopelessness',
            'Angry',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: 'a032c40b-7923-4ab7-aa50-326ddbed56ae',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits (linear, goal directed)',
            'Circumferential',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Disorganization',
            'Loose association',
            'Word salad',
            'Internal preoccupation',
            'Blocking',
            'Flight of ideas',
            'Other',
          ],
          defaultResponse: 'Within normal limits (linear, goal directed)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: '5ec5984d-afe5-4d38-b76a-04becd17be6f',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Visual hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Poverty of thought content',
            'Obsessions',
            'Paranoid ideation',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: '18050409-c640-4665-872b-df5f0b4238df',
          type: 'checklist',
          title: 'Judgement',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Fair',
            'Poor',
            'Impaired',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: '8d03c22f-3a8d-4724-a0a0-702b0ffeb8ac',
          type: 'checklist',
          title: 'Memory',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Impaired recall of recent events',
            'Impaired recall of remote events',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
        {
          id: '99a4fd4b-099c-4344-b1f4-59af79661fa5',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '160987a1-d37a-470b-87c0-552650225365',
        },
      ],
      id: '160987a1-d37a-470b-87c0-552650225365',
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '2f7f19c6-eb43-41a4-a81a-1ffb448bd42c',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'c4cf90cc-6a32-47a0-a64f-0b0522b11afc',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
        },
      ],
      id: 'febdd2f5-1fdc-4a5c-b2c4-6ec82f6b59a9',
    },
    {
      id: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
      header: 'Protective and Risk Factors',
      subHeader: '',
      style: {
        spacing: 'regular',
      },
      conditions: [],
      components: [
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors / Static liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'Impulsivity',
            'History of violent behaviors',
            'History of unresponsiveness to treatment',
            'Lack of insight',
            'Other antisocial behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors / Modifiable liabilities',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Chronic and/or acute physical pain',
            'Employment problems and/or unemployment',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Lack of engagement in appropriate psychiatric treatment',
            'Mood/anxiety disorder',
            'Psychosis',
            'Relation instability / poor social support',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors / Strengths',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '2515ecff-7e08-4c98-be96-0a77e581ebbd',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '05d8f6fe-7eef-4012-9fef-def9a3a2840c',
        },
      ],
    },
    {
      header: 'Assessment',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'bc3e49e1-9c92-4c71-97f7-809dcaac5085',
          type: 'longFreeText',
          title: 'Clinical summary',
          subTitle:
            'Description of current clinical presentation, including any new/recent symptom progression or regression',
          placeholder: '',
          description: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            extendedTextArea: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: '23bd94e4-84ba-4780-83e3-7205655e558d',
          type: 'longFreeText',
          title: 'Diagnostic support statement',
          subTitle: 'Symptom evidence that supports the client diagnosis',
          placeholder: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          description:
            'If a change in diagnosis, please describe the symptom evidence that support changes to the client diagnosis',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title: 'Assessment of symptoms',
          subTitle: '',
          placeholder: '',
          options: ['Escalated', 'Maintained', 'Improved'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '261bd3e3-c5af-486c-af93-cb751a82bbe1',
          type: 'longFreeText',
          title: 'Medical necessity',
          subTitle:
            'Support justification for level of care, length of session, frequency, and modality',
          description:
            'Example: “Client needed additional time to access emotional content to avoid the possible need for a higher level of care due to a recent loss. Given no changes to the client’s clinical presentation, weekly treatment is warranted in order to address and reduce symptoms.”',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
          isRequired: false,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
        },
        {
          id: '83c7e9fa-df90-49ed-b134-b300d74901b3',
          type: 'checklist',
          title: 'Therapeutic interventions used in session',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'ACT',
            'CBT',
            'DBT',
            'EMDR',
            'Interpersonal psychotherapy',
            'Mindfulness-based approaches',
            'Motivational interviewing',
            'Psychoeducation',
            'Socratic questioning',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
        },
      ],
      id: 'fca70f63-0040-4d83-984e-ad46883ebeb6',
    },
    {
      header: 'Progress toward treatment goals',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: 'c5d5efa3-c58e-4e7d-aefe-6ec8eee87464',
          type: 'longFreeText',
          title: 'Treatment goals',
          subTitle: 'Describe the client’s treatment goals',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            requirePrefillUniqueness: false,
            showInlineTreatmentPlan: true,
          },
          sectionParentUUID: '6f2d8ca5-d9f8-4f2b-b929-94d2afc554cd',
        },
        {
          id: '56b1230f-869e-4bb1-b779-2f2c017edf74',
          type: 'radio',
          title: 'Goal 1',
          subTitle: '',
          placeholder: '',
          options: ['Progressed', 'Regressed', 'No change', 'Goal achieved'],
          defaultResponse: 'Progressed',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '6f2d8ca5-d9f8-4f2b-b929-94d2afc554cd',
        },
        {
          id: '1be6de52-67bb-4a26-b84b-a021d0c8a460',
          type: 'radio',
          title: 'Goal 2',
          subTitle: '',
          placeholder: '',
          options: [
            'N/A (no second goal)',
            'Progressed',
            'Regressed',
            'No change',
            'Goal achieved',
            'Not yet started / Not addressed in this session',
          ],
          defaultResponse: 'N/A (no second goal)',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '6f2d8ca5-d9f8-4f2b-b929-94d2afc554cd',
        },
        {
          id: 'd5a74e8c-b866-4f28-898a-2b9ae1b2a997',
          type: 'radio',
          title: 'Barriers to treatment goals',
          subTitle: '',
          placeholder: '',
          options: [
            'Severe mental health symptoms',
            'Lack of motivation',
            'Difficulty with emotion regulation',
            'External stressors (e.g. financial, family issues)',
            'Limited social support',
            'Lack of insight into condition',
            'Resistance to change',
            'Limited access to healthcare services',
            'Co-occurring medical issues',
            'Cultural and/or linguistic barriers',
            'Substance use',
            'Other',
          ],
          defaultResponse: 'Severe mental health symptoms',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '6f2d8ca5-d9f8-4f2b-b929-94d2afc554cd',
        },
        {
          id: '8103851e-8ffd-47d4-9157-90ecc9a12bdb',
          type: 'checklist',
          title:
            'Interactive complexity element (only required for CPT code 90785)',
          subTitle:
            'Symptom evidence that support the client diagnosis. This statement must be supported and consistent with the information in the presenting issues and psychiatric history.',
          placeholder: 'Choose relevant options',
          options: [
            'Maladaptive communication among multiple participants',
            'Caregiver emotions or behavior that interferes with implementation of treatment plan',
            'Evidence or disclosure of a sentinel event and a mandated report to a third party',
            'Use of play equipment, physical devices, or translator to overcome significant communication barrier',
          ],
          defaultResponse: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [90785],
            prefillFromLastSession: false,
          },
          sectionParentUUID: '6f2d8ca5-d9f8-4f2b-b929-94d2afc554cd',
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
      id: '6f2d8ca5-d9f8-4f2b-b929-94d2afc554cd',
    },
    {
      header: 'Plan',
      infoComponent: null,
      components: [
        {
          id: 'f0023875-0477-4882-b22a-12513d8390d5',
          type: 'longFreeText',
          title: 'Provide recommendations for follow-up care or referrals',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
        },
        {
          id: 'b897f5a4-abe2-4ebb-938b-a2c2f31c66b6',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: '',
          options: [
            'Regular cadence planned: weekly',
            'Regular cadence planned: biweekly',
            'Regular cadence planned: monthly',
            'PRN: Sessions will be scheduled as needed',
            'Patient or Provider will be in touch to schedule',
            'Termination: Patient not returning',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
          isRequired: true,
          minLength: false,
          isRequiredErrorMessage: 'This is required.',
          minLengthErrorMessage: 'This requires a minimum of 400 characters.',
          minLengthNumber: '400',
          sectionParentUUID: '058d7401-41e4-4c81-af83-51f330c61ac0',
          validations: [
            {
              type: 'required',
              params: ['This is required.'],
            },
          ],
        },
      ],
      id: '058d7401-41e4-4c81-af83-51f330c61ac0',
    },
  ],
  state: {},
};
export const id_34_name_FreeTextV2_v1 = {
  templateInfo: {
    id: 34,
    name: 'FreeText',
    version: 1,
    schemaVersion: 2,
  },
  template: [
    {
      id: '546aabac-5ebb-4adb-922d-55f0a63719b7',
      type: 'richFreeText',
      title: '',
      subTitle: '',
      placeholder: 'Enter notes...',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
    },
  ],
};
export const id_34_name_FreeTextV2_v2 = {
  templateInfo: {
    id: 34,
    name: 'FreeText',
    version: 2,
    schemaVersion: 3,
  },
  template: [
    {
      id: '546aabac-5ebb-4adb-922d-55f0a63719b7',
      type: 'richFreeText',
      title: '',
      subTitle: '',
      placeholder: 'Enter notes...',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
    },
    {
      header: 'Mental status exam',
      subHeader: '',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'checklist',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'checklist',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'checklist',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'checklist',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'checklist',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          defaultResponse: 'Within normal limits',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Risk assessment',
      infoComponent: null,
      collapsible: {
        defaultOpen: true,
      },
      defaultOptionBehavior: {
        buttonText: 'Client currently denies all areas of risk',
      },
      components: [
        {
          id: '12f810a6-e8fe-4bfd-9144-7ed7192b48c1',
          type: 'checklist',
          title: 'Homicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Ideation without intent or plan',
            'Identified victim',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '2036b7c8-4771-49d5-b233-e82656ea2007',
          type: 'checklist',
          title: 'Self harm',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Intent',
            'Recent self-harm behaviors',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1680a6de-5092-46aa-9c8e-77e6d0e1c91d',
          type: 'checklist',
          title: 'Suicide',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Wish to be dead without thoughts of suicide',
            'Ideation without intent or plan',
            'Intent',
            'Plan',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '40571574-8977-404f-a0db-4adef575b494',
          type: 'checklist',
          title: 'Violent/destructive behavior',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Urges without plan or intent',
            'Identified victim',
            'Intent',
            'Plan',
            'Recent violent or destructive acts',
            'Other',
          ],
          defaultResponse: 'None',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '0fdeb1c1-6fb7-4ef5-827a-46c7c66de47f',
          type: 'longFreeText',
          title: 'Additional relevant information',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'b5c8561f-dd86-462f-88ee-b0e63a95134c',
          type: 'radio',
          title:
            'Have there been suicidal behaviors or attempts since the last session?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          defaultResponse: 'No',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '832fd326-1712-441d-9122-b19e48a58062',
          type: 'checklist',
          title: 'Static risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Chronic medical or mental illness',
            'Family history of suicide',
            'History of prior suicide attempts',
            'History of trauma',
            'History of violent behaviors',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '4979841a-45b7-4e8b-918a-fe357ba9711c',
          type: 'checklist',
          title: 'What actions have been taken to minimize risk?',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Continued to monitor',
            'Established and agreed to safety plan',
            'Increased frequency of sessions',
            'Made medication changes',
            'Referred for higher level of care',
            'Referred to another modality',
            'Reviewed emergency resources',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'bba9666f-1f8b-43cc-8993-f1e5008c3740',
          type: 'checklist',
          title: 'Modifiable risk factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Hopelessness',
            'Impulsivity',
            'Insomnia',
            'Mood/anxiety disorder',
            'Pain',
            'Psychosis',
            'Substance use/intoxication',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: '1e761a08-f3d8-4054-b3ec-00a005279c90',
          type: 'checklist',
          title: 'Protective factors',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'N/A',
            'None',
            'Cultural/moral/religious beliefs',
            'Engaged in school or work',
            'Engaged in treatment',
            'Fear of death',
            'Future oriented',
            'Help-seeking behavior',
            'No history of suicide attempts',
            'Social supports',
            'Other',
          ],
          defaultResponse: 'N/A',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
        {
          id: 'd8a262ac-eb34-4181-865e-ca50ca8fad8b',
          type: 'radio',
          title:
            "What is the client's current overall, acute risk of harm to themselves or others?",
          subTitle: '',
          placeholder: '',
          options: ['Low', 'Moderate', 'High'],
          defaultResponse: 'Low',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: false,
          },
        },
      ],
    },
  ],
};
export const id_35_name_schema3Test_v1 = {
  templateInfo: {
    id: 35,
    name: 'Schema Version 3',
    version: 1,
    schemaVersion: 3,
  },
  template: [
    {
      header: 'Collapsible',
      subHeader: '',
      infoComponent: null,
      collapsable: {
        enabled: true,
        defaultOpen: false,
      },
      components: [
        {
          id: '7c157329-49d4-4704-b08b-26f4736f1d3f',
          type: 'dropdownMulti',
          title: 'Diagnostic data',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: ['None', 'Audit C', 'GAD-7', 'Labs', 'PHQ-9', 'Other'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a4e3de59-c486-4ce3-8502-64b6d1c7a64d',
          type: 'radio',
          title: 'Is the client prescribed an antipsychotic?',
          subTitle: '',
          placeholder: '',
          options: ['Yes', 'No'],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '8cd1c42c-4a77-49f8-85cf-106c4652e3fa',
          type: 'longFreeText',
          title:
            'If client prescribed an antipsychotic, provide relevant metabolic monitoring',
          subTitle:
            'For example, routine hemoglobin A1c, lipid panel, body mass index (BMI) and assessment of involuntary motor movements (AIMS)',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '723a7e1e-c286-4dd4-be9e-eff2003558a2',
          type: 'longFreeText',
          title: 'Other relevant monitoring for medication management',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Conditional',
      subHeader: '',
      infoComponent: null,
      displayRequirements: {
        shouldDisplay: [
          {
            functionName: 'isPatientAgeInInclusiveRange',
            functionArguments: [0, 18],
          },
        ],
      },
      components: [
        {
          id: '2e0a7a7a-8f0d-41c9-92dc-b2ca588e662d',
          type: 'dropdownMulti',
          title: 'Symptoms',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'None',
            'Cardiovascular',
            'Consitutional',
            'Endocrine',
            'Gastrointestinal',
            'Genitourinary',
            'Head and neck',
            'Hematologic/lymphatic',
            'Musculoskeletal',
            'Neurological',
            'Other',
          ],
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Default Value',
      subHeader: '',
      infoComponent: null,
      defaultOptionBehavior: {
        buttonText: 'All within normal limits',
        componentOptionToSelect: 'Within normal limits',
      },
      components: [
        {
          id: 'e45a2b93-86c2-4342-a6b2-7e9757fbb633',
          type: 'dropdownMulti',
          title: 'Affect',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Congruent with mood',
            'Constricted',
            'Full range',
            'Incongruent with mood',
            'Irritable',
            'Labile',
            'Sad',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '2903935b-ac6c-4edb-8117-684d599a8adc',
          type: 'dropdownMulti',
          title: 'Mood',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Depressed',
            'Euthymic',
            'Happy',
            'Hopeless',
            'Irritable',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: 'a44123bb-9d72-4fd0-b417-f2b8611395f7',
          type: 'dropdownMulti',
          title: 'Orientation',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Oriented to self/place/time',
            'Not oriented to self/place/time',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '1fb83d56-dafe-4e99-b9c2-4b3905010aeb',
          type: 'dropdownMulti',
          title: 'Thought content',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Auditory hallucinations',
            'Delusions present',
            'Ideas of reference',
            'Obsessions',
            'Paranoid ideation',
            'Visual hallucinations',
            'Low self image',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0d28aaa6-37b7-4381-a5ad-7e262c859915',
          type: 'dropdownMulti',
          title: 'Thought process',
          subTitle: '',
          placeholder: 'Choose relevant options',
          options: [
            'Within normal limits',
            'Circumstantial',
            'Concrete',
            'Ruminative',
            'Perseverative',
            'Tangential',
            'Other',
          ],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '0bbe983c-0df5-44c0-94ee-43530ba9d11c',
          type: 'longFreeText',
          title: 'Anything else',
          subTitle: '',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: false,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '546aabac-5ebb-4adb-922d-55f0a63719b7',
          type: 'richFreeText',
          title:
            'For therapy: discussion of problems, how session adressed treatment goals / objectives.\nFor prescribers: history of present illness / interval history, psychiatric review of systems, medical review of systems ',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
    {
      header: 'Assessment and plan - Treatment Plan Test',
      subHeader: '',
      infoComponent: null,
      components: [
        {
          id: '59b8448f-d698-4c60-98fb-ace090565f28',
          type: 'longFreeText',
          title: 'Assessment',
          subTitle:
            'Include a summary of the client’s overall progress and treatment response',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
        {
          id: '9971ed20-6897-43a3-9865-364c786e6668',
          type: 'longFreeText',
          title: 'Plan',
          subTitle: 'Include progress towards treatment goals and objectives',
          placeholder: '',
          options: '',
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
            showInlineTreatmentPlan: true,
          },
        },
        {
          id: 'c3afe072-8724-4485-984b-1b90243c3e05',
          type: 'dropdown',
          title: 'Next session',
          subTitle: '',
          placeholder: 'Choose an option',
          options: ['In 1 week', 'In 2 weeks', 'In 1 month', 'Other'],
          metadata: {
            requiredAlways: true,
            requiredForCptCodes: [],
            prefillFromLastSession: true,
          },
        },
      ],
    },
  ],
};
export const id_36_name_builder_v1 = {
  templateInfo: {
    id: 36,
    name: 'Builder',
    version: 1,
    schemaVersion: 3,
  },
  template: [
    {
      id: '546aabac-5ebb-44db-922d-55f0a63719b7',
      type: 'shortFreeText',
      title: 'Modality',
      subTitle:
        'Note must include type of therapy (e.g. individual, couple, group) and planned frequency of sessions (e.g. weekly)',
      placeholder: 'Enter something...',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
    },
    {
      id: '546aabac-5ebb-4adb-922d-55f63719b7',
      type: 'richFreeText',
      title: 'Assessment',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
    },
    {
      id: '546aabac-5ebb-4bbdb-922d-55f63719b7',
      type: 'longFreeText',
      title: 'Plan',
      subTitle: 'Note must include interventions and next steps for treatment',
      placeholder: '',
      options: '',
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
    },
    {
      id: '546aabac-5ebb-34-922d-55f63719b7',
      type: 'radio',
      title: 'Plan',
      options: ['Options 1', 'Options 2'],
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
    },
    {
      id: '546aabac-23-4adb-922d-55f63719b7',
      type: 'dropdown',
      title: 'Plan dropdown',
      subTitle: 'Note must include interventions and next steps for treatment',
      placeholder: '',
      options: ['hi', 'goodbye'],
      metadata: {
        requiredAlways: true,
        requiredForCptCodes: [],
        prefillFromLastSession: true,
      },
      validations: [
        {
          type: 'required',
          params: ['The plan is required.'],
        },
        {
          type: 'min',
          params: [500, 'The plan requires 500 characters or more.'],
        },
      ],
    },
  ],
};
