import { Formik } from 'formik';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { SmsOptOutType as SmsReminderType } from '@headway/api/models/SmsOptOutType';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { Form } from '@headway/helix/Form';
import { FormStickyFooter } from '@headway/helix/Form';
import { FormControl } from '@headway/helix/FormControl';
import { Link as HelixLink } from '@headway/helix/Link';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { PageSection, PageSectionSubText } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Switch } from '@headway/helix/Switch';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { YUP_PHONE_MATCH } from '@headway/shared/constants/format';
import { notifyError, notifySuccess } from '@headway/ui/utils/notify';

const initialSmsOptOutTypeState = [
  SmsReminderType.CAL_DIGEST,
  SmsReminderType.CANCELED_SESSION,
  SmsReminderType.NEW_SESSION,
];

const afterHoursAcknowledgementText = `I agree to maintain and monitor a confidential voice mailbox for Headway clients,
with a message disclosing that I do not provide emergency services and including appropriate crisis
resources or protocol. I agree to communicate emergency contact and business hours expectations
with my client upon meeting.`;

const contactInfoSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required.'),
  phone: Yup.string()
    .nullable()
    .required('Phone number is required.')
    .matches(
      YUP_PHONE_MATCH,
      'Make sure the phone number follows this format: (###) ###-####.'
    ),
  afterHoursPhone: Yup.string()
    .matches(
      YUP_PHONE_MATCH,
      'Make sure the phone number follows this format: (###) ###-####.'
    )
    .nullable(),
  afterHoursAgreement: Yup.boolean().when('afterHoursPhone', {
    is: (val) => val?.length > 0,
    then: (schema: any) =>
      schema.oneOf([true], 'You must read and agree to the statement above.'),
  }),
  smsPhone: Yup.string()
    .nullable()
    .matches(
      YUP_PHONE_MATCH,
      'Make sure the phone number follows this format: (###) ###-####.'
    )
    .when('selectedSmsOptInTypes', (selectedSmsOptInTypes) => {
      if (selectedSmsOptInTypes.length > 0) {
        return Yup.string()
          .required(
            'Mobile phone number is required if opting into text notifications.'
          )
          .matches(
            YUP_PHONE_MATCH,
            'Make sure the phone number follows this format: (###) ###-####.'
          );
      }
    }),
  selectedSmsOptInTypes: Yup.array().of(Yup.string()),
});

interface FormValues {
  phone?: string;
  email?: string;
  afterHoursPhone?: string;
  afterHoursAgreement?: boolean;
  smsPhone?: string;
  selectedSmsOptInTypes: SmsReminderType[];
}

interface Props {
  AuthStore?: any;
}

interface State {
  formValues: {
    phone?: string;
    email?: string;
    afterHoursPhone?: string;
    afterHoursAgreement?: boolean;
    smsPhone?: string;
    selectedSmsOptInTypes: SmsReminderType[];
  };
}

export const Account = inject('AuthStore')(
  observer(
    class Account extends React.Component<Props, State> {
      state: State = {
        formValues: {
          phone: '',
          email: '',
          afterHoursPhone: '',
          afterHoursAgreement: false,
          smsPhone: '',
          selectedSmsOptInTypes: [],
        },
      };

      async onSubmit(
        values: FormValues,
        providerId: number,
        resetForm: (values: { values: FormValues }) => void
      ) {
        try {
          let updatedSmsOptOutTypes: SmsReminderType[];
          // if nothing is selected or toggle is off, save [ALL]
          if (
            values.selectedSmsOptInTypes.length === 0 &&
            this.props.AuthStore.provider.smsAgreementAcceptanceDate !== null
          ) {
            updatedSmsOptOutTypes = [SmsReminderType.ALL];
          } else if (values.selectedSmsOptInTypes.length > 0) {
            updatedSmsOptOutTypes = this.checkCurrentSmsOptInTypes(
              values.selectedSmsOptInTypes
            );
          } else {
            updatedSmsOptOutTypes = [];
          }

          let updateData: any = {
            email: values.email,
            phone: values.phone,
            smsPhone: values.smsPhone,
            afterHoursPhone: values.afterHoursPhone,
            smsOptOutTypes: updatedSmsOptOutTypes,
          };

          // check if needs to update smsAgreementAcceptanceDate
          // update agreement date if it didn't already exist but is toggled on with selectedSmsOptInTypes
          if (
            this.props.AuthStore.provider.smsAgreementAcceptanceDate === null &&
            values.selectedSmsOptInTypes.length > 0
          ) {
            updateData['smsAgreementAcceptanceDate'] = new Date().toISOString();
          }

          // check if needs to update the afterHoursAgreementDate
          // only need to update the agreement date if it doesn't already exist
          if (
            !!values.afterHoursPhone &&
            values.afterHoursPhone !==
              this.props.AuthStore.provider.afterHoursPhone &&
            !this.props.AuthStore.provider.afterHoursAgreementDate
          ) {
            updateData['afterHoursAgreementDate'] = new Date().toISOString();
          }

          const res = await ProviderApi.updateProvider(providerId, updateData);
          const {
            phone,
            email,
            afterHoursPhone,
            afterHoursAgreementDate,
            smsPhone,
            smsOptOutTypes,
            smsAgreementAcceptanceDate,
          } = res;

          this.props.AuthStore.provider.phone = phone;
          this.props.AuthStore.provider.email = email;
          this.props.AuthStore.provider.afterHoursPhone = afterHoursPhone;
          this.props.AuthStore.provider.afterHoursAgreementDate =
            afterHoursAgreementDate;
          this.props.AuthStore.provider.smsAgreementAcceptanceDate =
            smsAgreementAcceptanceDate;
          this.props.AuthStore.provider.smsPhone = smsPhone;
          this.props.AuthStore.provider.smsOptOutTypes = smsOptOutTypes;

          let selectedSmsTypes: SmsReminderType[];

          if (
            smsOptOutTypes?.includes(SmsReminderType.ALL) ||
            smsAgreementAcceptanceDate === null
          ) {
            selectedSmsTypes = [];
          } else if (
            smsOptOutTypes?.length === 0 &&
            smsAgreementAcceptanceDate !== null
          ) {
            selectedSmsTypes = [
              SmsReminderType.CANCELED_SESSION,
              SmsReminderType.CAL_DIGEST,
              SmsReminderType.NEW_SESSION,
            ];
          } else {
            selectedSmsTypes = this.checkCurrentSmsOptInTypes(smsOptOutTypes);
          }

          const afterHoursAgreement = !!afterHoursAgreementDate;
          notifySuccess('Account settings updated.');
          resetForm({
            values: {
              phone,
              email,
              afterHoursPhone,
              afterHoursAgreement,
              smsPhone,
              selectedSmsOptInTypes: selectedSmsTypes,
            },
          });
        } catch (err: AnyTS4TryCatchUnknownError) {
          notifyError(err.toString());
        }
      }

      componentDidMount() {
        const {
          email,
          phone,
          afterHoursPhone,
          afterHoursAgreementDate,
          smsPhone,
          smsAgreementAcceptanceDate,
          smsOptOutTypes,
        } = this.props.AuthStore.provider;

        const afterHoursAgreement = !!afterHoursAgreementDate;

        // On first render, check if provider has accepted the sms agreement and if smsOptOutType has ALL/ALL_BLOCKING
        // If not accepted, show sms terms
        const isAllOrAllBlockingOptedOut =
          smsOptOutTypes?.includes(SmsReminderType.ALL) ||
          smsOptOutTypes?.includes(SmsReminderType.ALL_BLOCKING);

        let selectedSmsTypes: SmsReminderType[];
        if (!smsAgreementAcceptanceDate || isAllOrAllBlockingOptedOut) {
          selectedSmsTypes = [];
        } else {
          selectedSmsTypes = this.checkCurrentSmsOptInTypes(smsOptOutTypes);
        }

        this.setState({
          formValues: {
            phone,
            email,
            afterHoursPhone,
            afterHoursAgreement,
            smsPhone: smsPhone || '',
            selectedSmsOptInTypes: selectedSmsTypes,
          },
        });
      }

      // filters out the opted OUT types to show what the provider is opting IN.
      checkCurrentSmsOptInTypes(
        smsOptOutTypes:
          | SmsReminderType[]
          | FormValues['selectedSmsOptInTypes'] = []
      ) {
        return initialSmsOptOutTypeState.filter((reminderType) => {
          return !smsOptOutTypes.includes(reminderType);
        });
      }

      render() {
        const { formValues } = this.state;
        const {
          email,
          phone,
          afterHoursPhone,
          afterHoursAgreement,
          smsPhone,
          selectedSmsOptInTypes,
        } = formValues;
        const { smsAgreementAcceptanceDate, id: providerId } =
          this.props.AuthStore.provider;

        return (
          <Formik
            initialValues={
              {
                email: email,
                phone: phone,
                afterHoursPhone: afterHoursPhone,
                afterHoursAgreement: afterHoursAgreement,
                smsPhone: smsPhone,
                selectedSmsOptInTypes: selectedSmsOptInTypes,
              } as FormValues
            }
            validationSchema={contactInfoSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              await this.onSubmit(values, providerId, resetForm);
            }}
            render={({
              isSubmitting,
              resetForm,
              dirty,
              submitForm,
              values,
              setFieldValue,
            }) => {
              return (
                <Form>
                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>Contact email</SectionHeader>
                      </h2>
                      {!this.props.AuthStore.user?.use_mfa && (
                        <PageSectionSubText>
                          <div>
                            Clients won’t see this email address — it’s just for
                            Headway to use to reach you. You can update your
                            login email{' '}
                            <HelixLink
                              href={`${process.env.REACT_APP_MAIN_URL}/account`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              from your Headway account
                            </HelixLink>
                            .
                          </div>
                        </PageSectionSubText>
                      )}
                      {this.props.AuthStore.user.use_mfa && (
                        <PageSectionSubText>
                          Clients won't see this email address - it's just for
                          Headway to use to reach you. You can update your login
                          email below.
                        </PageSectionSubText>
                      )}
                    </div>
                    <div>
                      <FormControl
                        name="email"
                        label="Email"
                        component={TextField}
                      />
                    </div>
                  </PageSection>

                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>Phone</SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        Clients will see this phone number after they have
                        booked a session or phone consultation with you.
                      </PageSectionSubText>
                    </div>
                    <div>
                      <FormControl
                        name="phone"
                        component={PhoneNumberField}
                        label="Practice phone number"
                      />
                    </div>
                  </PageSection>

                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>
                          Confidential voicemail number
                        </SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        Enter the phone number where Headway clients can reach
                        your practice's monitored voicemail.
                      </PageSectionSubText>
                    </div>
                    <div
                      css={{
                        ...theme.stack.vertical,
                        gap: theme.spacing.x6,
                      }}
                    >
                      <FormControl
                        name="afterHoursPhone"
                        component={PhoneNumberField}
                        label="Monitored voicemail number"
                        aria-describedby="confidential-voicemail-resources"
                      />

                      <div>
                        <FormControl
                          name="afterHoursAgreement"
                          component={Checkbox}
                        >
                          <span>{afterHoursAcknowledgementText}</span>
                        </FormControl>
                        <p
                          css={{ marginTop: theme.spacing.x3 }}
                          id="confidential-voicemail-resources"
                        >
                          <BodyText>
                            If you do not have a confidential voice mailbox,
                            please find resources{' '}
                            <HelixLink
                              target="_blank"
                              rel="noreferrer"
                              href="https://findheadway.zendesk.com/hc/en-us/articles/7290516629396"
                            >
                              here.
                            </HelixLink>
                          </BodyText>
                        </p>
                      </div>
                    </div>
                  </PageSection>
                  <PageSection layout="grid.two-column">
                    <div>
                      <h2>
                        <SectionHeader>Text Notifications</SectionHeader>
                      </h2>
                      <PageSectionSubText>
                        Get important updates, like new bookings and
                        cancellations, texted to your mobile phone.
                      </PageSectionSubText>
                    </div>
                    <div
                      css={{
                        ...theme.stack.vertical,
                        gap: theme.spacing.x6,
                      }}
                    >
                      <FormControl
                        name="smsPhone"
                        component={PhoneNumberField}
                        label="Mobile phone number"
                      />

                      <div>
                        <Switch
                          labelPosition="right"
                          name="sms-opt-in"
                          selected={values.selectedSmsOptInTypes.length > 0}
                          aria-describedby="sms-agreement-desc"
                          onChange={() => {
                            if (values.selectedSmsOptInTypes.length === 0) {
                              setFieldValue('selectedSmsOptInTypes', [
                                ...initialSmsOptOutTypeState,
                              ]);
                            } else {
                              setFieldValue('selectedSmsOptInTypes', []);
                            }
                          }}
                        >
                          Receive text notifications
                        </Switch>

                        {!smsAgreementAcceptanceDate &&
                          values.selectedSmsOptInTypes.length === 0 && (
                            <p css={{ marginTop: theme.spacing.x3 }}>
                              <BodyText>
                                <span id="sms-agreement-desc">
                                  By toggling Receive text notifications, I
                                  authorize Headway to send text messages to the
                                  mobile number on file. Message and data rates
                                  may apply.{' '}
                                  <HelixLink
                                    component={Link}
                                    elementType="a"
                                    to="/legal/notifications"
                                    target="_blank"
                                  >
                                    View full contract addendum.
                                  </HelixLink>
                                </span>
                              </BodyText>
                            </p>
                          )}
                      </div>
                      {values.selectedSmsOptInTypes.length > 0 && (
                        <>
                          <FormControl
                            name="selectedSmsOptInTypes"
                            component={CheckboxGroup}
                            aria-label="SMS notifications"
                          >
                            <Checkbox value={SmsReminderType.CAL_DIGEST}>
                              Overview of upcoming sessions and openings (weekly
                              on Sundays)
                            </Checkbox>
                            <Checkbox value={SmsReminderType.CANCELED_SESSION}>
                              Cancellations
                            </Checkbox>
                            <Checkbox value={SmsReminderType.NEW_SESSION}>
                              New referrals and bookings
                            </Checkbox>
                          </FormControl>
                        </>
                      )}
                    </div>
                  </PageSection>
                  <FormStickyFooter>
                    <Button
                      onPress={() => {
                        resetForm();
                      }}
                      disabled={!dirty || isSubmitting}
                      size="large"
                      variant="secondary"
                    >
                      Cancel
                    </Button>

                    <Button
                      type="submit"
                      disabled={!dirty || isSubmitting}
                      variant="primary"
                      size="large"
                    >
                      Save
                    </Button>
                  </FormStickyFooter>
                </Form>
              );
            }}
          />
        );
      }
    }
  )
);
