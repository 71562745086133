export const CALENDAR_VIEW_STORAGE_KEY = 'calendarView';

export enum RecurrenceType {
  DOES_NOT_REPEAT = 'DOES_NOT_REPEAT',
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  MONTHLY_LAST = 'MONTHLY_LAST',
  EVERY_THREE_MONTHS = 'EVERY_THREE_MONTHS',
  EVERY_THREE_MONTHS_LAST = 'EVERY_THREE_MONTHS_LAST',
}

export enum PopUpModalTitleType {
  GET_APPOINTMENT_REMINDER = 'Get appointment reminder texts?',
  UPDATE_BOOKING_AVAILABILITY = 'Update your booking availability?',
  IMPORT_EXTERNAL_CALENDAR = 'Import external calendar to Headway?',
  REQUIRE_INTRO_CALL = 'Require 15-minute introductory calls?',
  STOP_REFERRALS = 'Stop referrals from Headway?',
  CANCEL_SESSION = 'Cancel Session',
  MANAGE_CLIENT_REFERRAL = 'Learn how to manage client referrals',
}
