import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import * as React from 'react';

/**
 * Add this component at the same level of the Form component.
 * This allows for a submit anytime any of the values updates, without
 * requiring a specific submit/save button.
 */

interface SubmitListenerProps {
  considerInitialValues?: boolean;
  throttleMs?: number;
}

export const SubmitListener = ({
  considerInitialValues = true,
  throttleMs = 1200,
}: SubmitListenerProps) => {
  const formik = useFormikContext<any>();
  const [lastValues, updateState] = React.useState(formik.values);

  const submitForm = React.useCallback(
    debounce(
      (): void => {
        formik.submitForm();
      },
      throttleMs,
      { maxWait: 1500 }
    ),
    []
  );

  const [wasInvalid, setWasInvalid] = React.useState(false);

  React.useEffect(() => {
    if (!formik.isValid) {
      setWasInvalid(true);
      return;
    }

    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (considerInitialValues) {
      const valuesEqualInitialValues = isEqual(
        formik.values,
        formik.initialValues
      );

      if (
        (!valuesEqualLastValues &&
          !valuesEqualInitialValues &&
          formik.isValid) ||
        (wasInvalid && formik.isValid)
      ) {
        submitForm();
      }
    } else {
      if (
        (!valuesEqualLastValues && formik.isValid) ||
        (wasInvalid && formik.isValid)
      ) {
        submitForm();
      }
    }
  }, [formik.values, formik.isValid, formik.errors]);

  return null;
};
