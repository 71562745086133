import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import React from 'react';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';
import { PatientFormNestedRead } from '@headway/api/models/PatientFormNestedRead';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { UserRead } from '@headway/api/models/UserRead';
import { PatientFormApi } from '@headway/api/resources/PatientFormApi';
import { Badge, BadgeProps } from '@headway/helix/Badge';
import { Button } from '@headway/helix/Button';
import { IconError } from '@headway/helix/icons/Error';
import { Link } from '@headway/helix/Link';
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from '@headway/helix/Table';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import { useMediaQuery } from '@headway/helix/utils';
import { useQueryClient } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import { usePatientAssessments } from '@headway/ui/hooks/usePatientAssessments';

import {
  getUsePatientFormsQueryKey,
  usePatientForms,
} from 'hooks/usePatientForm';
import { usePatientFormSchemas } from 'hooks/usePatientFormSchema';

import { intakeEmailSentRecently } from './ClinicalDetail';

export interface IntakeFormsTableProps {
  client: UserRead;
  providerPatient: ProviderPatientRead;
  'aria-labelledby': string;
  isSendIntakeFormEmailToPatient: boolean;
  handleSendIntakeFormEmailToPatient: () => void;
}

export const IntakeFormsTable = ({
  client,
  providerPatient,
  'aria-labelledby': ariaLabelledBy,
  isSendIntakeFormEmailToPatient,
  handleSendIntakeFormEmailToPatient,
}: IntakeFormsTableProps) => {
  const queryClient = useQueryClient();
  const isMobileView = useMediaQuery(theme.mediaQuery.mobile);

  const { data: sentIntakeForms, isLoading: isPatientFormsLoading } =
    usePatientForms({
      providerPatientIds: [providerPatient.id],
      deletionStatus: ['ACTIVE', 'ARCHIVED'],
    });

  const sentIntakeFormsBySchemaType = groupBy(
    sortBy(sentIntakeForms, 'sentOn') ?? [],
    (sentIntakeForm) => sentIntakeForm.patientFormSchema.formType
  );

  const { data: activeFormSchemas, isLoading: isPatientFormSchemaLoading } =
    usePatientFormSchemas({
      providerId: providerPatient.providerId,
    });

  // get the date of the earliest whodas/promis sent to client, including any expired assessments
  const { data: initialWhodasSent } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      assessment_type: PatientAssessmentType.WHODAS_2,
      include_expired: true,
      order_by: 'created_on',
      limit: 1,
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.data.length > 0 ? data.data[0] : null;
      },
    }
  );
  const { data: initialPromisSent } = usePatientAssessments(
    {
      provider_patient_id: providerPatient?.id,
      assessment_type: PatientAssessmentType.PROMIS,
      include_expired: true,
      order_by: 'created_on',
      limit: 1,
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return data.data.length > 0 ? data.data[0] : null;
      },
    }
  );
  const firstNqfAssessmentSentOn =
    initialWhodasSent?.createdOn && initialPromisSent?.createdOn
      ? initialWhodasSent.createdOn < initialPromisSent.createdOn
        ? initialWhodasSent.createdOn
        : initialPromisSent.createdOn
      : initialWhodasSent?.createdOn || initialPromisSent?.createdOn;

  const columns = [
    ...(isMobileView
      ? [
          <Column key="formType" width={'20%'}>
            Form type
          </Column>,
        ]
      : [
          <Column key="formType" width={'50%'}>
            Form type
          </Column>,
          <Column key="dateSent">Date sent</Column>,
        ]),
    <Column key="intakeStatus">Status</Column>,
  ];

  const getPotentialDeletedSignedForms = () => {
    const forms: PatientFormNestedRead[] = [];
    const deletedSignedForms = sentIntakeForms?.filter(
      (intakeForm) =>
        intakeForm.submittedOn && intakeForm.patientFormSchema.deletedOn
    );

    deletedSignedForms?.forEach((form) => {
      if (
        !activeFormSchemas?.find(
          (formSchema) =>
            formSchema.formType === form.patientFormSchema.formType
        )
      ) {
        forms.push(form);
      }
    });

    return forms;
  };

  const isPrivacyOrConsentForm = (intakeFormId: string) =>
    intakeFormId === 'intake-form-type-privacy-practices' ||
    intakeFormId === 'intake-form-type-consent-forms';

  const privacyOrConsentFormSentDate = providerPatient.createdOn;
  const rows = [
    {
      id: 'intake-form-type-privacy-practices',
      name: 'Privacy practices (Headway)',
      sentOn: privacyOrConsentFormSentDate || '',
      submittedOn: client.privacyPracticesAcknowledgementDate,
      required: true,
      previewLink: 'https://headway.co/legal/privacy',
    },
    {
      id: 'intake-form-type-consent-forms',
      name: 'Consent forms (Headway)',
      sentOn: privacyOrConsentFormSentDate || '',
      submittedOn: client.assignmentOfBenefitsDate,
      required: true,
      previewLink: 'https://headway.co/legal/responsibility',
    },
    /**
     * We want to show both sent forms as well as unsent forms (schemas). We map over the schemas
     * and check if there are any sent forms for that schema. If there are no sent
     * forms, we add a row for the schema.
     *
     * If there are sent forms, we add a row for each sent form, suppressing any stale
     * forms. When a form's version is not the active version we append the createdOn
     * date to the form name.
     */
    ...(activeFormSchemas ?? []).flatMap((formSchema) => {
      const sentFormsForSchemaType =
        sentIntakeFormsBySchemaType[formSchema.formType] ?? [];

      const forms = [];
      const sentSchemaIds = new Set();

      for (const sentForm of sentFormsForSchemaType) {
        sentSchemaIds.add(sentForm.patientFormSchemaId);

        const isDeletedSubmittedAndSigned =
          sentForm.submittedOn && sentForm.patientFormSchema.deletedOn;

        // If the sent form is incomplete and the associated form schema has been deleted, we want to skip it.
        if (!sentForm.submittedOn && sentForm.patientFormSchema.deletedOn) {
          continue;
        }

        let name = formSchema.name;

        if (isDeletedSubmittedAndSigned) {
          const sent = moment(formSchema.createdOn).format('M/D/YY');

          name = `${name} (${sent})`;
        }

        forms.push({
          id: String(sentForm.id),
          name: name,
          sentOn: sentForm?.createdOn || '',
          submittedOn: sentForm?.submittedOn,
          required: false,
          previewLink: `/intake-forms/${sentForm.patientFormSchemaId}?responseFormId=${sentForm.id}&providerPatientId=${providerPatient.id}`,
        });
      }

      // If there are no sent forms for this schema type, we want to add a row for the schema itself.
      if (!sentSchemaIds.has(formSchema.id)) {
        forms.push({
          id: String(formSchema.id),
          name: formSchema.name,
          previewLink: `/intake-forms/${formSchema.id}`,
          required: false,
          sentOn: '',
          submittedOn: '',
        });
      }

      return forms;
    }),
    ...(getPotentialDeletedSignedForms() || []).flatMap(
      (form: PatientFormNestedRead) => {
        const sent = moment(form.patientFormSchema.createdOn).format('M/D/YY');
        const name = `${form.patientFormSchema.name} (${sent})`;
        return [
          {
            id: String(form.id),
            name: name,
            sentOn: form?.createdOn || '',
            submittedOn: form?.submittedOn,
            required: false,
            previewLink: `/intake-forms/${form.patientFormSchemaId}?responseFormId=${form.id}&providerPatientId=${providerPatient.id}`,
          },
        ];
      }
    ),
  ].sort((a, b) => {
    if (a.sentOn && b.sentOn) {
      return new Date(a.sentOn).getTime() - new Date(b.sentOn).getTime();
    }

    if (a.sentOn) {
      return -1;
    }

    if (b.sentOn) {
      return 1;
    }

    return 0;
  });

  // the NQF consent form is not an Intake Form and therefore not sent through the intake form schema, so we need to add it manually
  if (firstNqfAssessmentSentOn) {
    rows.push({
      id: 'nqf-consent-form',
      name: 'Consent to Participate in the Aligned Innovation Study with Headway',
      sentOn: firstNqfAssessmentSentOn,
      submittedOn: client.consentedToNqfOn,
      required: false,
      previewLink: `/aligned-innovation-study-consent?clientId=${client.id}&providerPatientId=${providerPatient.id}`,
    });
  }

  return isPatientFormsLoading || isPatientFormSchemaLoading ? (
    <Skeleton variant="rectangular" height={64} />
  ) : (
    <>
      <div css={intakeFormsTableCss.container}>
        <Table aria-labelledby={ariaLabelledBy}>
          <TableHeader>{columns}</TableHeader>
          <TableBody>
            {rows.map((intakeForm) => {
              return (
                <Row key={intakeForm.id}>
                  {[
                    <Cell key={`intakeFormName-${intakeForm.id}`}>
                      <Link href={intakeForm.previewLink} target="_blank">
                        {intakeForm?.name}{' '}
                      </Link>
                    </Cell>,
                    ...(isMobileView
                      ? []
                      : [
                          <Cell key="intakeFormOriginalSentOn">
                            {intakeForm.sentOn
                              ? moment(intakeForm.sentOn).format('M/D/YY')
                              : String.fromCodePoint(0x2013)}
                          </Cell>,
                        ]),
                    <Cell key={`badgeStatus-${intakeForm.id}`}>
                      <div
                        css={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <IntakeFormBadge
                          condition={intakeForm.submittedOn}
                          required={intakeForm.required}
                          sent={
                            !!intakeForm.sentOn ||
                            isPrivacyOrConsentForm(intakeForm.id)
                          }
                          isMobileView={isMobileView}
                        />
                        {!(
                          intakeForm.sentOn ||
                          isPrivacyOrConsentForm(intakeForm.id)
                        ) && (
                          <Button
                            key={`sendResendButton-${intakeForm.id}`}
                            variant="secondary"
                            size="medium"
                            disabled={isSendIntakeFormEmailToPatient}
                            onPress={async () => {
                              try {
                                await PatientFormApi.createPatientForm({
                                  providerPatientId: providerPatient.id,
                                  patientFormSchemaId: Number(intakeForm.id),
                                  sentOn: new Date().toISOString(),
                                });
                                queryClient.invalidateQueries({
                                  queryKey: getUsePatientFormsQueryKey([
                                    providerPatient.id,
                                  ]),
                                });

                                toasts.add(
                                  `Form sent to ${client?.displayFirstName}`,
                                  {
                                    variant: 'positive',
                                  }
                                );
                                if (
                                  !intakeEmailSentRecently(sentIntakeForms, 1)
                                ) {
                                  try {
                                    handleSendIntakeFormEmailToPatient();
                                  } catch (e) {
                                    toasts.add(
                                      'Failed to send email notification to patient',
                                      {
                                        variant: 'negative',
                                      }
                                    );
                                  }
                                }

                                trackEvent({
                                  name: 'Send Intake Form Button Clicked',
                                  properties: {
                                    providerId: providerPatient.providerId,
                                    providerPatientId: providerPatient.id,
                                  },
                                });
                              } catch (e) {
                                toasts.add('Failed to send form', {
                                  variant: 'negative',
                                });
                              }
                            }}
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </Cell>,
                  ]}
                </Row>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const intakeFormsTableCss = {
  container: css({
    margin: `0 -${theme.spacing.x4}`,
    display: 'grid',
    '--row-height': '68px',
    '& .hlx-table td > div': {
      // 68px row height - 16px top/bottom padding - border
      minHeight: 'calc(var(--row-height) - (16px * 2) - 1px)',
    },
  }),
};

interface IntakeFormBadgeProps {
  condition?: string;
  required?: boolean;
  sent?: boolean;
  isMobileView?: boolean;
}

const IntakeFormBadge = ({
  condition,
  required,
  sent,
  isMobileView,
}: IntakeFormBadgeProps) => {
  const badgeFirstText = !sent
    ? `Not sent`
    : condition
    ? `Completed `
    : required
    ? 'Incomplete: '
    : 'Incomplete';
  let badgeSecondText = '';
  let badgeColor: BadgeProps['variant'];

  if (!sent) {
    badgeColor = 'neutral';
  } else if (condition) {
    badgeSecondText = moment(condition).format('M/D/YY');
    badgeColor = 'positive';
  } else if (!condition && required) {
    badgeSecondText = 'required';
    badgeColor = 'negative';
  } else {
    badgeSecondText = '';
    badgeColor = 'warning';
  }

  return (
    <Badge
      variant={badgeColor}
      icon={badgeColor === 'negative' ? IconError : undefined}
    >
      {badgeFirstText}
      {isMobileView && <br />}
      {badgeSecondText}
    </Badge>
  );
};
