import { FormLabel } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';

import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import {
  isProviderInAnyStates,
  isProviderInState,
} from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { FieldControl, FieldDropzone, FieldErrorText } from '@headway/ui/form';

import { onDropFiles } from '../../../utils/providerQuestionnaire';
import { BlueCrossCaliforniaFollowupQuestions } from '../blueCrossCalifornia/BlueCrossCaliforniaFollowupQuestions';
import {
  BscaSpecialties,
  SectionStepForBscaSpecialty,
} from '../blueCrossCalifornia/constants';
import { getInitialValuesForStep as getBscaInitialValuesForStep } from '../blueCrossCalifornia/initialValues';
import { getSchemaForStep as getBscaSchemaForStep } from '../blueCrossCalifornia/schema';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

const statesThatRequireClinicalOrientation = [UnitedStates.CALIFORNIA];

const modalitiesRequiringCertificate = [
  'Dialectical Behavioral Therapy (DBT)',
  'EMDR',
];
const clinicalOrientations = [
  'Behavioral',
  'Biological/Medical',
  'Cognitive',
  'Cognitive Behavioral',
  'Psychodynamic',
  'Systems',
];

function PracticeModalities({ formikHelpers }: CustomComponentProps) {
  const { provider, modalities } = useQuestionnaireContext();

  if (!modalities) {
    return null;
  }

  const { values } = formikHelpers;

  const selectedModalitiesRequiringCertificate = values?.modalities
    ?.filter((modality: string) =>
      modalitiesRequiringCertificate.includes(modality)
    )
    .sort();
  return (
    <div css={{ ...theme.reset }}>
      <section
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: theme.spacing.x6,
        }}
      >
        <FormControl
          component={CheckboxGroup}
          name="modalities"
          label="Which therapy modalities do you commonly use during treatment?"
        >
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridRowGap: theme.spacing.x1,
            }}
          >
            {modalities.map((modality) => (
              <Checkbox value={modality.clinicalDisplayName}>
                {modality.clinicalDisplayName}
              </Checkbox>
            ))}
          </div>
        </FormControl>
        {selectedModalitiesRequiringCertificate?.map((modality: string) => (
          <FieldControl
            key={modality}
            name={`modalityCertificates[${modality}]`}
            fullWidth={true}
            css={{ marginTop: theme.spacing.x6 }}
          >
            <FormLabel>
              Please upload a copy of your <b>{modality} Certificate</b>.
            </FormLabel>
            <FieldDropzone
              accept="application/pdf,image/*"
              onDrop={(file: any) => onDropFiles(provider.id, file)}
            />
            <FieldErrorText />
          </FieldControl>
        ))}
        <FormControl component={Checkbox} name="evidenceBasedTreatmentAttest">
          I attest to using only evidence-based treatment approaches and
          modalities with patients that I see through Headway. I understand that
          using non evidence-based treatment approaches while on Headway may
          result in termination from the network.
        </FormControl>
        {isProviderInState(provider, UnitedStates.CALIFORNIA) && (
          <>
            <BlueCrossCaliforniaFollowupQuestions
              values={values}
              stepForBscaSpecialty={SectionStepForBscaSpecialty.Modalities}
              specialtyKeys={[BscaSpecialties.NeuropsychologicalTesting]}
            />
            <div css={{ ...theme.stack.vertical, gap: theme.spacing.x6 }}>
              <h2>
                <SectionHeader>Clinical Orientation</SectionHeader>
              </h2>
              <FormControl
                component={CheckboxGroup}
                name="clinicalOrientation"
                label="Please select all that apply."
              >
                {clinicalOrientations.map((clinicalOrientation) => (
                  <Checkbox
                    key={clinicalOrientation}
                    value={clinicalOrientation}
                  >
                    {clinicalOrientation}
                  </Checkbox>
                ))}
              </FormControl>
            </div>
          </>
        )}
      </section>
    </div>
  );
}

const stepConfig: QuestionnaireV2Step = {
  title: 'Practice Modalities',
  description:
    'In this section, we ask you to share details on your practice focus modalities.',
  Component: PracticeModalities,
  getFormMeta: ({
    provider,
    providerQuestionnaire: { rawData },
    modalities,
  }) => {
    const allModalities = modalities;
    const validationSchema = Yup.object().shape({
      modalities: Yup.array()
        .of(Yup.string().required('This cannot be blank.'))
        .min(1, 'You must select at least one modality.')
        .required('You must select at least one modality.'),
      evidenceBasedTreatmentAttest: Yup.boolean().oneOf(
        [true],
        'You must read and agree to the statement above.'
      ),
      modalityCertificates: Yup.object().when(
        'modalities',
        (modalities: string[], schema: any) => {
          return schema.shape(
            allModalities.reduce(
              (agg: { [key: string]: Yup.StringSchema }, modality) => {
                const modalityRequiresCertificate =
                  modalitiesRequiringCertificate.includes(
                    modality.clinicalDisplayName
                  );
                const modalityIsSelected = modalities?.find(
                  (item) => item === modality.clinicalDisplayName
                );
                let modalitySchema = Yup.string();
                if (modalityRequiresCertificate && modalityIsSelected) {
                  modalitySchema = Yup.string().required(
                    `A certificate for ${modality.clinicalDisplayName} is required.`
                  );
                }
                agg[modality.clinicalDisplayName] = modalitySchema;
                return agg;
              },
              {}
            )
          );
        }
      ),
      ...getBscaSchemaForStep(
        SectionStepForBscaSpecialty.Modalities,
        rawData?.selectedLicenses
      ),
      clinicalOrientation: isProviderInAnyStates(
        provider,
        statesThatRequireClinicalOrientation
      )
        ? Yup.array().of(Yup.string()).required('This cannot be blank.')
        : Yup.array(),
    });

    return {
      validationSchema: validationSchema,
      initialValue: {
        ...yupSchemaToDefaultValue(validationSchema),
        ...getBscaInitialValuesForStep(
          provider,
          SectionStepForBscaSpecialty.Modalities,
          rawData || {}
        ),
        ...(isProviderInAnyStates(
          provider,
          statesThatRequireClinicalOrientation
        ) && {
          clinicalOrientation: [],
        }),
        ...rawData,
      },
    } as FormMeta;
  },
};

export default stepConfig;
