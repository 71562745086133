import { MenuItem, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';

import { FieldControlContext } from '@headway/ui/form';

const COMMON_TIMES = [15, 30, 45, 60];

export const FieldDurationSelect = ({ startDate, ...props }) => {
  const { name, id } = React.useContext(FieldControlContext);
  const { values, setFieldValue } = useFormikContext();
  const [duration, setDuration] = React.useState(
    startDate
      ? moment.duration(moment(values[name]).diff(startDate)).asMinutes()
      : undefined
  );

  // if startDate changes, we need to add the selected duration again
  React.useEffect(() => {
    const addDuration = moment(startDate).add(duration, 'minutes');

    if (moment(values[name]).diff(addDuration) !== 0) {
      setFieldValue(name, addDuration.toISOString());
    }
  }, [startDate, duration, name, setFieldValue, values]);

  return (
    <Select
      variant="standard"
      {...props}
      id={id}
      labelId="duration"
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300,
          },
        },
      }}
      value={duration}
      onChange={({ target: { value } }) => {
        setFieldValue(
          name,
          moment(startDate).add(value, 'minutes').toISOString()
        );
        setDuration(value);
      }}
    >
      <MenuItem key="common_times" disabled={true}>
        Common times
      </MenuItem>
      {COMMON_TIMES.map((time, idx) => {
        return (
          <MenuItem key={`common_${time}`} value={time}>
            {time} minutes
          </MenuItem>
        );
      })}
      <MenuItem key="all_times" disabled={true}>
        All times
      </MenuItem>
      {Array.from(Array(24), (_, idx) => (idx + 1) * 5)
        .filter((time) => !COMMON_TIMES.includes(time))
        .map((time, idx) => {
          return (
            <MenuItem key={`all_${time}`} value={time}>
              {time} minutes
            </MenuItem>
          );
        })}
    </Select>
  );
};
