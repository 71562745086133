import moment, { Duration } from 'moment';

import { PatientAssessmentType } from '@headway/api/models/PatientAssessmentType';

import { TimeScale } from './types';

export interface AssessmentScoreRange {
  startScore: number; // inclusive
  endScore?: number; // inclusive, if not provided assumed infinitely long range
  descriptor: string;
  abridgedDescriptor?: string;
  // Variant used for badges and line chart glyphs
  variant: 'positive' | 'warning' | 'negative';
}

export const ASSESSMENT_SCORE_RANGES: {
  [assessmentType in PatientAssessmentType]: AssessmentScoreRange[];
} = {
  [PatientAssessmentType.ADNM8]: [
    {
      startScore: 0,
      endScore: 8,
      descriptor: 'Mild adjustment difficulty',
      variant: 'positive',
    },
    {
      startScore: 9,
      endScore: 17,
      descriptor: 'Moderate adjustment difficulty',
      variant: 'warning',
    },
    {
      startScore: 18,
      endScore: 32,
      descriptor: 'Significant adjustment difficulty',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.ASRS]: [
    {
      startScore: 0,
      endScore: 3,
      descriptor: 'Inconsistent with ADHD ',
      variant: 'positive',
    },
    {
      startScore: 4,
      descriptor: 'Highly consistent with ADHD ',
      variant: 'warning',
    },
  ],
  [PatientAssessmentType.GAD7]: [
    {
      startScore: 0,
      endScore: 4,
      descriptor: 'Minimal anxiety',
      variant: 'positive',
    },
    {
      startScore: 5,
      endScore: 9,
      descriptor: 'Mild anxiety',
      variant: 'warning',
    },
    {
      startScore: 10,
      endScore: 14,
      descriptor: 'Moderate anxiety',
      variant: 'warning',
    },
    {
      startScore: 15,
      endScore: 21,
      descriptor: 'Severe anxiety',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.IESR]: [
    {
      startScore: 0,
      endScore: 24,
      descriptor: 'Low to moderate distress',
      variant: 'positive',
    },
    {
      startScore: 24,
      endScore: 33,
      descriptor: 'Moderate to high distress',
      variant: 'warning',
    },
    {
      startScore: 33,
      endScore: 36,
      descriptor: 'Consistent with PTSD criteria',
      variant: 'warning',
    },
    {
      startScore: 37,
      descriptor: 'Severe distress',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.ISI]: [
    {
      startScore: 0,
      endScore: 7,
      descriptor: 'No clinically significant insomnia',
      variant: 'positive',
    },
    {
      startScore: 8,
      endScore: 14,
      descriptor: 'Subthreshold insomnia',
      variant: 'warning',
    },
    {
      startScore: 15,
      endScore: 21,
      descriptor: 'Clinical insomnia (moderate severity)',
      variant: 'negative',
    },
    {
      startScore: 22,
      endScore: 28,
      descriptor: 'Clinical insomnia (severe)',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.PCL5]: [
    {
      startScore: 0,
      endScore: 0,
      descriptor: 'No PTSD symptoms',
      variant: 'positive',
    },
    {
      startScore: 1,
      endScore: 20,
      descriptor: 'Mild PTSD symptoms',
      variant: 'warning',
    },
    {
      startScore: 21,
      endScore: 40,
      descriptor: 'Moderate PTSD symptoms',
      variant: 'warning',
    },
    {
      startScore: 41,
      endScore: 60,
      descriptor: 'Significant PTSD symptoms',
      variant: 'negative',
    },
    {
      startScore: 61,
      endScore: 80,
      descriptor: 'Extreme PTSD symptoms',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.PHQ9]: [
    {
      startScore: 0,
      endScore: 4,
      descriptor: 'Minimal depression',
      variant: 'positive',
    },
    {
      startScore: 5,
      endScore: 9,
      descriptor: 'Mild depression',
      variant: 'warning',
    },
    {
      startScore: 10,
      endScore: 14,
      descriptor: 'Moderate depression',
      variant: 'warning',
    },
    {
      startScore: 15,
      endScore: 19,
      descriptor: 'Moderately severe depression',
      variant: 'negative',
    },
    {
      startScore: 20,
      endScore: 27,
      descriptor: 'Severe depression',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.WHO5]: [
    {
      startScore: 0,
      endScore: 28,
      descriptor: 'Very low wellbeing, screening for depression is suggested',
      abridgedDescriptor: 'Very low wellbeing',
      variant: 'negative',
    },
    {
      startScore: 29,
      endScore: 52,
      descriptor: 'Poor wellbeing, screening for depression is suggested',
      abridgedDescriptor: 'Poor wellbeing',
      variant: 'negative',
    },
    {
      startScore: 53,
      endScore: 74,
      descriptor: 'Moderate wellbeing',
      variant: 'warning',
    },
    {
      startScore: 75,
      endScore: 100,
      descriptor: 'High wellbeing',
      variant: 'positive',
    },
  ],
  [PatientAssessmentType.WHODAS_2]: [
    {
      startScore: 1.0,
      endScore: 1.99,
      descriptor: 'No functional impairment',
      variant: 'positive',
    },
    {
      startScore: 2.0,
      endScore: 2.99,
      descriptor: 'Mild functional impairment',
      variant: 'warning',
    },
    {
      startScore: 3.0,
      endScore: 3.99,
      descriptor: 'Moderate functional impairment',
      variant: 'warning',
    },
    {
      startScore: 4.0,
      endScore: 4.99,
      descriptor: 'Severe functional impairment',
      variant: 'negative',
    },
    {
      startScore: 5,
      endScore: 5,
      descriptor: 'Extreme functional impairment',
      variant: 'negative',
    },
  ],
  [PatientAssessmentType.PROMIS]: [
    {
      startScore: 4,
      endScore: 10,
      descriptor: 'Poor physical health',
      variant: 'negative',
    },
    {
      startScore: 11,
      endScore: 13,
      descriptor: 'Fair physical health',
      variant: 'warning',
    },
    {
      startScore: 14,
      endScore: 16,
      descriptor: 'Good physical health',
      variant: 'positive',
    },
    {
      startScore: 17,
      endScore: 18,
      descriptor: 'Very good physical health',
      variant: 'positive',
    },
    {
      startScore: 19,
      endScore: 20,
      descriptor: 'Excellent physical health',
      variant: 'positive',
    },
  ],
  [PatientAssessmentType.ANCHOR]: [],
};

export const ASSESSMENT_DESCRIPTIONS: {
  [assessmentType in PatientAssessmentType]: string;
} = {
  [PatientAssessmentType.ADNM8]:
    'The Adjustment Disorder-New Module (ADNM-8) is a short questionnaire used to measure levels of distress and presence of adjustment disorder symptoms.',
  [PatientAssessmentType.ANCHOR]:
    'NQF Anchor Questions to be presented at 12 week follow up as well as 17 week follow up (these questions are to be sent at 12 week follow up AND 17 week follow up)',
  [PatientAssessmentType.ASRS]:
    'The Adult ADHD Self-Report Scale v1.1 (ASRSv1.1) is a self-report questionnaire designed to assess Attention Deficit Hyperactivity Disorder (ADHD) symptoms in adults.',
  [PatientAssessmentType.GAD7]:
    'The Generalized Anxiety Disorder 7-item (GAD-7) is a widely used tool to detect, diagnose, and manage anxiety disorders effectively.',
  [PatientAssessmentType.IESR]:
    'The Impact of Event Scale — Revised (IES-R) is a short questionnaire used to measure distress caused by traumatic events and identify self-reported trauma symptoms.',
  [PatientAssessmentType.ISI]:
    'The Insomnia Severity Index (ISI) is a brief screening tool for insomnia.',
  [PatientAssessmentType.PCL5]:
    'The PTSD Checklist for DSM-5 (PCL-5) is a self-report measure that assesses the presence and severity of PTSD symptoms.',
  [PatientAssessmentType.PHQ9]:
    'The Patient Health Questionnaire 9-item (PHQ-9) is a common clinical tool to screen individuals for depression, monitor changes in depression symptoms over time, and assess suicide risk.',
  [PatientAssessmentType.WHO5]:
    "The World Health Organization-Five Well-Being Index (WHO-5) is a short and easy to use tool to measure a client's current mental well-being.",
  [PatientAssessmentType.WHODAS_2]:
    'World Health Organization Disability Assessment Schedule 2.0 (WHODAS 2.0) is a self-report measure that assesses disability related to health conditions in the past 30 days. It was designed specifically to gauge a person’s potential level of disability and/or functional impairment.',
  [PatientAssessmentType.PROMIS]:
    'The PROMIS Scale v1.2 - Global Physical Health 4a is a self-report measure that assesses a person’s physical health. It is intended to globally reflect individuals’ assessment of their physical health.',
};

export const ASSESSMENT_TYPES_USING_SUBSCORES = [PatientAssessmentType.ASRS];

export const TIME_SCALE_TO_DURATION: {
  [scale in TimeScale]: Duration | undefined;
} = {
  [TimeScale.ALL]: undefined,
  [TimeScale.ONE_MONTH]: moment.duration(1, 'month'),
  [TimeScale.THREE_MONTHS]: moment.duration(3, 'month'),
  [TimeScale.SIX_MONTHS]: moment.duration(6, 'month'),
  [TimeScale.ONE_YEAR]: moment.duration(1, 'year'),
};

// Classname used for targeting Intercom tooltip
export const MOST_RECENT_ASSESSMENT_CLASSNAME = 'hw-line-chart-most-recent';
