import React from 'react';

import { BodyText } from '@headway/helix/BodyText';

import { SchemaComponent } from '../../schema/schema.types';

export const Paragraph = ({ element }: SchemaComponent) => {
  const { textContent } = element;

  if (!textContent) {
    // If we have an empty text content, we consider it intentional and render a line break
    return <br />;
  }

  return <BodyText>{textContent}</BodyText>;
};
