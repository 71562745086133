import { BodyText } from '@headway/helix/BodyText';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListHeader } from '@headway/helix/ListHeader';

import CredentialingSteps from 'assets/img/CredentialingSteps.png';

export const MscPromotionalCard = () => {
  return (
    <div className="flex justify-between overflow-hidden rounded bg-system-backgroundGray">
      <div className="flex-grow bg-system-backgroundGray px-5 py-6 shadow-[0_0_12px_8px] shadow-system-backgroundGray tablet:max-w-[630px]">
        <ListHeader>Expand your practice across multiple states</ListHeader>
        <div className={'pt-1'}>
          <BodyText>
            You can now use multiple licenses on Headway and manage your entire
            practice in one place
          </BodyText>
        </div>
        <div className="mt-4 flex flex-row gap-3">
          <LinkButton
            variant="primary"
            href="/credentials?newQuestionnaire=true"
          >
            Get credentialed
          </LinkButton>
        </div>
      </div>
      <div
        aria-hidden
        className="pointer-events-none flex flex-col items-end justify-center px-[52.5px] max-tablet:hidden"
      >
        <img src={CredentialingSteps} className="h-[118px] w-[auto]" />
      </div>
    </div>
  );
};
