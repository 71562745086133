import moment from 'moment';

import { getLocalStorageKeyForProvider } from './localStorage';

export const is1DayFromLastAcknowledgedDate = (
  key: string,
  providerId: number
) => {
  const lastAcknowledgedDateKey = getLocalStorageKeyForProvider(
    key,
    providerId
  );

  const lastAcknowledgedDate = window.localStorage.getItem(
    lastAcknowledgedDateKey
  );

  return (
    !lastAcknowledgedDate ||
    moment().isAfter(moment(lastAcknowledgedDate).add(1, 'days'))
  );
};

export const isFirstModalRender = (key: string, providerId: number) => {
  const lastAcknowledgedDateKey = getLocalStorageKeyForProvider(
    key,
    providerId
  );

  return window.localStorage.getItem(lastAcknowledgedDateKey) === null;
};
