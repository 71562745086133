import { FormLabel } from '@mui/material';
import React from 'react';
import * as Yup from 'yup';

import { License } from '@headway/api/models/License';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { FormControl } from '@headway/helix/FormControl';
import { Radio } from '@headway/helix/Radio';
import { RadioGroup } from '@headway/helix/RadioGroup';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import {
  YUP_NPI_ERROR_MESSAGE,
  YUP_NPI_MATCH,
} from '@headway/shared/constants/format';
import {
  getLowestLevelLicense,
  hasNPLicenseInStatesRequiringNPPrescriberAgreements,
  hasNurseLicenseType,
  hasPrescriberLicenseType,
} from '@headway/shared/utils/licenseHelper';
import { isProviderInState } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import { FieldControl, FieldDropzone, FieldErrorText } from '@headway/ui/form';

import {
  IN_PROCESS,
  onDropFiles,
  YesNo,
} from '../../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../../QuestionnaireV2Step';
import { CustomComponentProps } from '../../utils/CustomComponentProps';
import { yupSchemaToDefaultValue } from '../../utils/yupSchemaToDefaultValue';
import { BoardCertificationHeader } from './BoardCertificateHeader';

export const stateRequiresBoardCertificationUpload = [UnitedStates.FLORIDA];

const doesStateRequireBoardCertUpload = (selectedLicenses?: {
  [key: string]: License;
}) => {
  return hasPrescriberLicenseType(
    selectedLicenses,
    stateRequiresBoardCertificationUpload
  );
};

const BoardCertificationStep = ({
  initialValues,
  formikHelpers,
}: CustomComponentProps) => {
  const { provider } = useQuestionnaireContext();
  const lowestLevelLicense = getLowestLevelLicense(
    initialValues.selectedLicenses
  );

  const shouldAskIfRequiresCollabPhysician =
    hasNPLicenseInStatesRequiringNPPrescriberAgreements(
      formikHelpers.values?.selectedLicenses
    );
  const shouldShowCollabPhysicianQuestions =
    shouldAskIfRequiresCollabPhysician &&
    formikHelpers.values?.requiresSupervisingPhysician === YesNo.YES;

  return (
    <>
      <BoardCertificationHeader initialValues={initialValues} />
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        <div
          css={{
            ...theme.stack.vertical,
            gap: theme.spacing.x1,
            width: '60%',
          }}
        >
          <SectionHeader>Additional Information</SectionHeader>
        </div>
        {doesStateRequireBoardCertUpload(
          formikHelpers.values?.selectedLicenses
        ) && (
          <FieldControl
            name={`boardCertificationUrl`}
            fullWidth
            css={{ marginBottom: '0' }}
          >
            <FormLabel>
              Please upload a copy of this <b>Board Certification</b>.
            </FormLabel>
            <FieldDropzone
              accept="application/pdf,image/*"
              onDrop={(file: any) => onDropFiles(provider.id, file)}
              inputTestId="boardCertificationUrl"
              css={{ marginBottom: '0' }}
            />
            <FieldErrorText />
          </FieldControl>
        )}
        <FormControl
          component={TextField}
          name={'numberOfYearsOfExperience'}
          label={`How many years of experience do you have as a ${lowestLevelLicense?.licenseType}?`}
        />
        {hasPrescriberLicenseType(formikHelpers.values?.selectedLicenses) &&
          shouldAskIfRequiresCollabPhysician && (
            <>
              <FormControl
                name={`requiresSupervisingPhysician`}
                component={RadioGroup}
                label="If required, do you have a supervising physician?"
              >
                <Radio value={YesNo.YES}>
                  Yes, I have a supervising physician
                </Radio>
                <Radio value={IN_PROCESS}>
                  I am in the process of obtaining one and will have a
                  supervising/collaborative physician prior to holding my first
                  session on Headway
                </Radio>
                <Radio value={YesNo.NO}>
                  I don't require a supervising physician
                </Radio>
              </FormControl>
              {shouldShowCollabPhysicianQuestions && (
                <React.Fragment>
                  <FormControl
                    component={TextField}
                    name={`supervisingPhysicianName`}
                    label="What is your supervising physician's name?"
                  />
                  <FormControl
                    component={TextField}
                    name={`supervisingPhysicianNpi`}
                    label="What is your supervising physician's NPI?"
                    type="number"
                  />
                  {isProviderInState(provider, UnitedStates.ILLINOIS) && (
                    <FormControl
                      component={TextField}
                      name="supervisingPhysicianLicenseNumber"
                      label="What is your supervising physician's license number?"
                    />
                  )}
                  <FieldControl
                    name="collabPhysicianAgreement"
                    fullWidth
                    css={{ marginBottom: '0' }}
                  >
                    <FormLabel>
                      Please upload a copy of your{' '}
                      <b>Prescriptive Authority Agreement</b> (also known as a{' '}
                      <b>Collaborative Practice Agreement</b>) in PDF format.
                    </FormLabel>
                    <FieldDropzone
                      inputTestId="collabPhysicianAgreement"
                      accept="application/pdf,image/*"
                      onDrop={(file: any) => onDropFiles(provider.id, file)}
                      css={{ marginBottom: '0' }}
                    />
                    <FieldErrorText />
                  </FieldControl>
                </React.Fragment>
              )}
            </>
          )}
      </div>
    </>
  );
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Board Certification',
  description:
    'In this section we ask that you share your board certification information.',
  Component: BoardCertificationStep,
  getFormMeta: ({ provider, providerQuestionnaire }) => {
    const selectedLicenses = providerQuestionnaire.rawData?.selectedLicenses;
    const hasNurseLicense = hasNurseLicenseType(selectedLicenses);
    const validationSchema = Yup.object().shape({
      hasFailedBoardCertification: Yup.string().required(
        'This question is required'
      ),
      failedExamDate: Yup.string().when(['hasFailedBoardCertification'], {
        is: (hasFailedBoardCertification) =>
          hasFailedBoardCertification === YesNo.YES,
        then: Yup.string().required('This question is required'),
        otherwise: Yup.string(),
      }),
      failedExamBoardName: Yup.string().when(['hasFailedBoardCertification'], {
        is: (hasFailedBoardCertification) =>
          hasFailedBoardCertification === YesNo.YES,
        then: Yup.string().required('This question is required'),
        otherwise: Yup.string(),
      }),
      failedExamReason: Yup.string().when(['hasFailedBoardCertification'], {
        is: (hasFailedBoardCertification) =>
          hasFailedBoardCertification === YesNo.YES,
        then: Yup.string().required('This question is required'),
        otherwise: Yup.string(),
      }),

      // depends on licenses
      isBoardCertified: hasNurseLicense
        ? Yup.string()
            .matches(
              /(YES)/,
              'Nurse practioners must be ANCC board certified in Psychiatry in order to work with Headway'
            )
            .required('This question is required')
        : Yup.string().required('This question is required'),

      boardSpecialty: Yup.string().when('isBoardCertified', {
        is: (isBoardCertified) =>
          isBoardCertified === YesNo.YES || hasNurseLicense,
        then: Yup.string().required('Specialty is required'),
        otherwise: Yup.string(),
      }),
      certifyingBoardName: Yup.string().when('isBoardCertified', {
        is: (isBoardCertified) =>
          isBoardCertified === YesNo.YES || hasNurseLicense,
        then: Yup.string().required('Name of certifying board is required'),
        otherwise: Yup.string(),
      }),
      hasBoardExpirationDate: Yup.string().when('isBoardCertified', {
        is: (isBoardCertified) =>
          isBoardCertified === YesNo.YES || hasNurseLicense,
        then: Yup.string().required('This question is required'),
        otherwise: Yup.string(),
      }),
      boardIssueDate: Yup.string().when(
        ['isBoardCertified', 'hasBoardExpirationDate'],
        {
          is: (isBoardCertified, hasBoardExpirationDate) =>
            isBoardCertified === YesNo.YES || hasNurseLicense,
          then: Yup.string().required('Issue date is required'),
          otherwise: Yup.string(),
        }
      ),
      boardExpirationDate: Yup.string()
        .nullable(true)
        .when(['isBoardCertified', 'hasBoardExpirationDate'], {
          is: (isBoardCertified, hasBoardExpirationDate) =>
            (isBoardCertified === YesNo.YES || hasNurseLicense) &&
            hasBoardExpirationDate === YesNo.YES,
          then: Yup.string().required('Expiration date is required'),
          otherwise: Yup.string(),
        }),
      boardCertificationUrl: Yup.array()
        .of(
          Yup.object().shape({
            link: Yup.string(),
            name: Yup.string(),
          })
        )
        .when('isBoardCertified', (isBoardCertified, schema) =>
          (isBoardCertified === YesNo.YES || hasNurseLicense) &&
          doesStateRequireBoardCertUpload(selectedLicenses)
            ? schema.required(
                'Please upload a copy of your Board Certification.'
              )
            : schema
        ),
      hasSecondaryBoardCertification: Yup.string(),
      secondaryBoardSpecialty: Yup.string(),
      secondaryCertifyingBoardName: Yup.string(),
      hasSecondaryBoardExpirationDate: Yup.string(),
      secondaryBoardIssueDate: Yup.string(),
      secondaryBoardExpirationDate: Yup.string().nullable(true),
      numberOfYearsOfExperience: Yup.number()
        .required('This question is required')
        .typeError('You must specify a number.'),
      requiresSupervisingPhysician:
        hasPrescriberLicenseType(selectedLicenses) &&
        hasNPLicenseInStatesRequiringNPPrescriberAgreements(selectedLicenses)
          ? Yup.string().required('This question is required')
          : Yup.string(),
      supervisingPhysicianName: Yup.string().when(
        ['requiresSupervisingPhysician'],
        (requiresSupervisingPhysician, schema) =>
          requiresSupervisingPhysician === YesNo.YES
            ? schema.required('This question is required')
            : schema
      ),
      supervisingPhysicianNpi: Yup.string()
        .matches(YUP_NPI_MATCH, YUP_NPI_ERROR_MESSAGE)
        .when(
          ['requiresSupervisingPhysician'],
          (requiresSupervisingPhysician, schema) =>
            requiresSupervisingPhysician === YesNo.YES
              ? schema.required('This question is required')
              : schema
        ),
      supervisingPhysicianLicenseNumber: Yup.string().when(
        'requiresSupervisingPhysician',
        {
          is: (requiresSupervisingPhysician) =>
            requiresSupervisingPhysician === YesNo.YES &&
            isProviderInState(provider, UnitedStates.ILLINOIS),
          then: Yup.string().required('This question is required'),
          otherwise: Yup.string(),
        }
      ),
      collabPhysicianAgreement: Yup.array()
        .of(
          Yup.object().shape({
            link: Yup.string(),
            name: Yup.string(),
          })
        )
        .when(
          ['requiresSupervisingPhysician'],
          (requiresSupervisingPhysician, schema) =>
            requiresSupervisingPhysician === YesNo.YES
              ? schema.required('This question is required')
              : schema
        ),
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData
      ),
    } as FormMeta;
  },
};

export default stepConfig;
