import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { FormControl } from '@headway/helix/FormControl';
import { ListHeader } from '@headway/helix/ListHeader';
import { PhoneNumberField } from '@headway/helix/MaskedTextField';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { AddPlus } from '@headway/icons/dist/AddPlus';
import { YUP_PHONE_MATCH } from '@headway/shared/constants/format';
import { trackEvent } from '@headway/shared/utils/analytics';
import { maskPhoneNumber } from '@headway/shared/utils/strings';
import { emergencyContactRowCss } from '@headway/ui/EmergencyContact';

import { AddPatientFormValues } from './PatientDemographicsFormIntakeFlow';

type EmergencyContactCreating = {
  fullName: string;
  phone: string;
  relationship: string;
};

export interface EmergencyContactFormIntakeFlowProps {
  emergencyContactsCreated: EmergencyContactCreating[];
  setEmergencyContactsCreated: (changeTo: EmergencyContactCreating[]) => void;
  provider: ProviderRead;
}

export const EmergencyContactFormIntakeFlow = ({
  emergencyContactsCreated,
  setEmergencyContactsCreated,
  provider,
}: EmergencyContactFormIntakeFlowProps) => {
  const [isAddingContact, setIsAddingContact] = useState(false);
  const numEmergencyContacts = (emergencyContactsCreated ?? []).length;

  const {
    values: { contactName, contactPhone, contactRelationship },
    setFieldError,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<AddPatientFormValues>();

  const clearFields = () => {
    setFieldValue('contactName', '');
    setFieldValue('contactPhone', '');
    setFieldValue('contactRelationship', '');
  };

  const handleAddNewEmergencyContact = () => {
    trackEvent({
      name: 'Add Emergency Contact Button Clicked',
      properties: {
        providerId: provider.id,
      },
    });

    setIsAddingContact(true);
  };

  return (
    <div>
      <div
        css={{
          marginBottom: theme.spacing.x4,
        }}
      >
        <div css={titleCss}>
          <ListHeader>Emergency contacts</ListHeader>
          <Badge variant="neutral">Optional</Badge>
        </div>
        <div>
          <BodyText>
            If you don't have this information now, your client can update their
            emergency contacts later from their Headway Account page
          </BodyText>
        </div>
      </div>

      {numEmergencyContacts > 0 && (
        <div>
          {emergencyContactsCreated?.map((emergencyContact, index) => (
            <div
              css={{
                borderBottom: `1px solid ${theme.color.system.borderGray}`,
              }}
            >
              <div css={emergencyContactRowCss}>
                <div>
                  <BodyText>
                    <strong>Name</strong>
                  </BodyText>
                  <div className="canTextOverflow">
                    <BodyText>{emergencyContact.fullName}</BodyText>
                  </div>
                </div>

                <div>
                  <BodyText>
                    <strong>Phone number</strong>
                  </BodyText>
                  <BodyText>{maskPhoneNumber(emergencyContact.phone)}</BodyText>
                </div>

                <div>
                  <BodyText>
                    <strong>Relationship</strong>
                  </BodyText>
                  <div className="canTextOverflow">
                    <BodyText>{emergencyContact.relationship}</BodyText>
                  </div>
                </div>
                <Button
                  variant="link"
                  size="medium"
                  onPress={async () => {
                    setEmergencyContactsCreated(
                      emergencyContactsCreated.filter((_, i) => index !== i)
                    );
                  }}
                >
                  Remove
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
      {numEmergencyContacts < 2 &&
        (!isAddingContact ? (
          <div css={addLinkButtonCss}>
            <Button
              variant="link"
              size="medium"
              onPress={handleAddNewEmergencyContact}
            >
              <AddPlus />
              Add a new emergency contact
            </Button>
          </div>
        ) : (
          <div
            css={{
              marginTop: theme.spacing.x3,
              display: 'grid',
              gap: theme.spacing.x4,
            }}
          >
            <FormControl
              component={TextField}
              name="contactName"
              label="Name"
            />
            <FormControl
              component={PhoneNumberField}
              name="contactPhone"
              label="Phone number"
            />
            <FormControl
              component={TextField}
              name="contactRelationship"
              label="Relationship to client"
            />
            <div
              css={{
                ...theme.stack.horizontal,
                gap: theme.spacing.x2,
              }}
            >
              <div>
                <Button
                  variant="secondary"
                  onPress={() => {
                    setIsAddingContact(false);
                    clearFields();
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  variant="primary"
                  onPress={() => {
                    if (
                      !(
                        contactName &&
                        contactPhone &&
                        contactRelationship &&
                        contactPhone.match(YUP_PHONE_MATCH)
                      )
                    ) {
                      if (!contactName) {
                        setFieldTouched('contactName', true, false);
                        setFieldError(
                          'contactName',
                          "You must enter the name of your client's contact"
                        );
                      }

                      if (!contactPhone) {
                        setFieldTouched('contactPhone', true, false);
                        setFieldError(
                          'contactPhone',
                          'You must add a phone number for your contact'
                        );
                      } else if (!contactPhone.match(YUP_PHONE_MATCH)) {
                        setFieldTouched('contactPhone', true, false);
                        setFieldError(
                          'contactPhone',
                          'Phone number must be 10 digits'
                        );
                      }
                      if (!contactRelationship) {
                        setFieldTouched('contactRelationship', true, false);
                        setFieldError(
                          'contactRelationship',
                          'You must indicate your relationship to your contact'
                        );
                      }
                    } else {
                      setIsAddingContact(false);
                      const emergencyContactsCreatedNew = [
                        ...emergencyContactsCreated,
                        {
                          fullName: contactName,
                          phone: contactPhone,
                          relationship: contactRelationship,
                        },
                      ];
                      setEmergencyContactsCreated(emergencyContactsCreatedNew);

                      trackEvent({
                        name: 'Save Emergency Contact Button Clicked',
                        properties: {
                          providerId: provider.id,
                        },
                      });
                      clearFields();
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const titleCss = css`
  display: flex;
  margin-bottom: ${theme.spacing.x3};
  gap: ${theme.spacing.x2};
`;
const addLinkButtonCss = css`
  margin: ${theme.spacing.x3} 0 ${theme.spacing.x3} 0;
  row-gap: 20px;
  & > Button {
    display: flex;
    justify-content: center;
    gap: ${theme.spacing.x1};
  }
`;
