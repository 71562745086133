import { AxiosError, AxiosResponse } from 'axios';

import { HTTPValidationError } from '@headway/api/models/HTTPValidationError';

type ErrorDetail = HTTPValidationError | { detail: string };
/** Stub type. Only contains fields related to error message processing */
interface FastApiErrorResponse extends AxiosResponse {
  response: AxiosResponse<ErrorDetail>;
}

const isFastApiErrorResponse = (
  err: AxiosError | FastApiErrorResponse
): err is FastApiErrorResponse => {
  return !!(
    err?.response?.data &&
    typeof err.response.data === 'object' &&
    'detail' in err.response.data
  );
};

export const getApiErrorMessage = (err: AxiosError): string => {
  const defaultError = err.toString();

  if (isFastApiErrorResponse(err)) {
    const errorDetail = err.response.data.detail;

    if (!errorDetail) {
      return defaultError;
    }

    if (Array.isArray(errorDetail)) {
      return `Error: ${errorDetail.map((detail) => detail.msg).join('. ')}`;
    } else {
      return `Error: ${errorDetail}`;
    }
  }

  return defaultError;
};
