import Fuse from 'fuse.js';
import * as React from 'react';

export type Options<T> = Fuse.IFuseOptions<T>;

/**
 * A React Hook for fuzzy search functionality.
 *
 * Wrapper around fuse.js.
 * @see https://fusejs.io/
 */
function useFuzzy<T>(list: T[], options?: Options<T>) {
  const fuse = React.useMemo(() => {
    return new Fuse(list, options);
  }, [list, options]);

  const search = React.useCallback(
    (searchToken: string) => {
      if (searchToken === '') {
        return list;
      }

      return fuse.search(searchToken).map((res) => res.item);
    },
    [fuse]
  );

  return { search };
}

export default useFuzzy;
