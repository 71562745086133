import moment from 'moment';
import React from 'react';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';

import { CDSCertificate } from '@headway/api/models/CDSCertificate';
import { DEACertificate } from '@headway/api/models/DEACertificate';
import { ProviderQuestionnaireRawData } from '@headway/api/models/ProviderQuestionnaireRawData';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import '@headway/api/resources/ProviderApi';
import { BodyText } from '@headway/helix/BodyText';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { theme } from '@headway/helix/theme';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import { getSupportedStates } from '@headway/shared/utils/ProviderLicenseStatesHelper';
import {
  FieldControl,
  FieldDatePicker,
  FieldDropzone,
  FieldErrorText,
} from '@headway/ui/form';
import { FieldTextField } from '@headway/ui/form/FieldTextField';
import { FormRow } from '@headway/ui/form/FormRow';

import {
  onDropFiles,
  statesRequiringCDS,
} from '../../../utils/providerQuestionnaire';
import { useQuestionnaireContext } from '../QuestionnaireV2Context';
import { FormMeta, QuestionnaireV2Step } from '../QuestionnaireV2Step';
import { CustomComponentProps } from '../utils/CustomComponentProps';
import { transformDateToNullOrStartOfDay } from '../utils/transformDateToNullOrStartOfDay';
import { yupSchemaToDefaultValue } from '../utils/yupSchemaToDefaultValue';

export enum IDCertificationType {
  CDS = 'CDS',
  DEA = 'DEA',
}

const StateCertsForm = ({
  state,
  rawData,
}: {
  state: UnitedStates;
  rawData: ProviderQuestionnaireRawData;
}) => {
  const isStateRequiringCDS = statesRequiringCDS.has(state);

  return (
    <div
      css={{
        ...theme.stack.vertical,
        gap: theme.spacing.x2,
        borderBottom: `1px solid ${theme.color.system.borderGray}`,
        ':last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <SectionHeader>{statesToDisplayNames[state]}</SectionHeader>
      <IDCertificationForm
        state={state}
        idCertType={IDCertificationType.DEA}
        rawData={rawData}
      />
      {isStateRequiringCDS && (
        <IDCertificationForm
          state={state}
          idCertType={IDCertificationType.CDS}
          rawData={rawData}
        />
      )}
    </div>
  );
};

const IDCertificationForm = ({
  state,
  idCertType,
  rawData,
}: {
  state: UnitedStates;
  idCertType: IDCertificationType;
  rawData: ProviderQuestionnaireRawData;
}) => {
  const { provider } = useQuestionnaireContext();
  let formPrefix: string;
  let fieldDescription: string;
  let fieldDescriptionForUpload: string;
  let cert: DEACertificate | CDSCertificate | undefined;
  if (idCertType === IDCertificationType.DEA) {
    cert = rawData.userInputtedDeaCertificates?.[state];
    formPrefix = `userInputtedDeaCertificates[${state}]`;
    fieldDescription = 'DEA registration';
    fieldDescriptionForUpload = `${fieldDescription} certificate`;
  } else {
    cert = rawData.userInputtedCdsCertificates?.[state];
    formPrefix = `userInputtedCdsCertificates[${state}]`;
    fieldDescription = 'CDS license';
    fieldDescriptionForUpload = fieldDescription;
  }

  if (!cert) {
    return null;
  }

  return (
    <div>
      <div className="my-5">
        <b>{fieldDescription}</b>
      </div>
      <FieldControl name={`${formPrefix}number`} fullWidth={true}>
        <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
          <b>{fieldDescription} number</b>
        </span>
        <FieldTextField size="small" />
        <FieldErrorText />
      </FieldControl>
      <FormRow>
        <FieldControl name={`${formPrefix}issueDate`} fullWidth={true}>
          <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
            <b>Date issued</b>
          </span>
          <FieldDatePicker
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
            maxDate={moment()}
          />
          <FieldErrorText />
        </FieldControl>
        <FieldControl name={`${formPrefix}expirationDate`} fullWidth={true}>
          <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
            <b>Expiration date</b>
          </span>
          <FieldDatePicker
            inputFormat="MM/DD/YYYY"
            placeholder="MM/DD/YYYY"
            variant="outlined"
            size="small"
            minDate={moment()}
          />
          <FieldErrorText />
        </FieldControl>
      </FormRow>
      <FieldControl name={`${formPrefix}uploadUrl`} fullWidth={true}>
        <span css={{ fontSize: theme.typography.caption.regular.fontSize }}>
          <b>Upload a copy of your {fieldDescriptionForUpload}</b>
        </span>
        <FieldDropzone
          accept="application/pdf,image/*"
          onDrop={(file: any) => onDropFiles(provider.id, file)}
          css={{ marginBottom: '0', marginTop: '0' }}
        />
        <FieldErrorText />
      </FieldControl>
    </div>
  );
};

const PrescriberRegistrations = ({
  initialValues,
  formikHelpers,
}: CustomComponentProps) => {
  const { provider, providerQuestionnaire } = useQuestionnaireContext();
  const providerStates = getSupportedStates(provider);

  // if raw data has dea/cds from caqh but no user inputted yet, then we have pre-populated form with caqh data
  const { rawData } = providerQuestionnaire;
  const isFormPrepoulatedWithCAQHData =
    !!rawData?.deaCertificates?.length &&
    !!rawData?.cdsCertificates?.length &&
    !rawData?.userInputtedDeaCertificates &&
    !rawData?.userInputtedCdsCertificates;

  return (
    <>
      <BodyText>
        As a prescriber, you are required to have a DEA (Drug Enforcement
        Administration) registration in every state where you practice on
        Headway. Some states also require a CDS (Controlled Dangerous
        Substances) license.
      </BodyText>
      {isFormPrepoulatedWithCAQHData && (
        <GuidanceCard variant="neutral">
          We pre-filled this form with information from your CAQH account.
        </GuidanceCard>
      )}
      <div
        css={{
          ...theme.stack.vertical,
          gap: theme.spacing.x6,
          marginTop: '16px',
          borderTop: `1px solid ${theme.color.system.borderGray}`,
          paddingTop: '24px',
        }}
      >
        {providerStates.map((state) => (
          <StateCertsForm key={state} state={state} rawData={initialValues} />
        ))}
      </div>
    </>
  );
};

const getCertificateValidationSchema = (type: string) => {
  return Yup.object().shape({
    number: Yup.string().required('This field is required.'),
    state: Yup.string().required('This field is required.'),
    issueDate: Yup.date()
      .nullable()
      .transform(transformDateToNullOrStartOfDay)
      .required('This field is required.')
      .max(new Date(), 'Issue date cannot be in the future.'),
    expirationDate: Yup.date()
      .nullable()
      .transform(transformDateToNullOrStartOfDay)
      .required('This field is required.')
      .min(new Date(), 'Expiration date cannot be in the past.'),
    uploadUrl: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          link: Yup.string(),
          name: Yup.string(),
        })
      )
      .required('This field is required.'),
  });
};

type CertificatesValidation = {
  [state in UnitedStates]: ObjectSchema;
};

const stepConfig: QuestionnaireV2Step = {
  title: 'Prescriber Registrations',
  description:
    'As a prescriber, you are required to have a DEA (Drug Enforcement Administration) registration in every state where you practice on Headway. Some states also require a CDS (Controlled Dangerous Substances) license.',
  recredDescription: 'Please verify the following fields:',
  Component: PrescriberRegistrations,
  getFormMeta: ({ provider, providerQuestionnaire }) => {
    const providerStates = provider.activeProviderLicenseStates.map(
      (pls) => pls.state
    );
    const validationSchema = Yup.object().shape({
      userInputtedDeaCertificates: Yup.object().shape(
        providerStates.reduce((acc, state) => {
          acc[state] = getCertificateValidationSchema(IDCertificationType.DEA);
          return acc;
        }, {} as CertificatesValidation)
      ),
      userInputtedCdsCertificates: Yup.object().shape(
        providerStates
          .filter((state) => statesRequiringCDS.has(state))
          .reduce((acc, state) => {
            acc[state] = getCertificateValidationSchema(
              IDCertificationType.CDS
            );
            return acc;
          }, {} as CertificatesValidation)
      ),
    });

    const { rawData } = providerQuestionnaire;
    // user inputted certificate values come from:
    //   1. raw data if it has user inputted data already
    //   2. raw data if it has CAQH deaCertificates/cdsCertificates
    //   3. default empty data otherwise
    const userInputtedCertificateValues = {
      userInputtedDeaCertificates:
        rawData?.userInputtedDeaCertificates ||
        ({} as NonNullable<
          ProviderQuestionnaireRawData['userInputtedDeaCertificates']
        >),
      userInputtedCdsCertificates:
        rawData?.userInputtedCdsCertificates ||
        ({} as NonNullable<
          ProviderQuestionnaireRawData['userInputtedCdsCertificates']
        >),
    };
    providerStates.forEach((state) => {
      if (!userInputtedCertificateValues.userInputtedDeaCertificates[state]) {
        const deaFromRawData = rawData?.deaCertificates?.find(
          (cert) => cert.state === state
        );
        userInputtedCertificateValues.userInputtedDeaCertificates[state] =
          deaFromRawData
            ? { ...deaFromRawData }
            : {
                state,
                expirationDate: '',
                number: '',
              };
      }

      const isStateRequiringCDS = statesRequiringCDS.has(state);
      if (
        isStateRequiringCDS &&
        !userInputtedCertificateValues.userInputtedCdsCertificates[state]
      ) {
        const cdsFromRawData = rawData?.cdsCertificates?.find(
          (cert) => cert.state === state
        );
        userInputtedCertificateValues.userInputtedCdsCertificates[state] =
          cdsFromRawData
            ? { ...cdsFromRawData }
            : {
                state,
                expirationDate: '',
                number: '',
              };
      }
    });

    return {
      validationSchema: validationSchema,
      initialValue: Object.assign(
        yupSchemaToDefaultValue(validationSchema),
        providerQuestionnaire.rawData,
        userInputtedCertificateValues
      ),
    } as FormMeta;
  },
};

export default stepConfig;
