import * as React from 'react';

export const BigPlus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 24H48" stroke="#353535" stroke-width="3" />
    <path d="M24 48L24 0" stroke="#353535" stroke-width="3" />
  </svg>
);
